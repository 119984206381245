import { Injectable, OnDestroy } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { FormlyCommentComponent } from 'src/app/components/formly-builder/shared/comment-fr-notes/formly-comment-fr-sheet.component';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AttachmentDataModel } from 'src/app/models/formly-builder/formly/full-risk/attachment-data.model';
import { SectionEnum } from './section.config';
import { CommentsDataModel } from 'src/app/models/formly-builder/formly/full-risk/comment-fr-data.model';
import { RiskReportService } from './risk-report.service';
import { FormlyBuilderService } from './formly-builder.service';
import { formatDate } from '@angular/common';
import { FridService } from '../frid-service/frid.service';
import { UtilService } from 'src/app/services/util-service/util.service';


@Injectable({
  providedIn: 'root',
})
export class FormlyCommentService extends SectionServiceAbstract implements OnDestroy {
  protected ngUnsubscribe = new Subject<void>();
  constructor(private riskReportService: RiskReportService, private formlyBuilderService :FormlyBuilderService,private fridService: FridService, private utilService: UtilService) {
    super();
  }

  async updateData(model, riskReportData, sectionEnum: SectionEnum) {
    const attachmentsRRModel = [];
    const attachments = model?.attachments;
    for (let i = 0; i < attachments?.length; i++) {
      const attachment = attachments[i];
      attachment.attachmentSequence = i;
      const attachmentData = AttachmentDataModel.to(attachment);
      attachmentsRRModel.push(attachmentData); 
    }

    if (riskReportData?.EvidenceOfFireSprinkler === 'Y' && riskReportData?.SprinklerReport) {
      // Remove Sprinkler attachments from SprinklerReport
      riskReportData.SprinklerReport.ReportAttachments = [];
    }
    riskReportData.ReportAttachments = attachmentsRRModel;

    const commentArray = [];
    const comments = model?.comments
    if (comments !== null && comments !== undefined) {

      const commentData = CommentsDataModel.to(comments, riskReportData.ReportIdentifier);
      commentArray.push(commentData);
    }

    const frNotes = model?.frNotes
    if (frNotes !== null && frNotes !== undefined) {

      const frNotesData = CommentsDataModel.to(frNotes, riskReportData.ReportIdentifier);
      commentArray.push(frNotesData);
    }
    
    const finalCommentArray=commentArray.filter((value) =>{
      return value?.CommentDateTime && value?.CommentSegments[0]?.CommentSegmentText;
    });
    finalCommentArray.forEach((comment,index)=>{
      comment.CommentSegments[0].CommentSegmentSequenceNumber = comment?.CommentSegments[0]?.CommentSegmentSequenceNumber ? comment?.CommentSegments[0]?.CommentSegmentSequenceNumber : index;
    })

    return { ...riskReportData,  ...{ Comments: finalCommentArray } };
  }

  loadData(test: any[], fullData: any, isS3: boolean, model: any): any {
    const attachmentsArray: AttachmentDataModel[] = [];

    fullData?.ReportAttachments?.forEach((rrAttachment) => { 
      const attachment = AttachmentDataModel.fromS3(rrAttachment);
      // if (attachment.attachmentTypeCodeValue !== "0009") {
      attachmentsArray.push(attachment);
      // }
    });

    // Move SprinklerReport attachments to RR attachments
    let hasSprinklerAttachments = false;
    fullData?.SprinklerReport?.ReportAttachments?.forEach((srAttachment) => {
      const attachment = AttachmentDataModel.fromS3Asgr(srAttachment);
      attachment.attachmentTimestamp = fullData?.SprinklerReport?.SurveyDate;
      attachmentsArray.push(attachment);
      hasSprinklerAttachments = true;
    });

    const cmtData = CommentsDataModel.fromS3(fullData?.Comments);

    // Commented out: Don't save the RR right after loading it to avoid updating status
    // If the risk has Sprinkler Attachments, save updated data
    // if (hasSprinklerAttachments) {
    //   setTimeout(() => {
    //     this.riskReportService.updateSectionData(SectionEnum.FR_COMMENT_ATTACHMENT, false);
    //   }, 1000);
    // }

    return { ...model, ...{ attachments: attachmentsArray }, ...cmtData };
  }

  public addSheet = async (modalController: ModalController, field: FormlyFieldConfig) =>{

    const modal = await modalController.create({
      component: FormlyCommentComponent,
      componentProps: {
        field,
      },
      cssClass: 'modal-wrapper schedule-search-results-modal',
    });
    modal.present();
    const response = await modal.onWillDismiss();
    if (response?.role === 'save') {
      field?.formControl?.get(field.props.commentActionKey)?.setValue(response.data);
      const currentDate=new Date()
      const frId = await this.fridService.getToken();
      // ...So update the model as well...
      const updatedFrm = this.formlyBuilderService?.riskReport?.model;
      if (field?.props?.commentActionKey === 'formlyCommentSegmentText') {
        updatedFrm.comments.formlyCommentSegmentText = field?.formControl?.get('formlyCommentSegmentText')?.value;
        updatedFrm.comments.commentType = 'COMT'
        updatedFrm.comments.commentDateTime = this.utilService.convertDateToISOStringFormat(currentDate)
        updatedFrm.comments.reportIdentifier=this.formlyBuilderService?.riskReport?.report?.ReportIdentifier;
        updatedFrm.comments.commentBy =frId
      }
      if (field?.props?.commentActionKey === 'formlyFRNotesSegmentText') {
        updatedFrm.frNotes.formlyFRNotesSegmentText = field?.formControl?.get('formlyFRNotesSegmentText')?.value;
        updatedFrm.frNotes.commentType = 'FRNT'
        updatedFrm.frNotes.commentDateTime = this.utilService.convertDateToISOStringFormat(currentDate)
        updatedFrm.frNotes.reportIdentifier=this.formlyBuilderService?.riskReport?.report?.ReportIdentifier;
        updatedFrm.frNotes.commentBy =frId
      }
      this.formlyBuilderService.riskReport.model = { ...updatedFrm };
      const rr = await this.riskReportService.updateSectionData(SectionEnum.FR_COMMENT_ATTACHMENT, false);

    }
  };

  
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
