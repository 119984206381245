import { Storage as IonicLocalStorage } from '@ionic/storage-angular';
import { Constants } from '../util-service/constants';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class IonicStorageService {
    private orderQueue = new BehaviorSubject<any>({});
    public orderQueue$ = this.orderQueue.asObservable();

    constructor(private ionicStorage: IonicLocalStorage) {
        this.ionicStorage.create().then(() => {
            this.initializeOrderQueue();
        });
    }

    async initializeOrderQueue() {
        const orderQueue = await this.ionicStorage.get(Constants.orderQueue);
        if(!orderQueue) {
            this.ionicStorage.set(Constants.orderQueue, {});
        } else {
            this.orderQueue.next(orderQueue);
        }
    }

    storeOrderQueue(queue: any) {
        this.ionicStorage.set(Constants.orderQueue, queue).then(() => {
            this.orderQueue.next(queue);
        });
    }

    updateOrderQueue(orderDetails) {
        let orderQueue = this.orderQueue.value;

        orderQueue[orderDetails.id] = orderDetails;
        this.storeOrderQueue(orderQueue);
    }

}