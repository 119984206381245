import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'ionic-appauth';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { AmplifyService } from '../services/amplify/amplify.service';
import { OfflineTokenValidationService } from './offline-auth/offline-token-validation.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    private auth: AuthService,
    private navCtrl: NavController,
    private amplify: AmplifyService,
    private offlineValidation: OfflineTokenValidationService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.amplify.networkConnection.pipe(
      switchMap((value: boolean) => {
        if (value) {
          //Network is available
          return this.auth.initComplete$.pipe(
            filter((complete) => complete),
            switchMap(() => this.auth.isAuthenticated$),
            tap((isAuthenticated) => {
              if (!isAuthenticated) {
                this.navCtrl.navigateRoot(['login']);
              }
            })
          );
        } else {
          //Network is not available
          return this.offlineValidation.isValid$;
        }
      }),
      tap((isValid) => {
        if (!isValid) {
          this.navCtrl.navigateRoot(['login']);
        }
      })
    );
  }
}
