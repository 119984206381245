/* eslint-disable @typescript-eslint/naming-convention */
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { BuildingConditionExcessSingleStoryDataModel } from './building-condition-excess-single-story-data.model';
import { Guid } from 'guid-typescript';

export class BuildingConditionDataModel {
  static sectionName = 'buildingCondition';

  isC1orC2combustibility: boolean;
  excessiveSingleStoryHeight?: any[];
  buildingCondition: BuildingConditions;

  static fromS3(buildingConditionData: any, sprinklerReport: any): BuildingConditionDataModel {
    const bcExcessSingleStoriesArray: any[] = [];
    if (buildingConditionData?.BuildingConditionExcessSingleStories && buildingConditionData?.BuildingConditionExcessSingleStories.length) {
      buildingConditionData.BuildingConditionExcessSingleStories.forEach((story) => {
        const bcExcessSingleStoriesData = BuildingConditionExcessSingleStoryDataModel.fromS3(story);
        bcExcessSingleStoriesArray.push(bcExcessSingleStoriesData);
      });
    }
    const buildingCondition = BuildingConditionDataModel.setBuildingConditions(buildingConditionData);
    const isC1orC2combustibility = sprinklerReport?.AsgrWaterSupply?.BuildingCombustibilityClass &&
      (sprinklerReport?.AsgrWaterSupply?.BuildingCombustibilityClass === 1 ||
        sprinklerReport?.AsgrWaterSupply?.BuildingCombustibilityClass === 2)
      ? true : false;
    return {
      isC1orC2combustibility: isC1orC2combustibility,
      excessiveSingleStoryHeight: bcExcessSingleStoriesArray,
      buildingCondition
    };
  }

  static to(model: any, reportId?: Guid) {
    const buildingConditionExcessSingleStoriesData = [];
    model?.excessiveSingleStoryHeight?.forEach((story) => {
      buildingConditionExcessSingleStoriesData.push(BuildingConditionExcessSingleStoryDataModel.to(story));
    });
    return {
      ReportId: model?.buildingCondition?.reportId ? model?.buildingCondition?.reportId : reportId,
      FloorOpeningProtection: model?.buildingCondition?.floorOpeningProtection,
      OpenSidedLevels: Number(model?.buildingCondition.openSidedLevels) ? Number(model?.buildingCondition.openSidedLevels) : 0,
      IsC1orC2combustibility: model.isC1orC2combustibility ? model.isC1orC2combustibility : false,
      BuildingConditionExcessSingleStories: buildingConditionExcessSingleStoriesData,
      IsBuildingConditionSavedToS3 : model?.buildingCondition?.reportId !== null ? true : false,
      // Report: null,
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
    return false;

  }
  // To set building conditions values
  static setBuildingConditions(buildingConditionData: any) {
    const buildingCondition = {
      reportId: buildingConditionData?.ReportId,
      floorOpeningProtection: buildingConditionData?.FloorOpeningProtection?.trim(),
      openSidedLevels: buildingConditionData?.OpenSidedLevels?.toString(),
      isBuildingConditionSavedToS3 : buildingConditionData?.IsBuildingConditionSavedToS3
    };
    return buildingCondition;
  }
}
class BuildingConditions {
  reportId: string;
  floorOpeningProtection: string;
  openSidedLevels: number;
}
