/* eslint-disable @typescript-eslint/prefer-for-of */
import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SectionEnum } from './section.config';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class HookReuseableService  {
  constructor() { }
   /**
   * Set a valueChanges for a specific field in a section to update
   * @param form // sending entire form
   * @param arrayofObjs  // Sending array of objects  which contains Keys and values need to append to formcontrol
   */
  fieldSetValue(form, arrayofObjs) {
    for (let i = 0; i < arrayofObjs.length; i++) {
      form?.get(`${arrayofObjs[i].key}`)?.setValue(arrayofObjs[i].value);
    }
  }
  
   /**
   * Set a valueChanges for a specific field in a section to update
   * @param form // sending entire form
   * @param array // Sending array  which contains Keys needs to be disabled
   */
  disableFields(form, array) {
    for (let i = 0; i < array.length; i++) {
      form?.get(`${array[i]}`)?.disable();
    }
  }

   /**
   * Set a valueChanges for a specific field in a section to update
   * @param form // sending entire form
   * @param array // Sending array which contains Keys needs to be enabled
   */
  enableFields(form, array) {
    for (let i = 0; i < array.length; i++) {
      form?.get(`${array[i]}`)?.enable();
    }
  }

   /**
   * Set a valueChanges for a specific field in a section to update
   * @param value // Check whether formcontrol is null or undefined or empty string
   */
  isCheckNull(value): boolean {
    if (value === null || value === '' || value === undefined || value === 'null') {
      return false;
    }
    else {
      return true;
    }
  }

  /**
   * Set a valueChanges for a specific field in a section to update
   * 
   * @param field
   * @param section
   */
  onSectionFieldChange(field: FormlyFieldConfig, section: SectionEnum, value: any) {
    if (!value && field.formControl.pristine) {
      field.formControl.markAsTouched();
    }
    if (!field.formControl.pristine) {
      if (field.formControl.valid) {
        const formlyService = field.options.formState.service;
        formlyService.riskReport.updateSectionData(section, false);
      }
    }
    field.options.formState.service.valid = field.formControl.valid;
  }

}
