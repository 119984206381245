import { AbstractControl, FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export const percentageSumValidation = ((controlValue): boolean => {
  let sum = 0;
    if(controlValue){
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for(let i=0;i<controlValue.length;i++){
         sum=sum+controlValue[i].constructionPercentage;
      }
      if(sum===100){
        return true;
      }
    return false;
}
});

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function maxLengthForNumber(value: string,maxLength: number){
  return (value?.length === maxLength) ? true : false;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function numberCheck(charCode){
  return (charCode > 31 && (charCode < 48 || charCode > 57)) ? false : true;
}

export function enforceSimpleNumericInput(field: FormlyFieldConfig, event: any): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (field?.props?.maxLength && maxLengthForNumber(event.target.value, field.props.maxLength)) {
    event.preventDefault();
  }
  return numberCheck(charCode);
}