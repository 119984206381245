import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class WindEnvironmentExposuresSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      type: 'rf-sheet-section',
      key: 'environmentsAndExposure',
      props: {
        label: 'Environment and Exposures',
        subTitle: 'Environment and Exposures',
        title: 'Wind Report',
        isCollapsable: true,
        isCollapsed: false,
        isDisableable: true,
        actionButtons: [
          {
            section: 'windReport',
            action: 'showFormlySheet',
            label: 'Add',
            isDisabled: false,
            service: 'formlyWrapperService'
          },
        ],
        sheetButtons: [
          {
            section: 'windReport',
            label: 'Close',
            action: 'onCloseWindReport',
            service: 'windEnvironmentExposuresService',
            className: 'close-btn medium-btn',
            passInitialModel: true,
          },
          {
            section: 'windReport',
            label: 'Apply',
            action: 'onApply',
            service: 'windEnvironmentExposuresService',     // Can't be global
            className: 'apply-btn large-btn',
            hasConfirmationPopup: true
          },
        ],
      },
      fieldGroup: [
        {
          key: 'environment',
          wrappers: [ 'simple-section-wrapper' ],
          fieldGroupClassName: 'wr-environment-exposures-container',
          className: 'wr-environment',
          props: {
            label: 'Environment',
            isCollapsable: true,
            className: 'padding-bottom-20',
            checkTouchedControl: true
          },
          hooks: {
            onInit: (field: FormlyFieldConfig) => {
              let touched = false;
              field.fieldGroup.forEach(x => {
                if (x.formControl?.value !== undefined && x.formControl?.value !== null) {
                  x.formControl.parent.markAllAsTouched();
                  touched = true;
                }
              });
              if (!touched) {
                field.fieldGroup.forEach(x => {
                  x.formControl.markAsUntouched();
                });
              }
            },
          },
          fieldGroup: [
            {
              key: 'proximityToCoastLine',
              type: 'simple-select',
              className: 'select-full-width',
              props: {
                label: 'Proximity to the Mainland Coast',
                required: true,
                isSwipeGesture: true,
                isSectionLabelNormal: true,
                firstColSize: 6,
                showError: false,
                isCardEnabled: true,
                isHorizontalOrientation: true,
                isAsteriskNotRequired: true,
                options: [
                  { value: 'GT1M', label: '> 1 mile' },
                  { value: 'LT1M', label: '1500’ to 1 mile' },
                  { value: 'LT15', label: '< 1500’' },
                  { value: 'BARR', label: 'On Barrier or Coastal Island (Open water on 2 or more sides)' },
                ],
              },
              validators: {
                terrainValidation: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const terrain = field.form.get('surroundingTerrain');
                    const value = control.value;
                    if (terrain?.value !== null && terrain?.value === 'Directly on the Water') {
                      if (value === 'LT15' || value === 'BARR') {
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return true;
                  },
                  message: `If Surrounding Terrain is "directly on the water" then Proximity to coastline should be <1500' or Barrier Island`
                }
              }
            },
            {
              key: 'surroundingTerrain',
              type: 'simple-select',
              className: 'select-full-width',
              props: {
                label: 'Surrounding terrain',
                required: true,
                isSectionLabelNormal: true,
                isCardEnabled: true,
                isHorizontalOrientation: true,
                isSwipeGesture: true,
                firstColSize: 6,
                showError: false,
                isAsteriskNotRequired: true,
                options: [
                  { value: 'Urban Setting', label: 'Urban Setting' },
                  { value: 'Suburban Setting', label: 'Suburban Setting' },
                  { value: 'Open Terrain', label: 'Open Terrain' },
                  { value: 'Directly on the Water', label: 'Directly on the Water' },
                ],
              },
              expressions: {
                'props.isDisabled': (field: FormlyFieldConfig) => {
                  if (field.formControl?.value === 'Directly on the Water' && field.form?.get('proximityToCoastLine')?.value === 'BARR') {
                    return true;
                  } else {
                    return false;
                  }
                }
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  field.options.formState.service.windEnvironmentExposuresService.setSurroundingTerrainValue(field);
                  field.form?.get('surroundingTerrain')?.valueChanges.subscribe((value) => field.form.get('proximityToCoastLine')?.updateValueAndValidity());
                },
              },
            },
            {
              key: 'yardPropertyExposureComment',
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  field.formControl?.valueChanges.subscribe((value) => field.form.get('yardPropertyExposure')?.updateValueAndValidity());
                }
              }
            },
            {
              key: 'yardPropertyExposure',
              type: 'simple-select',
              wrappers: [ 'question-wrapper' ],
              className: 'select-full-width right-padding',
              props: {
                label: 'Yard property exposures',
                className: 'field-extended-select-container',
                required: true,
                isLabelDisabled: true,
                isSectionLabelNormal: true,
                isSwipeGesture: true,
                isCommentActionEnabled: true,
                firstColSize: 6,
                showError: false,
                isAsteriskNotRequired: true,
                commentActionKey: 'yardPropertyExposureComment',
                maxLength: 100,
                options: [
                  { value: "Yes, Insured's Property", label: 'Yes-Insured’s Property' },
                  { value: "Yes, Neighbor's Property", label: 'Yes-Neighbor’s Property' },
                  { value: "No Yard Property", label: 'No Yard Property' },
                ],
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  if (field.formControl?.value === "Yes, Insured's Property" || field.formControl?.value === "Yes, Neighbor's Property") {
                    field.formControl?.markAsTouched();
                  }
                  field.formControl?.valueChanges.subscribe((value) => {
                    field.formControl?.markAsTouched();
                  });
                },
              },
              validators: {
                fieldCommentValidation: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const comment = field?.form?.get('yardPropertyExposureComment')?.value;
                    const value = control.value;
                    if ((value !== null && value === "Yes, Neighbor's Property") || value == "Yes, Insured's Property") {
                      if (comment !== null && comment && comment.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return true;
                  },
                  message: 'Yard property exposures Comment is required',
                },
              },
            },
          ],
        },
        {
          key: 'exposures',
          wrappers: [ 'simple-section-wrapper' ],
          fieldGroupClassName: 'wr-environment-exposures-container',
          props: {
            label: 'Exposures',
            className: 'padding-bottom-20',
            checkTouchedControl: true
          },
          hooks: {
            onInit: (field: FormlyFieldConfig) => {
              let touched = false;
              field.fieldGroup.forEach(y => {
                y.fieldGroup.forEach(x => {
                  if (x.formControl.value !== undefined && x.formControl.value !== null) {
                    x.formControl.parent.markAllAsTouched();
                    touched = true;
                  }
                });
                if (!touched) {
                  y.fieldGroup.forEach(x => {
                    x.formControl.markAsUntouched();
                  });
                }
              });
            },
          },
          fieldGroup: [
            {
              fieldGroup: [
                {
                  key: 'surroundingBuildingsWithRoofGravelComment',
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field.formControl?.valueChanges.subscribe((value) => field.form.get('hasSurroundingBuildingsWithRoofGravel')?.updateValueAndValidity());
                    }
                  }
                },
                {
                  key: 'hasSurroundingBuildingsWithRoofGravel',
                  type: 'rf-yes-no',
                  wrappers: [ 'question-wrapper' ],
                  defaultValue: null,
                  className: 'exposure-yes-no-height',
                  props: {
                    label: `Do any buildings within 300' radius or 1000' radius for building 6 stories or higher have roof gravel?`,
                    isSwipeGesture: true,
                    isCommentActionEnabled: true,
                    commentActionKey: 'surroundingBuildingsWithRoofGravelComment',
                    rightAlign: true,
                    required: true,
                    firstColSize: 8,
                    showError: false,
                    isAsteriskNotRequired: true,
                    maxLength: 100
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field.formControl?.valueChanges.subscribe((value) => {
                        field.formControl?.markAsTouched();
                      });
                    },
                  },
                  validators: {
                    fieldCommentValidation: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        const comment = field.form.get('surroundingBuildingsWithRoofGravelComment')?.value;
                        const value = control.value;
                        if (value !== null && (value === 'true' || value === true)) {
                          if (comment !== undefined && comment !== null && comment?.length > 0) {
                            return true;
                          } else {
                            return false;
                          }
                        }
                        return true;
                      },
                      message: `Do any buildings within 300' radius or 1000' Comment is Required`,
                    },
                  },
                },
                {
                  key: 'surroundingBuildingsWithDebrisPotentialComment',
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field.formControl?.valueChanges.subscribe((value) => field.form.get('hasSurroundingBuildingsWithDebrisPotential')?.updateValueAndValidity());
                    }
                  }
                },
                {
                  key: 'hasSurroundingBuildingsWithDebrisPotential',
                  type: 'rf-yes-no',
                  wrappers: [ 'question-wrapper' ],
                  defaultValue: null,
                  className: 'exposure-yes-no-height',
                  props: {
                    label: `Is there a potential for debris from building structures or equipment other than yard property within 300' radius or 1000' radius for buildings 6 stories or higher? `,
                    isSwipeGesture: true,
                    isCommentActionEnabled: true,
                    commentActionKey: 'surroundingBuildingsWithDebrisPotentialComment',
                    rightAlign: true,
                    required: true,
                    firstColSize: 8,
                    showError: false,
                    isAsteriskNotRequired: true,
                    maxLength: 100
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field.formControl?.valueChanges.subscribe((value) => {
                        field.formControl?.markAsTouched();
                      });
                    },
                  },
                  validators: {
                    fieldCommentValidation: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        const comment = field.form.get('surroundingBuildingsWithDebrisPotentialComment')?.value;
                        const value = control.value;
                        if (value !== null && (value === 'true' || value === true)) {
                          if (comment !== undefined && comment !== null && comment?.length > 0) {
                            return true;
                          } else {
                            return false;
                          }
                        }
                        return true;
                      },
                      message: `Is there a potential for debris from building structures or equipment Comment is Required`,
                    },
                  },
                },
                {
                  key: 'lowerRoofWithGravel1InchOrSmallerComment',
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field.formControl?.valueChanges.subscribe((value) => field.form.get('isLowerRoofWithGravel1InchOrSmaller')?.updateValueAndValidity());
                    }
                  }
                },
                {
                  key: 'isLowerRoofWithGravel1InchOrSmaller',
                  type: 'rf-yes-no',
                  wrappers: [ 'question-wrapper' ],
                  defaultValue: null,
                  className: 'exposure-yes-no-height',
                  props: {
                    label: `Does building have a lower roof sections with 1 or smaller diameter gravel on the surface?`,
                    isSwipeGesture: true,
                    isCommentActionEnabled: true,
                    commentActionKey: 'lowerRoofWithGravel1InchOrSmallerComment',
                    rightAlign: true,
                    required: true,
                    firstColSize: 8,
                    showError: false,
                    isAsteriskNotRequired: true,
                    maxLength: 100
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field.formControl?.valueChanges.subscribe((value) => {
                        field.formControl?.markAsTouched();
                      });
                    },
                  },
                  validators: {
                    fieldCommentValidation: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        const comment = field.form.get('lowerRoofWithGravel1InchOrSmallerComment')?.value;
                        const value = control.value;
                        if (value !== null && (value === 'true' || value === true)) {
                          if (comment !== undefined && comment !== null && comment?.length > 0) {
                            return true;
                          } else {
                            return false;
                          }
                        }
                        return true;
                      },
                      message: 'Does building have a lower roof sections Comment is Required',
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
      expressions: {
        // Hide the whole section if WR is disabled
        hide: (field: FormlyFieldConfig) => {
          const disabledField = field?.options?.formState?.isMainSectionDisabled?.filter(val => val.sectionId === SectionEnum.FR_WIND);
          if (disabledField.length == 1) {
            return true;
          }
          else if (disabledField.length == 0) {
            return false;
          }
        }
      },
    });
  }
}
