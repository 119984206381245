import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
//import { WallsBgDataModel } from 'src/app/models/formly-builder/sections/full-risk/walls-bg.data.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { WallsBGIIDataModel } from 'src/app/models/formly-builder/formly/full-risk/walls-bg2-data.model';
import { ChargeableColumnDataModel } from 'src/app/models/formly-builder/formly/full-risk/walls-chargeable-column-data.model';
import { LightFrameConstructionDataModel } from 'src/app/models/formly-builder/formly/floors-roof-light-frame-construction-data.model';
@Injectable({
  providedIn: 'root',
})
export class floorsRoofLightFrameConstructionService extends SectionServiceAbstract {
  constructor(
    private http: HttpClient,
    
  ) {
    super();
  }
  async updateData(model: any, riskReportData: any) {
    // Check if data has changed
    const hasChanged: boolean = LightFrameConstructionDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      const newLightFrameInfo = LightFrameConstructionDataModel.to(model);
      return { ...riskReportData, ...newLightFrameInfo };
    } else {
      return undefined;
    }
  }

  loadData(unused: any[], reportInfo: any, isS3: boolean, model: any): any {
    // const lightFrameConstructionDataModel: LightFrameConstructionDataModel = LightFrameConstructionDataModel.fromS3(reportInfo);
    // return {
    //   ...model,
    //   ...{
    //     lightFrameConstruction: lightFrameConstructionDataModel
    //   }
    // };
    return undefined;

  }

}