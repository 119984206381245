import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { Amplify, Auth, Analytics } from 'aws-amplify';
import aws_exports from './aws-exports';
import 'hammerjs';

aws_exports.aws_appsync_graphqlEndpoint = environment.graphqlUrl
console.log('GraphQL Endpoint:', aws_exports.aws_appsync_graphqlEndpoint);
Amplify.configure(aws_exports);
Auth.configure(aws_exports);

Analytics.autoTrack('session', { enable: true });
Analytics.autoTrack('pageView', { enable: true });
Analytics.autoTrack('event', {
  enable: true,
  events: ['click'],
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));

defineCustomElements(window);
