import { Directive, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[max-length-limit]' // Attribute selector
})
export class MaxLengthLimitDirective {

  @Input('limit-to') maxLengthLimit;

  constructor() {
  }

  //HostListener decorator handle event handlers for input (onKeyPress)
  @HostListener('keypress', ['$event'])
  onkeypress(ev) {
    const limit = +this.maxLengthLimit; //convert to number
    // once it reaches the limit set, stop propgating event.
    if (ev.target.value.length === limit) {
      ev.preventDefault();
    }
  }
}