import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MapMarkerService } from 'src/app/services/mapboxgl-services/map-marker.service';


@Directive({
  selector: '[appTileColor]',
})
export class TileColorDirective implements OnChanges {
  @Input() styleType;
  @Input('survey') survey;
  constructor(private el: ElementRef, private mapMarkerService: MapMarkerService) {}

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.survey) {
      this.setColorStyle();
    }
  }

  setColorStyle() {
    const priority = this.survey.StrategicSourcingScore || this.survey.priority;
    const color = this.mapMarkerService.getColor(priority);
    this.el.nativeElement.style[this.styleType] = color;
  }
}
