import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class BuildingBGSectionModel extends FormlySectionModelAbstract {
  subscription: Subscription;
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'buildingBg',
      type: 'select',
      wrappers: [ 'question-wrapper' ],
      props: {
        label: 'Building BG II',
        required: true,
        options: formlyBuilderService.jsonOptionsService.getBuildingBGOptions(),
        className: 'width-margin',
      },
      expressions: {
        hide: (model: FormlyFieldConfig) => {
          if (model && model.parent && model.parent.model && model.parent.model.address &&
            model.parent.model.address.mainAddress && model.parent.model.address.mainAddress.state) {
            const state = model.parent.model.address.mainAddress.state;
            if (state === 'NY' || state === 'NX') {
              return false;
            }
          }
          return true;
        }
      },
      hooks: {
        // eslint-disable-next-line arrow-body-style
        afterViewInit: (field?: FormlyFieldConfig) => {
          return field.formControl.valueChanges.pipe(
            // debounceTime(5000),
            distinctUntilChanged(),
            tap(value => {
              if (!value && field.formControl.pristine) {
                field.formControl.markAsTouched();
              }
              if (!field.formControl.pristine) {
                if (field.formControl.valid) {
                  const formlyService = field.options.formState.service;
                  formlyService.riskReport.updateSectionData(SectionEnum.BUILDING_BG, false);
                }
              }
              // Notify that we changed the value...
              // 1. Formly Angular Control: field.formControl.setValue();
              // 2. Formly Model: field.options.formState.riskService.model = { ...field.options.formState.riskService.model, ...newValue };   // Submit
              // 3. Risk Report: formlyService.riskReport.updateSectionData(SectionEnum.BUILDING_BG, false);              // Save in memory Risk Report + save to local storage + sync to S3
              // 4. valid notification: Don't modify anything, but just notify possible validity changes even if value not changed in RR/Model
              field.options.formState.service.valid = field.formControl.valid;
            }),
          );
        }
      },
    });
  }
}
