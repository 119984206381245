import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
//import { WallsBgDataModel } from 'src/app/models/formly-builder/sections/full-risk/walls-bg.data.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { FormlyBuilderService } from '../formly-builder.service';
import { RiskReportService } from '../risk-report.service';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { WallsBGIIDataModel } from 'src/app/models/formly-builder/formly/full-risk/walls-bg2-data.model';
import { ChargeableColumnDataModel } from 'src/app/models/formly-builder/formly/full-risk/walls-chargeable-column-data.model';
import { HookReuseableService } from '../hook-reuseable.service';
@Injectable({
  providedIn: 'root',
})
export class WallsChargeableColumnService extends SectionServiceAbstract {
  constructor(
    private http: HttpClient,
    private formlyBuilderService: FormlyBuilderService, private riskReportService: RiskReportService,
    private hookReuseableService: HookReuseableService,
  ) {
    super();
  }
  async updateData(model: any, riskReportData: any) {
    // Check if data has changed
    const hasChanged: boolean = ChargeableColumnDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      const newChargeableColumnInfo = ChargeableColumnDataModel.to(model);
      return { ...riskReportData, ...newChargeableColumnInfo };
    } else {
      return undefined;
    }
  }

  loadData(unused: any[], reportInfo: any, isS3: boolean, model: any): any {
    const chargeableColumnDataModel: ChargeableColumnDataModel = ChargeableColumnDataModel.fromS3(reportInfo);
    // We can remove this condition once the fields got added in the backend DB
    if (this.formlyBuilderService.calculationService.isRiskReportLoaded !== false) {
      // 1) Lets respect HasWallsChargeableColumns value 
      // 2) if there is no value then for exsisting risk value should be calaulated based on requirement
      if (chargeableColumnDataModel.wallsChargeableColumn === null || chargeableColumnDataModel.wallsChargeableColumn === undefined) {
        if (this.formlyBuilderService.isExistingRisk()) {
          if (Number(chargeableColumnDataModel?.percentUnprotectedMetalColumnArea) > 0) {
            chargeableColumnDataModel.wallsChargeableColumn = true;
          } else {
            chargeableColumnDataModel.wallsChargeableColumn = false;
          }
        }        
      }      
      this.chargeableColumnAccess(model?.walls?.wallsArray);
      return {
        ...model,
        ...{
          areChargeableColumn: chargeableColumnDataModel
        }
      };
    }
  }
  
  chargeableColumnAccess(model) {
    const masonryFound = model?.some(wall => {
      wall.constructionType === "Masonry"
    });
    return masonryFound;
  }


  public thicknessValues(field) {
    const chargeableControl = field?.form?.root['controls']?.walls.get('chargeableColumn');
    field.props.options = chargeableControl?.valueChanges.pipe(startWith(''), map((val) => {
      const currentThicknessValue = field?.form?.value?.columnWallThickness;
      const wallsArray = field?.form?.root?.getRawValue()?.walls?.wallsArray;
      const hasMatchingThickness = wallsArray?.some(item => item.constructionType === 'Masonry' && item.thickness === currentThicknessValue);
      field.form.root['controls']?.areChargeableColumn?.get('columnWallThickness')?.updateValueAndValidity();

      const entries = wallsArray?.filter(item => item.constructionType === 'Masonry' && item.thickness)
        .map(item => ({ value: item.thickness, label: item.thickness + ' inches' }));
      if (!hasMatchingThickness && currentThicknessValue) {
        entries.push({ value: currentThicknessValue, label: currentThicknessValue + ' inches' })
      }
      const options = Array.from(new Set(entries?.map(obj => JSON.stringify(obj) ))).map(str => JSON.parse(str as string));
      return options;
    }));
  }

}