import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';

/**
 * Class that represents the Formly Model for Occupants
 */
export class ConstructionVerificationDataModel {
  static sectionName = 'constructionVerification';

  constructionVerification: boolean;

  // TODO: Remove Amplify once unnecessary
  static from(reports: any): ConstructionVerificationDataModel {
    return {
      constructionVerification: false,
    };
  }

  // TODO: Rename to read
  static fromS3(reports: any): ConstructionVerificationDataModel {
    return {
      constructionVerification:  reports.ConstructionVerification ?
      reports.ConstructionVerification.toString() : reports.ConstructionVerification,
    };
  }

  /**
   * 
   */
  static to(model: any) {
    return { ConstructionVerification: model.constructionVerification === 'true' ? true : false };
  }

  /**
   * Check if the model has changed
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   * @returns 
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    if (riskReportModel.ConstructionVerification === undefined) {
      return true;
    }

    if (formlyModel.constructionVerification === riskReportModel.ConstructionVerification) {
      return false;
    } else {
      return true;
    }
  }
}
