import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class WindRoofEnvelopeSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyService: FormlyBuilderService) {
    super({
      type: 'rf-sheet-section',
      props: {
        label: 'Roof Envelope',
        subTitle: 'Roof Envelope',
        title: 'Wind Report',
        actionButtons: [
          {
            section: 'windReport',
            action: 'showFormlySheet',
            label: 'Add',
            isDisabled: false,
            service: 'formlyWrapperService',
          },
        ],
        sheetButtons: [
          {
            section: 'windReport',
            action: 'onClose',
            label: 'Close',
            service: 'windRoofEnvelopeService',
            className: 'close-btn medium-btn',
            passInitialModel: true,
          },
          {
            section: 'windReport',
            action: 'onApply',
            label: 'Apply',
            hasConfirmationPopup: true,
            service: 'windRoofEnvelopeService',
            className: 'apply-btn large-btn',
          },
        ],
      },
      key: 'roofEnvelope',
      fieldGroup: [
        {
          wrappers: ['simple-section-wrapper'],
          fieldGroupClassName: 'wr-roof-envelop-section-container',
          key: 'roofEnvelopeContainer',
          props: {
            label: 'What type of roof covering(s) are on this building?',
            isCollapsable: true,
            showError: true,
          },
          fieldGroup: [
            {
              className: 'roof-envelope-predominant',
              type: 'rf-predominant-block',
              props: {
                // No label: margins are adapted to show inside a section wrapper
                options: [],
                path: 'windReport.roofEnvelope.roofEnvelopeContainer',               
                errorKey: 'hasRoofCoveringOnBuildingTypeNone',
                errorMessage: 'At least one Roof Covering Type must be selected',
                predominatErrorMessage: 'One Roof Covering Type must be marked as predominant',
              },
              hooks: {
                onInit: (field) => {
                  field.options.formState.service.windRoofEnvelopeService.markAsTouched(field);
                  field.options?.formState?.service?.windRoofEnvelopeService?.initCheckboxes(field, [
                  { label: 'None', checkboxKey: 'hasRoofCoveringOnBuildingTypeNone', predominantKey: 'isRoofCoveringOnBuildingTypeNonePredominant' },
                  { label: 'Built - up with Secured Gravel', checkboxKey: 'hasRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravel', predominantKey: 'isRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravelPredominant' },
                  { label: 'Built - up with Unsecured Gravel', checkboxKey: 'hasRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravel', predominantKey: 'isRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravelPredominant' },
                  { label: 'Built - up without Gravel', checkboxKey: 'hasRoofCoveringOnBuildingTypeBuiltUpWithoutGravel', predominantKey: 'isRoofCoveringOnBuildingTypeBuiltUpWithoutGravelPredominant' },
                  { label: 'Sprayed Polyurethane Foam (SPF) roof', checkboxKey: 'hasRoofCoveringOnBuildingTypeSprayedPolyurethaneFoam', predominantKey: 'isRoofCoveringOnBuildingTypeSprayedPolyurethaneFoamPredominant' },
                  { label: 'Single Ply Membrane', checkboxKey: 'hasRoofCoveringOnBuildingTypeSinglePlyMembrane', predominantKey: 'isRoofCoveringOnBuildingTypeSinglePlyMembranePredominant' },
                  { label: 'Wood Shingles', checkboxKey: 'hasRoofCoveringOnBuildingTypeWoodShingles', predominantKey: 'isRoofCoveringOnBuildingTypeWoodShinglesPredominant' },
                  { label: 'Light Metal Panels', checkboxKey: 'hasRoofCoveringOnBuildingTypeLightMetalPanels', predominantKey: 'isRoofCoveringOnBuildingTypeLightMetalPanelsPredominant' },
                  { label: 'Standing Seam Metal', checkboxKey: 'hasRoofCoveringOnBuildingTypeStandingSeamMetal', predominantKey: 'isRoofCoveringOnBuildingTypeStandingSeamMetalPredominant' },
                  { label: 'Masonry Tile', checkboxKey: 'hasRoofCoveringOnBuildingTypeMasonaryTile', predominantKey: 'isRoofCoveringOnBuildingTypeMasonaryTilePredominant' },
                  { label: 'Slate', checkboxKey: 'hasRoofCoveringOnBuildingTypeSlate', predominantKey: 'isRoofCoveringOnBuildingTypeSlatePredominant' },
                  { label: 'Asphalt or Fiberglass Shingles', checkboxKey: 'hasRoofCoveringOnBuildingTypeAsphaltOrFiberglassShingles', predominantKey: 'isRoofCoveringOnBuildingTypeAsphaltOrFiberglassShinglesPredominant' },
                  { label: 'Fabric over Structural Frame', checkboxKey: 'hasRoofCoveringOnBuildingTypeFabricOverStructuralFrame', predominantKey: 'isRoofCoveringOnBuildingTypeFabricOverStructuralFramePredominant' },
                  { label: 'Unknown', checkboxKey: 'hasRoofCoveringOnBuildingTypeUnknown', predominantKey: 'isRoofCoveringOnBuildingTypeUnknownPredominant' },
                ]);
              }
              },
              fieldGroup: [
                { key: 'hasRoofCoveringOnBuildingTypeNone' },
                { key: 'isRoofCoveringOnBuildingTypeNonePredominant' },
                { key: 'hasRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravel' },
                { key: 'isRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravelPredominant' },
                { key: 'hasRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravel' },
                { key: 'isRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravelPredominant' },
                { key: 'hasRoofCoveringOnBuildingTypeBuiltUpWithoutGravel' },
                { key: 'isRoofCoveringOnBuildingTypeBuiltUpWithoutGravelPredominant' },
                { key: 'hasRoofCoveringOnBuildingTypeSprayedPolyurethaneFoam' },
                { key: 'isRoofCoveringOnBuildingTypeSprayedPolyurethaneFoamPredominant' },
                { key: 'hasRoofCoveringOnBuildingTypeSinglePlyMembrane' },
                { key: 'isRoofCoveringOnBuildingTypeSinglePlyMembranePredominant' },
                { key: 'hasRoofCoveringOnBuildingTypeWoodShingles' },
                { key: 'isRoofCoveringOnBuildingTypeWoodShinglesPredominant' },
                { key: 'hasRoofCoveringOnBuildingTypeLightMetalPanels' },
                { key: 'isRoofCoveringOnBuildingTypeLightMetalPanelsPredominant' },
                { key: 'hasRoofCoveringOnBuildingTypeStandingSeamMetal' },
                { key: 'isRoofCoveringOnBuildingTypeStandingSeamMetalPredominant' },
                { key: 'hasRoofCoveringOnBuildingTypeMasonaryTile' },
                { key: 'isRoofCoveringOnBuildingTypeMasonaryTilePredominant' },
                { key: 'hasRoofCoveringOnBuildingTypeSlate' },
                { key: 'isRoofCoveringOnBuildingTypeSlatePredominant' },
                { key: 'hasRoofCoveringOnBuildingTypeAsphaltOrFiberglassShingles' },
                { key: 'isRoofCoveringOnBuildingTypeAsphaltOrFiberglassShinglesPredominant' },
                { key: 'hasRoofCoveringOnBuildingTypeFabricOverStructuralFrame' },
                { key: 'isRoofCoveringOnBuildingTypeFabricOverStructuralFramePredominant' },
                { key: 'hasRoofCoveringOnBuildingTypeUnknown' },
                { key: 'isRoofCoveringOnBuildingTypeUnknownPredominant' },
              ],
              validators: {
                validation: [{
                  name: 'at-least-one-boolean',
                  options: {
                    list: [
                      'hasRoofCoveringOnBuildingTypeNone',
                      'hasRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravel',
                      'hasRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravel',
                      'hasRoofCoveringOnBuildingTypeBuiltUpWithoutGravel',
                      'hasRoofCoveringOnBuildingTypeSprayedPolyurethaneFoam',
                      'hasRoofCoveringOnBuildingTypeSinglePlyMembrane',
                      'hasRoofCoveringOnBuildingTypeWoodShingles',
                      'hasRoofCoveringOnBuildingTypeLightMetalPanels',
                      'hasRoofCoveringOnBuildingTypeStandingSeamMetal',
                      'hasRoofCoveringOnBuildingTypeMasonaryTile',
                      'hasRoofCoveringOnBuildingTypeSlate',
                      'hasRoofCoveringOnBuildingTypeAsphaltOrFiberglassShingles',
                      'hasRoofCoveringOnBuildingTypeFabricOverStructuralFrame',
                      'hasRoofCoveringOnBuildingTypeUnknown',
                    ],
                    errorName: 'atLeastOneCheckedValidator',
                    message: 'At least one Roof to Wall Connection Type must be selected',
                  }
                },
                {
                  name: 'at-least-one-boolean',
                  options: {
                    list: [
                      'isRoofCoveringOnBuildingTypeNonePredominant',
                      'isRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravelPredominant',
                      'isRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravelPredominant',
                      'isRoofCoveringOnBuildingTypeBuiltUpWithoutGravelPredominant',
                      'isRoofCoveringOnBuildingTypeSprayedPolyurethaneFoamPredominant',
                      'isRoofCoveringOnBuildingTypeSinglePlyMembranePredominant',
                      'isRoofCoveringOnBuildingTypeWoodShinglesPredominant',
                      'isRoofCoveringOnBuildingTypeLightMetalPanelsPredominant',
                      'isRoofCoveringOnBuildingTypeStandingSeamMetalPredominant',
                      'isRoofCoveringOnBuildingTypeMasonaryTilePredominant',
                      'isRoofCoveringOnBuildingTypeSlatePredominant',
                      'isRoofCoveringOnBuildingTypeAsphaltOrFiberglassShinglesPredominant',
                      'isRoofCoveringOnBuildingTypeFabricOverStructuralFramePredominant',
                      'isRoofCoveringOnBuildingTypeUnknownPredominant'
                    ],
                    errorName: 'atLeastOnePredominantValidator',
                    message: 'One Roof to Wall Connection Type must be marked as predominant',
                  }
                }
                ],
              },
            }
          ],
        },
        {
          wrappers: ['simple-section-wrapper'],
          fieldGroupClassName: 'wr-roof-envelop-section-container',
          key: 'roofEnvelopeGeometry',
          props: {
            label: 'What is the roof geometry?',           
            isCollapsable: true,
            showError: true,   
          },
          fieldGroup: [
            {
              className:'roof-envelope-predominant',
              type: 'rf-predominant-block',
              props: {
                options: [],
                path: 'windReport.roofEnvelope.roofEnvelopeGeometry',
                errorKey: 'hasRoofGeometryTypeHip',
                errorMessage: 'At least one Roof Geometry Type is required.',
                predominatErrorMessage : 'One Roof Geometry Type must be marked as predominant.'
              },
              hooks: {
                onInit: (field) => { 
                  field.options.formState.service.windRoofEnvelopeService.markAsTouched(field);
                  field.options?.formState?.service?.windRoofEnvelopeService?.initCheckboxes(field, [
                  { label: 'Hip', checkboxKey: 'hasRoofGeometryTypeHip', predominantKey: 'isRoofGeometryTypeHipPredominant' },
                  { label: 'Shed', checkboxKey: 'hasRoofGeometryTypeShed', predominantKey: 'isRoofGeometryTypeShedPredominant' },
                  { label: 'Mansard', checkboxKey: 'hasRoofGeometryTypeMansard', predominantKey: 'isRoofGeometryTypeMansardPredominant' },
                  { label: 'Pyramid', checkboxKey: 'hasRoofGeometryTypePyramid', predominantKey: 'isRoofGeometryTypePyramidPredominant' },
                  { label: 'Gambrel', checkboxKey: 'hasRoofGeometryTypeGambrel', predominantKey: 'isRoofGeometryTypeGambrelPredominant' },
                  { label: 'Braced Gable', checkboxKey: 'hasRoofGeometryTypeBracedGable', predominantKey: 'isRoofGeometryTypeBracedGablePredominant' },
                  { label: 'Flat or Low Slope', checkboxKey: 'hasRoofGeometryTypeFlatOrLowSlope', predominantKey: 'isRoofGeometryTypeFlatOrLowSlopePredominant' },
                  { label: 'Gable', checkboxKey: 'hasRoofGeometryTypeGable', predominantKey: 'isRoofGeometryTypeGablePredominant' },
                  { label: 'Complex', checkboxKey: 'hasRoofGeometryTypeComplex', predominantKey: 'isRoofGeometryTypeComplexPredominant' },
                  { label: 'Stepped or Sawtooth', checkboxKey: 'hasRoofGeometryTypeSteppedOrSawtooth', predominantKey: 'isRoofGeometryTypeSteppedOrSawtoothPredominant' },
                ]);
              }
              },
              fieldGroup: [
                { key: 'hasRoofGeometryTypeHip' },
                { key: 'isRoofGeometryTypeHipPredominant' },
                { key: 'hasRoofGeometryTypeShed' },
                { key: 'isRoofGeometryTypeShedPredominant' },
                { key: 'hasRoofGeometryTypeMansard' },
                { key: 'isRoofGeometryTypeMansardPredominant' },
                { key: 'hasRoofGeometryTypePyramid' },
                { key: 'isRoofGeometryTypePyramidPredominant' },
                { key: 'hasRoofGeometryTypeGambrel' },
                { key: 'isRoofGeometryTypeGambrelPredominant' },
                { key: 'hasRoofGeometryTypeBracedGable' },
                { key: 'isRoofGeometryTypeBracedGablePredominant' },
                { key: 'hasRoofGeometryTypeFlatOrLowSlope' },
                { key: 'isRoofGeometryTypeFlatOrLowSlopePredominant' },
                { key: 'hasRoofGeometryTypeGable' },
                { key: 'isRoofGeometryTypeGablePredominant' },
                { key: 'hasRoofGeometryTypeComplex'}, 
                { key: 'isRoofGeometryTypeComplexPredominant' },
                { key: 'hasRoofGeometryTypeSteppedOrSawtooth' },
                { key: 'isRoofGeometryTypeSteppedOrSawtoothPredominant' }
              ],
              validators: {
                validation: [{
                  name: 'at-least-one-boolean',
                  options: {
                    list: [
                      'hasRoofGeometryTypeHip' ,
                      'hasRoofGeometryTypeShed' ,
                      'hasRoofGeometryTypeMansard',
                      'hasRoofGeometryTypePyramid' ,
                      'hasRoofGeometryTypeGambrel' ,
                      'hasRoofGeometryTypeBracedGable' ,
                      'hasRoofGeometryTypeFlatOrLowSlope',
                      'hasRoofGeometryTypeGable',
                      'hasRoofGeometryTypeComplex',
                      'hasRoofGeometryTypeSteppedOrSawtooth'
                    ],
                    errorName: 'atLeastOneCheckedValidator',
                    message: 'At least one Roof to Wall Connection Type must be selected',
                  }
                },
                {
                  name: 'at-least-one-boolean',
                  options: {
                    list: [
                      'isRoofGeometryTypeHipPredominant' ,
                      'isRoofGeometryTypeShedPredominant' ,
                      'isRoofGeometryTypeMansardPredominant' ,
                      'isRoofGeometryTypePyramidPredominant' ,
                      'isRoofGeometryTypeGambrelPredominant' ,
                      'isRoofGeometryTypeBracedGablePredominant' ,
                      'isRoofGeometryTypeFlatOrLowSlopePredominant' ,
                      'isRoofGeometryTypeGablePredominant' ,
                      'isRoofGeometryTypeComplexPredominant' ,
                      'isRoofGeometryTypeSteppedOrSawtoothPredominant' 
                    ],
                    errorName: 'atLeastOnePredominantValidator',
                    message: 'One Roof to Wall Connection Type must be marked as predominant',
                  }
                }
                ],
              }              
            }
          ],
        },
        {
          wrappers: ['simple-section-wrapper'],
          fieldGroupClassName: 'wr-roof-envelop-feature-section-container',
          key: 'roofFeatures',
          props: {
            label: 'Roof Features',
            isCollapsable: true,           
            showError: true,
            className:'padding-bottom-20',
            checkTouchedControl: true,
          },
          hooks:{
            onInit: (field: FormlyFieldConfig) => {
              field.options.formState.service.windRoofEnvelopeService?.markAsTouched(field);
            }
          },
          fieldGroup: [
            {
              key: 'impactResistanceDocumentationUl2218OrFm4473Comment',
              hooks:{
                onInit: (field: FormlyFieldConfig) => {
                  field.formControl?.valueChanges.subscribe((value) => field.form.get('impactResistanceDocumnetationUL22180OrFm4473')?.updateValueAndValidity());
                }
              }
            },
            {
              key: 'impactResistanceDocumnetationUL22180OrFm4473',
              type: 'simple-select',
              className: 'select-dropdown',
              wrappers:['question-wrapper'],
              props: {
                label: 'Documentation for Impact resistance UL2218 or FM2273',
                required: true,
                isLabelNotRequired: false,
                isSectionLabelNormal: true,
                isSwipeGesture: true,
                isCommentActionEnabled: true,
                commentActionKey:'impactResistanceDocumentationUl2218OrFm4473Comment',
                firstColSize: 7,
                showError: false,
                isAsteriskNotRequired: true,
                isLabelDisabled: true,
                maxLength: 100,
                options: [                  
                  { value: 'None or unknown', label: 'None or Unknown' },
                  { value: 'Not creditable', label: 'Not Creditable' },
                  { value: 'Class 4', label: 'Class 4' },
                  { value: 'Class 3', label: 'Class 3' },
                  { value: 'Class 2', label: 'Class 2' },
                  { value: 'Class 1', label: 'Class 1' }
                ],
              },
              hooks:{
                onInit: (field : FormlyFieldConfig) => {
                  if(field.formControl?.value === 'Not creditable' ){
                    field.formControl?.markAsTouched(); 
                  }
                }
              },              
              validators: {
                fieldCommentValidation: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const comment = field?.form?.get('impactResistanceDocumentationUl2218OrFm4473Comment')?.value;
                    const value = control.value;
                    if (value !== null && value === 'Not creditable') {
                      if (comment !== undefined && comment !== null && comment?.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return true;
                  },
                  message: 'Documentation for Impact resistance UL2218 or FM2273 Comment is Required',
                },
              },
            },
            {
              key: 'roofPitch',
              type: 'simple-select',
              className: 'select-full-width',
              wrappers:['question-wrapper'],
              props: {
                label: 'Roof Pitch',
                required: true,
                isLabelNotRequired: true,
                isSectionLabelNormal: true,                
                firstColSize: 6,
                showError: false,
                isAsteriskNotRequired: true,
                isLabelDisabled: true,
                options: [                  
                  { value: 'Flat', label: 'Flat' },
                  { value: 'Low Slope', label: 'Low Slope' },
                  { value: 'Medium Slope', label: 'Medium Slope' },
                  { value: 'Steep Slope', label: 'Steep Slope' },
                  { value: 'UKWN', label: 'Unknown' }
                ],
              },              
            },
            {
              key: 'roofPerimeterHigherThan6InchesParapet',
              type: 'simple-select',
              className: 'select-full-width',
              wrappers:['question-wrapper'],
              props: {
                label: 'Percentage of roof with at least 6” high parapet',
                required: true,
                isLabelNotRequired: true,
                isSectionLabelNormal: true,               
                firstColSize: 7,
                showError: false,
                isAsteriskNotRequired: true,
                isLabelDisabled: true,
                options: [                  
                  { value: 'NDSC', label: 'Not Discernible' },
                  { value: 'No parapet', label: 'No Parapet' },
                  { value: '<= 25%', label: '<= 25%' },
                  { value: '>25% - 50%', label: '> 25% to 50%' },
                  { value: '>50% - 75%', label: '> 50% to 75%' },
                  { value: '>75%', label: '> 75%' },
                ],
              },              
            },
            {
              key: 'averageParapetHeight',
              type: 'simple-select',
              className: 'select-full-width',
              wrappers:['question-wrapper'],
              props: {
                label: 'Average height of parapets',
                required: true,
                isLabelNotRequired: true,
                isSectionLabelNormal: true,               
                firstColSize: 6,
                showError: false,
                isAsteriskNotRequired: true,
                isLabelDisabled: true,
                options: [                  
                  { value: "NDSC", label: 'Not Discernible' },
                  { value: "NAPP", label: 'Not Applicable' },
                  { value: "LT12", label: '< 12"' },
                  { value: "LT36", label: '> 12" - 36"' },
                  { value: "GT36", label: '> 36"' },
                ],
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  field.options.formState.service.windRoofEnvelopeService?.setAverageHeightOfParapet(field);
                }
              },
            },
            {
              key: 'ageOfRoofCover',
              type: 'simple-select',
              className: 'select-full-width',
              wrappers: ['question-wrapper'],
              props: {
                label: 'Age of roof cover',
                required: true,
                isLabelNotRequired: true,
                isSectionLabelNormal: true,               
                firstColSize: 6,
                showError: false,
                isAsteriskNotRequired: true,
                isLabelDisabled: true,
                options: [                  
                  { value: 'New - 10 years', label: 'New - 10 years' },
                  { value: '>10 - 20 years', label: '>10 - 20 years' },
                  { value: '>20 years', label: 'Over 20 years' },
                  { value: 'UKWN', label: 'Unknown' },                 
                ],
              },
              hooks:{
                onInit: (field: FormlyFieldConfig) => {
                  field.options.formState.service.windRoofEnvelopeService.setAgeOfRoof(field);
                }
              },
            },
            {
              key: 'hasSkyLightsPresentOnRoof',
              type: 'rf-yes-no',
              wrappers: ['question-wrapper'],
              defaultValue: null,
              className: 'skylights-yes-no-height',
              props: {
                label: `Are skylights present in the roof?`,
                rightAlign: true,
                required: true,
                firstColSize: 8,
                showError: false,
                isAsteriskNotRequired: true,
                isLabelDisabled: true,
                isSectionLabelNormal: true, 
              },                 
              hooks:{
                onInit: (field: FormlyFieldConfig) => {
                  field.options.formState.service.windRoofEnvelopeService.setSkyLightsPresentOnRoof(field);
                }
              },              
            },
            {
              key: 'skyLightsRoofRatingType',
              type: 'simple-select',
              className: 'select-full-width',
              wrappers: ['question-wrapper'],
              props: {
                label: 'What wind uplift are the skylights rated for?',
                required: true,
                isLabelNotRequired: true,
                isSectionLabelNormal: true,
                firstColSize: 7,
                showError: false,
                isSwipeGesture: true,
                isCommentActionEnabled: true,
                isAsteriskNotRequired: true,
                isLabelDisabled: true,
                commentActionKey: 'skyLightsRoofRatingTypeComment',
                maxLength: 100,
                options: [
                  { value: 'NAPP', label: 'Does not apply' },
                  { value: 'None or unknown', label: 'None or Unknown' },
                  { value: 'Not creditable', label: 'Not Creditable' },
                  { value: 'UL 15', label: 'UL 15' },
                  { value: 'UL 30', label: 'UL 30' },
                  { value: 'UL 60', label: 'UL 60' },
                  { value: 'UL 90', label: 'UL 90' },
                ],
              },
              hooks: {
                onInit:(field: FormlyFieldConfig)=>{
                  field.options.formState.service.windRoofEnvelopeService.setSkyLightRoofRating(field);
                }
              },
              validators: {
                windUpliftValidation: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const comment = field?.form?.get('skyLightsRoofRatingTypeComment')?.value;
                    const value = control.value;

                    if ((value === 'Not creditable') && !(comment && comment?.length >= 0)) {
                      return false;
                    }
                    return true;
                  },
                  message: 'What wind uplift are the skylights rated for comment is required',
                },
              }
            },
            {
              key: 'skyLightsRoofRatingTypeComment',
              hooks: {
                onInit: (field: FormlyFieldConfig) => field.formControl?.valueChanges
                  .subscribe(src => field.form.get('skyLightsRoofRatingType')?.updateValueAndValidity())
              }
            }
          ],
        },
        {
          wrappers: ['simple-section-wrapper'],
          fieldGroupClassName: 'wr-additional-roof-container qw-label-normal',
          key: 'roofAdditionalFeature',
          props: {
            label: 'Additional Roof Features',
            isCollapsable: true,
            className: 'padding-bottom-20',
            checkTouchedControl: true,
          },
          fieldGroup: [
            {
              key: 'windUpliftRoofRatingUl580',
              type: 'simple-select',
              wrappers: ['question-wrapper'],
              className: 'select-full-width',
              props: {
                label: 'UL580 Wind uplift rating for the roof',
                isAsteriskNotRequired: true,
                required: true,
                isSectionLabelNormal: true,
                isSwipeGesture: true,
                isCommentActionEnabled: true,
                firstColSize: 7,
                showError: false,
                isLabelDisabled: true,
                commentActionKey: 'windUpliftRoofRatingUl580Comment',
                maxLength: 100,
                options: [
                  { value: 'None or unknown', label: 'None or Unknown' },
                  { value: 'UL 90 but not creditable for BGII', label: 'UL 90 but not creditable for BGII' },
                  { value: 'UL 90', label: 'UL 90' },
                  { value: 'UL 90 accounted for in BGII', label: 'UL 90 accounted for in BGII' },
                  { value: 'UL 60', label: 'UL 60' },
                  { value: 'UL 30', label: 'UL 30' },
                  { value: 'UL 15', label: 'UL 15' },
                ],
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                if (field?.form?.root?.value?.floorsAndRoofs?.bg2Data?.isWindUplift90) {
                    field?.formControl?.setValue('UL 90 accounted for in BGII');
                  }
                },
              },
              validators: {
                windUpliftValidation: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const comment = field?.form?.get('windUpliftRoofRatingUl580Comment')?.value;
                    const value = control.value;
                    if (
                      (value !== null && value === 'None or unknown') ||
                      value == 'UL 90 but not creditable for BGII'
                    ) {
                      if (comment !== null && comment && comment.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return true;
                  },
                  message: 'UL580 Wind uplift rating for the roof comment is required',
                },
              },
            },
            {
              key: 'windUpliftRoofRatingUl580Comment',
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  field.formControl?.valueChanges.subscribe((value) =>
                    field?.form?.get('windUpliftRoofRatingUl580')?.updateValueAndValidity()
                  );
                },
              },
            },
            {
              wrappers: ['question-wrapper'],
              key: 'preventativeMaintenanceProgram',
              type: 'simple-select',
              className: 'select-full-width',
              props: {
                label: 'Preventative maintenance and/or roof inspection program',
                required: true,
                isSectionLabelNormal: true,
                isAsteriskNotRequired: true,
                firstColSize: 7,
                isLabelDisabled: true,
                showError: false,
                isCardEnabled: true,
                options: [
                  { value: 'None known', label: 'None Known' },
                  { value: 'Yes - frequency 1 yr or less', label: 'Yes, frequency 1 year or less' },
                  { value: 'Yes - frequency >1yr - 2yrs', label: 'Yes, frequency > 1 year to 2 years' },
                  { value: 'Yes - frequency >2yrs', label: 'Yes, frequency > 2 years' },
                ],
              },
            },
            {
              key: 'solarPanels',
              type: 'simple-select',
              wrappers: ['question-wrapper'],
              className: 'select-full-width',
              props: {
                label: 'Are there any solar panels on the roof?',
                required: true,
                isSectionLabelNormal: true,
                isSwipeGesture: true,
                isCommentActionEnabled: true,
                isAsteriskNotRequired: true,
                firstColSize: 7,
                showError: false,
                isLabelDisabled: true,
                commentActionKey: 'solarPanelComment',
                maxLength: 100,
                options: [
                  { value: 'No', label: 'No', disabled: false },
                  { value: 'Yes', label: 'Yes', disabled: false },
                  { value: 'NDSC', label: 'Not Discernible', disabled: false },
                ],
              },
              hooks: {
                onInit: (field) => formlyService.windRoofEnvelopeService.setSolarpanelValue(field),
              },
            },
            {
              key: 'solarPanelComment',
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  field?.formControl?.valueChanges?.subscribe((value) =>
                    field?.form?.get('solarPanels')?.updateValueAndValidity()
                  );
                },
              },
            },
            {
              wrappers: ['question-wrapper'],
              key: 'roofPerimiterCoveredBySolarPanels',
              type: 'simple-select',
              className: 'select-full-width',
              props: {
                label: 'Percentage of the roof area covered by panels',
                required: true,
                isSectionLabelNormal: true,
                isAsteriskNotRequired: true,
                firstColSize: 7,
                showError: false,
                isCardEnabled: true,
                isLabelDisabled: true,
                options: [
                  { value: 'Does not apply', label: 'Does not apply' },
                  { value: '<= 25%', label: '< 25%' },
                  { value: '>25% - 50%', label: '25% to 50%' },
                  { value: '>50% - 75%', label: '> 50% to 75%' },
                  { value: '>75%', label: '> 75%' },
                ],
              },
              hooks: {
                onInit: (field) => formlyService.windRoofEnvelopeService.setRoofPerimiterCoveredBySolarPanels(field),
              },
            },
            {
              key: 'flashingCondition',
              type: 'simple-select',
              wrappers: ['question-wrapper'],
              className: 'select-full-width',
              props: {
                label: 'Condition of the flashing',
                required: true,
                isSectionLabelNormal: true,
                isAsteriskNotRequired: true,
                isSwipeGesture: true,
                isCommentActionEnabled: true,
                firstColSize: 7,
                showError: false,
                isLabelDisabled: true,
                commentActionKey: 'flashingConditionComment',
                maxLength: 100,
                options: [
                  { value: 'Good', label: 'Good' },
                  { value: 'Fair', label: 'Fair' },
                  { value: 'Poor', label: 'Poor' },
                  { value: 'NDSC', label: 'Not Discernible' },
                ],
              },
              validators: {
                flashingConditionValidation: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const comment = field?.form?.get('flashingConditionComment')?.value;
                    const value = control.value;
                    if ((value !== null && value === 'Fair') || value == 'Poor') {
                      if (comment !== null && comment && comment.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return true;
                  },
                  message: 'Condition of the flashing Comment is Required',
                },
              },
            },
            {
              key: 'flashingConditionComment',
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  field.formControl?.valueChanges.subscribe((value) =>
                    field?.form?.get('flashingCondition')?.updateValueAndValidity()
                  );
                },
              },
            },
            {
              wrappers: ['question-wrapper'],
              className: 'radio-group-wrapper',
              fieldGroupClassName: 'formly-flex-container full-width',
              props: {
                label: 'Is there a secondary water barrier',
                isAsteriskNotRequired: true,
                firstColSize: 6,
                hasShadow: true,
                showError: false,
                isSectionLabelNormal: true,
              },
              fieldGroup: [
                {
                  key: 'hasSecondaryWaterBarrier',
                  type: 'rf-radio',
                  className: 'radio-group',
                  props: {
                    isHorizontalOrientation: true,
                    slot: 'start',
                    rightAlign: true,
                    label: 'Is there a secondary water barrier',
                    ripplerColor: 'grey',
                    required: true,
                    options: [
                      { label: 'None / Unknown', value: false },
                      { label: 'Yes', value: true },
                    ],
                  },
                },
              ],
            },
            {
              key: 'mechanicalEquipmentOnRoof',
              type: 'simple-select',
              wrappers: ['question-wrapper'],
              className: 'select-full-width',
              props: {
                label: 'Is there any mechanical equipment on the roof?',
                required: true,
                isSectionLabelNormal: true,
                isSwipeGesture: true,
                isCommentActionEnabled: true,
                firstColSize: 7,
                isAsteriskNotRequired: true,
                showError: false,
                isLabelDisabled: true,
                commentActionKey: 'mechanicalEquipmentOnRoofComment',
                maxLength: 100,
                options: [
                  { value: 'None', label: 'None' },
                  { value: 'Yes - Secured', label: 'Yes, Secured' },
                  { value: 'Yes - Unsecured/Undetermined', label: 'Yes, Unsecured' },
                  { value: 'NDSC', label: 'Not Discernible' },                  
                ],
              },
              validators: {
                mechanicalConditionValidation: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const comment = field?.form?.get('mechanicalEquipmentOnRoofComment')?.value;
                    const value = control.value;
                    if (value !== null && value === 'Yes - Unsecured/Undetermined') {
                      if (comment !== null && comment && comment.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return true;
                  },
                  message: 'Mechanical equipment comments required',
                },
              },
            },
            {
              key: 'mechanicalEquipmentOnRoofComment',
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  field.formControl?.valueChanges.subscribe((value) =>
                    field?.form?.get('mechanicalEquipmentOnRoof')?.updateValueAndValidity()
                  );
                },
              },
            },
            {
              key: 'hailProtectedMountedRoofEquipment',
              type: 'simple-select',
              wrappers: ['question-wrapper'],
              className: 'select-full-width',
              props: {
                label: 'Does all roof mounted equipment have hail protection covers?',
                required: true,
                isSectionLabelNormal: true,
                firstColSize: 7,
                showError: false,
                isAsteriskNotRequired: true,
                isCardEnabled: true,
                isLabelDisabled: true,
                options: [
                  { value: 'NDSC', label: 'Not discernible' },
                  { value: 'NRME', label: 'No roof mounted equipment on building' },
                  { value: 'SRME', label: 'Only some roof mounted equipment has hail covers' },
                  { value: 'ARME', label: 'All roof mounted equipment has hail covers' },
                ],
              },
              hooks: {
                onInit: (field) => formlyService.windRoofEnvelopeService.setHailProtectedMountedRoofEquipment(field),
              },
            },
            {
              key: 'lightlyConstructedRoofStructures',
              type: 'simple-select',
              className: 'select-full-width',
              wrappers: ['question-wrapper'],
              props: {
                label: 'Are there any lightly constructed penthouse or roof structures?',
                required: true,
                isSectionLabelNormal: true,
                firstColSize: 7,
                showError: false,
                isAsteriskNotRequired: true,
                isCardEnabled: true,
                isLabelDisabled: true,
                options: [
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                  { value: 'NDSC', label: 'Not Discernible' },
                ],
              },
            },
            {
              key: 'roofCoverDegradation',
              type: 'simple-select',
              wrappers: ['question-wrapper'],
              className: 'select-full-width',
              props: {
                label: 'Is there any evidence of roof degradation or interior water damage on ceiling below roof?',
                required: true,
                isSectionLabelNormal: true,
                isSwipeGesture: true,
                isCommentActionEnabled: true,
                isAsteriskNotRequired: true,
                firstColSize: 7,
                showError: false,
                isLabelDisabled: true,
                commentActionKey: 'roofCoverDegradationComment',
                maxLength: 100,
                options: [
                  { value: 'NDSC', label: 'Not Discernible (none noted)' },
                  { value: 'Slight', label: 'Slight' },
                  { value: 'Moderate', label: 'Moderate' },
                  { value: 'Severe', label: 'Severe' },
                  { value: 'Unobserved', label: 'Unobserved (no roof access)' },
                ],
              },
              validators: {
                flashingConditionValidation: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const comment = field?.form?.get('roofCoverDegradationComment')?.value;
                    const value = control.value;
                    if ((value !== null && value === 'Slight') || value == 'Moderate' || value == 'Severe') {
                      if (comment !== null && comment && comment.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return true;
                  },
                  message: 'Is there any evidence of roof degradation or interior Comment is Required',
                },
              },
            },
            {
              key: 'roofCoverDegradationComment',
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  field.formControl?.valueChanges.subscribe((value) =>
                    field?.form?.get('roofCoverDegradation')?.updateValueAndValidity()
                  );
                },
              },
            },
            {
              key: 'miscellaneousRoofObjects',
              type: 'simple-select',
              wrappers: ['question-wrapper'],
              className: 'select-full-width',
              props: {
                label: 'Are there any stacks, antenna, satellite dishes or flag/light poles on the roof?',
                required: true,
                isSectionLabelNormal: true,
                isSwipeGesture: true,
                isCommentActionEnabled: true,
                isAsteriskNotRequired: true,
                firstColSize: 7,
                showError: false,
                isLabelDisabled: true,
                commentActionKey: 'miscellaneousRoofObjectsComment',
                maxLength: 100,
                options: [
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                  { value: 'NDSC', label: 'Not Discernible' },
                ],
              },
              validators: {
                flashingConditionValidation: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const comment = field?.form?.get('miscellaneousRoofObjectsComment')?.value;
                    const value = control.value;
                    if (value !== null && value === 'Yes') {
                      if (comment !== null && comment && comment.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return true;
                  },
                  message: 'Are there any stacks, antenna, satellite Comment is Required',
                },
              },
            },
            {
              key: 'miscellaneousRoofObjectsComment',
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  field.formControl?.valueChanges.subscribe((value) =>
                    field?.form?.get('miscellaneousRoofObjects')?.updateValueAndValidity()
                  );
                },
              },
            },
          ],
        },        
      ],
      expressions: {
        hide: (field: FormlyFieldConfig) => {
          // Hide the whole section if WR is disabled
          const disabledField = field?.options?.formState?.isMainSectionDisabled?.filter(val => val.sectionId === SectionEnum.FR_WIND);
          if (disabledField.length==1) {
            return true;
          }
           else if (disabledField.length==0){
            return false;
          }
        }
      },       
    });
  }
}
