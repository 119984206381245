import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { RiskAttachmentService } from 'src/app/services/formly-builder/risk-attachment.service';
import { PreviewAnyFile } from '@awesome-cordova-plugins/preview-any-file/ngx';
@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss'],
})
export class PdfPreviewComponent implements OnInit {
  @Input() data: any;
  @Input() path: string;
  @Input() extension: string;
  pdfSrc: Uint8Array;

  constructor(private modal: ModalController, private previewAnyFile: PreviewAnyFile, public platform: Platform) {}
  ngOnInit() {
    // console.debug("PDF Preview");
  }

  async ionViewDidEnter() {
    if (this.extension === 'pdf') {
      this.pdfSrc = RiskAttachmentService.base64ToArrayBuffer(this.data);
    } else if (this.extension === 'doc' || this.extension === 'docx') {
      if (this.platform.is('capacitor')) {
        const mimeType = this.getMimeTypeFromExtension(this.extension);
        const data = this.data.data ? this.data.data : this.data;
        let wordData = `${mimeType};base64,` + data;
        this.previewAnyFile.previewBase64(wordData).subscribe(
          (res) => {
            this.close();
          },
          (error) => {
            console.error(error);
            this.close();
          }
        );
      } else {
        await this.downloadWordDocument(this.data, this.path);
        this.close();
      }
    }
  }

  close() {
    this.modal.dismiss();
  }

  async downloadWordDocument(data: any, path: string) {
    const base64String = data.data ? data.data : data;
    // Convert base64 string to ArrayBuffer
    const arrayBuffer = RiskAttachmentService.base64ToArrayBuffer(base64String);
    const pathComponents = path.split('/');
    const fileName = pathComponents[pathComponents.length - 1]; //File name
    const fileExtension = fileName.split('.')[1]; //Take extension
    const mimeType = this.getMimeTypeFromExtension(fileExtension);
    // Create Blob from ArrayBuffer
    const blob = new Blob([arrayBuffer], { type: mimeType });

    // Create object URL
    const url = URL.createObjectURL(blob);

    // Trigger download
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    // Cleanup
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  getMimeTypeFromExtension(extension: string): string | undefined {
    const extensionToMimetype: { [key: string]: string } = {
      doc: 'application/msword',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    };

    return extensionToMimetype[extension.toLowerCase()];
  }
}
