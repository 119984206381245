import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { atLeastOneCheckedValidator } from 'src/app/validators/formly-builder/at-least-one-checked.validator';

export class ExposuresSectionModel extends FormlySectionModelAbstract {
  constructor(
    private formlyService: FormlyBuilderService
  ) {
    super({
      key: 'exposures',
      wrappers: ['simple-section-wrapper'],
      fieldGroupClassName: 'exposures-sheet-container',
      props: {
        label: 'Exposure',
        addType: 'Exposure',
        addText: 'Add Exposures',
        isCollapsable: true,
        isCollapsed: true,
        actionButtons: [
          { action: 'add', label: 'Add' }
        ],
      },
      expressions: {
        // Add will be disabled if we already have 4 exposures
        'props.actionButtons': (field: FormlyFieldConfig) => formlyService?.exposuresService?.getActionButtons(field),
        'props.isCollapsed': (field: FormlyFieldConfig) =>
          field?.form?.value?.exposures?.exposuresArray?.length ? field.props.isCollapsed : true,
        // disable the expand collapse arrow if no entries
        'props.isCollapseIconDisabled': (field: FormlyFieldConfig) =>
          !field?.form?.value?.exposures.exposuresArray?.length
      },
      fieldGroup: [
        {
          key: 'exposuresArray',
          type: 'fr-exposures-array',
          fieldArray: {
            wrappers: ['simple-section-wrapper', 'error-info-wrapper'],
            props: {
              // hasTitleRow: false,
              isShallowTitleRow: true,
              showError: false,
              // checkTouchedControl: true
            },
            hooks: {
              onInit: (field) => {
                formlyService?.markAsTouchedIfHasValues(field);
              }
            },
            fieldGroup: [
              {
                fieldGroupClassName: 'formly-flex-container',
                fieldGroup: [
                  {
                    key: 'exposureDirection',
                    id: 'exposureDirection',
                    name: 'exposureDirection',
                    type: 'simple-select',
                    className: 'select-width-sm',
                    props: {
                      label: 'Side',
                      required: true,
                      options: [], // formlyService?.exposuresService?.getDirectionsLookupData(),
                      optionsAvailable: true,
                      isAsteriskNotRequired: true
                    },
                    hooks: {
                      onInit: (field) => {
                        if (field?.props) {
                          field.props.options = formlyService?.exposuresService?.getFilteredDirectionsLookupData(field);
                        }
                      }
                    },
                  },
                  {
                    key: 'surveyDistanceExposure',
                    id: 'surveyDistanceExposure',
                    type: 'simple-input',
                    defaultValue: null,
                    className: 'smd-width max-z-index position-relative',
                    props: {
                      label: 'Distance',
                      type: 'number',
                      inputmode: 'numeric',
                      unit: 'ft',
                      required: true,
                      min: 0,
                      maxLength: 5,
                      isNonBlockingValidation: false,
                      isAsteriskNotRequired: true
                    },
                  }
                ]
              },
              // Building Being Surveyed
              {
                fieldGroupClassName: 'internal-card-view formly-flex-container subsection-block',
                fieldGroup: [
                  {
                    className: 'block-title',
                    template: "<h2>Building Being Surveyed</h2>"
                  },
                  {
                    template: "<span></span>", className: 'new-row'
                  },
                  {
                    key: 'riskConstructionType',
                    id: 'riskConstructionType',
                    name: 'riskConstructionType',
                    type: 'simple-select',
                    className: 'full-width-select',
                    props: {
                      label: 'Facing wall construction',
                      required: true,
                      options: formlyService?.exposuresService?.getConstructionTypeLookupData(),
                      optionsAvailable: true,
                      isHorizontalOrientation: true,
                      selectWidth: true,
                      isNonBlockingValidation: false,
                      isCardEnabled: true,
                      isAsteriskNotRequired: true
                    },
                  },
                  {
                    template: "<span></span>", className: 'new-row'
                  },
                  {
                    key: 'riskProtectionType',
                    id: 'riskProtectionType',
                    name: 'riskProtectionType',
                    type: 'simple-select',
                    className: 'full-width-select',
                    props: {
                      label: 'Opening protection',
                      required: true,
                      options: formlyService?.exposuresService?.getOpeningProtectionLookupData(),
                      optionsAvailable: true,
                      isHorizontalOrientation: true,
                      selectWidth: true,
                      isCardEnabled: true,
                      isAsteriskNotRequired: true
                    },
                    expressions: {
                      'props.disabled': (field: FormlyFieldConfig) => formlyService?.exposuresService?.checkRiskProtectionTypeDisabled(field),
                    }
                  },
                  {
                    template: "<span></span>", className: 'new-row'
                  },
                  {
                    key: 'hasOutsideAutomaticSprinkler',
                    type: 'custom-checkbox',
                    className: 'card-view checkbox-full-width',
                    props: {
                      label: 'Outside Automatic Sprinkler',
                      isHorizontalOrientation: true,
                      labelFirst: true,
                      isCardView: true
                    },
                  },
                ]
              },
              // Exposure
              {
                fieldGroupClassName: 'internal-card-view formly-flex-container subsection-block',
                fieldGroup: [
                  {
                    className: 'block-title',
                    template: "<h2>Exposure</h2>"
                  },
                  {
                    template: "<span></span>", className: 'new-row'
                  },
                  {
                    key: 'exposureConstructionType',
                    id: 'exposureConstructionType',
                    name: 'exposureConstructionType',
                    type: 'simple-select',
                    className: 'full-width-select',
                    props: {
                      label: 'Facing wall construction',
                      required: true,
                      options: formlyService?.exposuresService?.getConstructionTypeLookupData(),
                      optionsAvailable: true,
                      isHorizontalOrientation: true,
                      isCardEnabled: true,
                      isAsteriskNotRequired: true
                    },
                  },
                  {
                    template: "<span></span>", className: 'new-row'
                  },
                  {
                    key: 'exposureProtectionType',
                    id: 'exposureProtectionType',
                    name: 'exposureProtectionType',
                    type: 'simple-select',
                    className: 'full-width-select',
                    props: {
                      label: 'Opening protection',
                      required: true,
                      options: formlyService?.exposuresService?.getOpeningProtectionLookupData(),
                      optionsAvailable: true,
                      isHorizontalOrientation: true,
                      isCardEnabled: true,
                      isAsteriskNotRequired: true
                    },
                    expressions: {
                      'props.disabled': (field: FormlyFieldConfig) => formlyService?.exposuresService?.checkExposureProtectionTypeDisabled(field),
                    }
                  },
                  {
                    template: "<span></span>", className: 'new-row'
                  },
                  {
                    key: 'occupancyType',
                    id: 'occupancyType',
                    name: 'occupancyType',
                    type: 'simple-select',
                    className: 'full-width-select',
                    props: {
                      label: 'Occupancy V1',
                      required: true,
                      options: formlyService?.exposuresService?.getOccupancyTypeLookupData(),
                      optionsAvailable: true,
                      isHorizontalOrientation: true,
                      isCardEnabled: true,
                      isAsteriskNotRequired: true
                    },
                  },
                  {
                    template: "<span></span>", className: 'new-row'
                  },
                  {
                    key: 'lengthHeightFactor',
                    id: 'lengthHeightFactor',
                    type: 'simple-input',
                    defaultValue: null,
                    className: 'input-full-width',
                    props: {
                      label: 'Length - height factor',
                      type: 'number',
                      isCardEnabled: true,
                      isHorizontalOrientation: true,
                      required: true,
                      min: 1,
                      maxLength: 5,
                      isNonBlockingValidation: false,
                      isAsteriskNotRequired: true
                    }
                  }
                ]
              },
              // Communications with section wrapper
              {
                wrappers: ['simple-section-wrapper'],
                // key: 'communications',
                className: 'internal-section',
                props: {
                  toggleAction: true,
                  label: 'Communications',
                  className: 'section-row',
                  isCollapsable: false,
                  isCollapsed: false,
                  showError: false,
                  checkTouchedControl: true
                },
                expressions: {
                  // 'props.isCollapsed': '!field?.model?.hasCommunications',
                  // 'props.isCollapsable': 'field?.model?.hasCommunications',
                },
                fieldGroup: [
                  {
                    key: 'hasCommunications',
                    defaultValue: false,
                    hooks: {
                      onInit: (field) => {
                        field?.formControl?.markAsTouched();
                        return formlyService?.exposuresService?.onCommunicationsChanged(field);
                      }
                    },
                    validators: {
                      validation: [{
                        name: 'at-least-one-checked',
                        options: {
                          condition: 'hasCommunications',
                          list: ['isPartyWall', 'isPassage'],
                          message: 'If Communications are indicated then either Party Wall or Passageway must be selected.'
                        },
                      }]
                    },
                  },
                  // Party Fire Wall
                  {
                    fieldGroupClassName: 'internal-card-view formly-flex-container',
                    fieldGroup: [
                      {
                        key: 'isPartyWall',
                        type: 'custom-checkbox',
                        defaultValue: false,
                        className: 'checkbox-left',
                        props: {
                          label: 'Party Fire Wall',
                          isHorizontalOrientation: true
                        },
                        hooks: {
                          onInit: (field) => formlyService?.exposuresService?.onCommunicationsPartyWallDisabled(field)
                        }
                      },
                      {
                        key: 'partyProtectionType',
                        type: 'simple-select',
                        className: 'select-width-s no-padding-bottom right-aligned-field',
                        props: {
                          label: 'Opening protection',
                          required: true,
                          options: formlyService?.exposuresService?.getPartyProtectionLookupData(),
                          optionsAvailable: true,
                          isAsteriskNotRequired: true
                        },
                        expressions: {
                          // hide: '!field?.model?.isPartyWall'
                          hide: (field: FormlyFieldConfig) => {
                            const ret = field?.form?.value?.isPartyWall ? false : true;
                            return ret;
                          }
                        },
                      },
                    ],
                    expressions: {
                      hide: '!field?.model?.hasCommunications'
                    },
                  },
                  // Passageway
                  {
                    fieldGroupClassName: 'internal-card-view formly-flex-container',
                    fieldGroup: [
                      {
                        key: 'isPassage',
                        id: 'isPassage',
                        type: 'custom-checkbox',
                        defaultValue: false,
                        className: 'checkbox-left',
                        props: {
                          label: 'Passageway',
                          isHorizontalOrientation: true
                        },
                        hooks: {
                          onInit: (field) => formlyService?.exposuresService?.onCommunicationsPassageDisabled(field)
                        }
                      },
                      {
                        template: "<span></span>", className: 'new-row',
                        expressions: {
                          hide: '!field?.model?.isPassage'
                        }
                      },
                      {
                        key: 'passageCombustibleType',
                        id: 'passageCombustibleType',
                        type: 'simple-select',
                        className: 'select-width-m margin-l-m margin-r',
                        props: {
                          label: 'Construction Type',
                          required: true,
                          options: formlyService?.exposuresService?.getPassageCombustibleTypeData(),
                          optionsAvailable: true,
                          isAsteriskNotRequired: true
                        },
                        expressions: {
                          hide: '!field?.model?.isPassage'
                        }
                      },
                      {
                        key: 'passageLength',
                        id: 'passageLength',
                        type: 'simple-input',
                        className: 'smd-width',
                        props: {
                          label: 'Length',
                          type: 'number',
                          inputmode: 'numeric',
                          unit: 'ft',
                          required: true,
                          min: 1,
                          maxLength: 5,
                          isNonBlockingValidation: false,
                          isAsteriskNotRequired: true
                        },
                        expressions: {
                          hide: '!field?.model?.isPassage'
                        }
                      },
                      {
                        template: "<span></span>", className: 'new-row',
                        expressions: {
                          hide: '!field?.model?.isPassage'
                        }
                      },
                      {
                        key: 'passageProtectionType',
                        id: 'passageProtectionType',
                        type: 'simple-select',
                        className: 'select-width-m margin-l-m margin-r',
                        props: {
                          label: 'Opening protection',
                          required: true,
                          options: formlyService?.exposuresService?.getPassageProtectionTypeLookupData(),
                          optionsAvailable: true,
                          isAsteriskNotRequired: true
                        },
                        expressions: {
                          hide: '!field?.model?.isPassage'
                        }
                      },
                      {
                        key: 'isPassageOpen',
                        id: 'isPassageOpen',
                        type: 'simple-select',
                        className: 'select-width-m',
                        props: {
                          label: 'Passageway Type',
                          required: true,
                          options: formlyService?.exposuresService?.getPassageWayTypeLookupData(),
                          optionsAvailable: true,
                          isAsteriskNotRequired: true
                        },
                        expressions: {
                          hide: '!field?.model?.isPassage'
                        }
                      },
                    ],
                    expressions: {
                      hide: '!field?.model?.hasCommunications'
                    },
                  }
                ]
              }
            ],
          },
        },
      ],
    });
  }
}
