import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { SectionEnum } from '../../section.config';
import { TwoInchDrainTestDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/two-inch-drain-test-data.model';
import { UtilService } from 'src/app/services/util-service/util.service';
import { OverAgeSprinklerTestDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/overage-sprinkler-test-data.model';
import { FormlyBuilderService } from '../../formly-builder.service';


// Overage sprinkler Test Sheet datas needs to be saved when user hits on apply in OverageSprinklerTest sheet
// (i.e) In Case of Overage sprinkler test there will be two sheets one top of another, 
// When user hits apply on the top level sheet, it should call the updateData method and the changes needs to be saved to s3
@Injectable({
  providedIn: 'root',
})
export class OverAgeSprinklerTestService extends SectionServiceAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService,
    private utilService: UtilService) {
    super();
  }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    // Will get Called only when user hits apply inside Overage Sprinkler Test sheet
    const OverAgeSprinklerTests = [];
    model?.sprinklerReport?.asgrSprinklerSystemTest?.overAgeSprinklerTests?.forEach((overAgeSprinklerTest) => {
      const OverAgeSprinkler = OverAgeSprinklerTestDataModel.to(overAgeSprinklerTest);
      if (OverAgeSprinkler) {
        OverAgeSprinkler.DateTested = this.utilService.convertDateToISOStringFormat(OverAgeSprinkler.DateTested);
        OverAgeSprinkler.YearOfIssue = this.utilService.convertDateToISOStringFormat(OverAgeSprinkler.YearOfIssue);
      }
      OverAgeSprinklerTests.push(OverAgeSprinkler);
    });
    const newRr = {
      ...riskReportData,
      SprinklerReport: {
        ...riskReportData.SprinklerReport,
        AsgrSprinklerSystemTest: {
          ...riskReportData.SprinklerReport.AsgrSprinklerSystemTest,
          ...{ OverAgeSprinklerTests: OverAgeSprinklerTests }
        }
      }
    }
    return { ...riskReportData, ...newRr };
  }

  loadData(sectionData: any[], reportInfo: any, isS3: boolean, model: any): any {
    // Will get Called only when user hits apply inside OverAge SprinklerTest sheet or hit close inside OverAge Sprinkler test sheet
    const OverAgeSprinklerTests = sectionData;
    let overAgeSprinklerTest = [];
    if (OverAgeSprinklerTests && OverAgeSprinklerTests.length) {
      OverAgeSprinklerTests.forEach((test) => {
        const overAgeSprinklerTestData = OverAgeSprinklerTestDataModel.fromS3(test);
        overAgeSprinklerTest.push(overAgeSprinklerTestData);
      });
    }

    const newModel = {
      ...model,
      sprinklerReport: {
        ...model.sprinklerReport,
        asgrSprinklerSystemTest: {
          ...model.sprinklerReport.asgrSprinklerSystemTest,
          ...{ overAgeSprinklerTests: overAgeSprinklerTest }
        }
      }
    }
    if (newModel) {
      this.formlyBuilderService.riskReport.model = { ...newModel };
    }
    return this.formlyBuilderService.riskReport.model;
  }


  async onApply(modalCtrl, field) {
    // When apply we need to save the value to S3 here
    const overAgeSprinklerTestService = field.options.formState.service.overAgeSprinklerTestService;
    const riskReportService = field.options.formState.riskReportService;
    const rr = await riskReportService.updateSectionData(SectionEnum.FR_SR_ST_OVER_AGE_SPRINKLER_TEST, false, null, OverAgeSprinklerTestService);

    // After hits apply in the sheet, It needs to be sorted based on most recent date
    let OverAgeSprinklerTests = rr?.SprinklerReport?.AsgrSprinklerSystemTest?.OverAgeSprinklerTests;
    OverAgeSprinklerTests = field.options.formState.service.sprinklerReportService.sortArrayWithMostRecentDate(OverAgeSprinklerTests, 'DateTested');

    // loadData to set the sorted value back to model
    overAgeSprinklerTestService.loadData(OverAgeSprinklerTests, rr, true, riskReportService.model);

    modalCtrl.dismiss(null, 'save');
  }

  onClose(modalCtrl, field, initialModel) {
    // Tried with patchValue and resetModel
    // Tried with reset and patchValue with forLoop for each array values
    // The above two didin't help so going with load Data on close 
    const formlyBuilderService = field.options.formState.service;
    const riskReportService = field.options.formState.riskReportService;
    const formlyModel = riskReportService.model;
    const rr = riskReportService.report;
    formlyBuilderService.overAgeSprinklerTestService.loadData(rr?.SprinklerReport?.AsgrSprinklerSystemTest?.OverAgeSprinklerTests, rr, true, formlyModel);
    formlyBuilderService.errorService.closePreviousToaster();
    modalCtrl.dismiss(null, 'cancel');
  }

  getSprinklerTypeOptions(): any[] {
    return [
      { value: 'STRD', label: 'Standard Response' },
      { value: 'FRSP', label: 'Fast Response' },
      { value: 'SLDR', label: 'Solder Type - Extra High' },
      { value: 'DRYS', label: ' Dry Sprinkler' },
      { value: 'HENV', label: 'Harsh Environment' },
    ]
  }

  // will return the label of sprinklertypecode
  getSprinklerTypeValue(field, sprinklerTypeCodeValue) {
    const overAgeSprinklerTestService = field.options.formState.service.overAgeSprinklerTestService;
    const options = overAgeSprinklerTestService.getSprinklerTypeOptions();
    const option = options.find((option) => option.value === sprinklerTypeCodeValue);
    return option ? option.label : '';
  }

}

