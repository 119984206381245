import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogTimeService {

  constructor(@Inject(LOCALE_ID) public locale: string) {

  }

  getTime(timestamp?) {
    if (timestamp) {
      return formatDate(
        new Date(timestamp),
        'YYYY-MM-dd HH:mm:ss:SSS',
        this.locale
      );
    } else {
      return formatDate(
        new Date(),
        'YYYY-MM-dd HH:mm:ss:SSS',
        this.locale
      );
    }
  }

}
