import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';

/**
 * Class that represents the Formly Model for WallsBg2
 */
export class WallsBGIIDataModel {
    
    static sectionName = 'WallsBG';
    isOpenSided: boolean;
    isReinforcedConcrete?:boolean;
    // TODO: Rename to read
    static fromS3(Walls,fullData): WallsBGIIDataModel {//wholedata from cached report data
            let booleanFlag = false;            
     // let wallsArrayReportModel = this.field.form?.value;
              const IsReinforcedConcrete = Walls?.every(wall => { 
            return ( wall.ConstructionTypeCodeValue === "M" && wall.WallClassification == 1 && wall.IsReinforcedConcrete === true)    
        });
        return {
            isOpenSided: fullData?.IsOpenSided,// this variable is from top level
            isReinforcedConcrete:IsReinforcedConcrete,//key value is from walls

        };
    }

    /**
     * 
     */
    static to(model: any) {
        return {
            IsOpenSided: model.isOpenSided === 'true' ? true : false,
            IsReinforcedConcrete :  model.isReinforcedConcrete  
        };
    }

    /**
     * Check if the model has changed
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     * @returns 
     */
    static hasChanged(formlyModel: any, riskReportModel: any): boolean {
        if (riskReportModel.IsOpenSided === undefined  && riskReportModel.IsReinforcedConcrete === undefined) {
            return true;
        }
        if (formlyModel.isOpenSided === riskReportModel.IsOpenSided && formlyModel.isReinforcedConcrete === riskReportModel.IsReinforcedConcrete) {
            return false;
        } else {
            return true;
        }
      
      
    }
}