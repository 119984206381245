import { FormlyFieldConfig } from '@ngx-formly/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class ConstructionVerificationSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'constructionVerification',
      type: 'segment',
      wrappers: ['question-wrapper'],
      props: {
        // eslint-disable-next-line @typescript-eslint/quotes, max-len
        label: "Given the existing information, has there been any construction material changes or square footage changes to the building?",
        required: true,
        optionsAvailable: false,
        firstColSize: 8.6
      },
      hooks: {
        afterViewInit: (field: FormlyFieldConfig) => {
          const formlyService = field.options.formState.service;
          const constVerifService = formlyService.constVerifService;
          constVerifService.disableEnableFields(field);
          return field.formControl.valueChanges.pipe(
            // debounceTime(1000),
            // distinctUntilChanged(),
            tap(value => {
              if (!field.formControl.pristine) {
                if (field.formControl.valid) {
                  formlyService.riskReport.updateSectionData(SectionEnum.CONSTRUCTION_VERIFICATION, false);
                  constVerifService.disableEnableFields(field);
                }
                  // Notify possible validity changes even if value not changed in RR/Model
                  field.options.formState.service.valid = field.formControl.valid;
              }
            })
          );
        },
      },
    });
  }
}
