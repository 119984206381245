import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Constants } from 'src/app/services/util-service/constants';

export class FloorsAndRoofsBuildingInformation extends FormlySectionModelAbstract {
  protected ngUnsubscribe = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'buildingInformation',
      wrappers: ['simple-section-wrapper'],
      props: {
        label: 'Building Information',
        isCollapsable: true,
        isCollapsed: true,
      },
      fieldGroup: [
        {
          type: 'summary-row',
          fieldGroup: [
            {
              key: 'aboveGradeFloors',
              props: {
                label: 'Number of Stories',
              },
              validators: {
                validation: [{ name: 'fullrisk-comparison-validator', options: { compareWith: 'section-occupants',section: 'floors-roofs' } }],
              },
            },
            {
              key: 'basementLevels',
              props: {
                label: 'Basement Levels',
              },
              validators: {
                validation: [{ name: 'fullrisk-comparison-validator', options: { compareWith: 'section-occupants',section: 'floors-roofs' } }],
              },
            },
            {
              key: 'totalArea',
              props: {
                label: 'Total Floor Area',
                unit: 'sq.ft.'
              },
              validators: {
                validation: [{ name: 'fullrisk-comparison-validator', options: { compareWith: 'section-occupants',section: 'floors-roofs' } }],
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'construction-class-container',
          fieldGroup: [
            {
              wrappers: ['question-wrapper'],
              className: 'width construction-spacing-v1',
              props: {
                label: 'Construction Class',
                showError: false,
                version: 'v1',
                isSupEnabled: true,
                isFontSizeSmall: true
              },
              fieldGroupClassName: 'formly-flex-container',
              fieldGroup: [
                {
                  key: 'isConstructionClassOverride',
                  type: 'custom-checkbox',
                  className: 'checkbox-style',
                  props: {
                  },
                },
                {
                  key: 'constructionClass',
                  type: 'simple-select',
                  className: 'select-width',
                  props: {
                    isDisabled: false,
                    isSectionLabelNormal: true,
                    options: [
                      { value: '1', label: '1' },
                      { value: '2', label: '2' },
                      { value: '3', label: '3' },
                      { value: '4', label: '4' },
                      { value: '5', label: '5' },
                      { value: '6', label: '6' },
                    ]
                  },
                  expressions: {
                    'props.isDisabled': (field: FormlyFieldConfig) => (field?.form?.value?.isConstructionClassOverride !== true) ? true : false
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      // When constructionClass equal to 1, set interior and exterior finish to false
                      const interiorFinishControl = field?.form?.root?.get('secondaryConstructions')?.get('combustibleFinish')?.get('isInteriorFinish');
                      const exteriorFinishControl = field?.form?.root?.get('secondaryConstructions')?.get('combustibleFinish')?.get('isExteriorFinish');
                      field.formControl?.valueChanges.subscribe((value)=>{
                        if (value === '1') {
                          // Setting the angular/formly value seems not enough...
                          interiorFinishControl.setValue(false);
                          exteriorFinishControl.setValue(false);

                          // ...So update the model as well... But with interior and exterior finish values updated!
                          const updatedFrm = formlyBuilderService.riskReport.model;
                          if (formlyBuilderService?.riskReport?.model?.secondaryConstructions?.combustibleFinish) {
                            updatedFrm.secondaryConstructions.combustibleFinish.isInteriorFinish = false;
                            updatedFrm.secondaryConstructions.combustibleFinish.isExteriorFinish = false;
                          }
                          formlyBuilderService.riskReport.model = { ...updatedFrm };
                        }
                      })
                    }
                  }
                },
              ],
            },
            {
              wrappers: ['question-wrapper'],
              className: 'width construction-spacing-v2',

              props: {
                label: 'Construction Class',
                version: 'v2',
                showError: false,
                isSupEnabled: true,
                isFontSizeSmall: true
              },
              hide: true, // We will enable this in v2 scope
              fieldGroupClassName: 'formly-flex-container',
              fieldGroup: [
                {
                  key: 'IsConstructionClassOverride-v2',
                  type: 'custom-checkbox',
                  props: {
                  },
                  className: 'field-margin',
                },
                {
                  key: 'ConstructionClass',
                  type: 'simple-select',
                  className: 'select-width',
                  props: {
                  },
                },
              ],
            }
          ],
          hooks: {
            // onInit: (field?: FormlyFieldConfig) =>
            //   field?.formControl?.valueChanges.pipe(
            //     distinctUntilChanged(),
            //     tap((value) => formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_FLOORS_ROOFS_BUILDING_INFORMATION, value, true))
            //   ),

            onInit: (field: FormlyFieldConfig) => {
              field?.formControl?.valueChanges.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(),
              debounceTime(Constants.riskFormDataSyncDelay))
                .subscribe(value => {
                  if (!field.formControl?.pristine) {
                    formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_FLOORS_ROOFS_BUILDING_INFORMATION, value, true);
                  }
                });
            },
            onDestroy: () => {
              this.ngUnsubscribe.next();
              this.ngUnsubscribe.complete();
            }
          }
        }
      ],
    });
  }
}
