import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyBuilderService } from '../formly-builder.service';
import { ContactInformationDataModel } from 'src/app/models/formly-builder/formly/contact-information-data.model';
import { CalculationInterface } from 'src/app/interfaces/formly-builder/calculation.interface';
import { CalculationEnum } from 'src/app/enums/formly-builder/calculation.enum';
import { CalculationStatusInterface } from 'src/app/interfaces/formly-builder/calculation-status.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactInformationService extends SectionServiceAbstract implements CalculationInterface {
  calculationStatus: number;

  constructor(private formlyBuilderService: FormlyBuilderService) {
    super();
  }

  async updateData(model: any, riskReportData: any) {
    // Check if data has changed
    const hasChanged: boolean = ContactInformationDataModel.hasChanged(model, riskReportData);
    this.formatPhoneNumber(model.contactInformation.contactPhone);
    if (hasChanged) {
      const newContactInfo = ContactInformationDataModel.to(model);
      return { ...riskReportData, ...newContactInfo };
    } else {
      return undefined;
    }
  }

  loadData(unused: any[], reportInfo: any, isS3: boolean, model: any): any {
    const contactInformationDataModel: ContactInformationDataModel = ContactInformationDataModel.fromS3(reportInfo);
    return {
      ...model,
      ...{
        contactInformation: {
          contactName: contactInformationDataModel?.contactName,
          contactPhone: this.formatPhoneNumber(contactInformationDataModel?.contactPhone),
          escortedByName: contactInformationDataModel?.escortedByName,
          escortedByPhone: this.formatPhoneNumber(contactInformationDataModel?.escortedByPhone),
        },
      },
    };
  }

  formatPhoneNumber(value?: string) {
    if (value !== null && value?.length=== 10 ) {
      const phone = value.match(/\d+/g).join("");;
      const code = phone.slice(0, 3);
      const firstPart = phone.slice(3, 6);
      const secondPart = phone.slice(6);
      return `(${code}) ${firstPart}-${secondPart}`;
    } else {
      return value;
    }
  }

  /**
   * Method to help simulating a long running calculation
   * @param ms
   * @returns
   */
  sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  /**
   * Method to simulate a long running calculation
   * @param formlyBuilder
   * @returns
   */
  calculate(formlyBuilder: FormlyBuilderService): Observable<CalculationStatusInterface> {
    return new Observable((subscriber) => {
      console.log('Contact Info Service is doing something');
      this.sleep(25000).then(() => {
        console.log('Contact Info End');
        subscriber.next({
          enum: CalculationEnum.CONTACTS,
          name: '',
          status: 22,
          comment: 'Ended',
          service: this,
        });
      });
    });
  }
}
