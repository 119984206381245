import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
//import { WallsBgDataModel } from 'src/app/models/formly-builder/sections/full-risk/walls-bg.data.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { takeUntil } from 'rxjs/operators';
import { FormlyBuilderService } from '../formly-builder.service';
import { RiskReportService } from '../risk-report.service';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { WallsBGIIDataModel } from 'src/app/models/formly-builder/formly/full-risk/walls-bg2-data.model';
@Injectable({
    providedIn: 'root',
  })
  export class FormlyWallsBGService extends SectionServiceAbstract {
    constructor(
        private http: HttpClient, 
        private formlyBuilderService: FormlyBuilderService,private riskReportService: RiskReportService,
        ) {
        super();
      }
      async updateData(model, riskReportData) {
        // this.formlyBuilder.riskReport.updateData(address);
        // Check if data has changed
        const hasChanged: boolean = WallsBGIIDataModel.hasChanged(model, riskReportData);
        if (hasChanged) {
          const wallsBg = WallsBGIIDataModel.to(model);//model since we are passing on toplevel
          return { ...riskReportData, ...wallsBg };
        } else {
          return undefined;
        }
      }
      loadData(walls: any[], fullData: any, isS3: boolean, model: any): any {
        let wallsBG: WallsBGIIDataModel;
        if (isS3) {
            wallsBG = WallsBGIIDataModel.fromS3(walls,fullData);
        }
        return { ...model, ...wallsBG };
      }
      bgWallsAccess(control){
        const riskmodel = this.riskReportService.model;//localdata
        const walls = riskmodel.walls;
        const IsReinforcedConcrete = walls.wallsArray?.every(wall => { 
          return ( wall.constructionType === "Masonry" && wall.wallClassification == 1 && wall.isReinforcedConcrete === true) 
        
        })
        control.pristine = false;
        control.setValue(IsReinforcedConcrete);
        
       // riskmodel.IsReinforcedConcrete = IsReinforcedConcrete;
        // let fullData = this.riskReportService.model;
        // this.loadData(fullData.walls,fullData,true,fullData);
      }
  }