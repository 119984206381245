import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { SortDirectionEnum } from 'src/app/enums/sort-direction.enum';
import { environment } from 'src/environments/environment';
import { OrderModel } from './order.model';
import { OrdersFiltersModel } from './orders-filters.model';
import { OrdersSortByFieldModel } from './orders-sort-by-field.model';
import { OrdersSortByModel } from './orders-sort-by.model';
import { Constants } from 'src/app/services/util-service/constants';
export class OrderCollectionModel {
  private ordersSubject: BehaviorSubject<OrderModel[]>;
  private timeout?: NodeJS.Timeout;

  constructor(public collection: OrderModel[]) {
    this.ordersSubject = new BehaviorSubject<OrderModel[]>(this.collection);
  }

  filterAndSortBy(filters: OrdersFiltersModel, sortBy: OrdersSortByModel): void {
    // To force the trigger of angular change detection a new array is needed
    const collection = this._filter([...this.collection], filters);

    this._sortBy(collection, sortBy);
    this.ordersSubject.value;
   
    collection.sort((a, b) => (
      a.Status === Constants.failureStatus && b.Status === Constants.failureStatus ? 0 :
      a.Status === Constants.failureStatus ? -1 :
      b.Status === Constants.failureStatus ? 1 :
      0
    ));

    this.ordersSubject.next(collection);
  }

  filter(filters: OrdersFiltersModel): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      // To force the trigger of angular change detection a new array is needed
      const collection = this._filter([...this.collection], filters);

      this.ordersSubject.next(collection);
    }, environment.searchDelay);
  }

  sortBy(sortBy: OrdersSortByModel): void {
    // To force the trigger of angular change detection a new array is needed
    const collection = [...this.collection];

    this._sortBy(collection, sortBy);

    this.ordersSubject.next(collection);
  }

  public SortByDate(sortTitle, sortBy) {
    const collection = this.currentValue;
    collection.map((item) => {
      const dudate = new Date(item.DueDate);
      return { ...item, dueDate: dudate.toString() };
    });
    const sortedCollection = collection.sort((a, b) => {
      if (a.DueDate < b.DueDate) return -1;
      if (a.DueDate > b.DueDate) return 1;
      return 0;
    });
    this.ordersSubject.next(sortedCollection);
  }

  public filterSelectedRecord(data: any, tabId) {
    if (tabId == 'scheduled') {
      let scheduleRecord = data.filter((order) => {
        const d = moment(order.AppointmentSet);
        if (d.isValid() && d.isAfter('1900-01-01')) {
          return true;
        }
      });
      return scheduleRecord;
    } else if (tabId !== 'scheduled' && tabId !== 'all') {
      let groupRecord = data.filter((order) => {
        if (order.GroupName === tabId) {
          return true;
        }
      });
      return groupRecord;
    } else {
      return data;
    }
  }

  public filterApptData(date: any) {
    let scheduleRecord = this.ordersSubject.value.filter((collection) => {
      let arr: [string];
      const d = moment(collection?.AppointmentSet).format('YYYY-MM-DD');
      const selected_date = moment(date).format('YYYY-MM-DD');
      let time = moment(collection?.AppointmentSet).format('HH:mm A');
      if (d === selected_date) {
        return true;
      }
    });

    return scheduleRecord;
  }

  public getSortedApptdata(date) {
    const collection = this.filterApptData(date);
    collection.map((item) => {
      const dudate = new Date(item?.AppointmentSet);
      return { ...item, apptDate: dudate.toString() };
    });
    const sortedCollection = collection.sort((a, b) => {
      if (a.AppointmentSet < b.AppointmentSet) return -1;
      if (a.AppointmentSet > b.AppointmentSet) return 1;
      return 0;
    });
    return sortedCollection;
  }

  public updatetotime(groupOrder: OrderModel) {
    const index = this.collection.findIndex((collection) => collection.OrderAmplifyId === groupOrder.OrderAmplifyId);
    if (index !== -1) {
      this.collection[index].GroupName = groupOrder.GroupName;
    }
    this.ordersSubject.next(this.collection);
  }

  public addProperty(key: string, value: boolean) {
    let apiCollection: OrderModel[] = this.currentValue;
    apiCollection = apiCollection.map((survyOrder) => Object.assign({}, survyOrder, { isSelected: false }));
    this.collection = apiCollection;
    this.ordersSubject.next(this.collection);
  }
  //TODO: Refactor these methods

  public setSelectAll() {
    let currentCollection: OrderModel[] = this.currentValue;
    currentCollection = currentCollection.map((survyOrder) => Object.assign({}, survyOrder, { isSelected: true }));
    this.mapValues(currentCollection);
    this.ordersSubject.next(currentCollection);
  }

  private mapValues(currentCollection: OrderModel[]) {
    this.collection.map((collectionItem) => {
      currentCollection.map((currentCollectionItem) => {
        if (currentCollectionItem.OrderAmplifyId === collectionItem.OrderAmplifyId) {
          collectionItem.isSelected = currentCollectionItem.isSelected;
        }
      });
    });
  }

  public unSelectAll() {
    let apiCollection: OrderModel[] = this.currentValue;
    apiCollection = apiCollection.map((survyOrder) => Object.assign({}, survyOrder, { isSelected: false }));
    this.mapValues(apiCollection);
    this.ordersSubject.next(apiCollection);
  }

  private _filter(collection: OrderModel[], filters: OrdersFiltersModel): OrderModel[] {
    if (OrderCollectionModel.countFilters(filters, true) === 0) {
      this.ordersSubject.next(this.collection);

      return collection;
    }
    return collection.filter((order) => {
      if (!this._findInArray(filters.status, order.Status, 'name')) {
        return false;
      }

      if (!this._findInArray(filters.companyName, order.CompanyDetails?.CompanyName)) {
        return false;
      }
      if (!this._findInArray(filters.insuredName, order.InsuredNameDba)) {
        return false;
      }

      if (!this._findInArray(filters.city, order.ValidatedCity)) {
        return false;
      }
      if (!this._findInArray(filters.state, order.ValidatedStateAbbrev)) {
        return false;
      }
      if (!this._findInArray(filters.county, order.ValidatedCounty)) {
        return false;
      }

      if (!this._findInArray(filters.zipCode, order.ValidatedPostalCodeFive)) {
        return false;
      }

      if (!this._findInArray(filters.orderNo, order.OrderIdFullOrderNumber)) {
        return false;
      }

      if (filters.reportType.length > 0) {
      if (!this._findInTwoArray(filters.reportType, order.ReportSymbol)) {
        return false;
      }
    }

      const now = moment();

      if (filters.dueDate) {
        if (filters.dueDate.Value != -1) {
          const dueDate = moment(order.DueDate);
          const nextDueDate = now.clone().add(filters.dueDate.Value, 'days');
          if (filters.dueDate.Value > 0) {
            if (dueDate.isBefore(now) || dueDate.isAfter(nextDueDate)) {
              return false;
            }
          } else {
            if (dueDate.isAfter(nextDueDate)) {
              return false;
            }
          }
        }
      }

      if (filters.apptDate) {
        if (filters.apptDate.Value > 0) {
          if (!order.AppointmentSet) {
            return false;
          }
          const apptDate = moment(order.AppointmentSet);
          const nextApptDate = now.clone().add(filters.apptDate.Value, 'days');

          if (apptDate.isBefore(now) || apptDate.isAfter(nextApptDate)) {
            return false;
          }
        }
      }

      if (filters.isAccl) {
        if (filters.isAccl !== order.IsAccelerated) {
          return false;
        }
      }

      switch (filters.tabId) {
        case 'scheduled':
          const d = moment(order.AppointmentSet);
          if (!d.isValid() || !d.isAfter('1900-01-01')) {
            return false;
          }
          break;
      }

      if (filters.isGroupTab) {
        if (filters.tabId !== order.GroupName) {
          return false;
        }
      }

      if (filters.searchTerm.trim() !== '') {
        return this._findStrInFilters(order, filters.searchTerm.toLowerCase().trim());
      }

      return true;
    });
  }

  private _findInTwoArray(arr1: any[], arr2: any[]): boolean {
      const reportSymbol = arr2;     
        for (let index = 0; index < reportSymbol.length; index++) {
          const reportTypeName = reportSymbol[index];
          if (arr1.findIndex((reportType) => reportType.name === reportTypeName) > -1) {
            return true;
          }
        }
  }

  private _sortBy(collection: OrderModel[], sortBy: OrdersSortByModel) {
    switch (sortBy?.Direction) {
      case SortDirectionEnum.ASC:
        collection.sort((o1, o2) => this._compareAsc(o1, o2, sortBy.Field));
        break;
      case SortDirectionEnum.DESC:
        collection.sort((o1, o2) => this._compareDesc(o1, o2, sortBy.Field));
        break;
    }
  }

  private _compareAsc(o1: any, o2: any, field: OrdersSortByFieldModel): number {
    const leftOrderValue: any = this.getValue(o1,field);
    const rightOrderValue: any = this.getValue(o2,field);
      
    //Handle null values
      if (leftOrderValue === null && rightOrderValue === null) return 0;
      if (leftOrderValue === null) return 1;
      if (rightOrderValue === null) return -1;

    if (leftOrderValue > rightOrderValue) {
      return 1;
    }

    if (leftOrderValue < rightOrderValue) {
      return -1;
    }

    return 0;
  }

  private _compareDesc(o1: any, o2: any, field: OrdersSortByFieldModel): number {
    const leftOrderValue: any = this.getValue(o1,field);
    const rightOrderValue: any = this.getValue(o2,field);

        //Handle null values
        if (leftOrderValue === null && rightOrderValue === null) return 0;
        if (leftOrderValue === null) return 1;
        if (rightOrderValue === null) return -1;

    if (leftOrderValue < rightOrderValue) {
      return 1;
    }

    if (leftOrderValue > rightOrderValue) {
      return -1;
    }

    return 0;
  }

  private getValue(obj:any,field: OrdersSortByFieldModel){
    if( typeof obj[field.Value] === 'object' && obj[field.Value] !== null){
      const keyName:any = Object.keys(obj[field.Value]);
      const dataObj = obj[field.Value];
      return dataObj[keyName];
    }
    else{
      return obj[field.Value];
    }
  }

  private _findStrInFilters(order: OrderModel, searchTerm: string): boolean {
    let date = moment(order.DueDate).format('MM/DD/YYYY').toString();
    let apptDate = order.AppointmentSet ? moment(order.AppointmentSet).format('MM/DD/YYYY').toString() : '';
    let appTime = order.AppointmentSet ? moment(order.AppointmentSet).format('h:mm a').toString() : '';
    let appDateTime = apptDate + ' ' + appTime;
    return (
      order.CompanyDetails?.CompanyName?.toLowerCase().indexOf(searchTerm) > -1 ||
      order.InsuredNameDba?.toLowerCase().indexOf(searchTerm) > -1 ||
      order.ValidatedAddress1?.toLowerCase().indexOf(searchTerm) > -1 ||
      order.ValidatedCity?.toLowerCase().indexOf(searchTerm) > -1 ||
      order.ValidatedPostalCodeFive?.toString().indexOf(searchTerm) > -1 ||
      date.indexOf(searchTerm) > -1 ||
      appDateTime.indexOf(searchTerm) > -1 ||
      order.Status?.toLowerCase().indexOf(searchTerm) > -1 ||
      order.OrderIdFullOrderNumber?.toLowerCase().indexOf(searchTerm) > -1
    );
  }

  private _findInArray(arr: any[], value: string, propName?: string): boolean {
    if (arr.length == 0) {
      return true;
    }

    return propName ? arr.findIndex((str) => str[propName] === value) > -1 : arr.findIndex((str) => str === value) > -1;
  }

  static countFilters(filters: OrdersFiltersModel, includeOther: boolean = false): number {
    let count = 0;

    if(filters) {
      count += filters.status.length > 0 ? 1 : 0;
      count += filters.isAccl ? 1 : 0;
      count += filters.companyName.length > 0 ? 1 : 0;
  
      count += filters.insuredName.length > 0 ? 1 : 0;
      count += filters.city.length > 0 ? 1 : 0;
      count += filters.state.length > 0 ? 1 : 0;
  
      count += filters.county.length > 0 ? 1 : 0;
      count += filters.zipCode.length > 0 ? 1 : 0;
      count += filters.dueDate ? filters.dueDate.Value == -1 ? 0: 1 : 0;
  
      count += filters.apptDate ? filters.apptDate.Value == 0 ? 0: 1 : 0;
      count += filters.orderNo.length > 0 ? 1 : 0;
      count += filters.reportType.length > 0 ? 1 : 0;
  
      if (includeOther) {
        count += filters.tabId !== '' && filters.tabId !== 'all' ? 1 : 0;
        count += filters.searchTerm.trim() !== '' ? 1 : 0;
      }
    }
    

    return count;
  }

  get orders$(): Observable<OrderModel[]> {
    return this.ordersSubject.asObservable();
  }

  get currentValue(): OrderModel[] {
    return this.ordersSubject.value;
  }

  public selectedOrders(): OrderModel[] {
    return this.currentValue.filter((order) => order.isSelected === true);
  }

  public updateGroupNames(groupOrder) {
    const index = this.collection.findIndex((collection) => collection.OrderAmplifyId === groupOrder.id);
    if (index !== -1) {
      this.collection[index].GroupName = groupOrder.GroupName;
    }
    this.ordersSubject.next(this.collection);
  }
}
