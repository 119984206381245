import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { LocalNotificationDescriptor, LocalNotifications, PendingResult, ScheduleOptions } from "@capacitor/local-notifications";

@Injectable({
    providedIn: 'root'
})
export class LocalNotificationsService {
    async askNotificationPermission(): Promise<void> {
        const areNotificationsEnabled = await LocalNotifications.areEnabled();

        if (!areNotificationsEnabled.value) {
            await LocalNotifications.requestPermissions();
        }
    }

    async scheduleNotification(title: string, body: string, repeats = true): Promise<void> {
        const id = Date.now();
        const notificationBody = `${body} id:${id}`;

        let notificationObject: ScheduleOptions = {
            notifications: [
                {
                    id,
                    title,
                    body: notificationBody,
                    schedule: {
                        every: environment.localNotificationDelay,
                        repeats,
                        count: 60,
                    }
                }
            ]
        };

        await LocalNotifications.schedule(notificationObject);
    }

    async getPendingNotifications(): Promise<PendingResult> {
        const pendingNotifications: PendingResult = await LocalNotifications.getPending();

        return pendingNotifications;
    }

    async clearAllNotifications(): Promise<void> {
        await LocalNotifications.removeAllDeliveredNotifications();

        const pendingNotifications: PendingResult = await this.getPendingNotifications();
        const notificationsToCancel: LocalNotificationDescriptor[] = [];

        pendingNotifications.notifications.forEach((notification) => {
            notificationsToCancel.push({ id: notification.id })
        });

        if(notificationsToCancel.length) {
            await LocalNotifications.cancel({
                notifications: notificationsToCancel
            });
        }
    }

    async addEventListeners(): Promise<void> {
        try {
            LocalNotifications.addListener('localNotificationReceived', (notification) => {
                console.log('local notification received', notification);
            });
            LocalNotifications.addListener('localNotificationActionPerformed', (notification) => {
                console.log('local notification perfomed', notification);
            });
        }
        catch (ex) {
            console.log(ex);
        }
    }
}