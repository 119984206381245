/* eslint-disable @typescript-eslint/naming-convention */
import { Guid } from 'guid-typescript';


/**
 * Class that represents the Formly Model for Wind Env Exposure Model
 */
export class WindLossHistoryDataModel {
  static sectionName = 'windLossSection';

  reportId: string;
  description: string;
  windLossHistoryDateTime: string;
  windLossHistoryId: string;

  // TODO: Rename to read
  static fromS3(data: any): WindLossHistoryDataModel {

    return {
      reportId: data.ReportId,
      description: data.Description,
      windLossHistoryDateTime: data.WindLossHistoryDateTime,
      windLossHistoryId: data.WindLossHistoryId
    };
  }
  static to(model: any, reportId?: Guid) {

    return {
      ReportId: model?.reportId ? model?.reportId : reportId,
      Description: model.description,
      WindLossHistoryDateTime: model.windLossHistoryDateTime,
      WindLossHistoryId: model.windLossHistoryId
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param WindLossHistory Model from the S3 Risk Report
   * @returns
   */
  //TODO change name and values for windReport
  static hasChanged(formlyModel: any, windFrameworkData: any): boolean {
    return true;
  }
}

