import { FormControl } from '@angular/forms';
import { FormlyField, FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { RiskModeEnum } from 'src/app/enums/formly-builder/risk-mode.enum';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionConfig, SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Constants } from 'src/app/services/util-service/constants';

export class CombustibleFinishModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      id: SectionConfig.get(SectionEnum.FR_SECONDARY_CONSTRUCTION).name,
      key: 'combustibleFinish',
      wrappers: ['simple-section-wrapper'],
      className: 'combustible-finish-container',
      props: {
        label: 'Combustible Finish',
        isCollapsable: true,
        isDisabled: false,
        showError: true,
        className: 'combustible-finish-cont',
        isCollapseIconDisabled: false,
        shallowErrors: false
      },
      fieldGroupClassName: 'combustible-finish-style',
      fieldGroup: [
        {
              // fieldGroupClassName:'group-style',
              wrappers: ['simple-section-wrapper'],
              className: 'interior-finish-container',
              props: {
                label: 'Interior Finish',
                showError: false,
                disabled: false,
                isDisabled: false,
                toggleAction: true,
                disableToggle: false,
              },
        // Disable card if both Construction Class v1 and v2 = 1

              expressions: {
                'props.disableToggle': (field: FormlyFieldConfig) => {
                  const constructionClass = field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('constructionClass')?.value;
                  if(constructionClass == '1') {
                    field.options.formState.service.secondaryConstructionsService.resetInteriorFinishSection(field);
                    return true;
                  }
                  else {
                    return false;
                  }
                },
              },
              fieldGroupClassName: 'formly-flex-container group-style',
              fieldGroup: [
                {
                  key: 'isInteriorFinish',
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      // When we change toggle reset all the data
                      
                      field.formControl.valueChanges.subscribe((value)=>{
                        field.options.formState.service.secondaryConstructionsService.resetInteriorFinishSection(field);
                      })
                    }
                  }
                },
                {
                  key: 'finishInteriorCombustibleType',
                  type: 'simple-select',
                  className: 'select-width-250',
                  props: {
                    label: 'Type',
                    disabled: false,
                    isDisabled: false,
                    showError: false,
                    readonly: false,
                    required: false,
                    isSectionLabelNormal: true,
                    isAsteriskNotRequired: true,
                    options: [{ value: 'Combustible/Slow Burn', label: 'Combustible/Slow Burn'},
                              { value: 'Foamed Plastic', label: 'Foamed Plastic'},       
                  ]
                  },
                  expressions : {
                    'props.required': (field: FormlyFieldConfig) => field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishInteriorPercentOfStoriesAffected) || field?.form?.value?.isInteriorFinish === true,
                  },
                  hooks: {
                    onInit: (field) => {
                      field.formControl.markAsTouched();
                      field.formControl.valueChanges.subscribe((value)=>{
                        if(field.options.formState.service.formlyHookReuseableService?.isCheckNull(value)) {
                          field?.form?.get('finishInteriorFlameSpread').updateValueAndValidity();
                          field?.form?.get('finishInteriorSmokeDevelopment').updateValueAndValidity();
                        }
                      })
                    },
                  },
                },
                {
                  // get options values and confirm if the no of stories is coming from floors & roof
                  key: 'finishInteriorPercentOfStoriesAffected',
                  type: 'simple-select',
                  className: 'select-width-sm',
                  props: {
                    label: '% of stories',
                    disabled: false,
                    isDisabled: false,
                    showError: false,
                    readonly: false,
                    required: false,
                    isSectionLabelNormal: true,
                    isAsteriskNotRequired: true,
                    options: [],
                  },
                  expressions : {
                    'props.required': (field: FormlyFieldConfig) => field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishInteriorCombustibleType),
                    'props.isDisabled': (field: FormlyFieldConfig) =>  !field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishInteriorCombustibleType),
                  },
                  hooks: {
                    onInit: (field) => {
                      field.formControl.markAsTouched();
                      field.props.options = formlyBuilderService?.secondaryConstructionsService?.getPercentOfStories(field);
                      const noOfStoriesControl =  field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('aboveGradeFloors');
                     
                      if(field.formControl.value) {
                        const arr = formlyBuilderService?.secondaryConstructionsService?.getPercentOfStories(field);
                        let result = arr.map(a => a.value);
                        const Val = formlyBuilderService?.secondaryConstructionsService?.getNearestApproximateVal(result,field.formControl.value,noOfStoriesControl.value);
                        field.formControl.setValue(Val);
                      }
                        noOfStoriesControl.valueChanges.subscribe((value)=> {
                        if(value!== null && value!== undefined &&  this.formlyBuilderService.constructionByLevelService.isFloorsAndRoofsSheetEdited === true){
                          field.formControl.setValue(null);
                          const exteriorFinishControl = field?.form?.root?.get('secondaryConstructions')?.get('combustibleFinish')?.get('finishExteriorPercentOfStoriesAffected')?.setValue(null);
                             // ...So update the model as well... But with interior and exterior finish values updated!
                             const updatedFrm = formlyBuilderService.riskReport.model;
                             if (formlyBuilderService?.riskReport?.model?.secondaryConstructions?.combustibleFinish) {
                               updatedFrm.secondaryConstructions.combustibleFinish.finishInteriorPercentOfStoriesAffected = null;
                               updatedFrm.secondaryConstructions.combustibleFinish.finishExteriorPercentOfStoriesAffected = null;
                             }
                             formlyBuilderService.riskReport.model = { ...updatedFrm };
                        }
                      })
                    },
                  },
                },
                { template: '<span></span>', className: 'new-row field-padding-bottom-10' },
                {
                  key: 'finishInteriorFlameSpread',
                  type: 'simple-input',
                  props: {
                    label: 'FS',
                    showError: false,
                    isSectionLabelNormal: true,
                    isAsteriskNotRequired: true,
                    type: 'number',
                    inputmode: 'tel',
                    disabled: false,
                    readonly: true,
                    max: 999,
                    min: 0,
                  },
                  hooks: {
                    onInit: (field) => {
                      field.formControl.markAsTouched();
                    },
                  },
                  expressions : {
                    'props.isDisabled': (field: FormlyFieldConfig) => {
                     if((field?.formControl?.value === '' || field?.formControl?.value === null || field?.formControl?.value === undefined) && (field?.form?.value?.isFinishInteriorUnlisted === true ||  !field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishInteriorCombustibleType))){
                     field?.formControl?.updateValueAndValidity({emitEvent:false});
                      return true;
                     }
                     else {
                     field?.formControl?.updateValueAndValidity({emitEvent:false});
                      return false;
                     }
                    },
                    'props.readonly': 'field.props.isDisabled'
                  },
                      className: 'input-width-xxs max-z-index position-relative',
                      validators :{
                        finishInteriorFlameSpreadVal : {
                          expression : (control: FormControl, field: FormlyFieldConfig) => {
                            if(field?.props?.isDisabled === false && field?.form?.value?.isInteriorFinish === true && (!field.options.formState.service.formlyHookReuseableService?.isCheckNull(control.value)) && field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishInteriorCombustibleType)) {
                              return false;
                            }
                            else {
                               return true;
                            }
                          },
                          message: 'Enter value or mark unlisted'
                        }
                      }
                },
                {
                  key: 'finishInteriorSmokeDevelopment',
                  type: 'simple-input',
                  props: {
                    label: 'SD',
                    showError: false,
                    isSectionLabelNormal: true,
                    isAsteriskNotRequired: true,
                    type: 'number',
                    inputmode: 'tel',
                    disabled: false,
                    readonly: false,
                    max: 999,
                    min: 0,
                  },
                  hooks: {
                    onInit: (field) => {
                      field.formControl.markAsTouched();
                    },
                  },
                      validators :{
                        finishInteriorSmokeDevelopmentVal : {
                          expression : (control: FormControl, field: FormlyFieldConfig) => {
                            if( field?.props?.isDisabled === false && field?.form?.value?.isInteriorFinish === true && (!field.options.formState.service.formlyHookReuseableService?.isCheckNull(control.value)) && field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishInteriorCombustibleType) ) {
                              return false;
                            }
                            else {
                               return true;
                            }
                          },
                          message: 'Enter value or mark unlisted'
                        }
                      },
                      expressions : {
                        'props.isDisabled': (field: FormlyFieldConfig) => {
                         if((field?.formControl?.value === '' || field?.formControl?.value === null || field?.formControl?.value === undefined) && (field?.form?.value?.isFinishInteriorUnlisted === true ||  !field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishInteriorCombustibleType))){
                          field?.formControl?.updateValueAndValidity({emitEvent:false});
                          return true;
                         }
                         else {
                          field?.formControl?.updateValueAndValidity({emitEvent:false});
                          return false;
                         }
                        },
                        'props.readonly': 'field.props.isDisabled'
                      },
                      className: 'input-width-xxs max-z-index position-relative',
                },
                { template: '<span>or</span>', className: 'text-combustible-finish text-or' },
                {
                  key: 'isFinishInteriorUnlisted',
                  type: 'custom-checkbox',
                  className: 'combustibleCheckbox',
                  props: {
                    label: 'Unlisted',
                    disabled: false,
                    showError: false,
                    isSectionLabelNormal: true,
                  },
                  expressions : {
                    'props.isDisabled': (field: FormlyFieldConfig) => {
                      if(field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishInteriorSmokeDevelopment) || 
                    field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishInteriorFlameSpread )){
                      field.formControl.setValue(false,{emitEvent:false});
                      return true;
                    }
                    else {
                      return false;
                    }
                  }
                  },
                },
                {
                  key: 'isFinishInteriorThermalBarrierPresent',
                  type: 'custom-checkbox',
                  className: 'combustibleCheckbox',
                  defaultValue: false,
                  props: {
                    label: 'Thermal Barrier',
                    isSectionLabelNormal: true,
                    showError: false,
                  },
                },
              ],
            },
            {
              // fieldGroupClassName:'group-style',
              wrappers: ['simple-section-wrapper'],
              props: {
                label: 'Exterior Finish',
                showError: false,
                disabled: false,
                isDisabled: false,
                toggleAction: true
              },
               // Disable card if both Construction Class v1 and v2 = 1

              expressions: {
                'props.disableToggle': (field: FormlyFieldConfig) => {
                  const constructionClass = field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('constructionClass')?.value;
                  if(constructionClass == '1') {
                    field.options.formState.service.secondaryConstructionsService.resetExteriorFinishSection(field);
                    return true;
                  }
                  else {
                    return false;
                  }
                },
              },
              fieldGroupClassName: 'formly-flex-container group-style',
              fieldGroup: [
                {
                  key: 'isExteriorFinish',
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      // When we change toggle reset all the data
                      
                      field.formControl.valueChanges.subscribe((value)=>{
                        field.options.formState.service.secondaryConstructionsService.resetExteriorFinishSection(field);
                      })
                    }
                  }
                },
                {
                  key: 'finishExteriorCombustibleType',
                  type: 'simple-select',
                  className: 'select-width-250',
                  props: {
                    label: 'Type',
                    disabled: false,
                    isDisabled: false,
                    readonly: false,
                    required: false,
                    showError: false,
                    isAsteriskNotRequired: true,
                    isSectionLabelNormal: true,
                    size: 'cover',
                    options: [{ value: 'Combustible', label: 'Combustible'},
                    { value: 'Foamed Plastic', label: 'Foamed Plastic'},       
        ]
                  },
                  hooks: {
                    onInit: (field) => {
                      field.formControl.markAsTouched();
                    },
                  },
                  expressions : {
                    'props.required' : (field: FormlyFieldConfig) => field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishExteriorPercentOfStoriesAffected) || field?.form?.value?.isExteriorFinish === true,
                  }
                },
                {
                  key: 'finishExteriorPercentOfStoriesAffected',
                  type: 'simple-select',
                  className: 'select-width-sm',
                  props: {
                    label: '% of stories',
                    disabled: false,
                    isDisabled: false,
                    readonly: false,
                    isSectionLabelNormal: true,
                    required: false,
                    showError: false,
                    isAsteriskNotRequired: true,
                    options: []
                  },
                  expressions : {
                    'props.required': (field: FormlyFieldConfig) =>  field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishExteriorCombustibleType),
                    'props.isDisabled': (field: FormlyFieldConfig) =>  !field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishExteriorCombustibleType),
                  },
                  hooks: {
                    onInit: (field) => {
                      field.formControl.markAsTouched();
                     field.props.options = formlyBuilderService?.secondaryConstructionsService?.getPercentOfStories(field);
                     if(field.formControl.value) {
                      const noOfStoriesControl =  field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('aboveGradeFloors');
                     
                      const arr = formlyBuilderService?.secondaryConstructionsService?.getPercentOfStories(field);
                      let result = arr.map(a => a.value);
                      const Val = formlyBuilderService?.secondaryConstructionsService?.getNearestApproximateVal(result,field.formControl.value,noOfStoriesControl.value);
                      field.formControl.setValue(Val);
                    }
                    },
                  },
                },
                { template: '<span></span>', className: 'new-row' },
                {
                  
                  key: 'finishExteriorFlameSpread',
                  type: 'simple-input',
                  props: {
                    label: 'FS',
                    showError: false,
                    isSectionLabelNormal: true,
                    isAsteriskNotRequired: true,
                    type: 'number',
                    inputmode: 'tel',
                    disabled: false,
                    readonly: false,
                    max: 999,
                    min: 0
                  },
                  validators :{
                    finishExteriorFlameSpreadVal2 : {
                      expression : (control: FormControl, field: FormlyFieldConfig) => {
                        if(  field?.props?.isDisabled === false && field?.form?.value?.isExteriorFinish === true && (!field.options.formState.service.formlyHookReuseableService?.isCheckNull(control.value)) && field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishExteriorCombustibleType) ) {
                          return false;
                        }
                        else {
                           return true;
                        }
                      },
                      message: 'Enter value or mark unlisted'
                    }
                  },
                  hooks: {
                    onInit: (field) => {
                      field.formControl.markAsTouched();
                    },
                  },
                  expressions : {
                    'props.isDisabled': (field: FormlyFieldConfig) => {
                     if((field?.formControl?.value === '' || field?.formControl?.value === null || field?.formControl?.value === undefined) && (field?.form?.value?.isFinishExteriorUnlisted === true ||  !field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishExteriorCombustibleType))){
                      field?.formControl?.updateValueAndValidity({emitEvent:false});
                      return true;
                     }
                     else {
                      field?.formControl?.updateValueAndValidity({emitEvent:false});
                      return false;
                     }
                    },
                    'props.readonly': 'field.props.isDisabled'
                  },
                    className: 'input-width-xxs max-z-index position-relative',
                },
                {
                  key: 'finishExteriorSmokeDevelopment',
                  type: 'simple-input',
                  props: {
                    label: 'SD',
                    isAsteriskNotRequired: true,
                    isSectionLabelNormal: true,
                    showError: false,
                    type: 'number',
                    inputmode: 'tel',
                    disabled: false,
                    readonly: false,
                    max: 999,
                    min: 0
                  },
                  hooks: {
                    onInit: (field) => {
                      field.formControl.markAsTouched();
                    },
                  },
                  validators :{
                    finishExteriorSmokeDevelopmentVal : {
                      expression : (control: FormControl, field: FormlyFieldConfig) => {
                        if(  field?.props?.isDisabled === false && field?.form?.value?.isExteriorFinish === true && (!field.options.formState.service.formlyHookReuseableService?.isCheckNull(control.value)) && field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishExteriorCombustibleType)) {
                          return false;
                        }
                        else {
                           return true;
                        }
                      },
                      message: 'Enter value or mark unlisted'
                    }
                  },
                    expressions : {
                      'props.isDisabled': (field: FormlyFieldConfig) => {
                       if((field?.formControl?.value === '' || field?.formControl?.value === null || field?.formControl?.value === undefined) && (field?.form?.value?.isFinishExteriorUnlisted === true ||  !field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishExteriorCombustibleType))){
                        field?.formControl?.updateValueAndValidity({emitEvent:false});
                        return true;
                       }
                       else {
                        field?.formControl?.updateValueAndValidity({emitEvent:false});
                        return false;
                       }
                      },
                      'props.readonly': 'field.props.isDisabled'
                    },
                    className: 'input-width-xxs max-z-index position-relative',
                },
                { template: '<span>or</span>', className: 'text-combustible-finish text-or' },
                {
                  key: 'isFinishExteriorUnlisted',
                  type: 'custom-checkbox',
                  className: 'combustibleCheckbox',
                  props: {
                    label: 'Unlisted',
                    disabled: false,
                    isSectionLabelNormal: true,
                    showError: false,
                  },
                  expressions : {
                    'props.isDisabled': (field: FormlyFieldConfig) => {
                      if(field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishExteriorSmokeDevelopment) || 
                    field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.form?.value?.finishExteriorFlameSpread )){
                      field.formControl.setValue(false,{emitEvent:false});
                      return true;
                    }
                    else {
                       return false;
                    }
                  },
                  },
                },
                {
                  key: 'isFinishExteriorThermalBarrierPresent',
                  type: 'custom-checkbox',
                  className: 'combustibleCheckbox',
                  defaultValue: false,
                  props: {
                    label: 'Thermal Barrier',
                    isSectionLabelNormal: true,
                    showError: false,
                  },
                },
              ],
            },
      ],
      hooks: {
        onInit: (field?: FormlyFieldConfig) =>
          field.formControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(), debounceTime(Constants.riskFormDataSyncDelay)).
            subscribe((value) => formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_SECONDARY_CONSTRUCTION, value, true)),
          onDestroy: () => {
            this.ngUnsubscribe.next();
            this.ngUnsubscribe.complete();
          }
      },
    });
  }
}

