import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[LimitTo]'
})
export class LimitToDirective {
  @Input('LimitTo') maxlength:number;

  constructor(private el: ElementRef) { }

    @HostListener('ionInput', ['$event'])
    onInput(event: any): void {
      const inputText: string = event.target.value;
      if (inputText.length > this.maxlength) {
        const truncatedText = inputText.substring(0, this.maxlength);
        this.el.nativeElement.value = truncatedText;
        event.target.value = truncatedText;
        event.preventDefault();
      }
    }
}
