import { RiskReportDataFloorsAndRoof, RiskReportDataReports } from '../../risk-report/risk-report-data.model';
import { FloorsRoofBg2DataModel } from './floors-roof-bg2-data.model';
import { FloorsRoofBuildingInformationDataModel } from './floors-roof-building-information-data.model';
import { FloorsRoofConstructionDataModel } from './floors-roof-construction-data.model';
import { LightFrameConstructionDataModel } from './floors-roof-light-frame-construction-data.model';

export class FloorsRoofConstructionConstructionDataModel {
  public floorsAndRoofsLevels: FloorsRoofConstructionDataModel[];
  public levelOnGradeArea?: number;
  public levelOnGradeLength?: number;
  public levelOnGradeWidth?: number;
  public isLowestLevelCombustibleLvlOnGrade?: boolean;
  public hasDividingWallLvlOnGrade?: boolean;
}

export class FloorsAndRoofDataModel {
  static sectionName = 'floorsAndRoofs';

  constructionClass: number;
  totalArea: number;
  basementLevels: number;
  aboveGradeFloors: number;
  numberOfAttics: number;
  numberOfMezzanine: number;


  constructionEntries: FloorsRoofConstructionConstructionDataModel;
  buildingInformation: FloorsRoofBuildingInformationDataModel;
  lightFrameConstruction: LightFrameConstructionDataModel;
  bg2Data: FloorsRoofBg2DataModel;
  static from(floorsAndRoofModel: FloorsRoofConstructionDataModel[], reportData: RiskReportDataReports): FloorsAndRoofDataModel {
    const tempConstructionEntries: FloorsRoofConstructionConstructionDataModel = {
      floorsAndRoofsLevels: floorsAndRoofModel
    };

    return {
      constructionClass: reportData?.ConstClass,
      totalArea: 0,
      basementLevels: reportData?.BsmntLevels,
      aboveGradeFloors: reportData?.NoOfFloors,
      constructionEntries: tempConstructionEntries,
      numberOfAttics: 0,
      numberOfMezzanine: 0,
      buildingInformation: null,
      lightFrameConstruction: null,
      bg2Data: null
    };
  }

  static fromS3(floorsAndRoofModel: FloorsRoofConstructionDataModel[], reportData): FloorsAndRoofDataModel {
    const levelOnGradeDetails = floorsAndRoofModel?.filter(src => src.isLowestLevel)[ 0 ];
    const tempConstructionEntries: FloorsRoofConstructionConstructionDataModel = {
      floorsAndRoofsLevels: floorsAndRoofModel,
      levelOnGradeLength: levelOnGradeDetails?.length,
      levelOnGradeWidth: levelOnGradeDetails?.width,
      levelOnGradeArea: levelOnGradeDetails?.area,
      isLowestLevelCombustibleLvlOnGrade: levelOnGradeDetails?.isLowestLevelCombustible,
      hasDividingWallLvlOnGrade: levelOnGradeDetails?.hasDividingWall,
    };

    return {
      constructionClass: reportData?.ConstructionClass,
      totalArea: 0,
      basementLevels: reportData?.BasementLevels,
      aboveGradeFloors: reportData?.NumberOfFloors,
      constructionEntries: tempConstructionEntries,
      numberOfAttics: 0,
      numberOfMezzanine: 0,
      buildingInformation: null,
      lightFrameConstruction: null,
      bg2Data: null
    };
  }

  static getFloorName(name: string) {
    switch (name) {
      case 'FLOO': return 'Floor';
      case 'ROOF': return 'Roof';
      case 'MEZZ': return 'Mezzanine';
      case 'ATTI': return 'Attic';
      case 'BASE': return 'Basement';
      default: {
        if (!name) { return name; }
        const lowerName = name[ 0 ].toUpperCase() + name.substr(1).toLowerCase();
        return lowerName;
      }
    }
  }

  static getLevelType(name: string) {
    switch (name) {
      case 'Floor': return 'FLOO';
      case 'Roof': return 'ROOF';
      case 'Mezzanine': return 'MEZZ';
      case 'Attic': return 'ATTI';
      case 'Basement': return 'BASE';
      default: {
        if (!name) { return name; }
        const lowerName = name[ 0 ].toUpperCase() + name.substr(1).toLowerCase();
        return lowerName;
      }
    }
  }

  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    return true;
  }

  static to(model: any, isFullRisk = false) {
    const floorsRoofRrModel = [];
    if (model && model?.constructionEntries && model?.constructionEntries?.floorsAndRoofsLevels &&
      model?.constructionEntries?.floorsAndRoofsLevels?.length > 0) {
      model?.constructionEntries?.floorsAndRoofsLevels.forEach(fr => {
        const floorsRoofData = FloorsRoofConstructionDataModel.to(fr, isFullRisk);
        floorsRoofRrModel.push(floorsRoofData);
      });
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    return { FloorsAndRoofs: floorsRoofRrModel };
  }
}
