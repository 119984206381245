/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FloorsAndRoofDataModel } from 'src/app/models/formly-builder/formly/floors-roof-data.model';
import { FloorsRoofConstructionDataModel } from 'src/app/models/formly-builder/formly/floors-roof-construction-data.model';
import { FormlyBuilderService } from './formly-builder.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { Constants } from '../util-service/constants';
import { validateAllFormFields } from 'src/app/shared/validateAllFormFields';
import { RiskModeEnum } from 'src/app/enums/formly-builder/risk-mode.enum';
// import { RiskReportService } from './risk-report.service';

@Injectable({
  providedIn: 'root',
})
export class FormlyFloorsRoofService extends SectionServiceAbstract {

  constructor(
    public formlyBuilderService: FormlyBuilderService,
    // private riskReportService: RiskReportService
  ) {
    super();
  }

  async updateData(model: any, riskReportData: any) {
    const hasChanged: boolean = FloorsAndRoofDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      const floorsAdRoof = FloorsAndRoofDataModel.to(model[ 'floorsAndRoofs' ]);
      const updatedModel = {
        FloorsAndRoofs: floorsAdRoof.FloorsAndRoofs,
        NumberOfFloors: model[ 'floorsAndRoofs' ].aboveGradeFloors ? model[ 'floorsAndRoofs' ].aboveGradeFloors : 0,
        BasementLevels: model[ 'floorsAndRoofs' ].basementLevels ? model[ 'floorsAndRoofs' ].basementLevels : 0
      };
      // this.generateOptionsForLevelOnGrade(); // added for calculating new options for levelOngrade levelType
      return { ...riskReportData, ...updatedModel };
    }
  }

  loadData(floorsAndRoofs: any[], reportRecord: any, isS3: boolean, model: any): any {
    let floorsAndRoofModel: FloorsAndRoofDataModel;
    const floorsAndRoofConstructionDataModel: FloorsRoofConstructionDataModel[] = [];

    floorsAndRoofs?.forEach((floor) => {
      let floorsRoofRecord;
      if (isS3) {
        floorsRoofRecord = FloorsRoofConstructionDataModel.fromS3(floor);
      } else {
        floorsRoofRecord = FloorsRoofConstructionDataModel.from(floor.data);
      }
      if (!floorsRoofRecord.area) {
        floorsRoofRecord.area = 1;
      }
      floorsAndRoofConstructionDataModel.push(floorsRoofRecord);
    });
    // this.generateOptionsForLevelOnGrade(); // added for calculating new options for levelOngrade levelType

    // Info from general report area
    if (isS3) {
      if (reportRecord) {
        floorsAndRoofModel = FloorsAndRoofDataModel.fromS3(floorsAndRoofConstructionDataModel, reportRecord);
      }
    } else {
      if (reportRecord && reportRecord.data) {
        floorsAndRoofModel = FloorsAndRoofDataModel.from(floorsAndRoofConstructionDataModel, reportRecord.data);
      }
    }

    if (floorsAndRoofs.length) {
      floorsAndRoofModel = this.calculateSummaryRowValues(floorsAndRoofModel, floorsAndRoofConstructionDataModel, true);
      floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels =
        this.sortFloorsAndRoofs(floorsAndRoofModel.constructionEntries?.floorsAndRoofsLevels);
      this.formlyBuilderService.riskReport.model = {
        ...this.formlyBuilderService.riskReport.model, ...{ floorsAndRoofs: floorsAndRoofModel }
      };
      // this.riskReportService.model = { ...this.riskReportService.model, ...{ floorsAndRoof: floorsAndRoofModel } };
      // return this.riskReportService.model;
      return this.formlyBuilderService.riskReport.model;
    }
    return null;
  }

  calculateSummaryRowValues(floorsAndRoofModel: any, floorsAndRoofConstructionDataModel: any, isOnLoad?: boolean) {
    // floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels =
    //   floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels.filter(src => src && src.levelTypeCode);

    this.calculateLevelOnGrade(floorsAndRoofModel);
    const floorsRoofLevels = floorsAndRoofModel?.constructionEntries?.floorsAndRoofsLevels;
    if (floorsAndRoofModel && floorsAndRoofModel?.constructionEntries &&
      floorsRoofLevels && floorsRoofLevels.length > 0) {
      floorsAndRoofModel?.constructionEntries?.floorsAndRoofsLevels.forEach(element => {
        if (element.isLowestLevel) {
          if (!floorsAndRoofModel?.constructionEntries?.levelOnGradeLength && isOnLoad) {
            floorsAndRoofModel.constructionEntries.levelOnGradeLength = Number(element.length);
          }
          if (!floorsAndRoofModel?.constructionEntries?.levelOnGradeWidth && isOnLoad) {
            floorsAndRoofModel.constructionEntries.levelOnGradeWidth = Number(element.width);
          }
          element.length = floorsAndRoofModel?.constructionEntries?.levelOnGradeLength ?
            Number(floorsAndRoofModel.constructionEntries?.levelOnGradeLength) : 0;
          element.width = floorsAndRoofModel?.constructionEntries?.levelOnGradeWidth ?
            Number(floorsAndRoofModel.constructionEntries?.levelOnGradeWidth) : 0;
          element.area = floorsAndRoofModel?.constructionEntries?.levelOnGradeArea ?
            Number(floorsAndRoofModel.constructionEntries?.levelOnGradeArea) : 0;
        }
        element.area = Number(element.length) * Number(element.width);
      });
    }

    floorsAndRoofModel.totalArea = this.calculatetotalArea(floorsAndRoofConstructionDataModel);
    const floorsAndRoofsLevels = floorsAndRoofModel?.constructionEntries?.floorsAndRoofsLevels; // .filter(src => !src.isLowestLevel);
    floorsAndRoofModel.aboveGradeFloors =
      this.formlyBuilderService.mode === 1 ? this.calculateNoOfFloors(floorsAndRoofsLevels) : floorsAndRoofModel?.aboveGradeFloors;
    floorsAndRoofModel.basementLevels =
      this.formlyBuilderService.mode === 1 ? this.calculateBasementLevelsValue(floorsAndRoofsLevels) : floorsAndRoofModel?.basementLevels;
    floorsAndRoofModel.numberOfAttics =
      this.formlyBuilderService.mode === 1 ? this.calculateNumberOfAtticAndMezzanine(floorsAndRoofsLevels, 'ATTI') : floorsAndRoofModel?.numberOfAttics;
    floorsAndRoofModel.numberOfMezzanine =
      this.formlyBuilderService.mode === 1 ? this.calculateNumberOfAtticAndMezzanine(floorsAndRoofsLevels, 'MEZZ') : floorsAndRoofModel?.numberOfMezzanine;

    return floorsAndRoofModel;
  }

  calculateLevelOnGrade(floorsAndRoofModel: any) {

    const floorsRoofLevels = floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels;
    if (
      floorsAndRoofModel &&
      floorsAndRoofModel.constructionEntries &&
      floorsRoofLevels &&
      floorsRoofLevels.length > 0
    ) {
      const basementLevelOnGradeIndex = floorsRoofLevels
        .findIndex(src => src.levelTypeCode === 'BASE' && !src.levelType.includes('LEVELONGRADE') && src?.isLowestLevel === false &&
          Number(src.levelStart) === Math.max(...floorsRoofLevels.filter(x => x.levelTypeCode === 'BASE' && !x?.isLowestLevel)
            .map(x => x.levelStart && x.levelStart !== null && x.levelStart !== undefined ? Number(x.levelStart) : 0)));

      const floorLevelOnGradeIndex = floorsRoofLevels.findIndex(src => src.levelTypeCode === 'FLOO' && !src.isLowestLevel &&
        Number(src.levelStart) === 1);

      const index = floorsRoofLevels.findIndex(src => src.isLowestLevel === true);
      if (index > -1) {
        if (basementLevelOnGradeIndex !== -1) {
          const baseArray = floorsRoofLevels
            .filter((src) => src.levelTypeCode === 'BASE' && !src?.isLowestLevel);
          const levelVal = baseArray?.map((x) =>
            Math.max(
              x.levelStart && x.levelStart !== null && x.levelStart !== undefined ? Number(x.levelStart) : 0,
              x.levelStop && x.levelStop !== null && x.levelStop !== undefined ? Number(x.levelStop) : 0
            ));

          floorsAndRoofModel?.constructionEntries?.floorsAndRoofsLevels?.forEach((src) => {
            if (src.isLowestLevel === true) {
              src.isLowestLevel = false;
              switch (src.levelTypeCode) {
                case 'FLOO':
                  src.levelType = 'Floor';
                  break;
                case 'BASE':
                  src.levelType = 'Basement';
                  break;
              }
            }
          });

          const baseLevelStop = Math.max(...levelVal) + 1;
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].isLowestLevel = true;
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelType = 'LEVELONGRADEBase';
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelTypeCode = 'BASE';
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelStop = baseLevelStop.toString();
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelStart = baseLevelStop.toString();
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelStop = null;
        }
        else if (floorLevelOnGradeIndex !== -1) {
          const index = floorsRoofLevels.findIndex(src => src.isLowestLevel === true);
          floorsAndRoofModel?.constructionEntries?.floorsAndRoofsLevels?.forEach(src => {
            if (src.isLowestLevel === true) {
              src.isLowestLevel = false;
              switch (src.levelTypeCode) {
                case 'FLOO': src.levelType = 'Floor';
                  break;
                case 'BASE': src.levelType = 'Basement';
                  break;
              }
            }
          });
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].isLowestLevel = true;
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelType = 'LEVELONGRADEBase';
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelTypeCode = 'BASE';
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelStart = '1';
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelStop = null;
        }
        else if (floorLevelOnGradeIndex === -1 && basementLevelOnGradeIndex === -1) {
          const tempIndex = floorsRoofLevels.findIndex(src => src.levelType?.includes('LEVELONGRADE'));
          floorsAndRoofModel?.constructionEntries?.floorsAndRoofsLevels?.forEach(src => {
            if (src.isLowestLevel === true) {
              src.isLowestLevel = false;
              switch (src.levelTypeCode) {
                case 'FLOO': src.levelType = 'Floor';
                  break;
                case 'BASE': src.levelType = 'Basement';
                  break;
              }
            }
          });
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].isLowestLevel = true;
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelType = 'LEVELONGRADEFloor';
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelTypeCode = 'FLOO';
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelStart = '1';
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelStop = null;
        }
      }

    }
  }


  calculateNoOfFloors(floorsAndRoofModel: FloorsRoofConstructionDataModel[]) {
    let aboveGradeFloors = 0;
    aboveGradeFloors = floorsAndRoofModel?.length ?
      this.getMaxLevel(floorsAndRoofModel, 'FLOO') : 0;
    return aboveGradeFloors;
  }

  calculateBasementLevelsValue(floorsAndRoofModel: FloorsRoofConstructionDataModel[]) {
    let basementLevels = 0;
    basementLevels = floorsAndRoofModel?.length ?
      this.getMaxLevel(floorsAndRoofModel, 'BASE') : 0;
    return basementLevels;
  }

  calculatetotalArea(floorsAndRoofConstructionDataModel: any) {
    let totalArea = 0;
    floorsAndRoofConstructionDataModel.forEach((floor) => {
      if (floor && floor.levelType !== 'Roof' && floor.area) {
        if (Number(floor.levelStop) > 0 && Number(floor.levelStart) < Number(floor.levelStop)) {
          totalArea += floor.area * (Number(floor.levelStop) - Number(floor.levelStart) + 1);
        } else {
          totalArea += floor.area;
        }
      }
    });
    return totalArea;
  }

  sortFloorsAndRoofs(floorsAndRoofModel: any): FloorsRoofConstructionDataModel[] {
    let arrTemp: FloorsRoofConstructionDataModel[] = [];
    if (this.formlyBuilderService.mode === 0) {
      const roofArr = floorsAndRoofModel.filter((item) => item.levelType === 'Roof');
      roofArr.sort((a, b) => Number(b.area) - Number(a.area));
      const floorArr = floorsAndRoofModel
        .filter((item) => item.levelType === 'Floor' || item.levelType === 'Base' ||
          item.levelType === 'Mezzanine' || item.levelType === 'Attic');
      floorArr.sort((a, b) => Number(b.levelStart) - Number(a.levelStop ? a.levelStop : a.levelStart));
      const levelOnGradeArr = floorsAndRoofModel.filter((item) => item.levelType.indexOf('LEVELONGRADE') > -1);
      arrTemp = roofArr.concat(floorArr).concat(levelOnGradeArr);
      if (arrTemp.length === floorsAndRoofModel.length) {
        floorsAndRoofModel = [ ...arrTemp ];
      }
    } else if (this.formlyBuilderService.mode === 1) {
      let floorsRoofArray = floorsAndRoofModel;

      // Grouping based on level type
      floorsRoofArray = floorsAndRoofModel.reduce((group, floorsRoof) => {
        if (floorsRoof.isLowestLevel) {
          group[ 'levelOnGrade' ] = [ floorsRoof ];
        } else if (floorsRoof.levelTypeCode) {
          const { levelTypeCode } = floorsRoof;
          group[ levelTypeCode ] = group[ levelTypeCode ] ? group[ levelTypeCode ] : [];
          group[ levelTypeCode ].push(floorsRoof);
        } else {
          group[ 'emptyType' ] = [ floorsRoof ];
        }
        return group;
      }, {});

      // Sorting each level type based on requirement
      floorsRoofArray.ROOF = floorsRoofArray.ROOF ? this.sortByProperty(floorsRoofArray.ROOF, 1, 'area') : [];
      floorsRoofArray.ATTI = floorsRoofArray.ATTI ? this.sortByProperty(floorsRoofArray.ATTI, 1, 'levelStart') : [];
      floorsRoofArray.FLOO = floorsRoofArray.FLOO ? this.sortByProperty(floorsRoofArray.FLOO, 1, 'levelStart') : [];
      floorsRoofArray.MEZZ = floorsRoofArray.MEZZ ? this.sortByProperty(floorsRoofArray.MEZZ, 1, 'levelStart') : [];
      floorsRoofArray.BASE = floorsRoofArray.BASE ? this.sortByProperty(floorsRoofArray.BASE, 2, 'levelStart') : [];
      floorsRoofArray.levelOnGrade = floorsRoofArray.levelOnGrade ? floorsRoofArray.levelOnGrade : [];
      floorsRoofArray.emptyType = floorsRoofArray.emptyType ? floorsRoofArray.emptyType : [];

      // Concating all the leveltype datas
      arrTemp = arrTemp.concat(floorsRoofArray.ROOF, floorsRoofArray.ATTI, floorsRoofArray.FLOO,
        floorsRoofArray.MEZZ, floorsRoofArray.BASE, floorsRoofArray.emptyType, floorsRoofArray.levelOnGrade);
      if (arrTemp.length === floorsAndRoofModel.length) {
        floorsAndRoofModel = [ ...arrTemp ];
      }
    }
    return floorsAndRoofModel;
  }

  getMaxLevel(allLevels: any[], levelType: string): number {
    return Math.max(
      ...allLevels?.map(function (level) {
        if (level && level?.levelTypeCode && level?.levelStart) {
          if (level.levelTypeCode.indexOf(levelType) > -1 && Number(level.levelStart)) {
            if (Number(level.levelStop)) {
              return Math.max(Number(level.levelStart), Number(level.levelStop));
            }
            return Number(level.levelStart);
          }
        }
        return 0;
      })
    );
  }

  onLevelTypeChange(field: FormlyFieldConfig) {
    field.formControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res && res.length > 0 && field.options.formState.service.isSheetActive) {
          field.form?.get('area').updateValueAndValidity({ emitEvent: false });
          field.form?.get('constructionType')?.setValue(null);
          field.form?.get('constructionMaterial')?.setValue(null);
          field.form?.get('levelStart')?.setValue(null);
          field.form?.get('levelStop')?.setValue(null);
          field.form?.get('levelType')?.setValue(FloorsAndRoofDataModel.getFloorName(res));
        }
      });
  }

  areaCalculation(field: FormlyFieldConfig) {
    const floorLengthControl = field.form.get('length');
    const floorWidthControl = field.form.get('width');
    floorLengthControl?.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((updatedLength) => {
      if (updatedLength) {
        const updatedArea = updatedLength * field.form.get('width').value;
        field.formControl.setValue(updatedArea);
        field.model.area = updatedArea;
      } else {
        field.formControl.setValue(0);
        field.model.area = 0;
      }
      // eslint-disable-next-line @typescript-eslint/dot-notation
      field.parent.parent.parent.formControl[ 'controls' ][ 'levelOnGradeArea' ].updateValueAndValidity();
    });
    floorWidthControl?.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((updatedWidth) => {
      if (updatedWidth) {
        const updatedArea = updatedWidth * field.form.get('length').value;
        field.formControl.setValue(updatedArea);
        field.model.area = updatedArea;
      } else {
        field.formControl.setValue(0);
        field.model.area = 0;
      }
      // eslint-disable-next-line @typescript-eslint/dot-notation
      field.parent.parent.parent.formControl[ 'controls' ][ 'levelOnGradeArea' ].updateValueAndValidity();
    });
  }

  levelOnGradeAreaCalculation(field: FormlyFieldConfig) {
    const floorLengthControl = field.form.get('levelOnGradeLength');
    const floorWidthControl = field.form.get('levelOnGradeWidth');
    floorLengthControl?.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((updatedLength) => {
      if (updatedLength) {
        const updatedArea = Number(updatedLength) * Number(floorWidthControl.value);
        field.formControl.setValue(updatedArea);
        field.model.levelOnGradeArea = updatedArea;
      } else {
        field.formControl.setValue(0);
        field.model.area = 0;
      }
      validateAllFormFields(field.parent.parent.formControl[ 'controls' ][ 'floorsAndRoofsLevels' ], true);
    });
    floorWidthControl?.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((updatedWidth) => {
      if (updatedWidth) {
        const updatedArea = Number(updatedWidth) * Number(floorLengthControl.value);
        field.formControl.setValue(updatedArea);
        field.model.levelOnGradeArea = updatedArea;
      } else {
        field.formControl.setValue(0);
        field.model.area = 0;
      }
      validateAllFormFields(field.parent.parent.formControl[ 'controls' ][ 'floorsAndRoofsLevels' ], true);
    });
  }

  constructionMaterialInitalization(field: FormlyFieldConfig) {
    const constructionTypeValue = field.form?.value?.constructionType;
    const constructionTypeControl = field.form?.get('constructionType');
    if (!field.form?.value?.isLowestLevel) {
      field.props.options = constructionTypeControl.valueChanges.pipe(startWith(''),
        distinctUntilChanged(),
        map((val: any) => {
          const constructionMaterials = Constants.floorsAndRoofsConstructionAssembly;
          const levelTypeValue = field.form?.value?.levelTypeCode;

          if (val && val.length > 0 && field.options.formState.service.isSheetActive) {
            field.form.get('constructionMaterial').setValue(null);
            return constructionMaterials
              .filter(src => src.constructionType?.toLowerCase() === val?.toLowerCase() &&
                (src.levelType?.toLowerCase() === (levelTypeValue?.toLowerCase() === 'floo' ||
                  levelTypeValue?.toLowerCase() === 'base' || levelTypeValue?.toLowerCase() === 'mezz' ||
                  levelTypeValue?.toLowerCase() === 'atti' ? 'floo' : 'roof') || src.levelType === 'Both'))
              .map(x => ({ value: x.constructionAssembly, label: x.constructionAssembly }));
          } else if (constructionTypeValue && constructionTypeValue.length > 0) {
            return constructionMaterials
              .filter(src => src.constructionType?.toLowerCase() === constructionTypeValue?.toLowerCase() &&
                (src.levelType?.toLowerCase() === (levelTypeValue?.toLowerCase() === 'floo' ||
                  levelTypeValue?.toLowerCase() === 'base' || levelTypeValue?.toLowerCase() === 'mezz' ||
                  levelTypeValue?.toLowerCase() === 'atti' ? 'floo' : 'roof') || src.levelType === 'Both'))
              .map(x => ({ value: x.constructionAssembly, label: x.constructionAssembly }));
          }
        })
      );
    }
  }

  levelStartInitalization(field: FormlyFieldConfig) {
    const levelTypeControl = field.form?.get('levelTypeCode');
    field.props.options = levelTypeControl?.valueChanges.pipe(startWith(''), map((val) => {
      let levelOptions = [];
      levelOptions = this.getLevelOptions(1, 110);
      // if (field.form.value.levelTypeCode === 'ATTI' || field.form.value.levelTypeCode === 'FLOO') {
      //   levelOptions = levelOptions.reverse();
      // }
      return levelOptions;
    })
    );
  }

  levelStopInitalization(field: FormlyFieldConfig) {
    const levelTypeControl = field.form?.get('levelStart');
    field.props.options = levelTypeControl?.valueChanges.pipe(startWith(''), map((val) => {
      let levelOptions = [];
      if (val && val.length > 0 && field.options.formState.service.isSheetActive) {
        field.form.get('area').updateValueAndValidity({ emitEvent: false });
        field.parent.parent.form.get('levelOnGradeArea').updateValueAndValidity({ emitEvent: false });
        field.formControl?.setValue(null);
      }
      const startValue = val && val.length > 0 ? val :
        (levelTypeControl?.value && levelTypeControl?.value > 0 ? levelTypeControl?.value : 1);
      levelOptions = this.getLevelOptions(startValue, 110);
      // if (field.form.value.levelTypeCode === 'ATTI' || field.form.value.levelTypeCode === 'FLOO') {
      //   levelOptions = levelOptions.reverse();
      // }
      return levelOptions;
    }));
  }

  calculateNumberOfAtticAndMezzanine(floorsAndRoof, type) {
    let numberOfAttic = 0;
    numberOfAttic = floorsAndRoof?.length ? this.getMaxLevel(floorsAndRoof, type) : 0;

    return numberOfAttic;
  }

  updateSummaryRowValues(riskReport: any) {
    // let floorsAndRoofModel = this.riskReportService.model.floorsAndRoofs;
    let floorsAndRoofModel = this.formlyBuilderService.riskReport.model.floorsAndRoofs;
    floorsAndRoofModel = this.calculateSummaryRowValues(floorsAndRoofModel, floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels);
    floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels =
      this.sortFloorsAndRoofs(floorsAndRoofModel.constructionEntries?.floorsAndRoofsLevels);
    floorsAndRoofModel.constructionClass = riskReport.ConstructionClass;
    // this.riskReportService.model = { ...this.riskReportService.model, ...{ floorsAndRoof: floorsAndRoofModel } };
    this.formlyBuilderService.riskReport.model = {
      ...this.formlyBuilderService.riskReport.model, ...{ floorsAndRoofs: floorsAndRoofModel }
    };
  }

  getLevelOptions(minValue, maxValue) {
    const optionsArr = [];
    for (let i = minValue; i <= maxValue; i++) {
      optionsArr.push({ label: i, value: i });
    }
    return optionsArr;
  }

  /**
   *
   *
   * @param * array
   * @param number order  (Descending order - 1, Ascending order - 2)
   * @param string property1
   * @return *
   * @memberof FormlyFloorsRoofService
   */
  sortByProperty(array: any, order: number, property1: string): any {
    if (array.length && array.length > 1) {
      if (order === 1) {
        array.sort((a, b) => Number(b[ property1 ]) - Number(a[ property1 ]));
      } else if (order === 2) {
        array.sort((a, b) => Number(a[ property1 ]) - Number(b[ property1 ]));
      }
    }
    return array;
  }

  /**
   * generates Level on grade leveltype options for occupants floor select
   */
  generateOptionsForLevelOnGrade() {
    let isFloorOnePresent = false;
    let isBasementOnePresent = false;
    let isBasementTwoPresent = false;
    // const floorsAndRoofArray = this.riskReportService.model.floorsAndRoofs?.constructionEntries?.floorsAndRoofsLevels;
    const floorsAndRoofArray = this.formlyBuilderService.riskReport.model?.floorsAndRoofs?.constructionEntries?.floorsAndRoofsLevels;
    floorsAndRoofArray?.forEach((item) => {
      if (item.levelType === 'Floor' && item?.levelStart === '1') {
        isFloorOnePresent = true;
      }
      if (isFloorOnePresent) {
        if (item.levelType === 'Basement' && item?.levelStart === '1') {
          isBasementOnePresent = true;
        }
      }
      if (isBasementOnePresent) {
        if (item.levelType === 'Basement' && item?.levelStart === '2') {
          isBasementTwoPresent = true;
        }
      }
    });
    if (
      (isFloorOnePresent && !isBasementOnePresent) ||
      (isFloorOnePresent && isBasementOnePresent && !isBasementTwoPresent)
    ) {
      this.formlyBuilderService.riskReport.model.floorsAndRoofs.basementLevels += 1;
    }
  }
}
