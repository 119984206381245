import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { RoofTopDataModel } from 'src/app/models/formly-builder/formly/roof-top-data.model';
import { RooftopPlacement } from 'src/app/models/rooftop-locationpoint/rooftop-locationpoint.model';
import { FormlyWrapperService, ActionButton } from 'src/app/services/formly-builder/formly-wrapper.service';
import { tap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SectionRoofTopService extends SectionServiceAbstract {
  // protected ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private wrapperService: FormlyWrapperService) {
    super();
  }

  async updateData(model: any, riskReportData: any) {
    return { ...riskReportData, ...RoofTopDataModel.to(model) }
  }

  loadData(rooftop: any[], reportInfo: any, isS3: boolean, model: any): any {
    let roofTopModel: RoofTopDataModel;

    if (isS3) {
      roofTopModel = RoofTopDataModel.fromS3(reportInfo);
    } else {
      roofTopModel = RoofTopDataModel.from(reportInfo.data);
    }
    return { ...model, ...{ roofTopLocationPoint: roofTopModel } };
  }

  getLocPlacementFromModel(model: any, selectedTarget: any): RooftopPlacement {
    if (model && model.latitude && model.longitude) {
      let myTarget;
      if (selectedTarget.hasOwnProperty('surveyType')) {
        myTarget = selectedTarget.OrchtargetId;
      } else if (selectedTarget.hasOwnProperty('TypeOfSurvey')) {
        myTarget = selectedTarget.OrchestratorTargetId
      }
      return {
        target: myTarget,
        locPoints: {
          latitude: model.latitude,
          longitude: model.longitude
        }
      };
    } else {
      return undefined;
    }
  }

  getPointsFromPlacement(placement: RooftopPlacement) {
    return [{
      target: placement.target,
      locPoints: [
        placement.locPoints.latitude,
        placement.locPoints.longitude
      ]
    }];
  }

  getLocPointsFromTarget(selectedTarget: any): any {
    if (selectedTarget && selectedTarget.LocationPoint 
        && selectedTarget.LocationPoint.coordinates 
        && selectedTarget.LocationPoint.coordinates.length === 2
        && selectedTarget.LocationPoint.coordinates[0] !== 0
        && selectedTarget.LocationPoint.coordinates[1] !== 0) {
      // Warning: longitude goes first!!!!
      return [{
        target: selectedTarget.OrchestratorTargetId,
        locPoints: [
          selectedTarget.LocationPoint.coordinates[1],
          selectedTarget.LocationPoint.coordinates[0]
        ]
      }];  
    } else {
      return undefined;
    }
  }

  processActions(data) {
    this.wrapperService.triggeredActionButton$
    .pipe( takeUntil(this.ngUnsubscribe) )
      .subscribe(async (res: ActionButton) => {
        if(res && res.action === "add" && res.flag === true) {

        }
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
