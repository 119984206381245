/**
 * List of Error Groups as per Error Scrubber Description
 */
export enum ErrorGroupEnum {
    OVERVIEW,
    WALLS,
    OCCUPANTS,
    FLOORS,
    WATER_SUPPLY,
    HYDRAULIC,
    NON_SPRINKLERED,
    BGII
}

/**
 * List of Error Groups as per Error Scrubber Description
 */
export enum ErrorTypeEnum {
    UNKNOWN,
    EXTERNAL,
    EXTERNAL_REQUIRED,          // Required field: Detected in the backend
    SYS_FAILURE,                // System Failure error ???
    ERROR_MESSAGE,
    FORMLY,                     // Validation Errors detected by Formly
    BACKEND_RR_ERROR,           // Validation Errors coming from the backend as ValidationErrorListModel
    BACKEND_SR_ERROR,           // Validation Errors coming from the backend as ValidationErrorListModel
    BACKEND_WR_ERROR,           // Validation Errors coming from the backend as ValidationErrorListModel
}

/**
 * List of Error Groups as per Error Scrubber Description
 */
export class ErrorType {
    public static names = [
        "Unknown",
        "EXTERNAL",
        "Backend - Required",          // Required field: Detected in the backend
        "SYS_FAILURE",                // System Failure error ???
        "ERROR_MESSAGE",
        "Formly",                     // Validation Errors detected by Formly
        "Backend - RR",           // Validation Errors coming from the backend as ValidationErrorListModel
        "Backend - SR",           // Validation Errors coming from the backend as ValidationErrorListModel
        "Backend - WR",           // Validation Errors coming from the backend as ValidationErrorListModel
    ];
}
