/* eslint-disable @typescript-eslint/naming-convention */
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { OccupantHazardsLevelDataModel } from './occupant-hazards-level-data.model';
import { Guid } from 'guid-typescript';

/**
 * Class that represents the Formly Model for Occupants
 */
export class OccupantsHazardsDataModel {
  static sectionName = 'occupants';

  doingBusinessAs: string;
  occupantScheduleNo: string;
  occupantType?: string;
  suiteNumber: string;
  isPreexisting?: boolean = false;   // Set to true if occupant exists in S3
  isDeleted?: boolean;              // Soft delete for preexisiting
  basicGroup2Symbol: string;
  // New data to be collected from existing RiskReport
  totalArea: number;
  commercialStatisticalPlan?: string;


  // TODO: check case
  reportIdentifier?: string;    // It's read from the S3 and kept
  occupantIdentifier?: string;   // If provided, reuse. If new generate uuid
  occupantNumber?: number = 0;   // 0/null (if string) for just created occupants

  occupantLevels: any[];   // OccupantsLevel[];
  isNewOccupant = false;
  combustibilityClass?: number;
  isCombustibilityClassOverride?: boolean;
  isBaseGroupIioverride?: boolean;
  isCommercialStatisticalPlanOverride?: boolean;
  susceptibilityClass?: number;
  susceptibilityClassEidtField?: number;
  isSusceptibilityClassOverride?: boolean;
  sortOrder?: any;
  occupantComment?: any;
  scopesVersion?: number;
  occupantHazards?: any;
  earthQuake?: number;
  spoc?: number;

  //fullRisk

  occupantDetails?: any; //it includes all the 4 fields ( earth quake.....)

  /**
   * @deprecated
   * @param alias
   * @returns
   */
  //   static from(alias: RiskReportDataOccupants): OccupantsDataModel {
  //     return {
  //       doingBusinessAs: alias.Name,
  //       occupantScheduleNo: alias.ScheduleNo,
  //       suiteNumber: alias.SuiteNo,
  //       totalArea: 0,
  //       occupantLevels: [],
  //       basicGroupII: alias.BG2Symbol.trim(),
  //       isNewOccupant: false,
  //     };
  //   }

  static fromS3(alias: any): OccupantsHazardsDataModel { // alias = {} inside occupant :[]=> alias.comment = occupant.comment in topLevel
    const levelArray: any[] = [];
    const occupantHazards: any[] = [];
    let totalArea = 0;
    let occupantDetails;
    if (alias.OccupantLevels && alias.OccupantLevels.length) { // for occupants Level -//TODO:
      alias.OccupantLevels.forEach((level) => {
        const levelData = OccupantHazardsLevelDataModel.fromS3(level);
        levelArray.push(levelData);
        // Calculations
        totalArea += (levelData.area);
        // if (level.OccupantLevelType === `FLOO`) {
        //   // aboveGradeFloors++;
        // } else if (level.OccupantLevelType === `BASE`) {
        //   // basementLevels++;
        // }
      });
    }
    if (alias?.OccupantHazards && alias?.OccupantHazards.length > 0) {
      alias?.OccupantHazards?.forEach(hazard => {
        occupantHazards.push({
          occupantHazardIdentifier: hazard?.OccupantHazardIdentifier,
          occupantIdentifier: hazard?.OccupantIdentifier,
          scheduleNumber: hazard?.ScheduleNumber,
          description: hazard?.Description,
          comment: hazard?.Comment
        });
      });
    }
    if (alias) {
      occupantDetails = { //key has to be occupantDetails
        earthQuake: alias.EarthQuake,
        basicGroup2Symbol: alias.BasicGroup2Symbol,
        spoc: alias.SPOC,
        susceptibilityClass: alias.SusceptibilityClass,
        susceptibilityClassEidtField: alias.SusceptibilityClass
      };
    }
    return {
      doingBusinessAs: alias.OccupantName ? alias.OccupantName.trim() : '',
      occupantScheduleNo: alias.ScheduleNumber,
      occupantType: alias.OccupantType ? alias.OccupantType : null,
      suiteNumber: alias.SuiteNumber,
      totalArea,
      occupantLevels: levelArray,
      basicGroup2Symbol: alias.BasicGroup2Symbol ? alias.BasicGroup2Symbol.trim() : undefined,
      isDeleted: (alias.IsDeleted !== undefined) ? alias.IsDeleted : false,
      commercialStatisticalPlan: alias.CommercialStatisticalPlan ? alias.CommercialStatisticalPlan?.trim() : undefined,
      //FullRisk
      occupantDetails,
      //we dont use below data but to match db, we are adding it
      occupantIdentifier: alias.OccupantIdentifier ? alias.OccupantIdentifier : Guid.create()?.toString(),
      reportIdentifier: alias.ReportIdentifier ? alias.ReportIdentifier : undefined,
      occupantNumber: alias.OccupantNumber ? alias.OccupantNumber : undefined,
      isNewOccupant: alias.IsNewOccupant ? alias.IsNewOccupant : false,
      combustibilityClass: alias?.CombustibilityClass?Number(alias?.CombustibilityClass):undefined,
      isCombustibilityClassOverride: alias.IsCombustibilityClassOverride,
      isBaseGroupIioverride: alias.IsBaseGroupIioverride,
      isCommercialStatisticalPlanOverride: alias.IsCommercialStatisticalPlanOverride,
      susceptibilityClass: alias.SusceptibilityClass ? alias.SusceptibilityClass : 0,
      susceptibilityClassEidtField: alias?.SusceptibilityClass,
      isSusceptibilityClassOverride: alias.IsSusceptibilityClassOverride,
      sortOrder: alias.SortOrder,
      occupantComment: alias.OccupantComment,
      scopesVersion: alias.ScopesVersion,
      occupantHazards,
      earthQuake: alias.EarthQuake,
      spoc: alias.SPOC,
      //fullRisk

    };
  }

  /**
   * Returns a S3 RiskReport object from a Formly model
   */
  static to(model: any) {
    const floorsRr = [];
    const occupantHazards = [];
    model.occupantLevels?.forEach((floor) => {
      floorsRr.push(OccupantHazardsLevelDataModel.to(floor));
    });
    if (model.occupantHazards && model.occupantHazards?.length > 0) {
      model.occupantHazards?.forEach(hazard => {
        occupantHazards.push({
          OccupantHazardIdentifier: hazard?.occupantHazardIdentifier ? hazard?.occupantHazardIdentifier : Guid.create()?.toString(),
          OccupantIdentifier: hazard?.occupantIdentifier,
          ScheduleNumber: hazard?.scheduleNumber,
          Description: hazard?.description,
          Comment: hazard?.comment
        });
      });
    }
    return {
      OccupantIdentifier: model.occupantIdentifier ? model.occupantIdentifier : Guid.create()?.toString(),
      ReportIdentifier: model.reportIdentifier ? model.reportIdentifier : undefined,
      OccupantNumber: model.occupantNumber ? model.occupantNumber : null,
      OccupantName: model.doingBusinessAs ? model.doingBusinessAs : undefined,
      OccupantType: model.occupantType ? model.occupantType : null,
      CommercialStatisticalPlan: model.commercialStatisticalPlan ? model.commercialStatisticalPlan : '',
      // CommercialStatisticalPlan: (model.occupantScheduleNo === '3550000102') ? 2805: model?.commercialStatisticalPlan,
      SuiteNumber: model.suiteNumber,
      ScheduleNumber: model.occupantScheduleNo,
      CombustibilityClass: model?.combustibilityClass?Number(model?.combustibilityClass):undefined,
      BasicGroup2Symbol: model.basicGroup2Symbol,
      IsCombustibilityClassOverride: model.isCombustibilityClassOverride ? model.isCombustibilityClassOverride : false,
      IsBaseGroupIioverride: model.isBaseGroupIioverride ? model.isBaseGroupIioverride : false,
      IsCommercialStatisticalPlanOverride: model.isCommercialStatisticalPlanOverride ? model.isCommercialStatisticalPlanOverride : false,

      SusceptibilityClass: (model.occupantScheduleNo === '6063100000'
        || model.occupantScheduleNo === '6063200000') ? model?.susceptibilityClassEidtField : model?.susceptibilityClass,

      IsSusceptibilityClassOverride: model.isSusceptibilityClassOverride ? model.isSusceptibilityClassOverride : false,
      SortOrder: model.sortOrder,
      OccupantComment: model.occupantComment,
      ScopesVersion: model.scopesVersion ? model.scopesVersion : 1,
      IsDeleted: model.isDeleted,
      IsNewOccupant: model.isNewOccupant ? model.isNewOccupant : false,
      OccupantHazards: occupantHazards,
      OccupantLevels: floorsRr,
      EarthQuake: model?.earthQuake,
      SPOC: model?.spoc,
    };
  }

  static filterModel(formlyModel: any): OccupantsHazardsDataModel {
    return {
      doingBusinessAs: formlyModel.doingBusinessAs,
      occupantScheduleNo: formlyModel.occupantScheduleNo,
      occupantType: formlyModel.occupantType,
      suiteNumber: formlyModel.suiteNumber,
      totalArea: formlyModel.totalArea,
      occupantLevels: formlyModel.occupantLevels,
      basicGroup2Symbol: formlyModel.basicGroup2Symbol,
      isDeleted: formlyModel.isDeleted,
      isNewOccupant: formlyModel.isNewOccupant,
      commercialStatisticalPlan: formlyModel.commercialStatisticalPlan,
      earthQuake: formlyModel?.earthQuake,
      spoc: formlyModel?.spoc,
      occupantDetails: formlyModel.occupantDetails,

      //we dont use below data but to match db, we are adding it
      occupantIdentifier: undefined,
      reportIdentifier: undefined,
      occupantNumber: undefined,
      combustibilityClass: formlyModel?.combustibilityClass ? formlyModel?.combustibilityClass : undefined,
      isCombustibilityClassOverride: undefined,
      isBaseGroupIioverride: undefined,
      isCommercialStatisticalPlanOverride: undefined,
      susceptibilityClass: formlyModel.susceptibilityClass ? formlyModel.susceptibilityClass : undefined,
      susceptibilityClassEidtField: formlyModel.susceptibilityClassEidtField ? formlyModel.susceptibilityClassEidtField : undefined,
      isSusceptibilityClassOverride: undefined,
      sortOrder: undefined,
      occupantComment: undefined,
      scopesVersion: undefined,
      occupantHazards: formlyModel?.occupantHazards
    };
  }
  /**
   * @param formlyModel model from formly
   * @param riskReportModel Model from the S3 Risk Report
   */
  static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
    return true;
  }
}
