import { Component, OnInit } from '@angular/core';
import { StreetViewService } from 'src/app/services/google-maps-services/street-view.service';

@Component({
  selector: 'app-street-view',
  templateUrl: './street-view.component.html',
  styleUrls: ['./street-view.component.scss'],
})
export class StreetViewComponent implements OnInit {

  constructor(private streetViewSrvc:StreetViewService) { }

  streetViewDOM = 'street-view';
    
  onSwipeDown() {
    this.streetViewSrvc.updateStreetViewVisibility(false);
  }
  close() {
    this.streetViewSrvc.updateStreetViewVisibility(false);
  }

  ngOnInit() {
    this.streetViewSrvc.LoadResources().then(() => {
      this.streetViewSrvc.initStreetView(this.streetViewDOM);
    });
  }
}
