import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Constants } from 'src/app/services/util-service/constants';

export class FloorsAndRoofsLightFrameConstructionData extends FormlySectionModelAbstract {
  protected ngUnsubscribe = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService,) {
    super({
      key: 'lightFrameConstruction',
      wrappers: [ 'simple-section-wrapper' ],
      props: {
        label: 'Light Frame Construction',
        isCollapsable: true,
        isCollapsed: true,
      },
      fieldGroupClassName: 'light-frame-container',
      fieldGroup: [
        {
          wrappers: [ 'question-wrapper' ],
          className: 'floor-percent-style',
          props: {
            label: 'Floor Percent',
            firstColSize: 3,
            isHorizontalOrientation: true,
            hasShadow: true,
            showError: false,
            isSectionLabelNormal: true
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              const floorArr = field?.parent?.form?.value?.constructionEntries?.floorsAndRoofsLevels?.slice(0,-1)?.some(floor => floor?.constructionTypeCode === 'COMB' && floor?.levelTypeCode !== 'ROOF'
              );
              if (floorArr) {
                return false;
              } else {
                return true;
              }
            }
          },
          fieldGroupClassName: 'formly-flex-container align-items-center',
          fieldGroup: [
            {
              key: 'isFloorPercentLightFrameConstructionNotAvailable',
              type: 'custom-checkbox',
              className: 'checkbox-style',
              props: {
                // isNaEnabled: true,
                isFontSizeSmall: true
              },
            },
            {
              template: '<span>NA</span>',
              className: 'na-width'
            },
            {
              key: 'floorPercentLightFrameConstruction',
              type: 'simple-input',
              className: 'percent-input-width',
              props: {
                unit: '%',
                label: '',
                isDisabled: false,
                disabled: false,
                type: 'number',
                inputmode: 'tel',
                maxLength: 3
              },
              hooks: {
                onInit: (field) => {
                  field?.formControl?.markAsTouched();
                  field?.form?.get('isFloorPercentLightFrameConstructionNotAvailable')?.valueChanges.subscribe(src => {
                    if (src) {
                      field?.formControl?.setValue(null);
                    }
                  });
                },
              },
              expressions: {
                'props.isDisabled': (field: FormlyFieldConfig) => (field?.form?.value?.isFloorPercentLightFrameConstructionNotAvailable === true) ? true : false,
                'props.disabled': (field: FormlyFieldConfig) => (field?.form?.value?.isFloorPercentLightFrameConstructionNotAvailable === true) ? true : false,
              },
              validators: {
                floorCheck: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    if (!field?.form?.value?.isFloorPercentLightFrameConstructionNotAvailable && field?.formControl?.value !== null && field?.formControl?.value !== undefined && field?.formControl?.value !== '') {
                      return true;
                    } else {
                      return false;
                    }
                  },
                  message: 'Either enter the percentage of floor that is light frame or mark as N/A',
                },
                percentageLimit: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    if (field?.formControl?.value !== null && field?.formControl?.value !== undefined && field?.formControl?.value !== '') {
                      if (Number(field?.formControl?.value) > 0 && Number(field?.formControl?.value) <= 100) {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return true;
                    }
                  },
                  message: 'Percentage must be between 1 and 100',
                }
              },
            },
          ],
        },
        {
          wrappers: [ 'question-wrapper' ],
          className: 'roof-percent-style',
          props: {
            label: 'Roof Percent',
            firstColSize: 3,
            hasShadow: true,
            showError: false,
            isSectionLabelNormal: true
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              const floorArr = field?.parent?.form?.value?.constructionEntries?.floorsAndRoofsLevels?.slice(0,-1)?.some(floor => floor?.constructionTypeCode === 'COMB' && floor?.levelTypeCode === 'ROOF'
              );
              if (floorArr) {
                return false;
              } else {
                return true;
              }
            }
          },
          fieldGroupClassName: 'formly-flex-container align-items-center',
          fieldGroup: [
            {
              key: 'isRoofPercentLightFrameConstructionNotAvailable',
              type: 'custom-checkbox',
              className: 'checkbox-style',
              props: {
                // isNaEnabled: true,
                isFontSizeSmall: true
              },
            },
            {
              template: '<span>NA</span>',
              className: 'na-width'
            },
            {
              key: 'roofPercentLightFrameConstruction',
              type: 'simple-input',
              className: 'percent-input-width',
              props: {
                unit: '%',
                label: '',
                isDisabled: false,
                disabled: true,
                type: 'number',
                inputmode: 'tel',
                maxLength: 3
              },
              hooks: {
                onInit: (field) => {
                  field.formControl.markAsTouched();
                  field?.form?.get('isRoofPercentLightFrameConstructionNotAvailable')?.valueChanges.subscribe(src => {
                    if (src) {
                      field?.formControl?.setValue(null);
                    }
                  });
                },
              },
              expressions: {
                'props.isDisabled': (field: FormlyFieldConfig) => (field?.form?.value?.isRoofPercentLightFrameConstructionNotAvailable === true) ? true : false,
                'props.disabled': (field: FormlyFieldConfig) => (field?.form?.value?.isRoofPercentLightFrameConstructionNotAvailable === true) ? true : false,
              },
              validators: {
                roofCheck: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    if (!field?.form?.value?.isRoofPercentLightFrameConstructionNotAvailable && field?.formControl?.value !== null && field?.formControl?.value !== undefined && field?.formControl?.value !== '') {
                      return true;
                    } else {
                      return false;
                    }
                  },
                  message: 'Either enter the percentage of roof that is light frame or mark as N/A',
                },
                percentageLimit: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    if (field?.formControl?.value !== null && field?.formControl?.value !== undefined && field?.formControl?.value !== '') {
                      if (Number(field?.formControl?.value) > 0 && Number(field?.formControl?.value) <= 100) {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return true;
                    }
                  },
                  message: 'Percentage must be between 1 and 100',
                }
              },
            },
          ],
        },
      ],
      hooks: {
        // onInit: (field?: FormlyFieldConfig) =>
        //   field.formControl.valueChanges.pipe(
        //     distinctUntilChanged(),
        //     tap((value) => formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_FLOORS_ROOFS_LIGHT_FRAME_CONSTRUCTION, value, true))
        //   )
        onInit: (field: FormlyFieldConfig) => {
          field?.formControl?.valueChanges.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(),
          debounceTime(Constants.riskFormDataSyncDelay))
            .subscribe(value => {
              if (!field.formControl.pristine) {
                formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_FLOORS_ROOFS_LIGHT_FRAME_CONSTRUCTION, value, true);
              }
            });
        },
        onDestroy: () => {
          this.ngUnsubscribe.next();
          this.ngUnsubscribe.complete();
        }
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => {
          const combustibleArr = field?.form?.value?.constructionEntries?.floorsAndRoofsLevels.slice(0,-1)?.some(floor => floor?.constructionTypeCode === 'COMB'
          );
          if (combustibleArr) {
            return false;
          } else {
            return true;
          }
        }
      },
    });
  }
}
