export class s3SyncConfig {

    public sync: boolean;
    public model: string;
    public s3IdentifierField: string;
    public s3AssetFolders: string[];
    public amplifyIdentifierField: string;
    public amplifyData: any;
    public ionicStorageContent: any;
    
    constructor(model: string, s3Field: string, amplifyField: string, assetFolders: string[] = null) {
        this.model = model;
        this.s3IdentifierField = s3Field;
        this.amplifyIdentifierField = amplifyField;
        this.s3AssetFolders = assetFolders;
    }
}