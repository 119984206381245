import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class DryPipeValveTripTestDataModel {
    static sectionName = 'systemTest';

    dryPipeValveTripTestId: string;
    reportId: string;
    systemNumber: string;
    testDate: Date;
    testConductedBy: string;
    testType: string;
    airPressure?: number;
    airPressureComment?: string;
    waterPressure?: number;
    waterPressureComment?: string;
    alarmActivationTime?: number;
    alarmActivationTimeComment?: string;
    testResults?: string;
    testResultsComment?: string;

    static fromS3(dryPipeTripTestData: any): DryPipeValveTripTestDataModel {

        return {
            dryPipeValveTripTestId: dryPipeTripTestData.DryPipeValveTripTestId,
            reportId: dryPipeTripTestData.ReportId,
            systemNumber: dryPipeTripTestData.SystemNumber,
            testDate: dryPipeTripTestData.TestDate,
            testConductedBy: dryPipeTripTestData.TestConductedBy,
            testType: dryPipeTripTestData.TestType,
            airPressure: dryPipeTripTestData.AirPressure,
            airPressureComment: dryPipeTripTestData.AirPressureComment,
            waterPressure: dryPipeTripTestData.WaterPressure,
            waterPressureComment: dryPipeTripTestData.WaterPressureComment,
            alarmActivationTime: dryPipeTripTestData.AlarmActivationTime,
            alarmActivationTimeComment: dryPipeTripTestData.AlarmActivationTimeComment,
            testResults: dryPipeTripTestData.TestResults,
            testResultsComment: dryPipeTripTestData.TestResultsComment,
        };
    }

    static to(model: any, sectionEnum?: SectionEnum) {
        return {
            DryPipeValveTripTestId: model.dryPipeValveTripTestId,
            ReportId: model.reportId,
            SystemNumber: model.systemNumber ? model.systemNumber : '',
            TestDate: model.testDate ? model.testDate : '',
            TestConductedBy: model.testConductedBy ? model.testConductedBy : '',
            TestType: model.testType ? model.testType : '',
            AirPressure: model?.airPressure ? Number(model.airPressure) : null,
            AirPressureComment: model.airPressureComment,
            WaterPressure: model?.waterPressure ? Number(model.waterPressure) : null,
            WaterPressureComment: model.waterPressureComment,
            AlarmActivationTime: model?.alarmActivationTime ? Number(model.alarmActivationTime) : null,
            AlarmActivationTimeComment: model.alarmActivationTimeComment,
            TestResults: model.testResults,
            TestResultsComment: model.testResultsComment,
        };
    }

    /**
     * Check if the model has changed
     *
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     *
     */
    static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
        return false;

    }
}
