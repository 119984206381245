/* eslint-disable @typescript-eslint/naming-convention */
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { AlarmAndSupervisoryTestDataModel } from './alarm-supervisory-test-data.model';
import { DryPipeValveTripTestDataModel } from './drypipe-valve-trip-test-data.model';
import { FirePumpTestDataModel } from './fire-pump-test-data.model';
import { OverAgeSprinklerTestDataModel } from './overage-sprinkler-test-data.model';
import { TwoInchDrainTestDataModel } from './two-inch-drain-test-data.model';
import { WaterSupplyTestDataModel } from './water-supply-test-data.model';
import { InternalSprinklerPipeConditionsDataModel } from '../internal-pipe-conditions-data.modet';
import { Guid } from 'guid-typescript';
 

export class SystemTestingDataModel {
  static sectionName = 'systemTesting';

  systemTesting: SystemTesting;
  mainDrainTesting: MainDrainTesting;

  reportId: string;
  hasFullFlowMainDrainTest: boolean;
  hasDpvTestEvidence: boolean;
  hasFirePumpTestEvidence: boolean;
  isDrySystemInstallationInSmallArea: boolean;
  alarmAndSupervisoryTests?: any[];
  firePumpTests?: any[];
  overAgeSprinklerTests?: any[];
  waterSupplyTests?: any[];
  dryPipeValveTesting: DryPipeValveTesting;
  acceptanceTesting?: AcceptanceTesting;
  firePumpValveTesting: FirePumpValveTesting;
  hasAdequatePressureOnMainDrainTest: boolean;
  isFirePumpTestNotApplicable: boolean;

  static fromS3(systemTestsData: any, internalSprinklerPipeConditions: any): SystemTestingDataModel {
    const alarmAndSupervisoryTestsArray: any[] = [];
    const dryPipeValveTripTestsArray: any[] = [];
    const firePumpTestsArray: any[] = [];
    const overAgeSprinklerTestsArray: any[] = [];
    const internalSrinklerPipeConditionsArray: any[] = [];
    const twoInchDrainTestsArray: any[] = [];
    const waterSupplyTestsArray: any[] = [];

    if (systemTestsData?.AlarmAndSupervisoryTests && systemTestsData?.AlarmAndSupervisoryTests?.length) {
      systemTestsData?.AlarmAndSupervisoryTests?.forEach((test) => {
        const alarmTestData = AlarmAndSupervisoryTestDataModel.fromS3(test);
        alarmAndSupervisoryTestsArray.push(alarmTestData);
      });
    }
    if (systemTestsData?.DryPipeValveTripTests && systemTestsData?.DryPipeValveTripTests?.length) {
      systemTestsData?.DryPipeValveTripTests?.forEach((test) => {
        const dryPipeTestData = DryPipeValveTripTestDataModel.fromS3(test);
        dryPipeValveTripTestsArray.push(dryPipeTestData);
      });
    }
    if (systemTestsData?.FirePumpTests && systemTestsData?.FirePumpTests?.length) {
      systemTestsData?.FirePumpTests?.forEach((test) => {
        const firePumpTestData = FirePumpTestDataModel.fromS3(test);
        firePumpTestsArray.push(firePumpTestData);
      });
    }
    if (systemTestsData?.OverAgeSprinklerTests && systemTestsData?.OverAgeSprinklerTests?.length) {
      systemTestsData?.OverAgeSprinklerTests?.forEach((test) => {
        const overageTestData = OverAgeSprinklerTestDataModel.fromS3(test);
        overAgeSprinklerTestsArray.push(overageTestData);
      });
    }
    if (internalSprinklerPipeConditions && internalSprinklerPipeConditions?.length) {
      internalSprinklerPipeConditions.forEach((test) => {
        const internalSprinklerPipeConditionsData = InternalSprinklerPipeConditionsDataModel.fromS3(test);
        internalSrinklerPipeConditionsArray.push(internalSprinklerPipeConditionsData);
      });
    }
    if (systemTestsData?.TwoInchDrainTests && systemTestsData?.TwoInchDrainTests?.length) {
      systemTestsData?.TwoInchDrainTests?.forEach((test) => {
        const twoInchTestData = TwoInchDrainTestDataModel.fromS3(test);
        twoInchDrainTestsArray.push(twoInchTestData);
      });
    }

    if (systemTestsData?.WaterSupplyTests && systemTestsData?.WaterSupplyTests?.length) {
      systemTestsData?.WaterSupplyTests?.forEach((test) => {
        const waterSupplyTestData = WaterSupplyTestDataModel.fromS3(test);
        waterSupplyTestsArray.push(waterSupplyTestData);
      });
    }

    const systemTesting = SystemTestingDataModel.setSystemTesting(systemTestsData);
    const mainDrainTesting = SystemTestingDataModel.setMainDrainTesting(systemTestsData, twoInchDrainTestsArray);
    const dryPipeValveTesting = SystemTestingDataModel.setDryPipeValveTesting(systemTestsData, dryPipeValveTripTestsArray, internalSrinklerPipeConditionsArray);
    const acceptanceTesting = SystemTestingDataModel.setAcceptanceTesting(systemTestsData);
    const firePumpValveTesting = SystemTestingDataModel.setFirePipeValueTesting(systemTestsData, firePumpTestsArray);
    return {
      reportId: systemTestsData?.ReportId,
      hasFullFlowMainDrainTest: systemTestsData?.HasFullFlowMainDrainTest,
      hasDpvTestEvidence: systemTestsData?.HasDpvTestEvidence,
      hasFirePumpTestEvidence: systemTestsData?.HasFirePumpTestEvidence,
      isDrySystemInstallationInSmallArea: systemTestsData?.IsDrySystemInstallationInSmallArea,
      alarmAndSupervisoryTests: alarmAndSupervisoryTestsArray,
      overAgeSprinklerTests: overAgeSprinklerTestsArray,
      waterSupplyTests: waterSupplyTestsArray,
      dryPipeValveTesting,
      acceptanceTesting,

      systemTesting,
      mainDrainTesting,
      firePumpValveTesting,
      hasAdequatePressureOnMainDrainTest: systemTestsData?.HasAdequatePressureOnMainDrainTest,
      isFirePumpTestNotApplicable: systemTestsData?.IsFirePumpTestNotApplicable,

    };
  }

  static to(model: any, reportId?: Guid) {
    const alarmAndSupervisoryTestsData = [];
    const dryPipeValveTripTestsData = [];
    const firePumpTestsData = [];
    const overAgeSprinklerTestsData = [];
    const internalSprinklerPipeConditionsData = [];
    const twoInchDrainTestsData = [];
    const waterSupplyTestsData = [];
    model.alarmAndSupervisoryTests?.forEach((test) => {
      alarmAndSupervisoryTestsData.push(AlarmAndSupervisoryTestDataModel.to(test));
    });
    model.dryPipeValveTesting?.dryPipeValveTripTests?.forEach((test) => {
      dryPipeValveTripTestsData.push(DryPipeValveTripTestDataModel.to(test));
    });
    model.firePumpValveTesting?.firePumpTests?.forEach((test) => {
      const firePumpTest = FirePumpTestDataModel.to(test, reportId);
      if (firePumpTest.TestDate) {
        firePumpTest.TestDate = this.convertDateToISOStringFormat(firePumpTest.TestDate);
      }
      firePumpTestsData.push(firePumpTest);
    });
    model.overAgeSprinklerTests?.forEach((test) => {
      overAgeSprinklerTestsData.push(OverAgeSprinklerTestDataModel.to(test));
    });
    model?.mainDrainTesting?.twoInchDrainTests?.forEach((test) => {
      twoInchDrainTestsData.push(TwoInchDrainTestDataModel.to(test));
    });
    model.waterSupplyTests?.forEach((test) => {
      waterSupplyTestsData.push(WaterSupplyTestDataModel.to(test));
    });
    return {
      ReportId: model.reportId,
      HasFlushingAndHydrostaticTests: model?.acceptanceTesting?.hasFlushingAndHydrostaticTests,
      HasNoFlushingAndHydrostaticTestEvidence: model?.acceptanceTesting?.hasNoFlushingAndHydrostaticTestEvidence ?
        model?.acceptanceTesting?.hasNoFlushingAndHydrostaticTestEvidence : false,
      HasFullFlowMainDrainTest: model.hasFullFlowMainDrainTest ? model.hasFullFlowMainDrainTest : false,
      IsDpvTestNotApplicable: model?.dryPipeValveTesting?.isDpvTestNotApplicable ?
        model?.dryPipeValveTesting?.isDpvTestNotApplicable : false,
      HasDpvTestEvidence: model.hasDpvTestEvidence ? model.hasDpvTestEvidence : false,

      IsFirePumpTestNotApplicable: model?.isFirePumpTestNotApplicable === false ? false : true,
      HasFirePumpTestEvidence: model.hasFirePumpTestEvidence ? model.hasFirePumpTestEvidence : false,
      HasNoRateRecognition: model?.systemTesting?.hasNoRateRecognition ? model?.systemTesting?.hasNoRateRecognition : false,
      MainDrainDateAfterSurvey: model?.mainDrainTesting?.mainDrainDateAfterSurvey,
      MainDrainTestDate: model?.mainDrainTesting?.mainDrainTestDate ? model.mainDrainTesting.mainDrainTestDate : '',
      // In backend it is expected to send the reverse case of the check box value
      HasDpvTest: !model?.dryPipeValveTesting?.hasDpvTest,
      DpvDateAfterSurvey: model?.dryPipeValveTesting?.dpvDateAfterSurvey,
      DpvTestDate: model?.dryPipeValveTesting?.dpvTestDate,
      DpvInstallationDate: model?.dryPipeValveTesting?.dpvInstallationDate,
      InitialPipeExamTestDate: model?.dryPipeValveTesting?.initialPipeExamTestDate,
      IsDrySystemInstallationInSmallArea: model.isDrySystemInstallationInSmallArea ? model.isDrySystemInstallationInSmallArea : false,
      HasScheduledAppliedDate: model?.systemTesting?.hasScheduledAppliedDate ?  model?.systemTesting?.hasScheduledAppliedDate : false,
      HasNoAsCredit: model?.systemTesting?.hasNoAsCredit ? model?.systemTesting?.hasNoAsCredit : false,
      AlarmAndSupervisoryTests: alarmAndSupervisoryTestsData,
      DryPipeValveTripTests: dryPipeValveTripTestsData,
      FirePumpTests: firePumpTestsData,
      OverAgeSprinklerTests: overAgeSprinklerTestsData,
      TwoInchDrainTests: twoInchDrainTestsData,
      WaterSupplyTests: waterSupplyTestsData,

      FirePumpTestDate: model?.firePumpValveTesting?.firePumpTestDate,
      FirePumpDateAfterSurvey: model?.firePumpValveTesting?.firePumpDateAfterSurvey,
      // In backend it is expected to send the reverse case of the check box value
      HasFirePumpTest: !model?.firePumpValveTesting?.hasFirePumpTest,
      HasAdequatePressureOnMainDrainTest: model?.hasAdequatePressureOnMainDrainTest !== null && model?.hasAdequatePressureOnMainDrainTest !== undefined ? model?.hasAdequatePressureOnMainDrainTest : null, 
      // Report: null
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
    return false;
  }

  // To set value to system testing field values
  static setSystemTesting(systemTestsData: any): SystemTesting {
    const hasScheduledAppliedDate = systemTestsData?.MainDrainDateAfterSurvey || systemTestsData?.DpvDateAfterSurvey ||
      systemTestsData?.FirePumpDateAfterSurvey ? true : false;
    return {
      hasScheduledAppliedDate: hasScheduledAppliedDate ? hasScheduledAppliedDate : systemTestsData?.HasScheduledAppliedDate,
      hasNoAsCredit: systemTestsData?.HasNoAsCredit,
      hasNoRateRecognition: systemTestsData?.HasNoRateRecognition
    };
  }

  // To set value to Main Drain testing field values
  static setMainDrainTesting(systemTestsData: any, twoInchDrainTestsArray: any[]): MainDrainTesting {
    return {
      mainDrainTestDate: systemTestsData?.MainDrainTestDate,
      mainDrainDateAfterSurvey: systemTestsData?.MainDrainDateAfterSurvey,
      twoInchDrainTests: twoInchDrainTestsArray
    };
  }
  static setDryPipeValveTesting(systemTestsData: any, dryPipeValveTripTestsArray: any[], internalSrinklerPipeConditionsArray: any[]): DryPipeValveTesting {
    return {
      hasDpvTest: systemTestsData?.HasDpvTest !== null && systemTestsData?.HasDpvTest !== undefined ? !systemTestsData.HasDpvTest : null,
      dpvDateAfterSurvey: systemTestsData?.DpvDateAfterSurvey,
      dpvTestDate: systemTestsData?.DpvTestDate,
      dpvInstallationDate: systemTestsData?.DpvInstallationDate,
      initialPipeExamTestDate: systemTestsData?.InitialPipeExamTestDate,
      isDpvTestNotApplicable: systemTestsData?.IsDpvTestNotApplicable,
      dryPipeValveTripTests: dryPipeValveTripTestsArray,
      internalSprinklerPipeConditions: internalSrinklerPipeConditionsArray,
    };
  }

  // To set value to acceptance testing field values
  static setAcceptanceTesting(systemTestsData: any): AcceptanceTesting {
    return {
      hasFlushingAndHydrostaticTests: systemTestsData?.HasFlushingAndHydrostaticTests,
      hasNoFlushingAndHydrostaticTestEvidence: systemTestsData?.HasNoFlushingAndHydrostaticTestEvidence
    };
  }
  // To set value to acceptance testing field values
  static setFirePipeValueTesting(systemTestsData: any, firePumpTestsArray: any[]): FirePumpValveTesting {
    return {
      firePumpTestDate: systemTestsData?.FirePumpTestDate,
      firePumpDateAfterSurvey: systemTestsData?.FirePumpDateAfterSurvey,
      hasFirePumpTest: systemTestsData?.HasFirePumpTest !== null && systemTestsData?.HasFirePumpTest !== undefined ? !systemTestsData?.HasFirePumpTest : null,
      firePumpTests: firePumpTestsArray,
    };
  }

  static convertDateToISOStringFormat(date) {
    try {
      if (date) {
        const dateISOString = new Date(date).toISOString();
        return dateISOString;
      }
    } catch (e) {
      console.log(e);
    }
    return null;
  }
}

class SystemTesting {
  hasScheduledAppliedDate: boolean;
  hasNoAsCredit: boolean;
  hasNoRateRecognition: boolean;
}

class MainDrainTesting {
  mainDrainTestDate: Date;
  mainDrainDateAfterSurvey: Date;
  twoInchDrainTests: TwoInchDrainTestDataModel[];
}

class DryPipeValveTesting {
  dpvTestDate: Date;
  dpvDateAfterSurvey: Date;
  hasDpvTest: boolean;
  dpvInstallationDate: Date;
  initialPipeExamTestDate: Date;
  isDpvTestNotApplicable: boolean;
  dryPipeValveTripTests: DryPipeValveTripTestDataModel[];
  internalSprinklerPipeConditions: InternalSprinklerPipeConditionsDataModel[];
}
class AcceptanceTesting {
  hasFlushingAndHydrostaticTests: boolean;
  hasNoFlushingAndHydrostaticTestEvidence: boolean;
}
class FirePumpValveTesting {
  firePumpTestDate: Date;
  firePumpDateAfterSurvey: Date;
  hasFirePumpTest: boolean;
  firePumpTests: FirePumpTestDataModel[];
}
