import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Guid } from 'guid-typescript';

export class FirePumpTestResultDataModel {
    static sectionName = 'systemTesting';

    firePumpTestResultId: string;
    firePumpTestId: string;
    ratedCapacity?: number;
    suctionPsi?: number;
    dischargePsi?: number;
    rotationsPerMinute?: number;
    totalDischargeGpm?: number;

    static fromS3(firePumpTestResultData: any): FirePumpTestResultDataModel {

        return {
            firePumpTestResultId: firePumpTestResultData.FirePumpTestResultId,
            firePumpTestId: firePumpTestResultData.FirePumpTestId,
            ratedCapacity: firePumpTestResultData.RatedCapacity,
            suctionPsi: firePumpTestResultData.SuctionPsi,
            dischargePsi: firePumpTestResultData.DischargePsi,
            rotationsPerMinute: firePumpTestResultData.RotationsPerMinute,
            totalDischargeGpm: firePumpTestResultData.TotalDischargeGpm,
        };
    }

    static to(model: any, firePumpTestId, sectionEnum?: SectionEnum) {
        return {
            FirePumpTestResultId: model?.firePumpTestResultId ? model.firePumpTestResultId : Guid.create().toString(),
            FirePumpTestId: model?.firePumpTestId ? model.firePumpTestId : firePumpTestId,
            RatedCapacity: model?.ratedCapacity ? model.ratedCapacity : 0,
            SuctionPsi: model?.suctionPsi ? +model.suctionPsi : 0,
            DischargePsi: model?.dischargePsi ? +model.dischargePsi : 0,
            RotationsPerMinute: model?.rotationsPerMinute ? +model.rotationsPerMinute : 0,
            TotalDischargeGpm: model?.totalDischargeGpm ? +model.totalDischargeGpm : 0,
        }
    }

    /**
     * Check if the model has changed
     *
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     *
     */
    static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
        return false;

    }
}
