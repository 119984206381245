import { Injectable, OnInit } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyWrapperService, ActionButton } from 'src/app/services/formly-builder/formly-wrapper.service';
import { takeUntil } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { Observable, Subject, of } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SecondaryConstructionsDataModel } from 'src/app/models/formly-builder/formly/full-risk/secondary-constructions-data.model';
import { GreenConstructionDataModel } from 'src/app/models/formly-builder/formly/green-construction-data.model';

@Injectable({
  providedIn: 'root',
})
export class SecondaryConstructionsService extends SectionServiceAbstract {
  protected ngUnsubscribe = new Subject<void>();

  constructor(
    // private modalController: ModalController,
    private wrapperService: FormlyWrapperService) {
    super();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  async updateData(model: any, riskReportData: any) {
    // Check if data has changed
    const hasChanged: boolean = SecondaryConstructionsDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      // while sending/saving data to riskreport we save all secondary construction sections data as one object
      // SecondaryConstructionsDataModel reflects DTO of Secondary construction 
      const secondaryConstructions = SecondaryConstructionsDataModel.to(model);
      return { ...riskReportData, ...{ SecondaryConstructions: secondaryConstructions } };
    } else {
      return undefined;
    }
  }

  loadData(unused: any[], reportInfo: any, isS3: boolean, model: any): any {
    // while loading we load all children individually according to their own data model as well as formly structure 
    // store it as different objects in formly model
    const secondaryConstructions: SecondaryConstructionsDataModel = SecondaryConstructionsDataModel.fromS3(reportInfo);
    return {
      ...model, ...{ secondaryConstructions: secondaryConstructions }
    };
  }

  calculateFloorArea(field) {
    let sumOfArea = 0;
    const  floorsAndroof =field?.formControl?.root?.get('floorsAndRoofs')?.get('constructionEntries')?.get('floorsAndRoofsLevels')?.value;
    const roofArr = floorsAndroof?.filter((item)=> item.levelTypeCode === 'FLOO');
        roofArr.forEach((item)=>  sumOfArea += item.area);
    return sumOfArea;
  }
  calculateFireResistiveMasonryRoofArea(field) {
    let sumOfArea = 0;
    const floorsAndroof = field.formControl?.root?.get('floorsAndRoofs')?.get('constructionEntries')?.get('floorsAndRoofsLevels')?.value;
    if (floorsAndroof?.length) {
      const roofArr = floorsAndroof?.filter((item) => item.levelTypeCode === 'ROOF' && (item.constructionTypeCode === 'FIRE' || item.deckConstruction === 'COMA'));
      roofArr.forEach((item) => sumOfArea += item.area);
    }
    return sumOfArea;
  }
  calculateBuildingTotalRoofArea(field) {
    let sumOfArea = 0;
    const floorsAndroof = field?.formControl?.root?.get('floorsAndRoofs')?.get('constructionEntries')?.get('floorsAndRoofsLevels')?.value;
    if (floorsAndroof?.length) {
      const roofArr = floorsAndroof?.filter((item) => item.levelTypeCode === 'ROOF');
      roofArr.forEach((item) => sumOfArea += item.area);
    }
    return sumOfArea;
  }

  getLargestFloorArea(field) {
    const floorsAndroof = field?.formControl?.root?.get('floorsAndRoofs')?.get('constructionEntries')?.get('floorsAndRoofsLevels')?.value;
    if (floorsAndroof?.length) {
      const floorArr = floorsAndroof?.filter((item) => item.levelTypeCode === 'FLOO' || item.levelTypeCode === 'BASE');
      let largestArea;
      if (floorArr?.length) {
        const max = floorArr?.reduce(function (prev, current) {
          return (prev && prev.area > current.area) ? prev : current
        });
        return max?.area;
      }
    }
    return null;
  }

  getLevelOptions(minValue, maxValue,constructionClass): Observable<any> {
    const optionsArr = [];
    let newMax = maxValue -1;
    if(constructionClass == '5' || constructionClass == '6' ){
    newMax = maxValue > 8 ? 8 : (maxValue -1);
    }
    for (let i = minValue; i <= newMax; i++) {
      optionsArr.push({ label: i, value: i });
    }
    // console.log("getLevelStartOptions "+optionsArr);
    return of(optionsArr);
  }

  getPercentOfStories(field) {
    const noOfStories = field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('aboveGradeFloors')?.value;
    const optionArr = [];
    let prev =0;
    const arr= [];
    for(let i=0;i<noOfStories;i++) {
      optionArr[i] = (100/noOfStories)+prev;
      prev = optionArr[i];
      arr.push({label: ''+(i+1)+' - '+Math.round(optionArr[i])+'%', value: Math.round(optionArr[i]) });
    }
    return arr;
  }

  resetInteriorFinishSection(field) {
    const finishInteriorCombustibleTypeControl = field?.form?.root?.get('secondaryConstructions')?.get('combustibleFinish')?.get('finishInteriorCombustibleType');
    const finishInteriorPercentOfStoriesAffectedControl = field?.form?.root?.get('secondaryConstructions')?.get('combustibleFinish')?.get('finishInteriorPercentOfStoriesAffected');
    const finishInteriorFlameSpreadControl = field?.form?.root?.get('secondaryConstructions')?.get('combustibleFinish')?.get('finishInteriorFlameSpread');
    const finishInteriorSmokeDevelopmentControl = field?.form?.root?.get('secondaryConstructions')?.get('combustibleFinish')?.get('finishInteriorSmokeDevelopment');
    const isFinishInteriorUnlistedControl = field?.form?.root?.get('secondaryConstructions')?.get('combustibleFinish')?.get('isFinishInteriorUnlisted'); 
    const isFinishInteriorThermalBarrierPresentControl = field?.form?.root?.get('secondaryConstructions')?.get('combustibleFinish')?.get('isFinishInteriorThermalBarrierPresent');

    finishInteriorCombustibleTypeControl.setValue(null);
    finishInteriorPercentOfStoriesAffectedControl.setValue(null);
    finishInteriorFlameSpreadControl.setValue(null);
    finishInteriorSmokeDevelopmentControl.setValue(null);
    isFinishInteriorUnlistedControl.setValue(null);
    isFinishInteriorThermalBarrierPresentControl.setValue(null);                        
  }

  resetExteriorFinishSection(field) {
    const finishExteriorCombustibleTypeControl = field?.form?.root?.get('secondaryConstructions')?.get('combustibleFinish')?.get('finishExteriorCombustibleType');
    const finishExteriorPercentOfStoriesAffectedControl = field?.form?.root?.get('secondaryConstructions')?.get('combustibleFinish')?.get('finishExteriorPercentOfStoriesAffected');
    const finishExteriorFlameSpreadControl = field?.form?.root?.get('secondaryConstructions')?.get('combustibleFinish')?.get('finishExteriorFlameSpread');
    const finishExteriorSmokeDevelopmentControl = field?.form?.root?.get('secondaryConstructions')?.get('combustibleFinish')?.get('finishExteriorSmokeDevelopment');
    const isFinishExteriorUnlistedControl = field?.form?.root?.get('secondaryConstructions')?.get('combustibleFinish')?.get('isFinishExteriorUnlisted'); 
    const isFinishExteriorThermalBarrierPresentControl = field?.form?.root?.get('secondaryConstructions')?.get('combustibleFinish')?.get('isFinishExteriorThermalBarrierPresent');

    finishExteriorCombustibleTypeControl.setValue(null);
    finishExteriorPercentOfStoriesAffectedControl.setValue(null);
    finishExteriorFlameSpreadControl.setValue(null);
    finishExteriorSmokeDevelopmentControl.setValue(null);
    isFinishExteriorUnlistedControl.setValue(null);
    isFinishExteriorThermalBarrierPresentControl.setValue(null);                        
  }

  getNearestApproximateVal(array, goal, noOfLevels) {
    var closest = array.reduce(function(prev, curr) {
      return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
    });
    let finalValue;
   if(noOfLevels<20 && Math.abs(closest-goal)<=5){
        finalValue= closest;
      }
    else if(noOfLevels< 40 && Math.abs(closest-goal)<= 2.5){
        finalValue = closest;
      }
   else if(noOfLevels< 80 && Math.abs(closest-goal)<= 1 ){
        finalValue = closest;
      }
  else{
     finalValue = null;
    }
    return finalValue;
  }


  // public addSecondaryConstruction = async (modalController: ModalController, field: FormlyFieldConfig) =>{ 
  //   const modal = await modalController.create({
  //     component: FormlySecondaryConstructionSheetsComponent,
  //     componentProps: {
  //       field: field
  //     },
  //     cssClass: 'modal-fullscreen',
  //   });
  //   modal.present();
  //   const response = await modal.onWillDismiss();
  //   if (response.role === 'view') {

  //   }
  // }
}
