import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class WaterSupplyPipeScheduleSpocSectionModel extends FormlySectionModelAbstract {
  constructor() {
    super({
      key: 'waterSupplyPipeSchedules',
      wrappers: ['simple-section-wrapper'],
      className: 'spoc-two-col-layout-container',
      props: {
        label: 'Sprinkler Protection Occupancy Class',
        linkId: SectionEnum.FR_S_WS_PIPE_SCHEDULE_SPOC,
        isCollapsable: true,
        isCollapsed: false,
        className: 'row-padding-bottom',
      },
      fieldGroupClassName: '',
      fieldGroup: [
        {
          key: 'v1WaterSupplyPipeSchedules',
          type: 'spoc-card',
          props: {
            label: 'Scopes',
            version: 'v1',
            isSpecialStdNotNeeded: true
          },
          fieldArray: {
            fieldGroup: [
              {
                key: 'waterSupplyPipeScheduleId',
              },
              {
                key: 'reportId',
              },
              {
                key: 'spocNumber',
              },
              {
                key: 'squareFeet',
              },
              {
                key: 'scopesVersion',
              },
              {
                key: 'precentage',
              },
              {
                key: 'isMostDemanding'
              }
            ]
          }
        },
        {
          key: 'v2WaterSupplyPipeSchedules',
          type: 'spoc-card',
          hide: true,
          props: {
            label: 'Scopes',
            version: 'v2',
            isSpecialStdNotNeeded: true
          },
          fieldArray: {
            fieldGroup: [
              {
                key: 'waterSupplyPipeScheduleId',
              },
              {
                key: 'reportId',
              },
              {
                key: 'spocNumber',
              },
              {
                key: 'squareFeet',
              },
              {
                key: 'scopesVersion',
              },
              {
                key: 'precentage',
              },              
              {
                key: 'isMostDemanding'
              }
            ],
          },
          expressions: {
            // hide: (field) => true
            // TODO: enable V2 here if needed
            // !field.options.formState.v2Enabled,
          },
        }
      ],
      expressions: {
        fieldGroupClassName: (field: FormlyFieldConfig) =>  field.options?.formState?.v2Enabled ? 'formly-flex-container': ''
      }
    });
  }
}
