import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Storage as IonicLocalStorage } from '@ionic/storage-angular';
import { UserPermssions } from 'src/app/models/user-permssions.model';
import { Constants, IonicStorageKeys, Resource, UserRole } from './constants';
import { NavController, Platform } from '@ionic/angular';
import { LoadingService } from '../loading-service/loading.service';
import { environment } from 'src/environments/environment';
import { User } from 'src/models';
import { DataStore } from 'aws-amplify';
import { AuthHttpService } from 'src/app/auth/auth-http.service';


@Injectable({ providedIn: 'root' })
export class UserRolesService {

  userPermssions: UserPermssions = new UserPermssions();
  private appTabsSubject = new BehaviorSubject<any[]>([]);
  appTabs$: Observable<any[]> = this.appTabsSubject.asObservable();

  private checkUserRole$ = new BehaviorSubject<string[]>([]);
  checkUserRoleObservable = this.checkUserRole$.asObservable();
  constructor(private http: HttpClient, 
     private ionicLocalStorage: IonicLocalStorage,
      private navCtrl: NavController,
       private loadingService: LoadingService,
       private httpService: AuthHttpService,
      private platform: Platform) { }

  getUserPermissions() {
    return this.http.get<any>('assets/json/user-roles.json').toPromise();
  }

  async getUserRolesWithPermssions(frId: string) { 
    this.ionicLocalStorage.get(IonicStorageKeys.USER_PERMISSIONS).then((user) => {
      if (!user || !user.LastModifiedDate || user.LastModifiedDate.getDate() != new Date().getDate()) {
        this.loadingService.showLoading(Constants.pleaseWaitMessage);
        this.getUserConfig(frId).then((response: any) => {
          response.LastModifiedDate = new Date();
          this.ionicLocalStorage.set(IonicStorageKeys.USER_PERMISSIONS, response);
          this.userPermssions = response;
          this.loadingService.dismissLoading();
          this.restrictFRUserWebAccess(frId);
          this.getAppTabs();         
        }).catch((error) => {
          this.navCtrl.navigateRoot('no-access');
          this.loadingService.dismissLoading();
          console.log('Error fetching data', error);
        });
      }
      else {
        this.userPermssions = user;
        this.restrictFRUserWebAccess(frId);
        this.getAppTabs();      
      }
    });   
  }

  async restrictFRUserWebAccess(frId: string){
    if (!this.platform.is('capacitor')) {
      const allUsers = await DataStore.query(User);

      const isFrBureau = this.userPermssions.Roles.some(role => role.Name === UserRole.FR_Bureau);
      let amplifyUser: User = allUsers.find((item) => item.userId === frId);
      if (environment.users) {
        const user = environment.users.find((item) => item === frId);
        if (user || isFrBureau) {
           this.navCtrl.navigateRoot('home');
        } else {
          this.verifyUserRolesFromAmplify(amplifyUser);
        }
      }
    } 
  }
  verifyUserRolesFromAmplify(amplifyUser) {
    if (amplifyUser.data && amplifyUser.data['Roles'].length >= 1) {
      const isFieldRep = amplifyUser.data['Roles'].some((element) => element.Name === UserRole.FR);     
      
      if (isFieldRep) {
        this.navCtrl.navigateRoot('no-access');
      } 
    } else {
       this.navCtrl.navigateRoot('home');
    }
  }

  getUserConfig(frId: string) {
    return this.httpService.request('GET', environment.iSurveyAPIurl + Constants.userConfig + '?userId=' + frId);
  }

  canResourceViewable(resource: string) {
    if(!environment.isBureauState) return true;
    var canView = this.userPermssions.Permissions.some(permission => permission.Resource === resource) ? this.userPermssions.Permissions.some(permission => permission.Resource === resource && permission.CanView) : true;
    return canView;
  }

  getAppTabs() {
    var appTabs = this.canResourceViewable(Resource.EXTERIOR_SURVEY) ? Constants.appTabs : Constants.appTabs.filter(tab => tab.url !== 'exterior-survey');
    this.appTabsSubject.next(appTabs);
  }

  async checkUserRoles(frId: string) {
    let shouldBeDisabled = true;
    const users = await this.getUserPermissions();

    if (users[frId]?.permissions) {
      console.log('in if');
      this.checkUserRole$.next(users[frId]?.permissions);
    } else {
      console.log('in else');
      this.checkUserRole$.next([]);
    }
  }
}
