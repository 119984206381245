import { Injectable } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject } from 'rxjs';
import {
  FormlyGenericSheetComponent
} from 'src/app/components/formly-builder/templates/core/formly-generic-sheet/formly-generic-sheet.component';
import { cloneDeep } from 'lodash';
import { FormlyBuilderService } from './formly-builder.service';

export interface ActionButton {
  section: string;
  action: string;
  flag: boolean;
  counter: number;
  isDisabled?: boolean;
  data?: any;
  field?: FieldTypeConfig;
}

@Injectable({
  providedIn: 'root',
})
export class FormlyWrapperService {
  private dataSource = new BehaviorSubject(undefined);
  private actionIconsDataSource = new BehaviorSubject(undefined);
  private closeSwipeDataSource = new BehaviorSubject(undefined);
  public triggeredActionButton$ = this.dataSource.asObservable();
  public triggeredActionIcon$ = this.actionIconsDataSource.asObservable();
  public closeSwipeAction$ = this.closeSwipeDataSource.asObservable();
  public counter: number;

  constructor(private modalCtrl: ModalController,
    private formlyBuilder: FormlyBuilderService,
    private toastController: ToastController) {
    this.counter = 1;
  }

  triggerActionButton(section, action, flag, data?: any) {
    const actionButton: ActionButton = {
      section,
      action,
      flag,
      counter: this.counter++,
      data,
    };
    this.dataSource.next(actionButton);
  }

  triggerActionIcon(sectionName, actionName, triggerFlag, field?) {
    const actionButton: ActionButton = {
      section: sectionName,
      action: actionName,
      flag: triggerFlag,
      counter: this.counter++,
      field,
    };
    this.actionIconsDataSource.next(actionButton);
  }

  closeSwipeGesture(value: boolean) {
    this.closeSwipeDataSource.next(value);
  }

  /**
   * Show a sheet with the fieldGroup fields...
   *
   * @deprecated Use onOpen instead
   * @param modalController
   * @param field
   * @returns
   */
  async showFormlySheet(modalCtrl: ModalController, field: any) {
    // Save the model state in a temporary store (Map - name of the model)
    FormlyWrapperService.onSaveState(field);

    // Generic logic to mark as touched/untouched
    // this.onSetTouchedState(field);

    // Show the sheet
    const modal = await modalCtrl.create({
      component: FormlyGenericSheetComponent,
      componentProps: {
        field
      },
      cssClass: 'modal-fullscreen',
      handle: false,
    });

    modal.present();
    return modal;
  }

  /**
   * Show a sheet with the fieldGroup fields...
   *
   * @param modalCtrl
   * @param field
   * @returns
   */
  async onOpen(modalCtrl: ModalController, field: any) {
    return this.showFormlySheet(modalCtrl, field);
  }

  /**
   * Open a sheet. modal controller to use and field will be obtained automatically
   *
   * @param path string[] Array of keys to locate the branch we want
   */
  async onOpenSheet(path: string[]) {
    if (path?.length === 0) {
      return;
    }

    // Check for all empty / undefined /null branches
    path.forEach((branch) => {
      if (branch === undefined || branch === null || branch === '') {
        return;
      }
    });

    try {
      let field = this.formlyBuilder?.fields[ 0 ];
      path.forEach((branch) => {
        field = field.get(branch);
      });
      this.showFormlySheet(this.modalCtrl, field);
    } catch (ignore) { }
  }

  // Total area warning message
  async showToasterMessage(message) {
    const toast = await this.toastController.create({
      message: `<span part='img' class='icon'><img class='icon' src="assets/icon/warning-i-icon.png"/></span><ion-icon class='icon-large'></ion-icon><div class='message'>${message}</div>`,
      position: 'bottom',
      cssClass: [ 'gold-toaster full-width-toaster warning-toaster warning-toast-style' ],
      // color: 'warning',
      buttons: [
        {
          text: '\u2573',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          }
        }
      ],
    });

    await toast.present();
  }

  /**
   *
   * @param field
   */
  private static onSaveState(field: FormlyFieldConfig) {
    // Check if store exists
    if (!field?.options?.formState?.initialStateStore) {
      const initialStateStore: Map<string, any> = new Map();
      if (field?.options?.formState) {
        field.options.formState.initialStateStore = initialStateStore;
      }
    }

    const branches: string[] = FormlyWrapperService.getFullKeyArray(field);
    const branchModelName: string = FormlyWrapperService.getFullyQualifiedKey(branches);

    // Lodash deep clone model data
    if (field?.model) {
      const modelData = cloneDeep(field.model);        //  ???? field.formControl.value ????
      field?.options?.formState?.initialStateStore?.set(branchModelName, modelData);
    }
  }

  /**
   *
   * @param field
   * @returns Fully qualified key name including parents (example: 'greatparent.parent.children')
   */
  public static getFullKeyArray(field: FormlyFieldConfig): string[] {
    const branches: string[] = [];
    let currentField = field;

    while (currentField) {
      if (currentField.key) {
        branches.push(currentField.key as string);
      }
      currentField = currentField.parent;
    }
    branches.reverse();
    return branches;
  }

  /**
   *
   * @param branches Array of branches ordered from root to branch
   * @returns Fully qualified key name including parents (example: 'greatparent.parent.children')
   */
  public static getFullyQualifiedKey(branches: string[]): string {
    let fullName = '';
    branches.forEach((branch) => {
      fullName += branch;
      fullName += '.';
    });

    // Strip the last period
    if (fullName.length > 0) {
      fullName = fullName.substring(0, fullName.length - 1);
    }

    return fullName;
  }

  /**
   *
   * @param field
   */
  private static onRestoreState(field: FormlyFieldConfig): void {
    const branches: string[] = FormlyWrapperService.getFullKeyArray(field);
    const branchModelName: string = FormlyWrapperService.getFullyQualifiedKey(branches);
    const initialStoredState = field?.options?.formState?.initialStateStore?.get(branchModelName);
    const formlyService: FormlyBuilderService = field?.options?.formState?.service as FormlyBuilderService;

    // Update the model branch
    if (formlyService?.riskReport?.model) {
      const keyArray = FormlyWrapperService.getFullKeyArray(field);
      let specificModel = formlyService.riskReport.model;     // Root

      // Check last element to make sure it's the current key
      if (keyArray?.length > 0) {
        const currentKey = keyArray[ keyArray.length - 1 ];
        if (currentKey === field.key) {
          // Descend the model tree to only affect the relevant model branch
          keyArray.forEach((branch) => {
            specificModel = specificModel[ branch ];
          });


          // Option 1: "Restore ONLY the relevant submodel branch"
          // Descend to the parentBranch
          // submodel = { ...submodel, ...{ lastBranch: initialStoredState } }

          // Option 1.1:
          // Update with initial state
          if (initialStoredState === null) {
            specificModel = null;
          } else {
            // Replace the windReport: environmentAndExposures: { proxmlty: '15GT' }
            // Open questions:
            // 1. Will this anyway launch a lot of repeated validations?
            // 2. Will this awake the Angular Change Detection mechanism? PROBABLY NOT A PROBLEM.
            // 3. The spread operator does now work if the right side is null
            specificModel = { ...specificModel, ...initialStoredState };
          }

          // Option 2: Reload the state from the Risk Report that we have stored in the formState
          // const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
          // const reportData = formlyService.riskReport.report;
          // // Open the field...parent....options... and search the appropriate service to load data for this specific key branch (windReport.environmentAndExp...)
          // const newModel = formlyService.windReportService.loadData(reportData, reportData, true, formlyService.riskReport.model);
          // if (newModel) {
          //   // This will launch a lot of validations!!!
          //   // But: modifying the complete model WILL SPAWN THE ANGULAR CHANGE DETECTION
          //   formlyService.riskReport.model = { ...newModel };
          // }

          // Option 3. Formly resetModel

          console.log('Check if model was saved.');
        }
      }
    }
  }

  /**
   * Restore values and close
   *
   * @param modalCtrl
   * @param field
   * @param initialModel
   */
  public static onClose(modalCtrl: ModalController, field, initialModel) {
    FormlyWrapperService.onRestoreState(field);
    field.options.formState.service.errorService.closePreviousToaster();
    modalCtrl.dismiss();
  }

  private static onSetTouchedState(field: FormlyFieldConfig) {
    // 1. Check if the model for this field.fieldGroup is "empty"/"hasValues"
    // 2. field.fieldGroup. .... formControl.markAll
    //  2.1 If it's empy mark ALL untouched
    //        for loop
    //  2.2 If it's NOT EMPTY: mark ALL touched
  }

  /**
   * TODO: Standard one: just save the current branch (updateData)
   *
   * @returns
   */
  public static onApply() {
    return;
  }

  /**
   * @deprecated Please move to relevant section service.
   */
  async applyServiceExampleMethod() {
    // TODO: For testing purposes. Delete!
    console.log('Example sheet action. Please move to relevant section service.');
  }

  /**
   * Set selected radio button value and reset value of other radio buttons
   *
   * @param field
   * @param radioGroupNames
   * @param fieldToClear  optional
   */
  public changeRadioButtonGroup(field: FormlyFieldConfig, radioGroupNames: any[], fieldToClear?: string[]) {
    if (radioGroupNames !== null && radioGroupNames && radioGroupNames.length > 0) {
      radioGroupNames.forEach((item) => {
        field?.form?.get(item.formName)?.setValue(item.value);
        field?.form?.get(item.formName)?.setValue(item.value);
      });

      if (fieldToClear !== null && fieldToClear && fieldToClear.length > 0) {
        fieldToClear.forEach((item) => {
          field?.form?.get(item)?.setValue(null);
        });
      }
    }
  }

  /**
   * to format the entered value into proper decimal
   *
   * @param field
   * @param decimalFormatType
   */
  public formatDecimal(field: FormlyFieldConfig, decimalFormatType: string, decimalPoint: string) {
    if (!field?.formControl?.value || isNaN(parseFloat(field?.formControl?.value))) {
      field?.formControl?.patchValue(null);
    }
    if (field?.formControl?.value && field?.formControl?.value !== null) {
      if (decimalFormatType == 'truncate') {
        let truncatedVal = field?.formControl?.value.replace(/^0+(?=\d)/, '');
        if (truncatedVal) {
          const decimalIndex = truncatedVal.indexOf('.');
          if (decimalIndex !== -1) {
            truncatedVal = truncatedVal.slice(0, decimalIndex + parseInt(decimalPoint) + 1);
            truncatedVal = truncatedVal.charAt(0) === '.' ? '0' + truncatedVal : truncatedVal;
            const splitVal = truncatedVal.split('.');
            if (splitVal[ 1 ] == '' || splitVal[ 1 ] == '0' || splitVal[ 1 ] == '00') {
              truncatedVal = splitVal[ 0 ];
            }
          }
          // truncatedVal = truncatedVal.replace(/^0+(?=[1-9]|\.)/, '').replace(/(\.0+|(\.\d+?)0+)$/, '$2');
          truncatedVal = truncatedVal.charAt(0) === '.' ? '0' + truncatedVal : truncatedVal;
        }        
        field?.formControl?.patchValue(truncatedVal);
      }
      if (decimalFormatType == 'round') {
        //format value to 2 decimal precision and rounds the decimal precision value
        const formattedValue = parseFloat(field?.formControl?.value)?.toFixed(parseInt(decimalPoint))?.replace(/(\.[0-9]*[1-9])0+$/, '$1');
        field?.formControl?.patchValue(formattedValue?.replace(/\.?0*$/, ''));
      }


    }
  }

  /**
   * to scroll the UI to bottom
   *
   * @param field
   * @param className
   */
  public scrollToBottom(fieldId: any) {
    setTimeout(() => {
      if (document.getElementById(fieldId)) {
        document.getElementById(fieldId)?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest'
        });
      }
    }, 100);
  }
}
