import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-viewing-photo',
  templateUrl: './viewing-photo.component.html',
  styleUrls: ['./viewing-photo.component.scss'],
})
export class ViewingPhotoComponent implements OnInit {
  @Input() image;
  constructor(public modalController: ModalController) {}

  ngOnInit() {}

  close() {
    this.modalController.dismiss();
  }

  onSwipeDown() {
    this.close();
  }
}
