import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { FormControl } from '@angular/forms';
import { Constants } from 'src/app/services/util-service/constants';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Subject } from 'rxjs';
export class MiscellaneousSecondaryConstructionModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      wrappers: ['simple-section-wrapper'],
      key: 'miscellaneousSecondaryConstruction',
      props: {
        label: 'Miscellaneous',
        className: 'padding-bottom',
        isCollapsable: true,
        shallowErrors: false,
        required: false
      },
      className: 'miscellaneous-secondary-construction-container',
      fieldGroup: [
        {
          key: 'buildingConditionComment',
          props: {
            label: 'Building Condition Comment',
          },
          expressions: {
            'props.required': (field: FormlyFieldConfig) => field?.model?.buildingConditionType !== 'NONE' && field?.model?.buildingConditionType !== 'Null' && field?.model?.buildingConditionType !== null && field?.model?.buildingConditionType !== '' && field?.model?.buildingConditionType !== undefined,
          }
        },
        {
          wrappers: ['question-wrapper'],
          key: 'buildingConditionType',
          id: 'buildingConditionType',
          name: 'buildingConditionType',
          type: 'simple-select',
          className: 'select-full-width building-condition-field',
          defaultValue: null,
          expressions: {
            'props.isDisabled': (field: FormlyFieldConfig) => {
              if (field?.model?.buildingConditionType === 'Null' || field?.model?.buildingConditionType === 'NONE' || field?.model?.buildingConditionType === null) {
                field.props.isSwipeGesture = false;
              }
              else {
                field.props.isSwipeGesture = true;
              }
            },
          },
          props: {
            label: 'Building condition',
            firstColSize: 6,
            isLabelDisabled: true,
            isSwipeGesture: true,
            isCommentActionEnabled: true,
            commentActionKey: 'buildingConditionComment',
            options: [
              { value: 'NONE', label: 'None' },
              { value: 'MODE', label: 'Moderate' },
              { value: 'MAJO', label: 'Major' },
              { value: 'SEVE', label: 'Severe' }
            ],
          },
          hooks: {
            onInit: (field) => {
              field.formControl.markAsTouched();
            },
          },
        },
        {
          fieldGroupClassName: 'formly-flex-container',
          className: 'card-view-miscellaneous-secondary-construction',
          fieldGroup: [
            {
              key: 'combustibleExteriorAttachmentType',
              type: 'simple-select',
              props: {
                label: 'Combustible Exterior attachments',
                required: false,
                options: [
                  { value: null , label: '' },
                  { value: 'OPEN', label: 'Open' },
                  { value: 'CLOS', label: 'Enclosed' }
                ],
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => field?.options?.formState?.service._isV2,
                'props.isDisabled': (field: FormlyFieldConfig) => {
                  if(field?.formControl?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('constructionClass')?.value == 1){
                    field?.formControl?.setValue(null,{emitEvent:false});
                    return true;
                  }
                  else{
                    return false;
                  }
                },

              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  field.formControl.markAsTouched();
                  field?.formControl?.valueChanges.subscribe((value)=> {
                    if (value === 'Null' || value === null || value === '') {
                      field?.form?.get('isCombustibleExteriorAttachmentSprinklered')?.setValue(false,{emitEvent:false});
                      field?.form?.get('combustibleExteriorAttachmentArea')?.setValue(null,{emitEvent:false});
                    }
                    else if (value && value !== 'Null'|| value !== null || value !== '') {
                      field?.form?.controls['combustibleExteriorAttachmentArea']?.updateValueAndValidity();
                    }
                  }
                  );
                }
              },
              defaultValue: null,
              validators: {
                areaValidator: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const attachmentArea = Number(field?.form?.get('combustibleExteriorAttachmentArea')?.value);
                    if (control.value !== 'Null' || control.value !== null) {
                      return true;
                    }
                    else if (attachmentArea !== null && attachmentArea !== undefined && attachmentArea > 0) {
                      return false;
                    }
                    else {
                      return true;
                    }
                  },
                  message: 'Select Type if Combustible Attachment Area is entered.'
                },
                constructionClassValidator: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const constructionClass = field?.formControl?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('constructionClass')?.value;
                    if (constructionClass === '1' && (control.value!== null && control.value !== '' && control.value !== undefined)) {
                      return false;
                    }
                    else {
                      return true;
                    }
                  },
                  message: 'Not applicable in Construction Class 1 buildings.'
                },
              },

              className: 'field1-miscellaneous-secondary-construction',
            },
            {
              key: 'isCombustibleExteriorAttachmentSprinklered',
              type: 'custom-checkbox',
              props: {
                label: 'Sprinklers',
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => field?.options?.formState?.service._isV2,
                'props.isDisabled': (field: FormlyFieldConfig) => {
                  if(field?.model?.combustibleExteriorAttachmentType === null || field?.model?.combustibleExteriorAttachmentType === ''){
                    field?.formControl?.setValue(null,{emitEvent:false});
                    return true;
                  }
                  else {
                    return false;
                  }
                },
              },
              hooks: {
                onInit: (field) => {
                  field.formControl.markAsTouched();
                },
              },
              className: 'field2-miscellaneous-secondary-construction',
            },
            {
              key: 'combustibleExteriorAttachmentArea',
              type: 'simple-input',
              props: {
                label: 'Attachment Area',
                unit: 'sq.ft.',
                required: false,
                type: 'number',
                inputmode: 'tel',
                isAsteriskNotRequired: true,
                readonly: false
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => (field?.options?.formState?.service._isV2),
                'props.readonly': (field: FormlyFieldConfig) => {
                  if(field?.form?.get('combustibleExteriorAttachmentType')?.value === '' || field?.form?.get('combustibleExteriorAttachmentType')?.value === null){
                    field?.formControl?.setValue(null,{emitEvent:false})
                    return true;
                  }
                  else {
                    return false;
                  }
                },
              },
              hooks: {
                onInit: (field) => {
                  field.formControl.markAsTouched();
                  field?.formControl?.valueChanges.pipe(
                    // debounceTime(5000),
                    distinctUntilChanged(),
                    tap(value => {
                     if (value && value !== null) {
                      field?.form?.controls['combustibleExteriorAttachmentType']?.updateValueAndValidity();
                    }
                  }
                  ));
                },
              },
              className: 'field3-miscellaneous-secondary-construction',
              validators: {
                areaValidator: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {

                    if ((control.value === null || control.value === undefined || control.value === '') && (field?.form?.get('combustibleExteriorAttachmentType')?.value !== 'Null' && field?.form?.get('combustibleExteriorAttachmentType')?.value !== null && field?.form?.get('combustibleExteriorAttachmentType')?.value !== '' && field?.form?.get('combustibleExteriorAttachmentType')?.value !== undefined)) {

                      return false;
                    }
                    else {
                      return true;
                    }

                  },
                  message: 'Enter area.'
                },
                enclosedAreaValidator1: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    if (field?.form?.get('combustibleExteriorAttachmentType')?.value === 'Enclosed' && control.value !== null && control.value !== undefined && control.value !== '') {
                      if (Number(control.value) > 1000) {
                        return false;
                      }
                      else {
                        return true;
                      }
                    }
                    else {
                      return true;
                    }
                  },
                  message: "Enclosed Attachments that exceed 1,000 sq. ft. should be included in Basic Construction."
                },
                enclosedAreaValidator2: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const totalArea = (field?.formControl?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('totalArea')?.value) / 10;
                    if (field?.form?.get('combustibleExteriorAttachmentType')?.value === 'Enclosed' && control.value !== null && control.value !== undefined && control.value !== '') {
                      if (Number(control.value) > totalArea) {
                        return false;
                      }
                      else {
                        return true;
                      }
                    }
                    else {
                      return true;
                    }
                  },
                  message: "Enclosed Attachments that exceed 10% of the total building area should be included in Basic Construction"
                },
                enclosedAreaValidator3: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const totalArea = (field?.formControl?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('totalArea')?.value) / 2;
                    if (field?.form?.get('combustibleExteriorAttachmentType')?.value === 'Open' && control.value !== null && control.value !== undefined && control.value !== '') {
                      if (Number(control.value) > totalArea) {
                        return false;
                      }
                      else {
                        return true;
                      }
                    }
                    else {
                      return true;
                    }
                  },
                  message: "Open Attachments that exceed 50% of the total building area should be included in Basic Construction"
                }
              }
            },
            {
              type: 'rf-aerial',     // TODO: Fix: it messes the design
              className: 'aerial-icon-style',
              props: {
                areaKey: 'combustibleExteriorAttachmentArea',
                oneKey: ''
              }
            },             
          ],
        },
      ],
      hooks: {
        onInit: (field?: FormlyFieldConfig) =>{
          field?.formControl?.valueChanges.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(),
          debounceTime(Constants.riskFormDataSyncDelay))
            .subscribe(value => {
              if (!field.formControl.pristine) {
              formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_SECONDARY_CONSTRUCTION, value, true);
              }
            });
          },
          onDestroy: () => {
            this.ngUnsubscribe.next();
            this.ngUnsubscribe.complete();
          }
      },
    }
    );
  }
}
