import { RiskReportDataOccupants } from 'src/app/models/risk-report/risk-report-data.model';
import { OccupantLevelDataModel } from './occupant-level-data.model';
import { OccupantsDataModel } from './occupants-data.model';
import { OccupantsHazardsDataModel } from './full-risk/occupant-hazards/occupant-hazards.data.model';

/**
 * Class that represents the Formly Model for Occupants
 */
export class OccupantsSectionDataModel {
  static sectionName = 'section-occupants';

  // New data to be collected from existing RiskReport
  aboveGradeFloors: number;
  basementLevels: number;
  totalArea: number;
  ReportIdentifier?: string;    // It's read from the S3 and kept

  // Array of occupants
  occupants: any[];   

  static from(alias: RiskReportDataOccupants): OccupantsSectionDataModel {
    return {
      aboveGradeFloors: 0,
      basementLevels: 0,
      totalArea: 0,
      occupants: []
    };
  }

  static fromS3(alias: any): OccupantsSectionDataModel {
    let levelArray: any[] = [];
    let totalArea = 0;
    let aboveGradeFloors = 0;
    let basementLevels = 0;
    if (alias.OccupantLevels && alias.OccupantLevels.length) {
      alias.OccupantLevels.forEach((level) => {
        const levelData = OccupantLevelDataModel.fromS3(level);
        levelArray.push(levelData);

        // Calculations
        totalArea += levelData.area;
        if (level.OccupantLevelType === `FLOO`) {
          aboveGradeFloors++;
        } else if (level.OccupantLevelType === `BASE`) {
          basementLevels++;
        }
      });
    }
    
    return {
      aboveGradeFloors: aboveGradeFloors,
      basementLevels: basementLevels,
      totalArea: totalArea,
      occupants: levelArray
    };
  }

  /**
   * Returns a S3 RiskReport object from a Formly model
   */
  static to(model: any) {
    const occupantRrModel = [];
    model.occupants.forEach((occupant) => {
      const occupantData = OccupantsDataModel.to(occupant);
      occupantRrModel.push(occupantData);
    });
    return { Occupants: occupantRrModel };
  }
   /**
   * Returns a S3 RiskReport object from a Formly model
   */
   static saveDatatoS3(model: any) {
    const occupantRrModel = [];
    model.occupants.forEach((occupant) => {
      const occupantData = OccupantsHazardsDataModel.to(occupant);
      occupantRrModel.push(occupantData);
    });
    return { Occupants: occupantRrModel };
  }


    /**
   * Check if the model has changed
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   * @returns 
   */
    static hasChanged(formlyModel: any, riskReportModel: any): boolean {
      return true;
    }
}
