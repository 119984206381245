import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { MainDrainEstimationInputExcelModel, MainDrainEstimationModel, MainDrainEstimationOutputModel, MainDrainEstimationTrial } from 'src/app/models/formly-builder/main-drain-estimation.model';
import { FormlyErrorService } from '../../formly-error.service';
import { UtilService } from 'src/app/services/util-service/util.service';
import { Constants } from 'src/app/services/util-service/constants';

@Injectable({
  providedIn: 'root',
})
export class SystemDesignCalculateService {
  
  constructor(private errorService: FormlyErrorService, private utilService: UtilService) { }

  onCalculate(modalCtrl, field) {
    const formlyService = field?.options?.formState?.service;
    formlyService?.waterSupplyService?.setSystemDesignCalculateValues(field); 
    field?.form?.get('nonNumericDesign')?.markAsUntouched();     
    field?.options?.formState?.service?.errorService?.closePreviousToaster();
    modalCtrl?.dismiss();
  }
 
  onClose(modalCtrl, field, initialModel) {
    field?.formControl?.patchValue(initialModel);
    field?.form?.get('nonNumericDesign')?.markAsUntouched();
    field?.options?.formState?.service?.errorService?.closePreviousToaster();
    modalCtrl?.dismiss();
  }

}