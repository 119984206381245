import { Camera, CameraResultType, CameraSource, GalleryPhotos, ImageOptions, Photo } from '@capacitor/camera';
import { Storage } from 'aws-amplify';
import { Inject, Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, Subject, forkJoin, of } from 'rxjs';
import { ENV, Environment } from '../../../interfaces/env.interface';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { OrderPhotosModel } from 'src/app/models/order/order-photos.model';
import { Guid } from 'guid-typescript';
import { Directory, Filesystem, ReadFileResult } from '@capacitor/filesystem';
import { VeriskLog } from '../../util-service/verisk-log';
import { UtilService } from '../../util-service/util.service';
import { OrdersService } from '../orders.service';
import { Constants } from '../../util-service/constants';
import { RiskPhotoOptionsEnum } from 'src/app/enums/risk-photo-options.enum';
import { LocalStorageService } from '../../local-storage/local-storage.service';
import { AssetType, ConfigModel } from 'src/app/enums/config-model.enum';
import { QueueService } from '../../queue/queue.service';

@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  private photoSubject = new Subject<any>();
  public imageLoadedForRisk = new BehaviorSubject<any>(false);
  private imageDeletedForRisk = new BehaviorSubject<boolean>(false);
  public imageCapturedData: any;
  public imageInfo: any;
  private orderservice;
  private localStorageService;
  public selectedPhotoDetails;
  public completeBtnSubject = new BehaviorSubject<boolean>(true);
  public completeBtnStatus = this.completeBtnSubject.asObservable();
  public validCRPhotos: boolean = false;
  public validRiskPhotos: boolean = false;
  private queueService;

  constructor(
    @Inject(ENV) private environment: Environment,
    private http: HttpClient,
    private injector: Injector,
    private utilService: UtilService
  ) {}

  photoList = (): Observable<any> => {
    this.loadPhotos();
    return this.photoSubject.asObservable();
  };

  private loadPhotos = async (path = '') => {
    const list = await Storage.list(path);
    this.photoSubject.next(list);
  };

  getPhoto = (photoKey: string): Promise<any> => Storage.get(photoKey);

  async useCamera(cameraOverides = null, storagePath: string = null): Promise<Photo | { photo: Photo; key: any }> {
    return new Promise((resolve, reject) => {
      const cameraConfig: ImageOptions = {
        resultType: CameraResultType.DataUrl,
        source: cameraOverides?.source || CameraSource.Camera,
        quality: cameraOverides?.quality || this.environment.cameraDefaults.quality,
        width: cameraOverides?.width || this.environment.cameraDefaults.width,
        height: cameraOverides?.height || this.environment.cameraDefaults.height,
        saveToGallery: true, // Attempt to save photo to Gallery
      };
      // Take a photo
      Camera.getPhoto(cameraConfig).then(
        (photo: Photo) => {
          if (this.validPhotoFile(photo)) {
            if (storagePath) {
              Storage.put(storagePath, this.utilService.dataURItoBlob(photo.dataUrl), {
                useAccelerateEndpoint: true,
                contentType: `image/${photo.format}`,
                level: 'public',
              }).then(
                (key: any) => resolve({ photo: photo, key: key }),
                (error) => reject(error)
              );
            } else {
              resolve(photo);
            }
          } else {
            this.utilService.showAlert(Constants.photoValidation, '','');
          }
        },
        (error) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  addphotos(cameraOverides): Promise<GalleryPhotos> {
    return new Promise((resolve, reject) => {
      const cameraConfig: ImageOptions = {
        resultType: CameraResultType.DataUrl,
        source: cameraOverides?.source || CameraSource.Camera,
        quality: cameraOverides?.quality || this.environment.cameraDefaults.quality,
        width: cameraOverides?.width || this.environment.cameraDefaults.width,
        height: cameraOverides?.height || this.environment.cameraDefaults.height,
      };
      Camera.pickImages(cameraConfig).then(
        (val) => {
          const jpegImages = val.photos.filter((photo) => Constants.photosValidFileExtension.includes(photo.format));
          const otherImages = val.photos.filter((photo) => !Constants.photosValidFileExtension.includes(photo.format));
          if (otherImages.length > 0) {
            this.utilService.showAlert(Constants.photoValidation, '','');
          }

          if (jpegImages.length > 0) {
            resolve({ photos: jpegImages });
          }
        },
        (error) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  getPhotoSelectAction() {
    return this.http.get<any[]>('assets/json/collections/selected-photos-action.json').pipe(
      map((reasons) => {
        return reasons.map((option) => {
          return {
            text: option.label,
            role: option.role,
            data: {
              action: option.value,
            },
          };
        });
      })
    );
  }

  async getOrderPhotoList(selectedOrder, includeInCR): Promise<any> {
    return new Promise((resolve, reject) => {
      this.localStorageService = this.injector.get(LocalStorageService);

      let orderPhotoInfo;
      if (includeInCR) {
        orderPhotoInfo = selectedOrder.Photos.filter((item) => item.IncludeInCustomerReport === true);
      } else {
        orderPhotoInfo = selectedOrder.Photos;
      }

      const fullOrderNumber = selectedOrder.OrderIdFullOrderNumber;

      const observables = orderPhotoInfo.map((item) =>
        this.localStorageService.getLocalPhotoByIdentifier('Order', fullOrderNumber, item.Id).pipe(
          catchError((error) => {
            return of(null);
          })
        )
      );

      forkJoin(observables).subscribe(
        (results: ReadFileResult[]) => {
          const photosList = results
            .map((result, index) => {
              const item = orderPhotoInfo[index];
              if (result) {
                const photo64 = result.data ?? '';
                return this.setPhotoObject(item, selectedOrder.OrderId, photo64);
              }

              if (result == null){
                return this.setPhotoObject(item, selectedOrder.OrderId, null);
              }
              return null;
            });
            //.filter((photo) => photo !== null);

          this.validationForCRPhotos(selectedOrder, true);

          resolve(photosList);
        },
        (error) => {
          console.error('Error', error);
        }
      );
      if (!orderPhotoInfo.length && includeInCR) {
        resolve([]);
        this.validationForCRPhotos(selectedOrder, true);
      }
      this.validateCompleteForPhotos();
    });
  }

  setPhotoObject(item, orderId, photo) {
    let data = {
      OrderId: orderId,
      Picture: photo ? 'data:image/jpeg;base64,' + photo : '',
      Id: item.Id,
      SortOrder: item.SortOrder,
      Description: item.Description,
      FileName: item.Id + '.jpeg',
      PhotoGroup: Constants.photoGroup,
      Tag: '',
      PhotoType: item.PhotoType,
      IncludeInCustomerReport: item.IncludeInCustomerReport,
      ReportId: item.ReportId,
      IncludeInCustomerRecommendation: item.IncludeInCustomerRecommendation ?? false,
    };
    return data;
  }
  //update OrderPhoto model//

  async updateLocalPhotofile(localpath: string, photo: Photo, targetOrderImage): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Filesystem.writeFile({
        path: localpath,
        data: photo.dataUrl,
        directory: Directory.Documents,
        recursive: true,
      }).then(
        () => {
          resolve(true);
        },
        (e) => {
          this.delete_local_photos(targetOrderImage.Id);
          let veriskLog = new VeriskLog(
            'Filesystem write ERROR',
            'ERROR',
            this.updateLocalPhotofile.name,
            PhotoService.name,
            e
          );
          this.utilService.addLog(veriskLog);
          resolve(false);
        }
      );
    });
  }

  deleteLocalAsset(localFilePath: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const rfo: any = {};
        rfo.directory = Directory.Documents;
        rfo.path = localFilePath;
        await Filesystem.deleteFile(rfo);
        resolve(true);
      } catch (e) {
        let veriskLog = new VeriskLog(
          'Filesystem deleteFile ERROR',
          'ERROR',
          this.deleteLocalAsset.name,
          PhotoService.name,
          e
        );
        this.utilService.addLog(veriskLog);
        reject(e);
      }
    });
  }
  /**
   * Save Image process
   *
   * @param imageData
   * @param targetImageInput
   * @param option
   * @returns
   */
  async saveImage(imageData, targetOrderImageInput: OrderPhotosModel, Option, fullOrderNumber) {
    let blob = imageData?.webPath;

    return new Promise(async (resolve, reject) => {
      const targetOrderImage = targetOrderImageInput;
      this.http.get(blob, { responseType: 'blob' }).subscribe(async (res: any) => {
        this.utilService.convertBlobToBase64(res).then(async (photo: any) => {
          let dataObj: Photo = {
            format: imageData.format,
            dataUrl: photo,
            saved: true,
          };
          if (targetOrderImage && targetOrderImage.OrderId) {
            targetOrderImage.PhotoType = Option;
            let path = `${'Order'}/${fullOrderNumber}/${'photos'}/${targetOrderImage.Id}${'.jpeg'}`;
            await this.updateLocalPhotofile(path, dataObj,targetOrderImage).then(
              () => {
                resolve(true);
              },
              () => {
                reject();
              }
            );
          } else {
            console.warn('No Id; cannot save Photo');
          }
        });
      });
    });
  }

  getPhotoIds(photos) {
    let photosList = [];
    photos.forEach(async (item) => {
      photosList.push({
        PhotoId: item.Id,
        ReportId: item.ReportId,
      });
    });
    return photosList;
  }

  //Returns Only Order photos without risk photos
  getFilteredPhotosForOrder(selectedOrder) {
    this.localStorageService = this.injector.get(LocalStorageService);

    const orderPhotoInfo = selectedOrder.Photos;
    let photosList = [];
    const fullOrderNumber = selectedOrder.OrderIdFullOrderNumber;

    let photo64;
    orderPhotoInfo.forEach(async (item) => {
      if (
        item.PhotoType !== RiskPhotoOptionsEnum.FRNT &&
        item.PhotoType !== RiskPhotoOptionsEnum.REAR &&
        item.PhotoType !== RiskPhotoOptionsEnum.ROOF
      ) {
        await this.localStorageService.getLocalPhotoByIdentifier('Order', fullOrderNumber, item.Id).subscribe(
          (result: ReadFileResult) => {
            photo64 = result.data;

            let data = this.setPhotoObject(item, selectedOrder.OrderId, photo64);
            photosList.push(data);
          },
          (e) => {
            console.log('Error', e);
          }
        );
      }
    });

    return photosList;
  }

  /**
   * Save Image process
   *
   * @param imageData
   * @param targetImageInput
   * @param option
   * @returns
   */
  async saveImageForGallery(imageData, targetOrderImageInput: OrderPhotosModel, fullOrderNumber) {
    const Id = targetOrderImageInput.Id;

    return new Promise(async (resolve, reject) => {
      const targetOrderImage = targetOrderImageInput;
      let dataObj: Photo = {
        format: 'jpeg',
        dataUrl: imageData,
        saved: true,
      };
      if (targetOrderImage && targetOrderImage.Id) {
        let path = `${'Order'}/${fullOrderNumber}/${'photos'}/${targetOrderImage.Id}${'.jpeg'}`;

        await this.updateLocalPhotofile(path, dataObj, targetOrderImage).then(
          () => {
            resolve(true);
          },
          () => {
            reject();
          }
        );
      } else {
        console.warn('No Id; cannot save Photo');
      }
    });
  }

  getImageFromAsset(imageurl, photosUrl) {
    return new Promise(async (resolve, reject) => {
      this.http.get(imageurl, { responseType: 'blob' }).subscribe(async (res: any) => {
        this.utilService.convertBlobToBase64(res).then((photo: any) => {
          let data: OrderPhotosModel = {
            Picture: photo,
            Id: photosUrl.Id,
            OrderId: photosUrl.OrderId,
            SortOrder: -1,
            Description: photosUrl.Description,
            FileName: photosUrl.FileName,
            PhotoGroup: photosUrl.PhotoGroup,
            Tag: photosUrl.Tag,
            PhotoType: photosUrl.PhotoType,
            IncludeInCustomerReport: photosUrl.IncludeInCustomerReport,
            ReportId: photosUrl.ReportId,
            IncludeInCustomerRecommendation: photosUrl.IncludeInCustomerRecommendation ?? false,
          };
          resolve(data);
        });
      });
    });
  }

  deletePhotos(_selectedPhotoList, selectedOrder, photoListForGallery, riskreport) {
    return new Promise(async (resolve, reject) => {
      this.orderservice = this.injector.get(OrdersService);
      _selectedPhotoList.forEach(async (element) => {
        let index: number = selectedOrder.Photos.findIndex((result) => result.Id === element.Id);
        if (index !== -1) {
          if (
            element.PhotoType === RiskPhotoOptionsEnum.FRNT ||
            element.PhotoType === RiskPhotoOptionsEnum.REAR ||
            element.PhotoType === RiskPhotoOptionsEnum.ROOF
          ) {
            this.getRiskReportFromId(element.ReportId, riskreport).then((data) => {
              this.deleteriskPhotos(data, element.Id, selectedOrder.OrderIdFullOrderNumber, element.ReportId);
            });
          }
          selectedOrder.Photos = [...selectedOrder.Photos];
          selectedOrder.Photos.splice(index, 1);
          photoListForGallery.splice(index + 1, 1);

          const localPath = `${'Order'}/${selectedOrder.OrderIdFullOrderNumber}/${'photos'}/${element.Id}${'.jpeg'}`;

          await this.localStorageService.saveOrderJSONToLocal(selectedOrder, true).then(() => {
            this.deleteLocalAsset(localPath).then(() => {
              this.orderservice.fileSavedToLocal.next([
                {
                  sync: true,
                  model: ConfigModel.Order,
                  identifier: selectedOrder.OrderIdFullOrderNumber,
                  assetType: AssetType.Photos,
                  assetIdentifier: element.Id,
                  fileDeleted: true,
                },
              ]);
              this.orderservice.selectedOrderFromS3 = selectedOrder;
              this.imageDeleted();
              resolve(photoListForGallery);
            });
          });
        }
      });
    });
  }

  async delete_local_photos(photoid) {
    this.orderservice = this.injector.get(OrdersService);
    this.queueService = this.injector.get(QueueService);

    let selectedOrder = this.orderservice.selectedOrderFromS3;
    console.log('selectedOrder in delete_local_photos',selectedOrder);
    this.queueService.deletePhotosInQueue(photoid, selectedOrder?.OrderIdFullOrderNumber);

    if (selectedOrder?.Photos) {
      let index: number = selectedOrder.Photos.findIndex((result) => result.Id === photoid);

      if (index !== -1) {
        selectedOrder.Photos = [...selectedOrder.Photos];
        selectedOrder.Photos.splice(index, 1);
        console.log('photos',selectedOrder.Photos );
        this.orderservice.selectedOrderFromS3 = selectedOrder;
        this.orderservice.saveOrderJson(selectedOrder);
      }
    }
  }

  async saveRiskReport(photoid, reportid, option, riskReport, orderno) {
    return new Promise(async (resolve, reject) => {
      const newPhoto = {
        PhotoIdentifier: photoid,
        ReportIdentifier: reportid,
        ReportPhotoIdentifier: Guid.create().toString(),
        ReportPhotoType: option,
      };
      //This is to check if the ReportPhotos is null for new risks
      if (!riskReport?.RiskReport?.ReportPhotos ?? true) {
        riskReport.RiskReport.ReportPhotos = [];
      }

      riskReport?.RiskReport?.ReportPhotos.push(newPhoto);
      await this.localStorageService
        .saveFormOrRiskReportToLocal(
          riskReport,
          ConfigModel.Order,
          orderno,
          AssetType.RiskReport,
          reportid
        )
        .then(() => {
          resolve(true);
        });
    });
  }

  getRiskReportFromId(reportId, report) {
    return new Promise(async (resolve, reject) => {
      report.forEach((element) => {
        if (element.RiskReport.ReportIdentifier === reportId) {
          resolve(element);
        }
      });
    });
  }

  async deleteriskPhotos(riskreport, photoid, orderno, reportid) {
    return new Promise(async (resolve, reject) => {
      riskreport.RiskReport.ReportPhotos?.forEach(async (element, index) => {
        if (element.PhotoIdentifier === photoid) {
          riskreport.RiskReport.ReportPhotos = [...riskreport.RiskReport?.ReportPhotos];
          riskreport.RiskReport.ReportPhotos.splice(index, 1);

          await this.localStorageService
            .saveFormOrRiskReportToLocal(
              riskreport,
              ConfigModel.Order,
              orderno,
              AssetType.RiskReport,
              reportid
            )
            .then((data) => {
              this.imageDeleted();
              resolve(data);
            });
        }
      });
    });
  }

  updateRiskPhoto(reportId, id, riskreport, orderno, selectedOrder) {
    this.localStorageService = this.injector.get(LocalStorageService);
    return new Promise(async (resolve, reject) => {
      this.localStorageService.saveOrderJSONToLocal(selectedOrder, true).then(() => {
        this.deleteriskPhotos(riskreport, id, orderno, reportId);
        resolve(true);
      });
    });
  }

  public checkCustomerPhotos(order) {
    let orderPhotoInfo = order.Photos.filter((item) => item.IncludeInCustomerReport === true);
    const allDescriptionsFilled = orderPhotoInfo.every((photoInfo) => photoInfo.Description !== '');
    return allDescriptionsFilled;
  }

  public validationForCRPhotos(order, noCRPhotos) {
    if (noCRPhotos) {
      this.validCRPhotos = true;
    } else {
      let data = this.checkCustomerPhotos(order);
      this.validCRPhotos = data;
    }
    this.validateCompleteForPhotos();
  }

  public checkriskPhotos(photos) {
    const allPhotosValid =
      photos.length > 0 && photos.every((photo) => photo?.IsFrntImageDisplayed && photo?.IsRearImageDisplayed);
    return allPhotosValid;
  }

  public validationForRiskPhotos(photos) {
    let data = this.checkriskPhotos(photos);
    this.validRiskPhotos = data;
    this.validateCompleteForPhotos();
  }

  //Validation for Risk photos and Customer photos to enable Complete btn

  validateCompleteForPhotos(): void {
    let status = this.validRiskPhotos && this.validCRPhotos ? false : true;
    this.completeBtnSubject.next(status);
  }

  public imageLoaded() {
    this.imageLoadedForRisk.next(true);
    this.validateCompleteForPhotos();
    return this.imageLoadedForRisk.asObservable();
  }

  public imageDeleted() {
    this.imageDeletedForRisk.next(true);
    return this.imageDeletedForRisk.asObservable();
  }

  validPhotoFile(photo: Photo): boolean {
   return Constants.photosValidFileExtension.find((element) => element === photo.format) ? true : false;
  }
}
