/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable arrow-body-style */
/* eslint-disable no-cond-assign */
/* eslint-disable max-len */
import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { enforceSimpleNumericInput, maxLengthForNumber, numberCheck } from 'src/app/validators/formly-builder/validators';
export class OccupantsHazardsSectionModel extends FormlySectionModelAbstract {
  static maxCharacterLength = 1000;
  protected ngUnsubscribe = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'section-occupants',//form control name
      // type: 'section-occupants',   // No type! Just a simple Formly group using section wrapper

      wrappers: [ 'section-wrapper' ],
      props: {
        label: 'Occupants & Hazards', //field name
        addType: 'Occupants',
        addText: 'Add',
        actionButtons: [ { action: 'add', label: 'Add', isDisabled: false } ],
        isCollapsed: false,
        isDisabled: false,
        isEmptySectionOnInit: false
      },
      validators: {
        emptyOccupants: {
          expression: (control: FormControl, field: FormlyFieldConfig) => {
            const occupants = field.form.value[ 'section-occupants' ]?.occupants?.filter((occ) => !(occ.isDeleted || occ.isDeleted == null));
            if (occupants?.length === 0) {
              return false;
            }
            return true;
          },
          message: 'At least one occupancy entry is required.',
        },
        validation: [
          { name: 'schedulenumber-560d-validator' },
          { name: 'schedulenumber-vacant-validator' },
          { name: 'schedulenumber-validator' },
          { name: 'unique-occurance-validator', options: { field: 'occupant', propertyName: 'occupantScheduleNo', startWith: '355' } },
          // { name: 'occupant-hazards-sn-validation', options: { field: 'occupant', propertyName: 'occupantScheduleNo', startWith: '355' } },
          { name: 'occupant-hazards-area-validation', options: { field: 'occupant', propertyName: 'occupantScheduleNo', startWith: '' } },
          { name: 'csp-nonmercantile-validator' },
          { name: 'csp-mercantile-validator' },
          { name: 'occupancy-level-validator' },
          { name: 'occupancy-sprinkler-type-none-validator' },
          // {name:'occupancy-vacant-sno-validator',options:{ field: 'occupant', propertyName: 'occupantScheduleNo',compareWith:['5551100000','5551200000']}},
          {name:'occupant-hazard-sprinkler13R-validator'}
        ]
      },

      fieldGroup: [
        {
          type: 'summary-row',
          fieldGroup: [
            {
              key: 'aboveGradeFloors',
              props: {
                label: 'Number of Floors',
              },
              validators: {
                validation: [ { name: 'fullrisk-comparison-validator', options: { compareWith: 'section-occupants', section: 'occupants' } } ],
              },
            },
            {
              key: 'basementLevels',
              props: {
                label: 'Basement Levels',
              },
              validators: {
                validation: [ { name: 'fullrisk-comparison-validator', options: { compareWith: 'section-occupants', section: 'occupants' } } ],
              },
            },
            {
              //hide it
              key: 'totalArea',
              props: {
                label: 'Total Area',
                unit: 'sq.ft.',
                // isValidatorNeeded: formlyBuilderService.mode !== 1 ? true : false
              },
              // expressions: {
              //   hide: () => formlyBuilderService.mode === 1
              // },
              validators: {
                validation: [ { name: 'fullrisk-comparison-validator', options: { compareWith: 'section-occupants', section: 'occupants' } } ],
              },
            }
          ],
          expressions: {
            hide: (field: FormlyFieldConfig) => field.parent.props.isCollapseIconDisabled,
          }
        },
        {
          key: 'occupants',
          type: 'fr-occupant-array',//view mode + edit mode
          fieldArray: {
            props: {
              isContainLevels: true,
              levelColName: 'occupantLevels',
              hideVerticalBar:false
            },
            wrappers: [ 'error-info-wrapper' ],
            fieldGroupClassName: 'occupant-hazards-container',
            fieldGroup: [//type of fieldArray
              {
                type: 'toggle',
                key: 'isNewOccupant',
                defaultValue: true,
                wrappers: [ 'question-wrapper' ],
                props: {
                  label: 'Is this Occupant New or Same',
                  toggleSameNew: true,
                  isDisabled: true,
                  disabled: false,
                  required: false,
                },
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    field.options.formState.service.occupantsService.checkEditOccupantAsNew(field);
                  },
                },
              },

              {
                type: 'simple-input',
                key: 'doingBusinessAs',
                props: {
                  label: 'Doing Business As',
                  inputsize: 15,
                  required: true,
                  isAsteriskNotRequired: true,
                },
                className: 'occupant-doingbusiness',
                expressions: {
                  // 'props.required': !model.isDeleted
                  'props.maxLength': (model: FormlyFieldConfig) => {
                    if (model) {
                      const maxCharacters = model.form.value.isDeleted ? OccupantsHazardsSectionModel.maxCharacterLength : 35;
                      return maxCharacters;
                    } else {
                      return OccupantsHazardsSectionModel.maxCharacterLength;
                    }
                  }
                }
              },
              {
                type: 'simple-input',
                key: 'suiteNumber',
                className: 'occupant-suiteno',
                props: {
                  label: 'Suite',
                  inputsize: 8,
                  maxLength: 20
                  // type: 'number',
                  // inputmode: 'tel',
                },
              },
              {
                type: 'search-bar',
                key: 'occupantScheduleNo',
                props: {
                  label: 'Occupant Schedule #',
                  maxlength: 10,
                  minlength: 10,
                  type: 'number',
                  inputmode: 'tel',
                  jsonPath: 'schedule-codes',
                  required: true,
                  isAsteriskNotRequired: true,
                },
                className: 'rightFloat-field occupant-Scheduleno',
                validators: {
                  scheduleNumberLengthCheck: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      const schedNo = control.value;
                      const occupantType = field.form.get('occupantType');
                      if (field?.model?.isDeleted) {
                        return true;
                      }
                      else {
                        if (schedNo && schedNo.length === 10) {
                          try {
                            // Return false if no occupant type
                            const scheduleService = this.formlyBuilderService.scheduleService;
                            // const type = scheduleService.getScheduleType(schedNo);
                            const desc = scheduleService.getScheduledetails(schedNo);
                            const type = desc?.OccupantType;
                            if (!type || type === '') {
                              occupantType.setValue('');
                              return false;
                            } else {
                              occupantType.setValue(type);
                            }
                            // console.debug("validScheduleNo "+schedNo);
                          } catch (error) {
                            const occupantName = field?.form?.get('doingBusinessAs');
                            console.error('validScheduleNo ' + occupantName.value + ' #' + schedNo + ' ' + error);
                            return false;
                          }
                          return true;
                        } else {
                          occupantType.setValue('');
                          return false;
                        }
                      }
                    },
                    message: 'Schedule Number must be valid',
                  },
                },
                hooks: {
                  onInit: (field?: FormlyFieldConfig) => {
                    if(field?.options?.formState?.service?.isSheetActive){
                      const scheduleDetails: any = field.options.formState.service?.scheduleService?.getScheduledetails(field?.formControl?.value);
                      if (scheduleDetails) {
                        field?.form?.get('earthQuake')?.patchValue(scheduleDetails?.EQ?.toString());
                        field?.form?.get('spoc')?.patchValue(scheduleDetails?.SPOC?.toString());
                      }
                    }
                    field?.formControl?.valueChanges.subscribe((value) => {
                      if(field.options.formState.service.isSheetActive){
                        const scheduleDetails: any = field.options.formState.service?.scheduleService?.getScheduledetails(value);
                        if (scheduleDetails) {
                            field?.form?.get('combustibilityClass')?.patchValue(scheduleDetails?.CB?.toString());
                        }
                      }
                      if (value && value.length === 10) {
                        if (field?.form?.controls[ 'occupantHazards' ].length > 0) {
                          for (let i = 0; i < field?.form?.controls[ 'occupantHazards' ].length; i++) {
                            field?.form?.controls[ 'occupantHazards' ].controls[ i ].get('scheduleNumber').updateValueAndValidity();
                          }
                        }
                      }
                      if (field?.form?.controls[ 'occupantLevels' ].length > 0) {
                        for (let i = 0; i < field?.form?.controls[ 'occupantLevels' ].length; i++) {
                          field?.form?.controls[ 'occupantLevels' ].controls[ i ].get('sprinklerDesign').updateValueAndValidity();
                        }
                      }
                      if (field?.form?.controls[ 'occupantHazards' ].length==0) {
                        field?.form?.controls[ 'occupantHazards' ].updateValueAndValidity();
                      }
                      //for CSP - 
                      field.options.formState.service.occupantsService.setCspCheckboxEnable(field);
                    //  field.form.controls['occupantScheduleNo'].value
                       
                    });
                  }
                }
              },
              {
                type: 'simple-input',
                key: 'occupantType',
                props: {
                  label: 'Occupant Type',
                  inputsize: 35,
                  readonly: true,
                },
                className: 'common-alignment occupant-type',
              },
              {
                type: 'simple-input',
                key: 'occupantComment',
                props: {
                  label: 'Comments',
                  maxLength: 1200,
                  isLengthCount: true,
                  keypress: (field, event) => {
                    const charCode = (event.which) ? event.which : event.keyCode;
                    if (maxLengthForNumber(event.target.value, field.props.maxLength)) {
                      event.preventDefault();
                    }
                    //return false
                  },
                },
                className: 'common-alignment comments',
              },

              {
                fieldGroupClassName: 'occupant-details',
                fieldGroup: [
                  {
                    // key: 'occupantDetails',//based on new navigation we added key
                    wrappers: [ 'simple-section-wrapper' ],
                    className: 'full-width',
                    props: {
                      label: 'Occupant Details',
                      isCollapsable: true,
                      isCollapsed: true,
                      isSectionWithSummaryRow: true,
                      showError: false,
                    },
                    fieldGroup: [
                      {
                        type: 'summary-row',
                        className: 'box-container full-width',
                        fieldGroup: [

                          {
                            key: 'earthQuake',
                            props: {
                              label: 'Earthquake'
                            }
                          },
                          {
                            key: 'basicGroup2Symbol',
                            props: {
                              label: 'Basic Group II',
                              isTextField: true,
                              //  required: true,
                            },
                            expressions: {
                              hide: (field: FormlyFieldConfig) => {
                                // const address = field.form.parent.parent.parent.value.address;
                                const address = field.form.root.value.address;
                                if (address && address.mainAddress) {
                                  const state = address.mainAddress?.state;
                                  if (state === 'NY' || state === 'NX') {

                                    return true;
                                  }
                                }
                                return false;
                              },
                            }
                          },
                          {
                            key: 'spoc',
                            // defaultValue:0,
                            props: {
                              label: 'Sprinkler Protection Class'
                            }

                          },
                          {
                            key: 'susceptibilityClass',
                            resetOnHide: true,
                            props: {
                              label: 'Susceptibility Class',
                            },
                            expressions: {
                              hide: (field: FormlyFieldConfig) => {
                                if (field?.model?.occupantScheduleNo === '6063100000' || field?.model?.occupantScheduleNo === '6063200000') {
                                  return true;
                                }
                                return false;
                              }
                            },

                          },
                        ],
                        hooks: {
                          onInit: (field) => {

                            field.formControl.valueChanges.subscribe(value => {
                              if (value !== null && value !== undefined && field.options.formState.service.isSheetActive) {
                                field.form.get('levelStart')?.setValue(null);
                                field.form.get('levelStop')?.setValue(null);
                              }
                            });
                          }
                        },
                      },
                      {
                        fieldGroupClassName: 'full-width',
                        fieldGroup: [
                          {
                            defaultValue: true,
                            wrappers: [ 'question-wrapper' ],//field
                            fieldGroupClassName: 'select-checkbox-container',// it includes only select + checkbox-acts for the filedGroup
                            className: 'flex-box occupant-details-container',
                            props: {
                              label: 'Combustibility Class',
                              showError: false,
                            },
                            fieldGroup: [
                              {
                                key: 'combustibilityClass',
                                type: 'simple-select',
                                className: 'combustiblityclass-select',
                                // className: 'wall-construction-material',
                                props: {
                                  options: [ { value: 1, label: '1' },
                                  { value: 2, label: '2' },
                                  { value: 3, label: '3' },
                                  { value: 4, label: '4' },
                                  { value: 5, label: '5' },],
                                  rightAlign: true,
                                  isDisabled: true
                                },
                                expressions: {
                                  'props.isDisabled': (model: FormlyFieldConfig) => {
                                    return !model.form?.getRawValue()?.isCombustibilityClassOverride;
                                  }
                                },
                                hooks: {
                                  onInit: (field?: FormlyFieldConfig) => {
                                    field?.formControl?.valueChanges.subscribe((value) => {
                                        if (field?.form?.controls[ 'occupantHazards' ].length > 0) {
                                          for (let i = 0; i < field?.form?.controls[ 'occupantHazards' ].length; i++) {
                                            field?.form?.controls[ 'occupantHazards' ].updateValueAndValidity();
                                            
                                          }
                                        }
                                    });
                                }
                              },
                              },
                              {
                                key: 'isCombustibilityClassOverride',
                                type: 'custom-checkbox',
                                className: 'flex-box common-checkbox',
                                props: {
                                  firstColSize: 5,
                                },
                              },
                            ]
                          },
                          {
                            //  defaultValue: true,
                            wrappers: [ 'question-wrapper' ],//field
                            fieldGroupClassName: 'input-checkbox-container',// it includes only select + checkbox-acts for the filedGroup
                            className: 'flex-box occupant-details-container',
                            props: {
                              label: 'Commercial Statistical Plan ( CSP )',
                              showError: false,
                              firstColSize: 8,
                            },
                            fieldGroup: [
                              {
                                key: 'commercialStatisticalPlan',
                                type: 'simple-input',
                                className: 'csp-input',
                                // className: 'wall-construction-material',
                                props: {
                                  rightAlign: true,
                                  // isDisabled: true,
                                  readonly: true,
                                  required: true,
                                  isAsteriskNotRequired: true,
                                  maxLength:4,
                                  minLength:4,
                                  // pattern: '[0-9]+',
                                  attributes:{
                                    inputmode:'numeric'
                                  },
                                  keypress:(field,event)=>enforceSimpleNumericInput(field,event)
                                },
                                hooks: {
                                  onInit: (field?: FormlyFieldConfig) => {
                                    field?.formControl?.valueChanges.subscribe((value) => {
                                      if (value && value.length === 4) {
                                        if (field?.form?.controls['occupantHazards'].length > 0) {
                                          for (let i = 0; i < field?.form?.controls['occupantHazards'].length; i++) {
                                            field?.form?.controls['occupantHazards'].controls[i].get('scheduleNumber').updateValueAndValidity();
                                          }
                                        }
                                        if (field?.form?.controls[ 'occupantHazards' ].length==0) {
                                          field?.form?.controls[ 'occupantHazards' ].updateValueAndValidity();
                                        }
                                      }
                                    });
                                  }
                                },
                                expressions: {
                                'props.readonly': (field: FormlyFieldConfig) => {
                                 const address = field.form.root.value.address;
                                 const schedulenumber =field.parent?.model?.occupantScheduleNo;
                                 if (address && address.mainAddress  && schedulenumber) {
                                   const state = address.mainAddress?.state;
                                   if (state && state === 'MA' && schedulenumber === '6104110000') {
                                         return true;
                                   }
                                  
                                 }
                                 return !field.form?.value?.isCommercialStatisticalPlanOverride;
                                  },
                                   'props.required': (field: FormlyFieldConfig) => {
                                    if(field.parent?.model?.occupantScheduleNo?.startsWith('560')||
                                   !((field.parent?.model?.occupantScheduleNo?.startsWith('3')||field.parent?.model?.occupantScheduleNo?.startsWith('5')
                                  ||field.parent?.model?.occupantScheduleNo?.startsWith('6')))){
                                    return false
                                    }
                                    else {
                                      return true;
                                    }
                               },
                                },
                                validators: {
                                  validation: [{name:'csp-validation'}],
                                
                                    isNumberValidator: {
                                      expression: (control: FormControl, field: FormlyFieldConfig) => { 
                                        const isDeleted=control?.parent?.get('isDeleted')?.value;
                                          if (control?.value !== null && control?.value !== ''&& control?.value !==undefined&& !isDeleted) {
                                            const integerPattern = /^[0-9]+$/;
                                            if (!integerPattern.test(control.value)) {
                                              return false
                                            } else {
                                              return true
                                            }
                                          
                                        } else {
                                          return true
                                        }
                                      },
                                      message: 'Commercial Statistical Plan should be a number.'
                                    },
                                  
                               },
                              //  validation: {
                              //   messages: {
                              //     pattern: (error: any, field: FormlyFieldConfig) => `CommercialStatisticalPlan should be a number.`,
                              //   },
                              // },
                              },
                              {
                                key: 'isCommercialStatisticalPlanOverride',
                                type: 'custom-checkbox',
                                className: 'flex-box common-checkbox',
                                props: {
                                  firstColSize: 5,
                                 // disabled:false
                                },
                                expressions:{
                                  'props.isDisabled': (field: FormlyFieldConfig) => {
                                    const address = field.form.root.value.address;
                                    const schedulenumber =field.parent?.model?.occupantScheduleNo;
                                    if (address && address.mainAddress  && schedulenumber) {
                                      const state = address.mainAddress?.state; //
                                      if ((state && state === 'MA' && schedulenumber === '6104110000')|| (schedulenumber.startsWith("560")) ) {
                                            return true;
                                      }
                                      
                                    }
                                      return false;
                                  }
                                     
                                }
                              },
                            ]
                          },
                          {
                            defaultValue: true,
                            wrappers: [ 'question-wrapper' ],//field
                            fieldGroupClassName: 'input-checkbox-container',// it includes only select + checkbox-acts for the filedGroup
                            className: 'flex-box occupant-details-container susceptibility-container',
                            props: {
                              label: 'Susceptibility Class',
                              showError: false,
                              required: true,
                              isAsteriskNotRequired: true,
                            },
                            expressions: {
                              hide: (field: FormlyFieldConfig) => {
                                if (field?.parent?.parent?.parent?.model?.occupantScheduleNo === '6063100000' ||
                                  field?.parent?.parent?.parent?.model?.occupantScheduleNo === '6063200000') {
                                    field?.formControl?.get('susceptibilityClassEidtField')?.markAsTouched();
                                  return false;
                                }
                                return true;
                              }
                            },
                            fieldGroup: [
                              {
                                key: 'susceptibilityClassEidtField',
                                type: 'simple-select',
                                className: 'susceptibility-class',
                                // className: 'wall-construction-material',
                                resetOnHide: true,
                                props: {
                                  rightAlign: true,
                                  required: true,
                                  isAsteriskNotRequired: true,
                                  options: [
                                    { value: 1, label: '1' },
                                    { value: 2, label: '2' },
                                    { value: 3, label: '3' },
                                    { value: 4, label: '4' },
                                    { value: 5, label: '5' },
                                  ]
                                },
                              }
                            ]
                          },
                          {
                            defaultValue: true,
                            wrappers: [ 'question-wrapper' ],//field
                            fieldGroupClassName: 'selectbox-container',
                            className: 'flex-box occupant-details-container bg2symbol-container',
                            props: {
                              label: 'Basic Group II',
                              showError: false,
                            },
                            expressions: {
                              hide: (field: FormlyFieldConfig) => {
                                // const address = field.form.parent.parent.parent.value.address;
                                const address = field.form.root.value.address;
                                if (address && address.mainAddress) {
                                  const state = address.mainAddress?.state;
                                  if (state === 'NY' || state === 'NX') {

                                    return false;
                                  }
                                }
                                return true;
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'basicGroup2Symbol',
                                type: 'simple-select',
                                className: '',
                                // className: 'wall-construction-material',
                                props: {
                                  options: [
                                    { value: 'T', label: 'T' },
                                    { value: 'Z', label: 'Z' },
                                    { value: 'K', label: 'K' },
                                    { value: 'I', label: 'I' },
                                    { value: 'U', label: 'U' },
                                    { value: 'V', label: 'V' },
                                    { value: 'W', label: 'W' },
                                    { value: 'P', label: 'P' },
                                    { value: 'B', label: 'B' },
                                    { value: 'R', label: 'R' },
                                    { value: 'H', label: 'H' },
                                    { value: 'N', label: 'N' },
                                    { value: 'J', label: 'J' },
                                    { value: 'A', label: 'A' },
                                    { value: 'L', label: 'L' },
                                    { value: 'X', label: 'X' },
                                    { value: 'D', label: 'D' },
                                    { value: 'C', label: 'C' },
                                    { value: 'CD', label: 'CD' },
                                    { value: 'AB', label: 'AB' },
                                  ],
                                  rightAlign: true,
                                  required: true,
                                  isDisabled: false,
                                },

                              },

                            ]
                          },

                        ],
                        expressions: {
                          hide: (field: FormlyFieldConfig) => !field.parent.props.isCollapsed
                        }
                      }
                    ]
                  },
                  {
                    key: 'isDeleted',
                    defaultValue: false,
                  },
                ]
              },
              {
                key: 'occupantLevels',
                type: 'occupant-hazards-levels',
                props: {
                  addText: 'Add Floor',
                },
                className: 'formly-flex-container occupant-levels-fields',
                fieldArray: {
                  fieldGroup: [
                    {
                      key: 'name',
                      type: 'simple-select',
                      className: 'select-width-md',
                      props: {
                        label: 'Level Type',
                        defaultValue: 'Floor',
                        options: [
                          { value: 'Floor', label: 'Floor' },
                          { value: 'Attic', label: 'Attic' },
                          { value: 'Mezzanine', label: 'Mezzanine' },
                          { value: 'Basement', label: 'Basement' },
                        ],
                        required: true,
                        isAsteriskNotRequired: true,
                      },
                      hooks: {
                        onInit: (field) => {
                          if (!field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.formControl?.value)) {
                            field?.formControl?.setValue('Floor');
                          }
                          field.formControl.valueChanges.subscribe(value => {
                            if (value !== null && value !== undefined && field.options.formState.service.isSheetActive) {
                              field.form.get('levelStart')?.setValue(null);
                              field.form.get('levelStop')?.setValue(null);
                            }
                          });
                        }
                      },
                    },
                    {
                      type: 'rf-aerial'
                    },
                    {
                      key: 'length',
                      type: 'simple-input',
                      defaultValue: null,
                      className: 'smd-width max-z-index position-relative',
                      props: {
                        label: 'Length',
                        type: 'number',
                        inputmode: 'tel',
                        min: 1,
                        maxLength: 8,
                        required: true,
                        isAsteriskNotRequired: true,
                        className: 'ft-label-masonary'
                      },
                    },
                    {
                      key: 'width',
                      type: 'simple-input',
                      defaultValue: null,
                      className: 'smd-width max-z-index position-relative',
                      props: {
                        label: 'Width',
                        type: 'number',
                        inputmode: 'tel',
                        min: 1,
                        maxLength: 8,
                        required: true,
                        isAsteriskNotRequired: true,
                        className: 'ft-label-masonary'
                      },

                    },
                    { template: '<span>=</span>', className: 'equals-to' },
                    {
                      key: 'areaPerLevel', // Area per Occupant per Level
                      type: 'text',
                      className: 'calculated-hazards',
                      props: {
                        // prefix: '=',
                        unit: ' sq.ft.',
                      },
                      hooks: {
                        onInit: (field) => {
                          field.options.formState.service.occupantHazardsService.calculateAreaPerLevel(field);
                        },
                      },
                    },
                    { template: '<span></span>', className: 'new-row' },
                    {
                      key: 'levelStart',
                      type: 'simple-input',
                      defaultValue: null,
                      className: 'smd-width max-z-index position-relative newLine-field ',
                      props: {
                        label: 'Level Start',
                        type: 'number',
                        inputmode: 'tel',
                        required: true,
                        isAsteriskNotRequired: true,
                        min: 1,
                        maxLength: 8,
                      },
                      hooks: {
                        onInit: (field) => {
                          field.formControl.valueChanges.subscribe(value => {
                            if (value !== null && value !== undefined && field.options.formState.service.isSheetActive) {
                              field.form.get('levelStop')?.setValue(null);
                            }
                          });
                        },
                      },
                      validators: {
                        validation: [
                          { name: 'occupant-level-start-validator'}
                        ],
                      },
                    },
                    {
                      key: 'levelStop',
                      type: 'simple-input',
                      defaultValue: null,
                      className: 'smd-width max-z-index position-relative',
                      props: {
                        label: 'Level Stop',
                        type: 'number',
                        inputmode: 'tel',
                        // required: true,
                        // isAsteriskNotRequired: true,
                        min: 1,
                        maxLength: 8,
                      },
                      validators: {
                        validation: [
                          {
                            name: 'cross-field-comparison-validator',
                            options: {
                              compareWith: 'levelStart',
                              msg: 'Must be greater than LevelStart',
                              condition: 'lessThan'
                            },
                          },
                          {
                            name: 'cross-field-comparison-validator',
                            options: {
                              compareWith: 'levelStart',
                              msg: 'Must be greater than LevelStart',
                              condition: 'equalsTo'
                            },
                          },
                          { name: 'occupant-level-start-validator'}
                        ],
                      },
                      hooks: {
                        onInit: (field?: FormlyFieldConfig) => {
                          field?.formControl?.valueChanges.subscribe((value) => {
                            if (field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.formControl?.value)) {
                              if (field?.form?.get('name')?.value == 'Mezzanine' && field?.formControl?.invalid) {
                                field?.formControl?.markAsTouched();
                              }
                            }
                          });
                        }
                      }
                    },
                    {
                      key: 'hasExtinguisher',
                      type: 'custom-checkbox',
                      className: 'checkbox-white  checkbox-level',
                      props: {
                        label: 'Extinguishers',
                      },
                    },
                    {
                      key: 'hasLimitedSupplyFireProtectionSystem',
                      type: 'custom-checkbox',
                      className: 'checkbox-white checkbox-level',
                      props: {
                        label: 'Limited FPS',
                      },
                    },
                    {
                      key: 'evidenceOfFireSprinkler',
                      expressions: {
                        'props.isDisabled': (field?: FormlyFieldConfig) => {
                          const disabledField = field?.parent?.options?.formState?.isMainSectionDisabled;
                          const filterArray = disabledField.filter(val => {
                          return val.sectionId == SectionEnum.FR_SPRINKLERS;
                          }); 
                          field.formControl.setValue((filterArray.length == 1 ||field?.form?.root?.value?.evidenceOfFireSprinkler=='false'
                            ||field?.form?.root?.value?.evidenceOfFireSprinkler==null)? 'false' : 'true');
                          return true;
                        }
                      }
                    },
                    {
                      key: 'sprinklerDesign',
                      type: 'simple-select',
                      className: 'select-width-md newLine-field padding-btm-md',
                      props: {
                        isDisabled: false,
                        label: 'Sprinkler Design',
                        index: 0,
                        options: [
                          { value: 'None', label: 'None' },
                          { value: 'Hydraulic', label: 'Hydraulic' },
                          { value: 'Hydraulic - ESFR', label: 'Hydraulic - ESFR' },
                          { value: 'Hydraulic - CMDA', label: 'Hydraulic - CMDA' },
                          { value: 'Hydraulic - CMSA', label: 'Hydraulic - CMSA' },
                          { value: 'Pipe Schedule', label: 'Pipe Schedule' },
                          { value: '13R', label: '13R' },
                        ],
                        required: true,
                        isAsteriskNotRequired: true,
                      },
                      expressions: {
                        'props.index': 'field.parent.key',
                        'props.isDisabled': (field?: FormlyFieldConfig) => {
                          const formlyService = field.options.formState.service;
                          const model = formlyService.riskReport.model;
                          if (field?.form?.get('evidenceOfFireSprinkler').value == 'false') {
                            field?.form?.get('sprinklerDesign')?.setValue('None');
                            return true;
                          }
                          else {
                            return false;
                          }
                        },
                        'props.required': (field?: FormlyFieldConfig) => {
                          const formlyService = field.options.formState.service;
                          const model = formlyService.riskReport.model;
                          if (field?.form?.get('evidenceOfFireSprinkler').value == 'false') {
                            return false;
                          }
                          else {
                            return true;
                          }
                        }
                      },
                      validators: {
                        validation: [ { name: 'occupant-sprinker-validator' },
                        { name: 'occupant-spoc-validator' },
                        { name: 'occupant-hazard-sprinkler-hydralic-validator' },
                        { name: 'occupant-hazard-sprinkler-design-validator' } ],
                      },
                      hooks: {
                        onInit: (field) => {
                          if ((!field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.formControl?.value))
                          && field.options.formState.service.isModuleState=='add') {
                            field?.formControl?.setValue('None');
                            // field?.formControl?.markAsTouched();
                            // field?.form?.markAsTouched();
                            // field?.parent?.form?.markAsTouched();
                          }
                            field.formControl?.valueChanges?.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged()).subscribe((value) => {
                            if (field?.parent?.parent?.form?.controls[ 'occupantLevels' ]?.length > 0) {
                              for (let i = 0; i < field?.parent?.parent?.form?.controls[ 'occupantLevels' ]?.length; i++) {
                                let index= Number(field.props.index);
                                field?.parent?.parent?.form?.controls[ 'occupantLevels' ].controls[index].get('sprinklerDesign').setValue(value);
                                if(index != i)
                                field?.parent?.parent?.form?.controls[ 'occupantLevels' ].controls[ i ].get('sprinklerDesign').updateValueAndValidity();
                              }
                            }
                          })
                        },
                        onDestroy: () => {
                          this.ngUnsubscribe.next();
                          this.ngUnsubscribe.complete();
                        }
                        
                      },
                    },
                    {
                      key: 'sprinklerType',
                      type: 'simple-select',
                      className: 'select-width-md padding-btm-md',
                      props: {
                        label: 'Sprinkler Type',
                        options: [
                          { value: 'Wet', label: 'Wet' },
                          { value: 'Dry', label: 'Dry' },
                          { value: 'Wet - High Piled Stock', label: 'Wet - High Piled Stock' },
                          { value: 'Dry - High Piled Stock', label: 'Dry - High Piled Stock' },
                          { value: 'Non-Creditable', label: 'Non-Creditable' },
                          { value: 'Shut Off', label: 'Shut Off' }
                        ],
                        // required: true,
                        isAsteriskNotRequired: true,
                        isDisabled: false
                      },
                      expressions: {
                        'props.isDisabled': (field?: FormlyFieldConfig) => {
                          if (field.form?.getRawValue()?.sprinklerDesign == 'None') {
                            field?.form?.get('sprinklerType')?.setValue('');
                            return true;
                          }
                          else { return false; }
                        },
                        'props.required': (field?: FormlyFieldConfig) => {
                          const formlyService = field.options.formState.service;
                          const model = formlyService.riskReport.model;
                          if (field?.form?.get('evidenceOfFireSprinkler').value == 'true' && field.form?.getRawValue()?.sprinklerDesign != 'None') {
                            return true;
                          }
                          else {
                            return false;
                          }
                        }
                      },
                      hooks: {
                        onInit: (field?: FormlyFieldConfig) => {
                          field?.formControl?.valueChanges.subscribe((value) => {
                            field?.form?.get('sprinklerDesign').updateValueAndValidity();
                          });
                        }
                      }
                    },
                    {
                      key: 'automaticSprinkler',
                    },
                    {
                      key: 'occupantHazardIdentifier',
                    },
                    {
                      key: 'occupantLevelIdentifier',
                    },
                  ],
                }
              },
              {
                fieldGroup: [
                  {
                    key: 'occupantHazards',
                    type: 'occupant-building-hazards',
                    wrappers: [ 'simple-section-wrapper' ],
                    className: 'occupant-hazards-wrapper',
                    props: {
                      label: 'Occupant Hazards',
                      isCollapsable: true,
                      isCollapsed: false,
                      checkTouchedControl: true,
                      shallowErrors: true,
                    },
                    fieldArray: {
                      className: 'occupant-hazards-fields',
                      wrappers: [ 'error-info-wrapper' ],
                      props:{
                        hideVerticalBar:false
                      },
                      fieldGroup: [
                        {
                          type: 'search-bar',
                          key: 'scheduleNumber',
                          className: 'hazard-schedule-searchbar',
                          defaultValue: null,
                          props: {
                            label: 'Hazard Schedule #',
                            maxlength: 10,
                            minlength: 10,
                            type: 'number',
                            inputmode: 'tel',
                            jsonPath: 'hazard-codes',
                            onBlur: true,
                            required: true,
                            initialValue: '7'
                          },
                          hooks: {
                            onInit: (field: FormlyFieldConfig) => {
                              field.options.formState.service.occupantHazardsService.getCombustibleClassDetails(field);
                              field?.formControl?.valueChanges.subscribe((value) => {
                                //  //If value is invalid we are making it as empty string
                                field.options.formState.service.occupantHazardsService.getCombustibleClassDetails(field);
                                if (value !== null && value && value.length === 10) {
                                  if (value && field.options.formState.service.isSheetActive === true) {
                                    field.options.formState.service.occupantHazardsService.combustibilityClassValidator(value, field?.parent?.form?.value,
                                      field?.parent?.parent?.form?.get('occupantScheduleNo')?.value, false,
                                      field?.parent?.parent?.form, 'form', field?.parent?.parent?.form?.get('combustibilityClass')?.value,
                                      field?.parent?.key, field?.parent?.parent.parent.parent);
                                  }
                                  field.formControl.markAsTouched();
                                  field.form?.markAsTouched();
                                  field?.parent?.form?.markAsTouched();
                                }
                              });
                            }
                          },
                          validators: {
                            scheduleNumberLengthCheck: {
                              expression: (control: FormControl, field: FormlyFieldConfig) => {
                                const schedNo = control.value;
                                const description = field.form.get('description');
                                if (schedNo && schedNo.length === 10) {
                                  try {
                                    const scheduleService = this.formlyBuilderService.scheduleService;
                                    const desc = scheduleService.getHazardScheduleDescription(schedNo);
                                    const type = desc?.occupantDescription;
                                    if (!type || type === '') {
                                      description.setValue('');
                                      return false;
                                    } else {
                                      setTimeout(() => {
                                        description.setValue(type);
                                      }, 500);
                                    }
                                  } catch (error) {
                                    console.error('validScheduleNo ' + ' #' + schedNo + ' ' + error);
                                    return false;
                                  }
                                  return true;
                                } else {
                                  description.setValue('');
                                  return false;
                                }
                              },
                              message: 'Invalid schedule number',
                            },
                            validation: [
                              { name: 'occupant-hazards-scheduleno-start', options: { startsWith: '560' } },
                              { name: 'occupant-hazards-limited-cooking', options: { startsWith: '521', limitedCookingSchNo: '73413' } },
                              { name: 'occupant-hazards-regular-cooking', options: { regularCookingSchNo: '73414' } },
                              // {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['732'],propertyName:'scheduleNumber',errorMsg:'Only the single highest Painting or Coating hazard can be applied to an occupancy.'}},
                           
                            ]
                          },
                        },
                        {
                          key: 'description',
                          type: 'simple-input',
                          className: 'building-hazards-description-field',
                          defaultValue: null,
                          props: {
                            label: 'Description',
                            readonly: true,
                          },
                        },
                        {
                          key: 'comment',
                          type: 'rf-text-area',
                          className: 'building-hazards-comment-field',
                          defaultValue: null,
                          props: {
                            label: 'Comments',
                            isAsteriskNotRequired: true,
                            required: true,
                            rows: 5,
                            maxLength: 650,
                          },
                        },
                        {
                          fieldGroup: [
                            {
                              type: 'occupant-hazards-photo',
                              key: 'photo',
                              className: 'hazards-photo-field',
                              props: {
                                label: 'Select Photo',
                              },
                            },
                            {
                              key: 'photoIdentifier',
                              defaultValue: 'undefined',
                            },
                          ],
                        },
                        {
                          key: 'occupantHazardIdentifier',
                        },
                        {
                          key: 'occupantIdentifier',
                        },
                      ],
                    },
                    validators: {
                      // checkduplicateScheduleNo: {
                      //   expression: (control: FormControl, field: FormlyFieldConfig) => {
                      //     const formValues = control.value;
                      //     // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
                      //     if (formValues.length > 1) {
                      //       //remove undefined
                      //       const scheduleNumbers = formValues.filter((item) => {
                      //         if (item.scheduleNumber) {
                      //           return item;
                      //         }
                      //       });

                      //       //get all schedule numbers
                      //       const allScheduleNumber = scheduleNumbers.map((item) => item.scheduleNumber);

                      //       //remove duplicate
                      //       const uniqueScheduleNumber = new Set(allScheduleNumber);
                      //       const isDuplicate = uniqueScheduleNumber.size !== allScheduleNumber.length;
                      //       return !isDuplicate;
                      //     } else {
                      //       return true;
                      //     }
                      //   },
                      //   message: 'Hazards cannot be duplicated on one occupant Hazards',
                      // },
                      validation: [
                        // { name: 'occupant-hazards-restaurant-cooking', options: { regularCookingSchNo: '73414', occupantCookingSchNo: '52120' } },
                        { name: 'occupant-hazards-limited-occupancies', options: { startsWith: '521', limitedCookingSchNo: '73413' } },
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['732'],propertyName:'scheduleNumber',errorPropertyName:'1',errorMsg:'Only the single highest Painting or Coating hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['7312'],propertyName:'scheduleNumber',errorPropertyName:'2',excludedScheduleNoList: ['7312400000'],errorMsg:'Only the single highest combustible or flammable liquid storage hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['7313'],propertyName:'scheduleNumber',errorPropertyName:'3',errorMsg:'Only the single highest Combustible or Flammable Liquids handling hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['7341'],propertyName:'scheduleNumber',errorPropertyName:'4',errorMsg:'Only one Basic Cooking hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['7363'],propertyName:'scheduleNumber',errorPropertyName:'5',errorMsg:'Only the single highest Heat Device hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['7365'],propertyName:'scheduleNumber',errorPropertyName:'6',errorMsg:'Only the single highest Non-Standard Conditions for Heat Devices hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['738'],propertyName:'scheduleNumber',errorPropertyName:'7',errorMsg:'Only one Smoking Control hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['7392'],propertyName:'scheduleNumber',errorPropertyName:'8',errorMsg:'Only one Housekeeping hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['7406'],propertyName:'scheduleNumber',errorPropertyName:'9',errorMsg:'Only one Auxiliary Plastic Processing hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['737'],propertyName:'scheduleNumber',errorPropertyName:'10',errorMsg:'Only one High Piled Stock hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['7401'],propertyName:'scheduleNumber',errorPropertyName:'11',errorMsg:'Only one Auxiliary Process from 7401 can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['740810000','740820000'],propertyName:'scheduleNumber',errorPropertyName:'12',errorMsg:'Only one Auxiliary Sewing or Tailoring hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['736721000','736722000','736723000','736724000'],propertyName:'scheduleNumber',errorPropertyName:'21',errorMsg:'Only one Incinerator hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['735111000','735112000'],propertyName:'scheduleNumber',errorPropertyName:'13',errorMsg:'Only one Flammable Solids Dust Collection hazard, 735111 or 735112, can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['735210000','735220000'],propertyName:'scheduleNumber',errorPropertyName:'14',errorMsg:'Only one Metalworking Dust Collection hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['735410000','735420000'],propertyName:'scheduleNumber',errorPropertyName:'15',errorMsg:'Only one Flammable Gases or Vapors Exhaust System hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['735310000','735320000'],propertyName:'scheduleNumber',errorPropertyName:'16',errorMsg:'Only one Woodworking Dust Collection hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['734331000','734331100','734331200','734331300','734331400','734331500','734331600','734331700','734331800','734331900',
                        '734332000','734332100','734332200','734332300','734332400','734332500','734332600','734332700','734332800','734332900','734340000'],propertyName:'scheduleNumber',errorPropertyName:'17',errorMsg:'Only one Exhaust System Clearance hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['734321000','734322100','734322200','734322300','734323100'],propertyName:'scheduleNumber',errorPropertyName:'18',errorMsg:'Only one Exhaust System hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['734311000','734312000','734313000'],propertyName:'scheduleNumber',errorPropertyName:'19',errorMsg:'Only one Non-Standard Exhaust System hazard can be applied to an occupancy.'}},
                        {name:'occupant-hazard-validation',options: {hazardScheduleNo: ['740010000','740020000'],propertyName:'scheduleNumber',errorPropertyName:'20',errorMsg:'Only one Auxiliary Woodworking hazard can be applied to an occupancy.'}},
                        {name:'duplicate-scheduleNumber-validator',options: {propertyName:'scheduleNumber',errorPropertyName:'22',errorMsg:'Hazards cannot be duplicated on one occupant Hazards'}},
                        { name: 'filtering-hazard-sno-validator', options: {isInclude: true, scheduleNo: ['5212000000'], excludeHazardSNo: [],
                          hazardScheduleNo: ['734140000', '734141000', '734142000', '734143000'],errorMsg: 'Regular cooking restaurant requires regular cooking H of O.',errorPropertyName:'15',mustBe:true}
                        },
                        { name: 'filtering-hazard-sno-validator', options: {isInclude: false, scheduleNo: [ '5212000000' ],excludeHazardSNo:[] ,comparedLength:0,
                          hazardScheduleNo: [ '734130000', '734131000', '734132000', '734133000'],errorMsg: 'Limited cooking H of O cannot be applied to regular cooking restaurant.',errorPropertyName:'16',mustBe:false}
                        },
                        { name: 'filtering-hazard-sno-validator', options: {isInclude: true, scheduleNo: ['5212100000','5211120000','5211220000','5211320000','5211420000','5211520000','5211620000','5211720000'], excludeHazardSNo: [],
                        hazardScheduleNo: ['734130000', '734131000', '734132000', '734133000'],errorMsg: 'Limited cooking occupancies requires a limited cooking H of O.',errorPropertyName:'17',mustBe:true}
                        },
                        { name: 'filtering-hazard-sno-validator', options: {isInclude: false, scheduleNo: ['5212100000','5211120000','5211220000','5211320000','5211420000','5211520000','5211620000','5211720000'],excludeHazardSNo:[] ,comparedLength:0,
                        hazardScheduleNo: [ '734140000', '734141000', '734142000', '734143000'],errorMsg: 'Regular cooking H of O cannot be applied to limited cooking restaurant.',errorPropertyName:'18',mustBe:false}
                        },
                        { name: 'filtering-hazard-sno-validator', options: {isInclude: true, scheduleNo: ['6021110000','6021111000','6021120000'], excludeHazardSNo: [],
                        hazardScheduleNo: ['73631'],errorMsg: 'Bakeries must have a 736312 baking charge applied.',errorPropertyName:'19',mustBe:false}
                        },
                        {name: 'filtering-hazard-sno-startwith-validator', options: {isInclude: false, scheduleNoStartWith: ['608'], excludedString: [], comparedLength: 0,
                         hazardScheduleNo: ['732221001', '732221002', '732221003', '732222001', '732222002', '732222003'], excludeHazardSNo: [],
                         errorMsg: 'The hazard for coating with plastics does not apply to Plastic Product Manufacturing (608) occupancies.',errorPropertyName:'1'}
                        },
                        {name: 'filtering-hazard-sno-startwith-validator', options: {isInclude: false, scheduleNoStartWith: ['516','518','544','554','603'], excludedString: [], comparedLength: 0,
                        hazardScheduleNo: ['733'], excludeHazardSNo: [],
                        errorMsg: 'Highly Combustible Materials hazards do not apply to this occupancy.',errorPropertyName:'2'}
                        },
                        {name: 'filtering-hazard-sno-startwith-validator', options: {isInclude: true, scheduleNoStartWith: ['6051','6052','6054'], excludedString: [], comparedLength: 0,
                        hazardScheduleNo: ['728100000'], excludeHazardSNo: [],
                        errorMsg: 'Combustible Coating Process in a Metalworker can only be applied to occupancies starting with 6051, 6052, or 6054.',errorPropertyName:'3'}
                        },
                        {name: 'filtering-hazard-sno-startwith-validator', options: {isInclude: true, scheduleNoStartWith: ['6051','6052','6053'], excludedString: [], comparedLength: 0,
                        hazardScheduleNo: ['728210000'], excludeHazardSNo: [],
                        errorMsg: 'Use of Combustible Metals in a Metalworker can only be applied to occupancies starting with 6051, 6052, or 6053.',errorPropertyName:'4'}
                        },
                        {name: 'filtering-hazard-sno-startwith-validator', options: {isInclude: true, scheduleNoStartWith: ['605'], excludedString: [], comparedLength: 0,
                        hazardScheduleNo: ['728220000','728230000','728240000'], excludeHazardSNo: [],
                        errorMsg: 'Metalworking Special Hazards can only be applied to Metalworking occupancies (605).',errorPropertyName:'5'}
                        },
                        {name: 'filtering-hazard-sno-startwith-validator', options: {isInclude: true, scheduleNoStartWith: ['611'], excludedString: [], comparedLength: 0,
                        hazardScheduleNo: ['729100000','729200000'], excludeHazardSNo: [],
                        errorMsg: 'Vegetable Oil Processing Special Hazards can only be applied to Vegetable Oil Processing occupancies (611).',errorPropertyName:'6'}
                        },
                        {name: 'filtering-hazard-sno-startwith-validator', options: {isInclude: false, scheduleNoStartWith: ['550','608'], excludedString: [], comparedLength: 0,
                        hazardScheduleNo: ['735'], excludeHazardSNo: [],
                        errorMsg: 'Dust Collection hazards do not apply to Shoe Repair Shops or Plastic Products MFG.',errorPropertyName:'7'}
                        },
                        {name: 'filtering-hazard-sno-startwith-validator', options: {isInclude: false, scheduleNoStartWith: ['608','6101','61234'], excludedString: [], comparedLength: 0,
                        hazardScheduleNo: ['7363'], excludeHazardSNo: [],
                        errorMsg: 'Heat Device hazards do not apply to Plastic Product MFG, Cotton Gins, or Dry Kilns.',errorPropertyName:'8'}
                        },
                        {name: 'filtering-hazard-sno-startwith-validator', options: {isInclude: false, scheduleNoStartWith: ['601','608','60614'], excludedString: [], comparedLength: 0,
                        hazardScheduleNo: ['730'], excludeHazardSNo: ['7305000000','7305000001','7305000002','7305000003','7305000004','7305000005','7305000006','7305000007','7308200000','7308200001','7308200002','7308200003','7308200004','7308200005','7308200006','7308200007'],
                        errorMsg: 'Chemicals, Acids, or Gases hazards do not apply to Chemical MFG, Plastic Products MFG, or Sulphur Mining.',errorPropertyName:'9'}
                        },
                        {name: 'filtering-hazard-sno-startwith-validator', options: {isInclude: false, scheduleNoStartWith: ['513'], excludedString: [], comparedLength: 0,
                        hazardScheduleNo: ['730'], excludeHazardSNo: ['7308200000','7308200001','7308200002','7308200003','7308200004','7308200005','7308200006','7308200007'],
                        errorMsg: 'Chemicals, Acids, or Gases hazards do not apply to Chemical Occupancies.',errorPropertyName:'10'}
                        },
                        {name: 'filtering-hazard-sno-startwith-validator', options: {isInclude: false, scheduleNoStartWith: ['513'], excludedString: [], comparedLength: 0,
                        hazardScheduleNo: ['73121','73122','73123'], excludeHazardSNo: [],
                        errorMsg: 'Flammable and Combustible Liquid storage hazards do not apply to Chemical Occupancies.',errorPropertyName:'11'}
                        },
                        {name: 'filtering-hazard-sno-startwith-validator', options: {isInclude: true, scheduleNoStartWith: ['524','548'], excludedString: [], comparedLength: 0,
                        hazardScheduleNo: ['7341200000','7341210000','7341220000','7341230000','7341240000'], excludeHazardSNo: [],
                        errorMsg: 'Basic Cooking hazard 73412 can only be applied to Boarding & Lodging (524) or Recreation (548) occupancies only.',errorPropertyName:'12'}
                        },
                        {name: 'filtering-hazard-sno-startwith-validator', options: {isInclude: false, scheduleNoStartWith: ['602'], excludedString: [], comparedLength: 0,
                        hazardScheduleNo: ['734'], excludeHazardSNo: [],
                        errorMsg: 'Cooking and Cooking Equipment hazards cannot be applied to Food Manufacturing (602) occupancies.',errorPropertyName:'14'}
                        },
                        {name:'hazard-with-combustibleClass-validator'},
                        {name:'hazard-with-occupant-sno-validator'}
                        // 12 and 30 needs to do
                      ]
                    },
                  },
                ],
              },
              { key: 'occupantIdentifier' },
              {
                key: 'occupantNumber'
              }
            ],
          }
        }
      ],
    });
  }
}
