/**
 * List of Calculations
 */
export enum CalculationEnum {
    PPC,
    CONTACTS,
    SCORE_CARD
}

/**
 * List of Calculation Types
 */
export enum CalculationTypeEnum { 
    LONG_RUNNING,       // Long running calculations (?) Poll status?
    VALIDATION,         // Formly calc.
    API                 // API/Service calculations 
}