import { DisableOrDebounceDirective } from './disable-or-debounce.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsDirective } from './analytics.directive';
import { GesturesDirective } from './gestures.directive';
import { TimeDiffDirective } from './timediff-label.directive';
import { TileColorDirective } from './tile-color.directive';
import { DisableDirective } from './disable-elements.directive';
import { MaxLengthLimitDirective } from './max-length-limit.directive';
import { LimitToDirective } from './limit-to.directive';
import { NumericInputDirective } from './numeric-input.directive';

@NgModule({
  declarations: [
    AnalyticsDirective,
    GesturesDirective,
    DisableOrDebounceDirective,
    TimeDiffDirective,
    TileColorDirective,
    DisableDirective,
    MaxLengthLimitDirective,
    LimitToDirective,
    NumericInputDirective,
  ],
  imports: [CommonModule],
  exports: [
    AnalyticsDirective,
    GesturesDirective,
    DisableOrDebounceDirective,
    TimeDiffDirective,
    TileColorDirective,
    DisableDirective,
    MaxLengthLimitDirective,
    LimitToDirective,
    NumericInputDirective,
  ],
})
export class DirectivesModule {}
