import { CalculationConfigInterface } from "src/app/interfaces/formly-builder/calculation-config.interface";
import { PublicProtectionService } from "../full-risk/formly-public-protection.service";
import { CalculationEnum, CalculationTypeEnum } from "src/app/enums/formly-builder/calculation.enum";
import { ContactInformationService } from "../overview/contact-information.service";

/**
 * Calculations Configurations
 */
export const CalculationConfig: CalculationConfigInterface[] = [
    { 
        enum: CalculationEnum.PPC,
        name: '',
        type: CalculationTypeEnum.VALIDATION,
        service: PublicProtectionService
    },
    { 
        enum: CalculationEnum.CONTACTS,
        name: '',
        type: CalculationTypeEnum.VALIDATION,
        service: ContactInformationService
    },
];
