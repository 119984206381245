import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyBuilderService } from '../../formly-builder.service';
import { CalculationInterface } from 'src/app/interfaces/formly-builder/calculation.interface';
import { FloorsRoofBuildingInformationDataModel } from 'src/app/models/formly-builder/formly/floors-roof-building-information-data.model';

@Injectable({
  providedIn: 'root',
})
export class FloorsRoofBuildingInformationService extends SectionServiceAbstract  implements CalculationInterface {
  calculationStatus: number;

  constructor(private formlyBuilderService: FormlyBuilderService,) {
    super();
  }

  async updateData(model: any, riskReportData: any) {
    // Check if data has changed
    const hasChanged: boolean = FloorsRoofBuildingInformationDataModel.hasChanged(model, riskReportData);
      if (hasChanged) {
        const buildingInfo = FloorsRoofBuildingInformationDataModel.to(model);
        return { ...riskReportData, ...buildingInfo };
      } else {
        return undefined;
      }
  }

  loadData(unused: any[], reportInfo: any, isS3: boolean, model: any): any {
    return undefined;
  }

 
}
