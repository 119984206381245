/**
 * Risk report JSON model for S3 object used as reference for team members
 */

/**
 * Checked with DTO 8/2/2023
 */
export class S3RiskReportModel {
    ReportIdentifier?: string;
    ExistingReportIdentifier?: string;
    RiskId?: string;
    BuildingDescription?: string;
    RiskTypeCodeValue?: string;
    SurveyStatusCodeValue?: string;
    BuildingFireRatedCodeValue?: string;
    BuildingFireConstructionCodeValue?: string;
    PublicProtectionClass?: string;
    YearBuilt?: number;
    BuildingWindCommercialStatisticalPlanCodeValue?: string;
    BasicGroup2Symbol?: string;
    CommercialStatisticalPlanTerritoryCode?: string;
    CommercialStatisticalPlanCode?: string;
    NumberOfFloors?: number;
    SprinklerRatingAsgr?: number;
    IsYearBuiltApproximate?: boolean;
    SprinklerTypeCodeValue?: string;
    BuildingWindConstructionCodeValue?: string;
    ClmClassCsp?: string;
    ColumnType?: string;
    PanelConstructionType?: string;
    CombustibilityClass?: string;
    FinalScheduleResultType?: string;
    FinalExposedBuildingGrade?: number;
    PercentWallCombustible?: number;
    PercentwallNoncombustible?: number;
    PercentWallModeratelyFireResistive?: number;
    PercentWallFireResistive?: number;
    PercentFloorCombustible?: number;
    PercentFloorFireResistive?: number;
    PercentFloorHourlyRatingEqualsOne?: number;
    PercentFloorHourlyRatingGreaterThanOne?: number;
    NeededFireFlow?: number;
    TerritoryCode?: string;
    BillingType?: any;
    ContactName?: string;
    ContactPhone?: string;
    EscortedByName?: string;
    EscortedByPhone?: string;
    HasMultipleFireDivisions?: boolean;
    BasementLevels?: number;
    ConstructionClass?: number;
    IsWindUplift90?: boolean;
    SkylightsRecognition?: number;      // short
    IsCc3?: boolean;
    IsMixedLowHighRise?: boolean;
    Is22gaRoof?: boolean;
    IsSteelLight?: boolean;
    IsSteelOtherThanLight?: boolean;
    OrderId?: any;           // long
    FieldRep?: string;
    Status?: string;
    IsOpenSided?: boolean;
    IsReinforcedConcrete?: boolean;
    IsConstructionClassOverride?: boolean;
    IsBasicGroup2SymbolOverride?: boolean;
    WaterSupplyWorksAdequacy?: any;     // decimal
    HydrantSpacingAdequacy?: any;       // decimal
    FireFlowAt20Psi?: number;
    FireDepartmentCompaniesAdequacy?: any;      // decimal
    OverallAdequacy?: any;
    HasUnprotectedMetalColumns?: boolean;
    PercentUnprotectedMetalColumnArea?: number;
    HasCombustibleColumns?: boolean;
    PercentCombustibleColumnArea?: number;
    HasNonCombustiblePanels?: any;
    PercentNonCombustiblePanelArea?: number;
    HasGlassPanels?: boolean;
    PercentGlassPanelArea?: number;
    HasSlowBurnPanels?: boolean;
    PercentSlowBurnPanelArea?: number;
    HasCombustiblePanels?: boolean;
    PercentCombustiblePanelArea?: number;
    RoofPercentLightFrameConstruction?: number;
    IsRoofPercentLightFrameConstructionNotAvailable?: boolean;
    FloorPercentLightFrameConstruction?: number;
    IsFloorPercentLightFrameConstructionNotAvailable?: boolean;
    ColumnWallThickness?: number;
    RooftopLocationPoint: ReportPointModel;
    IsCc4?: boolean;
    FileNumber?: string;
    HasInaccessibleArea?: boolean;
    InaccessibleAreaComment?: string;
    IsSprinklerNotUpdated?: boolean;
    IsWindNotUpdated?: boolean;
    DeleteReasonCode?: string;
    ConstructionVerification?: boolean;
    IsSprinklerReportDeleted: boolean;      // ManDTOry!
    IsWindReportDeleted: boolean;           // ManDTOry!
    HasFireDivisionRisks?: boolean;
    CreationCodeValue: string;              // ManDTOry!
    UpdateCodeValue: string;                // ManDTOry!
    HasBasement?: string;                   // string in DTO!
    IsHydrantsWithin1000Feet: string;       // string in DTO!
    EvidenceOfFireSprinkler: string;        // string in DTO!
    IsCombustibleMaterialAcrossPartyWall: boolean;
    IsYearBuiltFrOverride: boolean;
    IsYearBuiltUndetermined: boolean;
    HasSlowBurnMetalCompositeMaterialPanels: boolean;       // Mandatory
    HasCombustibleMetalCompositeMaterialPanels: boolean;    // ManDTOry
    IsSprinklered: boolean;                 // ManDTOry!
    CompletedDateTime?: any;                // DateTime

    /**
     * Properties not present in original RR added for Risk Report use
     */

    // @added properties. For Coverage. This are calculated from Floors and Roofs entries
    // Transient - They will be recreated. No need to save to S3.
    RoofTopCovering: string;                // ManDTOry!
    TotalArea: number;                      // ManDTOry!
    AboveGradeFloors: number;               // ManDTOry!

    // @added properties for Public Protection class COMING FROM THE ORDER
    // Transient - They will be recreated. No need to save to S3.
    PpcFpa?: string;
    PpcAtRisk?: string;
    CommercialTerritoryCode? : string;
    AlternativePpc?: string;
    PpcFireHydrant?:string;

    // @added properties to distinguish scenarios:
    // If true, use PublicProtectionClass; else use Order PpcAtRisk/    
    // If true, use FireProtectionArea; else use Order PpcFpa/    
    // If true, use TerritoryCode; else use Order CommercialTerritoryCodek/   
    // If true, use FireHydrant; else use Order PpcFireHydrant/    
    // Persistent / Non transient - To be saved to S3.
    IsPublicProtectionClass?: boolean; 
    IsFireProtectionArea?: boolean;
    IsTerritoryCode?: boolean;
    IsPpcFireHydrant?:boolean;

    // @added properties to the top of the JSON, along with RR, SR, WR.
    // Documented here for reference
    // Persistent / Non transient - To be saved to S3
    // RiskUIStatus: number
    // NumberOfPendingErrors: number

    // @added properties
    HasWallsChargeableColumns?: boolean;
    HasWallsChargeablePanels?: boolean;

    // See also additions as part of the ReportHazardModel

    /**
     * End of Properties not present in original RR added for Risk Report use
     */

    // Arrays
    AdditionDates?: AdditionDateModel[];
    CombustibleMezzanines?: CombustibleMezzanineModel[];
    Comments?: CommentModel[]
    Exposures?: ExposureModel[];
    FireDivisionRisks?: FireDivisionRiskModel[];
    FloorsAndRoofs?: FloorsAndRoofModel[]
    MetalCompositeMaterials?: MetalCompositeMaterialModel[];
    InternalProtections?: InternalProtectionModel[]
    Occupants?: OccupantModel[];
    ReportAddresses?: ReportAddressModel[];
    ReportAttachments?: ReportAttachmentModel[];
    ReportCalculations?: ReportCalculationModel[];
    ReportHazards?: ReportHazardModel[];
    ReportPhotos?: ReportPhotoModel[];
    ReportRelatedDates?: ReportRelatedDateModel[];
    RetiredOccupantNumbers?: RetiredOccupantNumberModel[];
    SecondaryConstructions?: SecondaryConstructionModel[];
    Walls?: WallModel[];

}

/**
 * Checked with DTO 8/2/2023
 */
export class AdditionDateModel {
    AdditionDateIdentifier: string;
    ReportIdentifier?: string;
    AdditionDate1?: string;     // DateTime
}

/**
 * Checked with DTO 8/2/2023
 */
export class CombustibleMezzanineModel {
    CombustibleMezzanineIdentifier: string;
    ReportIdentifier?: string;
    SqFootage?: number;
    FloorLevel?: number;
}

/**
 * Checked with DTO 8/2/2023
 */
export class CommentModel {
    CommentIdentifier: string;
    ReportIdentifier: string;
    CommentBy: string;
    CommentDateTime: any;
    CommentType: string;
    CommentSegments: CommentSegmentModel[];
}

/**
 * Checked with DTO 8/2/2023
 */
export class CommentSegmentModel {
    CommentSegmentIdentifier: string;
    CommentIdentifier: string;
    CommentSegmentSequenceNumber: number;
    CommentSegmentText: string;
}

/**
 * Checked with DTO 8/2/2023
 */
export class ConstructionTypeCodeModel {
    ConstructionTypeCodeValue: string;              // nullable
    ConstructionTypeCodeDescription: string;        // nullable
}

/**
 * Checked with DTO 8/2/2023
 */
export class ExposureModel {
    ExposureIdentifier: string;
    ReportIdentifier: string;
    ExposureDirection?: string;
    SurveyDistanceExposure?: number;
    RiskConstructionType?: string;
    RiskProtectionType?: string;
    ExposureConstructionType?: string;
    ExposureProtectionType?: string;
    LengthHeightFactor?: number;
    OccupancyType?: string;
    HasOutsideAutomaticSprinkler?: boolean;
    HasCommunications?: boolean;
    IsPassage?: boolean;
    PartyProtectionType?: string;
    PassageCombustibleType?: string;
    PassageProtectionType?: string;
    PassageLength?: number;
    IsPassageOpen?: boolean;
    IsPartyWall?: boolean;
    TankCapacity?: number;
    ScopesVersion: number;
}

/**
 * Checked with DTO 8/2/2023
 */
export class FireDivisionRiskModel {
    FireDivisionRiskIdentifier: string;
    ReportIdentifier: string;
    RiskId: string;                                 // nullable
}

/**
 * Checked with DTO 8/2/2023
 */
export class FloorsAndRoofModel {
    FloorAndRoofIdentifier: string;
    ReportIdentifier?: string;
    LevelType?: string;
    LevelStart?: number;
    LevelStop?: number;
    Width?: number;
    Length?: number;
    ConstructionType?: string;
    Damageability?: number;
    FireResistanceListing?: string;
    HourlyRating?: number;
    Bgiitype?: string;
    IsVerticalOpeningProtection?: boolean;
    HasDividingWall?: boolean;
    DeckConstruction?: string;
    SupportType?: string;
    IsCombustibleInsulation?: boolean;
    IsFireClassified?: boolean;
    IsFinishSmokeDevelopmentAbove200?: boolean;
    MasonryThickness?: number;
    CombustibleType?: string;
    IsWoodIronClad?: boolean;
    IsHeavyTimberColumns?: boolean;
    IsExtraHeavyTimberColumns?: boolean;
    HasFoamPlasticInsulation?: boolean;
    InsulationFlameSpread?: number;
    InsulationSmokeDevelopment?: number;
    IsInsulationUnlisted?: boolean;
    HasAcceptableThermalBarrier?: boolean;
    IsInsulationSlowburning?: boolean;
    HasFireRetardantPaint?: boolean;
    PaintFlameSpread?: number;
    PaintSmokeDevelopment?: number;
    IsLowestLevel?: boolean;
    IsLowestLevelCombustible?: boolean;
    IsAreaCalc: boolean;                    // ManDTOry!
    YearOfRoofCover?: number;
    IsYearOfRoofCoverApproximate?: boolean;
    ScopesVersion: number;                  // ManDTOry!
    HasMetalCompositeMaterial: boolean;     // ManDTOry!   
    MassTimberFireResistive?: string;
    MassTimberProtection?: string;
    DivisionWallLocation?: string;
    MetalCompositeMaterials: any[];

    // @added properties. For Coverage.
    // Transient - They will be recreated. No need to save to S3.
    LevelArea?: number;
    ConstructionMaterial?: string;
    PercentageOccupied: number[];     // Added to coverage. To be decided. Maybe not needed(strored here but used for occupants)
}

/**
 * Checked with DTO 8/2/2023
 */
export class MetalCompositeMaterialModel {
    MetalCompositeMaterialIdentifier: string;
    ReportIdentifier: string;
    FloorLevelStart?: number;
    FloorLevelStop?: number;
    PercentageWallAreaPerFloor?: number;
    FlameSpread?: number;
    SmokeDevelopment?: number;
    IsUnlisted?: boolean;
    IsThermalBarrierPresent?: boolean;
    Section: string;                            // nullable
    FloorAndRoofIdentifier?: string;
    WallIdentifier?: string;
}

/**
 * Checked with DTO 8/2/2023
 */
export class InternalProtectionModel {
    InternalProtectionIdentifier?: string;
    ReportIdentifier?: string;
    HasNoInternalProtection?: boolean;
    FireAlarmGrade?: number;
    IsAutoFireSelected?: boolean;
    HasWatchman?: boolean;
    WatchmanType?: string;
    PartialSupplyProtectedArea?: number;
    PartialSupplyAlarmType?: string;
    LimitedSupplyProtectedArea?: number;
    LimitedSupplyAlarmType?: string;
    IsStandardAutoSprinklerSelected?: boolean;
    SprinkleredStationType?: string;
    SprinkleredStationAlarmType?: string;
    HasStandardPeriodicInspectionService?: boolean;
    HasExtinguishers?: boolean;
    HasStandpipeHose?: boolean;
    AlarmSignalDestinationType?: string;
    TroubleSignalDestinationType?: string;
    AutoFireAlarmSystemCoverageType?: string;
    AutoFireAlarmInspectionAndTestFrequencyType?: string;
    IsSystemAndTransmitterListed?: boolean;
    IsRecieverListed?: boolean;
    Comment?: string;
    HasNonStandardRetransmission: boolean;
    AutomaticFireAlarmDetectionSystemToggle?: boolean;
}

/**
 * Checked with DTO 8/2/2023
 */
export class OccupantModel {
    OccupantIdentifier: string;
    ReportIdentifier?: string;
    OccupantNumber?: number;
    OccupantName?: string;
    SuiteNumber?: string;
    ScheduleNumber?: string;
    CombustibilityClass?: number;
    BasicGroup2Symbol?: string;
    CommercialStatisticalPlan?: string;
    IsCombustibilityClassOverride?: boolean;
    IsBaseGroupIioverride?: boolean;
    IsCommercialStatisticalPlanOverride?: boolean;
    SusceptibilityClass?: number;
    IsSusceptibilityClassOverride: boolean;             // ManDTOry!
    SortOrder?: number;
    OccupantComment?: string;
    ScopesVersion: number;                              // ManDTOry!

    // @added properties. For Currency.
    // Transient - They will be recreated. No need to save to S3.
    IsNewOccupant: boolean;                             // ManDTOry!
    IsDeleted: boolean;                                 // ManDTOry!
    /** below integer are optional which used for RF internal purpose, added for coverage */
    NumberOfAttics?: number;
    NumberOfMezzanine?: number;
    // End of added properties

    // Arrays
    OccupantHazards: OccupantHazardModel[];
    OccupantLevels: OccupantLevelModel[];
}

/**
 * Checked with DTO 8/2/2023
 */
export class OccupantHazardModel {
    OccupantHazardIdentifier: string;
    OccupantIdentifier: string;
    ScheduleNumber?: string;
    Comment?: string;
}

/**
 * Checked with DTO 8/2/2023
 */
export class OccupantLevelModel {
    OccupantLevelIdentifier: string;
    OccupantIdentifier: string;
    OccupantLevelType?: string;
    FloorLength?: number;
    FloorWidth?: number;
    LevelStart?: number;
    LevelStop?: number;
    AutomaticSprinklerType?: string;
    HasExtinguisher?: boolean;
    HasLimitedSupplyFireProtectionSystem?: boolean;
    LimitedSupplyFireProtectionSystemComment?: string;
    Percentage?: number;
    // Added this two fields for capturing and prepopulating the data in frontend.
    SprinklerDesign: string;
    SprinklerType: string;
}

/**
 * Checked with DTO 8/2/2023
 */
export class ReportAddressModel {
    ReportAddressIdentifier: string;            // ManDTOry!
    ReportIdentifier?: string;
    AddressSequence: number;                    // ManDTOry!
    LowNumber?: string;
    HighNumber?: string;
    Prefix?: string;
    PreDirection?: string;
    StreetName?: string;
    StreetType?: string;
    PostDirection?: string;
    City?: string;
    PostalCity?: string;
    StateCode?: string;
    Zip?: string;
    Zip4?: string;
    County?: string;
    AddressVerificationTypeCodeValue?: string;
    FireProtectionArea?: string;
    CommunityName?: string;
    IsAlias: boolean;                           // ManDTOry!
}

/**
 * Checked with DTO 8/2/2023
 */
export class ReportAttachmentModel {
    ReportAttachmentIdentifier: string;
    ReportIdentifier: string;
    AttachmentTypeCodeValue: string;                // nullable
    AttachmentSequence: number;
    AttachmentDescription: string;                  // nullable
    AttachmentTimestamp?: any;                      // DateTime
    Attachment?: any;                               // byte[] - nullable
    FileName: string;                               // nullable
}

/**
 * Checked with DTO 8/2/2023
 */
export class ReportBuildingInformationModel {
    ReportBuildingInformationIdentifier: string;
    ReportIdentifier: string;
    DisplaySequence: number;
    BuildingInformation: string;                    // nullable
    IsComment: boolean;
}

export class ReportCalculationModel {
    // TODO: Check with DTO pending
    ReportCalculationIdentifier: string;
    ReportIdentifier: string;
    BasicGroup2Symbol: string;
    BuildingFireRatedCodeValue: string;
    BuildingWindCommercialStatisticalPlanCodeValue: string;
    ClmClassCsp: string;
    ClmConstructionClass: any;
    CombustibilityClass: string;
    CommercialStatisticalPlanCode: string;
    ConstructionClass: string;
    FinishExteriorCombustibleType: string;
    FinishInteriorCombustibleType: string;
    FireDepartmentCompaniesAdequacy: any;
    FireFlowAt20Psi: number;
    HasNonStandardTransmission: boolean;
    HasStandardPeriodicInspectionService: boolean;
    HydrantSpacingAdequacy: any;
    IsConstructionClassOverride: boolean;
    LargestFloorEffectiveArea: number;
    LossCostEligibilityRating: string;
    NeededFireFlow: number;
    OtherFloorEffectiveArea: number;
    OverallAdequacy: any;
    PartialSupplyAlarmType: string;
    PartialSupplyProtectedArea: any;
    PublicProtectionClass: string;
    ScopesVersion: number;
    SprinklerRatingAsgr: number;
    SprinklerTypeCodeValue: string;
    SprinkleredStationAlarmType: string;
    SprinkleredStationType: string;
    WaterSupplyWorksAdequacy: any;
}

/**
 * Checked with DTO 8/2/2023
 */
export class ReportHazardModel {
    ReportHazardIdentifier: string;
    ReportIdentifier: string;
    ScheduleNumber?: string;
    Comment?: string;
    ScopesVersion: number;

    // @added properties. (Not in initial DTO)
    // Persistent / Non Transient - We need to save to S3.
    Description?: string;
    PhotoIdentifier?: string;
    // End of added properties
}

/**
 * Checked with DTO 8/2/2023
 */
export class ReportPhotoModel {
    ReportPhotoIdentifier: string;
    ReportIdentifier: string;
    PhotoIdentifier?: string;
    ReportPhotoType: string;        // Support new reportPhotoType for HAZARDS
}

/**
 * Checked with DTO 8/2/2023
 */
export class ReportPointModel {
    Latitude: number;
    Longitude: number;

    // @added property. For Coverage
    // Non Transient / Persistent - To be saved to S3.
    Confirmed: boolean;
    // End of added property
}

/**
 * Checked with DTO 8/2/2023
 */
export class ReportRelatedDateModel {
    ReportRelatedDateIdentifier: string;
    ReportIdentifier: string;
    ReportDateTypeCodeValue: string;
    ReportDateTime: string;
    ReportDateTypeCodeValueNavigation: any;
}

/**
 * Checked with DTO 8/2/2023
 */
export class ReportDateTypeCodeModel {
    ReportDateTypeCodeValue: string;
    ReportDateTypeCodeDescription: string;
}

/**
 * Checked with DTO 8/2/2023
 */
export class RetiredOccupantNumberModel {
    RetiredOccupantNumberIdentifier: string;
    ReportIdentifier: string;
    OccupantNumber: number;
}

export class SecondaryConstructionModel {
    // TODO: Check with DTO pending
    BuildingConditionComment: any;
    BuildingConditionType: string;
    CombustibleExteriorAttachmentArea: number;
    CombustibleExteriorAttachmentType: string;
    ConcealedRoofArea: any;
    FinishExteriorCombustibleType: string;
    FinishExteriorFlameSpread: any;
    FinishExteriorPercentOfStoriesAffected: any;
    FinishExteriorSmokeDevelopment: any;
    FinishInteriorCombustibleType: string;
    FinishInteriorFlameSpread: any;
    FinishInteriorPercentOfStoriesAffected: any;
    FinishInteriorSmokeDevelopment: any;
    GreenRoofCoverage: string;
    HasMetalCompositeMaterialFinishExterior: boolean;
    HasMetalCompositeMaterialFinishInterior: boolean;
    InchesOfAirSpace: any;
    IsCombustibleExteriorAttachmentSprinklered: boolean;
    IsFabricatedRoofFlameSpreadLessThan25: boolean;
    IsFinishExteriorQualifiedAsSlowBurning: boolean;
    IsFinishExteriorThermalBarrierPresent: boolean;
    IsFinishExteriorUnlisted: boolean;
    IsFinishInteriorQualifiedAsSlowBurning: boolean;
    IsFinishInteriorThermalBarrierPresent: boolean;
    IsFinishInteriorUnlisted: boolean;
    IsHeightMultiplierDoesNotApplied: boolean;
    IsHighlyDamageableExteriorFinishEifs: any;
    IsHighlyDamageableExteriorFinishOther: any;
    IsHighlyDamageableExteriorFinishRoofAreaOver25Percent: any;
    IsHighlyDamageableExteriorFinishSyntheticStucco: any;
    IsHighlyDamageableExteriorFinishWallAreaOver25Percent: any;
    IsLimitedCombustibleHighlyDamageableExteriorFinish: any;
    IsNonCombustibleHighlyDamageableExteriorFinish: any;
    LargestFloorArea: number;
    NumberOfOtherFloors: number;
    OtherFloorArea: number;
    PercentOfExteriorWallArea: any;
    PercentOfOtherFloors: number;
    RaisedSurfaceFloorArea: any;
    ReportIdentifier: string;
    RoofSurfaceArea: any;
    RoofSurfaceConstructionType: string;
    SecondaryConstructionIdentifier: string;
    SolarPanelsCoverage: string;
    TotalEffectiveArea: any;
}

export class WallModel {
    WallIdentifier: string;
    ReportIdentifier: string;
    LineNumber: number;
    Thickness?: number;
    WallClassification?: number;
    IsReinforcedConcrete?: boolean;
    Damagibility?: number;
    HourlyRating?: number;
    IsNoncombustible?: boolean;
    IsSlowBurning?: boolean;
    IsMasonryVeneer?: boolean;
    InsulationFlameSpread?: number;
    InsulationSmokeDevelopment?: number;
    IsUnlisted?: boolean;
    CombustibilityClass?: string;
    IsWoodIronClad?: boolean;
    FireResistiveListType?: string;
    IsFlameSpreadAbove200?: boolean;
    HasAcceptableThermalBarrier?: boolean;
    HasNote2?: boolean;
    PaintFlameSpread?: number;
    PaintSmokeDevelopment?: number;
    ConstructionTypeCodeValue?: string;
    Width?: number;
    Height?: number;
    IsCombustible?: boolean;
    CombustibilityRating?: string;
    HasFoamPlasticInsulation?: boolean;
    HasFireRetardantPaint?: boolean;
    ScopesVersion: number;                      // ManDTOry!
    HasMetalCompositeMaterial: boolean;         // ManDTOry!
    MassTimberFireResistive?: string;
    MassTimberProtection?: string;
    ConstructionTypeCode?: ConstructionTypeCodeModel;

    // @added properties. For coverage
    // Non Transient / Persistent - To be saved to S3.
    ConstructionMaterial?: string;
    ConstructionPercentage?: number;
    // End of added properties

    // Arrays
    MetalCompositeMaterials: MetalCompositeMaterialModel[];
}