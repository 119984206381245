import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { map, startWith } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { Constants } from 'src/app/services/util-service/constants';
export class WallsSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'walls',
      wrappers: [ 'simple-section-wrapper' ],
      props: {
        label: 'Walls',
        addType: 'Walls',
        addText: 'Add Walls',
        isCollapsable: true,
        isEmptySectionOnInit: false,
        isCollapseIconDisabled: true,
        actionButtons: [
          { action: 'add', label: Constants.btnText, isDisabled: false }
        ],
      },
      expressions: {
        'props.isDisabled': 'formState.service.mode !== 1',
        'props.actionButtons[0].isDisabled': (field: FormlyFieldConfig) => field.options.formState.service.mode !== 1,
        'props.actionButtons[0].label': (field: FormlyFieldConfig) => field.form.value.walls.wallsArray?.length ? 'Edit' : Constants.btnText,
        'props.isCollapsed': (field: FormlyFieldConfig) =>
          field.form.value.walls.wallsArray.length ? field.props.isCollapsed : true,
        'props.isCollapseIconDisabled': (field: FormlyFieldConfig) =>
          field.form.value.walls.wallsArray.length ? false : true // disable the expand collapse arrow if no entries
      },
      hooks: {
        onInit: (field) => {
          if(!field.form?.value?.walls?.wallsArray?.length) {
            field.props.isEmptySectionOnInit = true;
          }
        }
      },
      validators: {
        emptyWalls: {
          expression: (control: FormControl,field: FormlyFieldConfig) => {
            const walls = field.form?.value?.walls?.wallsArray.filter((wall) => !wall.isDeleted);
            if (walls?.length === 0) {
              return false;
            }
            return true;
          },
          message: 'You have to add at least one Wall.',
        },
      },
      fieldGroup: [
        {
          key: 'wallsArray',
          type: 'walls-array',
          fieldArray: {
            wrappers: ['error-info-wrapper'],
            fieldGroup: [
              {
                key: 'constructionType',
                name: 'Construction Type',
                type: 'simple-select',

                props: {
                  label: 'Construction Type',
                  required: false,
                  options: formlyBuilderService.wallsService.getConstructionTypeLookupData(),
                  optionsAvailable: true,
                  isAsteriskNotRequired: true,
                  // popOverOptionsClassName: 'large-dropdown',
                },
                expressions: {
                  'props.required': (field: FormlyFieldConfig) => field.options.formState.service.mode === 1 ? true : false,
                },
                hooks: {
                  onInit: (field) => {
                    const walls = field.parent.parent.parent.model;
                    const formlyService = field.options.formState.service;
                    formlyService.wallsService.onConstructionypeChange(field);
                    formlyService.wallsService.updatePercentageOccupied(walls);
                  }
                }
              },
              {
                key: 'constructionMaterial',
                name: 'Construction Material',
                type: 'simple-select',
                className: 'wall-construction-material',
                props: {
                  label: 'Construction Material',
                  required: false,
                  options: [],
                  optionsAvailable: true,
                  popOverOptionsClassName: 'xl-dropdown',
                  isAsteriskNotRequired: true,
                },
                expressions: {
                  'props.required': (field: FormlyFieldConfig) => field.options.formState.service.mode === 1 ? true : false,
                  'props.isDisabled': (field?: FormlyFieldConfig) => !field.form.value.constructionType
                },
                hooks: {
                  onInit: (field) => {
                    // formlyWallsService.updatePercentageOccupied(field)
                    let constructionMaterial;
                    this.formlyBuilderService.wallsService
                      .getConstructionMaterialLookupData()
                      .subscribe((data) => (constructionMaterial = data));
                    const constructionControl = field.form.get('constructionType');
                    //field.props.options = constructionMaterial;
                    field.props.options = constructionControl.valueChanges.pipe(startWith(''),
                      map((type) => {

                        let options = [];
                        if (type && type.length > 0 && field.options.formState.service.isSheetActive) {
                          options = constructionMaterial.filter((src) => src.type === type);
                          if (!options.find((option) => type === option.type)) {
                            field.formControl.setValue(null);
                          }
                        }
                        else if (constructionControl.value && constructionControl.value.length > 0 &&
                          field.options.formState.service.isSheetActive) {
                          options = constructionMaterial.filter((src) => src.type === constructionControl.value
                          );
                          if (!options.find((option) => constructionControl.value === option.type)) {
                            field.formControl.setValue(null);
                          }

                        }
                        return options;
                      })
                    );
                  },
                },
              },
              {
                key: 'isWallAddedNewlyInEditSheet',//rename isNewArrayItem
                defaultValue: false,
              },
              {
                key: 'isDeleted',
                defaultValue: false,
              },
              {
                key: 'constructionPercentage',
                name: 'Construction Percentage',
                type: 'rf-range',
                defaultValue: 0,

                className: 'range-style',
                props: {
                  label: 'Percent of Walls',
                  isValid: false
                },
                validators: {
                  wallPercentageRequired: {
                    expression: (control: FormControl,field: FormlyFieldConfig ) => {
                      const percentage = control.value;
                      if (percentage > 0) {
                        return true;
                      }
                      else if(percentage === 0) {
                        return false;
                      }
                    },
                    message: 'Wall percentage is required',
                  }
                },
                hooks: {
                  onInit: (field) => {
                    field.props.index = field.parent.key;
                    field.formControl.valueChanges.subscribe(
                      (value) => {
                        if (value !== null && value !== undefined && field.options.formState.service.isSheetActive) {
                          const walls = field.parent.parent.parent.model;
                          this.formlyBuilderService.wallsService.updatePercentageOccupied(walls);
                        }
                      });

                  }
                },
                expressions: {
                  'props.isValid': (model: FormlyFieldConfig) => model.form.value.totalConstructionPercentage === 100 ? true : false,
                },
              },
              {
                key: 'totalConstructionPercentage',
                type: 'text',
                props: {
                  suffix: ' Construction',
                  unit: '%',
                  isCorrect: false,
                  format: 'integer',
                },
                hooks: {
                  onInit: (field) => {
                    const walls = field.parent.parent.parent.model;
                    field.formControl.markAsTouched();
                    this.formlyBuilderService.wallsService.updatePercentageOccupied(walls);
                  }
                },
                expressions: {
                  'props.isCorrect': (model: FormlyFieldConfig) => model.form.value.totalConstructionPercentage === 100 ? true : false,
                },
                validators: {
                  percentageIncomplete: {
                    expression: (control: FormControl,field: FormlyFieldConfig) => {
                      if (control?.value) {
                        if (control?.value !== 100) {
                          return false;
                        }
                      }
                      return true;
                    },
                    message: 'Construction percentages must total 100%',
                  }
                }
              },
            ],
          },

        },
        {

        }
      ],
    });
  }
}
