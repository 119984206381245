import { EventEmitter, Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyBuilderService } from '../../formly-builder.service';
import { SectionEnum } from '../../section.config';
import { SystemTestingDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/system-testing-data.model';
import { UtilService } from 'src/app/services/util-service/util.service';
import { isEqual } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SystemTestingService extends SectionServiceAbstract {
  SaveToS3Event: EventEmitter<any> = new EventEmitter<any>();
  constructor(private formlyBuilderService: FormlyBuilderService,
    private utilService: UtilService) {
    super();
  }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    const systemTesting = SystemTestingDataModel.to(model.sprinklerReport.asgrSprinklerSystemTest, riskReportData.ReportIdentifier);
    systemTesting.ReportId = systemTesting?.ReportId ? systemTesting?.ReportId : riskReportData.ReportIdentifier;
    if (systemTesting) {
      systemTesting.MainDrainTestDate = this.utilService.convertDateToISOStringFormat(systemTesting.MainDrainTestDate);
      systemTesting.MainDrainDateAfterSurvey = this.utilService.convertDateToISOStringFormat(systemTesting.MainDrainDateAfterSurvey);
      systemTesting.DpvDateAfterSurvey = this.utilService.convertDateToISOStringFormat(systemTesting.DpvDateAfterSurvey);
      systemTesting.DpvTestDate = this.utilService.convertDateToISOStringFormat(systemTesting.DpvTestDate);
      systemTesting.DpvInstallationDate = this.utilService.convertDateToISOStringFormat(systemTesting.DpvInstallationDate);
      systemTesting.InitialPipeExamTestDate = this.utilService.convertDateToISOStringFormat(systemTesting.InitialPipeExamTestDate);
      systemTesting.FirePumpTestDate = this.utilService.convertDateToISOStringFormat(systemTesting.FirePumpTestDate);
      systemTesting.FirePumpDateAfterSurvey = this.utilService.convertDateToISOStringFormat(systemTesting.FirePumpDateAfterSurvey);
      systemTesting.IsDpvTestNotApplicable = this.formlyBuilderService.dryPipeValveTestService.calculateIsDpvTestNotApplicable();
      systemTesting.IsFirePumpTestNotApplicable = this.calculateIsFirePumpTestNotApplicable(model?.sprinklerReport?.asgrWaterSupply);
    }
    if (systemTesting.IsDpvTestNotApplicable) {
      systemTesting.HasDpvTest = null;
    }
    if (systemTesting.IsFirePumpTestNotApplicable) {
      systemTesting.HasFirePumpTest = null;
    }
    if(systemTesting?.OverAgeSprinklerTests?.length) {
      systemTesting?.OverAgeSprinklerTests?.forEach(overAgeSprinkler =>{
        if (overAgeSprinkler) {
          overAgeSprinkler.DateTested = this.utilService.convertDateToISOStringFormat(overAgeSprinkler.DateTested);
          overAgeSprinkler.YearOfIssue = this.utilService.convertDateToISOStringFormat(overAgeSprinkler.YearOfIssue);
        }
      });
    }
    const sprinklerData = {
      ...riskReportData.SprinklerReport,
      ...{ AsgrSprinklerSystemTest: systemTesting },
    };
    return { ...riskReportData, ...{ SprinklerReport: sprinklerData } };
  }

  loadData(sectionData: any[], reportInfo: any, isS3: boolean, model: any): any {
    return undefined;
  }

  async onApply(modalCtrl, field) {
    const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const riskReport = await formlyService.riskReport.updateSectionData(SectionEnum.FR_SR_SYSTEM_TESTING, false);
    const newModel = formlyService.sprinklerReportService.loadData(
      riskReport,
      riskReport,
      true,
      formlyService.riskReport.model,
      false
    );
    if (newModel) {
      formlyService.riskReport.model = { ...newModel };
    }
    formlyService.errorService.closePreviousToaster();
    field.props.isApplied = true;
    formlyService.systemTestingService.SaveToS3Event.emit();
    modalCtrl.dismiss();
  }

  onClose(modalCtrl, field, initialModel) {
    setTimeout(() => {
        const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
        const reportData = formlyService.riskReport.report;
        const newModel = formlyService.sprinklerReportService.loadData(reportData, reportData, true, formlyService.riskReport.model, true);
        if (newModel) {
          formlyService.riskReport.model = { ...newModel };
        }
        formlyService.errorService.closePreviousToaster();
      }, 100);
      modalCtrl.dismiss();   
  }
  // Check fire pump section enable/disable
  checkFirePumpSystemEntry(field, isFromModel = true) {
    let hideFirePumpTestValve = false;
    if (this.formlyBuilderService.isExistingRisk() &&
      (field.form?.root?.value?.sprinklerReport?.asgrSprinklerSystemTest?.firePumpValveTesting?.firePumpTests?.length ||
        field.form?.root?.value?.sprinklerReport?.asgrSprinklerSystemTest?.firePumpValveTesting?.firePumpTestDate ||
        field.form?.root?.value?.sprinklerReport?.asgrSprinklerSystemTest?.firePumpValveTesting?.firePumpDateAfterSurvey)) {
      hideFirePumpTestValve = false;
    } else if (field.form?.root?.value?.sprinklerReport?.asgrWaterSupply?.firePumpEvaluation?.isEvaluatedThroughBypassOnly) {
      hideFirePumpTestValve = false;
    } else if (field.form?.root?.value?.sprinklerReport?.asgrWaterSupply?.firePumpEvaluation?.soleSourceDependency) {
      hideFirePumpTestValve = false;
    } else {
      hideFirePumpTestValve = true;
    }
    if(isFromModel) {
      field.props.hideFirePumpTestValve = hideFirePumpTestValve;
    }
    return hideFirePumpTestValve;
  }

  /**
   * Compare system testing section with empty one
   * Needs import:
   * import { isEqual } from 'lodash';
   *
   * @param sectionObject
   * @returns
   */
  isSectionEdited(sectionObject: any): boolean {
    const emptySection = {
      alarmAndSupervisoryTests: [],
      firePumpTests: [],
      overAgeSprinklerTests: [],
      //internalSprinklerPipeConditions: [],
      waterSupplyTests: [],
      dryPipeValveTesting: {
        dryPipeValveTripTests: [],
        internalSprinklerPipeConditions: []
      },
      acceptanceTesting: {
        hasFlushingAndHydrostaticTests: null,
        hasNoFlushingAndHydrostaticTestEvidence: null
      },
      systemTesting: {},
      mainDrainTesting: {
        twoInchDrainTests: []
      },
      firePumpValveTesting: {}
    };

    // Compare provided section with empty
    return isEqual(sectionObject, emptySection);
  }

   /**
   * get recent fire pump test date   
   * @param field
   * @returns 
   */
  checkFirePumpTestDate(field){
    let mostRecentDate = null;
    field.model?.firePumpTests?.forEach(item => {
      // Access the testDate property from firePumpTestInput
      const testDate = new Date(item?.firePumpTestInput?.testDate);
      // Compare the current testDate with the mostRecentDate
      if (!mostRecentDate || testDate > mostRecentDate) {
        mostRecentDate = testDate;
      }
    });
    if(mostRecentDate){
       field.formControl?.setValue(mostRecentDate);
    }
  }

  calculateIsFirePumpTestNotApplicable(waterSupplyData) {
    if(waterSupplyData?.firePumpEvaluation?.soleSourceDependency) {
      return false;
    }
    return true;
  }

  // Clear the fire pump testing section which is inside the system testing based on water supply value
  clearFirePumpTesting(formlyBuilderService) {
    const riskReportData = formlyBuilderService.riskReport.report;
    const model = formlyBuilderService.riskReport.model;
    formlyBuilderService.riskReport.report = {
      ...riskReportData,
      SprinklerReport: {
        ...riskReportData.SprinklerReport,
        AsgrSprinklerSystemTest: {
          ...riskReportData.SprinklerReport.AsgrSprinklerSystemTest,
          ...{ HasFirePumpTest: null },
          ...{ IsFirePumpTestNotApplicable: true},
          ...{ FirePumpTestDate: null},
          ...{ FirePumpDateAfterSurvey: null},
          ...{ FirePumpTests: []}
        }
      }
    }

    formlyBuilderService.riskReport.model = {
      ...model,
      sprinklerReport: {
        ...model.sprinklerReport,
        asgrSprinklerSystemTest: {
          ...model.sprinklerReport.asgrSprinklerSystemTest,
          firePumpValveTesting: {
            ...model.sprinklerReport.asgrSprinklerSystemTest.mainDrainTesting,
            ...{ firePumpTestDate: null },
            ...{ firePumpDateAfterSurvey: null},
            ...{ hasFirePumpTest: null},
            ...{ firePumpTests: []}
          }
        }
      }
    }
  }

}

