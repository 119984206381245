import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';

/**
 * Class that represents the Formly Model for GreenConstruction
 */
export class MiscellaneousSecondaryConstructionDataModel {
    static sectionName = 'miscellaneousSecondaryConstruction';
    buildingConditionComment: string;
    buildingConditionType: string;
    combustibleExteriorAttachmentType: string;
    isCombustibleExteriorAttachmentSprinklered: boolean;
    combustibleExteriorAttachmentArea: number
    static fromS3(reports: any): MiscellaneousSecondaryConstructionDataModel {//wholedata from cached report data

        return {
            buildingConditionComment: reports?.SecondaryConstructions?.length >0 ? (reports?.SecondaryConstructions[0]?.BuildingConditionComment ? reports?.SecondaryConstructions[0]?.BuildingConditionComment : null) : null,
            buildingConditionType: reports?.SecondaryConstructions?.length >0 ? (reports?.SecondaryConstructions[0]?.BuildingConditionType ? reports?.SecondaryConstructions[0]?.BuildingConditionType : null) : null,
            combustibleExteriorAttachmentType: reports?.SecondaryConstructions?.length >0 ? (reports?.SecondaryConstructions[0]?.CombustibleExteriorAttachmentType!== null && reports?.SecondaryConstructions[0]?.CombustibleExteriorAttachmentType!== undefined && reports?.SecondaryConstructions[0]?.CombustibleExteriorAttachmentType!== 'NONE' ? reports?.SecondaryConstructions[0]?.CombustibleExteriorAttachmentType : null) : null,
            isCombustibleExteriorAttachmentSprinklered: reports?.SecondaryConstructions?.length >0 ? (reports?.SecondaryConstructions[0]?.IsCombustibleExteriorAttachmentSprinklered ? reports?.SecondaryConstructions[0]?.IsCombustibleExteriorAttachmentSprinklered : null) : null,
            combustibleExteriorAttachmentArea: reports?.SecondaryConstructions?.length > 0 ? (reports?.SecondaryConstructions[0]?.CombustibleExteriorAttachmentArea !== null || reports?.SecondaryConstructions[0]?.CombustibleExteriorAttachmentArea !== undefined || reports?.SecondaryConstructions[0]?.CombustibleExteriorAttachmentArea !== '' ? +reports?.SecondaryConstructions[0]?.CombustibleExteriorAttachmentArea : null) : null,
        };
    }

    /**
     * 
     */
    static to(model: any) {
        return {
            BuildingConditionComment: model?.SecondaryConstructions?.buildingConditionComment,
            BuildingConditionType: model?.SecondaryConstructions?.buildingConditionType,
            CombustibleExteriorAttachmentType: model?.SecondaryConstructions?.combustibleExteriorAttachmentType,
            IsCombustibleExteriorAttachmentSprinklered: model?.SecondaryConstructions?.isCombustibleExteriorAttachmentSprinklered,
            CombustibleExteriorAttachmentArea: model?.SecondaryConstructions?.combustibleExteriorAttachmentArea,
        };
    }

    /**
     * Check if the model has changed
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     * @returns 
     */
    static hasChanged(formlyModel: any, riskReportModel: any): boolean {
        return true;
    }
}