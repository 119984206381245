export interface IQueue {
  type: QueueTypes;
  model: string;
  identifier: string;
  required: boolean;
  url?: string;
}

export enum QueueTypes {
  S3Json = 'S3Json',
  S3Photos = 'S3Photos',
  Documents = 'S3Documents',
  CustomerAssets = 'S3CustomerAssets',
  RiskReport = 'S3RiskReport',
  Forms = 'S3Forms',
  RiskReportAttachments='S3RiskReportAttachments',
  SprinklerReportAttachments='S3SprinklerReportAttachments',

}

export interface IModelPhotos {
  ReportPhotoIdentifier: string;
  ReportIdentifier: string;
  PhotoIdentifier: string;
  ReportPhotoType: string;
  Id: string;
}

export interface IModelAttachments {
  Id: number;
  UniqueKey: string;
  Filename: string;
  FileName: string;
}

export enum SyncStatus {
  NotSynced = 'Not Synced',
  Synced = 'Sync Success',
  SyncFailed = 'Sync Failed',
  Untouched = 'Untouched',
}

export interface IOrderQueue {
  id: string;
  syncStatus: SyncStatus;
  dirty: boolean;
  orderTouched: boolean;
  photos: {};
  riskreport: {};
  docments: {};
}

export const saved = 'saved';
