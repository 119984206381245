// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { CameraResultType, CameraSource } from '@capacitor/camera';
import { Environment } from '../app/interfaces/env.interface';

export const environment: Environment = {
  analyticStreamName: 'fdcStagingKinesis-staging',
  production: false,
  showCoverage: true,
  searchDelay: 500,
  iSurveyAPIurl: 'https://gatewayuat.verisk.com/underwriting/isurvey/report/test/v1/api',
  mapboxGLAccessToken: 'pk.eyJ1IjoibXRjOTY4MCIsImEiOiJja3pjdWFja2kycHdmMnFwdmFqMGh3ZjFpIn0.LYrD9SMPup6uEeniCcw2Gw',
  googleMapsApiKey: 'AIzaSyCSCHlHnzuhal7t5aInOSZ1ooWMYvmHxgE',
  graphqlUrl :'https://isurveyapit.verisk.com/graphql',
  auth_config: {
    client_id: '0oa1a5c6esttGdz1E0h8',
    server_host: 'https://sso.int.verisk.com/oauth2/aus111i606aVW3Wp30h8',
    redirect_url: window.location.origin + '/callback',
    end_session_redirect_url: window.location.origin + '/logout',
    scopes: 'openid email profile offline_access',
    pkce: true,
  },
  BINs_config: {
    orgId: '104152',
    shipId: '104152',
    quoteback: 'Location123',
  },
  isEnabledLogs: true,
  audience: 'api://default',
  scheme: 'com.verisk.int.sso', //needs to match url scheme in iOS info.plist
  cameraDefaults: {
    resultType: CameraResultType.DataUrl,
    source: CameraSource.Camera,
    quality: 100,
    width: 1280,
    height: 960,
  },
  lookUpSyncInterval: 0.01,
  templateSyncInterval: 0.01,
  localNotificationDelay: 'hour',
  users: [
    '35711',
    '27317',
    '53654',
    '70817',
    '99034',
    '93409',
    '34231',
    '29288',
    '36448',
    '36452',
    '36633',
    '37177',
    '37352',
    '37372',
    '37401',
    '38865',
    '37160',
    '39126',
    '39163',
    '39392',
    '39754',
    '39752',
    '13327',
    '34035',
    '12852',
    '38356',
    '38364',
    '58900',
    '39618',
    '39165',
    '41244',
    '40671',
    '39000',
    '36635',
    '40615',
    '39753',
    '40442',
    '98149',
    '37614',
    '31472',
    '39162',
    '37617',
    '37399',
    '42182',
    '42179',
    '42643',
    '38953',
    '38920',
    '40391',
    '42241',
    '37921',
    '37803',
    '36702',
    '56398',
    '56121',
    '02018',
    '43204',
    '43440',
    '43906',
    '43905',
    '37018',
    '43920',
    '42091',
    '43209',
    '37400',
    '38475',
    '57258',
    '32527',
    '42091',
    '99034',
    '32527',
    '37352'
  ],
  isEnabledUWForms: true,
  isUWFormRelease: true,
  isBureauState: true,
  enabledForTesting: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
