/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { ValidationErrors, FormArray, FormGroup, FormControl } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { FormlyConfig, FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ErrorSheetComponent } from 'src/app/components/formly-builder/shared/error-sheet/error-sheet.component';
import { ErrorType, ErrorTypeEnum } from 'src/app/enums/formly-builder/error-group.enum';
import { ErrorModel, ErrorSectionModel } from 'src/app/models/formly-builder/error.model';
import { UtilService } from 'src/app/services/util-service/util.service';
import { SectionEnum } from './section.config';
import { ErrorMappings } from './error-mappings';
import { Constants } from '../util-service/constants';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FormlyErrorService {
  public numberOfErrors?: number = 0;
  isErrorShowing: boolean;

  constructor(
    private config: FormlyConfig,
    private toastController: ToastController,
    private utilService: UtilService,
    private modalCtrl: ModalController
  ) { }

  /**
   * Recursively traverse the Form looking for all errors
   *
   * @param formControlGroup FormGroup to start with
   * @param parent key of the parent FormGroup
   * @param blocking If true, return only blocking errors; else, return all errors (both blocking and non-blocking)
   * @param keyValue Key value of particular form group or formArray
   * @param shallowErrors If true it will get only shallowErrors, If false it will get both shallowErrros and deepErrors
   * @param checkTouchedControl If True it will check only touched controls for errors, if false it will check all fields
   *                            (touched and untouched controls)
   * @returns Array of form errors
   */
  public checkErrors(formControlGroup: FormGroup | FormArray, parent: string, blocking: boolean, keyValue?: string,
    shallowErrors?: boolean, checkTouchedControl?: boolean, skipFormControlError = false) {
    let formErrors = [];

    try {
      // Single fields section and One to many section with errors in current level will go here
      if (formControlGroup.errors && !skipFormControlError) {
        formErrors = this.getControlErrors(formControlGroup, keyValue, parent, blocking, shallowErrors, checkTouchedControl);
      }

      // One to many sections with errors inside the controls will go here
      if (formControlGroup.controls) {
        const currentControlKeys = Object.keys(formControlGroup.controls);
        currentControlKeys?.forEach((key) => {
          // If Specific case for Occupants, We need to avoid deleted occupants
          if (key === 'occupants' && formControlGroup['_fields']?.length && formControlGroup['_fields'][0]?.options?.formState?.service?.mode === 2) {
            const field = formControlGroup.get(key);
            if (field && field['controls']?.length) {
              field['controls']?.forEach(src => {
                if ((src?.value?.isDeleted === false) && (this.utilService.isCheckNull(src?.value?.doingBusinessAs))) {
                  const deepErrors = (this.getControlErrors(src, key, '', blocking, shallowErrors, checkTouchedControl));
                  formErrors = [...formErrors, ...deepErrors];
                }
              });
            }
          } else {
            // Else case
            const deepErrors = (this.getControlErrors(formControlGroup, key, parent, blocking, shallowErrors, checkTouchedControl));
            formErrors = [...formErrors, ...deepErrors];
          }          
        });
      }
    } catch (error) {
      console.error('checkErrors ' + error);
    }

    this.numberOfErrors = formErrors && !shallowErrors ? formErrors.length : this.numberOfErrors;
    return formErrors;
  }

  /**
   * Recursively traverse the Form looking for all errors
   * Extension of check Error method
   *
   * @param formControlGroup FormGroup to start with
   * @param key Key value of particular form group or formArray
   * @param parent key of the parent FormGroup
   * @param blocking If true, return only blocking errors; else, return all errors (both blocking and non-blocking)
   * @param shallowErrors if true it will get only shallowErrors, If false it will get both shallowErrros and deepErrors
   * @param checkTouchedControl if True it will check only touched controls for errors, if false it will check all fields
   *                            (touched and untouched controls)
   * @returns * array of form errors
   */
  private getControlErrors(formControlGroup: FormGroup | FormArray, key, parent: string, blocking: boolean, shallowErrors?: boolean, checkTouchedControl?: boolean): any {
    let formErrors = [];
    // TODO: Need to check the duplicate key issue with wall type in on combustible walls
    const formGroup = formControlGroup.get(key) ? formControlGroup.get(key) as FormGroup : formControlGroup;
    const controlErrors: ValidationErrors = formGroup?.errors;
    let prettyName = this.getKeyPrettyName(formGroup);
    if (formControlGroup instanceof FormArray && prettyName === '') {
      prettyName = parent + '[' + key + ']';
    }

    const branchPaths = this.getPaths(formControlGroup);
    const linkIds = this.getBranchLinkIds(formControlGroup);

    // Manually treat special cases
    if (key === "isOpenSided") {
      branchPaths.push("walls");
    }

    if (controlErrors != null) {
      const errorKeys = Object.keys(controlErrors);
      errorKeys.forEach(failedValidation => {
        // Special case
        if (failedValidation === 'checkboxCheck' && !branchPaths.length) {
          branchPaths.push("walls");
        }

        const templateOptions = formGroup['_fields'][0].props;
        const isNonBlockingError = (templateOptions?.isNonBlockingValidation) ? templateOptions.isNonBlockingValidation : false;
        if (checkTouchedControl) {
          if (formGroup?.touched) {
            formErrors.push(this.getErrorMessage(formGroup, controlErrors, failedValidation, prettyName, branchPaths, linkIds));
          }
        } else {
          if ((blocking && !isNonBlockingError) || !blocking) {
            formErrors.push(this.getErrorMessage(formGroup, controlErrors, failedValidation, prettyName, branchPaths, linkIds));
          }
        }
      });
    } else {
      // Sometimes controlErrors is null but THERE ARE ERRORS below
      const field = formControlGroup.get(key) ? formControlGroup.get(key) : formControlGroup;
      key = formControlGroup.get(key) ? key : field['_fields']?.length ? field['_fields'][0].key: '';
      if (!field.valid && !shallowErrors) {
        const deepErrors = this.checkErrors(formGroup, prettyName, blocking, key, shallowErrors, checkTouchedControl);
        formErrors = [...formErrors, ...deepErrors];
      } else if (field && !field.valid && shallowErrors && !checkTouchedControl) {
        let multipleValidations = false;
        let msg;
        const formlyFields: FormlyFieldConfig[] = field['_fields'];

        // Special case for occupants
        // We are filtering the occupants which are not deleted i.e. isDeleted : false and then validating the filtered occupants for fullrisk and coverage since the deleted occupants validation error does not make sense by using mode and key of occupants
        if ((formlyFields.length && (formlyFields[0]?.options?.formState?.service?.mode === 2 || formlyFields[0]?.options?.formState?.service?.mode === 1) && key === 'occupants')) {
          const filteredNewOccupant = [];
          field['controls']?.filter(src => {
            if ((src?.value?.isDeleted === false) && (this.utilService.isCheckNull(src?.value?.doingBusinessAs))) {
              filteredNewOccupant.push(src);
            }
          });
          const valid = filteredNewOccupant.every((el) => el.valid == true);
          if (!valid) {
            multipleValidations = true;
          }
        }
        // we are bypassing all of the occupants validation errors to prevent the duplication errors here for full risk & coverage modes
        else if ((formlyFields.length && formlyFields[0]?.options?.formState?.service?.mode === 1  && key != 'occupants') ||
          (formlyFields.length && formlyFields[0]?.options?.formState?.service?.mode === 2 && key != 'occupants')) {
          multipleValidations = true;
        } else {
          multipleValidations = true;
        }

        if (msg != undefined) {
          formErrors.push({ message: msg });
        } else if (multipleValidations === true) {
          msg = Constants.multipleValidationsFailed;
          formErrors.push({ message: msg });

          // Log all validations to debug console
          try {
            const deepErrors = this.checkErrors(formGroup, prettyName, blocking, key, false, checkTouchedControl);
            const summaryErrors = this.summarizeErrors(deepErrors);
            this.utilService.addTextConsoleLog('Risk Form Errors', summaryErrors);
          } catch (ignore) {}
        }
      }
    }

    // Sometimes control errors are there and also there are more errors below
    if (controlErrors !== null && formGroup?.controls && Object.keys(formGroup.controls).length) {
      const field = formControlGroup.get(key);
      if (field && !field.valid && !shallowErrors) {
        const deepErrors = this.checkErrors(field as FormGroup, prettyName, blocking, key, shallowErrors, checkTouchedControl, true);
        formErrors = [...formErrors, ...deepErrors];
      } else if (field && !field.valid && shallowErrors && !checkTouchedControl) {
        const msg = Constants.multipleValidationsFailed;
        formErrors.push({ message: msg });

        // Log all validations to debug console
        try {
          const deepErrors = this.checkErrors(formGroup, prettyName, blocking, key, false, checkTouchedControl);
          const summaryErrors = this.summarizeErrors(deepErrors);
          this.utilService.addTextConsoleLog('Risk Form Errors', summaryErrors);
        } catch (ignore) { }
      }
    }

    return formErrors;
  }

  /**
   * @param errorArray 
   * @returns pretty summary of validation errors
   */
  private summarizeErrors(errorArray: any[]): string {
    let message = '';

    try {
      const summaryArray = [];
      if (errorArray?.length > 0) {
        errorArray.forEach((error) => {
          const path = error?.branch ? error.branch.join(".") : '';
          const val = (error?.validation && error?.validation !== 'Required') ? `(${error?.validation}) ` : '';
          summaryArray.push(`${error?.name} - ${error?.message ? error.message : error?.label} ${val}[${path}]`);
        });
      }

      if (summaryArray?.length > 0) {
        message = summaryArray.join(' + ');
      }
    } catch(ignore) {}

    return message;
  }

  /**
   * To get Error Message from the field validations or to get validation messages from config file
   * @param formControlGroup 
   * @param controlErrors 
   * @param failedValidation 
   * @param prettyName 
   * @param branchName 
   * @param linkIds 
   * @returns 
   */
  private getErrorMessage(formControlGroup: FormGroup | FormArray, controlErrors, failedValidation, prettyName, branchName: string[], linkIds?: number[]) {
    // Usually the fieldName is the label...
    const field = formControlGroup['_fields'][0];
    let fieldName = field?.props?.label;
    if(fieldName === 'V¹'){
      fieldName = '';
    }
    // But for sections with a toggle as the first field, this toggle doesn't have a label...
    if (!fieldName) {
      const parent = field?.parent;
      if (!field?.type) {
        // Probably a section wrapper with an initial toggle here
        // Use the parent (the section) label as fieldName
        if (parent?.props?.label) {
          fieldName = parent?.props?.label;
        }
      } else if (field?.key === 'dpvSystem') {
        fieldName = 'Dry System';
      } else if (field?.key === 'pipeScheduleNumberOfSprinklers') {
        fieldName = 'Number of Sprinklers';
      } else if (field?.key) {
        // The "skylightsRecogintion" field and others
        fieldName = FormlyErrorService.unCamelize(field.key);
      } else if (!isNaN(Number(parent.key))) {
        // key is "0", "1", "2", Use the parent.parent label
        fieldName = parent?.parent?.props?.label;
      }
    }
    let msg = controlErrors[failedValidation].message
      ? controlErrors[failedValidation].message
      : controlErrors[failedValidation];
    if (typeof msg !== 'string') {
      let message = this.config.getValidatorMessage(failedValidation);
      const formlyFields = formControlGroup['_fields'];
      if (!message) {
        // TODO: Need to check the duplicate key issue with wall type in on combustible walls ( check the index 0) 
        if (formlyFields?.length && formlyFields[0].validation?.messages?.[failedValidation]) {
          message = formlyFields[0].validation.messages[failedValidation];
        }
      }
      if (typeof message === 'function') {
        msg = message(formControlGroup.errors[failedValidation], formlyFields[0]);
      }
      // TODO: make comparison case independant
      // Specific case for required errors
      if (failedValidation === 'required' || failedValidation === 'Required') {
        if (msg === true) {
          if (!fieldName?.length) {
            // Untreated case: must investigate
            // msg = message + " [Field Name Unknown]";
            msg = 'Required';
            console.warn("Field with no label");
          } else if (fieldName.trim().endsWith("?")) {
            // Lengthy question... ellipsize
            // msg = "The \"" + truncateString(fieldName, 40) + "\" field is required.";
            msg = "The '" + FormlyErrorService.ellipsizeString(fieldName, 40) + "' field is required";
          } else {
            msg = fieldName + " is required";
          }
        } else {
          console.error("Required Validation Error Message unexpected case");
        }
      } else if (failedValidation === 'Comments required') {
        // TODO: 
      }
    } else {
      if (msg === "Required" && (failedValidation === "required" || failedValidation === "fieldCommentValidation")) {
        if (!fieldName && field?.key) {
          fieldName = FormlyErrorService.unCamelize(field.key);
        }
        msg = fieldName + " is required";
      }
    }

    // Keep this here to identify edge cases
    // if (fieldName === 'Station Type') {
    //   console.log("Hello");
    // }

    failedValidation = failedValidation?.charAt(0)?.toUpperCase() + failedValidation?.slice(1);
    return { name: fieldName, validation: failedValidation, message: msg, label: prettyName, parent, branch: branchName, linkId: linkIds };
  }

  /**
   * Get up the tree field hierarchy from the provided group to the top root 
   * to get the array of branch names for a FormGroup
   * @param formControlGroup 
   * @returns Ordered array of branches
   */
  private getPaths(formControlGroup: FormGroup | FormArray): string[] {
    let branch: string[] = [];
    let currentGroup: FormGroup | FormArray = formControlGroup;
    while (currentGroup) {
      if (currentGroup['_fields']?.length === 1 && currentGroup['_fields'][0].key) {
        const fieldKey = currentGroup['_fields'][0].key;
        branch.push(fieldKey);
        currentGroup = currentGroup['parent'];
      } else {
        currentGroup = null;
      }
    }

    return branch.reverse();
  }

  /**
   * Get up the tree field hierarchy from the provided group to the top root 
   * to get the array of linkId to get to a FormGroup
   * @param formControlGroup 
   * @returns Ordered array of linkIds
   */
  private getBranchLinkIds(formControlGroup: FormGroup | FormArray): number[] {
    let branch: number[] = [];

    try {
      let currentGroup: FormGroup | FormArray | FormlyFieldConfig = formControlGroup;
      while (currentGroup) {
        // We still are at intermediate branches
        if (currentGroup['_fields']?.length === 1 && currentGroup['_fields'][0].key) {
          const currentFieldConfig = currentGroup['_fields'][0];

          // Stop debugger
          if (currentFieldConfig.key === "walls") {
            console.debug("Stop debugger");
          }

          if (currentFieldConfig.key === "ss2") {
            console.debug("Stop debugger");
          }

          if (currentFieldConfig.props?.linkId) {
            branch.push(currentFieldConfig.props?.linkId);
          }

          let parentCheck = currentGroup['parent'];
          if (parentCheck['_fields']?.length) {
            // Move up the FormControl hierarchy...
            currentGroup = currentGroup['parent'];
          } else {
            // Move up the FormlyFieldConfig hierarchy
            currentGroup = currentFieldConfig.parent;
          }
        } else if ((currentGroup as FormlyFieldConfig).props) {
          // has props
          const currentFieldConfig = currentGroup as FormlyFieldConfig;
          if (currentFieldConfig.props?.linkId) {
            branch.push(currentFieldConfig.props.linkId);
          }
          currentGroup = null;
        } else {
          // We reached the top
          currentGroup = null;
        }
      }
    } catch (error) {
      console.error(error);
    }

    return branch.reverse();
  }

  /**
   * Print Validation Errors to console
   * @deprecated Use checkErrors instead
   * @param targetGroup
   * @param parent
   * @param blocking
   */
  printErrors(targetGroup: FormGroup | FormArray, parent: string, blocking: boolean) {
    try {
      const errorArray = this.checkErrors(targetGroup, parent, blocking);
      this.utilService.addTextConsoleLog('Risk Form Errors', JSON.stringify(errorArray));
    } catch (ignore) { }
  }

  private getKeyPrettyName(formGroup: FormGroup | FormArray) {
    // Try to get a pretty name for the failed field
    const prettyName = '';
    try {
      // if (formGroup._fields && formGroup._fields.length === 1) {
      //   const arrayFields = formGroup._fields;
      //   prettyName = arrayFields[ 0 ].props.label;
      // }
    } catch (error) {
    }
    return prettyName;
  }

  /**
   * Show all errors in the current Formly Form
   *
   * @param form FormGroup node to start looking for errors in the Form
   */
  public async showAllErrors(options: FormlyFormOptions, form) {
    let message = '<ul>';

    // Internal Errors from Formly
    const errorArray = this.checkErrors(form, '', false);
    errorArray.forEach((error) => {
      let msg = '';
      if (typeof error.message === 'string') {
        msg = error.message;
        // } else if (typeof error.message === "boolean") {
      }
      if (error.name) {
        // message += '<li><b>' + error.parent + '/' + error.label + '</b> [' + error.name + ']: ' + msg + ' [' + error.validation + ']</li>';
        message += '<li><b>Formly</b> [' + error.name + ']: ' + msg + ' [' + error.validation + ']</li>';
      } else {
        message += '<li>' + msg + '</li>';
      }
    });

    // Add External Errors
    if (options?.formState?.externalErrors?.length) {
      options.formState.externalErrors.forEach((err: ErrorModel) => {
        message += `<li><b>${this.getErrorTypeName(err?.type)}</b> ${err?.message} - Field(s) [ ${err?.paths?.toString()} ]</li>`;
      });
    }

    // End message error list
    message += '</ul>';

    this.allErrorsToast(errorArray, message);
  }

  /**
   * Shows a Toast with all Error messages
   * @param errorArray 
   * @param message 
   */
  private async allErrorsToast(errorArray: ErrorModel[], message: string) {
    // position: 'top' | 'middle' | 'bottom'
    const toast = await this.toastController.create({
      header: (errorArray.length > 0) ? 'Form Errors' : 'Form has no errors',
      message,
      // duration: 5000,
      position: 'bottom',       // 'middle'
      cssClass: (errorArray.length > 0) ? 'error-toast' : 'toaster',
      buttons: [
        {
          text: '\u2573',
          role: 'cancel',
          handler: () => {
            // console.log('Close clicked');
          }
        }
      ]
    });

    await toast.present();

    // To set position to the toaster close button
    const buttonGroup = toast.shadowRoot.querySelector('.toast-button-group') as HTMLElement;
    buttonGroup.style.position = 'sticky';
    buttonGroup.style.top = '0';

  }

  /**
   * Show Errors in a Sheet
   * @param options 
   * @param form 
   */
  public async showAllErrorsSheet(options: FormlyFormOptions, form: any) {
    // Internal Errors from Formly
    const frontendErrors = this.checkErrors(form, '', false);
    let errorNum = frontendErrors.length ? frontendErrors.length : 0;

    // Get External Errors
    const externalErrors = [];
    if (options?.formState?.externalErrors?.length) {
      options.formState.externalErrors.forEach((err: ErrorModel) => {
        externalErrors.push(err);
      });
    }

    // Add external errors to number
    errorNum += externalErrors.length;
    
    // Pending
    const backendErrors = this.mergeErrors(externalErrors, frontendErrors);

    // Test for classified errors
    const errorMap = this.classifyErrors(form, backendErrors, frontendErrors);

    const modal = await this.modalCtrl.create({
      component: ErrorSheetComponent,
      breakpoints: [0.25, 0.5, 0.75, 1],
      initialBreakpoint: 1,
      componentProps: {
        title: 'Errors',
        errors: errorMap,
        errorNum: errorNum,
        errorArray: frontendErrors,
        form: form
      },
      cssClass: 'modal-wrapper schedule-search-results-modal',
      handle: false
    });
    modal.present();
    const role = await modal.onWillDismiss();
    if (role.role === 'cancel') {
      // Cancel
    } else if (role.role === 'save') {
      // Save
    }
  }

  private getErrorTypeName(type: ErrorTypeEnum) {
    return ErrorType.names[+type];
  }

  /**
   * 
   * @param form FormGroup | FormArray - Group / Array to be checked for errors
   * @param key string Key value of the formGroup or formArray (form param)
   * @param shallowErrors 
   * @param checkTouchedControl 
   */
  public async showErrors(form, key?: any, shallowErrors?: boolean, checkTouchedControl?: boolean) {
    const errorArray = this.checkErrors(form, '', false, key, shallowErrors, checkTouchedControl);
    this.populateErrorMessages(errorArray, checkTouchedControl);
  }

  public getNoOfErrors(form, shallowErrors?: boolean): number {
    const errorArray = this.checkErrors(form, '', false, '', shallowErrors);
    return errorArray.length;
  }

  /**
   *
   * @description Will display errors mesages in single toast
   * @param * formGroup (Group 2 or more formControls into a group inside the
   *  property controls and pass it to this method)
   * @param * checkTouchedControl - If true will check only touched controls, If false will check both touched and untouched controls
   * @param boolean [shallowErrors] If true will check only shallowErrors, If false will check both shallow and deep errors.
   */
  public showErrorsForGroupControls(formGroup, checkTouchedControl, shallowErrors?: boolean) {
    let errorArray = [];
    formGroup.controls.forEach((formControl) => {
      const deepErrors = this.checkErrors(formControl, '', false, '', shallowErrors, checkTouchedControl);
      errorArray = [...errorArray, ...deepErrors];
    });
    this.populateErrorMessages(errorArray, checkTouchedControl);
  }

  /**
   *
   * @description Will display toaster with given error messages in single toaster
   * @param * errorArray
   * @param boolean checkTouchedControl
   */
  public populateErrorMessages(errorArray: any, checkTouchedControl: boolean) {
    // Closing the prevoius toast
    let timeDelay = 0;
    if (errorArray?.length) {
      timeDelay = 200;
      this.closePreviousToaster();
    }
    let foundMultiErrorMsg = false;
    // Populating the error message as unordered list
    let message = '<ul class=list>';
    errorArray.forEach((error) => {
      let msg;
      if (typeof error.message === 'string') {
        msg = error.message;
      }
      if (msg === Constants.multipleValidationsFailed) {
        if(foundMultiErrorMsg) {
          return;
        }
        foundMultiErrorMsg = true;
      }
      if (!msg) {
        if (checkTouchedControl) {
          message += '<li>' + error.name + ' ' + error.validation + '</li>';
        } else {
          message += '<li>' + error.validation + '</li>';
        }
      } else {
        message += '<li>' + msg + '</li>';
      }
    });
    message += '</ul>';

    // Call method to create and display Toast
    // Kept a timedelay to hide the transition of closing previous toaster and opening the new one.
    if (errorArray?.length) {
      setTimeout(() => {
        this.showToaster(message);
      }, timeDelay);
    }
  }

  /**
   *
   * @description Will present the toaster with given message
   * @param * message - Stirng to be displayed
   */
  public async showToaster(message) {
    const toast = await this.toastController.create({
      message,
      position: 'bottom',
      cssClass: ['error-toast'],
      buttons: [
        {
          text: '\u2573',
          role: 'cancel',
          handler: () => {
            // console.log('Close clicked');
          }
        }
      ],
    });

    await toast.present();

    // To set position to the toaster close button
    const buttonGroup = toast.shadowRoot.querySelector('.toast-button-group') as HTMLElement;
    buttonGroup.style.position = 'sticky';
    buttonGroup.style.top = '0';

  }

  /**
   *
   * @description will close the previous toaster if any opened
   */
  public closePreviousToaster() {
    this.toastController.getTop().then((value) => {
      if (value) {
        this.toastController.dismiss();
      }
    });
  }

  /**
   *
   * @description
   *  1) For One to Many Sections and simple sections Will display errors If the user clicks on complete button
   *        (based on formState.isCompleteBtnClicked)
   *  2) In One to Many sections When we load the form for first time if there r any errors other than Empty validation
   *        it will display errors
   *  3) When the section is loaded if the section is Empty then it will not display error(based on field.props.isEmptySectionOnInit)
   * @param formState to get the isCompleteBtnClicked value
   * @param formControl to be checked for validity
   * @param fieldProps to get the isEmptySectionOnInit value
   * @return section valid state
   */
  public isSectionValid(formState, formControl, fieldProps, field?: FormlyFieldConfig) {
    if (formState?.isCompleteBtnClicked) {
      return formControl.valid;
    } else {
      if (fieldProps?.isEmptySectionOnInit !== undefined) {
        return formControl.valid || fieldProps.isEmptySectionOnInit;
      } else {
        if (fieldProps.checkTouchedControl === true) {
          const errorArray = this.checkErrors(field.formControl as FormGroup, '', false, '', fieldProps.shallowErrors, fieldProps.checkTouchedControl);
          return errorArray.length ? false : true;
        }
        return formControl.valid;
      }
    }
  }

  /**
   *
   * @description To check whether the level is valid or not (It will check for only touched fields)
   * @param * targetGroup
   * @param (FormControl | FormArray) [formControl]
   * @return *  {boolean}
   */
  public isLevelValid(targetGroup: any, formControl?: FormControl | FormArray): boolean {
    let isLevelValid = true;
    try {
      if (targetGroup?.controls) {
        Object.entries(targetGroup.controls).forEach((object) => {
          let isControlValid;
          if (object[1] && object[1]['controls']) {
            isControlValid = this.isLevelValid(object[1]);
          } else {
            isControlValid = this.checkFormControlValidity(object[1]);
          }
          isLevelValid = isLevelValid && isControlValid;
        });
        return isLevelValid;
      }
    } catch (error) {
      console.error('checkErrors ' + error);
    }
    return true;
  }

  /**
   *
   * @description Will check the forControl validity based on touched property
   * @param * formControl
   * @return boolean
   */
  private checkFormControlValidity(formControl: any): boolean {
    if (formControl?.touched && formControl?.errors) {
      return false;
    }
    return true;
  }

  /**
   *
   * @description Will mark all the formControls as touched
   * @param * props
   * @param (FormControl | FormArray) formControl
   */
  public markSectionAsTouched(props: any, formControl: FormControl | FormArray) {
    if (!props?.isEmptySectionOnInit) {
      formControl.markAllAsTouched();
    }
  }

  /**
   * Add External Errors errors in memory
   * @param errorData 
   */
  public addExternalErrors(options: FormlyFormOptions, errorData: ErrorModel[]) {
    if (errorData) {
      // Clean old external error list
      options.formState.externalErrors = [];

      if (errorData?.length && options?.formState?.externalErrors) {
        errorData.forEach((error) => options.formState.externalErrors.push(error));

        // Save external errors to localStorage
        this.saveExternalErrors(options, errorData);
      }

      this.notifyErrorsAvailable(options, true);
    }
  }

  /**
   * Save External Errors to Local Storage
   * @param options 
   * @param errorData 
   */
  public saveExternalErrors(options: FormlyFormOptions, errorData: ErrorModel[]) {
    try {
      let localStoredErrs;
      let offlineExternalErrors: Map<string, ErrorModel[]> = new Map<string, ErrorModel[]>();
      const localStoredErrsStr = localStorage.offlineExternalErrors;
      if (localStoredErrsStr) {
        localStoredErrs = JSON.parse(localStoredErrsStr);
        offlineExternalErrors = new Map(localStoredErrs);
      }

      offlineExternalErrors.set(options.formState?.service?.riskReport?.report?.ReportIdentifier, errorData);
      localStorage.offlineExternalErrors = JSON.stringify(Array.from(offlineExternalErrors));
    } catch (error) {
      console.warn("Save External Errors Error");
    }      
  }

  /**
   * Retrieve External Errors from Local Storage
   * @returns true if external errors are available for this ReportIdentifier
   */
  public retrieveExternalErrors(options: FormlyFormOptions): boolean {
    try {
      const storedExternalErrors = localStorage.offlineExternalErrors;
      if (storedExternalErrors) {
        const offlineExternalErrors: Map<string, ErrorModel[]> = new Map(JSON.parse(storedExternalErrors));
        if (offlineExternalErrors && options.formState?.service?.riskReport?.report?.ReportIdentifier) {
          const extErrors = offlineExternalErrors.get(options.formState?.service?.riskReport?.report?.ReportIdentifier);
          if (extErrors?.length) {
            // Store the external errors
            options.formState.externalErrors = extErrors;
            return true;
          } else {
            // No errors: Notify clear!
            options.formState?.service?.clearErrors();

            // Store locally
            this.saveExternalErrors(options, []);
          }
        }
      }
    } catch (error) {
      console.warn("Retrieve Stored External Errors");
    }

    return false;
  }

  /**
   * Notify subscribers (f.i. the title header ) there are errors ready to check.
   * @param options 
   * @param visiblity true if errors are to be shown 
   */
  public notifyErrorsAvailable(options: FormlyFormOptions, visiblity: boolean) {
    // Warning: the array of errors is stored in the options, but the observable is in the service
    if (options?.formState?.service) {
      options.formState.service.statusLineVisibility = visiblity;
    }
  }

  /**
   * Return an Array of errors classified in categories
   * @param form 
   * @param externalErrors 
   * @param errorArray 
   * @returns 
   */
  private classifyErrors(form, originalExternalErrors: ErrorModel[], frontendErrors: ErrorModel[]): ErrorSectionModel[] {
    let pendingErrs = frontendErrors;  

    // TODO: Filter duplicates
    let externalErrors = cloneDeep(originalExternalErrors);

    // @Warning: Do Floors and Roof first, to avoid floorsAndRoofs.buildingInformation to go to overview
    // Floors & Roof
    const floorsErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("floorsAndRoofs"));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("floorsAndRoofs"));
    floorsErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("FLRROF")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("FLRROF"));

    // Overview Errors
    const overviewErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("address"));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("address"));
    overviewErrors.push(...pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("publicProtection")));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("publicProtection"));
    overviewErrors.push(...pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("contactInformation")));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("contactInformation"));
    overviewErrors.push(...pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("buildingInformation")));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("buildingInformation"));
    overviewErrors.push(...pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("roofTopLocationPoint")));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("roofTopLocationPoint"));
    overviewErrors.push(...pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("survey")));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("survey"));

    // Errors without located branch added to Overview errors
    overviewErrors.push(...pendingErrs.filter((err: ErrorModel) => !err?.branch || err?.branch.length === 0));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => err?.branch && err?.branch.length !== 0);
    overviewErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("RSKOVR")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("RSKOVR"));    
    // External errors without id added to Overview errors
    overviewErrors.push(...externalErrors.filter((err: ErrorModel) => !err?.id));
    externalErrors = externalErrors.filter((err: ErrorModel) => err?.id);


    // Walls
    const wallsErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("walls"));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("walls"));
    wallsErrors.push(...pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("areChargeableColumn")));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("areChargeableColumn"));
    wallsErrors.push(...pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("areChargeablePanels")));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("areChargeablePanels"));
    wallsErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("RSKWLL")));  
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("RSKWLL"));

    // Occupants & Hazards
    const occupantsErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("occupants"));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("occupants"));
    occupantsErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("RSKOHS")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("RSKOHS"));

    // Secondary Construction
    const secondaryErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("secondaryConstructions"));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("secondaryConstructions"));
    secondaryErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("RSKSEC")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("RSKSEC"));

    // Internal protection
    const internalProtectionErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("internalProtections"));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("internalProtections"));
    internalProtectionErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("RSKINT")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("RSKINT"));    

    // +--------------------------------------+
    // | SPRINKLERS                           |
    // +--------------------------------------+
    // Water Supply
    const waterSupplyErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("asgrWaterSupply"));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("asgrWaterSupply"));
    waterSupplyErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("SWOVR")));
    waterSupplyErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("SWHYD")));
    waterSupplyErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("SWADT")));
    waterSupplyErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("SWPIP")));
    waterSupplyErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("SWPOC")));
    waterSupplyErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("SWFLS")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("SWOVR") &&
      !err?.id?.startsWith("SWHYD") && !err?.id?.startsWith("SWADT") &&
      !err?.id?.startsWith("SWPIP") && !err?.id?.startsWith("SWPOC") &&
      !err?.id?.startsWith("SWFLS"));
    waterSupplyErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("SW")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("SW"));

    // System Components
    const systemComponentErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("asgrSprinklerSystemComponent"));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("asgrSprinklerSystemComponent"));
    systemComponentErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("SCOMP")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("SCOMP"));

    // System Test
    const systemTestErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("asgrSprinklerSystemTest"));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("asgrSprinklerSystemTest"));
    systemTestErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("SSTST")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("SSTST"));

    // Non Sprinklered
    const nonSprinkleredErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("asgrNonSprinkleredObstructedArea"));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("asgrNonSprinkleredObstructedArea"));
    nonSprinkleredErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("SNONS")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("SNONS"));

    // Building Conditions
    const buildingConditionErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("asgrBuildingCondition"));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("asgrBuildingCondition"));
    buildingConditionErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("SBUIL")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("SBUIL"));

    // +--------------------------------------+
    // | WIND                                 |
    // +--------------------------------------+
    // Enhanced Wind
    const enhancedWindErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("enhancedWindRatingEligibility"));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("enhancedWindRatingEligibility"));
    enhancedWindErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("ENHELG")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("ENHELG"));
    enhancedWindErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("WNDREP04")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("WNDREP04"));
    // Wind Wall Envelope
    const windWallEnvelopeErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("wallEnvelope"));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("wallEnvelope"));
    windWallEnvelopeErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("WIENVP")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("WIENVP"));
    windWallEnvelopeErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("WLENVP")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("WLENVP"));
    windWallEnvelopeErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("WNDREP08")));  
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("WNDREP08"));
    // Wind Environment and Exposure
    const windEnvironmentErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("environmentsAndExposure"));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("environmentsAndExposure"));
    windEnvironmentErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("ENVEXP")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("ENVEXP"));    
    windEnvironmentErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("WNDREP05")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("WNDREP05"));
    // Roof Envelope
    const roofEnvelopeErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("roofEnvelope"));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("roofEnvelope"));
    roofEnvelopeErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("RFENVP")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("RFENVP"));    
    roofEnvelopeErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("WNDREP07")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("WNDREP07"));
    
    // Wind Frameworkerrors, isCollapsed: true
    const frameworkErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("windFramework"));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("windFramework"));
    // Predominant Framework errors missing: Add them here
    let additionalFrameworkErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("windReport") && err?.name === 'Framework');
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !(err?.branch?.includes("windReport") && err?.name === 'Framework'));
    frameworkErrors.push(...additionalFrameworkErrors);    
    frameworkErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("RFWORK")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("RFWORK"));
    frameworkErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("FRWORK")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("FRWORK"));    
    frameworkErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("WNDREP06")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("WNDREP06"));

    // Do Exposures at the end, to avoid the WindEnvironment and Exposures...
    const exposuresErrors = pendingErrs.filter((err: ErrorModel) => err?.branch?.includes("exposures"));
    pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.branch?.includes("exposures"));
    exposuresErrors.push(...externalErrors.filter((err: ErrorModel) => err?.id?.startsWith("RSKEXP")));
    externalErrors = externalErrors.filter((err: ErrorModel) => !err?.id?.startsWith("RSKEXP"));

    // The rest of the external errors are classified as "Others"
    pendingErrs.push(...externalErrors);

    const errorsByCategory: ErrorSectionModel[] = [];
    errorsByCategory.push({ section: "Overview", errors: overviewErrors, target: SectionEnum.FR_OVERVIEW });
    errorsByCategory.push({ section: "Walls", errors: wallsErrors, target: SectionEnum.FR_WALLS });
    errorsByCategory.push({ section: "Floors & Roof", errors: floorsErrors, target: SectionEnum.FR_FLOORS_ROOFS });
    errorsByCategory.push({ section: "Occupancy", errors: occupantsErrors, target: SectionEnum.FR_OCCUPANTS_HAZARDS });
    errorsByCategory.push({ section: "Secondary Construction", errors: secondaryErrors, target: SectionEnum.FR_SECONDARY_CONSTRUCTION });
    errorsByCategory.push({ section: "Exposures", errors: exposuresErrors, target: SectionEnum.FR_EXPOSURES });
    errorsByCategory.push({ section: "Internal Protection", errors: internalProtectionErrors, target: SectionEnum.FR_INTERNAL_PROTECTION });
    if (form?.controls?.evidenceOfFireSprinkler?.value === 'true' || form?.controls?.evidenceOfFireSprinkler?.value === true) {
      errorsByCategory.push({ section: "Sprinkler - Water Supply", errors: waterSupplyErrors, target: SectionEnum.FR_S_WATER_SUPPLY });
      errorsByCategory.push({ section: "Sprinkler - System Components", errors: systemComponentErrors, target: SectionEnum.FR_S_SYSTEM_COMPONENT });
      errorsByCategory.push({ section: "Sprinkler - System Testing", errors: systemTestErrors, target: SectionEnum.FR_SR_SYSTEM_TESTING });
      errorsByCategory.push({ section: "Sprinkler - Non - Sprinklered Areas", errors: nonSprinkleredErrors, target: SectionEnum.FR_S_NON_SPRINKLERED_AREAS });
      errorsByCategory.push({ section: "Sprinkler - Building Conditions", errors: buildingConditionErrors, target: SectionEnum.FR_S_BUILDING_CONDITION });
    }
    errorsByCategory.push({ section: "Wind - Enhanced Wind", errors: enhancedWindErrors, target: SectionEnum.FR_W_ENHANCED_WIND });
    errorsByCategory.push({ section: "Wind - Environment and Exposures", errors: windEnvironmentErrors, target: SectionEnum.FR_W_ENV_EXPOSURES });
    errorsByCategory.push({ section: "Wind - Roof Envelope", errors: roofEnvelopeErrors, target: SectionEnum.FR_W_ROOF_ENVELOPE });
    errorsByCategory.push({ section: "Wind - Wall Envelope", errors: windWallEnvelopeErrors, target: SectionEnum.FR_W_WALL_ENVELOPE });
    errorsByCategory.push({ section: "Wind - Framework", errors: frameworkErrors, target: SectionEnum.FR_W_WALL_ENVELOPE });
    errorsByCategory.push({ section: "Others", errors: pendingErrs, target: SectionEnum.FR_OVERVIEW });

    // DEBUG
    // errorsByCategory.push({ section: "Frontend Errors", errors: frontendErrors, target: SectionEnum.FR_OVERVIEW });
    // errorsByCategory.push({ section: "Backend Errors", errors: externalErrors, target: SectionEnum.FR_OVERVIEW });

    return errorsByCategory;
  }

  /**
   * Remove repeated / duplicate errors from external error list
   * 
   * @param externalErrors 
   * @param internalErrors 
   */
  private mergeErrors(externalErrors: ErrorModel[], internalErrors: ErrorModel[]): ErrorModel[] {
    let pendingErrs = externalErrors;

    externalErrors.forEach((error: ErrorModel) => {
      // Check if mapping available for this errorId
      const mapping = ErrorMappings.BackendErrorsMapping.get(error.id);

      // There is a mapping
      if (mapping) {
        // A frontend equivalent message present in internalErrors
        const frontEndError = internalErrors.find((err: ErrorModel) => err?.message === mapping);

        // Remove the backendErrorId from the externalErrors
        if (frontEndError) {
          pendingErrs = pendingErrs.filter((err: ErrorModel) => !err?.id?.startsWith(error.id));
        }
      }
    });

    return pendingErrs;
  }

  /**
   *
   * @param fields 
   * @returns true if at least on error is present in the FormlyFieldConfig array
   */
  public hasUIErrors(fields: FormlyFieldConfig[]): boolean {
    if (fields) {
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        if (!field.formControl.valid) {
          return true;
        }
      }
    }
    return false;
  }

  public static unCamelize(text: string): string {
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }

  public static truncateString(str: string, num: number = str.length, ellipsisStr = "...") {
    return str.length >= num
      ? str.slice(0, num >= ellipsisStr.length ? num - ellipsisStr.length : num) +
      ellipsisStr
      : str;
  }

  public static ellipsizeString(str: string, maxLength: number = str.length, ellipsisStr = "...") {
    // Trim the string to the maximum length
    let trimmedString = str.substring(0, maxLength);

    // Re-trim if we are in the middle of a word
    trimmedString = trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))

    return trimmedString + ellipsisStr;
  }
}
