/* eslint-disable @typescript-eslint/naming-convention */
import { Guid } from 'guid-typescript';


/**
 * Class that represents the Formly Model for Wind Env Exposure Model
 */
export class WindFrameworkDataModel {
  static sectionName = 'windFramework';

  reportId: string;
  hasRoofToWallConnection: boolean;
  isRoofToWallConnectionTypeMechanicalPredominant: boolean;
  isRoofToWallConnectionTypeGravityHeavyFramingPredominant: boolean;
  isRoofToWallConnectionTypeFrictionPredominant: boolean;
  isRoofToWallConnectionTypeGravityLightFramingPredominant: boolean;
  isRoofToWallConnectionTypeAdhesivePredominant: boolean;
  isRoofToWallConnectionTypeNotDiscernablePredominant: boolean;
  isRoofToWallConnectionTypeMechanical: boolean;
  isRoofToWallConnectionTypeGravityHeavyFraming: boolean;
  isRoofToWallConnectionTypeFriction: boolean;
  isRoofToWallConnectionTypeGravityLightFraming: boolean;
  isRoofToWallConnectionTypeAdhesive: boolean;
  isRoofToWallConnectionTypeNotDiscernable: boolean;
  primaryRoofSystemSpan: string;
  firstStoryHeight: string;
  typicalUpperStoryHeight: string;

  // TODO: Rename to read
  static fromS3(data: any): WindFrameworkDataModel {

    return {
      reportId: data.ReportId,
      hasRoofToWallConnection: data.HasRoofToWallConnection,
      isRoofToWallConnectionTypeMechanicalPredominant: data.IsRoofToWallConnectionTypeMechanicalPredominant,
      isRoofToWallConnectionTypeGravityHeavyFramingPredominant: data.IsRoofToWallConnectionTypeGravityHeavyFramingPredominant,
      isRoofToWallConnectionTypeFrictionPredominant: data.IsRoofToWallConnectionTypeFrictionPredominant,
      isRoofToWallConnectionTypeGravityLightFramingPredominant: data.IsRoofToWallConnectionTypeGravityLightFramingPredominant,
      isRoofToWallConnectionTypeAdhesivePredominant: data.IsRoofToWallConnectionTypeAdhesivePredominant,
      isRoofToWallConnectionTypeNotDiscernablePredominant: data.IsRoofToWallConnectionTypeNotDiscernablePredominant,
      isRoofToWallConnectionTypeMechanical: data.IsRoofToWallConnectionTypeMechanical,
      isRoofToWallConnectionTypeGravityHeavyFraming: data.IsRoofToWallConnectionTypeGravityHeavyFraming,
      isRoofToWallConnectionTypeFriction: data.IsRoofToWallConnectionTypeFriction,
      isRoofToWallConnectionTypeGravityLightFraming: data.IsRoofToWallConnectionTypeGravityLightFraming,
      isRoofToWallConnectionTypeAdhesive: data.IsRoofToWallConnectionTypeAdhesive,
      isRoofToWallConnectionTypeNotDiscernable: data.IsRoofToWallConnectionTypeNotDiscernable,
      primaryRoofSystemSpan: data.PrimaryRoofSystemSpan,
      firstStoryHeight: data.FirstStoryHeight,
      typicalUpperStoryHeight: data.TypicalUpperStoryHeight,
    };
  }
  static to(model: any, reportId?: Guid) {
    const hasRoofToWallConnection = (model?.isRoofToWallConnectionTypeNotDiscernable || model?.isRoofToWallConnectionTypeAdhesive ||
      model?.isRoofToWallConnectionTypeGravityLightFraming || model?.isRoofToWallConnectionTypeFriction ||
      model?.isRoofToWallConnectionTypeGravityHeavyFraming || model?.isRoofToWallConnectionTypeMechanical) ? true : false;

    return {
      ReportId: model?.reportId ? model?.reportId : reportId,
      HasRoofToWallConnection: hasRoofToWallConnection,
      IsRoofToWallConnectionTypeMechanicalPredominant: model?.isRoofToWallConnectionTypeMechanicalPredominant ?
        model?.isRoofToWallConnectionTypeMechanicalPredominant : false,
      IsRoofToWallConnectionTypeGravityHeavyFramingPredominant: model?.isRoofToWallConnectionTypeGravityHeavyFramingPredominant ?
        model?.isRoofToWallConnectionTypeGravityHeavyFramingPredominant : false,
      IsRoofToWallConnectionTypeFrictionPredominant: model?.isRoofToWallConnectionTypeFrictionPredominant ?
        model?.isRoofToWallConnectionTypeFrictionPredominant : false,
      IsRoofToWallConnectionTypeGravityLightFramingPredominant: model?.isRoofToWallConnectionTypeGravityLightFramingPredominant ?
        model?.isRoofToWallConnectionTypeGravityLightFramingPredominant : false,
      IsRoofToWallConnectionTypeAdhesivePredominant: model?.isRoofToWallConnectionTypeAdhesivePredominant ?
        model?.isRoofToWallConnectionTypeAdhesivePredominant : false,
      IsRoofToWallConnectionTypeNotDiscernablePredominant: model?.isRoofToWallConnectionTypeNotDiscernablePredominant ?
        model?.isRoofToWallConnectionTypeNotDiscernablePredominant : false,
      IsRoofToWallConnectionTypeMechanical:
        model?.isRoofToWallConnectionTypeMechanical ? model?.isRoofToWallConnectionTypeMechanical : false,
      IsRoofToWallConnectionTypeGravityHeavyFraming: model?.isRoofToWallConnectionTypeGravityHeavyFraming ?
        model?.isRoofToWallConnectionTypeGravityHeavyFraming : false,
      IsRoofToWallConnectionTypeFriction: model?.isRoofToWallConnectionTypeFriction ?
        model?.isRoofToWallConnectionTypeFriction : false,
      IsRoofToWallConnectionTypeGravityLightFraming: model?.isRoofToWallConnectionTypeGravityLightFraming ?
        model?.isRoofToWallConnectionTypeGravityLightFraming : false,
      IsRoofToWallConnectionTypeAdhesive: model?.isRoofToWallConnectionTypeAdhesive ?
        model?.isRoofToWallConnectionTypeAdhesive : false,
      IsRoofToWallConnectionTypeNotDiscernable: model?.isRoofToWallConnectionTypeNotDiscernable ?
        model?.isRoofToWallConnectionTypeNotDiscernable : false,
      PrimaryRoofSystemSpan: model?.primaryRoofSystemSpan,
      FirstStoryHeight: model?.firstStoryHeight,
      TypicalUpperStoryHeight: model?.typicalUpperStoryHeight,
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param windFramework Model from the S3 Risk Report
   * @returns
   */
  //TODO change name and values for windReport
  static hasChanged(formlyModel: any, windFrameworkData: any): boolean {
    return true;
  }
}

