import { Injectable } from '@angular/core';
import { DataStore, SortDirection } from 'aws-amplify';
import { Group } from 'src/models';
import { OrderModel } from '../../models/order/order.model';
import { FridService } from '../frid-service/frid.service';
import { intersectionBy, differenceWith } from 'lodash';
import { AmplifySaveService } from '../amplify/amplify.save';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class addToGroupService {
  private frID: string;
  private grpDeleted = new BehaviorSubject<boolean>(false);

  constructor(private fridService: FridService, private amplifySaveService: AmplifySaveService) {
    this.setFrId();
  }

  private async setFrId() {
    this.frID = await this.fridService.getToken();
  }

  /**
   * Gets group amplify model to save new group
   * @param groupItem
   * @returns
   */
  private async getGroupAmplifyModel(groupItem) {
    const group = {
      frID: this.frID,
      name: groupItem.GroupName,
      isSel: false, //groupItem.isSelected
      data: JSON.stringify({
        groupOrders: groupItem.groupOrders,
      }),
    };
    return group;
  }

  /**
   * Gets groups filter by frID with sorting using name field
   * @returns groups
   */
  public async getGroups(): Promise<any> {
    const groupsList = await DataStore.query(Group, (g) => g.frID.eq(this.frID), {
      sort: (s) => s.name(SortDirection.ASCENDING),
    });
    return groupsList;
  }

  /**
   * Gets group by name
   * @param name
   * @returns
   */
  public async getGroupByName(name) {
    const groupsList = await DataStore.query(Group, (g) => g.and((g) => [g.frID.eq(this.frID), g.name.eq(name)]));

    return groupsList[0];
  }

  public async saveNewGroup(GroupName: string, selectedOrders: OrderModel[]) {
    const group = await this.getGroupAmplifyModel({
      GroupName: GroupName,
      isSelected: false,
      groupOrders: selectedOrders,
    });
    return await this.amplifySaveService.saveData(new Group(group));
  }

  /**
   * Updates group name
   * @param GroupName
   * @param selectedOrders
   * @param id
   * @returns
   */
  public async updateGroupName(GroupName: string, selectedOrders: OrderModel[], id: string) {
    const original = await DataStore.query(Group, id);
    return await this.amplifySaveService.saveData(
      Group.copyOf(original, (updated) => {
        updated.name = GroupName;
        updated.data = JSON.stringify({
          groupOrders: selectedOrders,
        });
      })
    );
  }

  /**
   * Removes group
   * @param GroupName
   * @param selectedOrders
   * @returns
   */
  public async removeGroup(GroupName: string, selectedOrders: OrderModel[]) {
    if (selectedOrders.length > 0) {
      const group = await this.getGroupByName(GroupName);
      const diff = differenceWith(group.data['groupOrders'], selectedOrders, function(groupListValue, selectedOrderValue) {
        return groupListValue.id === selectedOrderValue.OrderAmplifyId;
      });
      return this.updateGroupName(GroupName, diff, group.id);
    }
  }

  /**
   * Removes duplicates
   * @param groupItem
   * @param groupsList
   * @returns duplicates
   */
  public removeDuplicates(groupItem, groupsList): Group[] {
    const orders = groupItem.data['groupOrders'];
    groupsList.forEach(async (group, index) => {
      if (groupItem.id !== group.id) {
        const groupOrders = [...group.data.groupOrders];
        const commonOrders = intersectionBy(orders, groupOrders, 'id');
        if (commonOrders.length > 0) {
          commonOrders.forEach((order) => {
            const idx: number = groupOrders.findIndex((result) => result.id == order.id);
            if (idx > -1) {
              groupOrders.splice(idx, 1);
            }
          });
          groupsList[index].data.groupOrders = groupOrders;
          await this.updateGroupName(group.name, groupOrders, group.id);
        }
      }
    });
    return groupsList;
  }

  /**
   * Removes empty groups
   * @param groups
   * @returns empty groups
   */

public async removeEmptyGroups(groups): Promise<Group[]> {
    const groupsToDelete: Group[] = [];
    // Find groups to delete
    for (const order of groups) {
      const groupOrders = order.data.groupOrders;
      if (!groupOrders.length) {
        const groupToDelete = await this.getGroupByName(order.name);
        groupsToDelete.push(groupToDelete);
      }
    }
    // Delete groups from DataStore
    await Promise.all(groupsToDelete.map(async (group) => {
      await this.updateGroupName(group.name, [], group.id);
      await DataStore.delete(group);
    }));
    // Filter out deleted groups
    const remainingGroups = groups.filter((order) => !groupsToDelete.some((group) => group.name === order.name));
    return remainingGroups;
  }

  async deletegroup(OrderAmplifyId){
      let groups = await this.getGroups();
      let groupInfo =  this.getcompletegroup(groups ,OrderAmplifyId);

      groups.forEach(async (group, index) => {
          const groupOrders = [...group.data.groupOrders];
      const idx: number = groupOrders.findIndex((result) => result.id == groupInfo?.id);
      if (idx > -1) {
        groupOrders.splice(idx, 1);
        this.updateGroupName(group.name, [], group.id);
        if(!groupOrders.length){
            await DataStore.delete(group).then(()=>{
               this.groupsRefresh();
            })
        }
        else{
          this.groupsRefresh();
        }
      }
    });
  }

 
  /**
   * Gets group name
   * @param groups
   * @param id
   * @returns group name
   */
  public getGroupName(groups: Group[], id): string {
    let groupOrders = [];
    groups.forEach((group: Group) => {
      groupOrders = groupOrders.concat(group.data['groupOrders']);
    });
    const index = groupOrders.findIndex((result) => result.id == id);
    const GroupName = index > -1 ? groupOrders[index].GroupName : null;
    return GroupName;
  }

  public getcompletegroup(groups: Group[], id){
    let groupOrders = [];
    groups.forEach((group: Group) => {
      groupOrders = groupOrders.concat(group.data['groupOrders']);
    });
    const index = groupOrders.findIndex((result) => result.id == id);
    const Group = index > -1 ? groupOrders[index] : null;
    return Group;
  }

  public groupsRefresh() {
    this.grpDeleted.next(true);
    return this.grpDeleted.asObservable();
  }

}



