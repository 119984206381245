/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { FormlyBuilderService } from '../../formly-builder.service';
import { RiskReportService } from '../../risk-report.service';
import { HookReuseableService } from '../../hook-reuseable.service';
import { ExposureDataModel } from 'src/app/models/formly-builder/formly/full-risk/exposures/exposure-data.model';
import { ExposuresDataModel } from 'src/app/models/formly-builder/formly/full-risk/exposures/exposures-data.model';
import { Guid } from 'guid-typescript';
import { AbstractControl } from '@angular/forms';

class OptionData {
  value: string;
  label: string;
  shortLabel?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ExposuresService extends SectionServiceAbstract {
  public isExposuresLoaded: boolean;

  private sideData: OptionData[] = [
    { value: 'NORT', label: 'North' },
    { value: 'SOUT', label: 'South' },
    { value: 'EAST', label: 'East' },
    { value: 'WEST', label: 'West' }
  ];
  private constructionTypeData: OptionData[] = [
    { value: 'MSNR', label: 'Masonry' },
    { value: 'NCMB', label: 'Non-Combustible', shortLabel: 'NC' },
    { value: 'FRAM', label: 'Frame' }
  ];
  private protectionData: OptionData[] = [
    { value: 'UNPR', label: 'Unprotected' },
    { value: 'SEPR', label: 'Semiprotected' },
    { value: 'BLNK', label: 'Blank'}
  ];
  private occupancyTypeData: OptionData[] = [
    { value: 'LIGH', label: 'Light (C1-C2)', shortLabel: 'Light' },
    { value: 'MEDI', label: 'Moderate (C3-C4)', shortLabel: 'Moderate' },
    { value: 'HIGH', label: 'High (C5)', shortLabel: 'High' }
  ];
  private partyProtectionTypeData: OptionData[] = [
    { value: 'SING', label: 'Single Class A Fire Door' },
    { value: 'DCAD', label: 'Double Class A Fire Door' }
  ];
  private passageCombustibleTypeData: OptionData[] = [
    { value: 'COMB', label: 'Combustible' },
    { value: 'FNCS', label: 'All Other Construction Types' }
  ];
  private passageProtectionTypeData: OptionData[] = [
    { value: 'UNPR', label: 'Unprotected' },
    { value: 'SING', label: 'Single Class A Fire Door' },
    { value: 'DCAD', label: 'Double Class A Fire Door' }
  ];
  private passageOpenData: OptionData[] = [
    { value: 'true', label: 'Open' },        // isPassageOpen true
    { value: 'false', label: 'Enclosed' }    // isPassageOpen false
  ];

  constructor(
    private formlyService: FormlyBuilderService,
    private riskReportService: RiskReportService,
    private hookReuseableService: HookReuseableService,
  ) {
    super();
  }

  getDirectionsLookupData(): Observable<any> {
    return of(this.sideData);
  }

  public getFilteredDirectionsLookupData(field: FormlyFieldConfig): Observable<any> {
    const usedDirections: string[] = this.getUsedDirections(field);
    const remainingDirections = this.sideData.filter((el) => { return !usedDirections.includes(el.value) });
    return of(remainingDirections);
  }

  public onCommunicationsChanged(field: FormlyFieldConfig) {
    // Clear fields if Communication is toggled off
    field?.formControl?.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      // filter(value => value === false)
    ).subscribe(value => {
      // Clear all communications values
      if (value === false) {
        try {
          this.disableCommunicationsPartyWall(field);
          field?.form?.get('isPassage')?.setValue(undefined);

          this.disableCommunicationsPassage(field);
          field?.form?.get('isPartyWall')?.setValue(undefined);
        } catch (error) { console.error(error); }
      }
    });
  }

  /**
   * Clear all communications PartyWall values
   * @param field 
   */
  private disableCommunicationsPartyWall(field: FormlyFieldConfig) {
    try {
      this.disableField(field, 'partyProtectionType');
    } catch (error) { console.error(error); }
  }

  public onCommunicationsPartyWallDisabled(field: FormlyFieldConfig) {
    // Clear fields if Communication is toggled off
    field?.formControl?.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(value => value === false)).subscribe(value => {
        this.disableCommunicationsPartyWall(field);
      });
  }

  /**
   * Clear all communications Passage values
   * @param field 
   */
  private disableCommunicationsPassage(field: FormlyFieldConfig) {
    try {
      this.disableField(field, 'passageCombustibleType');
      this.disableField(field, 'passageLength');
      this.disableField(field, 'passageProtectionType');
      this.disableField(field, 'isPassageOpen');
    } catch (error) { console.error(error); }
  }

  private disableField(field, fieldName) {
    const fieldControl: AbstractControl = field?.form?.get(fieldName);
    fieldControl?.setValue(undefined);
    fieldControl?.markAsUntouched();
    fieldControl?.markAsPristine();
  }

  public onCommunicationsPassageDisabled(field: FormlyFieldConfig) {
    // Clear fields if Communication is toggled off
    field?.formControl?.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(value => value === false)).subscribe(value => {
        this.disableCommunicationsPassage(field);
      });
  }

  private getUsedDirections(field): string[] {
    const exposureList = field.form.parent.value;
    const toRemove: string[] = [];

    if (exposureList?.length > 0) {
      const currentDirection = (field.model?.exposureDirection) ? field.model.exposureDirection : '';
      exposureList.forEach((exp: ExposureDataModel) => {
        if (exp.exposureDirection && exp.exposureDirection !== currentDirection) {
          toRemove.push(exp.exposureDirection);
        }
      });
    }

    return toRemove;
  }

  public getConstructionTypeLookupData(): Observable<any> {
    return of(this.constructionTypeData);
  }

  public getOpeningProtectionLookupData(): Observable<any> {
    return of(this.protectionData);
  }

  public getPassageProtectionTypeLookupData(): Observable<any> {
    return of(this.passageProtectionTypeData);
  }

  public getOccupancyTypeLookupData(): Observable<any> {
    return of(this.occupancyTypeData);
  }

  getPassageWayTypeLookupData(): any[] {
    return this.passageOpenData;
  }

  getPartyProtectionLookupData(): Observable<any> {
    return of(this.partyProtectionTypeData);
  }

  getPassageCombustibleTypeData(): Observable<any> {
    return of(this.passageCombustibleTypeData);
  }

  // Getters for lookupdata text
  public getSideLabel(param: string): string {
    const option = this.sideData.find((myValue) => myValue.value === param);
    return option ? option.label : '';
  }

  public getConstructionTypeLabel(param: string): string {
    const option: OptionData = this.constructionTypeData.find((myValue) => myValue.value === param);

    let label = '';
    if (option?.label) {
      label = (option.shortLabel) ? option.shortLabel : option.label;
    }
    return label;
  }

  public getOpeningProtectionLabel(param: string): string {
    const option = this.protectionData.find((myValue) => myValue.value === param);
    return option ? option.label : '';
  }

  public getOccupancyTypeLabel(param: string): string {
    const option: OptionData = this.occupancyTypeData.find((myValue) => myValue.value === param);

    let label = '';
    if (option?.label) {
      label = (option.shortLabel) ? option.shortLabel : option.label;
    }
    return label;
  }

  async updateData(model, riskReportData) {
    // Check if data has changed
    const hasChanged: boolean = ExposuresDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      const exposures = ExposuresDataModel.to(model.exposures);
      return { ...riskReportData, ...exposures };
    } else {
      return undefined;
    }
  }

  loadData(exposures: any[], fullData: any, isS3: boolean, model: any): any {
    const exposuresDataModel: ExposuresDataModel = {
      exposuresArray: [],
    };

    let exposureRecord;
    if (isS3) {
      exposureRecord = ExposuresDataModel.fromS3(exposures);
    } else {
      exposureRecord = ExposuresDataModel.from(exposures);
    }
    if (exposureRecord?.exposuresArray?.length && exposureRecord.exposuresArray.length > 0) {
      exposureRecord.exposuresArray.forEach((exposure) => {
        let myExposure;
        if (isS3) {
          myExposure = ExposureDataModel.fromS3(exposure);
        } else {
          myExposure = ExposureDataModel.from(exposure.data);
        }

        exposuresDataModel.exposuresArray.push(myExposure);
      });
    }
    return { ...model, ...{ exposures: exposuresDataModel } };
  }

  addExposure() {
    const newExposure: ExposureDataModel = {
      exposureIdentifier: Guid.create().toString(),
      reportIdentifier: this.formlyService.riskReport.report.ReportIdentifier,
      scopesVersion: 1
    };
    return newExposure;
  }

  /**
   * "Unprotected" and disabled if ExposureConstructionType = "Non-Combustible" or "Frame"
   * @param field 
   * @return true if field needs to be disabled
   */
  checkExposureProtectionTypeDisabled(field: FormlyFieldConfig): boolean {
    if (field.form?.value?.exposureConstructionType === this.constructionTypeData[1].value ||
      field.form?.value?.exposureConstructionType === this.constructionTypeData[2].value) {

      // Set "Unprotected"
      field.form.controls['exposureProtectionType'].patchValue(this.protectionData[0].value);
      return true;
    }
    return false;
  }

  /**
   * "Unprotected" and disabled if RiskConstructionType = "Non-Combustible" or "Frame"
   * @param field 
   * @return true if field needs to be disabled
   */
  checkRiskProtectionTypeDisabled(field: FormlyFieldConfig): boolean {
    if (field.form?.value?.riskConstructionType === this.constructionTypeData[1].value ||
      field.form?.value?.riskConstructionType === this.constructionTypeData[2].value) {

      // Set "Unprotected"
      field.form.controls['riskProtectionType'].patchValue(this.protectionData[0].value);
      return true;
    }
    return false;
  }

  getActionButtons(field: FormlyFieldConfig): any[] {
    let myLen = field.form?.value?.exposures?.exposuresArray?.length;
    let rc: boolean = myLen >= 4;
    return [{ action: 'add', label: 'Add', isDisabled: rc }];
  }
}
