/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { RiskReportDataOccupantLevel } from 'src/app/models/risk-report/risk-report-data.model';
import { FloorsAndRoofDataModel } from './floors-roof-data.model';

/**
 * Class that represents the Formly Model for OccupantLevel
 */
export class OccupantLevelDataModel {
  static sectionName = 'floors';

  floorNo: string;
  floorPercentage: number;
  //percentageOccupied: number;

  // New data
  name: string;
  level: number;      // @deprecated Use levelStart
  area: number;
  levelStart: number;
  levelStop: number;
  length: number;
  width: number;
  areaPerLevel: number;
  automaticSprinkler: string;

  occupantLevelIdentifier?: string;
  occupantIdentifier?: string;
  hasExtinguisher?: boolean;
  hasLimitedSupplyFireProtectionSystem?: boolean;
  limitedSupplyFireProtectionSystemComment?: string;
/**
 * @deprecated
 * @param alias
 * @returns
 */
  static from(alias: RiskReportDataOccupantLevel): OccupantLevelDataModel {
    let area = 0;
    let areaPerLevel = 0;
    if (alias.FloorLength && alias.FloorWidth) {
      areaPerLevel = alias.FloorLength * alias.FloorWidth;
    }
    if (alias.LevelStop > 0 && alias.LevelStart < alias.LevelStop) {
      area = areaPerLevel*(alias.LevelStop - alias.LevelStart + 1);
    }

    // TODO: Check changes to model! floorNo is not there now!!!
    return {
      name: FloorsAndRoofDataModel.getFloorName(alias.LevelType),
      floorNo: (FloorsAndRoofDataModel.getFloorName(alias.LevelType) !== null && alias.LevelStart !== null) ? (FloorsAndRoofDataModel.getFloorName(alias.LevelType)+alias.LevelStart) : null,
      floorPercentage:  alias.Percentage   !== null ? alias.Percentage : 0,
      level: alias.LevelStart,
      levelStart: alias.LevelStart,
      levelStop: alias.LevelStop,
      areaPerLevel: areaPerLevel,
      area: area,
      length: alias.FloorLength,
      width: alias.FloorWidth,
      automaticSprinkler: alias.AutomaticSprinklerType,
    };
  }

  static fromS3(alias: any): OccupantLevelDataModel {
    let areaPerLevel = 0;
    let area = 0;
    if (alias.FloorLength && alias.FloorWidth) {
      areaPerLevel = alias.FloorLength * alias.FloorWidth;
    }
    if (alias.LevelStop > 0 && alias.LevelStart < alias.LevelStop) {
      area = areaPerLevel*(alias.LevelStop - alias.LevelStart + 1)
    }
    else {
      area = areaPerLevel;
    }

    // TODO: Check changes to model! floorNo is not there now!!!
    return {
      name: FloorsAndRoofDataModel.getFloorName(alias.OccupantLevelType),
      floorNo: (FloorsAndRoofDataModel.getFloorName(alias.OccupantLevelType) !== null && alias.LevelStart !== null) ? (FloorsAndRoofDataModel.getFloorName(alias.OccupantLevelType)+alias.LevelStart) : null,
      floorPercentage: alias.Percentage   !== null ? alias.Percentage : 0,
      level: alias.LevelStart,
      levelStart: alias.LevelStart,
      levelStop: alias.LevelStop,
      areaPerLevel: areaPerLevel,
      area: area,
      length: alias.FloorLength,
      width: alias.FloorWidth,
      automaticSprinkler: alias.AutomaticSprinklerType,
      occupantLevelIdentifier: alias.OccupantLevelIdentifier? alias.OccupantLevelIdentifier : undefined,
      occupantIdentifier: alias.OccupantIdentifier ? alias.OccupantIdentifier : undefined,
      hasExtinguisher: alias.HasExtinguisher,
      hasLimitedSupplyFireProtectionSystem: alias.HasLimitedSupplyFireProtectionSystem,
      limitedSupplyFireProtectionSystemComment: alias.LimitedSupplyFireProtectionSystemComment
    };
  }

  /**
   * Returns a S3 RiskReport object from a Formly model
   */
  static to(model: any) {
    return {
      OccupantLevelIdentifier: model.occupantLevelIdentifier ? model.occupantLevelIdentifier : undefined,
      OccupantIdentifier: model.occupantIdentifier ? model.occupantIdentifier : undefined,
      OccupantLevelType: FloorsAndRoofDataModel.getLevelType(model.name),
      FloorLength: +model.length,
      FloorWidth: +model.width,
      Percentage: model.floorPercentage,
      LevelStart: model.levelStart ? +model.levelStart : null, // BugFix: LevelStart Forced to Number
      LevelStop: +model.levelStop ? +model.levelStop : null,
      AutomaticSprinklerType: model.automaticSprinkler ? model.automaticSprinkler : undefined,
      HasExtinguisher: (model.hasExtinguisher === undefined ) ? undefined : ((model.hasExtinguisher === null) ? undefined : model.hasExtinguisher),
      HasLimitedSupplyFireProtectionSystem: (model.hasLimitedSupplyFireProtectionSystem === undefined) ? undefined : ((model.hasLimitedSupplyFireProtectionSystem === null) ? undefined : model.hasLimitedSupplyFireProtectionSystem ),
      LimitedSupplyFireProtectionSystemComment: (model.limitedSupplyFireProtectionSystemComment === undefined) ? undefined : model.limitedSupplyFireProtectionSystemComment,
    };
  }
}
