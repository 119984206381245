/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { WindEnvironmentExposuresService } from './wind-environment-exposures.service';
import { WindEnvExposureDataModel } from 'src/app/models/formly-builder/formly/wind/wind-environment-exposure-data.model';
import { EnhancedWindDataModel } from 'src/app/models/formly-builder/formly/wind/enhanced-wind-data.model';
import { RoofEnvelopeDataModel } from 'src/app/models/formly-builder/formly/wind/roof-envelope-data.model';
import { WindFrameworkDataModel } from 'src/app/models/formly-builder/formly/wind/wind-framework-data.model';
import { WallEnvelopeDataModel } from 'src/app/models/formly-builder/formly/wind/wall-envelope-data.model';
import { WindLossHistoryDataModel } from 'src/app/models/formly-builder/formly/wind/wind-loss-hristory-data.model';
import { EnhancedWindService } from './enhanced-wind.service';
import { WindRoofEnvelopeService } from './wind-roof-envelope.service';
import { WindWallEnvelopeService } from './wind-wall-envelop.service';
import { WindFrameworkService } from './wind-framework.service';
import { WindLossService } from './wind-loss.service';
import { FormlyBuilderService } from '../formly-builder.service';



@Injectable({
  providedIn: 'root'
})
export class WindReportService extends SectionServiceAbstract {

  constructor(
    private enhancedWindService: EnhancedWindService,
    private windEnvironmentExposuresService: WindEnvironmentExposuresService,
    private windRoofEnvelopeService: WindRoofEnvelopeService,
    private windWallEnvelopeService: WindWallEnvelopeService,
    private windFrameworkService: WindFrameworkService,
    private windLossService: WindLossService,
    private formlyBuilderService: FormlyBuilderService
  ) {
    super();
  }

  loadData(windReport: any[], reportInfo: any, isS3: boolean, model: any): any {

    if (reportInfo.WindReport && Object.keys(reportInfo.WindReport).length) {
      // Enhanced Wind report Environment Exposure componenet
      const enhancedWindComponent: EnhancedWindDataModel = EnhancedWindDataModel.fromS3(reportInfo.WindReport, reportInfo,
        this.formlyBuilderService.isExistingRisk());

      // Wind Environment Exposure componenet
      const windComponent: WindEnvExposureDataModel = WindEnvExposureDataModel.fromS3(reportInfo.WindReport.EnvironmentsAndExposure);

      // Roof Envelope
      const roofEnvelopeData: RoofEnvelopeDataModel = RoofEnvelopeDataModel.fromS3(reportInfo.WindReport.RoofEnvelope, reportInfo);
      // Roof Envelope
      const wallEnvelopData: WallEnvelopeDataModel = WallEnvelopeDataModel.fromS3(reportInfo.WindReport.WallEnvelope, reportInfo);
      // Wind Frameork
      const windFrameworkData: WindFrameworkDataModel = reportInfo.WindReport.Framework ?
        WindFrameworkDataModel.fromS3(reportInfo.WindReport.Framework) :
        reportInfo.WindReport.Framework;

      // Wind Loss

      const windLossData: WindLossHistoryDataModel[] = [];
      reportInfo?.WindReport?.WindLossHistories ?
        reportInfo?.WindReport?.WindLossHistories?.forEach(windLoss => {
          const windLossHistoryData = WindLossHistoryDataModel.fromS3(windLoss);
          windLossData.push(windLossHistoryData);
        }) : reportInfo.WindReport.WindLossHistories;

      return {
        ...model, ...{
          windReport: {                                     // Formly Model branch names in lowercase; S3 Risk/Wind Report in uppercase
            environmentsAndExposure: windComponent,
            enhancedWindRatingEligibility: enhancedWindComponent,
            roofEnvelope: roofEnvelopeData,
            windFramework: windFrameworkData,
            wallEnvelope: wallEnvelopData,                             // We needed this to exist
            windLossHistories: windLossData
          }
        }
      };

    } else {
      return {
        ...model, ...{
          windReport: {
            environmentsAndExposure: {},
            enhancedWindRatingEligibility: {},
            roofEnvelope: {},
            windFramework: {},
            wallEnvelope: {},
            windLossHistories: {}
          }
        }
      };
    }
  };

  async updateFullWindReport(model: any, riskReportData: any) {
    const enhancedWindData = await this.enhancedWindService.updateData(model, riskReportData);
    const windEnvironmentExposuresData = await this.windEnvironmentExposuresService.updateData(model, riskReportData);
    const windRoofEnvelopeData = await this.windRoofEnvelopeService.updateData(model, riskReportData);
    const windWallEnvelopeData = await this.windWallEnvelopeService.updateData(model, riskReportData);
    const windFrameworkData = await this.windFrameworkService.updateData(model, riskReportData);
    const windLossData = await this.windLossService.updateData(model, riskReportData);

    const windReport = {
      ...riskReportData.WindReport,
      ...enhancedWindData.WindReport,
      ...windEnvironmentExposuresData.WindReport,
      ...windRoofEnvelopeData.WindReport,
      ...windWallEnvelopeData.WindReport,
      ...windFrameworkData.WindReport,
      ...windLossData.WindReport,
      ...{
        SurveyDate: model?.survey && model?.survey?.surveyDate ? model?.survey?.surveyDate : null,
        ReportId: riskReportData?.ReportIdentifier,
        RiskId: riskReportData?.RiskId ? riskReportData?.RiskId : '',
        IsPrometrixUploadPending: (riskReportData?.SprinklerReport?.IsPrometrixUploadPending !== null &&
          riskReportData?.SprinklerReport?.IsPrometrixUploadPending !== undefined)
          ? riskReportData?.SprinklerReport?.IsPrometrixUploadPending : true,
      }
    };
    return windReport;
  }
}
