import { AbstractControl, FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Guid } from 'guid-typescript';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Constants } from 'src/app/services/util-service/constants';

export class BuildingNameSectionModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe1 = new Subject<void>();  
  protected ngUnsubscribe2 = new Subject<void>();  
  protected ngUnsubscribe3 = new Subject<void>();  
  protected ngUnsubscribe4 = new Subject<void>();  
  protected ngUnsubscribe5 = new Subject<void>();  
  constructor(private formlyService: FormlyBuilderService) {
    super({
      wrappers: ['simple-section-wrapper'],
      key: 'buildingInformation',
      // className: 'outside-section building-name-container',
      className: 'building-name-container',
      props: {
        label: 'Building Information',
        isCollapsable: true,
        // checkTouchedControl: true,
        shallowErrors: false,
        showError: true,
      },
      validators: {
        validation: [{
          name: 'duplicate-array-validator',
          options: {
            objects: 'additionDates',
            property: 'additionDate1',
            message: 'Additions cannot be duplicated, make one entry.'
          }
        }]
      }, 
      hooks: {
        onInit: (field?: FormlyFieldConfig) => {
          // Mark all as touched if not a new risk = building name or year built or additionDates present
          const initialValues = field?.formControl?.value;
          if (initialValues?.buildingName !== "" || initialValues?.yearBuilt !== "" ||
            initialValues?.additionDates?.length || initialValues?.noOfStories !== 1) {
            field?.formControl?.markAllAsTouched();
            }
            field.formControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe5),
            distinctUntilChanged(), debounceTime(Constants.riskFormDataSyncDelay))
              .subscribe(value => {
                if (!field.formControl.pristine) {
                  formlyService.onSectionFieldChange(field, SectionEnum.FR_BUILDING, value, true);
                }
              });
          },
          onDestroy: () => {
            this.ngUnsubscribe5.next();
            this.ngUnsubscribe5.complete();
          }
        },
      fieldGroup: [
        {
          key: 'buildingName',
          type: 'input',
          wrappers: ['question-wrapper'],
          modelOptions: {
            updateOn: 'blur',
          },
          props: {
            label: 'Building Description',
            placeholder: 'Enter building name',
            isSectionLabelNormal: true,
            required: true,
            optionsAvailable: false,
            isAsteriskNotRequired: true,
            maxLength: 35,
            showError: false
            // pattern: "^(?!New Risk$).*$"
          },
          validators: {
            noNewRisk: {
              expression: (formControl: AbstractControl, field: FormlyFieldConfig) => {
                if (formControl.value === 'New Risk') {
                  return false;
                }
                return true;
              },
              message: Constants.invalidBuildingName
            },
          },
          hooks: {
           onInit:(field?: FormlyFieldConfig)=>{
            field.formControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe5),
            distinctUntilChanged(), debounceTime(Constants.riskFormDataSyncDelay))
              .subscribe(newBuildingName => {
                //Change Survey Page title
                const riskId = field?.options?.formState?.service?.riskReport?.model?.riskId;
                const title = field?.options?.formState?.service?.deepLinkService?.getRiskFormTitle(riskId, newBuildingName);
                field?.options?.formState?.service?.deepLinkService?.changeTitleHeader(field?.options?.formState?.orderDetailsPage, title);
              });
           },
            onDestroy: () => {
              this.ngUnsubscribe5.next();
              this.ngUnsubscribe5.complete();
            }
          },
        },
        {
          key: 'noOfStories',
          type: 'input',
          wrappers: ['question-wrapper'],
          props: {
            label: 'Number of Stories',
            isSectionLabelNormal: true,
            required: false,
            type: 'number',
            inputmode: 'tel',
            isAsteriskNotRequired: true,
            min: 1,
            maxLength: 4,
            isNonBlockingValidation: false,
            readonly: true,
            inputsize: 4,
            maxColSize: 6,    
            className: 'field-date',
          },
          hooks: {
            onInit: (field?: FormlyFieldConfig) => formlyService?.formlyBuildingInformationService?.getNumberOfStories(field)
          },
        },
        {
          wrappers: ['question-wrapper'],
          props: {
            label: 'Year Built',
            isSectionLabelNormal: true,
            showError: false
          },
          // Fields in fieldGroup will fit inline in the question wrapper
          fieldGroupClassName: 'question-wrapper-inline',
          fieldGroup: [
            {
              key: 'isYearBuiltFrOverride',
              type: 'custom-checkbox',
              props: {
                label: 'Override',
                // Force label shown inside QW
                forceLabel: true
              },
              // hooks: {
              //   onInit: (field: FormlyFieldConfig) => {
              //     field?.formControl?.valueChanges?.pipe(
              //       takeUntil(this.ngUnsubscribe2),
              //       distinctUntilChanged(),
              //       debounceTime(5000)).subscribe((value) =>
              //       formlyService.onSectionFieldChange(field, SectionEnum.FR_BUILDING, value, true));
              //   },
              //   onDestroy: () => {
              //     this.ngUnsubscribe2.next();
              //     this.ngUnsubscribe2.complete();
              //   }                 
              // },              
              expressions: {
                'props.isDisabled': (field: FormlyFieldConfig) => 
                  formlyService?.formlyBuildingInformationService?.isYearOverrideDisabled(field),                  
              },
            },
            {
              key: 'isYearBuiltApproximate',
              type: 'custom-checkbox',
              props: {
                label: 'Approximate',
                // Force label shown inside QW
                forceLabel: true
              },
              expressions: {
                'props.isDisabled': (field: FormlyFieldConfig) =>
                  formlyService?.formlyBuildingInformationService?.isApproximateYearBuiltDisabled(field),
              },
              // hooks: {
              //   onInit: (field: FormlyFieldConfig) => {
              //     field?.formControl?.valueChanges?.pipe(
              //       takeUntil(this.ngUnsubscribe3),
              //       distinctUntilChanged(),
              //       debounceTime(5000))
              //     .subscribe((value) =>
              //       formlyService.onSectionFieldChange(field, SectionEnum.FR_BUILDING, value, true));
              //   },
              //   onDestroy: () => {
              //     this.ngUnsubscribe3.next();
              //     this.ngUnsubscribe3.complete();
              //   }                
              // }              
            },
            {
              key: 'yearBuilt',
              type: 'simple-input',
              // modelOptions: {
              //   updateOn: 'blur',
              // },
              props: {
                required: true,
                type: 'number',
                inputmode: 'tel',
                isAsteriskNotRequired: true,
                maxLength: 4,
                isNonBlockingValidation: false
              },
              expressions: {
                'props.readonly': (field: FormlyFieldConfig) =>
                  formlyService?.formlyBuildingInformationService?.isYearBuiltDisabled(field)
              },
              hooks: {
                onInit: (field?: FormlyFieldConfig) => field.formControl.valueChanges.subscribe(val=>{
                    formlyService?.formlyBuildingInformationService?.updateAdditionDates(field);})
                  },
              validators: {
                lessThan: {
                  expression: (c) => (c.value && c.value !== "") ? c.value > 1752 : true,
                  message: 'Year built cannot be before 1753',
                },                
                futureDate: {
                  expression: (c) => (c.value && c.value !== "") ? (c.value < (new Date().getFullYear() + 1)) : true,
                  message: 'Year built cannot be in the future'
                }
              }              
            },
          ]
        },
        {
          wrappers: ['simple-section-wrapper'],
          props: {
            isCollapsable: true,
            label: 'Additions',
            isSectionLabelNormal: true,
            showError: false
          },         
          fieldGroup: [
            {
              type: 'rf-common-flex-grid',
              key: 'additionDates',
              className: 'addition-fields',
              props: {
                // isCardEnabled: true,
                showAddButton: true
              },              
              fieldArray: {
                fieldGroup: [
                  {
                    className: 'addition-label',
                    template: "<span>Addition</span>"
                  },
                  {
                    key: 'additionDate1',
                    type: 'simple-input',
                    modelOptions: {
                      updateOn: 'blur',
                    },
                    className: 'addition-input',
                    props: {
                      label: 'Addition year',     // Moved to template
                      isLabelDisabled: true,
                      required: true,
                      type: 'number',
                      inputmode: 'tel',
                      maxLength: 4,
                      isNonBlockingValidation: false,
                      isHorizontalOrientation: true,
                    },
                    hooks: {
                      // onInit: (field?: FormlyFieldConfig) => field?.formControl?.valueChanges?.pipe(
                      //   takeUntil(this.ngUnsubscribe5),
                      //   distinctUntilChanged(),
                      //   debounceTime(5000),
                      //   tap((newAdditionYear) => formlyService.onSectionFieldChange(field, SectionEnum.FR_BUILDING, newAdditionYear, true))),
                      // onDestroy: () => {
                      //   this.ngUnsubscribe5.next();
                      //   this.ngUnsubscribe5.complete();
                      // }
                    },
                    validators: {
                      validation: [{ name: 'min-addition-year-validator', options: { compareWith: 'section-occupants' } }],                     
                      
                      futureDate: {
                        expression: (c: FormControl) => (c.value && c.value !== "") ? (c.value < (new Date().getFullYear() + 1)) : true,
                        message: 'Year of addition cannot be in the future'
                      },                     
                    },
                  },
                  {
                    key: 'reportIdentifier',
                    hooks: {
                      onInit: (field?: FormlyFieldConfig) => field?.form?.get('reportIdentifier')?.patchValue(field?.options?.formState?.frm?.reportIdentifier)
                    }
                  },
                  {
                    key: 'additionDateIdentifier',
                    hooks: {
                      onInit: (field?: FormlyFieldConfig) => field?.form?.get('additionDateIdentifier')?.setValue(Guid.create().toString())
                    }                    
                  },                  
                ]
              },             
            }
          ]
        },
      ]
    });
  }
}
