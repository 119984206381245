import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class FireSprinklerSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'evidenceOfFireSprinkler',
      type: 'segment',
      wrappers: [ 'question-wrapper' ],
      className: 'evidence-of-fire-sprinkler',
      props: {
        label: 'Presence of Fire Sprinkler System',
        required: true,
        optionsAvailable: false,
        firstColSize: 6
      },
      validators: {
        validation: [ { name: 'fire-sprinkler-validator' } ],
      },
      hooks: {
        onInit: (field : FormlyFieldConfig) => {
          const formlyService = field.options.formState.service;
          formlyService.fireSprinklerService.updateOccupantData(field);
        },
        afterViewInit: (field: FormlyFieldConfig) => {
          const formlyService = field.options.formState.service;
          formlyService.fireSprinklerService.
            valueChangeSubscription(field, SectionEnum.FR_FIRE_SPRINKLER, SectionEnum.FR_FIRE_SPRINKLER, 0);
        },
        onDestroy: (field: FormlyFieldConfig) => {
          const formlyService = field.options.formState.service;
          formlyService.fireSprinklerService.onDestoryHook();
        }
        /* onInit: (field : FormlyFieldConfig) => {
           if(field.formControl.value){
             field.formControl.markAsTouched();
           }
         }*/
      }
    });
  }
}
