/* eslint-disable max-len */
import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionConfig, SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Constants } from 'src/app/services/util-service/constants';

export class InternalProtectionSectionModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe = new Subject<void>();
  constructor() {
    super({
      id: SectionConfig.get(SectionEnum.FR_INTERNAL_PROTECTION).name,
      key: 'internalProtections',
      className: 'internal-protection-container',
      expressions: {
        'props.isDisabled': 'formState.service.mode !== 2',
      },
      fieldGroup: [
        { key: 'internalProtectionIdentifier' },
        { key: 'reportIdentifier' },
        {
          key: 'interProtection',
          wrappers: [ 'simple-section-wrapper' ],
          props: {
            label: 'Internal Protection',
            isCollapsable: true,
            shallowErrors: false
          },
          fieldGroupClassName: 'internal-protection',
          fieldGroup: [
            {
              fieldGroupClassName: 'display-flex',
              wrappers: [ 'question-wrapper' ],
              props: {
                label: 'First Aid Equipment',
                showError: false
              },
              fieldGroup: [
                {
                  key: 'hasStandpipeHose',
                  type: 'custom-checkbox',
                  props: {
                    label: 'Standpipe and hose',
                  },
                  className: 'padding-right-5 checkbox-label',
                },
                {
                  key: 'hasExtinguishers',
                  type: 'custom-checkbox',
                  props: {
                    label: 'Extinguishers',
                    disabled: true,
                  },
                  className: 'checkbox-label',
                  hooks: {
                    onInit: (field) => {
                      const fieldValue = field?.options?.formState?.service?.formlyInternalProtectionService?.calculateHasExstingUser();
                      field.formControl.setValue(fieldValue ? fieldValue : false);
                    }
                  }
                },
              ],
            },
            {
              wrappers: [ 'question-wrapper' ],
              props: {
                label: 'Watchman',
                showError: false
              },
              fieldGroupClassName: 'display-flex',
              fieldGroup: [
                {
                  key: 'hasWatchman',
                  type: 'custom-checkbox',
                  props: {
                    label: 'Has Watchman'
                  },
                  hooks: {
                    onInit: (field) => {
                      const formlyService = field.options.formState.service;
                      formlyService.formlyInternalProtectionService.enableOrDisableWatchmanType(field);
                      field?.formControl?.valueChanges.subscribe(value => {
                        formlyService.formlyInternalProtectionService.enableOrDisableWatchmanType(field);
                      });
                    }
                  },
                },
                {
                  key: 'watchmanType',
                  type: 'rf-select',
                  className: 'watchman-type-field-style',
                  props: {
                    readonly: true,
                    required: true,
                    options: [
                      { value: 'CENT', label: 'Central Station' },
                      { value: 'PROP', label: 'Proprietary' },
                      { value: 'NONE', label: 'No Monitoring' },
                    ]
                  },
                  expressions: {
                    'props.readonly': (field: FormlyFieldConfig) => field?.form?.value.hasWatchman !== true ? true : false,
                    'props.required': (field: FormlyFieldConfig) => field?.form?.value.hasWatchman === true ? true : false,
                  },
                },
              ],
            },
          ]
        },
        {
          wrappers: [ 'simple-section-wrapper' ],
          key: 'sprinklersData',
          props: {
            label: 'Sprinklers',
            isCollapsable: true,
            shallowErrors: false
          },
          expressions: {
            'props.isCollapsed': (field: FormlyFieldConfig) => field?.options?.formState?.service?.formlyInternalProtectionService?.disableSprinkler(field),
            'props.isCollapseIconDisabled': (field: FormlyFieldConfig) => field?.options?.formState?.service?.formlyInternalProtectionService?.disableSprinkler(field)
          },
          fieldGroupClassName: 'internal-protection-sprinkler-container',
          fieldGroup: [ {
            template: '<div><span>V</span><sup>1</sup></div>',
            className: 'version-style'
          },
          {
            type: 'summary-row',
            fieldGroup: [
              {
                key: 'sprinklerRatingAsgr',
                props: {
                  label: 'Sprinkler Report Score',
                },

              },
              {
                key: 'sprinklerTypeValue',
                props: {
                  label: 'Sprinkler Type',
                  isTextField: true,
                  isWarningEnabled: false,
                  warningMessageContent: '',
                },
                validators: {
                  roofCheck: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      const formlyService = field?.options?.formState?.service?.riskReport?.model;
                      const hydraulicDesignStandard = formlyService?.sprinklerReport?.asgrWaterSupply?.hydraulicDesignStandard;
                      if (hydraulicDesignStandard === 'BOTH' && field.formControl.value === 'Sprinklered') {
                        return false;
                      } else {
                        return true;
                      }
                    },
                    message: 'If NFPA 13R and NFPA 13 sprinkler is installed in building, full sprinkler credit is not allowed.',
                  },
                  roofCheck2: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
                      const isValidation2Checked = formlyService.formlyInternalProtectionService?.sprinklerTypeValidation2Check(field);
                      if (isValidation2Checked) {
                        return false;
                      } else {
                        return true;
                      }
                    },
                    message: 'If a NFPA 13R sprinkler is installed in building with habitational and commercial occupancies then no sprinkler credit is allowed.',
                  },
                  roofCheck3: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
                      const isValidation3Checked = formlyService.formlyInternalProtectionService?.sprinklerTypeValidation3Check(field);
                      if (isValidation3Checked) {
                        return false;
                      } else {
                        return true;
                      }
                    },
                    message: 'If all occupants have "13R(non-creditable)" as sprinkler indicator then full and partial sprinkler credit is not allowed.',
                  },
                  roofCheck4: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
                      const isValidation4Checked = formlyService.formlyInternalProtectionService?.sprinklerTypeValidation4Check(field);
                      if (isValidation4Checked) {
                        return false;
                      } else {
                        return true;
                      }
                    },
                    message: 'If a NFPA 13R sprinkler is installed in a non habitational occupancy then full and partial sprinkler credit is not allowed.',
                  },
                }
              },
              {
                key: 'sprinklerTypeCodeValue',
              }
            ],
            hooks: {
              onInit: (field?: FormlyFieldConfig) => {
                field?.form?.get('sprinklerTypeCodeValue')?.valueChanges.subscribe(src => {
                  if (src==='S') {
                    field?.form?.get('sprinklerTypeValue')?.setValue('Sprinklered');
                  } else if(src==='P') {
                    field?.form?.get('sprinklerTypeValue')?.setValue('Partially Sprinklered');
                  } else if(src==='N') {
                    field?.form?.get('sprinklerTypeValue')?.setValue('Non-Sprinklered');
                  }
                });
                if (field.form.get('sprinklerTypeValue').value === 'Sprinklered') {
                  field.form.get('sprinklerTypeCodeValue').setValue('S');
                  field.model.sprinklerTypeValue = 'Sprinklered';
                  field.model.sprinklerTypeCodeValue = 'S';
                } else if (field.form.get('sprinklerTypeValue').value === 'Partially Sprinklered') {
                  field.form.get('sprinklerTypeCodeValue').setValue('P');
                  field.model.sprinklerTypeValue = 'Partially Sprinklered';
                  field.model.sprinklerTypeCodeValue = 'P';
                } else if (field.form.get('sprinklerTypeValue').value === 'Non-Sprinklered') {
                  field.form.get('sprinklerTypeCodeValue').setValue('N');
                  field.model.sprinklerTypeValue = 'Non-Sprinklered';
                  field.model.sprinklerTypeCodeValue = 'N';
                }
              }
            },
          },
          {
            fieldGroupClassName: 'partial-group-style',
            fieldGroup: [
              {
                key: 'partialSupplyAlarmType',
                type: 'simple-select',
                className: 'select-width',
                resetOnHide: true,
                props: {
                  label: 'Alarm',
                  options: [
                    { value: 'NOAL', label: 'No Alarm' },
                    { value: 'LOCL', label: 'Local Alarm' },
                    { value: 'SUPR', label: 'Supervised Alarm' },                   
                  ],
                  required: true,
                  isAsteriskNotRequired: true,
                },
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    field?.formControl?.markAllAsTouched();
                  },
                },
                validators: {
                  requiredValidation: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      if ((!field?.formControl?.value || field?.formControl?.value === 'NONE') && !field.parent.hide &&
                        !field?.options?.formState?.service?.formlyInternalProtectionService?.disableSprinkler(field)) {
                        return false;
                      }
                      return true;
                    },
                    message: 'Partial Supply Alarm Type is Required',
                  }
                }
              },
              {
                key: 'partialSupplyProtectedArea',
                type: 'simple-input',
                className: 'input-style',
                resetOnHide: true,
                props: {
                  unit: ' sq.ft.',
                  isAsteriskNotRequired: true,
                  isDisabled: true,
                  readonly: true,
                },
                hooks: {
                  onInit: (field?: FormlyFieldConfig) => {
                    const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
                    const sprinkleredArea = formlyService.formlyInternalProtectionService?.calculatePartialSupplyProtectedArea(field);
                    field?.formControl?.setValue(sprinkleredArea);
                  }
                },
              }
            ],
            expressions: {
              hide: (field: FormlyFieldConfig) => {
                const partialSupplyProtectedArea = field?.options?.formState?.riskReportService?.report?.InternalProtections &&
                  field?.options?.formState?.riskReportService?.report?.InternalProtections[0]?.PartialSupplyProtectedArea ?
                  field?.options?.formState?.riskReportService?.report?.InternalProtections[0]?.PartialSupplyProtectedArea : null;

                const isHidden = field?.form?.get('sprinklerTypeCodeValue')?.value === 'P' || (field?.form?.get('sprinklerRatingAsgr')?.value === 0 &&
                  field.options.formState.isSprinklerTypeCodeWarningEnabled && partialSupplyProtectedArea) ? false : true;
                if (isHidden) {
                  if (field.model?.partialSupplyAlarmType !== 'NONE') {
                    field.model.partialSupplyAlarmType = 'NONE';
                  }
                  if (field.model?.partialSupplyProtectedArea) {
                    field.model.partialSupplyProtectedArea = null;
                  }
                } else {
                  if (!field.model?.partialSupplyProtectedArea) {
                    const sprinkleredArea = field.options?.formState?.service?.formlyInternalProtectionService?.calculatePartialSupplyProtectedArea(field);
                    const partialSupplyAlarmType = field?.options?.formState?.riskReportService?.report?.InternalProtections[0]?.PartialSupplyAlarmType;
                    field.parent.model.partialSupplyProtectedArea = sprinkleredArea;
                    if (field?.options?.formState?.riskReportService?.report?.InternalProtections[0]?.PartialSupplyAlarmType) {
                      field.parent.model.partialSupplyAlarmType = partialSupplyAlarmType;
                    }
                  }
                }
                return isHidden;
              }
            }
          },
          {
            fieldGroupClassName: 'partial-group-style',
            fieldGroup: [
              {
                key: 'sprinkleredStationType',
                type: 'simple-select',
                className: 'station-type-width',
                props: {
                  label: 'Station Type',
                  options: [
                    { value: 'NONE', label: '' },
                    { value: 'NOMS', label: 'No Monitoring Station' },
                    { value: 'CENT', label: 'Central Station' },
                    { value: 'REMO', label: 'Remote Station' },
                  ],
                  required: true,
                  isAsteriskNotRequired: true,
                },
                // expressions: {
                //   'props.required': (field?: FormlyFieldConfig) =>( field.formControl.value === null || field.formControl.value === '') ? true : false,
                // },
                validators: {
                  required: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      const isEvidanceOfFireSprinkler = field?.options?.formState?.service?.formlyInternalProtectionService?.disableSprinkler(field);
                      if ((control.value === 'NONE' || control.value === null) && !isEvidanceOfFireSprinkler) {
                        return false;
                      } else {
                        return true;
                      }
                    },
                    message: 'Required',
                  },
                },
                hooks: {
                  onInit: (field?: FormlyFieldConfig) => {
                    setTimeout(() => {
                      if (field?.formControl?.value === 'NONE' || field?.formControl?.value === null) {
                        field?.formControl?.markAllAsTouched();
                        field.formControl.updateValueAndValidity();
                      }
                    }, 100);
                    field.formControl.valueChanges?.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
                      field?.form?.get('sprinkleredStationAlarmType')?.updateValueAndValidity();
                    });
                  }
                },
              },
              {
                key: 'sprinkleredStationAlarmType',
                type: 'simple-select',
                className: 'station-alarm-width',
                props: {
                  label: 'Station Alarms',
                  options: [
                    { value: 'COMP', label: 'Complete Supervision' },
                    { value: 'WATE', label: 'Waterflow Supervision' },
                    { value: 'NONE', label: 'None' },
                  ],
                  required: true,
                  isAsteriskNotRequired: true,
                },
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    field?.formControl?.markAllAsTouched();
                    field.form.get('sprinkleredStationType').markAllAsTouched();
                  },
                },
                expressions: {
                  'props.required': (field?: FormlyFieldConfig) => (field?.formControl?.value === null || field?.formControl?.value === '') && !field?.options?.formState?.service?.formlyInternalProtectionService?.disableSprinkler(field) ? true : false,
                },
                validators: {
                  alarmValidation: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      const sprinkleredStationType = field?.form?.get('sprinkleredStationType');
                      if(control?.value === 'NONE' && 
                        (sprinkleredStationType?.value === 'CENT' || sprinkleredStationType?.value === 'REMO') ) {
                          return false;
                      }
                      return true;
                    },
                    message: 'Station Alarms cannot be None if Station Type is Remote or Central Station.',
                  }
                }           
              },
              {
                template: '<span></span>', className: 'new-row',
              },
              {
                key: 'hasNonStandardRetransmission',
                type: 'custom-checkbox',
                className: 'checkbox1-width',
                props: {
                  label: 'Non-standard retransmission',
                  isFontSizeSmall: true
                },
              },
              {
                key: 'hasStandardPeriodicInspectionService',
                type: 'custom-checkbox',
                className: 'checkbox2-width',
                props: {
                  label: 'Standard Periodic Inspection Service',
                  isFontSizeSmall: true
                },
              },
            ],
            expressions: {
              hide: (field: FormlyFieldConfig) => field?.form?.get('sprinklerTypeCodeValue')?.value === 'S' ? false : true,
            }
          },
          ],
          hooks: {
            onInit: (field?: FormlyFieldConfig) => {
              const formlyService: FormlyBuilderService = field?.options?.formState?.service as FormlyBuilderService;
              // formlyService.formlyInternalProtectionService?.setAsgrRating(field);
              formlyService.formlyInternalProtectionService?.setSprinklerType(field);
              field?.form?.get('sprinklerRatingAsgr')?.valueChanges.subscribe(src => {
                if (src) {
                  formlyService.formlyInternalProtectionService?.setSprinklerType(field);
                }
              });
            }
          },
        },
        {
          wrappers: [ 'simple-section-wrapper' ],
          key: 'limitedSupplyFps',
          props: {
            label: 'Limited Supply FPS',
            isCollapsable: true,
            shallowErrors: false,
            checkTouchedControl: true
          },
          expressions: {
            'props.isCollapsed': (field: FormlyFieldConfig) => field?.options?.formState?.service?.formlyInternalProtectionService?.isDisableCollapse(field),
            'props.isCollapseIconDisabled': (field: FormlyFieldConfig) => field?.options?.formState?.service?.formlyInternalProtectionService?.isDisableCollapse(field),
          },
          fieldGroupClassName: 'limited-supply-container',
          fieldGroup: [
            {
              wrappers: [ 'question-wrapper' ],
              className: 'limited-supply-style',
              props: {
                label: 'Limited Supply FPS',
                showError: false,
                isSectionLabelNormal: true,
              },
              fieldGroupClassName: 'formly-flex-container ',
              fieldGroup: [
                {
                  key: 'limitedSupplyProtectedArea',
                  type: 'simple-input',
                  className: 'input-style',
                  props: {
                    unit: 'sq.ft.',
                    label: '',
                    isDisabled: false,
                    disabled: false,
                    type: 'number',
                    inputmode: 'tel',
                  },
                  validators: {
                    roofCheck: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        const area = field?.options?.formState?.service?.formlyInternalProtectionService?.totalLimitedSupplyAreaValue(field);
                        if (Number(field?.formControl?.value) > area) {
                          return false;
                        } else {
                          return true;
                        }
                      },
                      message: 'Protected area cannot exceed identified area on Occupancy entries.',
                    },
                  },
                  hooks: {
                    onInit: (field?: FormlyFieldConfig) => {
                      field.formControl.markAsTouched();
                      if (field?.form?.get('limitedSupplyProtectedArea').value === '' || field.form?.get('limitedSupplyProtectedArea').value === null || field?.form?.get('limitedSupplyProtectedArea').value === undefined) {
                        field?.form?.get('limitedSupplyProtectedArea')?.setValue(field?.options?.formState?.service?.formlyInternalProtectionService?.totalLimitedSupplyAreaValue(field));
                      }
                    }
                  },
                },
                {
                  key: 'limitedSupplyAlarmType',
                  type: 'simple-select',
                  className: 'select-style',
                  defaultValue: null,
                  props: {
                    isDisabled: false,
                    isLabelNotRequired: true,
                    isLabelDisabled: true,
                    options: [
                      { value: 'NONE', label: '' },
                      { value: 'NOAL', label: 'No Alarm' },
                      { value: 'LOCL', label: 'Local Alarm' },
                      { value: 'SUPR', label: 'Supervised Alarm' },
                    ]
                  },
                  validators: {
                    required: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        if ((control.value === 'NONE' || control.value === null) && field?.form?.value?.limitedSupplyProtectedArea > 0) {
                          field?.formControl?.markAllAsTouched();
                          return false;
                        } else {
                          return true;
                        }
                      },
                      message: 'Required',
                    },
                  },
                  hooks: {
                    onInit: (field?: FormlyFieldConfig) => {
                      if (field?.form?.get('limitedSupplyAlarmType')?.value !== 'SUPR' && field?.form?.get('limitedSupplyAlarmType')?.value !== 'NOAL' && field?.form?.get('limitedSupplyAlarmType')?.value !== 'LOCL') {
                        field?.form?.get('limitedSupplyAlarmType')?.setValue('NONE');
                      }
                      setTimeout(() => {
                        if (field?.form?.get('limitedSupplyAlarmType')?.value === 'NONE') {
                          field?.formControl?.markAllAsTouched();
                          field.formControl.updateValueAndValidity();
                        }
                      }, 100);

                      field?.form?.get('limitedSupplyProtectedArea')?.valueChanges.subscribe(src => {
                        if (src > 0 && field?.form?.get('limitedSupplyAlarmType')?.value === null) {
                          field?.form?.get('limitedSupplyAlarmType')?.setValue('NONE');
                        }else{
                        field?.form?.get('limitedSupplyAlarmType')?.reset();
                        }
                      });
                    }
                  },
                  expressions: {
                    'props.isDisabled': (field: FormlyFieldConfig) => {
                      if (field?.form?.value?.limitedSupplyProtectedArea > 0) {
                        return false;
                      } else {
                        return true;
                      }
                    },
                  },
                },
              ],
              expressions: {
                hide: (field: FormlyFieldConfig) => field?.options?.formState?.service?.formlyInternalProtectionService?.isDisableCollapse(field)
              }
            },
          ]
        },
        {
          key: 'automaticFireAlarmDetectionSystem',   // Need to add in to method
          wrappers: [ 'simple-section-wrapper' ],
          props: {
            label: 'Automatic Fire Alarm Detection Systems',
            toggleAction: true,
            isCollapsable: true,
            isCollapsed: false,
            showError: false,
            checkTouchedControl: true,
            toggleConfirmationDetails: {
              title: 'You are about to delete the Automatic Fire Alarm information',
              msg: '',
              buttonText: [ 'Confirm', 'Cancel' ]
            }
          },
          fieldGroup: [
            {
              key: 'automaticFireAlarmDetectionSystemToggle',
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  const formlyService = field.options.formState.service;
                  formlyService.formlyInternalProtectionService.autoFireDetectionReset(field);
                }
              }
            },
            {
              fieldGroup: [
                {
                  type: 'summary-row',
                  resetOnHide: true,
                  expressions: {
                    hide: (field: FormlyFieldConfig) => field?.form?.value?.autoFireAlarmSystemCoverageType === 'FULL' ||
                      field?.form?.value?.autoFireAlarmSystemCoverageType === 'PART' ? false : true
                  },
                  fieldGroup: [
                    {
                      key: 'deficiencyPoints',
                      resetOnHide: true,
                      props: {
                        label: 'Deficiency points',
                      },
                    },
                    {
                      key: 'fireAlarmGrade',
                      resetOnHide: true,
                      props: {
                        label: 'Grade',
                        isTextField: true
                      },
                    }
                  ]
                }
              ]
            },
            {
              fieldGroupClassName: 'automatic-fire-alarm-detection-system',
              fieldGroup: [
                {
                  key: 'autoFireAlarmSystemCoverageType',
                  type: 'simple-select',
                  className: 'select-width full-width',
                  wrappers: [ 'question-wrapper' ],
                  props: {
                    label: 'Automatic fire alarm',
                    required: true,
                    isAsteriskNotRequired: true,
                    isHorizontalOrientation: true,
                    isLabelDisabled: true,
                    isSwipeGesture: true,
                    isCommentActionEnabled: true,
                    commentActionKey: 'autoFireAlarmSystemCoverageTypeComment',
                    maxLength: 500,
                    options: [
                      { value: 'FULL', label: 'Full' },
                      { value: 'PART', label: 'Partial' },
                      { value: 'LMTD', label: 'Limited' }
                    ]
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => field?.form?.get('automaticFireAlarmDetectionSystemToggle')?.value ? true : false
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      const formlyService = field.options.formState.service;
                      formlyService.formlyInternalProtectionService.calculateDeficiencyPoints(field);
                    }
                  }
                },
                {
                  key: 'autoFireAlarmSystemCoverageTypeComment',
                  resetOnHide: true
                },
                {
                  key: 'isSystemAndTransmitterListed',
                  type: 'custom-checkbox',
                  wrappers: [ 'question-wrapper' ],
                  resetOnHide: true,
                  props: {
                    label: 'System and Transmitter Listed',
                    isHorizontalOrientation: true,
                    labelFirst: true
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => field?.form?.value?.autoFireAlarmSystemCoverageType === 'FULL' ||
                      field?.form?.value?.autoFireAlarmSystemCoverageType === 'PART' ? false : true
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      const formlyService = field.options.formState.service;
                      formlyService.formlyInternalProtectionService.calculateDeficiencyPoints(field);
                    }
                  }
                },
                {
                  key: 'isRecieverListed',
                  type: 'custom-checkbox',
                  wrappers: [ 'question-wrapper' ],
                  resetOnHide: true,
                  props: {
                    label: 'Receiver Listed',
                    isHorizontalOrientation: true,
                    labelFirst: true
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => field?.form?.value?.autoFireAlarmSystemCoverageType === 'FULL' ||
                      field?.form?.value?.autoFireAlarmSystemCoverageType === 'PART' ? false : true
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      const formlyService = field.options.formState.service;
                      formlyService.formlyInternalProtectionService.calculateDeficiencyPoints(field);
                    }
                  }
                },
                {
                  key: 'alarmSignalDestinationType',
                  type: 'simple-select',
                  className: 'select-width full-width margin-right',
                  wrappers: [ 'question-wrapper' ],
                  resetOnHide: true,
                  props: {
                    label: 'Alarm transmitted to',
                    required: true,
                    isAsteriskNotRequired: true,
                    isHorizontalOrientation: true,
                    isLabelDisabled: true,
                    options: [
                      { value: 'CENT', label: 'Central Station - w/certificate' },
                      { value: 'CERT', label: 'Central Station - w/o certificate' },
                      { value: 'FIRE', label: 'Fire Department' },
                      { value: 'PROP', label: 'Proprietary Station' },
                      { value: 'NOST', label: 'Non-Standard Transmission' },
                      { value: 'OTHR', label: 'Other' }
                    ]
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => field?.form?.value?.autoFireAlarmSystemCoverageType === 'FULL' ||
                      field?.form?.value?.autoFireAlarmSystemCoverageType === 'PART' ? false : true
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      const formlyService = field.options.formState.service;
                      formlyService.formlyInternalProtectionService.calculateDeficiencyPoints(field);
                    }
                  }
                },
                {
                  key: 'troubleSignalDestinationType',
                  type: 'simple-select',
                  className: 'select-width full-width margin-right',
                  wrappers: [ 'question-wrapper' ],
                  resetOnHide: true,
                  props: {
                    label: 'Trouble signal to',
                    required: true,
                    isAsteriskNotRequired: true,
                    isHorizontalOrientation: true,
                    isLabelDisabled: true,
                    options: [
                      { value: 'NONE', label: 'None' },
                      { value: 'CENT', label: 'Central Station' },
                      { value: 'FIRE', label: 'Fire Department' },
                      { value: 'FINO', label: 'Fire Department - No Action' },
                      { value: 'PROP', label: 'Proprietary Station' },
                      { value: 'OTHR', label: 'Other' }
                    ]
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => field?.form?.value?.autoFireAlarmSystemCoverageType === 'FULL' ||
                      field?.form?.value?.autoFireAlarmSystemCoverageType === 'PART' ? false : true
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      const formlyService = field.options.formState.service;
                      formlyService.formlyInternalProtectionService.calculateDeficiencyPoints(field);
                    }
                  }
                },
                {
                  key: 'autoFireAlarmInspectionAndTestFrequencyType',
                  type: 'simple-select',
                  className: 'select-width full-width margin-right',
                  wrappers: [ 'question-wrapper' ],
                  resetOnHide: true,
                  props: {
                    label: 'Inspection & test',
                    isHorizontalOrientation: true,
                    isLabelDisabled: true,
                    required: true,
                    isAsteriskNotRequired: true,
                    options: [
                      { value: 'STAN', label: 'Standard' },
                      { value: 'QUAR', label: 'Quarterly' },
                      { value: 'SEMI', label: 'Semi- Annual' },
                      { value: 'NOIN', label: 'No Inspection' }
                    ]
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => field?.form?.value?.autoFireAlarmSystemCoverageType === 'FULL' ||
                      field?.form?.value?.autoFireAlarmSystemCoverageType === 'PART' ? false : true
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      const formlyService = field.options.formState.service;
                      formlyService.formlyInternalProtectionService.calculateDeficiencyPoints(field);
                    }
                  }
                }
              ]
            }
          ]
        },
        { key: 'isStandardAutoSprinklerSelected' }
      ],
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          field.formControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(), debounceTime(Constants.riskFormDataSyncDelay))
            .subscribe(src => {
              // Saving Internal Protection changes
              if (!field.formControl.pristine) {
                const formlyService = field.options.formState.service;
                formlyService.riskReport.updateSectionData(SectionEnum.FR_INTERNAL_PROTECTION_DETAILS, false);
              }
            });
        },
        onDestroy: () => {
          this.ngUnsubscribe.next();
          this.ngUnsubscribe.complete();
        }
      }

    });
  }
}
