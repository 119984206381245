import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';

export class FrictionLossSupplySourceSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      fieldGroupClassName: 'friction-loss-container',
      fieldGroup: [
        {
          key: 'supplySourceNumber',
          defaultValue: 1,
        },
        {
          key: 'pipeNumber',
          defaultValue: 1,
        },
        {
          key: 'allFittingValve',
          defaultValue: null,
        },
        {
          key: 'pipeLength',
          wrappers: ['question-wrapper'],
          type: 'simple-input',
          className: 'question-wrapper-item',
          defaultValue: null,
          props: {
            label: 'Length',
            type: 'number',
            unit: 'ft',
            required: false,
            isLabelDisabled: true,
            isAsteriskNotRequired: true,
            isHorizontalOrientation: true,
            isSectionLabelNormal: false,
          },
        },
        {
          key: 'otherFrictionLoss',
          type: 'simple-input',
          wrappers: ['question-wrapper'],
          className: 'question-wrapper-item',
          defaultValue: null,
          props: {
            label: 'Additional Friction Loss',
            type: 'number',
            unit: 'PSI',
            isLabelDisabled: true,
            isAsteriskNotRequired: true,
            isHorizontalOrientation: true,
            required: false,
            isSectionLabelNormal: false,
          },
        },
        {
          key: 'pipeCoefficientC',
          wrappers: ['question-wrapper'],
          type: 'simple-select',
          className: 'question-wrapper-item select-friction',
          defaultValue: null,
          props: {
            label: 'Coefficient C',
            isLabelDisabled: true,
            isAsteriskNotRequired: true,
            isHorizontalOrientation: true,
            selectWidth: true,
            required: false,
            isSectionLabelNormal: false,
            options: formlyBuilderService.frictionLossService.getPipeCoefficentOptions(),
          },
          expressions: {
            'props.required':( field: FormlyFieldConfig) => {
              const isHydraulicWaterDemandLocationBor = field.form?.root?.value?.sprinklerReport?.asgrWaterSupply?.hydraulicSystemDesign?.isHydraulicWaterDemandLocationBor;
              return !(!isHydraulicWaterDemandLocationBor);
            }
          }
        },
        {
          key: 'pipeInsideDiameter',
          type: 'simple-select',
          wrappers: ['question-wrapper'],
          className: 'question-wrapper-item select-friction',
          props: {
            label: 'Nominal Diameter',
            isLabelDisabled: true,
            isAsteriskNotRequired: true,
            isHorizontalOrientation: true,
            selectWidth: true,
            required: false,
            isSectionLabelNormal: false,
            options: formlyBuilderService.frictionLossService.getInsideDaimeterOption(),
          },
          expressions: {
            'props.required':( field: FormlyFieldConfig) => {
              const isHydraulicWaterDemandLocationBor = field.form?.root?.value?.sprinklerReport?.asgrWaterSupply?.hydraulicSystemDesign?.isHydraulicWaterDemandLocationBor;
              return !(!isHydraulicWaterDemandLocationBor);
            }
          }
        },
        {
          key: 'reportId',
          defaultValue: formlyBuilderService.riskReport?.report?.SprinklerReport?.ReportId,
        },
        {
          key: 'waterSupplyFrictionLossId',
          defaultValue: null,
          hooks: {
            onInit: (field: FormlyFieldConfig) => {
              formlyBuilderService.frictionLossService.generateFrictionLossId(field);
            },
          },
        },
        {
          key: 'usesActualGpmforFlow',
          defaultValue: false,
        },
        {
          wrappers: ['simple-section-wrapper'],
          type: 'rf-common-flex-grid',
          key: 'waterSupplyFrictionLossFittingsAndValves',
          className: 'fitting-valve-style',
          props: {
            showAddButton: true,
            label: 'Fitting and Valve Type',
            isGridHeaderDisabled: true,
            showError: true,
            shallowErrors: false,
            // checkTouchedControl: true,
          },
          fieldArray: {
            fieldGroup: [
              {
                key: 'fittingAndValveType',
                type: 'simple-select',
                className: 'fitting-select',
                defaultValue: null,
                props: {
                  isLabelDisabled: true,
                  isAsteriskNotRequired: true,
                  isHorizontalOrientation: true,
                  isSectionLabelNormal: false,
                  options: formlyBuilderService.frictionLossService.getFittingValveOptions(),
                },
                expressions: {
                  'props.required': (field: FormlyFieldConfig) => {
                    const isHydraulicWaterDemandLocationBor = field.form?.root?.value?.sprinklerReport?.asgrWaterSupply?.hydraulicSystemDesign?.isHydraulicWaterDemandLocationBor;
                    const count = field?.form?.get('numbersOfEachFittingAndValve')?.value;
                    if(isHydraulicWaterDemandLocationBor) {
                      if (count !== null && !isNaN(count) && Number(count) > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return false;
                    
                  },
                },
              },
              {
                key: 'numbersOfEachFittingAndValve',
                type: 'simple-input',
                className: 'count-input',
                defaultValue: 1,
                props: {
                  type: 'number',
                  unit: 'count',
                  isLabelDisabled: true,
                  isAsteriskNotRequired: true,
                  isHorizontalOrientation: true,
                  isSectionLabelNormal: false,
                },
                validators: {
                  numberofFittingValves: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      const isHydraulicWaterDemandLocationBor = field.form?.root?.value?.sprinklerReport?.asgrWaterSupply?.hydraulicSystemDesign?.isHydraulicWaterDemandLocationBor;
                      if (isHydraulicWaterDemandLocationBor) {
                        return formlyBuilderService.frictionLossService.numberOfFittingValveValidation(control, field);
                      }
                      return true;
                    },
                    message: 'Number of fittings or valves must be 1 or more',
                  },
                },
                expressions: {
                  'props.required': (field: FormlyFieldConfig) => {
                    const isHydraulicWaterDemandLocationBor = field.form?.root?.value?.sprinklerReport?.asgrWaterSupply?.hydraulicSystemDesign?.isHydraulicWaterDemandLocationBor;
                    if(isHydraulicWaterDemandLocationBor) {
                      const type = field?.form?.get('fittingAndValveType')?.value;
                      if (type !== null) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return false;                    
                  },
                },
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    field?.form?.markAllAsTouched();
                    field?.formControl?.valueChanges.subscribe((val) => {
                      field?.form?.get('fittingAndValveType')?.updateValueAndValidity();
                    });
                  },
                },
              },
              {
                key: 'waterSupplyFrictionLossFittingAndValveId',
                defaultValue: null,
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    formlyBuilderService.frictionLossService.generateFrictionLossId(field);
                  },
                },
              },
              {
                key: 'waterSupplyFrictionLossId',
                defaultValue: null,
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    const id = field?.parent?.parent?.form?.get('waterSupplyFrictionLossId')?.value;
                    if (id !== null && id) {
                      field?.formControl?.setValue(id);
                    }
                  },
                },
              },
              {
                key: 'fittingAndValveNumber',
                defaultValue: null,
              },
              {
                key: 'fittingAndValveLength',
                defaultValue: null,
              },
            ],
          },
        },
      ],
    });
  }
}
