/* eslint-disable @typescript-eslint/naming-convention */
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class ChargeableColumnDataModel {
  static sectionName = 'chargeableColumn';
  // Since the data model is already created and we have many refencence dont want to change in FRM and data model  
  wallsChargeableColumn: boolean; // HasWallsChargeableColumns Backend Property name 
  hasUnprotectedMetalColumns: boolean;
  hasCombustibleColumns: boolean;
  columnWallThickness: string;
  percentUnprotectedMetalColumnArea: any;
  columnType: string;

  static fromS3(reports: any): ChargeableColumnDataModel {
    const columnType = (reports.HasUnprotectedMetalColumns && reports.HasCombustibleColumns) ? "B" : reports.HasCombustibleColumns ? "C" : reports.HasUnprotectedMetalColumns ? "U" : " ";
    return {
      wallsChargeableColumn: reports.HasWallsChargeableColumns,
      hasUnprotectedMetalColumns: reports.HasUnprotectedMetalColumns,
      hasCombustibleColumns: reports.HasCombustibleColumns,       
      columnWallThickness: reports.ColumnWallThickness == 0 ? null : reports.ColumnWallThickness,
      percentUnprotectedMetalColumnArea: reports.PercentUnprotectedMetalColumnArea == 0 ? null : reports.PercentUnprotectedMetalColumnArea,
      columnType: columnType
    };
  }

  static to(model: any, sectionEnum?: SectionEnum) {
    const columnType = (model.areChargeableColumn.hasUnprotectedMetalColumns && model.areChargeableColumn.hasCombustibleColumns) ? "B" : model.areChargeableColumn.hasCombustibleColumns ? "C" : model.areChargeableColumn.hasUnprotectedMetalColumns ? "U" : " ";
    return {
      HasWallsChargeableColumns: model?.areChargeableColumn?.wallsChargeableColumn,
      HasUnprotectedMetalColumns: model?.areChargeableColumn?.hasUnprotectedMetalColumns ? model?.areChargeableColumn?.hasUnprotectedMetalColumns : false,
      HasCombustibleColumns: model?.areChargeableColumn?.hasCombustibleColumns ? model?.areChargeableColumn?.hasCombustibleColumns: false, 
      ColumnWallThickness: columnType === ' ' ? null : (model?.areChargeableColumn?.columnWallThickness ? model.areChargeableColumn.columnWallThickness : null),
      PercentUnprotectedMetalColumnArea: columnType === ' ' ? null : ( model?.areChargeableColumn?.percentUnprotectedMetalColumnArea ?  model.areChargeableColumn.percentUnprotectedMetalColumnArea : null),      
      ColumnType: columnType ? columnType : ' '
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    return true;
  }
}