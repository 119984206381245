import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FormlyOccupantsService } from './formly-occupants.service';
import { FormlyBuildingBGService } from './formly-building-bg.service';
import { FormlyWallsService } from './formly-walls.service';

/**
 * @deprecated This is an example service to delete in the future
 */
@Injectable({
  providedIn: 'root'
})
export class FormlyBuilderJsonOptionsService {
  isAdd: boolean;
  constructor(
    private http: HttpClient,
    private formlyWallsService: FormlyWallsService,
    private formlyBuildingBGService: FormlyBuildingBGService,
    private formlyOccupantsService: FormlyOccupantsService
  ) { }

  /**
   * Get options from an asset file named 'file.json'
   *
   * @param file JSON Filename from where to load options (appending .json not needed)
   * @returns
   */
  public getJsonOptions(file: string): Observable<any[]> {
    // return this.http.get<{ label: string; value: string }[]>('assets/json/formly-builder/'+file);
    return of([
      {
        label: 'No Preference',
        value: null,
      },
      {
        label: 'Green',
        value: 'green',
      },
      {
        label: 'Blue',
        value: 'blue',
      },
    ]);
  }

  public getWallConstructionTypeJsonOptions(file: string): Observable<any[]> {
    // return this.http.get<{ label: string; value: string }[]>('assets/json/formly-builder/'+file);

    return this.formlyWallsService.getConstructionTypeLookupData();
  }

  public getWallConstructionMaterialJsonOptions(type: string): Observable<any[]> {
    // return this.http.get<{ label: string; value: string }[]>('assets/json/formly-builder/'+file);

    return this.formlyWallsService.getConstructionMaterialLookupData();
  }

  public getLevelOptions(minValue, maxValue): Observable<any[]> {
    return this.formlyOccupantsService.getLevelOptions(minValue, maxValue);
  }
  public getMaxValue(val, field) {
    return this.formlyOccupantsService.getMaxValue(val, field);
  }
  public getBuildingBGOptions(): Observable<any[]> {
    return this.formlyBuildingBGService.getBuildingBGLookUpData();
  }

  public getAddOccupantFlag() {
    return this.isAdd;
  }

}
