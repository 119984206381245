import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyBuilderService } from '../../formly-builder.service';
import { UtilService } from 'src/app/services/util-service/util.service';
import { fi } from 'date-fns/locale';
import { SectionEnum } from '../../section.config';
import { WaterSupplyFrictionLossDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/water-supply-friction-loss-data.model';

@Injectable({
  providedIn: 'root',
})
export class FrictionLossSupplySourceService extends SectionServiceAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService, private utilService: UtilService) {
    super();
  }

  loadData(sectionData: any[], riskReportData: any, isDataInS3Format: boolean, model: any) {
    return null;
  }

  updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    let fsSupplySource1 = model?.sprinklerReport?.asgrWaterSupply?.asgrWaterSupplyFrictionLoss?.supplySource1;
    let fsSupplySource2 = model?.sprinklerReport?.asgrWaterSupply?.asgrWaterSupplyFrictionLoss?.supplySource2;
    let fsSupplySource = [];
    if (fsSupplySource1) {
      fsSupplySource1 = WaterSupplyFrictionLossDataModel.to(fsSupplySource1);
      fsSupplySource1 = this.setValveNumberLength(fsSupplySource1);
      fsSupplySource = fsSupplySource.concat(fsSupplySource1);
    }
    if (fsSupplySource2) {
      fsSupplySource2 = WaterSupplyFrictionLossDataModel.to(fsSupplySource2);
      fsSupplySource2 = this.setValveNumberLength(fsSupplySource2);
      fsSupplySource = fsSupplySource.concat(fsSupplySource2);
    }

    if (fsSupplySource.length > 0) {
      if (riskReportData?.SprinklerReport?.AsgrWaterSupply) {
      } else {
        riskReportData.SprinklerReport.AsgrWaterSupply = {};
      }
      riskReportData.SprinklerReport.AsgrWaterSupply['AsgrWaterSupplyFrictionLoss'] = fsSupplySource;
      const sprinklerData = {
        ...riskReportData.SprinklerReport,
      };
      return { ...riskReportData, ...{ SprinklerReport: sprinklerData } };
    }
  }

  async onApply(modalCtrl, field) {
    let allFittingValves;
    field.form.value.forEach((formValue, i) => {
      if (
        field.form?.value[i].waterSupplyFrictionLossFittingsAndValves &&
        field.form?.value[i].waterSupplyFrictionLossFittingsAndValves.length > 0
      ) {
        allFittingValves = field.form?.value[i].waterSupplyFrictionLossFittingsAndValves.map(
          (val, index) => val.fittingAndValveType
        );
        field?.form?.controls[i]?.get('allFittingValve')?.setValue(allFittingValves?.toString());
      }
      field?.form?.controls[i]?.get('pipeNumber')?.setValue(i + 1);
      if (field?.parent?.key === 'supplySource2') {
        field?.form?.controls[i]?.get('supplySourceNumber')?.setValue(2);
      }
    });
    // const riskReport = field.options.formState.riskReportService.updateSectionData(
      //   SectionEnum.FR_S_WS_FRICTION_LOSS,
      //   false,
      //   null,
      //   FrictionLossSupplySourceService
      // );
    field.options.formState.service.errorService.closePreviousToaster()
    modalCtrl.dismiss(field, 'save');
  }

  async onClose(modalCtrl, field, initialModel) {
    if (field?.parent?.key === 'supplySource2') {
      field.options.formState.service.riskReport.model.sprinklerReport.asgrWaterSupply.asgrWaterSupplyFrictionLoss.supplySource2[
        field.index
      ] = initialModel;
    } else {
      field.options.formState.service.riskReport.model.sprinklerReport.asgrWaterSupply.asgrWaterSupplyFrictionLoss.supplySource1[
        field.index
      ] = initialModel;
    }
    field.options.formState.service.riskReport.model = {
      ...field.options.formState.service.riskReport.model,
    };
    field.options.formState.service.errorService.closePreviousToaster();
    modalCtrl.dismiss(field, 'cancel');
  }

  getPipeCoefficentOptions() {
    return [
      { label: '100', value: 100 },
      { label: '120', value: 120 },
      { label: '130', value: 130 },
      { label: '140', value: 140 },
      { label: '150', value: 150 },
    ];
  }

  getFittingValveOptions() {
    return [
      { label: `45 deg Elbow`, value: `45' Elbow` },
      { label: `90 deg Long Turn Elbow`, value: `90' Long Turn` },
      { label: `90 deg Standard Elbow`, value: `90' Standard` },
      { label: `Butterfly Valve`, value: `Butterfly Valve` },
      { label: `Gate Valve`, value: `Gate Valve` },
      { label: `Swing Check Valve`, value: `Swing Check Valve` },
      { label: `Tee Or Cross`, value: `Tee Or Cross` },
    ];
  }

  getInsideDaimeterOption() {
    return [
      { label: '2', value: 2.067 },
      { label: '2 1/2', value: 2.469 },
      { label: '3', value: 3.068 },
      { label: '3 1/2', value: 3.548 },
      { label: '4', value: 4.026 },
      { label: '6', value: 6.065 },
      { label: '8', value: 7.981 },
      { label: '10', value: 10.02 },
      { label: '12', value: 11.938 },
    ];
  }

  generateFrictionLossId(field) {
    if (field?.formControl?.value === null) {
      const id = this.utilService.uuid();
      field?.formControl?.setValue(id);
    }
  }

  numberOfFittingValveValidation(control, field): boolean {
    const value = control.value;

    if (value !== null && value !== '') {
      const isNotNo = isNaN(value);
      if (!isNotNo) {
        if (Number(value) <= 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  addFittingValvesForNewRisk(field) {
    const obj = [
      {
        fittingAndValveType: 'Tee Or Cross',
        numbersOfEachFittingAndValve: null,
      },
      {
        fittingAndValveType: 'Gate Valve',
        numbersOfEachFittingAndValve: null,
      },
      {
        fittingAndValveType: `90' Long Turn`,
        numbersOfEachFittingAndValve: null,
      },
    ];
    const isNewRisk = this.formlyBuilderService.isNewRisk();
    if (isNewRisk && field?.model && field?.model.length > 0) {
      field.model[field.model.length - 1]['waterSupplyFrictionLossFittingsAndValves'] = obj;
    }

    field.options.formState.service.riskReport.model = {
      ...field.options.formState.service.riskReport.model,
    };
    return field;
  }

  checkWaterSupplySource2(field): boolean {
    const isSS2 =
      field?.options?.formState?.service?.riskReport?.model?.sprinklerReport?.asgrWaterSupply?.supplySource?.ss2
        ?.supplySourceTypeSs2;
    if (isSS2 !== null && isSS2 !== undefined && isSS2 !== '') {
      return false;
    } else {
      return true;
    }
  }
  updatePipeNumberValue(field) {
    const formValue = field?.formControl?.controls;
    if (formValue && formValue.length > 0) {
      formValue.forEach((item, i) => {
        field?.formControl?.controls[i].get('pipeNumber').setValue(i + 1);
      });
    }
  }
  setValveNumberLength(fsData) {
    fsData = fsData.map((item) => {
      item.WaterSupplyFrictionLossFittingsAndValves = item?.WaterSupplyFrictionLossFittingsAndValves?.map((val) => {
        const returnValue = this.calculateFittingAndValveLength(item.PipeInsideDiameter, val.FittingAndValveType);
        if (returnValue !== null) {
          val.FittingAndValveLength = returnValue?.valveLength;
          val.FittingAndValveNumber = returnValue?.valveNumber;
        }
        return val;
      });
      return item;
    });
    return fsData;
  }
  calculateFittingAndValveLength(diameter, valveType) {
    const calculationArray = [
      {
        nominalDiameter: 2.067, //2
        value: [
          {
            valveType: `45' deg Elbow`,
            valveNumber: 1,
            valveLength: 2,
          },
          {
            valveType: `90' Standard`,
            valveNumber: 2,
            valveLength: 5,
          },
          {
            valveType: `90' Long Turn`,
            valveNumber: 3,
            valveLength: 3,
          },
          {
            valveType: `Tee Or Cross`,
            valveNumber: 4,
            valveLength: 10,
          },
          {
            valveType: `Butterfly Valve`,
            valveNumber: 5,
            valveLength: 6,
          },
          {
            valveType: `Gate Valve`,
            valveNumber: 6,
            valveLength: 1,
          },
          {
            valveType: `Swing Check Valve`,
            valveNumber: 7,
            valveLength: 11,
          },
        ],
      },
      {
        nominalDiameter: 2.469, //2 1/2
        value: [
          {
            valveType: `45' deg Elbow`,
            valveNumber: 1,
            valveLength: 3,
          },
          {
            valveType: `90' Standard`,
            valveNumber: 2,
            valveLength: 6,
          },
          {
            valveType: `90' Long Turn`,
            valveNumber: 3,
            valveLength: 4,
          },
          {
            valveType: `Tee Or Cross`,
            valveNumber: 4,
            valveLength: 12,
          },
          {
            valveType: `Butterfly Valve`,
            valveNumber: 5,
            valveLength: 7,
          },
          {
            valveType: `Gate Valve`,
            valveNumber: 6,
            valveLength: 1,
          },
          {
            valveType: `Swing Check Valve`,
            valveNumber: 7,
            valveLength: 14,
          },
        ],
      },
      {
        nominalDiameter: 3.068, //3
        value: [
          {
            valveType: `45' deg Elbow`,
            valveNumber: 1,
            valveLength: 3,
          },
          {
            valveType: `90' Standard`,
            valveNumber: 2,
            valveLength: 7,
          },
          {
            valveType: `90' Long Turn`,
            valveNumber: 3,
            valveLength: 5,
          },
          {
            valveType: `Tee Or Cross`,
            valveNumber: 4,
            valveLength: 15,
          },
          {
            valveType: `Butterfly Valve`,
            valveNumber: 5,
            valveLength: 10,
          },
          {
            valveType: `Gate Valve`,
            valveNumber: 6,
            valveLength: 1,
          },
          {
            valveType: `Swing Check Valve`,
            valveNumber: 7,
            valveLength: 16,
          },
        ],
      },
      {
        nominalDiameter: 3.548, //3 1/2
        value: [
          {
            valveType: `45' deg Elbow`,
            valveNumber: 1,
            valveLength: 3,
          },
          {
            valveType: `90' Standard`,
            valveNumber: 2,
            valveLength: 8,
          },
          {
            valveType: `90' Long Turn`,
            valveNumber: 3,
            valveLength: 5,
          },
          {
            valveType: `Tee Or Cross`,
            valveNumber: 4,
            valveLength: 17,
          },
          {
            valveType: `Butterfly Valve`,
            valveNumber: 5,
            valveLength: 0,
          },
          {
            valveType: `Gate Valve`,
            valveNumber: 6,
            valveLength: 1,
          },
          {
            valveType: `Swing Check Valve`,
            valveNumber: 7,
            valveLength: 19,
          },
        ],
      },
      {
        nominalDiameter: 4.026, //4
        value: [
          {
            valveType: `45' deg Elbow`,
            valveNumber: 1,
            valveLength: 4,
          },
          {
            valveType: `90' Standard`,
            valveNumber: 2,
            valveLength: 10,
          },
          {
            valveType: `90' Long Turn`,
            valveNumber: 3,
            valveLength: 6,
          },
          {
            valveType: `Tee Or Cross`,
            valveNumber: 4,
            valveLength: 20,
          },
          {
            valveType: `Butterfly Valve`,
            valveNumber: 5,
            valveLength: 12,
          },
          {
            valveType: `Gate Valve`,
            valveNumber: 6,
            valveLength: 2,
          },
          {
            valveType: `Swing Check Valve`,
            valveNumber: 7,
            valveLength: 22,
          },
        ],
      },
      {
        nominalDiameter: 6.065, //6
        value: [
          {
            valveType: `45' deg Elbow`,
            valveNumber: 1,
            valveLength: 7,
          },
          {
            valveType: `90' Standard`,
            valveNumber: 2,
            valveLength: 14,
          },
          {
            valveType: `90' Long Turn`,
            valveNumber: 3,
            valveLength: 9,
          },
          {
            valveType: `Tee Or Cross`,
            valveNumber: 4,
            valveLength: 30,
          },
          {
            valveType: `Butterfly Valve`,
            valveNumber: 5,
            valveLength: 10,
          },
          {
            valveType: `Gate Valve`,
            valveNumber: 6,
            valveLength: 3,
          },
          {
            valveType: `Swing Check Valve`,
            valveNumber: 7,
            valveLength: 32,
          },
        ],
      },
      {
        nominalDiameter: 7.981, //8
        value: [
          {
            valveType: `45' deg Elbow`,
            valveNumber: 1,
            valveLength: 9,
          },
          {
            valveType: `90' Standard`,
            valveNumber: 2,
            valveLength: 18,
          },
          {
            valveType: `90' Long Turn`,
            valveNumber: 3,
            valveLength: 13,
          },
          {
            valveType: `Tee Or Cross`,
            valveNumber: 4,
            valveLength: 35,
          },
          {
            valveType: `Butterfly Valve`,
            valveNumber: 5,
            valveLength: 12,
          },
          {
            valveType: `Gate Valve`,
            valveNumber: 6,
            valveLength: 4,
          },
          {
            valveType: `Swing Check Valve`,
            valveNumber: 7,
            valveLength: 45,
          },
        ],
      },
      {
        nominalDiameter: 10.02, //10
        value: [
          {
            valveType: `45' deg Elbow`,
            valveNumber: 1,
            valveLength: 11,
          },
          {
            valveType: `90' Standard`,
            valveNumber: 2,
            valveLength: 22,
          },
          {
            valveType: `90' Long Turn`,
            valveNumber: 3,
            valveLength: 16,
          },
          {
            valveType: `Tee Or Cross`,
            valveNumber: 4,
            valveLength: 50,
          },
          {
            valveType: `Butterfly Valve`,
            valveNumber: 5,
            valveLength: 19,
          },
          {
            valveType: `Gate Valve`,
            valveNumber: 6,
            valveLength: 5,
          },
          {
            valveType: `Swing Check Valve`,
            valveNumber: 7,
            valveLength: 55,
          },
        ],
      },
      {
        nominalDiameter: 11.938, //12
        value: [
          {
            valveType: `45' deg Elbow`,
            valveNumber: 1,
            valveLength: 13,
          },
          {
            valveType: `90' Standard`,
            valveNumber: 2,
            valveLength: 27,
          },
          {
            valveType: `90' Long Turn`,
            valveNumber: 3,
            valveLength: 18,
          },
          {
            valveType: `Tee Or Cross`,
            valveNumber: 4,
            valveLength: 60,
          },
          {
            valveType: `Butterfly Valve`,
            valveNumber: 5,
            valveLength: 21,
          },
          {
            valveType: `Gate Valve`,
            valveNumber: 6,
            valveLength: 6,
          },
          {
            valveType: `Swing Check Valve`,
            valveNumber: 7,
            valveLength: 65,
          },
        ],
      },
    ];

    let obj = null;
    if (diameter && valveType) {
      const selectedDiameter = calculationArray.filter((item) => {
        if (item?.nominalDiameter === diameter) {
          return item;
        }
      });

      let selectedType;
      if (selectedDiameter && selectedDiameter.length > 0) {
        const value = selectedDiameter[ 0 ].value;
        selectedType = value.filter((item) => {
          if (item?.valveType === valveType) {
            return item;
          }
        });
      }

      if (selectedType && selectedType.length > 0) {
        obj = {};
        obj = { valveNumber: selectedType[0].valveNumber, valveLength: selectedType[0].valveLength };
      }
    }
    return obj;
  }

  // will return the label of sprinklertypecode
  getNominalDiameterValue(field, diameterValue) {
    const frictionLossService = field.options.formState.service.frictionLossService;
    const options = frictionLossService.getInsideDaimeterOption();
    const option = options.find((item) => item.value === Number(diameterValue));
    return option ? option.label : '';
  }

  getFittingValveValue(field, fittingValveValue) {
    const values = fittingValveValue?.split(',');
    const valves = [];
    const frictionLossService = field.options.formState.service.frictionLossService;
    const options = frictionLossService.getFittingValveOptions();
    if (values && values.length > 0) {
      values.forEach((val) => {
        if (val && val !== '') {
          const obj = options.find((item) => item.value === val);
          const label = obj ? obj.label : '';
          valves.push(label);
        }
      });
    }
    return valves?.toString();
  }
}
