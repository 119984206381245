import { Injectable } from '@angular/core';
import { WaterSupplyHydraulicDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/water-supply-hydraulic-data.model';
import { RiskReportService } from '../../risk-report.service';
import { WaterSupplyPipeScheduleDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/water-supply-pipe-schedule-data.model';

@Injectable({
  providedIn: 'root',
})
export class HydralicService {
  constructor(private riskReportService: RiskReportService) {

  }

  sortAndFormatHydralicSpoc(waterSupplyHydraulics, version) {
    let waterSupplyHydraulicsArray = [];
    if (waterSupplyHydraulics?.length === 7) {
      waterSupplyHydraulicsArray = this.sortByProperty(waterSupplyHydraulics, 'spocNumber');
    } else {
      for (let i = 1; i <= 7; i++) {
        const element = waterSupplyHydraulics.find(item => item.spocNumber === i);
        if (element) {
          waterSupplyHydraulicsArray.push(element);
        } else {
          const reportId = this.riskReportService.report.SprinklerReport.ReportId;
          const waterSupplyHydraulic = WaterSupplyHydraulicDataModel.createEmptySpocModel(reportId, i, version);
          waterSupplyHydraulicsArray.push(waterSupplyHydraulic);
        }
      }
    }
    return waterSupplyHydraulicsArray;
  }

  sortAndFormatPipeScheduleSpoc(waterSupplyPipeSchedules, version) {
    let waterSupplyPipeSchedulesArray = [];
    if (waterSupplyPipeSchedules?.length === 6) {
      waterSupplyPipeSchedulesArray = this.sortByProperty(waterSupplyPipeSchedules, 'spocNumber');
    } else {
      for (let i = 1; i < 7; i++) {
        const element = waterSupplyPipeSchedules.find(item => item.spocNumber === i);
        if (element) {
          waterSupplyPipeSchedulesArray.push(element);
        } else {
          const reportId = this.riskReportService.report.SprinklerReport.ReportId;
          const waterSupplyPipeSchedule = WaterSupplyPipeScheduleDataModel.createEmptySpocModel(reportId, i, version);
          waterSupplyPipeSchedulesArray.push(waterSupplyPipeSchedule);
        }
      }
    }
    return waterSupplyPipeSchedulesArray;
  }

   /**
   * @param * array
   * @param string property
   * @return * sorted Array
   * @memberof FormlyFloorsRoofService
   */
   sortByProperty(array: any, property: string): any {
    if (array.length && array.length > 1) {
      array.sort((a, b) => Number(a[ property ]) - Number(b[ property ]));
    }
    return array;
  }
}

