export class UserPermssions {
    UserId: string = '';
    Roles: Role[] = [];
    Permissions: Permssion[] = []
}

export class Role {
    Name: string = '';
}

export class Permssion {
    Resource: string = '';
    CanView: boolean;
    CanEdit: boolean
}