import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';

/**
 * Class that represents the Formly Model for BuildingBG
 */
export class BuildingBGDataModel {
  static sectionName = 'buildingBg';

  buildingBg: string;

  // TODO: Remove Amplify once unnecessary
  static from(reports: any): BuildingBGDataModel {
    return {
      buildingBg: reports.data.BG2Symbol.trim(),
    };
  }

  static fromS3(reports: RiskReportDataReports): BuildingBGDataModel {
    return {
      buildingBg: reports.BasicGroup2Symbol ? reports.BasicGroup2Symbol.trim() : '',
    };
  }

  /**
   *
   * @method to - to store data back to s3
   * @param string buildingBg
   * @param * reports
   */
  static to(model: any): any {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return { BasicGroup2Symbol: model.buildingBg };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    if (!formlyModel.buildingBg || !riskReportModel.BasicGroup2Symbol) {
      return false;
    }

    if (formlyModel.buildingBg === riskReportModel.BasicGroup2Symbol) {
      return false;
    } else {
      return true;
    }
  }
}
