/* eslint-disable @typescript-eslint/naming-convention */
import { Guid } from 'guid-typescript';

/**
 * Class that represents the Formly Model for BuildingBG
 */
export class SurveyDetailsDataModel {
  static sectionName = 'survey';

  dataCreation: string;
  surveyDate: string;
  riskTypeCodeValue?: string;

  static fromS3(reports: any): SurveyDetailsDataModel {
    // if (reports?.SurveyDate === undefined || reports?.SurveyDate === null) {
    //   if (reports && reports?.ReportRelatedDates && reports?.ReportRelatedDates?.length > 0 &&
    //     reports?.ReportRelatedDates?.find(src => src?.ReportDateTypeCodeValue === 'OSSD') !== undefined) {
    //     reports.SurveyDate = reports?.ReportRelatedDates?.find(src => src?.ReportDateTypeCodeValue === 'OSSD')?.ReportDateTime;
    //   }
    // }
    return {
      dataCreation: reports?.SprinklerReport?.DataCreation ? reports?.SprinklerReport?.DataCreation : 'On-site Survey',
      surveyDate: reports?.SurveyDate,
      riskTypeCodeValue: reports?.RiskTypeCodeValue
    };
  }

  /**
   *
   * @method to - to store data back to s3
   * @param string buildingBg
   * @param * reports
   */
  static to(model: any): any {
    return {
      DataCreation: model.survey.dataCreation ? model.survey.dataCreation : '',
      SurveyDate: model.survey.surveyDate ? model.survey.surveyDate : null,
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    // if (!formlyModel.buildingBg || !riskReportModel.BasicGroup2Symbol) {
    //   return false;
    // }

    // if (formlyModel.buildingBg === riskReportModel.BasicGroup2Symbol) {
    //   return false;
    // } else {
    //   return true;
    // }
    return true;
  }

  /**
   * Set Report Related Dates
   *
   * @param surveyDate survey date
   * @param riskReportData S3 Risk Report
   */
  static setRelatedDates(surveyDate: any, riskReportData: any) {
    if (riskReportData && riskReportData?.ReportRelatedDates && riskReportData?.ReportRelatedDates?.length > 0) {
      if (riskReportData?.ReportRelatedDates?.find(src => src?.ReportDateTypeCodeValue === 'OSSD') !== undefined) {
        riskReportData?.ReportRelatedDates?.forEach(x =>
          x.ReportDateTime = x.ReportDateTypeCodeValue === 'OSSD' ? surveyDate : x.ReportDateTime);
      } else {
        riskReportData?.ReportRelatedDates.push({
          ReportIdentifier: riskReportData.ReportIdentifier,
          ReportRelatedDateIdentifier: Guid.create().toString(),
          ReportDateTypeCodeValue: 'OSSD',
          ReportDateTime: surveyDate
        });
      }
      return riskReportData?.ReportRelatedDates;
    } else {
      return [ {
        ReportIdentifier: riskReportData.ReportIdentifier,
        ReportRelatedDateIdentifier: Guid.create().toString(),
        ReportDateTypeCodeValue: 'OSSD',
        ReportDateTime: surveyDate
      } ];
    }
  }
}
