import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Subject } from 'rxjs';
import { Constants } from 'src/app/services/util-service/constants';



export class PublicProtectionClassSectionModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe1 = new Subject<void>();
  protected ngUnsubscribe2 = new Subject<void>();
  protected ngUnsubscribe3 = new Subject<void>();
  protected ngUnsubscribe4 = new Subject<void>();
  protected ngUnsubscribe5 = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super(
      {
        key: 'publicProtection',
        className: 'outside-section public-protection-container-outer',
        wrappers: [ 'simple-section-wrapper' ],
        props: {
          label: 'Public Protection Class',
          isCollapsable: true,
          className: 'padding-bottom-20',
        },
        fieldGroupClassName: 'public-protection-container',
        fieldGroup: [
          {
            fieldGroup: [
              {
                fieldGroup: [
                  {
                    key: 'fireProtectionArea',
                    type: 'search-box',
                    props: {
                      label: 'Fire Protection Area',
                      isDisabled: false,
                      searchList: true,
                      popover: true,
                      showError: true,
                      required: true,
                    },
                    expressions: {
                    },
                    hooks: {
                      onInit: (field: FormlyFieldConfig) => {
                        if (field.formControl?.value !== null && field.formControl?.value !== undefined) {
                          field?.form?.get('isFireProtectionArea')?.setValue(true);
                        }
                        field?.formControl?.valueChanges?.pipe(takeUntil(this.ngUnsubscribe1))
                          .subscribe(value => {
                            if (value !== null && value !== undefined) {
                              field?.form?.get('isFireProtectionArea')?.setValue(true);
                            }
                          });
                      },
                      onDestroy: () => {
                        this.ngUnsubscribe1.next();
                        this.ngUnsubscribe1.complete();
                      }
                    }
                  }
                ]
              },
              {
                wrappers: [ 'question-wrapper' ],
                props: {
                  label: 'Public Protection Class',
                  firstColSize: 4,
                  isHorizontalOrientation: true,
                  hasShadow: true,
                  showError: false,
                  isSectionLabelNormal: true
                },
                // fieldGroupClassName: 'formly-flex-container',
                fieldGroup: [
                  {
                    key: 'publicProtectionClass',
                    type: 'simple-select',
                    className: 'ppc-select-margin',
                    props: {
                      label: 'V¹',
                      isHorizontalOrientation: true,
                      selectWidth: true,
                      required: true,
                      isAsteriskNotRequired: true,
                      superScriptText: '1',
                      isSupEnabled: true,
                      options: [],
                    },
                    hooks: {
                      onInit: (field) => {
                        const formlyService = field.options.formState.service;
                        formlyService.publicProtectionService.addField(field);
                        formlyService.publicProtectionService.publicProtectionClassValues(field);
                        if (field?.formControl?.value !== null && field?.formControl?.value !== undefined ) {
                          field?.form?.get('isPublicProtectionClass')?.setValue(true);
                        }
                        field?.formControl?.valueChanges?.pipe(takeUntil(this.ngUnsubscribe2))
                          .subscribe(value => {
                            if (value !== null && value !== undefined) {
                              field?.form?.get('isPublicProtectionClass')?.setValue(true);
                            }
                          });
                      },
                      onDestroy: () => {
                        this.ngUnsubscribe2.next();
                        this.ngUnsubscribe2.complete();
                      }
                    },

                  },
                ],
              },
              {
                key: 'territoryCode',
                type: 'simple-select',
                wrappers: [ 'question-wrapper' ],
                className: 'select-full-width ppc-select-margin',
                props: {
                  label: 'Territory Code',
                  required: true,
                  isSectionLabelNormal: true,
                  firstColSize: 7,
                  showError: false,
                  isAsteriskNotRequired: true,
                  isCardEnabled: true,
                  isLabelDisabled: true,
                  options: formlyBuilderService.publicProtectionAreaService.selectDataStream as any,
                },
                hooks: {
                  onInit: (field) => {
                    formlyBuilderService?.publicProtectionAreaService?.getTerritory(field?.form?.get('fireProtectionArea')?.value);
                    if (field?.form?.get('fireProtectionArea')?.value) {
                      setTimeout(() => {
                        field.formControl.updateValueAndValidity();
                      }, 1500)
                    }
                    if (field.formControl?.value !== null && field.formControl?.value !== undefined) {
                      field?.form?.get('isTerritoryCode')?.setValue(true);
                    }
                    field.formControl.valueChanges.subscribe(value => {
                      if (value !== null && value !== undefined) {
                        field?.form?.get('isTerritoryCode')?.setValue(true);
                      }
                    });
                  },
                },
              },
              {
                key: 'isHydrantsWithin1000feet',
                type: 'rf-yes-no',
                wrappers: [ 'question-wrapper' ],
                props: {
                  label: 'Fire Hydrant within 1,000\'',
                  firstColSize: 4,
                  isHorizontalOrientation: true,
                  hasShadow: true,
                  showError: false,
                  isSectionLabelNormal: true,
                  // isAsteriskNotRequired: true,
                  disabled: false,
                  required: true,
                  isTitleDisableable: false
                },
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    if (field?.formControl?.value !== null && field?.formControl?.value !== undefined) {
                      field?.form?.get('isPpcFireHydrant')?.setValue(true);
                    }
                    field?.formControl?.valueChanges?.pipe(takeUntil(this.ngUnsubscribe4))
                      .subscribe(value => {
                        if (value !== null && value !== undefined) {
                          field?.form?.get('isPpcFireHydrant')?.setValue(true);
                        }
                      });
                  },
                  onDestroy: () => {
                    this.ngUnsubscribe4.next();
                    this.ngUnsubscribe4.complete();
                  }
                },
              },
              {
                wrappers: [ 'question-wrapper' ],
                props: {
                  label: 'Needed Fire Flow',
                  firstColSize: 3,
                  isHorizontalOrientation: true,
                  hasShadow: true,
                  showError: false,
                  isSectionLabelNormal: true
                },
                fieldGroupClassName: 'formly-flex-container',
                fieldGroup: [
                  {
                    key: 'neededFireFlow',
                    type: 'simple-input',
                    className: 'fire-flow-label-align ppc-field-padding',
                    props: {
                      label: 'V¹',
                      unit: 'GPM',
                      isHorizontalOrientation: true,
                      readonly: true,
                      required: false,
                      type: 'number',
                      maxLength: 5,
                      min: 0,
                      max: 99999,
                    },
                  },
                ],
              },
              {
                key: 'isPublicProtectionClass'
              },
              {
                key: 'isFireProtectionArea'
              },
              {
                key: 'isTerritoryCode'
              },
              {
                key: 'isPpcFireHydrant'
              }
            ],
            hooks: {
              onInit: (field?: FormlyFieldConfig) => {
                field.formControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe5), distinctUntilChanged(),
                  debounceTime(Constants.riskFormDataSyncDelay))
                  .subscribe(value => {
                    if (!field.formControl.pristine) {
                      formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_OW_PPC, value, true);
                    }
                  });
              },
              onDestroy: () => {
                this.ngUnsubscribe5.next();
                this.ngUnsubscribe5.complete();
              }
            }
          },
          {
            className: 'section-two-outside-section',
            wrappers: [ 'simple-section-wrapper' ],
            resetOnHide: true,
            props: {
              label: 'Section II Evaluation',
              className: 'padding-bottom-20',
              isCollapsable: false,
              showError: false
            },
            fieldGroupClassName: 'section-two-evaluation-container',
            fieldGroup: [
              {
                wrappers: [ 'simple-section-wrapper' ],
                props: {
                  hasTitleRow: false
                },
                fieldGroupClassName: 'hydrant-input',
                fieldGroup: [
                  {
                    wrappers: [ 'question-wrapper' ],
                    type: 'simple-input',
                    className: 'hydrant-with-outlet',
                    key: 'hydrantsWithPumperOutlet',
                    props: {
                      label: '# of hydrants within 1000 road ft. with 4” pumper outlet',
                      isHorizontalOrientation: true,
                      firstColSize: 9,
                      checkTouchedControl: true,
                      showError: false,
                      isSectionLabelNormal: true,
                      isLabelDisabled: true,
                      isLabelNotRequired: false,
                      hasShadow: false,
                      type: 'number',
                      required: true,
                      maxLength: 3,
                      isAsteriskNotRequired: true,
                      options: [],
                    },
                    // expressions: {
                    //   'props.required': (field: FormlyFieldConfig) => (field?.parent?.parent?.hide === false) ? true : false,
                    // }
                  },
                  {
                    wrappers: [ 'question-wrapper' ],
                    type: 'simple-input',
                    className: 'hydrant-with-outlet',
                    key: 'hydrantsWithTwoHoseOutlets',
                    props: {
                      label: '# of hydrants within 1000 road feet with two 2 1/2" hose outlets',
                      firstColSize: 9,
                      isHorizontalOrientation: true,
                      showError: false,
                      isSectionLabelNormal: true,
                      checkTouchedControl: true,
                      isLabelDisabled: true,
                      isLabelNotRequired: false,
                      hasShadow: false,
                      type: 'number',
                      required: true,
                      maxLength: 3,
                      isAsteriskNotRequired: true,
                      options: [],
                    }
                  },
                  {
                    wrappers: [ 'question-wrapper' ],
                    type: 'simple-input',
                    className: 'hydrant-with-outlet',
                    key: 'hydrantsWithOneHoseOutlets',
                    props: {
                      label: '# of hydrants within 1000 road feet with one 2 1/2" hose outlet',
                      isHorizontalOrientation: true,
                      firstColSize: 9,
                      isSectionLabelNormal: true,
                      checkTouchedControl: true,
                      showError: false,
                      isLabelDisabled: true,
                      isLabelNotRequired: false,
                      hasShadow: false,
                      type: 'number',
                      required: true,
                      maxLength: 3,
                      isAsteriskNotRequired: true,
                      options: [],
                    }
                  },
                  {
                    fieldGroupClassName: 'action-needed-container',
                    fieldGroup: [
                      {
                        className: 'action-needed action-width',
                        template: `<div class="action-needed-icon"><span>i</span></div><div><b>Action Needed.</b>
                                      </div><div>Email X for the next two fields</div>`,
                      },
                      {
                        type: 'rf-image',
                        className: 'action-needed-button',
                        props: {
                          buttonLabel: 'Get Data via Email',
                          action: (field: FormlyFieldConfig) => {
                            formlyBuilderService.publicProtectionService.getDataViaEmail(field);
                          },
                        },
                      },
                    ],
                  },
                  {
                    wrappers: [ 'question-wrapper' ],
                    type: 'simple-input',
                    className: 'hydrant-with-outlet',
                    key: 'waterSupplyWorksAdequacy',
                    props: {
                      label: 'Water Supply Works Adequacy',
                      isSectionLabelNormal: true,
                      isLabelDisabled: true,
                      isLabelNotRequired: false,
                      checkTouchedControl: true,
                      isInfoIconEnabled: true,
                      showError: false,
                      hasShadow: false,
                      isHorizontalOrientation: true,
                      firstColSize: 9,
                      type: 'number',
                      required: true,
                      unit: '%',
                      maxLength: 3,
                      max: 100,
                      isAsteriskNotRequired: true,
                      options: [],
                    }
                  },
                  {
                    wrappers: [ 'question-wrapper' ],
                    type: 'simple-input',
                    className: 'hydrant-with-outlet',
                    key: 'fireDepartmentCompaniesAdequacy',
                    props: {
                      isSectionLabelNormal: true,
                      isLabelDisabled: true,
                      showError: false,
                      isLabelNotRequired: false,
                      checkTouchedControl: true,
                      label: 'FD Companies Adequacy',
                      hasShadow: false,
                      isHorizontalOrientation: true,
                      firstColSize: 9,
                      type: 'number',
                      required: true,
                      isInfoIconEnabled: true,
                      unit: '%',
                      maxLength: 3,
                      max: 100,
                      isAsteriskNotRequired: true,
                      options: [],
                    }
                  },
                  {
                    type: 'rf-image',
                    className: 'calculator-wrapper',
                    props: {
                      buttonLabel: 'Calculate!',
                      source: 'calculator.png',
                      action: (field: FormlyFieldConfig) => formlyBuilderService.publicProtectionService.estimate(field)
                    },
                  }
                ],
              },
              {
                wrappers: [ 'simple-section-wrapper' ],
                props: {
                  label: 'Section II Results',
                  isCollapsable: false,
                  showError: false,
                  isCollapsed: true,
                },
                fieldGroupClassName: 'section-evaluation-result',
                fieldGroup: [
                  {
                    type: 'summary-row',
                    className: 'section-evaluation-result-row',
                    fieldGroup: [
                      {
                        key: 'calculatedWaterSupplyWorksAdequacy',
                        props: {
                          label: 'Water Supply Works Adequacy',
                          unit: '%'
                        },
                      },
                    ]
                  },
                  {
                    key: 'calculatedWaterSupplyWorksAdequacy'
                  },
                  {
                    type: 'summary-row',
                    className: 'section-evaluation-result-row',
                    fieldGroup: [
                      {
                        key: 'hydrantSpacingAdequacy',
                        props: {
                          label: 'Hydrant Spacing Adequacy',
                          unit: '%'
                        }
                      }
                    ]
                  },
                  {
                    key: 'hydrantSpacingAdequacy'
                  },
                  {
                    type: 'summary-row',
                    className: 'section-evaluation-result-row',
                    fieldGroup: [
                      {
                        key: 'calculatedFireDepartmentCompaniesAdequacy',
                        props: {
                          label: 'FD Companies Adequacy',
                          unit: '%'
                        }
                      }
                    ]
                  },
                  {
                    key: 'calculatedFireDepartmentCompaniesAdequacy'
                  },
                  {
                    type: 'summary-row',
                    className: 'section-evaluation-result-row',
                    fieldGroup: [
                      {
                        key: 'fireFlowAt20Psi',
                        props: {
                          label: 'Fire Flow available @20 psi',
                        }
                      }
                    ]
                  },
                  {
                    key: 'fireFlowAt20Psi'
                  },
                  {
                    type: 'summary-row',
                    className: 'section-evaluation-result-row',
                    fieldGroup: [
                      {
                        key: 'overallAdequacy',
                        props: {
                          label: 'Fire Flow Adequate',
                          unit: '%'
                        }
                      }
                    ]
                  },
                  {
                    key: 'overallAdequacy',
                  },
                  {
                    type: 'summary-row',
                    className: 'section-evaluation-result-row',
                    fieldGroup: [
                      {
                        key: 'evaluatedClass',
                        props: {
                          label: 'Evaluated Class'
                        }
                      }
                    ]
                  },
                  {
                    key: 'evaluatedClass'
                  },
                ],
              }
            ],
            expressions: {
              hide: (field: FormlyFieldConfig) => field.form.get('neededFireFlow')?.value > 3500 ? false : true,
              'props.isCollapsable': (field: FormlyFieldConfig) => field.form.get('neededFireFlow')?.value > 3500 ? false : true
            }
          },
        ],
      },);
  }
};
