import { InjectionToken } from '@angular/core';
import { ImageOptions } from '@capacitor/camera';
import { ScheduleEvery } from '@capacitor/local-notifications';

export interface Environment {
  readonly analyticStreamName: string;
  readonly production: boolean;
  readonly showCoverage: boolean;
  readonly iSurveyAPIurl: string;
  readonly mapboxGLAccessToken: string;
  readonly googleMapsApiKey: string;
  readonly searchDelay: number;
  readonly auth_config?: AuthConfig;
  readonly BINs_config?: BINsConfig;
  readonly audience?: string;
  readonly scheme?: string;
  readonly isEnabledLogs: boolean;
  readonly cameraDefaults: ImageOptions;
  readonly lookUpSyncInterval: number,
  readonly templateSyncInterval: number,
  readonly localNotificationDelay: ScheduleEvery,
  readonly users?: string[];
  readonly isEnabledUWForms: boolean;
  readonly isUWFormRelease: boolean;
  readonly isBureauState: boolean;
  readonly graphqlUrl: string;
  readonly enabledForTesting: boolean;
}

export interface BINsConfig {
  readonly orgId: string;
  readonly shipId: string;
  readonly quoteback: string;
}

export interface AuthConfig {
  client_id: string;
  server_host: string;
  redirect_url: string;
  end_session_redirect_url: string;
  scopes: string;
  pkce: boolean;
}

export const ENV = new InjectionToken<Environment>('ENV');
