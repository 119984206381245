import { ExposureDataModel } from './exposure-data.model';

export class ExposuresDataModel {
  static sectionName = 'exposures';
  exposuresArray: ExposureDataModel[];

  static from(exposure: ExposureDataModel[]): ExposuresDataModel {
    return {
      exposuresArray: exposure,
    };
  }

  static fromS3(exposure: ExposureDataModel[]): ExposuresDataModel {
    return {
      exposuresArray: exposure,
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   * @returns
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    return true;
  }

  static to(model: any) {
    const exposuresRrModel = [];
    model?.exposuresArray?.forEach((exposure) => {
      const exposureData = ExposureDataModel.to(exposure);
      exposuresRrModel.push(exposureData);
    });
    return { Exposures: exposuresRrModel };
  }
}
