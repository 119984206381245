// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Form, Order, SurveyOrder, Target, RiskReport, Lookup, Group, User } = initSchema(schema);

export {
  Form,
  Order,
  SurveyOrder,
  Target,
  RiskReport,
  Lookup,
  Group,
  User
};