import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class SystemComponentSprinklersDataModel {
  static sectionName = 'systemComponentSprinkler';

  sprinklerId: string;
  reportId: string;
  systemNumber: string;
  sprinklerMakeAndModel: string;
  yearManufactured: number;
  yearManufacturedComment: string;
  numberOfUprightSprinklers: number;
  numberOfPendantSprinklers: number;
  numberOfSideWallSprinklers: number;
  numberOfDryPendantSprinklers: number;
  orificeSize: number;
  orificeSizeComment: string;
  temperatureRating: number;
  comments: string;
  static fromS3(sprinklersData: any) {
    if (sprinklersData !== null && sprinklersData && sprinklersData.length > 0) {
      const sprinklerTableData = sprinklersData.map((item) => {
        const object = {
          sprinklerId: item.SprinklerId,
          reportId: item.ReportId,
          systemNumber: item.SystemNumber,
          sprinklerMakeAndModel: item.SprinklerMakeAndModel,
          yearManufactured: item.YearManufactured,
          yearManufacturedComment: item.YearManufacturedComment,
          numberOfUprightSprinklers: item.NumberOfUprightSprinklers,
          numberOfPendantSprinklers: item.NumberOfPendantSprinklers,
          numberOfSideWallSprinklers: item.NumberOfSideWallSprinklers,
          numberOfDryPendantSprinklers: item.NumberOfDryPendantSprinklers,
          orificeSize: item.OrificeSize,
          orificeSizeComment: item.OrificeSizeComment,
          temperatureRating: item.TemperatureRating,
          comments: item.Comments,
        };
        return object;
      });
      return sprinklerTableData;
    } else {
      return null;
    }
  }

  static to(model: any, sectionEnum?: SectionEnum) {
    const sprinklers = model.map((item) => {
      const object = {
        SprinklerId: item?.sprinklerId,
        ReportId: item?.reportId,
        SystemNumber: item?.systemNumber ? item.systemNumber : '',
        SprinklerMakeAndModel: item?.sprinklerMakeAndModel ? item?.sprinklerMakeAndModel : null,
        YearManufactured:
          item?.yearManufactured !== null && item?.yearManufactured ? Number(item?.yearManufactured) : null,
        YearManufacturedComment: item.yearManufacturedComment,
        NumberOfUprightSprinklers:
          item?.numberOfUprightSprinklers !== null && item?.numberOfUprightSprinklers
            ? Number(item?.numberOfUprightSprinklers)
            : null,
        NumberOfPendantSprinklers:
          item?.numberOfPendantSprinklers !== null && item?.numberOfPendantSprinklers
            ? Number(item?.numberOfPendantSprinklers)
            : null,
        NumberOfSideWallSprinklers:
          item?.numberOfSideWallSprinklers !== null && item?.numberOfSideWallSprinklers
            ? Number(item?.numberOfSideWallSprinklers)
            : null,
        NumberOfDryPendantSprinklers:
          item?.numberOfDryPendantSprinklers !== null && item?.numberOfDryPendantSprinklers
            ? Number(item?.numberOfDryPendantSprinklers)
            : null,
        OrificeSize: item?.orificeSize !== null && item?.orificeSize ? Number(item?.orificeSize) : null,
        OrificeSizeComment: item.orificeSizeComment,
        TemperatureRating:
          item?.temperatureRating !== null && item?.temperatureRating ? Number(item?.temperatureRating) : null,
        Comments: item?.comments ? item.comments : null,
      };
      return object;
    });
    return sprinklers;
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
    return false;
  }
}
