import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyBuilderService } from './formly-builder.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { SectionEnum } from './section.config';
import { GeneralInformationDataModel } from 'src/app/models/formly-builder/formly/general-information-data.model';

@Injectable({
  providedIn: 'root',
})
export class GeneralInformationService extends SectionServiceAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super();
  }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    const hasChanged: boolean = GeneralInformationDataModel.hasChanged(model, riskReportData, sectionEnum);
    if (hasChanged) {
      return { ...riskReportData, ...GeneralInformationDataModel.to(model, sectionEnum) };
    } else {
      return undefined;
    }
  }

  loadData(sectionData: any[], reportInfo: any, isS3: boolean, model: any): any {
    let generalInfoModel: GeneralInformationDataModel;
    if (isS3) {
      generalInfoModel = GeneralInformationDataModel.fromS3(reportInfo);
    } else {
      generalInfoModel = GeneralInformationDataModel.from(reportInfo.data);
    }
    return { ...model, ...generalInfoModel };
  }

  valueChangeSubscription(field: FormlyFieldConfig, sectionName: SectionEnum, sectionEnum: SectionEnum, debounceTimeValue: number) {
    return field.formControl.valueChanges.pipe(
      debounceTime(debounceTimeValue),
      distinctUntilChanged(),
      tap(value => {
        if (!field.formControl.pristine) {
          if (field.formControl.valid) {
            field.options.formState.service.riskReport.updateSectionData(sectionName, false, sectionEnum);
          }
          // Notify possible validity changes even if value not changed in RR/Model
          field.options.formState.service.valid = field.formControl.valid;
        }
      }),
    );
  }
}
