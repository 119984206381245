import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class TwoInchDrainTestDataModel {
    static sectionName = 'systemTesting';

    twoInchDrainTestId: string;
    reportId: string;
    systemNumber: string;
    testDate: Date;
    testConductedBy: string;
    staticPsi?: number;
    staticPsiComment?: string;
    residualPsi?: number;
    residualPsiComment?: string;

    static fromS3(twoInchDrainTestDta: any): TwoInchDrainTestDataModel {

        return {
            twoInchDrainTestId: twoInchDrainTestDta.TwoInchDrainTestId,
            reportId: twoInchDrainTestDta.ReportId,
            systemNumber: twoInchDrainTestDta.SystemNumber,
            testDate: twoInchDrainTestDta.TestDate,
            testConductedBy: twoInchDrainTestDta.TestConductedBy,
            staticPsi: twoInchDrainTestDta.StaticPsi,
            staticPsiComment: twoInchDrainTestDta.StaticPsiComment,
            residualPsi: twoInchDrainTestDta.ResidualPsi,
            residualPsiComment: twoInchDrainTestDta.ResidualPsiComment
        };
    }

    static to(model: any, sectionEnum?: SectionEnum) {
        return {
            TwoInchDrainTestId: model.twoInchDrainTestId,
            ReportId: model.reportId,
            SystemNumber: model.systemNumber ? model.systemNumber : '',
            TestDate: model.testDate,
            TestConductedBy: model.testConductedBy ? model.testConductedBy : '',
            StaticPsi: model?.staticPsi ? Number(model.staticPsi) : model.staticPsi,
            StaticPsiComment: model.staticPsiComment,
            ResidualPsi: model?.residualPsi ? Number(model.residualPsi) : model.residualPsi,
            ResidualPsiComment: model.residualPsiComment
        };
    }

    /**
     * Check if the model has changed
     *
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     *
     */
    static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
        return false;

    }
}
