/* eslint-disable @typescript-eslint/naming-convention */
import { RiskReportDataOccupants } from 'src/app/models/risk-report/risk-report-data.model';
import { OccupantLevelDataModel } from './occupant-level-data.model';

/**
 * Class that represents the Formly Model for Occupants
 */
export class OccupantsDataModel {
  static sectionName = 'occupants';

  doingBusinessAs: string;
  occupantScheduleNo: string;
  occupantType?: string;
  suiteNumber: string;
  isPreexisting?: boolean = false;   // Set to true if occupant exists in S3
  isDeleted?: boolean;              // Soft delete for preexisiting
  basicGroupII: string;
  // New data to be collected from existing RiskReport
  totalArea: number;
  commercialStatisticalPlan?: string;


  // TODO: check case
  reportIdentifier?: string;    // It's read from the S3 and kept
  occupantIdentifier?: string;   // If provided, reuse. If new generate uuid
  occupantNumber?: number = 0;   // 0/null (if string) for just created occupants

  occupantLevels: any[];   // OccupantsLevel[];
  isNewOccupant: boolean = false;
  combustibilityClass?: number;
  isCombustibilityClassOverride?: boolean;
  isBaseGroupIioverride?: boolean;
  isCommercialStatisticalPlanOverride?: boolean;
  susceptibilityClass?: number;
  isSusceptibilityClassOverride?: boolean;
  sortOrder?: any;
  occupantComment?: any;
  scopesVersion?: number;
  occupantHazards?: any;

  /**
   * @deprecated
   * @param alias 
   * @returns 
   */
  static from(alias: RiskReportDataOccupants): OccupantsDataModel {
    return {
      doingBusinessAs: alias.Name,
      occupantScheduleNo: alias.ScheduleNo,
      suiteNumber: alias.SuiteNo,
      totalArea: 0,
      occupantLevels: [],
      basicGroupII: alias.BG2Symbol.trim(),
      isNewOccupant: false,
     /* isDeleted: (alias.IsDeleted != undefined) ? alias.IsDeleted : false,
      OccupantIdentifier:alias.OccupantIdentifier ,
      ReportIdentifier: alias.ReportIdentifier,
      OccupantNumber: alias.OccupantNumber,
      IsNewOccupant: alias.IsNewOccupant,
      CombustibilityClass: alias.CombustibilityClass,
      CommercialStatisticalPlan:alias.CommercialStatisticalPlan,
      IsCombustibilityClassOverride:alias.IsCombustibilityClassOverride,
      IsBaseGroupIioverride: alias.IsBaseGroupIioverride,
      IsCommercialStatisticalPlanOverride: alias.IsCommercialStatisticalPlanOverride,
      SusceptibilityClass: alias.SusceptibilityClass,
      IsSusceptibilityClassOverride: alias.IsSusceptibilityClassOverride,
      SortOrder: alias.SortOrder,
      OccupantComment: alias.OccupantComment,
      ScopesVersion: alias.ScopesVersion,
      OccupantHazards: alias.OccupantHazards,*/
    };
  }

  static fromS3(alias: any): OccupantsDataModel {
    let levelArray: any[] = [];
    let totalArea = 0;
    if (alias.OccupantLevels && alias.OccupantLevels.length) {
      alias.OccupantLevels.forEach((level) => {
        const levelData = OccupantLevelDataModel.fromS3(level);
        levelArray.push(levelData);
        // Calculations
        totalArea += (levelData.area);
        // if (level.OccupantLevelType === `FLOO`) {
        //   // aboveGradeFloors++;
        // } else if (level.OccupantLevelType === `BASE`) {
        //   // basementLevels++;
        // }
      });
    }
    return {
      doingBusinessAs: alias.OccupantName ? alias.OccupantName.trim() : '',
      occupantScheduleNo: alias.ScheduleNumber,
      occupantType: alias.OccupantType ? alias.OccupantType : null,
      suiteNumber: alias.SuiteNumber,
      totalArea: totalArea,
      occupantLevels: levelArray,
      basicGroupII:  alias.BasicGroup2Symbol ? alias.BasicGroup2Symbol.trim() : undefined,
      isDeleted: (alias.IsDeleted != undefined) ? alias.IsDeleted : false,
      commercialStatisticalPlan: alias.CommercialStatisticalPlan,

      //we dont use below data but to match db, we are adding it
      occupantIdentifier:alias.OccupantIdentifier ? alias.OccupantIdentifier : undefined,
      reportIdentifier: alias.ReportIdentifier ? alias.ReportIdentifier : undefined,
      occupantNumber: alias.OccupantNumber ? alias.OccupantNumber: undefined,
      isNewOccupant: alias.IsNewOccupant ? alias.IsNewOccupant : false,
      combustibilityClass: alias.CombustibilityClass,
      isCombustibilityClassOverride:alias.IsCombustibilityClassOverride,
      isBaseGroupIioverride: alias.IsBaseGroupIioverride,
      isCommercialStatisticalPlanOverride: alias.IsCommercialStatisticalPlanOverride,
      susceptibilityClass: alias.SusceptibilityClass,
      isSusceptibilityClassOverride: alias.IsSusceptibilityClassOverride,
      sortOrder: alias.SortOrder,
      occupantComment: alias.OccupantComment,
      scopesVersion: alias.ScopesVersion,
      occupantHazards: alias.OccupantHazards
    };
  }

  /**
   * Returns a S3 RiskReport object from a Formly model
   */
    static to(model: any) {
      const floorsRr = [];
      model.occupantLevels?.forEach((floor) => {
        floorsRr.push(OccupantLevelDataModel.to(floor));
      });
      return {
        OccupantIdentifier: model.occupantIdentifier ? model.occupantIdentifier : undefined,
        ReportIdentifier: model.reportIdentifier ? model.reportIdentifier : undefined,
        OccupantNumber: model.occupantNumber ? model.occupantNumber :null,
        OccupantName: model.doingBusinessAs ? model.doingBusinessAs :undefined,
        OccupantType: model.occupantType ? model.occupantType : null,
        CommercialStatisticalPlan: model.commercialStatisticalPlan ? model.commercialStatisticalPlan : '',
        SuiteNumber: model.suiteNumber,
        ScheduleNumber: model.occupantScheduleNo,
        CombustibilityClass: model.combustibilityClass,
        BasicGroup2Symbol: model.basicGroupII,
        IsCombustibilityClassOverride:model.isCombustibilityClassOverride,
        IsBaseGroupIioverride: model.isBaseGroupIioverride,
        IsCommercialStatisticalPlanOverride: model.isCommercialStatisticalPlanOverride,
        SusceptibilityClass: model.susceptibilityClass,
        IsSusceptibilityClassOverride: model.isSusceptibilityClassOverride ? model.isSusceptibilityClassOverride : false,
        SortOrder: model.sortOrder,
        OccupantComment: model.occupantComment,
        ScopesVersion: model.scopesVersion ?  model.scopesVersion : 1,
        IsDeleted: model.isDeleted,
        IsNewOccupant: model.isNewOccupant,
        OccupantHazards: model.occupantHazards,
        OccupantLevels: floorsRr,
      };
    }

    static filterModel(formlyModel: any): OccupantsDataModel {
      return {
        doingBusinessAs: formlyModel.doingBusinessAs,
        occupantScheduleNo: formlyModel.occupantScheduleNo,
        occupantType: formlyModel.occupantType,
        suiteNumber: formlyModel.suiteNumber,
        totalArea: formlyModel.totalArea,
        occupantLevels: formlyModel.occupantLevels,
        basicGroupII:  formlyModel.basicGroupII,
        isDeleted: formlyModel.isDeleted ,
        isNewOccupant: formlyModel.isNewOccupant,
        commercialStatisticalPlan: formlyModel.commercialStatisticalPlan,

        //we dont use below data but to match db, we are adding it
        occupantIdentifier: undefined,
        reportIdentifier: undefined,
        occupantNumber: undefined,
        combustibilityClass: undefined,
        isCombustibilityClassOverride:undefined,
        isBaseGroupIioverride: undefined,
        isCommercialStatisticalPlanOverride: undefined,
        susceptibilityClass: undefined,
        isSusceptibilityClassOverride: undefined,
        sortOrder: undefined,
        occupantComment: undefined,
        scopesVersion: undefined,
        occupantHazards: undefined
      };
    }
}
