/* eslint-disable max-len */
import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { Constants } from 'src/app/services/util-service/constants';

export class OccupantsSectionModel extends FormlySectionModelAbstract {
  static maxCharacterLength = 1000;
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'section-occupants',
      // type: 'section-occupants',   // No type! Just a simple Formly group using section wrapper
      wrappers: [ 'section-wrapper' ],
      props: {
        label: 'Occupants',
        addType: 'Occupants',
        addText: 'Add',
        actionButtons: [ { action: 'add', label: 'Add', isDisabled: false } ],
        isCollapsed: false,
        isDisabled: false,
        isEmptySectionOnInit: false
      },
      expressions: {
        'props.isCollapseIconDisabled': (field: FormlyFieldConfig) =>
          field.options.formState.service.occupantsService
            .setPropsValue(field.form.value[ 'section-occupants' ], field, formlyBuilderService),
        'props.isCollapsed': (field: FormlyFieldConfig) =>
          field.form.value[ 'section-occupants' ]?.occupants?.length >= 1 ? false : true
      },
      hooks: {
        onInit: (field) => {
          const occupants = field.form.value[ 'section-occupants' ]?.occupants;
          if (occupants?.length) {
            const occupantsList = occupants.filter((occ) => !occ.isDeleted);
            if (!occupantsList?.length) {
              field.props.isEmptySectionOnInit = true;
            }
          } else {
            field.props.isEmptySectionOnInit = true;
          }
        }
      },
      // expressions: {
      // 'props.disabled': 'formState.disabled',  // Disables children as well!!
      // 'props.isDisabled': 'formState.disabled',
      // 'props.actionButtons[0].isDisabled': 'formState.disabled'    // Not showing???
      // },
      validators: {
        emptyOccupants: {
          expression: (control: FormControl, field: FormlyFieldConfig) => {
            const occupants = field.form.value[ 'section-occupants' ]?.occupants?.filter((occ) => !occ.isDeleted);
            if (occupants?.length === 0) {
              return false;
            }
            return true;
          },
          message: 'You have to add at least one Occupant.',
        },
        validation: [
          { name: 'schedulenumber-bm-validator' },
          { name: 'schedulenumber-vacant-validator' },
          { name: 'schedulenumber-validator' },
          { name: 'unique-occurance-validator', options: { field: 'occupant', propertyName: 'occupantScheduleNo', startWith: '355' } },
          { name: 'csp-nonmercantile-validator' },
          { name: 'csp-mercantile-validator' },
          // {
          //   name: 'filtering-hazard-sno-validator', options: {
          //     isInclude: false, scheduleNo: [ '5212100000', '5211420000' ],excludeHazardSNo:[] ,comparedLength:0,
          //     hazardScheduleNo: [ '7341400000', '7341400001', '7341400002', '7341400003', '7341410000', '7341410001', '7341410002', '7341410003', '7341420000', '7341420001', '7341420002',
          //       '7341420003', '7341430000', '7341430001', '7341430002', '7341430003', '7402100000', '7402110000', '7402120000', '7402130000' ],
          //     errorMsg: 'Regular cooking H of O cannot be added to limited cooking restaurant.'
          //   }
          // },
          // {
          //   name: 'filtering-hazard-sno-validator', options: {
          //     isInclude: true, scheduleNo: [ '5212000000' ],excludeHazardSNo:[],comparedLength:0,
          //     hazardScheduleNo: [ '7341400000', '7341400001', '7341400002', '7341400003', '7341410000', '7341410001', '7341410002', '7341410003', '7341420000', '7341420001',
          //       '7341420002', '7341420003', '7341430000', '7341430001', '7341430002', '7341430003' ],
          //     errorMsg: 'Regular cooking restaurant requires regular cooking H of O.'
          //   }
          // },
          // {
          // //   // 2
          //   name: 'filtering-hazard-sno-startwith-validator', options: {
          //     isInclude: false, scheduleNoStartWith: ['608'],excludedString:[],excludeHazardSNo:[],comparedLength:0,
          //     hazardScheduleNo: [ '7322210000','7322210010','7322220000','7322220010' ],
          //     errorMsg: 'The hazard for coating with plastics does not apply to Plastic Product Manufacturing (608) occupancies.'
          //   }
          // },
          //  {
          //   // 1
          //     name: 'filtering-hazard-sno-startwith-validator', options: {
          //       isInclude: true, scheduleNoStartWith: ['6021101000', '6021102000'],excludedString:[],comparedLength:0,
          //       hazardScheduleNo: [ '736312' ],excludeHazardSNo:[],
          //       errorMsg: 'Bakeries must have a 736312 baking charge applied.'
          //     }
          //   },
          // {
          //   // 5
          //     name: 'filtering-hazard-sno-startwith-validator', options: {
          //       isInclude: false, scheduleNoStartWith: [ '601','608','60614'],excludedString:[],comparedLength:0,
          //       hazardScheduleNo: [ '730' ], excludeHazardSNo:['7309000000','7305000000' ],
          //       errorMsg: 'Dust Collection hazards do not apply to Shoe Repair Shops or Plastic Products MFG.'
          //     }
          //   },
        ],
      },
      fieldGroup: [
        {
          type: 'summary-row',
          fieldGroup: [
            {
              key: 'aboveGradeFloors',
              props: {
                label: 'Above Grade Floors',
              },
              validators: {
                validation: [ { name: 'comparison-validator', options: { compareWith: 'floorsAndRoofs' } } ],
              },
            },
            {
              key: 'basementLevels',
              props: {
                label: 'Basement Levels',
              },
              validators: {
                validation: [ { name: 'comparison-validator', options: { compareWith: 'floorsAndRoofs' } } ],
              },
            },
            {
              //hide it
              key: 'totalArea',
              props: {
                label: 'Total Area',
                unit: 'sq.ft.',
                isValidatorNeeded: formlyBuilderService.mode !== 1 ? true : false
              },
              expressions: {
                hide: () => formlyBuilderService.mode === 1
              },
              validators: {
                validation: [ { name: 'comparison-validator', options: { compareWith: 'floorsAndRoofs' } } ]
              },
            }
          ],
          expressions: {
            hide: (field: FormlyFieldConfig) => field.parent.props.isCollapseIconDisabled,
          }
        },
        {
          key: 'occupantLevelValidator',
          /*expressions: {
            hide: () => formlyBuilderService.mode !== 1,
          },*/
        },
        {
          key: 'crossSectionValidator',
          /*expressions: {
            hide: () => formlyBuilderService.mode !== 1,
          },*/
        },
        {
          key: 'occupants',
          type: 'array-occupants',
          props: {
            label: 'Occupants',
            addType: 'Occupants',
            addText: Constants.btnText,
          },
          fieldArray: {
            props: {
              isContainLevels: true,
              levelColName: 'occupantLevels'
            },
            wrappers: [ 'error-info-wrapper' ],
            fieldGroup: [
              {
                type: 'toggle',
                key: 'isNewOccupant',
                defaultValue: true,
                wrappers: [ 'question-wrapper' ],
                props: {
                  label: 'Is this Occupant New or Same',
                  toggleSameNew: true,
                  isDisabled: true,
                  disabled: false,
                  required: false,
                },
                expressions: {
                  hide: () => formlyBuilderService.mode === 1
                },
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    if (formlyBuilderService.mode !== 1) {
                      field.options.formState.service.occupantsService.checkEditOccupantAsNew(field);
                    }
                    if (formlyBuilderService.mode === 1) {
                      field.formControl.setValue(true);
                    }
                  },
                },
              },
              {
                type: 'simple-input',
                key: 'doingBusinessAs',
                props: {
                  label: 'Doing Business As',
                  inputsize: 15,
                  required: true,
                },
                className: 'occupant-doingBuisinessAs',
                hooks: {
                  onInit: (field) => {
                    field.options.formState.service.occupantsService.fixFormlyBug(field, 'doingBusinessAs');
                  }
                },
                expressions: {
                  // 'props.required': !model.isDeleted
                  'props.maxLength': (model: FormlyFieldConfig) => {
                    if (model) {
                      const maxCharacters = model.form.value.isDeleted ? OccupantsSectionModel.maxCharacterLength : 35;
                      return maxCharacters;
                    } else {
                      return OccupantsSectionModel.maxCharacterLength;
                    }
                  }
                }
              },
              {
                type: 'simple-input',
                key: 'suiteNumber',
                props: {
                  label: 'Suite',
                  inputsize: 8,
                  // type: 'number',
                  // inputmode: 'tel',
                },
                className: 'occupant-suiteNo',
                expressions: {
                  // 'props.required': !model.isDeleted
                  'props.maxLength': (model: FormlyFieldConfig) => {
                    if (formlyBuilderService.mode === 1) {
                      return 5;
                    }
                  }
                },
                validators: {
                  suiteNumberLengthCheck: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      const suiteNo = control.value;
                      if (suiteNo && suiteNo.length > 5) {
                        return false;
                      }
                      else {
                        return true;
                      }
                    },
                    message: 'Invalid Suite Number',
                  },
                },
                hooks: {
                  onInit: (field) => {
                    field.options.formState.service.occupantsService.fixFormlyBug(field, 'suiteNumber');
                  },
                },
              },
              {
                type: 'search-bar',
                key: 'occupantScheduleNo',
                props: {
                  label: 'Occupant Schedule #',
                  maxlength: 10,
                  minlength: 10,
                  type: 'number',
                  inputmode: 'tel',
                  jsonPath: 'schedule-codes'
                },
                className: 'rightFloat-field occupant-ScheduleNo',
                validators: {
                  scheduleNumberLengthCheck: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      const schedNo = control.value;
                      const occupantType = field.form.get('occupantType');
                      if (field?.model?.isDeleted) {
                        return true;
                      }
                      else {
                        if (schedNo && schedNo.length === 10) {
                          try {
                            // Return false if no occupant type
                            const scheduleService = this.formlyBuilderService.scheduleService;
                            // const type = scheduleService.getScheduleType(schedNo);
                            const desc = scheduleService.getScheduledetails(schedNo);
                            const type = desc?.OccupantType;
                            if (!type || type === '') {
                              occupantType.setValue('');
                              return false;
                            } else {
                              occupantType.setValue(type);
                            }
                            // console.debug("validScheduleNo "+schedNo);
                          } catch (error) {
                            const occupantName = field.form.get('doingBusinessAs');
                            console.error('validScheduleNo ' + occupantName.value + ' #' + schedNo + ' ' + error);
                            return false;
                          }
                          return true;
                        } else {
                          occupantType.setValue('');
                          return false;
                        }
                      }
                    },
                    message: 'Schedule Number must be valid',
                  },
                },
                hooks: {
                  onInit: (field) => {
                    field.options.formState.service.occupantsService.fixFormlyBug(field, 'occupantScheduleNo');
                  },
                },
              },
              {
                type: 'simple-input',
                key: 'occupantType',
                props: {
                  label: 'Occupant Type',
                  inputsize: 35,
                  readonly: true,
                },
                className: 'newLine-field occupant-type',
              },
              {
                key: 'commercialStatisticalPlan',
              },
              {
                key: 'basicGroupII',
                type: 'simple-select',
                props: {
                  label: 'Basic Group II',
                  // required: true,
                  type: 'string',
                  inputsize: 5,
                  isItNY: false,
                  options: [
                    { value: 'T', label: 'T' },
                    { value: 'Z', label: 'Z' },
                    { value: 'K', label: 'K' },
                    { value: 'I', label: 'I' },
                    { value: 'U', label: 'U' },
                    { value: 'V', label: 'V' },
                    { value: 'W', label: 'W' },
                    { value: 'P', label: 'P' },
                    { value: 'B', label: 'B' },
                    { value: 'R', label: 'R' },
                    { value: 'H', label: 'H' },
                    { value: 'N', label: 'N' },
                    { value: 'J', label: 'J' },
                    { value: 'A', label: 'A' },
                    { value: 'L', label: 'L' },
                    { value: 'X', label: 'X' },
                    { value: 'D', label: 'D' },
                    { value: 'C', label: 'C' },
                    { value: 'CD', label: 'CD' },
                    { value: 'AB', label: 'AB' },
                  ],
                },
                className: 'rightFloat-field',
                hooks: {
                  onInit: (field) => {
                    if (formlyBuilderService.mode !== 1 && field.props.isItNY) {
                      field.options.formState.service.occupantsService.fixFormlyBug(field, 'basicGroupII');
                      field.options.formState.service.occupantsService.checkBGIIValue(field);
                    }
                  },
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => {
                    // const address = field.form.parent.parent.parent.value.address;
                    const address = field.form.root.value.address;
                    if (address && address.mainAddress && formlyBuilderService.mode !== 1) {
                      const state = address.mainAddress.state;
                      if (state === 'NY' || state === 'NX') {
                        field.props.isItNY = true;
                        return false;
                      }
                    }
                    return true;
                  },
                  'props.required': (model: FormlyFieldConfig) => {
                    if (model && formlyBuilderService.mode !== 1) {
                      const isReq = model.form.value.isDeleted ? false : true;
                      return isReq;
                    } else {
                      return false;
                    }
                  }
                }
              },
              {
                key: 'isDeleted',
                defaultValue: false,
              },
              { key: 'occupantIdentifier' },
              {
                key: 'occupantLevels',
                type: 'occupant-levels',
                props: {
                  addText: 'Add Floor',
                },
                className: 'newLine-field full-width',
                fieldArray: {
                  fieldGroup: [
                    {
                      key: 'name',
                      type: formlyBuilderService.mode === 0 ? 'simple-select' : null,
                      props: {
                        label: 'Level Type',
                        options: [],
                        // required: true,
                        isNonBlockingValidation: true,
                      },
                      // hideExpression: formlyBuilderService.mode === 1,
                      hooks: {
                        onInit: (field) => {
                          if (formlyBuilderService.mode !== 1) {
                            const formlyService = field.options.formState.service;
                            formlyService.occupantsService.initLevelTypes(field);
                            formlyService.occupantsService.initFieldSubscription(field, 'name');
                            // TODO: Check if better as option
                            formlyService.occupantsService.levelTypeChanged(field);
                          }
                        },
                      },
                      expressions: {
                        // hide: () => formlyBuilderService.mode === 1,
                        'props.required': (field: FormlyFieldConfig) => {
                          // eslint-disable-next-line @typescript-eslint/dot-notation
                          if (!field?.form?.parent.parent.value[ 'isDeleted' ] && formlyBuilderService.mode !== 1) {
                            return true;
                          }
                          return false;
                        }
                      },
                    },
                    {
                      type: 'rf-aerial',     // TODO: Design messed; commented out
                      expressions: {
                        hide: () => formlyBuilderService.mode === 1,
                      },
                    },
                    {
                      key: 'length',
                      type: 'simple-input',
                      defaultValue: null,
                      props: {
                        label: 'Length',
                        type: 'number',
                        inputmode: 'tel',
                        min: 1,
                        maxLength: 8,
                        // required: true,
                        isNonBlockingValidation: true,
                      },
                      hooks: {
                        onInit: (field) => {
                          if (formlyBuilderService.mode !== 1) {
                            const formlyService = field.options.formState.service;
                            formlyService.occupantsService.initFieldSubscription(field, 'length');
                          }
                        },
                      },
                      expressions: {
                        hide: () => formlyBuilderService.mode === 1,
                        'props.required': (field: FormlyFieldConfig) => {
                          // eslint-disable-next-line @typescript-eslint/dot-notation
                          if (!field?.form?.parent.parent.value[ 'isDeleted' ] && formlyBuilderService.mode !== 1) {
                            return true;
                          }
                          return false;
                        }
                      },
                    },
                    {
                      key: 'width',
                      type: 'simple-input',
                      defaultValue: null,
                      props: {
                        label: 'Width',
                        type: 'number',
                        inputmode: 'tel',
                        min: 1,
                        maxLength: 8,
                        // required: true,
                        isNonBlockingValidation: true,
                      },
                      hooks: {
                        onInit: (field) => {
                          if (formlyBuilderService.mode !== 1) {
                            const formlyService = field.options.formState.service;
                            formlyService.occupantsService.initFieldSubscription(field, 'width');
                          }
                        },
                      },
                      expressions: {
                        hide: () => formlyBuilderService.mode === 1,
                        'props.required': (field: FormlyFieldConfig) => {
                          // eslint-disable-next-line @typescript-eslint/dot-notation
                          if (!field?.form?.parent.parent.value[ 'isDeleted' ] && formlyBuilderService.mode !== 1) {
                            return true;
                          }
                          return false;
                        }
                      },
                    },
                    {
                      key: 'areaPerLevel', // Area per Occupant per Level
                      type: 'text',
                      className: 'width',
                      props: {
                        prefix: '=',
                        unit: ' sq.ft.',
                      },
                      // template: `<strong>formControl.value sq. ft.</strong>`,
                      expressions: {
                        hide: () => formlyBuilderService.mode === 1
                      },
                      hooks: {
                        onInit: (field) => {
                          if (formlyBuilderService.mode !== 1) {
                            field.options.formState.service.occupantsService.calculateAreaPerLevel(field);
                          }
                        },
                      },
                    },
                    {
                      key: 'levelStart',
                      type: formlyBuilderService.mode === 0 ? 'simple-select' : null,
                      props: {
                        label: 'Level Start',
                        maxLevels: 0,
                        disabled: false,
                        isDisabled: false,
                        options: [],
                        optionsCopy: [],
                        // required: true,
                        isNonBlockingValidation: true,
                      },
                      hooks: {
                        onInit: (field) => {
                          if (formlyBuilderService.mode !== 1) {
                            // TODO: Move to occupantsService
                            const formlyService = field.options.formState.service;
                            formlyService.occupantsService.initFieldSubscription(field, 'levelStart');
                            formlyService.occupantsService.loadLevelStartInitialOptions(field);
                          }
                        },
                      },
                      expressions: {
                        // hide: () => formlyBuilderService.mode === 1,
                        'props.isDisabled': (field?: FormlyFieldConfig) =>
                          !field.form?.value.name,
                        // Not required if occupant is deleted
                        'props.required': (field: FormlyFieldConfig) => {
                          // eslint-disable-next-line @typescript-eslint/dot-notation
                          if (!field?.form?.parent.parent.value[ 'isDeleted' ] && formlyBuilderService.mode !== 1) {
                            return true;
                          }
                          return false;
                        }
                      },
                    },
                    {
                      key: 'levelStop',
                      type: 'simple-select',
                      props: {
                        label: 'Level Stop',
                        maxLevels: 0,
                        disabled: false,
                        isDisabled: false,
                        options: [], // TODO: section service method that return Observable / changes with xxxx.next()
                        optionsCopy: [],
                      },
                      hooks: {
                        onInit: (field) => {
                          if (formlyBuilderService.mode !== 1) {
                            const formlyService = field.options.formState.service;
                            formlyService.occupantsService.initFieldSubscription(field, 'levelStop');
                            formlyService.occupantsService.resetLevelsOnChange(field);
                          }
                          else if (formlyBuilderService.mode !== 1) {
                            field.formControl.setValue(0);
                          }
                        },
                      },
                      expressions: {
                        hide: () => formlyBuilderService.mode === 1,
                        'props.isDisabled': (field?: FormlyFieldConfig) => !(field.form?.value.name && field.form?.value.levelStart)
                      }
                    },
                    {
                      key: 'floorNo',
                      type: 'simple-select',
                      props: {
                        label: 'Floors',
                        options: [],
                        required: true,
                        isNonBlockingValidation: true,
                        isAsteriskNotRequired: true,
                        popOverOptionsClassName: 'xxxl-dropdown',
                      },
                      className: 'occupant-FloorNo',
                      expressions: {
                        hide: () => formlyBuilderService.mode === 0,
                      },
                      hooks: {
                        onInit: (field) => {
                          if (formlyBuilderService.mode === 1) {
                            const formlyService = field.options.formState.service;
                            formlyService.occupantsService.initFieldSubscription(field, 'floorNo');
                            const maxFloors = formlyBuilderService.occupantsService.getMaxValue('Floor', field);
                            const maxBase = formlyBuilderService.occupantsService.getMaxValue('Basement', field);
                            const minBase = formlyBuilderService.occupantsService.getMinValue('Basement', field);
                            /* const maxAttics = formlyBuilderService.occupantsService.getMaxValue('Attic', field);
                             const minAttics = formlyBuilderService.occupantsService.getMinValue('Attic', field);
                             const maxMezz = formlyBuilderService.occupantsService.getMaxValue('Mezzanine', field);
                             const minMezz = formlyBuilderService.occupantsService.getMinValue('Mezzanine', field);*/
                            const mezzArray = formlyBuilderService.occupantsService.getMezzAtticArray('Mezzanine', field);
                            const atticArray = formlyBuilderService.occupantsService.getMezzAtticArray('Attic', field);
                            field.props.options = formlyBuilderService.occupantsService.getFloorsOptions(1, (maxFloors ? maxFloors : 1), minBase, maxBase, mezzArray, atticArray);
                            field.formControl.valueChanges.subscribe((val) => {
                              if (val && val !== null && val !== undefined && field.options.formState.service.isSheetActive) {
                                const value = val.match(/\d+/g);
                                field.form.get('levelStart')?.setValue(value[ 0 ]);
                                const levelType = formlyBuilderService.occupantsService.getLevelType(val);
                                field.form.get('name')?.setValue(levelType);
                                if (formlyBuilderService.mode === 1) {
                                  const occupantsArray = field.parent.parent.parent.parent.model;
                                  const occupantLevels = field.options.formState.service.occupantsService.getOccupantLevels(occupantsArray);
                                  const summarizedArray = field.options.formState.service.occupantsService.calculatePercentageOccupiedForEachFloor(occupantLevels);
                                  if (summarizedArray.length > 0) {
                                    const occArr: any = field.form.parent.parent.parent.controls;
                                    field.options.formState.service.occupantsService.setPercentageOccupiedForEachFloor(occArr, summarizedArray);
                                  }
                                }
                              }
                            });
                          }
                        },
                      },
                    },
                    {
                      key: 'percentageOccupied',
                      type: 'text',
                      defaultValue: 0,
                      props: {
                        suffix: ' Occupied',
                        unit: '%',
                        isCorrect: false,
                        format: 'integer',
                        required: true,
                        isNonBlockingValidation: true,
                      },
                      className: 'occupant-percentageOccupied',
                      expressions: {
                        'props.isCorrect': (model: FormlyFieldConfig) => model.form.value.percentageOccupied === 100 ? true : false,
                      },
                      hooks: {
                        onInit: (field) => {
                          field.formControl.markAsTouched();
                        }
                      },
                      validators: {
                        percentageIncomplete: {
                          expression: (control: FormControl, field: FormlyFieldConfig) => {
                            if (control?.value) {
                              if (control?.value !== 100) {
                                return false;
                              }
                            }
                            return true;
                          },
                          message: 'Occupant percentages per floors must total 100%',
                        }
                      }
                    },
                    {
                      type: 'rf-range',
                      key: 'floorPercentage',
                      defaultValue: 0,
                      props: {
                        label: 'Percentage Occupied',
                        type: 'number',
                        isValid: false,
                        isDisabled: false,
                        required: true,
                        isNonBlockingValidation: true,
                        isAsteriskNotRequired: true,
                      },
                      className: 'newLine-field full-width occupant-floor-percentage',
                      expressions: {
                        hide: () => formlyBuilderService.mode === 0,
                        'props.isValid': (model: FormlyFieldConfig) => model.form.value.percentageOccupied === 100 ? true : false
                      },
                      validators: {
                        occupantPercentageRequired: {
                          expression: (control: FormControl, field: FormlyFieldConfig) => {
                            const percentage = control.value;
                            if (percentage > 0) {
                              return true;
                            }
                            else if (percentage === 0) {
                              return false;
                            }
                          },
                          message: 'Occupant percentage is required',
                        }
                      },
                      hooks: {
                        onInit: (field) => {
                          if (this.formlyBuilderService.mode === 0) {
                            field.formControl.setValue(undefined);
                          }
                          else {
                            field.props.index = field.parent.parent.parent.key + '' + field.parent.key;
                            if (formlyBuilderService.mode === 1) {
                              field.formControl.valueChanges.subscribe(value => {
                                if (value !== null && value !== undefined && field.options.formState.service.isSheetActive) {
                                  const occupantsArray = field.parent.parent.parent.parent.model;
                                  const occupantLevels = field.options.formState.service.occupantsService.getOccupantLevels(occupantsArray);
                                  const summarizedArray = field.options.formState.service.occupantsService.calculatePercentageOccupiedForEachFloor(occupantLevels);
                                  if (summarizedArray.length > 0) {
                                    const occArr: any = field.form.parent.parent.parent.controls;
                                    field.options.formState.service.occupantsService.setPercentageOccupiedForEachFloor(occArr, summarizedArray);
                                  }
                                }
                              });
                            }
                          }
                        }
                      }
                    },
                    /*                     {
                      key: 'automaticSprinkler',
                      type: 'simple-select',
                      props: {
                        label: 'Automatic Sprinkler',
                        // disabled: true,
                        options: [
                          { value: 'HYDR', label: 'Hydraulic' },
                          { value: 'PIPE', label: 'PIPE' },
                          { value: 'NONE', label: 'NONE' },
                        ],
                        // required: true,
                        isNonBlockingValidation: true
                      },
                      hooks: {
                        onInit: (field) => {
                          const formlyService = field.options.formState.service;
                          const ret2 = formlyService.occupantsService.initFieldSubscription(field, 'automaticSprinkler');
                        }
                      },
                      expressions: {
                      hide:()=> formlyBuilderService.mode === 0 || formlyBuilderService.mode === 1,
                        // TODO: Check if this is working...
                        // 'props.disabled': (model: any, formState: any, field: FormlyFieldConfig) => field?.form?.parent.parent.parent.parent.parent['evidenceOfFireSprinkler'],
                        'props.disabled': (model: any, formState: any, field: FormlyFieldConfig) => {
                          const value = field?.form?.root.value['evidenceOfFireSprinkler'];
                          return value;
                        },
                        // Not required if occupant is deleted
                        'props.required': (model: any, formState: any, field: FormlyFieldConfig) => field?.form?.parent.parent.value['isDeleted'] ? false : true
                      }
                    }
                    */
                  ]
                }
              }
            ],
            validators: {
              validation: [ { name: 'occupant-level-validator', options: { compareWith: 'floorsAndRoofs' } } ],
            },
          }
        }
      ],
    });
  }
}
