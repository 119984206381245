import { Injectable } from "@angular/core";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { SafariViewController, SafariViewControllerOriginal } from '@awesome-cordova-plugins/safari-view-controller'
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class OneDriveAuthService{
    constructor(private iab: InAppBrowser, private platform: Platform) { }

    svc: SafariViewControllerOriginal;
   
    async SafariViewController(){        
        const ADAuth = {
            "authority" : "https://login.microsoftonline.com/",
            "tenantId" : "3b07dc1f-22e7-4be1-ac66-a88bf3550222",
            "clientId" : "40bb1f8a-7eb3-4017-aed8-e9163ef6af37",
            "redirectUri" : `${environment.scheme}://localhost`,
            "scope" : "User.Read.All Files.Read.All"
        }

        console.log("Starting Safari View Controller");
        const authUrl = `${ADAuth.authority + ADAuth.tenantId}/oauth2/v2.0/authorize?`
            + `client_id=${ADAuth.clientId}&`
            + `response_type=token&`
            + `redirect_uri=${ADAuth.redirectUri}&`
            + `scope=${ADAuth.scope}`;
        console.log("Starting Safari View Auth", authUrl);

        if (SafariViewController.isAvailable && (this.platform.is('capacitor'))) {
            SafariViewController.show({
                url: authUrl,
                hidden: false, // default false. You can use this to load cookies etc in the background (see issue #1 for details).
                animated: false, // default true, note that 'hide' will reuse this preference (the 'Done' button will always animate though)
                transition: 'curl', // (this only works in iOS 9.1/9.2 and lower) unless animated is false you can choose from: curl, flip, fade, slide (default)
                tintColor: "#00ffff", // default is ios blue
                barColor: "#0000ff", // on iOS 10+ you can change the background color as well
                controlTintColor: "#ffffff" // on iOS 10+ you can override the default tintColor
                }).subscribe((e: any) => {
                    console.log("SVC url opened", e);
                });
                
        } else {
            // potentially powered by InAppBrowser because that (currently) clobbers window.open
            window.open(authUrl, '_blank', 'location=yes;width=200;height=200');
        }
    }

    closeSVC(){
        SafariViewController.hide();
    }
}