// To disable a button for a set period of time (default is 1 second)
// To change the default pass in a debounceTime in milliseconds
// <button disableOrDebounce [debounceTime]="3000">Disable for DeBounce</button>

// To disable a button using a boolean (ex. disable while you are saving data and re-enable when request is finished) 
// Set useDebounce property to false, set isDisabled property to your local boolean that you control in your async function
// <button disableOrDebounce [useDebounce]="false" [isDisabled]="isDisabled">Disable While Processing</button>

import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    SimpleChanges,
  } from '@angular/core';
  
  @Directive({
    selector: '[disableOrDebounce]'
  })
  export class DisableOrDebounceDirective implements OnChanges {
    /**
    * @type {boolean} true to disable for interval, false for variable control. default: true
    */
    @Input() useDebounce: boolean = true;
    /**
    * @type {number} time, in ms to disable when using useDebounce=true. default 1000
    */
    @Input() debounceTime: number = 1000;
    /**
    * @type {boolean} pass in the local variable you are using to toggle the disabled state. must be combined with useDebounce=false 
    */
    @Input() isDisabled: boolean = false;
  
    @HostListener('click') onClick() {
      if (this.useDebounce) {
        this.debounceElement();
      }
    }
  
    constructor(private _el: ElementRef) {}
  
    ngOnChanges(changes: SimpleChanges) {
      if(changes && changes.isDisabled){
        this._el.nativeElement.disabled = changes.isDisabled.currentValue;
      }
    }
  
    setDisable(elements) {
      this._el.nativeElement.disabled = this.isDisabled;
    }
  
    debounceElement() {
      this._el.nativeElement.disabled = true;
      setTimeout(
        () => (this._el.nativeElement.disabled = false),
        this.debounceTime
      );
    }
  }
  