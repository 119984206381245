/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/prefer-for-of */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';

// Loaded at App start and added to App providers, so no need to repeat here
// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class ScheduleCodeService {
  private scheduleDetails = [];
  private scheduleCspDetails = [];
  private buildingHazardsDetails =[];
  constructor(
    private http: HttpClient
  ) {
    // console.debug('ScheduleCodeService Init');
    this.getSchedule('schedule-flat-group').subscribe((val) => {
      this.scheduleDetails = val;
    });
    this.getSchedule('schedule-flat-codes').subscribe((val) => {
      this.scheduleCspDetails = val;
    });
    //To get hazard code long description
    this.getSchedule('hazard-flat-codes').subscribe((val)=>{
      this.buildingHazardsDetails =val;
    });
  }

  public getScheduleLookupData(): Observable<any> {
    const url = 'assets/json/schedule-lookup-data.json';
    return this.http.get(url) as Observable<any>;
  }

  /**
   * @param schedNo
   * @returns occupantDescription, commercialStatisticalPlan
   * @method getHazardScheduleDescription
   */
  getHazardScheduleDescription(schedNo){
    const searchedNumber = Number(schedNo);
    const description = this.buildingHazardsDetails.find((item)=>item.OccupancyScheduleNumber === searchedNumber);
    if(description) {
     return { occupantDescription:description.LongDescription,CSP:description.CommercialStatPlan};
    }else{
      return { occupantDescription:'',CSP:''};
    }
  }

  getScheduleType(occupantScheduleNo){
    try {
      if(occupantScheduleNo?.length !== 10) {
        return '';
      }
     occupantScheduleNo = occupantScheduleNo.slice(0,3);
      const searchedNumber: number = Number(occupantScheduleNo); 
      for(let i = 0; i < this.scheduleDetails.length; i++) {
        if(this.scheduleDetails[i] && this.scheduleDetails[i].OccupancyScheduleNumber &&
           (this.scheduleDetails[i].OccupancyScheduleNumber === searchedNumber)) {
          return this.scheduleDetails[i].ScheduleDescription;
        }
      }
    } catch (error) {
      console.warn("Occupant Schedule Type search "+error);
    }
    return '';
  }

  /**
   * @param occupantScheduleNo
   * @return occupantType, commercialStatisticalPlan
   * @method getScheduledetails
   */
  getScheduledetails(occupantScheduleNo) {
    try {
      if(occupantScheduleNo?.length !== 10) {
        return null;
      }
      const scheduleDescription = this.getScheduleType(occupantScheduleNo);
      const searchedNumber = Number(occupantScheduleNo);
      for (let i = 0; i < this.scheduleCspDetails.length; i++) {
        if (
          this.scheduleCspDetails[i] &&
          this.scheduleCspDetails[i].OccupancyScheduleNumber &&
          this.scheduleCspDetails[i].OccupancyScheduleNumber === searchedNumber
        ) {
          let commercialStatisticalPlan = this.scheduleCspDetails[i].CommercialStatPlan;
          if (commercialStatisticalPlan) {
            commercialStatisticalPlan = commercialStatisticalPlan.toString().padStart(4, '0');
          }
          return {
            OccupantType: scheduleDescription,
            CSP: commercialStatisticalPlan ? commercialStatisticalPlan : '',
            SPOC: this.scheduleCspDetails[i]?.SprinklerProtectionOccupancyClass?this.scheduleCspDetails[i]?.SprinklerProtectionOccupancyClass:'',
            SU:this.scheduleCspDetails[i]?.SusceptibilityClass?this.scheduleCspDetails[i]?.SusceptibilityClass:0,
            EQ:this.scheduleCspDetails[i]?.EarthQuake?this.scheduleCspDetails[i]?.EarthQuake:0,
            CB :this.scheduleCspDetails[i]?.CombustibilityClass?this.scheduleCspDetails[i]?.CombustibilityClass:'',
          };
        }
      }
    } catch (error) {
      console.warn('Occupant Schedule Detail search ' + error);
    }
    return null;
  }

  getSchedule(env: string): Observable<any> {
    // console.debug('Schedule Lookup Data Request (assets/json/' + env + '.json)');
    return this.http
      .get('assets/json/schedules/' + env + '.json')
      .pipe(
      tap(() => {
        console.debug('Schedule Lookup Data Fetched (assets/json/' + env + '.json)');
      })
      )
  }
}