/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { SurveyDetailsDataModel } from 'src/app/models/formly-builder/formly/survey-details-data.model';
import { UtilService } from '../util-service/util.service';

@Injectable({
  providedIn: 'root'
})
export class FormlySurveyDetailsService extends SectionServiceAbstract {
  constructor(private utilService: UtilService) {
    super();
  }

  async updateData(model: any, riskReportData: any) {
    const hasChanged: boolean = SurveyDetailsDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      const surveyDetails = SurveyDetailsDataModel.to(model);
      if (surveyDetails) {
        surveyDetails.SurveyDate = this.utilService.convertDateToISOStringFormat(surveyDetails.SurveyDate);
      }
      riskReportData.SurveyDate = surveyDetails.SurveyDate;
      if (riskReportData && riskReportData?.SprinklerReport && riskReportData.EvidenceOfFireSprinkler === 'Y') {
        riskReportData.SprinklerReport.SurveyDate = surveyDetails.SurveyDate;
        riskReportData.SprinklerReport.DataCreation = surveyDetails.DataCreation;
      }
      riskReportData.ReportRelatedDates = SurveyDetailsDataModel.setRelatedDates(surveyDetails.SurveyDate, riskReportData);
      if (riskReportData.WindReport) {
        riskReportData.WindReport.SurveyDate = surveyDetails?.SurveyDate;
      }
      return { ...riskReportData };
    } else {
      return undefined;
    }
  }

  loadData(sectionData: any[], reportInfo: any, isS3: boolean, model: any) {
    let surveyDetails: SurveyDetailsDataModel;
    if (isS3) {
      surveyDetails = SurveyDetailsDataModel.fromS3(reportInfo);
    }
    const surveyDate = surveyDetails.surveyDate ? surveyDetails.surveyDate : null;
    return {
      ...model,
      ...{
        survey: {
          dataCreation: surveyDetails.dataCreation,
          surveyDate,

        }
      }
    };

  }
}
