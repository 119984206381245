import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { CalculationEnum } from 'src/app/enums/formly-builder/calculation.enum';
import { CalculationInterface } from 'src/app/interfaces/formly-builder/calculation.interface';
import { CalculationStatusInterface } from 'src/app/interfaces/formly-builder/calculation-status.interface';
import { FormlyBuilderService } from '../formly-builder.service';
import { PublicProtectionClassDataModel } from 'src/app/models/formly-builder/formly/ppc-data.model';
import { takeUntil } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SectionEnum } from '../section.config';
import { Constants } from '../../util-service/constants';
import { UtilService } from '../../util-service/util.service';
import { FormlyPublicProtectionAreaService } from '../formly-ppa.service';

@Injectable({
  providedIn: 'root',
})
export class PublicProtectionService extends SectionServiceAbstract implements CalculationInterface {
  calculationStatus: number;

  constructor(private formlyBuilder: FormlyBuilderService,private utilService: UtilService, 
    private publicProtectionService: FormlyPublicProtectionAreaService
  ) {
    super();
  }

  async updateData(model: any, riskReportData: any) {
    // Check if data has changed
    const hasChanged: boolean = PublicProtectionClassDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      const publicProtectionInfo = PublicProtectionClassDataModel.to(model);
      let addressArray: any[] =[];
      riskReportData.ReportAddresses.forEach((address) => {
        address['FireProtectionArea'] = model.publicProtection.fireProtectionArea;
        addressArray.push(address);
      });
     
      
      return { ...riskReportData, ...{ReportAddresses:addressArray},...publicProtectionInfo };
    } else {
      return undefined;
    }
  }

  loadData(sectionData: any[], riskReportData: any, isDataInS3Format: boolean, model: any) {
    const publicProtectionClassData: PublicProtectionClassDataModel = PublicProtectionClassDataModel.fromS3(riskReportData);
    if ((!publicProtectionClassData.isPublicProtectionClass || !publicProtectionClassData.isFireProtectionArea ||
      !publicProtectionClassData.isTerritoryCode || !publicProtectionClassData.isPpcFireHydrant) &&
      (publicProtectionClassData?.fireProtectionArea || publicProtectionClassData?.publicProtectionClass ||
        publicProtectionClassData?.territoryCode || publicProtectionClassData?.isHydrantsWithin1000feet) 
      && (riskReportData.PublicProtectionClass !== publicProtectionClassData?.publicProtectionClass || 
        riskReportData.TerritoryCode !== publicProtectionClassData?.territoryCode ||
        riskReportData.PpcFpa !== publicProtectionClassData?.fireProtectionArea ||
        riskReportData.IsHydrantsWithin1000Feet !== publicProtectionClassData?.isHydrantsWithin1000feet
      )) {
      setTimeout(() => {
        this.formlyBuilder.riskReport.updateSectionData(SectionEnum.FR_OW_PPC, false, SectionEnum.FR_OW_PPC);
      }, 1000);
    }
    return { ...model, ...{ publicProtection: publicProtectionClassData } };
  }

  /**
   * Method to help simulating a long running calculation
   * @param ms 
   * @returns 
   */
  sleep = (ms) => new Promise(r => setTimeout(r, ms));

  /**
   * Method to simulate a long running calculation
   * @param formlyBuilder 
   * @returns 
   */
  calculate(formlyBuilder: FormlyBuilderService): Observable<CalculationStatusInterface> {
    return new Observable((subscriber) => {
      console.log("Public Protection Service is doing something");
      this.sleep(10000).then(() => {
        console.log("PPC End");
        subscriber.next({
          enum: CalculationEnum.PPC,
          name: '',
          status: 12,
          comment: 'Running',
          service: this
        });
      });
    });
  }

 

  public addField(field) {
    const riskReport=field?.options?.formState?.service?.riskReport?.report;
    let mainAddress;
    if(riskReport?.ReportAddresses?.length){
    mainAddress = riskReport.ReportAddresses.find((address) => address.StateCode === 'NC');
    }
    if (mainAddress) {
      field.props.options = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '9E', label: '9E' },
        { value: '10', label: '10' },

      ];
    }
    else {
      field.props.options = [
        { value: '1', label: '1' },
        { value: '1X', label: '1X' },
        { value: '1Y', label: '1Y' },
        { value: '2', label: '2' },
        { value: '2X', label: '2X' },
        { value: '2Y', label: '2Y' },
        { value: '3', label: '3' },
        { value: '3X', label: '3X' },
        { value: '3Y', label: '3Y' },
        { value: '4', label: '4' },
        { value: '4X', label: '4X' },
        { value: '4Y', label: '4Y' },
        { value: '5', label: '5' },
        { value: '5X', label: '5X' },
        { value: '5Y', label: '5Y' },
        { value: '6', label: '6' },
        { value: '6X', label: '6X' },
        { value: '6Y', label: '6Y' },
        { value: '7', label: '7' },
        { value: '7X', label: '7X' },
        { value: '7Y', label: '7Y' },
        { value: '8', label: '8' },
        { value: '8B', label: '8B' },
        { value: '8X', label: '8X' },
        { value: '8Y', label: '8Y' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '10W', label: '10W' },

      ];
    }

  }

  public publicProtectionClassValues(field){
    const publicProtectionValue = field?.form?.controls?.publicProtectionClass?.value;;


    if (publicProtectionValue?.includes('/')) {
      const ppcValues = publicProtectionValue.split('/');
      const lowerPpc = ppcValues[0];
      const higherPpc = ppcValues[1];

      if (field.form?.value?.isHydrantsWithin1000feet === 'true') {
        field.form.get('publicProtectionClass')?.setValue(lowerPpc);
      }
      else if (field.form?.value?.isHydrantsWithin1000feet === 'false') {
        field.form.get('publicProtectionClass')?.setValue(higherPpc);
      }
      else {
        field.form.get('publicProtectionClass')?.setValue(null);
      }
    }
  }

  public estimate(field: FormlyFieldConfig) {
    field.parent?.formControl?.markAllAsTouched();
    let fireFlowAt20Psi;
    field?.options?.formState?.order?.
    PrePopulatingInformations?.forEach((prePopulatingInformation)=>
      {
        if(prePopulatingInformation?.PrepopulatingInformationType === 'FlowAt20Psi'){
          fireFlowAt20Psi = prePopulatingInformation?.PrepopulatingInformationValue;
        }
      })
    
    const waterSupplyWorksAdequacy = field.form?.get('waterSupplyWorksAdequacy')?.value;
    const fireDepartmentCompaniesAdequacy = field.form?.get('fireDepartmentCompaniesAdequacy')?.value;
    const neededFireFlow = field.parent?.parent?.form?.get('neededFireFlow')?.value;
    const hydrantsWithPumOutlet = field.form?.get('hydrantsWithPumperOutlet')?.value;
    const hydrantsWithTwoHoseOutlet = field.form?.get('hydrantsWithTwoHoseOutlets')?.value;
    const hydrantWithOneHoseOutlet = field.form?.get('hydrantsWithOneHoseOutlets')?.value;
    let hydrantSpacingAdequacy;
    if (neededFireFlow && (hydrantsWithPumOutlet !== null || hydrantsWithPumOutlet !== undefined) &&
      (hydrantsWithTwoHoseOutlet !== null || hydrantsWithTwoHoseOutlet !== undefined) &&
      (hydrantWithOneHoseOutlet !== null || hydrantWithOneHoseOutlet !== undefined) &&
      waterSupplyWorksAdequacy && fireDepartmentCompaniesAdequacy) {
      field.parent.parent.fieldGroup[ 1 ].props.isCollapsed = false;
      if (fireFlowAt20Psi) {
        field.form?.get('fireFlowAt20Psi')?.setValue(fireFlowAt20Psi);
      } else {
        fireFlowAt20Psi = 3500;
        field.form?.get('fireFlowAt20Psi')?.setValue(3500);
      }
      hydrantSpacingAdequacy = ((hydrantsWithPumOutlet * 1500) + (hydrantsWithTwoHoseOutlet * 750) + (hydrantWithOneHoseOutlet * 500)) / neededFireFlow;
      hydrantSpacingAdequacy = hydrantSpacingAdequacy * 100;
      if (hydrantSpacingAdequacy > 100) {
        hydrantSpacingAdequacy = 100;
      }
      field.form?.get('calculatedFireDepartmentCompaniesAdequacy')?.setValue(fireDepartmentCompaniesAdequacy);
      field.form?.get('calculatedWaterSupplyWorksAdequacy')?.setValue(waterSupplyWorksAdequacy);

        //const waterSupplyWorksAdequacy = field.parent?.formControl?.get('waterSupplyWorksAdequacy')?.value;
        let overallValue;
        if (neededFireFlow) {
          overallValue = (fireFlowAt20Psi / neededFireFlow) * 100;
          if (overallValue > 100) {
            overallValue = 100;
            field.parent?.formControl?.get('overallAdequacy')?.setValue(100);
          } else {
            field.parent?.formControl?.get('overallAdequacy')?.setValue(Math.floor(overallValue));
          }
        }
        field.form?.get('hydrantSpacingAdequacy')?.setValue(Math.floor(hydrantSpacingAdequacy));
        let evaluatedClass;
        const evaluatedAdequacy = Math.min(overallValue, hydrantSpacingAdequacy, fireDepartmentCompaniesAdequacy, waterSupplyWorksAdequacy)

        if (evaluatedAdequacy >= 90) { evaluatedClass = 1; }
        else if (evaluatedAdequacy >= 80) { evaluatedClass = 2; }
        else if (evaluatedAdequacy >= 70) { evaluatedClass = 3; }
        else if (evaluatedAdequacy >= 60) { evaluatedClass = 4; }
        else if (evaluatedAdequacy >= 50) { evaluatedClass = 5; }
        else if (evaluatedAdequacy >= 40) { evaluatedClass = 6; }
        else if (evaluatedAdequacy >= 30) { evaluatedClass = 7; }
        else if (evaluatedAdequacy >= 20) { evaluatedClass = 8; }
        else if (evaluatedAdequacy >= 10) { evaluatedClass = 9; }
        else if (evaluatedAdequacy >= 0) { evaluatedClass = 10; }

        let publicProtection = field.form?.get('publicProtectionClass')?.value
        const lastChar = publicProtection.charAt(publicProtection.length - 1);
        if (isNaN(parseInt(lastChar, 10))) {
          // If the last character is non-numeric, remove it
          publicProtection = publicProtection.slice(0, -1);
        }
        publicProtection = Number(publicProtection);
        if (evaluatedClass > publicProtection) {
          field.form?.get('publicProtectionClass')?.setValue(evaluatedClass);
          field.form?.get('evaluatedClass')?.setValue(evaluatedClass);
        } else {
          field.form?.get('evaluatedClass')?.setValue(publicProtection);
        }
        field.formControl.markAsDirty();
        this.formlyBuilder.onSectionFieldChange(field, SectionEnum.FR_OW_PPC, true, true);
               
      }
  }

  /**
   * get data via email
   * @field
   */
    getDataViaEmail(field){
      const mainAddress = field?.form?.root?.value?.address?.mainAddress;
      const state = mainAddress?.state?.trim();
      const city = mainAddress?.city?.trim();
      const streetNumber = mainAddress?.streetNumber ? mainAddress?.streetNumber?.trim()+',' : '';
      const streetName = mainAddress?.streetName?.trim();
      const zipCode = mainAddress?.zipcode?.trim();
      const county =mainAddress?.county?.trim();
      const requestMail = Constants.requestMail;
      const fireProtectionArea = field?.model?.fireProtectionArea ? field?.model?.fireProtectionArea : '';
      const neededFireFlow = field?.model?.neededFireFlow ? field?.model?.neededFireFlow : '';
      const subject =`Section II Request - ${city}, ${state}`;
      // eslint-disable-next-line max-len
      const body =`Please provide the Water Supply Works and FD Companies Adequacy for\nPrimary Address: ${streetNumber} ${streetName}, ${city}, ${state}, ${zipCode}\nFire Protection Area: ${fireProtectionArea} \nCounty: ${county} \nNeed Fire Flow: ${neededFireFlow}`;
      const url: string =
        'mailto:' +
        requestMail +
        '?subject=' +subject+
        '&body=' + encodeURIComponent(body);

      this.utilService.openInNewWindow(url);
    }
}
