export enum RiskModeEnum {
  CURRENCY,
  COVERAGE,
  FULLRISK
}

export const RiskModeConfig = new Map<RiskModeEnum, string>([
  // Risk Form Modes
  [ RiskModeEnum.CURRENCY, "Currency" ],
  [ RiskModeEnum.COVERAGE, "Coverage" ],
  [ RiskModeEnum.FULLRISK, "Full Risk" ]
]);
