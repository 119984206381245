/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { RiskModeEnum } from 'src/app/enums/formly-builder/risk-mode.enum';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';

import { Constants } from 'src/app/services/util-service/constants';
import { SectionConfig, SectionEnum } from 'src/app/services/formly-builder/section.config';
import { FormlyWallsConstructionService } from 'src/app/services/formly-builder/formly-walls-construction.service';
import { FormControl } from '@angular/forms';
export class WallsConstructionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService, private formlywallsConstructionService: FormlyWallsConstructionService) {
    super({
      key: 'walls',
      wrappers: ['simple-section-wrapper'],
      props: {
        label: 'Wall Construction',
        addType: 'Walls',
        addText: 'Add Walls',
        isCollapsable: true,
        actionButtons: [
          { action: 'add', label: 'Add New', isDisabled: false }
        ],
      },
      expressions: {
        'props.actionButtons[0].label': (field: FormlyFieldConfig) => field.form?.value?.walls?.wallsArray?.length ? 'Edit' : Constants.btnText,
        'props.isCollapsed': (field: FormlyFieldConfig) =>
          field.form?.value?.walls?.wallsArray?.length ? false : true,
        'props.isCollapseIconDisabled': (field: FormlyFieldConfig) =>
          field.form?.value?.walls.wallsArray?.length ? false : true // disable the expand collapse arrow if no entries
      },
      fieldGroup: [
        {
          key: 'chargeableColumn',
        },
        {
          key: 'wallsArray',
          type: 'fr-walls-array',
          fieldArray: {
            fieldGroupClassName: 'formly-flex-container',
            wrappers: ['error-info-wrapper'],
            fieldGroup: [
              {
                key: 'constructionType',
                id: 'constructionType',
                name: 'Construction Type',
                type: 'simple-select',
                className: 'select-width-md',
                props: {
                  label: 'Wall Construction',
                  required: true,
                  options: [],
                  optionsAvailable: true,
                  isAsteriskNotRequired: true,
                },
                hooks: {
                  onInit: (field) => {
                    {
                      field.formControl.markAsTouched();
                      if (field?.options?.formState?.service._isV2) {
                        field.props.options = formlyBuilderService.wallsConstructionService.getConstructionTypeLookupDataV2();
                      }
                      else {
                        field.props.options = formlyBuilderService.wallsConstructionService.getConstructionTypeLookupDataV1();
                      }
                      field.formControl.valueChanges.subscribe((value) => {
                        if (value && field.options.formState.service.isSheetActive === true) {
                          field?.parent?.formControl[ 'controls' ]?.constructionMaterial?.setValue(null);
                          field.form.get('isSlowBurning')?.setValue(false);
                        }
                      });
                    }
                  }
                },
              },
              {
                type: 'rf-aerial',
                props: {
                  areaKey: 'width',
                  oneKey: 'height',
                  initialMode: 'draw_line_string'
                }
              },               
              {
                key: 'width',
                id: 'width',
                type: 'simple-input',
                defaultValue: null,
                // defaultValue: 1,
                className: 'smd-width max-z-index position-relative',
                props: {
                  label: 'Length',
                  type: 'number',
                  inputmode: 'tel',
                  required: true,
                  isAsteriskNotRequired: true,
                  min: 0,
                  maxLength: 8,
                  isNonBlockingValidation: false,
                  className: 'ft-label-masonary'
                },
                validators: {
                  validation: [
                    { name: 'pureInteger' },
                  ]
                },                
                hooks: {
                  onInit: (field) => {
                    field.formControl.markAsTouched();
                    const formlyService = field.options.formState.service;
                    formlyService.wallsConstructionService.calculateArea(field);
                    field?.formControl?.valueChanges.subscribe(
                      (value) => {
                        formlyService.wallsConstructionService
                          .calculateArea(field);
                        field?.form?.get('wallLevelValidator')?.updateValueAndValidity();
                      }
                    );
                  }
                },
              },
              {
                key: 'height',
                id: 'height',
                type: 'simple-input',
                defaultValue: null,
                // defaultValue: 1,
                className: 'smd-width max-z-index position-relative',
                props: {
                  label: 'Height',
                  type: 'number',
                  inputmode: 'tel',
                  required: true,
                  isAsteriskNotRequired: true,
                  min: 0,
                  maxLength: 8,
                  isNonBlockingValidation: false,
                  className: 'ft-label-masonary'
                },
                validators: {
                  validation: [
                    { name: 'pureInteger' },
                  ]
                },
                hooks: {
                  onInit: (field) => {
                    field.formControl.markAsTouched();
                    const formlyService = field.options.formState.service;
                    formlyService.wallsConstructionService.calculateArea(field);
                    field?.formControl?.valueChanges.subscribe(
                      (value) => {
                        formlyService.wallsConstructionService
                          .calculateArea(field);
                        field?.form?.get('wallLevelValidator')?.updateValueAndValidity();
                      }
                    );
                  }
                },
              },
              { template: '<span>=</span>', className: 'equals-to' },
              {
                key: 'area',
                id: 'area',
                type: 'text',
                className: 'calculated-value',
                props: {
                  label: 'Area',
                  required: false,
                  readonly: true,
                  unit: ' sq.ft.',
                  // prefix: '=',
                  isCorrect: undefined,
                },
                expressions: {
                  'props.isCorrect': (field: FormlyFieldConfig) => !field.formControl.valid ? false : undefined
                },
              },
              {
                key: 'constructionPercentage',
              },
              {
                key: 'enabledV2',
                hooks: {
                  onInit: (field) => {
                    field?.form?.get('enabledV2')?.setValue(field?.options?.formState?.service._isV2);
                  }
                },
              },
              { template: '<span></span>', className: 'new-row' },
              // Masonry
              {
                fieldGroupClassName: 'formly-flex-container',
                fieldGroup: [
                  {
                    key: 'constructionMaterial',
                    id: 'constructionMaterial',
                    name: 'Construction Material',
                    //   id: Guid.create().toString(),
                    type: 'simple-select',
                    className: 'select-width-md',
                    // className: 'wall-construction-material',
                    props: {
                      label: 'Construction Preset',
                      defaultValue: null,
                      required: true,
                      options: [],
                      optionsAvailable: true,
                      isAsteriskNotRequired: true,
                    },
                    // expressions: {
                    //   'props.required': (field: FormlyFieldConfig) => field.options.formState.service.mode === 1 ? true : false,
                    // },
                    hooks: {
                      onInit: (field) => {
                        field.formControl.markAsTouched();
                        const masonryOptions = field.options.formState.service.wallsConstructionService.getConstructionPresetValues(field, 'Masonry');
                        field.props.options = masonryOptions;
                        field.options.formState.service.wallsConstructionService.setNewconstructionPreset(field, 'Masonry');
                        field?.formControl?.valueChanges.subscribe(
                          (value) => {
                            const presetVal = field.parent.formControl['controls']?.constructionMaterial?.value;
                            if (value !== null && value !== undefined && presetVal !== null && presetVal !== undefined && presetVal === 'Brick') {

                              field?.parent.form.get('wallClassification')?.setValue(1);
                              field.model.wallClassification = 1;
                            }
                            else if (presetVal === null) {
                              field?.parent.form.get('wallClassification')?.setValue(null);
                            }
                          }
                        );
                      }
                    },
                  },
                  {
                    key: 'wallClassification',
                    id: 'wallClassification',
                    type: 'simple-select',
                    defaultValue: null,
                    // defaultValue: 1,
                    //className: 'wall-typewidth',
                    className: 'select-width-sm',
                    props: {
                      label: 'Wall Type',
                      required: false,
                      isAsteriskNotRequired: true,
                      isNonBlockingValidation: false,
                      isDisabled: true,
                      // unit: 'in',
                      options: [
                        { value: 1, label: 'W1' },
                        { value: 2, label: 'W2' },
                        { value: 3, label: 'W3' }
                      ]
                    },
                    expressions: {
                      'props.required': (field: FormlyFieldConfig) => field.form?.value?.constructionMaterial === 'Other' ? true : false,
                      // 'props.disabled': (field: FormlyFieldConfig) => field.form?.value?.constructionMaterial !== 'Other'? true : false,
                      'props.isDisabled': (field: FormlyFieldConfig) => field.form?.value?.constructionMaterial !== 'Other' ? true : false,
                    },
                    hooks: {
                      onInit: (field: FormlyFieldConfig) => {
                        field.formControl.markAsTouched();
                        // const initialpresetVal = field.parent.formControl[ 'controls' ]?.constructionMaterial.value;
                        // field.options.formState.service.wallsConstructionService.setWallClassification(field,initialpresetVal);
                        const presetVal = field.parent.formControl['controls']?.constructionMaterial.valueChanges.subscribe((value) => {
                          if (value && field.options.formState.service.isSheetActive === true) {
                            field.options.formState.service.wallsConstructionService.setWallClassification(field, value);
                          }
                        })
                        field.options.formState.service.wallsConstructionService.onCombustibleTypeChanges(field, 'wallClassification');
                        field?.formControl?.valueChanges.subscribe(
                          (value) => {
                            if (value && field.options.formState.service.isSheetActive === true) {
                              field.options.formState.service.wallsConstructionService
                                .onCombustibleTypeChanges(field, 'wallClassification');
                            }
                          }
                        );

                      }
                    },
                  },
                  {
                    key: 'thickness',
                    type: 'simple-input',
                    defaultValue: null,
                    // defaultValue: 1,
                    resetOnHide: true,
                    className: 'smd-width walltype-thickness max-z-index position-relative',
                    props: {
                      label: 'Thickness',
                      type: 'number',
                      inputmode: 'tel',
                      isAsteriskNotRequired: true,
                      maxLength: 8,
                      isNonBlockingValidation: false,
                      className: 'ft-label-in'
                    },
                    expressions: {
                      'props.required': (field?: FormlyFieldConfig) => !field.form.value.constructionMaterial ? false : true,
                      'props.isDisabled': (field?: FormlyFieldConfig) => !field.form.value.constructionMaterial,
                      // 'props.disabled': (field?: FormlyFieldConfig) => !field.form.value.constructionMaterial
                    },
                    validators: {
                      thickness: {
                        expression: (control: FormControl, field: FormlyFieldConfig) => {
                          if (control?.value !== null && control?.value !== undefined && control?.value !== '' && control?.value < 4) {
                            return false;
                          } else {
                            return true;
                          }
                        },
                        message: 'Masonry thickness must be 4 or greater',
                      },
                    },
                    hooks: {
                      onInit: (field) => {
                        field.formControl.markAsTouched();
                      },
                    },
                  },
                  { template: '<span></span>', className: 'new-row' },
                  {
                    key: 'isReinforcedConcrete',
                    type: 'custom-checkbox',
                    className: 'checkbox-white',
                    props: {
                      label: 'Reinforced Concrete',
                      isDisabled: true,
                      disabled: true,
                    },
                    expressions: {
                      'props.isDisabled': (field: FormlyFieldConfig) => field.form?.value?.constructionMaterial !== 'Other' ? true : false,
                      'props.disabled': (field: FormlyFieldConfig) => field.form?.value?.constructionMaterial !== 'Other' ? true : false,
                      hide: (field) => {
                        if (field.form?.get('wallClassification')?.value == 1) {
                          return false;
                        }
                        field.formControl.setValue(false);
                        return true;
                      }
                    },
                    hooks: {
                      onInit: (field) => {
                        field.formControl.markAsTouched();
                      },
                    },
                  },
                ],
                expressions: {
                  hide: (field) => {
                    if (field.form?.get('constructionType')?.value === 'Masonry') {
                      return false;
                    }
                    return true;
                  }
                }
              },
              // Non-Combustible/Slow Burn
              {
                fieldGroupClassName: 'walls-noncombustible formly-flex-container',
                fieldGroup: [
                  {
                    key: 'constructionMaterial',
                    name: 'Construction Present',
                    type: 'simple-select',
                    className: 'select-width-md',
                    props: {
                      label: 'Construction Preset',
                      required: true,
                      options: [],
                      optionsAvailable: true,
                      isAsteriskNotRequired: true,
                    },
                    hooks: {
                      onInit: (field: FormlyFieldConfig) => {
                        field.formControl.markAsTouched();
                        const nclOptions = field.options.formState.service.wallsConstructionService.getConstructionPresetValues(field, 'Non-Combustible/Slow Burn');
                        field.props.options = nclOptions;
                        field.options.formState.service.wallsConstructionService.setNewconstructionPreset(field, 'Non-Combustible/Slow Burn');

                        field?.formControl?.valueChanges.subscribe(
                          (value) => {
                            const presetVal = field.parent.formControl['controls']?.constructionMaterial?.value;
                            if (value !== null && value !== undefined && presetVal !== null && presetVal !== undefined && presetVal === 'Metal Panels on Steel Supports') {

                              const wallClassificationControl = field?.form?.get('nCWallType');
                              wallClassificationControl?.setValue('Non-Combustible');
                            }
                            else if (presetVal === null) {
                              field?.parent.form.get('nCWallType')?.setValue(null);
                            }
                          }
                        );

                      }
                    },
                  },
                  {
                    key: 'nCWallType',
                    name: 'Wall Type',
                    type: 'simple-select',
                    className: 'select-width-md',
                    props: {
                      label: 'Wall Type',
                      required: false,
                      readonly: false,
                      isDisabled: false,
                      options: [],
                      optionsAvailable: true,
                      isAsteriskNotRequired: true,
                    },
                    expressions: {
                      //  'props.disabled': (field: FormlyFieldConfig) => field.form?.value.constructionMaterial !== 'Other'? true : false,
                      'props.isDisabled': (field: FormlyFieldConfig) => field.form?.value.constructionMaterial !== 'Other' ? true : false,
                    },
                    hooks: {
                      onInit: (field) => {
                        field.formControl.markAsTouched();
                        const formlyService = field.options.formState.service;
                        formlyService.wallsConstructionService
                          .getwalltypeLookupData()
                          .subscribe((data) => {
                            field.props.options = data.filter(value => {
                              if (value.type === 'Non-Combustible/Slow Burn') {
                                return value;
                              }
                            });
                          });
                        formlyService.wallsConstructionService.onWallTypeChanges(field);
                        field?.formControl?.valueChanges.subscribe(
                          (value) => {
                            if (value && field.options.formState.service.isSheetActive === true) {
                              formlyService.wallsConstructionService
                                .onWallTypeChanges(field);
                            }
                          }
                        );
                        // const initialpresetVal = field.parent.formControl[ 'controls' ]?.constructionMaterial.value;
                        // field.options.formState.service.wallsConstructionService.setWallType(field,initialpresetVal);
                        const presetVal = field.parent.formControl['controls']?.constructionMaterial.valueChanges.subscribe((value) => {
                          if (value && field.options.formState.service.isSheetActive === true) {
                            field.options.formState.service.wallsConstructionService.setWallType(field, value);
                          }
                        })
                      }
                    },
                    validators: {
                      wallTypeNonCombustibleVal: {
                        expression: (control: FormControl, field: FormlyFieldConfig) => {
                          const cmValue = field?.form?.get('constructionMaterial')?.value;
                          const ctValue = field?.form?.get('constructionType')?.value;
                          if ((control.value === null || control.value === undefined) && cmValue === 'Other' && ctValue === 'Non-Combustible/Slow Burn') {
                            return false;
                          }
                          else {
                            return true;
                          }
                        },
                        message: 'Wall Type is required.'
                      },
                    },
                  },
                  {
                    key: 'isFlameSpreadAbove200',
                    type: 'custom-checkbox',
                    className: 'sd-checkbox-style max-z-index position-relative',
                    resetOnHide: true,
                    props: {
                      label: 'SD > 200',
                    },
                    expressions: {
                      'props.isDisabled': (field: FormlyFieldConfig) => {
                        const arr = field.options.formState.service.wallsConstructionService.getNonCombustibleLookupData();
                        return arr.find((item) =>
                          (item.preset === field?.form?.value?.constructionMaterial)
                        )?.isFlameSpreadAbove200State === 'disable' ? true : false;
                      }
                    },
                  },
                  { template: '<span></span>', className: 'new-row' },
                  {
                    className: 'full-width padding-right-0 ',
                    fieldGroupClassName: 'nccard-view',
                    fieldGroup: [
                      {
                        key: 'hasMetalCompositeMaterial',
                        type: 'custom-checkbox',
                        resetOnHide: true,
                        defaultValue: null,
                        // className: 'mcm-styles',
                        props: {
                          label: 'Metal Composite Materials (MCM)',
                          required: true
                        },
                        hooks: {
                          onInit: (field) => {
                            const formlyService = field.options.formState.service;
                            formlyService.wallsConstructionService.onMcmChanges(field);
                            field?.formControl?.valueChanges.subscribe(
                              (value) => {
                                formlyService.wallsConstructionService.onMcmChanges(field);
                              }
                            );
                          }
                        },
                      },
                      {
                        key: 'metalCompositeMaterials',
                        type: 'walls-combustible-array',
                        resetOnHide: true,
                        props: {
                          addText: 'Add Floor',
                        },
                        expressions: {
                          hide: (field: FormlyFieldConfig) => !field.form?.value?.hasMetalCompositeMaterial
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              key: 'flameSpread',
                              type: 'simple-input',
                              defaultValue: null,
                              resetOnHide: true,
                              props: {
                                label: 'Flame Spread',
                                type: 'number',
                                min: 0,
                                max: 25,
                                maxLength: 3
                              },
                              expressions: {
                                'props.required': (field: FormlyFieldConfig) => field.form?.value?.hasMetalCompositeMaterial ? true : false,
                              },
                              className: 'ncFlameSpread max-z-index position-relative',
                              hooks: {
                                onInit: (field) => {
                                  field.formControl.markAsTouched();
                                },
                              },
                            },
                            {
                              key: 'smokeDevelopment',
                              type: 'simple-input',
                              resetOnHide: true,
                              props: {
                                label: 'Smoke Dev',
                                type: 'number',
                                required: true,
                                min: 0,
                                max: 450,
                                maxLength: 3,
                              },
                              expressions: {
                                'props.required': (field: FormlyFieldConfig) => field.form?.value?.hasMetalCompositeMaterial ? true : false,
                              },
                              className: 'ncSmokeDev max-z-index position-relative',
                              hooks: {
                                onInit: (field) => {
                                  field.formControl.markAsTouched();
                                  const formlyService = field.options.formState.service;
                                  if (field.props.disabled === false) {
                                    formlyService.wallsConstructionService.smokeDevChanges(field);
                                  }
                                  field?.formControl?.valueChanges.subscribe(
                                    (value) => {
                                      if (field.props.disabled === false) {
                                        formlyService.wallsConstructionService.smokeDevChanges(field);
                                      }
                                    }
                                  );
                                }
                              },
                            },
                            {
                              key: 'section'
                            },
                            // {
                            //   key: 'metalWallIdentifier'
                            // },
                          ]
                        }
                      }
                    ],
                    expressions: {
                      hide: (field: FormlyFieldConfig) => !field?.options?.formState?.service._isV2
                    },
                  },
                  {
                    key: 'isNoncombustible'
                  },
                  {
                    key: 'isSlowBurning',
                    resetOnHide: true
                  },
                  {
                    key: 'backendPresetValueforexistingRisk'  // stores preset value for existing risk when user changes it from Other to new value
                  }
                ],
                expressions: {
                  hide: (field) => {
                    if (field.form.get('constructionType')?.value === 'Non-Combustible/Slow Burn') {
                      return false;
                    }
                    return true;
                  }
                }
              },
              // Fire Resistive
              {
                fieldGroupClassName: 'formly-flex-container fire-resistive-container',
                fieldGroup: [
                  {
                    key: 'constructionMaterial',
                    name: 'Construction Preset',
                    type: 'simple-select',
                    className: 'select-width-md',
                    // className: 'wall-construction-material',
                    props: {
                      label: 'Construction Preset',
                      required: true,
                      options: [],
                      optionsAvailable: true,
                      isAsteriskNotRequired: true,
                    },
                    hooks: {
                      onInit: (field: FormlyFieldConfig) => {
                        field.formControl.markAsTouched();
                        const fireResistiveOptions = field.options.formState.service.wallsConstructionService.getConstructionPresetValues(field, 'Fire Resistive');
                        field.props.options = fireResistiveOptions;
                        field.options.formState.service.wallsConstructionService.setNewconstructionPreset(field, 'Fire Resistive');

                        field?.formControl?.valueChanges.subscribe(
                          (value) => {
                            const presetVal = field.parent.formControl['controls']?.constructionMaterial?.value;
                            if (value !== null && value !== undefined && presetVal !== null && presetVal !== undefined && presetVal === 'Steel prot. by Cementitious Mixtures') {

                              field.form.get('damagibility')?.setValue(3);
                              field.model.damagibility = 3;
                            }
                            else if (presetVal === null) {
                              field?.parent.form.get('damagibility')?.setValue(null);
                            }
                          }
                        );

                      }
                    },
                  },
                  {
                    key: 'damagibility',
                    type: 'simple-select',
                    className: 'select-width-sm',
                    props: {
                      label: 'Damageability',
                      index: 0,
                      isDisabled: false,
                      required: false,
                      options: [
                        { value: 1, label: 'D1' },
                        { value: 2, label: 'D2' },
                        { value: 3, label: 'D3' },
                      ],
                      isAsteriskNotRequired: true,
                      isNonBlockingValidation: false,
                    },
                    expressions: {
                      'props.index': 'field.parent.key',
                      'props.required': (field: FormlyFieldConfig) => field.form?.value?.constructionMaterial === 'Other' ? true : false,
                      // 'props.disabled': (field: FormlyFieldConfig) => field.form?.value?.constructionMaterial === 'Other' ? false : true,
                      'props.isDisabled': (field: FormlyFieldConfig) => field.form?.value?.constructionMaterial === 'Other' ? false : true,
                    },
                    hooks: {
                      onInit: (field: FormlyFieldConfig) => {
                        field.formControl.markAsTouched();
                        // const initialpresetVal = field.parent.formControl[ 'controls' ]?.constructionMaterial.value;
                        // field.options.formState.service.wallsConstructionService.setDamagibility(field,initialpresetVal);
                        const presetVal = field.parent.formControl['controls']?.constructionMaterial.valueChanges.subscribe((value) => {
                          if (value && field.options.formState.service.isSheetActive === true) {
                            field.options.formState.service.wallsConstructionService.setDamagibility(field, value);

                          }
                        });
                        field?.formControl?.valueChanges?.pipe(
                          distinctUntilChanged()
                        ).subscribe((value) => {
                          if (value && (value === '2' || value === '3') && field.options.formState.service.isSheetActive === true) {
                            field?.form?.get('fireResistiveListType')?.setValue(null);
                          }
                        })
                      },
                    },
                  },
                  {
                    key: 'hourlyRating',
                    type: 'simple-select',
                    // className: 'select-width-sm',
                    defaultValue: null,
                    resetOnHide: true,
                    className: 'select-width-sm',
                    props: {
                      label: 'Hourly Rating',
                      index: 0,
                      isDisabled: false,
                      required: true,
                      unit: 'hrs',
                      options: [
                        { value: '1', label: '1' },
                        { value: '2', label: '2' },
                        { value: '3', label: '3' },
                      ],
                      isAsteriskNotRequired: true,
                      isNonBlockingValidation: false,
                    },
                    expressions: {
                      'props.index': 'field.parent.key',
                      'props.isDisabled': (field: FormlyFieldConfig) => field?.form?.value.constructionMaterial === 'Reinforced Concrete',
                    },
                    hooks: {
                      onInit: (field) => {
                        field.formControl.markAsTouched();
                      },
                    },
                  },
                  { template: '<span></span>', className: 'new-row' },
                  {
                    key: 'fireResistiveListType',
                    type: 'rf-yes-no',
                    className: 'max-z-index position-relative fire-resistive-list-type',
                    resetOnHide: true,
                    props: {
                      label: 'Recognized',
                      required: false,
                      disabled: false,
                      isDisabled: false,
                    },
                    expressions: {
                      'props.index': 'field.parent.key',
                      'props.isDisabled': (field: FormlyFieldConfig) => field.form?.value?.constructionMaterial === 'Lally Column - Unlisted' ? true : false,
                      //  'props.disabled': (field: FormlyFieldConfig) => field.form?.value?.constructionMaterial === 'Lally Column - Unlisted' ? true : false,
                      hide: (field: FormlyFieldConfig) => {
                        const damagibilityControl = field?.parent?.formControl?.get('damagibility')?.value;
                        if (damagibilityControl !== undefined && damagibilityControl !== null && (damagibilityControl == '2' || damagibilityControl == '3')) {
                          field.props.required = true;
                          return false;
                        }
                        else {
                          return true;
                        }

                      },
                      'props.required': (field: FormlyFieldConfig) => field?.form?.value?.damagibility === 'D2' || field?.form?.value?.damagibility === 'D3' || field?.form?.value?.damagibility == 2 || field?.form?.value?.damagibility == 3,
                    },
                  },
                  {
                    key: 'backendPresetValueforexistingRisk'  // stores preset value for existing risk when user changes it from Other to new value
                  }
                ],
                expressions: {
                  hide: (field) => {
                    if (field.form.get('constructionType').value === 'Fire Resistive') {
                      return false;
                    }
                    return true;
                  }
                }
              },
              // for combustible
              {
                fieldGroupClassName: 'walls-noncombustible formly-flex-container',
                fieldGroup: [
                  {
                    key: 'constructionMaterial',
                    name: 'Construction Material',
                    type: 'simple-select',
                    className: 'select-width-md',
                    props: {
                      label: 'Construction Preset',
                      required: true,
                      options: [],
                      optionsAvailable: true,
                      isAsteriskNotRequired: true,
                    },
                    hooks: {
                      onInit: (field: FormlyFieldConfig) => {
                        field.formControl.markAsTouched();
                        const combustibleOptions = field.options.formState.service.wallsConstructionService.getConstructionPresetValues(field, 'Combustible');
                        field.props.options = combustibleOptions;

                        field.options.formState.service.wallsConstructionService.setNewconstructionPreset(field, 'Combustible');
                        field?.formControl?.valueChanges.subscribe(
                          (value) => {
                            field?.form?.get('combustibleWallType')?.updateValueAndValidity();
                            const presetVal = field.parent.formControl['controls']?.constructionMaterial?.value;
                            if (value !== null && value !== undefined && presetVal !== null && presetVal !== undefined && presetVal === 'EIFS on metal or wood studs') {

                              const wallClassificationControl = field?.form?.get('combustibleWallType');
                              wallClassificationControl?.setValue('Combustible');
                            }
                            else if (presetVal === null) {
                              field?.parent.form.get('combustibleWallType ')?.setValue(null);
                            }
                          }
                        );
                      }
                    },
                  },
                  {
                    key: 'wallType',
                    name: 'Combustible Type',
                    type: 'simple-select',
                    className: ' select-width-smd',
                    props: {
                      label: 'Combustible Type',
                      required: true,
                      options: [],
                      optionsAvailable: true,
                      isAsteriskNotRequired: true,
                    },
                    // expressions: {
                    //   // 'props.required': (field: FormlyFieldConfig) => field.options.formState.service.mode === 1 ? true : false,
                    //   'props.disabled': (field: FormlyFieldConfig) => field.form?.value?.constructionMaterial === 'EIFS on metal or wood studs'? true : false,
                    //   'props.isDisabled': (field: FormlyFieldConfig) => field.form?.value?.constructionMaterial === 'EIFS on metal or wood studs'? true : false,
                    // },
                    expressions: {
                      'props.required': (field: FormlyFieldConfig) => field.form?.value.constructionMaterial === 'Other' ? true : false,
                      'props.isDisabled': (field: FormlyFieldConfig) => field.form?.value.constructionMaterial !== 'Other' ? true : false,
                    },
                    hooks: {
                      onInit: (field) => {
                        field.formControl.markAsTouched();
                        const formlyService = field.options.formState.service;
                        formlyService.wallsConstructionService
                          .getwalltypeLookupData()
                          .subscribe((data) => {
                            field.props.options = data.filter(value => {
                              if (value.type === 'Combustible') {
                                return value;
                              }
                            });
                          });
                        if (field.props.disabled === false) {
                          formlyService.wallsConstructionService.onCombustibleTypeChanges(field, 'wallType');
                        }
                        field?.formControl?.valueChanges.subscribe(
                          (value) => {
                            if (field.props.disabled === false) {
                              formlyService.wallsConstructionService
                                .onCombustibleTypeChanges(field, 'wallType');
                            }
                          }
                        );
                        // const initialpresetVal = field.parent.formControl[ 'controls' ]?.constructionMaterial.value;
                        // field.options.formState.service.wallsConstructionService.setCombustibleWallType(field,initialpresetVal);
                        const presetVal = field.parent.formControl['controls']?.constructionMaterial.valueChanges.subscribe((value) => {
                          if (value && field.options.formState.service.isSheetActive === true) {
                            field.options.formState.service.wallsConstructionService.setCombustibleWallType(field, value);
                          }
                        });
                      }
                    },
                    // validators: {
                    //   wallTypeCombustibleVal: {
                    //     expression: (control: FormControl, field: FormlyFieldConfig) => {
                    //       const cmValue = field?.form?.get('constructionMaterial')?.value;
                    //       const ctValue = field?.form?.get('constructionType')?.value;
                    //       if ((control.value === null || control.value === undefined) && cmValue === 'Other' && ctValue === 'Combustible') {
                    //         return false;
                    //       }
                    //       else {
                    //         return true;
                    //       }
                    //     },
                    //     message: 'Combustible Type is required.'
                    //   },
                    // },
                  },
                  {
                    key: 'hasNote2',
                    type: 'custom-checkbox',
                    resetOnHide: true,
                    props: {
                      label: 'Timber Columns (Note2)',
                    },
                    className: 'sd-checkbox-style timber-column-width max-z-index position-relative',
                    expressions: {
                      // hide: (field: FormlyFieldConfig) => {
                      //   if (field.form?.getRawValue()?.wallType ==null ||field.form?.getRawValue()?.wallType ==undefined||field.form?.getRawValue()?.wallType =='') {
                      //     field?.form?.get('hasNote2')?.setValue(false);
                      //     field.formControl.enable();
                      //     return true;
                      //   }
                      //   else {
                      //     return false;
                      //   }
                      // },
                      'props.isDisabled': (field: FormlyFieldConfig) => {
                        const arr = field.options.formState.service.wallsConstructionService.getCombustibleLookupData();
                        return arr.find((item) =>
                          (item.preset === field?.form?.value?.constructionMaterial)
                        )?.hasNote2State === 'disable' ? true : false;
                      },
                      //   'props.disabled': 'field?.props?.isDisabled',
                    },
                    // validators: {
                    //   checkboxCheck: {
                    //     expression: (control: FormControl,field: FormlyFieldConfig) => {
                    //       if (control?.value===true) {
                    //         return true;
                    //       } else {
                    //         return false;
                    //       }
                    //     },
                    //     message: 'Timber Columns (Note2) required',
                    //   },
                    // },
                  },
                  {
                    className: 'full-width max-z-index padding-right-0',
                    fieldGroupClassName: 'nccard-view margin-top',
                    fieldGroup: [
                      {
                        key: 'hasFoamPlasticInsulation',
                        type: 'custom-checkbox',
                        resetOnHide: true,
                        className: 'mcm-styles max-z-index position-relative',
                        props: {
                          label: 'Foam Plastic Insulation',
                          disabled: false,
                          isDisabled: false,
                        },
                        expressions: {
                          'props.isDisabled': (field: FormlyFieldConfig) => {
                            const arr = field.options.formState.service.wallsConstructionService.getCombustibleLookupData();
                            return arr.find((item) =>
                              (item.preset === field?.form?.value?.constructionMaterial)
                            )?.hasFoamPlasticInsulationState === 'disable' ? true : false;
                          }
                        },
                      },
                      {
                        fieldGroupClassName: 'nccard-child-view',
                        expressions: {
                          hide: (field: FormlyFieldConfig) => {
                            if (field.form?.getRawValue()?.hasFoamPlasticInsulation) {
                              return false;
                            }
                            else {
                              field?.form?.get('insulationFlameSpread')?.setValue(null);
                              field?.form?.get('insulationSmokeDevelopment')?.setValue(null);
                              field?.form?.get('isUnlisted')?.setValue(false);
                              field?.form?.get('hasAcceptableThermalBarrier')?.setValue(false);
                              return true;
                            }
                          }
                        },
                        fieldGroup: [
                          {
                            key: 'insulationFlameSpread',
                            type: 'simple-input',
                            defaultValue: null,
                            resetOnHide: true,
                            props: {
                              label: 'Flame Spread',
                              type: 'number',
                              disabled: false,
                              isAsteriskNotRequired: true
                            },
                            expressions: {
                              'props.isDisabled': (field: FormlyFieldConfig) => {
                                if ((field.form?.value?.isUnlisted)) {
                                  field?.form?.get('insulationFlameSpread')?.setValue(null);
                                  return true;
                                }
                                else {
                                  return false;
                                }
                              },
                              'props.required': (field: FormlyFieldConfig) => {
                                if (field.form?.value?.hasFoamPlasticInsulation) {
                                  if ((field.form?.value?.isUnlisted)) {
                                    field?.form?.get('insulationFlameSpread')?.setValue(null);
                                    return false;
                                  }
                                  else {
                                    return true;
                                  }
                                }
                                else {
                                  return false;
                                }
                              }
                            },
                            className: 'ncFlameSpread max-z-index position-relative',
                            hooks: {
                              onInit: (field) => {
                                field.formControl.markAsTouched();
                              },
                            },
                          },
                          {
                            key: 'insulationSmokeDevelopment',
                            type: 'simple-input',
                            defaultValue: null,
                            resetOnHide: true,
                            props: {
                              label: 'Smoke Dev',
                              type: 'number',
                              disabled: false,
                              isAsteriskNotRequired: true
                            },
                            expressions: {
                              'props.isDisabled': (field: FormlyFieldConfig) => {
                                if ((field.form?.value?.isUnlisted)) {
                                  field?.form?.get('insulationSmokeDevelopment')?.setValue(null);
                                  return true;
                                }
                                else {
                                  return false;
                                }
                              },
                              'props.required': (field: FormlyFieldConfig) => {
                                if (field.form?.value?.hasFoamPlasticInsulation) {
                                  if ((field.form?.value?.isUnlisted)) {
                                    field?.form?.get('insulationSmokeDevelopment')?.setValue(null);
                                    return false;
                                  }
                                  else {
                                    return true;
                                  }
                                }
                                else {
                                  return false
                                }
                              }
                            },
                            className: 'ncSmokeDev max-z-index position-relative',
                            hooks: {
                              onInit: (field) => {
                                field.formControl.markAsTouched();
                              },
                            },
                          },
                          { template: '<span>or</span>', className: 'text-message wall-combustible-or-text' },
                          {
                            key: 'isUnlisted',
                            type: 'custom-checkbox',
                            className: 'insulationCheckbox',
                            resetOnHide: true,
                            props: {
                              label: 'Unlisted',
                              disabled: false
                            },
                            expressions: {
                              'props.isDisabled': (field: FormlyFieldConfig) => {
                                if ((field.form?.value.insulationSmokeDevelopment != null && field.form?.value.insulationSmokeDevelopment != undefined && field.form?.value.insulationSmokeDevelopment != '') &&
                                  (field.form?.value.insulationFlameSpread != null && field.form?.value.insulationFlameSpread != undefined && field.form?.value.insulationFlameSpread != '')) {
                                  field?.form?.get('isUnlisted')?.setValue(false);
                                  return true;
                                }
                                else {
                                  return false;
                                }
                              },
                            }
                          },
                          {
                            key: 'hasAcceptableThermalBarrier',
                            type: 'custom-checkbox',
                            className: 'insulationCheckbox',
                            resetOnHide: true,
                            props: {
                              label: 'Thermal Barrier',
                            },
                          },
                        ]
                      }
                    ]
                  },
                  {
                    className: 'full-width max-z-index padding-right-0',
                    fieldGroupClassName: 'nccard-view',
                    fieldGroup: [
                      {
                        key: 'hasMetalCompositeMaterial',
                        type: 'custom-checkbox',
                        resetOnHide: true,
                        className: 'mcm-styles max-z-index position-relative',
                        props: {
                          label: 'Metal Composite Materials (MCM)',
                        },
                      },
                      {
                        key: 'metalCompositeMaterials',
                        type: 'walls-combustible-array',
                        resetOnHide: true,
                        props: {
                          addText: 'Add Floor',
                        },
                        expressions: {
                          hide: (field: FormlyFieldConfig) => {
                            if (field.form?.value?.hasMetalCompositeMaterial) {
                              return false;
                            }
                            else {
                              return true;
                            }
                          }
                        },
                        // className: 'nccard-child-view',
                        fieldArray: {
                          fieldGroup: [
                            {
                              key: 'flameSpread',
                              type: 'simple-input',
                              resetOnHide: true,
                              defaultValue: null,
                              props: {
                                label: 'Flame Spread',
                                type: 'number',
                                disabled: false
                              },
                              expressions: {
                                'props.isDisabled': (field: FormlyFieldConfig) => {
                                  if ((field.form?.value?.isUnlisted)) {
                                    field?.form?.get('flameSpread')?.setValue(null);
                                    return true;
                                  }
                                  else {
                                    return false;
                                  }
                                },
                              },
                              className: 'ncFlameSpread max-z-index position-relative',
                              hooks: {
                                onInit: (field) => {
                                  field.formControl.markAsTouched();
                                },
                              },
                            },
                            {
                              key: 'smokeDevelopment',
                              type: 'simple-input',
                              defaultValue: null,
                              resetOnHide: true,
                              props: {
                                label: 'Smoke Dev',
                                type: 'number',
                                disabled: false
                              },
                              expressions: {
                                'props.isDisabled': (field: FormlyFieldConfig) => {
                                  if ((field.form?.value?.isUnlisted)) {
                                    field?.form?.get('smokeDevelopment')?.setValue(null);
                                    return true;
                                  }
                                  else {
                                    return false;
                                  }
                                },
                              },
                              className: 'ncSmokeDev max-z-index position-relative',
                              hooks: {
                                onInit: (field) => {
                                  field.formControl.markAsTouched();
                                },
                              },
                            },
                            { template: '<span>or</span>', className: 'text-message' },
                            {
                              key: 'isUnlisted',
                              type: 'custom-checkbox',
                              resetOnHide: true,
                              props: {
                                label: 'Unlisted',
                              },
                              expressions: {
                                'props.isDisabled': (field: FormlyFieldConfig) => {
                                  if ((field.form?.value?.smokeDevelopment != null && field.form?.value?.smokeDevelopment != undefined && field.form?.value.smokeDevelopment != '') &&
                                    (field.form?.value?.flameSpread != null && field.form?.value?.flameSpread != undefined && field.form?.value?.flameSpread != '')) {
                                    field?.form?.get('isUnlisted')?.setValue(false);
                                    return true;
                                  }
                                  else {
                                    return false;
                                  }
                                },
                              },
                              className: 'insulationCheckbox',
                            },
                            {
                              key: 'isThermalBarrierPresent',
                              type: 'custom-checkbox',
                              resetOnHide: true,
                              props: {
                                label: 'Termal barrier',
                              },
                              className: 'insulationCheckbox',
                            },
                            { template: '<span></span>', className: 'new-row' },
                            {
                              key: 'floorFrom',
                              type: 'simple-select',
                              defaultValue: null,
                              resetOnHide: true,
                              className: 'floorFrom',
                              props: {
                                label: 'Floor',
                                type: 'number',
                                options: [{ value: '1', label: '1' }, { value: '2', label: '2' }, { value: '3', label: '3' }]
                              },
                              hooks: {
                                onInit: (field) => {
                                  field.formControl.markAsTouched();
                                },
                              },
                            },
                            { template: '<span>to</span>', className: 'textTo' },
                            {
                              key: 'floorTo',
                              type: 'simple-select',
                              defaultValue: null,
                              resetOnHide: true,
                              className: 'select-width-sm',
                              props: {
                                // label: 'Smoke Dev',
                                type: 'number',
                                options: [{ value: '1', label: '1' }, { value: '2', label: '2' }, { value: '3', label: '3' }]
                              },
                              hooks: {
                                onInit: (field) => {
                                  field.formControl.markAsTouched();
                                },
                              },
                            },
                            { template: '<span>has</span>', className: 'textTo' },
                            {
                              key: 'wallsPercentage',
                              type: 'simple-input',
                              // defaultValue: 1,
                              resetOnHide: true,
                              className: 'percentage-width max-z-index position-relative',
                              props: {
                                type: 'number',
                                unit: '%',
                              },
                              hooks: {
                                onInit: (field) => {
                                  field.formControl.markAsTouched();
                                },
                              },
                            },
                            { template: '<span>Walls area per floor with MCM</span>', className: 'textTo' },
                          ]
                        }
                      }
                    ],
                    expressions: {
                      hide: (field: FormlyFieldConfig) => !field?.options?.formState?.service._isV2
                    },
                  },
                  {
                    className: 'full-width max-z-index padding-right-0',
                    fieldGroupClassName: 'nccard-view margin-bottom',
                    fieldGroup: [
                      {
                        key: 'hasFireRetardantPaint',
                        type: 'custom-checkbox',
                        resetOnHide: true,
                        className: 'mcm-styles max-z-index position-relative',
                        props: {
                          label: 'Fire Retardant Paint',
                        },
                      },
                      {
                        fieldGroupClassName: 'nccard-child-view',
                        expressions: {
                          hide: (field: FormlyFieldConfig) => {
                            if (field.form?.value?.hasFireRetardantPaint) {
                              return false;
                            }
                            else {
                              field?.form?.get('paintFlameSpread')?.setValue(null);
                              field?.form?.get('paintSmokeDevelopment')?.setValue(null);
                              return true;
                            }
                          }
                        },
                        fieldGroup: [
                          {
                            key: 'paintFlameSpread',
                            type: 'simple-input',
                            defaultValue: null,
                            resetOnHide: true,
                            props: {
                              label: 'Flame Spread',
                              type: 'number',
                              isAsteriskNotRequired: true
                            },
                            className: 'ncFlameSpread max-z-index position-relative',
                            expressions: {
                              'props.required': (field: FormlyFieldConfig) => field.form?.value?.hasFireRetardantPaint ? true : false,
                            },
                            hooks: {
                              onInit: (field) => {
                                field.formControl.markAsTouched();
                              },
                            },
                          },
                          {
                            key: 'paintSmokeDevelopment',
                            type: 'simple-input',
                            defaultValue: null,
                            resetOnHide: true,
                            props: {
                              label: 'Smoke Dev',
                              type: 'number',
                              isAsteriskNotRequired: true
                            },
                            className: 'ncSmokeDev max-z-index position-relative',
                            expressions: {
                              'props.required': (field: FormlyFieldConfig) => field.form?.value?.hasFireRetardantPaint ? true : false,
                            },
                            hooks: {
                              onInit: (field) => {
                                field.formControl.markAsTouched();
                              },
                            },
                          },
                        ]
                      }
                    ]
                  },
                  { key: 'isCombustible' },
                  { key: 'combustibilityRating' },
                  { key: 'isMasonryVeneer' },
                  { key: 'isWoodIronClad' },
                  {
                    key: 'backendPresetValueforexistingRisk'  // stores preset value for existing risk when user changes it from Other to new value
                  }
                ],
                expressions: {
                  hide: (field) => {
                    if (field?.form?.get('constructionType').value === 'Combustible') {
                      return false;
                    }
                    return true;
                  }
                }
              },
              // Mass timber
              {
                fieldGroupClassName: 'formly-flex-container fire-resistive-fg mass-timber-padding',
                fieldGroup: [
                  {
                    key: 'massTimberFireResistive',
                    type: 'simple-select',
                    resetOnHide: true,
                    className: 'select-width-md',
                    props: {
                      label: 'Fire Resistive Rating',
                      defaultValue: null,
                      required: false,
                      options: [{ value: ' Not less than 2 hrs', label: ' Not less than 2 hrs' },
                      { value: 'Not less than 3 hrs', label: 'Not less than 3 hrs' },],
                      optionsAvailable: true
                    },
                    hooks: {
                      onInit: (field) => {
                        field.formControl.markAsTouched();
                      },
                    },
                  },
                  {
                    key: 'massTimberProtection',
                    type: 'simple-select',
                    resetOnHide: true,
                    className: 'select-width-md',
                    props: {
                      label: 'Protection',
                      defaultValue: null,
                      required: false,
                      options: [{ value: ' With Non - combustible Protection', label: ' With Non - combustible Protection' },
                      { value: 'Without Non - combustible Protection', label: 'Without Non - combustible Protection' },],
                      optionsAvailable: true
                    },
                    hooks: {
                      onInit: (field) => {
                        field.formControl.markAsTouched();
                      },
                    },
                  }
                ],
                expressions: {
                  hide: (field) => {
                    if (field.form.get('constructionType').value === 'Mass Timber') {
                      return false;
                    }
                    return true;
                  }
                }
              },
              {
                key: 'isWallAddedNewlyInEditSheet',//rename isNewArrayItem
                defaultValue: false,
              },
              {
                key: 'isDeleted',
                defaultValue: false,
              },
              {
                key: 'wallIdentifier',
              },
              {
                key: 'wallLevelValidator',
                validators: {
                  validation: [
                    { name: 'walls-level-validator' },]
                },
              }
            ],
          },
          validators: {
            wallsSectionValidation: {
              expression: (control: FormControl, field: FormlyFieldConfig) => {
                const wallsLevels = field?.formControl?.value;
                if (wallsLevels !== undefined && wallsLevels !== null && wallsLevels?.length < 1) {
                  return false;
                }
                else {
                  return true;
                }
              },
              message: 'At least one wall construction entry is required on the Risk Form.'
            },
          },
        },
      ],
    });
  }
}
