import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { ContactInformationService } from 'src/app/services/formly-builder/overview/contact-information.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Constants } from 'src/app/services/util-service/constants';

export class ContactInformationModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe = new Subject<void>();
  constructor(formlyBuilderService: FormlyBuilderService, contactService: ContactInformationService) {
    super({
      wrappers: ['simple-section-wrapper'],
      key: 'contactInformation',
      props: {
        label: 'Contact Information',
        isCollapsable: true,
        isCollapsed: false,
        isDisableable: true,
        className: 'padding-bottom',
      },
      fieldGroup: [
        {
          fieldGroupClassName: 'card-view',
          fieldGroup: [
            {
              key: 'contactName',
              type: 'simple-input',
              className: 'name-width',
              props: {
                label: 'Contact',
                maxLength: 100,
                onBlur: true,
                required: true,
                isAsteriskNotRequired: true,
              },
            },
            {
              key: 'contactPhone',
              type: 'simple-input',
              className: 'phone-width',
              props: {
                label: 'Phone Number',
                maxLength: 14,
                type: 'tel' ,
                required: true,  
                isAsteriskNotRequired: true,                           
              },
             
              validators: {
                validation: [{ name: 'phone-number-validator' }],
              },
            },
          ],
        },
        { className: 'new-row' },
        {
          fieldGroupClassName: 'card-view',
          fieldGroup: [
            {
              key: 'escortedByName',
              type: 'simple-input',
              className: 'name-width',
              props: {
                label: 'Escorted By',
                maxLength: 100,
                onBlur: true,
                required: true,
                isAsteriskNotRequired: true,
              },
            },
            {
              key: 'escortedByPhone',
              type: 'simple-input',
              className: 'phone-width',
              props: {
                // width: 157px,
                label: 'Phone Number',
                maxLength: 14,
                type: 'tel'
              },
              validators: {
                validation: [{ name: 'phone-number-validator' }],
              },

            },
          ],
        },
        { className: 'new-row' },
      ],
      hooks: {
        // onInit: (field?: FormlyFieldConfig) =>
        //   field.formControl.valueChanges.pipe(
        //     distinctUntilChanged(),
        //     tap((value) => formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_CONTACTS, value))
        //   ),
        onInit: (field: FormlyFieldConfig) => {
          field.formControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe),
          distinctUntilChanged(), debounceTime(Constants.riskFormDataSyncDelay))
            .subscribe(value => {
              if (!field.formControl.pristine) {
                const formlyService = field.options.formState.service;
                formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_CONTACTS, value);
              }
            });
        },
        onDestroy: () => {
          this.ngUnsubscribe.next();
          this.ngUnsubscribe.complete();
        }
      },
    });
  }
}
