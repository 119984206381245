/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyBuilderService } from '../formly-builder.service';
import { SectionEnum } from '../section.config';
import { WindLossHistoryDataModel } from 'src/app/models/formly-builder/formly/wind/wind-loss-hristory-data.model';
import { UtilService } from '../../util-service/util.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WindLossService extends SectionServiceAbstract {

  constructor(public formlyBuilderService: FormlyBuilderService,
    private utilService: UtilService) {
    super();
  }

  async updateData(model: any, riskReportData: any): Promise<any> {
    // Check if data has changed
    const hasChanged: boolean = WindLossHistoryDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      const windLossHistory = [];
      if (Array.isArray(model[ 'windReport' ]?.windLossHistories)) {
        model[ 'windReport' ]?.windLossHistories?.forEach((windLossData) => {
          const windLossHistoryData = WindLossHistoryDataModel.to(windLossData, riskReportData?.ReportIdentifier);
          if (windLossHistoryData) {
            windLossHistoryData.WindLossHistoryDateTime = this.utilService.convertDateToISOStringFormat(windLossHistoryData.WindLossHistoryDateTime);
          }
          windLossHistory.push(windLossHistoryData);
        });
      }
      // model[ 'windReport' ].windLossHistories?.forEach((windLossData) => {
      //   const windLossHistoryData = WindLossHistoryDataModel.to(windLossData, riskReportData?.ReportIdentifier);
      //   if (windLossHistoryData) {
      //     windLossHistoryData.WindLossHistoryDateTime = this.utilService.convertDateToISOStringFormat(windLossHistoryData.WindLossHistoryDateTime);
      //   }
      //   windLossHistory.push(windLossHistoryData);
      // });

      const windReportData = {
        ...riskReportData.WindReport,
        ...{ WindLossHistories: windLossHistory }
      };

      return { ...riskReportData, ...{ WindReport: windReportData } };
    } else {
      return undefined;
    }
  }


  loadData(riskReport: any[], fullData: any, isS3: boolean, model: any): any {
    return model;
  }

  async onApply(modalCtrl, field) {
    const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const riskReport = await formlyService.riskReport.updateSectionData(SectionEnum.FR_W_WIND_LOSS, false);
    const newModel = formlyService.windReportService.loadData(riskReport, riskReport, true, formlyService.riskReport.model);
    if (newModel) {
      formlyService.riskReport.model = { ...newModel };
    }
    modalCtrl.dismiss(null, 'save');
  }

  onClose(modalCtrl, field, initialModel) {
    // Call generic method
    // FormlyWrapperService.onClose(modalCtrl, field, initialModel);

    const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const reportData = formlyService.riskReport.report;
    const newModel = formlyService.windReportService.loadData(reportData, reportData, true, formlyService.riskReport.model);
    if (newModel) {
      formlyService.riskReport.model = { ...newModel };
    }
    formlyService.errorService.closePreviousToaster();
    modalCtrl.dismiss(null, 'cancel');
  }

  collapseField(field: FormlyFieldConfig) {
    if ((field.formControl?.value && field.formControl?.value?.length == 0) || (!field.formControl?.value[ 0 ]?.description && field.formControl?.value[ 0 ]?.windLossHistoryDateTime == null)) {
      field.props.isCollapsed = true;
    }
    field.formControl?.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if ((field.formControl?.value && field.formControl?.value?.length == 0) || (!field.formControl?.value[ 0 ]?.description && field.formControl?.value[ 0 ]?.windLossHistoryDateTime == null)) {
          field.props.isCollapsed = true;
        } else {
          field.props.isCollapsed = false;
        }
      });
  }
}
