import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';

/**
 * Class that represents the Formly Model for CombustibleConstruction
 */
export class EffectiveAreaDataModel {
    
    static sectionName = 'effectiveArea';


    largestFloorArea: number;
    otherFloorArea: number;
    percentOfOtherFloors: string; // number in Backend
    numberOfOtherFloors: number; // number in Backend
    totalEffectiveArea: number;
    isHeightMultiplierDoesNotApplied : boolean;

    static fromS3(reports:any):EffectiveAreaDataModel {
        const percentOfOtherfloor = reports.ConstructionClass === '' || reports.AboveGradeFloors == 1 ? null :  (Number(reports.ConstructionClass) === 1 || Number(reports.ConstructionClass) === 2 || Number(reports.ConstructionClass) === 3 || Number(reports.ConstructionClass) === 4 ) ? '50%' : null;
           
        return {
            largestFloorArea: reports?.SecondaryConstructions?.length > 0 ? reports?.SecondaryConstructions[ 0 ]?.LargestFloorArea : null,
            otherFloorArea: reports?.SecondaryConstructions?.length > 0 ? reports?.SecondaryConstructions[ 0 ]?.OtherFloorArea : null,
            percentOfOtherFloors: percentOfOtherfloor === null ? (reports?.SecondaryConstructions?.length > 0 ? ((reports?.SecondaryConstructions[ 0 ]?.PercentOfOtherFloors && reports?.AboveGradeFloors !== null && reports?.AboveGradeFloors !== '' && Number(reports?.AboveGradeFloors) > 1) ? reports?.SecondaryConstructions[ 0 ]?.PercentOfOtherFloors + '%' : null) : null) : percentOfOtherfloor,
            numberOfOtherFloors: reports?.SecondaryConstructions?.length > 0 ? (reports?.SecondaryConstructions[ 0 ]?.NumberOfOtherFloors ? reports?.SecondaryConstructions[ 0 ]?.NumberOfOtherFloors : null) : null,
            totalEffectiveArea: reports?.SecondaryConstructions?.length > 0 ? (reports?.SecondaryConstructions[ 0 ]?.TotalEffectiveArea ? reports?.SecondaryConstructions[ 0 ]?.TotalEffectiveArea : null) : null,
            isHeightMultiplierDoesNotApplied: reports?.SecondaryConstructions?.length > 0 ? (reports?.SecondaryConstructions[ 0 ]?.IsHeightMultiplierDoesNotApplied ? reports?.SecondaryConstructions[ 0 ]?.IsHeightMultiplierDoesNotApplied : null) : null,
        };
    }

    /**
     * 
     */
    static to(model: any) {
       return
    }

    /**
     * Check if the model has changed
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     * @returns 
     */
    static hasChanged(formlyModel: any, riskReportModel: any): boolean {
        return true;
      
      
    }
}