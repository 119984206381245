import { WallsConstructionDataModel } from './walls-construction-data.model';



export class WallsDataModel {
  static sectionName = 'walls';
  wallsArray: WallsConstructionDataModel[];

  static from(wall: WallsConstructionDataModel[]): WallsDataModel {
    return {
      wallsArray: wall,
    };
  }

  static fromS3(wall: WallsConstructionDataModel[]): WallsDataModel {
    return {
      wallsArray: wall,
    };
  }
    /**
   * Check if the model has changed
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   * @returns 
   */
    static hasChanged(formlyModel: any, riskReportModel: any): boolean {
      return true;
    }
    static to(model: any) {
      const wallsRrModel = [];
      model.wallsArray.forEach((wall) => {
        const wallData = WallsConstructionDataModel.to(wall);
        wallsRrModel.push(wallData);
      });
      return { Walls: wallsRrModel };
    }
}
