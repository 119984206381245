import { NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StorageBackend, Requestor } from '@openid/appauth';
import { AuthService, Browser } from 'ionic-appauth';
import { environment } from '../../../environments/environment';
import { App } from '@capacitor/app';
import { OneDriveAuthObserver } from 'src/app/services/auth-service/onedrive-auth-observer';

export const authFactory = (platform: Platform, ngZone: NgZone,
                            requestor: Requestor, browser: Browser, storage: StorageBackend,
                            oneDriveAuthObserver:OneDriveAuthObserver) => {

  const authService = new AuthService(browser, storage, requestor);

  if (platform.is('capacitor')) {
    environment.auth_config.scopes += ' offline_access';
    environment.auth_config.redirect_url = `${environment.scheme}:/callback`;
    environment.auth_config.end_session_redirect_url = `${environment.scheme}:/logout`;
  }
  authService.authConfig = environment.auth_config;

  if (platform.is('capacitor')) {
    App.addListener('appUrlOpen', (data: any) => {
      console.log("Auth Tester - Add listener", data);
      if (data.url !== undefined) {
        ngZone.run(() => {
          if ((data.url).indexOf('sso://localhost') > 0 && (data.url).indexOf('access_token') > 0) {
            const params = new URLSearchParams(data.url.split('#')[1]);
            storage.setItem('msAuthAccessToken', params.get("access_token"));
            oneDriveAuthObserver.setMSAuthSubject(params.get("access_token"));            
          }
          else if ((data.url).indexOf(authService.authConfig.redirect_url) === 0) {
            authService.authorizationCallback(data.url);
          } else {
            authService.endSessionCallback();
          }
        });
      }
    });
  }

  return authService;
};
