import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { YearBuiltDataModel } from 'src/app/models/formly-builder/formly/year-built-data.model';
import { FireSprinklerDataModel } from 'src/app/models/formly-builder/formly/fire-sprinkler-data.model';
import { BuildingInformationDataModel } from 'src/app/models/formly-builder/formly/building-information-data.model ';
import { BuildingNameDataModel } from 'src/app/models/formly-builder/formly/building-name-data.model';
import { BehaviorSubject } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable({
  providedIn: 'root',
})
export class FormlyBuildingInformationService extends SectionServiceAbstract {
  // TODO: MGSG: Check why this complexity!
  riskreportData;
  public riskreport = new BehaviorSubject('');

  constructor() {
    super();
  }

  async updateData(model: any, riskReportData: any) {
    // Check if data has changed
    const hasChanged: boolean = BuildingInformationDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      const mergedData = {
        ...riskReportData,
        ...BuildingInformationDataModel.to(model),
        ...{ ReportIdentifier: model.reportIdentifier }
      };
      return mergedData;
    } else {
      return undefined;
    }
  }

  loadData(reportInfo: any, isS3: boolean, model: any): any {
    this.riskreportData = reportInfo;

    let buildingInformation: BuildingInformationDataModel;
    let evidenceOfFireSprinkler: FireSprinklerDataModel;

    if (isS3) {
      evidenceOfFireSprinkler = FireSprinklerDataModel.fromS3(reportInfo);
      buildingInformation = BuildingInformationDataModel.fromS3(reportInfo);
    }

    const loadedData = {
      ...model,
      ...evidenceOfFireSprinkler,
      ...{
        reportIdentifier: reportInfo.ReportIdentifier ? reportInfo.ReportIdentifier : '',
      },
      ...{
        buildingInformation: buildingInformation
      }
    };
    return loadedData;
  }

  /**
   * Update the addition dates whenever the year built is changed, 
   * so validations will be reexecuted.
   * @param yearBuiltField 
   */
  updateAdditionDates(yearBuiltField: FormlyFieldConfig): void {
    const additionDates = yearBuiltField?.parent?.parent?.fieldGroup[3]?.fieldGroup[0].fieldGroup;
    additionDates.forEach((addition) => { 
      const newAddition = addition?.formControl?.value;
      addition.formControl.patchValue(newAddition);
    });
  }

  getNumberOfStories(field: FormlyFieldConfig) {
    // Default to 1 on new risks
    const formlyService = field?.options?.formState?.service;
    const constrEntries = field?.options?.formState?.service?.riskReport?.model?.floorsAndRoofs?.constructionEntries;
    if (formlyService?.isNewRisk()) {
      if (constrEntries?.floorsAndRoofsLevels?.length > 0) {
        // Use getMaxLevel vs getMaxLevelSimple
        const maxLevel = formlyService.formlyFloorsRoofService.getMaxLevel(constrEntries?.floorsAndRoofsLevels, 'FLOO');
        field?.formControl?.setValue(maxLevel);
      } else {
        field?.formControl?.setValue(1);
      }
    } else if (formlyService && constrEntries) {
      // Refresh from Floors & Roof otherwise
      const maxLevel = formlyService.formlyFloorsRoofService.getMaxLevel(constrEntries?.floorsAndRoofsLevels, 'FLOO');
      field?.formControl?.setValue(maxLevel);
    } else {
      field?.formControl?.setValue(1);
    }
  }

  isYearBuiltDisabled(field: FormlyFieldConfig): boolean {
    // Year Built
    const formlyService = field?.options?.formState?.service;
    const yearBuilt = field?.options?.formState?.service?.riskReport?.report?.YearBuilt;
    if (formlyService) {
      let isYearBuiltPopulated = formlyService.isValidNumber(yearBuilt);
      const fieldIsOverride = field.form?.get('isYearBuiltFrOverride');
      const isOverriden = (fieldIsOverride?.value === true);

      if (isYearBuiltPopulated && yearBuilt === 0) {
        isYearBuiltPopulated = false;         // Year Built of 0 is considered to be invalid
      }      
      if (isYearBuiltPopulated) {
        if (isOverriden) {
          return false;     // 2.1. Year Built provided, overriden: Year Built enabled for editing
        } else {
          return true;      // 1. Year Built provided, not overriden: year built disabled
        }
      } else {
        return false;       // 4. Year built not provided, field is blank and enabled
      }
    }
    return true;
  }

  isYearOverrideDisabled(field: FormlyFieldConfig): boolean {
    // Override
    const formlyService = field?.options?.formState?.service;
    const yearBuilt = field?.options?.formState?.service?.riskReport?.report?.YearBuilt;
    let isYearBuiltPopulated = formlyService.isValidNumber(field?.options?.formState?.rr?.YearBuilt);

    if (isYearBuiltPopulated && yearBuilt === 0) {
      isYearBuiltPopulated = false;         // Year Built of 0 is considered to be invalid
    }

    // TODO : Prevent endless call of update section data method on value change making emit event false
    if (!isYearBuiltPopulated) {
      field?.formControl?.patchValue(true,{emitEvent:false});       // 5. No Year Built: Override is true and disabled
      return true;
    } else {
      return false;       // 2. Year Built provided: Override is enabled. 2.1. If overriden, year built enabled for editing and 2.2. approximate is enabled
    }
  }

  isApproximateYearBuiltDisabled(field: FormlyFieldConfig): boolean {
    // Approximate
    const formlyService = field?.options?.formState?.service;   
    const yearBuilt = field?.options?.formState?.service?.riskReport?.report?.YearBuilt;    
    let isYearBuiltPopulated = formlyService.isValidNumber(field?.options?.formState?.rr?.YearBuilt);
    const isOverriden = (field.form?.get('isYearBuiltFrOverride')?.value === true);

    if (isYearBuiltPopulated && yearBuilt === 0) {
      isYearBuiltPopulated = false;         // Year Built of 0 is considered to be invalid
    }
    if (!isYearBuiltPopulated) {
      // field?.formControl?.patchValue(false);      // This causes the checkbox to be uncheckable...
      return false;                    // 6. No Year Built: Approximate is false and enabled
    } else if (isOverriden === true) {
        return false;         // 2.2. Year Built provided, overriden => approximate is enabled
    } else {
        return true;          // 3. Year Built provided: Approximate is disabled unless overriden
    }
  }
}
