import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class BuildingConditionExcessSingleStoryDataModel {
  static sectionName = 'buildingCondition';

    buildingConditionExcessSingleStoryId: string;
    reportId: string;
    areaDescription?: string;
    excessStoryHeight?: number;
    excessStoryArea?: number;
    excessStoryAreaComment?: string;

  static fromS3(bcExcessSingleStoryData: any): BuildingConditionExcessSingleStoryDataModel {

    return {
        buildingConditionExcessSingleStoryId: bcExcessSingleStoryData.BuildingConditionExcessSingleStoryId,
        reportId: bcExcessSingleStoryData.ReportId,
        areaDescription: bcExcessSingleStoryData.AreaDescription,
        excessStoryHeight: bcExcessSingleStoryData.ExcessStoryHeight,
        excessStoryArea: bcExcessSingleStoryData.ExcessStoryArea,
        excessStoryAreaComment: bcExcessSingleStoryData.ExcessStoryAreaComment
    };
  }

  static to(model: any, sectionEnum?: SectionEnum) {
    return{
        BuildingConditionExcessSingleStoryId: model.buildingConditionExcessSingleStoryId,
        ReportId: model.reportId,
        AreaDescription: model.areaDescription,
        ExcessStoryHeight: model?.excessStoryHeight ? Number(model?.excessStoryHeight) : model?.excessStoryHeight,
        ExcessStoryArea: model?.excessStoryArea ? Number(model?.excessStoryArea) : model?.excessStoryArea,
        ExcessStoryAreaComment: model.excessStoryAreaComment
    }
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
    return false;

  }
}
