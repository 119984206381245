import { Injectable } from '@angular/core';
import { FormlyBuilderService } from '../../formly-builder.service';
import { SectionEnum } from '../../section.config';
import { UtilService } from 'src/app/services/util-service/util.service';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { HydraulicDataDesignDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/hydraulic-data-design-data.model';

@Injectable({
  providedIn: 'root',
})
export class DataDesignTableService extends SectionServiceAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService, private utilService: UtilService) {
    super();
  }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    let dataTable = [];
    if ('hydraulicDataDesign' in model.sprinklerReport.asgrWaterSupply) {
      const designData = this.setDataTableValue( model.sprinklerReport.asgrWaterSupply?.hydraulicDataDesign);
      dataTable = HydraulicDataDesignDataModel.to(designData);
    }
    const sprinklerData = {
      ...riskReportData.SprinklerReport,
      ...{ HydraulicDesignData:  dataTable },
    };
    return { ...riskReportData, ...{ SprinklerReport: sprinklerData } };
  }

  loadData(sectionData: any[], riskReportData: any, isDataInS3Format: boolean, model: any) {
    return null;
  }

  async onApply(modalCtrl, field) {
    const riskReport = field.options.formState.riskReportService.updateSectionData(
      SectionEnum.FR_S_WS_DESIGNDATA,
      false,
      null,
      DataDesignTableService
    );
    modalCtrl.dismiss(field, 'save');
  }

  onClose(modalCtrl, field,initialModel) {
    field.formControl.reset();
    field.options.formState.service.riskReport.model.sprinklerReport.asgrWaterSupply.hydraulicDataDesign= initialModel;
    field.options.formState.service.riskReport.model = {
      ...field.options.formState.service.riskReport.model,
    };
    field.options.formState.service.errorService.closePreviousToaster();
    modalCtrl.dismiss(field, 'cancel');
  }  
  
  hasDuplicateWithDifferentFields(field): boolean {
    let allValues;

    if (typeof field.formControl.value === 'string') {
      allValues = field?.parent?.form?.value;
    } else {
      allValues = field?.formControl?.value;
    }
    const systemNumbers = {};
    for (const obj of allValues) {
      if (systemNumbers[obj.systemNumber]) {
        // Found a duplicate systemNumber
        const existingObjs = systemNumbers[obj.systemNumber];
        for (const existingObj of existingObjs) {
          if(!existingObj.designArea) existingObj.designArea= null;
          if(!existingObj.designDensity) existingObj.designDensity= null;
          if(!existingObj.waterDemandGpm) existingObj.waterDemandGpm= null;
          if(!existingObj.waterDemandPsi) existingObj.waterDemandPsi= null;
          if (
            existingObj.areaDescription !== obj.areaDescription ||
            existingObj.designStandard !== obj.designStandard ||
            Number(existingObj.areaSquareFeet) !== Number(obj.areaSquareFeet) ||
            Number(existingObj.designArea) !== Number(obj.designArea) ||
            Number(existingObj.designDensity) !== Number(obj.designDensity) ||
            // Number(existingObj.systemNumber) !== Number(obj.systemNumber) ||
            Number(existingObj.waterDemandGpm) !== Number(obj.waterDemandGpm) ||
            Number(existingObj.waterDemandPsi) !== Number(obj.waterDemandPsi)          
          ) {
            // Other fields are different, continue checking
            continue;
          } else {
            // Other fields are the same, return true
            return true;
          }
        }
        // If no match found, add the object to existingObjs array
        existingObjs.push(obj);
      } else {
        // Store the object with its systemNumber
        systemNumbers[obj.systemNumber] = [obj];
      }
    }
    // No duplicate systemNumber with different fields found
    return false;
  }
   
  setDataTableValue(dataTable) {
    let dataDesignArray = [];
    if (dataTable !== null && dataTable) {
      if (dataTable.length > 0) {
        dataDesignArray = dataTable.map((item) => {
          item.reportId = this.formlyBuilderService.riskReport.report.SprinklerReport?.ReportId;
          item.hydraulicDesignDataId = item?.sprinklerId !== null && item?.sprinklerId ? item?.sprinklerId : this.utilService.uuid();         
          return item;

        })
        dataDesignArray = dataDesignArray.filter((item) => {
          const isAllNull =
            item.systemNumber === null &&
            item.designStandard === null &&
            item.areaSquareFeet === null &&
            item.areaDescription === null &&
            item.designDensity === null &&
            item.designArea === null &&
            item.waterDemandGpm === null &&
            item.waterDemandPsi === null 
            
          if (!isAllNull) {
            return item;
          }
        });
      }
    }
    return dataDesignArray;
  }
}

