import { Injectable } from '@angular/core';
import { Photo } from '@capacitor/camera';
import { Directory, Encoding, Filesystem, ReadFileResult } from '@capacitor/filesystem';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { VeriskLog } from '../util-service/verisk-log';
import { UtilService } from '../util-service/util.service';
import { AssetType, ConfigModel } from 'src/app/enums/config-model.enum';
import { S3SyncService } from '../s3-sync-service/s3-sync.service';
import { Storage as AmplifyStorage } from 'aws-amplify';
import { OrdersService } from '../orders/orders.service';
import { Injector } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private orderservice;
  constructor(
    private utilService: UtilService,
    private syncService: S3SyncService,
    private injector: Injector
  ) { }

  /*
   * save data to local storage and optionally save to s3
   * @param isSync - when true, it will sync the data to s3, default is false
   */
  public saveOrderJSONToLocal(data: any, isSync: boolean = false): Promise<any> {
    this.orderservice = this.injector.get(OrdersService);
    return new Promise((resolve, reject) => {
      const rfo: any = {};
      rfo.directory = Directory.Documents;
      rfo.path = `${ConfigModel.Order}/${data.OrderIdFullOrderNumber}/order.json`;
      rfo.encoding = Encoding.UTF8;
      rfo.recursive = false;
      rfo.data = JSON.stringify(data);

      Filesystem.writeFile(rfo).then(
        () => {
          this.orderservice.fileSavedToLocal.next([
            {
              sync: isSync,
              model: ConfigModel.Order,
              identifier: data.OrderIdFullOrderNumber,
            },
          ]);
          let veriskLog = new VeriskLog(
            'Updating local copy',
            'INFO',
            this.saveOrderJSONToLocal.name,
            LocalStorageService.name,
            data.OrderIdFullOrderNumber
          );
          this.utilService.addLog(veriskLog);
          resolve(data);
        },
        (e) => {
          let veriskLog = new VeriskLog(
            'Updating local copy failed',
            'ERROR',
            this.saveOrderJSONToLocal.name,
            LocalStorageService.name,
            data.OrderIdFullOrderNumber
          );
          this.utilService.addLog(veriskLog);
          reject('LOCAL SAVE FAILED [' + e.message + ']');
        }
      );
    });
  }

  public savePhotoToLocal(
    model: string,
    identifier: string,
    photo: Photo,
    name: string,
    folder: string = 'photos'
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      Filesystem.writeFile({
        path: `${model}/${identifier}/${folder}/${name}.${photo.format}`,
        data: photo.dataUrl,
        directory: Directory.Documents,
        recursive: true,
      }).then(
        () => {
          let veriskLog = new VeriskLog(
            'Updating local photo copy',
            'INFO',
            this.savePhotoToLocal.name,
            LocalStorageService.name,
            identifier
          );
          this.utilService.addLog(veriskLog);
          resolve(true);
        },
        (e) => {
          let veriskLog = new VeriskLog(
            'Updating local photo copy failed',
            'ERROR',
            this.savePhotoToLocal.name,
            LocalStorageService.name,
            identifier
          );
          this.utilService.addLog(veriskLog);
          reject('LOCAL PHOTOS SAVE FAILED [' + e.message + ']');
        }
      );
    });
  }

  public saveFormOrRiskReportToLocal(
    data: any,
    model: string,
    identifier: string,
    assetType: AssetType,
    assetIdentifier: string,
    isSync: boolean = false,
    formHtmlContent: any = '',
  ): Promise<any> {
    this.orderservice = this.injector.get(OrdersService);
    return new Promise((resolve, reject) => {
      const rfo: any = {};
      rfo.directory = Directory.Documents;
      rfo.path = model === 'RiskReport' ? `${model}/${identifier}/${identifier}.json` :
        `${model}/${identifier}/${assetType}/${assetIdentifier}/${assetIdentifier}.json`;
      rfo.encoding = Encoding.UTF8;
      rfo.recursive = true;
      rfo.data = JSON.stringify(data);
      
      if(environment.isUWFormRelease && assetType == AssetType.Forms){
       const htmlFileSaveOptions: any= {};
       htmlFileSaveOptions.path = `${model}/${identifier}/${assetType}/${assetIdentifier}/${assetIdentifier}.html`;
       htmlFileSaveOptions.data = formHtmlContent;
       htmlFileSaveOptions.directory = Directory.Documents;
       htmlFileSaveOptions.encoding = Encoding.UTF8;
       htmlFileSaveOptions.recursive = true;
        
       Filesystem.writeFile(htmlFileSaveOptions).then(() =>{
          console.log('html form Saved successfully!');
        }, (e) => {
          console.log('html form Save error: '+e)
        });
      }
     
      Filesystem.writeFile(rfo).then(
        () => {
          if (model !== 'RiskReport') {
            this.orderservice.fileSavedToLocal.next([
              {
                sync: isSync,
                model: ConfigModel.Order,
                identifier: identifier,
                assetType: assetType,
                assetIdentifier: assetIdentifier,
              },
            ]);
          }
          let veriskLog = new VeriskLog(
            'Updating local copy',
            'INFO',
            this.saveFormOrRiskReportToLocal.name,
            LocalStorageService.name,
            identifier
          );
          this.utilService.addLog(veriskLog);
          resolve(data);
        },
        (e) => {
          let veriskLog = new VeriskLog(
            'Updating local copy failed',
            'ERROR',
            this.saveFormOrRiskReportToLocal.name,
            LocalStorageService.name,
            identifier
          );
          this.utilService.addLog(veriskLog);
          reject('LOCAL SAVE FAILED [' + e.message + ']');
        }
      );
    });
  }

  public getLocalDataByIdentifier(model: string, identifier: string): Observable<ReadFileResult> {
    const rfo: any = {};
    const fileName = model === ConfigModel.Order ? 'order' : identifier;

    rfo.directory = Directory.Documents;
    rfo.path = model + '/' + identifier + '/' + fileName + '.json';
    rfo.encoding = Encoding.UTF8;

    return from(Filesystem.readFile(rfo));
  }

  public getListByIdentifier(model: any, identifier: string, listIdentifier: AssetType): Observable<any[]> {
    return this.getLocalDataByIdentifier(model, identifier).pipe(
      map((file) => {
        const data = JSON.parse(file.data);
        let list;

        switch (listIdentifier) {
          case AssetType.Photos:
            list = (model === ConfigModel.RiskReport ? data.ReportPhotos : data.Photos) || [];
            break;
          case AssetType.Documents:
            list = data.Attachments || [];
            break;
          case AssetType.CustomerAssets:
            list = data.CustomerAssets || [];
            break;
          case AssetType.RiskReport:
            list = data.RiskList || [];
            break;

          case AssetType.Forms:
            var packForms = [];
            data.BillingDetails?.ServiceBillingInfos?.forEach(serviceBillingInfo => {
              if(serviceBillingInfo.Service?.PackForms && serviceBillingInfo.Service?.PackForms.length > 0){
              packForms = packForms.concat(serviceBillingInfo.Service?.PackForms)
              }
            });
            list = packForms;
            break;
        }

        return list;
      })
    );
  }
  public getFormOrReportListByIdentifier(model: any, assetType: AssetType, modelIdentifier: string): Observable<any[]> {
    return this.getListByIdentifier(model, modelIdentifier, assetType).pipe(
      map((data) => {
        return data;
      })
    );
  }

  //This method works for folder on ipad
  checkFileExists(path: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      Filesystem.stat({
        path: path,
        directory: Directory.Documents,
        
      })
        .then((_) => {
          resolve(true);
        })
        .catch((_) => {
            resolve(false);
        });
    })
  }
  public getLocalPhotoByIdentifier(
    model: string,
    identifier: string,
    photoIdentifier: string
  ): Observable<ReadFileResult> {
    const rfo: any = {};

    rfo.directory = Directory.Documents;
    rfo.path = `${model}/${identifier}/${AssetType.Photos}/${photoIdentifier}.jpeg`;

    return from(Filesystem.readFile(rfo));
  }

  public getLocalAttachmentByIdentifier(
    model: any,
    identifier: string,
    attachmentIdentifier: string,
    fileExtension: string,
    customerAssets = false
  ): Observable<ReadFileResult> {
    const rfo: any = {};
    const documentType = customerAssets ? AssetType.CustomerAssets : AssetType.Documents;

    rfo.directory = Directory.Documents;
    rfo.path = `${model}/${identifier}/${documentType}/${attachmentIdentifier}.${fileExtension}`;
    rfo.encoding = Encoding.UTF8;

    return from(Filesystem.readFile(rfo));
  }

  public getLocalHtmlForm(
    model: any,
    identifier: string,
    attachmentIdentifier: string
  ): Observable<ReadFileResult> {
    const rfo: any = {};

    rfo.directory = Directory.Documents;
    rfo.path = `${model}/${identifier}/${AssetType.Forms}/${attachmentIdentifier}/${attachmentIdentifier}.html`;
    rfo.encoding = Encoding.UTF8;

    return from(Filesystem.readFile(rfo));
  }

  public getLocalRiskReportAttachmentByIdentifier(
    model: any,
    identifier: string,
    riskReportIdentifier: string,
    fileName: string,
    fileExtension: string,
    sprinklerattachment = false
  ): Observable<ReadFileResult> {
    const rfo: any = {};
    const documentType = sprinklerattachment ? AssetType.SprinklerReportAttachments : AssetType.RiskReportAttachments;

    rfo.directory = Directory.Documents;
    rfo.path = `${model}/${identifier}/${AssetType.RiskReport}/${riskReportIdentifier}/${documentType}/${fileName}.${fileExtension}`;
    if (fileExtension == 'json') {
      rfo.encoding = Encoding.UTF8;
    }
    return from(Filesystem.readFile(rfo));
  }

  public getLocalRiskOrFormByIdentifier(
    model: any,
    identifier: string,
    assetType: AssetType,
    formOrRiskIdentifier: string
  ): Observable<ReadFileResult> {
    const rfo: any = {};

    rfo.directory = Directory.Documents;
    rfo.path = `${model}/${identifier}/${assetType}/${formOrRiskIdentifier}/${formOrRiskIdentifier}.json`;
    rfo.encoding = Encoding.UTF8;

    return from(Filesystem.readFile(rfo));
  }

  getLocalFDFormByIdentifier(model: any, identifier: string, assetType: AssetType,
    formOrRiskIdentifier: string): Observable<ReadFileResult> {
    const rfo: any = {};
    rfo.directory = Directory.Documents;
    rfo.path = `${model}/${identifier}/${assetType}/${formOrRiskIdentifier}/${formOrRiskIdentifier}.fd.json`;
    rfo.encoding = Encoding.UTF8;
    return from(Filesystem.readFile(rfo));
  }

  public async updateLocalfile(localpath: string, file): Promise<boolean> {
    return new Promise((resolve) => {
      Filesystem.writeFile({
        path: localpath,
        data: file,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
        recursive: true,
      }).then(
        (data: any) => {
          resolve(data);
        },
        (e) => {
          let veriskLog = new VeriskLog(
            'Filesystem write ERROR',
            'ERROR',
            this.updateLocalfile.name,
            LocalStorageService.name,
            e
          );
          this.utilService.addLog(veriskLog);
          resolve(false);
        }
      );
    });
  }

  readLocalFile(filePath: any, isBinary = false): Promise<any> {
    const clientPath: any = {};
    clientPath.directory = Directory.Documents;
    clientPath.path = filePath;
    if (!isBinary) {
      clientPath.encoding = Encoding.UTF8;
    }
    return new Promise(async (resolve, reject) => {
      Filesystem.readFile(clientPath).then(
        (file: any) => {
          let fileData = (file as ReadFileResult).data;
          resolve(fileData);
        },
        (e) => {
          let veriskLog = new VeriskLog('Filesystem readFile error', 'ERROR', filePath, e);
          this.utilService.addLog(veriskLog);
          reject(e);
        }
      );
    });
  }

  downloadPdfFromS3(key: string): Observable<Blob> {
    return from(AmplifyStorage.get(key, { download: true, cacheControl: 'no-cache' })).pipe(
      map((data: any) => new Blob([data.Body], { type: 'application/pdf' }))
    );
  }

  // downloads pdf file from s3 to local
  downloadAndSavePdfFromS3ToLocal(folder: string, serverpath: string, localFilePath: string) {
    return new Promise(async (resolve, reject) => {
      this.downloadPdfFromS3(serverpath).subscribe((storageResult) => {
        this.syncService.checkAndCreateDir(folder).then(async (_) => {
          let doc64 = await this.utilService.convertBlobToBase64(storageResult as Blob);
          this.updateLocalfile(localFilePath, doc64).then(() => {
            resolve(doc64);
          }),
            (e: any) => {
              reject('downloadAndSavePdfFromS3ToLocal - LOCAL SAVE FAILED [' + localFilePath + '] [' + e.message + ']');
            }
        });
      },
        (error) => {
          if (error.name === 'NoSuchKey') {
            reject(`${serverpath} not found in s3!`);
          }
        }
      );
    });
  }

  public async listFilesInFolder(folderPath: string): Promise<string[]> {
    try {
      const result = await Filesystem.readdir({
        path: folderPath,
        directory: Directory.Documents,
      });
      const fileNames = result.files.map((fileInfo) => fileInfo.name);
      return fileNames;
    } catch (error) {
      console.log('Error getting file list: ', error);
      return null;
    }
  }

  public async deleteLocalAttachmentByIdentifier(
    model: any,
    identifier: string,
    attachmentIdentifier: string,
    fileExtension: string,
    customerAssets = false
  ) {
    const rfo: any = {};
    const documentType = customerAssets ? AssetType.CustomerAssets : AssetType.Documents;
    rfo.directory = Directory.Documents;
    rfo.path = `${model}/${identifier}/${documentType}/${attachmentIdentifier}.${fileExtension}`;
    await Filesystem.deleteFile(rfo);
  }

  public async deleteLocalFile(path: any) {
    const rfo: any = {};
    rfo.directory = Directory.Documents;
    rfo.path = path;
    await Filesystem.deleteFile(rfo);
  }

  public async deleteRiskReport(
    model: ConfigModel,
    modelIdentifier: string,
    assetType: AssetType,
    formOrRiskIdentifier: string
  ) {
    const rfo: any = {};
    rfo.directory = Directory.Documents;
    rfo.path = `${model}/${modelIdentifier}/${assetType}/${formOrRiskIdentifier}/${formOrRiskIdentifier}.json`;

    await Filesystem.deleteFile(rfo);
  }

  checkAndCreateDir(path: string): Promise<boolean> {
    return Filesystem.readdir({
      path: path,
      directory: Directory.Documents,
    })
      .then((_) => {
        return true;
      })
      .catch((_) => {
        return Filesystem.mkdir({
          path: path,
          directory: Directory.Documents,
          recursive: true,
        }).then((_) => {
          return true;
        });
      });
  }

  /**
 * checkDirExist - used to check if directory exists or not, Does not work on Ios Capacitor Use CheckFileExists instead
 * @param path
 * @returns
 */
  checkDirExist(path: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      Filesystem.readdir({
        path: path,
        directory: Directory.Documents,
      })
        .then((_) => {
          resolve(true);
        })
        .catch((_) => {
            resolve(false);
        });
    })
  }

  public async updateLocalfileOneDrive(localpath: string, file,): Promise<boolean> {
    return new Promise((resolve) => {
      Filesystem.writeFile({
        path: localpath,
        data: file,
        directory: Directory.Documents,
    // encoding: Encoding.UTF8, 
        recursive: true,
      }).then(
        (data: any) => {
          resolve(data);
        },
        (e) => {
          let veriskLog = new VeriskLog(
            'Filesystem write ERROR',
            'ERROR',
            this.updateLocalfile.name,
            LocalStorageService.name,
            e
          );
          this.utilService.addLog(veriskLog);
          resolve(false);
        }
      );
    });
  }
}
