import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { RiskReportService } from 'src/app/services/formly-builder/risk-report.service';
import { Constants } from 'src/app/services/util-service/constants';
import { maxLengthForNumber, numberCheck } from 'src/app/validators/formly-builder/validators';

export class InternalSprinklerPipeConditionsSectionModel extends FormlySectionModelAbstract {
    constructor(private riskReportService: RiskReportService) {
        super({
            key: 'internalSprinklerPipeConditions',
            type: 'fr-common-custom-grid-view',
            className: 'internal-sprinkler-pipe-container',
            wrappers: ['simple-section-wrapper'],
            props: {
                // Props for simple section wrapper
                className: 'padding-bottom',
        
                // Props for fr-common-custom-grid-view
                label: 'Internal Pipe Exam',
                isCollapsable: true,
                isCollapsed: false,
                actionButtons: [{ section: 'systemTesting', action: 'openArraySheetAdd', label: 'Add', isDisabled: false }],
                gridSection: 'internalSprinklerPipeConditions',
                gridColConfig: [
                  { label: 'System', propertyPath: 'systemNumber', className: 'common-grid-view-width-100' },
                  { label: 'Inspection Date', propertyPath: 'inspectionDate', isDate: true, className: 'common-grid-view-width-145' },
                  { label: 'Results', propertyPath: 'internalExamResults', className: 'common-grid-view-width-115' },
                ],
        
                // Props for edit sheet
                title: 'System Testing',
                subTitle: 'Internal Pipe Conditions',
                isAddIdentifier: true,
                identifierKey: 'internalSprinklerPipeConditionId',
                reportIdentifierKey: 'reportId',
                commentFieldKey:'notes',
                reportName: 'SprinklerReport',
                swipeActionDetails:[{ action: 'comment', styleName: 'commentIcon'}],
                
                sheetButtons: [
                  {
                    section: 'internalPipeConditions',
                    action: 'onClose',
                    label: 'Close',
                    service: 'internalSprinklerPipeConditionsService',
                    className: 'close-btn medium-btn',
                  },
                  {
                    section: 'internalPipeConditions',
                    action: 'onApply',
                    label: 'Apply',
                    service: 'internalSprinklerPipeConditionsService',
                    className: 'apply-btn large-btn',
                    role: 'apply'
                  }
                ],
              },
              expressions: {
                'props.actionButtons[0].label': (field: FormlyFieldConfig) => field?.model?.length ? 'Edit' : Constants.btnText,
                'props.actionButtons[0].action': (field: FormlyFieldConfig) => field?.model?.length ? 'openArraySheetEdit' : 'openArraySheetAdd'
              },
              fieldArray: {
                fieldGroupClassName: 'formly-flex-container internal-pipe-condition-container',
                wrappers: ['error-info-wrapper'],
                fieldGroup: [
                  {
                    key: 'systemNumber',
                    type: 'simple-input',
                    className: 'system-number-input ss-input-padding',
                    props: {
                      label: 'System',
                      required: true,
                      isAsteriskNotRequired: true,
                      maxLength: 75,
                    },
                  },
                  {
                    key: 'areaAffected',
                    type: 'simple-input',
                    className: 'area-affected-input ss-input-padding',
                    props: {
                      label: 'Affected Area',
                      type: 'number',
                      required: true,
                      isAsteriskNotRequired: true,
                      unit: 'sq.ft.'
                    },
                  },
                  {
                    key: 'internalExamResults',
                    type: 'simple-input',
                    className: 'area-affected-input ss-input-padding',
                    props: {
                      label: 'Results',
                      required: true,
                      isAsteriskNotRequired: true,
                      maxLength: 20
                    },
                  },
                  {
                    key: 'actionsTaken',
                    type: 'simple-input',
                    className: 'action-taken-input ss-input-padding',
                    props: {
                      label: 'Actions Taken',
                      required: true,
                      isAsteriskNotRequired: true,
                      maxLength: 100
                    },
                  },
                  {
                    key: 'initialServiceDate',
                    type: 'rf-date-picker',
                    className:'date-width',
                    props: {
                      label: 'Initial Service Date',
                      displayFormat: 'MM/DD/YY',
                      required: true,
                      isAsteriskNotRequired: true,
                      datePickerId: 'initialServiceDate'
                    },
                    hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          if( field?.parent && field?.parent['index']) {
                            field.props.datePickerId = 'initialServiceDate' + (field?.parent['index']+1) ;
                          }
                        }
                      },
                    validators: {
                        validation: [{ name: 'future-date-validator', options: { msg: 'Test Date Cannot be in the future.' } }]
                      }
                  },
                  {
                    key: 'inspectionDate',
                    type: 'rf-date-picker',
                    className:'date-width',
                    props: {
                      label: 'Inspection Date ',
                      displayFormat: 'MM/DD/YY',
                      required: true,
                      isAsteriskNotRequired: true,
                      datePickerId: 'inspectionDate'
                    },
                    hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          if( field?.parent && field?.parent['index']) {
                            field.props.datePickerId = 'inspectionDate' + (field?.parent['index']+1) ;
                          }
                        }
                      },
                    validators: {
                        validation: [{ name: 'future-date-validator', options: { msg: 'Test Date Cannot be in the future.' } }]
                      }
                  },
                  {
                    key: 'testConductedBy',
                    type: 'simple-input',
                    className: 'test-conducted-input ss-input-padding',
                    props: {
                      label: 'Conducted by',
                      required: true,
                      isAsteriskNotRequired: true,
                      maxLength: 100
                    },
                  },
                  {
                    key:'notes',
                    props:{
                      commentActionKey:'notes',
                      maxLength: 300,
                    }
                  },
                      
                ]
              },
        });
    }
}