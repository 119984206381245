import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class WaterSupplyCommentsDataModel {
    static sectionName = 'waterSupply';

    waterSupplyCommentId: string;
    reportId: string;
    comment: string;

    static fromS3(wsCommentsData: any): WaterSupplyCommentsDataModel {
        return {
            waterSupplyCommentId: wsCommentsData.WaterSupplyCommentId,
            reportId: wsCommentsData.ReportId,
            comment: wsCommentsData.Comment
        };
    }

    static to(model: any, sectionEnum?: SectionEnum) {
        return {
            WaterSupplyCommentId: model.waterSupplyCommentId,
            ReportId: model.reportId,
            Comment: model.comment
        };
    }

    /**
     * Check if the model has changed
     *
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     *
     */
    static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
        return false;

    }
}
