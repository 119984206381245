import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';

/**
 * Class that represents the Formly Model for Occupants
 */
export class WallsReportDataModel {
    
    static sectionName = 'WallsBG';
    isOpenSided: boolean;
    IsReinforcedConcrete?:boolean;
    // TODO: Rename to read
    static fromS3(ReportModel): WallsReportDataModel {//wholedata from cached report data
            let booleanFlag = false;
     // let wallsArrayReportModel = this.field.form?.value;
            const result = ReportModel.Walls.find(wall => { 
            // Check if reinforcedConcrete, constructionType, and wallClassification are not null
            if (wall.reinforcedConcrete === null || wall.constructionType === null || wall.wallClassification === null) {
                return false;
            }
            // Check if constructionType is "Masonry", wallClassification is "w1", and reinforcedConcrete is true
            return wall.constructionType === "Masonry" && wall.wallClassification === " W-1" && wall.reinforcedConcrete === true;
        });
        let IsReinforcedConcrete;
        if (result) {
            IsReinforcedConcrete =true;//setting BGII IsReinforcedConcrete to true based on the result
        } else {
            IsReinforcedConcrete =false;
        }
        
        return {
            isOpenSided: ReportModel.IsOpenSided,
            IsReinforcedConcrete:IsReinforcedConcrete,//key value

        };
    }

    /**
     * 
     */
    static to(model: any) {
        return {
            IsOpenSided: model.isOpenSided === 'true' ? true : false,
            IsReinforcedConcrete: model.IsReinforcedConcrete === 'true' ? true : false
        };
    }

    /**
     * Check if the model has changed
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     * @returns 
     */
    static hasChanged(formlyModel: any, riskReportModel: any): boolean {
        if (riskReportModel.IsOpenSided === undefined) {
            return true;
        }

        if (formlyModel.isOpenSided === riskReportModel.IsOpenSided) {
            return false;
        } else {
            return true;
        }
        if (riskReportModel.IsReinforcedConcrete === undefined) {
            return true;
        }

        if (formlyModel.IsReinforcedConcrete === riskReportModel.IsReinforcedConcrete) {
            return false;
        } else {
            return true;
        }
    }
}
