import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class AlarmAndSupervisoryTestDataModel {
  static sectionName = 'systemTesting';

  alarmAndSupervisoryTestId: string;
  reportId: string;
  testConductedBy: string;
  systemNumber: string;
  testDate: Date;
  localAlarmResults?: string;
  localAlarmResultsComment?: string;
  signalAlarmResults?: string;
  signalAlarmResultsComment?: string;
  supervisedAlarmResults?: string;
  supervisedAlarmResultsComment?: string;
  comments?: string;

  static fromS3(alarmSupervisoryTestData: any): AlarmAndSupervisoryTestDataModel {

    return {
      alarmAndSupervisoryTestId: alarmSupervisoryTestData.AlarmAndSupervisoryTestId,
      reportId: alarmSupervisoryTestData.ReportId,
      testConductedBy: alarmSupervisoryTestData.TestConductedBy,
      systemNumber: alarmSupervisoryTestData.SystemNumber,
      testDate: alarmSupervisoryTestData.TestDate,
      localAlarmResults: alarmSupervisoryTestData.LocalAlarmResults,
      localAlarmResultsComment: alarmSupervisoryTestData.LocalAlarmResultsComment,
      signalAlarmResults: alarmSupervisoryTestData.SignalAlarmResults,
      signalAlarmResultsComment: alarmSupervisoryTestData.SignalAlarmResultsComment,
      supervisedAlarmResults: alarmSupervisoryTestData.SupervisedAlarmResults,
      supervisedAlarmResultsComment: alarmSupervisoryTestData.SupervisedAlarmResultsComment,
      comments: alarmSupervisoryTestData.Comments
    };
  }

  static to(model: any, sectionEnum?: SectionEnum) {
    return {
      AlarmAndSupervisoryTestId: model.alarmAndSupervisoryTestId,
      ReportId: model.reportId,
      TestConductedBy: model.testConductedBy ? model.testConductedBy : '',
      SystemNumber: model.systemNumber ? model.systemNumber : '',
      TestDate: model.testDate,
      LocalAlarmResults: model.localAlarmResults,
      LocalAlarmResultsComment: model.localAlarmResultsComment,
      SignalAlarmResults: model.signalAlarmResults,
      SignalAlarmResultsComment: model.signalAlarmResultsComment,
      SupervisedAlarmResults: model.supervisedAlarmResults,
      SupervisedAlarmResultsComment: model.supervisedAlarmResultsComment,
      Comments: model.comments
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
    return false;

  }
}
