import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class HydraulicDataDesignDataModel {
  static sectionName = 'hydraulicDataDesign';

  hydraulicDesignDataId: string;
  reportId: string;
  systemNumber: string;
  designStandard?: string;
  areaSquareFeet?: number;
  areaDescription?: string;
  designDensity?: number;
  designDensityComment?: string;
  designArea?: number;
  designAreaComment?: string;
  waterDemandGpm?: number;
  waterDemandGpmComment?: string;
  waterDemandPsi?: number;
  waterDemandPsiComment?: string;
  static fromS3(model: any, dataLength: number, data?: any) {  
  if(dataLength > 1){
    if(data?.DesignDensity){ 
      data.DesignDensity= +data.DesignDensity;
    }else{
      data.DesignDensity= null;
    } 
    if(data?.DesignArea){ 
      data.DesignArea= +data.DesignArea;
    }else{
      data.DesignArea= null;
    } 
    if(data?.WaterDemandGpm){ 
      data.WaterDemandGpm= +data.WaterDemandGpm;
    }else{
      data.waterDemandGpm= null;
    } 
    if(data?.WaterDemandPsi){ 
      data.WaterDemandPsi= +data.WaterDemandPsi;
    }else{
      data.WaterDemandPsi= null;
    }   
  }else{
    data.DesignDensity= data?.DesignDensity? +data.DesignDensity : model?.HydraulicDesignDensity ? +model.HydraulicDesignDensity : null;
    data.DesignArea= data?.DesignArea? +data.DesignArea : model?.HydraulicRemoteArea ? +model.HydraulicRemoteArea : null;
    data.WaterDemandGpm= data?.WaterDemandGpm? +data.WaterDemandGpm: model?.HydraulicWaterDemandFlow? +model.HydraulicWaterDemandFlow: null
    data.WaterDemandPsi= data?.WaterDemandPsi? +data.WaterDemandPsi : model?.HydraulicWaterDemandPressure? +model.HydraulicWaterDemandPressure: null;
  }  
 
      return {
          hydraulicDesignDataId: data?.HydraulicDesignDataId,
          reportId: data?.ReportId,
          systemNumber: data?.SystemNumber,                             
          designStandard: (data?.DesignStandard === 'Density Area' || data?.DesignStandard === 'Room Design' ||
                          data?.DesignStandard === 'CMDA' || data?.DesignStandard === 'ESFR' || data?.DesignStandard === 'CMSA')? data.DesignStandard : '', 
          areaSquareFeet: data?.AreaSquareFeet? +data?.AreaSquareFeet: data.AreaSquareFeet,
          areaDescription: data?.AreaDescription,               
          designDensity: data?.DesignDensity,
          designDensityComment: data?.DesignDensityComment,           
          designArea: data?.DesignArea,
          designAreaComment: data?.designAreaComment,          
          waterDemandGpm: data?.WaterDemandGpm,
          waterDemandGpmComment: data?.WaterDemandGpmComment,         
          waterDemandPsi: data?.WaterDemandPsi,
          waterDemandPsiComment: data?.WaterDemandPsiComment,
      }   
  }

  static to(model: any, sectionEnum?: SectionEnum) {
    const designData = model.map((item) => {
      const object = {
        HydraulicDesignDataId: item?.hydraulicDesignDataId,
        ReportId: item?.reportId,
        SystemNumber: item?.systemNumber ? item.systemNumber : '', 
        DesignStandard: item?.designStandard ? item?.designStandard : '',
        AreaSquareFeet: item?.areaSquareFeet !== null && item?.areaSquareFeet ? Number(item?.areaSquareFeet) : null,
        AreaDescription: item?.areaDescription? item.areaDescription: '',
        DesignDensity: item?.designDensity !== null && item?.designDensity? Number(item?.designDensity): null,
        DesignDensityComment: item?.designDensityComment ? item?.designDensityComment : '',
        DesignArea: item?.designArea !== null && item?.designArea ? Number(item?.designArea): null,
        DesignAreaComment: item?.designAreaComment ? item?.designAreaComment : '',
        WaterDemandGpm: item?.waterDemandGpm !== null && item?.waterDemandGpm ? Number(item?.waterDemandGpm) : null,
        WaterDemandGpmComment: item?.waterDemandGpmComment? item.waterDemandGpmComment: '',
        WaterDemandPsi: item?.waterDemandPsi !== null && item?.waterDemandPsi ? Number(item?.waterDemandPsi) : null,
        WaterDemandPsiComment: item?.waterDemandPsiComment ? item.waterDemandPsiComment :'',
      };
      return object;
    });
    return designData;
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
    return false;
  }
}
