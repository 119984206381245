import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
//import { WallsBgDataModel } from 'src/app/models/formly-builder/sections/full-risk/walls-bg.data.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { takeUntil } from 'rxjs/operators';
import { FormlyBuilderService } from '../formly-builder.service';
import { RiskReportService } from '../risk-report.service';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { WallsBGIIDataModel } from 'src/app/models/formly-builder/formly/full-risk/walls-bg2-data.model';
import { ChargeableColumnDataModel } from 'src/app/models/formly-builder/formly/full-risk/walls-chargeable-column-data.model';
import { HookReuseableService } from '../hook-reuseable.service';
import { ChargeablePanelsDataModel } from 'src/app/models/formly-builder/formly/full-risk/walls-chargeable-panels-data.model';
@Injectable({
  providedIn: 'root',
})
export class WallsChargeablePanelsService extends SectionServiceAbstract {
  constructor(
    private http: HttpClient,
    private formlyBuilderService: FormlyBuilderService, private riskReportService: RiskReportService,
    private hookReuseableService: HookReuseableService,
  ) {
    super();
  }
  async updateData(model: any, riskReportData: any) {
    // Check if data has changed
    const hasChanged: boolean = ChargeablePanelsDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      const newChargeablePanelsInfo = ChargeablePanelsDataModel.to(model);
      return { ...riskReportData, ...newChargeablePanelsInfo };
    } else {
      return undefined;
    }
  }

  loadData(unused: any[], reportInfo: any, isS3: boolean, model: any): any {
    const chargeablePanelDataModel: ChargeablePanelsDataModel = ChargeablePanelsDataModel.fromS3(reportInfo);
    // We can remove this condition once the fields got added in the backend DB
    if (this.formlyBuilderService.calculationService.isRiskReportLoaded !== false) {
      // 1) Lets respect HasWallsChargeablePanels value 
      // 2) if there is no value then for exsisting risk value should be calaulated based on requirement
      if (chargeablePanelDataModel.chargeablePanels === null || chargeablePanelDataModel.chargeablePanels === undefined) {
        if (this.formlyBuilderService.isExistingRisk()) {
          if ((chargeablePanelDataModel.percentInputI !== null && chargeablePanelDataModel.percentInputI > 0) ||
            (chargeablePanelDataModel.percentInputII !== null && chargeablePanelDataModel.percentInputII > 0)
            || (chargeablePanelDataModel.percentInputIII !== null && chargeablePanelDataModel.percentInputIII > 0)) {
              chargeablePanelDataModel.chargeablePanels = true;
          } else {
            chargeablePanelDataModel.chargeablePanels = false;
          }
        }        
      }
    }
    return {
      ...model,
      ...{
        areChargeablePanels: chargeablePanelDataModel
      }
    };
  }
}