import { Injectable } from '@angular/core';
import { ReadFileResult } from '@capacitor/filesystem';
import { BehaviorSubject, Observable, forkJoin, from, of } from 'rxjs';
import { concatMap, delay, retryWhen, switchMap, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { AmplifyService } from '../amplify/amplify.service';
import {
  IQueue,
  saved,
  QueueTypes,
  IModelPhotos,
  IModelAttachments,
  SyncStatus,
} from 'src/app/models/risk-report/queue-service.model';
import { AssetType, ConfigModel } from 'src/app/enums/config-model.enum';
import { UtilService } from '../util-service/util.service';
import { IonicStorageService } from '../ionic-storage/ionic-storage.service';
import { OrdersService } from '../orders/orders.service';
import { OrderCollectionModel } from 'src/app/models/order/order-collection.model';
import { OrderModel } from 'src/app/models/order/order.model';
import { LocalNotificationsService } from '../local-notifications/local-notifications.service';
import { LoadingService } from '../loading-service/loading.service';
import { VeriskLog } from '../util-service/verisk-log';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QueueService {
  callsQueueStatusById = {};
  orderQueue = {};
  hasNetworkConnection: boolean = false;
  private ordersToBeSynced = new BehaviorSubject(0);
  public ordersToBeSynced$ = this.ordersToBeSynced.asObservable();
  private filesToBeSynced = new BehaviorSubject(0);
  public filesToBeSynced$ = this.filesToBeSynced.asObservable();
  initialSyncDone = false;

  constructor(
    private localStorageService: LocalStorageService,
    private amplifyService: AmplifyService,
    private utilService: UtilService,
    private ionicStorageService: IonicStorageService,
    private ordersService: OrdersService,
    private localNotificationsService: LocalNotificationsService,
    private loadingService: LoadingService
  ) {
    this.amplifyService.networkConnection.subscribe((hasNetwork: boolean) => {
      this.hasNetworkConnection = hasNetwork;
    });
    this.ordersService.fileSavedToLocal$.subscribe((fileSaved) => {
      if (fileSaved?.length) {
        fileSaved.forEach((file) => {
          this.onFileStatusChange(
            file.sync || false,
            file.model,
            file.identifier,
            file.assetType || null,
            file.assetIdentifier || null,
            file.fileName || null,
            true,
            SyncStatus.NotSynced,
            file.fileDeleted || null
          );
        });
      }
    });
  }

  initializeQueueCreation(): void {
    this.ionicStorageService.orderQueue$
      .pipe(
        switchMap((orderQueue) => {
          if (Object.keys(orderQueue).length) {
            this.orderQueue = orderQueue;
            this.trySyncing(this.initialSyncDone);
          }

          return of(null);
        }),
        switchMap((_) => {
          return this.ordersService.observeCollection();
        }),
        switchMap((ordersCollection: OrderCollectionModel) => {
          return ordersCollection.orders$;
        })
      )
      .subscribe({
        next: (orders: OrderModel[]) => {
          this.addMissingOrdersToQueue(orders);
        },
        error: (e) => {
          console.log('Cannot fetch order');
          let veriskLog = new VeriskLog(
            'Cannot fetch order',
            'ERROR',
            this.initializeQueueCreation.name,
            QueueService.name,
            e,
            true
          );
          this.utilService.addLog(veriskLog);
        }
      });
  }

  trySyncing(initialSyncDone = false) {
    if (this.hasNetworkConnection && !initialSyncDone) {
      this.syncAllOrders();
    } else {
      this.calculateFilesToBeSynced();
    }
  }

  startQueueProcess(callsQueue: IQueue[]) {
    const requestArrayRequired: Observable<any>[] = [];
    const requestArrayOptional: Observable<any>[] = [];

    callsQueue.forEach((queue: IQueue) => {
      // if (this.callsQueueStatusById[queue.type] !== saved) {
      switch (queue.type) {
        case QueueTypes.S3Json:
          queue.required
            ? requestArrayRequired.push(this.updateS3Json(queue))
            : requestArrayOptional.push(this.updateS3Json(queue));
          break;
        case QueueTypes.S3Photos:
          queue.required
            ? requestArrayRequired.push(this.updateS3Photos(queue))
            : requestArrayOptional.push(this.updateS3Photos(queue));
          break;
        case QueueTypes.Documents:
          queue.required
            ? requestArrayRequired.push(this.updateS3Documents(queue))
            : requestArrayOptional.push(this.updateS3Documents(queue));
          break;
        case QueueTypes.CustomerAssets:
          queue.required
            ? requestArrayRequired.push(this.updateS3Documents(queue, true))
            : requestArrayOptional.push(this.updateS3Documents(queue, true));
          break;
        case QueueTypes.RiskReport:
          queue.required
            ? requestArrayRequired.push(this.updateS3OrderRiskReportsOrForms(queue, AssetType.RiskReport))
            : requestArrayOptional.push(this.updateS3OrderRiskReportsOrForms(queue, AssetType.RiskReport));
          break;

        case QueueTypes.RiskReportAttachments:
          queue.required
            ? requestArrayRequired.push(this.updateS3OrderRiskReportDocuments(queue, AssetType.RiskReportAttachments))
            : requestArrayOptional.push(this.updateS3OrderRiskReportDocuments(queue, AssetType.RiskReportAttachments));
          break;
        case QueueTypes.SprinklerReportAttachments:
          queue.required
            ? requestArrayRequired.push(this.updateS3OrderRiskReportDocuments(queue, AssetType.SprinklerReportAttachments, true))
            : requestArrayOptional.push(this.updateS3OrderRiskReportDocuments(queue, AssetType.SprinklerReportAttachments, true));
          break;
        case QueueTypes.Forms:
          queue.required
            ? requestArrayRequired.push(this.updateS3OrderRiskReportsOrForms(queue, AssetType.Forms))
            : requestArrayOptional.push(this.updateS3OrderRiskReportsOrForms(queue, AssetType.Forms));
          break;
      }
      // }
    });

    forkJoin(requestArrayOptional).subscribe({
      next: () => {
        this.calculateFilesToBeSynced();
        this.loadingService.dismissLoading();
      },
      error: () => this.loadingService.dismissLoading(),
    });

    return forkJoin(requestArrayRequired).pipe(
      tap((_) => {
        callsQueue?.[0]?.model === ConfigModel.Order && this.resetQueueStatus();
        this.calculateFilesToBeSynced();
      })
    );
  }

  updateS3Json(queue: IQueue): Observable<any> {
    if (
      (queue.model === ConfigModel.Order && this.orderQueue[queue.identifier].dirty) ||
      queue.model === ConfigModel.RiskReport
    ) {
      return this.localStorageService.getLocalDataByIdentifier(queue.model, queue.identifier).pipe(
        switchMap((file: ReadFileResult) => {
          const data = JSON.parse(file.data);
          if (queue.model === ConfigModel.RiskReport) {
            data.CompletedDateTime = moment().format('YYYY-MM-DDTHH:mm:ss');
            return from(this.localStorageService.saveFormOrRiskReportToLocal(data, queue.model, queue.identifier, AssetType.RiskReport, queue.identifier));
          } else {
            return of(data);
          }
        }),
        switchMap((data: any) => {
          const fileName = queue.model === ConfigModel.Order ? 'order' : queue.identifier;
          const filePath: string =
            'isurvey/' + queue.model.toLowerCase() + '/' + queue.identifier + '/' + fileName + '.json';
          const itemAsBlob = new Blob([JSON.stringify(data)]);

          return this.amplifyService.uploadJsonToS3(filePath, itemAsBlob, queue.identifier).pipe(
            tap({
              next: () => {
                this.callsQueueStatusById[queue.type] = saved;
                queue.model === ConfigModel.Order &&
                  this.onFileStatusChange(
                    false,
                    queue.model as ConfigModel,
                    queue.identifier,
                    null,
                    null,
                    null,
                    false,
                    SyncStatus.Synced
                  );
              },
              error: (e) => {
                queue.model === ConfigModel.Order &&
                  this.onFileStatusChange(
                    false,
                    queue.model as ConfigModel,
                    queue.identifier,
                    null,
                    null,
                    null,
                    true,
                    SyncStatus.SyncFailed
                  );

                let veriskLog = new VeriskLog(
                  'Cannot update s3 Json',
                  'ERROR',
                  this.updateS3Json.name,
                  QueueService.name,
                  e,
                  true
                );
                this.utilService.addLog(veriskLog);
              },
            }),
            this.handleRetryError()
          );
        })
      );
    } else {
      return of(true);
    }
  }

  updateS3Photos(queue: IQueue): Observable<any> {
    const photosToBeUploaded: Observable<any>[] = [];

    return this.localStorageService.getListByIdentifier(queue.model, queue.identifier, AssetType.Photos).pipe(
      switchMap((photosList: IModelPhotos[]) => {
        photosList.forEach((photosDetails) => {
          if (
            (queue.model === ConfigModel.Order &&
              this.orderQueue[queue.identifier][AssetType.Photos][photosDetails.PhotoIdentifier || photosDetails.Id]
                ?.dirty) ||
            queue.model === ConfigModel.RiskReport
          ) {
            photosToBeUploaded.push(
              this.uploadSinglePhoto(queue.model, queue.identifier, photosDetails.PhotoIdentifier || photosDetails.Id)
            );
          }
        });

        return forkJoin(photosToBeUploaded).pipe(
          tap((_) => {
            this.callsQueueStatusById[queue.type] = saved;
          })
        );
      })
    );
  }

  uploadSinglePhoto(model: ConfigModel, modelIdentifier: string, photoIdentifier: string): Observable<any> {
    return this.localStorageService.getLocalPhotoByIdentifier(model, modelIdentifier, photoIdentifier).pipe(
      switchMap((photo: any) => {
        const filePath = `isurvey/${model.toLowerCase()}/${modelIdentifier}/${AssetType.Photos
          }/${photoIdentifier}.jpeg`;

        if (!photo.dataUrl) {
          photo.dataUrl = 'data:image/jpeg;base64,' + photo.data;
        }

        return this.amplifyService.uploadPhotoToS3(filePath, photo, modelIdentifier, photoIdentifier).pipe(
          this.handleRetryError(),
          tap({
            next: () => {
              model === ConfigModel.Order &&
                this.onFileStatusChange(
                  false,
                  model as ConfigModel,
                  modelIdentifier,
                  AssetType.Photos,
                  photoIdentifier,
                  null,
                  false,
                  SyncStatus.Synced
                );
            },
            error: (e) => {
              model === ConfigModel.Order &&
                this.onFileStatusChange(
                  false,
                  model as ConfigModel,
                  modelIdentifier,
                  AssetType.Photos,
                  photoIdentifier,
                  null,
                  true,
                  SyncStatus.SyncFailed
                );

              let veriskLog = new VeriskLog(
                'Cannot upload single photo',
                'ERROR',
                this.uploadSinglePhoto.name,
                QueueService.name,
                e,
                true
              );
              this.utilService.addLog(veriskLog);
            },
          })
        );
      })
    );
  }

  uploadFormHtml(model: ConfigModel, modelIdentifier: string, formIdentifier: string): Observable<any> {
    return this.localStorageService.getLocalHtmlForm(model, modelIdentifier, formIdentifier).pipe(
      switchMap((form: any) => {
        const filePath = `isurvey/${model.toLowerCase()}/${modelIdentifier}/${AssetType.Forms}/${formIdentifier}/${formIdentifier}.html`;

        return this.amplifyService.uploadHtmlToS3(filePath, form, modelIdentifier, formIdentifier).pipe(
          this.handleRetryError(),
          tap({
            next: () => {
              let veriskLog = new VeriskLog(
                'Uploaded Form html to s3 Successfully',
                'INFO',
                this.uploadFormHtml.name,
                QueueService.name,
                null,
                true
              );
              this.utilService.addLog(veriskLog);
            },
            error: (e) => {
              model === ConfigModel.Order &&
                this.onFileStatusChange(
                  false,
                  model as ConfigModel,
                  modelIdentifier,
                  AssetType.Forms,
                  formIdentifier,
                  null,
                  true,
                  SyncStatus.SyncFailed
                );

              let veriskLog = new VeriskLog(
                'Cannot upload Form Html',
                'ERROR',
                this.uploadFormHtml.name,
                QueueService.name,
                e,
                true
              );
              this.utilService.addLog(veriskLog);
            },
          })
        );
      })
    );
  }

  deletePhotosInQueue(photoid, OrderIdFullOrderNumber) {
    const queue = this.getQueueByModel(ConfigModel.Order);
    let orderDetails = queue[OrderIdFullOrderNumber];
    if (photoid && orderDetails) {
      delete orderDetails[AssetType?.Photos]?.[photoid];
      this.ionicStorageService.updateOrderQueue(orderDetails);
    }
  }

  updateS3Documents(queue: IQueue, customerAssets = false): Observable<any> {
    const attachmentsToBeUploaded: Observable<any>[] = [];
    const documentType = customerAssets ? AssetType.CustomerAssets : AssetType.Documents;

    return this.localStorageService.getListByIdentifier(queue.model, queue.identifier, documentType).pipe(
      switchMap((attachmentList: IModelAttachments[]) => {
        attachmentList.forEach((attachmentDetails) => {
          if (
            (queue.model === ConfigModel.Order &&
              this.orderQueue[queue.identifier][documentType][
                attachmentDetails.UniqueKey || attachmentDetails.FileName?.split('.').slice(0, -1).join('.')
              ]?.dirty) ||
            queue.model === ConfigModel.RiskReport
          ) {
            attachmentsToBeUploaded.push(
              this.uploadSingleS3Document(
                queue.model,
                queue.identifier,
                attachmentDetails.UniqueKey || attachmentDetails.FileName?.split('.').slice(0, -1).join('.'),
                attachmentDetails.FileName,
                customerAssets
              )
            );
          }
        });

        return forkJoin(attachmentsToBeUploaded).pipe(
          tap((_) => {
            this.callsQueueStatusById[queue.type] = saved;
          })
        );
      })
    );
  }

  deleteSingleFormOrRiskReport(
    model: ConfigModel,
    modelIdentifier: string,
    assetType: AssetType,
    formOrRiskIdentifier: string
  ): Observable<any> {
    const filePath = `isurvey/${model.toLowerCase()}/${modelIdentifier}/${assetType}/${formOrRiskIdentifier}/${formOrRiskIdentifier}.json`;
    return this.amplifyService.deleteFromS3(filePath).pipe(
      this.handleRetryError(),
      tap({
        next: () => {
          model === ConfigModel.Order &&
            this.onFileStatusChange(
              false,
              model as ConfigModel,
              modelIdentifier,
              AssetType.RiskReport,
              formOrRiskIdentifier,
              null,
              false,
              SyncStatus.Synced,
              true
            );
        },
        error: (e) => {
          model === ConfigModel.Order &&
            this.onFileStatusChange(
              false,
              model as ConfigModel,
              modelIdentifier,
              AssetType.RiskReport,
              formOrRiskIdentifier,
              null,
              true,
              SyncStatus.SyncFailed,
              true
            );

          let veriskLog = new VeriskLog(
            'Error to delete single Form/RiskReport',
            'ERROR',
            this.deleteSingleFormOrRiskReport.name,
            QueueService.name,
            e,
            true
          );
          this.utilService.addLog(veriskLog);
        },
      })
    );
  }

  deleteSinglePhoto(model: ConfigModel, modelIdentifier: string, photoIdentifier: string): Observable<any> {
    const filePath = `isurvey/${model.toLowerCase()}/${modelIdentifier}/${AssetType.Photos}/${photoIdentifier}.jpeg`;
    return this.amplifyService.deleteFromS3(filePath).pipe(
      this.handleRetryError(),
      tap({
        next: () => {
          model === ConfigModel.Order &&
            this.onFileStatusChange(
              false,
              model as ConfigModel,
              modelIdentifier,
              AssetType.Photos,
              photoIdentifier,
              null,
              false,
              SyncStatus.Synced,
              true
            );
        },
        error: (e) => {
          model === ConfigModel.Order &&
            this.onFileStatusChange(
              false,
              model as ConfigModel,
              modelIdentifier,
              AssetType.Photos,
              photoIdentifier,
              null,
              true,
              SyncStatus.SyncFailed,
              true
            );

          let veriskLog = new VeriskLog(
            'Error to delete single Photo',
            'ERROR',
            this.deleteSinglePhoto.name,
            QueueService.name,
            e,
            true
          );
          this.utilService.addLog(veriskLog);
        },
      })
    );
  }

  deleteSingleS3Document(
    model: ConfigModel,
    modelIdentifier: string,
    documentIdentifier: string,
    fileName: string,
    customerAssets = false
  ) {
    const documentType = customerAssets ? AssetType.CustomerAssets : AssetType.Documents;
    const fileExtension = this.utilService.getExtensionFromFileName(fileName);
    const filePath = `isurvey/${model.toLowerCase()}/${modelIdentifier}/${documentType}/${documentIdentifier}.${fileExtension}`;

    return this.amplifyService.deleteFromS3(filePath).pipe(
      this.handleRetryError(),
      tap({
        next: () => {
          model === ConfigModel.Order &&
            this.onFileStatusChange(
              false,
              model as ConfigModel,
              modelIdentifier,
              documentType,
              documentIdentifier,
              fileName,
              false,
              SyncStatus.Synced,
              true
            );
        },
        error: (e) => {
          model === ConfigModel.Order &&
            this.onFileStatusChange(
              false,
              model as ConfigModel,
              modelIdentifier,
              documentType,
              documentIdentifier,
              fileName,
              true,
              SyncStatus.SyncFailed,
              true
            );

          let veriskLog = new VeriskLog(
            'Cannot delete single s3 Document',
            'ERROR',
            this.deleteSingleS3Document.name,
            QueueService.name,
            e,
            true
          );
          this.utilService.addLog(veriskLog);
        },
      })
    );
  }

  deleteSingleRiskReportS3Document(
    model: ConfigModel,
    modelIdentifier: string,
    riskReportIdentifier: string,
    documentIdentifier: string,
    sprinklerattachment = false
  ) {
    const documentType = sprinklerattachment ? AssetType.SprinklerReportAttachments : AssetType.RiskReportAttachments;
    const fileExtension = this.utilService.getExtensionFromFileName(documentIdentifier);
    const filePath = `isurvey/${model.toLowerCase()}/${modelIdentifier}/${AssetType.RiskReport}/${riskReportIdentifier}/${documentType}/${documentIdentifier}`;

    return this.amplifyService.deleteFromS3(filePath).pipe(
      this.handleRetryError(),
      tap({
        next: () => {
          model === ConfigModel.Order &&
            this.onFileStatusChange(
              false,
              model as ConfigModel,
              modelIdentifier,
              documentType,
              riskReportIdentifier,
              documentIdentifier,
              false,
              SyncStatus.Synced,
              true
            );
        },
        error: () => {
          model === ConfigModel.Order &&
            this.onFileStatusChange(
              false,
              model as ConfigModel,
              modelIdentifier,
              documentType,
              riskReportIdentifier,
              documentIdentifier,
              true,
              SyncStatus.SyncFailed,
              true
            );
        },
      })
    );
  }
  uploadSingleS3Document(
    model: ConfigModel,
    modelIdentifier: string,
    documentIdentifier: string,
    fileName: string,
    customerAssets = false
  ): Observable<any> {
    const documentType = customerAssets ? AssetType.CustomerAssets : AssetType.Documents;
    const fileExtension = this.utilService.getExtensionFromFileName(fileName);
    return this.localStorageService
      .getLocalAttachmentByIdentifier(model, modelIdentifier, documentIdentifier, fileExtension, customerAssets)
      .pipe(
        switchMap((attachment: any) => {
          const filePath = `isurvey/${model.toLowerCase()}/${modelIdentifier}/${documentType}/${documentIdentifier}.${fileExtension}`;

          return this.amplifyService
            .uploadDocumentToS3(model, filePath, attachment, modelIdentifier, documentIdentifier)
            .pipe(
              this.handleRetryError(),
              tap({
                next: () => {
                  model === ConfigModel.Order &&
                    this.onFileStatusChange(
                      false,
                      model as ConfigModel,
                      modelIdentifier,
                      documentType,
                      documentIdentifier,
                      fileName,
                      false,
                      SyncStatus.Synced
                    );
                },
                error: (e) => {
                  model === ConfigModel.Order &&
                    this.onFileStatusChange(
                      false,
                      model as ConfigModel,
                      modelIdentifier,
                      documentType,
                      documentIdentifier,
                      fileName,
                      true,
                      SyncStatus.SyncFailed
                    );

                  let veriskLog = new VeriskLog(
                    'Cannot upload single s3 Document',
                    'ERROR',
                    this.uploadSingleS3Document.name,
                    QueueService.name,
                    e,
                    true
                  );
                  this.utilService.addLog(veriskLog);
                },
              })
            );
        })
      );
  }


  uploadRiskReportSingleS3Document(
    model: ConfigModel,
    modelIdentifier: string,
    riskReportIdentifier: string,
    documentIdentifier: string,
    sprinklerattachment = false
  ): Observable<any> {
    const documentType = sprinklerattachment ? AssetType.SprinklerReportAttachments : AssetType.RiskReportAttachments;
    const fileExtension = this.utilService.getExtensionFromFileName(documentIdentifier);
    return this.localStorageService
      .getLocalRiskReportAttachmentByIdentifier(model, modelIdentifier, riskReportIdentifier,
        documentIdentifier.split('.')?.slice(0, -1)?.join('.'), fileExtension, sprinklerattachment)
      .pipe(
        switchMap((attachment: any) => {
          const filePath = `isurvey/${model.toLowerCase()}/${modelIdentifier}/${AssetType.RiskReport}/${riskReportIdentifier}/${documentType}/${documentIdentifier}`;

          return this.amplifyService
            .uploadDocumentToS3(model, filePath, attachment, modelIdentifier, documentIdentifier)
            .pipe(
              this.handleRetryError(),
              tap({
                next: () => {
                  model === ConfigModel.Order &&
                    this.onFileStatusChange(
                      false,
                      model as ConfigModel,
                      modelIdentifier,
                      documentType,
                      riskReportIdentifier,
                      documentIdentifier,
                      false,
                      SyncStatus.Synced
                    );
                },
                error: () => {
                  model === ConfigModel.Order &&
                    this.onFileStatusChange(
                      false,
                      model as ConfigModel,
                      modelIdentifier,
                      documentType,
                      riskReportIdentifier,
                      documentIdentifier,
                      true,
                      SyncStatus.SyncFailed
                    );
                },
              })
            );
        })
      );


  }

  updateS3OrderRiskReportsOrForms(queue: IQueue, assetType: AssetType): Observable<any> {
    const reportsToBeUploaded: Observable<any>[] = [];

    return this.localStorageService.getFormOrReportListByIdentifier(queue.model, assetType, queue.identifier).pipe(
      switchMap((formsOrRiskList: any[]) => {
        if (assetType === AssetType.Forms) {
          formsOrRiskList.forEach((riskOrFormDetails) => {
            if (
              queue.model === ConfigModel.Order &&
              this.orderQueue[queue.identifier][AssetType.Forms][riskOrFormDetails.DataFile].dirty
            ) {
              reportsToBeUploaded.push(
                this.uploadSingleFormOrRiskReport(
                  queue.model,
                  queue.identifier,
                  AssetType.Forms,
                  riskOrFormDetails.DataFile
                )
              );
              if (environment.isUWFormRelease) {
                this.uploadFormHtml(queue.model, queue.identifier, riskOrFormDetails.DataFile)
              }
            }
          });
        } else {
          formsOrRiskList.forEach((riskOrFormDetails) => {
            if (
              queue.model === ConfigModel.Order &&
              this.orderQueue[queue.identifier][AssetType.RiskReport]?.[riskOrFormDetails.ReportId]?.dirty
            ) {
              reportsToBeUploaded.push(
                this.uploadSingleFormOrRiskReport(
                  queue.model,
                  queue.identifier,
                  AssetType.RiskReport,
                  riskOrFormDetails.ReportId
                )
              );
            }
          });
        }

        return (reportsToBeUploaded.length ? forkJoin(reportsToBeUploaded) : of([])).pipe(
          tap((_) => {
            this.callsQueueStatusById[queue.type] = saved;
          })
        );
      })
    );
  }


  updateS3OrderRiskReportDocuments(queue: IQueue, assetType: AssetType, isSprinklerReport = false): Observable<any> {
    const reportsToBeUploaded: Observable<any>[] = [];
    Object.keys(this.orderQueue).forEach(orderNumber => {
      Object.keys(this.orderQueue[orderNumber][assetType]).forEach(ReportAttachmentId => {
        if (this.orderQueue[orderNumber][assetType][ReportAttachmentId].dirty) {
          reportsToBeUploaded.push(
            this.uploadRiskReportSingleS3Document(
              ConfigModel.Order,
              queue.identifier,
              this.orderQueue[orderNumber][assetType][ReportAttachmentId].riskReportIdentifier,
              this.orderQueue[orderNumber][assetType][ReportAttachmentId].attachmentFileName,
              isSprinklerReport
            )
          )
        }
      })
    })
    return forkJoin(reportsToBeUploaded).pipe(
      tap((_) => {
        this.callsQueueStatusById[queue.type] = saved;
      })
    );
  }

  uploadSingleFormOrRiskReport(
    model: ConfigModel,
    modelIdentifier: string,
    assetType: AssetType,
    formOrRiskIdentifier: string
  ) {
    return this.localStorageService
      .getLocalRiskOrFormByIdentifier(model, modelIdentifier, assetType, formOrRiskIdentifier)
      .pipe(
        switchMap((formOrRisk: any) => {
          const filePath = `isurvey/${model.toLowerCase()}/${modelIdentifier}/${assetType}/${formOrRiskIdentifier}/${formOrRiskIdentifier}.json`;
          const itemAsBlob = new Blob([formOrRisk.data]);
          return this.amplifyService.uploadJsonToS3(filePath, itemAsBlob, modelIdentifier, formOrRiskIdentifier).pipe(
            this.handleRetryError(),
            tap({
              next: () => {
                model === ConfigModel.Order &&
                  this.onFileStatusChange(
                    false,
                    model as ConfigModel,
                    modelIdentifier,
                    assetType,
                    formOrRiskIdentifier,
                    null,
                    false,
                    SyncStatus.Synced
                  );
              },
              error: (e) => {
                model === ConfigModel.Order &&
                  this.onFileStatusChange(
                    false,
                    model as ConfigModel,
                    modelIdentifier,
                    AssetType.RiskReport,
                    formOrRiskIdentifier,
                    null,
                    true,
                    SyncStatus.SyncFailed
                  );

                let veriskLog = new VeriskLog(
                  'Cannot upload single Form/RiskReport',
                  'ERROR',
                  this.uploadSingleFormOrRiskReport.name,
                  QueueService.name,
                  e,
                  true
                );
                this.utilService.addLog(veriskLog);
              },
            })
          );
        })
      );
  }

  onFileStatusChange(
    sync: boolean,
    model: ConfigModel,
    modelIdentifier: string,
    assetType: AssetType,
    assetIdentifier: string,
    fileName: string,
    dirty: boolean,
    syncStatus: SyncStatus,
    fileDeleted: boolean = false
  ) {
    const queue = this.getQueueByModel(model);
    let orderDetails = queue[modelIdentifier];
    let assetId = assetIdentifier;
    if (assetId) {
      if (fileDeleted) {
        delete orderDetails[assetType]?.[assetId];
      } else {
        if (!orderDetails[assetType]?.[assetId]) {
          orderDetails[assetType][assetId] = {};
        }
        orderDetails[assetType][assetId]['id'] = assetId;
        orderDetails[assetType][assetId]['dirty'] = dirty;
        orderDetails[assetType][assetId]['syncStatus'] = syncStatus;
        orderDetails[assetType][assetId]['lastSynced'] = new Date();
        if (assetType === AssetType.RiskReportAttachments || assetType === AssetType.SprinklerReportAttachments) {
          orderDetails[assetType][assetId]['riskReportIdentifier'] = assetIdentifier;
          orderDetails[assetType][assetId]['attachmentFileName'] = fileName;
        }
      }
    } else {
      orderDetails.dirty = dirty;
      orderDetails.syncStatus = syncStatus;
      orderDetails.lastSynced = new Date();
    }
    orderDetails.orderTouched = true;

    this.ionicStorageService.updateOrderQueue(orderDetails);

    this.hasNetworkConnection &&
      sync &&
      this.syncSingleFiles(model, modelIdentifier, assetType, assetIdentifier, fileName, fileDeleted);
  }

  syncSingleFiles(
    model: ConfigModel,
    modelIdentifier: string,
    assetType: AssetType,
    assetIdentifier: string,
    fileName: string,
    fileDeleted: boolean,

  ) {
    switch (assetType) {
      case AssetType.Photos:
        fileDeleted
          ? this.deleteSinglePhoto(model, modelIdentifier, assetIdentifier).subscribe()
          : this.uploadSinglePhoto(model, modelIdentifier, assetIdentifier).subscribe();
        break;
      case AssetType.Documents:
        fileDeleted
          ? this.deleteSingleS3Document(model, modelIdentifier, assetIdentifier, fileName).subscribe()
          : this.uploadSingleS3Document(model, modelIdentifier, assetIdentifier, fileName).subscribe();
        break;
      case AssetType.RiskReportAttachments:
        fileDeleted
          ? this.deleteSingleRiskReportS3Document(model, modelIdentifier, assetIdentifier, fileName).subscribe()
          : this.uploadRiskReportSingleS3Document(model, modelIdentifier, assetIdentifier, fileName).subscribe();
        break;
      case AssetType.SprinklerReportAttachments:
        fileDeleted
          ? this.deleteSingleRiskReportS3Document(model, modelIdentifier, assetIdentifier, fileName, true).subscribe()
          : this.uploadRiskReportSingleS3Document(model, modelIdentifier, assetIdentifier, fileName, true).subscribe();
        break;
      case AssetType.CustomerAssets:
        fileDeleted
          ? this.deleteSingleS3Document(model, modelIdentifier, assetIdentifier, fileName, true).subscribe()
          : this.uploadSingleS3Document(model, modelIdentifier, assetIdentifier, fileName, true).subscribe();
        break;
      case AssetType.RiskReport:
        fileDeleted
          ? this.deleteSingleFormOrRiskReport(model, modelIdentifier, assetType, assetIdentifier).subscribe()
          : this.uploadSingleFormOrRiskReport(model, modelIdentifier, assetType, assetIdentifier).subscribe();
        break;
      case AssetType.Forms:
        if (fileDeleted) {
          this.deleteSingleFormOrRiskReport(model, modelIdentifier, assetType, assetIdentifier).subscribe()
        } else {
          if(environment.isUWFormRelease){
           this.uploadFormHtml(model, modelIdentifier, assetIdentifier).subscribe();
          }
          this.uploadSingleFormOrRiskReport(model, modelIdentifier, assetType, assetIdentifier).subscribe();
        }
        break;
      default:
        this.updateS3Json({ model, identifier: modelIdentifier, type: QueueTypes.S3Json, required: false }).subscribe();
    }
  }

  getQueueByModel(model: ConfigModel) {
    let queue;
    switch (model) {
      case ConfigModel.Order:
        queue = { ...this.orderQueue };
        break;
      case ConfigModel.RiskReport:
        break;
      default:
        break;
    }

    return queue;
  }

  syncAllOrders() {
    const queueArray: IQueue[] = [];

    Object.keys(this.orderQueue).forEach((orderId: string) => {
      this.addOrderToQueue(orderId, queueArray);
    });

    this.startQueueProcess(queueArray).subscribe();
    this.initialSyncDone = true;
  }

  addOrderToQueue(orderId: string, queueArray) {
    // JSON
    this.orderQueue[orderId].dirty &&
      queueArray.push({
        type: QueueTypes.S3Json,
        model: ConfigModel.Order,
        identifier: orderId,
        required: false,
      });

    // Photos
    const isPhotoPendingSync =
      Object.values(this.orderQueue[orderId][AssetType.Photos]).findIndex((photo: any) => photo.dirty === true) !== -1;
    isPhotoPendingSync &&
      queueArray.push({
        type: QueueTypes.S3Photos,
        model: ConfigModel.Order,
        identifier: orderId,
        required: false,
      });

    // Documents
    const isDocumentPendingSync =
      Object.values(this.orderQueue[orderId][AssetType.Documents]).findIndex(
        (document: any) => document.dirty === true
      ) !== -1;
    isDocumentPendingSync &&
      queueArray.push({
        type: QueueTypes.Documents,
        model: ConfigModel.Order,
        identifier: orderId,
        required: false,
      });

    // CustomerAssets
    const isCustomerAssetPendingSync =
      Object.values(this.orderQueue[orderId][AssetType.CustomerAssets]).findIndex(
        (document: any) => document.dirty === true
      ) !== -1;
    isCustomerAssetPendingSync &&
      queueArray.push({
        type: QueueTypes.CustomerAssets,
        model: ConfigModel.Order,
        identifier: orderId,
        required: false,
      });

    // RiskReports
    const isRiskReportPendingSync =
      Object.values(this.orderQueue[orderId][AssetType.RiskReport]).findIndex(
        (riskReport: any) => riskReport.dirty === true
      ) !== -1;
    isRiskReportPendingSync &&
      queueArray.push({
        type: QueueTypes.RiskReport,
        model: ConfigModel.Order,
        identifier: orderId,
        required: false,
      });
    // RiskReports Attachments
    const isRiskReportAttachmentsPendingSync =
      Object.values(this.orderQueue[orderId][AssetType.RiskReportAttachments]).findIndex(
        (riskReportAttachment: any) => riskReportAttachment.dirty === true
      ) !== -1;
    isRiskReportAttachmentsPendingSync &&
      queueArray.push({
        type: QueueTypes.RiskReportAttachments,
        model: ConfigModel.Order,
        identifier: orderId,
        required: false,
      });
    // Sprinkler Attachments
    const isSprinklerAttachmentsPendingSync =
      Object.values(this.orderQueue[orderId][AssetType.SprinklerReportAttachments]).findIndex(
        (sprinklerReportAttachment: any) => sprinklerReportAttachment.dirty === true
      ) !== -1;
    isSprinklerAttachmentsPendingSync &&
      queueArray.push({
        type: QueueTypes.SprinklerReportAttachments,
        model: ConfigModel.Order,
        identifier: orderId,
        required: false,
      });
    // Forms
    const isFormsPendingSync =
      Object.values(this.orderQueue[orderId][AssetType.Forms]).findIndex((form: any) => form.dirty === true) !== -1;
    isFormsPendingSync &&
      queueArray.push({
        type: QueueTypes.Forms,
        model: ConfigModel.Order,
        identifier: orderId,
        required: false,
      });
  }

  handleRetryError() {
    let retries = 0;
    const attemptLimit = 2;
    return retryWhen((error) => {
      return error.pipe(
        delay(2000),
        concatMap((err) => {
          retries++;
          if (retries < attemptLimit) {
            return of(err);
          } else {
            throw err;
          }
        })
      );
    });
  }

  resetQueueStatus(): void {
    this.callsQueueStatusById = {};
  }

  addMissingOrdersToQueue(orders) {
    orders.forEach((order: OrderModel) => {
      if (!this.orderQueue[order.OrderIdFullOrderNumber]) {
        this.addOrderToSync(order);
      }
    });
  }

  addOrderToSync(order: OrderModel) {
    const newOrder = this.getBaseOrderObject(order);

    this.ionicStorageService.updateOrderQueue(newOrder);
  }

  getBaseOrderObject(order: OrderModel) {
    return {
      id: order.OrderIdFullOrderNumber,
      name: order.InsuredNameDba,
      syncStatus: SyncStatus.Untouched,
      dirty: false,
      orderTouched: false,
      [AssetType.Photos]: {},
      [AssetType.RiskReport]: {},
      [AssetType.RiskReportAttachments]: {},
      [AssetType.SprinklerReportAttachments]: {},
      [AssetType.Documents]: {},
      [AssetType.CustomerAssets]: {},
      [AssetType.Forms]: {},
    };
  }

  async calculateFilesToBeSynced() {
    let filesToBeSynced = 0;
    let ordersToBeSynced = 0;

    await this.localNotificationsService.clearAllNotifications();

    Object.keys(this.orderQueue).forEach((orderId: string) => {
      const orderDetails = this.orderQueue[orderId];
      let orderDirty = false;

      if (orderDetails.dirty) {
        filesToBeSynced++;
        orderDirty = true;
      }

      [AssetType.Documents, AssetType.CustomerAssets, AssetType.Photos, AssetType.RiskReport, AssetType.Forms].forEach(
        (assetType) => {
          Object.keys(orderDetails[assetType]).forEach((assetId: string) => {
            if (orderDetails[assetType][assetId].dirty) {
              filesToBeSynced++;
              orderDirty = true;
            }
          });
        }
      );

      orderDirty && ordersToBeSynced++;
    });

    this.filesToBeSynced.next(filesToBeSynced);
    this.ordersToBeSynced.next(ordersToBeSynced);

    if (this.ordersToBeSynced.value > 0) {
      this.manageNotifications();
    }
  }

  async manageNotifications() {
    if (this.filesToBeSynced.value > 0) {
      this.localNotificationsService.scheduleNotification(
        'Tap to sync orders',
        `${this.filesToBeSynced.value} file${this.filesToBeSynced.value > 1 ? 's' : ''
        } needs to be synced - internet connection is required.`
      );
    }
  }
}
