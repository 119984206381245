/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */
import { Guid } from 'guid-typescript';
import { RiskReportDataWalls } from 'src/app/models/risk-report/risk-report-data.model';

export class WallsConstructionDataModel {
  constructionType: string;
  constructionMaterial: string;
  constructionPercentage: number;
  totalConstructionPercentage: number;
  area: number;   // Calculated field not in RR
  wallIdentifier?: string;
  reportIdentifier?: string;
  //  ReportIdentifier?:string
  isFlameSpreadAbove200?: boolean;
  hasAcceptableThermalBarrier?: boolean;
  hasNote2?: boolean;
  scopesVersion?: number;
  hasMetalCompositeMaterial?: boolean;
  lineNumber?: number;
  thickness?: number;
  wallClassification?: number;
  isReinforcedConcrete?: boolean;
  damagibility?: number;
  hourlyRating?: number; 
  isNoncombustible?: boolean;
  isSlowBurning?: boolean; 
  isMasonryVeneer?: boolean; 
  insulationFlameSpread?: number;
  insulationSmokeDevelopment?: number; 
  isUnlisted?: boolean; 
  combustibilityClass?: string;
  isWoodIronClad?: boolean; 
  fireResistiveListType?: boolean;  //converting string to boolean here
  paintFlameSpread?: number; 
  paintSmokeDevelopment?: number; 
  constructionTypeCodeValue?: string; 
  width?: number; 
  height?: number; 
  isCombustible?: boolean; 
  combustibilityRating?: string; 
  hasFoamPlasticInsulation?: boolean; 
  hasFireRetardantPaint?: boolean;
  massTimberFireResistive?: string; 
  massTimberProtection?: string;
  wallType?: string;
  nCWallType?: string;
  flameSpread?: number;
  smokeDevelopment?: number;
  section?:string;
  // new fields added

 // constructionTypeCode?: ConstructionTypeCode? ; 
//  metalCompositeMaterials?: List<MetalCompositeMaterial>; 

// Need to check
listing?: boolean;
metalCompositeMaterials: any[]; 

backendPresetValueforexistingRisk?: string;  // stores preset value for existing risk when user changes it from Other to new value


/**
   * @deprecated
   * @param alias 
   * @returns 
   */
  static from(alias: RiskReportDataWalls): WallsConstructionDataModel {
    let constructionMaterial;
    let constructionType;
    let wallIdentifier;

    // TODO: Check that this is the right value to read
    // constructionType = alias.ConstTypeCode;

    switch (alias.ConstTypeCV) {
      case 'M':
        constructionType = 'Masonry';
        break;
      case 'C':
        constructionType = 'Combustible';
        break;
      case 'N':
        constructionType = 'Non-combustible/ Slow Burn';
        break;
      case 'D':
        constructionType = 'Fire Resistive';
        break;
      default:
        constructionType = alias.ConstTypeCV;

    }

    // TODO: Fill with the right material
    constructionMaterial = alias.ConstructionMaterial;
    wallIdentifier = alias.WallIdentifier ? alias.WallIdentifier : Guid.create().toString();

    return {
      wallIdentifier: wallIdentifier,
      constructionMaterial: constructionMaterial,
      constructionType: constructionType,
      constructionPercentage: 0,
      totalConstructionPercentage: 0,
      area: 0,
      metalCompositeMaterials:[]
    }
  }

  static to(model: any): any {
    let ConstructionMaterial;
    let ConstructionTypeCodeValue;
    let mcmArray = [];
    let isSlowBurning = false;
    switch (model.constructionType) {
      case 'Masonry':
        ConstructionTypeCodeValue = 'M';
        break;
      case 'Combustible':
        ConstructionTypeCodeValue = 'C';
        break;
      case 'Non-Combustible/Slow Burn':
        ConstructionTypeCodeValue = 'N';
        break;
      case 'Fire Resistive':
        ConstructionTypeCodeValue = 'D';
        break;
      default:
        ConstructionTypeCodeValue = model.constructionType;

    }
    // ConstructionMaterial = model.constructionMaterial 
      if(model?.metalCompositeMaterials?.length>0 && model.hasMetalCompositeMaterial==true && model?.enabledV2){
        model?.metalCompositeMaterials?.forEach((mcmdata) => {
          mcmArray.push({
            FlameSpread: mcmdata?.flameSpread ? mcmdata?.flameSpread : null,
            SmokeDevelopment: mcmdata?.smokeDevelopment ? mcmdata?.smokeDevelopment : null,
            Section: mcmdata?.section ? mcmdata?.section : null,
            // wallIdentifier: mcmdata?.wallIdentifier ? mcmdata?.wallIdentifier : false
          });
      });
    }
    else {
      mcmArray = [];
    }
    if (ConstructionTypeCodeValue === 'C' && model.hasFoamPlasticInsulation && model.hasAcceptableThermalBarrier &&
      (model.insulationFlameSpread && Number(model.insulationFlameSpread) <= 25)) {
      isSlowBurning = true;
    } else {
      isSlowBurning = (ConstructionTypeCodeValue !== 'C' && model.isSlowBurning) ? model.isSlowBurning : false;
    }
    return {
      ConstructionMaterial: model.constructionMaterial,
      ConstructionTypeCodeValue: model?.constructionTypeCodeValue ? model?.constructionTypeCodeValue : (ConstructionTypeCodeValue ? ConstructionTypeCodeValue : null),
      ConstructionPercentage: model.constructionPercentage ? Math.round(model.constructionPercentage) : null,
      WallIdentifier: model.wallIdentifier,
      ReportIdentifier: model.reportIdentifier,
      IsFlameSpreadAbove200: model.isFlameSpreadAbove200 ? model.isFlameSpreadAbove200 : false,
      HasAcceptableThermalBarrier: model.hasAcceptableThermalBarrier ? model.hasAcceptableThermalBarrier : false,   // Backend team: return null
      HasNote2: model.hasNote2 ? model.hasNote2 : false,
      ScopesVersion: model.scopesVersion ? model.scopesVersion : 1,
      HasMetalCompositeMaterial: model.hasMetalCompositeMaterial ? model.hasMetalCompositeMaterial : false,
      LineNumber: model.lineNumber ? model.lineNumber : null,
      Thickness: model.thickness ? +model.thickness : null,
      WallClassification: model.wallClassification ? Number(model.wallClassification) : null,
      IsReinforcedConcrete: model.isReinforcedConcrete ? model.isReinforcedConcrete : false,  
      Damagibility: model.damagibility ? +model.damagibility : null,
      HourlyRating: model.hourlyRating ? model.hourlyRating : null,
      IsNoncombustible: model.isNoncombustible ? model.isNoncombustible : false,
      IsSlowBurning: isSlowBurning,
      IsMasonryVeneer: model.isMasonryVeneer ? model.isMasonryVeneer : false,
      InsulationFlameSpread: model?.insulationFlameSpread,
      InsulationSmokeDevelopment: model?.insulationSmokeDevelopment,
      IsUnlisted: model.isUnlisted ? model.isUnlisted : false,
      CombustibilityClass: model.combustibilityClass ? model.combustibilityClass : null,
      IsWoodIronClad: model.isWoodIronClad ? model.isWoodIronClad : false,
      FireResistiveListType: model.fireResistiveListType === true ? 'R' : (model.fireResistiveListType === false ? 'U' : null),
      PaintFlameSpread: model?.paintFlameSpread,
      PaintSmokeDevelopment: model?.paintSmokeDevelopment,
      Width: model.width !== null && model.width !== undefined && model.width !== '' ? Number(model.width) : null,
      Height: model.height !== null &&  model.height !== undefined &&  model.height !== '' ? Number(model.height) : null,
      IsCombustible: model.isCombustible ? model.isCombustible : false,
      CombustibilityRating: model.combustibilityRating ? model.combustibilityRating : 'NONE',
      HasFoamPlasticInsulation: model.hasFoamPlasticInsulation ? model.hasFoamPlasticInsulation : false,
      HasFireRetardantPaint: model.hasFireRetardantPaint ? model.hasFireRetardantPaint : false,
      MassTimberFireResistive: model.massTimberFireResistive ? model.massTimberFireResistive : null,
      MassTimberProtection: model.massTimberProtection ? model.massTimberProtection : null,
       // new fields added
      MetalCompositeMaterials:mcmArray,
      WallType: model.wallType ? model.wallType : null,

   //   ConstructionTypeCode 
      // MetalCompositeMaterials 
      // Width: model.constructionPercentage ? Number(model.constructionPercentage) : 0       // Backend team: return null
      // area: model.area,  Area is a calculated field, don't return

  listing: model.listing ? model.listing : null,

    }
  }

  /**
   * 
   * @param s3WallData 
   * @returns 
   */
  static fromS3(s3WallData: any): WallsConstructionDataModel {
    let constructionMaterial;
    let constructionType;
    let nCWallType;
    let mcmArray=[];
    switch (s3WallData.ConstructionTypeCodeValue) {
      case 'M':
        constructionType = 'Masonry';
        break;
      case 'C':
        constructionType = 'Combustible';
        break;
      case 'N':
        constructionType = 'Non-Combustible/Slow Burn';
        break;
      case 'D':
        constructionType = 'Fire Resistive';
        break;
      default:
        constructionType = s3WallData.ConstructionTypeCodeValue;

    }

    nCWallType = (s3WallData.IsNoncombustible == true && constructionType === 'Non-Combustible/Slow Burn') ? 'Non-Combustible' : (s3WallData.IsSlowBurning == true && constructionType === 'Non-Combustible/Slow Burn') ? 'Slow Burn' : null;

    // Fill with the right material
    constructionMaterial = s3WallData.ConstructionMaterial;
    if (constructionMaterial === null || constructionMaterial === undefined) {
      constructionMaterial = 'Other';
    }
    if(s3WallData?.MetalCompositeMaterials?.length>0 && s3WallData.HasMetalCompositeMaterial==true){
      s3WallData?.MetalCompositeMaterials?.forEach((mcmdata) => {
        mcmArray.push({
          flameSpread: mcmdata?.FlameSpread ? mcmdata?.FlameSpread : null,
          smokeDevelopment: mcmdata?.SmokeDevelopment ? mcmdata?.SmokeDevelopment : null,
          section: mcmdata?.Section ? mcmdata?.Section : null,
          // wallIdentifier: mcmdata?.wallIdentifier ? mcmdata?.wallIdentifier : false
        });
    });
  }
  else{
   mcmArray=[];
  }
    return {
      wallIdentifier: s3WallData.WallIdentifier ? s3WallData.WallIdentifier : Guid.create().toString(),//wallIdentifier is coming from backend
      reportIdentifier: s3WallData.ReportIdentifier ? s3WallData.ReportIdentifier : undefined,
      isFlameSpreadAbove200: s3WallData.IsFlameSpreadAbove200 ? s3WallData.IsFlameSpreadAbove200 : false,
      hasAcceptableThermalBarrier: s3WallData.HasAcceptableThermalBarrier ? s3WallData.HasAcceptableThermalBarrier : false,   // Backend team: return null
      hasNote2: s3WallData.HasNote2 ? s3WallData.HasNote2 : false,
      lineNumber: s3WallData.LineNumber ? s3WallData.LineNumber : null,                                                     // Backend team: return null
      scopesVersion: s3WallData.ScopesVersion ? s3WallData.ScopesVersion : 1,
      hasMetalCompositeMaterial: s3WallData.HasMetalCompositeMaterial ? s3WallData.HasMetalCompositeMaterial : false,
      // constructionPercentage: s3WallData.Width,
      constructionPercentage: s3WallData.ConstructionPercentage,

      // Calculated values
      constructionMaterial: constructionMaterial,
      constructionType: constructionType,
      totalConstructionPercentage: 0,
      area: 0,

      thickness: s3WallData.Thickness ? s3WallData.Thickness : null,
      wallClassification: s3WallData.WallClassification? s3WallData.WallClassification : null,
      isReinforcedConcrete: s3WallData.IsReinforcedConcrete ? s3WallData.IsReinforcedConcrete : false,  
      damagibility: s3WallData.Damagibility ? s3WallData.Damagibility : null,
      hourlyRating: s3WallData.HourlyRating ? s3WallData.HourlyRating : null,
      isNoncombustible: s3WallData.IsNoncombustible ? s3WallData.IsNoncombustible : false,
      isSlowBurning: s3WallData.IsSlowBurning ? s3WallData.IsSlowBurning : false,
      isMasonryVeneer: s3WallData.IsMasonryVeneer ? s3WallData.IsMasonryVeneer : false,
      insulationFlameSpread: s3WallData?.InsulationFlameSpread,
      insulationSmokeDevelopment: s3WallData?.InsulationSmokeDevelopment,
      isUnlisted: s3WallData.IsUnlisted ? s3WallData.IsUnlisted : false,
      combustibilityClass: s3WallData.CombustibilityClass ? s3WallData.combustibilityClass : null,
      isWoodIronClad: s3WallData.IsWoodIronClad ? s3WallData.IsWoodIronClad : false,
      fireResistiveListType: s3WallData.FireResistiveListType === 'R' ? true : (s3WallData.FireResistiveListType === 'U' ? false : null),
      paintFlameSpread: s3WallData?.PaintFlameSpread,
      paintSmokeDevelopment: s3WallData?.PaintSmokeDevelopment,
      width: s3WallData.Width !== null && s3WallData.Width !== undefined && s3WallData.Width !== '' ? Number(s3WallData.Width) : null,
      height: s3WallData.Height !== null && s3WallData.Height !== undefined && s3WallData.Height !== '' ? Number(s3WallData.Height) : null,
      isCombustible: s3WallData.IsCombustible ? s3WallData.IsCombustible : false,
      combustibilityRating: s3WallData.CombustibilityRating ? s3WallData.CombustibilityRating : null,
      hasFoamPlasticInsulation: s3WallData.HasFoamPlasticInsulation ? s3WallData.HasFoamPlasticInsulation : false,
      hasFireRetardantPaint: s3WallData.HasFireRetardantPaint ? s3WallData.HasFireRetardantPaint : false,
      massTimberFireResistive: s3WallData.MassTimberFireResistive ? s3WallData.MassTimberFireResistive : null,
      massTimberProtection: s3WallData.MassTimberProtection ? s3WallData.MassTimberProtection : null,
      metalCompositeMaterials:mcmArray,
      constructionTypeCodeValue: s3WallData?.constructionTypeCodeValue ? s3WallData?.constructionTypeCodeValue : null,

  listing: s3WallData.listing ? s3WallData.listing : null,
  wallType: s3WallData.WallType ? s3WallData.WallType : null,
  nCWallType : nCWallType,
    }
  }
}
