import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { WallsConstructionDataModel } from 'src/app/models/formly-builder/formly/walls-construction-data.model';
import { WallsDataModel } from 'src/app/models/formly-builder/formly/walls-data.model';
import { FormlyBuilderService } from './formly-builder.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { takeUntil } from 'rxjs/operators';
import { WallsReportDataModel } from 'src/app/models/formly-builder/formly/full-risk/walls-report-data.model';
@Injectable({
  providedIn: 'root',
})
export class FormlyWallsService extends SectionServiceAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super();
  }

  public getConstructionTypeLookupData(): Observable<any> {
    // TODO: Replace when lookup data available
    return of([
      { value: 'Masonry', label: 'Masonry' },
      { value: 'Fire Resistive', label: 'Fire Resistive' },
      { value: 'Non-Combustible/Slow Burn', label: 'Non-Combustible/Slow Burn' },
      { value: 'Combustible', label: 'Combustible' },
    ]);
  }

  public getConstructionMaterialLookupData(): Observable<any> {
    return of([
      { value: 'Reinforced Concrete Frame', label: 'Reinforced Concrete Frame', type: 'Masonry' },
      { value: 'Reinforced Concrete Solid', label: 'Reinforced Concrete Solid', type: 'Masonry' },
      { value: 'Brick', label: 'Brick', type: 'Masonry' },
      { value: 'Tilt Wall', label: 'Tilt Wall', type: 'Masonry' },
      { value: 'Tilt Wall w/ Brick Face', label: 'Tilt Wall w/ Brick Face', type: 'Masonry' },
      { value: 'Tilt Wall w/ Stone Face', label: 'Tilt Wall w/ Stone Face', type: 'Masonry' },
      { value: 'Natural Stone', label: 'Natural Stone', type: 'Masonry' },
      { value: 'Precast Concrete Frame', label: 'Precast Concrete Frame', type: 'Masonry' },
      { value: 'Hollow Concrete Block', label: 'Hollow Concrete Block', type: 'Masonry' },
      { value: 'HCB w/Brick Face', label: 'HCB w/Brick Face', type: 'Masonry' },
      { value: 'HCB w/Stone Face', label: 'HCB w/Stone Face', type: 'Masonry' },
      { value: 'Clay Tile', label: 'Clay Tile', type: 'Masonry' },
      { value: 'Gypsum Block', label: 'Gypsum Block', type: 'Masonry' },
      { value: 'Adobe', label: 'Adobe', type: 'Masonry' },
      { value: 'Other Masonry Assemblies', label: 'Other Masonry Assemblies', type: 'Masonry' },
      { value: 'Protected Steel', label: 'Protected Steel', type: 'Fire Resistive' },
      { value: 'Masonry Protected Steel', label: 'Masonry Protected Steel', type: 'Fire Resistive' },
            { value: 'Other Fire Resistive Assemblies', label: 'Other Fire Resistive Assemblies', type: 'Fire Resistive' },
      { value: 'Masonry Veneer On Steel Supports', label: 'Masonry Veneer On Steel Supports', type: 'Non-Combustible/Slow Burn' },
      { value: 'Metal Panels on Steel Supports', label: 'Metal Panels on Steel Supports', type: 'Non-Combustible/Slow Burn' },
      { value: 'Glass in Steel Frame w/Steel Supports', label: 'Glass in Steel Frame w/Steel Supports', type: 'Non-Combustible/Slow Burn' },
      { value: 'Open Steel Frame', label: 'Open Steel Frame', type: 'Non-Combustible/Slow Burn' },
      {
        value: 'Other Non-Comb. / Limited Comb. Assemblies', label: 'Other Non-Comb. / Limited Comb. Assemblies',
        type: 'Non-Combustible/Slow Burn'
      },
      { value: 'Wood Iron Clad (WIC)', label: 'Wood Iron Clad (WIC)', type: 'Combustible' },
      { value: 'Wood, Vinyl, or Stucco on Wood Supports', label: 'Wood, Vinyl, or Stucco on Wood Supports', type: 'Combustible' },
      { value: 'Masonry Veneer on Wood Supports', label: 'Masonry Veneer on Wood Supports', type: 'Combustible' },
      { value: 'Wood Panels on Steel Supports', label: 'Wood Panels on Steel Supports', type: 'Combustible' },
      { value: 'Foam Sandwich Panels', label: 'Foam Sandwich Panels', type: 'Combustible' },
      { value: 'Structural Insulated Panels', label: 'Structural Insulated Panels', type: 'Combustible' },
      { value: 'EIFS on Metal or Wood Supports', label: 'EIFS on Metal or Wood Supports', type: 'Combustible' },
      {
        value: 'Metal Panels on Steel Supports w/interior comb', label: 'Metal Panels on Steel Supports w/interior comb',
        type: 'Combustible'
      },
      { value: 'Metal Panels on Steel Supports w/foam insul.', label: 'Metal Panels on Steel Supports w/foam insul.', type: 'Combustible' },
      { value: 'Comb Assemblies w/foam insul.', label: 'Comb Assemblies w/foam insul.', type: 'Combustible' },
      { value: 'Mass Timber', label: 'Mass Timber', type: 'Combustible' },
      { value: 'Other Combustible Assemblies', label: 'Other Combustible Assemblies', type: 'Combustible' },
    ]);
  }

  async updateData(model, riskReportData) {
    // this.formlyBuilder.riskReport.updateData(address);
    // Check if data has changed
    const hasChanged: boolean = WallsDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      const walls = WallsDataModel.to(model.walls);
      return { ...riskReportData, ...walls };
    } else {
      return undefined;
    }
  }


  loadData(walls: any[], fullData: any, isS3: boolean, model: any): any {
    const wallsModel: WallsDataModel = {
      wallsArray: [],
    };
    let totalArea = 0;
    let wallRecord;
    if (isS3) {
      wallRecord = WallsDataModel.fromS3(walls);
    } else {
      wallRecord = WallsDataModel.from(walls);
    }
    if (wallRecord?.wallsArray?.length > 0) {
      wallRecord.wallsArray.forEach((wall) => {
        let myWall;
        let wallentry;
        if (isS3) {
          wallentry = wall;
          myWall = WallsConstructionDataModel.fromS3(wall);
        } else {
          wallentry = wall.data;
          myWall = WallsConstructionDataModel.from(wall.data);
        }

        // TODO: Avoid 0 area. Replace with 1.
        myWall.area = wallentry.Height * wallentry.Width;
        if (!myWall.area) {
          myWall.area = 1;
        }

        totalArea += myWall.area;
        wallsModel.wallsArray.push(myWall);
      });
    }
    // TODO: Calculate the percentage

    let sumOfConstructionPercentage = 0;
    if (this.formlyBuilderService.mode !== 1) {
      wallsModel?.wallsArray?.forEach((wall: WallsConstructionDataModel, index) => {
        if (index < wallsModel?.wallsArray?.length) {
          wall.constructionPercentage = Math.round((wall.area / totalArea) * 100);
          sumOfConstructionPercentage += wall.constructionPercentage;
        }
        if (index === wallsModel.wallsArray.length - 1 && sumOfConstructionPercentage > 100) {
          const diff = sumOfConstructionPercentage - 100;
          wall.constructionPercentage = wall.constructionPercentage - diff;
        }
      });
    }
    else {
      wallsModel?.wallsArray?.forEach((wall: WallsConstructionDataModel, index) => {
        sumOfConstructionPercentage += wall.constructionPercentage;

      });
      wallsModel.wallsArray.forEach((wall: WallsConstructionDataModel, index) => {

        wall.totalConstructionPercentage = sumOfConstructionPercentage;
      });
    }

    // Sorting the WallsModel based on Provied Order (SortOrder)
    const sortOrder = [ 'Masonry', 'Fire Resistive', 'Non-Combustible/ Slow Burn', 'Combustible' ];
    const sorter = (value1: WallsConstructionDataModel, value2: WallsConstructionDataModel) => {
      const indexOfValue1 = sortOrder.indexOf(value1.constructionType);
      const indexOfValue2 = sortOrder.indexOf(value2.constructionType);
      if (indexOfValue1 !== -1 && indexOfValue2 !== -1) {
        return indexOfValue1 - indexOfValue2;
      } else {
        if (indexOfValue1 === -1) {
          return wallsModel.wallsArray.length - indexOfValue2;
        } else if (indexOfValue2 === -1) {
          return indexOfValue1 - wallsModel.wallsArray.length;
        }
      }
    };
    wallsModel.wallsArray.sort(sorter);

    // Grouping walls based on constructionType
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    let index = 0;
    for (let i = 1; i < wallsModel.wallsArray.length; i++) {
      if (wallsModel.wallsArray[ index ].constructionType === wallsModel.wallsArray[ i ].constructionType &&
        wallsModel.wallsArray[ index ].constructionMaterial === wallsModel.wallsArray[ i ].constructionMaterial) {
        wallsModel.wallsArray[ index ].area += wallsModel.wallsArray[ i ].area;
        wallsModel.wallsArray[ index ].constructionPercentage += wallsModel.wallsArray[ i ].constructionPercentage;
        wallsModel.wallsArray.splice(i, 1);
        i--;
      } else {
        index++;
      }
    }

    if (walls?.length) {
      return { ...model, ...{ totalArea }, ...{ walls: wallsModel } };
    }
    return null;
    //}
    // else {
    //   return null;
    // }
  }
  public updatePercentageOccupied(walls) {
    let wallsPercentageTotal = 0;
    walls.wallsArray.forEach((wall, wallIndex) => {
      if (wall.isDeleted !== true) {
        wallsPercentageTotal += wall.constructionPercentage;
      }
    });
    walls.wallsArray.forEach((wall, wallIndex) => {
      if (wall.isDeleted !== true) {
        wall.totalConstructionPercentage = wallsPercentageTotal;
      }
    });
    this.formlyBuilderService.riskReport.model = {...this.formlyBuilderService.riskReport.model};
  }
  onConstructionypeChange(field: FormlyFieldConfig) {
    field.formControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res && res.length > 0 && field.options.formState.service.isSheetActive) {
          //field.form.get('constructionType').setValue(null);
          field.form.get('constructionMaterial').setValue(null);
        }
      });
  }
  uuid() {
    // TODO: Move to utils file
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }
  public totalPercentageOccupied(walls) {
    let constructionPercentageCalculated = 0;
    const nonDeletedWalls = walls.value.wallsArray?.filter(wall=>!(wall.isDeleted));
    if (nonDeletedWalls[ 0 ].totalConstructionPercentage < 100) {// last value for slider should be 0,if total is 100
      walls.value.wallsArray.forEach((wall, wallIndex) => {
        if (wall.isDeleted !== true) {
          wall.totalConstructionPercentage = 100;
          constructionPercentageCalculated += wall.constructionPercentage;
        }
      });
      walls.controls.wallsArray.controls[ walls.value.wallsArray.length - 1 ].get('constructionPercentage').setValue(100 - constructionPercentageCalculated);
    }
  }
  public getwalltypeLookupData(): Observable<any> {
    // TODO: Replace when lookup data available
    return of([
      { value: 'Non-Combustible', label: 'Non-Combustible' },
      { value: 'Limited Combustible', label: 'Limited Combustible' }
    ]);
  }
  public testModal: WallsReportDataModel = new WallsReportDataModel();
}