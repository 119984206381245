import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { SectionEnum } from '../../section.config';
import { UtilService } from 'src/app/services/util-service/util.service';
import { FormlyBuilderService } from '../../formly-builder.service';
import { InternalSprinklerPipeConditionsDataModel } from 'src/app/models/formly-builder/formly/full-risk/internal-pipe-conditions-data.modet';


// Internal Sprinkler Pipe Sheet data needs to be saved when user hits on apply in Internalpipecondition sheet
// (i.e) In Case of Internal pipe condition there will be two sheets one top of another,
// When user hits apply on the top level sheet, it should call the updateData method and the changes needs to be saved to s3
@Injectable({
  providedIn: 'root',
})
export class InternalSprinklerPipeConditionsService extends SectionServiceAbstract {

  constructor(private formlyBuilderService: FormlyBuilderService,
    private utilService: UtilService) {
    super();
  }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    // Will get Called only when user hits apply internal pipe Sprinkler Test sheet
    const InternalSprinklerPipeConditions = [];
    model?.sprinklerReport?.asgrSprinklerSystemTest?.dryPipeValveTesting?.internalSprinklerPipeConditions?.forEach((internalPipeCondition) => {
      const InternalPipe = InternalSprinklerPipeConditionsDataModel.to(internalPipeCondition);
      InternalPipe.InspectionDate = this.utilService.convertDateToISOStringFormat(InternalPipe.InspectionDate);
      InternalPipe.InitialServiceDate = this.utilService.convertDateToISOStringFormat(InternalPipe.InitialServiceDate);
      InternalSprinklerPipeConditions.push(InternalPipe);
    });
    const newRr = {
      ...riskReportData,
      SprinklerReport: {
        ...riskReportData.SprinklerReport,
        InternalSprinklerPipeConditions
      }
    };
    return { ...riskReportData, ...newRr };
  }

  loadData(sectionData: any[], reportInfo: any, isS3: boolean, model: any): any {
    // Will get Called only when user hits apply inside internal pipe condition sheet or hit close inside Dry pipe valve trip test sheet
    const InternalSprinklerPipeConditions = sectionData;
    const internalPipeCondition = [];
    if (InternalSprinklerPipeConditions && InternalSprinklerPipeConditions.length) {
      InternalSprinklerPipeConditions.forEach((test) => {
        const internalPipeConditionData = InternalSprinklerPipeConditionsDataModel.fromS3(test);
        internalPipeCondition.push(internalPipeConditionData);
      });
    }

    const newModel = {
      ...model,
      sprinklerReport: {
        ...model.sprinklerReport,
        asgrSprinklerSystemTest: {
          ...model.sprinklerReport.asgrSprinklerSystemTest,
          dryPipeValveTesting: {
            ...model.sprinklerReport.asgrSprinklerSystemTest.dryPipeValveTesting,
            ...{ internalSprinklerPipeConditions: internalPipeCondition }
          }
        }
      }
    };
    if (newModel) {
      this.formlyBuilderService.riskReport.model = { ...newModel };
    }
    return this.formlyBuilderService.riskReport.model;
  }


  async onApply(modalCtrl, field) {
    // When apply we need to save the value to S3 here
    const internalSprinklerPipeConditionsService = field.options.formState.service.internalSprinklerPipeConditionsService;
    const riskReportService = field.options.formState.riskReportService;
    const rr = await riskReportService.updateSectionData(SectionEnum.FR_SR_ST_INTERNAL_PIPE_CONDITION, false, null, InternalSprinklerPipeConditionsService);

    // After hits apply in the sheet, It needs to be sorted based on most recent date
    let InternalSprinklerPipeConditions = rr?.SprinklerReport?.InternalSprinklerPipeConditions;
    InternalSprinklerPipeConditions = field.options.formState.service.sprinklerReportService.sortArrayWithMostRecentDate(InternalSprinklerPipeConditions, 'InspectionDate');

    // loadData to set the sorted value back to model
    internalSprinklerPipeConditionsService.loadData(InternalSprinklerPipeConditions, rr, true, riskReportService.model);

    // if dpvTestDate is empty populate with most recent  initialPipeExamTestDate date but enabled, when user applies initial PipeExamTest Date sheet.
    setTimeout(() => {
      if (InternalSprinklerPipeConditions && InternalSprinklerPipeConditions.length) {
        if (InternalSprinklerPipeConditions[ length ].InspectionDate && !field?.form?.get('initialPipeExamTestDate').value) {
          field?.form?.get('initialPipeExamTestDate')?.setValue(InternalSprinklerPipeConditions[ length ].InspectionDate);
        }
      }
    }, 100);
    modalCtrl.dismiss(null, 'save');
  }

  onClose(modalCtrl, field, initialModel) {
    // Tried with patchValue and resetModel
    // Tried with reset and patchValue with forLoop for each array values
    // The above two didin't help so going with load Data on close
    const formlyBuilderService = field.options.formState.service;
    const riskReportService = field.options.formState.riskReportService;
    const formlyModel = riskReportService.model;
    const rr = riskReportService.report;
    formlyBuilderService.internalSprinklerPipeConditionsService.loadData(rr?.SprinklerReport?.AsgrSprinklerSystemTest?.InternalSprinklerPipeConditions, rr, true, formlyModel);
    formlyBuilderService.errorService.closePreviousToaster();
    modalCtrl.dismiss(null, 'cancel');
  }

}
