/* eslint-disable @typescript-eslint/naming-convention */
export enum SoftDisableFieldList {
    // Key Value from Formly Section Model
    // BuildingNameSectionModel.key
    ADDRESS = 'address',
    OCCUPANTS = 'section-occupants',
    ROOFTOP = 'rooftop',
    WALLS = 'walls',
    FLOORSANDROOF = 'floorsAndRoofs',
    PHOTOS = 'reportPhotos',
    ROOFTOPPLACEMENT = 'roofTopLocationPoint'
}

export enum CurrencyDisabledList {
    // Key Value from Formly Section Model
    // BuildingNameSectionModel.key
    ADDRESS = 'address',
    WALLS = 'walls',
    FLOORSANDROOF = 'floorsAndRoofs'
}

export enum GeneralInformationDisableList {
    YEARBUILT = 'yearBuilt',
}
