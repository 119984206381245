import { Directive, ElementRef, Renderer2, Input } from '@angular/core';
import { Analytics } from 'aws-amplify';
@Directive({
  selector: '[analytics]',
})
export class AnalyticsDirective {
  @Input('analytics') options: Analytics;
  private dispose: Function;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  ngOnInit() {
    this.dispose = this.renderer.listen(this.elementRef.nativeElement, this.options.event, (e) => {
      Analytics.record({
        name: this.options.name,
        attributes: { eventName: this.options.event, attributes: this.options.attributes },
      });
    });
  }

  ngOnDestroy() {
    this.dispose();
  }
}

interface Analytics {
  name: string;
  event: string;
  attributes?: any;
}
