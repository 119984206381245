/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { OccupantsDataModel } from 'src/app/models/formly-builder/formly/occupants-data.model';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { OccupantsSectionDataModel } from 'src/app/models/formly-builder/formly/occupants-section-data.model';
import { ScheduleCodeService } from 'src/app/services/formly-builder/schedule-tree.service';
import { FormlyBuilderService } from './formly-builder.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { map, startWith } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Constants } from 'src/app/services/util-service/constants';
import { RiskModeEnum } from 'src/app/enums/formly-builder/risk-mode.enum';
@Injectable({
  providedIn: 'root',
})
export class FormlyOccupantsService extends SectionServiceAbstract {
  // ngUnsubscribe = new Subject<void>();
  isAdd: boolean;
  aboveGradeFloorsValue: number;
  isOccupantSheetClosed: boolean;

  private dataSource = new BehaviorSubject(undefined);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  occupantActionData = this.dataSource.asObservable();

  constructor(
    private http: HttpClient,
    private scheduleService: ScheduleCodeService,
    private formlyBuilderService: FormlyBuilderService
  ) {
    super();
  }

  // clean(): void {
  //   this.ngUnsubscribe.next();
  //   this.ngUnsubscribe.complete();
  // }

  setAddOccupantFlag(isAdd: boolean) {
    this.isAdd = isAdd;
  }

  getAddOccupantFlag() {
    return this.isAdd;
  }

  setOccupantSheetClosedFlag(flag: boolean) {
    this.isOccupantSheetClosed = flag;
  }

  getOccupantSheetClosedFlag() {
    return this.isOccupantSheetClosed;
  }

  /**
   * Get the maximum for an array of levels of the levelStart or levelStop for a specific level type ('FLOO', 'BASE')
   *
   * @param allLevels Array of levels
   * @param floorName Can be 'FLOO' or 'BASE'
   * @returns
   */
  getMaxLevel(allLevels: any[], floorName: string): number {
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    return Math.max(
      ...allLevels.map(function (level) {
        if (level.name === floorName && level.levelStart) {
          return Math.max(level.levelStart, level.levelStop);
        }
        return 0;
      })
    );
  }

  loadData(occupants: any[], fullData: any, isS3: boolean, model: any): any {
    const occupantsModel: OccupantsDataModel[] = [];
    let totalArea = 0;
    let aboveGradeFloors = 0;
    let basementLevels = 0;

    if (!occupants) {
      console.debug('Unexpected empty occupant list\n' + fullData);
      return null;
    }

    if (this.formlyBuilderService.mode === 1 && occupants.length === 0) {
      // const newModel = { ...model, ...{ occupants: occupantsModel } };

      // For now add occupants to section-occupants as well
      const newModel = {
        ...model,
        'section-occupants': {
          // eslint-disable-next-line object-shorthand
          aboveGradeFloors: aboveGradeFloors,
          // eslint-disable-next-line object-shorthand
          basementLevels: basementLevels,
          // eslint-disable-next-line object-shorthand
          totalArea: totalArea,
          occupants: [ ...occupantsModel ],
        },
      };
      return newModel;
    }

    occupants.forEach((occupant) => {
      let occupantData;
      if (isS3) {
        occupantData = OccupantsDataModel.fromS3(occupant);
        occupantsModel.push(occupantData);
      } else {
        const occupantRecord = occupant.data;
        occupantData = OccupantsDataModel.from(occupantRecord);
        occupantsModel.push(occupantData);
      }
      const scheduleDetails: any = this.scheduleService.getScheduledetails(occupantData.occupantScheduleNo);
      if (scheduleDetails) {
        occupantData.occupantType = scheduleDetails?.OccupantType;
        occupantData.commercialStatisticalPlan = scheduleDetails?.CSP;
      }
      if (!occupant.IsDeleted) {
        totalArea += occupantData.totalArea;
        const occupantAboveGradeFloors = this.getMaxLevel(occupantData.occupantLevels, 'Floor');
        if (occupantAboveGradeFloors > aboveGradeFloors) {
          aboveGradeFloors = occupantAboveGradeFloors;
        }
        const occupantBasementLevels = this.getMaxLevel(occupantData.occupantLevels, 'Basement');
        if (occupantBasementLevels > basementLevels) {
          basementLevels = occupantBasementLevels;
        }
      }
    });

    occupantsModel.forEach((occupantLevel) => {
      if (occupantLevel.occupantLevels.length) {
        occupantLevel.occupantLevels.sort((a, b) => Number(a.levelStart) - Number(b.levelStart));
      }
      // console.log("OccupantsService "+occupantsModel);
    });
    const occupantLevels = this.getOccupantLevels(occupantsModel);
    const summarizedArray = this.calculatePercentageOccupiedForEachFloor(occupantLevels);
    summarizedArray.forEach((item) => {
      occupantsModel.forEach((occ) => {
        const occLevelArr = occ.occupantLevels;
        occLevelArr.forEach((occLevel) => {
          if (occLevel.floorNo === item.floorNo) {
            occLevel.percentageOccupied = item.percentageOccupied;
          }
        });
      });
    });

    if (occupants.length) {
      // const newModel = { ...model, ...{ occupants: occupantsModel } };

      // For now add occupants to section-occupants as well
      const newModel = {
        ...model,
        'section-occupants': {
          // eslint-disable-next-line object-shorthand
          aboveGradeFloors: aboveGradeFloors,
          // eslint-disable-next-line object-shorthand
          basementLevels: basementLevels,
          // eslint-disable-next-line object-shorthand
          totalArea: this.calculateTotalArea(occupantsModel),
          occupants: [ ...occupantsModel ],
        },
      };
      return newModel;
    }
    return null;
  }

  async updateData(model: any, riskReportData: any): Promise<any> {
    // Check if data has changed
    const hasChanged: boolean = OccupantsSectionDataModel.hasChanged(model, riskReportData);
    // Check section-occupants data
    // Check occupants data
    // Check level data for every occupant
    if (hasChanged) {
      const occupants = OccupantsSectionDataModel.to(model[ 'section-occupants' ]);
      return { ...riskReportData, ...occupants };
    } else {
      return undefined;
    }
  }

  getLevelOptions(minValue, maxValue): Observable<any> {
    const optionsArr = [];
    for (let i = minValue; i <= maxValue; i++) {
      optionsArr.push({ label: i, value: i });
    }
    // console.log("getLevelStartOptions "+optionsArr);
    return of(optionsArr);
  }

  getFloorsOptions(minFloors, maxFloors, minBase, maxBase, mezzArray, atticArray): Observable<any> {
    const arr = [
      { minValue: minFloors, maxValue: maxFloors, levelType: 'Floor' },
      { levelType: 'Attic', arrayValues: atticArray },
      { arrayValues: mezzArray, levelType: 'Mezzanine' },
      { minValue: minBase, maxValue: maxBase, levelType: 'Basement' },
    ];
    const optionsArr = [];
    arr.forEach((obj) => {
      if (obj.levelType == 'Floor' || obj.levelType == 'Basement') {
        for (let i = obj.minValue; i <= obj.maxValue; i++) {
          optionsArr.push({ label: obj.levelType + ' ' + i, value: obj.levelType + '' + i });
        }
      } else if (obj.levelType == 'Mezzanine' || obj.levelType == 'Attic') {
        obj.arrayValues?.forEach((arrayVal) => {
          optionsArr.push({ label: obj.levelType + ' ' + arrayVal, value: obj.levelType + '' + arrayVal });
        });
      }
    });
    return of(optionsArr);
  }

  public getMaxValue(val, field) {
    const maxLevels = 1;
    if (val === 'Floor') {
      return field.form.parent.parent.parent.parent.parent.value.floorsAndRoofs.aboveGradeFloors;
    } else if (val === 'Basement') {
      return field.form.parent.parent.parent.parent.parent.value.floorsAndRoofs.basementLevels;
    } else if (val === 'Attic') {
      if (this.formlyBuilderService.mode === 1) {
        return field.options.formState.service.riskReport.model.floorsAndRoofs.numberOfAttics;
      } else if (this.formlyBuilderService.mode === 0) {
        return field.form.parent.parent.parent.parent.parent.value.floorsAndRoofs.aboveGradeFloors;
      }
    } else if (val === 'Mezzanine') {
      if (this.formlyBuilderService.mode === 1) {
        return field.options.formState.service.riskReport.model.floorsAndRoofs.numberOfMezzanine;
      } else if (this.formlyBuilderService.mode === 0) {
        return field.form.parent.parent.parent.parent.parent.value.floorsAndRoofs.aboveGradeFloors;
      }
    }
    return maxLevels;
  }

  public getMinValue(val, field) {
    const floorsRoofArray =
      field.form.root.controls.floorsAndRoofs.controls.constructionEntries.controls.floorsAndRoofsLevels.value;
    let minValue = 1;
    floorsRoofArray.filter((item) => {
      if (item.levelType === val && item.levelStart > minValue) {
        minValue = item.levelStart;
      }
    });
    return minValue;
  }

  public getMezzAtticArray(val, field) {
    let combinedArray = [];
    const floorsRoofArray =
      field.form.root.controls.floorsAndRoofs.controls.constructionEntries.controls.floorsAndRoofsLevels.value;
    const levelsList = floorsRoofArray
      ?.filter((src) => src.levelType === val)
      ?.map((level) => {
        if (level && level.levelStop && Number(level.levelStop) > 0) {
          return Array.from(
            { length: Number(level.levelStop) - Number(level.levelStart) + 1 },
            (value, index) => Number(level.levelStart) + index
          );
        } else if (level && level.levelStart && Number(level.levelStart) > 0) {
          return [ Number(level.levelStart) ];
        } else {
          return [];
        }
      });
    levelsList?.forEach((x) => (combinedArray = combinedArray.concat(x)));
    combinedArray = combinedArray?.filter((item, index) => combinedArray.indexOf(item) === index);
    return combinedArray?.sort();
  }

  public initFieldSubscription(field, fieldKey) {
    // For inputFields we want a debounceTime
    field.formControl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(1000), distinctUntilChanged())
      .subscribe((val) => {
        if (val !== null && !Number.isNaN(val) && val !== field.model[ fieldKey ]) {
          console.warn('Formly Model not saved for occupantLevels.' + fieldKey);
          const levelIndex: number = +field.parent.key;
          const occupantIndex: number = +field.parent.parent.parent.key;
          const riskReportService = field.options.formState.riskReportService;
          const newModel = riskReportService.model;
          newModel[ 'section-occupants' ].occupants[ occupantIndex ].occupantLevels[ levelIndex ][ fieldKey ] = val;
          riskReportService.model = { ...riskReportService.model, newModel };
        }
      });
  }

  public getLevelType(value) {
    let levelType;
    switch (value.replace(/[0-9]/g, '')) {
      case 'Floor': {
        levelType = 'Floor';
        break;
      }
      case 'Basement': {
        levelType = 'Basement';
        break;
      }
      case 'Attic': {
        levelType = 'Attic';
        break;
      }
      case 'Mezzanine': {
        levelType = 'Mezzanine';
        break;
      }

      default: {
        break;
      }
    }
    return levelType;
  }

  public fixFormlyBug(field, fieldKey) {
    field.formControl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(1000), distinctUntilChanged())
      .subscribe((val) => {
        // const val = field.formControl.value;
        if (val !== null && val !== field.model[ fieldKey ]) {
          console.warn('Formly Model not saved for: ' + fieldKey);
          // const levelIndex: number = +field.parent.key;
          const occupantIndex: number = +field.parent.key;
          const riskReportService = field.options.formState.riskReportService;
          const newModel = riskReportService.model;
          newModel[ 'section-occupants' ].occupants[ occupantIndex ][ fieldKey ] = val;
          riskReportService.model = { ...riskReportService.model, newModel };
        }
      });
  }

  public initLevelTypes(field) {
    const ifBaseExists = field.form.parent.parent.parent.parent.parent.value.floorsAndRoofs.basementLevels;
    if (ifBaseExists) {
      field.props.options = [
        { value: 'Floor', label: 'Floor' },
        { value: 'Basement', label: 'Basement' },
        { value: 'Mezzanine', label: 'Mezzanine' },
        { value: 'Attic', label: 'Attic' },
      ];
    } else {
      field.props.options = [
        { value: 'Floor', label: 'Floor' },
        { value: 'Mezzanine', label: 'Mezzanine' },
        { value: 'Attic', label: 'Attic' },
      ];
    }
  }

  /**
   * @param FormlyFieldConfig field
   * @param string commercialStatisticalPlan
   * @memberof FormlyOccupantsService
   * @Method setCommercialStatisticalPlan  - to set CommercialStatisticalPlan value to the model
   */
  public setScheduledetails(field: FormlyFieldConfig, scheduleNo: string): void {
    const scheduleDetails: any = this?.scheduleService?.getScheduledetails(scheduleNo);
    if (scheduleDetails) {
      const occupantType = field?.form?.get('occupantType');
      occupantType?.setValue(scheduleDetails?.OccupantType);
      const csp = field?.form?.get('commercialStatisticalPlan');
      csp?.setValue(scheduleDetails?.CSP);
      
      if (field.options.formState.service.mode === RiskModeEnum.FULLRISK) {
        this.setCspValue(field);
        const spoc = field?.form?.get('spoc');
        spoc?.setValue(scheduleDetails?.SPOC);
        const su = field?.form?.get('susceptibilityClass');
        su?.setValue(scheduleDetails?.SU && scheduleDetails?.SU);
        const suEdit = field?.form?.get('susceptibilityClassEidtField');
        suEdit?.setValue(scheduleDetails?.SU && scheduleDetails?.SU ? scheduleDetails?.SU : undefined);
        const eq = field?.form?.get('earthQuake');
        eq?.setValue(scheduleDetails?.EQ);
        // const cb = field?.form?.get('combustibilityClass');
        // cb?.setValue(scheduleDetails?.CB);
      }
    }
    else if (scheduleDetails === null) {
      const occupantType = field?.form?.get('occupantType');
      occupantType?.setValue('');
      const csp = field?.form?.get('commercialStatisticalPlan');
      csp?.setValue('');
      if (field.options.formState.service.mode === RiskModeEnum.FULLRISK) {
        const spoc = field?.form?.get('spoc');
        spoc?.setValue('');
        const eq = field?.form?.get('earthQuake');
        eq?.setValue('');
        const cb = field?.form?.get('combustibilityClass');
        cb?.setValue('');
        const su = field?.form?.get('susceptibilityClass');
        su?.setValue('');
        const suEdit = field?.form?.get('susceptibilityClassEidtField');
        suEdit?.setValue('');
      }
    }

    field.options.formState.service.riskReport.model.occupants = {
      ...field.options.formState.service.riskReport.model.occupants,
      ...field.form?.root?.value?.occupants
    };
  }
  setCspValue(field: FormlyFieldConfig){
   // if(field.options.formState.service.mode === RiskModeEnum.FULLRISK){
    const address = field.form.root.value.address;
    const schedulenumber =field.parent?.model?.occupantScheduleNo;
    if (address && address.mainAddress  && schedulenumber) {
      const state = address.mainAddress?.state;
      if (state && state === 'MA' && schedulenumber === '6104110000') {
             field.form.get('commercialStatisticalPlan')?.setValue('2805');
             field.form.get('isCommercialStatisticalPlanOverride')?.setValue(true)
      }
    }
    //}
   
  }
  setCspCheckboxEnable(field: FormlyFieldConfig){
    const occSN = field.form.controls['occupantScheduleNo'].value
    const enableScheduleNoList =["5551100000","5551200000","6063100000","6063200000","6073100000","6073200000","6125810000","6125820000","6125830000"]
    if (enableScheduleNoList.includes(occSN)){
      field.form.get('isCommercialStatisticalPlanOverride')?.setValue(true);
    }
    else if(occSN?.startsWith('560')) {
      field?.form.get('isCommercialStatisticalPlanOverride')?.setValue(false);
      
    }
   else  {
      field.form.get('isCommercialStatisticalPlanOverride')?.setValue(false);
    }
  }

  /**
   * Get the total area for an array of occupants
   *
   * @param model Model of occupants
   * @returns
   */
  public calculateTotalArea(model) {
    const occupantsLevelArray = []; // an array of all levels from all occupants
    const floorsPerLevelAreaArray = []; // an array of area per level for all floors from all occupants
    let totalArea = 0;
    // filters levels from non deleted occupants
    model.forEach((occupant) => {
      if (!occupant.isDeleted) {
        occupant.occupantLevels.forEach((level) => {
          occupantsLevelArray.push(level);
        });
      }
    });
    const mezzanineArray = [];
    occupantsLevelArray.forEach((level) => {
      if (level.levelStop > level.levelStart) {
        for (let i = Number(level.levelStart); i <= Number(level.levelStop); i++) {
          if (level.name === 'Mezzanine') {
            mezzanineArray.push({
              name: level.name,
              level: i,
              area: isNaN(level.width) || isNaN(level.length) ? 0 : level.length * level.width,
            }); // filtering mezzanine levels
          } else {
            totalArea += isNaN(level.width) || isNaN(level.length) ? 0 : level.length * level.width; // sum of area of all levels(floor,basement,attic) except mezzanine
          }
          if (level.name === 'Floor') {
            floorsPerLevelAreaArray.push({
              name: level.name,
              level: i,
              area: isNaN(level.width) || isNaN(level.length) ? 0 : level.length * level.width,
            }); // filtering floors levels
          }
        }
      } else {
        if (level.name === 'Mezzanine') {
          // filtering mezzanine levels
          mezzanineArray.push({
            name: level.name,
            level: Number(level.levelStart),
            area: isNaN(level.width) || isNaN(level.length) ? 0 : level.length * level.width,
          });
        } else {
          totalArea += isNaN(level.width) || isNaN(level.length) ? 0 : level.length * level.width;
          // sum of area of all levels(floor,basement,attic) except mezzanine
        }
        if (level.name === 'Floor') {
          // sum of area of all levels(floor,basement,attic) except mezzanine
          floorsPerLevelAreaArray.push({
            name: level.name,
            level: Number(level.levelStart),
            area: isNaN(level.width) || isNaN(level.length) ? 0 : level.length * level.width,
          });
        }
      }
    });

    if (mezzanineArray.length > 0) {
      const totalMezzanineArea = this.calculateMezzanineArea(mezzanineArray, floorsPerLevelAreaArray);
      totalArea += totalMezzanineArea;
    }
    return totalArea;
  }

  /**
   * Get the total mezzanine area for an array of occupants where return  the chargeable mezzanine(area greater than 25%) area
   *
   * @param mezzanineArray Filtered array of mezzanines
   * @param floorsPerLevelAreaArray Filtered array of floors with each level's area
   * @returns
   */
  public calculateMezzanineArea(mezzanineArray, floorsPerLevelAreaArray) {
    let totalMezzanineArea = 0;
    let flag;
    let summarizedMezzArray = [];
    if (mezzanineArray.length > 1) {
      mezzanineArray.forEach((item) => {
        const obj = summarizedMezzArray.find((o) => o.level === item.level);
        if (obj) {
          obj.area = obj.area + item.area;
        } else {
          summarizedMezzArray.push(item);
        }
      });
    } else {
      summarizedMezzArray = mezzanineArray;
    }

    summarizedMezzArray.forEach((mezzLevel) => {
      let totalFloorArea = 0;
      floorsPerLevelAreaArray.filter((eachLevel) => {
        if (eachLevel.level === mezzLevel.level) {
          totalFloorArea += eachLevel.area;
        }
      });
      flag = (mezzLevel.area / totalFloorArea) * 100 >= 25 ? true : false;
      if (flag) {
        totalMezzanineArea += mezzLevel.area;
      }
    });
    return totalMezzanineArea;
  }

  public calculateAreaPerLevel(field) {
    const floorLengthControl = field.form.get('length');
    const floorWidthControl = field.form.get('width');
    floorLengthControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((updatedLength) => {
      if (updatedLength) {
        setTimeout(() => {
          const updatedArea = updatedLength * field.form.get('width').value;
          field.formControl.setValue(updatedArea);
        }, 0);
      }
    });
    floorWidthControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((updatedWidth) => {
      if (updatedWidth) {
        setTimeout(() => {
          const updatedArea = updatedWidth * field.form.get('length').value;
          field.formControl.setValue(updatedArea);
        }, 0);
      }
    });
  }

  public checkEditOccupantAsNew(field) {
    field.props.isDisabled = true;
    const isNewOccupantVal = field?.formControl?.value;
    const occupantNameControl = field?.form?.get('doingBusinessAs');
    const occupantNameControlVal = occupantNameControl?.value;
    occupantNameControl?.valueChanges?.pipe(takeUntil(this?.ngUnsubscribe))?.subscribe((updatedOccupantName) => {
      if (updatedOccupantName && !field?.formControl?.value) {
        if (updatedOccupantName?.trim() !== occupantNameControlVal?.trim()) {
          field?.formControl?.reset();
          field.props.required = true;
          field.props.isDisabled = false;
        } else {
          field?.formControl?.setValue(false);
          field.props.isDisabled = true;
        }
      }
    });

    const occupantScheduleNumberControl = field.form?.get('occupantScheduleNo');
    const occupantScheduleNumberControlVal = occupantScheduleNumberControl.value;
    occupantScheduleNumberControl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(1000), distinctUntilChanged())
      .subscribe((updatedOccupantScheduleNumber) => {
        if (isNewOccupantVal !== undefined && !isNewOccupantVal) {
          if (updatedOccupantScheduleNumber && !field?.formControl.value) {
            if (updatedOccupantScheduleNumber !== occupantScheduleNumberControlVal) {
              setTimeout(() => {
                field.formControl.setValue(true);
              }, 0);
            } else {
              field.formControl.setValue(false);
            }
            field.props.isDisabled = true;
          } else if (field.formControl.value) {
            if (updatedOccupantScheduleNumber === occupantScheduleNumberControlVal) {
              field.formControl.setValue(false);
            }
          }
        }
        // Retrieve the occupant type
        try {
          if (field.options.formState.service.isSheetActive) {
            this.setScheduledetails(field, updatedOccupantScheduleNumber);
          }
        } catch (error) {
          console.error('ScheduleService ' + error);
        }
      });
  }

  public checkBGIIValue(field) {
    const toggleControl = field.form.get('isNewOccupant');
    const initialBg2Value = field.formControl.value;
    toggleControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      if (field.options.formState.service.isSheetActive) {
        if (value === true) {
          field.formControl.setValue('');
        } else if (value === false) {
          field.formControl.setValue(initialBg2Value);
        }
      }
    });
  }

  public levelTypeChanged(field) {
    field.formControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((val) => {
      if (field.options.formState.service.isSheetActive) {
        field.form.get('levelStart').setValue(null);
        field.form.get('levelStop').setValue(null);
      }
    });
  }

  public loadLevelStartInitialOptions(field) {
    const levelTypeControl = field.form.get('name');
    if (levelTypeControl.value) {
      field.props.maxLevels = this.getMaxValue(levelTypeControl.value, field);
    }
    this.getLevelOptions(1, field.props.maxLevels)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (field.props.options = data));
    field.props.options = levelTypeControl.valueChanges.pipe(
      startWith(''),
      map((val) => {
        if (val) {
          field.props.maxLevels = this.getMaxValue(val, field);
        }

        this.getLevelOptions(1, field.props.maxLevels)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((data) => {
            field.props.optionsCopy = data;
          });
        return field.props.optionsCopy;
      })
    );
  }

  public resetLevelsOnChange(field) {
    const minValue = field.form.get('levelStart').value;
    field.props.optionsCopy = field.props.options = field.parent.fieldGroup
      .find((obj) => obj.key === 'levelStart')
      .props.optionsCopy.filter((option) => option.value >= Number(minValue ? minValue : 1));
    field.props.options = field.form.get('levelStart').valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      map((val: string) => {
        if (val && field.options.formState.service.isSheetActive) {
          if (field.model.levelStart !== field.form.value.levelStart) {
            field.props.optionsCopy = field.parent.fieldGroup
              .find((obj) => obj.key === 'levelStart')
              .props.optionsCopy.filter((option) => option.value >= Number(val));
            field.formControl.setValue(null);
          }
        }
        return field.props.optionsCopy;
      })
    );
  }

  public getOccupantLevels(occupantsArray) {
    const occupantLevels = [];
    occupantsArray?.forEach((occupant) => {
      occupant?.occupantLevels?.forEach((level) => {
        occupantLevels.push(level);
      });
    });
    return occupantLevels;
  }

  /**
   * @deprecated Use typescript UUID instead
   * @returns
   */
  uuid() {
    // TODO: Move to utils file
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  /**
   * Calculates Percentageoccupied for each floor of summarizes the value for same floors within all occupants and updates the model
   *
   * @param field  percentageOccupied field
   */
  public calculatePercentageOccupiedForEachFloor(occupantLevels) {
    const summarizedArray = [];
    occupantLevels.forEach((item) => {
      const obj = summarizedArray?.find(
        (o) => Number(o?.levelStart) === Number(item?.levelStart) && o?.name === item?.name
      );
      if (obj && item.floorPercentage !== null && item.floorPercentage !== undefined) {
        obj.percentageOccupied = obj.percentageOccupied + item.floorPercentage;
      } else if (item.floorPercentage !== null && item.floorPercentage !== undefined) {
        item.percentageOccupied = item.floorPercentage;
        summarizedArray.push(item);
      }
    });
    return summarizedArray;
  }

  setPercentageOccupiedForEachFloor(occArr, summarizedArray) {
    summarizedArray.forEach((item) => {
      occArr.forEach((occ) => {
        const occLevelArr = occ.get('occupantLevels').controls;
        occLevelArr.forEach((occLevel) => {
          if (
            Number(occLevel?.get('levelStart')?.value) === Number(item?.levelStart) &&
            occLevel?.get('floorNo')?.value === item?.floorNo
          ) {
            occLevel.get('percentageOccupied').setValue(item.percentageOccupied);
          }
        });
      });
    });
  }

  setPropsValue(model: any, field: FormlyFieldConfig, formlyBuilderService: FormlyBuilderService): boolean {
    if (model.occupants && model.occupants?.length > 0) {
      const occupantsList = model.occupants.filter((occ) => !occ.isDeleted);
      if (occupantsList?.length) {
        if (formlyBuilderService.mode === 1) {
          field.props.actionButtons[ 0 ].label = 'Edit';
          field.props.actionButtons[ 0 ].action = 'edit';
        }
        return false; // disable the expand collapse arrow if no entries
      } else {
        field.props.isCollapsed = true;
        if (formlyBuilderService.mode === 1) {
          field.props.actionButtons[ 0 ].label = Constants.btnText;
          field.props.actionButtons[ 0 ].action = 'add';
        }
        return true;
      }
    } else {
      field.props.isCollapsed = true;
      if (formlyBuilderService.mode === 1) {
        field.props.actionButtons[ 0 ].label = Constants.btnText;
        field.props.actionButtons[ 0 ].action = 'add';
      }
      return true;
    }
  }

  /**
   * General function for occupancy total area
   *
   * @param field
   * @param sprinklerDesignFlag refer include sprinklerDesign
   * @returns Number or null
   */
  public getOccupancyTotalArea(field: FormlyFieldConfig, sprinklerDesignFlag?: boolean): number | null {
    const formlyService = field.options?.formState?.service;
    const occupants = formlyService.riskReport?.model?.occupants;
    const sectionOccupant = occupants
      ? 'section-occupants' in occupants
        ? occupants[ 'section-occupants' ]
        : null
      : null;
    let totalArea = null;
    if (sectionOccupant !== null && sectionOccupant) {
      const occupant = sectionOccupant?.occupants;
      const occupantLevel = [];
      if (occupant?.length > 0) {
        occupant.forEach((item) => {
          if ('occupantLevels' in item && item.occupantLevels.length > 0 && !item?.isDeleted) {
            item.occupantLevels.map((val) => {
              occupantLevel.push(val);
            });
          }
        });
      }
      let area = occupantLevel;
      if (sprinklerDesignFlag && occupantLevel?.length) {
        area = occupantLevel.filter((acc) => {
          if (acc && 'sprinklerDesign' in acc && acc.sprinklerDesign !== 'None') {
            return acc;
          }
        });
      }

      if (area?.length === 1) {
        totalArea = area[ 0 ].area;
      }
      if (area?.length > 1) {
        totalArea = area.reduce((a, p) => a + p.area, 0);
      }
    }
    return totalArea;
  }
}
