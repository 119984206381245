/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Guid } from 'guid-typescript';
import { RiskReportDataAddressAlias } from 'src/app/models/risk-report/risk-report-data.model';

export class AddressAliasDataModel {
  static sectionName = 'address';

  streetNumber: string;
  preDirectional: string;
  streetName: string;
  streetType: string;
  postDirectional: string;
  city: string;
  state: string;
  zipcode: string;
  county: string;
  isAlias = false;
  isAddressValidated = true;
  addressVerificationTypeCodeValue: string;
  prefix: string;
  zip4: string;
  reportAddressIdentifier: string;
  reportIdentifier: string;
  highNumber?: string;

  static from(alias: RiskReportDataAddressAlias): AddressAliasDataModel {
    return {
      streetNumber: alias.LowNo,
      preDirectional: alias.PreDir,
      streetName: alias.StreetName,
      streetType: alias.StreetType,
      postDirectional: alias.PostDir,
      city: alias.City,
      state: alias.StateCode,
      zipcode: alias.Zip,
      isAlias: alias.isAlias,
      county: alias.County ? alias.County?.replace(new RegExp('county', 'ig'), '')?.trim() : undefined,
      isAddressValidated: true,
      addressVerificationTypeCodeValue: alias.AddressVerificationTypeCodeValue,
      prefix: alias.Prefix,
      zip4: alias.Zip4,
      reportAddressIdentifier: alias.ReportAddressIdentifier ? alias.ReportAddressIdentifier : Guid.create().toString(),
      reportIdentifier: alias.ReportIdentifier ? alias.ReportIdentifier : Guid.create().toString()
    };
  }

  static fromS3(alias: any): AddressAliasDataModel {
    return {
      streetNumber: alias.LowNumber,
      highNumber: alias?.HighNumber,
      preDirectional: alias.PreDirection,
      streetName: alias.StreetName,
      streetType: alias.StreetType,
      postDirectional: alias.PostDirection,
      city: alias.City,
      state: alias.StateCode,
      zipcode: alias.Zip,
      isAlias: alias.IsAlias,
      county: alias.County ? alias.County?.replace(new RegExp('county', 'ig'), '')?.trim() : undefined,
      isAddressValidated: true,
      addressVerificationTypeCodeValue: alias.AddressVerificationTypeCodeValue,
      prefix: alias.Prefix,
      zip4: alias.Zip4,
      reportAddressIdentifier: alias.ReportAddressIdentifier ? alias.ReportAddressIdentifier : Guid.create().toString(),
      reportIdentifier: alias.ReportIdentifier ? alias.ReportIdentifier : Guid.create().toString()
    };
  }

  /**
   * Returns a S3 RiskReport object from a Formly model
   *
   * @param model Model from Formly
   */
  static to(model: any) {
    const reportAddresses = [];
    const mainAddressModal = {
      LowNumber: model?.mainAddress?.streetNumber,
      HighNumber: model?.mainAddress?.highNumber,
      PreDirection: model?.mainAddress?.preDirectional,
      StreetName: model?.mainAddress?.streetName,
      StreetType: model?.mainAddress?.streetType,
      PostDirection: model?.mainAddress?.postDirectional,
      City: model?.mainAddress?.city,
      PostalCity: model?.mainAddress.city,
      StateCode: model?.mainAddress?.state,
      Zip: model?.mainAddress?.zipcode,
      County: model?.mainAddress?.county ? model?.mainAddress?.county?.replace(new RegExp('county', 'ig'), '')?.trim() : null,
      IsAlias: false,
      AddressVerificationTypeCodeValue: model?.mainAddress?.addressVerificationTypeCodeValue,
      Prefix: model?.prefix,
      Zip4: model?.zip4,
      AddressSequence: 1,
      ReportAddressIdentifier: model?.mainAddress?.reportAddressIdentifier,
      ReportIdentifier: model?.mainAddress?.reportIdentifier
    };
    reportAddresses.push(mainAddressModal);

    if (model && model.addressAliases && model.addressAliases.length > 0) {
      model.addressAliases.forEach((alias, index) => {
        reportAddresses.push({
          LowNumber: alias.streetNumber,
          HighNumber: model?.highNumber,
          PreDirection: alias.preDirectional,
          StreetName: alias.streetName,
          StreetType: alias.streetType,
          PostDirection: alias.postDirectional,
          City: alias.city,
          PostalCity: alias.postalCity ? alias.postalCity : alias.city,
          StateCode: alias.state,
          Zip: alias.zipcode,
          County: alias.county ? alias.county?.replace(new RegExp('county', 'ig'), '')?.trim() : null,
          IsAlias: true,
          AddressVerificationTypeCodeValue: alias?.addressVerificationTypeCodeValue,
          Prefix: alias.prefix,
          Zip4: alias?.zip4,
          AddressSequence: index + 2,
          ReportAddressIdentifier: alias?.reportAddressIdentifier,
          ReportIdentifier: alias?.reportIdentifier
        });
      });
    }
    return { ReportAddresses: reportAddresses };
  }
  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   * @returns
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    return true;
  }
}
