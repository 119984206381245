import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { FormControl } from '@angular/forms';
import { Constants } from 'src/app/services/util-service/constants';

export class FullRiskPhotosSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'reportPhotos',
      type: 'fr-section-photos',
      wrappers: ['simple-section-wrapper'],
      props: {
        label: 'Front and Rear Exterior Photos',
        //  required: true,
        isCollapsable: true,
        isCollapsed: false,
        isEdit: true,
        isDisabled: false,
        isCollapseIconDisabled: true,
        isFullRisk: true,
        // showError: true,
        // checkTouchedControl: false,
        actionButtons: [
          { section: 'reportPhotos', action: 'add', label: Constants.btnText, isDisabled: false }
        ],
      },
      expressions: {
        'props.isCollapseIconDisabled': (field: FormlyFieldConfig) => {
          if (field?.fieldGroup[0]?.fieldGroup[0]?.formControl?.value == undefined || field?.fieldGroup[0]?.fieldGroup[0]?.formControl?.value == null ||
            field?.fieldGroup[0]?.fieldGroup[2]?.formControl?.value == undefined || field?.fieldGroup[0]?.fieldGroup[2]?.formControl?.value == null) {
            field.props.isCollapseIconDisabled = true;
          }
          else field.props.isCollapseIconDisabled = false;
        },
        'props.isCollapsed': (field: FormlyFieldConfig) => field?.options?.formState?.service?.sectionPhotosService?.isCollapsed(field)
      },
      fieldArray: {
        fieldGroup: [
          {
            key: 'reportPhotoIdentifier',
            defaultValue: 'undefined'
          },
          {
            key: 'reportIdentifier',
            defaultValue: 'undefined'
          },
          {
            key: 'photoIdentifier',
            defaultValue: 'undefined'
          },
          {
            key: 'reportPhotoType',
            defaultValue: 'undefined'
          }
        ]
      },
      validators: {
        photo: {
          expression: (control: FormControl, field: FormlyFieldConfig) => {
            const rc = field?.options?.formState?.service?.sectionPhotosService?.checkPhotos(control, field);
            field.form.markAsTouched();
            if (rc === false) {
              return false;
            } 
            return true;
          },
          message: formlyBuilderService.mode === 1 ?
            'Front photo is required' : 'Front and Rear photos are required'
        },
      }
    });
  }
}
