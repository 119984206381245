import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { SectionEnum } from '../../section.config';
import { TwoInchDrainTestDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/two-inch-drain-test-data.model';
import { FormlyBuilderService } from '../../formly-builder.service';
import { UtilService } from 'src/app/services/util-service/util.service';

// TwoInch drain Test Sheet datas needs to be saved when user hits on apply in TwoInchDrainTest sheet
// (i.e) In Case of two Inch drain test there will be two sheets one top of another, 
// When user hits apply on the top level sheet, it should call the updateData method and the changes needs to be saved to s3
@Injectable({
  providedIn: 'root',
})
export class TwoInchDrainTestService extends SectionServiceAbstract {
  constructor(public formlyBuilderService: FormlyBuilderService,
    private utilService: UtilService) {
    super();
  }


  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
     // Will get Called only when user hits apply inside twoInchDrianTest sheet
    const TwoInchDrainTests = [];
    model?.sprinklerReport?.asgrSprinklerSystemTest?.mainDrainTesting?.twoInchDrainTests?.forEach((twoInchDrainTest) => {
      const TwoInchDrainTest = TwoInchDrainTestDataModel.to(twoInchDrainTest);
      if (TwoInchDrainTest?.TestDate) {
        TwoInchDrainTest.TestDate = this.utilService.convertDateToISOStringFormat(TwoInchDrainTest.TestDate);
      }
      TwoInchDrainTests.push(TwoInchDrainTest);
    });
    const newRr = {
      ...riskReportData,
      SprinklerReport: {
        ...riskReportData.SprinklerReport,
        AsgrSprinklerSystemTest: {
          ...riskReportData.SprinklerReport.AsgrSprinklerSystemTest,
          ...{ TwoInchDrainTests: TwoInchDrainTests }
        }
      }
    }
    return { ...riskReportData, ...newRr };
  }

  loadData(sectionData: any[], reportInfo: any, isS3: boolean, model: any): any {
    // Will get Called only when user hits apply inside twoInchDrianTest sheet or hit close inside twoInchDrianTest sheet
    const TwoInchDrainTests = sectionData;
    let twoInchDrainTest = [];
    if (TwoInchDrainTests && TwoInchDrainTests.length) {
      TwoInchDrainTests.forEach((test) => {
        const twoInchTestData = TwoInchDrainTestDataModel.fromS3(test);
        twoInchDrainTest.push(twoInchTestData);
      });
    }

    const newModel = {
      ...model,
      sprinklerReport: {
        ...model.sprinklerReport,
        asgrSprinklerSystemTest: {
          ...model.sprinklerReport.asgrSprinklerSystemTest,
          mainDrainTesting: {
            ...model.sprinklerReport.asgrSprinklerSystemTest.mainDrainTesting,
            ...{ twoInchDrainTests: twoInchDrainTest }
          }
        }
      }
    }
    if (newModel) {
      this.formlyBuilderService.riskReport.model = { ...newModel };
    }
    return this.formlyBuilderService.riskReport.model;
  }


  async onApply(modalCtrl, field) {
    // When apply we need to save the value to S3 here
    const twoInchDrainTestService = field.options.formState.service.twoInchDrainTestService;
    const riskReportService = field.options.formState.riskReportService;
    const rr = await riskReportService.updateSectionData(SectionEnum.FR_SR_ST_TWO_INCH_DRAIN_TEST, false, null, TwoInchDrainTestService);

    // After hits apply in the sheet, It needs to be sorted based on most recent date
    let TwoInchDrainTests = rr?.SprinklerReport?.AsgrSprinklerSystemTest?.TwoInchDrainTests;
    TwoInchDrainTests = field.options.formState.service.sprinklerReportService.sortArrayWithMostRecentDate(TwoInchDrainTests, 'TestDate');

    // loadData to set the sorted value back to model
    twoInchDrainTestService.loadData(TwoInchDrainTests, rr, true, riskReportService.model);

    // if MainDrainTestDate is empty populate with most recent Two Inch Drain Tests date but enabled, when user applies Two Inch Drain Test sheet.
    setTimeout(() => {
      if (TwoInchDrainTests && TwoInchDrainTests.length) {
        if (TwoInchDrainTests[length].TestDate && !field?.form?.get('mainDrainTestDate').value) {
          field?.form?.get('mainDrainTestDate')?.setValue(TwoInchDrainTests[length].TestDate);
        }
      }  
    },100);
    modalCtrl.dismiss(null, 'save');
  }

  onClose(modalCtrl, field, initialModel) {
    // Tried with patchValue and resetModel
    // Tried with reset and patchValue with forLoop for each array values
    // The above two didin't help so going with load Data on close 
    const formlyBuilderService = field.options.formState.service;
    const riskReportService = field.options.formState.riskReportService;
    const formlyModel = riskReportService.model;
    const rr = riskReportService.report;
    formlyBuilderService.twoInchDrainTestService.loadData(rr?.SprinklerReport?.AsgrSprinklerSystemTest?.TwoInchDrainTests, rr, true, formlyModel)
    formlyBuilderService.errorService.closePreviousToaster();
    modalCtrl.dismiss(null, 'cancel');
  }

}

