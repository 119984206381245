import { Directive, ElementRef, Renderer2, Input } from '@angular/core';

@Directive({
    selector: '[timeDiff]',
})
export class TimeDiffDirective {
    @Input('startTime') startTime;
    @Input('endTime') endTime;

    constructor(private elementRef: ElementRef) { }

    ngOnInit() {
        const diff = Number(this.endTime) - Number(this.startTime);
        let label = '';
        if (diff > 1000) {
            const intervals = {
                'year': 31536000,
                'month': 2592000,
                'week': 604800,
                'day': 86400,
                'hour': 3600,
                'minute': 60,
                'second': 1
            };
            const keys = Object.keys(intervals);
            for (let index = 0; index < keys.length; index++) {
                const counter = Math.floor(diff / (1000 * intervals[keys[index]]));
                if (counter > 0) {
                    label = `${counter} ${keys[index]}${counter> 1 ? 's': ''}`;
                    break;
                }
            }
        } else {
            label = `${diff} ms`;
        }
        if (label) {
            this.elementRef.nativeElement.innerHTML = label;
        }
    }
}