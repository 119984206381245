/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { SectionEnum } from '../../section.config';
import { WaterSupplyDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/water-supply-data.model';
import { SystemComponentDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/system-component-data.model';
import { SystemTestingDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/system-testing-data.model';
import { BuildingConditionDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/building-condition-data.model';
import { NonSprinkleredAreasDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/non-sprinklered-areas-data.model';
import {
  RackStorageObstructionDataModel

} from 'src/app/models/formly-builder/formly/full-risk/sprinkler/rack-storage-obstruction-data.model';
import { HydralicService } from './hydralic.service';
import { SpocCalculationService } from './spoc-calculation.service';
import { WaterSupplyService } from './water-supply.service';
import { SystemComponentService } from './system-component.service';
import { SystemTestingService } from './system-testing.service';
import { NonSprinkleredAreasService } from './non-sprinklered-areas.service';
import { BuildingConditionService } from './building-condition.service';
import { DataDesignTableService } from './data-design-table.service';
import { InternalSprinklerPipeConditionsService } from './internal-pipe-conditions.service';
import { SprinklerTableService } from './sprinkler-table.service';
import { FormlyBuilderService } from '../../formly-builder.service';

@Injectable({
  providedIn: 'root',
})
export class SprinklerReportService extends SectionServiceAbstract {
  public isCalculateApiHit = false;
  constructor(
    private hydralicService: HydralicService,
    private spocCalculationService: SpocCalculationService,
    private waterSupplyService: WaterSupplyService,
    private systemComponentService: SystemComponentService,
    private systemTestingService: SystemTestingService,
    private nonSprinkleredAreasService: NonSprinkleredAreasService,
    private buildingConditionService: BuildingConditionService,
    private dataDesignTableService: DataDesignTableService,
    private internalSprinklerPipeConditionsService: InternalSprinklerPipeConditionsService,
    private sprinklerTableService : SprinklerTableService,
    private formlyBuilderService: FormlyBuilderService
  ) {
    super();
  }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    return undefined;
  }

  loadData(sprinklerReport: any[], reportInfo: any, isS3: boolean, model: any, isPreserveDeficiencyPoints?: boolean): any {
    let defListV1: any[] = [];
    let defListV2: any[] = [];
    if (reportInfo.SprinklerReport && Object.keys(reportInfo.SprinklerReport).length) {
      //water suppply
      const waterSupply = WaterSupplyDataModel.fromS3(reportInfo.SprinklerReport.AsgrWaterSupply, reportInfo.SprinklerReport, reportInfo);
      const waterSupplyData = { ...waterSupply };

      if (waterSupplyData?.waterSupplyHydraulics?.v1WaterSupplyHydraulics?.length) {
        waterSupplyData.waterSupplyHydraulics.v1WaterSupplyHydraulics = this.hydralicService.sortAndFormatHydralicSpoc(waterSupplyData.waterSupplyHydraulics.v1WaterSupplyHydraulics, 1);
      }
      if (waterSupplyData?.waterSupplyHydraulics?.v2WaterSupplyHydraulics?.length) {
        waterSupplyData.waterSupplyHydraulics.v2WaterSupplyHydraulics = this.hydralicService.sortAndFormatHydralicSpoc(waterSupplyData.waterSupplyHydraulics.v2WaterSupplyHydraulics, 2);
      }
      if (waterSupplyData?.waterSupplyPipeSchedules?.v1WaterSupplyPipeSchedules?.length) {
        waterSupplyData.waterSupplyPipeSchedules.v1WaterSupplyPipeSchedules = this.hydralicService.sortAndFormatPipeScheduleSpoc(waterSupplyData.waterSupplyPipeSchedules.v1WaterSupplyPipeSchedules, 1);
      }
      if (waterSupplyData?.waterSupplyPipeSchedules?.v2WaterSupplyPipeSchedules?.length) {
        waterSupplyData.waterSupplyPipeSchedules.v2WaterSupplyPipeSchedules = this.hydralicService.sortAndFormatPipeScheduleSpoc(waterSupplyData.waterSupplyPipeSchedules.v2WaterSupplyPipeSchedules, 2);
      }
      waterSupplyData.groundFloorArea = this.waterSupplyService.getGroundFloorArea();

      //system componenet
      const systemComponent = SystemComponentDataModel.fromS3(reportInfo.SprinklerReport.AsgrSprinklerSystemComponent, reportInfo.SprinklerReport
      );
      const systemComponentData = { ...systemComponent };

      //system Test
      const systemTest = SystemTestingDataModel.fromS3(reportInfo?.SprinklerReport?.AsgrSprinklerSystemTest, reportInfo?.SprinklerReport?.InternalSprinklerPipeConditions);
      if (systemTest?.mainDrainTesting?.twoInchDrainTests?.length) {
        systemTest.mainDrainTesting.twoInchDrainTests = this.sortArrayWithMostRecentDate(
          systemTest.mainDrainTesting.twoInchDrainTests,
          'testDate'
        );
      }
      if (systemTest?.overAgeSprinklerTests?.length) {
        systemTest.overAgeSprinklerTests = this.sortArrayWithMostRecentDate(
          systemTest.overAgeSprinklerTests,
          'dateTested'
        );
      }
      if (systemTest?.dryPipeValveTesting?.dryPipeValveTripTests?.length) {
        systemTest.dryPipeValveTesting.dryPipeValveTripTests = this.sortArrayWithMostRecentDate(
          systemTest.dryPipeValveTesting?.dryPipeValveTripTests,
          'testDate'
        );
      }
      systemTest.isFirePumpTestNotApplicable =
        this.systemTestingService.calculateIsFirePumpTestNotApplicable(waterSupplyData);

      //building condition
      const buildingCondition: BuildingConditionDataModel = BuildingConditionDataModel.fromS3(
        reportInfo?.SprinklerReport?.AsgrBuildingCondition, reportInfo.SprinklerReport
      );

      //non sprinklered area
      const nonSprinkleredArea: NonSprinkleredAreasDataModel = NonSprinkleredAreasDataModel.fromS3(
        reportInfo?.SprinklerReport?.AsgrNonSprinkleredObstructedArea
      );

      //rackStorage Obstruction
      const rackStorageObstruction: RackStorageObstructionDataModel = RackStorageObstructionDataModel.fromS3(
        reportInfo?.SprinklerReport?.AsgrRackStorageObstruction
      );

      if (isPreserveDeficiencyPoints === true) {
        defListV1 = model.sprinklerReport.defListV1;
        defListV2 = model.sprinklerReport.defListV2;
      } else if (isPreserveDeficiencyPoints === false) {
        this.clearScoreValues(model);
      }
      // Calulate Spoc Values based on Occupants
      setTimeout(() => {
        this.spocCalculationService.calculateSpocEntries();
      });
      if (
        reportInfo?.SprinklerReport?.ReportScore !== undefined &&
        reportInfo?.SprinklerReport?.ReportScore !== null &&
        reportInfo?.SprinklerReport?.ReportScore !== '' &&
        reportInfo?.SprinklerReport?.ReportScore !== model.internalProtections.sprinklersData.sprinklerRatingAsgr
      ) {
        model.internalProtections.sprinklersData.sprinklerRatingAsgr = reportInfo?.SprinklerReport?.ReportScore;
      }
      return {
        ...model,
        ...{ flowTestLocation: reportInfo.FlowTestLocation },
        ...{
          sprinklerReport: {
            // surveyDate: reportInfo.SprinklerReport.SurveyDate,
            reportScore:
              reportInfo?.SprinklerReport?.ReportScore !== undefined ? reportInfo.SprinklerReport.ReportScore : null,
            waterSupplyPoints: reportInfo?.SprinklerReport?.WaterSupplyPoints  !== undefined
              ? reportInfo.SprinklerReport.WaterSupplyPoints
              : null,
            systemComponentPoints: reportInfo?.SprinklerReport?.SystemComponentPoints  !== undefined
              ? reportInfo.SprinklerReport.SystemComponentPoints
              : null,
            systemTestPoints: reportInfo?.SprinklerReport?.SystemTestPoints  !== undefined
              ? reportInfo.SprinklerReport.SystemTestPoints
              : null,
            nonSprinkleredObstructedAreaPoints: reportInfo?.SprinklerReport?.NonSprinkleredObstructedAreaPoints  !== undefined
              ? reportInfo.SprinklerReport.NonSprinkleredObstructedAreaPoints
              : null,
            rackStorageObstructionPoints: reportInfo?.SprinklerReport?.RackStorageObstructionPoints !== undefined
              ? reportInfo.SprinklerReport.RackStorageObstructionPoints
              : null,
            buildingConditionPoints: reportInfo?.SprinklerReport?.BuildingConditionPoints !== undefined
              ? reportInfo.SprinklerReport.BuildingConditionPoints
              : null,
            defListV1,
            defListV2,
            //v2
            reportScoreV2:
              reportInfo?.SprinklerReport?.ReportScoreV2 !== undefined
                ? reportInfo.SprinklerReport.ReportScoreV2
                : null,
            waterSupplyPointsV2: reportInfo?.SprinklerReport?.WaterSupplyPointsV2
              ? reportInfo.SprinklerReport.WaterSupplyPointsV2
              : null,
            systemComponentPointsV2: reportInfo?.SprinklerReport?.SystemComponentPointsV2
              ? reportInfo.SprinklerReport.SystemComponentPointsV2
              : null,
            systemTestPointsV2: reportInfo?.SprinklerReport?.SystemTestPointsV2
              ? reportInfo.SprinklerReport.SystemTestPointsV2
              : null,
            nonSprinkleredObstructedAreaPointsV2: reportInfo?.SprinklerReport?.NonSprinkleredObstructedAreaPointsV2
              ? reportInfo.SprinklerReport.NonSprinkleredObstructedAreaPointsV2
              : null,
            rackStorageObstructionPointsV2: reportInfo?.SprinklerReport?.rackStorageObstructionPointsV2
              ? reportInfo.SprinklerReport.rackStorageObstructionPointsV2
              : null,
            buildingConditionPointsV2: reportInfo?.SprinklerReport?.BuildingConditionPointsV2
              ? reportInfo.SprinklerReport.BuildingConditionPointsV2
              : null,
            //scoreCard: scoreCard,
            asgrWaterSupply: waterSupplyData,
            asgrSprinklerSystemComponent: systemComponentData,
            asgrSprinklerSystemTest: systemTest,
            asgrBuildingCondition: buildingCondition,
            asgrNonSprinkleredObstructedArea: nonSprinkleredArea,
            asgrRackStorageObstruction: rackStorageObstruction,

            reportNumber: reportInfo?.SprinklerReport?.ReportNumber ? reportInfo.SprinklerReport.ReportNumber : '',
            surveyDate: model?.survey?.surveyDate ? model.survey.surveyDate : null,
            buildingCombustibilityClass: reportInfo?.CombustibilityClass
              ? parseInt(reportInfo.CombustibilityClass)
              : null,
          },
        },
      };
    } else {
      return { ...model, ...{ flowTestLocation: reportInfo.FlowTestLocation }, ...{ sprinklerReport: {} } };
    }
  }

  clearScoreValues(model) {
    this.isCalculateApiHit = false;
    model.sprinklerReport.reportScore = null;
    model.sprinklerReport.waterSupplyPoints = null;
    model.sprinklerReport.systemComponentPoints = null;
    model.sprinklerReport.systemTestPoints = null;
    model.sprinklerReport.nonSprinkleredObstructedAreaPoints = null;
    model.sprinklerReport.rackStorageObstructionPoints = null;
    model.sprinklerReport.buildingConditionPoints = null;
    model.sprinklerReport.defListV1 = [];
    //v2
    model.sprinklerReport.reportScoreV2 = null;
    model.sprinklerReport.waterSupplyPointsV2 = null;
    model.sprinklerReport.systemComponentPointsV2 = null;
    model.sprinklerReport.systemTestPointsV2 = null;
    model.sprinklerReport.nonSprinkleredObstructedAreaPointsV2 = null;
    model.sprinklerReport.rackStorageObstructionPointsV2 = null;
    model.sprinklerReport.buildingConditionPointsV2 = null;
    model.sprinklerReport.defListV2 = [];
  }

  sortArrayWithMostRecentDate(testArray: any[], propertyName: string) {
    return testArray.sort((a: any, b: any) => Date.parse(b[ propertyName ]) - Date.parse(a[ propertyName ]));
  }

  async updateFullSprinklerReport(model: any, riskReportData: any) {
    const waterSupplyData = await this.waterSupplyService.updateData(model, riskReportData);
    const systemComponentData = await this.systemComponentService.updateData(model, riskReportData);
    const systemTestingData = await this.systemTestingService.updateData(model, riskReportData);
    const nonSprinkleredAreasData = await this.nonSprinkleredAreasService.updateData(model, riskReportData);
    const buildingConditionData = await this.buildingConditionService.updateData(model, riskReportData);
    const hydraulicDesignData = await this.dataDesignTableService.updateData(model, riskReportData);
    const internalSprinklerPipeConditions = await this.internalSprinklerPipeConditionsService.updateData(model, riskReportData);
    const sprinklers = await this.sprinklerTableService.updateData(model, riskReportData);
    const sprinklerReport = {
      ...riskReportData.SprinklerReport,
      ...{ AsgrWaterSupply: waterSupplyData.SprinklerReport?.AsgrWaterSupply },
      ...{ AsgrSprinklerSystemComponent: systemComponentData.SprinklerReport?.AsgrSprinklerSystemComponent },
      ...{ AsgrSprinklerSystemTest: systemTestingData.SprinklerReport?.AsgrSprinklerSystemTest },
      ...{ AsgrNonSprinkleredObstructedArea: nonSprinkleredAreasData.SprinklerReport?.AsgrNonSprinkleredObstructedArea },
      ...{ AsgrBuildingCondition: buildingConditionData.SprinklerReport?.AsgrBuildingCondition },
      ...{ AsgrRackStorageObstruction: waterSupplyData.SprinklerReport?.AsgrRackStorageObstruction},
      ...{ HydraulicDesignData: hydraulicDesignData.SprinklerReport?.HydraulicDesignData},
      ...{ HasAdequateMaintenance: waterSupplyData.SprinklerReport?.HasAdequateMaintenance},
      ...{ InternalSprinklerPipeConditions: internalSprinklerPipeConditions.SprinklerReport?.InternalSprinklerPipeConditions},
      ...{ Sprinklers: sprinklers.SprinklerReport?.Sprinklers},
      ...{
        ReportId: riskReportData?.ReportIdentifier,
        VersionNumberCode: riskReportData?.SprinklerReport?.VersionNumberCode ? riskReportData?.SprinklerReport?.VersionNumberCode : '',
        ReportNumber: riskReportData?.SprinklerReport?.ReportNumber ? riskReportData?.SprinklerReport?.ReportNumber : '',
        StreetAddress: riskReportData?.SprinklerReport?.StreetAddress ? riskReportData?.SprinklerReport?.StreetAddress : '',
        FieldRep: riskReportData?.SprinklerReport?.FieldRep ? riskReportData?.SprinklerReport?.FieldRep : '',
        IsNewFile: riskReportData?.SprinklerReport?.IsNewFile !== null && riskReportData?.SprinklerReport?.IsNewFile !== undefined
          ? riskReportData?.SprinklerReport?.IsNewFile : true,
        IsPrometrixUploadPending:
          riskReportData?.SprinklerReport?.IsPrometrixUploadPending !== null && riskReportData?.SprinklerReport?.IsPrometrixUploadPending !== undefined
            ? riskReportData?.SprinklerReport?.IsPrometrixUploadPending : true,
        Status: riskReportData?.SprinklerReport?.Status ? riskReportData?.SprinklerReport?.Status : '',
        Stage: riskReportData?.SprinklerReport?.Stage ? riskReportData?.SprinklerReport?.Stage : '',
        IsCustomService: false,
        SurveyDate: model?.survey && model?.survey?.surveyDate ? model?.survey?.surveyDate : null,
        IsScheduleAppliedDate: riskReportData?.SprinklerReport?.IsScheduleAppliedDate ?  riskReportData?.SprinklerReport?.IsScheduleAppliedDate : false,
        RiskId: riskReportData?.SprinklerReport?.RiskId ? riskReportData?.SprinklerReport?.RiskId : '',
        Community: riskReportData?.SprinklerReport?.Community ? riskReportData?.SprinklerReport?.Community : null,
        County: riskReportData?.SprinklerReport?.County ? riskReportData?.SprinklerReport?.County : null,
        Zip: riskReportData?.SprinklerReport?.Zip ? riskReportData?.SprinklerReport?.Zip :null,
        Zip4: riskReportData?.SprinklerReport?.Zip4 ? riskReportData?.SprinklerReport?.Zip4 : null,
        OrderId: riskReportData?.SprinklerReport?.OrderId ? riskReportData?.SprinklerReport?.OrderId : null,
        LastModifiedDate: riskReportData?.SprinklerReport?.LastModifiedDate ? riskReportData?.SprinklerReport?.LastModifiedDate : null,
        AdditionalRawDataValues: riskReportData?.SprinklerReport?.AdditionalRawDataValues?.length ? riskReportData?.SprinklerReport?.AdditionalRawDataValues : [],
        BuildingDescription: riskReportData?.SprinklerReport?.BuildingDescription ? riskReportData?.SprinklerReport?.BuildingDescription : null,
        BuildingNumber: riskReportData?.SprinklerReport?.BuildingNumber ?  riskReportData?.SprinklerReport?.BuildingNumber : null,
        CalculationSummaries: riskReportData?.SprinklerReport?.CalculationSummaries?.length ? riskReportData?.SprinklerReport?.CalculationSummaries : [],
        Comments:  riskReportData?.SprinklerReport?.Comments?.length ? riskReportData?.SprinklerReport?.Comments : [],
        DisplaysActualGrading: riskReportData?.SprinklerReport?.DisplaysActualGrading ? riskReportData?.SprinklerReport?.DisplaysActualGrading: null,
        GeneralInformations: riskReportData?.SprinklerReport?.GeneralInformations?.length ?  riskReportData?.SprinklerReport?.GeneralInformations : [],
        IntermediateValues: riskReportData?.SprinklerReport?.IntermediateValues?.length ?  riskReportData?.SprinklerReport?.IntermediateValues : [],
        OccupancyDescription: riskReportData?.SprinklerReport?.OccupancyDescription ? riskReportData?.SprinklerReport?.OccupancyDescription: null,
        ReceivesPartialSprinklerCredit: riskReportData?.SprinklerReport?.ReceivesPartialSprinklerCredit ? riskReportData?.SprinklerReport?.ReceivesPartialSprinklerCredit : null,
        ReportAttachments: riskReportData?.SprinklerReport?.ReportAttachments?.length ? riskReportData?.SprinklerReport?.ReportAttachments : [],
        ScheduleAppliedDate: riskReportData?.SprinklerReport?.ScheduleAppliedDate ? riskReportData?.SprinklerReport.ScheduleAppliedDate: null,
        SprinklerValveStatuses: riskReportData?.SprinklerReport?.SprinklerValveStatuses ?  riskReportData?.SprinklerReport?.SprinklerValveStatuses : [],
        TypesOfSystems: riskReportData?.SprinklerReport?.TypesOfSystems ? riskReportData?.SprinklerReport?.TypesOfSystems : []
      },
    };
    return sprinklerReport;
  }

  async updateSprinklerCombustibilityValues(value: any) {
    const combustibilityClass = value ? Number(value) : 0;
    const report = this.formlyBuilderService?.riskReport?.report;
    if(combustibilityClass && report?.SprinklerReport?.AsgrWaterSupply?.BuildingCombustibilityClass) {
      this.formlyBuilderService.riskReport.report.SprinklerReport.AsgrWaterSupply.BuildingCombustibilityClass = combustibilityClass;
      if(this.formlyBuilderService?.riskReport?.report?.SprinklerReport?.AsgrBuildingCondition) {
        this.formlyBuilderService.riskReport.report.SprinklerReport.AsgrBuildingCondition.IsC1orC2combustibility = (combustibilityClass === 1 || combustibilityClass === 2);
      }    
    }
  }
}
