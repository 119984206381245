import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionConfig, SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Constants } from 'src/app/services/util-service/constants';

export class EffectiveAreaModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      id: SectionConfig.get(SectionEnum.FR_SECONDARY_CONSTRUCTION).name,
      key: 'effectiveArea',
      wrappers: ['simple-section-wrapper'],
      props: {
        label: 'Effective Area',
        isCollapsable: true,
        isDisabled: false,
        showError: true,
        className: 'effective-area-container',
        isCollapseIconDisabled: false
      },
      expressions: {
        'props.isCollapseIconDisabled': (field: FormlyFieldConfig) => {
          const floorsAndroof = field?.form?.root?.get('floorsAndRoofs')?.get('constructionEntries')?.get('floorsAndRoofsLevels')?.value;
          const arr = floorsAndroof?.filter((item) => (item?.hasDividingWall === true || item?.hasDividingWallLvlOnGrade === true));
          if (arr?.length > 0) {
            return false;
          }
          else {
            return true;
          }
        },
        // 'props.isCollapsed': 'field.props.isCollapseIconDisabled' 
      },
      fieldGroupClassName: 'effective-area-style',
      fieldGroup: [
        {
          fieldGroup :[
            {
              // fieldGroupClassName:'group-style',
              wrappers: ['question-wrapper'],
              props: {
                label: 'Largest floor area',
                showError: false,
                disabled: false,
                isDisabled: false,
                isSectionLabelNormal: true,
              },
              fieldGroup: [
                {
                  key: 'largestFloorArea',
                  type: 'simple-input',
                  props: {
                    label: 'Largest Floor Area',
                    unit: 'sq.ft.',
                    type: 'number',
                    inputmode: 'tel',
                    min: 1,
                    disabled: false,
                    isDisabled: false,
                    readonly: false,
                    required: false,
                    isAsteriskNotRequired: true,
                    isLabelDisabled: true
                  },
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  hooks: {
                    onInit: (field) => {
                      field.formControl.markAsTouched();
                    },
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => {
                      const floorsAndroof = field?.form?.root?.get('floorsAndRoofs')?.get('constructionEntries')?.get('floorsAndRoofsLevels')?.value;
                      const arr = floorsAndroof?.filter((item) => (item?.hasDividingWall === true || item?.hasDividingWallLvlOnGrade === true));
                      if (arr?.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                    'props.readonly': '!field.props.required',
                  },
                  validators: {
                    largestFloorAreaValidator: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        // check roof area in floors and roof section
                        const area = field?.options?.formState?.service?.secondaryConstructionsService?.getLargestFloorArea(field);
    
                        if (Number(control.value) > area) {
                          return false;
                        }
                        else {
                          return true;
                        }
                      },
                      message: 'Largest Floor Area for Effective Area cannot  exceed the largest floor area of the building.'
                    },
                  },
                  className: 'field-style1',
                },
              ],
            },
            {
              // key: 'otherFloors',
              wrappers: ['question-wrapper'],
              props: {
                label: 'Other Floors',
                isDisabled: false,
                isSectionLabelNormal: true,
                showError: false,
                firstColSize: 2
              },
              fieldGroupClassName: 'otherFloor-styling',
              fieldGroup: [
                {
                  key: 'percentOfOtherFloors',
                  className: 'percent-other-floor-style',
                  type: 'simple-select',
                  props: {
                    isLabelDisabled: true,
                    isAsteriskNotRequired: true,
                    isDisabled: false,
                    options: [{ value: '25%', label: '25%' }, { value: "50%", label: "50%" }]
                  },
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  expressions: {
                    'props.isDisabled': (field: FormlyFieldConfig) => {
                      const noOFFloors = field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('aboveGradeFloors')?.value;
                      const constructionClass = field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('constructionClass')?.value;
                      if (noOFFloors && Number(noOFFloors) > 1) {
                        if (constructionClass !== null && Number(constructionClass) === 1 ||  Number(constructionClass) === 2 ||  Number(constructionClass) === 3 ||  Number(constructionClass) === 4) {
                          // field.formControl.setValue('50%');
                          return true;
                        } else {
                          return false;
                        }
                      } else {
                        return true;
                      }
                    }
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      const noOFFloors = field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('aboveGradeFloors');
                      const constructionClass = field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('constructionClass');
                      noOFFloors.valueChanges?.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged())?.subscribe((value) => {
                        let percentValue;
                        if (value && Number(value) > 1) {
                          if (constructionClass?.value !== null && Number(constructionClass?.value) === 1 ||  Number(constructionClass?.value) === 2 ||  Number(constructionClass?.value) === 3 ||  Number(constructionClass?.value) === 4) {
                            percentValue = '50%';
                          }
                          else if((Number(constructionClass?.value) === 5 ||  Number(constructionClass?.value) === 6) && formlyBuilderService.constructionByLevelService.isFloorsAndRoofLoaded) {
                            percentValue = null;
                          }
                        }
                        else if( value && value !== '' && Number(value) === 1) {
                          percentValue = null;
                        }
                        if(percentValue !== undefined) {
                          const updatedFrm = formlyBuilderService.riskReport.model;
                          if (formlyBuilderService?.riskReport?.model?.secondaryConstructions?.effectiveArea) {
                            updatedFrm.secondaryConstructions.effectiveArea.percentOfOtherFloors = percentValue;
                          }
                          formlyBuilderService.riskReport.model = { ...updatedFrm };
                        }
                      });

                      constructionClass.valueChanges?.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged())?.subscribe((value) => {
                        let percentValue;
                        if (noOFFloors && Number(noOFFloors?.value) > 1) {
                          if (Number(value) === 1 || Number(value) === 2 || Number(value) === 3 || Number(value) === 4) {
                            percentValue = '50%';
                          }
                          else if((Number(value) === 5 ||  Number(value) === 6) && formlyBuilderService.constructionByLevelService.isFloorsAndRoofLoaded) {
                            percentValue = null;
                          }
                        }
                        else if(noOFFloors && Number(noOFFloors?.value) == 1) {
                          percentValue = null;
                        }
                        if(percentValue !== undefined) {
                        const updatedFrm = formlyBuilderService.riskReport.model;
                        if (formlyBuilderService?.riskReport?.model?.secondaryConstructions?.effectiveArea) {
                          updatedFrm.secondaryConstructions.effectiveArea.percentOfOtherFloors = percentValue;
                        }
                        formlyBuilderService.riskReport.model = { ...updatedFrm };
                      }
                      })

                    }
                  }
                },
                { template: '<span>or</span>', className: 'text-effective-area text-or' },
                {
                  key: 'numberOfOtherFloors',
                  type: 'simple-select',
                  props: {
                    isLabelDisabled: true,
                    isAsteriskNotRequired: true,
                    isDisabled: false,
                    options: [],
                  },
                  hooks: {
                    onInit: (field) => {
                      const noOFFloors = field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('aboveGradeFloors')?.value;
                      const constructionClass = field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('constructionClass')?.value;
                      field.props.options = formlyBuilderService.secondaryConstructionsService.getLevelOptions(1, noOFFloors,constructionClass);
                    },
                  },
                  expressions: {
                    'props.isDisabled': (field: FormlyFieldConfig) => {
                      const noOFFloors = field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('aboveGradeFloors')?.value;
                      if (noOFFloors > 1) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  }
                },
                { template: '<span>Levels</span>', className: 'text-effective-area' },
                {
                  key: 'otherFloorArea',
                  type: 'simple-input',
                  defaultValue: (formlyBuilderService.riskReport.report.AboveGradeFloors && Number(formlyBuilderService.riskReport.report.AboveGradeFloors)) < 2 ? 0 : null,
                  props: {
                    unit: 'sq.ft.',
                    type: 'number',
                    inputmode: 'tel',
                    isDisabled: false,
                    isAsteriskNotRequired: true
                  },
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  hooks: {
                    onInit: (field) => {
                      field.formControl.markAsTouched();
                    },
                  },
                  expressions: {
                    'props.readonly': (field: FormlyFieldConfig) => {
                      const noOFFloors = field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('aboveGradeFloors')?.value;
                      if (noOFFloors > 1) {
                        return false;
                      }
                      else {
                        return true;
                      }
                    },
                    'props.required': (field: FormlyFieldConfig) => {
                      const floorsAndroof = field?.form?.root?.get('floorsAndRoofs')?.get('constructionEntries')?.get('floorsAndRoofsLevels')?.value;
                      const arr = floorsAndroof?.filter((item) => (item?.hasDividingWall === true || item?.hasDividingWallLvlOnGrade === true));
                      if (arr?.length > 0  && field.props.readonly === false) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                  },
                  validators: {
                    otherFloorAreaVal: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        // check roof area in floors and roof section
                        const area = field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('totalArea')?.value;
                        if (control.value !== null && Number(control.value) > area && field.props.readonly === false) {
                          return false;
                        }
                        else {
                          return true;
                        }
                      },
                      message: 'Other areas for Effective Area cannot exceed the area of the building.'
                    },
                  },
                }
              ]
            },
            {
              wrappers: ['question-wrapper'],
              props: {
                label: 'Total effective area',
                showError: false,
                disabled: false,
                isDisabled: false,
                isSectionLabelNormal: true,
              },
              fieldGroup: [
                {
                  key: 'totalEffectiveArea',
                  type: 'simple-input',
                  props: {
                    isDisabled: true,
                    unit: 'sq.ft.',
                    isLabelDisabled: true,
                    readonly: true,
                  },
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  //get largest floor area & populate it should always be disabled
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      const largestFloorArea = field?.form?.get('largestFloorArea')?.value ? Number(field?.form?.get('largestFloorArea')?.value) : 0;
                      const otherFloorArea = field?.form?.get('otherFloorArea')?.value ? Number(field?.form?.get('otherFloorArea')?.value) : 0;
                      field.formControl.setValue(largestFloorArea + otherFloorArea, { emitEvent: false });

                      field?.form?.get('largestFloorArea').valueChanges?.pipe(takeUntil(this.ngUnsubscribe),
                        startWith(field?.form?.get('largestFloorArea')?.value), pairwise())
                        .subscribe(([ prev, current ]) => {
                          if (current !== null && prev != current) {
                            const otherFloorArea = field?.form?.get('otherFloorArea')?.value ? Number(field?.form?.get('otherFloorArea')?.value) : 0;
                            field.formControl.setValue(Number(current) + otherFloorArea);
                          }
                        });

                      field?.form?.get('otherFloorArea').valueChanges?.pipe(takeUntil(this.ngUnsubscribe),
                        startWith(field?.form?.get('otherFloorArea')?.value), pairwise())
                        .subscribe(([ prev, current ]) => {
                          if (current !== null && prev != current) {
                            const largestFloorArea = field?.form?.get('largestFloorArea')?.value ? Number(field?.form?.get('largestFloorArea')?.value) : 0;
                            field.formControl.setValue(largestFloorArea + Number(current));
                          }
                        });
                    }
                  },
                  validators: {
                    totalEffectiveArea: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        // check roof area in floors and roof section
                        const area = field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('totalArea')?.value;
                        if (Number(control.value) > area) {
                          return false;
                        }
                        else {
                          return true;
                        }
                      },
                      message: 'Total Effective Area cannot exceed building area.'
                    }
                  }
                }
              ]
            },
          ],
          expressions: {
            hide : (field: FormlyFieldConfig) => {
              const floorsAndroof = field?.form?.root?.get('floorsAndRoofs')?.get('constructionEntries')?.get('floorsAndRoofsLevels')?.value;
              const arr = floorsAndroof?.filter((item) => (item?.hasDividingWall === true || item?.hasDividingWallLvlOnGrade === true));
              if (arr?.length > 0) {
                return false;
              }
              else {
                return true;
              }
            },
            // 'props.isCollapsed': 'field.props.isCollapseIconDisabled' 
          },
        },
        {
          key: 'isHeightMultiplierDoesNotApplied',
          wrappers: ['question-wrapper'],
          type: 'custom-checkbox',
          props: {
            label: 'Height Multiplier Does Not Apply',
            isSectionLabelNormal: true,
          },
          hooks:{
          onInit:(field: FormlyFieldConfig)=>{
            const noOFFloors = field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('aboveGradeFloors')?.value;
            const constructionClass = field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('constructionClass')?.value;
            if (noOFFloors >= 3 && (constructionClass?.toString() === '1' || constructionClass?.toString() === '2')) {
               if(field?.formControl?.value === null || field?.formControl?.value === undefined)
                 { field?.formControl?.setValue(false);}
            }
          }
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              const noOFFloors = field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('aboveGradeFloors')?.value;
              const constructionClass = field?.form?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('constructionClass')?.value;
              if (noOFFloors >= 3 && (constructionClass?.toString() === '1' || constructionClass?.toString() === '2')) {
                return false;
              }
              else {
                return true;
              }
            }
          },
        }
      ],
      hooks: {
        onInit: (field?: FormlyFieldConfig) =>{
          field?.formControl?.valueChanges.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(),
          debounceTime(Constants.riskFormDataSyncDelay))
            .subscribe(value => {
              if (!field.formControl.pristine) {
              formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_SECONDARY_CONSTRUCTION, value, true);
              }
            });
          },
          onDestroy: () => {
            this.ngUnsubscribe.next();
            this.ngUnsubscribe.complete();
          }
      },
    });
  }
}

