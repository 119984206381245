/* eslint-disable @typescript-eslint/dot-notation */
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { WallsChargeableColumnService } from 'src/app/services/formly-builder/full-risk/formly-walls-chargeable-column.service';
import { FormControl } from '@angular/forms';
import { Constants } from 'src/app/services/util-service/constants';
import { Subject } from 'rxjs';

export class WallsPanelsModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService, private wallsChargeableColumnService: WallsChargeableColumnService,) {
    super({
      key: 'areChargeablePanels',
      // type: 'segment',
      wrappers: ['simple-section-wrapper'],
      props: {
        label: 'Are there any Chargeable Panels',
        isCollapsable: true,
        isCollapsed: false,
        toggleYesNo: true,
        isDisabled: false,
        required: true,
        className: 'padding-bottom',
        checkTouchedControl: true,
        sectiontitleEnabled: true
      },
      fieldGroup: [
        {
          key: 'chargeablePanels',
          defaultValue: null,
          props: {
          required: true,
          label: 'Are there any Chargeable Panels',
          }
        },
        {
          fieldGroupClassName: 'column-shadow',
          fieldGroup: [
            {
              fieldGroupClassName: 'formly-flex-container panel-card',
              fieldGroup: [
                {
                  key: 'nonCombustible',
                  type: 'custom-checkbox',
                  className: 'nonCombustible-style',
                  resetOnHide: true,
                  props: {
                    label: 'Non - Combustible:',
                    maxLength: 100,
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field.formControl.valueChanges.subscribe((value) => {
                        if (value === false) {
                          field.parent?.formControl['controls']?.masonry?.setValue(null);
                          field.parent?.formControl['controls']?.glass?.setValue(null);
                          field.parent?.formControl['controls']?.nc?.setValue(null);                          
                          field.parent?.formControl['controls']?.percentInputI?.setValue(null);
                        }
                      });
                    }
                  }
                },
                {
                  key: 'masonry',
                  type: 'custom-checkbox',
                  className: 'masonry-style',
                  defaultValue: false,
                  resetOnHide: true,
                  props: {
                    label: 'Masonry',
                    isDisabled: false,
                    readonly: false,
                  },
                  expressions: {
                    'props.isDisabled': (field: FormlyFieldConfig) => {

                      if (field?.form?.value?.glass === true || field?.form?.value?.nc === true || field?.form?.value?.nonCombustible !== true) {
                        return true;
                      }
                    },
                    'props.readonly': 'field.props.isDisabled',
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      const glassControl = field.form.get('glass');
                      const ncControl = field.form.get('nc');
                      if (glassControl.value === true || ncControl.value === true) {
                        field.formControl.setValue(false, { emitEvent: false });
                      }
                      glassControl.valueChanges.subscribe((value) => {
                        if (value === true) {
                          field.formControl.setValue(false, { emitEvent: false });
                        }
                      });
                      ncControl.valueChanges.subscribe((value) => {
                        if (value === true) {
                          field.formControl.setValue(false, { emitEvent: false });
                        }
                      });
                    }
                  }
                },
                {
                  key: 'glass',
                  type: 'custom-checkbox',
                  className: 'glass-style',
                  defaultValue: false,
                  resetOnHide: true,
                  props: {
                    label: 'Glass',
                    isDisabled: false,
                    readonly: false,
                  },
                  expressions: {
                    'props.isDisabled': (field: FormlyFieldConfig) => {

                      if (field?.form?.value?.masonry === true || field?.form?.value?.nonCombustible !== true) {
                        return true;
                      }
                    },
                    'props.readonly': 'field.props.isDisabled',
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      const masonryControl = field?.form?.get('masonry');
                      if (masonryControl.value === true) {
                        field.formControl.setValue(false, { emitEvent: false });
                      }
                      masonryControl.valueChanges.subscribe((value) => {
                        if (value === true) {
                          field.formControl.setValue(false, { emitEvent: false });
                        }
                      })
                    }
                  }
                },
                {
                  key: 'nc',
                  type: 'custom-checkbox',
                  className: 'nc-style',
                  defaultValue: false,
                  resetOnHide: true,
                  props: {
                    label: 'NC',
                    className: 'in-thickness-width',
                    isDisabled: true,
                    readonly: false,
                  },
                  expressions: {
                    'props.isDisabled': (field: FormlyFieldConfig) => {
                      if (field?.form?.value?.masonry === true || field?.form?.value?.nonCombustible !== true) {
                        return true;
                      }
                    },
                    'props.readonly': 'field.props.isDisabled',
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      const masonryControl = field?.form?.get('masonry');
                      if (masonryControl.value === true) {
                        field.formControl.setValue(false, { emitEvent: false });
                      }
                      masonryControl.valueChanges.subscribe((value) => {
                        if (value === true) {
                          field.formControl.setValue(false, { emitEvent: false });
                        }
                      })
                    }
                  }
                },
                {
                  key: 'percentInputI',
                  type: 'simple-input',
                  className: 'percent-width',
                  resetOnHide: true,
                  props: {
                    unit: '% of walls',
                    required: false,
                    type: 'number',
                    inputmode: 'tel',
                    isAsteriskNotRequired: true,
                    min: 1,
                    max: 100,
                    isDisabled: false,
                    readonly: false,
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => field?.model?.nonCombustible === true,
                    'props.isDisabled': (field: FormlyFieldConfig) => field?.form?.value?.nonCombustible !== true,
                    'props.readonly': 'field.props.isDisabled',
                  },
                  hooks: {
                    onInit: (field) => {
                      field.formControl.markAsTouched();
                    },
                  },
                },
              ],
              validators: {
                checkboxCheck: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    if (field?.model?.chargeablePanels === null || field?.model?.chargeablePanels === false || field?.model?.chargeablePanels === undefined || field?.model?.nonCombustible === null || field?.model?.nonCombustible === false || field?.model?.nonCombustible === undefined || field?.model?.masonry === true || field?.model?.glass === true || field?.model?.nc === true) {
                      return true;
                    } else {
                      return false;
                    }
                  },
                  message: 'Panel type required',
                },
              },
            },
            {
              fieldGroupClassName: 'formly-flex-container panel-card',
              fieldGroup: [
                {
                  key: 'slowBurn',
                  type: 'custom-checkbox',
                  className: 'limitedCombustible-style',
                  resetOnHide: true,
                  props: {
                    label: 'Slow Burn:',
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field.formControl.valueChanges.subscribe((value) => {
                        if (value === false) {
                          field.parent?.formControl['controls']?.limitedCombustibleMcm?.setValue(null);
                          field.parent?.formControl['controls']?.percentInputII?.setValue(null);
                        }
                      });
                    }
                  }
                },
                {
                  key: 'limitedCombustibleMcm',
                  type: 'custom-checkbox',
                  className: 'limitedCombustible-mcm-style',
                  resetOnHide: true,
                  props: {
                    label: 'MetalComposite Materials(MCM)',
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !field?.options?.formState?.service._isV2
                  },
                },
                {
                  key: 'percentInputII',
                  type: 'simple-input',
                  className: 'percent-width',
                  resetOnHide: true,
                  props: {
                    unit: '% of walls',
                    required: false,
                    type: 'number',
                    inputmode: 'tel',
                    isAsteriskNotRequired: true,
                    min: 1,
                    max: 100,
                    isDisabled: false,
                    readonly: false,
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => field?.model?.slowBurn === true,
                    'props.isDisabled': (field: FormlyFieldConfig) => field?.form?.value?.slowBurn !== true,
                    'props.readonly': 'field.props.isDisabled',
                  },
                  hooks: {
                    onInit: (field) => {
                      field.formControl.markAsTouched();

                    },
                  },
                },
              ],

            },
            { template: '<span></span>', className: 'new-row' },
            {
              fieldGroupClassName: 'formly-flex-container panel-card',
              fieldGroup: [
                {
                  key: 'combustible',
                  type: 'custom-checkbox',
                  className: 'Combustible-style',
                  resetOnHide: true,
                  props: {
                    label: 'Combustible:',
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field.formControl.valueChanges.subscribe((value) => {
                        if (value === false) {
                          field.parent?.formControl['controls']?.combustibleMcm?.setValue(null);
                          field.parent?.formControl['controls']?.percentInputIII?.setValue(null);
                        }
                      });
                    }
                  }
                },
                {
                  key: 'combustibleMcm',
                  type: 'custom-checkbox',
                  className: 'combustible-mcm-style',
                  resetOnHide: true,
                  props: {
                    label: 'MetalComposite Materials(MCM)',
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !field?.options?.formState?.service._isV2
                  },
                },
                {
                  key: 'percentInputIII',
                  type: 'simple-input',
                  className: 'percent-width',
                  resetOnHide: true,
                  props: {
                    unit: '% of walls',
                    required: false,
                    type: 'number',
                    inputmode: 'tel',
                    isAsteriskNotRequired: true,
                    min: 1,
                    max: 100,
                    isDisabled: false,
                    readonly: false,
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => field?.model?.combustible === true,
                    'props.isDisabled': (field: FormlyFieldConfig) => field?.form?.value?.combustible !== true,
                    'props.readonly': 'field.props.isDisabled',
                  },
                  hooks: {
                    onInit: (field) => {
                      field.form.controls['percentInputIII'].markAsTouched();
                    },
                  },
                },
              ],
            }
          ],
        },
        {
          key: 'panelConstructionType',
          defaultValue: "  ",
        },
      ],
      hooks: {
        // onInit: (field?: FormlyFieldConfig) =>
        //   field.formControl.valueChanges.pipe(
        //     distinctUntilChanged(),
        //     tap((value) => formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_WALLS_PANELS, value, true))
        //   )

        onInit: (field: FormlyFieldConfig) => {
          field.formControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(),
            debounceTime(Constants.riskFormDataSyncDelay))
            .subscribe(value => {
              if (!field.formControl.pristine) {
                formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_WALLS_PANELS, value, true);
              }
            });
        },
        onDestroy: () => {
          this.ngUnsubscribe.next();
          this.ngUnsubscribe.complete();
        }
      },
      expressions: {
        hide:
          (field: FormlyFieldConfig) => {
            const masonryArr = field?.parent?.model?.walls?.wallsArray?.filter(wall =>
              wall.constructionType === 'Masonry' || wall.constructionType === 'Fire Resistive'
            );
            if (masonryArr?.length > 0) {
              return false;
            } else {
              return true;
            }
          },

        'props.isDisabled': (field: FormlyFieldConfig) => {
          if (field?.form?.value?.areChargeablePanels?.nonCombustible === true || field?.form?.value?.areChargeablePanels?.combustible === true || field?.form?.value?.areChargeablePanels?.slowBurn === true) {
           return true;
          }
          else {
            return false;
          }
        },
       // 'props.isCollapsableIconDisabled': (field: FormlyFieldConfig) => { field?.form?.value?.chargeablePanels === null || field?.form?.value?.chargeablePanels === undefined ? true : false; },
       // 'props.isCollapsed': (field: FormlyFieldConfig) => !field?.form?.value?.chargeablePanels 
      },
    });
  }
}
