/* eslint-disable @typescript-eslint/dot-notation */
import { EventEmitter, Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyBuilderService } from '../../formly-builder.service';
import { SectionEnum } from '../../section.config';
import { WaterSupplyDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/water-supply-data.model';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FirePumpTestDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/fire-pump-test-data.model';
import { UtilService } from 'src/app/services/util-service/util.service';
import {
  RackStorageObstructionDataModel
} from 'src/app/models/formly-builder/formly/full-risk/sprinkler/rack-storage-obstruction-data.model';
import {
  WaterSupplyFrictionLossDataModel
} from 'src/app/models/formly-builder/formly/full-risk/sprinkler/water-supply-friction-loss-data.model';

@Injectable({
  providedIn: 'root',
})
export class WaterSupplyService extends SectionServiceAbstract {
  SaveToS3Event: EventEmitter<any> = new EventEmitter<any>();
  options: FormlyFormOptions = {};
  subTitle = '';
  constructor(private formlyBuilderService: FormlyBuilderService, private utilService: UtilService) {
    super();
  }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    let waterSupplyData = WaterSupplyDataModel.to(model?.sprinklerReport?.asgrWaterSupply);
    waterSupplyData.ReportId = waterSupplyData?.ReportId ? waterSupplyData?.ReportId : riskReportData.ReportIdentifier;
    waterSupplyData = this.setHydratorFieldValues(model, waterSupplyData, riskReportData);
    waterSupplyData.TestDateSs1 = this.utilService.convertDateToISOStringFormat(waterSupplyData.TestDateSs1);
    waterSupplyData.TestDateSs2 = this.utilService.convertDateToISOStringFormat(waterSupplyData.TestDateSs2);
    const hasAdequateMaintenance =
      model?.sprinklerReport?.asgrWaterSupply?.evalutionOfWaterSupply?.hasAdequateMaintenance;
    const rackStorageObstruction = RackStorageObstructionDataModel.to(
      model?.sprinklerReport?.asgrWaterSupply?.hydraulicRackStorageObstruction
    );
    rackStorageObstruction.ReportId = rackStorageObstruction.ReportId
      ? rackStorageObstruction.ReportId
      : riskReportData?.ReportIdentifier;

    // Friction loss data
    let fsSupplySource1 = model?.sprinklerReport?.asgrWaterSupply?.asgrWaterSupplyFrictionLoss?.supplySource1;
    let fsSupplySource2 = model?.sprinklerReport?.asgrWaterSupply?.asgrWaterSupplyFrictionLoss?.supplySource2;
    let fsSupplySource = [];
    if (fsSupplySource1) {
      fsSupplySource1 = WaterSupplyFrictionLossDataModel.to(fsSupplySource1);
      fsSupplySource1 = this.formlyBuilderService.frictionLossService.setValveNumberLength(fsSupplySource1);
      fsSupplySource = fsSupplySource.concat(fsSupplySource1);
    }
    if (fsSupplySource2) {
      fsSupplySource2 = WaterSupplyFrictionLossDataModel.to(fsSupplySource2);
      fsSupplySource2 = this.formlyBuilderService.frictionLossService.setValveNumberLength(fsSupplySource2);
      fsSupplySource = fsSupplySource.concat(fsSupplySource2);
    }
    if (fsSupplySource.length > 0) {
      waterSupplyData.WaterSupplyFrictionLosses = fsSupplySource;
    }
    this.formlyBuilderService.buildingConditionService.resetExcessiveHeight();
    let sprinklerData = {
      ...riskReportData.SprinklerReport,
      ...{ HasAdequateMaintenance: hasAdequateMaintenance !== null && hasAdequateMaintenance !== undefined ? hasAdequateMaintenance : null },
      ...{ AsgrWaterSupply: waterSupplyData },
      ...{ AsgrRackStorageObstruction: rackStorageObstruction },
    };
    //update firepump test in SprinklerSystemTest
    const ss1 = model.sprinklerReport?.asgrWaterSupply?.supplySource?.ss1?.supplySourceTypeSs1;
    const ss2 = model.sprinklerReport?.asgrWaterSupply?.supplySource?.ss2?.supplySourceTypeSs2;
    let firePumpTests = [];
    const sprinklerTestFirepumpTest = [];

    if ((ss1 && ss1 === 'Sole Source Pump') || (ss2 && ss2 === 'Booster Pump') || ss2 === 'Sole Source Pump') {
      if (ss1 === 'Sole Source Pump') {
        firePumpTests = model.sprinklerReport.asgrWaterSupply?.supplySource?.ss1?.firePumpTests;
      }
      if (ss2 === 'Booster Pump' || (ss2 === 'Sole Source Pump' && ss1 !== 'Sole Source Pump')) {
        firePumpTests = model.sprinklerReport.asgrWaterSupply?.supplySource?.ss2?.firePumpTests;
      }

      if (firePumpTests && firePumpTests.length > 0) {
        let mostRecentDate = null;
        firePumpTests.forEach((test) => {
          const firepumpTest: any = FirePumpTestDataModel.to(test, riskReportData.ReportIdentifier);
          if (firepumpTest.TestDate) {
            let testDate = new Date(firepumpTest.TestDate);
            // Compare the current testDate with the mostRecentDate
            if (!mostRecentDate || testDate > mostRecentDate) {
              mostRecentDate = testDate;
            }
            firepumpTest.TestDate = this.utilService.convertDateToISOStringFormat(firepumpTest.TestDate);
          }
          sprinklerTestFirepumpTest.push(firepumpTest);
        });
        if (mostRecentDate && riskReportData?.SprinklerReport?.AsgrSprinklerSystemTest?.FirePumpTestDate) {
          riskReportData.SprinklerReport.AsgrSprinklerSystemTest.FirePumpTestDate = mostRecentDate;
        }
      }
      const sprinklerSystemTest = {
        ...riskReportData.SprinklerReport.AsgrSprinklerSystemTest,
        ...{
          ...riskReportData.SprinklerReport.AsgrSprinklerSystemTest,
          ...{ FirePumpTests: sprinklerTestFirepumpTest },
        },
      };
      sprinklerSystemTest.IsFirePumpTestNotApplicable = model?.sprinklerReport?.asgrSprinklerSystemTest?.isFirePumpTestNotApplicable;
      sprinklerData = { ...sprinklerData, ...{ AsgrSprinklerSystemTest: sprinklerSystemTest } };
    }
    const hasAdequatePressureOnMainDrainTest =
      model?.sprinklerReport?.asgrWaterSupply?.hasAdequatePressureOnMainDrainTest;
    sprinklerData = {
      ...sprinklerData,
      AsgrSprinklerSystemTest: {
        ...sprinklerData?.AsgrSprinklerSystemTest,
        ...{ HasAdequatePressureOnMainDrainTest: hasAdequatePressureOnMainDrainTest },
      },
    };

    // Mark the hydrulic and pipeschedule spoc array as [] accordingly, if the hydrulic or pipeschedule entries are not present  
    this.updateHydraulicPipeScheduleSpocEntries(sprinklerData);

    return { ...riskReportData, ...{ SprinklerReport: sprinklerData } };
    // return undefined;
  }

  loadData(sectionData: any[], reportInfo: any, isS3: boolean, model: any): any {
    // const waterSupply: WaterSupplyDataModel = WaterSupplyDataModel.fromS3(reportInfo.SprinklerReport.AsgrWaterSupply);
    // model.sprinklerReport.asgrWaterSupply = waterSupply;
    // return model;
    return undefined;
  }

  getSupplySourceType(model, supplySource) {
    if (supplySource === 'ss1') {
      const supplySource = model?.sprinklerReport?.asgrWaterSupply?.supplySource?.ss1?.supplySourceTypeSs1;
      if (supplySource === 'Booster Pump' || supplySource === 'Sole Source Pump') {
        return supplySource;
      }
    } else if (supplySource === 'ss2') {
      const supplySource = model?.sprinklerReport?.asgrWaterSupply?.supplySource?.ss2?.supplySourceTypeSs2;
      if (supplySource === 'Booster Pump' || supplySource === 'Sole Source Pump') {
        return supplySource;
      }
    }
    return null;
  }

  setHydratorFieldValues(model, waterSupplyData, riskReportData) {
    const supplySource1Type = this.getSupplySourceType(model, 'ss1');
    const supplySource2Type = this.getSupplySourceType(model, 'ss2');
    waterSupplyData.NonBypassPumpForSupplySource =
      supplySource1Type && supplySource1Type === 'Sole Source Pump'
        ? '1'
        : supplySource2Type && supplySource2Type === 'Sole Source Pump'
          ? '2'
          : '';
    waterSupplyData.BypassPumpForSupplySource =
      supplySource1Type && supplySource1Type === 'Booster Pump'
        ? '1'
        : supplySource2Type && supplySource2Type === 'Booster Pump'
          ? '2'
          : '';
    waterSupplyData.IsMainDrainFlowEstimation =
      supplySource1Type === 'Main Drain Estimation' ? true : waterSupplyData.IsMainDrainFlowEstimation;
    return waterSupplyData;
  }

  async onApply(modalCtrl, field) {
    const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const asgrWaterSupply = formlyService.riskReport.model.sprinklerReport.asgrWaterSupply;
    const isFirePumpTestNotApplicable = formlyService.systemTestingService.calculateIsFirePumpTestNotApplicable(asgrWaterSupply);
    formlyService.riskReport.model.sprinklerReport.asgrSprinklerSystemTest = {
      ...formlyService.riskReport.model.sprinklerReport.asgrSprinklerSystemTest,
      isFirePumpTestNotApplicable
    };
    if (formlyService.systemTestingService.checkFirePumpSystemEntry(field, false)) {
      formlyService.systemTestingService.clearFirePumpTesting(formlyService);
    }
    if (asgrWaterSupply?.firePumpEvaluation?.isEvaluatedThroughBypassOnly) {
      formlyService.riskReport.report.SprinklerReport.AsgrSprinklerSystemTest.FirePumpTestDate = null;
      formlyService.riskReport.report.SprinklerReport.AsgrSprinklerSystemTest.FirePumpDateAfterSurvey = null;
      formlyService.riskReport.report.SprinklerReport.AsgrSprinklerSystemTest.HasFirePumpTest = null;
    }
    field?.parent?.formControl[ 'controls' ]?.asgrSprinklerSystemComponent?.controls?.miscItemsLayout?.controls?.hasNoPlansOrHydraulicCalculations?.updateValueAndValidity();
    const riskReport = await formlyService.riskReport.updateSectionData(SectionEnum.FR_S_WATER_SUPPLY, false);
    const newModel = formlyService.sprinklerReportService.loadData(
      riskReport,
      riskReport,
      true,
      formlyService.riskReport.model,
      false
    );
    let firePumpTestDate = formlyService.waterSupplyService.updateSprinklerSystemTest(field.model,formlyService);
    if(firePumpTestDate && field?.get("supplySource")?.props?.isUpdateSupplySource){
      newModel.sprinklerReport.asgrSprinklerSystemTest.firePumpValveTesting.firePumpTestDate = firePumpTestDate;
    }
    if (newModel) {
      formlyService.riskReport.model = { ...newModel };
    }
    formlyService.errorService.closePreviousToaster();
    field.props.isApplied = true;
    formlyService.waterSupplyService.SaveToS3Event.emit();
    modalCtrl.dismiss();    
  }
  updateSprinklerSystemTest(model,riskReportData) {
    //update firepump test in SprinklerSystemTest
    const ss1 = model?.supplySource?.ss1?.supplySourceTypeSs1;
    const ss2 = model?.supplySource?.ss1?.supplySourceTypeSs2;
    let firePumpTests = [];
    const sprinklerTestFirepumpTest = [];
    let mostRecentDate = null;
    if ((ss1 && ss1 === 'Sole Source Pump') || (ss2 && ss2 === 'Booster Pump' || ss2 === 'Sole Source Pump')) {
      if (ss1 === 'Sole Source Pump') {
        firePumpTests = model?.supplySource?.ss1?.firePumpTests;
      }
      if (ss2 === 'Booster Pump' || (ss2 === 'Sole Source Pump' && ss1 !== 'Sole Source Pump')) {
        firePumpTests = model?.supplySource?.ss2?.firePumpTests;
      }

      if (firePumpTests && firePumpTests.length > 0) {
        firePumpTests.forEach((test) => {
          const firepumpTest: any = FirePumpTestDataModel.to(test, riskReportData.ReportIdentifier);
          if (firepumpTest.TestDate) {
            let testDate = new Date(firepumpTest.TestDate);
            // Compare the current testDate with the mostRecentDate
            if (!mostRecentDate || testDate > mostRecentDate) {
              mostRecentDate = testDate;
          }
            firepumpTest.TestDate = this.utilService.convertDateToISOStringFormat(firepumpTest.TestDate);
          };
          sprinklerTestFirepumpTest.push(firepumpTest);
        });
      }
      return mostRecentDate;
    }
  }

  onClose(modalCtrl, field, initialModel) {
    const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const reportData = formlyService.riskReport.report;
    const newModel = formlyService.sprinklerReportService.loadData(
      reportData,
      reportData,
      true,
      formlyService.riskReport.model,
      true
    );
    if (newModel) {
      formlyService.riskReport.model = { ...newModel };
    }
    formlyService?.errorService?.closePreviousToaster();
    modalCtrl.dismiss();
  }

  //set supply source field values on apply of fire pump test input
  setSupplySourceFieldValues(field, sectionName) {

    if (field.parent?.parent?.key == 'ss1') {
      if (sectionName === 'firePumpTest') {
        field.parent.form.get('staticPressureSs1').setValue(field.model?.firePumpTestResults[ 0 ]?.dischargePsi);
        field.parent.form.get('residualPressureSs1').setValue(field.model?.firePumpTestResults[ 1 ]?.dischargePsi);
        field.parent.form.get('waterFlowSs1').setValue(field.model?.firePumpTestResults[ 1 ]?.totalDischargeGpm);
        field.parent.form.get('testDateSs1').setValue(field.model?.firePumpTestInput?.testDate);
      } else if (sectionName === 'mainDrainEstimation') {
        field.parent.form
          .get('staticPressureSs1')
          .setValue(field.formControl.value?.mainDrainEstimation?.staticPressure);
        field.parent.form
          .get('residualPressureSs1')
          .setValue(field.formControl.value?.mainDrainEstimation?.residualPressure);
        field.parent.form.get('waterFlowSs1').setValue(field.formControl.value?.mainDrainEstimation?.flow);
        field.parent.form
          .get('testDateSs1')
          .setValue(field.formControl.value?.mainDrainEstimation?.mainDrainEstimationTestDate);
      }
    } else if (field.parent?.parent?.key == 'ss2') {
      if (sectionName === 'firePumpTest') {
        field.parent.form.get('staticPressureSs2').setValue(field.model?.firePumpTestResults[ 0 ]?.dischargePsi);
        field.parent.form.get('residualPressureSs2').setValue(field.model?.firePumpTestResults[ 1 ]?.dischargePsi);
        field.parent.form.get('waterFlowSs2').setValue(field.model?.firePumpTestResults[ 1 ]?.totalDischargeGpm);
        field.parent.form.get('testDateSs2').setValue(field.model?.firePumpTestInput?.testDate);
      }
    }
    if(field.parent?.parent?.parent?.props?.isUpdateSupplySource === false){
      field.parent.parent.parent.props.isUpdateSupplySource = true;
    }
  }
  setSystemDesignCalculateValues(field) {
    if (
      field?.fieldGroup[ 0 ].form.value.nonNumericDesign.demandFlowComb1 &&
      field?.fieldGroup[ 0 ].form.value.nonNumericDesign.demandFlowComb1 !== null &&
      field?.fieldGroup[ 0 ].form.value.nonNumericDesign.remoteAreaComb1 &&
      field?.fieldGroup[ 0 ].form.value.nonNumericDesign.remoteAreaComb1 !== null
    ) {
      const calculatedDesignDensityComb1 =
        Math.floor(
          Number(
            field?.fieldGroup[ 0 ].form?.value?.nonNumericDesign?.demandFlowComb1 /
            field?.fieldGroup[ 0 ].form.value.nonNumericDesign.remoteAreaComb1
          ) * 100
        ) / 100;
      field.parent.form.get('hydraulicDesignDensity').setValue(calculatedDesignDensityComb1);
      field.parent.form
        .get('hydraulicWaterDemandFlow')
        .setValue(field?.fieldGroup[ 0 ].form.value.nonNumericDesign.demandFlowComb1);
      field.parent.form
        .get('hydraulicRemoteArea')
        .setValue(field?.fieldGroup[ 0 ].form.value.nonNumericDesign.remoteAreaComb1);
    }
    if (
      field?.fieldGroup[ 0 ].form?.value?.nonNumericDesign?.numberOfHeadsComb2 &&
      field?.fieldGroup[ 0 ].form?.value?.nonNumericDesign?.numberOfHeadsComb2 !== null &&
      field?.fieldGroup[ 0 ].form.value.nonNumericDesign.areaPerHeadComb2 &&
      field?.fieldGroup[ 0 ].form.value.nonNumericDesign.areaPerHeadComb2 !== null &&
      field?.fieldGroup[ 0 ].form.value.nonNumericDesign.demandFlowComb2 &&
      field?.fieldGroup[ 0 ].form.value.nonNumericDesign.demandFlowComb2 !== null
    ) {
      const calculatedDesignDensityComb2 =
        Math.floor(
          Number(
            field?.fieldGroup[ 0 ].form?.value?.nonNumericDesign?.demandFlowComb2 /
            (field?.fieldGroup[ 0 ].form?.value?.nonNumericDesign?.numberOfHeadsComb2 *
              field?.fieldGroup[ 0 ].form.value.nonNumericDesign.areaPerHeadComb2)
          ) * 100
        ) / 100;
      field.parent.form.get('hydraulicDesignDensity').setValue(calculatedDesignDensityComb2);
      field.parent.form
        .get('hydraulicWaterDemandFlow')
        .setValue(field?.fieldGroup[ 0 ].form.value.nonNumericDesign.demandFlowComb2);
      field.parent.form
        .get('hydraulicRemoteArea')
        .setValue(
          Number(field?.fieldGroup[ 0 ].form.value.nonNumericDesign.numberOfHeadsComb2) *
          Number(field?.fieldGroup[ 0 ].form.value.nonNumericDesign.areaPerHeadComb2)
        );
    }
  }

  // To update the supplysource2 type options based on supply source1 selection
  updateSupplySouce2Type(field: FormlyFieldConfig) {
    const ss1Type = field?.form?.parent?.controls[ 'ss1' ]?.get('supplySourceTypeSs1');
    const options = [
      { value: 'Municipal Supply', label: 'Municipal Supply' },
      { value: 'Sole Source Pump', label: 'Sole Source Pump' },
      { value: 'Elevated Tank', label: 'Elevated Tank' },
      { value: 'Pressure Tank', label: 'Pressure Tank' }
    ];
    if (
      ss1Type?.value === 'Municipal Supply' ||
      ss1Type?.value === 'Elevated Tank' ||
      ss1Type?.value === 'Main Drain Estimation' ||
      !ss1Type.value
    ) {
      options.splice(1, 0, { value: 'Booster Pump', label: 'Booster Pump' });
    }
    field.props.options = options;
    ss1Type?.valueChanges.subscribe((value) => {
      if (value) {
        const found = options.find((option) => option.value === 'Booster Pump');
        if (value === 'Municipal Supply' || value === 'Elevated Tank' || value === 'Main Drain Estimation') {
          if (!found) {
            options.splice(1, 0, { value: 'Booster Pump', label: 'Booster Pump' });
          }
        } else {
          if (found) {
            if (field.formControl.value === 'Booster Pump') {
              field.formControl?.setValue(null);
            }
            options.splice(1, 1);
          }
        }
        field.props.options = options;
      }
    });
  }

  // To reset the fields based on Type (None Available)
  SupplySource1ValueChangeSubscription(field: FormlyFieldConfig) {
    // on init hook
    if (field?.form?.parent?.controls['ss1']?.get('supplySourceTypeSs1')?.value === 'Municipal Supply') {
      this.bindSupplySourceOne(field);
    }
    field?.formControl?.valueChanges.subscribe((value) => {
      if (value && value === 'None Available') {
        // clear ss1 fields other than Supply source type
        field.parent.fieldGroup.forEach((field) => {
          if (field.key) {
            if (field.key !== 'supplySourceTypeSs1') {
              field?.formControl?.reset();
            }
          }
        });

        // If ss1 is none avaiaable reset ss2 and hide ss2 section
        if (field?.parent?.parent?.formControl['controls']?.ss2) {
          field.parent.parent.formControl['controls']?.ss2.reset();
        }
        if (field?.parent?.parent?.props) {
          field.parent.parent.props.hideSupplySource2 = true;
        }
      }

      if (value && value !== 'Pressure Tank') {
        if (field?.model) {
          field.model.isSupervisedPressureTankSs1 = null;
          //on value change
          if (value && value === 'Municipal Supply') {
            this.bindSupplySourceOne(field);
          }
        }
      }
      this.calculateSoleSourceDependency(field);
    });
  }

  bindSupplySourceOne(field: any) {
    const order = this.formlyBuilderService?.options?.formState?.order;
    if(!field?.model?.staticPressureSs1 && !field?.model?.residualPressureSs1 && !field?.model?.waterFlowSs1 && !field?.model?.testDateSs1 && !field?.model?.locationSs1){
    if (order?.PrePopulatingInformations?.length) {
      const staticPressureInformation = order?.PrePopulatingInformations?.filter(x => x.PrepopulatingInformationType === "StaticPsi");
      const residualPressureInformation = order?.PrePopulatingInformations?.filter(x => x.PrepopulatingInformationType === "ResidualPsi");
      const waterFlowInformation = order?.PrePopulatingInformations?.filter(x => x.PrepopulatingInformationType === "TotalFlow");
      const testDateInformation = order?.PrePopulatingInformations?.filter(x => x.PrepopulatingInformationType === "FlowTestDate");
      const locationInformation = order?.PrePopulatingInformations?.filter(x => x.PrepopulatingInformationType === "FlowTestLocation");

        if (staticPressureInformation?.length > 0) {
          field?.form?.parent?.controls['ss1']?.get('staticPressureSs1')?.setValue(staticPressureInformation[0]?.PrepopulatingInformationValue === null ? 0 : staticPressureInformation[0]?.PrepopulatingInformationValue);
        }
      if (residualPressureInformation?.length > 0) {
        field?.form?.parent?.controls['ss1']?.get('residualPressureSs1')?.setValue(residualPressureInformation[0]?.PrepopulatingInformationValue === null ? 0 : residualPressureInformation[0]?.PrepopulatingInformationValue);
      }
      if (waterFlowInformation?.length > 0) {
        field?.form?.parent?.controls['ss1']?.get('waterFlowSs1')?.setValue(waterFlowInformation[0]?.PrepopulatingInformationValue === null ? 0 : waterFlowInformation[0]?.PrepopulatingInformationValue);
      }
      if (testDateInformation?.length > 0) {
        field?.form?.parent?.controls['ss1']?.get('testDateSs1')?.setValue(testDateInformation[0]?.PrepopulatingInformationValue === null ? "" : testDateInformation[0]?.PrepopulatingInformationValue);
      }
      if (locationInformation?.length > 0) {
        field?.form?.parent?.controls['ss1']?.get('locationSs1')?.setValue(locationInformation[0]?.PrepopulatingInformationValue === null ? "" : locationInformation[0]?.PrepopulatingInformationValue);
      }
    }
  }
  }

  markAsReadOnly(field: FormlyFieldConfig, type: string) {
    return type === 'None Available' ? true : false;
  }

  // To hide supply source 2 section if Supply source 2 is not having any values or Supply source1 type is 'None Avaliable'
  hideSupplySource2Section(field) {
    let hideSupplySource2 = false;

    if (field?.model?.ss1?.supplySourceTypeSs1 === 'None Available') {
      hideSupplySource2 = true;
    } else {
      const ss2 = field?.model?.ss2;
      if (ss2) {
        hideSupplySource2 =
          ss2.feetGaugeSs2 ||
            ss2.locationSs2 ||
            ss2.isSupervisedPressureTankSs2 ||
            ss2.residualPressureSs2 ||
            ss2.staticPressureSs2 ||
            ss2.storedVolumeSs2 ||
            ss2.supplySourceTypeSs2 ||
            ss2.testDateSs2 ||
            ss2.waterFlowSs2
            ? false
            : true;
      } else {
        hideSupplySource2 = true;
      }
    }
    if (hideSupplySource2) {
      if (field?.formControl?.controls.ss2) {
        field.formControl.controls.ss2.reset();
      }
    }
    field.props.hideSupplySource2 = hideSupplySource2;
  }

  saveAttachment(field) {
    console.log('Save attachment Logic goes here');
  }
  setDecimalPoints(field) {
    const value = field?.formControl?.value;
    if (value !== null && value !== undefined) {
      const valStr: string = value.toString();
      const parts: string[] = valStr.split('.');
      if (parts.length === 2) {
        field.formControl.setValue(Number(field.formControl.value).toFixed(2));
      }
    }
  }

  getSpocArea(formlyService) {
    let totalSpocArea = 0;
    const spocArea = [];
    const v1waterSupplyHydraulic = formlyService?.riskReport?.model?.sprinklerReport?.asgrWaterSupply
      ?.waterSupplyHydraulics?.v1WaterSupplyHydraulics
      ? formlyService?.riskReport?.model?.sprinklerReport?.asgrWaterSupply?.waterSupplyHydraulics
        .v1WaterSupplyHydraulics
      : null;
    if (v1waterSupplyHydraulic !== null && v1waterSupplyHydraulic?.length) {
      v1waterSupplyHydraulic.forEach((watersupply) => {
        if (watersupply?.squareFeet) {
          if (watersupply?.squareFeet !== null) {
            totalSpocArea += Number(watersupply.squareFeet);
          }
        }
      });
    }
    return totalSpocArea;
  }

  calculateArea(field: FormlyFieldConfig) {
    let totalArea = 0;
    const designArea = [];
    const model = field.model;
    if (model && model !== null && model?.length) {
      model.forEach((item) => {
        if (item?.areaSquareFeet) {
          if (item?.areaSquareFeet !== null) {
            totalArea += Number(item.areaSquareFeet);
          }
        }
      });
    }
    return totalArea;
  }

  updateHoseStream(field) {
    if (
      field?.model?.hydraulicDesignDensity &&
      field?.model?.hydraulicRemoteArea &&
      field?.model?.hydraulicHoseDemand &&
      field?.model?.hydraulicWaterDemandFlow
    ) {
      const checkHoseDemand =
        Number(field.model.hydraulicDesignDensity * field.model.hydraulicRemoteArea) +
        Number(field.model.hydraulicHoseDemand);
      if (checkHoseDemand <= Number(field.model.hydraulicWaterDemandFlow)) {
        field?.form?.get('hydraulicIncludesHoseDemand')?.setValue(true);
      } else {
        field?.form?.get('hydraulicIncludesHoseDemand')?.setValue(false);
      }
    }else{
      field?.form?.get('hydraulicIncludesHoseDemand')?.setValue(false);
    }
  }

  getoccupantSprinklerDesign(field) {
    const formlyService = field?.options?.formState?.service;
    const occupantLevels = [];
    const occupants = formlyService?.riskReport?.model?.occupants;
    const occupantData = occupants
      ? 'section-occupants' in occupants
        ? occupants[ 'section-occupants' ]?.occupants
        : null
      : null;
    if (occupantData !== null && occupantData?.length) {
      occupantData.forEach((occupant) => {
        if (occupant?.occupantLevels?.length) {
          occupant?.occupantLevels.forEach((occupantLevel) => {
            if (occupantLevel?.sprinklerDesign !== null) {
              occupantLevels.push(occupantLevel.sprinklerDesign);
            }
          });
        }       
      });
    }
    const checkSprinklerDesign =
      occupantLevels?.length > 0 ? occupantLevels.every((value, index, occupant) => value === occupant[ 0 ]) : false;
    if (checkSprinklerDesign) {
      switch (occupantLevels[ 0 ]) {
        case '13R':
          field.form.get('hydraulicDesignMethod')?.setValue('HCMC');
          break;
        case 'Hydraulic':
          field.form.get('hydraulicDesignMethod')?.setValue('HCMC');
          break;
        case 'Hydraulic - CMDA':
          field.form.get('hydraulicDesignMethod')?.setValue('CMDA');
          break;
        case 'Hydraulic - CMSA':
          field.form.get('hydraulicDesignMethod')?.setValue('CMSA');
          break;
        case 'Hydraulic - ESFR':
          field.form.get('hydraulicDesignMethod')?.setValue('ESFR');
      }
    } else {
      const designMethod = field.form.get('hydraulicDesignMethod')?.value;
      field.form.get('hydraulicDesignMethod')?.value
        ? field.form.get('hydraulicDesignMethod')?.setValue(designMethod)
        : field.form.get('hydraulicDesignMethod')?.setValue(null);
    }
  }

  calculateHydraulicDesignStandard(sprinklerDesignList) {
    // In back end We have the BOTH, NFPA13, NFPA13R, NULL
    let sprinklerDesign = 'NULL';
    if (sprinklerDesignList?.length) {
      if ((sprinklerDesignList.includes('Hydraulic') || sprinklerDesignList.includes('Pipe Schedule') || sprinklerDesignList.includes('Hydraulic - CMSA') ||  sprinklerDesignList.includes('Hydraulic - ESFR') ||  sprinklerDesignList.includes('Hydraulic - CMDA')) &&
      sprinklerDesignList.includes('13R')) {
        sprinklerDesign = 'BOTH';
      } else if (sprinklerDesignList.includes('Hydraulic') || sprinklerDesignList.includes('Pipe Schedule') || sprinklerDesignList.includes('Hydraulic - CMSA') ||  sprinklerDesignList.includes('Hydraulic - ESFR') ||  sprinklerDesignList.includes('Hydraulic - CMDA')) {
        sprinklerDesign = 'NFPA13';
      } else if (sprinklerDesignList.includes('13R')) {
        sprinklerDesign = 'NFPA13R';
      }
      return sprinklerDesign;
    }    
  }

  supplySource2ValueChangeSubscription(field) {
    field?.formControl?.valueChanges.subscribe((value) => {
      if (value && value !== 'Pressure Tank') {
        if (field?.model) {
          field.model.isSupervisedPressureTankSs2 = null;
        }
      }
      this.calculateSoleSourceDependency(field);
    });
  }

  // To SetSubtitle for the Sheet
  setSubTitle(selectedTab, field) {
    if (
      selectedTab == SectionEnum.FR_S_WS_WATERSUPPLY ||
      selectedTab == SectionEnum.FR_S_WS_HYDRAULIC ||
      selectedTab == SectionEnum.FR_S_WS_PIPE_SCHEDULE ||
      selectedTab == SectionEnum.FR_S_WS_FRICTION_LOSS
    ) {
      if (selectedTab && field?.parent?.props) {
        switch (+selectedTab) {
          case SectionEnum.FR_S_WS_WATERSUPPLY:
            field.parent.props.subTitle = 'Manage Water Supply';
            break;
          case SectionEnum.FR_S_WS_HYDRAULIC:
            field.parent.props.subTitle = 'Hydraulic';
            break;
          case SectionEnum.FR_S_WS_PIPE_SCHEDULE:
            field.parent.props.subTitle = 'Pipe Schedule';
            break;
          case SectionEnum.FR_S_WS_FRICTION_LOSS:
            field.parent.props.subTitle = 'Friction Loss';
            break;
          default:
            field.parent.props.subTitle = 'Manage Water Supply';
            break;
        }
      }
    }
  }

  // Hydralic System Design some of the Fields required based on spoc entries and isWaterSupplyNotEvaluated
  // Will return true if the given condition saticifies
  isHydralicSystemDesignFieldRequired(field, formlyService) {
    // const spocNumbers = this.getSpocNumber(formlyService);
    const isSpocNumberExist = this.formlyBuilderService?.options?.formState?.isHydraulicEntryPresent;
    const v1spocNumbers = formlyService?.riskReport?.model?.sprinklerReport?.asgrWaterSupply
    ?.waterSupplyHydraulics?.v1WaterSupplyHydraulics;
    if (isSpocNumberExist && v1spocNumbers && !field?.form?.parent?.value?.isWaterSupplyNotEvaluated) {
      return true;
    }
    return false;
  }

  /**
   * Check if the Pipe Schedule sub section should be enabled or disabled
   *
   * @returns false if the Pipe Schedule sub section should be disabled
   */
  checkPipeSchedule(field: FormlyFieldConfig): boolean {
    const formState = this.formlyBuilderService?.options?.formState;
    return formState?.isPipeScheduleEntryPresent
  }

  
  /**
 * Check if the Hydraulic sub section should be enabled or disabled
 *
 * @returns false if the Hydraulic sub section should be disabled
 */
  checkHydraulic(): boolean {
    const formState = this.formlyBuilderService?.options?.formState;
    return formState?.isHydraulicEntryPresent;
  }

  updateAdjustmentField(field) {
    field?.formControl?.root.controls?.sprinklerReport?.controls.asgrWaterSupply?.controls.adjustmentRequirement?.updateValueAndValidity();
  }

  // Ground Floor Area is a Hydrator field, It needs to be calculated when ever there is a chnge in floors roof
  updateGroundFloorArea() {
    const model = this.formlyBuilderService?.riskReport?.model;
    const lowestLevelArea = this.getGroundFloorArea();
    if (model.evidenceOfFireSprinkler === "true") {
      this.formlyBuilderService.riskReport.model = {
        ...model,
        sprinklerReport: {
          ...model.sprinklerReport,
          asgrWaterSupply: {
            ...model.sprinklerReport?.asgrWaterSupply,
            ... { groundFloorArea: lowestLevelArea }
          }
        }
      };
      const riskReportData = this.formlyBuilderService.riskReport.report;
      this.formlyBuilderService.riskReport.report = {
        ...riskReportData,
        SprinklerReport: {
          ...riskReportData.SprinklerReport,
          AsgrWaterSupply: {
            ...riskReportData.SprinklerReport?.AsgrWaterSupply,
            ...{ GroundFloorArea: lowestLevelArea}
          }
        }
      }
    }
  }

  getGroundFloorArea() {
    let lowestLevelArea;
    const constructionEntries = this.formlyBuilderService?.riskReport?.model?.floorsAndRoofs?.constructionEntries;
    if (constructionEntries?.floorsAndRoofsLevels?.length) {
      const lowestLevel = constructionEntries.floorsAndRoofsLevels.find(floorRoof => floorRoof.isLowestLevel);
      lowestLevelArea = lowestLevel ? constructionEntries.levelOnGradeArea : 0;
    }
    return lowestLevelArea;
  }

  pipScheduleReset(){
    const waterSupplyAdjustmentCodeValue = WaterSupplyDataModel.setWaterSupplyAdjustmentCodeValue(null);
    const dpvSytemValue = WaterSupplyDataModel.setDpvSystemValue(null);
    const riskReportService = this.formlyBuilderService.riskReport;
    const model = riskReportService.model;
    riskReportService.model = {
      ...model,
      sprinklerReport: {
        ...model.sprinklerReport,
        asgrWaterSupply: {
          ...model.sprinklerReport?.asgrWaterSupply,
          ...{ hasCreditableSupervisionOfWaterFlow: false },
          ...{ hollowSheathedAreaRangeMinimum: null },
          ...{ adjustmentRequirement: waterSupplyAdjustmentCodeValue },
          ...{ dpv: dpvSytemValue },
          ...{ waterSupplyPipeSchedules: { v1WaterSupplyPipeSchedules: [], v2WaterSupplyPipeSchedules: [] } }

        }
      }
    };
  }

  updatePipeScheduleModel(){
    const  isPipeScheduleEntryPresent = this.formlyBuilderService.options.formState.isPipeScheduleEntryPresent;
    if(!isPipeScheduleEntryPresent){
      this.pipScheduleReset();
    }
  }

  updateHydraulicModel(field){
    const  isHydraulicEntryPresent = this.formlyBuilderService.options.formState.isHydraulicEntryPresent;
    if(!isHydraulicEntryPresent){
      this.hydraulicReset();
    }
  }
  hydraulicReset(){
    const sprinklerReport = this.formlyBuilderService.riskReport?.report?.SprinklerReport;
    const hydraulicDensityConversion1= WaterSupplyDataModel.setHydraulicDensityConversion(null);
    const hydraulicRackStorageObstruction1 = WaterSupplyDataModel.setHydraulicRackStorageObstruction(null,sprinklerReport);
    const hydraulicSystemDesign1 = WaterSupplyDataModel.setHydraulicSystemDesign(null);
    const riskReportService = this.formlyBuilderService.riskReport;
    const model = riskReportService.model;
    const hydraulicReportModel = riskReportService?.report?.SprinklerReport?.HydraulicDesignData;
    if(hydraulicReportModel){
      riskReportService.report.SprinklerReport.HydraulicDesignData = [];
    }
    riskReportService.model = {
      ...model,
      sprinklerReport: {
        ...model.sprinklerReport,
        asgrWaterSupply: {
          ...model.sprinklerReport?.asgrWaterSupply,
          ...{ isWaterSupplyNotEvaluated: false },
          ...{ isWaterSupplyNotEvaluatedComment: null },
          ...{ hasAdequatePressureOnMainDrainTest: null },
          ...{ hydraulicDensityConversion: hydraulicDensityConversion1 },
          ...{ hydraulicRackStorageObstruction: hydraulicRackStorageObstruction1 },
          ...{ hydraulicSystemDesign: hydraulicSystemDesign1 },
          ...{ waterSupplyHydraulics: { v1WaterSupplyHydraulics: [], v2WaterSupplyHydraulics: [] } },
          ...{ hydraulicDataDesign: [] },
        }
      }
    };    
    riskReportService.model = { ...riskReportService.model };   
  }

  updateHydraulicPipeScheduleSpocEntries(sprinklerData) {
    if(!this.formlyBuilderService.options.formState.isHydraulicEntryPresent) {
      sprinklerData.AsgrWaterSupply.WaterSupplyHydraulics = [];
    }
    if(!this.formlyBuilderService.options.formState.isPipeScheduleEntryPresent) {
      sprinklerData.AsgrWaterSupply.WaterSupplyPipeSchedules = [];
    }
  }

  requiredCheckOfPumps(field) {
    const model = field.parent.parent.parent.parent.parent.parent.parent?.model;
    const supplySource1Type = this.getSupplySourceType(model, 'ss1');
    const supplySource2Type = this.getSupplySourceType(model, 'ss2');
    if ((supplySource1Type === 'Sole Source Pump' || supplySource1Type === 'Booster Pump' ||
      supplySource2Type === 'Sole Source Pump' || supplySource2Type === 'Booster Pump') ||
      model?.sprinklerReport?.asgrWaterSupply?.firePumpEvaluation?.isEvaluatedThroughBypassOnly ||
      model?.sprinklerReport?.asgrSprinklerSystemTest?.isFirePumpTestNotApplicable === false
    ) {
      return true;
    }
    return false;
  }

  public getSoleSourceDependencyLookupData(isDisabled): any[] {
    // Since radio btn is not working with value null, For now proceeding with '',
    // In the data model will convert the '' to null
    return [
      { label: 'N/A', value: '', disabled: isDisabled },
      { label: 'Sole Source', value: 'AUTO', disabled: isDisabled },
      { label: 'Booster Pump', value: 'BOOST', disabled: isDisabled }
    ]
  }

  /**
   * @param {*} field
   * @return {*} 
   * @memberof WaterSupplyService
   * if the value is sole source pump then AUTO, If it is booster pump then BOOST
   * If both ss1 and ss2 are fire Pump then we need to send sole source pump(AUTO)
   * if isEvaluatedThroughBypassOnly is true then soleSourceDependency null
   */
  calculateSoleSourceDependency(field, isSupplySourceDelete = false) {
    if ((field.formControl.value !== null && field.formControl.value !== undefined && field.formControl.value !== '') || isSupplySourceDelete) {
      const model = this.formlyBuilderService.riskReport.model;
      const supplySource1Type = this.getSupplySourceType(model, 'ss1');
      const supplySource2Type = this.getSupplySourceType(model, 'ss2');
      const isEvaluatedThroughBypassOnly = model?.sprinklerReport?.asgrWaterSupply?.firePumpEvaluation?.isEvaluatedThroughBypassOnly;
      let soleSourceDependency = (supplySource1Type && supplySource2Type) || supplySource1Type === 'Sole Source Pump' ||
        supplySource2Type === 'Sole Source Pump' ? 'AUTO' : supplySource2Type === 'Booster Pump' ? 'BOOST' : '';
      if (!supplySource1Type && !supplySource2Type) {
        if (isEvaluatedThroughBypassOnly) {
          soleSourceDependency = '';
        }
      }
      if (field.form?.root?.get('sprinklerReport.asgrWaterSupply.firePumpEvaluation.soleSourceDependency')?.value !== soleSourceDependency) {
        field.form?.root?.get('sprinklerReport.asgrWaterSupply.firePumpEvaluation.soleSourceDependency')?.setValue(soleSourceDependency);
      }
      if (soleSourceDependency && isEvaluatedThroughBypassOnly) {
        field.form?.root?.get('sprinklerReport.asgrWaterSupply.firePumpEvaluation.isEvaluatedThroughBypassOnly').setValue(false);
      }
    }
  }
  /**
   * @return {*} 
   * @memberof WaterSupplyService
   * Disable the radio btns of soleSource dependency if any of supply source type is
   * sole source pump or booster pump or if isEvaluatedThroughBypassOnly checkbox is checked
   */
  disableSoleSourceDependency(): any {
    const model = this.formlyBuilderService.riskReport.model;
    const supplySource1Type = this.getSupplySourceType(model, 'ss1');
    const supplySource2Type = this.getSupplySourceType(model, 'ss2');
    const isEvaluatedThroughBypassOnly = model?.sprinklerReport?.asgrWaterSupply?.firePumpEvaluation?.isEvaluatedThroughBypassOnly;
    if (supplySource1Type || supplySource2Type || isEvaluatedThroughBypassOnly) {
      return this.getSoleSourceDependencyLookupData(true);
    } else {
      return this.getSoleSourceDependencyLookupData(false);
    }
  }
}
