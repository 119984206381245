import { FormGroup, FormControl } from '@angular/forms';

export const validateAllFormFields = (formGroup: FormGroup, shouldEmitEvent?: boolean) => {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      /** clear the error state that was added by the api. see modelStateFormMapper function*/
      control.updateValueAndValidity({ onlySelf: true, emitEvent: shouldEmitEvent ? true : false });
      // control.markAsDirty({ onlySelf: true });
      // control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      validateAllFormFields(control);
    }
  });
};
