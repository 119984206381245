import { Inject, Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyBuilderService } from '../../formly-builder.service';
import { SectionEnum } from '../../section.config';
import { CalculationInterface } from 'src/app/interfaces/formly-builder/calculation.interface';
import { Observable } from 'rxjs';
import { CalculationStatusInterface } from 'src/app/interfaces/formly-builder/calculation-status.interface';
import { CalculationEnum } from 'src/app/enums/formly-builder/calculation.enum';
import { AuthHttpService } from 'src/app/auth/auth-http.service';
import { RiskReportService } from '../../risk-report.service';
import { Constants } from 'src/app/services/util-service/constants';
import { ENV, Environment } from 'src/app/interfaces/env.interface';

@Injectable({
  providedIn: 'root',
})
export class ScoreCardService extends SectionServiceAbstract implements CalculationInterface {
  calculationStatus: number;
  constructor(
    private httpService: AuthHttpService,
    private riskReportService: RiskReportService,
    @Inject(ENV) private environment: Environment
  ) {
    super();
  }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    return undefined;
  }

  loadData(sprinklers: any[], reportInfo: any, isS3: boolean, model: any): any {
    return null;
  }
  onClose(modalCtrl, field) {
    modalCtrl.dismiss();
  }

  getDeficiecnypointsList(selectedIndex: number, version: string) {
    // return this.riskReportService.model.sprinklerReport.defListV1[selectedIndex];
    if (this.riskReportService.model?.sprinklerReport?.defListV1[ selectedIndex ] ||
      this.riskReportService.model?.sprinklerReport?.defListV2[ selectedIndex ]) {
      if (version === 'v1') {
        return this.riskReportService.model?.sprinklerReport?.defListV1[ selectedIndex ];
      } else if (version === 'v2') {
        return this.riskReportService.model?.sprinklerReport?.defListV2[ selectedIndex ];
      }
    }
  }

  /**
   * Method to execute calculate api
   */
  async calculateScore(payload: any): Promise<any> {
    payload = this.riskReportService.unflattensFullRiskReport(payload);
    if (payload.SprinklerReport && !Object.keys(payload.SprinklerReport).length) {
      payload.SprinklerReport = null;
    }
    else {
      payload.SprinklerReport.IsCustomService = false;
    }
    // if (payload.WindReport && !Object.keys(payload.WindReport).length) {
    //   payload.WindReport = {};
    // }
    console.log(payload);
    // const url = 'https://underwritingapit-internal-isurvey.iso.com/api/risks/calculate-full-risk';
    const url = this.environment.iSurveyAPIurl + Constants.calculateAPI;
    return this.httpService.request('POST', url, payload);
  }
  /**
   * Method to help simulating a long running calculation
   *
   * @param ms
   * @returns
   */
  sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  /**
   * Method to simulate a long running calculation
   *
   * @param formlyBuilder
   * @returns
   */

  calculate(formlyBuilder: FormlyBuilderService): Observable<CalculationStatusInterface> {
    return new Observable((subscriber) => {
      //TODO: Invoke remote api to get new riskreport
      //TODO : We have to reload riskreport
      //TODO: Extract the relevant part of the new riskreport to create score card model which is different from formly model
      this.sleep(100).then(() => {
        //TODO: change model (replace risk model)
        // formlyBuilder.riskreport.model.sprinklerReport.scoreCard.v1.waterSupplyScore =
        subscriber.next({
          enum: CalculationEnum.SCORE_CARD,
          name: '',
          status: 22,
          comment: 'Ended',
          service: this,
        });
      });
    });
  }
}

