/* eslint-disable @typescript-eslint/naming-convention */
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class LightFrameConstructionDataModel {
  static sectionName = 'lightFrameConstruction';
  isFloorPercentLightFrameConstructionNotAvailable: boolean;
  isRoofPercentLightFrameConstructionNotAvailable: boolean;
  floorPercentLightFrameConstruction: string;
  roofPercentLightFrameConstruction: string;

  static fromS3(reports: any): LightFrameConstructionDataModel {

    return {
        isFloorPercentLightFrameConstructionNotAvailable: reports?.IsFloorPercentLightFrameConstructionNotAvailable,
        isRoofPercentLightFrameConstructionNotAvailable: reports?.IsRoofPercentLightFrameConstructionNotAvailable,
        floorPercentLightFrameConstruction: reports?.FloorPercentLightFrameConstruction,
        roofPercentLightFrameConstruction: reports?.RoofPercentLightFrameConstruction,
    };
  }

  static to(model: any, sectionEnum?: SectionEnum) {
    return {
      IsFloorPercentLightFrameConstructionNotAvailable: model?.floorsAndRoofs?.lightFrameConstruction?.isFloorPercentLightFrameConstructionNotAvailable ? model?.floorsAndRoofs?.lightFrameConstruction?.isFloorPercentLightFrameConstructionNotAvailable : false,
      IsRoofPercentLightFrameConstructionNotAvailable: model?.floorsAndRoofs?.lightFrameConstruction?.isRoofPercentLightFrameConstructionNotAvailable ? model?.floorsAndRoofs?.lightFrameConstruction?.isRoofPercentLightFrameConstructionNotAvailable : false,
      FloorPercentLightFrameConstruction: model?.floorsAndRoofs?.lightFrameConstruction?.floorPercentLightFrameConstruction ? model?.floorsAndRoofs?.lightFrameConstruction?.floorPercentLightFrameConstruction : null,
      RoofPercentLightFrameConstruction: model?.floorsAndRoofs?.lightFrameConstruction?.roofPercentLightFrameConstruction ? model?.floorsAndRoofs?.lightFrameConstruction?.roofPercentLightFrameConstruction : null,
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    return true;
  }
}