import { Guid } from 'guid-typescript';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class WaterSupplyHydraulicDataModel {
    static sectionName = 'waterSupplyHydraulic';

    waterSupplyHydraulicId: string;
    reportId: string;
    spocNumber: number;
    squareFeet: number;
    scopesVersion: number;

    // transient variables
    precentage: number;
    isMostDemanding: boolean;

    static fromS3(wsHydraulicData: any, sprinklerReport: any): WaterSupplyHydraulicDataModel {

        return {
            waterSupplyHydraulicId: wsHydraulicData.WaterSupplyHydraulicId,
            reportId: sprinklerReport?.ReportId,
            spocNumber: wsHydraulicData.SpocNumber,
            squareFeet: wsHydraulicData.SquareFeet,
            scopesVersion: wsHydraulicData.ScopesVersion,
            precentage: 0,
            isMostDemanding: false
        };
    }

    static to(model: any, sectionEnum?: SectionEnum) {
        return {
            WaterSupplyHydraulicId: model.waterSupplyHydraulicId,
            ReportId: model.reportId,
            SpocNumber: model.spocNumber ?  model.spocNumber : 0,
            SquareFeet: model.squareFeet ? model.squareFeet : 0,
            ScopesVersion: model.scopesVersion ? model.scopesVersion : 0
        }
    }

    static createEmptySpocModel(reportId, index, scope) {
        return {
            waterSupplyHydraulicId: Guid.create().toString(),
            reportId: reportId,
            spocNumber: index,
            squareFeet: 0,
            scopesVersion: scope,
            precentage: 0,
            isMostDemanding: false
        }
    }

    static clearSpocModel(wsHydraulicData) {
        return {
            waterSupplyHydraulicId: wsHydraulicData?.waterSupplyHydraulicId,
            reportId: wsHydraulicData?.reportId,
            spocNumber: wsHydraulicData?.spocNumber,
            squareFeet: 0,
            scopesVersion: wsHydraulicData?.scopesVersion,
            precentage: 0,
            isMostDemanding: false
        };
    }

    /**
     * Check if the model has changed
     *
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     *
     */
    static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
        return false;

    }
}
