import { FormlyFieldConfig } from '@ngx-formly/core';

export const MOCK_OVERVIEW: FormlyFieldConfig[] = [
  {
    wrappers: [ 'section-wrapper' ],
    key: 'Name',
    props: {
      label: 'Name',
      debug: true,
      anchor: 'overview1',
    },
    fieldGroup: [
      {
        type: 'input',
        key: 'name',
        props: {
          label: 'Name',
          disabled: false,
        },
      },
      {
        type: 'input',
        key: 'lastName',
        props: {
          label: 'last name',
          disabled: false,
        },
      },
    ],
  },
  {
    wrappers: [ 'section-wrapper' ],
    key: 'overview',
    props: {
      label: 'Address',
      debug: false,
      anchor: 'overview1',
    },
    fieldGroup: [
      {
        type: 'input',
        key: 'Street',
        props: {
          label: 'Street',
          disabled: false,
        },
      },
      {
        type: 'input',
        key: 'zipCode',
        props: {
          label: 'Zip code',
          disabled: false,
        },
      },
    ],
  },
];
