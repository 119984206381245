import { Injectable } from '@angular/core';
import { FormlyBuilderService } from '../../formly-builder.service';
import { SectionEnum } from '../../section.config';
import { UtilService } from 'src/app/services/util-service/util.service';
import { SystemComponentSprinklersDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/system-component-sprinklers-data.model';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyWrapperService } from '../../formly-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class SprinklerTableService extends SectionServiceAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService, private utilService: UtilService) {
    super();
  }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    let sprinkerTable = [];
    if ('automaticSprinklers' in model.sprinklerReport.asgrSprinklerSystemComponent) {
      const sprinkerTableData = this.setSprinklerTableValue(
        model.sprinklerReport.asgrSprinklerSystemComponent?.automaticSprinklers.sprinklers
      );
      sprinkerTable = SystemComponentSprinklersDataModel.to(sprinkerTableData);
      const sprinklerData = {
        ...riskReportData.SprinklerReport,
        ...{ Sprinklers: sprinkerTable },
      };
      return { ...riskReportData, ...{ SprinklerReport: sprinklerData } };
    }
  }

  loadData(sectionData: any[], riskReportData: any, isDataInS3Format: boolean, model: any) {
    return null;
  }

  async onApply(modalCtrl, field) {
    const riskReport = field.options.formState.riskReportService.updateSectionData(
      SectionEnum.FR_SR_SPRINKLERS,
      false,
      null,
      SprinklerTableService
    );

    modalCtrl.dismiss(field, 'save');
  }

  onClose(modalCtrl, field,initialModel) {
    field.formControl.reset();
    field.options.formState.service.riskReport.model.sprinklerReport.asgrSprinklerSystemComponent.automaticSprinklers.sprinklers =
      initialModel;
    field.options.formState.service.riskReport.model = {
      ...field.options.formState.service.riskReport.model,
    };
    field.options.formState.service.errorService.closePreviousToaster();
    modalCtrl.dismiss(field, 'cancel');
  }

  /**
   * One of # Upright, # Pendant, # Side Wall, or # Dry Pendant requires an entry validation
   * @param field
   * @returns
   */
  checkRequiredField(field): boolean {
    const dryPendent = field?.form?.get('numberOfDryPendantSprinklers')?.value;
    const sideWall = field?.form?.get('numberOfSideWallSprinklers')?.value;
    const upRight = field?.form?.get('numberOfUprightSprinklers')?.value;
    const pendent = field?.form?.get('numberOfPendantSprinklers')?.value;

    if (
      (dryPendent === null || dryPendent === undefined || dryPendent === '') &&
      (sideWall === null || sideWall === undefined || sideWall === '') &&
      (upRight === null || upRight === undefined || upRight === '') &&
      (pendent === null || pendent === undefined || pendent === '')
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   *
   * @param field
   * @returns
   */
  hasDuplicateWithDifferentFields(field): boolean {
    let allValues;

    if (typeof field.formControl.value === 'string') {
      allValues = field?.parent.form.value;
    } else {
      allValues = field?.formControl.value;
    }
    const systemNumbers = {};
    for (const obj of allValues) {
      if (systemNumbers[obj.systemNumber]) {
        // Found a duplicate systemNumber
        const existingObjs = systemNumbers[obj.systemNumber];
        for (const existingObj of existingObjs) {
          if (
            existingObj.sprinklerMakeAndModel !== obj.sprinklerMakeAndModel ||
            Number(existingObj.yearManufactured) !== Number(obj.yearManufactured) ||
            Number(existingObj.numberOfUprightSprinklers) !== Number(obj.numberOfUprightSprinklers) ||
            Number(existingObj.numberOfPendantSprinklers) !== Number(obj.numberOfPendantSprinklers) ||
            Number(existingObj.numberOfDryPendantSprinklers) !== Number(obj.numberOfDryPendantSprinklers) ||
            Number(existingObj.numberOfSideWallSprinklers) !== Number(obj.numberOfSideWallSprinklers) ||
            Number(existingObj.orificeSize) !== Number(obj.orificeSize) ||
            Number(existingObj.temperatureRating) !== Number(obj.temperatureRating)
          ) {
            // Other fields are different, continue checking
            continue;
          } else {
            // Other fields are the same, return true
            return true;
          }
        }
        // If no match found, add the object to existingObjs array
        existingObjs.push(obj);
      } else {
        // Store the object with its systemNumber
        systemNumbers[obj.systemNumber] = [obj];
      }
    }
    // No duplicate systemNumber with different fields found
    return false;
  }

  /**
   *
   * @param sprinklers
   * @returns
   */
  setSprinklerTableValue(sprinklers) {
    let sprinklersArray = [];
    if (sprinklers !== null && sprinklers) {
      if (sprinklers.length > 0) {
        sprinklersArray = sprinklers.map((item) => {
          item.reportId = this.formlyBuilderService.riskReport.report.SprinklerReport?.ReportId;
          item.sprinklerId =
            item?.sprinklerId !== null && item?.sprinklerId ? item?.sprinklerId : this.utilService.uuid();
          return item;
        });
        sprinklersArray = sprinklersArray.filter((item) => {
          const isAllNull =
            item.systemNumber === null &&
            item.yearManufactured === null &&
            item.sprinklerMakeAndModel === null &&
            item.temperatureRating === null &&
            (item.numberOfDryPendantSprinklers === null ||
              item.numberOfSideWallSprinklers === null ||
              item.numberOfPendantSprinklers === null ||
              item.numberOfUprightSprinklers === null);
          if (!isAllNull) {
            return item;
          }
        });
      }
    }
    return sprinklersArray;
  }
}
