import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { UsedNonSprinkleredObstructedAreasDataModel } from './used-non-sprinklered-obstructed-areas-data.model';

export class NonSprinkleredAreasDataModel {
  static sectionName = 'nonSprinkleredAreas';
  unusedNonSprinkleredAreas: UnusedNonSprinkleredAreas;
  usedNonSprinkleredObstructedAreas?: any[];

  static fromS3(nonSprinkleredAreasData: any): NonSprinkleredAreasDataModel {
    const usedNonSprinkleredObstructedAreasArray: any[] = [];
    if (
      nonSprinkleredAreasData?.UsedNonSprinkleredObstructedAreas &&
      nonSprinkleredAreasData?.UsedNonSprinkleredObstructedAreas.length
    ) {
      nonSprinkleredAreasData.UsedNonSprinkleredObstructedAreas.forEach((area) => {
        const usedNsObstructedAreaData = UsedNonSprinkleredObstructedAreasDataModel.fromS3(area);
        usedNonSprinkleredObstructedAreasArray.push(usedNsObstructedAreaData);
      });
    }
    const unUsedArea = NonSprinkleredAreasDataModel.setUnUsedSprinkleredArea(nonSprinkleredAreasData);
    return {
      unusedNonSprinkleredAreas: unUsedArea,
      usedNonSprinkleredObstructedAreas: usedNonSprinkleredObstructedAreasArray,
    };
  }

  static to(model: any, sectionEnum?: SectionEnum) {
    const usedNsObstructedAreasData = [];
    model?.usedNonSprinkleredObstructedAreas?.forEach((area) => {
      usedNsObstructedAreasData.push(UsedNonSprinkleredObstructedAreasDataModel.to(area));
    });
    return {
      ReportId: model?.unusedNonSprinkleredAreas?.reportId,
      IsUnusedBeneathCombustibleLowerFloor: model?.unusedNonSprinkleredAreas?.isUnusedBeneathCombustibleLowerFloor ?
        model?.unusedNonSprinkleredAreas?.isUnusedBeneathCombustibleLowerFloor : false,
      IsUnusedCombustibleConcealedCeilingAttic:
        model?.unusedNonSprinkleredAreas?.isUnusedCombustibleConcealedCeilingAttic ?
          model?.unusedNonSprinkleredAreas?.isUnusedCombustibleConcealedCeilingAttic : false,
      IsUnusedCombustibleRoofSpaceAboveConcrete:
        model?.unusedNonSprinkleredAreas?.isUnusedCombustibleRoofSpaceAboveConcrete ?
          model?.unusedNonSprinkleredAreas?.isUnusedCombustibleRoofSpaceAboveConcrete : false,
      IsUnusedNonCombustibleFloorRoofSpace: model?.unusedNonSprinkleredAreas?.isUnusedNonCombustibleFloorRoofSpace ?
        model?.unusedNonSprinkleredAreas?.isUnusedNonCombustibleFloorRoofSpace : false,
      RequiredAreaUnusedBeneathCombustibleLowerFloor: model?.unusedNonSprinkleredAreas
        ?.requiredAreaUnusedBeneathCombustibleLowerFloor
        ? Number(model?.unusedNonSprinkleredAreas?.requiredAreaUnusedBeneathCombustibleLowerFloor)
        : model?.unusedNonSprinkleredAreas?.requiredAreaUnusedBeneathCombustibleLowerFloor,
      RequiredAreaUnusedCombustibleConcealedCeilingAttic: model?.unusedNonSprinkleredAreas
        ?.requiredAreaUnusedCombustibleConcealedCeilingAttic
        ? Number(model?.unusedNonSprinkleredAreas?.requiredAreaUnusedCombustibleConcealedCeilingAttic)
        : model?.unusedNonSprinkleredAreas?.requiredAreaUnusedCombustibleConcealedCeilingAttic,
      RequiredAreaUnusedCombustibleRoofSpaceAboveConcrete: model?.unusedNonSprinkleredAreas
        ?.requiredAreaUnusedCombustibleRoofSpaceAboveConcrete
        ? Number(model?.unusedNonSprinkleredAreas?.requiredAreaUnusedCombustibleRoofSpaceAboveConcrete)
        : model?.unusedNonSprinkleredAreas?.requiredAreaUnusedCombustibleRoofSpaceAboveConcrete,
      RequiredAreaUnusedNonCombustibleFloorRoofSpace: model?.unusedNonSprinkleredAreas
        ?.requiredAreaUnusedNonCombustibleFloorRoofSpace
        ? Number(model?.unusedNonSprinkleredAreas?.requiredAreaUnusedNonCombustibleFloorRoofSpace)
        : model?.unusedNonSprinkleredAreas?.requiredAreaUnusedNonCombustibleFloorRoofSpace,
      AffectedAreaUnusedBeneathCombustibleLowerFloor: model?.unusedNonSprinkleredAreas
        ?.affectedAreaUnusedBeneathCombustibleLowerFloor
        ? Number(model?.unusedNonSprinkleredAreas?.affectedAreaUnusedBeneathCombustibleLowerFloor)
        : model?.unusedNonSprinkleredAreas?.affectedAreaUnusedBeneathCombustibleLowerFloor,
      AffectedAreaUnusedCombustibleConcealedCeilingAttic: model?.unusedNonSprinkleredAreas
        ?.affectedAreaUnusedCombustibleConcealedCeilingAttic
        ? Number(model?.unusedNonSprinkleredAreas?.affectedAreaUnusedCombustibleConcealedCeilingAttic)
        : model?.unusedNonSprinkleredAreas?.affectedAreaUnusedCombustibleConcealedCeilingAttic,
      AffectedAreaUnusedCombustibleRoofSpaceAboveConcrete: model?.unusedNonSprinkleredAreas
        ?.affectedAreaUnusedCombustibleRoofSpaceAboveConcrete
        ? Number(model?.unusedNonSprinkleredAreas?.affectedAreaUnusedCombustibleRoofSpaceAboveConcrete)
        : model?.unusedNonSprinkleredAreas?.affectedAreaUnusedCombustibleRoofSpaceAboveConcrete,
      AffectedAreaUnusedNonCombustibleFloorRoofSpace: model?.unusedNonSprinkleredAreas
        ?.affectedAreaUnusedNonCombustibleFloorRoofSpace
        ? Number(model?.unusedNonSprinkleredAreas?.affectedAreaUnusedNonCombustibleFloorRoofSpace)
        : model?.unusedNonSprinkleredAreas?.affectedAreaUnusedNonCombustibleFloorRoofSpace,
      UsedNonSprinkleredObstructedAreas: usedNsObstructedAreasData,
      // Report: null
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
    return false;
  }

  static setUnUsedSprinkleredArea(nonSprinkleredAreasData: any) {
    const unUsedArea = {
      reportId: nonSprinkleredAreasData?.ReportId,
      isUnusedBeneathCombustibleLowerFloor: nonSprinkleredAreasData?.IsUnusedBeneathCombustibleLowerFloor,
      isUnusedCombustibleConcealedCeilingAttic: nonSprinkleredAreasData?.IsUnusedCombustibleConcealedCeilingAttic,
      isUnusedCombustibleRoofSpaceAboveConcrete: nonSprinkleredAreasData?.IsUnusedCombustibleRoofSpaceAboveConcrete,
      isUnusedNonCombustibleFloorRoofSpace: nonSprinkleredAreasData?.IsUnusedNonCombustibleFloorRoofSpace,
      requiredAreaUnusedBeneathCombustibleLowerFloor:
        nonSprinkleredAreasData?.RequiredAreaUnusedBeneathCombustibleLowerFloor,
      requiredAreaUnusedCombustibleConcealedCeilingAttic:
        nonSprinkleredAreasData?.RequiredAreaUnusedCombustibleConcealedCeilingAttic,
      requiredAreaUnusedCombustibleRoofSpaceAboveConcrete:
        nonSprinkleredAreasData?.RequiredAreaUnusedCombustibleRoofSpaceAboveConcrete,
      requiredAreaUnusedNonCombustibleFloorRoofSpace:
        nonSprinkleredAreasData?.RequiredAreaUnusedNonCombustibleFloorRoofSpace,
      affectedAreaUnusedBeneathCombustibleLowerFloor:
        nonSprinkleredAreasData?.AffectedAreaUnusedBeneathCombustibleLowerFloor,
      affectedAreaUnusedCombustibleConcealedCeilingAttic:
        nonSprinkleredAreasData?.AffectedAreaUnusedCombustibleConcealedCeilingAttic,
      affectedAreaUnusedCombustibleRoofSpaceAboveConcrete:
        nonSprinkleredAreasData?.AffectedAreaUnusedCombustibleRoofSpaceAboveConcrete,
      affectedAreaUnusedNonCombustibleFloorRoofSpace:
        nonSprinkleredAreasData?.AffectedAreaUnusedNonCombustibleFloorRoofSpace,
    };
    return unUsedArea;
  }
}

class UnusedNonSprinkleredAreas {
  reportId: string;
  isUnusedBeneathCombustibleLowerFloor: boolean;
  isUnusedCombustibleConcealedCeilingAttic: boolean;
  isUnusedCombustibleRoofSpaceAboveConcrete: boolean;
  isUnusedNonCombustibleFloorRoofSpace: boolean;
  readonlyequiredAreaUnusedBeneathCombustibleLowerFloor?: number;
  requiredAreaUnusedCombustibleConcealedCeilingAttic?: number;
  requiredAreaUnusedCombustibleRoofSpaceAboveConcrete?: number;
  requiredAreaUnusedNonCombustibleFloorRoofSpace?: number;
  affectedAreaUnusedBeneathCombustibleLowerFloor?: number;
  affectedAreaUnusedCombustibleConcealedCeilingAttic?: number;
  affectedAreaUnusedCombustibleRoofSpaceAboveConcrete?: number;
  affectedAreaUnusedNonCombustibleFloorRoofSpace?: number;
}
