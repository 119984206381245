import { Injectable } from '@angular/core';
import { StorageService } from '../storage-service/storage.service';
@Injectable({
  providedIn: 'root',
})
export class FridService {
  private idToken: string;
  public userData;
  public frId;
  constructor(private storage: StorageService) {}

  private async getUserData() {
    const data = await this.storage.getObject('token_response');
    if (data) {
      this.idToken = data['id_token'];
      this.userData = JSON.parse(atob(this.idToken.split('.')[1]));
    } else {
      //If user is offline use this
      this.userData = JSON.parse(localStorage.getItem('userInfo'));
    }
  }

  async getToken(): Promise<string> {
    await this.getUserData();
    if (this.userData && this.userData['preferred_username']) {
      if (
        this.userData['preferred_username'] === 'ISurveyTestUser2@test.com' ||
        this.userData['preferred_username'] === 'ISurveyAccpUser@test.com' ||
        this.userData['preferred_username'] === 'isurveyProdPenUser@test.com'
      ) {
        let userName: string = this.userData['preferred_username'].replace('@test.com', '');
        this.frId = userName.substring(1);
      } else {
        this.frId = this.userData['preferred_username'].substring(1, 6);
      }
    }
    return this.frId;
  }

  async getLastLoggedIn(): Promise<string> {
    await this.getUserData();
    return this.userData?.['auth_time'] ?? '';
  }
}
