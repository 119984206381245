/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { WallsConstructionDataModel } from 'src/app/models/formly-builder/formly/walls-construction-data.model';
import { WallsDataModel } from 'src/app/models/formly-builder/formly/walls-data.model';
import { FormlyBuilderService } from './formly-builder.service';
import { RiskReportService } from './risk-report.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { takeUntil } from 'rxjs/operators';
import { HookReuseableService } from './hook-reuseable.service';
import { hasDuplicatesFromArrayOfObjects } from 'src/app/validators/formly-builder/duplicate-array-of-objects-validator';
import { excludeObjectsFromArrayOfObjects, filteringMoreStartWithValues } from 'src/app/validators/formly-builder/occupancy-hazards-validator/filtering-hazard-sno-startswith-validator/filtering-hazard-sno-startswith-validator';
@Injectable({
  providedIn: 'root',
})
export class FormlyWallsConstructionService extends SectionServiceAbstract {

  public isWallsSheetEdited: boolean;

  constructor(
    private http: HttpClient,
    private formlyBuilderService: FormlyBuilderService, private riskReportService: RiskReportService,
    private hookReuseableService: HookReuseableService,
  ) {
    super();
  }

  public getConstructionTypeLookupDataV2(): Observable<any> {
    // TODO: Replace when lookup data available
    return of([
      { value: 'Masonry', label: 'Masonry' },
      { value: 'Fire Resistive', label: 'Fire Resistive' },
      { value: 'Non-Combustible/Slow Burn', label: 'Non-Combustible/Slow Burn' },
      { value: 'Combustible', label: 'Combustible' },
      { value: 'Mass Timber', label: 'Mass Timber' },
    ]);
  }

  public getConstructionTypeLookupDataV1(): Observable<any> {
    // TODO: Replace when lookup data available
    return of([
      { value: 'Masonry', label: 'Masonry' },
      { value: 'Fire Resistive', label: 'Fire Resistive' },
      { value: 'Non-Combustible/Slow Burn', label: 'Non-Combustible/Slow Burn' },
      { value: 'Combustible', label: 'Combustible' },
    ]);
  }

  public getConstructionMaterialLookupData(): Observable<any> {
    return of([
      { value: 'Reinforced Concrete Frame', label: 'Reinforced Concrete Frame', type: 'Masonry' },
      { value: 'Reinforced Concrete Solid', label: 'Reinforced Concrete Solid', type: 'Masonry' },
      { value: 'Brick', label: 'Brick', type: 'Masonry' },
      { value: 'Precast Concrete - Prot. Tabs', label: 'Precast Concrete - Prot. Tabs', type: 'Masonry' },
      { value: 'Precast Concrete - Unprot. Tabs', label: 'Precast Concrete - Unprot. Tabs', type: 'Masonry' },
      { value: 'Tilt Wall - Prot. Tabs', label: 'Tilt Wall - Prot. Tabs', type: 'Masonry' },
      { value: 'Tilt Wall - Unprot. Tabs', label: 'Tilt Wall - Unprot. Tabs', type: 'Masonry' },
      { value: 'Tilt Wall w/ Brick Face', label: 'Tilt Wall w/ Brick Face', type: 'Masonry' },
      { value: 'Tilt Wall w/Stone Face', label: 'Tilt Wall w/Stone Face', type: 'Masonry' },
      { value: 'Non-reinforced concrete', label: 'Non-reinforced concrete', type: 'Masonry' },
      { value: 'Natural Stone', label: 'Natural Stone', type: 'Masonry' },
      { value: 'Hollow Concrete Block', label: 'Hollow Concrete Block', type: 'Masonry' },
      { value: 'HCB w/Brick Face', label: 'HCB w/Brick Face', type: 'Masonry' },
      { value: 'HCB w/Stone Face', label: 'HCB w/Stone Face', type: 'Masonry' },
      { value: 'Clay Tile', label: 'Clay Tile', type: 'Masonry' },
      { value: 'Gypsum Block', label: 'Gypsum Block', type: 'Masonry' },
      { value: 'Adobe', label: 'Adobe', type: 'Masonry' },
      { value: 'Glass Block', label: 'Glass Block', type: 'Masonry' },
      { value: 'HCB - Filled & Reinforced', label: 'HCB - Filled & Reinforced', type: 'Masonry' },
      { value: 'HCB or Masonry Units - 3 hrs', label: 'HCB or Masonry Units - 3 hrs', type: 'Masonry' },
      { value: 'HCB or Masonry Units - 2 hrs', label: 'HCB or Masonry Units - 2 hrs', type: 'Masonry' },
      { value: 'Other', label: 'Other', type: 'Masonry' },
      { value: 'Reinforced Concrete', label: 'Reinforced Concrete', type: 'Fire Resistive' },
      { value: 'Masonry Protected Steel', label: 'Masonry Protected Steel', type: 'Fire Resistive' },
      { value: 'Lally Column - Listed', label: 'Lally Column - Listed', type: 'Fire Resistive' },
      { value: 'Steel prot. by Plaster on Metal Lath', label: 'Steel prot. by Plaster on Metal Lath', type: 'Fire Resistive' },
      { value: 'Lally Column - Unlisted', label: 'Lally Column - Unlisted', type: 'Fire Resistive' },
      { value: 'Steel prot. by Gypsum Board/Sheetrock', label: 'Steel prot. by Gypsum Board/Sheetrock', type: 'Fire Resistive' },
      { value: 'Steel prot. by Intumescent Mastic Coating', label: 'Steel prot. by Intumescent Mastic Coating', type: 'Fire Resistive' },
      { value: 'Steel prot. by Spray fiber/plaster', label: 'Steel prot. by Spray fiber/plaster', type: 'Fire Resistive' },
      { value: 'Steel prot. by Cementitious Mixtures', label: 'Steel prot. by Cementitious Mixtures', type: 'Fire Resistive' },
      { value: 'Other', label: 'Other', type: 'Fire Resistive' },
      { value: 'Masonry Veneer on Steel Supports', label: 'Masonry Veneer on Steel Supports', type: 'Non-Combustible/Slow Burn' },
      { value: 'Masonry Veneer on Steel Supports w/insulation', label: 'Masonry Veneer on Steel Supports w/insulation', type: 'Non-Combustible/Slow Burn' },
      { value: 'Metal Panels on Steel Supports', label: 'Metal Panels on Steel Supports', type: 'Non-Combustible/Slow Burn' },
      { value: 'Metal Panels on Steel Supports w/insulation', label: 'Metal Panels on Steel Supports w/insulation', type: 'Non-Combustible/Slow Burn' },
      { value: 'Glass in Steel Frame w/Steel Supports', label: 'Glass in Steel Frame w/Steel Supports', type: 'Non-Combustible/Slow Burn' },
      { value: 'Open Steel Frame', label: 'Open Steel Frame', type: 'Non-Combustible/Slow Burn' },
      { value: 'Listed EIFS on Steel Supports - SB', label: 'Listed EIFS on Steel Supports - SB', type: 'Non-Combustible/Slow Burn' },
      { value: 'Listed EIFS on Steel Supports - NC', label: 'Listed EIFS on Steel Supports - NC', type: 'Non-Combustible/Slow Burn' },
      // { value: "NC Assemblies w/MCM's", label: "NC Assemblies w/MCM's", type: 'Non-Combustible/Slow Burn' },
      // { value: "Limited Comb. Assemblies  w/MCM's", label: "Limited Comb. Assemblies  w/MCM's",type: 'Non-Combustible/Slow Burn'},
      { value: "Other", label: "Other", type: 'Non-Combustible/Slow Burn' },
      { value: 'Wood, Vinyl, or Stucco on Wood Supports', label: 'Wood, Vinyl, or Stucco on Wood Supports', type: 'Combustible' },
      { value: 'EIFS on metal or wood studs', label: 'EIFS on metal or wood studs', type: 'Combustible' },
      { value: 'Wood Iron Clad (WIC)', label: 'Wood Iron Clad (WIC)', type: 'Combustible' },
      { value: 'Masonry Veneer on Wood Supports', label: 'Masonry Veneer on Wood Supports', type: 'Combustible' },
      { value: 'Wood Panels on Steel Supports', label: 'Wood Panels on Steel Supports', type: 'Combustible' },
      { value: 'Metal Panels on Steel Supports w/interior comb', label: 'Metal Panels on Steel Supports w/interior comb', type: 'Combustible' },
      { value: 'Metal Panels on Steel Supports w/foam insul.', label: 'Metal Panels on Steel Supports w/foam insul.', type: 'Combustible' },
      { value: 'Comb Assemblies w/foam insul.', label: 'Comb Assemblies w/foam insul.', type: 'Combustible' },
      { value: 'Foam Sandwich Panels', label: 'Foam Sandwich Panels', type: 'Combustible' },
      { value: 'Structural Insulated Panels (SIPS)', label: 'Structural Insulated Panels (SIPS)', type: 'Combustible' },
      { value: 'MCM on Steel Supports', label: 'MCM on Steel Supports', type: 'Combustible' },
      // { value: "Comb Assemblies w/MCM's", label: "Comb Assemblies w/MCM's", type: 'Combustible' },
      { value: '1 HR Combustible Assemblies', label: '1 HR Combustible Assemblies', type: 'Combustible' },
      { value: 'Mass Timber (Unlisted or <2 hrs)', label: 'Mass Timber (Unlisted or <2 hrs)', type: 'Combustible' },
      { value: 'Other', label: 'Other', type: 'Combustible' },
    ]);
  }

  public getWallClassificationLookupData() {
    return [
      { value: 1, preset: 'Reinforced Concrete Frame', rienforcedCheckbox: true },
      { value: 1, preset: 'Reinforced Concrete Solid', rienforcedCheckbox: true },
      { value: 1, preset: 'Brick', rienforcedCheckbox: false },
      { value: 1, preset: 'Precast Concrete - Prot. Tabs', rienforcedCheckbox: false },
      { value: 1, preset: 'Tilt Wall - Prot. Tabs', rienforcedCheckbox: false },
      { value: 1, preset: 'HCB or Masonry Units - 3 hrs', rienforcedCheckbox: false },
      { value: 2, preset: 'Precast Concrete - Unprot. Tabs', rienforcedCheckbox: false },
      { value: 2, preset: 'Tilt Wall - Unprot. Tabs', rienforcedCheckbox: false },
      { value: 2, preset: 'Tilt Wall w/ Brick Face', rienforcedCheckbox: false },
      { value: 2, preset: 'Tilt Wall w/Stone Face', rienforcedCheckbox: false },
      { value: 2, preset: 'Non-reinforced concrete', rienforcedCheckbox: false },
      { value: 2, preset: 'Natural Stone', rienforcedCheckbox: false },
      { value: 2, preset: 'HCB - Filled & Reinforced', rienforcedCheckbox: false },
      { value: 2, preset: 'HCB or Masonry Units - 2 hrs', rienforcedCheckbox: false },
      { value: 3, preset: 'Hollow Concrete Block', rienforcedCheckbox: false },
      { value: 3, preset: 'HCB w/Brick Face', rienforcedCheckbox: false },
      { value: 3, preset: 'HCB w/Stone Face', rienforcedCheckbox: false },
      { value: 3, preset: 'Clay Tile', rienforcedCheckbox: false },
      { value: 3, preset: 'Gypsum Block', rienforcedCheckbox: false },
      { value: 3, preset: 'Adobe', rienforcedCheckbox: false },
      { value: 3, preset: 'Glass Block', rienforcedCheckbox: false },
      { value: null, preset: 'Other', rienforcedCheckbox: false },
    ];
  }

  public getDamagibiltyLookupData() {
    return [
      { value: 1, preset: 'Reinforced Concrete', hourlyRating: 3, fireResistiveListType: null },
      { value: 1, preset: 'Masonry Protected Steel', hourlyRating: null, fireResistiveListType: null },
      { value: 1, preset: 'Lally Column - Listed', hourlyRating: null, fireResistiveListType: null },
      { value: 2, preset: 'Steel prot. by Plaster on Metal Lath', hourlyRating: null, fireResistiveListType: null },
      { value: 2, preset: 'Lally Column - Unlisted', hourlyRating: null, fireResistiveListType: false },
      { value: 3, preset: 'Steel prot. by Gypsum Board/Sheetrock', hourlyRating: null, fireResistiveListType: null },
      { value: 3, preset: 'Steel prot. by Intumescent Mastic Coating', hourlyRating: null, fireResistiveListType: null },
      { value: 3, preset: 'Steel prot. by Spray fiber/plaster', hourlyRating: null, fireResistiveListType: null },
      { value: 3, preset: 'Steel prot. by Cementitious Mixtures', hourlyRating: null, fireResistiveListType: null },
      { value: null, preset: 'Other', hourlyRating: null, fireResistiveListType: null },
    ];
  }
  public getNonCombustibleLookupData() {
    return [
      { value: 'Non-Combustible', preset: 'Masonry Veneer on Steel Supports', isNoncombustible: true, isSlowBurning: false, isFlameSpreadAbove200: null, isFlameSpreadAbove200State: 'enable', hasMetalCompositeMaterial: false },
      { value: 'Slow Burn', preset: 'Masonry Veneer on Steel Supports w/insulation', isNoncombustible: false, isSlowBurning: true, isFlameSpreadAbove200: null, isFlameSpreadAbove200State: 'enable', hasMetalCompositeMaterial: false },
      { value: 'Non-Combustible', preset: 'Metal Panels on Steel Supports', isNoncombustible: true, isSlowBurning: false, isFlameSpreadAbove200: false, isFlameSpreadAbove200State: 'disable', hasMetalCompositeMaterial: false },
      { value: 'Slow Burn', preset: 'Metal Panels on Steel Supports w/insulation', isNoncombustible: false, isSlowBurning: true, isFlameSpreadAbove200: null, isFlameSpreadAbove200State: 'enable', hasMetalCompositeMaterial: false },
      { value: 'Non-Combustible', preset: 'Glass in Steel Frame w/Steel Supports', isNoncombustible: true, isSlowBurning: false, isFlameSpreadAbove200: false, isFlameSpreadAbove200State: 'disable', hasMetalCompositeMaterial: false },
      { value: 'Non-Combustible', preset: 'Open Steel Frame', isNoncombustible: true, isSlowBurning: false, isFlameSpreadAbove200: false, isFlameSpreadAbove200State: 'disable', hasMetalCompositeMaterial: false },
      { value: 'Slow Burn', preset: 'Listed EIFS on Steel Supports - SB', isNoncombustible: false, isSlowBurning: true, isFlameSpreadAbove200: null, isFlameSpreadAbove200State: 'enable', hasMetalCompositeMaterial: false },
      { value: 'Non-Combustible', preset: 'Listed EIFS on Steel Supports - NC', isNoncombustible: true, isSlowBurning: false, isFlameSpreadAbove200: null, isFlameSpreadAbove200State: 'enable', hasMetalCompositeMaterial: false },
      { value: 'Non-Combustible', preset: "NC Assemblies w/MCM's", isNoncombustible: true, isSlowBurning: false, isFlameSpreadAbove200: null, isFlameSpreadAbove200State: 'enable', hasMetalCompositeMaterial: true },
      { value: 'Slow Burn', preset: "Limited Comb. Assemblies  w/MCM's", isNoncombustible: false, isSlowBurning: true, isFlameSpreadAbove200: null, isFlameSpreadAbove200State: 'enable', hasMetalCompositeMaterial: true },
      { value: null, preset: 'Other', isNoncombustible: null, isSlowBurning: null, isFlameSpreadAbove200: null, isFlameSpreadAbove200State: 'enable', hasMetalCompositeMaterial: false },

    ];
  }
  public getCombustibleLookupData() {
    return [
      { value: 'Combustible', preset: 'Wood, Vinyl, or Stucco on Wood Supports', isCombustible: true, isMasonryVeneer: false, isWoodIronClad: false, combustibilityRating: 'COMB', hasFoamPlasticInsulation: false, hasFoamPlasticInsulationState: 'disable', hasMetalCompositeMaterial: false, hasNote2: false, hasNote2State: 'disable', hasFireRetardantPaint: false, isUnlisted: false, hasAcceptableThermalBarrier: false, insulationFlameSpread: null, insulationSmokeDevelopment: null, paintFlameSpread: null, paintSmokeDevelopment: null },
      { value: 'Combustible', preset: 'EIFS on metal or wood studs', isCombustible: true, isMasonryVeneer: false, isWoodIronClad: false, combustibilityRating: 'COMB', hasFoamPlasticInsulation: false, hasFoamPlasticInsulationState: 'disable', hasMetalCompositeMaterial: false, hasNote2: false, hasNote2State: 'disable', hasFireRetardantPaint: false, isUnlisted: false, hasAcceptableThermalBarrier: false, insulationFlameSpread: null, insulationSmokeDevelopment: null, paintFlameSpread: null, paintSmokeDevelopment: null },
      { value: 'Wood Iron Clad', preset: 'Wood Iron Clad (WIC)', isCombustible: false, isMasonryVeneer: false, isWoodIronClad: true, combustibilityRating: null, hasFoamPlasticInsulation: false, hasFoamPlasticInsulationState: 'disable', hasMetalCompositeMaterial: false, hasNote2: false, hasNote2State: 'enable', hasFireRetardantPaint: false, isUnlisted: false, hasAcceptableThermalBarrier: false, insulationFlameSpread: null, insulationSmokeDevelopment: null, paintFlameSpread: null, paintSmokeDevelopment: null },
      { value: 'Masonry Veneer', preset: 'Masonry Veneer on Wood Supports', isCombustible: false, isMasonryVeneer: true, isWoodIronClad: false, combustibilityRating: null, hasFoamPlasticInsulation: false, hasFoamPlasticInsulationState: 'disable', hasMetalCompositeMaterial: false, hasNote2: false, hasNote2State: 'enable', hasFireRetardantPaint: false, isUnlisted: false, hasAcceptableThermalBarrier: false, insulationFlameSpread: null, insulationSmokeDevelopment: null, paintFlameSpread: null, paintSmokeDevelopment: null },
      { value: 'Combustible', preset: 'Wood Panels on Steel Supports', isCombustible: true, isMasonryVeneer: false, isWoodIronClad: false, combustibilityRating: 'COMB', hasFoamPlasticInsulation: false, hasFoamPlasticInsulationState: 'enable', hasMetalCompositeMaterial: false, hasNote2: false, hasNote2State: 'disable', hasFireRetardantPaint: false, isUnlisted: false, hasAcceptableThermalBarrier: false, insulationFlameSpread: null, insulationSmokeDevelopment: null, paintFlameSpread: null, paintSmokeDevelopment: null },
      { value: 'Combustible', preset: 'Metal Panels on Steel Supports w/interior comb', isCombustible: true, isMasonryVeneer: false, isWoodIronClad: false, combustibilityRating: 'COMB', hasFoamPlasticInsulation: false, hasFoamPlasticInsulationState: 'disable', hasMetalCompositeMaterial: false, hasNote2: false, hasNote2State: 'disable', hasFireRetardantPaint: false, isUnlisted: false, hasAcceptableThermalBarrier: false, insulationFlameSpread: null, insulationSmokeDevelopment: null, paintFlameSpread: null, paintSmokeDevelopment: null },
      { value: 'Combustible', preset: 'Metal Panels on Steel Supports w/foam insul.', isCombustible: true, isMasonryVeneer: false, isWoodIronClad: false, combustibilityRating: 'COMB', hasFoamPlasticInsulation: true, hasFoamPlasticInsulationState: 'disable', hasMetalCompositeMaterial: false, hasNote2: false, hasNote2State: 'disable', hasFireRetardantPaint: false, isUnlisted: false, hasAcceptableThermalBarrier: false, insulationFlameSpread: null, insulationSmokeDevelopment: null, paintFlameSpread: null, paintSmokeDevelopment: null },
      { value: 'Combustible', preset: 'Comb Assemblies w/foam insul.', isCombustible: true, isMasonryVeneer: false, isWoodIronClad: false, combustibilityRating: 'COMB', hasFoamPlasticInsulation: true, hasFoamPlasticInsulationState: 'disable', hasMetalCompositeMaterial: false, hasNote2: false, hasNote2State: 'disable', hasFireRetardantPaint: false, isUnlisted: false, hasAcceptableThermalBarrier: false, insulationFlameSpread: null, insulationSmokeDevelopment: null, paintFlameSpread: null, paintSmokeDevelopment: null },
      { value: 'Combustible', preset: 'Foam Sandwich Panels', isCombustible: true, isMasonryVeneer: false, isWoodIronClad: false, combustibilityRating: 'COMB', hasFoamPlasticInsulation: true, hasMetalCompositeMaterial: false, hasFoamPlasticInsulationState: 'disable', hasNote2: false, hasNote2State: 'disable', falsehasFireRetardantPaint: false, isUnlisted: false, hasAcceptableThermalBarrier: false, insulationFlameSpread: null, insulationSmokeDevelopment: null, paintFlameSpread: null, paintSmokeDevelopment: null },
      { value: 'Combustible', preset: "Structural Insulated Panels (SIPS)", isCombustible: true, isMasonryVeneer: false, isWoodIronClad: false, combustibilityRating: 'COMB', hasFoamPlasticInsulation: true, hasFoamPlasticInsulationState: 'disable', hasMetalCompositeMaterial: false, hasNote2: false, hasNote2State: 'disable', hasFireRetardantPaint: false, isUnlisted: false, hasAcceptableThermalBarrier: false, insulationFlameSpread: null, insulationSmokeDevelopment: null, paintFlameSpread: null, paintSmokeDevelopment: null },
      { value: 'Combustible', preset: "MCM on Steel Supports", isCombustible: true, isMasonryVeneer: false, isWoodIronClad: false, combustibilityRating: 'COMB', hasFoamPlasticInsulation: false, hasFoamPlasticInsulationState: 'enable', hasMetalCompositeMaterial: true, hasNote2: false, hasNote2State: 'enable', hasFireRetardantPaint: false, isUnlisted: false, hasAcceptableThermalBarrier: false, insulationFlameSpread: null, insulationSmokeDevelopment: null, paintFlameSpread: null, paintSmokeDevelopment: null },
      { value: '1 HR Rated Combustible', preset: "1 HR Combustible Assemblies", isCombustible: true, isMasonryVeneer: false, isWoodIronClad: false, combustibilityRating: 'HOUR', hasFoamPlasticInsulation: false, hasFoamPlasticInsulationState: 'disable', hasMetalCompositeMaterial: false, hasNote2: false, hasNote2State: 'disable', hasFireRetardantPaint: false, isUnlisted: false, hasAcceptableThermalBarrier: false, insulationFlameSpread: null, insulationSmokeDevelopment: null, paintFlameSpread: null, paintSmokeDevelopment: null },
      { value: '1 HR Rated Combustible', preset: "Mass Timber (Unlisted or <2 hrs)", isCombustible: true, isMasonryVeneer: false, isWoodIronClad: false, combustibilityRating: 'HOUR', hasFoamPlasticInsulation: false, hasFoamPlasticInsulationState: 'disable', hasMetalCompositeMaterial: false, hasNote2: false, hasNote2State: 'disable', hasFireRetardantPaint: false, isUnlisted: false, hasAcceptableThermalBarrier: false, insulationFlameSpread: null, insulationSmokeDevelopment: null, paintFlameSpread: null, paintSmokeDevelopment: null },
      { value: null, preset: 'Other', isCombustible: null, isMasonryVeneer: null, isWoodIronClad: null, combustibilityRating: null, hasFoamPlasticInsulation: null, hasFoamPlasticInsulationState: 'enable', hasNote2: false, hasNote2State: 'enable', hasFireRetardantPaint: false, isUnlisted: false, hasAcceptableThermalBarrier: false, insulationFlameSpread: null, insulationSmokeDevelopment: null, paintFlameSpread: null, paintSmokeDevelopment: null },
    ];
  }
  public setWallClassification(field, presetValue) {
    const classificationArr = this.getWallClassificationLookupData();
    classificationArr.filter((item) => {
      if (item.preset === presetValue) {
        field.formControl.setValue(item.value);
        setTimeout(() => {
          field.parent.formControl['controls']?.isReinforcedConcrete?.setValue(item.rienforcedCheckbox);
        }, 0)
      }
    })
  }

  public setDamagibility(field, presetValue) {
    const damagibilityArr = this.getDamagibiltyLookupData();
    damagibilityArr.filter((item) => {
      if (item.preset === presetValue) {
        field.formControl.setValue(item.value);
        setTimeout(() => {
          field.parent?.formControl['controls']?.fireResistiveListType?.setValue(item.fireResistiveListType);
          field.parent?.formControl['controls']?.hourlyRating?.setValue(item.hourlyRating);
        }, 0);
      }
    })
  }

  public setWallType(field, presetValue) {
    const damagibilityArr = this.getNonCombustibleLookupData();
    damagibilityArr.filter((item) => {
      if (item.preset === presetValue) {
        field.formControl.setValue(item.value);
        setTimeout(() => {
          field.parent?.formControl['controls']?.isNoncombustible?.setValue(item.isNoncombustible);
          field.parent?.formControl['controls']?.isSlowBurning?.setValue(item.isSlowBurning);
          field.parent?.formControl['controls']?.isFlameSpreadAbove200?.setValue(item.isFlameSpreadAbove200);
          // if (field.parent?.formControl['controls']?.isFlameSpreadAbove200?.value !== null) {
          // field.parent?.formControl['controls']?.isFlameSpreadAbove200?.disable();
          // }
          // else {
          // field.parent?.formControl['controls']?.isFlameSpreadAbove200?.enable();
          // }
          if (item.isFlameSpreadAbove200State === 'disable') {
            field.parent?.formControl['controls']?.isFlameSpreadAbove200?.disable();
          }
          else if (item.isFlameSpreadAbove200State === 'enable') {
            field.parent?.formControl['controls']?.isFlameSpreadAbove200?.enable();
          }
          field.parent?.formControl['controls']?.hasMetalCompositeMaterial?.setValue(item.hasMetalCompositeMaterial);
        }, 0);
      }
    })
  }
  public setCombustibleWallType(field, presetValue) {
    const damagibilityArr = this.getCombustibleLookupData();
    damagibilityArr.filter((item) => {
      if (item.preset === presetValue) {
        field.formControl.setValue(item.value);
        setTimeout(() => {
          field.parent?.formControl['controls']?.isCombustible?.setValue(item.isCombustible);
          field.parent?.formControl['controls']?.isMasonryVeneer?.setValue(item.isMasonryVeneer);
          field.parent?.formControl['controls']?.isWoodIronClad?.setValue(item.isWoodIronClad);
          field.parent?.formControl['controls']?.hasFoamPlasticInsulation?.setValue(item.hasFoamPlasticInsulation);
          field.parent?.formControl['controls']?.hasMetalCompositeMaterial?.setValue(item.hasMetalCompositeMaterial);
          field.parent?.formControl['controls']?.hasNote2?.setValue(item.hasNote2);
          field.parent?.formControl['controls']?.hasFireRetardantPaint?.setValue(item.hasFireRetardantPaint);
          field.parent?.formControl['controls']?.isUnlisted?.setValue(item.isUnlisted);
          field.parent?.formControl['controls']?.hasAcceptableThermalBarrier?.setValue(item.hasAcceptableThermalBarrier);
          field.parent?.formControl['controls']?.insulationFlameSpread?.setValue(item.insulationFlameSpread);
          field.parent?.formControl['controls']?.insulationSmokeDevelopment?.setValue(item.insulationSmokeDevelopment);
          field.parent?.formControl['controls']?.paintFlameSpread?.setValue(item.paintFlameSpread);
          field.parent?.formControl['controls']?.paintSmokeDevelopment?.setValue(item.paintSmokeDevelopment);
          if (item.hasNote2State === 'disable') {
            field.parent?.formControl['controls']?.hasNote2?.disable();
          }
          else if (item.hasNote2State === 'enable') {
            field.parent?.formControl['controls']?.hasNote2?.enable();
          }
          if (field.parent?.formControl['controls']?.hasFoamPlasticInsulation?.value === true) {
            field.parent?.formControl['controls']?.hasFoamPlasticInsulation?.disable();
          }
          if (item.hasFoamPlasticInsulationState === 'disable') {
            field.parent?.formControl['controls']?.hasFoamPlasticInsulation?.disable();
          }
          else if (item.hasFoamPlasticInsulationState === 'enable') {
            field.parent?.formControl['controls']?.hasFoamPlasticInsulation?.enable();
          }
        }, 0);
      }
    })
  }

  // This method we are not using
  async updateData(model, riskReportData) {
    // this.formlyBuilder.riskReport.updateData(address);
    // Check if data has changed
    const hasChanged: boolean = WallsDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      const walls = WallsDataModel.to(model.walls);
      return { ...riskReportData, ...walls };
    } else {
      return undefined;
    }
  }


  loadData(walls: any[], fullData: any, isS3: boolean, model: any): any {
    // Loading wallType value for combustible
    this.loadWalltype(fullData.Walls);
    const wallsModel: WallsDataModel = {
      wallsArray: [],
    };
    let totalArea = 0;
    let wallRecord;
    if (isS3) {
      wallRecord = WallsDataModel.fromS3(walls);
    } else {
      wallRecord = WallsDataModel.from(walls);
    }
    const existingRiskFlag = this.formlyBuilderService?.isExistingRisk();
    if (wallRecord.wallsArray.length > 0) {
      wallRecord.wallsArray.forEach((wall) => {
        let myWall;
        let wallentry;
        if (isS3) {
          wallentry = wall;
          myWall = WallsConstructionDataModel.fromS3(wall);

          if (this.formlyBuilderService?.calculationService?.isRiskReportLoaded !== false && existingRiskFlag === true && myWall !== undefined && myWall !== null && (myWall?.constructionMaterial === null || myWall?.constructionMaterial === undefined || myWall?.constructionMaterial === '')) {
            myWall.constructionMaterial = 'Other';
          }
        } else {
          wallentry = wall.data;
          myWall = WallsConstructionDataModel.from(wall.data);
        }

        // TODO: Avoid 0 area. Replace with 1.
        myWall.area = wallentry.Height * wallentry.Width;
        if (!myWall.area) {
          myWall.area = null;
        }

        totalArea += myWall.area;
        wallsModel.wallsArray.push(myWall);
      });
    }


    // Sorting the WallsModel based on Provied Order (SortOrder)
    const sortOrder = ['Masonry', 'Fire Resistive', 'Non-Combustible/Slow Burn', 'Combustible', 'Mass Timber'];
    const sorter = (value1: WallsConstructionDataModel, value2: WallsConstructionDataModel) => {
      const indexOfValue1 = sortOrder.indexOf(value1.constructionType);
      const indexOfValue2 = sortOrder.indexOf(value2.constructionType);
      if (indexOfValue1 !== -1 && indexOfValue2 !== -1) {
        return indexOfValue1 - indexOfValue2;
      } else {
        if (indexOfValue1 === -1) {
          return wallsModel.wallsArray.length - indexOfValue2;
        } else if (indexOfValue2 === -1) {
          return indexOfValue1 - wallsModel.wallsArray.length;
        }
      }
    };
    const sortedWallsModel = this.sortWalls(wallsModel)
    // wallsModel.wallsArray.sort(sorter);
    if (walls.length) {
      return { ...model, ...{ totalArea }, ...{ walls: sortedWallsModel } };
    }
    return { ...model, ...{ walls: wallsModel } };
  }



  loadWalltype(wall) {
    // //   const newArray  = wall.filter(function(obj){
    // //     return( obj.ConstructionTypeCodeValue==="C" && obj.WallClassification!=='1 HR Rated Combustible');
    // // });
    //  const isDuplicate=hasDuplicatesFromArrayOfObjects(wall,'WallClassification');
    //  if(isDuplicate){
    //   wall.forEach((obj,index) => {
    //     if(wall[index].ConstructionTypeCodeValue=='C'&& this.hookReuseableService.isCheckNull(wall[index].WallClassification)){
    //     wall[index].WallClassification='Combustible';
    //     wall[index].IsCombustible=true;
    //     wall[index].CombustibilityRating='Combustible';
    //     wall[index].IsMasonryVeneer=false;
    //     wall[index].IsWoodIronClad=false;
    //     }
    //   });
    //  }
    wall?.forEach((obj, index) => {
      const wallKeys = [];
      if (obj?.IsCombustible == true) {
        wallKeys.push('IsCombustible');
      }
      if (obj?.IsMasonryVeneer == true) {
        wall[index].WallType = 'Masonry Veneer';
        wallKeys.push('IsMasonryVeneer');
      }
      if (obj?.IsWoodIronClad == true) {
        wall[index].WallType = 'Wood Iron Clad';
        wallKeys.push('IsWoodIronClad');
      }
      if (obj?.IsCombustible == true && obj?.CombustibilityRating == 'COMB') {
        wall[index].WallType = 'Combustible';
      }
      if (obj?.IsCombustible == true && obj?.CombustibilityRating == 'HOUR') {
        wall[index].WallType = '1 HR Rated Combustible';
      }
      if (wallKeys?.length > 1) {
        wall[index].WallType = 'Combustible';
        wall[index].IsCombustible = true;
        wall[index].CombustibilityRating = 'COMB';
        wall[index].IsMasonryVeneer = false;
        wall[index].IsWoodIronClad = false;
      }
      if (obj?.IsNoncombustible == true && obj?.ConstructionTypeCodeValue == "N") {
        wall[index].nCWallType = 'Non-Combustible';
      }
      if (obj?.IsSlowBurning == true && obj?.ConstructionTypeCodeValue == "N") {
        wall[index].nCWallType = 'Slow Burn';
      }
    });
  }

  sortWalls(WallsSectionModel: any): WallsConstructionDataModel[] {
    let arrTemp: WallsConstructionDataModel[] = [];
    const masonryArr = WallsSectionModel.wallsArray.filter((item) => item.constructionType === 'Masonry');
    masonryArr.sort((a, b) => Number(b.constructionPercentage) - Number(a.constructionPercentage));
    const fireResistiveArr = WallsSectionModel.wallsArray.filter((item) => item.constructionType === 'Fire Resistive');
    fireResistiveArr.sort((a, b) => Number(b.constructionPercentage) - Number(a.constructionPercentage));
    const noncombustibleArr = WallsSectionModel.wallsArray.filter((item) => item.constructionType === 'Non-Combustible/Slow Burn');
    noncombustibleArr.sort((a, b) => Number(b.constructionPercentage) - Number(a.constructionPercentage));
    const combustibleArr = WallsSectionModel.wallsArray.filter((item) => item.constructionType === 'Combustible');
    combustibleArr.sort((a, b) => Number(b.constructionPercentage) - Number(a.constructionPercentage));
    const massTimberArr = WallsSectionModel.wallsArray.filter((item) => item.constructionType === 'Mass Timber');
    massTimberArr.sort((a, b) => Number(b.constructionPercentage) - Number(a.constructionPercentage));

    // Concating all the leveltype datas
    arrTemp = arrTemp.concat(masonryArr, fireResistiveArr, noncombustibleArr, combustibleArr, massTimberArr);
    if (arrTemp.length === WallsSectionModel.wallsArray.length) {
      WallsSectionModel.wallsArray = [...arrTemp];
    }
    return WallsSectionModel;
    ;
  }

  /**
   *
   *
   * @param * array
   * @param number order  (Descending order - 1, Ascending order - 2)
   * @param string property1
   * @return *
   * @memberof FormlyFloorsRoofService
   */
  sortByProperty(array: any, order: number, property1: string): any {
    if (array.length && array.length > 1) {
      if (order === 1) {
        array.sort((a, b) => Number(b[property1]) - Number(a[property1]));
      } else if (order === 2) {
        array.sort((a, b) => Number(a[property1]) - Number(b[property1]));
      }
    }
    return array;
  }
  public updatePercentageOccupied(walls) {
    let wallsPercentageTotal = 0;
    walls.wallsArray.forEach((wall, wallIndex) => {
      if (wall.isDeleted !== true) {
        wallsPercentageTotal += wall.constructionPercentage;
      }
    });
    walls.wallsArray.forEach((wall, wallIndex) => {
      if (wall.isDeleted !== true) {
        wall.totalConstructionPercentage = wallsPercentageTotal;
      }
    });
    this.formlyBuilderService.riskReport.model = { ...this.formlyBuilderService.riskReport.model };
  }
  onConstructionypeChange(field: FormlyFieldConfig) {
    field.formControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res && res.length > 0 && field.options.formState.service.isSheetActive) {
          //field.form.get('constructionType').setValue(null);
          field.form.get('constructionMaterial').setValue(null);
        }
      });
  }
  uuid() {
    // TODO: Move to utils file
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }
  public totalPercentageOccupied(walls) {
    let constructionPercentageCalculated = 0;
    const nonDeletedWalls = walls.value.wallsArray?.filter(wall => !(wall.isDeleted));
    if (nonDeletedWalls[0].totalConstructionPercentage < 100) {// last value for slider should be 0,if total is 100
      walls.value.wallsArray.forEach((wall, wallIndex) => {
        if (wall.isDeleted !== true) {
          wall.totalConstructionPercentage = 100;
          constructionPercentageCalculated += wall.constructionPercentage;
        }
      });
      walls.controls.wallsArray.controls[walls.value.wallsArray.length - 1].get('constructionPercentage').setValue(100 - constructionPercentageCalculated);
    }
  }

  appendFieldGroup(constructionType, field, initialFieldGroup) {
    const fireResistive: FormlyFieldConfig[] = [
      {
        key: 'length',
        type: 'simple-input',
        defaultValue: null,
        // defaultValue: 1,
        className: 'input-width',
        props: {
          label: 'Length-fireResistive',
          type: 'number',
          inputmode: 'tel',
          min: 1,
          maxLength: 8,
          required: false,
          isAsteriskNotRequired: true,
          isNonBlockingValidation: false,
          className: 'ft-label'
        },
        expressions: {
          //   'props.required': () => formlyBuilderService.mode === 1 ? true : false
        }
      },
    ];
    const fieldG = field.parent.fieldGroup;
    switch (constructionType) {
      case 'Masonry':
        if (fieldG.length < 4) {
          // fieldG.push(...masonryConstructionType);
        }
        else {
          fieldG.splice(2, fieldG.length - 2);
          //  fieldG.push(...masonryConstructionType);
        }
        break;
      case 'Fire Resistive': if (fieldG.length < 4) {
        fieldG.push(...fireResistive);
      }
      else {
        fieldG.splice(2, fieldG.length - 2);
        fieldG.push(...fireResistive);
      }
        break;
      default: if (fieldG.length > 3) { fieldG.splice(2, fieldG.length - 2); }
        break;
    }
    console.log(this.riskReportService.model);
    setTimeout(() => {
      this.riskReportService.model = { ...this.riskReportService.model };
    }, 500);
  }
  public getwalltypeLookupData(): Observable<any> {
    // TODO: Replace when lookup data available
    return of([
      { value: 'Non-Combustible', label: 'Non-Combustible', type: 'Non-Combustible/Slow Burn' },
      { value: 'Slow Burn', label: 'Slow Burn', type: 'Non-Combustible/Slow Burn' },
      { value: 'Combustible', label: 'Combustible', type: 'Combustible' },
      { value: '1 HR Rated Combustible', label: '1 HR Rated Combustible', type: 'Combustible' },
      { value: 'Masonry Veneer', label: 'Masonry Veneer', type: 'Combustible' },
      { value: 'Wood Iron Clad', label: 'Wood Iron Clad', type: 'Combustible' }
    ]);
  }
  // For noncombustible
  onWallTypeChanges(field: FormlyFieldConfig) {
    let fieldval = field?.parent?.formControl['controls']?.nCWallType?.value;
    if (fieldval !== null && fieldval !== undefined) {
      fieldval == 'Non-Combustible' ? field?.form?.get('isNoncombustible')?.setValue(true) : field?.form?.get('isNoncombustible')?.setValue(false);
      fieldval == 'Slow Burn' ? field?.form?.get('isSlowBurning')?.setValue(true) : field?.form?.get('isSlowBurning')?.setValue(false);
    }
  }
  onMcmChanges(field: FormlyFieldConfig) {
    let fieldval = field?.parent?.formControl['controls']?.hasMetalCompositeMaterial?.value;
    if (fieldval !== null && fieldval !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (fieldval) {
        this.formArray(field?.parent?.formControl['controls']?.metalCompositeMaterials, [{ key: 'section', value: 'WALLSLBN' }]);
        // field?.form?.get('metalWallIdentifier').setValue(true);
      }
      else {
        this.formArray(field?.parent?.formControl['controls']?.metalCompositeMaterials, [{ key: 'flameSpread', value: null }, { key: 'smokeDevelopment', value: null }, { key: 'section', value: null },
        ]);
        field?.form?.get('isFlameSpreadAbove200')?.setValue(false);
      }
    }
  }
  formArray(formArray, arrayofObjs) {
    formArray?.value?.forEach((element, index) => {
      for (let i = 0; i < arrayofObjs.length; i++) {
        formArray?.at(index)?.get(`${arrayofObjs[i].key}`)?.setValue(arrayofObjs[i].value);
      }
    });
  }
  smokeDevChanges(field: FormlyFieldConfig) {
    let fieldval = field?.form?.get('smokeDevelopment')?.value;
    if (fieldval !== null && fieldval !== undefined && field?.form?.get('smokeDevelopment')?.valid) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      fieldval > 200 ? this.hookReuseableService.fieldSetValue(field?.parent?.parent?.form, [{ key: 'isFlameSpreadAbove200', value: true }]) : this.hookReuseableService.fieldSetValue(field?.parent?.parent?.form, [{ key: 'isFlameSpreadAbove200', value: false }]);
    }
  }
  onCombustibleTypeChanges(field: FormlyFieldConfig, controlName) {
    let fieldval = field?.parent?.formControl['controls']?.[`${controlName}`]?.value;
    if (fieldval !== null && fieldval !== undefined) {
      switch (fieldval) {
        case 'Combustible': {
          this.hookReuseableService.fieldSetValue(field.form, [{ key: 'isCombustible', value: true }, { key: 'isMasonryVeneer', value: false }, { key: 'isWoodIronClad', value: false },
          { key: 'combustibilityRating', value: 'COMB' }]);
          this.hookReuseableService.enableFields(field.form, ['hasFoamPlasticInsulation', 'hasMetalCompositeMaterial']);
        }
          break;
        case '1 HR Rated Combustible': {
          this.hookReuseableService.fieldSetValue(field.form, [{ key: 'isCombustible', value: true }, { key: 'isMasonryVeneer', value: false }, { key: 'isWoodIronClad', value: false },
          { key: 'hasMetalCompositeMaterial', value: false }, { key: 'hasFoamPlasticInsulation', value: false }, { key: 'combustibilityRating', value: 'HOUR' }]);
          this.hookReuseableService.disableFields(field.form, ['hasFoamPlasticInsulation', 'hasMetalCompositeMaterial']);
        }
          break;
        case 'Masonry Veneer': {
          this.hookReuseableService.fieldSetValue(field.form, [{ key: 'isCombustible', value: false }, { key: 'isMasonryVeneer', value: true }, { key: 'isWoodIronClad', value: false },
          { key: 'combustibilityRating', value: null }]);
          this.hookReuseableService.enableFields(field.form, ['hasFoamPlasticInsulation', 'hasMetalCompositeMaterial']);
        }
          break;
        case 'Wood Iron Clad': {
          this.hookReuseableService.fieldSetValue(field.form, [{ key: 'isCombustible', value: false }, { key: 'isMasonryVeneer', value: false }, { key: 'isWoodIronClad', value: true },
          { key: 'combustibilityRating', value: null }]);
          this.hookReuseableService.enableFields(field.form, ['hasFoamPlasticInsulation', 'hasMetalCompositeMaterial']);
        }
          break;
        case '2':
        case '3': {
          this.hookReuseableService.fieldSetValue(field.form, [{ key: 'isReinforcedConcrete', value: false },
          ]);
          this.hookReuseableService.enableFields(field.form, ['isReinforcedConcrete']);
        }
          break;
        default: {
          this.hookReuseableService.fieldSetValue(field.form, [{ key: 'isCombustible', value: false }, { key: 'isMasonryVeneer', value: false }, { key: 'isWoodIronClad', value: false },
          { key: 'combustibilityRating', value: null }]);
          this.hookReuseableService.enableFields(field.form, ['hasFoamPlasticInsulation', 'hasMetalCompositeMaterial']);
        }
      }
    }
  }
  calculateArea(field: FormlyFieldConfig) {
    let widthVal = field?.parent?.formControl['controls']?.width.value;
    let heightVal = field?.parent?.formControl['controls']?.height.value;
    if (heightVal !== null && heightVal !== undefined && heightVal !== '' && widthVal !== '' && widthVal !== null && widthVal !== undefined) {
      const updatedArea = widthVal * heightVal;
      field.form.get('area')?.setValue(updatedArea);
      field.model.area = updatedArea;
    }
    else {
      field.form.get('area')?.setValue(null);
      field.model.area = null;
    }
  }
  setNewconstructionPreset(field, type: string) {
    const initialValue = field?.form?.controls?.constructionType?.value;
    const newWallflag = field?.form?.controls?.isWallAddedNewlyInEditSheet?.value;
    const existingRiskFlag = field.options.formState.service.isExistingRisk();
    if ((initialValue !== null && initialValue !== undefined) && (this.isWallsSheetEdited !== undefined && this.isWallsSheetEdited === false) && newWallflag === false && existingRiskFlag === true) {
      // field?.form?.controls?.backendPresetValueforexistingRisk?.setValue(field.formControl.value);
      // check if backendPresetValueforexistingRisk is not 'Other', and then set it to 'Other'
      field.formControl.setValue('Other');
    }
  }

  // when user closes the sheet without applying the new change to preset, we restore the presetValue
  // resetConstructionPreset(field) {
  //   const control = field.form?.controls?.wallsArray?.controls?.forEach((control)=>{
  //     if(!(control?.backendPresetValueforexistingRisk?.value === 'Other') )
  //    control?.constructionMaterial?.setValue(control?.backendPresetValueforexistingRisk?.value);
  //   })
  // }

  getConstructionPresetValues(field, type) {
    let constructionMaterial;
    field.options.formState.service.wallsConstructionService
      .getConstructionMaterialLookupData()
      .subscribe((data) => {
        constructionMaterial = data;

      });
    const options = constructionMaterial.filter(value => {
      if (value.type === type) {
        return value;
      }
    });
    return options;
  }
}
