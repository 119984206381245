import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { FormlyWallsService } from 'src/app/services/formly-builder/formly-walls.service';
import { Constants } from 'src/app/services/util-service/constants';
import { SectionConfig, SectionEnum } from 'src/app/services/formly-builder/section.config';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

export class WallsBGIIModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService, private formlyWallsService: FormlyWallsService) {
    super( 
      {
      fieldGroup: [
      {
        key: 'isOpenSided',
        type: 'segment',
        wrappers: [ 'question-wrapper' ],
        props: {
          label: 'Are the walls open - sided for BGII',
          isCollapsable: true,
          isRequired:true,
          required: true,
          isAsteriskNotRequired: true,
         firstColSize: 5,
         showError: true,
         checkTouchedControl: true
        },
        hooks: {
          // eslint-disable-next-line arrow-body-style
          afterViewInit: (field?: FormlyFieldConfig) => {
            return field.formControl.valueChanges.pipe(
              // debounceTime(5000),
              distinctUntilChanged(),
              tap(value => {
                if (!value && field.formControl.pristine) {
                  field.formControl.markAsTouched();
                }
                if (!field.formControl.pristine) {
                  if (field.formControl.valid) {
                    const formlyService = field.options.formState.service;
                        field.options.formState.service.statusLineVisibility = false;
                    formlyService.riskReport.updateSectionData(SectionEnum.FR_WALLS_BGII, false);//model name. this is for toggle
                  }
                }
                // Notify that we changed the value...
                // 1. Formly Angular Control: field.formControl.setValue();
                // 2. Formly Model: field.options.formState.riskService.model = { ...field.options.formState.riskService.model, ...newValue };   // Submit
                // 3. Risk Report: formlyService.riskReport.updateSectionData(SectionEnum.BUILDING_BG, false);              // Save in memory Risk Report + save to local storage + sync to S3
                // 4. valid notification: Don't modify anything, but just notify possible validity changes even if value not changed in RR/Model
                field.options.formState.service.valid = field.formControl.valid;
              }),
            );
          }
        },
      },
      {
        key:'isReinforcedConcrete',
        hooks: {
          // onInit: (field?: FormlyFieldConfig) =>
          //   field.formControl.valueChanges.pipe(
          //     distinctUntilChanged(),
          //     tap((value) => formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_WALLS_BGII, value))
          //   ),
          onInit: (field: FormlyFieldConfig) => {
            field.formControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(),
            debounceTime(Constants.riskFormDataSyncDelay))
              .subscribe(value => {
                if (!field.formControl.pristine) {
                  formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_WALLS_BGII, value);
                }
              });
          },
          onDestroy: () => {
            this.ngUnsubscribe.next();
            this.ngUnsubscribe.complete();
          }
        },
      }
    ],
  }
      );
  }
}
