import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';

/**
 * Class that represents the Formly Model for RoofTop
 */
export class RoofTopDataModel {
  static sectionName = 'roofTopLocationPoint';      // Name of the Formly Model Section

  latitude: number;
  longitude: number;
  confirmed = false;

  /**
   * @deprecated
   * @param reports
   * @returns
   */
  static from(reports: RiskReportDataReports): RoofTopDataModel {
    return {
      latitude: 0,
      longitude: 0,
      confirmed: false
    };
  }

  /**
   *
   * @param reports
   * @returns
   */
  static fromS3(riskReport: any): RoofTopDataModel {
    const lat = riskReport?.RooftopLocationPoint?.Latitude ? riskReport?.RooftopLocationPoint?.Latitude : 0;
    const lon = riskReport?.RooftopLocationPoint?.Longitude ? riskReport?.RooftopLocationPoint?.Longitude : 0;
    let confirmd = riskReport?.RooftopLocationPoint?.Confirmed ? riskReport?.RooftopLocationPoint?.Confirmed : false;

    if (lat === undefined || lon === undefined) {
      confirmd = false;
    }

    return {
      latitude: lat,
      longitude: lon,
      confirmed: confirmd
    };
  }

  /**
   *
   * @param model
   * @returns
   */
  static to(model: any) {
    return {
      RooftopLocationPoint: {
        Latitude: model.roofTopLocationPoint.latitude,
        Longitude: model.roofTopLocationPoint.longitude,
        Confirmed: model.roofTopLocationPoint.confirmed
      }
    };
  }
}
