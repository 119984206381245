import { EventEmitter, Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyBuilderService } from '../../formly-builder.service';
import { SectionEnum } from '../../section.config';
import { SystemComponentDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/system-component-data.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UtilService } from 'src/app/services/util-service/util.service';

@Injectable({
  providedIn: 'root',
})
export class SystemComponentService extends SectionServiceAbstract {
  SaveToS3Event: EventEmitter<any> = new EventEmitter<any>();
  constructor(private formlyBuilderService: FormlyBuilderService, private utilService: UtilService) {
    super();
  }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {

    const systemComponentData = SystemComponentDataModel.to(model?.sprinklerReport?.asgrSprinklerSystemComponent);
    systemComponentData.ReportId = systemComponentData.ReportId ? systemComponentData.ReportId : riskReportData.ReportIdentifier;
    const sprinklerData = {
      ...riskReportData.SprinklerReport,
      ...{ AsgrSprinklerSystemComponent: systemComponentData },
    };
    return { ...riskReportData, ...{ SprinklerReport: sprinklerData } };
  }

  loadData(sectionData: any[], reportInfo: any, isS3: boolean, model: any): any {
    return null;
  }

  async onApply(modalCtrl, field) {
    const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const riskReport = await formlyService.riskReport.updateSectionData(SectionEnum.FR_S_SYSTEM_COMPONENT, false);
    const newModel = formlyService.sprinklerReportService.loadData(
      riskReport,
      riskReport,
      true,
      formlyService.riskReport.model,
      false
    );
    if (newModel) {
      formlyService.riskReport.model = { ...newModel };
    }
    field?.parent?.fieldGroup[ 3 ]?.formControl?.controls.overAgeSprinklerTests?.updateValueAndValidity();
    formlyService.errorService.closePreviousToaster();
    field.props.isApplied = true;
    formlyService.systemComponentService.SaveToS3Event.emit();
    modalCtrl?.dismiss();    
  }

  onClose(modalCtrl, field, initialModel) {
     const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const reportData = formlyService.riskReport.report;
    const newModel = formlyService.sprinklerReportService.loadData(
      reportData,
      reportData,
      true,
      formlyService.riskReport.model,
      true
    );
    if (newModel) {
      formlyService.riskReport.model = { ...newModel };
    }
    formlyService.errorService.closePreviousToaster();
    modalCtrl?.dismiss();   
  }


  // get total area occupancy of dry pipe valve with sprinkler type
  getTotalArea() {
    const formlyService = this.formlyBuilderService.options.formState.service;
    const occupants = formlyService.riskReport.model?.occupants;
    const sectionOccupant = occupants
      ? 'section-occupants' in occupants
        ? occupants[ 'section-occupants' ]
        : null
      : null;
    let totalArea = null;
    if (sectionOccupant !== null && sectionOccupant) {
      const occupant = sectionOccupant?.occupants;
      const occupantLevel = [];
      if (occupant.length > 0) {
        occupant.forEach((item) => {
          if ('occupantLevels' in item && item.occupantLevels.length > 0 && item.isDeleted === false) {
            item.occupantLevels.map((val) => {
              occupantLevel.push(val);
            });
          }
        });
      }
      const area = occupantLevel.filter((acc) => {
        if (acc && 'sprinklerType' in acc && acc.sprinklerType !== 'None' && (acc.sprinklerType === 'Dry' || acc.sprinklerType === 'Dry - High Piled Stock')) {
          return acc;
        }
      });

      if (area.length === 1) {
        totalArea = area[ 0 ].area;
      }
      if (area.length > 1) {
        totalArea = area.reduce((a, p) => a + (p.area || 0), 0);
      }
    }
    return totalArea;
  }
  // get the total occupancy area
  checkOccupancyLevels() {
    let area = null;
    const occupants = this.formlyBuilderService.riskReport.model?.occupants;
    const sectionOccupant = occupants
      ? 'section-occupants' in occupants
        ? occupants['section-occupants']
        : null
      : null;
    if (sectionOccupant && sectionOccupant?.totalArea) {
      area = sectionOccupant?.totalArea;
    }
    return area;
  }

  // check the condition to enable the dry pipe valve
  enableDryPipe(field: FormlyFieldConfig) {
    const totalDryPipeArea = this.getTotalArea();
    const systemTesting = this.getAreaByDryPipeSystemTesting(field);
    const formlyService = field.options?.formState?.service;
    let isDryPipe: boolean;
    const isPipeScheduleEntryPresent = field?.options?.formState?.isPipeScheduleEntryPresent;
    const dpvSystem = formlyService.riskReport?.model?.sprinklerReport?.asgrWaterSupply?.dpv?.dpvSystem;
    const dpvSystemValue = isPipeScheduleEntryPresent && (dpvSystem === 'QOD Not Required' || dpvSystem === 'No Required QOD');
    if ((totalDryPipeArea !== null && totalDryPipeArea !== undefined) || (systemTesting !== null && systemTesting !== undefined && systemTesting !== false) ||
      (dpvSystem !== undefined && dpvSystemValue)) {
      isDryPipe = true;
    }
    return isDryPipe;
  }

  // validation of system testing dry pipe valve
  getAreaByDryPipeSystemTesting(field: FormlyFieldConfig) {
    const formlyService = field.options.formState.service;
    const dpvInstallationDate = formlyService.riskReport?.model?.sprinklerReport?.asgrSprinklerSystemTest?.dryPipeValveTesting?.dpvInstallationDate;
    const dpvTestDate = formlyService.riskReport?.model?.sprinklerReport?.asgrSprinklerSystemTest?.dryPipeValveTesting?.dpvTestDate;
    const isDpvTestNotApplicable = formlyService.riskReport?.model?.sprinklerReport?.asgrSprinklerSystemTest?.dryPipeValveTesting?.isDpvTestNotApplicable;

    if ((dpvInstallationDate !== null && dpvInstallationDate) || (dpvTestDate !== null && dpvTestDate) ||
      (isDpvTestNotApplicable && isDpvTestNotApplicable === false)) {
      return true;
    } else {
      return false;
    }
  }

  // reset the formcontrol values on toggle action off
  resetValueOnToggleOff(field: FormlyFieldConfig) {
    field.form?.get('nonStandardPipeScheduleInstallTypeThird')?.reset('N/A');
    field.form?.get('nonStandardPipeScheduleInstallTypeSecond')?.reset('N/A');
    field.form?.get('nonStandardPipeScheduleInstallTypeFirst')?.reset('N/A');
    field.form?.get('sprinklerOccupancyClass1For234Comment')?.reset();
    field.form?.get('sprinklerOccupancyClass1For56Comment')?.reset();
    field.form?.get('sprinklerOccupancyClass234For56Comment')?.reset();
  }

  // check occupant hazards sprinkler design for overhead piping
  checkSprinklerDesign(): boolean {
    const formlyService = this.formlyBuilderService.options.formState.service;
    const occupants = formlyService.riskReport.model?.occupants;
    const sectionOccupant = occupants
      ? 'section-occupants' in occupants
        ? occupants[ 'section-occupants' ]
        : null
      : null;
    let isPipeSchedule = false;
    if (sectionOccupant !== null && sectionOccupant) {
      const occupant = sectionOccupant?.occupants;
      const occupantLevel = [];
      if (occupant?.length > 0) {
        occupant.forEach((item) => {
          if ('occupantLevels' in item && item.occupantLevels.length > 0) {
            item.occupantLevels.map((val) => {
              occupantLevel.push(val);
            });
          }
        });
      }
      const design = occupantLevel?.length ? occupantLevel.filter((acc) => {
        if (acc && 'sprinklerDesign' in acc && acc.sprinklerDesign === 'Pipe Schedule') {
          return acc;
        }
      }) : [];
      if (design && design.length > 0) {
        isPipeSchedule = true;
      }
    }
    return isPipeSchedule;
  }

  // Validation check with Area Protected By Dry Pipe System
  areaProtectedByDryPipeSystem(control, field: FormlyFieldConfig) {
    const areaProtectedByDryPipeSystem = field?.form?.get('areaProtectedByDryPipeSystem')?.value;
    const unacceptableDpvArea = control.value;
    if (unacceptableDpvArea !== null && unacceptableDpvArea && areaProtectedByDryPipeSystem !== null && areaProtectedByDryPipeSystem) {
      if (Number(unacceptableDpvArea) > Number(areaProtectedByDryPipeSystem)) {
        field.formControl?.markAsTouched();
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  // validation check for Sprinklered Area in Occupancy
  sprinkleredAreaValidation(control, field: FormlyFieldConfig) {
    const affectedArea = control.value;
    const totalOccupancyArea = this.checkOccupancyLevels();
    if (affectedArea !== null && affectedArea && totalOccupancyArea !== null) {
      if (Number(affectedArea) > Number(totalOccupancyArea)) {
        field.formControl?.markAsTouched();
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

 // validation check for Sprinklered Area in Occupancy in Dry pipe valve
  dryPipeSprinkleredOccupancyArea(control, field: FormlyFieldConfig) {
    const affectedArea = control.value;
    const totalOccupancyArea = this.formlyBuilderService.occupantsService.getOccupancyTotalArea(
      field,
      true
    );
    if (affectedArea !== null && affectedArea && totalOccupancyArea !== null) {
      if (Number(affectedArea) > Number(totalOccupancyArea)) {
        field.formControl?.markAsTouched();
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  /**
   *
   * @param control
   * @param field
   * @returns
   */

  checkAtleastDryPipeValve(control, field: FormlyFieldConfig): boolean {
    const dpvType = field?.formControl?.get('dpvType')?.value;
    let areaProtectedByDryPipeSystem = field?.formControl?.get('areaProtectedByDryPipeSystem')?.value;
    const totalDryPipeArea = this.getTotalArea();
    const isDpvPipe = this.enableDryPipe(field);
    let dpvToggle = field?.formControl?.get('dpvToggle')?.value;
    dpvToggle = (dpvToggle !== null) ? dpvToggle : isDpvPipe;
    areaProtectedByDryPipeSystem = (areaProtectedByDryPipeSystem !== null) ? areaProtectedByDryPipeSystem : totalDryPipeArea;
    if (dpvType !== null && dpvType === false && areaProtectedByDryPipeSystem < 0) {
      return true;
    } else if ((dpvToggle && dpvToggle === true) && (dpvType === null || dpvType === undefined || dpvType === 'N/A') && (areaProtectedByDryPipeSystem !== null &&
      areaProtectedByDryPipeSystem !== '' && Number(areaProtectedByDryPipeSystem) > 0)) {
      field.formControl?.markAsTouched();
      return false;
    } else {
      return true;
    }
  }

  // validation check for accumilated total area of automatic sprinkler
  accumulatedTotalAreaValidation(field: FormlyFieldConfig) {
    const overageSprinklerArea = field?.form?.get('overageSprinkler')?.get('overageSprinklerArea')?.value;
    const corrodedSprinklerArea = field?.form?.get('corrodedSprinkler')?.get('corrodedSprinklerArea')?.value;
    const paintedSprinklerArea = field?.form?.get('paintedSprinkler')?.get('paintedSprinklerArea')?.value;
    const recalledSprinklerHeadArea = field?.form?.get('recalledSprinkler')?.get('recalledSprinklerHeadArea')?.value;
    const otherUnacceptableOrNonStandardSprinklerArea = field?.form?.get('nonStandardSprinkler')?.get('otherUnacceptableOrNonStandardSprinklerArea')?.value;

    if ((overageSprinklerArea !== null || overageSprinklerArea !== undefined) && (corrodedSprinklerArea !== null || corrodedSprinklerArea !== undefined) 
      && (paintedSprinklerArea !== null || paintedSprinklerArea !== undefined ) && (recalledSprinklerHeadArea !== null || recalledSprinklerHeadArea !== undefined )
      && (otherUnacceptableOrNonStandardSprinklerArea !== null || otherUnacceptableOrNonStandardSprinklerArea !== undefined)) {
      const accumulatedTotalArea = Number(overageSprinklerArea) + Number(corrodedSprinklerArea) + Number(paintedSprinklerArea)
        + Number(recalledSprinklerHeadArea) + Number(otherUnacceptableOrNonStandardSprinklerArea);
      const totalOccupancyArea = this.checkOccupancyLevels();
      if (accumulatedTotalArea !== null && accumulatedTotalArea && totalOccupancyArea !== null) {
        if (Number(accumulatedTotalArea) > Number(totalOccupancyArea)) {
          return false;
        } 
      }
    }
    return true;    
  }

  // validation for overage sprinkler test entry
  overAgeSprinklerTest(field: FormlyFieldConfig, control) {
    const value = control.value;
    const formlyService = field?.options?.formState?.service;
    const overAgeSprinklerTest =
      formlyService.riskReport?.model?.sprinklerReport?.asgrSprinklerSystemTest?.overAgeSprinklerTests;
    if (overAgeSprinklerTest && overAgeSprinklerTest.length > 0 && !value) {
      return false;
    } else {
      return true;
    }
  }
}
