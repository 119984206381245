import { FormControl, Validators } from '@angular/forms';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { Constants } from 'src/app/services/util-service/constants';
import { numericInputValidator } from 'src/app/validators/formly-builder/numeric-input.validator';


export class SprinklerTableSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'sprinklers',
      type: 'fr-common-custom-grid-view',
      className: 'sprinkler-table-grid-view',
      wrappers: ['simple-section-wrapper'],
      props: {
        label: 'Sprinklers',
        isCollapsable: true,
        isCollapsed: false,
        shallowErrors: true,
        // eslint-disable-next-line max-len
        actionButtons: [{ section: 'sprinklerTable', action: 'openArraySheetAdd', label: 'Add', isDisabled: false }],
        gridSection: 'sprinklers',
        gridColConfig: [
          { label: 'System', propertyPath: 'systemNumber', className: 'common-grid-view-width-80' },
          {
            label: 'Make Model',
            propertyPath: 'sprinklerMakeAndModel',
            className: 'common-grid-view-width-120',
          },
          { label: 'Year MRD', propertyPath: 'yearManufactured', className: 'common-grid-view-width-100' },
        ],
        isAddIdentifier: true,
        identifierKey: 'sprinkerTable',
        reportIdentifierKey: 'reportId',
        reportName: 'SprinklerReport',
        commentFieldKey: 'comments',
        title: 'Sprinklers',
        subTitle: 'List of all types sprinklers',
        isSubTitleNeededInsideSheet: false,
        swipeActionDetails: [{ action: 'clone', styleName: 'cloneIcon' },
        { action: 'comment', styleName: 'commentIcon' }],
        sheetButtons: [
          {
            section: 'sprinklerTable',
            action: 'onClose',
            label: 'Close',
            service: 'sprinklerTableService',
            className: 'close-btn medium-btn',
            passInitialModel: true,
          },
          {
            section: 'sprinklerTable',
            action: 'onApply',
            label: 'Apply',
            service: 'sprinklerTableService',
            className: 'apply-btn large-btn',
            role: 'apply',
          },
        ],
      },
      fieldGroupClassName: 'sprinkler-table-container',
      expressions: {
        'props.actionButtons[0].label': (field: FormlyFieldConfig) => field?.model?.length ? 'Edit' : Constants.btnText,
        'props.actionButtons[0].action': (field: FormlyFieldConfig) => field?.model?.length ?
          'openArraySheetEdit' : 'openArraySheetAdd',
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          field.props.actionButtons[0].label = field?.model?.length ? 'Edit' : Constants.btnText;
        },
      },
      fieldArray: {
        fieldGroupClassName: 'sprinkler-table-field-container',
        wrappers: ['error-info-wrapper'],
        fieldGroup: [
          {
            key: 'systemNumber',
            name: 'System',
            type: 'simple-input',
            className: 'system-number-input',
            defaultValue: null,
            props: {
              label: 'System',
              required: true,
              isAsteriskNotRequired: true,
              maxLength: 3,
            },
          },
          {
            key: 'sprinklerMakeAndModel',
            name: 'MakeModel',
            type: 'simple-input',
            className: 'make-model-input',
            defaultValue: null,
            props: {
              label: 'Make Model',
              required: true,
              isAsteriskNotRequired: true,
            },
          },
          {
            key: 'yearManufactured',
            name: 'Year MFD',
            type: 'simple-input',
            className: 'medium-input',
            defaultValue: null,
            props: {
              label: 'Year MFD',
              required: true,
              isAsteriskNotRequired: true,
              type: 'number',
              maxLength: 4,
              minLength: 4
            },
            validators: {
              year1753Validator: {
                expression: (control: FormControl, field: FormlyFieldConfig) => {
                  const value = control.value;
                  const pastyear = 1753;
                  if (value !== null && value) {
                    if (value >= pastyear) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                },
                message: 'Year manufactured cannot be before 1753'
              },
              currentYearValidator: {
                expression: (control: FormControl, field: FormlyFieldConfig) => {
                  const value = control.value;
                  const currentYear = new Date().getFullYear();
                  if (value !== null && value) {
                    if (value <= currentYear) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                },
                message: 'Year manufactured cannot be in the future'
              }
            }
          },
          {
            key: 'numberOfUprightSprinklers',
            name: 'Upright',
            type: 'simple-input',
            className: 'medium-input',
            defaultValue: null,
            focus: true,
            props: {
              label: '# Upright',
              required: false,
              type: 'number',
              isAsteriskNotRequired: true,
            },
            expressions: {
              'props.required': (field: FormlyFieldConfig) => {
                const val = this.formlyBuilderService.sprinklerTableService.checkRequiredField(field);
                return val;
              }
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                field?.form?.valueChanges.subscribe((value) => {
                  if (value) {
                    field.props.required = this.formlyBuilderService.sprinklerTableService.checkRequiredField(field);
                  }
                });
              }
            }
          },
          {
            key: 'numberOfPendantSprinklers',
            name: 'Pendant',
            type: 'simple-input',
            className: 'medium-input',
            defaultValue: null,
            props: {
              label: '# Pendant',
              required: false,
              type: 'number',
              isAsteriskNotRequired: true,
            },
            expressions: {
              'props.required': (field: FormlyFieldConfig) => {
                const val = this.formlyBuilderService.sprinklerTableService.checkRequiredField(field);
                return val;
              }
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                field?.form?.valueChanges.subscribe((value) => {
                  if (value) {
                    field.props.required = this.formlyBuilderService.sprinklerTableService.checkRequiredField(field);
                  }
                });
              }
            }
          },
          {
            key: 'numberOfSideWallSprinklers',
            name: 'sideWall',
            type: 'simple-input',
            className: 'medium-input-row-second',
            defaultValue: null,
            props: {
              label: '# Side Wall',
              required: false,
              type: 'number',
              isAsteriskNotRequired: true,
            },
            expressions: {
              'props.required': (field: FormlyFieldConfig) => {
                const val = this.formlyBuilderService.sprinklerTableService.checkRequiredField(field);
                return val;
              }
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                field?.form?.valueChanges.subscribe((value) => {
                  if (value) {
                    field.props.required = this.formlyBuilderService.sprinklerTableService.checkRequiredField(field);
                  }
                });
              }
            }
          },
          {
            key: 'numberOfDryPendantSprinklers',
            name: 'dryPendent',
            type: 'simple-input',
            className: 'medium-input-row-second',
            defaultValue: null,
            props: {
              label: '# Dry Pendent',
              required: false,
              type: 'number',
              isAsteriskNotRequired: true,
            },
            expressions: {
              'props.required': (field: FormlyFieldConfig) => {
                const val = this.formlyBuilderService.sprinklerTableService.checkRequiredField(field);
                return val;
              }
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                field?.form?.valueChanges.subscribe((value) => {
                  if (value) {
                    field.props.required = this.formlyBuilderService.sprinklerTableService.checkRequiredField(field);
                  }
                });
              }
            }
          },
          {
            key: 'orificeSize',
            name: 'orificeSize',
            type: 'simple-input',
            className: 'medium-input-row-second',
            defaultValue: null,
            props: {
              label: 'Orifice Size',
              required: false,
              isDecimal: true,
              type: 'decimal',
              //code to format the input value into decimal on blur(field change)
              modelOptions: {
                updateOn: 'blur'
              },
              change: (field: FieldTypeConfig) =>
                formlyBuilderService.formlyWrapperService.formatDecimal(field, "truncate", "2")
            },
          },
          {
            key: 'temperatureRating',
            name: 'temperatureRating',
            type: 'simple-input',
            className: 'medium-input-row-second',
            defaultValue: null,
            props: {
              label: 'Temp Rating',
              required: true,
              type: 'number',
              isAsteriskNotRequired: true,
            },
          },
          {
            key: 'comments',
            props: {
              commentActionKey: 'comments'
            }
          },
          {
            key: 'reportId',
            defaultValue: null,
          },
          {
            key: 'yearManufacturedComment',
            defaultValue: null,
          },
          {
            key: 'orificeSizeComment',
            defaultValue: null,
          },
          {
            key: 'orificeSizeComment',
            defaultValue: null,
          },
          {
            key: 'sprinklerId',
            defaultValue: null
          }
        ],
      },
      validators: {
        duplicateValidation: {
          expression: (control: FormControl, field: FormlyFieldConfig) => {
            const isDuplicate = this.formlyBuilderService.sprinklerTableService.hasDuplicateWithDifferentFields(field);
            if (isDuplicate) {
              return false;
            } else {
              return true;
            }
          },
          message: 'Duplicate entries should not be made, either remove or edit the duplicate entries'
        }
      }
    });
  }
}


