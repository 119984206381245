import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';

/**
 * Class that represents the Formly Model for RoofTop
 */
export class PhotosDataModel {
  static sectionName = 'reportPhotos';

  reportPhotoIdentifier: string;
  reportIdentifier: string;
  photoIdentifier: string;
  reportPhotoType: string;

  /**
   * 
   * @param reports Specific Photo part of the report (ReportPhotos section)
   * @returns 
   */
  static fromS3(riskReport: any): PhotosDataModel[] {
    const modelPhoto: PhotosDataModel[] = [];

    if (riskReport) {
      riskReport.forEach((photo) => {
        modelPhoto.push({
          reportPhotoIdentifier: photo.ReportPhotoIdentifier,
          reportIdentifier: photo.ReportIdentifier,
          photoIdentifier: photo.PhotoIdentifier,
          reportPhotoType: photo.ReportPhotoType,
        });
      });
    }

    return modelPhoto;
  }

  /**
   * 
   * @param model Formly Model
   * @returns Risk Report
   */
  static to(model: any) {
    const riskPhoto = [];

    model.forEach((photo) => {
      if (photo.photoIdentifier) {
        riskPhoto.push({
          ReportPhotoIdentifier: photo.reportPhotoIdentifier,
          ReportIdentifier: photo.reportIdentifier,
          PhotoIdentifier: photo.photoIdentifier,
          ReportPhotoType: photo.reportPhotoType,
        });
      }
    });

    return riskPhoto;
  }

  /**
   * @deprecated
   * @param reports 
   * @returns 
   */
  static from(reports: RiskReportDataReports): PhotosDataModel {
    return undefined
  }
}
