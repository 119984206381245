export class ErrorMappings {
  /**
   * This is the mapping between repeated errors / duplicates both in Frontend and Backend
   */
  public static readonly BackendErrorsMapping = new Map<string, string>([
    [ 'FLRROF20', 'Occupancy Area and Construction Area are not equal' ],     // Backend error: Occupancy Area and Construction Floor Area are not equal.
    [ 'FLRROF39', 'Roof Cover Age is required' ],                             // Backend error: Year Of Roof Cover is required
    [ 'RFENVP05', 'Mechanical equipment comments required' ],
    [ 'ENVEXP01', 'Yard property exposures comment is required' ],
    [ 'ENVEXP02', `Do any buildings within 300' radius or 1000' Comment is Required` ],
    [ 'ENVEXP03', 'Is there a potential for debris from building structures or equipment Comment is Required' ],
    [ 'ENVEXP04', 'Does building have a lower roof sections Comment is Required' ],
    [ 'FLRROF12', 'Listing is required' ],
    [ 'FLRROF28', 'Listing is required' ],
    [ 'RSKINT01', 'Partial Supply Alarm Type is Required']
  ]);
}
