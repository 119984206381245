import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { OrdersService } from '../orders.service';
import { UtilService } from '../../util-service/util.service';
import { LocalStorageService } from '../../local-storage/local-storage.service';
import { AssetType, ConfigModel } from 'src/app/enums/config-model.enum';
import { AmplifyService } from 'src/app/services/amplify/amplify.service';
import { OrderAttachmentsModel } from 'src/app/models/order/order-attachments.model';
import { CustomerAttachmentsModel } from 'src/app/models/order/order-customer-attachment.model';
import { ToastService } from '../../toast-service/toast.service';
import { Constants } from '../../util-service/constants';
import { FileCategoryEnum } from 'src/app/enums/file-category.enum';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  attachmentData: any;
  fileFormat: string | null = null;
  fileDataUri: string | null = null;
  selectedOrder: string;

  constructor(
    private utilService: UtilService,
    private orderservice: OrdersService,
    private localStorageService: LocalStorageService,
    private amplifyService: AmplifyService,
    private toasterService: ToastService
  ) {
    this.selectedOrder = this.orderservice.selectedOrderFromS3;
  }

  async getOrderAttachmentList(selectedOrder) {
    const orderAttachInfo:OrderAttachmentsModel[] = selectedOrder.Attachments;
    let attachmentList= [];
    const fullOrderNumber = selectedOrder.OrderIdFullOrderNumber;
    await this.amplifyService.syncOrderAttachments(selectedOrder, fullOrderNumber); 
    orderAttachInfo.forEach(async (item) => {
      attachmentList.push({
        assetData: {
          Id: item.Id,
          UniqueKey: item.UniqueKey,
          description: item.Description ? item.Description : 'Order Attachment Details',
          fileName: item.FileName,
          attachType: item.AttachType ? item.AttachType : 'General',
        },
        Id: Guid.create().toString(),
      });
    });
    return attachmentList;
  }

  pickFile() {
    const result = new Promise((resolve, reject) => {
      FilePicker.pickFiles({
        multiple: false,
        readData: true,
      })
        .then((val) => {
          resolve(val);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return result;
  }

  //saves customer assets
  async saveCustomerAssetsOrder(attachmentList, deleted = false) {
    let assetsdata: CustomerAttachmentsModel[] = [];
    await attachmentList.forEach(async (item) => {
      assetsdata.push({
        AttachType: item.assetData.attachType,
        FileName: item.assetData.FileName,
        Description: item.assetData.description,
      });
    });
    this.selectedOrder = this.orderservice.selectedOrderFromS3;
    this.selectedOrder['CustomerAssets'] = assetsdata;
    this.SaveCustomerAssets(assetsdata, this.selectedOrder, deleted).then(()=>{
      return true;
    });
  }

  SaveCustomerAssets(attachmentList, selectedOrder, isDeleted: boolean) {
    return new Promise((resolve, reject) => {
          this.orderservice.selectedOrderFromS3 = selectedOrder;
           this.localStorageService.saveOrderJSONToLocal(selectedOrder, true).then(async (status) => {
            if(status){
            if (this.attachmentData?.fileData?.data?.name && !isDeleted) {
              this.fileFormat = this.attachmentData?.fileData.data.name.split('.').pop();
              let path = `${'Order'}/${selectedOrder.OrderIdFullOrderNumber}/${'customerassets'}/${this.attachmentData.fileData.data.name }`;
              this.localStorageService.updateLocalfile(path, this.attachmentData?.fileData.data.data).then(() => {
                this.orderservice.fileSavedToLocal.next([
                  {
                    sync: true,
                    model: ConfigModel.Order,
                    identifier: selectedOrder.OrderIdFullOrderNumber,
                    assetType: AssetType.CustomerAssets,
                    assetIdentifier: this.attachmentData?.fileData?.data?.name?.split('.').slice(0, -1).join('.'),
                    fileName: this.attachmentData?.fileData.data.name,
                  },
                ]);
                resolve(true);
              });
            } else {
          if (isDeleted) {
              const fileExtension = this.utilService.getExtensionFromFileName(this.attachmentData?.assetData.FileName);
              await this.localStorageService.deleteLocalAttachmentByIdentifier(
                ConfigModel.Order,
                selectedOrder.OrderIdFullOrderNumber,
                this.attachmentData?.assetData?.FileName?.split('.').slice(0, -1).join('.'),
                fileExtension,
                true
              );

              this.orderservice.fileSavedToLocal.next([
                {
                  sync: true,
                  model: ConfigModel.Order,
                  identifier: selectedOrder.OrderIdFullOrderNumber,
                  assetType: AssetType.CustomerAssets,
                  assetIdentifier: this.attachmentData?.assetData?.FileName?.split('.').slice(0, -1).join('.'),
                  fileName: this.attachmentData?.assetData.FileName,
                  fileDeleted: true,
                },
              ]);
              resolve(true);
            }
          }
        }
      });
    });
  }

  // saves internal assets
  async saveOrder(attachmentList, UniqueKey, deleted = false) {
    let assetsdata: OrderAttachmentsModel[] = [];
    await attachmentList.forEach(async (item) => {
      assetsdata.push({
        Id: item.assetData.Id,
        UniqueKey: item.assetData.UniqueKey,
        AttachType: item.assetData.attachType,
        FileName: item.assetData.fileName,
        Description: item.assetData.description,
      });
    });
    this.selectedOrder = this.orderservice.selectedOrderFromS3;
    this.selectedOrder['Attachments'] = assetsdata;
    this.SaveInternalAssets(assetsdata, this.selectedOrder, UniqueKey, deleted).then(() => {
      return true;
    });
  }

  SaveInternalAssets(attachmentList, selectedOrder, UniqueKey, isDeleted: boolean) {
    return new Promise((resolve, reject) => {
      this.orderservice.selectedOrderFromS3 = selectedOrder;

      this.localStorageService.saveOrderJSONToLocal(selectedOrder, true).then(async (status) => {
        if(status){
            if (this.attachmentData?.fileData?.data?.name && !isDeleted) {
              this.fileFormat = this.attachmentData?.fileData.data.name.split('.').pop();
              let path = `Order/${selectedOrder.OrderIdFullOrderNumber}/documents/${UniqueKey}.${this.fileFormat}`;
              this.localStorageService.updateLocalfile(path, this.attachmentData?.fileData.data.data).then(() => {
                this.orderservice.fileSavedToLocal.next([
                  {
                    sync: true,
                    model: ConfigModel.Order,
                    identifier: selectedOrder.OrderIdFullOrderNumber,
                    assetType: AssetType.Documents,
                    assetIdentifier: UniqueKey,
                    fileName: UniqueKey + '.' + this.fileFormat,
                  },
                ]);
                resolve(true);
              });
            } else {
          if (isDeleted) {
              this.fileFormat = this.utilService.getExtensionFromFileName(this.attachmentData?.assetData.fileName);
              await this.localStorageService
                .deleteLocalAttachmentByIdentifier(
                  ConfigModel.Order,
                  selectedOrder.OrderIdFullOrderNumber,
                  UniqueKey,
                  this.fileFormat
                )
                .then(async () => {
                  this.orderservice.fileSavedToLocal.next([
                    {
                      sync: true,
                      model: ConfigModel.Order,
                      identifier: selectedOrder.OrderIdFullOrderNumber,
                      assetType: AssetType.Documents,
                      assetIdentifier: UniqueKey,
                      fileName: UniqueKey + '.' + this.fileFormat,
                      fileDeleted: true,
                    },
                  ]);
                  resolve(true);
                });
          }
        }
      }
      });
    });
  }

  getCustomerAssetObj(data:CustomerAttachmentsModel) {
    return new Promise((resolve, reject) => {
      let obj = {
        assetData: {
          FileName: data.FileName,
          attachType: data.AttachType,
          description: data.Description
        } ,
        Id: Guid.create().toString(),
      };
      resolve(obj);
    });
  }

  // deletes file from attatachment model page
  deleteAttachment(attachment, fileData) {
    return new Promise((resolve, reject) => {
      let assetType = fileData.assets;
      this.attachmentData = fileData.selectedfiles;
      this.selectedOrder = this.orderservice.selectedOrderFromS3;
      if (assetType === FileCategoryEnum.InternalAssets) {
        let UniqueKey = attachment.assetData.UniqueKey;
        this.SaveInternalAssets(fileData.attachmentList, this.selectedOrder, UniqueKey, true).then((status) => {
          if (status) {
            resolve(attachment);
          }
        });
      } else {
        if (assetType === FileCategoryEnum.CustomerAssets) {
          this.SaveCustomerAssets(fileData.attachmentList, this.selectedOrder, true).then((status) => {
            if (status) {
              resolve(attachment);
            }
          });
        }
      }
    });
  }

  deleteInternalAttachment(attachment, attachmentList) {
    return new Promise((resolve, reject) => {
      this.attachmentData = attachment;
      let UniqueKey = attachment.assetData.UniqueKey;
      attachmentList = attachmentList.filter((item) => item.Id !== attachment.Id);
      this.saveOrder(attachmentList, UniqueKey, true).then(() => {
        resolve(attachmentList);
      });
    });
  }

  deleteCustomerAttachment(attachment,attachmentList){
    return new Promise((resolve, reject) => {
        this.attachmentData = attachment;
    for (var i = 0; i < attachmentList.length; i++) {
      if (attachmentList[i].Id === attachment.Id) {
        attachmentList.splice(i, 1);
      }
    }
    this.saveCustomerAssetsOrder(attachmentList, true).then(() => {
      resolve(attachmentList);
    });
  });
  }

  //deletes asset record after click close without adding file in existing asset
  deleteAttachmentRecord(fileData) {
    return new Promise((resolve, reject) => {
      let assetType = fileData.assets;
      fileData.attachmentList = fileData.attachmentList.filter((item) => item.Id !== fileData.selectedfiles.Id);
      if (assetType === FileCategoryEnum.InternalAssets) {
        this.saveOrder(fileData.attachmentList, fileData.selectedfiles.assetData.UniqueKey).then(() => {
          resolve(fileData.attachmentList);
        });
      } else {
        if (assetType === FileCategoryEnum.CustomerAssets) {
          this.saveCustomerAssetsOrder(fileData.attachmentList).then(() => {
            resolve(fileData.attachmentList);
          });
        }
      }
    });
  }

  async getFileData(fileData) {
    try {
      this.selectedOrder = this.orderservice.selectedOrderFromS3;
      const {
        assets: assetType,
        selectedfiles: { assetData },
      } = fileData;

      const orderId = this.selectedOrder['OrderIdFullOrderNumber'];
      let path;
      let extension;
      if (assetType === FileCategoryEnum.InternalAssets) {
        extension = assetData.fileName.split('.').pop();
        const uniqueKey = assetData.UniqueKey;
        path = `Order/${orderId}/documents/${uniqueKey}.${extension}`;
      } else if (assetType === FileCategoryEnum.CustomerAssets) {
        extension = assetData.FileName.split('.').pop();
        const fileName = assetData.FileName;
        path = `Order/${orderId}/customerassets/${fileName}`;
      }
      const data = await this.localStorageService.readLocalFile(path, true);
      return { data: data, path: path, extension: extension };
    } catch (error) {
      this.toasterService.showToast(`${Constants.FILE_NOT_FOUND}`, false, {
        cssClass: 'error-toast',
        duration: 3000,
      });
      return null;
    }
  }
}
