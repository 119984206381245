import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNumericInput]',
})
export class NumericInputDirective {
  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() allowDecimals: string = 'false';

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: any): void {
    let inputValue = event.target.value;
    // Remove non-numeric characters
    inputValue = inputValue.replace(/[^\d.]/g, '');
    // Ensure the value is not empty
    if (!inputValue) {
      this.el.nativeElement.value = null;
      return;
    }
    // Convert string to boolean
    const allowDecimalsBool = this.allowDecimals.toLowerCase() === 'true';
    // If decimals are allowed, ensure valid decimal format
    if (allowDecimalsBool) {
      // Regular expression to validate decimal number with up to two decimal places
      const decimalRegex = /^(?:\d{0,2}(?:\.\d{0,2})?|0?\.\d{0,2})$/;
      // Check if the inputValue matches the decimalRegex
      if (!decimalRegex.test(inputValue)) {
        // Prevent the input if it's invalid
        inputValue = inputValue.slice(0, -1); // Remove the last character
      }
    } else {
      // Remove leading zeros, except for the single zero
      inputValue = inputValue.replace(/^0+(?!$)|\./g, '');
    }
    // Convert to number and check validity
    const numericValue = parseFloat(inputValue);
    // Ensure the value is within the specified range
    if (isNaN(numericValue) || numericValue < this.minValue || numericValue > this.maxValue) {
      event.preventDefault();
      this.el.nativeElement.value = inputValue.slice(0, -1); // Revert to the previous valid value
    } else {
      this.el.nativeElement.value = inputValue; // Update the input value
    }
  }
}
