/* eslint-disable @typescript-eslint/naming-convention */
import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

/**
 * Class that represents the Formly Model for Occupants
 */
export class GeneralInformationDataModel {
  static sectionName = 'generalInfo';

  buildingName: string;
  yearBuilt: number;
  fireHydrant: boolean;
  evidenceOfFireSprinkler: boolean;

  // TODO: Remove Amplify once unnecessary
  static from(reports: RiskReportDataReports): GeneralInformationDataModel {
    return {
        buildingName: reports.BldgDesc.trim(),
        yearBuilt: reports.YearBuilt,
        fireHydrant: reports.IsHydrantsWithin1000Feet,
        evidenceOfFireSprinkler: reports.EvidenceOfFireSprinkler
    };
  }

  // TODO: Rename to read
  static fromS3(reports: any): GeneralInformationDataModel {
    let isHydrantsWithin1000Feet = null;
    if(reports.IsHydrantsWithin1000Feet === 'Y') {
      isHydrantsWithin1000Feet = 'true';
    } else if (reports.IsHydrantsWithin1000Feet === 'N') {
      isHydrantsWithin1000Feet  = 'false';
    }

    let evidenceOfFireSprinkler = null;
    if(reports.EvidenceOfFireSprinkler === 'Y') {
      evidenceOfFireSprinkler = 'true';
    } else if (reports.EvidenceOfFireSprinkler === 'N') {
      evidenceOfFireSprinkler  = 'false';
    }

    return {
        buildingName: reports.BuildingDescription.trim(),
        yearBuilt: reports.YearBuilt,
        fireHydrant: isHydrantsWithin1000Feet,
        evidenceOfFireSprinkler
    };
  }

  /**
   *
   */
  static to(model: any, sectionEnum?: SectionEnum) {
    if(sectionEnum === SectionEnum.BUILDING) {
      return { BuildingDescription: model.buildingName };
    } else if (sectionEnum === SectionEnum.YEAR_BUILT) {
      return { YearBuilt: model.yearBuilt ? Number(model.yearBuilt) : null };
    } else if (sectionEnum === SectionEnum.FIRE_HYDRANT) {
      let isHydrantsWithin1000Feet = null;
      if (model.fireHydrant === 'true') {
        isHydrantsWithin1000Feet = 'Y';
      } else if(model.fireHydrant === 'false') {
        isHydrantsWithin1000Feet = 'N';
      }
      return { IsHydrantsWithin1000Feet: isHydrantsWithin1000Feet };
    } else if(sectionEnum === SectionEnum.FIRE_SPRINKLER) {
      let evidenceOfFireSprinkler = null;
      if (model.evidenceOfFireSprinkler === 'true') {
        evidenceOfFireSprinkler = 'Y';
      } else if(model.evidenceOfFireSprinkler === 'false') {
        evidenceOfFireSprinkler = 'N';
      }
      return { EvidenceOfFireSprinkler: evidenceOfFireSprinkler };
    } else {
      return undefined;
    }
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
    if (sectionEnum === SectionEnum.BUILDING) {
      if (!formlyModel.buildingName || !riskReportModel.BuildingDescription) {
        return false;
      }
      if (formlyModel.buildingName === riskReportModel.BuildingDescription) {
        return false;
      } else {
        return true;
      }
    } else if (sectionEnum === SectionEnum.YEAR_BUILT) {
      if (riskReportModel.YearBuilt === undefined) {
        return true;
      }
      if (formlyModel.yearBuilt === riskReportModel.YearBuilt) {
        return false;
      } else {
        return true;
      }
    } else if (sectionEnum === SectionEnum.FIRE_HYDRANT) {
      if (riskReportModel.IsHydrantsWithin1000Feet === undefined) {
        return true;
      }
      if (formlyModel.fireHydrant === riskReportModel.IsHydrantsWithin1000Feet) {
        return false;
      } else {
        return true;
      }
    } else if (sectionEnum === SectionEnum.FIRE_SPRINKLER) {
      if (riskReportModel.EvidenceOfFireSprinkler === undefined) {
        return true;
      }
      if (formlyModel.evidenceOfFireSprinkler === riskReportModel.EvidenceOfFireSprinkler) {
        return false;
      } else {
        return true;
      }
    }

  }
}
