import { Constants } from "../util-service/constants";

// style
export const STYLE_SATELLITE_STREETS = 'mapbox://styles/mapbox/satellite-streets-v12';
export const STYLE_MAP = 'mapbox://styles/mapbox/streets-v11';
export const ROOF_TOP_ZOOM = 18;
// map center - the center at which the map initially loads in [lng, lat]
export const LNG = -74.479414;
export const LAT = 39.372172;

export const STARTING_ZOOM = 15;
export const AerialMap_ZOOM = 19;
export const AerialMap_MIN_ZOOM = 15;
export const AerialMap_CLUSTER_ZOOM = 16;

// priorities:
// [1-33] = low, [34-66] = medium, [67-100] = high
// (-inf, 0] U (100, inf) OR (p <= 0 || p > 100) = unkown

// 'step' logic used by mapboxgl
// marker priorities logic and their corresponding colors
export const LOW_PRIO = [
  'all',
  ['>', ['get', 'StrategicSourcingScore'], 0],
  ['<=', ['get', 'StrategicSourcingScore'], 79],
];
export const MEDIUM_PRIO = [
  'all',
  ['>', ['get', 'StrategicSourcingScore'], 79],
  ['<=', ['get', 'StrategicSourcingScore'], 89],
];
export const HIGH_PRIO = [
  'all',
  ['>', ['get', 'StrategicSourcingScore'], 89],
  ['<=', ['get', 'StrategicSourcingScore'], 100],
];
export const UNKNOWN_PRIO = [
  'any',
  ['<=', ['get', 'StrategicSourcingScore'], 0],
  ['>', ['get', 'StrategicSourcingScore'], 100],
];

// how far you can zoom into the map for a cluster and a marker, and the factor for the duration
export const CLUSTER_ZOOM = 14;
export const MARKER_ZOOM = 15;
export const ZOOM_DURATION_FACTOR = 400;

// radius at which markers become a cluster
export const CLUSTER_RADIUS = 75;

// time in ms it takes to zoom to a marker
export const EASE_TO_DURATION = 1500;

// marker colors that correspond to the priorities: [low, medium, high, unkown]
export const LOW_PRIO_COLOR = '#00358E';
export const MEDIUM_PRIO_COLOR = '#2ECDDC';
export const HIGH_PRIO_COLOR = '#FFC600';
export const UNKOWN_PRIO_COLOR = '#FFD264';
export const VERISK_BLUE_COLOR = '#00358E'; //For Order markers
// cluster sizes and radii are calculated based on the marker amount: [small = 10, large = 100]
export const SMALL_MARKER_AMOUNT = 10;
export const LARGE_MARKER_AMOUNT = 100;

// font sizes for number on clusters
export const SMALL_CLUSTER_FS = 20;
export const MEDIUM_CLUSTER_FS = 22;
export const LARGE_CLUSTER_FS = 24;

// radii for the size of the cluster circles
export const SMALL_CLUSTER_R = 32;
export const MEDIUM_CLUSTER_R = 36;
export const LARGE_CLUSTER_R = 40;

// how far you can zoom into the map for a BINS Polygons
export const BINS_ZOOM = 15;

export const BINS_BUILDING_POLYGON_COLOR = '#FF5833';
export const BINS_SITES_POLYGON_COLOR = '#168BF6';

export const EMPTY_GEOJSON = {
  type: 'FeatureCollection',
  features: [],
};

//to get markers colors correspond to their status
export const Risk_Map_New_Risk = ['all', ['==', ['get', 'RiskUIStatus'], Constants.New_Status]];
 
export const Risk_Map_In_Progress = ['all', ['==', ['get', 'RiskUIStatus'], Constants.In_Progress_Status]];
export const Risk_Map_Completed = ['all', ['==', ['get', 'RiskUIStatus'], Constants.Completed_Status]];
export const Risk_Map_Validation_Issues = ['all', ['==', ['get', 'RiskUIStatus'], Constants.Validation_Issues_Status]];
export const Risk_Map_Deleted = ['all', ['==', ['get', 'RiskUIStatus'], Constants.Deleted_Status]];
 
export const Risk_Map_Existing = ['all', ['==', ['get', 'RiskUIStatus'], Constants.Add_Existing_Status]];
