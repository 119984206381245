import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController, Platform } from '@ionic/angular';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { Subject } from 'rxjs';
import { UtilService } from 'src/app/services/util-service/util.service';
import { FormlyWrapperService } from 'src/app/services/formly-builder/formly-wrapper.service';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { takeUntil } from 'rxjs/operators';
import { FormlyErrorService } from 'src/app/services/formly-builder/formly-error.service';
import { Constants } from 'src/app/services/util-service/constants';

/**
 * Generic Sheet Component
 * Shows a sheet with a title, subtitle and action buttons
 * Supported field props:
 * - sheetButtons: ActionButton[].
 * Buttons can have this properties:
 * - action - Action name OR method name if a service is specified.
 * - label - Text that will appear in the initial/landing page section
 * - title - Title (on Top) of the section (Sprinkler Report, Wind Report,...)
 * - subTitle - In italic (on Bold).
 * - isDisabled
 * - service - A service accessible from FormlyBuilderService with a getter with this name. The action is the method name in this case.
 * - className - Extra class to be applied to the buttons. NEED TO BE DEFINED IN THIS COMPONENTS SCSS
 * - hasConfirmationPopup - Boolean Flag used to show(true) confirmation on submit
 */
@Component({
  selector: 'app-formly-generic-sheet',
  templateUrl: './formly-generic-sheet.component.html',
  styleUrls: ['./formly-generic-sheet.component.scss'],
})
export class FormlyGenericSheetComponent extends FieldType<FieldTypeConfig> implements OnInit {
  @ViewChild(IonContent) content: IonContent;
  @Input() Options;

  // isSaveButtonEnabled = true;
  // isSavingEnabled = true;
  // isConfirmedAndDirty = false;
  isValid = true;
  public initialForm: any;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    public modalController: ModalController,
    public screenOrientation: ScreenOrientation,
    public formlyService: FormlyBuilderService,
    public utilService: UtilService,
    private wrapperService: FormlyWrapperService,
    private errorService: FormlyErrorService,
  ) {
    super();
  }

  async ngOnInit() {
    // if (this.field?.options?.formState?.target) {
    //   const target = this.field?.options?.formState?.target;
    // };

    this.initialForm = this.formControl?.value;
  }

  //After ion modal content loaded
  ngAfterViewInit() {
    this.utilService.setContentHeight(this.content, this.ngUnsubscribe);
  }

  ionViewWillLeave() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onClose() {
    this.formlyService.errorService.closePreviousToaster();
    this.modalController.dismiss();
  }

  onSwipeDown() {
    if(this.field?.props?.sheetButtons?.length) {
      this.field.props.sheetButtons.forEach((sheetBtn)=>{
        if(sheetBtn.label === 'Close') {
          this.onSheetButton(sheetBtn.action, sheetBtn.service, sheetBtn.passInitialModel);
        }
      });
    } else {
      this.onClose();
    }
  }

  /**
   * A sheet Button has been pressed
   *
   * @param $event
   */
  onSheetButton($event, service, passInitialModel?: boolean, hasConfirmationPopup?: boolean) {
    // // For Full risk
    // if (sectionId !== undefined) {
    //   this.router.navigate([], { queryParams: { sectionId, isEdit: true }, queryParamsHandling: 'merge', relativeTo: this.activatedRoute });
    //   return;
    // }

    // 1. Check if pressed button is in list of action buttons
    const actionKey = $event;
    // const actionLabel = ...
    // Check that this is a valid action
    // if (this.field.props[this.name] === actionKey){
    if (this.isSheetButtonValid(actionKey)) {
      // If a service was provided and it exists in the FormlyBuilderService as getter, execute the action method for this service
      if (service && this.field.options.formState.service) {
        try {
          const formBuilderService: any = this.field.options.formState.service;
          const serviceRef = formBuilderService[service];
          const methodRef = serviceRef[actionKey];
          // methodRef(this.modalController, this.field);
          if(hasConfirmationPopup && !this.isSheetValid(this.field)){
              this.confirmationPopUp(this.field, service, actionKey,passInitialModel);
          }else{
            if (!passInitialModel) {
              methodRef(this.modalController, this.field);
            } else {
                methodRef(this.modalController, this.field, this.initialForm);
            }
          }
        } catch (ignore) {
          console.error('Method ' + service + '.' + actionKey);
        }
      } else {
        // For instance: key: "Occupants" action: "add"
        this.wrapperService.triggerActionButton(this.field.key, actionKey, true);
      }
    }
  }

  isSheetButtonValid(actionKey) {
    if (this.field.props.sheetButtons) {
      for (const myAction of this.field.props.sheetButtons) {
        if (myAction.action === actionKey) {
          return true;
        }
      }
    }
    return false;
  }
  
  onApply(service, actionKey, passInitialModel){
    const formBuilderService: any = this.field.options.formState.service;
    const serviceRef = formBuilderService[service];
    const methodRef = serviceRef[actionKey];
    if (!passInitialModel) {
      methodRef(this.modalController, this.field);
    } else {
        methodRef(this.modalController, this.field, this.initialForm);
    }
  }
  
  /**
   *
   * @description To check wheteher the sheet is valid or not
   * @return * {boolean}
   */
   isSheetValid(field): boolean {
    return field.formControl.valid;
  }

  /**
   *
   * @description If there are validation errors will open a confirmation popup,
   * To check whether wants to save with those errors from the sheet
   */
  confirmationPopUp(field,service, actionKey,passInitialModel) {
    this.errorService.closePreviousToaster();
    this.utilService.showConfirmationPopup(
      Constants.sheetconfirmationMsg,
      '',
      async (cb) => {
        if (cb === 1) {
          try {
            this.onApply(service, actionKey, passInitialModel);
          } catch (error) {
            console.error('error' + error);
          }
        } else if (cb === 0) {
          // We mark all fields as touched
          field.formControl.markAllAsTouched();
          // Predominat Custom types are not informed when we mark fields as Touched  
          // so we need to explicity change show error on Cancel  
          if(field.fieldGroup?.length ){
            field.fieldGroup?.forEach(section => {
              if(section.fieldGroup?.length){
                section.fieldGroup?.forEach( element => {
                  if(element.type === 'rf-predominant-block'){
                    section.props.showError = true;
                  }
                });
              }
            });
          }
        }
      },
      [ 'Save', 'Cancel' ]
    );
  }
}
