import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';

/**
 * Class that represents the Formly Model for Occupants
 */
export class FireSprinklerDataModel {
  static sectionName = 'presenceOfFireSprinkler';

  evidenceOfFireSprinkler: boolean;

  static from(data: RiskReportDataReports): FireSprinklerDataModel {
    return {
      evidenceOfFireSprinkler: data.EvidenceOfFireSprinkler,
    };
  }

  static fromS3(data: any): FireSprinklerDataModel {
    return {
      //evidenceOfFireSprinkler: FireSprinklerDataModel.hasSprinkler(data.SprinklerTypeCodeValue),
      evidenceOfFireSprinkler: data.EvidenceOfFireSprinkler
    };
  }

  static to(model: any) {
    return { EvidenceOfFireSprinkler: model.evidenceOfFireSprinkler};
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    if (riskReportModel.EvidenceOfFireSprinkler === undefined) {
      return true;
    }

    if (formlyModel.evidenceOfFireSprinkler === riskReportModel.EvidenceOfFireSprinkler) {
      return false;
    } else {
      return true;
    }
  }

  /*static hasSprinkler(value): boolean {
    if (value === 'S' || value === 'P') {
      // "S" or "P" Partial
      return true;
    } else if (value === 'N') {
      return false;
    } else {
      return false;
    }
  }*/
}
