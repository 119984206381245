/* eslint-disable @typescript-eslint/naming-convention */
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { RiskReport } from '../../risk-report/risk-report-vm';

export class FloorsRoofBuildingInformationDataModel {
  static sectionName = 'buidingInfo';

  constructionClass: number;
  isConstructionClassOverride : boolean;
  basementLevels: number;
  aboveGradeFloors: number;
  totalArea: number;
  

  static fromS3(fullData: any): FloorsRoofBuildingInformationDataModel {
    return {
        constructionClass: fullData?.ConstructionClass,
        isConstructionClassOverride: fullData?.IsConstructionClassOverride,
        basementLevels: fullData?.BasementLevels,
        aboveGradeFloors: fullData?.AboveGradeFloors,
        totalArea: fullData?.TotalArea,
    };
  }

  static to(model: any, sectionEnum?: SectionEnum) {
      return { 
        ConstructionClass: model?.floorsAndRoofs?.buildingInformation?.constructionClass,
        IsConstructionClassOverride: model?.floorsAndRoofs?.buildingInformation?.isConstructionClassOverride ? model?.floorsAndRoofs?.buildingInformation?.isConstructionClassOverride : false,
        BasementLevels: model?.floorsAndRoofs?.buildingInformation?.basementLevels,
        AboveGradeFloors: model?.floorsAndRoofs?.buildingInformation?.aboveGradeFloors,
        TotalArea: model?.floorsAndRoofs?.buildingInformation?.totalArea ?  model.floorsAndRoofs.buildingInformation.totalArea : 0,
     };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
      return true;
}
}