/* eslint-disable @typescript-eslint/naming-convention */
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class PublicProtectionClassDataModel {
  static sectionName = 'ppc';

  fireProtectionArea: string;
  publicProtectionClass: string;
  territoryCode: string;
  isHydrantsWithin1000feet: boolean;
  neededFireFlow: number;
  isPublicProtectionClass : boolean;
  isFireProtectionArea: boolean;
  isTerritoryCode: boolean;
  isPpcFireHydrant: boolean;
  fireDepartmentCompaniesAdequacy : number;
  waterSupplyWorksAdequacy: number;
  overallAdequacy: number;
  hydrantSpacingAdequacy: number;
  fireFlowAt20Psi : number;
  hydrantsWithPumperOutlet  : number;
  hydrantsWithOneHoseOutlets : number;
  hydrantsWithTwoHoseOutlets: number;
  calculatedWaterSupplyWorksAdequacy: number;
  calculatedFireDepartmentCompaniesAdequacy: number;
  evaluatedClass: number;


  static fromS3(reports: any): PublicProtectionClassDataModel {
    
    //PpcAtRisk,PpcFpa,CommercialTerritoryCode,PpcFireHydrant and AlternativePpc are from orders
    let Ppc = reports.PublicProtectionClass ? reports.PublicProtectionClass.replace(/^0/, '').trim() : '';
    let PpcAtRisk= reports.PpcAtRisk ? reports.PpcAtRisk.replace(/^0/, '').trim() : '';
    let AlternatePpc=reports.AlternativePpc ?  reports.AlternativePpc.replace(/^0/, '').trim() : '';
    let FireHdyrant=reports.IsHydrantsWithin1000Feet === 'Y' ? true : (reports.IsHydrantsWithin1000Feet === 'N' ? false : null)
    let checkFireHydrant =reports.PpcFireHydrant === 'Hydrant' ?  true : null

    
    return {
        fireProtectionArea: reports.IsFireProtectionArea ?  reports.ReportAddresses[0]?.FireProtectionArea : reports.PpcFpa,
        publicProtectionClass: reports.IsPublicProtectionClass ? Ppc : PpcAtRisk ? PpcAtRisk : AlternatePpc ,
        territoryCode: reports.IsTerritoryCode ? reports.TerritoryCode : reports.CommercialTerritoryCode ,
        isHydrantsWithin1000feet: reports.IsPpcFireHydrant ? FireHdyrant :checkFireHydrant ,
        neededFireFlow: reports.NeededFireFlow,
        isPublicProtectionClass:reports.IsPublicProtectionClass,
        isFireProtectionArea:reports.IsFireProtectionArea,
        isTerritoryCode:reports.IsTerritoryCode,
        isPpcFireHydrant:reports.IsPpcFireHydrant,
        fireDepartmentCompaniesAdequacy:reports.FireDepartmentCompaniesAdequacy,
        waterSupplyWorksAdequacy:reports.WaterSupplyWorksAdequacy,
        overallAdequacy:reports.OverallAdequacy,
        hydrantSpacingAdequacy:reports.HydrantSpacingAdequacy,
        fireFlowAt20Psi:reports.FireFlowAt20Psi,
        hydrantsWithPumperOutlet:reports.HydrantsWithPumperOutlet,
        hydrantsWithTwoHoseOutlets:reports.HydrantsWithTwoHoseOutlets,
        hydrantsWithOneHoseOutlets:reports.HydrantsWithOneHoseOutlets,
        calculatedWaterSupplyWorksAdequacy: reports.CalculatedWaterSupplyWorksAdequacy,
        calculatedFireDepartmentCompaniesAdequacy: reports.CalculatedFireDepartmentCompaniesAdequacy,
        evaluatedClass: reports.EvaluatedClass
    };
  }

  static to(model: any, sectionEnum?: SectionEnum) {
    return {
      PublicProtectionClass: model.publicProtection?.publicProtectionClass ? model.publicProtection?.publicProtectionClass : null,
      TerritoryCode: model.publicProtection?.territoryCode ? model.publicProtection?.territoryCode : null,
      PpcFpa: model.publicProtection?.fireProtectionArea ? model.publicProtection?.fireProtectionArea  : null,
      //converting isHydrantsWithin1000feet to string as per DTO
      IsHydrantsWithin1000Feet: model.publicProtection?.isHydrantsWithin1000feet === true ? 'Y' : ( model.publicProtection?.isHydrantsWithin1000feet === false ? 'N' : null) , 
      NeededFireFlow: model.publicProtection?.neededFireFlow || model.publicProtection?.neededFireFlow?.length>0?Number(model.publicProtection.neededFireFlow):null,
      IsPublicProtectionClass: model.publicProtection?.isPublicProtectionClass,
      IsFireProtectionArea:model.publicProtection?.isFireProtectionArea,
      IsTerritoryCode:model.publicProtection?.isTerritoryCode,
      IsPpcFireHydrant:model.publicProtection?.isPpcFireHydrant,
      WaterSupplyWorksAdequacy :model.publicProtection?.waterSupplyWorksAdequacy || model.publicProtection?.waterSupplyWorksAdequacy === 0 ? Number(model.publicProtection?.waterSupplyWorksAdequacy) : null,
      FireDepartmentCompaniesAdequacy :model.publicProtection?.fireDepartmentCompaniesAdequacy || model.publicProtection?.fireDepartmentCompaniesAdequacy === 0 ? Number(model.publicProtection?.fireDepartmentCompaniesAdequacy) : null,
      HydrantSpacingAdequacy : model.publicProtection?.hydrantSpacingAdequacy || model.publicProtection?.hydrantSpacingAdequacy === 0 ? Number(model.publicProtection?.hydrantSpacingAdequacy) : null,
      OverallAdequacy : model.publicProtection?.overallAdequacy || model.publicProtection?.overallAdequacy === 0 ? Number(model.publicProtection?.overallAdequacy) : null,
      FireFlowAt20Psi : model.publicProtection?.fireFlowAt20Psi || model.publicProtection?.fireFlowAt20Psi === 0 ? Number(model.publicProtection?.fireFlowAt20Psi) : null,
      HydrantsWithPumperOutlet : model.publicProtection?.hydrantsWithPumperOutlet || model.publicProtection?.hydrantsWithPumperOutlet === 0 ? Number(model.publicProtection?.hydrantsWithPumperOutlet) : null,
      HydrantsWithOneHoseOutlets : model.publicProtection?.hydrantsWithOneHoseOutlets || model.publicProtection?.hydrantsWithOneHoseOutlets === 0 ? Number(model.publicProtection?.hydrantsWithOneHoseOutlets) : null,
      HydrantsWithTwoHoseOutlets : model.publicProtection?.hydrantsWithTwoHoseOutlets || model.publicProtection?.hydrantsWithTwoHoseOutlets === 0 ? Number(model.publicProtection?.hydrantsWithTwoHoseOutlets) : null,
      CalculatedWaterSupplyWorksAdequacy: model.publicProtection?.calculatedWaterSupplyWorksAdequacy ||  model.publicProtection?.calculatedWaterSupplyWorksAdequacy === 0 ? Number(model.publicProtection?.calculatedWaterSupplyWorksAdequacy) : null,
      CalculatedFireDepartmentCompaniesAdequacy: model.publicProtection?.calculatedFireDepartmentCompaniesAdequacy || model.publicProtection?.calculatedFireDepartmentCompaniesAdequacy === 0 ? Number(model.publicProtection?.calculatedFireDepartmentCompaniesAdequacy) : null,
      EvaluatedClass: model.publicProtection?.evaluatedClass ? model.publicProtection?.evaluatedClass : null
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
      return true;
}
}

