import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './auth/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },

  { path: 'callback', loadChildren: () => import('./auth/auth-callback/auth-callback.module').then(m => m.AuthCallbackPageModule) },
  { path: 'logout', loadChildren: () => import('./auth/end-session/end-session.module').then(m => m.EndSessionPageModule) },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  {
    path: 'home',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'maps',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/maps/maps.module').then((m) => m.MapsPageModule),
  },
  {
    path: 'my-orders',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/my-orders/my-orders.module').then((m) => m.MyOrdersPageModule),
  },
  {
    path: 'exterior-survey',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/bbs-orders/bbs-orders.module').then((m) => m.BbsOrdersPageModule),
  },
  {
    path: 'more',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/more/more.module').then((m) => m.MorePageModule),
  },
  {
    path: 'filter-modal',
    loadChildren: () => import('./pages/filter-modal/filter-modal.module').then((m) => m.FilterModalPageModule),
  },
  {
    path: 'search-filter-modal',
    loadChildren: () =>
      import('./pages/search-filter-modal/search-filter-modal.module').then((m) => m.SearchFilterModalPageModule),
  },
  {
    path: 'formly-builder',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/formly-builder/formly-builder.module').then((m) => m.FormlyBuilderPageModule),
  },
  {
    path: 'photo-details',
    loadChildren: () => import('./pages/my-orders/order-details/order-photo-management/photo-details/photo-details.module').then((m) => m.PhotoDetailsPageModule),
  },
  {
    path: 'attachment-model',
    loadChildren: () => import('./pages/attachment-model/attachment-model.module').then( m => m.AttachmentModelPageModule)
  },
  {
    path: 'no-access',
    loadChildren: () => import('./pages/no-access/no-access.module').then( m => m.NoAccessPageModule)
  },



];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
