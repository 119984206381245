/* eslint-disable @typescript-eslint/naming-convention */
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class ChargeablePanelsDataModel {
  static sectionName = 'areChargeablePanels';
  // Since the data model is already created and we have many refencence dont want to change in FRM and data model 
  chargeablePanels?: boolean; // HasWallsChargeablePanels Backend property name
  nonCombustible?: boolean;
  masonry?: boolean;
  glass?: boolean;
  nc?: boolean;
  percentInputI?: number;
  slowBurn?: boolean;
  percentInputII?: number;
  combustibleMcm?: boolean;
  percentInputIII?: number;
  combustible?: boolean;
  panelConstructionType?: string;


  static fromS3(reports: any): ChargeablePanelsDataModel {
    const percentGlassPanelArea = reports.PercentGlassPanelArea;
    const percentSlowBurnPanelArea = reports.PercentSlowBurnPanelArea;
    const percentCombustiblePanelArea = reports.PercentCombustiblePanelArea;
    // Now we are doing this inside service after this method execution
    // const wallpanelflag = reports.AreChargeablePanels ? reports.AreChargeablePanels : ( percentGlassPanelArea !== null && percentGlassPanelArea > 0 )|| ( percentSlowBurnPanelArea !== null && percentSlowBurnPanelArea > 0 ) || ( percentCombustiblePanelArea !== null && percentCombustiblePanelArea > 0 )
    const panelConstructionType = (reports.HasGlassPanels && reports.HasNCPanels) ? "C " : reports.HasGlassPanels ? "A " : reports.HasNCPanels ? "B " : reports.HasMasonryPanels ? "D " : "  ";
    return {
      chargeablePanels: reports.HasWallsChargeablePanels,
      slowBurn: percentSlowBurnPanelArea !== null ? (percentSlowBurnPanelArea > 0 ? true : false) : null,
      nonCombustible: percentGlassPanelArea !== null ? (percentGlassPanelArea > 0 ? true : false) : null,
      combustible: percentCombustiblePanelArea !== null ? (percentCombustiblePanelArea > 0 ? true : false) : null,
      nc: reports.HasNonCombustiblePanels ? reports.HasNonCombustiblePanels : false,
      glass: reports.HasGlassPanels ? reports.HasGlassPanels : false,
      masonry: percentGlassPanelArea !== null ? (percentGlassPanelArea > 0 && !(reports.HasGlassPanels == true || reports.HasNonCombustiblePanels == true)) ? true : false : false,
      // masonry: reports.HasMasonryPanels ? reports.HasMasonryPanels : false,
      percentInputII: reports.PercentSlowBurnPanelArea ? reports.PercentSlowBurnPanelArea : null,
      percentInputI: reports.PercentGlassPanelArea ? reports.PercentGlassPanelArea : null,
      percentInputIII: reports.PercentCombustiblePanelArea ? reports.PercentCombustiblePanelArea : null,
      panelConstructionType: panelConstructionType
    };
  }
  // make PercentGlassPanelArea null if PanelConstructionType is empty string or null or not a valid value
  static to(model: any, sectionEnum?: SectionEnum) {
    const panelConstructionType = (model.areChargeablePanels.glass && model.areChargeablePanels.nc) ? "C " : model.areChargeablePanels.glass ? "A " : model.areChargeablePanels.nc ? "B " : model.areChargeablePanels.masonry ? "D " : "  ";
    const ncFlag = model.areChargeablePanels.glass || model.areChargeablePanels.nc || model.areChargeablePanels.masonry;
    return {
      HasWallsChargeablePanels: model.areChargeablePanels.chargeablePanels,
      HasNonCombustiblePanels: model.areChargeablePanels.nc ? model.areChargeablePanels.nc : false,
      HasCombustiblePanels: model.areChargeablePanels.combustible ? model.areChargeablePanels.combustible : model.areChargeablePanels.percentInputIII > 0 ? true : false,
      HasSlowBurnPanels: model.areChargeablePanels.slowBurn ? model.areChargeablePanels.slowBurn : model.areChargeablePanels.percentInputII > 0 ? true : false,
      // HasNCPanels: model.areChargeablePanels.nc ? model.areChargeablePanels.nc : panelConstructionType == "D " ? false : null,
      HasGlassPanels: model.areChargeablePanels.glass ? model.areChargeablePanels.glass : panelConstructionType == "D " ? false : false,
      // HasMasonryPanels: model.areChargeablePanels.masonry ? model.areChargeablePanels.masonry : null,
      PercentSlowBurnPanelArea : model.areChargeablePanels.percentInputII ? model.areChargeablePanels.percentInputII : null,
      PercentGlassPanelArea: panelConstructionType === '  ' ? null : (model.areChargeablePanels.percentInputI ? model.areChargeablePanels.percentInputI : null),
      PercentCombustiblePanelArea : model.areChargeablePanels.percentInputIII ? model.areChargeablePanels.percentInputIII : null,
      PanelConstructionType: panelConstructionType ? panelConstructionType : '  ',

    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    return true;
  }
}