import { DataStore } from 'aws-amplify';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth-service/auth.service';
import { Network } from '@ionic-native/network/ngx';

@Injectable({
  providedIn: 'root',
})
export class AmplifySaveService {
  constructor(private veriskAuth: AuthService, private network: Network) {}
  async saveData(dataObj) {
    // When attempting to save Amplify data in offline mode, we encountered a token error.
    // As a solution, we now exclusively call 'await this.veriskAuth.updateTokens()' in online mode.
    if (this.network.type !== 'none') {
      await this.veriskAuth.updateTokens();
    }
    return await DataStore.save(dataObj);
  }
}
