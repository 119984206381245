/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { FormControl } from '@angular/forms';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { Constants } from 'src/app/services/util-service/constants';
import { maxLengthForNumber, numberCheck } from 'src/app/validators/formly-builder/validators';

export class FloorsAndRoofsConstructionByLevel extends FormlySectionModelAbstract {
  constructor() {
    super({
      key: 'constructionEntries',
      type: 'fr-construction-level-array',
      wrappers: [ 'simple-section-wrapper' ],
      resetOnHide: true,
      props: {
        label: 'Basic Construction By Level',
        addType: 'constructionByLevel',
        addText: 'Add',
        isCollapsable: true,
        isCollapsed: false,
        // isEmptySectionOnInit: true,
        actionButtons: [
          { action: 'add', label: Constants.btnText, isDisabled: false, section: 'floorsAndRoofFullRisk' }
        ],
      },
      expressions: {
        'props.actionButtons[0].action': (field: FormlyFieldConfig) => {
          if (field.form?.value?.constructionEntries?.floorsAndRoofsLevels?.length > 0) {
            field.props.actionButtons[ 0 ].label = 'Edit';
            field.props.isCollapseIconDisabled = false;
            return 'edit';
          } else {
            field.props.actionButtons[ 0 ].label = Constants.btnText;
            field.props.isCollapseIconDisabled = true; // disable the expand collapse arrow if no entries
            field.props.isCollapsed = true;
            return 'add';
          }
        },
        'props.isCollapsed': (field: FormlyFieldConfig) =>
          field.form?.value?.constructionEntries?.floorsAndRoofsLevels?.length ? false : true,
      },
      hooks: {
        onInit: (field) => {
          field.formControl.updateValueAndValidity();

          if (!field.form?.value?.constructionEntries?.floorsAndRoofsLevels?.length) {
            field.props.isEmptySectionOnInit = true;
          }
        }
      },
      fieldGroup: [
        {
          key: 'floorsAndRoofsLevels',
          type: 'fr-level-tiles',
          fieldArray: {
            fieldGroupClassName: 'formly-flex-container',
            wrappers: [ 'error-info-wrapper' ],
            fieldGroup: [
              {
                key: 'levelTypeCode',
                type: 'simple-select',
                className: 'select-width',
                props: {
                  label: 'Level Type',
                  required: true,
                  isAsteriskNotRequired: true,
                  isDisabled: false,
                  index: 0,
                  options: [
                    { value: 'ROOF', label: 'Roof' },
                    { value: 'FLOO', label: 'Floor' },
                    { value: 'ATTI', label: 'Attic' },
                    { value: 'MEZZ', label: 'Mezzanine' },
                    { value: 'BASE', label: 'Basement' },
                  ]
                },
                expressions: {
                  'props.index': 'field.parent.key',
                  'props.isDisabled': (field?: FormlyFieldConfig) => field.props.index === '0' ? true : false,
                  'props.className': (field?: FormlyFieldConfig) => field.props.index === '0' ? 'custom-disabled-background' : '',
                  hide: (field: FormlyFieldConfig) => field.form.get('isAreaCalc')?.value === true ? true : false
                },
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    field.hide = (field.form?.value?.isAreaCalc && !field.form?.value?.isLowestLevel) ? true : false;
                    field.props = {
                      ...field.props
                    };
                    field.formControl.valueChanges.subscribe(src => {
                      if (field.options.formState.service.isSheetActive) {
                        field.form.get('constructionType')?.setValue(null);
                        field.form.get('constructionTypeCode')?.setValue(null);
                        field.form.get('constructionMaterial')?.setValue(null);
                        field.form.get('levelStart')?.setValue(null);
                        field.form.get('levelStop')?.setValue(null);
                      }
                    });
                  }
                }
              },
              {
                key: 'areaCalcLevelTypeCode',
                type: 'simple-select',
                className: 'select-width',
                props: {
                  label: 'Level Type',
                  isDisabled: true,
                  className: 'custom-disabled-red-color',
                  options: [
                    { value: 'ROOF', label: 'Roof - ACA' },
                    { value: 'FLOO', label: 'Floor - ACA' }
                  ]
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.get('isAreaCalc')?.value === true ? false : true
                }
              },
              // {
              //   template: '<img style="width: 52px; height: 42px" src="../../../../assets/icon/floors-and-roofs-calculate.png">',
              //   className: 'image-margin'
              // },
              {
                type: 'rf-aerial',
                expressions: {
                  //  'props.areaKey': (field) => (field: FormlyFieldConfig) => field.form.get('isAreaCalc')?.value ? 'length' : 'areaCalcLength'
                  hide: (field: FormlyFieldConfig) => field.form?.get('isAreaCalc')?.value ? true : false
                }
              },
              {
                key: 'areaCalcLength',
                type: 'simple-input',
                className: 'input-width ft-label-input',
                props: {
                  label: 'Roof Area',
                  isDisabled: true,
                  readonly: true
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.get('isAreaCalc')?.value ? false : true
                }
              },
              {
                key: 'areaCalcWidth',
                type: 'simple-input',
                className: 'input-width ft-label-input',
                props: {
                  label: 'Floor Area',
                  isDisabled: true,
                  readonly: true
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.get('isAreaCalc')?.value ? false : true
                }
              },
              {
                key: 'areaCalcArea',
                type: 'text',
                className: 'text-area',
                props: {
                  label: 'Area',
                  readonly: true,
                  unit: 'sq.ft.',
                  prefix: '=',
                  isDisabled: true,
                  isCorrect: undefined,
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.get('isAreaCalc')?.value === true ? false : true,
                  'props.isCorrect': (field: FormlyFieldConfig) => field.form?.get('isAreaCalc')?.value ? false : undefined
                }
              },
              {
                key: 'length',
                type: 'simple-input',
                defaultValue: null,
                className: 'input-width ft-label-input max-z-index position-relative',
                props: {
                  label: 'Length',
                  type: 'number',
                  inputmode: 'tel',
                  min: 1,
                  maxLength: 8,
                  required: true,
                  isAsteriskNotRequired: true,
                  isNonBlockingValidation: false,
                  attributes: { tabIndex: '2' }
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.value?.isAreaCalc === true ? true : false,
                  'props.required': (field?: FormlyFieldConfig) => (!field.form?.value?.isAreaCalc && !field.form?.value?.isLowestLevel) ? true : false
                },
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    if (field.form?.get('isAreaCalc')?.value) {
                      field.hide = true;
                    } else {
                      field.hide = false;
                    }
                  }
                }
              },
              {
                key: 'width',
                type: 'simple-input',
                defaultValue: null,
                className: 'input-width ft-label-input max-z-index position-relative',
                props: {
                  label: 'Width',
                  type: 'number',
                  inputmode: 'tel',
                  required: true,
                  isAsteriskNotRequired: true,
                  min: 1,
                  maxLength: 8,
                  isNonBlockingValidation: false,
                  attributes: { tabIndex: '3' }
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.value?.isAreaCalc === true ? true : false,
                  'props.required': (field?: FormlyFieldConfig) => (!field.form?.value?.isAreaCalc && !field.form?.value?.isLowestLevel) ? true : false
                },
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    if (field.form?.get('isAreaCalc')?.value) {
                      field.hide = true;
                    } else {
                      field.hide = false;
                    }
                  }
                }
              },
              {
                key: 'area',
                type: 'text',
                className: 'text-area',
                props: {
                  label: 'Area',
                  required: false,
                  readonly: true,
                  unit: ' sq.ft.',
                  prefix: '=',
                  isCorrect: undefined,
                },
                expressions: {
                  'props.isCorrect': (field: FormlyFieldConfig) => !field.formControl.valid ? false : undefined,
                  hide: (field: FormlyFieldConfig) => field.form?.value?.isAreaCalc ? true : false
                },
                hooks: {
                  onInit: (field) => {
                    field.formControl.markAllAsTouched();
                    const formlyService = field.options.formState.service;
                    formlyService.formlyFloorsRoofService.areaCalculation(field);
                  }
                },
                validators: {
                  mezzAreaToFloorArea: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      if (field.form?.value?.isAreaCalc) {
                        return true;
                      }
                      if (field.form?.get('levelTypeCode')?.value === 'MEZZ') {
                        const floorLevels = field.parent.form?.value?.filter(src => src?.levelTypeCode === 'FLOO' && !src?.isLowestLevel);
                        floorLevels.sort((a, b) => Number(b?.levelStart) - Number(a?.levelStop ? a?.levelStop : a?.levelStart));
                        const mezzValues = field.form.value;
                        let floor;
                        if (mezzValues?.levelStop && Number(mezzValues?.levelStop) > 0) {
                          for (let level = Number(mezzValues?.levelStart); level <= Number(mezzValues?.levelStop); level++) {
                            floor = floorLevels.find(x => (x?.levelStart && x?.levelStart !== undefined ?
                              Number(x?.levelStart) <= level : true) &&
                              (x?.levelStop && x?.levelStop !== undefined ? level <= Number(x?.levelStop) : true));
                            if (floor && floor !== undefined && field.formControl?.value && floor?.area &&
                              field.formControl?.value > Number(floor?.area)) {
                              return false;
                            }
                          }
                        } else {
                          floor = floorLevels.find(x => (x?.levelStart && x?.levelStart !== undefined ?
                            Number(x?.levelStart) <= Number(field.form?.get('levelStart')?.value) : true) &&
                            (x?.levelStop && x?.levelStop !== undefined ? Number(field.form?.get('levelStart')?.value) <= Number(x?.levelStop) : true));
                          if (Number(field.form.get('levelStart')?.value) === 1) {
                            if (floor && floor !== undefined && floor?.levelStart === 1 && Number(field.formControl?.value) > Number(floor?.area)) {
                              if (Number(field.formControl?.value) <= Number(field.parent.parent.form?.get('levelOnGradeArea')?.value)) {
                                return true;
                              }
                              return false;
                            }
                          } else if (floor && floor !== undefined && field.formControl?.value && floor?.area &&
                            field.formControl?.value > Number(floor?.area)) {
                            return false;
                          }
                          return true;
                        }
                        if (floorLevels && floorLevels.find(x => x?.levelStart && +x?.levelStart === 1) === undefined &&
                          field.formControl?.value) {
                          if (field.parent.parent.form?.get('levelOnGradeArea')?.value &&
                            (field.formControl?.value > Number(field.parent.parent.form?.get('levelOnGradeArea')?.value))) {
                            return false;
                          }
                        }
                      }
                      return true;
                    },
                    message: 'Mezzanine area cannot be greater than floor area',
                  },
                  mezzanineFloorArea: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      if (field.form.value?.isAreaCalc) {
                        return true;
                      }
                      if (field.form.get('levelTypeCode')?.value === 'MEZZ') {
                        const floorLevels = field.parent.form?.value?.filter(src => src?.levelTypeCode === 'FLOO' && !src?.isLowestLevel);
                        floorLevels.sort((a, b) => Number(b?.levelStart) - Number(a?.levelStop ? a?.levelStop : a?.levelStart));
                        const floor = floorLevels.find(x => (x?.levelStart && x?.levelStart !== undefined ?
                          Number(x?.levelStart) <= Number(field.form.get('levelStart')?.value) : true) &&
                          (x?.levelStop && x?.levelStop !== undefined ? Number(field.form.get('levelStart')?.value) <= Number(x?.levelStop) : true));
                        if (floor && floor !== undefined && floor?.area && field.formControl?.value &&
                          field.formControl?.value < (Number(floor?.area) * 0.25)) {
                          return false;
                        }
                        if (field.form?.get('levelStart')?.value && Number(field.form?.get('levelStart')?.value) === 1 &&
                          floorLevels && floorLevels.find(x => x && +x === 1) === undefined && field.formControl?.value) {
                          if (field.parent.parent.form?.get('levelOnGradeArea')?.value &&
                            (field.formControl?.value < (Number(field.parent.parent.form?.get('levelOnGradeArea')?.value) * 0.25))) {
                            return false;
                          }
                        }
                      }
                      return true;
                    },
                    message: 'Mezzanines must be 25% or greater than the level they are located on',
                  }
                }
              },
              { template: '<span></span>', className: 'new-row field-padding-bottom-10' },
              {
                key: 'levelStart',
                type: 'simple-input',
                className: 'input-width max-z-index position-relative',
                resetOnHide: true,
                props: {
                  label: 'Level Start',
                  required: true,
                  type: 'number',
                  inputmode: 'tel',
                  min: 1,
                  maxLength: 8,
                  readonly: true,
                  isAsteriskNotRequired: true
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => (field.form?.get('levelTypeCode')?.value === 'ROOF' ||
                    field.form?.get('areaCalcLevelTypeCode')?.value === 'ROOF') ? true : false,
                  'props.readonly': (field?: FormlyFieldConfig) => (!field.form?.value?.levelTypeCode || field.form?.value?.isAreaCalc) ? true : false,
                  'props.required': (field?: FormlyFieldConfig) => (!field.form?.value?.isLowestLevel && !field.form?.value?.isAreaCalc) ? true : false,
                  'props.min': (field?: FormlyFieldConfig) => field.form?.value?.isAreaCalc ? 0 : 1
                },
                hooks: {
                  onInit: (field?: FormlyFieldConfig) => {
                    const formlyService = field.options.formState.service;
                    // field.formControl.valueChanges.pipe(distinctUntilChanged()).subscribe(() => field.form.get('levelStop')?.updateValueAndValidity());
                    formlyService.constructionByLevelService.validateArea(field);
                  }
                },
                validators: {
                  lessThan: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      if (field.form?.value?.isAreaCalc) {
                        return true;
                      }
                      if (field.form?.get('levelStop')?.value && +field.form?.get('levelStop')?.value > 0) {
                        return !(+field.formControl?.value >= +field.form?.get('levelStop')?.value);
                      }
                      return true;
                    },
                    message: 'Level Start must be less than Level Stop',
                  },
                  mezzanineLevelValidator: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      if (field.form?.value?.isAreaCalc) {
                        return true;
                      }
                      if (field.form?.get('levelTypeCode')?.value === 'MEZZ' && field.parent.form?.value &&
                        field.parent.form?.value?.length > 2 && control?.value && +control?.value > 0) {
                        const formlyService = field.options.formState.service;
                        let aboveGradeFloors = formlyService.formlyFloorsRoofService.getMaxLevel(field?.parent?.form?.value, 'FLOO');
                        aboveGradeFloors = aboveGradeFloors > 0 ? aboveGradeFloors : 1;

                        if (+control?.value > aboveGradeFloors) {
                          return false;
                        }
                      }
                      return true;
                    },
                    message: 'Mezzanine Level Number cannot be greater than any Floor levels'
                  }
                }
              },
              {
                key: 'levelStop',
                type: 'simple-input',
                resetOnHide: true,
                className: 'input-width max-z-index position-relative',
                props: {
                  label: 'Level Stop',
                  type: 'number',
                  inputmode: 'tel',
                  required: false,
                  maxLength: 8,
                  readonly: true
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => (field.form?.get('levelTypeCode')?.value === 'ROOF' ||
                    field.form?.get('areaCalcLevelTypeCode')?.value === 'ROOF') ? true : false,
                  'props.readonly': (field?: FormlyFieldConfig) => (field.form?.value?.isAreaCalc ||
                    !(field.form?.value?.levelTypeCode && field.form?.value?.levelStart)) ? true : false
                },
                hooks: {
                  onInit: (field?: FormlyFieldConfig) => {
                    const formlyService = field.options.formState.service;
                    field.formControl?.valueChanges.pipe(distinctUntilChanged())?.subscribe(() => field.form?.get('levelStart')?.updateValueAndValidity());
                    formlyService.constructionByLevelService.validateArea(field);
                  }
                },
                validators: {
                  validation: [
                    {
                      name: 'cross-field-comparison-validator',
                      options: {
                        compareWith: 'levelStart',
                        msg: 'Level Stop cannot be equal to or less than Level Start',
                        condition: 'lessThan'
                      },
                    },
                    {
                      name: 'cross-field-comparison-validator',
                      options: {
                        compareWith: 'levelStart',
                        msg: 'Level Stop cannot be equal to or less than Level Start',
                        condition: 'equalsTo'
                      },
                    },
                    {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        if (field.form?.get('levelTypeCode')?.value === 'MEZZ' && field.formControl?.value) {
                          const formlyService = field.options.formState.service;
                          let aboveGradeFloors = formlyService.formlyFloorsRoofService.getMaxLevel(field.parent.form?.value, 'FLOO');
                          aboveGradeFloors = aboveGradeFloors > 0 ? aboveGradeFloors : 1;
                          if (+control.value > aboveGradeFloors) {
                            return false;
                          }
                        }
                        return true;
                      },
                      message: 'Mezzanine Level Number cannot be greater than any Floor levels'
                    }
                  ]
                },
              },
              {
                template: '<span></span>', className: 'new-row field-padding-bottom-10',
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.get('levelTypeCode')?.value === 'ROOF' ||
                    field.form?.get('areaCalcLevelTypeCode')?.value === 'ROOF' ? true : false
                }
              },
              { key: 'constructionType' },
              {
                key: 'constructionTypeCode',
                type: 'simple-select',
                className: 'select-width-md',
                props: {
                  label: 'Construction Type',
                  required: true,
                  isAsteriskNotRequired: true,
                  isDisabled: true,
                  options: [
                    { value: 'FIRE', label: 'Fire Resistive' },
                    { value: 'NCSB', label: 'Non-Combustible / Slow Burn' },
                    { value: 'COMB', label: 'Combustible' },
                    // { value: 'MSTB', label: 'Mass Timber' }
                  ]
                },
                expressions: {
                  'props.isDisabled': (field?: FormlyFieldConfig) => (!field.form?.value?.levelTypeCode || field.form?.value?.isAreaCalc) ? true : false,
                  'props.required': (field?: FormlyFieldConfig) => (!field.form?.value?.isLowestLevel && !field.form?.value?.isAreaCalc) ? true : false
                },
                hooks: {
                  onInit: (field: FieldTypeConfig) => {
                    // field.props = {
                    //   ...field.props
                    // };
                    field.formControl?.valueChanges?.subscribe(src => {
                      let consType;
                      if (src && src.length > 0 && field.options.formState.service?.isSheetActive) {
                        switch (src) {
                          case 'M':
                            consType = 'Masonry';
                            break;
                          case 'COMB':
                            consType = 'Combustible';
                            break;
                          case 'NCSB':
                            consType = 'Non-Combustible/Limited Combustible';
                            break;
                          case 'FIRE':
                            consType = 'Fire Resistive';
                            break;
                          default:
                            consType = src;
                        }
                        field.form?.get('constructionType')?.setValue(consType);
                      }
                    });
                  }
                }
              },
              {
                key: 'constructionMaterial',
                type: 'simple-select',
                className: 'select-width-md',
                props: {
                  label: 'Construction Preset',
                  required: false,
                  isAsteriskNotRequired: true,
                  options: []
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form.get('constructionType')?.value === 'MSTB' ? true : false,
                  'props.isDisabled': (field?: FormlyFieldConfig) => (!field.form?.value?.constructionType || field.form?.value?.isAreaCalc) ? true : false,
                  'props.required': (field?: FormlyFieldConfig) => (!field.form?.value?.isLowestLevel && !field.form?.value?.isAreaCalc && field.form?.value?.constructionType) ? true : false
                },
                hooks: {
                  onInit: (field) => {
                    const formlyService = field.options.formState.service;
                    formlyService.constructionByLevelService.constructionPresetInitalization(field);
                    formlyService.constructionByLevelService.setPresetForExistingRisk(field);
                    formlyService.constructionByLevelService.onConstructionPresetChange(field);
                  }
                }
              },
              { template: '<span></span>', className: 'new-row field-padding-bottom-10' },
              // Fire Resistive
              {
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.get('constructionTypeCode')?.value === 'FIRE' ? false : true
                },
                className: 'field-padding-bottom-0',
                fieldGroupClassName: 'formly-flex-container',
                fieldGroup: [
                  {
                    key: 'damageability',
                    type: 'simple-select',
                    className: 'select-width-sm',
                    resetOnHide: true,
                    props: {
                      label: 'Damageability',
                      required: true,
                      isAsteriskNotRequired: true,
                      isDisabled: false,
                      options: [
                        { value: 1, label: 'D1' },
                        { value: 2, label: 'D2' },
                        { value: 3, label: 'D3' },
                        { value: 4, label: 'D4' }
                      ]
                    },
                    expressions: {
                      'props.isDisabled': (field?: FormlyFieldConfig) => (field.form?.value?.isAreaCalc ||
                        field.form?.value?.constructionMaterial !== 'Other Fire Resistive Assemblies') ? true : false,
                      'props.required': (field?: FormlyFieldConfig) => (!field.form?.value?.isLowestLevel && !field.form?.value?.isAreaCalc &&
                        field.form?.value?.constructionMaterial) ? true : false
                    }
                  },
                  {
                    key: 'hourlyRating',
                    type: 'simple-select',
                    className: 'select-width-sm',
                    resetOnHide: true,
                    props: {
                      label: 'Hourly Rating',
                      required: true,
                      isAsteriskNotRequired: true,
                      options: [
                        { value: 1, label: '1 hr' },
                        { value: 2, label: '2 hrs' },
                        { value: 3, label: '3 hrs' },
                        { value: 4, label: '4 hrs' }
                      ]
                    },
                    expressions: {
                      'props.isDisabled': (field?: FormlyFieldConfig) => {
                        const formlyService = field.options.formState.service;
                        if (field.form?.value?.constructionMaterial && field.form?.value?.constructionMaterial?.length > 0 &&
                          !field.form?.value?.isAreaCalc) {
                          if (formlyService.constructionByLevelService.fireResistivePresetValues
                            .find(src => src.constructionPreset === field.form?.value?.constructionMaterial)?.hourlyRating === null) {
                            return false;
                          }
                        }
                        return true;
                      },
                      'props.required': (field?: FormlyFieldConfig) => (!field.form?.value?.isLowestLevel && !field.form?.value?.isAreaCalc && field.form?.value?.constructionMaterial) ? true : false
                    }
                  },
                  {
                    key: 'fireResistanceListing',
                    type: 'simple-select',
                    resetOnHide: true,
                    className: 'select-width-md',
                    props: {
                      label: 'Listing',
                      required: true,
                      isAsteriskNotRequired: true,
                      options: [
                        { value: 'RECO', label: 'Recognized' },
                        { value: 'UNRE', label: 'Unrecognized' },
                        { value: 'MASO', label: 'Masonry' }
                      ]
                    },
                    expressions: {
                      hide: (field: FormlyFieldConfig) => (field.form?.value?.damageability === undefined ||
                        field.form?.value?.damageability === null || field.form?.value?.damageability === '1' ||
                        field.form?.value?.damageability === 1) ? true : false,
                      'props.isDisabled': (field?: FormlyFieldConfig) => {
                        const formlyService = field.options.formState.service;
                        if (field.form?.value?.constructionMaterial && field.form?.value?.constructionMaterial?.length > 0 &&
                          !field.form?.value?.isAreaCalc) {
                          if (formlyService.constructionByLevelService.fireResistivePresetValues
                            .find(src => src.constructionPreset === field.form?.value?.constructionMaterial)?.fireResistanceListing === null) {
                            return false;
                          }
                        }
                        return true;
                      },
                      'props.required': (field?: FormlyFieldConfig) => (!field.form?.value?.isLowestLevel && !field.form?.value?.isAreaCalc && field.form?.value?.constructionMaterial) ? true : false
                    }
                  },
                  { template: '<span></span>', className: 'new-row field-padding-bottom-10' },
                  {
                    key: 'bgiitype',
                    type: 'simple-select',
                    resetOnHide: true,
                    className: 'select-width-md',
                    props: {
                      label: 'BGII Type',
                      required: true,
                      isAsteriskNotRequired: true,
                      options: [
                        { value: 'STEE', label: 'Steel' },
                        { value: 'MASO', label: 'Masonry' }
                      ]
                    },
                    expressions: {
                      'props.isDisabled': (field?: FormlyFieldConfig) => (field.form?.value?.isAreaCalc ||
                        field.form?.value?.constructionMaterial !== 'Other Fire Resistive Assemblies') ? true : false,
                      'props.required': (field?: FormlyFieldConfig) => (!field.form?.value?.isLowestLevel &&
                        !field.form?.value?.isAreaCalc && field.form?.value?.constructionMaterial) ? true : false
                    }
                  },
                  {
                    key: 'isVerticalOpeningProtection',
                    type: 'rf-yes-no',
                    resetOnHide: true,
                    defaultValue: null,
                    className: 'vertical-opening-slider max-z-index position-relative',
                    props: {
                      label: 'Vertical Openings Protected',
                      required: true,
                      attributes: { tabIndex: '1' }
                      // disabled: true
                    },
                    expressions: {
                      hide: (field: FormlyFieldConfig) => field.form.get('levelTypeCode')?.value === 'ROOF' ||
                        field.form.get('areaCalcLevelTypeCode')?.value === 'ROOF' ? true : false,
                      // 'props.disabled': (field?: FormlyFieldConfig) => (field.options.formState.service.isExistingRisk() &&
                      //   field.form?.value?.constructionMaterial !== 'Other Fire Resistive Assemblies') ||
                      //   field.form.value?.isAreaCalc ? true : false,
                      'props.required': (field?: FormlyFieldConfig) => (!field.form?.value?.isLowestLevel && !field.form?.value?.isAreaCalc) ? true : false
                    }
                  },
                  {
                    template: '<span></span>', className: 'new-row field-padding-bottom-10',
                    expressions: {
                      hide: (field: FormlyFieldConfig) => field.form?.get('levelTypeCode')?.value === 'ROOF' ||
                        field.form?.get('areaCalcLevelTypeCode')?.value === 'ROOF' ? true : false
                    }
                  },
                  {
                    key: 'hasDividingWall',
                    type: 'custom-checkbox',
                    resetOnHide: true,
                    props: {
                      label: 'Division Wall v1',
                      // disabled: true
                    },
                    expressions: {
                      hide: (field: FormlyFieldConfig) => field.form?.get('levelTypeCode')?.value === 'ROOF' ||
                        field.form?.get('areaCalcLevelTypeCode')?.value === 'ROOF' ? true : false,
                      'props.disabled': (field?: FormlyFieldConfig) => field.form?.value?.isAreaCalc ? true : false,
                    }
                  },
                  {
                    key: 'isDivisionWallV2',
                    type: 'custom-checkbox',
                    hide: true,
                    resetOnHide: true,
                    props: {
                      label: 'Division Wall v2',
                      // disabled: true
                    },
                    expressions: {
                      // hide: "true",
                      // TODO: enable V2 here if needed
                      // hide: (field: FormlyFieldConfig) => !field?.options?.formState?.service._isV2,
                      'props.disabled': (field?: FormlyFieldConfig) => field.form?.value?.isAreaCalc ? true : false,
                    }
                  }
                ]
              },
              // Non-Combustible/Limited Combustible
              {
                expressions: {
                  hide: (field: FormlyFieldConfig) =>
                    field.form?.get('constructionTypeCode')?.value === 'NCSB' ? false : true
                },
                className: 'field-padding-bottom-0',
                fieldGroupClassName: 'formly-flex-container',
                fieldGroup: [
                  {
                    key: 'deckConstruction',
                    type: 'simple-select',
                    className: 'select-width-md',
                    resetOnHide: true,
                    props: {
                      label: 'Deck Construction',
                      required: true,
                      isAsteriskNotRequired: true,
                      isDisabled: false,
                      options: []
                    },
                    expressions: {
                      'props.isDisabled': (field?: FormlyFieldConfig) => field.form?.value?.isAreaCalc ||
                        field.form?.value?.constructionMaterial !== 'Other Non-Combustible or Slow Burn Assemblies' ? true : false,
                      'props.required': (field?: FormlyFieldConfig) => (!field.form?.value?.isLowestLevel && !field.form?.value?.isAreaCalc &&
                        field.form?.value?.constructionMaterial) ? true : false
                    },
                    hooks: {
                      onInit: (field?: FormlyFieldConfig) => {
                        const formlyService = field.options.formState.service;
                        formlyService.constructionByLevelService.getDeckConstructionList(field);
                      }
                    }
                  },
                  {
                    key: 'supportType',
                    type: 'simple-select',
                    className: 'select-width-md',
                    resetOnHide: true,
                    props: {
                      label: 'Supports',
                      required: true,
                      isAsteriskNotRequired: true,
                      isDisabled: true,
                      options: []
                    },
                    expressions: {
                      'props.isDisabled': (field?: FormlyFieldConfig) => {
                        const formlyService = field.options.formState.service;
                        if (field.form?.value?.constructionMaterial && field.form?.value?.constructionMaterial?.length > 0 &&
                          !field.form?.value?.isAreaCalc) {
                          if (formlyService.constructionByLevelService.nonCombustiblePresetValues
                            .find(src => src.constructionPreset === field.form?.value?.constructionMaterial)?.supportType === null &&
                            field.form?.value?.deckConstruction && field.form?.value?.deckConstruction.length > 0) {
                            return false;
                          }
                        }
                        return true;
                      },
                      'props.required': (field?: FormlyFieldConfig) => (!field.form?.value?.isLowestLevel && !field.form?.value?.isAreaCalc && field.form?.value?.constructionMaterial) ? true : false
                    },
                    hooks: {
                      onInit: (field?: FormlyFieldConfig) => {
                        const formlyService = field.options.formState.service;
                        formlyService.constructionByLevelService.getSupportTypeList(field);
                      }
                    }
                  },
                  {
                    key: 'isFinishSmokeDevelopmentAbove200',
                    type: 'custom-checkbox',
                    className: 'sd-checkbox-style',
                    resetOnHide: true,
                    props: {
                      label: '>200 SD',
                      // disabled: true
                    },
                    expressions: {
                      'props.disabled': (field?: FormlyFieldConfig) => field.form?.value?.isAreaCalc ? true : false,
                    }
                  },
                  { template: '<span></span>', className: 'new-row field-padding-bottom-10' },
                  {
                    key: 'masonryThickness',
                    type: 'simple-input',
                    defaultValue: null,
                    resetOnHide: true,
                    className: 'input-width max-z-index position-relative',
                    props: {
                      label: 'Thickness',
                      type: 'number',
                      inputmode: 'tel',
                      required: true,
                      isAsteriskNotRequired: true,
                      minNumeric: 2,
                      maxLength: 8,
                      isNonBlockingValidation: false
                    },
                    expressions: {
                      hide: (field: FieldTypeConfig) => field.form?.value?.deckConstruction === 'COMA' ? false : true,
                      'props.required': (field?: FormlyFieldConfig) => (!field.form?.value?.isLowestLevel && !field.form?.value?.isAreaCalc) ? true : false,
                      'props.isDisabled': (field?: FormlyFieldConfig) => field.form?.value?.isAreaCalc ? true : false,
                    },
                    validators: {
                      validation: [
                        {
                          name: 'numeric-min-max-validator',
                          options: {
                            msg: 'Must be greater than 1, otherwise class as Built Up'
                          },
                        },
                      ]
                    },
                  },
                  { template: '<span></span>', className: 'new-row' },
                  {
                    key: 'isCombustibleInsulation',
                    type: 'custom-checkbox',
                    className: 'select-width-md',
                    resetOnHide: true,
                    props: {
                      label: 'Combustible Insulation',
                      // disabled: true
                    },
                    expressions: {
                      hide: (field: FieldTypeConfig) => (field.form?.value?.deckConstruction === 'BUSB' ||
                        field.form?.value?.deckConstruction === 'BUNC') ? false : true,
                      'props.disabled': (field?: FormlyFieldConfig) => field.form?.value?.isAreaCalc ? true : false,
                    }
                  },
                  {
                    key: 'isFireClassified',
                    type: 'custom-checkbox',
                    className: 'select-width-md',
                    resetOnHide: true,
                    props: {
                      label: 'Fire Classified',
                      // disabled: true
                    },
                    expressions: {
                      hide: (field: FieldTypeConfig) => !field.form?.value?.isCombustibleInsulation ? true : false,
                      'props.disabled': (field?: FormlyFieldConfig) => field.form?.value?.isAreaCalc ? true : false,
                    }
                  }
                ]
              },
              // Combustible
              {
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.get('constructionTypeCode')?.value === 'COMB' ? false : true
                },
                className: 'full-width padding-right-0',
                fieldGroupClassName: 'formly-flex-container',
                fieldGroup: [
                  { key: 'isWoodIronClad' },
                  { key: 'isHeavyTimberColumns' },
                  {
                    key: 'combustibleType',
                    type: 'simple-select',
                    className: 'select-width-md',
                    resetOnHide: true,
                    props: {
                      label: 'Combustible Type',
                      required: false,
                      isAsteriskNotRequired: true,
                      isDisabled: false,
                      options: [
                        { value: 'COMB', label: 'Combustible' },
                        { value: 'HOUR', label: '1 Hr Rated Combustible' },
                        { value: 'Wood Iron Clad', label: 'Wood Iron Clad' },
                        { value: 'Heavy Timber', label: 'Heavy Timber' }
                      ]
                    },
                    expressions: {
                      'props.isDisabled': (field?: FormlyFieldConfig) => (field.form?.value?.isAreaCalc ||
                        field.form?.value?.constructionMaterial !== 'Other Combustible Assemblies') ? true : false,
                    },
                    hooks: {
                      onInit: (field?: FormlyFieldConfig) => {
                        field.formControl?.valueChanges.subscribe(src => {
                          if (src === 'Wood Iron Clad') {
                            field.form?.get('isWoodIronClad')?.setValue(true);
                            field.form?.get('isHeavyTimberColumns')?.setValue(false);
                          } else {
                            field.form?.get('isWoodIronClad')?.setValue(false);
                          }
                          if (src === 'Heavy Timber') {
                            field.form?.get('isHeavyTimberColumns')?.setValue(true);
                            field.form?.get('isWoodIronClad')?.setValue(false);
                          } else {
                            field.form?.get('isHeavyTimberColumns')?.setValue(false);
                          }
                          if (src === 'HOUR' || src === 'Heavy Timber') {
                            field.form?.get('hasFoamPlasticInsulation')?.setValue(false);
                          }
                        });
                      }
                    }
                  },
                  {
                    key: 'isExtraHeavyTimberColumns',
                    type: 'custom-checkbox',
                    className: 'sd-checkbox-style',
                    resetOnHide: true,
                    props: {
                      label: 'Heavy Timber Columns (Note 2)',
                      disabled: true
                      // disabled: true
                    },
                    expressions: {
                      'props.disabled': (field?: FormlyFieldConfig) => {
                        const formlyService = field.options.formState.service;
                        if (field.form?.value?.constructionMaterial && field.form?.value?.constructionMaterial?.length > 0 &&
                          !field.form?.value?.isAreaCalc) {
                          if (formlyService.constructionByLevelService.combustiblePresetValues
                            .find(src => src.constructionPreset === field.form?.value?.constructionMaterial)?.isExtraHeavyTimberColumns === null) {
                            return false;
                          }
                        }
                        return true;
                      }
                    }
                  },
                  { template: '<span></span>', className: 'new-row' },
                  {
                    className: 'full-width max-z-index padding-right-0',
                    fieldGroupClassName: 'nccard-view margin-top',
                    fieldGroup: [
                      {
                        key: 'hasFoamPlasticInsulation',
                        type: 'custom-checkbox',
                        className: 'mcm-styles max-z-index position-relative',
                        resetOnHide: true,
                        props: {
                          label: 'Foam Plastic Insulation',
                          required: false,
                          isAsteriskNotRequired: true,
                          disabled: false
                        },
                        expressions: {
                          'props.disabled': (field: FormlyFieldConfig) => {
                            const formlyService = field.options.formState.service;
                            if (field.form.value?.isAreaCalc) {
                              return true;
                            }
                            const constructionDetails = formlyService.constructionByLevelService.combustiblePresetValues
                              .find(src => src.constructionPreset === field.form?.value?.constructionMaterial);
                            if (field.form?.value?.constructionMaterial === undefined || field.form?.value?.constructionMaterial === null ||
                              constructionDetails?.hasFoamPlasticInsulation === true || constructionDetails?.hasFoamPlasticInsulation === 'N/A' ||
                              (field.form?.value?.combustibleType === 'HOUR' || field.form?.value?.combustibleType === 'Heavy Timber')) {
                              return true;
                            }
                            return false;
                          }
                        },
                        hooks: {
                          onInit: (field?: FormlyFieldConfig) => {
                            field.formControl.valueChanges.subscribe(src => {
                              if (!src) {
                                field.form?.get('insulationFlameSpread')?.setValue(null);
                                field.form?.get('insulationSmokeDevelopment')?.setValue(null);
                                field.form?.get('isInsulationUnlisted')?.setValue(false);
                                field.form?.get('hasAcceptableThermalBarrier')?.setValue(false);
                              }
                            });
                          }
                        }
                      },
                      {
                        fieldGroupClassName: 'nccard-child-view',
                        className: 'padding-right-0',
                        expressions: {
                          hide: (field: FormlyFieldConfig) => field.form?.get('hasFoamPlasticInsulation')?.value ? false : true
                        },
                        fieldGroup: [
                          {
                            key: 'insulationFlameSpread',
                            type: 'simple-input',
                            defaultValue: null,
                            resetOnHide: true,
                            className: 'ncFlameSpread max-z-index position-relative',
                            props: {
                              label: 'Flame Spread',
                              type: 'number',
                              disabled: false,
                              required: true,
                              isAsteriskNotRequired: true
                            },
                            expressions: {
                              'props.disabled': (field: FormlyFieldConfig) => {
                                if (field.form?.value?.isAreaCalc) {
                                  return true;
                                }
                                if (field.form?.value?.isInsulationUnlisted) {
                                  field.formControl?.setValue(null);
                                  return true;
                                }
                                return false;
                              },
                              'props.required': (field: FormlyFieldConfig) => !field.form?.value?.isAreaCalc ? true : false
                            }
                          },
                          {
                            key: 'insulationSmokeDevelopment',
                            type: 'simple-input',
                            defaultValue: null,
                            resetOnHide: true,
                            className: 'ncSmokeDev max-z-index position-relative',
                            props: {
                              label: 'Smoke Dev',
                              type: 'number',
                              disabled: false,
                              required: true,
                              isAsteriskNotRequired: true
                            },
                            expressions: {
                              'props.disabled': (field: FormlyFieldConfig) => {
                                if (field.form?.value?.isAreaCalc) {
                                  return true;
                                }
                                if (field.form?.value?.isInsulationUnlisted) {
                                  field.formControl?.setValue(null);
                                  return true;
                                }
                                return false;
                              },
                              'props.required': (field: FormlyFieldConfig) => !field.form?.value?.isAreaCalc ? true : false
                            }
                          },
                          { template: '<span>or</span>', className: 'text-message' },
                          {
                            key: 'isInsulationUnlisted',
                            type: 'custom-checkbox',
                            resetOnHide: true,
                            className: 'insulationCheckbox',
                            props: {
                              label: 'Unlisted',
                              disabled: false
                            },
                            expressions: {
                              'props.disabled': (field: FormlyFieldConfig) => {
                                if (field.form.value?.isAreaCalc) {
                                  return true;
                                }
                                if ((field.form?.value?.insulationSmokeDevelopment &&
                                  field.form?.value?.insulationSmokeDevelopment !== undefined &&
                                  field.form?.value?.insulationSmokeDevelopment?.length > 0) &&
                                  (field.form?.value?.insulationFlameSpread &&
                                    field.form?.value?.insulationFlameSpread !== undefined &&
                                    field.form?.value?.insulationFlameSpread?.length > 0)) {
                                  field.formControl?.setValue(false);
                                  return true;
                                }
                                return false;
                              },
                            }
                          },
                          {
                            key: 'hasAcceptableThermalBarrier',
                            type: 'custom-checkbox',
                            resetOnHide: true,
                            className: 'insulationCheckbox padding-right-0',
                            props: {
                              label: 'Thermal barrier',
                            },
                            expressions: {
                              'props.disabled': (field?: FormlyFieldConfig) => field.form?.value?.isAreaCalc ? true : false
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    className: 'full-width max-z-index padding-right-0',
                    hide: true,
                    fieldGroupClassName: 'nccard-view',
                    expressions: {
                      hide: (field: FormlyFieldConfig) => !field?.options?.formState?.service?._isV2 ? true : false
                    },
                    fieldGroup: [
                      {
                        key: 'hasMetalCompositeMaterial',
                        type: 'custom-checkbox',
                        className: 'mcm-styles max-z-index position-relative',
                        props: {
                          label: 'Metal Composite Materials (MCM)',
                        },
                        expressions: {
                          'props.disabled': (field?: FormlyFieldConfig) => field.form?.value?.isAreaCalc ? true : false
                        }
                      },
                    ]
                  },
                  {
                    className: 'full-width max-z-index padding-right-0',
                    fieldGroupClassName: 'nccard-view margin-bottom',
                    fieldGroup: [
                      {
                        key: 'hasFireRetardantPaint',
                        type: 'custom-checkbox',
                        className: 'mcm-styles max-z-index position-relative',
                        resetOnHide: true,
                        props: {
                          label: 'Fire Retardant Paint',
                        },
                        expressions: {
                          'props.disabled': (field: FormlyFieldConfig) => (field.form?.value?.isAreaCalc || field.form?.value?.constructionMaterial === undefined ||
                            field.form?.value?.constructionMaterial === null) ? true : false
                        },
                      },
                      {
                        fieldGroupClassName: 'nccard-child-view',
                        expressions: {
                          hide: (field: FormlyFieldConfig) => field.form?.get('hasFireRetardantPaint')?.value ? false : true
                        },
                        fieldGroup: [
                          {
                            key: 'paintFlameSpread',
                            type: 'simple-input',
                            defaultValue: null,
                            resetOnHide: true,
                            className: 'ncFlameSpread max-z-index position-relative',
                            props: {
                              label: 'Flame Spread',
                              type: 'number',
                              required: true,
                              isAsteriskNotRequired: true
                            },
                            expressions: {
                              'props.required': (field: FormlyFieldConfig) => (!field.form?.value?.isLowestLevel &&
                                !field.form?.value?.isAreaCalc && field.form?.value?.hasFireRetardantPaint) ? true : false,
                              'props.isDisabled': (field?: FormlyFieldConfig) => field.form?.value?.isAreaCalc ? true : false
                            }
                          },
                          {
                            key: 'paintSmokeDevelopment',
                            type: 'simple-input',
                            defaultValue: null,
                            resetOnHide: true,
                            className: 'ncSmokeDev max-z-index position-relative',
                            props: {
                              label: 'Smoke Dev',
                              type: 'number',
                              required: true,
                              isAsteriskNotRequired: true
                            },
                            expressions: {
                              'props.required': (field: FormlyFieldConfig) => !field.form?.value?.isLowestLevel &&
                                !field.form?.value?.isAreaCalc && field.form?.value?.hasFireRetardantPaint ? true : false,
                              'props.isDisabled': (field?: FormlyFieldConfig) => field.form?.value?.isAreaCalc ? true : false
                            }
                          },
                        ]
                      }
                    ]
                  }

                ]
              },
              // Level Type = Roof
              { template: '<span></span>', className: 'new-row field-padding-bottom-10' },
              {
                key: 'yearOfRoofCover',
                type: 'simple-input',
                defaultValue: null,
                className: 'input-width',
                resetOnHide: true,
                props: {
                  label: 'Roof Cover Age',
                  type: 'number',
                  inputmode: 'tel',
                  required: true,
                  isAsteriskNotRequired: true,
                  min: 1000,
                  maxLength: 4,
                  isNonBlockingValidation: false,
                  keypress: (field, event) => {
                    const charCode = (event.which) ? event.which : event.keyCode;
                    if (maxLengthForNumber(event.target.value, field.props.maxLength)) {
                      event.preventDefault();
                    }
                    return numberCheck(charCode);
                  },
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form.get('levelTypeCode')?.value === 'ROOF' ||
                    field.form.get('areaCalcLevelTypeCode')?.value === 'ROOF' ? false : true,
                  'props.required': (field?: FormlyFieldConfig) => (!field.form?.value?.isLowestLevel && !field.form?.value?.isAreaCalc) ? true : false,
                  'props.isDisabled': (field?: FormlyFieldConfig) => field.form?.value?.isAreaCalc ? true : false,
                  'props.disabled': (field?: FormlyFieldConfig) => field.form?.value?.isAreaCalc ? true : false
                },
                validators: {
                  lessThan: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      if (field.form?.value?.isAreaCalc || control.value === null || control.value === undefined) {
                        return true;
                      }
                      // TODO: Kevin: please check
                      if (field.options.formState.service.riskReport.model?.buildingInformation?.yearBuilt &&
                        field.options.formState.service.riskReport.model?.buildingInformation?.yearBuilt !== undefined &&
                        control.value < field.options.formState.service.riskReport.model?.buildingInformation?.yearBuilt) {
                        return false;
                      }
                      if ((field.options.formState.service.riskReport.model?.buildingInformation?.yearBuilt === null ||
                        field.options.formState.service.riskReport.model?.buildingInformation?.yearBuilt === undefined) &&
                        +control.value < new Date().getFullYear()) {
                        return false;
                      }
                      return true;
                    },
                    message: 'Roof Cover Age cannot be older than building',
                  },
                  futureDate: {
                    expression: (c) => c.value ? c.value < (new Date().getFullYear() + 1) : true,
                    message: 'Year of roof cover installation cannot be in the future'
                  }
                },
                validation: {
                  messages: {
                    pattern: (error: any, field: FormlyFieldConfig) => `Invalid year`,
                  },
                }
              },
              {
                key: 'isYearOfRoofCoverApproximate',
                type: 'custom-checkbox',
                className: 'sd-checkbox-style',
                resetOnHide: true,
                props: {
                  label: 'Approx',
                  // disabled: true
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.get('levelTypeCode')?.value === 'ROOF' ||
                    field.form?.get('areaCalcLevelTypeCode')?.value === 'ROOF' ? false : true,
                  'props.disabled': (field?: FormlyFieldConfig) => field.form?.value?.isAreaCalc ? true : false
                }
              },
              { key: 'isLowestLevel' },
              { key: 'levelType', },
              { key: 'floorAndRoofIdentifier' },
              { key: 'isLowestLevelCombustible' },
              { key: 'hasDividingWall' },
              { key: 'isAreaCalc' }
            ],
            validators: {
              validation: [
                { name: 'floor-level-validator' },
                {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    if (field.parent.formControl?.value && field.parent.formControl?.value?.length > 1) {
                      if (field.parent.formControl?.value?.find(level => level?.levelTypeCode === 'ROOF') === undefined) {
                        field.parent.form?.get('roofValidator')?.setErrors({ error: 'Atleast one LevelType must = Roof' });
                      } else {
                        field.parent.form?.get('roofValidator')?.setErrors(null);
                      }
                    }
                    return true;
                  },
                  message: 'Atleast one LevelType must be equal to Roof'
                }
              ],
            }
          }
        },
        {
          fieldGroupClassName: 'formly-flex-container',
          wrappers: [ 'error-info-wrapper' ],
          fieldGroup: [
            {
              key: 'levelOnGrade',
              type: 'simple-select',
              defaultValue: 'Level on Grade',
              className: 'select-width',
              props: {
                label: 'Level Type',
                readonly: true,
                options: [ { value: 'Level on Grade', label: 'Level on Grade' } ],
                isDisabled: true,
                className: 'custom-disabled-background'
              }
            },
            // {
            //   template: '<img style="width: 52px; height: 42px" src="../../../../assets/icon/floors-and-roofs-calculate.png">',
            //   className: 'image-margin'
            // },
            {
              type: 'rf-aerial',
              props: {
                areaKey: 'levelOnGradeLength',
                oneKey: 'levelOnGradeWidth'
              }
            },
            {
              key: 'levelOnGradeLength',
              type: 'simple-input',
              defaultValue: null,
              className: 'input-width ft-label-input max-z-index position-relative',
              props: {
                label: 'Length',
                type: 'number',
                inputmode: 'tel',
                min: 1,
                maxLength: 8,
                required: true,
                isAsteriskNotRequired: true,
                isNonBlockingValidation: false
              },
              expressions: {
                'props.required': (field: FormlyFieldConfig) => (field.form?.value?.floorsAndRoofsLevels?.length > 0) ? true : false
              }
            },
            {
              key: 'levelOnGradeWidth',
              type: 'simple-input',
              defaultValue: null,
              className: 'input-width ft-label-input max-z-index position-relative',
              props: {
                label: 'Width',
                type: 'number',
                inputmode: 'tel',
                required: true,
                isAsteriskNotRequired: true,
                min: 1,
                maxLength: 8,
                isNonBlockingValidation: false
              },
              expressions: {
                'props.required': (field: FormlyFieldConfig) => (field.form?.value?.floorsAndRoofsLevels?.length > 0) ? true : false
              }
            },
            {
              key: 'levelOnGradeArea',
              type: 'text',
              className: 'text-area',
              props: {
                label: 'Area',
                required: false,
                readonly: true,
                unit: ' sq.ft.',
                prefix: '=',
                isCorrect: undefined,
              },
              expressions: {
                'props.isCorrect': (field: FormlyFieldConfig) => !field.formControl?.valid ? false : undefined
              },
              hooks: {
                onInit: (field) => {
                  if (field.form?.value?.floorsAndRoofsLevels && field.form?.value?.floorsAndRoofsLevels.length > 0) {
                    field.form?.value?.floorsAndRoofsLevels?.forEach(element => {
                      if (element.isLowestLevel) {
                        field.formControl?.setValue(Number(element?.length) * Number(element?.width));
                      }
                    });
                  }
                  const formlyService = field.options.formState.service;
                  formlyService.formlyFloorsRoofService.levelOnGradeAreaCalculation(field);
                }
              },
              // validators: {
              //   areaValidator: {
              //     expression: (control: FormControl, field: FormlyFieldConfig) => {
              //       let floorsAndRoofsDeatils = field.parent.form.value.floorsAndRoofsLevels;
              //       floorsAndRoofsDeatils = floorsAndRoofsDeatils.filter(src => src?.levelTypeCode === 'FLOO' &&
              //         src.levelStart && Number(src.levelStart) === 1);
              //       return floorsAndRoofsDeatils?.filter(x => x.area > control.value)?.length > 0 ? false : true;
              //     },
              //     message: 'Floor 1 area cannot exceed Level on Grade area',
              //   }
              // }
            },
            {
              template: '<span></span>', className: 'new-row field-padding-bottom-10',
            },
            {
              key: 'isLowestLevelCombustibleLvlOnGrade',
              type: 'custom-checkbox',
              className: 'level-width',
              props: {
                label: 'Combustible Lowest Level',
                isFontSizeSmall: true,    // To set the font-size to 16px
              },
            },
            {
              key: 'hasDividingWallLvlOnGrade',
              type: 'custom-checkbox',
              className: 'division-width',
              props: {
                label: 'Division Wall v1',
                isFontSizeSmall: true
              },
            },
            {
              key: 'hasDividingWall-v2',
              type: 'custom-checkbox',
              className: 'division-width-v2',
              props: {
                label: 'Division Wall v2',
                isFontSizeSmall: true
              },
              hide: true, // We will enable this in v2 scope
            },
            { key: 'floorsAndRoofsLevelsValidator' },
            { key: 'crossSectionValidator' },
            { key: 'roofValidator' }
          ]
        }
      ],
      validators: {
        validation: [ { name: 'occupancy-level-validator' } ]
      }
    });
  }
}
