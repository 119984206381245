import { Injectable } from '@angular/core';
import { RiskReportService } from '../../risk-report.service';
import { UtilService } from 'src/app/services/util-service/util.service';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable({
  providedIn: 'root',
})
export class DryPipeValveTestService {
  isDrySytem:boolean;
  constructor(private riskReportService: RiskReportService,
    private utilService: UtilService) {
  }


  checkDrySystemEntry(field: FormlyFieldConfig) {
    const modelValue = this.riskReportService?.model;
    const occupantData = modelValue?.occupants && modelValue?.occupants['section-occupants'] 
      && modelValue?.occupants['section-occupants']?.occupants ? modelValue.occupants['section-occupants'].occupants : null;
    let isOccupantDrySystem;
    if(occupantData && occupantData.length) {
      occupantData?.find((occupant) => {
        if (occupant?.occupantLevels?.length) {
          isOccupantDrySystem = occupant.occupantLevels.find((occupantLevel) => occupantLevel.sprinklerType === 'Dry' || occupantLevel.sprinklerType === 'Dry - High Piled Stock')
        }
        if (isOccupantDrySystem !== undefined) {
          return false;
        }
        return true;
      });
    }
    
    const areaProtectedByDryPipeSystem = modelValue?.sprinklerReport?.asgrSprinklerSystemComponent?.dryPipeValve?.areaProtectedByDryPipeSystem;
    const dpvSystem = modelValue?.sprinklerReport?.asgrWaterSupply?.dpv?.dpvSystem;
    field.props.hideDryPipeValve = ((isOccupantDrySystem) || (areaProtectedByDryPipeSystem > 0) || (dpvSystem === 'QOD Not Required' || dpvSystem === 'No Required QOD')) ? false : true;
    field.formControl['controls']?.isDpvTestNotApplicable?.setValue(field.props.hideDryPipeValve);
    return field.props.hideDryPipeValve;
  }

  calculateIsDpvTestNotApplicable() {
    const modelValue = this.riskReportService.model;
    const occupantData = modelValue.occupants ? modelValue.occupants['section-occupants'].occupants : null;
    let isOccupantDrySystem;
    occupantData?.find((occupant) => {
      if (occupant?.occupantLevels?.length) {
        isOccupantDrySystem = occupant.occupantLevels.find((occupantLevel) => occupantLevel.sprinklerType === 'Dry' || occupantLevel.sprinklerType === 'Dry - High Piled Stock')
      }
      if (isOccupantDrySystem !== undefined) {
        return false;
      }
      return true;
    });
    const areaProtectedByDryPipeSystem = modelValue?.sprinklerReport?.asgrSprinklerSystemComponent?.dryPipeValve?.areaProtectedByDryPipeSystem;
    const dpvSystem = modelValue?.sprinklerReport?.asgrWaterSupply?.dpv?.dpvSystem;
    const isDpvTestNotApplicable = ((isOccupantDrySystem) || (areaProtectedByDryPipeSystem > 0) || (dpvSystem === 'QOD Not Required' || dpvSystem === 'No Required QOD')) ? false : true;
    return isDpvTestNotApplicable;
  }

}

