import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { RiskReportService } from '../../risk-report.service';
import { FormlyBuilderService } from '../../formly-builder.service';
import { FirePumpTestDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/fire-pump-test-data.model';

@Injectable({
  providedIn: 'root',
})
export class FirePumpTestsService {  
  constructor(private riskReportService: RiskReportService,
    private formlyBuilderService: FormlyBuilderService) {}


  onApplyFirePumpTest(modalCtrl,field){
    // Set value for Fire Pump Test Date
    if(field.parent?.parent?.parent?.props?.isFromSystemTesting){
      let mostRecentDate = null;
      field.parent?.model?.forEach(item => {
        let testDate = null;
        // if the TestDate is null new Date(null) will return Thu Jan 01 1970 05:30:00 GMT+0530
        if (item?.firePumpTestInput?.testDate) {
          // Access the testDate property from firePumpTestInput
          testDate = new Date(item?.firePumpTestInput?.testDate);
        }

        // Compare the current testDate with the mostRecentDate
        if (!mostRecentDate || testDate > mostRecentDate) {
            mostRecentDate = testDate;
        }
      });
      if (!field?.options?.formState?.riskReportService?.report?.SprinklerReport?.AsgrWaterSupply?.IsEvaluatedThroughBypassOnly) {
        field.parent?.form?.get('firePumpTestDate')?.setValue(mostRecentDate);
        field.parent?.form?.get('hasFirePumpTest')?.setValue(false);
        field.parent?.form?.get('hasFirePumpTest')?.props?.isDisabled?.setValue(true);
      }
    }
    if(field.parent?.parent?.key == 'ss1' || field.parent?.parent?.key == 'ss2'){
      const formlyService = field.options.formState.service;
      formlyService.waterSupplyService.setSupplySourceFieldValues(field , 'firePumpTest');
    }
    modalCtrl.dismiss(null ,'save');
  }

  onCloseFirePumpTest(modalCtrl,field,initialModel){
    field.formControl.patchValue(initialModel);
    setTimeout(() => {
      const length = field?.parent?.formControl?.controls?.length;
      if (length && length > 0) {
        field?.parent?.formControl?.controls[length - 1].controls['firePumpTestInput']?.controls['testDate']?.updateValueAndValidity();
      }
    })
    modalCtrl.dismiss(null ,'cancel');
  }

  getTypesOptions() {
    return [
      { value: 'Horizontal Split Case', label: 'Horizontal Split Case' },
      { value: 'Vertical In-line', label: 'Vertical In-line' },
      { value: 'Vertical Shaft Turbine', label: 'Vertical Shaft Turbine' },
      { value: 'Multi-stage Multi-port', label: 'Multi-stage Multi-port' }
    ];
  }

  // Will return true if the firePumpTest is the last index
  isTestMostRecentOne(parent) {
    if (parent?.parent?.key === 'firePumpTests' && parent?.parent?.formControl['controls']?.length) {
      if ( parent['index'] === parent?.parent?.formControl['controls']?.length - 1){
        return true;
      }
    }
    return false;
  }

  // reset Supply source Fire Pump Test when we click on remove icon
  resetSupplySource2FirePumpTest(field: FormlyFieldConfig) {
    const model = this.riskReportService.model;
    const firePumpTests = this.riskReportService?.report?.SprinklerReport?.AsgrSprinklerSystemTest?.FirePumpTests;
    const firePumpTestsArray: any[] = [];
    if (firePumpTests?.length) {
      firePumpTests?.forEach((test) => {
        const firePumpTestData = FirePumpTestDataModel.fromS3(test);
        firePumpTestsArray.push(firePumpTestData);
      });
    }
    const newModel = {
      ...model,
      sprinklerReport: {
        ...model.sprinklerReport,
        asgrWaterSupply: {
          ...model.sprinklerReport.asgrWaterSupply,
          supplySource : {
            ...model.sprinklerReport.asgrWaterSupply.supplySource,
            ss2: {
              ...model.sprinklerReport.asgrWaterSupply.supplySource.ss2,
              ...{ firePumpTests: firePumpTestsArray}
            }
          }
        }
      }
    }
    if (newModel) {
      this.formlyBuilderService.riskReport.model = { ...newModel };
    }
  }
  
  /**
 * @method updateFirePumpTestDate Update most recent date after delete 
 *  need to apply validation
 * @param FormlyFieldConfig field
 * @return *  Most Recent survey date
 * */
  updateFirePumpTestDate(field) {
    let mostRecentDate = null;
    field.model?.forEach(item => {
      let testDate = null;
      // if the TestDate is null new Date(null) will return Thu Jan 01 1970 05:30:00 GMT+0530
      if (item?.firePumpTestInput?.testDate) {
        // Access the testDate property from firePumpTestInput
        testDate = new Date(item?.firePumpTestInput?.testDate);
      }
      // Compare the current testDate with the mostRecentDate
      if (!mostRecentDate || testDate > mostRecentDate) {
        mostRecentDate = testDate;
      }
    });
    if (!field?.options?.formState?.riskReportService?.report?.SprinklerReport?.AsgrWaterSupply?.IsEvaluatedThroughBypassOnly) {
      if (mostRecentDate) {
        field.form?.get('firePumpTestDate')?.setValue(mostRecentDate);
        field.form?.get('hasFirePumpTest')?.setValue(false);
        field.form?.get('hasFirePumpTest')?.props?.isDisabled?.setValue(true);
      }
    }
  }
}

