import { SectionEnum } from "src/app/services/formly-builder/section.config";

export enum DeepLinkTargetEnum {
  ALL,
  LANDING_PAGE,
  SECTION,
  SUBSECTION
}
export interface DeepLinkMessageModel {
  source?: string;              // Who emits this message
  target?: DeepLinkTargetEnum;  // Who's this messsage for

  module?: string;              // "rf", "uw" for risk form, underwriting form
  resource?: string;            // guid of the riskform or UW

  landingId?: SectionEnum;      // aka Main Section
  landing?: string;             // "Landing Page". F.i.: "overview", "floors",... as seen in the RF dropdown

  sectionId?: SectionEnum;      // sections are identified by Section Formly id
  section?: string;             // Section Formly id - this would be the section card inside a landing page, like "contactInformation" or "rooftop".

  subSection?: string;          // Only Water Supply has subsections...
  subSectionId?: SectionEnum;  

  isEdit?: boolean;             // view or edit mode. If edit, a sheet will have to be opened
                                // Better use same name as in Model
  editAction?: string;          // add/edit/...
  index1?: number;              // Index of first one to many item
  index2?: number;              // Index of second one to many item

  field?: string;               // Formly key/id for the field to set the focus/scrollTo
}