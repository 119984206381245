import { Injectable, OnInit } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyWrapperService, ActionButton } from 'src/app/services/formly-builder/formly-wrapper.service';
import { tap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { PhotosDataModel } from 'src/app/models/formly-builder/formly/photos-data.model';
import { S3SyncService } from '../s3-sync-service/s3-sync.service';
import { FormlyBuilderService } from './formly-builder.service';
import { SectionEnum } from './section.config';
import { UtilService } from '../util-service/util.service';
import { Directory, Encoding, Filesystem, ReadFileResult } from '@capacitor/filesystem';
import { RiskReportService } from './risk-report.service';
import { FormlyFieldConfig } from '@ngx-formly/core';

export interface TargetImage {
  targetId: string;
  rptId?: string;
  rearImage: string;
  frontImage: string;
  frontImageurl?: string;
  rearImageurl?: string;
  frontUuid?: string;
  rearUuid?: string;
  imageDeleted?: boolean[];    // Constants.front/rearPhotoIndex
}

@Injectable({
  providedIn: 'root',
})
export class SectionPhotosService extends SectionServiceAbstract {
  constructor() // private s3SyncService: S3SyncService, // private formlyService: FormlyBuilderService,
  // private utilService: UtilService
  {
    super();
  }

  /**
   *
   * @param model
   * @param riskReportData
   * @returns
   */
  async updateData(model: any, riskReportData: any) {
    const newPhotosData = PhotosDataModel.to(model.reportPhotos);
    return { ...riskReportData, ...{ ReportPhotos: newPhotosData } };
  }

  /**
   *
   * @param photoData
   * @param riskReport
   * @param isS3
   * @param model
   * @returns
   */
  loadData(photoData: any[], riskReport: any, isS3: boolean, model: any): any {
    let photoModel: PhotosDataModel[];
    photoModel = PhotosDataModel.fromS3(photoData);
    return { ...model, ...{ reportPhotos: photoModel } };
  }

  public get uuid() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  /**
   * 
   * @param control 
   * @param field 
   * @returns true if Photos check is Ok, false for errors
   */
  public checkPhotos(control: FormControl, field: FormlyFieldConfig): boolean {
    let result = false;
    const formlyBuilderService = field?.options?.formState?.service;

    // Check that the array contains a photo with reportPhotoType === "FRNT" & "REAR"
    let photoTypes = [];
    if (formlyBuilderService.mode === 0 || formlyBuilderService.mode === 2) {
      photoTypes = ['FRNT', 'REAR'];
    } else if (formlyBuilderService.mode === 1) {
      photoTypes = ['FRNT'];
    }
    const photos = control.value;
    if (photos && photos.length > 0 && photoTypes.length) {
      result = photoTypes?.every(val => photos.find(src => src.reportPhotoType === val));
      if (!result) {
        // Some Photos
        if (field.props.isEmptySectionOnInit === undefined) {
          field.props.isEmptySectionOnInit = false;
        }
      } else {
        // All Photos
        field.props.isEmptySectionOnInit = false;
      }
    } else {
      // No Photos
      if (field.props.isEmptySectionOnInit === undefined) {
        field.props.isEmptySectionOnInit = false;
      }
    }

    return result;
  }

  public isCollapsed(field: FormlyFieldConfig): boolean {
    const control = field?.formControl;

    // If no  Photos, collapse until user presses Add
    const photos = control.value;
    if (photos && photos.length > 0) {
      return false;
    }

    return false;
  }
}
