/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Guid } from 'guid-typescript';
import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';

/**
 * Class that represents the Formly Model for Internal protection
 */
export class InternalProtectionDataModel {
  static sectionName = 'internalProtections';

  interProtection: InternalProtection;
  limitedSupplyFps: LimitedSupplyFps;
  sprinklersData?: SprinklersData;
  //we dont use below data but to match db, we are adding it
  internalProtectionIdentifier?: any;
  reportIdentifier?: any;
  hasNoInternalProtection?: any;
  fireAlarmGrade?: number;
  isAutoFireSelected?: boolean;
  sprinklerRatingAsgr?: any;
  sprinklerTypeCodeValue?: any;
  sprinklerTypeValue?: any;
  partialSupplyProtectedArea?: any;
  partialSupplyAlarmType?: any;
  isStandardAutoSprinklerSelected?: boolean;
  sprinkleredStationType?: any;
  sprinkleredStationAlarmType?: any;
  hasStandardPeriodicInspectionService?: boolean;
  alarmSignalDestinationType?: any;
  troubleSignalDestinationType?: any;
  automaticFireAlarmDetectionSystem?: AutomaticFireAlarmDetectionSystem;

  autoFireAlarmSystemCoverageType?: any;
  autoFireAlarmInspectionAndTestFrequencyType?: any;
  isSystemAndTransmitterListed?: boolean;
  isRecieverListed?: boolean;
  comment?: any;
  hasNonStandardRetransmission?: boolean;
  // TODO: Remove Amplify once unnecessary
  static from(reports: any): InternalProtectionDataModel {
    const interProtectionData = reports?.InternalProtections[ 0 ];
    if (reports?.InternalProtections && reports?.InternalProtections.length) {
      return {
        // reports.InternalProtections.forEach((data) => {
        interProtection: InternalProtectionDataModel.loadInternalProtection(interProtectionData),
        limitedSupplyFps: InternalProtectionDataModel.loadLimitedSupplyFps(interProtectionData),
        //we dont use below data but to match db, we are adding it
        internalProtectionIdentifier: interProtectionData?.InternalProtectionIdentifier ? interProtectionData?.InternalProtectionIdentifier : Guid.create().toString(),
        reportIdentifier: interProtectionData?.reportIdentifier ? interProtectionData?.reportIdentifier : '',
        hasNoInternalProtection: interProtectionData?.HasNoInternalProtection ? interProtectionData?.HasNoInternalProtection : '',
        fireAlarmGrade: interProtectionData?.FireAlarmGrade ? interProtectionData?.FireAlarmGrade : '',
        isAutoFireSelected: interProtectionData?.IsAutoFireSelected ? interProtectionData?.IsAutoFireSelected : false,
        sprinklerRatingAsgr: reports?.SprinklerRatingAsgr ? reports?.SprinklerRatingAsgr : '',
        sprinklerTypeCodeValue: reports?.SprinklerTypeCodeValue ? reports?.SprinklerTypeCodeValue : '',
        sprinklerTypeValue: reports?.SprinklerTypeValue ? reports?.SprinklerTypeValue : '',
        partialSupplyProtectedArea: interProtectionData?.PartialSupplyProtectedArea ? interProtectionData?.PartialSupplyProtectedArea : '',
        partialSupplyAlarmType: interProtectionData?.PartialSupplyAlarmType ? interProtectionData?.PartialSupplyAlarmType : '',
        isStandardAutoSprinklerSelected: interProtectionData?.IsStandardAutoSprinklerSelected ? interProtectionData?.IsStandardAutoSprinklerSelected : false,
        sprinkleredStationType: interProtectionData?.SprinkleredStationType ? interProtectionData?.SprinkleredStationType : '',
        sprinkleredStationAlarmType: interProtectionData?.SprinkleredStationAlarmType ? interProtectionData?.SprinkleredStationAlarmType : '',
        hasStandardPeriodicInspectionService: interProtectionData?.HasStandardPeriodicInspectionService ? interProtectionData?.HasStandardPeriodicInspectionService : false,
        alarmSignalDestinationType: interProtectionData?.AlarmSignalDestinationType ? interProtectionData?.AlarmSignalDestinationType : 'NONE',
        troubleSignalDestinationType: interProtectionData?.TroubleSignalDestinationType ? interProtectionData?.TroubleSignalDestinationType : '',
        autoFireAlarmSystemCoverageType: interProtectionData?.AutoFireAlarmSystemCoverageType ? interProtectionData?.AutoFireAlarmSystemCoverageType : '',
        autoFireAlarmInspectionAndTestFrequencyType: interProtectionData?.AutoFireAlarmInspectionAndTestFrequencyType ? interProtectionData?.AutoFireAlarmInspectionAndTestFrequencyType : '',
        isSystemAndTransmitterListed: interProtectionData?.IsSystemAndTransmitterListed ? interProtectionData?.IsSystemAndTransmitterListed : false,
        isRecieverListed: interProtectionData?.IsRecieverListed ? interProtectionData?.IsRecieverListed : false,
        comment: interProtectionData?.Comment ? interProtectionData?.Comment : '',
        hasNonStandardRetransmission: interProtectionData?.HasNonStandardRetransmission ? interProtectionData?.HasNonStandardRetransmission : false
      };
    }
  }

  static fromS3(reports: any): InternalProtectionDataModel {
    const interProtectionData = reports.InternalProtections != null ? reports?.InternalProtections[ 0 ] ? reports?.InternalProtections[ 0 ] : [] : [];
    // if (reports?.InternalProtections && reports?.InternalProtections.length) {
    return {
      // reports.InternalProtections.forEach((data) => {
      interProtection: InternalProtectionDataModel.loadInternalProtection(interProtectionData),
      limitedSupplyFps: InternalProtectionDataModel.loadLimitedSupplyFps(interProtectionData),
      automaticFireAlarmDetectionSystem: InternalProtectionDataModel.loadAutomaticFireAlarmDetectionSystem(interProtectionData),
      sprinklersData: InternalProtectionDataModel.loadSprinklersData(interProtectionData, reports),
      //we dont use below data but to match db, we are adding it
      internalProtectionIdentifier: interProtectionData?.InternalProtectionIdentifier ? interProtectionData?.InternalProtectionIdentifier : Guid.create().toString(),
      reportIdentifier: interProtectionData?.reportIdentifier ? interProtectionData?.reportIdentifier : '',
      hasNoInternalProtection: interProtectionData?.HasNoInternalProtection ? interProtectionData?.HasNoInternalProtection : '',
      // fireAlarmGrade: interProtectionData?.FireAlarmGrade ? interProtectionData?.FireAlarmGrade : '',
      isAutoFireSelected: interProtectionData?.IsAutoFireSelected ? interProtectionData?.IsAutoFireSelected : false,
      // partialSupplyProtectedArea: interProtectionData?.PartialSupplyProtectedArea ? interProtectionData?.PartialSupplyProtectedArea : '',
      // partialSupplyAlarmType: interProtectionData?.PartialSupplyAlarmType ? interProtectionData?.PartialSupplyAlarmType : '',
      isStandardAutoSprinklerSelected: interProtectionData?.IsStandardAutoSprinklerSelected ? interProtectionData?.IsStandardAutoSprinklerSelected : false,
      // sprinkleredStationType: interProtectionData?.SprinkleredStationType ? interProtectionData?.SprinkleredStationType : '',
      // sprinkleredStationAlarmType: interProtectionData?.SprinkleredStationAlarmType ? interProtectionData?.SprinkleredStationAlarmType : '',
      // hasStandardPeriodicInspectionService: interProtectionData?.HasStandardPeriodicInspectionService ? interProtectionData?.HasStandardPeriodicInspectionService : false,
      // alarmSignalDestinationType: interProtectionData?.AlarmSignalDestinationType ? interProtectionData?.AlarmSignalDestinationType : '',
      // troubleSignalDestinationType: interProtectionData?.TroubleSignalDestinationType ? interProtectionData?.TroubleSignalDestinationType : '',
      // autoFireAlarmSystemCoverageType: interProtectionData?.AutoFireAlarmSystemCoverageType ? interProtectionData?.AutoFireAlarmSystemCoverageType : '',
      // autoFireAlarmInspectionAndTestFrequencyType: interProtectionData?.AutoFireAlarmInspectionAndTestFrequencyType ? interProtectionData?.AutoFireAlarmInspectionAndTestFrequencyType : '',
      // isSystemAndTransmitterListed: interProtectionData?.IsSystemAndTransmitterListed ? interProtectionData?.IsSystemAndTransmitterListed : false,
      // isRecieverListed: interProtectionData?.IsRecieverListed ? interProtectionData?.IsRecieverListed : false,
      // comment: interProtectionData?.Comment ? interProtectionData?.Comment : '',
      hasNonStandardRetransmission: interProtectionData?.HasNonStandardRetransmission ? interProtectionData?.HasNonStandardRetransmission : false
    };
    // }
  }

  static to(model: any) {
    const internalProtectionData = model?.internalProtections;
    let fireAlarmGrade = internalProtectionData?.automaticFireAlarmDetectionSystem?.fireAlarmGrade;
    InternalProtectionDataModel.setSprinklerDatatoS3(internalProtectionData);
    if (internalProtectionData?.automaticFireAlarmDetectionSystem?.fireAlarmGrade &&
      internalProtectionData?.automaticFireAlarmDetectionSystem?.fireAlarmGrade === 'No Credit') {
      fireAlarmGrade = 0;
    }
    return {
      InternalProtections: [ {
        HasExtinguishers: internalProtectionData?.interProtection?.hasExtinguishers ? internalProtectionData?.interProtection?.hasExtinguishers : false,
        HasWatchman: internalProtectionData?.interProtection?.hasWatchman ? internalProtectionData?.interProtection?.hasWatchman : false,
        HasStandpipeHose: internalProtectionData?.interProtection?.hasStandpipeHose ? internalProtectionData?.interProtection?.hasStandpipeHose : false,
        WatchmanType: internalProtectionData?.interProtection?.watchmanType ? internalProtectionData?.interProtection?.watchmanType : 'NONE',
        //we dont use below data but to match db, we are adding it
        InternalProtectionIdentifier: internalProtectionData?.internalProtectionIdentifier ? internalProtectionData?.internalProtectionIdentifier : Guid.create().toString(),
        ReportIdentifier: internalProtectionData?.reportIdentifier ? internalProtectionData?.reportIdentifier : model?.reportIdentifier,
        HasNoInternalProtection: internalProtectionData?.hasNoInternalProtection ? internalProtectionData?.hasNoInternalProtection : false,
        FireAlarmGrade: fireAlarmGrade,
        IsAutoFireSelected: internalProtectionData?.isAutoFireSelected ? internalProtectionData?.isAutoFireSelected : false,
        PartialSupplyProtectedArea: internalProtectionData?.sprinklersData?.partialSupplyProtectedArea || internalProtectionData?.sprinklersData?.partialSupplyProtectedArea === 0 ? Number(internalProtectionData?.sprinklersData?.partialSupplyProtectedArea) : null,
        PartialSupplyAlarmType: internalProtectionData?.sprinklersData?.partialSupplyAlarmType ? internalProtectionData?.sprinklersData?.partialSupplyAlarmType : 'NONE',
        SprinklerRatingAsgr: (internalProtectionData?.sprinklersData?.sprinklerRatingAsgr || internalProtectionData?.sprinklersData?.sprinklerRatingAsgr === 0) ? internalProtectionData?.sprinklersData?.sprinklerRatingAsgr : 0,
        SprinklerTypeCodeValue: internalProtectionData?.sprinklersData?.sprinklerTypeCodeValue ? internalProtectionData?.sprinklersData?.sprinklerTypeCodeValue : '',
        SprinklerTypeValue: internalProtectionData?.sprinklersData?.sprinklerTypeValue ? internalProtectionData?.sprinklersData?.sprinklerTypeValue : '',
        LimitedSupplyProtectedArea: internalProtectionData?.limitedSupplyFps?.limitedSupplyProtectedArea || internalProtectionData?.limitedSupplyFps?.limitedSupplyProtectedArea === 0 ? Number(internalProtectionData?.limitedSupplyFps?.limitedSupplyProtectedArea) : null,
        LimitedSupplyAlarmType: internalProtectionData?.limitedSupplyFps?.limitedSupplyAlarmType ? internalProtectionData?.limitedSupplyFps?.limitedSupplyAlarmType : null,
        IsStandardAutoSprinklerSelected: internalProtectionData?.isStandardAutoSprinklerSelected ? internalProtectionData?.isStandardAutoSprinklerSelected : false,
        SprinkleredStationType: (internalProtectionData?.sprinklersData?.sprinklerTypeCodeValue === 'P' ||
          internalProtectionData?.sprinklersData?.sprinklerTypeCodeValue === 'N') ? 'NONE' : (internalProtectionData?.sprinklersData?.sprinkleredStationType ? internalProtectionData?.sprinklersData?.sprinkleredStationType : 'NONE'),
        SprinkleredStationAlarmType: (internalProtectionData?.sprinklersData?.sprinklerTypeCodeValue === 'P' ||
          internalProtectionData?.sprinklersData?.sprinklerTypeCodeValue === 'N') ? 'NONE' : (internalProtectionData?.sprinklersData?.sprinkleredStationAlarmType ? internalProtectionData?.sprinklersData?.sprinkleredStationAlarmType : 'NONE'),
        HasStandardPeriodicInspectionService: internalProtectionData?.sprinklersData?.hasStandardPeriodicInspectionService ? internalProtectionData?.sprinklersData?.hasStandardPeriodicInspectionService : false,
        AlarmSignalDestinationType: internalProtectionData?.automaticFireAlarmDetectionSystem?.alarmSignalDestinationType ? internalProtectionData?.automaticFireAlarmDetectionSystem?.alarmSignalDestinationType : 'NONE',
        TroubleSignalDestinationType: internalProtectionData?.automaticFireAlarmDetectionSystem?.troubleSignalDestinationType ? internalProtectionData?.automaticFireAlarmDetectionSystem?.troubleSignalDestinationType : 'NONE',
        AutoFireAlarmSystemCoverageType: internalProtectionData?.automaticFireAlarmDetectionSystem?.autoFireAlarmSystemCoverageType ? internalProtectionData?.automaticFireAlarmDetectionSystem?.autoFireAlarmSystemCoverageType : 'NONE',
        AutoFireAlarmInspectionAndTestFrequencyType: internalProtectionData?.automaticFireAlarmDetectionSystem?.autoFireAlarmInspectionAndTestFrequencyType ? internalProtectionData?.automaticFireAlarmDetectionSystem?.autoFireAlarmInspectionAndTestFrequencyType : 'NONE',
        IsSystemAndTransmitterListed: internalProtectionData?.automaticFireAlarmDetectionSystem?.isSystemAndTransmitterListed ? internalProtectionData?.automaticFireAlarmDetectionSystem?.isSystemAndTransmitterListed : false,
        IsRecieverListed: internalProtectionData?.automaticFireAlarmDetectionSystem?.isRecieverListed ? internalProtectionData?.automaticFireAlarmDetectionSystem?.isRecieverListed : false,
        Comment: internalProtectionData?.automaticFireAlarmDetectionSystem?.autoFireAlarmSystemCoverageTypeComment ? internalProtectionData?.automaticFireAlarmDetectionSystem?.autoFireAlarmSystemCoverageTypeComment : null,
        HasNonStandardRetransmission: internalProtectionData?.sprinklersData?.hasNonStandardRetransmission ? internalProtectionData?.sprinklersData?.hasNonStandardRetransmission : false,
        AutomaticFireAlarmDetectionSystemToggle: internalProtectionData?.automaticFireAlarmDetectionSystem?.automaticFireAlarmDetectionSystemToggle ? internalProtectionData?.automaticFireAlarmDetectionSystem?.automaticFireAlarmDetectionSystemToggle : false
      }
      ],
      SprinklerRatingAsgr: (internalProtectionData?.sprinklersData?.sprinklerRatingAsgr || internalProtectionData?.sprinklersData?.sprinklerRatingAsgr === 0) ? internalProtectionData?.sprinklersData?.sprinklerRatingAsgr : 0,
      SprinklerTypeCodeValue: internalProtectionData?.sprinklersData?.sprinklerTypeCodeValue ? internalProtectionData?.sprinklersData?.sprinklerTypeCodeValue : '',
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    return true;
  }

  static loadInternalProtection(interProtectionData): InternalProtection {
    return {
      hasStandpipeHose: interProtectionData?.HasStandpipeHose ? interProtectionData?.HasStandpipeHose : false,
      hasExtinguishers: interProtectionData?.HasExtinguishers ? interProtectionData?.HasExtinguishers : false,
      hasWatchman: interProtectionData?.HasWatchman ? interProtectionData?.HasWatchman : false,
      watchmanType: interProtectionData?.WatchmanType ? interProtectionData?.WatchmanType : null,
    };
  }

  static loadLimitedSupplyFps(interProtectionData): LimitedSupplyFps {
    return {
      limitedSupplyAlarmType: interProtectionData?.LimitedSupplyAlarmType ? interProtectionData?.LimitedSupplyAlarmType : '',
      limitedSupplyProtectedArea: interProtectionData?.LimitedSupplyProtectedArea ? interProtectionData?.LimitedSupplyProtectedArea : '',

    };
  }

  static setSprinklerDatatoS3(internalProtectionData): any {
    return {
      SprinklerRatingAsgr: internalProtectionData?.sprinklersData?.sprinklerRatingAsgr ? internalProtectionData?.sprinklersData?.sprinklerRatingAsgr : '',
      SprinklerTypeCodeValue: internalProtectionData?.sprinklersData?.sprinklerTypeCodeValue ? internalProtectionData?.sprinklersData?.sprinklerTypeCodeValue : '',
    };
  }
  static loadSprinklersData(interProtectionData, reports): SprinklersData {
    return {
      sprinklerRatingAsgr: (reports?.SprinklerRatingAsgr !== '' && reports?.SprinklerRatingAsgr !== null) ? reports?.SprinklerRatingAsgr : '',
      sprinklerTypeCodeValue: reports?.SprinklerTypeCodeValue ? reports?.SprinklerTypeCodeValue : '',
      sprinklerTypeValue: reports?.SprinklerTypeCodeValue === 'S' ? 'Sprinklered' : reports?.SprinklerTypeCodeValue === 'P' ? 'Partially Sprinklered' : reports?.SprinklerTypeCodeValue === 'N' ? 'Non-Sprinklered' : '',
      partialSupplyAlarmType: interProtectionData?.PartialSupplyAlarmType ? interProtectionData?.PartialSupplyAlarmType : '',
      partialSupplyProtectedArea: interProtectionData?.PartialSupplyProtectedArea || interProtectionData?.PartialSupplyProtectedArea === 0 ? interProtectionData?.PartialSupplyProtectedArea : null,
      sprinkleredStationType: interProtectionData?.SprinkleredStationType ? interProtectionData?.SprinkleredStationType : '',
      sprinkleredStationAlarmType: interProtectionData?.SprinkleredStationAlarmType ? interProtectionData?.SprinkleredStationAlarmType : '',
      hasNonStandardRetransmission: interProtectionData?.HasNonStandardRetransmission ? interProtectionData?.HasNonStandardRetransmission : false,
      hasStandardPeriodicInspectionService: interProtectionData?.HasStandardPeriodicInspectionService ? interProtectionData?.HasStandardPeriodicInspectionService : false,
    };
  }
  static loadAutomaticFireAlarmDetectionSystem(data): AutomaticFireAlarmDetectionSystem {
    return {
      automaticFireAlarmDetectionSystemToggle: data?.AutomaticFireAlarmDetectionSystemToggle ? data?.AutomaticFireAlarmDetectionSystemToggle :
        ((data?.AutoFireAlarmSystemCoverageType && data?.AutoFireAlarmSystemCoverageType.length > 0 &&
          data?.AutoFireAlarmSystemCoverageType !== 'NONE') ? true : false),
      // automaticFireAlarmDetectionSystemToggle: data?.AutoFireAlarmSystemCoverageType && data?.AutoFireAlarmSystemCoverageType.length > 0 ? true : false,
      // deficiencyPoints?: number;
      fireAlarmGrade: data?.FireAlarmGrade,
      autoFireAlarmSystemCoverageType: (data?.AutoFireAlarmSystemCoverageType && data?.AutoFireAlarmSystemCoverageType.length > 0 &&
        data?.AutoFireAlarmSystemCoverageType !== 'NONE') ? data?.AutoFireAlarmSystemCoverageType : null,
      autoFireAlarmSystemCoverageTypeComment: data?.Comment ? data?.Comment : '',
      isSystemAndTransmitterListed: data?.IsSystemAndTransmitterListed ? data?.IsSystemAndTransmitterListed : false,
      isRecieverListed: data?.IsRecieverListed ? data?.IsRecieverListed : false,
      alarmSignalDestinationType: data?.AlarmSignalDestinationType &&
        data?.AlarmSignalDestinationType !== 'NONE' ? data?.AlarmSignalDestinationType : '',
      troubleSignalDestinationType: data?.TroubleSignalDestinationType ? data?.TroubleSignalDestinationType : '',
      autoFireAlarmInspectionAndTestFrequencyType: data?.AutoFireAlarmInspectionAndTestFrequencyType &&
        data?.AutoFireAlarmInspectionAndTestFrequencyType !== 'NONE' ? data?.AutoFireAlarmInspectionAndTestFrequencyType : '',
    };
  }
}

class InternalProtection {
  hasStandpipeHose: boolean;
  hasExtinguishers: boolean;
  hasWatchman: boolean;
  watchmanType: any;
}

class LimitedSupplyFps {
  limitedSupplyAlarmType: string;
  limitedSupplyProtectedArea: any;
}

class SprinklersData {
  sprinklerRatingAsgr?: string;
  sprinklerTypeValue?: string;
  sprinklerTypeCodeValue?: string;
  partialSupplyAlarmType?: string;
  partialSupplyProtectedArea?: string;
  sprinkleredStationType?: string;
  sprinkleredStationAlarmType?: string;
  hasNonStandardRetransmission?: boolean;
  hasStandardPeriodicInspectionService?: boolean;
}
class AutomaticFireAlarmDetectionSystem {
  automaticFireAlarmDetectionSystemToggle?: boolean;
  deficiencyPoints?: number;
  fireAlarmGrade?: number;
  autoFireAlarmSystemCoverageType?: string;
  autoFireAlarmSystemCoverageTypeComment?: string;
  isSystemAndTransmitterListed?: boolean;
  isRecieverListed?: boolean;
  alarmSignalDestinationType?: string;
  troubleSignalDestinationType?: string;
  autoFireAlarmInspectionAndTestFrequencyType?: string;
}

