import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class InternalSprinklerPipeConditionsDataModel {
    static sectionName = 'systemTesting';

    internalSprinklerPipeConditionId: string;
    reportId: string;
    systemNumber: string;
    testConductedBy: string;
    areaAffected?: number;
    areaAffectedComment?: string;
    initialServiceDate?: Date;
    inspectionDate?: Date;
    internalExamResults?: string;
    actionsTaken?: string;
    notes?: string;

    static fromS3(internalSprinklerPipeConditionsData: any): InternalSprinklerPipeConditionsDataModel {

        return {
            internalSprinklerPipeConditionId: internalSprinklerPipeConditionsData.InternalSprinklerPipeConditionId,
            reportId: internalSprinklerPipeConditionsData.ReportId,
            systemNumber: internalSprinklerPipeConditionsData.SystemNumber,
            testConductedBy: internalSprinklerPipeConditionsData.TestConductedBy,
            areaAffected: internalSprinklerPipeConditionsData.AreaAffected,
            areaAffectedComment: internalSprinklerPipeConditionsData.AreaAffectedComment,
            initialServiceDate: internalSprinklerPipeConditionsData.InitialServiceDate,
            inspectionDate: internalSprinklerPipeConditionsData.InspectionDate,
            internalExamResults: internalSprinklerPipeConditionsData.InternalExamResults,
            actionsTaken: internalSprinklerPipeConditionsData.ActionsTaken,
            notes: internalSprinklerPipeConditionsData.Notes
        };
    }

    static to(model: any, sectionEnum?: SectionEnum) {
        return {
            InternalSprinklerPipeConditionId: model.internalSprinklerPipeConditionId,
            ReportId: model.reportId,
            SystemNumber: model.systemNumber ? model.systemNumber : '',
            TestConductedBy: model.testConductedBy ? model.testConductedBy : '',
            AreaAffected: model?.areaAffected ? Number(model.areaAffected) : model.areaAffected,
            AreaAffectedComment: model.areaAffectedComment,
            InitialServiceDate: model.initialServiceDate,
            InspectionDate: model.inspectionDate,
            InternalExamResults: model.internalExamResults,
            ActionsTaken: model.actionsTaken,
            Notes: model.notes,
        };
    }

    /**
     * Check if the model has changed
     *
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     *
     */
    static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
        return false;

    }
}
