import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class WaterSupplyFrictionLossFittingsAndValvesDataModel {
  static sectionName = 'waterSupply';

  waterSupplyFrictionLossFittingAndValveId: string;
  waterSupplyFrictionLossId: string;
  fittingAndValveNumber?: number;
  fittingAndValveType?: string;
  fittingAndValveSize?: number;
  fittingAndValveLength: number;
  numbersOfEachFittingAndValve: number;

  static fromS3(wsValvesData: any): WaterSupplyFrictionLossFittingsAndValvesDataModel {
    // check fitting type valve
    if (wsValvesData?.FittingAndValveType) {
      if (wsValvesData?.FittingAndValveType === '45° Elbow') {
        wsValvesData.FittingAndValveType = `45' Elbow`;
      } else if (wsValvesData?.FittingAndValveType === '90° Standard Elbow') {
        wsValvesData.FittingAndValveType = `90' Standard`;
      } else if (wsValvesData?.FittingAndValveType === '90° Long Turn Elbow') {
        wsValvesData.FittingAndValveType = `90' Long Turn`;
      } else {
        wsValvesData.FittingAndValveType = wsValvesData?.FittingAndValveType;
      }
    }
    return {
      waterSupplyFrictionLossFittingAndValveId: wsValvesData.WaterSupplyFrictionLossFittingAndValveId,
      waterSupplyFrictionLossId: wsValvesData.WaterSupplyFrictionLossId,
      fittingAndValveNumber: wsValvesData.FittingAndValveNumber,
      fittingAndValveType: wsValvesData.FittingAndValveType,
      fittingAndValveSize: wsValvesData.FittingAndValveSize,
      fittingAndValveLength: wsValvesData.FittingAndValveLength,
      numbersOfEachFittingAndValve: wsValvesData.NumbersOfEachFittingAndValve,
    };
  }

  static to(model: any, sectionEnum?: SectionEnum) {
    return {
      WaterSupplyFrictionLossFittingAndValveId: model.waterSupplyFrictionLossFittingAndValveId,
      WaterSupplyFrictionLossId: model.waterSupplyFrictionLossId,
      FittingAndValveNumber: model?.fittingAndValveNumber
        ? Number(model?.fittingAndValveNumber)
        : model?.fittingAndValveNumber,
      FittingAndValveType: model.fittingAndValveType,
      FittingAndValveSize: model?.fittingAndValveSize || model?.fittingAndValveSize === 0 ? Number(model.fittingAndValveSize) : null,
      FittingAndValveLength: model?.fittingAndValveLength ? Number(model.fittingAndValveLength) : 0,
      NumbersOfEachFittingAndValve: model?.numbersOfEachFittingAndValve
        ? Number(model.numbersOfEachFittingAndValve)
        : 0,
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
    return false;
  }
}
