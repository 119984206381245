import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';

export class EnhancedWindEligibleSectionModel extends FormlySectionModelAbstract {
  constructor() {
    super({
      key: 'isWindReportDeleted',
      type: 'segment',
      wrappers: [ 'question-wrapper' ],
      className: 'evidence-of-fire-sprinkler',
      resetOnHide: true,
      props: {
        label: 'Risk is Enhanced Wind Eligible',
        required: true,
        optionsAvailable: false,
        firstColSize: 6
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => {
          const geographicRiskFactor = field?.options?.formState?.service?.riskReport?.report?.GeographicRiskFactor?.trim();
          return geographicRiskFactor?.toUpperCase() === 'LOW' ? true : false;
        }
      },
      validators: {
        validation: [ 'enhanced-wind-eligible-validator' ]
      },
      hooks: {
        afterViewInit: (field: FormlyFieldConfig) => {
          const formlyService = field.options.formState.service;
          formlyService.enhancedWindEligibleService.enhancedWindEligiblevalueChange(field);
        },
        onDestroy: (field: FormlyFieldConfig) => {
          const formlyService = field.options.formState.service;
          formlyService.enhancedWindEligibleService.onDestoryHook();
        }
      }
    });
  }
}
