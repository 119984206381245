import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';

export abstract class FormlySectionModelAbstract {
    public fields$: Observable<FormlyFieldConfig>;
    private _fields: BehaviorSubject<FormlyFieldConfig>;

    /* eslint-disable no-underscore-dangle */

    constructor(initialContent: FormlyFieldConfig) {
        this._fields = new BehaviorSubject<FormlyFieldConfig>(initialContent);
        this.fields$ = this._fields.asObservable();
    }

    /**
     * Factory pattern to instantiate a derived class knowing its className
     *
     * @param this
     * @param service
     * @returns
     */
    static build<T = FormlySectionModelAbstract>(this: new(service) => T, service: FormlyBuilderService) {
        return new this(service);
     }

    get fields(): FormlyFieldConfig {
        return this._fields.getValue();
    }

    setFields(nextState: FormlyFieldConfig): void {
        this._fields.next(nextState);
    }
}
