import { Injectable } from '@angular/core';
import { WaterSupplyHydraulicDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/water-supply-hydraulic-data.model';
import { RiskReportService } from '../../risk-report.service';
import { FormlyBuilderService } from '../../formly-builder.service';
import { ScheduleCodeService } from '../../schedule-tree.service';
import { WaterSupplyPipeScheduleDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/water-supply-pipe-schedule-data.model';

@Injectable({
  providedIn: 'root',
})
export class SpocCalculationService {
  v1WaterSupplyHydraulicsArray = [];
  v1WaterSupplyPipeScheduleArray = [];
  v2WaterSupplyHydraulicsArray = [];
  v2WaterSupplyPipeScheduleArray = [];
  sprinklerDesignList = [];

  constructor(private riskReportService: RiskReportService,
    private formlyBuilderService: FormlyBuilderService,
    private scheduleService: ScheduleCodeService) {
  }

  // Spoc entries calculation needs to be perfomed when ever there is change in occupants, On load of sprinkle report and also when the fire sprinkler is toggle is turned on
  calculateSpocEntries() {
    try {
      this.formlyBuilderService.options.formState.isPipeScheduleEntryPresent = false;
      this.formlyBuilderService.options.formState.isHydraulicEntryPresent = false;
      this.sprinklerDesignList = [];
      this.v1WaterSupplyHydraulicsArray = [];
      this.v1WaterSupplyPipeScheduleArray = [];
      this.v2WaterSupplyHydraulicsArray = [];
      this.v2WaterSupplyPipeScheduleArray = [];

      if (this.riskReportService.model.sprinklerReport && (this.riskReportService.model.evidenceOfFireSprinkler === 'true' ||
        this.riskReportService.model.evidenceOfFireSprinkler === true)) {
        const occupants = this.riskReportService?.model?.occupants ? this.riskReportService?.model?.occupants[ 'section-occupants' ]?.occupants : null;
        const waterSupplyHydraulics = this.riskReportService.model.sprinklerReport?.asgrWaterSupply?.waterSupplyHydraulics;
        const waterSupplyPipeSchedules = this.riskReportService.model.sprinklerReport?.asgrWaterSupply?.waterSupplyPipeSchedules;
        // if (this.formlyBuilderService.isV1) {
          this.v1WaterSupplyHydraulicsArray = waterSupplyHydraulics?.v1WaterSupplyHydraulics?.length ? this.ClearSpocCalculations(waterSupplyHydraulics.v1WaterSupplyHydraulics, true) : this.createEmptySpocEntries(true, 1);
          this.v1WaterSupplyPipeScheduleArray = waterSupplyPipeSchedules?.v1WaterSupplyPipeSchedules?.length ? this.ClearSpocCalculations(waterSupplyPipeSchedules.v1WaterSupplyPipeSchedules, false) : this.createEmptySpocEntries(false, 1);
          this.getSpocEntries(occupants, this.v1WaterSupplyHydraulicsArray, this.v1WaterSupplyPipeScheduleArray);
        // }
        if (this.formlyBuilderService.isV2) {
          this.v2WaterSupplyHydraulicsArray = waterSupplyHydraulics?.v2WaterSupplyHydraulics?.length ? this.ClearSpocCalculations(waterSupplyHydraulics.v2WaterSupplyHydraulics, true) : this.createEmptySpocEntries(true, 1);
          this.v2WaterSupplyPipeScheduleArray = waterSupplyPipeSchedules?.v2WaterSupplyPipeSchedules?.length ? this.ClearSpocCalculations(waterSupplyPipeSchedules.v2WaterSupplyPipeSchedules, false) : this.createEmptySpocEntries(false, 1);
          this.getSpocEntries(occupants, this.v2WaterSupplyHydraulicsArray, this.v2WaterSupplyPipeScheduleArray);
        }

        // HydraulicDesignStandard is a hydrator field we need this info for sprinkler report calculation ,
        // This needs to be calculated when ever there is a change in occupants
        const hydraulicDesignStandard = this.formlyBuilderService.waterSupplyService.calculateHydraulicDesignStandard(this.sprinklerDesignList);

        // Changes to update the model
        const hydraulicsSPoc = {
          v1WaterSupplyHydraulics: [ ...this.v1WaterSupplyHydraulicsArray ],
          v2WaterSupplyHydraulics: [ ...this.v2WaterSupplyHydraulicsArray ]
        };
        const pipeScheduleSpoc = {
          v1WaterSupplyPipeSchedules: [ ...this.v1WaterSupplyPipeScheduleArray ],
          v2WaterSupplyPipeSchedules: [ ...this.v2WaterSupplyPipeScheduleArray ]
        };
        if (this.riskReportService.model.sprinklerReport.asgrWaterSupply) {
          this.riskReportService.model.sprinklerReport.asgrWaterSupply.waterSupplyHydraulics = hydraulicsSPoc;
          this.riskReportService.model.sprinklerReport.asgrWaterSupply.waterSupplyPipeSchedules = pipeScheduleSpoc;
          this.riskReportService.model.sprinklerReport.asgrWaterSupply.hydraulicDesignStandard = hydraulicDesignStandard;
        } else {
          this.riskReportService.model.sprinklerReport.asgrWaterSupply = {
            waterSupplyHydraulics: hydraulicsSPoc,
            waterSupplyPipeSchedules: pipeScheduleSpoc,
            hydraulicDesignStandard
          };
        }
        this.riskReportService.model = { ...this.riskReportService.model };

        // changes to update the RR
        const hydraulicsData = [];
        const pipeSchedulesData = [];
        if (this.formlyBuilderService.options.formState.isHydraulicEntryPresent) {
          this.v1WaterSupplyHydraulicsArray?.forEach((hydraulic) => {
            if (hydraulic.squareFeet > 0) {
              hydraulicsData.push(WaterSupplyHydraulicDataModel.to(hydraulic));
            }
          });
          this.v2WaterSupplyHydraulicsArray?.forEach((hydraulic) => {
            if (hydraulic.squareFeet > 0) {
              hydraulicsData.push(WaterSupplyHydraulicDataModel.to(hydraulic));
            }
          });
        }
        if(this.formlyBuilderService.options.formState.isPipeScheduleEntryPresent) {
          this.v1WaterSupplyPipeScheduleArray?.forEach((pipeSchedule) => {
            if (pipeSchedule.squareFeet > 0) {
              pipeSchedulesData.push(WaterSupplyPipeScheduleDataModel.to(pipeSchedule));
            }
          });
          this.v2WaterSupplyPipeScheduleArray?.forEach((pipeSchedule) => {
            if (pipeSchedule.squareFeet > 0) {
              pipeSchedulesData.push(WaterSupplyPipeScheduleDataModel.to(pipeSchedule));
            }
          });
        }        
        if (this.riskReportService?.report?.SprinklerReport) {
          if (this.riskReportService?.report?.SprinklerReport?.AsgrWaterSupply) {
            this.riskReportService.report.SprinklerReport.AsgrWaterSupply.WaterSupplyHydraulics = hydraulicsData;
            this.riskReportService.report.SprinklerReport.AsgrWaterSupply.WaterSupplyPipeSchedules = pipeSchedulesData;
            this.riskReportService.report.SprinklerReport.AsgrWaterSupply.HydraulicDesignStandard = hydraulicDesignStandard;
          } else {
            this.riskReportService.report.SprinklerReport.AsgrWaterSupply = {
              WaterSupplyHydraulics: hydraulicsData,
              WaterSupplyPipeSchedules: pipeSchedulesData,
              HydraulicDesignStandard: hydraulicDesignStandard
            };
          }
        } else {
          this.riskReportService.report = {
            SprinklerReport: {
              AsgrWaterSupply: {
                WaterSupplyHydraulics: hydraulicsData,
                WaterSupplyPipeSchedules: pipeSchedulesData,
                HydraulicDesignStandard: hydraulicDesignStandard
              }
            }
          };
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  getSpocEntries(occupants, waterSupplyHydraulicsArray, waterSupplyPipeScheduleArray) {
    let allPipeScheduleSpocEntryTotal = 0;
    let allHydraulicSpocEntryTotal = 0;
    let allSpecialStdEntryTotal = 0;
    const allNoneSprinklerDesign = [];
    if (occupants && occupants?.length) {
      occupants.forEach((occupant) => {
        if (!occupant.isDeleted) {
          if (occupant?.occupantLevels && occupant.occupantLevels.length > 0) {
            let spoc = occupant.spoc;
            const noneSprinklerDesign = [];
            let pipeScheduleSpocEntryTotal = 0;
            let hydraulicSpocEntryTotal = 0;
            let specialStdEntryTotal = 0;
            if (!occupant.spoc && occupant?.occupantScheduleNo) {
              const scheduleDetails: any = this.scheduleService.getScheduledetails(occupant?.occupantScheduleNo);
              spoc = scheduleDetails?.SPOC;
            }
            occupant.occupantLevels.map((occupantLevel) => {
              const level = {... occupantLevel};
              level.spoc = spoc;
              if (level && level.sprinklerDesign === 'None') {
                noneSprinklerDesign.push(level);                
              } else if (level && level.sprinklerDesign === 'Pipe Schedule') {
                const spocArea = this.updateSpocEntry(level, waterSupplyPipeScheduleArray, spoc);
                pipeScheduleSpocEntryTotal += spocArea;
                allPipeScheduleSpocEntryTotal += spocArea;
                this.formlyBuilderService.options.formState.isPipeScheduleEntryPresent = true;
              } else if (level &&
                level.sprinklerDesign === 'Hydraulic' ||
                level.sprinklerDesign === '13R') {
                const spocArea = this.updateSpocEntry(level, waterSupplyHydraulicsArray, spoc);
                hydraulicSpocEntryTotal += spocArea;
                allHydraulicSpocEntryTotal += spocArea;
                this.formlyBuilderService.options.formState.isHydraulicEntryPresent = true;
              } else if (level &&
                level.sprinklerDesign === 'Hydraulic - ESFR' ||
                level.sprinklerDesign === 'Hydraulic - CMDA' ||
                level.sprinklerDesign === 'Hydraulic - CMSA') {
                const spocArea = this.updateSpocEntry(level, waterSupplyHydraulicsArray, 7);
                specialStdEntryTotal += spocArea;
                allSpecialStdEntryTotal += spocArea;
                this.formlyBuilderService.options.formState.isHydraulicEntryPresent = true;
              }
              this.sprinklerDesignList.push(level.sprinklerDesign);
            });

            // If prodominent entries are 'Hydraulic' then add the none entry to hydraulic list
            // If there is special entries and hydraulic entries and the hydraulic entries are predominent
            // then add none entry to hydralic list
            // otherwise to special entry
            // If prodominent entries are pipe schedule then add the none entry to pipe schedule list
            if (noneSprinklerDesign && noneSprinklerDesign.length) {
              if (hydraulicSpocEntryTotal || specialStdEntryTotal || pipeScheduleSpocEntryTotal) {
                if (hydraulicSpocEntryTotal + specialStdEntryTotal >= pipeScheduleSpocEntryTotal) {
                  if (hydraulicSpocEntryTotal >= specialStdEntryTotal) {
                    noneSprinklerDesign.forEach((level) => {
                      this.updateSpocEntry(level, waterSupplyHydraulicsArray, spoc);
                    });
                  } else {
                    noneSprinklerDesign.forEach((level) => {
                      this.updateSpocEntry(level, waterSupplyHydraulicsArray, 7);
                    });
                  }
                } else {
                  noneSprinklerDesign.forEach((level) => {
                    this.updateSpocEntry(level, waterSupplyPipeScheduleArray, spoc);
                  });
                }
              } else {
                noneSprinklerDesign.forEach((level) => {
                  allNoneSprinklerDesign.push(level);
                });                
              }
            }
          }
        }
      });
    }

    // When there is a occupant with only None sprinklered design levels,
    // we need to add that entry to the prodominent entries(In total from all occupants)
    if (allNoneSprinklerDesign && allNoneSprinklerDesign.length) {
      if (allHydraulicSpocEntryTotal || allSpecialStdEntryTotal || allPipeScheduleSpocEntryTotal) {
        if (allHydraulicSpocEntryTotal + allSpecialStdEntryTotal >= allPipeScheduleSpocEntryTotal) {
          if (allHydraulicSpocEntryTotal >= allSpecialStdEntryTotal) {
            allNoneSprinklerDesign.forEach((level) => {
              this.updateSpocEntry(level, waterSupplyHydraulicsArray, level.spoc);
            });
          } else {
            allNoneSprinklerDesign.forEach((level) => {
              this.updateSpocEntry(level, waterSupplyHydraulicsArray, 7);
            });
          }
        } else {
          allNoneSprinklerDesign.forEach((level) => {
            this.updateSpocEntry(level, waterSupplyPipeScheduleArray, level.spoc);
          });
        }
      } 
    }


    if (waterSupplyHydraulicsArray?.length) {
      this.calculatePercentage(waterSupplyHydraulicsArray);
      this.findMostDemanding(waterSupplyHydraulicsArray);
    }
    if (waterSupplyPipeScheduleArray?.length) {
      this.calculatePercentage(waterSupplyPipeScheduleArray);
      this.findMostDemanding(waterSupplyPipeScheduleArray);
    }
  }

  // Update the spoc model with value from level details
  updateSpocEntry(level, spocArray, spocNumber) {
    let area = 0;
    if (spocArray?.length) {
      const index = spocArray.findIndex((spocEntry) => spocEntry.spocNumber === spocNumber);
      if (index + 1) {
        if (level.length && level.width) {
          area = level.length * level.width;
        }
        if (level.levelStop > 0 && level.levelStart < level.levelStop) {
          area = area * (level.levelStop - level.levelStart + 1);
        }
        spocArray[ index ].squareFeet += area;
      }
    }
    return area;
  }

  // Clear the spoc entry model and set default value
  ClearSpocCalculations(spocArray, isHydraulic) {
    if (spocArray?.length) {
      if (isHydraulic) {
        spocArray = spocArray.map((entry) => WaterSupplyHydraulicDataModel.clearSpocModel(entry));
      } else {
        spocArray = spocArray.map((entry) => WaterSupplyPipeScheduleDataModel.clearSpocModel(entry));
      }
    }
    return spocArray;
  }

  createEmptySpocEntries(isHydraulic, scope) {
    const reportId = this.riskReportService.model.sprinklerReport.reportId;
    const spocArray = [];
    for (let i = 1; i <= 7; i++) {
      if (isHydraulic) {
        const hydraulicData = WaterSupplyHydraulicDataModel.createEmptySpocModel(reportId, i, scope);
        spocArray.push(hydraulicData);
      } else {
        const pipeScheduleData = WaterSupplyPipeScheduleDataModel.createEmptySpocModel(reportId, i, scope);
        spocArray.push(pipeScheduleData);
      }
    }
    return spocArray;
  }

  // caculate percentage for spoc array
  calculatePercentage(spocArray) {
    if (spocArray?.length) {
      let total = 0;
      spocArray.forEach(entry => total += Number(entry.squareFeet));
      if (total) {
        spocArray = spocArray.map(entry => {
          const precentage = Number(Number(entry.squareFeet) / total * 100);
          entry.precentage = precentage;
          return entry;
        });
      }
    }
  }

  // find the most deamanding entry from the array and mark isMostDemanding as true
  findMostDemanding(spocArray) {
    if (spocArray?.length) {
      let mostDemandingEntryIndex;
      spocArray.forEach((entry, index) => {
        if (entry.squareFeet >= 5000 || entry.precentage >= 5) {
          mostDemandingEntryIndex = index;
        }
      });
      if (mostDemandingEntryIndex + 1) {
        spocArray[ mostDemandingEntryIndex ].isMostDemanding = true;
      }
    }
  }
}

