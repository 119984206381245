import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  getUserData(): any {}

  private hiddenTabsSubject = new BehaviorSubject<boolean>(false);
  private signOutActionSubject = new Subject();

  constructor(private http: HttpClient) {}
  get getFields() {
    return this.http.get<FormlyFieldConfig[]>('assets/json/user-form.json').toPromise();
  }

  showTabs(val) {
    this.hiddenTabsSubject.next(val);
  }

  getHiddenTabsSubject() {
    return this.hiddenTabsSubject.asObservable();
  }

  proceedSignOut(val = null) {
    this.signOutActionSubject.next(val);
  }

  getSignOutObservable() {
    return this.signOutActionSubject.asObservable();
  }
  
  getOptions(fieldName){
   if(fieldName == 'floors'){
    return [ { value: '1', label: 'Floor 1'},
    { value: '2', label: 'Floor 2'},
    { value: '3-wall', label: 'Floor 3'}];
   }   
  }

}
