import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { FormControl } from '@angular/forms';
import { Constants } from 'src/app/services/util-service/constants';
export class PhotosSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'reportPhotos',
      type: 'section-photos',
      wrappers: [ 'simple-section-wrapper' ],
      props: {
        label: 'Front and Rear Exterior Photos',
        //  required: true,
        isCollapsable: true,
        isCollapsed: false,
        isEdit: true,
        isDisabled: false,
        isCollapseIconDisabled:true,
        actionButtons: [
          { section: 'reportPhotos', action: 'add', label: Constants.btnText, isDisabled: false }
        ],
      },
      expressions: {
        // 'props.disabled': 'formState.disabled',
        // 'props.isDisabled': 'formState.disabled',
        // 'props.actionButtons[0].isDisabled': 'formState.disabled',
        // 'props.actionButtons[0].label': (model) => {
        //   if(model.length>0){
        //     return 'Edit';
        //   }
        //   return 'Add New';
        //  }
        'props.isCollapseIconDisabled': (field: FormlyFieldConfig) => {
       if(field?.fieldGroup[0]?.fieldGroup[0]?.formControl?.value==undefined || field?.fieldGroup[0]?.fieldGroup[0]?.formControl?.value ==null ||
        field?.fieldGroup[0]?.fieldGroup[2]?.formControl?.value==undefined || field?.fieldGroup[0]?.fieldGroup[2]?.formControl?.value ==null){
          field.props.isCollapseIconDisabled = true;
        }
        else field.props.isCollapseIconDisabled =false;
      }
    },
      fieldArray: {
        fieldGroup: [
          {
            key: 'reportPhotoIdentifier',
            defaultValue: 'undefined'
          },
          {
            key: 'reportIdentifier',
            defaultValue: 'undefined'
          },
          {
            key: 'photoIdentifier',
            defaultValue: 'undefined'
          },
          {
            key: 'reportPhotoType',
            defaultValue: 'undefined'
          }
        ]
      },
      validators: {
        photo: {
          expression: (control: FormControl, field: FormlyFieldConfig) => {
            // Check that the array contains a photo with reportPhotoType === "FRNT" & "REAR"
            let photoTypes;
            if (formlyBuilderService.mode === 0) {
              photoTypes = [ 'FRNT', 'REAR' ];
            }
            else if (formlyBuilderService.mode === 1) {
              photoTypes = [ 'FRNT' ];
            }
            const photos = control.value;
            if (photos && photos.length > 0) {
              const result = photoTypes.every(val => photos.find(src => src.reportPhotoType === val));
              if (!result) {
                if (field.props.isEmptySectionOnInit === undefined) {
                  field.props.isEmptySectionOnInit = true;
                }
              } else {
                field.props.isEmptySectionOnInit = false;
              }
              return result;
            }
            if (field.props.isEmptySectionOnInit === undefined) {
              field.props.isEmptySectionOnInit = true;
            }
            return false;
          },
          message: formlyBuilderService.mode === 1 ? 'Front photo is required' : 'Front and Rear photos are required'
        },
      }
    });
  }
}
