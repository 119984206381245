import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { MainDrainEstimationInputExcelModel, MainDrainEstimationModel, MainDrainEstimationOutputModel, MainDrainEstimationTrial } from 'src/app/models/formly-builder/main-drain-estimation.model';
import { FormlyErrorService } from '../../formly-error.service';
import { UtilService } from 'src/app/services/util-service/util.service';
import { Constants } from 'src/app/services/util-service/constants';
import { RiskAttachmentService } from '../../risk-attachment.service';
import { Guid } from 'guid-typescript';
import { AttachmentDataModel } from 'src/app/models/formly-builder/formly/full-risk/attachment-data.model';

@Injectable({
  providedIn: 'root',
})
export class MainDrainEstimationService {
  static staticInput: MainDrainEstimationModel = null;
  static staticOutput: MainDrainEstimationOutputModel = null;
  public isEstimationDone = false;

  constructor(
    private errorService: FormlyErrorService,
    private riskAttachmentService: RiskAttachmentService,
    private utilService: UtilService) { }

  public onApplyMainDrainEstimation(modalCtrl, field) {
    const formlyService = field?.options?.formState?.service;
    formlyService?.waterSupplyService?.setSupplySourceFieldValues(field, 'mainDrainEstimation');
    // formlyService?.waterSupplyService?.saveAttachment(field);

    // Create MainDrainEstimation.csv attachment    
    formlyService?.mainDrainEstimationService?.saveAttachment(field);

    modalCtrl?.dismiss();
  }

  public onCloseMainDrainEstimation(modalCtrl, field, initialModel) {
    field?.formControl?.patchValue(initialModel);
    field?.form?.get('mainDrainEstimation')?.markAsUntouched();
    modalCtrl?.dismiss();
  }

  getMainDrainLineOptions() {
    return [
      { value: 0.824, label: '0.824' },
      { value: 1.049, label: '1.049' },
      { value: 1.380, label: '1.380' },
      { value: 1.610, label: '1.610' },
      { value: 2.067, label: '2.067' },
    ];
  }

  getRiserPipeOptions() {
    return [
      { value: 0.824, label: '0.824' },
      { value: 1.049, label: '1.049' },
      { value: 1.380, label: '1.380' },
      { value: 1.610, label: '1.610' },
      { value: 2.067, label: '2.067' },
      { value: 2.469, label: '2.469' },
      { value: 3.068, label: '3.068' },
      { value: 3.548, label: '3.548' },
      { value: 4.026, label: '4.026' },
      { value: 5.047, label: '5.047' },
      { value: 6.065, label: '6.065' },
      { value: 7.981, label: '7.981' },
      { value: 10.020, label: '10.020' },
      { value: 11.938, label: '11.938' }
    ];
  }

  /**
   * Calculates an estimation according to the 
   * @param input Main Drain Estmation input data
   * @returns Observer callback will be notified with the calculated estimation as part of the Main Drain Estimation data
   */
  public estimateAsync(field: FormlyFieldConfig): Observable<MainDrainEstimationOutputModel> {
    return new Observable(subscriber => {
      const output = this.estimate(field);
      subscriber.next(output);
    });
  }

  /**
   * Calculates an estimation according to the 
   * @param input Main Drain Estmation input data
   * @returns Observer callback will be notified with the calculated estimation as part of the Main Drain Estimation data
   */
  public estimate(field: FormlyFieldConfig): MainDrainEstimationOutputModel {
    // Don't estimate if the field is not valid
    if (!field?.parent?.formControl?.valid) {
      field?.form?.markAllAsTouched();
    } else {
      MainDrainEstimationService.staticInput = field?.model;
      const excelInput = this.model2Excel(MainDrainEstimationService.staticInput);
      MainDrainEstimationService.staticOutput = this.calculate(excelInput);

      // Set the output fields
      field?.parent?.formControl?.get('staticPressure')?.setValue(Math.round(MainDrainEstimationService.staticOutput?.staticPressure));
      field?.parent?.formControl?.get('residualPressure')?.setValue(Math.round(MainDrainEstimationService.staticOutput?.residualPressure));
      field?.parent?.formControl?.get('flow')?.setValue(Math.round(MainDrainEstimationService.staticOutput?.flow));
      this.isEstimationDone = true;
      field.parent.parent.props.sheetButtons[ 1 ].isDisabled = false;
    }

    return (MainDrainEstimationService.staticOutput);
  }

  /**
   * 
   * @param reportIdentifier 
   * @param input 
   * @param output 
   * @returns 
   */
  public saveAttachment(field: FormlyFieldConfig): string {
    let cvsFileString = '';
    const separator = ',';
    const crlf = '\n';

    // Get Test Date field.fieldGroup[0]
    // let testDate = field?.parent?.formControl?.get('mainDrainEstimationTestDate')?.value;
    let testDate = field?.form?.get('mainDrainEstimationTestDate')?.value;
    if (!testDate) {
      const timestamp = new Date();
      testDate = timestamp.toISOString();
    }

    const input = MainDrainEstimationService.staticInput;
    const output = MainDrainEstimationService.staticOutput;

    // Create file content
    const useHorizontalLayout = false;
    if (input && output) {
      if (useHorizontalLayout) {
        cvsFileString +=
          'Timestamp, Lower gauge static pressure, Lower gauge residual pressure, Is lower gauge on the main drain line, ' +
          'Main drain connection elevation (in feet), Main drain discharge elevation (in feet), Lower gauge elevation, ' +
          'Diameter of main drain line (in inches)', 'Diameter of riser pipe (in inches)' +
          'Tee\'s in main drain line(not including branching off riser)', 'Gate valves in main drain line',
          'Angle valves in main drain line (flow turned 90° inside valve)',
          '90° elbows in main drain line', '45° elbows in main drain line', 'Length of main drain piping (ft)',
          'Static Pressure', 'Residual Pressure', 'Flow';
        cvsFileString += testDate + separator;
      
        // Input
        cvsFileString += '' + input.lowerGaugeStaticPressure + separator;
        cvsFileString += '' + input.lowerGaugeResidualPressure + separator;
        cvsFileString += '' + input.isLowerGaugeOnTheMainDrainLine + separator;

        cvsFileString += '' + input.mainDrainConnectionElevation + separator;
        cvsFileString += '' + input.mainDrainDischargeOutElevation + separator;
        cvsFileString += '' + input.lowerGaugeElevation + separator;
      
        cvsFileString += '' + input.diameterOfMainDrainLine + separator;
        cvsFileString += '' + input.diameterOfRiser + separator;
      
        cvsFileString += '' + input.teeItemsInMainDrainLine + separator;
        cvsFileString += '' + input.gateValvesInMainDrainLine + separator;
        cvsFileString += '' + input.angleValvesInMainDrainLine + separator;
        cvsFileString += '' + input.NinetyElbowsInMainDrainLine + separator;
        cvsFileString += '' + input.FourtyFiveElbowsInMainDrainLine + separator;
        cvsFileString += '' + input.lengthOfMainDrainPiping + separator;

        // Output
        cvsFileString += '' + output.staticPressure + separator;
        cvsFileString += '' + output.residualPressure + separator;
        cvsFileString += '' + output.flow + separator;
      } else {
        cvsFileString += 'Property, Value';               
        cvsFileString += 'Timestamp' + separator + testDate + crlf;

        // Input
        cvsFileString += 'Lower gauge static pressure' + separator + input.lowerGaugeStaticPressure + crlf;
        cvsFileString += 'Lower gauge residual pressure' + separator + input.lowerGaugeResidualPressure+ crlf;
        cvsFileString += 'Is lower gauge on the main drain line' + separator + input.isLowerGaugeOnTheMainDrainLine+ crlf;

        cvsFileString += 'Main drain connection elevation (in feet)' + separator + input.mainDrainConnectionElevation + crlf;
        cvsFileString += 'Main drain discharge elevation (in feet)' + separator + input.mainDrainDischargeOutElevation + crlf;
        cvsFileString += 'Lower gauge elevation' + separator + input.lowerGaugeElevation + crlf;

        cvsFileString += 'Diameter of main drain line (in inches)' + separator + input.diameterOfMainDrainLine + crlf;
        cvsFileString += 'Diameter of riser pipe (in inches)' + separator + input.diameterOfRiser + crlf;

        cvsFileString += 'Tee\'s in main drain line(not including branching off riser)' + separator + input.teeItemsInMainDrainLine + crlf;
        cvsFileString += 'Gate valves in main drain line' + separator + input.gateValvesInMainDrainLine + crlf;
        cvsFileString += 'Angle valves in main drain line (flow turned 90° inside valve)' + separator + input.angleValvesInMainDrainLine + crlf;
        cvsFileString += '90° elbows in main drain line' + separator + input.NinetyElbowsInMainDrainLine + crlf;
        cvsFileString += '45° elbows in main drain line' + separator + input.FourtyFiveElbowsInMainDrainLine + crlf;
        cvsFileString += 'Length of main drain piping (ft)' + separator + input.lengthOfMainDrainPiping + crlf;

        // Output
        cvsFileString += 'Static Pressure' + separator + output.staticPressure + crlf;
        cvsFileString += 'Residual Pressure' + separator + output.residualPressure + crlf;
        cvsFileString += 'Flow' + separator + output.flow + crlf;        
      }  
    }    

    // Convert standard text cvs file to base64 for compatibility with Amplify/S3 data transmission
    cvsFileString = btoa(cvsFileString);

    // Add attachment
    const orderIdFullOrderNumber = field?.options?.formState?.order?.OrderIdFullOrderNumber;
    const attachment = new AttachmentDataModel;
    attachment.reportIdentifier = field?.options?.formState?.service?.riskReport?.model?.reportIdentifier;
    attachment.reportAttachmentIdentifier = null;     // Null key means create new one if new attachment or else reuse existing
    attachment.fileName = 'MainDrainEstimation.csv';
    attachment.attachmentDescription = 'Main Drain Estimation Data';
    this.riskAttachmentService.saveRiskAttachment(
      field,
      attachment,
      cvsFileString,
      false,          // Don't delete
      true);          // Overwrite file with same filename

    return cvsFileString;
  }

  getNiceNumericValue(value) {
    return isNaN(value) ? 'N/A' : Math.round(value);
  }

  /**
   * 
   * @param inp Main Drain Estimation Input Data
   * @returns Main Drain Estimation Input Data in Excel notation
   */
  private model2Excel(inp: MainDrainEstimationModel): MainDrainEstimationInputExcelModel {
    return {
      R: +inp?.lowerGaugeResidualPressure,
      S: +inp?.lowerGaugeStaticPressure,
      Cond_A: inp?.isLowerGaugeOnTheMainDrainLine,
      Ec: +inp?.mainDrainConnectionElevation,
      Eo: +inp?.mainDrainDischargeOutElevation,
      Eg: +inp?.lowerGaugeElevation,
      MDd: +inp?.diameterOfMainDrainLine,
      Dr: +inp?.diameterOfRiser,

      Mdt: inp?.teeItemsInMainDrainLine ? +inp?.teeItemsInMainDrainLine : 0,                  // # of Tee's in main drain line (not including branching off riser)
      MDgv: inp?.gateValvesInMainDrainLine ? +inp?.gateValvesInMainDrainLine : 0,             // # of Gate valves in main drain line
      _MDav: inp?.angleValvesInMainDrainLine ? +inp?.angleValvesInMainDrainLine : 0,          // # of Angle valves in main drain line (flow turned 90° inside valve)
      _MD90: inp?.NinetyElbowsInMainDrainLine ? +inp?.NinetyElbowsInMainDrainLine : 0,        // # of 90° elbows in main drain line
      _MD45: inp?.FourtyFiveElbowsInMainDrainLine ? +inp?.FourtyFiveElbowsInMainDrainLine :0, // # of 45° elbows in main drain line    
      MDp: inp?.lengthOfMainDrainPiping ? +inp?.lengthOfMainDrainPiping : 0                   // Length of main drain piping(ft)      
    };
  }

  /**
   * Actual calculation method
   * @param input 
   * @returns 
   */
  private calculate(input: MainDrainEstimationInputExcelModel): MainDrainEstimationOutputModel {
    // Hardcoded initial output data with mandatory values
    const output: MainDrainEstimationOutputModel = {
      'staticPressure': 0,
      'residualPressure': 0,
      'flow': 0,
      'count': 3
    }

    try {
      // Intermediate calculations
      const subCondition: string = (input?.Eg - input?.Ec) < 1 ? "B" : "C";
      output.Condition = (input?.Cond_A === true) ? "A" : subCondition;
      output.QEST1 = 25 * input?.MDd * Math.pow(input?.R, 0.5);
      output.PE = (input?.Eg - input?.Eo) * 0.434;
      output.PVd = this.calculatePE(output.Condition, input?.MDd, input?.Dr);
      output.MDL = input?.Mdt * 11.6 + input?.MDgv * 1.2 + input?._MDav * 6.9 + input?._MD90 * 5.8 + input?._MD45 * 2.3 + input?.MDp + 3;

      // Trials
      output.trial = [];
      let seed = 25;
      for (let i = 0; i < 24; i++) {
        this.trial(i, seed, input, output);

        // Recalculate the seed
        const seedSubCondition = output.trial[i].Recalc ? seed + (output.trial[i].Difference / 100) : 0;
        seed = (Number.isNaN(output.trial[i].Recalc)) ? NaN : seedSubCondition;
      }

      // Estimation Output
      const flowCalc = this.calculateFlow(output.trial);
      output.count = flowCalc.count;
      output.staticPressure = input?.S + input?.Eg * 0.434;
      const residualSubCondition: number = (output.Condition === "C") ? 0 : (Math.pow(flowCalc.flow, 2) / output.PVd);
      output.residualPressure = input?.R + input?.Eg * 0.434 + residualSubCondition;
      output.flow = flowCalc.flow / flowCalc.count;
      // console.debug(`MDE Input  S=${input.S} R=${input.R} Cond.A=${input.Cond_A} Ec=${input.Ec} Eo=${input.Eo} Eg=${input.Eg}`);
      // console.debug(`           MDd=${input.MDd} Dr=${input.Dr} Mdt=${input.Mdt} MDgv=${input.MDgv} MDav=${input._MDav} MD90=${input._MD90} MD45=${input._MD45} MDp=${input.MDp}`);
      // console.debug(`    Output Cnd=${output.Condition} QEST1=${output.QEST1} PE=${output.PE} PVd=${output.PVd} MDL=${output.MDL}`);
      // console.debug(`           Static=${output.staticPressure} Residual=${output.residualPressure} Flow=${output.flow} cflow=${flowCalc.flow} count=${flowCalc.count}`);
    } catch (error) {
      console.error(error);
    }

    return output;
  }

  private trial(index: number, seed: number, input: MainDrainEstimationInputExcelModel, output: MainDrainEstimationOutputModel) {
    try {
      const Qest = seed * input?.MDd * Math.pow(input?.R, 0.5);
      const PV = output.Condition === "C" ? 0 : Math.pow(Qest, 2) / output.PVd;
      const PF = output.MDL * (4.52 * Math.pow(Qest, 1.85)) / (Math.pow(130, 1.85) * Math.pow(input?.MDd, 4.87));
      const P = input?.R + output.PE + PV - PF;
      const Q = 29.83 * 0.8 * Math.pow(input?.MDd, 2) * Math.pow(P, 0.5);
      const Difference = Q - Qest;
      const recalcSubCondition2: boolean = (Difference < -5);
      const recalcSubCondition1 = (Difference > 5) ? true : recalcSubCondition2;
      const Recalc = isNaN(Difference) ? NaN : recalcSubCondition1;
      const flow = Recalc ? 0 : (Qest + Q) / 2;

      output.trial.push({
        'index': index,
        'seed': seed,
        'Qest': Qest,
        'PV': PV,
        'PF': PF,
        'P': P,
        'Q': Q,
        'Difference': Difference,
        'Recalc': Recalc,
        'flow': flow
      });
    } catch (error) {
      console.error(error);
    }
  }

  private calculateFlow(trials: MainDrainEstimationTrial[]) {
    let totalFlow = 0;
    let count = 0;

    try {
      // Case 1. If all trial flows are NaN, we return NaN
      let isAllNaN = true;
      trials.forEach(trial => {
        if (!Number.isNaN(trial.flow)) {
          isAllNaN = false;
        }
      });
      if (isAllNaN) {
        return {
          'flow': NaN,
          'count': count
        };
      }

      // Case 2. For some NaN
      // Case 3. No NaN
      trials.forEach(trial => {
        if (trial.flow) {
          totalFlow += trial.flow;
          count++;
        }
      });
    } catch (error) {
      console.error(error);
    }

    return {
      'flow': totalFlow,
      'count': count
    }
  }

  private calculatePE(Condition, MDd, Dr) {
    if (Condition === 'A') {
      return 890 * Math.pow(MDd, 4);
    } else if (Condition === 'B') {
      return 890 * Math.pow(Dr, 4);
    } else {
      return 0;
    }
  }
}