import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toaster: any;

  constructor(public toast: ToastController) {}
  
  async showToast(message, showButton, options?: ToastOptions) {
    var button = {};
    if (showButton) {
      this.toast
        .create({
          message: message,
          position: 'bottom',
          cssClass: 'toast-class',
          duration: 2000,
          buttons: [
            {
              text: 'Undo',
              role: 'cancel',
              cssClass: 'undo',
              handler: () => {},
            },
          ],
        })
        .then((toastData) => {
          this.toaster = toastData;          
          toastData.present();
        });
    } else {
      let toastOptions: ToastOptions = {
        message: message,
        position: 'bottom',
        cssClass: 'toaster',
        duration: 2000,
      };
      if (options) {
        if (options.cssClass) {
          options.cssClass = `${toastOptions.cssClass} ${options.cssClass}`;
        }
        toastOptions = Object.assign(toastOptions, options)
      }
      this.toaster = await this.toast
        .create(toastOptions);
      this.toaster.present();  
      return this.toaster;
    }
  }
  async HideToast() {
    await this.toast.dismiss();
  }
}
