/* eslint-disable @typescript-eslint/naming-convention */
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { WaterSupplyCommentsDataModel } from './water-supply-comments-data.model';
import { WaterSupplyFrictionLossDataModel } from './water-supply-friction-loss-data.model';
import { WaterSupplyHydraulicDataModel } from './water-supply-hydraulic-data.model';
import { WaterSupplyPipeScheduleDataModel } from './water-supply-pipe-schedule-data.model';
import { FirePumpTestDataModel } from './fire-pump-test-data.model';
import { RackStorageObstructionDataModel } from './rack-storage-obstruction-data.model';
import { HydraulicDataDesignDataModel } from './hydraulic-data-design-data.model';

export class WaterSupplyDataModel {
  static sectionName = 'waterSupply';

  //Evaluation of water supplies
  evalutionOfWaterSupply: EvalutionOfWaterSupply;

  //Secondary Supply
  secondarySupply: SecondarySupply;
  //Hrdraulic
  hydraulicDensityConversion: HydraulicDensityConversion;
  hydraulicRackStorageObstruction: HydraulicRackStorageObstruction;
  hydraulicSystemDesign: HydraulicSystemDesign;

  // Supply Source
  supplySource: {
    ss1: SupplySource1;
    ss2: SupplySource2;
  };
  waterSupplyHydraulics: {
    v1WaterSupplyHydraulics: WaterSupplyHydraulicDataModel[];
    v2WaterSupplyHydraulics: WaterSupplyHydraulicDataModel[];
  };
  waterSupplyPipeSchedules: {
    v1WaterSupplyPipeSchedules: WaterSupplyPipeScheduleDataModel[];
    v2WaterSupplyPipeSchedules: WaterSupplyPipeScheduleDataModel[];
  };

  // Fire Pump Evaluation
  firePumpEvaluation: FirePumpEvaluation;

  reportId: string;
  isWaterSupplyNotEvaluated: boolean;
  hasHydraulicEsfrSprinklers: boolean;
  groundFloorArea: number;
  buildingCombustibilityClass: number;
  hasCreditableSupervisionOfWaterFlow: boolean;
  hasSpecialTankRuleApplies: boolean;
  pipeScheduleSprinklerDemandFlow: number;
  pipeScheduleSprinklerDemandPressure: number;
  pipeScheduleDurationInMinutes: number;
  pipeScheduleHoseDemandFlow: number;
  combustibleHollowSheathedFactor: number;
  hollowSheathedAreaRangeMinimum: number;
  dryPipeFactor: number;
  dpv: DpvSystem;
  isMainDrainFlowEstimation: boolean;
  buildingSizeSs1: string;
  buildingSizeSs2: string;
  percentProtectedAreaDependentOnPumpWithBypass: number;
  pumpCharges: number;
  isSupervisedPressureTank: boolean;
  sectionalizedVsSecondary: string;
  isWaterSupplyNotEvaluatedComment: string;
  hydraulicDesignStandard: string;
  waterSupplyComments?: any[];
  hydraulicDataDesign?: any[];
  waterSupplyFrictionLosses?: any[];
  asgrWaterSupplyFrictionLoss: any;
  adjustmentRequirement: WaterSupplyAdjustmentCodeValue;
  hasAdequatePressureOnMainDrainTest: boolean;

  static fromS3(waterSupplyData: any, sprinklerReport: any, reportInfo: any): WaterSupplyDataModel {
    const waterSupplyCommentsArray: any[] = [];
    const waterSupplyPipeSchedulesArray: any[] = [];
    const waterSupplyDataDesignArray: any[] = [];

    if (waterSupplyData?.WaterSupplyComments?.length) {
      waterSupplyData?.WaterSupplyComments.forEach((comment) => {
        const commentData = WaterSupplyCommentsDataModel.fromS3(comment);
        waterSupplyCommentsArray.push(commentData);
      });
    }

    if (sprinklerReport?.HydraulicDesignData?.length) {
       const dataLength= sprinklerReport?.HydraulicDesignData?.length;
      sprinklerReport.HydraulicDesignData.forEach((data) => {
        const dataDesignTable = HydraulicDataDesignDataModel.fromS3(waterSupplyData, dataLength, data);
        waterSupplyDataDesignArray.push(dataDesignTable);
      });
    }
    const waterSupplyHydraulics = WaterSupplyDataModel.getWaterSupplyHydralicData(waterSupplyData, sprinklerReport);
    const waterSupplyPipeSchedules = WaterSupplyDataModel.getWaterSupplyPipeSchedulesData(waterSupplyData, sprinklerReport);
    const evalutionOfWaterSupply = WaterSupplyDataModel.setEvaluationOfWaterSupply(waterSupplyData, sprinklerReport);
    const secondarySupply = WaterSupplyDataModel.setSecondarySupply(waterSupplyData);
    const supplySource1 = WaterSupplyDataModel.setSupplySource1(waterSupplyData, sprinklerReport);
    const supplySource2 = WaterSupplyDataModel.setSupplySource2(waterSupplyData, sprinklerReport);
    const firePumpEvaluation = WaterSupplyDataModel.setFirePumpEvaluationFields(
      waterSupplyData,
      supplySource1,
      supplySource2
    );
    const densityConversion = WaterSupplyDataModel.setHydraulicDensityConversion(waterSupplyData);
    const rackStorageObstruction = WaterSupplyDataModel.setHydraulicRackStorageObstruction(
      waterSupplyData,
      sprinklerReport
    );
    const systemDesign = WaterSupplyDataModel.setHydraulicSystemDesign(waterSupplyData);

    // get friction loss supply source
    const frictionLoss = WaterSupplyFrictionLossDataModel.fromS3(waterSupplyData?.WaterSupplyFrictionLosses);
    const fsSupplySource1 = [];
    const fsSupplySource2 = [];
    if (frictionLoss && frictionLoss.length > 0) {
      frictionLoss.forEach((item) => {
        if (item.supplySourceNumber === 1) {
          fsSupplySource1.push(item);
        } else {
          fsSupplySource2.push(item);
        }
      });
    }

    //pipe schedule
    const waterSupplyAdjustmentCodeValue = WaterSupplyDataModel.setWaterSupplyAdjustmentCodeValue(waterSupplyData);
    const dpvSytemValue = WaterSupplyDataModel.setDpvSystemValue(waterSupplyData);

    return {
      // TODO: send undefined if thereis no value
      reportId: waterSupplyData?.ReportId,
      isWaterSupplyNotEvaluated: waterSupplyData?.IsWaterSupplyNotEvaluated,
      hasHydraulicEsfrSprinklers: waterSupplyData?.HasHydraulicEsfrSprinklers,
      groundFloorArea: waterSupplyData?.GroundFloorArea,
      buildingCombustibilityClass: reportInfo?.CombustibilityClass ? Number(reportInfo.CombustibilityClass) : 0,
      hasCreditableSupervisionOfWaterFlow: waterSupplyData?.HasCreditableSupervisionOfWaterFlow,
      hasSpecialTankRuleApplies: waterSupplyData?.HasSpecialTankRuleApplies,
      pipeScheduleSprinklerDemandFlow: waterSupplyData?.PipeScheduleSprinklerDemandFlow,
      pipeScheduleSprinklerDemandPressure: waterSupplyData?.PipeScheduleSprinklerDemandPressure,
      pipeScheduleDurationInMinutes: waterSupplyData?.PipeScheduleDurationInMinutes,
      pipeScheduleHoseDemandFlow: waterSupplyData?.PipeScheduleHoseDemandFlow,
      adjustmentRequirement: waterSupplyAdjustmentCodeValue,
      combustibleHollowSheathedFactor: waterSupplyData?.CombustibleHollowSheathedFactor,
      hollowSheathedAreaRangeMinimum: waterSupplyData?.HollowSheathedAreaRangeMinimum,
      dryPipeFactor: waterSupplyData?.DryPipeFactor,
      dpv: dpvSytemValue,
      isMainDrainFlowEstimation: waterSupplyData?.IsMainDrainFlowEstimation,
      buildingSizeSs1: waterSupplyData?.BuildingSizeSs1,
      buildingSizeSs2: waterSupplyData?.BuildingSizeSs2,
      percentProtectedAreaDependentOnPumpWithBypass: waterSupplyData?.PercentProtectedAreaDependentOnPumpWithBypass,
      pumpCharges: waterSupplyData?.PumpCharges || waterSupplyData?.PumpCharges === 0 ? waterSupplyData?.PumpCharges : null,
      isSupervisedPressureTank: waterSupplyData?.IsSupervisedPressureTank,
      sectionalizedVsSecondary: waterSupplyData?.SectionalizedVsSecondary?.trim(),
      isWaterSupplyNotEvaluatedComment: waterSupplyData?.IsWaterSupplyNotEvaluatedComment,
      hydraulicDesignStandard: waterSupplyData?.HydraulicDesignStandard,
      waterSupplyComments: waterSupplyCommentsArray,
      waterSupplyHydraulics: waterSupplyHydraulics,
      waterSupplyPipeSchedules: waterSupplyPipeSchedules,
      evalutionOfWaterSupply: evalutionOfWaterSupply,
      hydraulicDensityConversion: densityConversion,
      hydraulicRackStorageObstruction: rackStorageObstruction,
      hydraulicSystemDesign: systemDesign,
      secondarySupply,
      supplySource: {
        ss1: supplySource1,
        ss2: supplySource2,
      },
      firePumpEvaluation,
      asgrWaterSupplyFrictionLoss: { supplySource1: fsSupplySource1, supplySource2: fsSupplySource2 },
      hydraulicDataDesign: waterSupplyDataDesignArray,
      hasAdequatePressureOnMainDrainTest: sprinklerReport?.AsgrSprinklerSystemTest?.HasAdequatePressureOnMainDrainTest
    };
  }

  static to(model: any, sectionEnum?: SectionEnum) {
    const commentsData = [];
    const frictionLossData = [];
    const hydraulicsData = [];
    const pipeSchedulesData = [];
    model?.waterSupplyComments?.forEach((comment) => {
      commentsData.push(WaterSupplyCommentsDataModel.to(comment));
    });
    model?.waterSupplyFrictionLosses?.forEach((frictionLoss) => {
      frictionLossData.push(WaterSupplyFrictionLossDataModel.to(frictionLoss));
    });
    model?.waterSupplyHydraulics?.v1WaterSupplyHydraulics?.forEach((hydraulic) => {
      if (hydraulic.squareFeet > 0) {
        hydraulicsData.push(WaterSupplyHydraulicDataModel.to(hydraulic));
      }
    });
    model?.waterSupplyHydraulics?.v2WaterSupplyHydraulics?.forEach((hydraulic) => {
      if (hydraulic.squareFeet > 0) {
        hydraulicsData.push(WaterSupplyHydraulicDataModel.to(hydraulic));
      }
    });
    model?.waterSupplyPipeSchedules?.v1WaterSupplyPipeSchedules?.forEach((pipeSchedule) => {
      if (pipeSchedule.squareFeet > 0) {
        pipeSchedulesData.push(WaterSupplyPipeScheduleDataModel.to(pipeSchedule));
      }
    });
    model?.waterSupplyPipeSchedules?.v2WaterSupplyPipeSchedules?.forEach((pipeSchedule) => {
      if (pipeSchedule.squareFeet > 0) {
        pipeSchedulesData.push(WaterSupplyPipeScheduleDataModel.to(pipeSchedule));
      }
    });
    let supplySource1 = model?.supplySource?.ss1?.supplySourceTypeSs1
      ? model?.supplySource?.ss1?.supplySourceTypeSs1
      : '';
    supplySource1 = model?.supplySource?.ss1?.locationSs1 && supplySource1 ?
      supplySource1 + '-' + model?.supplySource?.ss1?.locationSs1 : model?.supplySource?.ss1?.locationSs1 ?
        '-' + model?.supplySource?.ss1?.locationSs1 : supplySource1 + '-';
    let supplySource2 = model?.supplySource?.ss2?.supplySourceTypeSs2
      ? model?.supplySource?.ss2?.supplySourceTypeSs2
      : '';
    supplySource2 = model?.supplySource?.ss2?.locationSs2 && supplySource2 ?
      supplySource2 + '-' + model?.supplySource?.ss2?.locationSs2 : model?.supplySource?.ss2?.locationSs2 ?
        '-' + model?.supplySource?.ss2?.locationSs2 : supplySource2 + '-';

    // Prevent divide by zero with HydraulicDesignDensity
    let savedHydraulicDesignDensity = model?.hydraulicSystemDesign?.hydraulicDesignDensity ?
      +model?.hydraulicSystemDesign?.hydraulicDesignDensity
      : model?.hydraulicSystemDesign?.hydraulicDesignDensity;
    if (savedHydraulicDesignDensity === 0) {
      savedHydraulicDesignDensity = null;
    }
    return {
      ReportId: model?.reportId,
      IsWaterSupplyNotEvaluated: model?.isWaterSupplyNotEvaluated ? model?.isWaterSupplyNotEvaluated : false,

      HydraulicDesignDensityComment: model?.hydraulicSystemDesign?.hydraulicDesignDensityComment ? model?.hydraulicSystemDesign?.hydraulicDesignDensityComment : '',
      HydraulicDesignMethod: model?.hydraulicSystemDesign?.hydraulicDesignMethod
        ? model?.hydraulicSystemDesign?.hydraulicDesignMethod
        : null,
      HydraulicDesignDensity: savedHydraulicDesignDensity || savedHydraulicDesignDensity === 0 ? savedHydraulicDesignDensity : null ,
      HydraulicRemoteArea: model?.hydraulicSystemDesign?.hydraulicRemoteArea || model?.hydraulicSystemDesign?.hydraulicRemoteArea === 0
        ? +model?.hydraulicSystemDesign?.hydraulicRemoteArea : null,
      HydraulicWaterDemandFlow: model?.hydraulicSystemDesign?.hydraulicWaterDemandFlow || model?.hydraulicSystemDesign?.hydraulicWaterDemandFlow === 0
        ? +model?.hydraulicSystemDesign?.hydraulicWaterDemandFlow : null,
      HydraulicWaterDemandPressure: model?.hydraulicSystemDesign?.hydraulicWaterDemandPressure || model?.hydraulicSystemDesign?.hydraulicWaterDemandPressure === 0
        ? +model?.hydraulicSystemDesign?.hydraulicWaterDemandPressure : null,
      IsHydraulicWaterDemandLocationBor: model?.hydraulicSystemDesign?.isHydraulicWaterDemandLocationBor !== null && model?.hydraulicSystemDesign?.isHydraulicWaterDemandLocationBor !== undefined ? 
        model?.hydraulicSystemDesign?.isHydraulicWaterDemandLocationBor : null,
      HydraulicIncludesHoseDemand: model?.hydraulicSystemDesign?.hydraulicIncludesHoseDemand !== null && model?.hydraulicSystemDesign?.hydraulicIncludesHoseDemand !== undefined ? model?.hydraulicSystemDesign?.hydraulicIncludesHoseDemand : null,
      HydraulicHoseDemand: model?.hydraulicSystemDesign?.hydraulicHoseDemand ||  model?.hydraulicSystemDesign?.hydraulicHoseDemand === 0 
        ? +model.hydraulicSystemDesign?.hydraulicHoseDemand : null,
      HydraulicWaterSupplyDuration: model?.hydraulicSystemDesign?.hydraulicWaterSupplyDuration || model?.hydraulicSystemDesign?.hydraulicWaterSupplyDuration === 0
        ? +model?.hydraulicSystemDesign?.hydraulicWaterSupplyDuration : null,
      HydraulicInformationSource: model?.hydraulicSystemDesign?.hydraulicInformationSource
        ? model?.hydraulicSystemDesign?.hydraulicInformationSource
        : null,

      HasHydraulicEsfrSprinklers: model?.hasHydraulicEsfrSprinklers ? model?.hasHydraulicEsfrSprinklers : false,
      HydraulicApplyDensityConversion: model?.hydraulicDensityConversion?.hydraulicApplyDensityConversion,
      HydraulicNewDesignDensity: model?.hydraulicDensityConversion?.hydraulicNewDesignDensity || model?.hydraulicDensityConversion?.hydraulicNewDesignDensity === 0
        ? +model?.hydraulicDensityConversion?.hydraulicNewDesignDensity : null,
      HydraulicNewDesignArea: model?.hydraulicDensityConversion?.hydraulicNewDesignArea || model?.hydraulicDensityConversion?.hydraulicNewDesignArea === 0
        ? +model?.hydraulicDensityConversion?.hydraulicNewDesignArea : null,
      HydraulicNewHoseDemand: model?.hydraulicDensityConversion?.hydraulicNewHoseDemand || model?.hydraulicDensityConversion?.hydraulicNewHoseDemand === 0
        ? +model?.hydraulicDensityConversion?.hydraulicNewHoseDemand : null,
      HydraulicNewWaterSupplyDuration: model?.hydraulicDensityConversion?.hydraulicNewWaterSupplyDuration || model?.hydraulicDensityConversion?.hydraulicNewWaterSupplyDuration === 0
        ? +model?.hydraulicDensityConversion?.hydraulicNewWaterSupplyDuration : null,
      GroundFloorArea: model?.groundFloorArea ? model?.groundFloorArea : 0,
      BuildingCombustibilityClass: model?.buildingCombustibilityClass ? Number(model.buildingCombustibilityClass) : 0,
      HasCreditableSupervisionOfWaterFlow: model?.hasCreditableSupervisionOfWaterFlow ? model?.hasCreditableSupervisionOfWaterFlow : false,
      HasSpecialTankRuleApplies: model?.hasSpecialTankRuleApplies ? model?.hasSpecialTankRuleApplies : false,
      PipeScheduleSprinklerDemandFlow: model?.pipeScheduleSprinklerDemandFlow || model?.pipeScheduleSprinklerDemandFlow === 0 ?  model?.pipeScheduleSprinklerDemandFlow : null,
      PipeScheduleSprinklerDemandPressure: model?.pipeScheduleSprinklerDemandPressure || model?.pipeScheduleSprinklerDemandPressure === 0 ? model?.pipeScheduleSprinklerDemandPressure : null,
      PipeScheduleDurationInMinutes: model?.pipeScheduleDurationInMinutes || model?.pipeScheduleDurationInMinutes === 0 ? model?.pipeScheduleDurationInMinutes : null,
      PipeScheduleHoseDemandFlow: model?.pipeScheduleHoseDemandFlow || model?.pipeScheduleHoseDemandFlow === 0 ? model?.pipeScheduleHoseDemandFlow : null,
      WaterSupplyAdjustmentCodeValue: model?.adjustmentRequirement?.waterSupplyAdjustmentCodeValue ?
        model?.adjustmentRequirement?.waterSupplyAdjustmentCodeValue : 'UNAD',
      CombustibleHollowSheathedFactor: model?.combustibleHollowSheathedFactor || model?.combustibleHollowSheathedFactor === 0 ? model?.combustibleHollowSheathedFactor : null,
      HollowSheathedAreaRangeMinimum: model?.hollowSheathedAreaRangeMinimum ,
      DryPipeFactor: model?.dryPipeFactor || model?.dryPipeFactor === 0 ? model?.dryPipeFactor : null,
      DpvSystem: model?.dpv?.dpvSystem ? model?.dpv?.dpvSystem : null,
      PipeScheduleNumberOfSprinklers: model?.adjustmentRequirement?.pipeScheduleNumberOfSprinklers || model?.adjustmentRequirement?.pipeScheduleNumberOfSprinklers === 0
        ? Number(model?.adjustmentRequirement?.pipeScheduleNumberOfSprinklers) : null,
      IsMainDrainFlowEstimation: model?.supplySource?.ss1?.supplySourceTypeSs1 === 'Main Drain Estimation' ? true : false,
      BuildingHeightInFeet:
        model?.evalutionOfWaterSupply?.buildingHeightInFeet ? Number(model?.evalutionOfWaterSupply?.buildingHeightInFeet) : 0,
      IsBuildingHeightSameAsDesignAreaHeight: (model?.evalutionOfWaterSupply?.isBuildingHeightSameAsDesignAreaHeight &&
        (model?.evalutionOfWaterSupply?.isBuildingHeightSameAsDesignAreaHeight === 'true' ||
          model?.evalutionOfWaterSupply?.isBuildingHeightSameAsDesignAreaHeight === true)) ? true : false,
      SupplySource1: supplySource1 !== '-' && supplySource1 ? supplySource1 : null,
      StaticPressureSs1: model?.supplySource?.ss1?.staticPressureSs1 || model?.supplySource?.ss1?.staticPressureSs1 === 0
        ? +model?.supplySource?.ss1?.staticPressureSs1 : null,
      ResidualPressureSs1: model?.supplySource?.ss1?.residualPressureSs1 || model?.supplySource?.ss1?.residualPressureSs1 === 0
        ? +model?.supplySource?.ss1?.residualPressureSs1 : null,
      WaterFlowSs1: model?.supplySource?.ss1?.waterFlowSs1 || model?.supplySource?.ss1?.waterFlowSs1 === 0
        ? +model?.supplySource?.ss1?.waterFlowSs1 : null,
      StoredVolumeSs1: model?.supplySource?.ss1?.storedVolumeSs1 || model?.supplySource?.ss1?.storedVolumeSs1 === 0
        ? +model?.supplySource?.ss1?.storedVolumeSs1 : null,
      TestDateSs1: model?.supplySource?.ss1?.testDateSs1,
      FeetGaugeSs1: model?.supplySource?.ss1?.feetGaugeSs1 || model?.supplySource?.ss1?.feetGaugeSs1 === 0
        ? +model?.supplySource?.ss1?.feetGaugeSs1 : null,
      BuildingSizeSs1: model?.buildingSizeSs1 ? model.buildingSizeSs1 : null,
      SupplySource2: supplySource2 !== '-' && supplySource2 ? supplySource2 : null,
      StaticPressureSs2: model?.supplySource?.ss2?.staticPressureSs2 || model?.supplySource?.ss2?.staticPressureSs2 === 0
        ? +model?.supplySource?.ss2?.staticPressureSs2 : null,
      ResidualPressureSs2: model?.supplySource?.ss2?.residualPressureSs2 || model?.supplySource?.ss2?.residualPressureSs2 === 0
        ? +model?.supplySource?.ss2?.residualPressureSs2 : null,
      WaterFlowSs2: model?.supplySource?.ss2?.waterFlowSs2 || model?.supplySource?.ss2?.waterFlowSs2 === 0
        ? +model?.supplySource?.ss2?.waterFlowSs2 : null,
      StoredVolumeSs2: model?.supplySource?.ss2?.storedVolumeSs2 || model?.supplySource?.ss2?.storedVolumeSs2 === 0
        ? +model?.supplySource?.ss2?.storedVolumeSs2 : null,
      TestDateSs2: model?.supplySource?.ss2?.testDateSs2,
      FeetGaugeSs2: model?.supplySource?.ss2?.feetGaugeSs2 || model?.supplySource?.ss2?.feetGaugeSs2 === 0
        ? +model?.supplySource?.ss2?.feetGaugeSs2 : null,
      BuildingSizeSs2: model?.buildingSizeSs2 ? model?.buildingSizeSs2 : null,
      SoleSourceDependency: model?.firePumpEvaluation?.soleSourceDependency ? model.firePumpEvaluation.soleSourceDependency : null,
      PercentProtectedAreaDependentOnPump: model?.firePumpEvaluation?.percentProtectedAreaDependentOnPump !== null &&  model?.firePumpEvaluation?.percentProtectedAreaDependentOnPump !== undefined 
        && model?.firePumpEvaluation?.percentProtectedAreaDependentOnPump !== '' ? Number(model?.firePumpEvaluation?.percentProtectedAreaDependentOnPump) : null,
      PercentProtectedAreaDependentOnPumpWithBypass: model?.percentProtectedAreaDependentOnPumpWithBypass || model?.percentProtectedAreaDependentOnPumpWithBypass === 0 ?
        model?.percentProtectedAreaDependentOnPumpWithBypass : null,
      PumpCharges: model?.pumpCharges || model?.pumpCharges === 0 ? model?.pumpCharges : null,
      IsSupervisedPump: model?.firePumpEvaluation?.isSupervisedPump !== null && model?.firePumpEvaluation?.isSupervisedPump !== undefined ? model?.firePumpEvaluation?.isSupervisedPump : null,
      IsTwoPowerSourcePump: model?.firePumpEvaluation?.isTwoPowerSourcePump !== null && model?.firePumpEvaluation?.isTwoPowerSourcePump !== undefined ? model?.firePumpEvaluation?.isTwoPowerSourcePump : null,
      NonBypassPumpForSupplySource: model?.firePumpEvaluation?.nonBypassPumpForSupplySource ?
        model?.firePumpEvaluation?.nonBypassPumpForSupplySource : '',
      BypassPumpForSupplySource: model?.firePumpEvaluation?.bypassPumpForSupplySource ?
        model?.firePumpEvaluation?.bypassPumpForSupplySource : '',
      IsUndependablePumpElectricSupply: model?.firePumpEvaluation?.isUndependablePumpElectricSupply ?
        model?.firePumpEvaluation?.isUndependablePumpElectricSupply : false,
      IsNaturalGasPump: model?.firePumpEvaluation?.isNaturalGasPump ? model?.firePumpEvaluation?.isNaturalGasPump : false,
      IsGasolinePump: model?.firePumpEvaluation?.isGasolinePump ? model?.firePumpEvaluation?.isGasolinePump : false,
      IsNonStandardInstallationPump: model?.firePumpEvaluation?.isNonStandardInstallationPump ?
        model?.firePumpEvaluation?.isNonStandardInstallationPump : false,
      IsUnlistedPump: model?.firePumpEvaluation?.isUnlistedPump ? model?.firePumpEvaluation?.isUnlistedPump : false,
      IsNonStandardAuxilliaryEquipment: model?.firePumpEvaluation?.isNonStandardAuxilliaryEquipment ?
        model?.firePumpEvaluation?.isNonStandardAuxilliaryEquipment : false,
      IsUnsatisfactorySuction: model?.firePumpEvaluation?.isUnsatisfactorySuction ?
        model?.firePumpEvaluation?.isUnsatisfactorySuction : false,
      IsSupervisedPressureTank: model?.isSupervisedPressureTank !== null &&  model?.isSupervisedPressureTank !== undefined ?  model?.isSupervisedPressureTank : null,
      SectionalizedVsSecondary: model?.secondarySupply?.sectionalizedVsSecondary ? model?.secondarySupply.sectionalizedVsSecondary : '',
      PressureTankSs1:
        model?.supplySource?.ss1?.supplySourceTypeSs1 === 'Pressure Tank' &&
          model?.supplySource?.ss1?.isSupervisedPressureTankSs1
          ? true
          : false,
      PressureTankSs2:
        model?.supplySource?.ss2?.supplySourceTypeSs2 === 'Pressure Tank' &&
          model?.supplySource?.ss2?.isSupervisedPressureTankSs2
          ? true
          : false,
      HasImproperPumpMaintenance: model?.firePumpEvaluation?.hasImproperPumpMaintenance !== null && model?.firePumpEvaluation?.hasImproperPumpMaintenance !== undefined ? model?.firePumpEvaluation?.hasImproperPumpMaintenance : null,
      IsEvaluatedThroughBypassOnly: model?.firePumpEvaluation?.isEvaluatedThroughBypassOnly ? model?.firePumpEvaluation?.isEvaluatedThroughBypassOnly : false,
      IsFirePumpInBothWaterSupplies: model?.firePumpEvaluation?.isFirePumpInBothWaterSupplies ? model?.firePumpEvaluation?.isFirePumpInBothWaterSupplies: false,
      IsWaterSupplyNotEvaluatedComment: model?.isWaterSupplyNotEvaluatedComment ? model?.isWaterSupplyNotEvaluatedComment : '',
      TopLineSprinklerHeightInFeet: model?.evalutionOfWaterSupply?.topLineSprinklerHeightInFeet || model?.evalutionOfWaterSupply?.topLineSprinklerHeightInFeet === 0 ? model?.evalutionOfWaterSupply?.topLineSprinklerHeightInFeet : null,
      HydraulicDesignStandard: model?.hydraulicDesignStandard ? model?.hydraulicDesignStandard : null,
      WaterSupplyComments: commentsData,
      WaterSupplyFrictionLosses: frictionLossData,
      WaterSupplyHydraulics: hydraulicsData,
      WaterSupplyPipeSchedules: pipeSchedulesData,
      // Report: null
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
    return false;
  }
  // To set EvaluationOfWaterSupply Field values
  static setEvaluationOfWaterSupply(waterSupply: any, sprinklerReport: any) {
    const isBuildingHeightSameAsDesignAreaHeight = (waterSupply?.IsBuildingHeightSameAsDesignAreaHeight !== null &&
      waterSupply?.IsBuildingHeightSameAsDesignAreaHeight !== undefined) ? waterSupply?.IsBuildingHeightSameAsDesignAreaHeight
      : waterSupply?.TopLineSprinklerHeightInFeet ? false : true;
    const evalutionOfWaterSupply = {
      buildingHeightInFeet: waterSupply?.BuildingHeightInFeet !== 0 ? waterSupply?.BuildingHeightInFeet : null,
      isBuildingHeightSameAsDesignAreaHeight: isBuildingHeightSameAsDesignAreaHeight,
      topLineSprinklerHeightInFeet: waterSupply?.TopLineSprinklerHeightInFeet,
      hasAdequateMaintenance: sprinklerReport?.HasAdequateMaintenance,
    };
    return evalutionOfWaterSupply;
  }
  // To set SecondarySupply Field values
  static setSecondarySupply(waterSupply: any) {
    const secondarySupply = {
      sectionalizedVsSecondary: waterSupply?.SectionalizedVsSecondary?.trim(),
    };
    return secondarySupply;
  }

  //To set SupplySource1 Field Values
  static setSupplySource1(waterSupply: any, sprinklerReport: any) {
    let supplySourceTypeSs1;
    let locationSs1;
    if(waterSupply?.SupplySource1?.indexOf('-') < 0) {
      supplySourceTypeSs1 = '';
      locationSs1 = waterSupply?.SupplySource1;
    } else {
      supplySourceTypeSs1 = waterSupply?.SupplySource1?.split('-')[ 0 ];
      locationSs1 = waterSupply?.SupplySource1?.split('-')[ 1 ];
    }
    const firePumpTestsSs1 = [];
    if (sprinklerReport.AsgrSprinklerSystemTest?.FirePumpTests?.length) {
      sprinklerReport.AsgrSprinklerSystemTest.FirePumpTests.forEach((test) => {
        const firePumpTestsData = FirePumpTestDataModel.fromS3(test);
        firePumpTestsSs1.push(firePumpTestsData);
      });
    }
    const supplySourceType = waterSupply?.IsMainDrainFlowEstimation ? 'Main Drain Estimation' :
      waterSupply?.PressureTankSs1 ? 'Pressure Tank' : supplySourceTypeSs1;
    return {
      supplySourceTypeSs1: supplySourceType,
      locationSs1,
      testDateSs1: waterSupply?.TestDateSs1,
      staticPressureSs1: waterSupply?.StaticPressureSs1,
      residualPressureSs1: waterSupply?.ResidualPressureSs1,
      waterFlowSs1: waterSupply?.WaterFlowSs1,
      storedVolumeSs1: waterSupply?.StoredVolumeSs1,
      feetGaugeSs1: waterSupply?.FeetGaugeSs1,
      isSupervisedPressureTankSs1: waterSupply?.PressureTankSs1 ? true : false,
      firePumpTests: firePumpTestsSs1,
    };
  }

  //To set SupplySource2 Field Values
  static setSupplySource2(waterSupply: any, sprinklerReport) {
    let supplySourceTypeSs2;
    let locationSs2;
    if (waterSupply?.SupplySource2?.indexOf('-') < 0) {
      supplySourceTypeSs2 = '';
      locationSs2 = waterSupply?.SupplySource2;
    } else {
      supplySourceTypeSs2 = waterSupply?.SupplySource2?.split('-')[ 0 ];
      locationSs2 = waterSupply?.SupplySource2?.split('-')[ 1 ];
    }    
    const firePumpTestsSs2 = [];
    if (sprinklerReport.AsgrSprinklerSystemTest?.FirePumpTests?.length) {
      sprinklerReport.AsgrSprinklerSystemTest.FirePumpTests.forEach((test) => {
        const firePumpTestsData = FirePumpTestDataModel.fromS3(test);
        firePumpTestsSs2.push(firePumpTestsData);
      });
    }
    const supplySourceType = waterSupply?.PressureTankSs1 ? 'Pressure Tank' : supplySourceTypeSs2;
    return {
      supplySourceTypeSs2: supplySourceType,
      locationSs2,
      testDateSs2: waterSupply?.TestDateSs2,
      staticPressureSs2: waterSupply?.StaticPressureSs2,
      residualPressureSs2: waterSupply?.ResidualPressureSs2,
      waterFlowSs2: waterSupply?.WaterFlowSs2,
      storedVolumeSs2: waterSupply?.StoredVolumeSs2,
      feetGaugeSs2: waterSupply?.FeetGaugeSs2,
      isSupervisedPressureTankSs2: waterSupply?.PressureTankSs2 ? true : false,
      firePumpTests: firePumpTestsSs2,
    };
  }
  // To set Hydraulic Density Conversion field values
  static setHydraulicDensityConversion(waterSupply: any) {
    const hydraulicDensityConversion = {
      hydraulicNewDesignDensity: waterSupply?.HydraulicNewDesignDensity,
      hydraulicApplyDensityConversion:
        (waterSupply?.HydraulicNewDesignDensity !== null && waterSupply?.HydraulicNewDesignDensity !== undefined) ||
          (waterSupply?.HydraulicNewDesignArea !== null && waterSupply?.HydraulicNewDesignArea !== undefined) ||
          (waterSupply?.HydraulicNewHoseDemand !== null && waterSupply?.HydraulicNewHoseDemand !== undefined) ||
          (waterSupply?.HydraulicNewWaterSupplyDuration !== null &&
            waterSupply?.HydraulicNewWaterSupplyDuration !== undefined)
          ? true
          : waterSupply?.HydraulicApplyDensityConversion,
      hydraulicNewDesignArea: waterSupply?.HydraulicNewDesignArea,
      hydraulicNewHoseDemand: waterSupply?.HydraulicNewHoseDemand,
      hydraulicNewWaterSupplyDuration: waterSupply?.HydraulicNewWaterSupplyDuration,
    };
    return hydraulicDensityConversion;
  }
  // To set HydraulicRackStorageObstruction Field values
  static setHydraulicRackStorageObstruction(waterSupply: any, sprinklerReport) {
    const rackStorageObstruction = RackStorageObstructionDataModel.fromS3(sprinklerReport?.AsgrRackStorageObstruction);

    const hydraulicRackStorageObstruction = {
      isRackStorageObstruction:
        (sprinklerReport?.AsgrRackStorageObstruction?.LevelsMissingSprinklers !== null &&
          sprinklerReport?.AsgrRackStorageObstruction?.LevelsMissingSprinklers !== undefined) ||
          (sprinklerReport?.AsgrRackStorageObstruction?.HasConsecutiveMissingLevels !== null &&
            sprinklerReport?.AsgrRackStorageObstruction?.HasConsecutiveMissingLevels !== undefined &&
            sprinklerReport?.AsgrRackStorageObstruction?.HasConsecutiveMissingLevels == true) ||
          (sprinklerReport?.AsgrRackStorageObstruction?.IsDoubleRowStorage !== null &&
            sprinklerReport?.AsgrRackStorageObstruction?.IsDoubleRowStorage !== undefined &&
            sprinklerReport?.AsgrRackStorageObstruction?.IsDoubleRowStorage == true)
          ? true
          : false,
      rackStorageObstruction,
    };
    return hydraulicRackStorageObstruction;
  }

  static setHydraulicSystemDesign(waterSupply: any) {
    const systemDesign = {
      hydraulicDesignMethod: waterSupply?.HydraulicDesignMethod,
      hydraulicDesignDensity: waterSupply?.HydraulicDesignDensity,
      hydraulicDesignDensityComment: waterSupply?.HydraulicDesignDensityComment,
      hydraulicRemoteArea: waterSupply?.HydraulicRemoteArea,
      hydraulicWaterDemandFlow: waterSupply?.HydraulicWaterDemandFlow,
      hydraulicWaterDemandPressure: waterSupply?.HydraulicWaterDemandPressure,
      isHydraulicWaterDemandLocationBor: waterSupply?.IsHydraulicWaterDemandLocationBor,
      hydraulicIncludesHoseDemand: waterSupply?.HydraulicIncludesHoseDemand,
      hydraulicHoseDemand: waterSupply?.HydraulicHoseDemand,
      hydraulicWaterSupplyDuration: waterSupply?.HydraulicWaterSupplyDuration,
      hydraulicInformationSource: waterSupply?.HydraulicInformationSource,
    };
    return systemDesign;
  }
  // To set FirePumpEvaluation Field values
  static setFirePumpEvaluationFields(waterSupply: any, supplySource1: any, supplySource2: any) {
    return {
      percentProtectedAreaDependentOnPump: (waterSupply?.PercentProtectedAreaDependentOnPump !== null &&
        waterSupply?.PercentProtectedAreaDependentOnPump !== undefined) ? waterSupply?.PercentProtectedAreaDependentOnPump : null,
      isSupervisedPump: waterSupply?.IsSupervisedPump,
      isTwoPowerSourcePump: waterSupply?.IsTwoPowerSourcePump,
      hasImproperPumpMaintenance: waterSupply?.HasImproperPumpMaintenance,
      isEvaluatedThroughBypassOnly: waterSupply?.IsEvaluatedThroughBypassOnly,
      isFirePumpInBothWaterSupplies: waterSupply?.IsFirePumpInBothWaterSupplies,
      soleSourceDependency: waterSupply?.SoleSourceDependency ? waterSupply?.SoleSourceDependency.trim() : '',
      nonBypassPumpForSupplySource: waterSupply?.NonBypassPumpForSupplySource,
      bypassPumpForSupplySource: waterSupply?.BypassPumpForSupplySource,
      isNaturalGasPump: waterSupply?.IsNaturalGasPump,
      isGasolinePump: waterSupply?.IsGasolinePump,
      isUndependablePumpElectricSupply: waterSupply?.IsUndependablePumpElectricSupply,
      isNonStandardInstallationPump: waterSupply?.IsNonStandardInstallationPump,
      isUnlistedPump: waterSupply?.IsUnlistedPump,
      isNonStandardAuxilliaryEquipment: waterSupply?.IsNonStandardAuxilliaryEquipment,
      isUnsatisfactorySuction: waterSupply?.IsUnsatisfactorySuction,
    };
  }

  // To set WaterSupplyAdjustmentCodeValue field values
  static setWaterSupplyAdjustmentCodeValue(waterSupply: any) {
    const waterSupplyAdjustment = {
      reduce: waterSupply?.WaterSupplyAdjustmentCodeValue,
      numberOfSprinklers: waterSupply?.WaterSupplyAdjustmentCodeValue,
      unusualConditions: waterSupply?.WaterSupplyAdjustmentCodeValue,
      unjastedSprinkler: waterSupply?.WaterSupplyAdjustmentCodeValue,
      pipeScheduleNumberOfSprinklers: waterSupply?.PipeScheduleNumberOfSprinklers,
      waterSupplyAdjustmentCodeValue: waterSupply?.WaterSupplyAdjustmentCodeValue,
    };
    return waterSupplyAdjustment;
  }

  // To set DpvSystem field values
  static setDpvSystemValue(waterSupply: any) {
    const dpvSystemValue = {
      dpvSystem: waterSupply?.DpvSystem,
    };
    return dpvSystemValue;
  }

  // Get the get WaterSupplyHydraulics from the s3 data
  static getWaterSupplyHydralicData(waterSupplyData, sprinklerReport) {
    const v1WaterSupplyHydraulicsArray: any[] = [];
    const v2WaterSupplyHydraulicsArray: any[] = [];
    if (waterSupplyData?.WaterSupplyHydraulics?.length) {
      waterSupplyData.WaterSupplyHydraulics.forEach((hydraulic) => {
        const hydraulicData = WaterSupplyHydraulicDataModel.fromS3(hydraulic, sprinklerReport);
        if (hydraulicData.scopesVersion === 1) {
          v1WaterSupplyHydraulicsArray.push(hydraulicData);
        } else if (hydraulicData.scopesVersion === 2) {
          v2WaterSupplyHydraulicsArray.push(hydraulicData);
        }
      });
    } else {
      for (let i = 1; i <= 7; i++) {
        const hydraulicData = WaterSupplyHydraulicDataModel.createEmptySpocModel(sprinklerReport.ReportId, i, 1);
        const hydraulicV2Data = WaterSupplyHydraulicDataModel.createEmptySpocModel(sprinklerReport.ReportId, i, 2);
        v1WaterSupplyHydraulicsArray.push(hydraulicData);
        v2WaterSupplyHydraulicsArray.push(hydraulicV2Data);
      }
    }

    return {
      v1WaterSupplyHydraulics: v1WaterSupplyHydraulicsArray,
      v2WaterSupplyHydraulics: v2WaterSupplyHydraulicsArray
    };
  }

  // Get the get WaterSupplyPipeSchedules from the s3 data
  static getWaterSupplyPipeSchedulesData(waterSupplyData, sprinklerReport) {
    let v1WaterSupplyPipeSchedulesArray: any[] = [];
    let v2WaterSupplyPipeSchedulesArray: any[] = [];
    if (waterSupplyData?.WaterSupplyPipeSchedules?.length) {
      waterSupplyData.WaterSupplyPipeSchedules.forEach((pipeSchedule) => {
        const pipeScheduleData = WaterSupplyPipeScheduleDataModel.fromS3(pipeSchedule, sprinklerReport);
        if (pipeScheduleData.scopesVersion === 1 ) {
          v1WaterSupplyPipeSchedulesArray.push(pipeScheduleData);
        } else if (pipeScheduleData.scopesVersion === 2) {
          v2WaterSupplyPipeSchedulesArray.push(pipeScheduleData);
        }
      });
    } else {
      for (let i = 1; i < 7; i++) {
        const pipeScheduleData =  WaterSupplyPipeScheduleDataModel.createEmptySpocModel(sprinklerReport.ReportId, i, 1);
        const pipeScheduleV2Data =  WaterSupplyPipeScheduleDataModel.createEmptySpocModel(sprinklerReport.ReportId, i, 2);
        v1WaterSupplyPipeSchedulesArray.push(pipeScheduleData);
        v2WaterSupplyPipeSchedulesArray.push(pipeScheduleV2Data);
      }
    }

    return {
      v1WaterSupplyPipeSchedules: v1WaterSupplyPipeSchedulesArray,
      v2WaterSupplyPipeSchedules: v2WaterSupplyPipeSchedulesArray
    }
  }
}

class FirePumpEvaluation {
  //Fire pump evaluation
  percentProtectedAreaDependentOnPump: number;
  isSupervisedPump: boolean;
  isTwoPowerSourcePump: boolean;
  hasImproperPumpMaintenance: boolean;
  isEvaluatedThroughBypassOnly: boolean;
  isFirePumpInBothWaterSupplies: boolean;

  // Hydrator fields
  soleSourceDependency: string;
  nonBypassPumpForSupplySource: string;
  bypassPumpForSupplySource: string;

  //Fire pump deficiencies
  isNaturalGasPump: boolean;
  isGasolinePump: boolean;
  isUndependablePumpElectricSupply: boolean;
  isNonStandardInstallationPump: boolean;
  isUnlistedPump: boolean;
  isNonStandardAuxilliaryEquipment: boolean;
  isUnsatisfactorySuction: boolean;
}

class EvalutionOfWaterSupply {
  buildingHeightInFeet: number;
  topLineSprinklerHeightInFeet: number;
  isBuildingHeightSameAsDesignAreaHeight: boolean;
  hasAdequateMaintenance: boolean;
}

class SecondarySupply {
  sectionalizedVsSecondary: string;
}

class SupplySource1 {
  supplySourceTypeSs1: string;
  locationSs1: string;
  testDateSs1: Date;
  staticPressureSs1: number;
  residualPressureSs1: number;
  waterFlowSs1: number;
  storedVolumeSs1: number;
  feetGaugeSs1: number;
  isSupervisedPressureTankSs1: boolean;
  firePumpTests: any[];
}

class SupplySource2 {
  supplySourceTypeSs2: string;
  locationSs2: string;
  testDateSs2: Date;
  staticPressureSs2: number;
  residualPressureSs2: number;
  waterFlowSs2: number;
  storedVolumeSs2: number;
  feetGaugeSs2: number;
  isSupervisedPressureTankSs2: boolean;
  firePumpTests: any[];
}
class HydraulicDensityConversion {
  hydraulicApplyDensityConversion: boolean;
  hydraulicNewDesignDensity: number | '1.2-2';
  hydraulicNewDesignArea: number;
  hydraulicNewHoseDemand: number;
  hydraulicNewWaterSupplyDuration: number;
}
class HydraulicRackStorageObstruction {
  isRackStorageObstruction: boolean;
  rackStorageObstruction: RackStorageObstructionDataModel;
}
class HydraulicSystemDesign {
  hydraulicDesignMethod: string;
  hydraulicDesignDensity: number;
  hydraulicDesignDensityComment: string;
  hydraulicRemoteArea: number;
  hydraulicWaterDemandFlow: number;
  hydraulicWaterDemandPressure: number;
  isHydraulicWaterDemandLocationBor: boolean;
  hydraulicIncludesHoseDemand: boolean;
  hydraulicHoseDemand: number;
  hydraulicWaterSupplyDuration: number;
  hydraulicInformationSource: string;
}

class WaterSupplyAdjustmentCodeValue {
  pipeScheduleNumberOfSprinklers: number;
  reduce: string;
  numberOfSprinklers: string;
  unusualConditions: string;
  unjastedSprinkler: string;
  waterSupplyAdjustmentCodeValue: string;
}

class DpvSystem {
  dpvSystem: string;
}
