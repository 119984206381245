import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class WaterSupplyTestDataModel {
    static sectionName = 'systemTesting';

    waterSupplyTestId: string;
    reportId: string;
    testDate: Date;
    testTime?: any;  //timespan
    testLocation: string;
    waterSupplySource: string;
    staticPsi: number;
    residualPsi: number;
    flowLocation?: string;
    gpm: number;
    gpmBor?: number;
    suppliedBy?: string;
    phoneNumber?: string;

    static fromS3(waterSupplyTestData: any): WaterSupplyTestDataModel {

        return {
            waterSupplyTestId: waterSupplyTestData.WaterSupplyTestId,
            reportId: waterSupplyTestData.ReportId,
            testDate: waterSupplyTestData.TestDate,
            testTime: waterSupplyTestData.TestTime,
            testLocation: waterSupplyTestData.TestLocation,
            waterSupplySource: waterSupplyTestData.WaterSupplySource,
            staticPsi: waterSupplyTestData.StaticPsi,
            residualPsi: waterSupplyTestData.ResidualPsi,
            flowLocation: waterSupplyTestData.FlowLocation,
            gpm: waterSupplyTestData.Gpm,
            gpmBor: waterSupplyTestData.GpmBor,
            suppliedBy: waterSupplyTestData.SuppliedBy,
            phoneNumber: waterSupplyTestData.PhoneNumber
        };
    }

    static to(model: any, sectionEnum?: SectionEnum) {
        return {
            WaterSupplyTestId: model.waterSupplyTestId,
            ReportId: model.reportId,
            TestDate: model.testDate,
            TestTime: model.testTime,
            TestLocation: model.testLocation,
            WaterSupplySource: model.waterSupplySource,
            StaticPsi: model.staticPsi,
            ResidualPsi: model.residualPsi,
            FlowLocation: model.flowLocation,
            Gpm: model.gpm,
            GpmBor: model.gpmBor,
            SuppliedBy: model.suppliedBy,
            PhoneNumber: model.phoneNumber
        }
    }

    /**
     * Check if the model has changed
     *
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     *
     */
    static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
        return false;

    }
}
