import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { WaterSupplyFrictionLossFittingsAndValvesDataModel } from './water-supply-frictionloss-fitting-and-valves-data.model';

export class WaterSupplyFrictionLossDataModel {
  static sectionName = 'waterSupplyFrictionLoss';

  waterSupplyFrictionLossId: string;
  reportId: string;
  supplySourceNumber: number;
  pipeNumber: number;
  pipeLength: number;
  pipeCoefficientC: number;
  pipeInsideDiameter: number;
  usesActualGpmforFlow: boolean;
  otherFrictionLoss: number;
  waterSupplyFrictionLossFittingsAndValves?: any[];

  static fromS3(wsFrictionLossData: any) {
    let wsFrictionLossFittingsAndValvesArray: any[] = [];
    const frictionLossData: Array<any> = [];
    let allFittingValveText = null;
    let allFittingValves = [];
    if (wsFrictionLossData && wsFrictionLossData.length > 0) {
      wsFrictionLossData.forEach((item, index) => {
        wsFrictionLossFittingsAndValvesArray = [];
        allFittingValveText = null;
        allFittingValves = [];
        if (item?.WaterSupplyFrictionLossFittingsAndValves && item.WaterSupplyFrictionLossFittingsAndValves.length) {
          item.WaterSupplyFrictionLossFittingsAndValves.forEach((valve) => {
            const wsValveData = WaterSupplyFrictionLossFittingsAndValvesDataModel.fromS3(valve);
            wsFrictionLossFittingsAndValvesArray.push(wsValveData);
            allFittingValves.push(wsValveData.fittingAndValveType);
          });
        }
        if (allFittingValves.length > 0) {
          allFittingValveText = allFittingValves.toString();
        }

        frictionLossData.push({
          waterSupplyFrictionLossId: item?.WaterSupplyFrictionLossId,
          reportId: item?.ReportId,
          supplySourceNumber: item?.SupplySourceNumber,
          pipeNumber: item?.PipeNumber,
          pipeLength: item?.PipeLength,
          pipeCoefficientC: item?.PipeCoefficientC,
          pipeInsideDiameter: item?.PipeInsideDiameter,
          usesActualGpmforFlow: item?.UsesActualGpmforFlow,
          otherFrictionLoss: item?.OtherFrictionLoss,
          waterSupplyFrictionLossFittingsAndValves: wsFrictionLossFittingsAndValvesArray,
          allFittingValve: allFittingValveText,
        });
      });
      return frictionLossData;
    } else {
      return null;
    }
  }

  static to(model: any, sectionEnum?: SectionEnum) {
    let wsFrictionLossFittingAndValvesData = [];

    const supplySource = model.map((item, index) => {
      wsFrictionLossFittingAndValvesData = [];
      item?.waterSupplyFrictionLossFittingsAndValves?.forEach((valve) => {
        wsFrictionLossFittingAndValvesData.push(WaterSupplyFrictionLossFittingsAndValvesDataModel.to(valve));
      });
      const object = {
        WaterSupplyFrictionLossId: item.waterSupplyFrictionLossId,
        ReportId: item.reportId,
        SupplySourceNumber: item?.supplySourceNumber ? Number(item.supplySourceNumber) : item?.supplySourceNumber,
        PipeNumber: index + 1,
        PipeLength: item?.pipeLength || item?.pipeLength === 0 ? Number(item.pipeLength) : null,
        PipeCoefficientC: item?.pipeCoefficientC ? Number(item?.pipeCoefficientC) : item?.pipeCoefficientC,
        PipeInsideDiameter: item?.pipeInsideDiameter || item?.pipeInsideDiameter === 0 ? Number(item?.pipeInsideDiameter) : null,
        UsesActualGpmforFlow: item?.usesActualGpmforFlow,
        OtherFrictionLoss: item?.otherFrictionLoss || item?.otherFrictionLoss === 0 ? Number(item?.otherFrictionLoss) : null,
        WaterSupplyFrictionLossFittingsAndValves: wsFrictionLossFittingAndValvesData,
      };
      return object;
    });
    return supplySource;
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
    return false;
  }
}
