/* eslint-disable @typescript-eslint/naming-convention */
import { Guid } from 'guid-typescript';
import { RiskReportDataFloorsAndRoof } from 'src/app/models/risk-report/risk-report-data.model';

/**
 * Class that represents the Formly Model for FloorsAndRoof
 */
export class FloorsRoofConstructionDataModel {
  static sectionName = 'FloorsAndRoof';

  constructionType: string;
  constructionTypeCode?: string;
  area: number;
  length: number;
  width: number;
  levelStart: number;
  levelStop: number;
  levelType: string;
  constructionMaterial: string;
  levelTypeCode: string;
  isLowestLevel: boolean;
  floorAndRoofIdentifier: string;
  damageability?: number;
  hourlyRating?: number;
  fireResistanceListing?: string;
  bgiitype?: string;
  isVerticalOpeningProtection?: boolean;
  hasDividingWall?: boolean;
  deckConstruction?: string;
  supportType?: string;
  combustibleType?: string;
  isWoodIronClad?: boolean;
  isHeavyTimberColumns?: boolean;
  isExtraHeavyTimberColumns?: boolean;
  insulationFlameSpread?: number;
  insulationSmokeDevelopment?: number;
  isInsulationUnlisted?: boolean;
  hasAcceptableThermalBarrier?: boolean;
  // MetalCompositeMaterials  -> for V2
  hasFireRetardantPaint?: boolean;
  paintFlameSpread?: number;
  paintSmokeDevelopment?: number;


  hasFoamPlasticInsulation?: boolean;
  isLowestLevelCombustible?: boolean;
  yearOfRoofCover?: number;
  isYearOfRoofCoverApproximate?: boolean;
  isFinishSmokeDevelopmentAbove200?: boolean;
  masonryThickness?: number;
  isCombustibleInsulation?: boolean;
  isFireClassified?: boolean;
  // Area Calc
  isAreaCalc?: boolean;
  areaCalcLevelTypeCode?: string;
  areaCalcLength?: number;
  areaCalcWidth?: number;
  areaCalcArea?: number;

  static from(floorsAndRoof: RiskReportDataFloorsAndRoof): FloorsRoofConstructionDataModel {
    let consType;
    let levelType;
    switch (floorsAndRoof?.ConstType) {
      case 'M':
        consType = 'Masonry';
        break;
      case 'COMB':
        consType = 'Combustible';
        break;
      case 'NCSB':
        consType = 'Non-combustible/ Slow Burn';
        break;
      case 'FIRE':
        consType = 'Fire Resistive';
        break;
      default:
        consType = floorsAndRoof?.ConstType;
    }

    switch (floorsAndRoof?.LevelType) {
      case 'FLOO':
        levelType = 'Floor';
        break;
      case 'ROOF':
        levelType = 'Roof';
        break;
      case 'BASE':
        levelType = 'Base';
        break;
      case 'MEZZ':
        levelType = 'Mezzanine';
        break;
      case 'ATTI':
        levelType = 'Attic';
        break;
      default:
        levelType = floorsAndRoof?.LevelType;
    }

    if (floorsAndRoof?.IsLowLevel) {
      levelType = 'LEVELONGRADE' + levelType;
    }

    return {
      constructionType: consType,
      constructionTypeCode: floorsAndRoof?.ConstType,
      levelType,
      area: floorsAndRoof?.Length * floorsAndRoof?.Width,
      length: floorsAndRoof?.Length,
      width: floorsAndRoof?.Width,
      levelStart: floorsAndRoof?.LevelStart,
      levelStop: floorsAndRoof?.LevelStop,
      constructionMaterial: '',
      levelTypeCode: floorsAndRoof?.LevelType,
      isLowestLevel: floorsAndRoof?.IsLowestLevel,
      floorAndRoofIdentifier: floorsAndRoof?.FloorAndRoofIdentifier ? floorsAndRoof?.FloorAndRoofIdentifier : Guid.create().toString()
    };
  }

  static fromS3(floorsAndRoof: any, isFullRisk = false): FloorsRoofConstructionDataModel {
    let consType;
    let levelType;
    switch (floorsAndRoof?.ConstructionType) {
      case 'M':
        consType = 'Masonry';
        break;
      case 'COMB':
        consType = 'Combustible';
        break;
      case 'NCSB':
        consType = 'Non-combustible/ Slow Burn';
        break;
      case 'FIRE':
        consType = 'Fire Resistive';
        break;
      default:
        consType = floorsAndRoof?.ConstructionType;
    }

    switch (floorsAndRoof.LevelType) {
      case 'FLOO':
        levelType = 'Floor';
        break;
      case 'ROOF':
        levelType = 'Roof';
        break;
      case 'BASE':
        levelType = 'Base';
        break;
      case 'MEZZ':
        levelType = 'Mezzanine';
        break;
      case 'ATTI':
        levelType = 'Attic';
        break;
      default:
        levelType = floorsAndRoof?.LevelType;
    }

    if (floorsAndRoof?.IsLowestLevel) {
      levelType = 'LEVELONGRADE' + levelType;
    }

    let combustibleTypeVal = floorsAndRoof?.CombustibleType;
    let isHeavyTimberColumnsVal = floorsAndRoof?.IsHeavyTimberColumns;
    let isWoodIronCladVal = floorsAndRoof?.IsWoodIronClad;
    if (isWoodIronCladVal) {
      combustibleTypeVal = 'Wood Iron Clad';
    } else if (isHeavyTimberColumnsVal) {
      combustibleTypeVal = 'Heavy Timber';
    }
    if (floorsAndRoof?.CombustibleType !== undefined && floorsAndRoof?.IsWoodIronClad !== undefined &&
      floorsAndRoof?.IsHeavyTimberColumns !== undefined) {
      if (floorsAndRoof?.IsHeavyTimberColumns && floorsAndRoof?.IsWoodIronClad ||
        (floorsAndRoof?.CombustibleType !== 'NONE' && (floorsAndRoof?.IsHeavyTimberColumns || floorsAndRoof?.IsWoodIronClad))) {
        combustibleTypeVal = 'COMB';
        isHeavyTimberColumnsVal = false;
        isWoodIronCladVal = false;
      }
    }
    let constructionMaterial = floorsAndRoof?.ConstructionMaterial;
    if (!floorsAndRoof?.ConstructionMaterial) {
      switch (floorsAndRoof?.ConstructionType) {
        case 'COMB':
          constructionMaterial = 'Other Combustible Assemblies';
          break;
        case 'NCSB':
          constructionMaterial = 'Other Non-Combustible or Slow Burn Assemblies';
          break;
        case 'FIRE':
          constructionMaterial = 'Other Fire Resistive Assemblies';
          break;
        default:
          constructionMaterial = floorsAndRoof?.ConstructionMaterial;
      }
    }

    return {
      constructionMaterial: isFullRisk ? constructionMaterial : floorsAndRoof?.ConstructionMaterial,   // constructionPreset
      constructionType: consType,
      constructionTypeCode: floorsAndRoof?.ConstructionType,
      levelType,
      isLowestLevel: floorsAndRoof?.IsLowestLevel,
      levelTypeCode: floorsAndRoof?.LevelType,
      area: floorsAndRoof?.Length * floorsAndRoof?.Width,
      length: floorsAndRoof?.Length,
      width: floorsAndRoof?.Width,
      levelStart: floorsAndRoof?.LevelStart,
      levelStop: floorsAndRoof?.LevelStop,
      floorAndRoofIdentifier: floorsAndRoof?.FloorAndRoofIdentifier ? floorsAndRoof?.FloorAndRoofIdentifier : Guid.create().toString(),
      // Full Risk
      //      Fire Resistive
      damageability: floorsAndRoof?.Damageability,
      hourlyRating: floorsAndRoof?.HourlyRating,
      fireResistanceListing: floorsAndRoof?.FireResistanceListing,
      bgiitype: floorsAndRoof?.Bgiitype,
      isVerticalOpeningProtection: floorsAndRoof?.IsVerticalOpeningProtection,
      hasDividingWall: floorsAndRoof?.HasDividingWall,
      // roofCoverage
      // isApprox
      //      Non Combustible
      deckConstruction: floorsAndRoof?.DeckConstruction,
      supportType: floorsAndRoof?.SupportType,
      isFinishSmokeDevelopmentAbove200: floorsAndRoof?.IsFinishSmokeDevelopmentAbove200,
      masonryThickness: floorsAndRoof?.MasonryThickness,
      isCombustibleInsulation: floorsAndRoof?.IsCombustibleInsulation,
      isFireClassified: floorsAndRoof?.IsFireClassified,
      // isFlameSpreadAbove200
      //      Combustible
      combustibleType: combustibleTypeVal, // floorsAndRoof?.CombustibleType,
      isWoodIronClad: isWoodIronCladVal,  // floorsAndRoof?.IsWoodIronClad,
      isHeavyTimberColumns: isHeavyTimberColumnsVal, // floorsAndRoof?.IsHeavyTimberColumns,
      isExtraHeavyTimberColumns: floorsAndRoof?.IsExtraHeavyTimberColumns,
      hasFoamPlasticInsulation: floorsAndRoof?.HasFoamPlasticInsulation,
      insulationFlameSpread: floorsAndRoof?.InsulationFlameSpread,
      insulationSmokeDevelopment: floorsAndRoof?.InsulationSmokeDevelopment,
      isInsulationUnlisted: floorsAndRoof?.IsInsulationUnlisted,
      hasAcceptableThermalBarrier: floorsAndRoof?.HasAcceptableThermalBarrier,
      // metalCompositeMaterials: floorsAndRoof?.MetalCompositeMaterials,  -> for V2
      hasFireRetardantPaint: floorsAndRoof?.HasFireRetardantPaint,
      paintFlameSpread: floorsAndRoof?.PaintFlameSpread,
      paintSmokeDevelopment: floorsAndRoof?.PaintSmokeDevelopment,

      //      Level on Grade
      isLowestLevelCombustible: floorsAndRoof?.IsLowestLevelCombustible,
      // LevelType = Roof
      yearOfRoofCover: floorsAndRoof?.YearOfRoofCover,
      isYearOfRoofCoverApproximate: floorsAndRoof?.IsYearOfRoofCoverApproximate,
      // Area Calc
      isAreaCalc: floorsAndRoof?.IsAreaCalc,
      areaCalcLevelTypeCode: floorsAndRoof?.LevelType,
      areaCalcLength: floorsAndRoof?.Length,
      areaCalcWidth: floorsAndRoof?.Width,
      areaCalcArea: floorsAndRoof?.Length + floorsAndRoof.Width
    };
  }

  static to(model: any, isFullRisk: boolean): any {
    let consType;
    let levelType;
    let isLowestLevel = false;
    switch (model.constructionType) {
      case 'Masonry':
        consType = 'M';
        break;
      case 'Combustible':
        consType = 'COMB';
        break;
      case 'Non-combustible/ Slow Burn':
      case 'Non-Combustible/Limited Combustible':
        consType = 'NCSB';
        break;
      case 'Fire Resistive':
        consType = 'FIRE';
        break;
      default:
        consType = model?.ConstructionType;
    }

    switch (model?.levelType) {
      case 'Floor':
        levelType = 'FLOO';
        break;
      case 'Roof':
        levelType = 'ROOF';
        break;
      case 'Base':
        levelType = 'BASE';
        break;
      case 'Mezzanine':
        levelType = 'MEZZ';
        break;
      case 'Attic':
        levelType = 'ATTI';
        break;
      default:
        if (levelType?.includes('LEVELONGRADE')) {
          levelType = model?.LevelType.replace('LEVELONGRADE', '');
          isLowestLevel = true;
        }
    }
    let combustibleType = model?.combustibleType;
    let isVerticalOpeningProtection = model?.isVerticalOpeningProtection;
    if (isFullRisk) {
      combustibleType = combustibleType === 'Wood Iron Clad' || combustibleType === 'Heavy Timber' ? 'COMB' : combustibleType;
      isVerticalOpeningProtection = (consType === 'FIRE' && levelType !== 'ROOF') ? isVerticalOpeningProtection : false;
    }
    const isInsulationSlowburning = (consType === 'COMB' && model?.hasFoamPlasticInsulation && model?.hasAcceptableThermalBarrier &&
      (model?.insulationFlameSpread && Number(model?.insulationFlameSpread) <= 25)) ? true : false;
    return {
      ConstructionMaterial: model?.constructionMaterial,
      ConstructionType: consType,
      LevelType: model?.isAreaCalc ? model?.areaCalcLevelTypeCode : model?.levelTypeCode,
      Length: model?.isAreaCalc ? model?.areaCalcLength : (model?.length || model?.length === 0) ? Number(model?.length) : null,
      Width: model?.isAreaCalc ? model?.areaCalcWidth : (model?.width || model?.width === 0) ? Number(model?.width) : null,
      LevelArea: (model?.area) ? Number(model?.area) : null,
      LevelStart: model?.levelStart && (!model?.isLowestLevel || isFullRisk) ? Number(model?.levelStart) : null,
      LevelStop: model?.levelStop && (!model?.isLowestLevel || isFullRisk) ? Number(model?.levelStop) : null,
      IsLowestLevel: model?.isLowestLevel,
      FloorAndRoofIdentifier: model?.floorAndRoofIdentifier,
      ScopesVersion: 1,
      HasMetalCompositeMaterial: false,
      IsAreaCalc: model?.isAreaCalc && model?.isAreaCalc !== undefined ? model?.isAreaCalc : false,
      Damageability: model?.damageability && model?.damageability !== undefined ? Number(model?.damageability) : null,
      HourlyRating: model?.hourlyRating && model?.hourlyRating !== undefined ? Number(model?.hourlyRating) : null,
      FireResistanceListing: model?.fireResistanceListing,
      Bgiitype: model?.bgiitype,
      IsVerticalOpeningProtection: model?.isLowestLevel ? true : isVerticalOpeningProtection,
      YearOfRoofCover: model?.yearOfRoofCover ? model?.yearOfRoofCover : null,
      IsYearOfRoofCoverApproximate: model?.isYearOfRoofCoverApproximate ? model.isYearOfRoofCoverApproximate : false,
      HasDividingWall: model?.hasDividingWall ? model.hasDividingWall : false,
      DeckConstruction: model?.deckConstruction,
      SupportType: model?.supportType,

      CombustibleType:
        (combustibleType && model?.isWoodIronClad !== true && model?.isHeavyTimberColumns !== true) ? combustibleType : 'NONE',
      IsWoodIronClad: model?.isWoodIronClad ? model?.isWoodIronClad : false,
      IsHeavyTimberColumns: model?.isHeavyTimberColumns ? model?.isHeavyTimberColumns : false,
      IsExtraHeavyTimberColumns: model?.isExtraHeavyTimberColumns ? model?.isExtraHeavyTimberColumns : false,
      HasFoamPlasticInsulation: model?.hasFoamPlasticInsulation ? model?.hasFoamPlasticInsulation : false,
      InsulationFlameSpread: model?.insulationFlameSpread,
      InsulationSmokeDevelopment: model?.insulationSmokeDevelopment,
      IsInsulationUnlisted: model?.isInsulationUnlisted ? model.isInsulationUnlisted : false,
      HasAcceptableThermalBarrier: model?.hasAcceptableThermalBarrier ? model.hasAcceptableThermalBarrier : false,
      HasFireRetardantPaint: model?.hasFireRetardantPaint ? model?.hasFireRetardantPaint : false,
      PaintFlameSpread: model?.paintFlameSpread,
      PaintSmokeDevelopment: model?.paintSmokeDevelopment,
      IsLowestLevelCombustible: model?.isLowestLevelCombustible ? model.isLowestLevelCombustible : false,
      IsFinishSmokeDevelopmentAbove200: model?.isFinishSmokeDevelopmentAbove200 ? model.isFinishSmokeDevelopmentAbove200 : false,
      MasonryThickness: model?.masonryThickness,
      IsCombustibleInsulation: model?.isCombustibleInsulation ? model.isCombustibleInsulation : false,
      IsFireClassified: model?.isFireClassified ? model.isFireClassified : false,

      // In UI we are not having mapping for this property
      IsInsulationSlowburning: isInsulationSlowburning
    };

  }
}
