/* eslint-disable @typescript-eslint/naming-convention */
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { GreenConstructionDataModel } from '../green-construction-data.model';
import { CombustibleConstructionDataModel } from '../combustible-construction-data.model';
import { EffectiveAreaDataModel } from '../effective-area-data.model';
import { MiscellaneousSecondaryConstructionDataModel } from '../miscellaneous-secondary-construction-data.model';
import { CombustibleFinishDataModel } from '../combustible-finish-data.model';
import { Guid } from 'guid-typescript';

export class SecondaryConstructionsDataModel {
  static sectionName = 'secondaryConstructions';

  secondaryConstructionIdentifier: undefined;
  reportIdentifier: undefined;
  hasMetalCompositeMaterialFinishInterior: boolean;
  hasMetalCompositeMaterialFinishExterior: boolean;

  combustibleConstruction: CombustibleConstructionDataModel;
  greenConstruction: GreenConstructionDataModel;
  miscellaneousSecondaryConstruction: MiscellaneousSecondaryConstructionDataModel;
  effectiveArea: EffectiveAreaDataModel;
  combustibleFinish: CombustibleFinishDataModel;
  static fromS3(reports: any): SecondaryConstructionsDataModel {
    const greenConstruction = GreenConstructionDataModel.fromS3(reports);
    const combustibleConstruction = CombustibleConstructionDataModel.fromS3(reports);
    const miscellaneousSecondaryConstruction = MiscellaneousSecondaryConstructionDataModel.fromS3(reports);
    const effectiveArea = EffectiveAreaDataModel.fromS3(reports);
    const combustibleFinish = CombustibleFinishDataModel.fromS3(reports);
    return {
      secondaryConstructionIdentifier: reports?.SecondaryConstructions?.length >0 ? (reports?.SecondaryConstructions[0]?.SecondaryConstructionIdentifier ? reports?.SecondaryConstructions[0]?.SecondaryConstructionIdentifier : null) : null,
      reportIdentifier: reports?.ReportIdentifier ? reports?.ReportIdentifier : null,
      hasMetalCompositeMaterialFinishInterior: reports?.SecondaryConstructions?.length >0 ? (reports?.SecondaryConstructions[0]?.HasMetalCompositeMaterialFinishInterior ? reports?.SecondaryConstructions[0]?.HasMetalCompositeMaterialFinishInterior : false) : false,
      hasMetalCompositeMaterialFinishExterior: reports?.SecondaryConstructions?.length >0 ? (reports?.SecondaryConstructions[0]?.HasMetalCompositeMaterialFinishExterior ? reports?.SecondaryConstructions[0]?.HasMetalCompositeMaterialFinishExterior : false) : false,
      combustibleConstruction: combustibleConstruction,
      greenConstruction: greenConstruction,
      miscellaneousSecondaryConstruction: miscellaneousSecondaryConstruction,
      effectiveArea: effectiveArea,
      combustibleFinish: combustibleFinish,
    };
  }

  static to(model: any, sectionEnum?: SectionEnum) {
    const secondaryConstructions = [];
    let finishInteriorCombustibleType;
    switch (model?.secondaryConstructions?.combustibleFinish?.finishInteriorCombustibleType) {
      case 'Combustible/Slow Burn':
        finishInteriorCombustibleType = 'CBSB';
        break;
      case 'Foamed Plastic':
        finishInteriorCombustibleType = 'FOAM';
        break;
      default:
        finishInteriorCombustibleType = model?.secondaryConstructions?.combustibleFinish?.inishInteriorCombustibleType;

    }
    let finishExteriorCombustibleType;
    switch (model?.secondaryConstructions?.combustibleFinish?.finishExteriorCombustibleType) {
      case 'Combustible':
        finishExteriorCombustibleType = 'CBSB';
        break;
      case 'Foamed Plastic':
        finishExteriorCombustibleType = 'FOAM';
        break;
      default:
        finishExteriorCombustibleType = model?.secondaryConstructions?.combustibleFinish?.finishExteriorCombustibleType;

    }
    let percentagteOfOtherFloor=null;
    if (model?.secondaryConstructions?.effectiveArea?.percentOfOtherFloors) {
      percentagteOfOtherFloor=
        model?.secondaryConstructions?.effectiveArea?.percentOfOtherFloors.toString().replace('%', '');
    }
    secondaryConstructions.push({
      SecondaryConstructionIdentifier: model?.secondaryConstructions?.secondaryConstructionIdentifier ? model?.secondaryConstructions?.secondaryConstructionIdentifier : Guid.create()?.toString(),
      ReportIdentifier: model.reportIdentifier ? model.reportIdentifier : undefined,
      HasMetalCompositeMaterialFinishInterior: model?.secondaryConstructions?.hasMetalCompositeMaterialFinishInterior ? model?.secondaryConstructions?.hasMetalCompositeMaterialFinishInterior : false,
      HasMetalCompositeMaterialFinishExterior: model?.secondaryConstructions?.hasMetalCompositeMaterialFinishExterior ? model?.secondaryConstructions?.hasMetalCompositeMaterialFinishExterior : false,
      RoofSurfaceConstructionType: model.secondaryConstructions?.combustibleConstruction?.roofSurfaceConstructionType ? model.secondaryConstructions?.combustibleConstruction?.roofSurfaceConstructionType : 'NONE',
      IsFabricatedRoofFlameSpreadLessThan25: model.secondaryConstructions?.combustibleConstruction?.isFabricatedRoofFlameSpreadLessThan25 ? model.secondaryConstructions?.combustibleConstruction?.isFabricatedRoofFlameSpreadLessThan25 : false,
      RoofSurfaceArea: model.secondaryConstructions?.combustibleConstruction?.roofSurfaceArea ? model.secondaryConstructions?.combustibleConstruction?.roofSurfaceArea : null,
      InchesOfAirSpace: model.secondaryConstructions?.combustibleConstruction?.inchesOfAirSpace ? model.secondaryConstructions?.combustibleConstruction?.inchesOfAirSpace : null,
      RaisedSurfaceFloorArea: model.secondaryConstructions?.combustibleConstruction?.raisedSurfaceFloorArea ? model.secondaryConstructions?.combustibleConstruction?.raisedSurfaceFloorArea : null,
      ConcealedRoofArea: model.secondaryConstructions?.combustibleConstruction?.concealedRoofArea ? model.secondaryConstructions?.combustibleConstruction?.concealedRoofArea : null,
      PercentOfExteriorWallArea: model.secondaryConstructions?.combustibleConstruction?.percentOfExteriorWallArea ? model.secondaryConstructions?.combustibleConstruction?.percentOfExteriorWallArea : null,
      GreenRoofCoverage: model?.secondaryConstructions?.greenConstruction?.greenRoofCoverage ? model?.secondaryConstructions?.greenConstruction?.greenRoofCoverage : 'NONE',
      SolarPanelsCoverage: model?.secondaryConstructions?.greenConstruction?.solarPanelCoverage ? model?.secondaryConstructions?.greenConstruction?.solarPanelCoverage : 'NONE',
      BuildingConditionComment: model?.secondaryConstructions?.miscellaneousSecondaryConstruction?.buildingConditionComment ? model?.secondaryConstructions?.miscellaneousSecondaryConstruction?.buildingConditionComment : null,
      BuildingConditionType: model?.secondaryConstructions?.miscellaneousSecondaryConstruction?.buildingConditionType ? model?.secondaryConstructions?.miscellaneousSecondaryConstruction?.buildingConditionType : 'NONE',
      CombustibleExteriorAttachmentType: model?.secondaryConstructions?.miscellaneousSecondaryConstruction?.combustibleExteriorAttachmentType ? model?.secondaryConstructions?.miscellaneousSecondaryConstruction?.combustibleExteriorAttachmentType :  (model.floorsAndRoofs.buildingInformation.constructionClass == 1 ? 'NONE' : 'NONE'),
      IsCombustibleExteriorAttachmentSprinklered: model?.secondaryConstructions?.miscellaneousSecondaryConstruction?.isCombustibleExteriorAttachmentSprinklered ? model?.secondaryConstructions?.miscellaneousSecondaryConstruction?.isCombustibleExteriorAttachmentSprinklered : false,
      CombustibleExteriorAttachmentArea: model?.secondaryConstructions?.miscellaneousSecondaryConstruction?.combustibleExteriorAttachmentArea ? model?.secondaryConstructions?.miscellaneousSecondaryConstruction?.combustibleExteriorAttachmentArea : null,
      LargestFloorArea: model?.secondaryConstructions?.effectiveArea?.largestFloorArea,
      OtherFloorArea: (model?.secondaryConstructions?.effectiveArea?.otherFloorArea || model?.secondaryConstructions?.effectiveArea?.otherFloorArea === 0) ?
        model?.secondaryConstructions?.effectiveArea?.otherFloorArea : (model.floorsAndRoofs.buildingInformation.aboveGradeFloors &&
          model.floorsAndRoofs.buildingInformation.aboveGradeFloors !== '' && Number(model.floorsAndRoofs.buildingInformation.aboveGradeFloors) < 2 ? 0 : null),
      PercentOfOtherFloors: percentagteOfOtherFloor ? Number(percentagteOfOtherFloor) : 0,
      NumberOfOtherFloors: model?.secondaryConstructions?.effectiveArea?.numberOfOtherFloors ? model?.secondaryConstructions?.effectiveArea?.numberOfOtherFloors : 1,
      TotalEffectiveArea: model?.secondaryConstructions?.effectiveArea?.totalEffectiveArea ? model?.secondaryConstructions?.effectiveArea?.totalEffectiveArea : null,
      IsHeightMultiplierDoesNotApplied: model?.secondaryConstructions?.effectiveArea?.isHeightMultiplierDoesNotApplied ? model?.secondaryConstructions?.effectiveArea?.isHeightMultiplierDoesNotApplied : false,
      FinishInteriorCombustibleType: finishInteriorCombustibleType ? finishInteriorCombustibleType : 'NONE',
      FinishInteriorPercentOfStoriesAffected: model?.secondaryConstructions?.combustibleFinish?.finishInteriorPercentOfStoriesAffected ? (+model?.secondaryConstructions?.combustibleFinish?.finishInteriorPercentOfStoriesAffected) : null,
      FinishInteriorFlameSpread: model?.secondaryConstructions?.combustibleFinish?.finishInteriorFlameSpread !== null &&  model?.secondaryConstructions?.combustibleFinish?.finishInteriorFlameSpread !== undefined ? (model?.secondaryConstructions?.combustibleFinish?.finishInteriorFlameSpread) : null,
      FinishInteriorSmokeDevelopment: model?.secondaryConstructions?.combustibleFinish?.finishInteriorSmokeDevelopment !== null && model?.secondaryConstructions?.combustibleFinish?.finishInteriorSmokeDevelopment !== undefined ? (model?.secondaryConstructions?.combustibleFinish?.finishInteriorSmokeDevelopment) : null,
      IsFinishInteriorUnlisted: model?.secondaryConstructions?.combustibleFinish?.isFinishInteriorUnlisted ? model?.secondaryConstructions?.combustibleFinish?.isFinishInteriorUnlisted : false,
      IsFinishInteriorThermalBarrierPresent: model?.secondaryConstructions?.combustibleFinish?.isFinishInteriorThermalBarrierPresent ? model?.secondaryConstructions?.combustibleFinish?.isFinishInteriorThermalBarrierPresent : false,
      IsFinishInteriorQualifiedAsSlowBurning: model?.secondaryConstructions?.combustibleFinish?.isFinishInteriorQualifiedAsSlowBurning !== null && model?.secondaryConstructions?.combustibleFinish?.isFinishInteriorQualifiedAsSlowBurning !== undefined ? model?.secondaryConstructions?.combustibleFinish?.isFinishInteriorQualifiedAsSlowBurning : false,
      FinishExteriorCombustibleType: finishExteriorCombustibleType ? finishExteriorCombustibleType : 'NONE',
      FinishExteriorPercentOfStoriesAffected: model?.secondaryConstructions?.combustibleFinish?.finishExteriorPercentOfStoriesAffected ? (+model?.secondaryConstructions?.combustibleFinish?.finishExteriorPercentOfStoriesAffected) : null,
      FinishExteriorFlameSpread: model?.secondaryConstructions?.combustibleFinish?.finishExteriorFlameSpread !== null &&  model?.secondaryConstructions?.combustibleFinish?.finishExteriorFlameSpread !== undefined ? (model?.secondaryConstructions?.combustibleFinish?.finishExteriorFlameSpread) : null,
      FinishExteriorSmokeDevelopment: model?.secondaryConstructions?.combustibleFinish?.finishExteriorSmokeDevelopment !== null && model?.secondaryConstructions?.combustibleFinish?.finishExteriorSmokeDevelopment !== undefined ? (model?.secondaryConstructions?.combustibleFinish?.finishExteriorSmokeDevelopment) : null,
      IsFinishExteriorUnlisted: model?.secondaryConstructions?.combustibleFinish?.isFinishExteriorUnlisted ? model?.secondaryConstructions?.combustibleFinish?.isFinishExteriorUnlisted : false,
      IsFinishExteriorThermalBarrierPresent: model?.secondaryConstructions?.combustibleFinish?.isFinishExteriorThermalBarrierPresent ? model?.secondaryConstructions?.combustibleFinish?.isFinishExteriorThermalBarrierPresent : false,
      IsFinishExteriorQualifiedAsSlowBurning: model?.secondaryConstructions?.combustibleFinish?.isFinishExteriorQualifiedAsSlowBurning !== null && model?.secondaryConstructions?.combustibleFinish?.isFinishExteriorQualifiedAsSlowBurning !== undefined ? model?.secondaryConstructions?.combustibleFinish?.isFinishExteriorQualifiedAsSlowBurning : false,
    });

    return secondaryConstructions;
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    return true;
  }
}