/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { SectionEnum } from '../section.config';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FullRiskGeneralService } from '../full-risk/full-risk-general.service';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, pairwise, startWith } from 'rxjs/operators';
import { FormlyBuilderService } from '../formly-builder.service';
import { UtilService } from '../../util-service/util.service';
import { Constants } from '../../util-service/constants';
import { WindReportService } from '../wind/wind-report.service';
import { EnhancedWindEligibleDataModel } from 'src/app/models/formly-builder/formly/full-risk/enhanced-wind-eligible-data.model';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class EnhancedWindEligibleService extends SectionServiceAbstract {

  private subscription: Subscription;
  constructor(private formlyBuilderService: FormlyBuilderService, private fullRiskGeneralService: FullRiskGeneralService,
    private utilService: UtilService, private windReportService: WindReportService,
    private toastController: ToastController
  ) {
    super();
  }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    const hasChanged: boolean = EnhancedWindEligibleDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      if (model.isWindReportDeleted === 'true') {
        const riskReportService = this.formlyBuilderService.riskReport;
        const windReportDefaultData =
          await this.windReportService.updateFullWindReport(riskReportService.model, riskReportService.report);

        return { ...riskReportData, ... { WindReport: windReportDefaultData } };
      } else if (model.isWindReportDeleted === 'false') {
        return { ...riskReportData, ... { WindReport: null } };
      } else {
        const riskReportService = this.formlyBuilderService.riskReport;
        const windReportDefaultData = await this.windReportService.updateFullWindReport(riskReportService.model, riskReportService.report);
        return { ...riskReportData, ...{ WindReport: windReportDefaultData } };
      }
    }
    return undefined;
  }

  loadData(sectionData: any[], reportInfo: any, isS3: boolean, model: any): any {
    const windModel = EnhancedWindEligibleDataModel.fromS3(reportInfo);
    if (this.formlyBuilderService.isExistingRisk()) {
      if (reportInfo && reportInfo?.WindReport && Object.keys(reportInfo.WindReport).length > 2) {
        windModel.isWindReportDeleted = 'true';
      } else {
        windModel.isWindReportDeleted = 'false';
      }
    }
    if(this.formlyBuilderService?.options?.formState?.isMainSectionDisabled) {
      if (windModel.isWindReportDeleted === 'true') {
        const index = this.formlyBuilderService.options.formState.isMainSectionDisabled.findIndex(section => section.sectionId === SectionEnum.FR_WIND);
        if (index !== -1) { this.formlyBuilderService.options.formState.isMainSectionDisabled.splice(index, 1) };
      } else if(windModel.isWindReportDeleted === 'false' || windModel.isWindReportDeleted === null) {
        const index = this.formlyBuilderService.options.formState.isMainSectionDisabled.findIndex(section => section.sectionId === SectionEnum.FR_WIND);
        if (index === -1) {
          this.formlyBuilderService.options.formState.isMainSectionDisabled.push({sectionId: SectionEnum.FR_WIND});
        }
      }
    }    
    return { ...model, ...windModel };
  }

  enhancedWindEligiblevalueChange(field: FormlyFieldConfig) {
    const initialValue = field.formControl?.value;
    let fromPopup = false;
    if (field.formControl?.value === 'true' || field.formControl?.value === true) {

      this.enableWindReport(field);
    } else {
      this.disableWindReport(field);
    }
    this.subscription = field.formControl.valueChanges.pipe(startWith(initialValue), pairwise(),
      distinctUntilChanged()).subscribe(([ prev, next ]: [ any, any ]) => {
        field.formControl.markAsTouched();
        if ((next === 'true' || next === true) && !fromPopup) {
          this.formlyBuilderService.riskReport.report.IsWindReportDeleted = false;
          this.enableWindReport(field);
          this.showToasterMessage('Wind Report has been added to this risk.');
          this.updateFireWindData(field);
        } else if ((next === 'false' || next === false) && !prev) {
          this.removeWindData();
          this.disableWindReport(field);
          this.updateFireWindData(field);
        } else if ((prev === 'true' || prev === true) && (next === 'false' || next === false)) {
          this.closeActiveToaster();
          this.utilService.showConfirmationPopup(Constants.removeWindReport, '', async (cb) => {
            if (cb === 1) {
              fromPopup = false;
              if (this.formlyBuilderService.isExistingRisk()) {
                this.formlyBuilderService.riskReport.report.IsWindReportDeleted = true;
              }
              this.disableWindReport(field);
              this.removeWindData();
              this.updateFireWindData(field);
            } else {
              fromPopup = true;
              field.formControl.setValue('true');
            }
          }, [ 'Confirm', 'Cancel' ]);
        }
      });
  }

  async updateFireWindData(field) {
    if (!field.formControl.pristine) {
      const rr = await field.options.formState.service.riskReport.updateSectionData(SectionEnum.FR_ENHANCED_WIND_ELIGIBLE, false);
      //update Wind Report Model
      const newModel = this.windReportService.loadData(rr, rr, true, field.options.formState.service.riskReport.model);
      if (newModel) {
        this.formlyBuilderService.riskReport.model = { ...newModel };
      }

    }
  }

  /**
   * Check if Wind Report is disabled
   *
   * @param field
   * @returns
   */
  public checkWindreportDisabled(field) {
    const disabledField = field?.parent?.options?.formState?.isMainSectionDisabled;
    const filterArray = disabledField.filter(val => val.sectionId === SectionEnum.FR_WIND);
    return filterArray;
  }

  enableWindReport(field: FormlyFieldConfig) {
    const filterArray = this.checkWindreportDisabled(field);
    this.fullRiskGeneralService.enableMainSection(field, SectionEnum.FR_WIND);
    // if (filterArray.length == 1) {
    //   enablePopup == "showingPopup" ? this.fireSprinklerService.showToasterMessage(Constants.eligibleWindform) : '';
    // }
  }
  disableWindReport(field: FormlyFieldConfig) {
    const filterArray = this.checkWindreportDisabled(field);
    this.fullRiskGeneralService.disableMainSection(field, SectionEnum.FR_WIND);
    // if (filterArray.length == 0) {
    //   enablePopup == "showingPopup" ? this.showToaster(Constants.ineligibleWindform) : '';
    // }
  }
  removeWindData() {
    this.formlyBuilderService.riskReport.model = {
      ...this.formlyBuilderService.riskReport.model, ...{ windReport: null }
    };
  }

  async showToasterMessage(message) {
    const toast = await this.toastController.create({
      message,
      position: 'bottom',
      cssClass: [ 'toaster full-width-toaster green-toaster' ],
      buttons: [
        {
          text: '\u2573',
          role: 'cancel',
          handler: () => { }
        }
      ],
    });
    await toast.present();
  }

  closeActiveToaster() {
    this.toastController.getTop().then((value) => {
      if (value) {
        this.toastController.dismiss();
      }
    });
  }

  onDestoryHook() {
    this.subscription.unsubscribe();
  }
}
