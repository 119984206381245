import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { Constants } from 'src/app/services/util-service/constants';

export class DryPipeValveTripTestingSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'dryPipeValveTripTests',
      type: 'fr-common-custom-grid-view',
      className: 'dry-pipe-valve-trip-test-container',
      wrappers: ['simple-section-wrapper'],
      props: {
        // Props for simple section wrapper
        className: 'padding-bottom',

        // Props for fr-common-custom-grid-view
        label: 'Dry Pipe Valve Trip Tests',
        isCollapsable: true,
        isCollapsed: false,
        actionButtons: [{ section: 'systemTesting', action: 'openArraySheetAdd', label: 'Add', isDisabled: false }],
        gridSection: 'dryPipeValveTripTests',
        gridColConfig: [
          { label: 'System', propertyPath: 'systemNumber', className: 'common-grid-view-width-100' },
          { label: 'Date Tested', propertyPath: 'testDate', isDate: true, className: 'common-grid-view-width-115' },
          { label: 'Test Type', propertyPath: 'testType', className: 'common-grid-view-width-115' },
          { label: 'Test Results', propertyPath: 'testResults', formatByGivenMethod: true, action: 'getTestResultValue',
          service: 'dryPipeValveTripTestService', className: 'common-grid-view-width-195' },
        ],

        // Props for edit sheet
        title: 'System Testing',
        subTitle: 'Dry Pipe Valve Trip Tests',
        isAddIdentifier: true,
        identifierKey: 'dryPipeValveTripTestId',
        reportIdentifierKey: 'reportId',
        reportName: 'SprinklerReport',
        isSwipeActionNeeded: true,
        isCloneFnRequired: true,
        sheetButtons: [
          {
            section: 'dryPipeValveTripTest',
            action: 'onClose',
            label: 'Close',
            service: 'dryPipeValveTripTestService',
            className: 'close-btn medium-btn',
          },
          {
            section: 'dryPipeValveTripTest',
            action: 'onApply',
            label: 'Apply',
            service: 'dryPipeValveTripTestService',
            className: 'apply-btn large-btn',
            role: 'apply'
          }
        ],
      },
      expressions: {
        'props.actionButtons[0].label': (field: FormlyFieldConfig) => field?.model?.length ? 'Edit' : Constants.btnText,
        'props.actionButtons[0].action': (field: FormlyFieldConfig) => field?.model?.length ? 'openArraySheetEdit' : 'openArraySheetAdd'
      },
      // fieldGroupClassName: 'container-test',
      fieldArray: {
        fieldGroupClassName: 'formly-flex-container',
        wrappers: ['error-info-wrapper'],
        fieldGroup: [
          {
            key: 'systemNumber',
            type: 'simple-input',
            className: 'input-width-xs',
            props: {
              label: 'System',
              required: true,
              maxLength: 75,
              isAsteriskNotRequired: true,
            },
          },
          {
            key: 'testDate',
            type: 'rf-date-picker',
            className: 'smd-width ss-input-padding',
            props: {
              label: 'Date Tested',
              displayFormat: 'MM/DD/YY',
              required: true,
              isAsteriskNotRequired: true,
              datePickerId: 'testDate'
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                if( field?.parent && field?.parent['index']) {
                  field.props.datePickerId = 'testDate' + (field?.parent['index']+1) ;
                }
              }
            },
            validators: {
              validation: [{ name: 'future-date-validator', options: { msg: 'Test date cannot be in the future.' } }]
            }
          },
          {
            key: 'airPressure',
            type: 'simple-input',
            className: 'smd-width ss-input-padding',
            props: {
              label: 'Air Pressure',
              type: 'number',
              unit: 'PSI',
              required: true,
              isAsteriskNotRequired: true
            },
          },
          {
            key: 'waterPressure',
            type: 'simple-input',
            className: 'input-width-126 ss-input-padding',
            props: {
              label: 'Water Pressure',
              type: 'number',
              unit: 'PSI',
              required: true,
              isAsteriskNotRequired: true
            },
          },
          {
            key: 'alarmActivationTime',
            type: 'simple-input',
            className: 'select-width-183 ss-input-padding',
            props: {
              label: 'Alarm Activation Time',
              type: 'number',
              max: 10000,
              unit: 'sec',
              required: true,
              isAsteriskNotRequired: true
            },
          },
          {
            key: 'testType',
            type: 'simple-select',
            className: 'select-width-115 ss-input-padding',
            props: {
              label: 'Test Type',
              options: [
                { value: 'Partial Trip', label: 'Partial Trip' },
                { value: 'Full Trip', label: 'Full Trip' },
              ],
              required: true,
              isAsteriskNotRequired: true
            },
          },
          {
            key: 'testResults',
            type: 'simple-select',
            className: 'select-width-183 ss-input-padding',
            props: {
              label: 'Test Result',
              options: [
                { value: 'S', label: 'Satisfactory' },
                { value: 'U', label: 'Unsatisfactory' },
              ],
              required: true,
              isAsteriskNotRequired: true
            },
          },
          {
            key: 'testConductedBy',
            type: 'simple-input',
            className: 'test-conducted-by-width ss-input-padding',
            props: {
              label: 'Conducted by',
              required: true,
              isAsteriskNotRequired: true,
              maxLength: 100
            },
          },
        ]
      },
      validators:{
        duplicateValidation:{
          expression:(control: FormControl, field: FormlyFieldConfig)=>{
            const isDuplicate = this.formlyBuilderService.dryPipeValveTripTestService.hasDuplicateWithDifferentFields(field);
            if(isDuplicate){
              return false;
          }else{
            return true;
          }
        },
        message:'Duplicate entries should not be made, either remove or edit the duplicate entries'
      }
    }
    });
  }
}
