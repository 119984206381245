import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { FormControl } from '@angular/forms';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class WindWallEnvelopeSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyService: FormlyBuilderService) {
    super({
      type: 'rf-sheet-section',
      key: 'wallEnvelope',
      props: {
        label: 'Wall Envelope',
        subTitle: 'Wall Envelope',
        title: 'Wind Report',
        actionButtons: [ { section: 'windReport', action: 'showFormlySheet', label: 'Add', isDisabled: false, service: 'formlyWrapperService' } ],
        sheetButtons: [
          {
            section: 'windReport',
            action: 'onClose',
            label: 'Close',
            service: 'windWallEnvelopeService',
            className: 'close-btn medium-btn',
            passInitialModel: true,
          },
          {
            section: 'windReport',
            action: 'onApply',
            label: 'Apply',
            hasConfirmationPopup: true,
            service: 'windWallEnvelopeService',
            className: 'apply-btn large-btn',
          },
        ],
      },
      fieldGroup: [
        {
          wrappers: ['simple-section-wrapper'],
          className: 'outside-section wr-wall-container qw-label-normal', 
          key: 'wallCladding',
          props: {
            label: 'What type(s) of cladding other than glass is on the building?',
            // isCollapsed: false,
            isCollapsable: true,
            className: 'padding-bottom-20',
            // showError: true,   
            checkTouchedControl: true,
          },
          fieldGroup: [
            {
              type: 'rf-predominant-block',
              props: {
                // No label: margins are adapted to show inside a section wrapper
                options: [],
                path: 'windReport.wallEnvelope.wallCladding',
                errorKey: 'wallCladding',
                errorMessage: 'At least one Wall Cladding Type is required.',
                predominatErrorMessage : 'One Wall Cladding Type must be marked as predominant.'
              },
              hooks: {
                onInit: (field) => {
                  formlyService.windWallEnvelopeService.markAsTouched(field);
                  formlyService.windWallEnvelopeService.initCheckboxes(field, [
                    { label: 'None', checkboxKey: 'hasCladdingTypeOtherThanGlassTypeNone', predominantKey: 'isCladdingTypeOtherThanGlassTypeNonePredominant' },
                    { label: 'Precast masonry', checkboxKey: 'hasCladdingTypeOtherThanGlassTypePrecastMasonry', predominantKey: 'isCladdingTypeOtherThanGlassTypePrecastMasonryPredominant' },
                    { label: 'Masonry veneer over wood or metal studs', checkboxKey: 'hasCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStuds', predominantKey: 'isCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStudsPredominant' },
                    { label: 'Metal Exterior Sandwich Panels', checkboxKey: 'hasCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanels', predominantKey: 'isCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanelsPredominant' },
                    { label: 'Wood, Vinyl, or Stucco on wood or metal studs', checkboxKey: 'hasCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStuds', predominantKey: 'isCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStudsPredominant' },
                    { label: 'Reinforced masonry', checkboxKey: 'hasCladdingTypeOtherThanGlassTypeReinforcedMasonry', predominantKey: 'isCladdingTypeOtherThanGlassTypeReinforcedMasonryPredominant' },
                    { label: 'EIFS over masonry', checkboxKey: 'hasCladdingTypeOtherThanGlassTypeEIFSOverMasonry', predominantKey: 'isCladdingTypeOtherThanGlassTypeEIFSOverMasonryPredominant' },
                    { label: 'Single layer of steel panels', checkboxKey: 'hasCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanels', predominantKey: 'isCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanelsPredominant' },
                    { label: 'EIFS over wood or metal studs', checkboxKey: 'hasCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStuds', predominantKey: 'isCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStudsPredominant' },
                    { label: 'Unreinforced masonry, including masonry veneer over masonry block', checkboxKey: 'hasCladdingTypeOtherThanGlassTypeUnreinforcedMasonry', predominantKey: 'isCladdingTypeOtherThanGlassTypeUnreinforcedMasonryPredominant' },
                  ]);
                }
              },
              fieldGroup: [
                { key: 'hasCladdingTypeOtherThanGlassTypeNone' },
                { key: 'isCladdingTypeOtherThanGlassTypeNonePredominant' },
                { key: 'hasCladdingTypeOtherThanGlassTypePrecastMasonry' },
                { key: 'isCladdingTypeOtherThanGlassTypePrecastMasonryPredominant' },
                { key: 'hasCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStuds' },
                { key: 'isCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStudsPredominant' },
                { key: 'hasCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanels' },
                { key: 'isCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanelsPredominant' },
                { key: 'hasCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStuds' },
                { key: 'isCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStudsPredominant' },
                { key: 'hasCladdingTypeOtherThanGlassTypeReinforcedMasonry' },
                { key: 'isCladdingTypeOtherThanGlassTypeReinforcedMasonryPredominant' },
                { key: 'hasCladdingTypeOtherThanGlassTypeEIFSOverMasonry' },
                { key: 'isCladdingTypeOtherThanGlassTypeEIFSOverMasonryPredominant' },
                { key: 'hasCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanels' },
                { key: 'isCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanelsPredominant' },
                { key: 'hasCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStuds' },
                { key: 'isCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStudsPredominant' },
                { key: 'hasCladdingTypeOtherThanGlassTypeUnreinforcedMasonry' },
                { key: 'isCladdingTypeOtherThanGlassTypeUnreinforcedMasonryPredominant' },
              ],
              validators: {
                validation: [{
                  name: 'at-least-one-boolean',
                  options: {
                    list: [
                       'hasCladdingTypeOtherThanGlassTypeNone',
                       'hasCladdingTypeOtherThanGlassTypePrecastMasonry',
                       'hasCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStuds',
                       'hasCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanels',
                       'hasCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStuds',
                       'hasCladdingTypeOtherThanGlassTypeReinforcedMasonry',
                       'hasCladdingTypeOtherThanGlassTypeEIFSOverMasonry',
                       'hasCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanels',
                       'hasCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStuds',
                       'hasCladdingTypeOtherThanGlassTypeUnreinforcedMasonry'
                    ],
                    errorName: 'atLeastOneCheckedValidator',
                    message: 'At least one Roof to Wall Connection Type must be selected',
                  }
                },
                {
                  name: 'at-least-one-boolean',
                  options: {
                    list: [
                       'isCladdingTypeOtherThanGlassTypeNonePredominant' ,
                       'isCladdingTypeOtherThanGlassTypePrecastMasonryPredominant' ,
                       'isCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStudsPredominant' ,
                       'isCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanelsPredominant' ,
                       'isCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStudsPredominant' ,
                       'isCladdingTypeOtherThanGlassTypeReinforcedMasonryPredominant' ,
                       'isCladdingTypeOtherThanGlassTypeEIFSOverMasonryPredominant' ,
                       'isCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanelsPredominant' ,
                       'isCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStudsPredominant' ,
                       'isCladdingTypeOtherThanGlassTypeUnreinforcedMasonryPredominant',
                    ],
                    errorName: 'atLeastOnePredominantValidator',
                    message: 'One Roof to Wall Connection Type must be marked as predominant',
                  }
                }
                ],
              },              
            },
          ]
        },
        {
          wrappers: ['simple-section-wrapper'],
          className: 'outside-section wr-wall-container qw-label-normal', 
          key: 'wallGlassType',
          props: {
            label: 'What type(s) of glass are used in the building?',
            // isCollapsed: false,
            isCollapsable: true,
            className: 'padding-bottom-20',
            // showError: true,   
            checkTouchedControl: true,
          },
          fieldGroup: [
            {
              type: 'rf-predominant-block',
              props: {
                // No label: margins are adapted to show inside a section wrapper
                options: [],
                path: 'windReport.wallEnvelope.wallGlassType',
                errorKey: 'wallGlassType',
                errorMessage: 'At least one Glass Type is required',
                predominatErrorMessage: 'One Glass Type must be marked as predominant',
                mergeChildErrors: true                
              },
              hooks: {
                onInit: (field) => {
                  formlyService.windWallEnvelopeService.markAsTouched(field);
                  formlyService.windWallEnvelopeService.initCheckboxes(field, [
                  { label: 'Impact resistant', checkboxKey: 'hasBuildingGlassTypeImpactResistant', predominantKey: 'isBuildingGlassTypeImpactResistantPredominant' },
                  { label: 'Laminated', checkboxKey: 'hasBuildingGlassTypeImpactLaminated', predominantKey: 'isBuildingGlassTypeImpactLaminatedPredominant' },
                  { label: 'Full Tempered', checkboxKey: 'hasBuildingGlassTypeImpactFullyTempered', predominantKey: 'isBuildingGlassTypeImpactFullyTemperedPredominant' },
                  { label: 'Heat Strengthened', checkboxKey: 'hasBuildingGlassTypeImpactHeatStrengthened', predominantKey: 'isBuildingGlassTypeImpactHeatStrengthenedPredominant' },
                  { label: 'Insulating units', checkboxKey: 'hasBuildingGlassTypeImpactInsulatingUnits', predominantKey: 'isBuildingGlassTypeImpactInsulatingUnitsPredominant' },
                  { label: 'Annealed or ordinary', checkboxKey: 'hasBuildingGlassTypeImpactAnnealedOrOrdinary', predominantKey: 'isBuildingGlassTypeImpactAnnealedOrOrdinaryPredominant' },
                  { label: 'N/A (no glazing)', checkboxKey: 'hasBuildingGlassTypeDoesNotApplyNoGlazing', 
                    expressionKeysOnchecked : ['percentageOfGlassWallArea','maximumGlassPanelSize','windBourneDebrisProtection'], predominantKey: 'isBuildingGlassTypeDoesNotApplyNoGlazingPredominant', isDisableAll: true },
                ])}
              },
              fieldGroup: [
                { key: 'hasBuildingGlassTypeImpactResistant' },
                { key: 'isBuildingGlassTypeImpactResistantPredominant' },
                { key: 'hasBuildingGlassTypeImpactLaminated' },
                { key: 'isBuildingGlassTypeImpactLaminatedPredominant' },
                { key: 'hasBuildingGlassTypeImpactFullyTempered' },
                { key: 'isBuildingGlassTypeImpactFullyTemperedPredominant' },
                { key: 'hasBuildingGlassTypeImpactHeatStrengthened' },
                { key: 'isBuildingGlassTypeImpactHeatStrengthenedPredominant' },
                { key: 'hasBuildingGlassTypeImpactInsulatingUnits' },
                { key: 'isBuildingGlassTypeImpactInsulatingUnitsPredominant' },
                { key: 'hasBuildingGlassTypeImpactAnnealedOrOrdinary' },
                { key: 'isBuildingGlassTypeImpactAnnealedOrOrdinaryPredominant' },
                { key: 'hasBuildingGlassTypeDoesNotApplyNoGlazing' },
                { key: 'isBuildingGlassTypeDoesNotApplyNoGlazingPredominant' },
              ],
              validators: {
                validation: [{
                  name: 'at-least-one-boolean',
                  options: {
                    list: [
                      'hasBuildingGlassTypeImpactResistant' ,
                      'hasBuildingGlassTypeImpactLaminated' ,
                      'hasBuildingGlassTypeImpactFullyTempered' ,
                      'hasBuildingGlassTypeImpactHeatStrengthened' ,
                      'hasBuildingGlassTypeImpactInsulatingUnits' ,
                      'hasBuildingGlassTypeImpactAnnealedOrOrdinary' ,
                      'hasBuildingGlassTypeDoesNotApplyNoGlazing' ,
                    ],
                    errorName: 'atLeastOneCheckedValidator',
                    message: 'At least one Roof to Wall Connection Type must be selected',
                  }
                },
                {
                  name: 'at-least-one-boolean',
                  options: {
                    list: [
                      'isBuildingGlassTypeImpactResistantPredominant' ,
                      'isBuildingGlassTypeImpactLaminatedPredominant' ,
                      'isBuildingGlassTypeImpactFullyTemperedPredominant' ,
                      'isBuildingGlassTypeImpactHeatStrengthenedPredominant' ,
                      'isBuildingGlassTypeImpactInsulatingUnitsPredominant' ,
                      'isBuildingGlassTypeImpactAnnealedOrOrdinaryPredominant' ,
                      'isBuildingGlassTypeDoesNotApplyNoGlazingPredominant' ,
                    ],
                    errorName: 'atLeastOnePredominantValidator',
                    message: 'One Roof to Wall Connection Type must be marked as predominant',
                  }
                }
                ],
              },               
            },
            {
              key: 'percentageOfGlassWallArea',              
              type: 'simple-select',
              className: 'select-full-width',
              wrappers: ['question-wrapper'],
              props: {
                label: 'Percentage of glass in the wall area',
                required: true,
                isSectionLabelNormal: true,
                firstColSize: 7,
                showError: false,
                isLabelDisabled: true,
                isCardEnabled: true,
                isAsteriskNotRequired: true,
                options: [
                  { value: 'N/A (no glazing)', label: 'N/A (no glazing)' },
                  { value: '<= 10%', label: '< 10%' }, 
                  { value: '> 10% - 25%', label: '> 10 - 25%' },
                  { value: '> 25% - 35%', label: '> 25 - 35%' },
                  { value: '> 35% - 50%', label: '> 35 - 50%' },
                  { value: '> 50%', label: '> 50%' }
                ],
              },
              hooks:{
                onInit: (field: FormlyFieldConfig) => {
                  field.formControl?.valueChanges?.subscribe((value)=> 
                    {
                      if(value === 'N/A (no glazing)'){
                        if(field?.form?.get('windBourneDebrisProtection')?.value !== 'N/A (no glazing)'){
                          field?.form?.get('windBourneDebrisProtection')?.setValue("N/A (no glazing)");
                        }
                        if(field?.form?.get('maximumGlassPanelSize')?.value !== 'N/A (no glazing)'){
                          field?.form?.get('maximumGlassPanelSize')?.setValue("N/A (no glazing)");
                        }
                      }
                    }
                  );
                }
              }
            },
            {
              key: 'maximumGlassPanelSize',              
              type: 'simple-select',
              className: 'select-full-width',
              wrappers: ['question-wrapper'],
              props: {
                label: 'Maximum glass panel size',
                required: true,
                isSectionLabelNormal: true,
                firstColSize: 7,
                showError: false,
                isCardEnabled: true,
                isAsteriskNotRequired: true,
                isLabelDisabled: true,
                options: [
                  { value: 'N/A (no glazing)', label: 'N/A (no glazing)' },
                  { value: '<= 20 sq. ft.', label: '<= 20 sq. ft' }, 
                  { value: '> 20 - 40 sq. ft.', label: '> 20 - 40 sq. ft' },
                  { value: '> 40 sq. ft.', label: '> 40 sq. ft' },
                ],
              },
              hooks:{
                onInit: (field: FormlyFieldConfig) => {
                  field.formControl?.valueChanges?.subscribe((value)=> 
                    {
                      if(value === 'N/A (no glazing)'){
                        if(field?.form?.get('percentageOfGlassWallArea')?.value !== 'N/A (no glazing)'){
                          field?.form?.get('percentageOfGlassWallArea')?.setValue("N/A (no glazing)");
                        }
                        if(field?.form?.get('windBourneDebrisProtection')?.value !== 'N/A (no glazing)'){
                          field?.form?.get('windBourneDebrisProtection')?.setValue("N/A (no glazing)");
                        }
                      }
                    }
                  );
                }
              }
            },
            {
              key: 'windBourneDebrisProtection',              
              type: 'simple-select',
              className: 'select-full-width',
              wrappers: ['question-wrapper'],
              props: {
                label: 'Do Windows have wind borne debris protection?',
                required: true,
                isSectionLabelNormal: true,
                firstColSize: 7,
                showError: false,
                isCardEnabled: true,
                isAsteriskNotRequired: true,
                isLabelDisabled: true,
                options: [
                  { value: 'N/A (no glazing)', label: 'N/A (no glazing)' },
                  { value: 'No protection found', label: 'No protection found' },
                  { value: 'Nonstandard', label: 'Nonstandard' },
                  { value: 'Yes - Engineered window protection', label: 'Yes, engineered window protection' }
                ],
              },
              hooks:{
                onInit: (field: FormlyFieldConfig) => {
                  field.formControl?.valueChanges?.subscribe((value)=> 
                    {
                      if(value === 'N/A (no glazing)'){
                        if(field?.form?.get('percentageOfGlassWallArea')?.value !== 'N/A (no glazing)'){
                          field?.form?.get('percentageOfGlassWallArea')?.setValue("N/A (no glazing)");
                        }
                        if(field?.form?.get('maximumGlassPanelSize')?.value !== 'N/A (no glazing)'){
                          field?.form?.get('maximumGlassPanelSize')?.setValue("N/A (no glazing)");
                        }
                      }
                    }
                  );
                }
              }
            }
          ]
        },
        {
          wrappers: ['simple-section-wrapper'],
          fieldGroupClassName: 'wr-additional-wall-container', 
          key: 'additionalWallFeature',
          props: {
            label: 'Additional Wall Features',
            isCollapsable: true,
            className: 'padding-bottom-20',
            checkTouchedControl: true,
          },
          hooks: {
            onInit: (field: FormlyFieldConfig) => {
              let touched = false;
              field.fieldGroup.forEach(x => {
                if (x.formControl?.value !== undefined && x.formControl?.value !== null) {
                  x.formControl.parent.markAllAsTouched();
                  touched = true;
                }
              });
              if (!touched) {
                field.fieldGroup.forEach(x => {
                  x.formControl.markAsUntouched();
                });
              }
            },
          },
          fieldGroup: [
            {
              key: 'percentageofReinforcedOverheadDoors',              
              type: 'simple-select',
              className: 'select-full-width select-full-width-full',
              wrappers: ['question-wrapper'],
              props: {
                label: 'Arrangement of overhead doors relative to the wall area',
                required: true,
                isSectionLabelNormal: true,
                firstColSize: 7,
                showError: false,
                isLabelDisabled: true,
                isCardEnabled: true,
                isAsteriskNotRequired: true,
                options: [
                  { value: 'No overhead doors', label: 'No overhead doors', },
                  { value: 'All overhead doors are reinforced', label: 'All overhead doors are reinforced' },
                  { value: '<=10% overhead doors', label: '<= 10%' },
                  { value: '>10% to 20% overhead doors', label: '> 10 - 20%' },
                  { value: '>20% to 50% overhead doors', label: '> 20 - 50%' },
                  { value: 'Over 50% overhead doors', label: '> 50%' },
                ],
              }
            },
            {
              key: 'evidenceOfWallDegradation',              
              type: 'simple-select',
              wrappers: ['question-wrapper'],
              className: 'evidence-wall-envelope',
              props: {
                label: 'Evidence of wall degradation',
                className: 'field-extended-select-container',
                required: true,
                isAsteriskNotRequired: true,
                isSectionLabelNormal: true,
                isSwipeGesture: true,
                isLabelDisabled: true,
                isCommentActionEnabled: true,
                firstColSize: 7,
                showError: false,
                isLabelNotRequired: true,
                commentActionKey: 'evidenceOfWallDegradationComment',
                maxLength: 100,
                options: [
                  { value: 'NDSC', label: 'Not discernible' },
                  { value: 'None noted', label: ' None noted' },
                  { value: 'Slight', label: 'Slight'},
                  { value: 'Moderate', label: 'Moderate'},
                  { value: 'Severe', label: 'Severe'}
                ],
              },
              validators: {
                fieldCommentValidation: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const comment = field.form?.get('evidenceOfWallDegradationComment')?.value;
                    const value = control.value;
                    if (value !== null && (value === 'Slight'  || value === 'Moderate' || value === 'Severe')) {
                      if (comment !== undefined && comment !== null && comment?.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return true;
                  },
                  message: 'Evidence of wall degradation Comment is Required',
                },
              },
            },
            {
              key: 'evidenceOfWallDegradationComment',
              hooks:{
                onInit: (field: FormlyFieldConfig) => {
                  field.formControl?.valueChanges?.subscribe((value)=> field?.form?.get('evidenceOfWallDegradation')?.updateValueAndValidity());
                }
              }
            },
            {
              key: 'reinforcedPersonnelDoors',              
              type: 'simple-select',
              className: 'select-full-width',
              wrappers: ['question-wrapper'],
              props: {
                label: 'All personnel doors reinforced',
                required: true,
                isSectionLabelNormal: true,
                firstColSize: 7,
                showError: false,
                isLabelDisabled: true,
                isCardEnabled: true,
                isAsteriskNotRequired: true,
                options: [
                  { value: 'All are reinforced', label: 'All are reinforced' },
                  { value: 'At least one unreinforced door', label: 'There is at least one unreinforced door' },
                ],
              },
            },
            {
              key: 'awningsCanopiesOrCoveredWalkways',              
              type: 'simple-select',
              className: 'select-full-width qw-label-normal',
              wrappers: ['question-wrapper'],
              props: {
                label: 'Are there any awnings, canopies, or covered walkways?',
                required: true,
                isSectionLabelNormal: true,
                firstColSize: 7,
                showError: false,
                isLabelDisabled: true,
                isCardEnabled: true,
                isAsteriskNotRequired: true,
                isTitleDisableable: false,
                options: [
                  { value: 'None noted', label: 'None noted' },
                  { value: 'Yes - Included in Basic Construction', label: 'Yes, included in basic construction'  },
                  { value: 'Yes - Not included in Basic Construction', label: 'Yes, not included in basic construction' },
                ],
              },
              // expressions: {
              //   'props.isDisabled': (field: FormlyFieldConfig) =>
              //     field.form?.root?.value?.secondaryConstructions?.miscellaneousSecondaryConstruction?.combustibleExteriorAttachmentArea === 'Yes, not included in basic construction' ? false : true,
              // },
              hooks: {
                onInit: (field) => formlyService.windWallEnvelopeService.setWallNotInBasicConstructio(field),
              },
            },
            {
              key: 'hasSignsOrArchitecturalAppurtenances',
              type: 'rf-yes-no',
              wrappers: ['question-wrapper'],
              defaultValue: null,
              className: 'sign-architectutal-yes-or-no', 
              props: {
                label: `Any signs or architectural appurtenances`,
                isSwipeGesture: true,
                isCommentActionEnabled: true,
                commentActionKey: 'signsOrArchitecturalAppurtenancesComment',
                required: true,
                isSectionLabelNormal: true,
                firstColSize: 7,
                showError: false,
                isLabelDisabled: true,
                isCardEnabled: true,
                isAsteriskNotRequired: true,
                maxLength: 100
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {                      
                  field.formControl?.valueChanges?.subscribe((value)=> {
                    field.formControl?.markAsTouched();                        
                  });                     
                },
              },
              validators: {
                fieldCommentValidation: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const comment = field.form?.get('signsOrArchitecturalAppurtenancesComment')?.value;
                    const value = control.value;
                    if (value !== null && (value === 'true' || value === true)) {
                      if (comment !== undefined && comment !== null && comment?.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return true;
                  },
                  message: 'Any signs or architectural appurtenances Comment is Required',
                },
              },
              
            },
            {
              key: 'signsOrArchitecturalAppurtenancesComment',
              hooks:{
                onInit: (field: FormlyFieldConfig) => {
                  field.formControl?.valueChanges?.subscribe((value)=> field?.form?.get('hasSignsOrArchitecturalAppurtenances')?.updateValueAndValidity());
                }
              }
            },
          ],
        },
       
      ],
      expressions: {
        hide: (field: FormlyFieldConfig) => {
          // Hide the whole section if WR is disabled
          const disabledField = field?.options?.formState?.isMainSectionDisabled?.filter(val => val.sectionId === SectionEnum.FR_WIND);
          if (disabledField.length==1) {
            return true;
          }
           else if (disabledField.length==0){
            return false;
          }
        }
      }, 
    });
  }
}