import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { RiskReportService } from 'src/app/services/formly-builder/risk-report.service';
import { Constants } from 'src/app/services/util-service/constants';
import { maxLengthForNumber, numberCheck } from 'src/app/validators/formly-builder/validators';

export class OverAgeSprinklerTestSectionModel extends FormlySectionModelAbstract {
  constructor(private riskReportService: RiskReportService) {
    super({
      key: 'overAgeSprinklerTests',
      type: 'fr-common-custom-grid-view',
      className: 'overage-sprinkler-test-container',
      wrappers: ['simple-section-wrapper'],
      props: {
        // Props for simple section wrapper
        className: 'padding-bottom',

        // Props for fr-common-custom-grid-view
        label: 'Overage Sprinkler Tests',
        isCollapsable: true,
        isCollapsed: false,
        actionButtons: [{ section: 'systemTesting', action: 'openArraySheetAdd', label: 'Add', isDisabled: false }],
        gridSection: 'overAgeSprinklerTests',
        gridColConfig: [
          { label: 'System', propertyPath: 'systemNumber', className: 'common-grid-view-width-100' },
          { label: 'MFG Year', propertyPath: 'yearOfIssue', className: 'common-grid-view-width-115' },
          { label: 'Sprinkler Type', propertyPath: 'sprinklerTypeCodeValue', formatByGivenMethod: true, action: 'getSprinklerTypeValue',
            service: 'overAgeSprinklerTestService', className: 'common-grid-view-width-195' },
          { label: 'Date Tested', propertyPath: 'dateTested', isDate: true, className: 'common-grid-view-width-115' },
        ],

        // Props for edit sheet
        title: 'System Testing',
        subTitle: 'Overage Sprinkler Tests',
        isAddIdentifier: true,
        identifierKey: 'overAgeSprinklerTestId',
        reportIdentifierKey: 'reportId',
        reportName: 'SprinklerReport',
        isSwipeActionNeeded: false,
        sheetButtons: [
          {
            section: 'overAgeSprinklerTests',
            action: 'onClose',
            label: 'Close',
            service: 'overAgeSprinklerTestService',
            className: 'close-btn medium-btn',
          },
          {
            section: 'overAgeSprinklerTests',
            action: 'onApply',
            label: 'Apply',
            service: 'overAgeSprinklerTestService',
            className: 'apply-btn large-btn',
            role: 'apply'
          }
        ],
      },
      expressions: {
        'props.actionButtons[0].label': (field: FormlyFieldConfig) => field?.model?.length ? 'Edit' : Constants.btnText,
        'props.actionButtons[0].action': (field: FormlyFieldConfig) => field?.model?.length ? 'openArraySheetEdit' : 'openArraySheetAdd'
      },
      fieldArray: {
        fieldGroupClassName: 'formly-flex-container',
        wrappers: ['error-info-wrapper'],
        fieldGroup: [
          {
            key: 'systemNumber',
            type: 'simple-input',
            className: 'input-width-xs',
            props: {
              label: 'System',
              required: true,
              isAsteriskNotRequired: true,
              maxLength: 75,
            },
          },
          {
            key: 'dateTested',
            type: 'rf-date-picker',
            props: {
              label: 'Date Tested',
              displayFormat: 'MM/DD/YY',
              required: true,
              isAsteriskNotRequired: true,
              datePickerId: 'overageSprinklerTest'
            },
            expressions: {
              'props.required':(field: FormlyFieldConfig) => field?.model?.testResults !== 'NO TEST'
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                if( field?.parent && field?.parent['index']) {
                  field.props.datePickerId = 'overageSprinklerTest' + (field?.parent['index']+1) ;
                }
              }
            },
            validators: {
              validation: [{ name: 'future-date-validator', options: { msg: 'Cannot be future date.' } }]
            }
          },
          {
            key: 'yearOfIssue',
            type: 'simple-input',
            className: 'input-width',
            props: {
              label: 'MFG Year',
              type: 'number',
              required: true,
              isAsteriskNotRequired: true,
              //pattern: '^[12]\\d{3}$',
              maxLength: 4,
              keypress: (field, event) => {
                const charCode = (event.which) ? event.which : event.keyCode;
                if (maxLengthForNumber(event.target?.value, field.props?.maxLength)) {
                  event.preventDefault();
                }
                return numberCheck(charCode);
              },
            },
            validators: {
              futureYear: {
                expression: (control: FormControl) => { if (control.value) { return Number(control.value) <= new Date().getFullYear() } return true; },
                message: 'Cannot be a future year.'
              },
              lessThan: {
                expression: (c) => c.value >= 1753,
                message: 'Invalid year',
              },
            },
            validation: {
              messages: {
                pattern: (error: any, field: FormlyFieldConfig) => `Invalid year`,
              },
            },
          },
          {
            key: 'sprinklerTypeCodeValue',
            type: 'simple-select',
            className: 'select-width-md',
            props: {
              label: 'Sprinkler Type',
              options: [],
              required: true,
              isAsteriskNotRequired: true,
            },
            hooks: {
              onInit:(field: FormlyFieldConfig) => {
                const overAgeSprinklerTestService = field.options.formState.service.overAgeSprinklerTestService;
                field.props.options = overAgeSprinklerTestService.getSprinklerTypeOptions();
              }
            }
          },
          {
            key: 'areaAffected',
            type: 'simple-input',
            className: 'input-width-xl',
            props: {
              label: 'Affected Area',
              type: 'number',
              required: true,
              isAsteriskNotRequired: true,
              unit: 'sq.ft.'
            },
          },
          {
            key: 'testConductedBy',
            type: 'simple-input',
            className: 'input-width-l',
            props: {
              label: 'Conducted by',
              required: true,
              isAsteriskNotRequired: true,
              maxlength: 100
            },
          },
          {
            key: 'testResults',
            type: 'simple-select',
            className: 'select-width-md',
            props: {
              label: 'Test Results',
              options: [
                { value: 'PASS', label: 'Pass' },
                { value: 'FAIL', label: 'Fail' },
                { value: 'NO TEST', label: 'No Test' }
              ],
              required: true,
              isAsteriskNotRequired: true,
            },
          },
          {
            key: 'actionsTaken',
            type: 'simple-input',
            className: 'input-width-l',
            props: {
              label: 'Actions Taken',
              maxLength: 100
            },
          },
        ]
      },
      validators: {
        emptyOverageSprinklerTest: {
          expression: (control: FormControl, field: FormlyFieldConfig) => {
            field.formControl.markAsTouched();
            const isOverAgeSprinkler = field.form?.root?.value?.sprinklerReport?.asgrSprinklerSystemComponent?.automaticSprinklers?.overageSprinkler?.isOverAgeSprinkler;
            if (isOverAgeSprinkler) {
              if (field.model?.length === 0) {
                return false;
              }
            }
            return true;
          },
          message: 'If Overage Sprinklers are noted in components, an entry is required in Overage Sprinkler Testing.',
        },
      }
    });
  }
}
