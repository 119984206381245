import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { FormControl } from '@angular/forms';

export class RooftopSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'roofTopLocationPoint',
      //type: 'roof-top',
      wrappers: ['simple-section-wrapper'],
      props: {
        label: 'Rooftop Placement',
        required: false,
        isCollapsable:true,
        isCollapsed:false,
        isEmptySectionOnInit: false,
        // isCollapseIconDisabled: true,
        actionButtons: [
          { section: 'roofTopLocationPoint', action: 'add', label: 'Drop Pin', isDisabled: false, className: 'btn-width' }
        ],
      },
      expressions: {
        // 'props.disabled': 'formState.disabled',
        // 'props.isDisabled': 'formState.disabled',
        // 'props.actionButtons[0].isDisabled': 'formState.disabled'
      },
      // validators: {
      //   rooftopConfirmed: {
      //     expression: (control: FormControl, field: FormlyFieldConfig) => {
      //       // const confirmed: boolean = field.formControl.value;
      //       const confirmed = control.value.confirmed;
      //       // const conf3 = field.value;
      //       if (!confirmed) {
      //         return false;
      //       }
      //       return true;
      //     },
      //     message: (error, field: FormlyFieldConfig) => `Rooftop location must be updated.`,
      //   },
      // },
      fieldGroup: [
        {
        type: 'roof-top',
        fieldGroup:[
        {
          key: 'latitude',
          defaultValue: 'undefined'
        },
        {
          key: 'longitude',
          defaultValue: 'undefined'
        },
        {
          key: 'confirmed',
          defaultValue: false,
          validators: {
            rooftopConfirmed: {
              expression: (control: FormControl, field: FormlyFieldConfig) => {
                // const confirmed: boolean = field.formControl.value;
                // const confirmed = control.value.confirmed;
                const confirmed = control.value;
                if (!confirmed) {
                  field.parent.parent.props.isEmptySectionOnInit = true;
                  return false;
                }
                field.parent.parent.props.isEmptySectionOnInit = false;
                return true;
              },
              message: 'Rooftop location must be updated',
            },
          },
        }
      ]
    }
    ]
  
    });
  }
}
