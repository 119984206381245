import { Injectable } from '@angular/core';
import { AssetType, ConfigModel } from 'src/app/enums/config-model.enum';
import { FormlyBuilderService } from './formly-builder.service';
import { UtilService } from '../util-service/util.service';
import { OrdersService } from '../orders/orders.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { AttachmentDataModel } from 'src/app/models/formly-builder/formly/full-risk/attachment-data.model';
import { SectionEnum } from './section.config';
import { CrudServiceModel } from 'src/app/models/formly-builder/crud-service.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ModalController, Platform } from '@ionic/angular';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { RiskAttachmentComponent } from 'src/app/components/formly-builder/shared/risk-attachment/risk-attachment.component';
import { Guid } from 'guid-typescript';
import { CameraSource } from '@capacitor/camera';
import { ActionSheetService } from '../actionsheet-service/actionsheet.service';
import { PhotoService } from '../orders/photo-service/photo.service';
import { ToastService } from '../toast-service/toast.service';
import { Constants } from '../util-service/constants';
import { FormlyErrorService } from './formly-error.service';
import { cloneDeep } from 'lodash';
import { Directory, Encoding, Filesystem, WriteFileOptions, WriteFileResult } from '@capacitor/filesystem';
import { OneDriveService } from '../onedrive/onedrive-service';

export enum AttachmentSourceEnum {
  Camera,
  Gallery,
  Files,
  None,
  OneDrive
};

@Injectable({
  providedIn: 'root',
})
export class RiskAttachmentService implements CrudServiceModel {
  fileData: any;

  constructor(
    private utilService: UtilService,
    private orderservice: OrdersService,
    private localStorageService: LocalStorageService,
    private actionSheetSvc: ActionSheetService,    
    public photoService: PhotoService,   
    private toasterService: ToastService,
    private errorService: FormlyErrorService,
    private modalCtrl: ModalController,
    private platform: Platform,
    private oneDriveService: OneDriveService
  ) { }

  /**
   * 
   * @param field 
   * @param attachment 
   * @param data 
   * @param isToBeDeleted 
   * @param isToBeOverwritten 
   * @returns 
   */
  public async saveRiskAttachment(field: FormlyFieldConfig, attachment: AttachmentDataModel, data,
    isToBeDeleted: boolean, isToBeOverwritten: boolean): Promise<boolean> {
    // Get values from attachment
    const reportIdentifier = attachment.reportIdentifier;
    const UniqueKey = attachment.reportAttachmentIdentifier;
    const fileName = attachment.fileName;
    const fileDescription = attachment.attachmentDescription;
    const selectedOrderNumber = field?.options?.formState?.order?.OrderIdFullOrderNumber;  
    const fileExtension = fileName ? this.utilService.getExtensionFromFileName(fileName) : '';
    
    let currentAttachments: AttachmentDataModel[];
    let keyToUpdate = UniqueKey;
    let keyToDelete;
    let isNewAttachmentNeeded = true;
    const formlyBuilder = field?.options?.formState?.service;

    if (formlyBuilder?.riskReport?.model) {
      currentAttachments = formlyBuilder?.riskReport?.model?.attachments;
    }

    if (isToBeOverwritten && currentAttachments) {
      // Update attachment
      const toBeUpdatedAttachment = currentAttachments.find((attachment: AttachmentDataModel) =>
        attachment?.fileName === fileName);
      
      if (toBeUpdatedAttachment) {
        // Only update description and timestamp
        toBeUpdatedAttachment.attachmentDescription = fileDescription || fileName;
        toBeUpdatedAttachment.attachmentTimestamp = new Date().toISOString();
        keyToUpdate = toBeUpdatedAttachment.reportAttachmentIdentifier;
        // If data is empty it won't be saved to local storage 
        isNewAttachmentNeeded = false;
      } else {
        // Add attachment
        keyToUpdate = Guid.create().toString();        
      }
    }
    
    // Update the Formly Risk Model FRM
    if (isToBeDeleted && currentAttachments) {
      // Remove attachment from FRM using the fileName
      // Search the attachments with the given fileName
      const tempAttachment = currentAttachments.find((attachment: AttachmentDataModel) =>
        attachment?.fileName === fileName);
      const toBeDeletedAttachment = cloneDeep(tempAttachment);
      // console.error("saveRiskDelete " + toBeDeletedAttachment.fileName);      
      if (toBeDeletedAttachment) {
        keyToDelete = toBeDeletedAttachment.reportAttachmentIdentifier;
        const tempAttachments2 = currentAttachments.filter((attachment: AttachmentDataModel) =>
          attachment?.reportAttachmentIdentifier !== toBeDeletedAttachment.reportAttachmentIdentifier);
        const filteredAttachments = cloneDeep(tempAttachments2);
        field.options.formState.service.riskReport.model.attachments = filteredAttachments;
      } else if (keyToUpdate) {
        keyToDelete = keyToUpdate;
        // If the filename is not found, we'll remove the attachment with the specified UniqueKey
        const filteredAttachments = currentAttachments.filter((attachment: AttachmentDataModel) =>
          attachment?.reportAttachmentIdentifier !== keyToDelete);
        formlyBuilder.riskReport.model.attachments = filteredAttachments;
      }
    } else if (isNewAttachmentNeeded && formlyBuilder?.riskReport?.model) {
      // Add the attachment to the FRM
      const newAttachment = new AttachmentDataModel();
      newAttachment.fileName = fileName;
      newAttachment.reportIdentifier = reportIdentifier;
      newAttachment.attachmentTimestamp = new Date().toISOString();
      newAttachment.attachmentDescription = fileDescription || fileName;      
      newAttachment.reportAttachmentIdentifier = keyToUpdate;
      newAttachment.fileExtension = fileExtension;
      newAttachment.attachmentTypeCodeValue = this.getTypeCodeValue(newAttachment.fileExtension);      

      if (formlyBuilder?.riskReport?.model) {
        if (!formlyBuilder?.riskReport?.model?.attachments) {
          formlyBuilder.riskReport.model.attachments = [];
        }
        formlyBuilder?.riskReport?.model?.attachments.push(newAttachment);
      }
    }

    // Update the Risk Report
    await formlyBuilder.riskReport.updateSectionData(SectionEnum.FR_COMMENT_ATTACHMENT, false);

    // Update local storage    
    const folder = attachment?.isSprinklerAttachment ? 'sprinklerattachment' : 'riskreportattachments';
    if (fileName && !isToBeDeleted && data) {
      const path = `Order/${selectedOrderNumber}/riskreport/${reportIdentifier}/${folder}/${fileName}`; 
      try {
        let isBinary = true;
        if (fileExtension.toLowerCase() === 'csv') {
          isBinary = false;
        }
        await this.updateLocalBinaryFile(path, data, isBinary);
        this.orderservice.fileSavedToLocal.next([{
          sync: true,
          model: ConfigModel.Order,
          identifier: selectedOrderNumber,
          assetType: AssetType.RiskReportAttachments,
          assetIdentifier: reportIdentifier,
          fileName: fileName,
        }]);

        // Force RR sync
        this.orderservice.fileSavedToLocal.next([{
          sync: true,
          model: ConfigModel.Order,
          identifier: selectedOrderNumber,
          assetType: AssetType.RiskReport,
          assetIdentifier: reportIdentifier
        }]);
      } catch (error) {
        console.warn(error.message);
      }        
    } else if (isToBeDeleted) {
      // Delete
      const path = `Order/${selectedOrderNumber}/riskreport/${reportIdentifier}/${folder}/${fileName}`;
      try {
        await this.localStorageService.deleteLocalFile(path);
        // Give time for the image to be deleted before notifying
        setTimeout(() => {
          this.orderservice.fileSavedToLocal.next([{
            sync: true,
            model: ConfigModel.Order,
            identifier: selectedOrderNumber,
            assetType: AssetType.RiskReportAttachments,
            assetIdentifier: reportIdentifier,
            fileName: fileName,
            fileDeleted: true,
          }]); 
        }, 1000);               
      } catch (error) {
        console.warn(error.message);
      }
    }
 
    return false;
  }  

  /**
   * Type AttachmentCodeValues according to this values:
   * public const string FrontPhoto = "0001";
   *         public const string RearPhoto = "0002";
   *         public const string Diagram = "0003";
   *         public const string Four = "0004";
   *         public const string Ax2Diagram = "0007";
   *         public const string AsgrForm = "0009";
   *         public const string WindForm = "0010";
   *         public const string Roof = "0011";
   *         public const string NinetyEight = "0098";
   *         public const string NinetyNine = "0099";
   * @param extension 
   * @returns 
   */
  private getTypeCodeValue(extension: string): string {
    if (extension === "ax2") {
      return "0007";
    }
    return "0003";
  }

  public async deleteItem(field: FormlyFieldConfig, index: number) {
    // Attachment to remove
    // console.error("deleteItem "+index);
    const attachment: AttachmentDataModel = field?.formControl.value[index];

    // Remove from local storage   
    const formlyService: FormlyBuilderService = field?.options?.formState?.service;  
    await formlyService?.riskAttachmentService?.saveRiskAttachment(field, attachment, null, true, false);

    // Refresh model anyway
    formlyService?.forceModelChangeDetection();    
  }

  public async editItem(field: FormlyFieldConfig, index: number) {
    // When called with methodRef, this is undefined!
    const formlyService: FormlyBuilderService = field?.options?.formState?.service;
    const selectedFile = await formlyService?.riskAttachmentService?.getExistingFile(field, index);
    if (selectedFile) {
      await formlyService?.riskAttachmentService?.showAttachmentSaveSheet(field, true, selectedFile);    
    }
  }

  public async addItem(field: FormlyFieldConfig) {
    // When called with methodRef, this is undefined!
    const formlyService: FormlyBuilderService = field?.options?.formState?.service;
    await formlyService?.riskAttachmentService?.showAttachmentOptions(field);
  }

  public async pickFile() {
    try {
      return await FilePicker.pickFiles({
        multiple: false,
        readData: true,
      });
    } catch (error) {
      return null;
    }
  }

  private async getExistingFile(field: FormlyFieldConfig, index: number = 0): Promise<any> {
    const formlyService = field?.options?.formState?.service;
    const reportIdentifier = formlyService?.riskReport?.model?.reportIdentifier;
    const selectedOrderNumber = field?.options?.formState?.order?.OrderIdFullOrderNumber; 
    
    // Existing attachment to edit
    const attachment = field?.formControl.value[index];

    // Retrieve file from local storage
    const folder = attachment?.isSprinklerAttachment ? 'sprinklerattachment' : 'riskreportattachments';
    const path =
      `${ConfigModel.Order}/${selectedOrderNumber}/riskreport/${reportIdentifier}/${folder}/${attachment.fileName}`;
    const fileExtension = attachment.fileExtension;

    try {
      let isBinary = true;
      if (fileExtension.toLowerCase() === 'csv') {
        isBinary = false;
      }      
      const data = await this.localStorageService.readLocalFile(path, isBinary);
      // Simulate a FilePicker result for common treatment
      return {
        assetData: {
          fileData: data,            // Retrieve from local storage?
          fileName: attachment.fileName,
          attachType: attachment.fileExtension,                         // attachment.attachmentTypeCodeValue,
          description: attachment.attachmentDescription,
        },
        Id: attachment.reportAttachmentIdentifier
      }
    } catch (error) {
      this.toasterService.showToast(`${Constants.FILE_NOT_FOUND}`, false, {
        cssClass: 'error-toast',
        duration: 3000,
      });
    }    
    return null;
  }

  private async showAttachmentSaveSheet(field: FormlyFieldConfig, isEdit: boolean, initialSelectedFile: any): Promise<boolean> {  
    let selectedFile = initialSelectedFile;
    const formlyService = field?.options?.formState?.service;
    const reportIdentifier = formlyService?.riskReport?.model?.reportIdentifier;
    const selectedOrderNumber = field?.options?.formState?.order?.OrderIdFullOrderNumber;    

    const modal = this.modalCtrl.create({
      component: RiskAttachmentComponent,
      breakpoints: [0.25, 0.5, 0.75, 1],
      initialBreakpoint: 1,
      cssClass: 'modal-fullscreen',
      componentProps: {
        fileData: {
          selectedfiles: selectedFile,
          isEdit: isEdit,
          assets: 'Risk Attachments',
          orderNumber: selectedOrderNumber,
          reportIdentifier: reportIdentifier,
          isSprinklerAttachment: false
        },
        field: field 
      },
    });
    (await modal).present();

    const response = (await (await modal).onWillDismiss()).data;
    if (response) {
      const attachment = new AttachmentDataModel;
      attachment.reportIdentifier = reportIdentifier;
      attachment.reportAttachmentIdentifier = response.Id;
      attachment.fileName = response.attachData.fileName;
      attachment.attachmentDescription = response.attachData.fileDescription;
      if (response.isEdit) {
        // If edit, overwrite with  new description (only thing that could have changed)
        this.saveRiskAttachment(field, attachment, null, false, true);
      } else if (selectedFile?.assetData?.fileData || (selectedFile?.files?.length && selectedFile.files[0].data)) {
        const isGalleryAttachment: boolean = selectedFile?.assetData?.fileData;
        if (isGalleryAttachment) {
          // Gallery photos don't have a filename, index of attachments is used
          this.saveRiskAttachment(field, attachment, selectedFile.assetData.fileData, false, false); 
        } else {
          this.saveRiskAttachment(field, attachment, selectedFile.files[0].data, false, false); 
        }
      }
    }

    // Refresh model anyway
    formlyService?.forceModelChangeDetection();

    return true;
  }  

  /**
   * Button click to Select/Capture photo from camera or gallery
   * @param id
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public async showAttachmentOptions(field: FormlyFieldConfig) {
    let selectedFile;
    const actionButtons = [
      {
        text: 'Add From Local Storage',
        role: AttachmentSourceEnum.Files,
        handler: async () => {
          selectedFile = await this.pickFile();
        }
      },
      {
        text: 'Add From Photos',
        role: AttachmentSourceEnum.Gallery,
        handler: async () => {
          // Open Gallery          
          const imageData = await this.photoService.useCamera({ source: CameraSource.Photos });
          const index = RiskAttachmentService.getLastAttachment(field);
          selectedFile = await this.getGalleryImageData(imageData, index);
        }
      },
      {
        text: 'Cancel',
        role: AttachmentSourceEnum.None,
      },
    ];
    if (this.platform.is('capacitor')) {
      actionButtons.unshift({
        text: 'Add From OneDrive',
        role: AttachmentSourceEnum.OneDrive
      });
    }

    const actionSheet = await this.actionSheetSvc.present('', 'action-sheet-fixed-width', actionButtons);
    const res = await actionSheet.onWillDismiss();
    if (res.role === AttachmentSourceEnum.OneDrive) {
      this.oneDriveService.PickFile().then((result) => {
        if (result) {
          this.showAttachmentSaveSheet(field, false, result);
        } else {
          console.log('Can not open OneDrive Modal');
          actionSheet.dismiss();
        };
      }, (error) => {
        console.log('Error in opening OneDrive Modal', error);
        actionSheet.dismiss();
      });
    } else if (res.role === AttachmentSourceEnum.Files) {
      if (selectedFile) {
        this.showAttachmentSaveSheet(field, false, selectedFile);
      } else {
        actionSheet.dismiss();
      }
    } else if (res.role === AttachmentSourceEnum.Gallery) {
      if (selectedFile) {
        this.showAttachmentSaveSheet(field, false, selectedFile);
      } else {
        actionSheet.dismiss();        
      }
    } else {
      actionSheet.dismiss();
    }
  }   

  /**
   * 
   * @param imageData 
   * @param idxAttachment 
   * @returns 
   */
  async getGalleryImageData(imageData, idxAttachment: number): Promise<any> {
    this.errorService.closePreviousToaster();
    let selectedFile;

    if (imageData?.dataUrl) {
      // "data:image/jpeg;base64,";
      const stringData: string = imageData.dataUrl;
      const index: number = stringData.indexOf("base64");
      const data = stringData.substring(index + 7);
      selectedFile = {
        assetData: {
          fileData: data,
          fileName: 'gallery' + idxAttachment + '.' + imageData.format,
          attachType: imageData.format ? imageData.format : '',
          description: 'Gallery Picture',
        },
        Id: undefined
      }  
    }
    
    return selectedFile;
  }  

  /**
   * @param field 
   * @returns Number of attachments
   */
  private static getLastAttachment(field: FormlyFieldConfig): number {
    if (field?.options?.formState?.service?.riskReport?.model?.attachments?.length) {
      return field?.options?.formState?.service?.riskReport?.model?.attachments?.length;
    } else {
      return 0;
    }
  }

  /**
   * 
   * @param base64 Can be directly a string or a Blob
   * @returns 
   */
  public static base64ToArrayBuffer(base64: any): any {
    let base64data = base64;
    console.log("base64 data : ", base64.data);
    if (base64?.data) {
      base64data = base64.data;
    }

    // Convert string to byte array
    var binaryString = atob(base64data);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  /**
   * 
   * @param localpath 
   * @param data 
   */
  public async updateLocalBinaryFile(localpath, data, isBinary = false) {
    try {
      const writeOptions: WriteFileOptions = {
        path: localpath,
        data: data,
        directory: Directory.Documents,
        // encoding: Encoding.UTF8,
        recursive: true,
      };

      if (!isBinary) {
        writeOptions.encoding = Encoding.UTF8;
      }
      const res: WriteFileResult = await Filesystem.writeFile(writeOptions);
      console.debug("Attachment updated " + res.uri);     
    } catch(error) {
      console.error("Attachment update " + error.message);
    }    
  }
}
