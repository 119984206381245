import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule } from '@angular/common';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppConfig } from './app.config';
import { AppService } from './services/app-service/app.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './auth/auth.module';
import { AmplifyService } from './services/amplify/amplify.service';
import { ENV } from './interfaces/env.interface';
import { environment } from '../environments/environment';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { Network } from '@ionic-native/network/ngx';
import { StreetViewComponent } from './components/streetview/street-view.component';
import { S3SyncService } from './services/s3-sync-service/s3-sync.service';

import { IonicStorageModule } from '@ionic/storage-angular';
import { DirectivesModule } from './directives/directives.module';
import { FridService } from './services/frid-service/frid.service';
import { ScheduleCodeService } from './services/formly-builder/schedule-tree.service';
import { AmplifySaveService } from './services/amplify/amplify.save';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ViewingPhotoComponent } from './components/viewing-photo/viewing-photo.component';
import { OrdersService } from './services/orders/orders.service';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { RiskAttachmentService } from './services/formly-builder/risk-attachment.service';
import { PreviewAnyFile } from '@awesome-cordova-plugins/preview-any-file/ngx';
import { OneDriveExplorerComponent } from './components/onedrive-explorer/onedrive-explorer.component';
import { OneDriveAuthObserver } from './services/auth-service/onedrive-auth-observer';
@NgModule({
  declarations: [AppComponent, StreetViewComponent, ViewingPhotoComponent, OneDriveExplorerComponent],
  imports: [
    BrowserModule,
    DirectivesModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AuthModule,
    DirectivesModule,
    CommonModule,
    IonicStorageModule.forRoot(),
    PdfViewerModule,
  ],
  providers: [
    AppConfig,
    CallNumber,
    Calendar,
    FridService,
    Network,
    S3SyncService,
    Storage,
    AmplifySaveService,
    OrdersService,
    ScheduleCodeService,
    LocalStorageService,
    OneDriveAuthObserver,
    RiskAttachmentService,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: ENV,
      useValue: environment,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfig) => () => config.load(),
      deps: [AppConfig, AppService, AmplifyService, S3SyncService, ScheduleCodeService],
      multi: true,
    },
    ScreenOrientation,
    InAppBrowser,
    PhotoViewer,
    PreviewAnyFile
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
