/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { RiskReportDataOccupantLevel } from 'src/app/models/risk-report/risk-report-data.model';
import { FloorsAndRoofDataModel } from '../../floors-roof-data.model';

/**
 * Class that represents the Formly Model for OccupantLevel
 */
export class OccupantHazardsLevelDataModel {
  static sectionName = 'floors';

  floorNo: string;
  floorPercentage: number;
  //percentageOccupied: number;

  // New data
  name: string;
  level: number;      // @deprecated Use levelStart
  area: number;
  levelStart: number;
  levelStop: number;
  length: number;
  width: number;
  areaPerLevel: number;
  automaticSprinkler: string;

  occupantLevelIdentifier?: string;
  occupantIdentifier?: string;
  hasExtinguisher?: boolean;
  hasLimitedSupplyFireProtectionSystem?: boolean;
  limitedSupplyFireProtectionSystemComment?: string;
  sprinklerDesign: string;
  sprinklerType: string;
/**
 * @deprecated
 * @param alias
 * @returns
 */
  static from(alias: RiskReportDataOccupantLevel): OccupantHazardsLevelDataModel {
    let area = 0;
    let areaPerLevel = 0;
    if (alias.FloorLength && alias.FloorWidth) {
      areaPerLevel = alias.FloorLength * alias.FloorWidth;
    }
    if (alias.LevelStop > 0 && alias.LevelStart < alias.LevelStop) {
      area = areaPerLevel*(alias.LevelStop - alias.LevelStart + 1);
    }

    // TODO: Check changes to model! floorNo is not there now!!!
    return {
      name: FloorsAndRoofDataModel.getFloorName(alias.LevelType),
      floorNo: (FloorsAndRoofDataModel.getFloorName(alias.LevelType) !== null && alias.LevelStart !== null) ? (FloorsAndRoofDataModel.getFloorName(alias.LevelType)+alias.LevelStart) : null,
      floorPercentage:  alias.Percentage   !== null ? alias.Percentage : 0,
      level: alias.LevelStart,
      levelStart: alias.LevelStart,
      levelStop: alias.LevelStop,
      areaPerLevel: areaPerLevel,
      area: area,
      length: alias.FloorLength,
      width: alias.FloorWidth,
      automaticSprinkler: alias.AutomaticSprinklerType,
      sprinklerDesign: alias?.SprinklerDesign?alias?.SprinklerDesign:null,
      sprinklerType: alias?.SprinklerType?alias?.SprinklerType:null,
    };
  }


  static fromS3(alias: any): OccupantHazardsLevelDataModel {
    let areaPerLevel = 0;
    let area = 0;
    if (alias.FloorLength && alias.FloorWidth) {
      areaPerLevel = alias.FloorLength * alias.FloorWidth;
    }
    if (alias.LevelStop > 0 && alias.LevelStart < alias.LevelStop) {
      area = areaPerLevel*(alias.LevelStop - alias.LevelStart + 1)
    }
    else {
      area = areaPerLevel;
    }
    switch (alias?.AutomaticSprinklerType) {
      case 'NONE':
        alias.SprinklerDesign = 'None';
        // alias.SprinklerType=null;
        break;
      case 'PIPE':
         alias.SprinklerDesign = 'Pipe Schedule';
        //  alias.SprinklerType=null;
        break;
      case '13-R':
         alias.SprinklerDesign = '13R';
        //  alias.SprinklerType=null;
        break;
      default:
        alias.SprinklerDesign = alias.SprinklerDesign? alias.SprinklerDesign: null;
        alias.SprinklerType =alias.SprinklerType?alias.SprinklerType:null;
    }
    if(alias.AutomaticSprinklerType=='13NC'){
      alias.SprinklerDesign='13R';
          alias.SprinklerType='Non-Creditable';
    }
    if(alias.AutomaticSprinklerType=='SHUT'){
      alias.SprinklerType='Shut Off';
      // alias.SprinklerDesign = null;
    }
     if(alias.AutomaticSprinklerType=='HYDR' && (alias.SprinklerDesign=='Hydraulic'||alias.SprinklerDesign==null||alias.SprinklerDesign==''||alias.SprinklerDesign==undefined)){
      alias.SprinklerDesign='Hydraulic';
    }
    if(alias.AutomaticSprinklerType=='HYDR' && alias.SprinklerDesign=='Hydraulic - ESFR'){
      alias.SprinklerDesign='Hydraulic - ESFR';
    }
    if(alias.AutomaticSprinklerType=='HYDR' && alias.SprinklerDesign=='Hydraulic - CMDA'){
      alias.SprinklerDesign='Hydraulic - CMDA';
    }
    if(alias.AutomaticSprinklerType=='HYDR' && alias.SprinklerDesign=='Hydraulic - CMSA'){
      alias.SprinklerDesign='Hydraulic - CMSA';
    }
    // TODO: Check changes to model! floorNo is not there now!!!
    return {
      name: FloorsAndRoofDataModel.getFloorName(alias.OccupantLevelType),
      floorNo: (FloorsAndRoofDataModel.getFloorName(alias.OccupantLevelType) !== null && alias.LevelStart !== null) ? (FloorsAndRoofDataModel.getFloorName(alias.OccupantLevelType)+alias.LevelStart) : null,
      floorPercentage: alias.Percentage   !== null ? alias.Percentage : 0,
      level: alias.LevelStart,
      levelStart: alias.LevelStart,
      levelStop: alias.LevelStop ? alias.LevelStop : null,
      areaPerLevel,
      area,
      length: alias.FloorLength,
      width: alias.FloorWidth,
      automaticSprinkler: alias.AutomaticSprinklerType,
      occupantLevelIdentifier: alias.OccupantLevelIdentifier? alias.OccupantLevelIdentifier : undefined,
      occupantIdentifier: alias.OccupantIdentifier ? alias.OccupantIdentifier : undefined,
      hasExtinguisher: alias.HasExtinguisher,
      hasLimitedSupplyFireProtectionSystem: alias.HasLimitedSupplyFireProtectionSystem,
      limitedSupplyFireProtectionSystemComment: alias.LimitedSupplyFireProtectionSystemComment,
      sprinklerDesign: alias?.SprinklerDesign?alias?.SprinklerDesign:null,
      sprinklerType: alias?.SprinklerType?alias?.SprinklerType:null,
    };
  }
 
  /**
   * Returns a S3 RiskReport object from a Formly model
   */
  static to(model: any) {
    let AutomaticSprinklerType;
      switch (model?.sprinklerDesign) {
        case 'None':
          AutomaticSprinklerType = 'NONE';
          break;
        case 'Hydraulic':
          AutomaticSprinklerType = 'HYDR';
          break;
        case 'Pipe Schedule':
          AutomaticSprinklerType = 'PIPE';
          break;
        case '13R':
          AutomaticSprinklerType = '13-R';
          break;
        case 'Hydraulic - ESFR':
          AutomaticSprinklerType = 'HYDR';
          break;
        case 'Hydraulic - CMDA':
          AutomaticSprinklerType = 'HYDR';
          break;
        case 'Hydraulic - CMSA':
        AutomaticSprinklerType = 'HYDR';
        break;
        default:
          AutomaticSprinklerType = null;
      }
      if (model?.sprinklerType == 'Non-Creditable' && model?.sprinklerDesign == '13R') {
        AutomaticSprinklerType = '13NC';
      }
      if (model?.sprinklerType == 'Shut Off') {
        AutomaticSprinklerType = 'SHUT';
      }
    return {
      OccupantLevelIdentifier: model.occupantLevelIdentifier ? model.occupantLevelIdentifier : undefined,
      OccupantIdentifier: model.occupantIdentifier ? model.occupantIdentifier : undefined,
      OccupantLevelType: FloorsAndRoofDataModel.getLevelType(model.name),
      FloorLength: +model.length,
      FloorWidth: +model.width,
      Percentage: model.floorPercentage,
      LevelStart: model.levelStart ? +model.levelStart : null, // BugFix: LevelStart Forced to Number
      LevelStop: +model.levelStop ? +model.levelStop : null,
      AutomaticSprinklerType:AutomaticSprinklerType ?AutomaticSprinklerType : null,
      HasExtinguisher: model.hasExtinguisher ? model.hasExtinguisher : false,
      HasLimitedSupplyFireProtectionSystem: model.hasLimitedSupplyFireProtectionSystem ? model.hasLimitedSupplyFireProtectionSystem : false,
      LimitedSupplyFireProtectionSystemComment: model.limitedSupplyFireProtectionSystemComment,
       // newly added keys for Sprinkler
    SprinklerDesign: model?.sprinklerDesign?model?.sprinklerDesign:null,
    SprinklerType: model?.sprinklerType?model?.sprinklerType:null,
    };
  }

}
