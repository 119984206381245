/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { takeUntil } from 'rxjs/operators';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { WallEnvelopeDataModel } from 'src/app/models/formly-builder/formly/wind/wall-envelope-data.model';
import { FormlyBuilderService } from '../formly-builder.service';
import { ModalController } from '@ionic/angular';
import { SectionEnum } from '../section.config';

@Injectable({
  providedIn: 'root',
})
export class WindWallEnvelopeService extends SectionServiceAbstract {

  constructor() {
    super();
  }

  async updateData(model: any, riskReportData: any): Promise<any> {
    // Check if data has changed
    const hasChanged: boolean = WallEnvelopeDataModel.hasChanged(model, riskReportData);;           // AddressAliasDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const wallEnvelope = WallEnvelopeDataModel.to(model[ 'windReport' ].wallEnvelope, riskReportData?.ReportIdentifier);
      const windReportData = { ...riskReportData.WindReport, ...{ WallEnvelope: wallEnvelope } };
      return { ...riskReportData, ...{ WindReport: windReportData } };
    } else {
      return undefined;
    }      // AddressAliasDataModel.to(model[ 'address' ]);

  }

  async onApply(modal: ModalController, field: FormlyFieldConfig) {
    try {
      // Get values from the options...
      const checkboxOptionsWallCladding = field.fieldGroup[ 0 ].fieldGroup[ 0 ].props.options;
      const checkboxOptionsGlassType = field.fieldGroup[ 1 ].fieldGroup[ 0 ].props.options;
      const wallGlassTypeModel = {};
      const wallCladdingModel = {};
      checkboxOptionsWallCladding.forEach((option) => {
        wallCladdingModel[ option[ 'checkboxKey' ] ] = option[ 'isItemChecked' ] === true;
        wallCladdingModel[ option[ 'predominantKey' ] ] = option[ 'isPredominant' ] === true;
      });

      checkboxOptionsGlassType.forEach((option) => {
        wallGlassTypeModel[ option[ 'checkboxKey' ] ] = option[ 'isItemChecked' ] === true;
        wallGlassTypeModel[ option[ 'predominantKey' ] ] = option[ 'isPredominant' ] === true;
      });

      // update model
      const predominantformlyBuilderService: FormlyBuilderService = field.options.formState.service;
      const glassTypeSelect = predominantformlyBuilderService.riskReport.model.windReport.wallEnvelope.wallGlassType;
      for (const key in glassTypeSelect) {
        if (!(key in wallGlassTypeModel)) {
          wallGlassTypeModel[ key ] = glassTypeSelect[ key ];
        }
      }

      const claddingTypeSelect = predominantformlyBuilderService.riskReport.model?.windReport?.wallEnvelope?.wallCladding;
      for (const key in claddingTypeSelect) {
        if (!(key in wallCladdingModel)) {
          wallCladdingModel[ key ] = claddingTypeSelect[ key ];
        }
      }
      const wallEnvelope = {
        ...predominantformlyBuilderService.riskReport.model.windReport.wallEnvelope,
        ...{
          wallGlassType: wallGlassTypeModel,
          wallCladding: wallCladdingModel
        }
      };


      predominantformlyBuilderService.riskReport.model = {
        ...predominantformlyBuilderService.riskReport.model,
        windReport: {
          ...predominantformlyBuilderService.riskReport.model.windReport,
          ...{ wallEnvelope }
        }
      };

      const formlyBuilderService: FormlyBuilderService = field.options.formState.service;
      // Save data to the Risk Report
      const riskReport = await formlyBuilderService.riskReport.updateSectionData(SectionEnum.FR_W_WALL_ENVELOPE, false);

      const newModel = formlyBuilderService.windReportService.loadData(
        riskReport,
        riskReport,
        true,
        formlyBuilderService.riskReport.model
      );
      if (newModel) {
        formlyBuilderService.riskReport.model = { ...newModel };
      }
      //Getting initial state data, if we dont have any data initially we will set error as null on close
      let hasSomeValues = false;
      const roofEnvelope = field.options.formState.initialStateStore.get('windReport.wallEnvelope');
      // Undefine check
      if (wallEnvelope[ 'wallCladding' ]) {
        const hasEnvelopeGeometry = Object.values(roofEnvelope[ 'wallCladding' ]).some(value => value === undefined);
        hasSomeValues = roofEnvelope ? true : false;
        // If we have values we mark as touched
        if (hasSomeValues && !hasEnvelopeGeometry) {
          field.fieldGroup.forEach(field => {
            field.formControl.markAsTouched();
            field.fieldGroup?.forEach(field => {
              field.formControl?.markAsTouched();
            });
          });
        } else {
          // else if remove all errors
          field.fieldGroup.forEach(field => {
            field.formControl.setErrors(null);
            field.formControl?.markAsUntouched();
            field.fieldGroup?.forEach(field => {
              field.formControl.setErrors(null);
              field.formControl?.markAsUntouched();
            });
          });
        }
      }
    } catch (ignore) { }

    // Dismiss the modal
    modal.dismiss();
  }

  onClose(modalCtrl, field, initialModel) {
    const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const reportData = formlyService.riskReport.report;
    const newModel = formlyService.windReportService.loadData(
      reportData,
      reportData,
      true,
      formlyService.riskReport.model
    );
    if (newModel) {
      formlyService.riskReport.model = { ...newModel };
    }
    formlyService.errorService.closePreviousToaster();
    modalCtrl.dismiss();
  }

  loadData(addresses: any[], fullData: any, isS3: boolean, model: any): any {
    return model;
  }
  initCheckboxes(field: FormlyFieldConfig, checkboxOptions: any[]) {
    try {
      // Get values from the options...
      const rootModel = field?.options?.formState?.service?.riskReport?.model;
      let checkboxGroupModel = rootModel;
      const branchArray = field?.props?.path.split('.');
      branchArray.forEach((branch) => {
        checkboxGroupModel = checkboxGroupModel[ branch ];
      });

      checkboxOptions.forEach((option) => {
        if (checkboxGroupModel) {
          if (checkboxGroupModel[ option[ 'checkboxKey' ] ] === true) {
            option[ 'isItemChecked' ] = true;
          }

          if (checkboxGroupModel[ option[ 'predominantKey' ] ] === true) {
            option[ 'isPredominant' ] = true;
          }
        }
      });
    } catch (ignore) { }

    // Load the options as props
    field.props.options = checkboxOptions;
  }

  setDefaultValue(field: FormlyFieldConfig, value, isDisabled) {
    field.formControl?.setValue(value);
    field.props.isDisabled = isDisabled;
  }

  setWallNotInBasicConstructio(field: FormlyFieldConfig) {
    field?.form?.root?.value?.secondaryConstructions?.miscellaneousSecondaryConstruction?.combustibleExteriorAttachmentArea > 0 ? this.setDefaultValue(field, 'Yes - Not included in Basic Construction', true) : this.setDefaultValue(field, field?.form?.get('awningsCanopiesOrCoveredWalkways')?.value, false);
  }

  markAsTouched(field: FormlyFieldConfig) {
    const wallEnvelope = field.options.formState.initialStateStore.get('windReport.wallEnvelope');
    const hasSomeValues = wallEnvelope?.wallGlassType ? (Object.values(wallEnvelope?.wallGlassType)?.filter(x => x !== undefined && x !== null)?.length > 0 ? true : false) : false;
    const predominantHasSomeValues = wallEnvelope?.wallGlassType ? (Object.values(wallEnvelope?.wallGlassType)?.filter(x => x !== undefined && x !== null)?.length > 0 ? true : false) : false;

    if (hasSomeValues || predominantHasSomeValues) {
      field.formControl?.markAsTouched();
      field.fieldGroup?.forEach(field => field.formControl?.markAsTouched());
    } else {
      field.formControl?.markAsUntouched();
      field.formControl?.setErrors(null);
      field.fieldGroup?.forEach(field => field.formControl?.markAsUntouched());
    }
  }
}
