import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public loading: any;
  constructor(public loadingController: LoadingController) {}
  presentLoading() {
    if (this.loading == null) {
      this.loadingController
        .create({
          cssClass: 'maps-spinner',
          message: 'Loading Markers',
          showBackdrop: false,
          duration: 3000,
        })
        .then((response) => {
          response.present();
        });
    }
  }

  dismissLoading() {
    this.loadingController.dismiss().catch((err) => {
      console.log('Error occured closing loader : ', err);
      this.loading = null;
    });
  }

  async showLoading(message) {
    this.loading = await this.loadingController.create({
      message,
      cssClass: 'custom-spinner',
    });
    await this.loading.present();
  }
}
