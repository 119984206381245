/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, Injector } from '@angular/core';
import { MOCK_OVERVIEW } from '../../../mock_data/mock-overview';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RiskModeEnum, RiskModeConfig } from 'src/app/enums/formly-builder/risk-mode.enum';
import { FormlyBuilderJsonOptionsService } from './formly-json-options.service';
import { SectionPhotosService } from './section-photos.service';
import { SectionRoofTopService } from './section-roof-top.service';
import { Platform } from '@ionic/angular';
import { RiskReportService } from './risk-report.service';
import { SectionConfig, SectionEnum } from './section.config';
import { FormlyBuildingBGService } from './formly-building-bg.service';
import { FormlyOccupantsService } from 'src/app/services/formly-builder/formly-occupants.service';
import { FormGroup } from '@angular/forms';
import { AmplifyService } from '../amplify/amplify.service';
import { takeUntil } from 'rxjs/operators';
import { ScheduleCodeService } from './schedule-tree.service';
import { SectionConstVerifService } from './section-construction-verification.service';
import { FormlyAddressService } from './formly-address.service';
import { FormlyErrorService } from './formly-error.service';
import { FormlyWallsService } from './formly-walls.service';
import { SprinklerReportService } from './full-risk/sprinkler/sprinkler-report.service';
import { FormlyFloorsRoofService } from './formly-floors-roof.service';
import { GeneralInformationService } from './general-information.service';
import { FormlyCommentService } from './formly-comment.service';
import { FireSprinklerService } from './overview/fire-sprinkler.service';
import { FormlyWallsConstructionService } from './formly-walls-construction.service';
import { FormlyFormState } from 'src/app/models/formly-builder/formly-form-state.model';
import { FormlyWrapperService } from './formly-wrapper.service';
import { CalculationService } from './calculation.service';
import { FormlyInternalProtectionService } from './full-risk/formly-internal-protection.service';
import { HookReuseableService } from './hook-reuseable.service';
import { WaterSupplyService } from './full-risk/sprinkler/water-supply.service';
import { WallsChargeableColumnService } from './full-risk/formly-walls-chargeable-column.service';
import { WallsChargeablePanelsService } from './full-risk/formly-walls-chargeable-panels.service';
import { FormlyPublicProtectionAreaService } from './formly-ppa.service';
import { ScoreCardService } from './full-risk/sprinkler/score-card.service';
import { ExposuresService } from './full-risk/exposures/exposures.service';
import { OccupantHazardsService } from './full-risk/occupancy-hazards/occupant-hazards.service';
import { FirePumpTestsService } from './full-risk/sprinkler/fire-pump-tests.service';
import { MainDrainEstimationService } from './full-risk/sprinkler/main-drain-estimation.service';
import { ConstructionByLevelService } from './full-risk/floors-and-roofs/construction-by-level.service';
import { PublicProtectionService } from './full-risk/formly-public-protection.service';
import { WindRoofEnvelopeService } from './wind/wind-roof-envelope.service';
import { EnhancedWindService } from './wind/enhanced-wind.service';
import { WindEnvironmentExposuresService } from './wind/wind-environment-exposures.service';
import { WindFrameworkService } from './wind/wind-framework.service';
import { WindLossService } from './wind/wind-loss.service';
import { WindWallEnvelopeService } from './wind/wind-wall-envelop.service';
import { SecondaryConstructionsService } from './secondary-constructions.service';
import { SystemComponentService } from './full-risk/sprinkler/system-component.service';
import { SystemTestingService } from './full-risk/sprinkler/system-testing.service';
import { WindReportService } from './wind/wind-report.service';
import { DeepLinkService } from './deep-link.service';
import { ErrorModel } from 'src/app/models/formly-builder/error.model';
import { SprinklerTableService } from './full-risk/sprinkler/sprinkler-table.service';
import { SystemDesignCalculateService } from './full-risk/sprinkler/system-design-calculate.service';
import { TwoInchDrainTestService } from './full-risk/sprinkler/two-inch-drain-test.service';
import { DryPipeValveTestService } from './full-risk/sprinkler/dry-pipe-valve-test.service';
import { DryPipeValveTripTestService } from './full-risk/sprinkler/dry-pipe-valve-trip-test.service';
import { FrictionLossSupplySourceService } from './full-risk/sprinkler/friction-loss-supply-source.service';
import { OverAgeSprinklerTestService } from './full-risk/sprinkler/overage-sprinkler-test.service';
import { InternalSprinklerPipeConditionsService } from './full-risk/sprinkler/internal-pipe-conditions.service';
import { AlarmAndSupervisoryTestService } from './full-risk/sprinkler/alarm-supervisory-test.service';

import { DataDesignTableService } from './full-risk/sprinkler/data-design-table.service';
import { NonSprinkleredAreasService } from './full-risk/sprinkler/non-sprinklered-areas.service';
import { BuildingCodesService } from './overview/building-codes.service';
import { BuildingConditionService } from './full-risk/sprinkler/building-condition.service';
import { FormlyBuildingInformationService } from './formly-building-information.service';
import { SpocCalculationService } from './full-risk/sprinkler/spoc-calculation.service';
import { RiskAttachmentService } from './risk-attachment.service';
import { EnhancedWindEligibleService } from './overview/enhanced-wind-eligible.service';
/**
 * FormlyBuilderService
 * Facade Service to call other specific reusable services.
 * Main responsibilities:
 * - Hold the state of the Formly form (fields, options)
 * - Intermediately hold some other app status variables
 * - Serve as a Facade for other services needed from inside Formly (for hooks, expressions)
 */
@Injectable({
  providedIn: 'root',
})
export class FormlyBuilderService {
  // Formly state variables
  public fields$: Observable<FormlyFieldConfig[]>;
  public options: FormlyFormOptions = {};
  public valid$: Observable<boolean>;
  public statusLineVisibility$: Observable<boolean>;

  // Other state variables
  public isOnline: boolean;
  public isSheetActive: boolean;

  // Private Formly state variables
  /* eslint-disable no-underscore-dangle */
  private _fields: BehaviorSubject<FormlyFieldConfig[]> = new BehaviorSubject([]);
  /* eslint-disable no-underscore-dangle */
  private _valid: BehaviorSubject<boolean> = new BehaviorSubject(true);
  // Notify subscribers of new errors arrived
  private _statusLineVisibility: BehaviorSubject<boolean> = new BehaviorSubject(false);

  // Private - Other state variables
  private _mode: RiskModeEnum = RiskModeEnum.CURRENCY; // Mode of operation (CURRENCY / COVERAGE / FULLRISK)
  private userInfo?; // Developer Tools
  private userId?;
  private platform = 'unknown';
  private _isV1 = true; // SCOPES V1
  private _isV2 = true; // SCOPES V2
  private _isMainSectionDisabled = [];
  private ngUnsubscribe = new Subject<void>();

  // Section Services to inject to the Facade
  // RiskReportService and ScheduleCodeService are Core Services and will
  //   be directly injected and added to the Formly options state, no need to add here
  private _deepLinkService?: DeepLinkService;
  private _calculationService?: CalculationService;
  private _jsonOptionsService?: FormlyBuilderJsonOptionsService;
  private _sectionPhotosService?: SectionPhotosService;
  private _sectionOccupantsService?: FormlyOccupantsService;
  private _sectionRoofTopService?: SectionRoofTopService;
  private _sectionConstVerifService?: SectionConstVerifService;
  private _sectionWallsService?: FormlyWallsService;
  private _sprinklerReportService?: SprinklerReportService;
  private _secondaryConstructionsService?: SecondaryConstructionsService;
  private _floorsAndRoofService?: FormlyFloorsRoofService;
  private _publicProtectionAreaService?: FormlyPublicProtectionAreaService;
  // TODO: Rename to SectionBuildingBGService
  private _formlyBuildingBGService?: FormlyBuildingBGService;
  private _formlyBuildingInformationService?: FormlyBuildingInformationService;
  private _formlyAddressService?: FormlyAddressService;
  private _generalInformationService?: GeneralInformationService;
  private _formlyCommentService?: FormlyCommentService;
  private _fireSprinklerService?: FireSprinklerService;
  private _hookReuseableService?: HookReuseableService;
  private _waterSupplyService?: WaterSupplyService;
  private _systemDesignCalculateService?: SystemDesignCalculateService;
  private _wallsChargeableColumnService?: WallsChargeableColumnService;
  private _wallsChargeablePanelsService?: WallsChargeablePanelsService;
  private _scoreCardService?: ScoreCardService;
  private _exposuresService?: ExposuresService;
  private _occupantHazardsService?: OccupantHazardsService;
  private _firePumpTestsService?: FirePumpTestsService;
  private _mainDrainEstimationService?: MainDrainEstimationService;
  private _constructionByLevelService?: ConstructionByLevelService;
  private _publicProtectionService?: PublicProtectionService;
  private _systemComponentService?: SystemComponentService;
  private _systemTestingService?: SystemTestingService;
  private _sprinkerTableService?: SprinklerTableService;
  private _dataDesignTableService?: DataDesignTableService;
  private _twoInchDrainTestService?: TwoInchDrainTestService;
  private _dryPipeValveTestService?: DryPipeValveTestService;
  private _dryPipeValveTripTestService?: DryPipeValveTripTestService;
  private _frictionLossService?: FrictionLossSupplySourceService;
  private _nonSprinkleredAreaService?: NonSprinkleredAreasService;
  private _overAgeSprinklerTestService?: OverAgeSprinklerTestService;
  private _spocCalculationService?: SpocCalculationService;
  private _alarmAndSupervisoryTestService?: AlarmAndSupervisoryTestService;
  private _internalSprinklerPipeConditionsServiice?: InternalSprinklerPipeConditionsService;
  private _windEnvExposureService?: WindEnvironmentExposuresService;
  private _windReportService?: WindReportService;
  private _buildingCodesService?: BuildingCodesService;
  private _riskAttachmentService?: RiskAttachmentService;
  private _enhancedWindEligibleService: EnhancedWindEligibleService;

  // Wind Services
  private _enhancedWindService: EnhancedWindService;
  private _windFrameworkService: WindFrameworkService;
  private _windLossService: WindLossService;
  private _windRoofEnvelopeService: WindRoofEnvelopeService;
  private _windWallEnvelopeService: WindWallEnvelopeService;
  private _buildingConditionService: BuildingConditionService;

  // service for 3.0 walls
  private _sectionConstructionWallsService?: FormlyWallsConstructionService;
  private _formlyWrapperService?: FormlyWrapperService;
  private _formlyInternalprotectionService?: FormlyInternalProtectionService;

  constructor(
    private injector: Injector,
    private amplify: AmplifyService,
    private _scheduleService: ScheduleCodeService,
    private _riskReportService: RiskReportService,
    private _errorService: FormlyErrorService,
    public plt: Platform
  ) {
    this.fields$ = this._fields.asObservable();
    this.valid$ = this._valid.asObservable();
    this.statusLineVisibility$ = this._statusLineVisibility.asObservable();

    // Checking for network connection
    this.amplify.networkConnection.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      this.isOnline = value;
    });

    // Get platform info
    if (plt.is('ios')) {
      this.platform = 'ios';
    } else if (plt.is('mobileweb')) {
      this.platform = 'mobileweb';
    } else if (plt.is('desktop')) {
      this.platform = 'desktop';
    }

    try {
      // Get user info
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.userId = this.userInfo.preferred_username.split('@')[ 0 ];
    } catch (ignore) { }

    this.initFormState();
  }

  /**
   * Providing Form Options to be available from Formly expressions / hooks
   * Best for storing state info not needed in the model
   */
  private initFormState() {
    this.options.formState = {
      service: this, // This Facade Service itself
      sheduleService: this._scheduleService, // Schedule Service
      riskReportService: this._riskReportService, // Risk Report Service
      platform: this.platform, // IOS, Web
      user: this.userId, // User iNumber
      target: {}, // Risk Form Target for Currency/Coverage. Simulated for Full Risk
      order: null, // Order information for Full Risk. Emtpy for Curency/Coverage
      isCompleteBtnClicked: false, // Complete Button has been clicked
      v1Enabled: this._isV1, // SCOPES v1 enabled
      v2Enabled: this._isV2, // SCOPES v2 enabled
      isMainSectionDisabled: this._isMainSectionDisabled, // Full risk main section drop down option disabled List (eg: walls/ sprinkler)
      externalErrors: null, // Array of errors returned from the calculate API backend endpoint
      initialStateStore: new Map<string, any>(), // Store where we temprrary save initial state
      frm: {}, // For easier debugging: copy frm and rr to the initFormState
      rr: {},
      isHydraulicEntryPresent: false,
      isPipeScheduleEntryPresent: false,
      isSprinklerTypeCodeWarningEnabled: false,
      isSectionIICollapsed: true
    };
  }

  // Observables
  get fields(): FormlyFieldConfig[] {
    return this._fields.getValue();
  }

  set fields(nextState: FormlyFieldConfig[]) {
    this._fields.next(nextState);
  }

  get valid(): boolean {
    return this._valid.getValue();
  }

  set valid(nextState: boolean) {
    this._valid.next(nextState);
  }

  get statusLineVisibility(): boolean {
    return this._statusLineVisibility.getValue();
  }

  set statusLineVisibility(nextState: boolean) {
    this._statusLineVisibility.next(nextState);
  }

  // Flags
  public get isMainSectionDisabled() {
    return this._isMainSectionDisabled;
  }
  public set isMainSectionDisabled(value) {
    this._isMainSectionDisabled = value;
  }

  get isV1(): boolean {
    return this._isV1;
  }

  set setV1(nextState: boolean) {
    this._isV1 = nextState;
  }

  get isV2(): boolean {
    return this._isV2;
  }

  set setV2(nextState: boolean) {
    this._isV2 = nextState;
  }

  get isUserDeveloper(): boolean {
    if (!this.userInfo) {
      return false;
    }

    const authUsers: Array<string> = [
      'I31034', // Aaron Alanis
      'I98149', // Sundar Thiyagarajan
      'I36635', // Miguel Sobejano Garcia
      'I37399', // Ramya Bandaru
      'I37617', // Mounika Pandala
      'I39165', // Valarmathi Balashunmugam
      'I39162', // Mounika Pandala
      'I39618', //Kevin SabuP
      'I39614', //Sudharsan Murugan
      'I40442', //Snehasis Den
      'I37615', //Vishnuprasad TV
      'I40391', //Sajid Hussain
      'I39753', //Nuthanakalva Sunitha
      'I42643', //Amit Sodmise
      'I43335', // Rutvi Saini
      'I42182', //Namita Panda
    ];
    if (authUsers.indexOf(this.userId) > -1) {
      return true;
    }
    return false;
  }

  /**
   * Change the mode of the active Risk Form
   *
   * @param mode RiskModeEnum.COVERAGE / CURRENCY / FULLRISK
   */
  set mode(mode: RiskModeEnum) {
    this._mode = mode;
  }

  get mode(): RiskModeEnum {
    return this._mode;
  }

  /**
   * TODO: Check if needed
   *
   * @returns
   */
  public getOverviewForm() {
    return MOCK_OVERVIEW;
  }

  public resetModel(model: any) {
    // (this.options as any).resetModel();
    this.options = {};
    this.options.resetModel?.(model);
    this.initFormState();
  }

  /**
   * Clears the form to start fresh
   */
  public clearFields() {
    this.fields = [];
  }

  /**
   * Show all form errors
   */
  public async showErrors(): Promise<void> {
    try {
      if (this.fields.length > 0) {
        await this._errorService.showErrors(this.fields[ 0 ].formControl.root);
      }
    } catch (error) {
      console.warn('showErrors ' + error);
    }
  }

  /**
   * Check if form is valid
   *
   * @param consChange forces valid to be true
   * @param form
   * @returns
   */
  public checkFormValidity(consChange: boolean, form: FormGroup) {
    let newValid: boolean;
    if (consChange === true) {
      newValid = true;
    } else {
      const allErrors = this._errorService.checkErrors(form, '', false);
      newValid = allErrors && allErrors.length > 0 ? false : true;
      if (newValid != form.valid) {
        // This should not happen...
        console.warn('Errors exist but form.valid is true');
      }
    }
    return newValid;
  }

  /*+-------------------------------+*/
  /*| Service specific method calls |*/
  /*+-------------------------------+*/
  public get riskReport(): RiskReportService {
    return this._riskReportService;
  }

  public get scheduleService(): ScheduleCodeService {
    return this._scheduleService;
  }

  public get errorService(): FormlyErrorService {
    return this._errorService;
  }

  public get deepLinkService(): DeepLinkService {
    if (!this._deepLinkService) {
      this._deepLinkService = this.injector.get(DeepLinkService);
    }
    return this._deepLinkService;
  }

  public get calculationService(): CalculationService {
    if (!this._calculationService) {
      this._calculationService = this.injector.get(CalculationService);
    }
    return this._calculationService;
  }

  public get formlyBuildingBGService(): FormlyBuildingBGService {
    if (!this._formlyBuildingBGService) {
      this._formlyBuildingBGService = this.injector.get(FormlyBuildingBGService);
    }
    return this._formlyBuildingBGService;
  }

  public get formlyBuildingInformationService(): FormlyBuildingInformationService {
    if (!this._formlyBuildingInformationService) {
      this._formlyBuildingInformationService = this.injector.get(FormlyBuildingInformationService);
    }
    return this._formlyBuildingInformationService;
  }

  public get sectionPhotosService(): SectionPhotosService {
    if (!this._sectionPhotosService) {
      this._sectionPhotosService = this.injector.get(SectionPhotosService);
    }
    return this._sectionPhotosService;
  }

  public get sectionRoofTopService(): SectionRoofTopService {
    if (!this._sectionRoofTopService) {
      this._sectionRoofTopService = this.injector.get(SectionRoofTopService);
    }
    return this._sectionRoofTopService;
  }

  public get constVerifService(): SectionConstVerifService {
    if (!this._sectionConstVerifService) {
      this._sectionConstVerifService = this.injector.get(SectionConstVerifService);
    }
    return this._sectionConstVerifService;
  }

  public get occupantsService(): FormlyOccupantsService {
    if (!this._sectionOccupantsService) {
      this._sectionOccupantsService = this.injector.get(FormlyOccupantsService);
    }
    return this._sectionOccupantsService;
  }

  public get wallsService(): FormlyWallsService {
    if (!this._sectionWallsService) {
      this._sectionWallsService = this.injector.get(FormlyWallsService);
    }
    return this._sectionWallsService;
  }

  // service used specifically for 3.0 walls
  public get wallsConstructionService(): FormlyWallsConstructionService {
    if (!this._sectionConstructionWallsService) {
      this._sectionConstructionWallsService = this.injector.get(FormlyWallsConstructionService);
    }
    return this._sectionConstructionWallsService;
  }

  public get formlyAddressService(): FormlyAddressService {
    if (!this._formlyAddressService) {
      this._formlyAddressService = this.injector.get(FormlyAddressService);
    }
    return this._formlyAddressService;
  }

  public get sprinklerReportService(): SprinklerReportService {
    if (!this._sprinklerReportService) {
      this._sprinklerReportService = this.injector.get(SprinklerReportService);
    }
    return this._sprinklerReportService;
  }
  public get secondaryConstructionsService(): SecondaryConstructionsService {
    if (!this._secondaryConstructionsService) {
      this._secondaryConstructionsService = this.injector.get(SecondaryConstructionsService);
    }
    return this._secondaryConstructionsService;
  }
  public get formlyFloorsRoofService(): FormlyFloorsRoofService {
    if (!this._floorsAndRoofService) {
      this._floorsAndRoofService = this.injector.get(FormlyFloorsRoofService);
    }
    return this._floorsAndRoofService;
  }

  public get formlyWrapperService(): FormlyWrapperService {
    if (!this._formlyWrapperService) {
      this._formlyWrapperService = this.injector.get(FormlyWrapperService);
    }
    return this._formlyWrapperService;
  }

  public get formlyCommentService(): FormlyCommentService {
    if (!this._formlyCommentService) {
      this._formlyCommentService = this.injector.get(FormlyCommentService);
    }
    return this._formlyCommentService;
  }

  public get generalInformationService(): GeneralInformationService {
    if (!this._generalInformationService) {
      this._generalInformationService = this.injector.get(GeneralInformationService);
    }
    return this._generalInformationService;
  }

  public get fireSprinklerService(): FireSprinklerService {
    if (!this._fireSprinklerService) {
      this._fireSprinklerService = this.injector.get(FireSprinklerService);
    }
    return this._fireSprinklerService;
  }
  public get formlyInternalProtectionService(): FormlyInternalProtectionService {
    if (!this._formlyInternalprotectionService) {
      this._formlyInternalprotectionService = this.injector.get(FormlyInternalProtectionService);
    }
    return this._formlyInternalprotectionService;
  }

  public get publicProtectionAreaService(): FormlyPublicProtectionAreaService {
    if (!this._publicProtectionAreaService) {
      this._publicProtectionAreaService = this.injector.get(FormlyPublicProtectionAreaService);
    }
    return this._publicProtectionAreaService;
  }
  public get formlyHookReuseableService(): HookReuseableService {
    if (!this._hookReuseableService) {
      this._hookReuseableService = this.injector.get(HookReuseableService);
    }
    return this._hookReuseableService;
  }
  public get waterSupplyService(): WaterSupplyService {
    if (!this._waterSupplyService) {
      this._waterSupplyService = this.injector.get(WaterSupplyService);
    }
    return this._waterSupplyService;
  }

  public get buildingConditionService(): BuildingConditionService {
    if (!this._buildingConditionService) {
      this._buildingConditionService = this.injector.get(BuildingConditionService);
    }
    return this._buildingConditionService;
  }
  public get systemDesignCalculateService(): SystemDesignCalculateService {
    if (!this._systemDesignCalculateService) {
      this._systemDesignCalculateService = this.injector.get(SystemDesignCalculateService);
    }
    return this._systemDesignCalculateService;
  }
  public get wallsChargeableColumnService(): WallsChargeableColumnService {
    if (!this._wallsChargeableColumnService) {
      this._wallsChargeableColumnService = this.injector.get(WallsChargeableColumnService);
    }
    return this._wallsChargeableColumnService;
  }
  public get wallsChargeablePanelsService(): WallsChargeablePanelsService {
    if (!this._wallsChargeablePanelsService) {
      this._wallsChargeablePanelsService = this.injector.get(WallsChargeablePanelsService);
    }
    return this._wallsChargeablePanelsService;
  }

  public get scoreCardService(): ScoreCardService {
    if (!this._scoreCardService) {
      this._scoreCardService = this.injector.get(ScoreCardService);
    }
    return this._scoreCardService;
  }
  public get exposuresService(): ExposuresService {
    if (!this._exposuresService) {
      this._exposuresService = this.injector.get(ExposuresService);
    }
    return this._exposuresService;
  }
  public get occupantHazardsService(): OccupantHazardsService {
    if (!this._occupantHazardsService) {
      this._occupantHazardsService = this.injector.get(OccupantHazardsService);
    }
    return this._occupantHazardsService;
  }

  public get firePumpTestsService(): FirePumpTestsService {
    if (!this._firePumpTestsService) {
      this._firePumpTestsService = this.injector.get(FirePumpTestsService);
    }
    return this._firePumpTestsService;
  }
  public get mainDrainEstimationService(): MainDrainEstimationService {
    if (!this._mainDrainEstimationService) {
      this._mainDrainEstimationService = this.injector.get(MainDrainEstimationService);
    }
    return this._mainDrainEstimationService;
  }

  public get sprinklerTableService(): SprinklerTableService {
    if (!this._sprinkerTableService) {
      this._sprinkerTableService = this.injector.get(SprinklerTableService);
    }
    return this._sprinkerTableService;
  }
  public get dataDesignTableService(): DataDesignTableService {
    if (!this._dataDesignTableService) {
      this._dataDesignTableService = this.injector.get(DataDesignTableService);
    }
    return this._dataDesignTableService;
  }

  public get frictionLossService(): FrictionLossSupplySourceService {
    if (!this._frictionLossService) {
      this._frictionLossService = this.injector.get(FrictionLossSupplySourceService);
    }
    return this._frictionLossService;
  }

  public get nonSprinkleredAreaService(): NonSprinkleredAreasService {
    if (!this._nonSprinkleredAreaService) {
      this._nonSprinkleredAreaService = this.injector.get(NonSprinkleredAreasService);
    }
    return this._nonSprinkleredAreaService;
  }

  public get publicProtectionService(): PublicProtectionService {
    if (!this._publicProtectionService) {
      this._publicProtectionService = this.injector.get(PublicProtectionService);
    }
    return this._publicProtectionService;
  }
  // Wind Report Services
  public get enhancedWindService(): EnhancedWindService {
    if (!this._enhancedWindService) {
      this._enhancedWindService = this.injector.get(EnhancedWindService);
    }
    return this._enhancedWindService;
  }

  public get windEnvironmentExposuresService(): WindEnvironmentExposuresService {
    if (!this._windEnvExposureService) {
      this._windEnvExposureService = this.injector.get(WindEnvironmentExposuresService);
    }
    return this._windEnvExposureService;
  }

  public get windFrameworkService(): WindFrameworkService {
    if (!this._windFrameworkService) {
      this._windFrameworkService = this.injector.get(WindFrameworkService);
    }
    return this._windFrameworkService;
  }

  public get windLossService(): WindLossService {
    if (!this._windLossService) {
      this._windLossService = this.injector.get(WindLossService);
    }
    return this._windLossService;
  }

  public get windRoofEnvelopeService(): WindRoofEnvelopeService {
    if (!this._windRoofEnvelopeService) {
      this._windRoofEnvelopeService = this.injector.get(WindRoofEnvelopeService);
    }
    return this._windRoofEnvelopeService;
  }

  public get windWallEnvelopeService(): WindWallEnvelopeService {
    if (!this._windWallEnvelopeService) {
      this._windWallEnvelopeService = this.injector.get(WindWallEnvelopeService);
    }
    return this._windWallEnvelopeService;
  }

  public get systemComponentService(): SystemComponentService {
    if (!this._systemComponentService) {
      this._systemComponentService = this.injector.get(SystemComponentService);
    }
    return this._systemComponentService;
  }
  public get systemTestingService(): SystemTestingService {
    if (!this._systemTestingService) {
      this._systemTestingService = this.injector.get(SystemTestingService);
    }
    return this._systemTestingService;
  }
  public get twoInchDrainTestService(): TwoInchDrainTestService {
    if (!this._twoInchDrainTestService) {
      this._twoInchDrainTestService = this.injector.get(TwoInchDrainTestService);
    }
    return this._twoInchDrainTestService;
  }
  public get dryPipeValveTestService(): DryPipeValveTestService {
    if (!this._dryPipeValveTestService) {
      this._dryPipeValveTestService = this.injector.get(DryPipeValveTestService);
    }
    return this._dryPipeValveTestService;
  }
  public get dryPipeValveTripTestService(): DryPipeValveTripTestService {
    if (!this._dryPipeValveTripTestService) {
      this._dryPipeValveTripTestService = this.injector.get(DryPipeValveTripTestService);
    }
    return this._dryPipeValveTripTestService;
  }
  public get overAgeSprinklerTestService(): OverAgeSprinklerTestService {
    if (!this._overAgeSprinklerTestService) {
      this._overAgeSprinklerTestService = this.injector.get(OverAgeSprinklerTestService);
    }
    return this._overAgeSprinklerTestService;
  }
  public get spocCalculationService(): SpocCalculationService {
    if (!this._spocCalculationService) {
      this._spocCalculationService = this.injector.get(SpocCalculationService);
    }
    return this._spocCalculationService;
  }
  public get alarmAndSupervisoryTestService(): AlarmAndSupervisoryTestService {
    if (!this._alarmAndSupervisoryTestService) {
      this._alarmAndSupervisoryTestService = this.injector.get(AlarmAndSupervisoryTestService);
    }
    return this._alarmAndSupervisoryTestService;
  }
  public get internalSprinklerPipeConditionsService(): InternalSprinklerPipeConditionsService {
    if (!this._internalSprinklerPipeConditionsServiice) {
      this._internalSprinklerPipeConditionsServiice = this.injector.get(InternalSprinklerPipeConditionsService);
    }
    return this._internalSprinklerPipeConditionsServiice;
  }
  public get windReportService(): WindReportService {
    if (!this._windReportService) {
      this._windReportService = this.injector.get(WindReportService);
    }
    return this._windReportService;
  }
  public get buildingCodesService(): BuildingCodesService {
    if (!this._buildingCodesService) {
      this._buildingCodesService = this.injector.get(BuildingCodesService);
    }
    return this._buildingCodesService;
  }

  public get riskAttachmentService(): RiskAttachmentService {
    if (!this._riskAttachmentService) {
      this._riskAttachmentService = this.injector.get(RiskAttachmentService);
    }
    return this._riskAttachmentService;
  }
  public get enhancedWindEligibleService(): EnhancedWindEligibleService {
    if (!this._enhancedWindEligibleService) {
      this._enhancedWindEligibleService = this.injector.get(EnhancedWindEligibleService);
    }
    return this._enhancedWindEligibleService;
  }

  /**
   * Getter method for ConstructionByLevelService
   */
  public get constructionByLevelService(): ConstructionByLevelService {
    if (!this._constructionByLevelService) {
      this._constructionByLevelService = this.injector.get(ConstructionByLevelService);
    }
    return this._constructionByLevelService;
  }

  /**
   * Example options loading method from specific service.
   * Calls another service from the facade.
   *
   * @returns Options loaded from backend
   */
  public getOptions(): Observable<any[]> {
    return this.jsonOptionsService.getJsonOptions('colors');
  }

  /**
   * @deprecated Please use a proper specific section service
   * Retrieve service instace through injector
   */
  public get jsonOptionsService(): FormlyBuilderJsonOptionsService {
    if (!this._jsonOptionsService) {
      this._jsonOptionsService = this.injector.get(FormlyBuilderJsonOptionsService);
    }
    return this._jsonOptionsService;
  }
  getNoOfError(): number {
    return this._errorService.getNoOfErrors(this.fields[ 0 ].formControl.root, false);
  }

  /**
   * Load all sections from models using the specified order
   */
  public reloadSections(mode: RiskModeEnum) {
    this.mode = mode;
    console.info('Loading Risk Sections... [' + RiskModeConfig.get(this._mode) + ']');
    const sectionArray: any[] = [];
    const currentMode = mode as number;
    const unorderedSectionList = [ ...SectionConfig.entries() ];
    const orderedSectionList = unorderedSectionList.sort((o1, o2) =>
      this._compareAsc(o1[ 1 ].order[ currentMode ], o2[ 1 ].order[ currentMode ])
    );
    orderedSectionList.forEach((sectionData) => {
      if (sectionData[ 1 ].model && sectionData[ 1 ].order[ currentMode ] != 0) {
        sectionArray.push(sectionData[ 1 ].model.build(this).fields);
      }
    });

    // init section subscriptions
    this._riskReportService.initSubscriptions();

    // Set the Form fields here
    this.fields = sectionArray;
  }

  /**
   * Load all sections from models using the specified order
   */
  public reloadFullRisk(mode: RiskModeEnum, mainSection: SectionEnum) {
    this.mode = mode;
    console.info('Loading Full Risk Subsection... [' + RiskModeConfig.get(this._mode) + ']');
    const mainSectionArray: any[] = [];
    const currentMode = mode as number;
    const unorderedSectionList = [ ...SectionConfig.entries() ];
    const orderedSectionList = unorderedSectionList.sort((o1, o2) =>
      this._compareAsc(o1[ 1 ].order[ currentMode ], o2[ 1 ].order[ currentMode ])
    );
    orderedSectionList.forEach((mainSectionData) => {
      const mainSectionInfo = mainSectionData[ 1 ];
      if (mainSectionInfo.order[ currentMode ] !== 0) {
        if (mode === RiskModeEnum.FULLRISK) {
          if (mainSectionInfo.children) {
            const sectionArray: any[] = [];
            mainSectionInfo.children.forEach((sectionInfo) => {
              if (sectionInfo.model) {
                // Add linkId to all linkable sections
                const childrenSectionModel = (sectionInfo.model).build(this).fields;
                if (!childrenSectionModel.props) {
                  childrenSectionModel.props = {};
                }
                childrenSectionModel.props.linkId = sectionInfo.enum;
                sectionArray.push(childrenSectionModel);
              }
            });
            const mainSectionField = {
              id: mainSectionInfo.name,
              props: {
                linkId: mainSectionInfo.enum
              },
              fieldGroup: sectionArray
            };
            // Sprinkler and Wind Report have an extra top branch
            if (mainSectionInfo.property && mainSectionInfo.property !== '') {
              mainSectionField[ 'key' ] = mainSectionInfo.property;
            }
            mainSectionArray.push(mainSectionField);
          } else if (mainSectionInfo.model) {
            // TODO: This is a dirty trick to show info from old fashioned main sections/landing pages. TO BE DELETED
            mainSectionArray.push(mainSectionInfo.model.build(this).fields);
          }
        } else {
          if (
            mainSectionInfo.enum === mainSection &&
            mainSectionInfo.model &&
            mainSectionInfo.order[ currentMode ] != 0
          ) {
            mainSectionArray.push(mainSectionInfo.model.build(this).fields);
          }
        }
      }
    });

    // init section subscriptions
    this._riskReportService.initSubscriptions();

    // Set the Form fields here
    this.fields = [
      {
        type: 'fr-header-stepper',
        fieldGroup: mainSectionArray,
      },
    ];
  }

  public _compareAsc(o1: number, o2: number): number {
    const leftOrderValue: number = o1;
    const rightOrderValue: number = o2;

    if (leftOrderValue > rightOrderValue) {
      return 1;
    }

    if (leftOrderValue < rightOrderValue) {
      return -1;
    }

    return 0;
  }

  /**
   * Set a valueChanges for a specific field in a section to update
   *
   * @param field
   * @param section
   * @param value
   * @param isSaveOverride if true then will be saved even if the field is invalid
   */
  onSectionFieldChange(field: FormlyFieldConfig, section: SectionEnum, value: any, isSaveOverride?: boolean) {
    if (!value && field.formControl.pristine) {
      field.formControl.markAsTouched();
    }
    if (!field.formControl.pristine) {
      if (field.formControl.valid || isSaveOverride === true) {
        const formlyService = field.options.formState.service;
        formlyService.riskReport.updateSectionData(section, false);
      }
    }
    field.options.formState.service.valid = field.formControl.valid;
  }

  /**
   *
   * @returns true if Risk is to be considered existing; otherwise false
   */
  public isExistingRisk(): boolean {
    return !!(this._riskReportService?.riskId && this._riskReportService?.riskId?.length === 12);
  }

  /**
   * Check if an object has values(=is filled, =is not empty, =has content)
   *
   * @param obj An object (potentially contains arrays, objects and primitives)
   * @param isFalseEmpty [optional, default true] If true, we consider a false value as "empty"
   *   - Ignore "N/A"
   *   - Ignore ""
   * @param exclusions List of properties that we will exclude from the check
   * @returns true if at least one value is not null, undefined (or false boolean if we consider this to be empty as well)
   */
  public hasValues(obj, isFalseEmpty: boolean = true, exclusions?: string[]): boolean {
    const keyArray = Object.keys(obj);
    const valueArray = Object.values(obj);

    for (let i = 0; i < valueArray.length; i++) {
      const index = i;
      const v = valueArray[ index ];
      const k = keyArray[ index ];
      if (Array.isArray(v)) {
        // If value is an array, check descendants
        for (let j; j < v.length; j++) {
          const arrayV = v[ j ];
          if (this.hasValues(arrayV, isFalseEmpty, exclusions)) {
            return true;
          }
        }
      } else if (v === Object(v)) {
        // It's an object, not a primitive
        if (this.hasValues(v, isFalseEmpty, exclusions)) {
          return true;
        }
      } else if (exclusions === undefined || (exclusions && !exclusions.includes(k))) {
        if (this.primitiveHasValue(v, isFalseEmpty)) {
          return true;
        }
      }
    }

    return false;
  }

  private primitiveHasValue(v: any, isFalseEmpty: boolean): boolean {
    if (v !== null && typeof v !== 'undefined') {
      if (typeof v === 'boolean') {
        // We discard boolean false values

        // TODO: Add "N/A", ""
        if (v === true || !isFalseEmpty) {
          return true;
        }
      } else if (typeof v === 'string') {
        if (v !== 'N/A' && v !== '') {
          return true;
        }
      } else {
        // Non null value exists
        return true;
      }
    }

    return false;
  }

  /**
   *
   * @param field
   * @returns
   */
  public markAsTouchedIfHasValues(field: FormlyFieldConfig) {
    const exclusions = this.getAllFieldKeysForToggles(field);
    const hasSomeValues = this.hasValues(field?.formControl?.value, true, exclusions);
    if (hasSomeValues) {
      field?.formControl?.markAllAsTouched();
    }
  }

  /**
   * Return a list of descendants of type toggle (only if they have an associated key)
   *
   * @param field
   * @returns
   */
  private getAllFieldKeysForToggles(field: FormlyFieldConfig): string[] {
    return [];
  }

  /**
   * Updates a specific FRM model property
   *
   * @param sectionPath FRM Branch where we want to set the property
   * @param property Name of the property to set
   * @param value Value of the property to set
   * @param forceModelCD Forces an Angular Change Detection by updating the model with itself
   * @returns The updated FRM model
   */
  public setModelProperty(sectionPath: string, property: string, value: any, forceModelCD: boolean = false): any {
    const sectionArray: string[] = sectionPath?.split('.');
    let section: any = this._riskReportService.model;
    sectionArray.forEach((branch: string) => {
      if (branch?.length) {
        section = section[ branch ];
      }
    });

    section[ property ] = value;

    // Angular Change Detection
    if (forceModelCD) {
      this.forceModelChangeDetection();
    }
    return this._riskReportService.model;
  }

  /**
   * Forces an Angular Change Detection by updating the model with itself
   */
  public forceModelChangeDetection() {
    this._riskReportService.model = { ...this._riskReportService.model };
  }

  /**
   * Clear all backend errors and hide status line (visibility as false)
   */
  public clearErrors() {
    if (this.options?.formState?.externalErrors) {
      this.options.formState.externalErrors = null;
      // Inform observers
      this.statusLineVisibility = false;
      this.errorService.saveExternalErrors(this.options, []);
    }
  }

  /**
   * Returns false if Risk has a RiskId
   */
  isNewRisk() {
    if (this.options?.formState?.rr?.RiskId) {
      return false;
    }
    return true;
  }

  /**
   * Is a valid number
   *
   * @param myNumber
   * @returns
   */
  isValidNumber(myNumber: any): boolean {
    if (typeof myNumber === 'string' && myNumber !== '') {
      const myNumberFromString: number = +myNumber;
      return Number.isFinite(myNumberFromString);
    }

    return Number.isFinite(myNumber);
  }

  /* eslint-enable */
}
