/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { ModalController } from '@ionic/angular';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { WindFrameworkDataModel } from 'src/app/models/formly-builder/formly/wind/wind-framework-data.model';
import { FormlyBuilderService } from '../formly-builder.service';
import { SectionEnum } from '../section.config';
@Injectable({
  providedIn: 'root',
})
export class WindFrameworkService extends SectionServiceAbstract {

  constructor() {
    super();
  }

  async updateData(model: any, riskReportData: any): Promise<any> {
    // Check if data has changed
    const hasChanged: boolean = WindFrameworkDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const windFrameworkModel =
        WindFrameworkDataModel.to(model[ 'windReport' ].windFramework, riskReportData?.ReportIdentifier);
      const windReportData = { ...riskReportData.WindReport, ...{ Framework: windFrameworkModel } };
      return { ...riskReportData, ...{ WindReport: windReportData } };
    } else {
      return undefined;
    }
  }

  loadData(sectionData: any[], riskReport: any, isDataInS3Format: boolean, model: any) {
    return model;
  }
  async onApply(modal: ModalController, field: FormlyFieldConfig) {
    const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const riskReport = await formlyService.riskReport.updateSectionData(SectionEnum.FR_W_FRAMEWORK, false);
    const newModel = formlyService.windReportService.loadData(riskReport, riskReport, true, formlyService.riskReport.model);
    if (newModel) {
      formlyService.riskReport.model = { ...newModel };
    }
    modal.dismiss();
  }
  onClose(modal: ModalController, field: FormlyFieldConfig, initialDataModel: any) {
    const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const reportData = formlyService.riskReport.report;
    const newModel = formlyService.windReportService.loadData(reportData, reportData, true, formlyService.riskReport.model);
    if (newModel) {
      formlyService.riskReport.model = { ...newModel };
    }
    formlyService.errorService.closePreviousToaster();
    modal.dismiss();
  }
  initCheckboxes(field: FormlyFieldConfig, checkboxOptions: any[]) {
    try {
      // Get values from the options...
      const rootModel = field?.options?.formState?.service?.riskReport?.model;
      let checkboxGroupModel = rootModel;
      const branchArray = field?.props?.path.split('.');
      branchArray.forEach((branch) => {
        checkboxGroupModel = checkboxGroupModel[ branch ];
      });

      checkboxOptions.forEach((option) => {
        if (checkboxGroupModel) {
          if (checkboxGroupModel[ option[ 'checkboxKey' ] ] === true) {
            option[ 'isItemChecked' ] = true;
          }

          if (checkboxGroupModel[ option[ 'predominantKey' ] ] === true) {
            option[ 'isPredominant' ] = true;
          }
        }
      });
    } catch (ignore) { }

    // Load the options as props
    field.props.options = checkboxOptions;
  }

  /**
   * @param FormlyFieldConfig field
   */
  setFirstStoryHeight(field: FormlyFieldConfig) {
    const fieldRoot = field?.form?.root?.value;
    // Fetch Floors and Roof(Number of Floors), sprinkler Report(BuildingHeightInFeet)
    if (fieldRoot?.sprinklerReport?.asgrWaterSupply?.evalutionOfWaterSupply?.buildingHeightInFeet
      && fieldRoot?.floorsAndRoofs?.buildingInformation?.aboveGradeFloors === 1
    ) {
      const input = fieldRoot?.sprinklerReport?.asgrWaterSupply?.evalutionOfWaterSupply?.buildingHeightInFeet;
      let label;
      switch (true) {
        case input <= 10:
          label = `<= 10'`;
          break;
        case input <= 15:
          label = `> 10' - 15'`;
          break;
        case input <= 20:
          label = `>15' - 20'`;
          break;
        default:
          label = `> 20'`;
      }
      field.formControl.setValue(label);
    }
  }

  /**
   * @param FormlyFieldConfig field
   */
  setTypicalUpperStoryHeight(field: FormlyFieldConfig) {
    const fieldRoot = field?.form?.root?.value;
    // Fetch Floors and Roof(Number of Floors) value
    if (fieldRoot?.floorsAndRoofs?.buildingInformation?.aboveGradeFloors === 1) {
      field.formControl.setValue('NAPP');
      field.props.disabled = true;
    } else {
      field.props.disabled = false;
    }
  }
}
