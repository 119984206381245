/* eslint-disable @typescript-eslint/naming-convention */
import { Guid } from 'guid-typescript';

/**
 * Class that represents the Formly Model for BuildingBG
 */
export class RoofEnvelopeDataModel {
  static sectionName = 'roofEnvelope';

  roofEnvelopeContainer: RoofEnvelopePredominant;
  roofAdditionalFeature: RoofAdditionalFeature;
  roofFeatures: RoofFeatures;
  roofEnvelopeGeometry: RoofEnvelopeGeometry;
  /**
   * @deprecated Amplify no longer used to fetch this data / now S3
   * @param reports
   * @returns
   */
  static from(reports: any): RoofEnvelopeDataModel {
    return null;
  }

  static fromS3(s3data: any, reportInfo): RoofEnvelopeDataModel {
    const roofPredominant = this.setRoofEnvelopePredominant(s3data);
    const roofAdditionalFeatures = this.setAdditionalRoofFeature(s3data);
    const roofFeaturesData = this.setRoofFeatures(s3data);
    const roofEnvelopeGeometryPredominant = this.setRoofEnvelopeGeometry(s3data);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return {
      roofEnvelopeContainer: roofPredominant,
      //Roof Additional Feature
      roofAdditionalFeature: roofAdditionalFeatures,
      //Roof Features
      roofFeatures: roofFeaturesData,
      //Roof Envelope Geometry
      roofEnvelopeGeometry: roofEnvelopeGeometryPredominant,
    };
  }

  /**
   *
   * @method to - to store data back to s3
   * @param string buildingBg
   * @param * reports
   */
  static to(data: any, reportId?: Guid): any {
    return {
      ReportId: data?.reportId ? data?.reportId : reportId,
      HasRoofCoveringOnBuilding: true,
      HasRoofCoveringOnBuildingTypeNone: data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeNone ?
        data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeNone : false,
      IsRoofCoveringOnBuildingTypeNonePredominant:
        data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeNonePredominant ?
          data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeNonePredominant : false,
      HasRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravel:
        data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravel ?
          data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravel : false,
      IsRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravelPredominant:
        data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravelPredominant ?
          data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravelPredominant : false,
      HasRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravel:
        data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravel ?
          data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravel : false,
      IsRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravelPredominant:
        data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravelPredominant ?
          data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravelPredominant : false,
      HasRoofCoveringOnBuildingTypeBuiltUpWithoutGravel:
        data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeBuiltUpWithoutGravel ?
          data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeBuiltUpWithoutGravel : false,
      IsRoofCoveringOnBuildingTypeBuiltUpWithoutGravelPredominant:
        data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeBuiltUpWithoutGravelPredominant ?
          data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeBuiltUpWithoutGravelPredominant : false,
      HasRoofCoveringOnBuildingTypeSprayedPolyurethaneFoam:
        data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeSprayedPolyurethaneFoam ?
          data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeSprayedPolyurethaneFoam : false,
      IsRoofCoveringOnBuildingTypeSprayedPolyurethaneFoamPredominant:
        data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeSprayedPolyurethaneFoamPredominant ?
          data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeSprayedPolyurethaneFoamPredominant : false,
      HasRoofCoveringOnBuildingTypeSinglePlyMembrane:
        data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeSinglePlyMembrane ?
          data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeSinglePlyMembrane : false,
      IsRoofCoveringOnBuildingTypeSinglePlyMembranePredominant:
        data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeSinglePlyMembranePredominant ?
          data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeSinglePlyMembranePredominant : false,
      HasRoofCoveringOnBuildingTypeWoodShingles: data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeWoodShingles ?
        data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeWoodShingles : false,
      IsRoofCoveringOnBuildingTypeWoodShinglesPredominant:
        data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeWoodShinglesPredominant ?
          data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeWoodShinglesPredominant : false,
      HasRoofCoveringOnBuildingTypeLightMetalPanels:
        data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeLightMetalPanels ?
          data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeLightMetalPanels : false,
      IsRoofCoveringOnBuildingTypeLightMetalPanelsPredominant:
        data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeLightMetalPanelsPredominant ?
          data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeLightMetalPanelsPredominant : false,
      HasRoofCoveringOnBuildingTypeStandingSeamMetal:
        data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeStandingSeamMetal ?
          data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeStandingSeamMetal : false,
      IsRoofCoveringOnBuildingTypeStandingSeamMetalPredominant:
        data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeStandingSeamMetalPredominant ?
          data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeStandingSeamMetalPredominant : false,
      HasRoofCoveringOnBuildingTypeMasonaryTile: data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeMasonaryTile ?
        data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeMasonaryTile : false,
      IsRoofCoveringOnBuildingTypeMasonaryTilePredominant:
        data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeMasonaryTilePredominant ?
          data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeMasonaryTilePredominant : false,
      HasRoofCoveringOnBuildingTypeSlate: data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeSlate ?
        data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeSlate : false,
      IsRoofCoveringOnBuildingTypeSlatePredominant:
        data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeSlatePredominant ?
          data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeSlatePredominant : false,
      HasRoofCoveringOnBuildingTypeAsphaltOrFiberglassShingles:
        data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeAsphaltOrFiberglassShingles ?
          data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeAsphaltOrFiberglassShingles : false,
      IsRoofCoveringOnBuildingTypeAsphaltOrFiberglassShinglesPredominant:
        data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeAsphaltOrFiberglassShinglesPredominant ?
          data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeAsphaltOrFiberglassShinglesPredominant : false,
      HasRoofCoveringOnBuildingTypeFabricOverStructuralFrame:
        data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeFabricOverStructuralFrame ?
          data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeFabricOverStructuralFrame : false,
      IsRoofCoveringOnBuildingTypeFabricOverStructuralFramePredominant:
        data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeFabricOverStructuralFramePredominant ?
          data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeFabricOverStructuralFramePredominant : false,
      HasRoofCoveringOnBuildingTypeUnknown: data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeUnknown ?
        data.roofEnvelopeContainer?.hasRoofCoveringOnBuildingTypeUnknown : false,
      IsRoofCoveringOnBuildingTypeUnknownPredominant:
        data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeUnknownPredominant ?
          data.roofEnvelopeContainer?.isRoofCoveringOnBuildingTypeUnknownPredominant : false,

      //AdditionalRoof Feature
      WindUpliftRoofRatingUl580: data.roofAdditionalFeature?.windUpliftRoofRatingUl580 ?
        data.roofAdditionalFeature?.windUpliftRoofRatingUl580 : '',
      WindUpliftRoofRatingUl580Comment: data.roofAdditionalFeature?.windUpliftRoofRatingUl580Comment ?
        data.roofAdditionalFeature?.windUpliftRoofRatingUl580Comment : '',
      PreventativeMaintenanceProgram: data.roofAdditionalFeature?.preventativeMaintenanceProgram ?
        data.roofAdditionalFeature?.preventativeMaintenanceProgram : '',
      MiscellaneousRoofObjectsComment: data.roofAdditionalFeature?.miscellaneousRoofObjectsComment ?
        data.roofAdditionalFeature?.miscellaneousRoofObjectsComment : '',
      MiscellaneousRoofObjects: data.roofAdditionalFeature?.miscellaneousRoofObjects ?
        data.roofAdditionalFeature?.miscellaneousRoofObjects : '',
      RoofCoverDegradation: data.roofAdditionalFeature?.roofCoverDegradation ?
        data.roofAdditionalFeature?.roofCoverDegradation : '',
      LightlyConstructedRoofStructures: data.roofAdditionalFeature?.lightlyConstructedRoofStructures ?
        data.roofAdditionalFeature?.lightlyConstructedRoofStructures : '',
      HailProtectedMountedRoofEquipment: data.roofAdditionalFeature?.hailProtectedMountedRoofEquipment ?
        data.roofAdditionalFeature?.hailProtectedMountedRoofEquipment : '',
      MechanicalEquipmentOnRoofComment: data.roofAdditionalFeature?.mechanicalEquipmentOnRoofComment ?
        data.roofAdditionalFeature?.mechanicalEquipmentOnRoofComment : '',
      HasSecondaryWaterBarrier: (data.roofAdditionalFeature?.hasSecondaryWaterBarrier === null ||
        data.roofAdditionalFeature?.hasSecondaryWaterBarrier === undefined) ? null : data.roofAdditionalFeature?.hasSecondaryWaterBarrier,
      FlashingConditionComment: data.roofAdditionalFeature?.flashingConditionComment ?
        data.roofAdditionalFeature?.flashingConditionComment : '',
      FlashingCondition: data.roofAdditionalFeature?.flashingCondition ?
        data.roofAdditionalFeature?.flashingCondition : '',
      MechanicalEquipmentOnRoof: data.roofAdditionalFeature?.mechanicalEquipmentOnRoof ?
        data.roofAdditionalFeature?.mechanicalEquipmentOnRoof : '',
      RoofPerimiterCoveredBySolarPanels: data.roofAdditionalFeature?.roofPerimiterCoveredBySolarPanels ?
        data.roofAdditionalFeature?.roofPerimiterCoveredBySolarPanels : '',
      SolarPanels: data.roofAdditionalFeature?.solarPanels ?
        data.roofAdditionalFeature?.solarPanels : '',
      SolarPanelComment: data.roofAdditionalFeature?.solarPanelComment ?
        data.roofAdditionalFeature?.solarPanelComment : '',
      RoofCoverDegradationComment: data.roofAdditionalFeature?.roofCoverDegradationComment ?
        data.roofAdditionalFeature?.roofCoverDegradationComment : '',

      //Roof feature
      ImpactResistanceDocumentationUl2218OrFm4473: data.roofFeatures?.impactResistanceDocumnetationUL22180OrFm4473 ?
        data.roofFeatures?.impactResistanceDocumnetationUL22180OrFm4473 : '',
      ImpactResistanceDocumentationUl2218OrFm4473Comment:
        data.roofFeatures?.impactResistanceDocumentationUl2218OrFm4473Comment ?
          data.roofFeatures?.impactResistanceDocumentationUl2218OrFm4473Comment : '',
      RoofPitch: data.roofFeatures?.roofPitch ?
        data.roofFeatures?.roofPitch : '',
      RoofPerimeterHigherThan6InchesParapet: data.roofFeatures?.roofPerimeterHigherThan6InchesParapet ?
        data.roofFeatures?.roofPerimeterHigherThan6InchesParapet : '',
      AverageParapetHeight: data.roofFeatures?.averageParapetHeight ?
        data.roofFeatures?.averageParapetHeight : '',
      AgeOfRoofCover: data.roofFeatures?.ageOfRoofCover ?
        data.roofFeatures?.ageOfRoofCover : '',
      HasSkyLightsPresentOnRoof: (data.roofFeatures?.hasSkyLightsPresentOnRoof === null ||
        data.roofFeatures?.hasSkyLightsPresentOnRoof === undefined) ? null : data.roofFeatures?.hasSkyLightsPresentOnRoof,
      SkyLightsRoofRatingType: data.roofFeatures?.skyLightsRoofRatingType ?
        data.roofFeatures?.skyLightsRoofRatingType : '',
      SkyLightsRoofRatingTypeComment: data.roofFeatures?.skyLightsRoofRatingTypeComment ?
        data.roofFeatures?.skyLightsRoofRatingTypeComment : null,

      //Roof Envelope Geometry
      HasRoofGeometry: true,
      IsRoofGeometryTypeHipPredominant: data.roofEnvelopeGeometry?.isRoofGeometryTypeHipPredominant ?
        data.roofEnvelopeGeometry?.isRoofGeometryTypeHipPredominant : false,
      IsRoofGeometryTypeShedPredominant: data.roofEnvelopeGeometry?.isRoofGeometryTypeShedPredominant ?
        data.roofEnvelopeGeometry?.isRoofGeometryTypeShedPredominant : false,
      IsRoofGeometryTypeMansardPredominant: data.roofEnvelopeGeometry?.isRoofGeometryTypeMansardPredominant ?
        data.roofEnvelopeGeometry?.isRoofGeometryTypeMansardPredominant : false,
      IsRoofGeometryTypePyramidPredominant: data.roofEnvelopeGeometry?.isRoofGeometryTypePyramidPredominant ?
        data.roofEnvelopeGeometry?.isRoofGeometryTypePyramidPredominant : false,
      IsRoofGeometryTypeGambrelPredominant: data.roofEnvelopeGeometry?.isRoofGeometryTypeGambrelPredominant ?
        data.roofEnvelopeGeometry?.isRoofGeometryTypeGambrelPredominant : false,
      IsRoofGeometryTypeBracedGablePredominant: data.roofEnvelopeGeometry?.isRoofGeometryTypeBracedGablePredominant ?
        data.roofEnvelopeGeometry?.isRoofGeometryTypeBracedGablePredominant : false,
      IsRoofGeometryTypeFlatOrLowSlopePredominant:
        data.roofEnvelopeGeometry?.isRoofGeometryTypeFlatOrLowSlopePredominant ?
          data.roofEnvelopeGeometry?.isRoofGeometryTypeFlatOrLowSlopePredominant : false,
      IsRoofGeometryTypeGablePredominant: data.roofEnvelopeGeometry?.isRoofGeometryTypeGablePredominant ?
        data.roofEnvelopeGeometry?.isRoofGeometryTypeGablePredominant : false,
      IsRoofGeometryTypeComplexPredominant: data.roofEnvelopeGeometry?.isRoofGeometryTypeComplexPredominant ?
        data.roofEnvelopeGeometry?.isRoofGeometryTypeComplexPredominant : false,
      IsRoofGeometryTypeSteppedOrSawtoothPredominant:
        data.roofEnvelopeGeometry?.isRoofGeometryTypeSteppedOrSawtoothPredominant ?
          data.roofEnvelopeGeometry?.isRoofGeometryTypeSteppedOrSawtoothPredominant : false,
      HasRoofGeometryTypeHip: data.roofEnvelopeGeometry?.hasRoofGeometryTypeHip ?
        data.roofEnvelopeGeometry?.hasRoofGeometryTypeHip : false,
      HasRoofGeometryTypeShed: data.roofEnvelopeGeometry?.hasRoofGeometryTypeShed ?
        data.roofEnvelopeGeometry?.hasRoofGeometryTypeShed : false,
      HasRoofGeometryTypeMansard: data.roofEnvelopeGeometry?.hasRoofGeometryTypeMansard ?
        data.roofEnvelopeGeometry?.hasRoofGeometryTypeMansard : false,
      HasRoofGeometryTypePyramid: data.roofEnvelopeGeometry?.hasRoofGeometryTypePyramid ?
        data.roofEnvelopeGeometry?.hasRoofGeometryTypePyramid : false,
      HasRoofGeometryTypeGambrel: data.roofEnvelopeGeometry?.hasRoofGeometryTypeGambrel ?
        data.roofEnvelopeGeometry?.hasRoofGeometryTypeGambrel : false,
      HasRoofGeometryTypeBracedGable: data.roofEnvelopeGeometry?.hasRoofGeometryTypeBracedGable ?
        data.roofEnvelopeGeometry?.hasRoofGeometryTypeBracedGable : false,
      HasRoofGeometryTypeFlatOrLowSlope: data.roofEnvelopeGeometry?.hasRoofGeometryTypeFlatOrLowSlope ?
        data.roofEnvelopeGeometry?.hasRoofGeometryTypeFlatOrLowSlope : false,
      HasRoofGeometryTypeGable: data.roofEnvelopeGeometry?.hasRoofGeometryTypeGable ?
        data.roofEnvelopeGeometry?.hasRoofGeometryTypeGable : false,
      HasRoofGeometryTypeComplex: data.roofEnvelopeGeometry?.hasRoofGeometryTypeComplex ?
        data.roofEnvelopeGeometry?.hasRoofGeometryTypeComplex : false,
      HasRoofGeometryTypeSteppedOrSawtooth: data.roofEnvelopeGeometry?.hasRoofGeometryTypeSteppedOrSawtooth ?
        data.roofEnvelopeGeometry?.hasRoofGeometryTypeSteppedOrSawtooth : false,
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    return true;
  }

  static setRoofEnvelopePredominant(data: any) {
    return {
      hasRoofCoveringOnBuildingTypeNone: data?.HasRoofCoveringOnBuildingTypeNone,
      isRoofCoveringOnBuildingTypeNonePredominant: data?.IsRoofCoveringOnBuildingTypeNonePredominant,
      hasRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravel:
        data?.HasRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravel,
      isRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravelPredominant:
        data?.IsRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravelPredominant,
      hasRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravel:
        data?.HasRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravel,
      isRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravelPredominant:
        data?.IsRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravelPredominant,
      hasRoofCoveringOnBuildingTypeBuiltUpWithoutGravel: data?.HasRoofCoveringOnBuildingTypeBuiltUpWithoutGravel,
      isRoofCoveringOnBuildingTypeBuiltUpWithoutGravelPredominant:
        data?.IsRoofCoveringOnBuildingTypeBuiltUpWithoutGravelPredominant,
      hasRoofCoveringOnBuildingTypeSprayedPolyurethaneFoam: data?.HasRoofCoveringOnBuildingTypeSprayedPolyurethaneFoam,
      isRoofCoveringOnBuildingTypeSprayedPolyurethaneFoamPredominant:
        data?.IsRoofCoveringOnBuildingTypeSprayedPolyurethaneFoamPredominant,
      hasRoofCoveringOnBuildingTypeSinglePlyMembrane: data?.HasRoofCoveringOnBuildingTypeSinglePlyMembrane,
      isRoofCoveringOnBuildingTypeSinglePlyMembranePredominant:
        data?.IsRoofCoveringOnBuildingTypeSinglePlyMembranePredominant,
      hasRoofCoveringOnBuildingTypeWoodShingles: data?.HasRoofCoveringOnBuildingTypeWoodShingles,
      isRoofCoveringOnBuildingTypeWoodShinglesPredominant: data?.IsRoofCoveringOnBuildingTypeWoodShinglesPredominant,
      hasRoofCoveringOnBuildingTypeLightMetalPanels: data?.HasRoofCoveringOnBuildingTypeLightMetalPanels,
      isRoofCoveringOnBuildingTypeLightMetalPanelsPredominant:
        data?.IsRoofCoveringOnBuildingTypeLightMetalPanelsPredominant,
      hasRoofCoveringOnBuildingTypeStandingSeamMetal: data?.HasRoofCoveringOnBuildingTypeStandingSeamMetal,
      isRoofCoveringOnBuildingTypeStandingSeamMetalPredominant:
        data?.IsRoofCoveringOnBuildingTypeStandingSeamMetalPredominant,
      hasRoofCoveringOnBuildingTypeMasonaryTile: data?.HasRoofCoveringOnBuildingTypeMasonaryTile,
      isRoofCoveringOnBuildingTypeMasonaryTilePredominant: data?.IsRoofCoveringOnBuildingTypeMasonaryTilePredominant,
      hasRoofCoveringOnBuildingTypeSlate: data?.HasRoofCoveringOnBuildingTypeSlate,
      isRoofCoveringOnBuildingTypeSlatePredominant: data?.IsRoofCoveringOnBuildingTypeSlatePredominant,
      hasRoofCoveringOnBuildingTypeAsphaltOrFiberglassShingles:
        data?.HasRoofCoveringOnBuildingTypeAsphaltOrFiberglassShingles,
      isRoofCoveringOnBuildingTypeAsphaltOrFiberglassShinglesPredominant:
        data?.IsRoofCoveringOnBuildingTypeAsphaltOrFiberglassShinglesPredominant,
      hasRoofCoveringOnBuildingTypeFabricOverStructuralFrame:
        data?.HasRoofCoveringOnBuildingTypeFabricOverStructuralFrame,
      isRoofCoveringOnBuildingTypeFabricOverStructuralFramePredominant:
        data?.IsRoofCoveringOnBuildingTypeFabricOverStructuralFramePredominant,
      hasRoofCoveringOnBuildingTypeUnknown: data?.HasRoofCoveringOnBuildingTypeUnknown,
      isRoofCoveringOnBuildingTypeUnknownPredominant: data?.IsRoofCoveringOnBuildingTypeUnknownPredominant,
    };
  }

  static setAdditionalRoofFeature(data: any) {
    return {
      windUpliftRoofRatingUl580: data?.WindUpliftRoofRatingUl580,
      windUpliftRoofRatingUl580Comment: data?.WindUpliftRoofRatingUl580Comment,
      preventativeMaintenanceProgram: data?.PreventativeMaintenanceProgram,
      miscellaneousRoofObjectsComment: data?.MiscellaneousRoofObjectsComment,
      miscellaneousRoofObjects: data?.MiscellaneousRoofObjects,
      roofCoverDegradation: data?.RoofCoverDegradation,
      lightlyConstructedRoofStructures: data?.LightlyConstructedRoofStructures,
      hailProtectedMountedRoofEquipment: data?.HailProtectedMountedRoofEquipment,
      mechanicalEquipmentOnRoofComment: data?.MechanicalEquipmentOnRoofComment,
      hasSecondaryWaterBarrier: data?.HasSecondaryWaterBarrier,
      flashingConditionComment: data?.FlashingConditionComment,
      flashingCondition: data?.FlashingCondition,
      mechanicalEquipmentOnRoof: data?.MechanicalEquipmentOnRoof,
      roofPerimiterCoveredBySolarPanels: data?.RoofPerimiterCoveredBySolarPanels,
      solarPanels: data?.SolarPanels,
      solarPanelComment: data?.SolarPanelComment,
      roofCoverDegradationComment: data?.RoofCoverDegradationComment,
    };
  }

  static setRoofFeatures(data: any) {
    return {
      impactResistanceDocumnetationUL22180OrFm4473: data?.ImpactResistanceDocumentationUl2218OrFm4473,
      impactResistanceDocumentationUl2218OrFm4473Comment: data?.ImpactResistanceDocumentationUl2218OrFm4473Comment,
      roofPitch: data?.RoofPitch,
      roofPerimeterHigherThan6InchesParapet: data?.RoofPerimeterHigherThan6InchesParapet,
      averageParapetHeight: data?.AverageParapetHeight,
      ageOfRoofCover: data?.AgeOfRoofCover,
      hasSkyLightsPresentOnRoof: data?.HasSkyLightsPresentOnRoof,
      skyLightsRoofRatingType: data?.SkyLightsRoofRatingType,
      skyLightsRoofRatingTypeComment: data?.SkyLightsRoofRatingTypeComment
    };
  }

  static setRoofEnvelopeGeometry(data: any) {
    return {
      hasRoofGeometryTypeHip: data?.HasRoofGeometryTypeHip,
      hasRoofGeometryTypeShed: data?.HasRoofGeometryTypeShed,
      hasRoofGeometryTypeMansard: data?.HasRoofGeometryTypeMansard,
      hasRoofGeometryTypePyramid: data?.HasRoofGeometryTypePyramid,
      hasRoofGeometryTypeGambrel: data?.HasRoofGeometryTypeGambrel,
      hasRoofGeometryTypeBracedGable: data?.HasRoofGeometryTypeBracedGable,
      hasRoofGeometryTypeFlatOrLowSlope: data?.HasRoofGeometryTypeFlatOrLowSlope,
      hasRoofGeometryTypeGable: data?.HasRoofGeometryTypeGable,
      hasRoofGeometryTypeComplex: data?.HasRoofGeometryTypeComplex,
      hasRoofGeometryTypeSteppedOrSawtooth: data?.HasRoofGeometryTypeSteppedOrSawtooth,
      isRoofGeometryTypeHipPredominant: data?.IsRoofGeometryTypeHipPredominant,
      isRoofGeometryTypeShedPredominant: data?.IsRoofGeometryTypeShedPredominant,
      isRoofGeometryTypeMansardPredominant: data?.IsRoofGeometryTypeMansardPredominant,
      isRoofGeometryTypePyramidPredominant: data?.IsRoofGeometryTypePyramidPredominant,
      isRoofGeometryTypeGambrelPredominant: data?.IsRoofGeometryTypeGambrelPredominant,
      isRoofGeometryTypeBracedGablePredominant: data?.IsRoofGeometryTypeBracedGablePredominant,
      isRoofGeometryTypeFlatOrLowSlopePredominant: data?.IsRoofGeometryTypeFlatOrLowSlopePredominant,
      isRoofGeometryTypeGablePredominant: data?.IsRoofGeometryTypeGablePredominant,
      isRoofGeometryTypeComplexPredominant: data?.IsRoofGeometryTypeComplexPredominant,
      isRoofGeometryTypeSteppedOrSawtoothPredominant: data?.IsRoofGeometryTypeSteppedOrSawtoothPredominant,
    };
  }
}
class RoofEnvelopePredominant {
  hasRoofCoveringOnBuildingTypeNone: boolean;
  isRoofCoveringOnBuildingTypeNonePredominant: boolean;
  hasRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravel: boolean;
  isRoofCoveringOnBuildingTypeBuiltUpWithSecuredGravelPredominant: boolean;
  hasRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravel: boolean;
  isRoofCoveringOnBuildingTypeBuiltUpWithUnsecuredGravelPredominant: boolean;
  hasRoofCoveringOnBuildingTypeBuiltUpWithoutGravel: boolean;
  isRoofCoveringOnBuildingTypeBuiltUpWithoutGravelPredominant: boolean;
  hasRoofCoveringOnBuildingTypeSprayedPolyurethaneFoam: boolean;
  isRoofCoveringOnBuildingTypeSprayedPolyurethaneFoamPredominant: boolean;
  hasRoofCoveringOnBuildingTypeSinglePlyMembrane: boolean;
  isRoofCoveringOnBuildingTypeSinglePlyMembranePredominant: boolean;
  hasRoofCoveringOnBuildingTypeWoodShingles: boolean;
  isRoofCoveringOnBuildingTypeWoodShinglesPredominant: boolean;
  hasRoofCoveringOnBuildingTypeLightMetalPanels: boolean;
  isRoofCoveringOnBuildingTypeLightMetalPanelsPredominant: boolean;
  hasRoofCoveringOnBuildingTypeStandingSeamMetal: boolean;
  isRoofCoveringOnBuildingTypeStandingSeamMetalPredominant: boolean;
  hasRoofCoveringOnBuildingTypeMasonaryTile: boolean;
  isRoofCoveringOnBuildingTypeMasonaryTilePredominant: boolean;
  hasRoofCoveringOnBuildingTypeSlate: boolean;
  isRoofCoveringOnBuildingTypeSlatePredominant: boolean;
  hasRoofCoveringOnBuildingTypeAsphaltOrFiberglassShingles: boolean;
  isRoofCoveringOnBuildingTypeAsphaltOrFiberglassShinglesPredominant: boolean;
  hasRoofCoveringOnBuildingTypeFabricOverStructuralFrame: boolean;
  isRoofCoveringOnBuildingTypeFabricOverStructuralFramePredominant: boolean;
  hasRoofCoveringOnBuildingTypeUnknown: boolean;
  isRoofCoveringOnBuildingTypeUnknownPredominant: boolean;
}

class RoofEnvelopeGeometry {
  hasRoofGeometryTypeHip: boolean;
  hasRoofGeometryTypeShed: boolean;
  hasRoofGeometryTypeMansard: boolean;
  hasRoofGeometryTypePyramid: boolean;
  hasRoofGeometryTypeGambrel: boolean;
  hasRoofGeometryTypeBracedGable: boolean;
  hasRoofGeometryTypeFlatOrLowSlope: boolean;
  hasRoofGeometryTypeGable: boolean;
  hasRoofGeometryTypeComplex: boolean;
  hasRoofGeometryTypeSteppedOrSawtooth: boolean;

  isRoofGeometryTypeHipPredominant: boolean;
  isRoofGeometryTypeShedPredominant: boolean;
  isRoofGeometryTypeMansardPredominant: boolean;
  isRoofGeometryTypePyramidPredominant: boolean;
  isRoofGeometryTypeGambrelPredominant: boolean;
  isRoofGeometryTypeBracedGablePredominant: boolean;
  isRoofGeometryTypeFlatOrLowSlopePredominant: boolean;
  isRoofGeometryTypeGablePredominant: boolean;
  isRoofGeometryTypeComplexPredominant: boolean;
  isRoofGeometryTypeSteppedOrSawtoothPredominant: boolean;
}

class RoofAdditionalFeature {
  windUpliftRoofRatingUl580: string; // null !
  windUpliftRoofRatingUl580Comment: string; // null !
  preventativeMaintenanceProgram: string;
  solarPanelComment: string;
  solarPanels: string;
  roofPerimiterCoveredBySolarPanels: string;
  flashingCondition: string;
  flashingConditionComment: string;
  hasSecondaryWaterBarrier: boolean;
  mechanicalEquipmentOnRoof: string;
  mechanicalEquipmentOnRoofComment: string;
  hailProtectedMountedRoofEquipment: string;
  lightlyConstructedRoofStructures: string;
  roofCoverDegradation: string;
  roofCoverDegradationComment: string;
  miscellaneousRoofObjects: string;
  miscellaneousRoofObjectsComment: string;
}

//Roof Features
class RoofFeatures {
  impactResistanceDocumnetationUL22180OrFm4473: string;
  impactResistanceDocumentationUl2218OrFm4473Comment: string;
  roofPitch: string;
  roofPerimeterHigherThan6InchesParapet: string;
  averageParapetHeight: string;
  ageOfRoofCover: string;
  hasSkyLightsPresentOnRoof: string;
  skyLightsRoofRatingType: string;
  skyLightsRoofRatingTypeComment: string;
}

