import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { RiskModeEnum } from 'src/app/enums/formly-builder/risk-mode.enum';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { Constants } from 'src/app/services/util-service/constants';
export class FloorRoofSection extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'floorsAndRoofs',
      wrappers: [ 'section-wrapper' ],
      props: {
        label: 'Floors and Roofs',
        addType: 'Floors and Roof',
        addText: 'Floors and Roof',
        actionButtons: [ { section: 'floorsAndRoofs', action: 'add', label: Constants.btnText, isDisabled: false } ],
        isCollapsed: false,
        isEmptySectionOnInit: false,
      },
      expressions: {
        'props.isDisabled': 'formState.service.mode === 0',
        // 'props.actionButtons[0].isDisabled': 'formState.service.mode === 0',
        'props.actionButtons[0].action': (field: FormlyFieldConfig) => {
          if (field.form.value.floorsAndRoofs.constructionEntries?.floorsAndRoofsLevels?.length) {
            field.props.actionButtons[ 0 ].label = 'Edit';
            field.props.isCollapseIconDisabled = false;
            return 'edit';
          } else {
            field.props.actionButtons[ 0 ].label = Constants.btnText;
            field.props.isCollapseIconDisabled = true; // disable the expand collapse arrow if no entries
            field.props.isCollapsed = true;
            return 'add';
          }
        },
      },
      hooks: {
        onInit: (field) => {
          if (!field.form.value.floorsAndRoofs?.constructionEntries?.floorsAndRoofsLevels?.length) {
            field.props.isEmptySectionOnInit = true;
          }
        }
      },
      validators: {
        emptyOccupants: {
          expression: (control: FormControl, field: FormlyFieldConfig) => {
            if (field.options.formState.service.mode === RiskModeEnum.CURRENCY) {
              return true;
            }
            const floorsRoofLevels = field.form.value.floorsAndRoofs?.constructionEntries?.floorsAndRoofsLevels;
            if (floorsRoofLevels?.length === 0) {
              return false;
            }
            return true;
          },
          message: 'You have to add at least one Roof.',
        },
      },
      fieldGroup: [
        {
          type: 'summary-row',
          fieldGroup: [
            {
              key: 'constructionClass',
              props: {
                label: 'Construction Class'
              },
              expressions: {
                hide: () => formlyBuilderService.mode === 1
              }
            },
            {
              key: 'aboveGradeFloors',
              props: {
                label: 'Above Grade Floors',
                isValidatorNeeded: formlyBuilderService.mode === RiskModeEnum.CURRENCY ? false : true
              },
              validators: {
                validation: [ { name: 'comparison-validator', options: { compareWith: 'section-occupants' } } ],
              },
            },
            {
              key: 'basementLevels',
              props: {
                label: 'Basement Levels',
                isValidatorNeeded: formlyBuilderService.mode === RiskModeEnum.CURRENCY ? false : true
              },
              validators: {
                validation: [ { name: 'comparison-validator', options: { compareWith: 'section-occupants' } } ],
              },
            },
            {
              key: 'totalArea',
              props: {
                label: 'Total Area',
                unit: 'sq.ft.'
              },
            },
            {
              key: 'numberOfAttics',
              expressions: {
                //hide: () => true
              }
            },
            {
              key: 'numberOfMezzanine',
              expressions: {
                //hide: () => true
              }
            },
            {
              key: 'percentageOccupied',
              expressions: {
                hide: () => true
              }
            }
          ],
          expressions: {
            hide: (field: FormlyFieldConfig) => field.parent.props.isCollapseIconDisabled,
          }
        },
        { key: 'floorsAndRoofsLevelsValidator' },
        { key: 'mezzanineLevelValidator' },
        {
          key: 'crossSectionValidator',
          /*expressions: {
            hide: () => formlyBuilderService.mode !== 1,
          },*/
        },
        {
          key: 'constructionEntries',
          type: 'array-floors-roof',
          props: {
            label: 'Floors and Roof',
            addType: 'FloorsAndRoof',
            addText: Constants.btnText,
          },
          fieldGroup: [
            {
              key: 'roofTopCovering',
              // type: 'simple-select',
              props: {
                label: 'Predominant Roof Covering',
                options: [
                  { value: 'None', label: 'None' },
                  { value: 'Built-up Roof with Unsecured Gravel', label: 'Built-up Roof with Unsecured Gravel' },
                  { value: 'Built-up Roof with Secured Gravel', label: 'Built-up Roof with Secured Gravel' },
                  { value: 'Built-up Roof without a Gravel Surface', label: 'Built-up Roof without a Gravel Surface' },
                  { value: 'Sprayed Polyurethane Foam (SPF)', label: 'Sprayed Polyurethane Foam (SPF)' },
                  { value: 'Single-Ply Membrane', label: 'Single-Ply Membrane' },
                  { value: 'Wood Shingles', label: 'Wood Shingles' },
                  { value: 'Light Metal Panels', label: 'Light Metal Panels' },
                  { value: 'Standing Seam Metal', label: 'Standing Seam Metal' },
                  { value: 'Masonry Tile', label: 'Masonry Tile' },
                  { value: 'Slate', label: 'Slate' },
                  { value: 'Asphalt Shingles', label: 'Asphalt Shingles' },
                  { value: 'Fabric over structural frame', label: 'Fabric over structural frame' },
                  { value: 'Unknown', label: 'Unknown' },
                ]
              }
            },
            {
              key: 'floorsAndRoofsLevels',
              type: 'floors-roofs-level-tiles',
              fieldArray: {
                fieldGroupClassName: 'formly-flex-container',
                wrappers: [ 'error-info-wrapper' ],
                fieldGroup: [
                  {
                    key: 'levelTypeCode',
                    type: 'simple-select',
                    className: 'select-width',
                    props: {
                      label: 'Level Type',
                      required: false,
                      isAsteriskNotRequired: true,
                      isDisabled: false,
                      index: 0,
                      className: '',
                      options: [
                        { value: 'ROOF', label: 'Roof' },
                        { value: 'FLOO', label: 'Floor' },
                        // { value: 'BASE', label: 'Basement' },
                        //commenting MEZZ and BASE option as its not needed for Exterior survey
                        //{ value: 'MEZZ', label: 'Mezzanine' },
                        { value: 'ATTI', label: 'Attic' }
                      ]
                    },
                    expressions: {
                      'props.index': 'field.parent.key',
                      'props.isDisabled': (field?: FormlyFieldConfig) => field.props.index === '0' ? true : false,
                      'props.className': (field?: FormlyFieldConfig) => field.props.index === '0' ? 'custom-disabled-background' : '',
                      'props.required': () => formlyBuilderService.mode === 1 ? true : false
                    },
                    hooks: {
                      onInit: (field) => {
                        const formlyService = field.options.formState.service;
                        formlyService.formlyFloorsRoofService.onLevelTypeChange(field);
                      }
                    }
                  },
                  // {
                  //   template: '<img style="width: 52px; height: 42px" src="../../../../assets/icon/floors-and-roofs-calculate.png">',
                  //   className: 'image-margin'
                  // },
                  {
                    type: 'rf-aerial'
                  },                  
                  {
                    key: 'length',
                    type: 'simple-input',
                    defaultValue: null,
                    // defaultValue: 1,
                    className: 'input-width ft-label-input',
                    props: {
                      label: 'Length',
                      type: 'number',
                      inputmode: 'tel',
                      min: 1,
                      maxLength: 8,
                      required: false,
                      isAsteriskNotRequired: true,
                      isNonBlockingValidation: false
                    },
                    expressions: {
                      'props.required': () => formlyBuilderService.mode === 1 ? true : false
                    }
                  },
                  {
                    key: 'width',
                    type: 'simple-input',
                    defaultValue: null,
                    // defaultValue: 1,
                    className: 'input-width ft-label-input',
                    props: {
                      label: 'Width',
                      type: 'number',
                      inputmode: 'tel',
                      required: false,
                      isAsteriskNotRequired: true,
                      min: 1,
                      maxLength: 8,
                      isNonBlockingValidation: false
                    },
                    expressions: {
                      'props.required': () => formlyBuilderService.mode === 1 ? true : false
                    }
                  },
                  {
                    key: 'area',
                    type: 'text',
                    className: 'text-area',
                    props: {
                      label: 'Area',
                      required: false,
                      readonly: true,
                      unit: ' sq.ft.',
                      prefix: '=',
                      isCorrect: undefined,
                    },
                    expressions: {
                      'props.isCorrect': (field: FormlyFieldConfig) => !field.formControl.valid ? false : undefined
                    },
                    hooks: {
                      onInit: (field) => {
                        const formlyService = field.options.formState.service;
                        formlyService.formlyFloorsRoofService.areaCalculation(field);
                      }
                    },
                    validators: {
                      validation: [ { name: 'area-validator' } ]
                    }
                  },
                  { template: '<span></span>', className: 'new-row' },
                  {
                    key: 'constructionType',
                    type: 'simple-select',
                    className: 'select-width-md',
                    props: {
                      label: 'Construction Type',
                      required: false,
                      isAsteriskNotRequired: true,
                      options: []
                    },
                    expressions: {
                      hide: (field: FormlyFieldConfig) => field.form.value.isLowestLevel,
                      'props.required': () => formlyBuilderService.mode === 1 ? true : false
                    },
                    hooks: {
                      onInit: (field) => {
                        const formlyService = field.options.formState.service;
                        field.props.options = [
                          { value: 'Fire Resistive', label: 'Fire Resistive' },
                          { value: 'Non-combustible/ Slow Burn', label: 'Non-combustible/Slow burn' },
                          { value: 'Combustible', label: 'Combustible' } ];
                      }
                    }
                  },
                  {
                    key: 'constructionMaterial',
                    type: 'simple-select',
                    className: 'select-width-md',
                    props: {
                      label: 'Construction Material',
                      required: false,
                      isDisabled: true,
                      isAsteriskNotRequired: true,
                      options: [],
                      popOverOptionsClassName: 'xl-dropdown'
                    },
                    expressions: {
                      'props.required': (field: FormlyFieldConfig) =>field.form.value.constructionType && formlyBuilderService.mode === 1 ? true : false,
                      hide: (field: FormlyFieldConfig) => field.form.value.isLowestLevel,
                      'props.isDisabled': (field?: FormlyFieldConfig) => !field.form.value.constructionType
                    },
                    hooks: {
                      onInit: (field) => {
                        const formlyService = field.options.formState.service;
                        formlyService.formlyFloorsRoofService.constructionMaterialInitalization(field);
                      }
                    }
                  },
                  { template: '<span></span>', className: 'new-row' },
                  {
                    key: 'levelStart',
                    type: 'simple-select',
                    className: 'select-width-sm',
                    props: {
                      label: 'Level Start',
                      required: false,
                      maxLevels: 110,
                      options: [],
                      isDisabled: true,
                      isAsteriskNotRequired: true
                    },
                    expressions: {
                      hide: (field: FormlyFieldConfig) =>
                        field.form.value.levelTypeCode === 'ROOF' || field.form.value.isLowestLevel ? true : false,
                      'props.required': (field?: FormlyFieldConfig) =>
                        field.form.value.levelTypeCode && field.form.value.levelTypeCode === 'ROOF' ? false : true,
                      'props.isDisabled':
                        (field?: FormlyFieldConfig) => !field.form.value.levelTypeCode
                    },
                    hooks: {
                      onInit: (field?: FormlyFieldConfig) => {
                        const formlyService = field.options.formState.service;
                        formlyService.formlyFloorsRoofService.levelStartInitalization(field);
                      }
                    }
                  },
                  {
                    key: 'levelStop',
                    type: 'simple-select',
                    className: 'select-width-sm',
                    props: {
                      label: 'Level Stop',
                      required: false,
                      maxLevels: 110
                    },
                    expressions: {
                      hide: (field: FormlyFieldConfig) =>
                        field.form.value.levelTypeCode === 'ROOF' || field.form.value.isLowestLevel ? true : false,
                      'props.isDisabled':
                        (field?: FormlyFieldConfig) =>
                          !(field.form.value.levelTypeCode && field.form.value.levelStart && field.form.value.levelStart.length > 0)
                    },
                    hooks: {
                      onInit: (field?: FormlyFieldConfig) => {
                        const formlyService = field.options.formState.service;
                        formlyService.formlyFloorsRoofService.levelStopInitalization(field);
                      }
                    }
                  },
                  { key: 'isLowestLevel' },
                  { key: 'levelType', },
                  { key: 'floorAndRoofIdentifier' }
                ],
                validators: {
                  validation: [
                    { name: 'mezzanine-validator' },
                    { name: 'floor-level-validator' },
                    { name: 'occupant-level-validator', options: { compareWith: 'section-occupants' } }
                  ],
                }
              }
            },
            {
              fieldGroupClassName: 'formly-flex-container',
              wrappers: [ 'error-info-wrapper' ],
              fieldGroup: [
                {
                  key: 'levelOnGrade',
                  type: 'simple-select',
                  defaultValue: 'Level on Grade',
                  className: 'select-width',
                  props: {
                    label: 'Level Type',
                    readonly: true,
                    options: [ { value: 'Level on Grade', label: 'Level on Grade' } ],
                    isDisabled: true,
                    className: 'custom-disabled-background'
                  }
                },
                // {
                //   template: '<img style="width: 52px; height: 42px" src="../../../../assets/icon/floors-and-roofs-calculate.png">',
                //   className: 'image-margin'
                // },
                {
                  type: 'rf-aerial',
                  props: {
                    areaKey: 'levelOnGradeLength',
                    oneKey: 'levelOnGradeWidth'
                  }
                },                
                {
                  key: 'levelOnGradeLength',
                  type: 'simple-input',
                  defaultValue: null,
                  // defaultValue: 1,
                  className: 'input-width ft-label-input',
                  props: {
                    label: 'Length',
                    type: 'number',
                    inputmode: 'tel',
                    min: 1,
                    maxLength: 8,
                    required: false,
                    isAsteriskNotRequired: true,
                    isNonBlockingValidation: false
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => (formlyBuilderService.mode === 1 &&
                      field.form.value?.floorsAndRoofsLevels?.length > 0) ? true : false
                  }
                },
                {
                  key: 'levelOnGradeWidth',
                  type: 'simple-input',
                  defaultValue: null,
                  // defaultValue: 1,
                  className: 'input-width ft-label-input',
                  props: {
                    label: 'Width',
                    type: 'number',
                    inputmode: 'tel',
                    required: false,
                    isAsteriskNotRequired: true,
                    min: 1,
                    maxLength: 8,
                    isNonBlockingValidation: false
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => (formlyBuilderService.mode === 1 &&
                      field.form.value?.floorsAndRoofsLevels?.length > 0) ? true : false
                  }
                },
                {
                  key: 'levelOnGradeArea',
                  type: 'text',
                  className: 'text-area',
                  props: {
                    label: 'Area',
                    required: false,
                    readonly: true,
                    unit: ' sq.ft.',
                    prefix: '=',
                    isCorrect: undefined,
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => (formlyBuilderService.mode === 1 &&
                      field.form.value?.constructionEntries?.floorsAndRoofsLevels?.length > 0) ? true : false,
                    'props.isCorrect': (field: FormlyFieldConfig) => !field.formControl.valid ? false : undefined
                  },
                  hooks: {
                    onInit: (field) => {
                      if (field.form.value?.floorsAndRoofsLevels && field.form.value?.floorsAndRoofsLevels.length > 0) {
                        field.form.value?.floorsAndRoofsLevels?.forEach(element => {
                          if (element.isLowestLevel) {
                            field.formControl.setValue(Number(element?.length) * Number(element?.width));
                          }
                        });
                      }
                      const formlyService = field.options.formState.service;
                      formlyService.formlyFloorsRoofService.levelOnGradeAreaCalculation(field);
                    }
                  },
                  validators: {
                    areaValidator: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        if (field.options.formState.service.mode === RiskModeEnum.CURRENCY) {
                          return true;
                        }
                        let floorsAndRoofsDeatils = field.parent.form.value.floorsAndRoofsLevels;
                        floorsAndRoofsDeatils = floorsAndRoofsDeatils.filter(src => src?.levelTypeCode === 'FLOO' &&
                          src.levelStart && Number(src.levelStart) === 1);
                        return floorsAndRoofsDeatils?.filter(x => x.area > control.value)?.length > 0 ? false : true;
                      },
                      message: 'Floor 1 area cannot exceed Level on Grade area',
                    }
                  }
                }
              ]
            }

          ],
        }
      ]
    });
  }
}
