import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FirePumpTestsSectionModel } from './fire-pump-tests.model';
import { MainDrainEstimationSectionModel } from './main-drain-estimation-section.model';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import moment from 'moment';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class SupplySourceSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'supplySource',
      wrappers: [ 'simple-section-wrapper' ],
      props: {
        label: 'Supply Source 1',
        linkId: SectionEnum.FR_SR_WS_SUPPLY_SOURCE,
        isCollapsable: true,
        isCollapsed: false,
        shallowErrors: false,
        checkTouchedControl: true,
        hideSupplySource2: true,
        isUpdateSupplySource: false,
      },
      fieldGroupClassName: 'supply-source',
      fieldGroup: [
        {
          key: 'ss1',
          type: 'fr-supply-source',
          props: {
            showRemoveIcon: false,
            showCalculateBtn: false
          },
          expressions: {
            'props.showAddBtn': (field: FormlyFieldConfig) => field?.parent?.props?.hideSupplySource2,
            'props.disableAddBtn': (field: FormlyFieldConfig) => field?.model?.supplySourceTypeSs1 === 'None Available' ? true : false,
            'props.showCalculateBtn': (field: FormlyFieldConfig) => field?.model?.supplySourceTypeSs1 === 'Main Drain Estimation' ? true : false
          },
          fieldGroupClassName: 'formly-flex-container',
          fieldGroup: [
            {
              key: 'supplySourceTypeSs1',
              type: 'simple-select',
              className: 'select-width-md',
              defaultValue: 'Municipal Supply',
              props: {
                label: 'Type',
                options: [
                  { value: 'Municipal Supply', label: 'Municipal Supply' },
                  { value: 'Main Drain Estimation', label: 'Main Drain Estimation' },
                  { value: 'Sole Source Pump', label: 'Sole Source Pump' },
                  { value: 'Elevated Tank', label: 'Elevated Tank' },
                  { value: 'Pressure Tank', label: 'Pressure Tank' },
                  { value: 'None Available', label: 'None Available' }
                ],
                required: true,
                isAsteriskNotRequired: true
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  formlyBuilderService.waterSupplyService.SupplySource1ValueChangeSubscription(field);
                  const isExistingRisk = formlyBuilderService.isExistingRisk();
                  if(isExistingRisk){
                    field.defaultValue =null;
                  }
                }
              }
            },
            {
              key: 'locationSs1',
              type: 'simple-input',
              className: 'ss-location-width',
              props: {
                label: 'Location',
                defaultValue: ''
              },
              expressions: {
                'props.readonly': (field: FormlyFieldConfig) => formlyBuilderService.waterSupplyService.markAsReadOnly(field, field?.parent?.model?.supplySourceTypeSs1),
              },
            },
            { template: '<span></span>', className: 'new-row' },
            {
              key: 'testDateSs1',
              type: 'rf-date-picker',
              className: 'smd-width ss-input-padding date-picker',
              props: {
                label: 'Test Date',
                displayFormat: 'MM/DD/YY',
                required: true,
                isAsteriskNotRequired: true,
                datePickerId: 'testDateSs1'
              },
              expressions: {
                'props.required': (field: FormlyFieldConfig) => !field?.props?.readonly,
                'props.readonly': (field: FormlyFieldConfig) => formlyBuilderService.waterSupplyService.markAsReadOnly(field, field?.parent?.model?.supplySourceTypeSs1),
              },
              validators: {
                validation: [ { name: 'future-date-validator', options: { msg: 'Test date cannot be future date.' } } ],
                compareWithSurveyDate: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    try {
                      let surveyDate = field?.form?.root?.get('survey')?.value?.surveyDate;
                      if (surveyDate && control.value) {
                        surveyDate = moment(surveyDate);
                        const testDate = moment(control.value);
                        const diff = surveyDate.diff(testDate, 'years');
                        return diff && diff > 10 ? false : true;
                      }
                    }
                    catch (e) {
                      console.log(e);
                    }
                    return true;
                  },
                  message: 'Water supply information cannot be older than 10 years from the survey date'
                }
              },
            },
            {
              key: 'staticPressureSs1',
              type: 'simple-input',
              className: 'smd-width ss-input-padding',
              props: {
                label: 'Static',
                type: 'number',
                unit: 'PSI'
              },
              expressions: {
                'props.readonly': (field: FormlyFieldConfig) => formlyBuilderService.waterSupplyService.markAsReadOnly(field, field?.parent?.model?.supplySourceTypeSs1),
                'props.required': (field: FormlyFieldConfig) => field?.parent?.model?.residualPressureSs1 || field?.parent?.model?.waterFlowSs1 ? true : false
              }
            },
            {
              key: 'residualPressureSs1',
              type: 'simple-input',
              className: 'smd-width ss-input-padding',
              props: {
                label: 'Residual',
                type: 'number',
                unit: 'PSI',
              },
              expressions: {
                'props.readonly': (field: FormlyFieldConfig) => formlyBuilderService.waterSupplyService.markAsReadOnly(field, field?.parent?.model?.supplySourceTypeSs1),
                'props.required': (field: FormlyFieldConfig) => field?.parent?.model?.staticPressureSs1 || field?.parent?.model?.waterFlowSs1 ? true : false
              },
              validators: {
                validation: [
                  {
                    name: 'cross-field-comparison-validator',
                    options: {
                      compareWith: 'staticPressureSs1',
                      msg: 'Residual Pressure cannot be greater than Static Pressure',
                      condition: 'greaterThan'
                    },
                  },
                ],
              },
            },
            {
              key: 'waterFlowSs1',
              type: 'simple-input',
              className: 'water-flow-ss1 smd-width ss-input-padding',
              props: {
                label: 'Water Flow',
                type: 'number',
                unit: 'GPM'
              },
              expressions: {
                'props.readonly': (field: FormlyFieldConfig) => formlyBuilderService.waterSupplyService.markAsReadOnly(field, field?.parent?.model?.supplySourceTypeSs1),
                'props.required': (field: FormlyFieldConfig) => field?.parent?.model?.staticPressureSs1 || field?.parent?.model?.residualPressureSs1 ? true : false
              }
            },
            MainDrainEstimationSectionModel.build(formlyBuilderService).fields,
            { template: '<span></span>', className: 'new-row' },
            {
              key: 'storedVolumeSs1',
              type: 'simple-input',
              className: 'smd-width ss-input-padding',
              props: {
                label: 'Stored Volume',
                type: 'number',
                unit: 'GAL'
              },
              expressions: {
                'props.required': (field: FormlyFieldConfig) => field?.parent?.model?.supplySourceTypeSs1 === 'Pressure Tank'
                  || field?.parent?.model?.supplySourceTypeSs1 === 'Elevated Tank' ? true : false,
                'props.readonly': (field: FormlyFieldConfig) => formlyBuilderService.waterSupplyService.markAsReadOnly(field, field?.parent?.model?.supplySourceTypeSs1)
              },
            },
            {
              key: 'feetGaugeSs1',
              type: 'simple-input',
              className: 'smd-width ss-input-padding',
              props: {
                label: 'Feet Gauge',
                type: 'number',
                unit: 'ft'
              },
              expressions: {
                'props.readonly': (field: FormlyFieldConfig) => formlyBuilderService.waterSupplyService.markAsReadOnly(field, field?.parent?.model?.supplySourceTypeSs1),
              }
            },
            {
              key: 'isSupervisedPressureTankSs1',
              type: 'custom-checkbox',
              className: 'pressure-tank',
              props: {
                label: 'Pressure tank supervised'
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => field?.parent?.model?.supplySourceTypeSs1 === 'Pressure Tank' ? false : true
              },
            },
            { template: '<span></span>', className: 'new-row' },
            FirePumpTestsSectionModel.build(formlyBuilderService).fields
          ]
        },
        {
          template: '<div class="ws-title-style">Supply Source 2</div>',
          expressions: {
            hide: (field: FormlyFieldConfig) => field?.parent?.props?.hideSupplySource2
          },
        },
        {
          key: 'ss2',
          type: 'fr-supply-source',
          props: {
            showAddBtn: false,
          },
          expressions: {
            hide: (field: FormlyFieldConfig) => field?.parent?.props?.hideSupplySource2
          },
          fieldGroupClassName: 'formly-flex-container',
          fieldGroup: [
            {
              key: 'supplySourceTypeSs2',
              type: 'simple-select',
              className: 'select-width-md',
              props: {
                label: 'Type',
                required: false,
                isAsteriskNotRequired: true
              },
              expressions: {
                'props.required': (field: FormlyFieldConfig) => field?.parent?.parent?.props?.hideSupplySource2 !== true ? true : false
              },
              hooks: {
                onInit: (field) => {
                  const ss2 = field.model;
                  const isValuePresent = ss2?.feetGaugeSs2 ||ss2?.locationSs2 ||ss2?.isSupervisedPressureTankSs2 ||
                    ss2?.residualPressureSs2 || ss2?.staticPressureSs2 || ss2?.storedVolumeSs2 ||ss2?.supplySourceTypeSs2 ||
                    ss2?.testDateSs2 || ss2?.waterFlowSs2;
                  if (!field.formControl.value && !isValuePresent && formlyBuilderService?.isNewRisk()) {
                    field?.formControl?.setValue('Municipal Supply');
                  } else if(!field.formControl.value && isValuePresent) {
                    field.formControl.markAsTouched();
                  }
                  formlyBuilderService.waterSupplyService.updateSupplySouce2Type(field);
                  formlyBuilderService.waterSupplyService.supplySource2ValueChangeSubscription(field);
                }
              }
            },
            {
              key: 'locationSs2',
              type: 'simple-input',
              className: 'ss-location-width',
              props: {
                label: 'Location',
                defaultValue: ''
              },
            },
            { template: '<span></span>', className: 'new-row' },
            {
              key: 'testDateSs2',
              type: 'rf-date-picker',
              className: 'smd-width ss-input-padding date-picker',
              props: {
                label: 'Test Date',
                displayFormat: 'MM/DD/YY',
                datePickerId: 'testDateSs2',
                required: false,
                isAsteriskNotRequired: true
              },
              expressions: {
                'props.readonly': (field: FormlyFieldConfig) => formlyBuilderService.waterSupplyService.markAsReadOnly(field, field?.parent?.model?.supplySourceTypeSs1),
                'props.required': (field: FormlyFieldConfig) => field?.parent?.parent?.props?.hideSupplySource2 !== true ? true : false
              },
              validators: {
                validation: [ { name: 'future-date-validator', options: { msg: 'Test date cannot be future date.' } } ],
              },
            },
            {
              key: 'staticPressureSs2',
              type: 'simple-input',
              className: 'smd-width ss-input-padding',
              props: {
                label: 'Static',
                type: 'number',
                unit: 'PSI'
              },
              expressions: {
                'props.required': (field: FormlyFieldConfig) => field?.parent?.model?.residualPressureSs2 || field?.parent?.model?.waterFlowSs2 ? true : false
              }
            },
            {
              key: 'residualPressureSs2',
              type: 'simple-input',
              className: 'smd-width ss-input-padding',
              props: {
                label: 'Residual',
                type: 'number',
                unit: 'PSI'
              },
              expressions: {
                'props.required': (field: FormlyFieldConfig) => field?.parent?.model?.staticPressureSs2 || field?.parent?.model?.waterFlowSs2 ? true : false
              },
              validators: {
                validation: [
                  {
                    name: 'cross-field-comparison-validator',
                    options: {
                      compareWith: 'staticPressureSs2',
                      msg: 'Residual Pressure cannot be greater than Static Pressure',
                      condition: 'greaterThan'
                    },
                  },
                ],
              },
            },
            {
              key: 'waterFlowSs2',
              type: 'simple-input',
              className: 'smd-width ss-input-padding',
              props: {
                label: 'Water Flow',
                type: 'number',
                unit: 'GPM'
              },
              expressions: {
                'props.required': (field: FormlyFieldConfig) => field?.parent?.model?.staticPressureSs2 || field?.parent?.model?.residualPressureSs2 ? true : false
              },
            },
            { template: '<span></span>', className: 'new-row' },
            {
              key: 'storedVolumeSs2',
              type: 'simple-input',
              className: 'smd-width ss-input-padding',
              props: {
                label: 'Stored Volume',
                type: 'number',
                unit: 'GAL'
              },
              expressions: {
                'props.required': (field: FormlyFieldConfig) => field?.parent?.model?.supplySourceTypeSs2 === 'Pressure Tank'
                  || field?.parent?.model?.supplySourceTypeSs2 === 'Elevated Tank' ? true : false,
              },
            },
            {
              key: 'feetGaugeSs2',
              type: 'simple-input',
              className: 'smd-width ss-input-padding',
              props: {
                label: 'Feet Gauge',
                type: 'number',
                unit: 'ft'
              },
            },
            {
              key: 'isSupervisedPressureTankSs2',
              type: 'custom-checkbox',
              className: 'pressure-tank',
              props: {
                label: 'Pressure tank supervised'
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => field?.parent?.model?.supplySourceTypeSs2 === 'Pressure Tank' ? false : true
              },
            },
            { template: '<span></span>', className: 'new-row' },
            FirePumpTestsSectionModel.build(formlyBuilderService).fields
          ],
        },
      ],
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          formlyBuilderService.waterSupplyService.hideSupplySource2Section(field);
        }
      }
    });
  }
}
