import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { RiskReportService } from 'src/app/services/formly-builder/risk-report.service';
import { Constants } from 'src/app/services/util-service/constants';

export class AlarmSupervisoryTestSectionModel extends FormlySectionModelAbstract {
  constructor(private riskReportService: RiskReportService) {
    super({
      key: 'alarmAndSupervisoryTests',
      type: 'fr-common-custom-grid-view',
      className: 'alarm-supervisory-container',
      wrappers: ['simple-section-wrapper'],
      props: {
        // Props for simple section wrapper
        className: 'padding-bottom',

        // Props for fr-common-custom-grid-view
        label: 'Alarm And Supervisory Test',
        isCollapsable: true,
        isCollapsed: false,
        actionButtons: [{ section: 'systemTesting', action: 'openArraySheetAdd', label: 'Add', isDisabled: false }],
        gridSection: 'alarmAndSupervisoryTests',
        gridColConfig: [
          { label: 'System', propertyPath: 'systemNumber', className: 'common-grid-view-width-100' },
          { label: 'Date Tested', propertyPath: 'testDate', isDate: true, className: 'common-grid-view-width-115' },
          {
            label: 'Conducted by', propertyPath: 'testConductedBy',
            className: 'common-grid-view-width-195'
          },

        ],

        // Props for edit sheet
        title: 'System Testing',
        subTitle: ' Alarm and Supervisory Tests',
        isAddIdentifier: true,
        identifierKey: 'alarmAndSupervisoryTestId',
        reportIdentifierKey: 'reportId',
        reportName: 'SprinklerReport',
        isSwipeActionNeeded: true,
        commentFieldKey:'comments',
        swipeActionDetails: [ { action: 'comment', styleName: 'commentIcon' }],
        sheetButtons: [
          {
            section: 'alarmAndSupervisoryTests',
            action: 'onClose',
            label: 'Close',
            service: 'alarmAndSupervisoryTestService',
            className: 'close-btn medium-btn',
          },
          {
            section: 'alarmAndSupervisoryTests',
            action: 'onApply',
            label: 'Apply',
            service: 'alarmAndSupervisoryTestService',
            className: 'apply-btn large-btn',
            role: 'apply'
          }
        ],
      },
      expressions: {
        'props.actionButtons[0].label': (field: FormlyFieldConfig) => field?.model?.length ? 'Edit' : Constants.btnText,
        'props.actionButtons[0].action': (field: FormlyFieldConfig) => field?.model?.length ? 'openArraySheetEdit' : 'openArraySheetAdd'
      },
      fieldArray: {
        fieldGroupClassName: 'formly-flex-container alarm-supervisory-sheet-container',
        wrappers: ['error-info-wrapper'],
        fieldGroup: [
          {
            key: 'systemNumber',
            type: 'simple-input',
            className: 'input-width-xs',
            props: {
              label: 'System',
              required: true,
              isAsteriskNotRequired: true,
              maxLength: 75,
            },
          },
          {
            key: 'testDate',
            type: 'rf-date-picker',
            props: {
              label: 'Date Tested',
              displayFormat: 'MM/DD/YY',
              required: true,
              isAsteriskNotRequired: true,
              datePickerId: 'alarmAndSupervisoryTest'
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                if (field?.parent && field?.parent['index']) {
                  field.props.datePickerId = 'alarmAndSupervisoryTest' + (field?.parent['index'] + 1);
                }
              }
            },
            validators: {
              validation: [{ name: 'future-date-validator', options: { msg: 'Cannot be future date.' } }]
            }
          },
          {
            key: 'testConductedBy',
            type: 'simple-input',
            className: 'input-width-307 conducted-by-width',
            props: {
              label: 'Conducted by',
              type: 'text',
              required: true,
              isAsteriskNotRequired: true,
              maxLength: 100
            },
          },
          {
            key: 'localAlarmResults',
            type: 'simple-select',
            className: 'select-width-198',
            props: {
              label: 'Local Results',
              options: [],
              required: true,
              isAsteriskNotRequired: true,
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                const alarmAndSupervisoryTestService = field.options.formState.service.alarmAndSupervisoryTestService;
                field.props.options = alarmAndSupervisoryTestService.getAlarmSupervisoryOptions();
              }
            }
          },
          {
            key: 'signalAlarmResults',
            type: 'simple-select',
            className: 'select-width-198',
            props: {
              label: 'Signal Results',
              options: [],
              required: true,
              isAsteriskNotRequired: true,
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                const alarmAndSupervisoryTestService = field.options.formState.service.alarmAndSupervisoryTestService;
                field.props.options = alarmAndSupervisoryTestService.getAlarmSupervisoryOptions();
              }
            }
          },
          {
            key: 'supervisedAlarmResults',
            type: 'simple-select',
            className: 'select-width-198',
            props: {
              label: 'Supervisory Results',
              options: [],
              required: true,
              isAsteriskNotRequired: true,
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                const alarmAndSupervisoryTestService = field.options.formState.service.alarmAndSupervisoryTestService;
                field.props.options = alarmAndSupervisoryTestService.getAlarmSupervisoryOptions();
              }
            }
          },
          {
            key: 'comments',
            props: {
              commentActionKey: 'comments',
              maxLength: 300
            }
          },
        ]
      },
    
    });
  }
}
