
import { Guid } from 'guid-typescript';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class CommentsDataModel {
    static sectionName = 'comments';

    commentIdentifier: string;
    reportIdentifier: string;
    commentBy: string;
    commentDateTime: any;
    commentType: string;
    commentSegmentText: string;
    commentSegments: CommentSegmentModel[];

    static fromS3(commentsData: any) {
       
        const frNoteData=commentsData?.filter((data)=>{ 
            return data.CommentType === 'FRNT';
         });
         const cmtData=commentsData?.filter((data)=>{ 
            return data.CommentType === 'COMT';
         });

       const rfCmtData=CommentsDataModel.setRiskCommentModel(cmtData);
       const frNotesCmtData=CommentsDataModel.setRiskCommentModel(frNoteData);

       return {
        comments:rfCmtData,
        frNotes:frNotesCmtData,
       }
       
       
    }

    static to(model: any, reportId: string, sectionEnum?: SectionEnum) {
        const commentSegments = [];
        const commentIdentifier=model?.commentIdentifier ?  model?.commentIdentifier : Guid.create().toString()
        commentSegments.push(CommentsDataModel.setCommentSegmentModelS3( model));
        commentSegments.forEach((comment, index)=>{
           comment.CommentIdentifier = comment?.CommentIdentifier ? comment?.CommentIdentifier : commentIdentifier;
        })
        return {
            CommentIdentifier: commentIdentifier,
            ReportIdentifier: model?.reportIdentifier ? model?.reportIdentifier : reportId,
            CommentBy: model?.commentBy ? model?.commentBy : '',
            CommentDateTime: model?.commentDateTime ? model?.commentDateTime : '',
            CommentType: model?.commentType ? model?.commentType : '',
            CommentSegments: commentSegments
        };
    }

    /**
     * Check if the model has changed
     *
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     *
     */
    static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
        return false;

    }

    static setCommentSegmentModel(segmentData) {
        let data;
        segmentData?.forEach((segment)=>{
           data= {
                commentSegmentIdentifier: segment?.CommentSegmentIdentifier,
                commentIdentifier: segment?.CommentIdentifier,
                commentSegmentSequenceNumber: segment?.CommentSegmentSequenceNumber,
               commentSegmentText: (segment?.CommentSegments?.length > 0 &&
                   segment?.CommentSegments[ 0 ]?.CommentSegmentText) ? segment?.CommentSegments[ 0 ]?.CommentSegmentText : null
            };
        });
        return data;
    }

    static setCommentSegmentModelS3(commentData) {
        const segmentData = commentData.commentSegments;
        return {
            CommentSegmentIdentifier: segmentData?.commentSegmentIdentifier ? segmentData?.commentSegmentIdentifier :  Guid.create().toString(),
            CommentIdentifier: commentData?.commentIdentifier ? commentData?.commentIdentifier : '',
            CommentSegmentSequenceNumber: segmentData?.commentSegmentSequenceNumber ? segmentData?.commentSegmentSequenceNumber : 0,
            CommentSegmentText: commentData.commentType === 'COMT' ? commentData.formlyCommentSegmentText : (commentData.commentType === 'FRNT'  ? commentData.formlyFRNotesSegmentText : ''),
        };
    }

    static setRiskCommentModel(commentsData){
      const  commentSegments=[]
        let data;
        commentSegments.push(CommentsDataModel.setCommentSegmentModel(commentsData));

        commentsData?.forEach((comment)=>{
            data= {
                commentBy: comment.CommentBy,
                commentDateTime: comment.CommentDateTime,
                commentType: comment.CommentType,
                formlyCommentSegmentText: comment.CommentType === 'COMT' ? (comment.CommentSegments?.length > 0
                    && comment.CommentSegments[ 0 ]?.CommentSegmentText ? comment.CommentSegments[ 0 ]?.CommentSegmentText:null) : null,
                formlyFRNotesSegmentText: comment.CommentType === 'FRNT' ? (comment.CommentSegments?.length > 0
                    && comment.CommentSegments[ 0 ]?.CommentSegmentText ? comment.CommentSegments[ 0 ]?.CommentSegmentText : null) : null,
                commentIdentifier: comment.CommentIdentifier ? comment.CommentIdentifier  : Guid.create().toString(),
                reportIdentifier: comment.ReportIdentifier,
                commentSegments
            };
        });
        return data;
    }
}



export class CommentSegmentModel {
    commentSegmentIdentifier: string;
    commentIdentifier: string;
    commentSegmentSequenceNumber: number;
    commentSegmentText: string;
}
