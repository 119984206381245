import { AddressSectionModel } from 'src/app/models/formly-builder/sections/address.model';
import { BuildingBGSectionModel } from 'src/app/models/formly-builder/sections/building-bg.model';
import { ConstructionVerificationSectionModel } from 'src/app/models/formly-builder/sections/construction-verification.model';
import { FloorRoofSection } from 'src/app/models/formly-builder/sections/floor-roof.model';
import { InternalProtectionSectionModel } from 'src/app/models/formly-builder/sections/full-risk/internal-protection.model';
import { CombustibleConstructionModel } from 'src/app/models/formly-builder/sections/full-risk/combustible-construction.model';
import { GeneralInformationModel } from 'src/app/models/formly-builder/sections/general-information.model';
import { OccupantsSectionModel } from 'src/app/models/formly-builder/sections/occupants.model';
import { PhotosSectionModel } from 'src/app/models/formly-builder/sections/photos.model';
import { FullRiskPhotosSectionModel } from 'src/app/models/formly-builder/sections/overview/full-risk-photos-section.model';
import { WallsSectionModel } from 'src/app/models/formly-builder/sections/walls.model';
import { SectionRoofTopService } from './section-roof-top.service';
import { SectionPhotosService } from './section-photos.service';
import { RooftopSectionModel } from 'src/app/models/formly-builder/sections/roof-top.model';
import { FormlyAddressService } from './formly-address.service';
import { FormlyBuildingBGService } from './formly-building-bg.service';
import { FormlyFloorsRoofService } from './formly-floors-roof.service';
import { FormlyOccupantsService } from './formly-occupants.service';
import { FormlyWallsService } from './formly-walls.service';
import { GeneralInformationService } from './general-information.service';
import { SectionConstVerifService } from './section-construction-verification.service';
import { FormlyCommentService } from './formly-comment.service';
import { CommentAndAttachmentSectionModel } from 'src/app/models/formly-builder/sections/full-risk/comment-attachment.model';
import { SectionConfigInterface } from 'src/app/interfaces/formly-builder/section-config.interface';
import { ContactInformationService } from './overview/contact-information.service';
import { ContactInformationModel } from 'src/app/models/formly-builder/sections/overview/contact-information.model';
import { FormlyBuildingInformationService } from './formly-building-information.service';
import { BuildingNameSectionModel } from 'src/app/models/formly-builder/sections/overview/building-name-section.model';
import { PublicProtectionService } from './full-risk/formly-public-protection.service';
import { PublicProtectionClassSectionModel } from 'src/app/models/formly-builder/sections/overview/ppc.model';
import { FireSprinklerSectionModel } from 'src/app/models/formly-builder/sections/overview/fire-sprinkler.model';
import { FireSprinklerService } from './overview/fire-sprinkler.service';
import { WallsConstructionModel } from 'src/app/models/formly-builder/sections/full-risk/walls/walls-construction.model';
import { WallsBGIIModel } from 'src/app/models/formly-builder/sections/full-risk/walls/walls-bg2.model';
import { FormlyWallsConstructionService } from './formly-walls-construction.service';
import { FormlyWallsBGService } from './full-risk/formly-walls-bg.service';
import { EnhancedWindService } from './wind/enhanced-wind.service';
import { WindLossService } from './wind/wind-loss.service';
import { WindEnvironmentExposuresService } from './wind/wind-environment-exposures.service';
import { WindRoofEnvelopeService } from './wind/wind-roof-envelope.service';
import { WindWallEnvelopeService } from './wind/wind-wall-envelop.service';
import { WindFrameworkService } from './wind/wind-framework.service';
import { EnhancedWindSectionModel } from 'src/app/models/formly-builder/sections/wind/enhanced-wind-section.model';
import { WindEnvironmentExposuresSectionModel } from 'src/app/models/formly-builder/sections/wind/wind-environment-exposures-section.model';
import { WindRoofEnvelopeSectionModel } from 'src/app/models/formly-builder/sections/wind/wind-roof-envelope-section.model';
import { WindWallEnvelopeSectionModel } from 'src/app/models/formly-builder/sections/wind/wind-wall-envelope-section.model';
import { WindFrameworkSectionModel } from 'src/app/models/formly-builder/sections/wind/wind-framework-section.model';
import { WindLossSectionModel } from 'src/app/models/formly-builder/sections/wind/wind-loss-section.model';
//sprinkler models
import { ScoreCardSectionModel } from 'src/app/models/formly-builder/sections/full-risk/sprinkler/score-card.model';
import { SystemComponentSectionModel } from 'src/app/models/formly-builder/sections/full-risk/sprinkler/system-component.model';
import { WaterSupplySectionModel } from 'src/app/models/formly-builder/sections/full-risk/sprinkler/water-supply.model';
import { SystemTestingSectionModel } from 'src/app/models/formly-builder/sections/full-risk/sprinkler/system-testing.model';
import { NonSpinkleredAreasSectionModel } from 'src/app/models/formly-builder/sections/full-risk/sprinkler/non-sprinklered-areas.model';
import { BuildingConditionSectionModel } from 'src/app/models/formly-builder/sections/full-risk/sprinkler/building-condition.model';
import { BuildingConditionService } from './full-risk/sprinkler/building-condition.service';
import { NonSprinkleredAreasService } from './full-risk/sprinkler/non-sprinklered-areas.service';
import { SystemTestingService } from './full-risk/sprinkler/system-testing.service';
import { SystemComponentService } from './full-risk/sprinkler/system-component.service';
import { WaterSupplyService } from './full-risk/sprinkler/water-supply.service';
import { ScoreCardService } from './full-risk/sprinkler/score-card.service';
import { FormlyInternalProtectionService } from './full-risk/formly-internal-protection.service';
import { SprinklerReportService } from './full-risk/sprinkler/sprinkler-report.service';
import {
  FloorsAndRoofsBuildingInformation
} from 'src/app/models/formly-builder/sections/full-risk/floors-roofs/floors-and-roofs-building-information.model';
import {
  FloorsAndRoofsConstructionByLevel
} from 'src/app/models/formly-builder/sections/full-risk/floors-roofs/floors-and-roofs-construction-by-level.model';
import { FloorsAndRoofsBgiiData } from 'src/app/models/formly-builder/sections/full-risk/floors-roofs/floors-and-roofs-bgii-data.model';
import { BuildingHazardsService } from './full-risk/occupancy-hazards/building-hazards.service';
import { BuildingHazardsSectionModel } from 'src/app/models/formly-builder/sections/full-risk/occupancy-hazards/building-hazards.model';
import { OccupantsHazardsSectionModel } from 'src/app/models/formly-builder/sections/full-risk/occupancy-hazards/occupant-hazards.model';
import { WallsChargeableColumnModel } from 'src/app/models/formly-builder/sections/full-risk/walls/chargeable-column.model';
import { WallsChargeableColumnService } from './full-risk/formly-walls-chargeable-column.service';
import { WallsPanelsModel } from 'src/app/models/formly-builder/sections/full-risk/walls/walls-panels.model';
import { WallsChargeablePanelsService } from './full-risk/formly-walls-chargeable-panels.service';
import { FullRiskFloorsRoofsService } from './full-risk/floors-and-roofs/full-risk-floors-roofs.service';
import { ConstructionByLevelService } from './full-risk/floors-and-roofs/construction-by-level.service';
import { ExposuresService } from './full-risk/exposures/exposures.service';
import { ExposuresSectionModel } from 'src/app/models/formly-builder/sections/full-risk/exposures/exposures-section.model';
import { FloorsRoofBuildingInformationService } from './full-risk/floors-and-roofs/floors-roofs-building-information.service';
import { OccupantHazardsService } from './full-risk/occupancy-hazards/occupant-hazards.service';
import { GreenConstructionModel } from 'src/app/models/formly-builder/sections/full-risk/green-construction.model';
import { MiscellaneousSecondaryConstructionModel } from 'src/app/models/formly-builder/sections/full-risk/miscellaneous-secondary-construction.model';
import { OccupantFullriskService } from './full-risk/occupancy-hazards/occupant-fullrisk.service';
import { SecondaryConstructionsService } from './secondary-constructions.service';
import { WindReportService } from './wind/wind-report.service';
import { FloorsAndRoofsLightFrameConstructionData } from 'src/app/models/formly-builder/sections/full-risk/floors-roofs/floors-and-roofs-light-frameconstruction.model';
import { floorsRoofLightFrameConstructionService } from './full-risk/floors-and-roofs/floors-roof-light-frame-construction.service';
import { SystemDesignSectionModel } from 'src/app/models/formly-builder/sections/full-risk/sprinkler/hydraulic-system-design.model';
import { floorsRoofBg2Service } from './full-risk/floors-and-roofs/floors-roof-bg2.service';
import { EffectiveAreaModel } from 'src/app/models/formly-builder/sections/full-risk/effective-area.model';
import { CombustibleFinishModel } from 'src/app/models/formly-builder/sections/full-risk/combustible-finish.model';
import { BuildingCodesSectionModel } from 'src/app/models/formly-builder/sections/overview/building-codes-section.model';
import { BuildingCodesService } from './overview/building-codes.service';
import { SurveyDetailsModel } from 'src/app/models/formly-builder/sections/survey-details.model';
import { FormlySurveyDetailsService } from './formly-survey-details.service';
import { EnhancedWindEligibleSectionModel } from 'src/app/models/formly-builder/sections/overview/enhanced-wind-eligible-section.model';
import { EnhancedWindEligibleService } from './overview/enhanced-wind-eligible.service';


/**
 * List of sections
 */
export enum SectionEnum {
  // +-------------------------------------------+
  // | C & C                                     |
  // +-------------------------------------------+
  ADDRESS,
  OCCUPANTS,
  WALLS,
  FLOORS_ROOF,
  GENERAL_INFO,
  BUILDING,
  BUILDING_BG,
  YEAR_BUILT,
  FIRE_SPRINKLER,
  FIRE_HYDRANT,
  CONSTRUCTION_VERIFICATION,                 // 10
  PHOTOS,
  ROOF_TOPS,
  SURVEY_DETAILS,
  BUILDING_INFORMATION,
  ROOF_ENVELOPE,

  // +-------------------------------------------+
  // | Risk Form                                 |
  // +-------------------------------------------+

  // Overview Main Section
  FR_OVERVIEW,                              // 16
  FR_OW_PPC,
  FR_CONTACTS,
  FR_BUILDING,
  FR_FIRE_SPRINKLER,                        // 20
  FR_PHOTOS,
  FR_BUILDING_CODES,

  // Walls Main Section
  FR_WALLS,                                 // 23
  FR_WALLS_BGII,
  FR_WALLS_EDIT,
  FR_WALLS_PANELS,
  FR_WALLS_CHARGEABLE,

  // Floors
  FR_FLOORS_ROOFS,                          // 28
  FR_FLOORS_ROOFS_BUILDING_INFORMATION,
  FR_FLOORS_ROOFS_CONSTRUCTION_BY_LEVEL,
  FR_FLOORS_ROOFS_BGII_DATA,
  FR_FLOORS_ROOFS_LIGHT_FRAME_CONSTRUCTION,

  // Occupants
  FR_OCCUPANTS_HAZARDS,                     // 33
  FR_OCCUPANTS_BUILDING_HAZARDS,
  FR_OCCUPANTS_AND_HAZARDS,

  // Secondary Construction
  FR_SECONDARY_CONSTRUCTION,                // 36
  FR_COMBUSTIBLE_CONSTRUCTION,
  FR_GREEN_CONSTRUCTION,
  FR_MISCELLANEOUS_SECONDARY_CONSTRUCTION,
  FR_EFFECTIVE_AREA,
  FR_COMBUSTIBLE_FINISH,

  // Exposures
  FR_EXPOSURES,                             // 42
  FR_EXPOSURES_EDIT,

  // Internal Protection
  FR_INTERNAL_PROTECTION,                   // 44
  FR_INTERNAL_PROTECTION_DETAILS,

  // Comments & Attachments
  FR_COMMENT_ATTACHMENT,                    // 46

  // +-------------------------------------------+
  // | Sprinkler Report                          |
  // +-------------------------------------------+
  FR_SPRINKLERS,                            // 47
  FR_S_SCORE,
  FR_S_WATER_SUPPLY,                        // 49

  // SubSection Water Supply
  FR_S_WS_WATERSUPPLY,                      // 50
  FR_SR_WS_EVALUATION,
  FR_SR_WS_SUPPLY_SOURCE,
  FR_SR_WS_FIRE_PUMP,
  FR_SR_WS_SECONDARY_SUPPLY,

  // Subsection Hydraulic
  FR_S_WS_HYDRAULIC,                        // 55
  FR_S_WS_HY_OCCUPANCY_CLASS,
  FR_S_WS_HY_SYSTEM_DESIGN,                 // 57
  FR_S_WS_HY_DENSITY_CONVERSION,
  FR_S_WS_DESIGNDATA,

  // Subsection Pipe Schedule
  FR_S_WS_PIPE_SCHEDULE,                    // 60
  FR_S_WS_PIPE_SCHEDULE_SPOC,

  // Subsection Friction Loss
  FR_S_WS_FRICTION_LOSS,

  // Remaining Sprinkler sections
  FR_S_SYSTEM_COMPONENT,                    // 63
  FR_SR_SYSTEM_TESTING,
  FR_SR_ST_TWO_INCH_DRAIN_TEST,
  FR_SR_ST_OVER_AGE_SPRINKLER_TEST,
  FR_SR_ST_INTERNAL_PIPE_CONDITION,
  FR_SR_ST_DRY_PIPE_VALVE_TRIP_TEST,
  FR_S_NON_SPRINKLERED_AREAS,
  FR_S_BUILDING_CONDITION,
  FR_SR_SPRINKLERS,

  // +-------------------------------------------+
  // | Wind Report Main Sections                 |
  // +-------------------------------------------+
  FR_WIND,                                  // 72
  FR_W_ENHANCED_WIND,
  FR_W_ENV_EXPOSURES,
  FR_W_ROOF_ENVELOPE,
  FR_W_WALL_ENVELOPE,
  FR_W_FRAMEWORK,
  FR_W_WIND_LOSS,

  FR_ENHANCED_WIND_ELIGIBLE,
}

// Mapping between backend errors and landing pages / sections to navigate to
export const BackendErrorSection = [
  { errorId: 'RSKOVR', report: SectionEnum.FR_OVERVIEW, section: SectionEnum.FR_OVERVIEW },
  { errorId: 'SOVER', report: SectionEnum.FR_OVERVIEW, section: SectionEnum.FR_OVERVIEW },                        // SPRINKLER PROBLEMS, OVERVIEW RELATED
  { errorId: 'FLRROF', report: SectionEnum.FR_FLOORS_ROOFS, section: SectionEnum.FR_FLOORS_ROOFS },
  { errorId: 'RSKWLL', report: SectionEnum.FR_WALLS, section: SectionEnum.FR_WALLS },
  { errorId: 'RSKOHS', report: SectionEnum.FR_OCCUPANTS_HAZARDS, section: SectionEnum.FR_OCCUPANTS_HAZARDS },
  { errorId: 'SPR13R', report: SectionEnum.FR_OCCUPANTS_HAZARDS, section: SectionEnum.FR_OCCUPANTS_HAZARDS },     // SPRINKLER PROBLEMS, OCCUPANTS RELATED
  { errorId: 'RSKSEC', report: SectionEnum.FR_SECONDARY_CONSTRUCTION, section: SectionEnum.FR_SECONDARY_CONSTRUCTION },
  { errorId: 'RSKEXP', report: SectionEnum.FR_EXPOSURES, section: SectionEnum.FR_EXPOSURES },
  { errorId: 'RSKINT', report: SectionEnum.FR_INTERNAL_PROTECTION, section: SectionEnum.FR_INTERNAL_PROTECTION },
  { errorId: 'RSKCMT', report: SectionEnum.FR_COMMENT_ATTACHMENT, section: SectionEnum.FR_COMMENT_ATTACHMENT },


  { errorId: 'SWOVR', report: SectionEnum.FR_SPRINKLERS, section: SectionEnum.FR_S_WS_WATERSUPPLY },
  { errorId: 'SWHYD', report: SectionEnum.FR_SPRINKLERS, section: SectionEnum.FR_S_WS_HYDRAULIC },
  { errorId: 'SWADT', report: SectionEnum.FR_SPRINKLERS, section: SectionEnum.FR_S_WS_FRICTION_LOSS },
  { errorId: 'SWPIP', report: SectionEnum.FR_SPRINKLERS, section: SectionEnum.FR_S_WS_PIPE_SCHEDULE },
  { errorId: 'SSPOC', report: SectionEnum.FR_SPRINKLERS, section: SectionEnum.FR_S_WS_WATERSUPPLY },

  { errorId: 'SSTST', report: SectionEnum.FR_SPRINKLERS, section: SectionEnum.FR_SR_SYSTEM_TESTING },
  { errorId: 'SCOMP', report: SectionEnum.FR_SPRINKLERS, section: SectionEnum.FR_S_SYSTEM_COMPONENT },
  { errorId: 'SBUIL', report: SectionEnum.FR_SPRINKLERS, section: SectionEnum.FR_S_BUILDING_CONDITION },
  { errorId: 'SNONS', report: SectionEnum.FR_SPRINKLERS, section: SectionEnum.FR_S_NON_SPRINKLERED_AREAS },

  { errorId: 'ENHELG', report: SectionEnum.FR_WIND, section: SectionEnum.FR_W_ENHANCED_WIND },
  { errorId: 'RFENVP', report: SectionEnum.FR_WIND, section: SectionEnum.FR_W_ROOF_ENVELOPE },
  { errorId: 'ENVEXP', report: SectionEnum.FR_WIND, section: SectionEnum.FR_W_ENV_EXPOSURES },
  { errorId: 'FRWORK', report: SectionEnum.FR_WIND, section: SectionEnum.FR_W_FRAMEWORK },
  { errorId: 'WLENVP', report: SectionEnum.FR_WIND, section: SectionEnum.FR_W_WALL_ENVELOPE },
];

/**
 * Sections enabled for Currency with config values
 */
export const SectionConfig = new Map<SectionEnum, SectionConfigInterface>([
  // Sections
  [
    SectionEnum.ADDRESS,
    {
      enum: SectionEnum.ADDRESS,
      name: SectionEnum.ADDRESS.toString(),
      property: 'ReportAddresses',
      enabled: true,
      service: FormlyAddressService,
      model: AddressSectionModel,
      order: [ 1, 1, 0 ]
    },
  ],
  [
    SectionEnum.OCCUPANTS,
    {
      enum: SectionEnum.OCCUPANTS,
      name: SectionEnum.OCCUPANTS.toString(),
      property: 'Occupants',
      enabled: true,
      service: FormlyOccupantsService,
      model: OccupantsSectionModel,
      order: [ 7, 7, 0 ]
    },
  ],
  [
    SectionEnum.WALLS,
    {
      enum: SectionEnum.WALLS,
      name: SectionEnum.WALLS.toString(),
      property: 'Walls',
      enabled: true,
      service: FormlyWallsService,
      model: WallsSectionModel,
      order: [ 3, 5, 0 ]
    },
  ],
  [
    SectionEnum.FLOORS_ROOF,
    {
      enum: SectionEnum.FLOORS_ROOF,
      name: SectionEnum.FLOORS_ROOF.toString(),
      property: 'FloorsAndRoofs',
      enabled: true,
      service: FormlyFloorsRoofService,
      model: FloorRoofSection,
      order: [ 4, 6, 0 ]
    },
  ],
  [
    SectionEnum.GENERAL_INFO,
    {
      enum: SectionEnum.GENERAL_INFO,
      name: SectionEnum.GENERAL_INFO.toString(),
      property: '',         // Empty property: pass the complete RiskReport
      enabled: true,
      service: GeneralInformationService,
      model: GeneralInformationModel,
      order: [ 2, 2, 0 ]
    },
  ],
  [
    SectionEnum.CONSTRUCTION_VERIFICATION,
    {
      enum: SectionEnum.CONSTRUCTION_VERIFICATION,
      name: SectionEnum.CONSTRUCTION_VERIFICATION.toString(),
      property: '',         // Empty property: pass the complete RiskReport
      enabled: true,
      service: SectionConstVerifService,
      model: ConstructionVerificationSectionModel,
      order: [ 5, 0, 0 ]
    },
  ],
  [
    SectionEnum.PHOTOS,
    {
      enum: SectionEnum.PHOTOS,
      name: SectionEnum.PHOTOS.toString(),
      property: 'ReportPhotos',
      enabled: true,
      service: SectionPhotosService,
      model: PhotosSectionModel,
      order: [ 8, 8, 0 ]
    },
  ],
  [
    SectionEnum.BUILDING_BG,
    {
      enum: SectionEnum.BUILDING_BG,
      name: SectionEnum.BUILDING_BG.toString(),
      property: '',         // Empty property: pass the complete RiskReport
      enabled: true,
      service: FormlyBuildingBGService,
      model: BuildingBGSectionModel,
      order: [ 6, 0, 0 ]
    },
  ],
  [
    SectionEnum.ROOF_TOPS,
    {
      enum: SectionEnum.ROOF_TOPS,
      name: SectionEnum.ROOF_TOPS.toString(),
      property: 'RooftopLocationPoint',
      enabled: true,
      service: SectionRoofTopService,
      model: RooftopSectionModel,
      order: [ 9, 9, 0 ]
    },
  ],
  // Main Sections follow
  [
    SectionEnum.FR_OVERVIEW,
    {
      enum: SectionEnum.FR_OVERVIEW,
      name: 'Overview',
      property: '',
      enabled: true,
      // service: FormlyOverviewService,
      // model: OverviewSectionModel,
      order: [ 0, 0, 1 ],
      children: [
        {
          enum: SectionEnum.ADDRESS,
          name: SectionEnum.ADDRESS.toString(),
          property: 'ReportAddresses',
          enabled: true,
          service: FormlyAddressService,
          model: AddressSectionModel
        },
        {
          enum: SectionEnum.ROOF_TOPS,
          name: SectionEnum.ROOF_TOPS.toString(),
          property: 'RooftopLocationPoint',
          enabled: true,
          service: SectionRoofTopService,
          model: RooftopSectionModel
        },
        {
          enum: SectionEnum.FR_PHOTOS,
          name: SectionEnum.FR_PHOTOS.toString(),
          property: 'ReportPhotos',
          enabled: true,
          service: SectionPhotosService,
          model: FullRiskPhotosSectionModel,
        },
        {
          enum: SectionEnum.FR_OW_PPC,
          name: SectionEnum.FR_OW_PPC.toString(),
          property: '',
          enabled: true,
          service: PublicProtectionService,    // Replace with proper service that reads needed data
          model: PublicProtectionClassSectionModel
        },
        {
          enum: SectionEnum.FR_CONTACTS,
          name: SectionEnum.FR_CONTACTS.toString(),
          property: '',
          enabled: true,
          service: ContactInformationService,
          model: ContactInformationModel
        },
        {
          enum: SectionEnum.SURVEY_DETAILS,
          name: SectionEnum.SURVEY_DETAILS.toString(),
          property: '',
          enabled: true,
          service: FormlySurveyDetailsService,
          model: SurveyDetailsModel
        },
        {
          enum: SectionEnum.FR_BUILDING,
          name: SectionEnum.FR_BUILDING.toString(),
          property: '',
          enabled: true,
          service: FormlyBuildingInformationService,
          model: BuildingNameSectionModel
        },
        {
          enum: SectionEnum.FR_FIRE_SPRINKLER,
          name: SectionEnum.FR_FIRE_SPRINKLER.toString(),
          property: '',
          enabled: true,
          service: FireSprinklerService,
          model: FireSprinklerSectionModel
        },
        {
          enum: SectionEnum.FR_ENHANCED_WIND_ELIGIBLE,
          name: SectionEnum.FR_ENHANCED_WIND_ELIGIBLE.toString(),
          property: '',
          enabled: true,
          service: EnhancedWindEligibleService,
          model: EnhancedWindEligibleSectionModel
        },
        {
          enum: SectionEnum.FR_BUILDING_CODES,
          name: SectionEnum.FR_BUILDING_CODES.toString(),
          property: '',
          enabled: true,
          service: BuildingCodesService,
          model: BuildingCodesSectionModel,
        }
      ]
    },
  ],
  [
    SectionEnum.FR_WALLS,
    {
      enum: SectionEnum.FR_WALLS,
      name: 'Walls',
      property: '',
      enabled: true,
      order: [ 0, 0, 2 ],
      children: [
        {
          enum: SectionEnum.FR_WALLS_BGII,
          name: SectionEnum.FR_WALLS_BGII.toString(),
          property: 'Walls',
          enabled: true,
          service: FormlyWallsBGService,
          model: WallsBGIIModel,
        },
        {
          enum: SectionEnum.FR_WALLS_EDIT,
          name: SectionEnum.FR_WALLS_EDIT.toString(),
          property: 'Walls',
          enabled: true,
          service: FormlyWallsConstructionService,
          model: WallsConstructionModel,
        },
        {
          enum: SectionEnum.FR_WALLS_CHARGEABLE,
          name: SectionEnum.FR_WALLS_CHARGEABLE.toString(),
          property: 'Walls',
          enabled: true,
          service: WallsChargeableColumnService,
          model: WallsChargeableColumnModel,
        },
        {
          enum: SectionEnum.FR_WALLS_PANELS,
          name: SectionEnum.FR_WALLS_PANELS.toString(),
          property: 'Walls',
          enabled: true,
          service: WallsChargeablePanelsService,
          model: WallsPanelsModel,
        },
      ]
    },
  ],
  [
    SectionEnum.FR_FLOORS_ROOFS,
    {
      enum: SectionEnum.FR_FLOORS_ROOFS,
      name: 'Floors & Roof',
      property: 'floorsAndRoofs',
      enabled: true,
      service: FullRiskFloorsRoofsService,   // FormlyFloorsRoofService,
      // model: FloorAndRoofSectionModel,
      order: [ 0, 0, 3 ],
      children: [
        {
          enum: SectionEnum.FR_FLOORS_ROOFS_BUILDING_INFORMATION,
          name: SectionEnum.FR_FLOORS_ROOFS_BUILDING_INFORMATION.toString(),
          property: 'FloorsAndRoofs',
          enabled: true,
          service: FloorsRoofBuildingInformationService,
          model: FloorsAndRoofsBuildingInformation
        },
        {
          enum: SectionEnum.FR_FLOORS_ROOFS_CONSTRUCTION_BY_LEVEL,
          name: SectionEnum.FR_FLOORS_ROOFS_CONSTRUCTION_BY_LEVEL.toString(),
          property: 'FloorsAndRoofs',
          enabled: true,
          service: ConstructionByLevelService,
          model: FloorsAndRoofsConstructionByLevel
        },
        {
          enum: SectionEnum.FR_FLOORS_ROOFS_BGII_DATA,
          name: SectionEnum.FR_FLOORS_ROOFS_BGII_DATA.toString(),
          property: 'FloorsAndRoofs',
          enabled: true,
          service: floorsRoofBg2Service,
          model: FloorsAndRoofsBgiiData
        },
        {
          enum: SectionEnum.FR_FLOORS_ROOFS_LIGHT_FRAME_CONSTRUCTION,
          name: SectionEnum.FR_FLOORS_ROOFS_LIGHT_FRAME_CONSTRUCTION.toString(),
          property: 'FloorsAndRoofs',
          enabled: true,
          service: floorsRoofLightFrameConstructionService,
          model: FloorsAndRoofsLightFrameConstructionData
        },
      ]
    },
  ],
  [
    SectionEnum.FR_OCCUPANTS_HAZARDS,
    {
      enum: SectionEnum.FR_OCCUPANTS_HAZARDS,
      name: 'Occupants & Hazards',
      property: 'occupants',
      enabled: true,
      service: OccupantFullriskService,
      order: [ 0, 0, 4 ],
      children: [
        {
          enum: SectionEnum.FR_OCCUPANTS_AND_HAZARDS,
          name: SectionEnum.FR_OCCUPANTS_AND_HAZARDS.toString(),
          property: '',
          enabled: true,
          service: OccupantHazardsService, //here updatee data methodd... parent service will handle load data
          model: OccupantsHazardsSectionModel,
        },
        {
          enum: SectionEnum.FR_OCCUPANTS_BUILDING_HAZARDS,
          name: SectionEnum.FR_OCCUPANTS_BUILDING_HAZARDS.toString(),
          property: '',
          enabled: true,
          service: BuildingHazardsService,
          model: BuildingHazardsSectionModel
        },
      ]
    },
  ],
  [
    SectionEnum.FR_SECONDARY_CONSTRUCTION,
    {
      enum: SectionEnum.FR_SECONDARY_CONSTRUCTION,
      name: 'Secondary Construction',
      property: 'secondaryConstructions',
      enabled: true,
      service: SecondaryConstructionsService,
      order: [ 0, 0, 5 ],
      children: [
        {
          enum: SectionEnum.FR_EFFECTIVE_AREA,
          name: SectionEnum.FR_EFFECTIVE_AREA.toString(),
          property: '',
          enabled: true,
          model: EffectiveAreaModel,
        },
        {
          enum: SectionEnum.FR_COMBUSTIBLE_CONSTRUCTION,
          name: SectionEnum.FR_COMBUSTIBLE_CONSTRUCTION.toString(),
          property: '',
          enabled: true,
          model: CombustibleConstructionModel,
        },
        {
          enum: SectionEnum.FR_COMBUSTIBLE_FINISH,
          name: SectionEnum.FR_COMBUSTIBLE_FINISH.toString(),
          property: '',
          enabled: true,
          model: CombustibleFinishModel,
        },
        {
          enum: SectionEnum.FR_MISCELLANEOUS_SECONDARY_CONSTRUCTION,
          name: SectionEnum.FR_MISCELLANEOUS_SECONDARY_CONSTRUCTION.toString(),
          property: '',
          enabled: true,
          model: MiscellaneousSecondaryConstructionModel,
        },
        {
          enum: SectionEnum.FR_GREEN_CONSTRUCTION,
          name: SectionEnum.FR_GREEN_CONSTRUCTION.toString(),
          property: '',
          enabled: true,
          model: GreenConstructionModel,
        }
      ]
    },
  ],
  [
    SectionEnum.FR_EXPOSURES,
    {
      enum: SectionEnum.FR_EXPOSURES,
      name: 'Exposures',
      property: '',
      enabled: true,
      order: [ 0, 0, 6 ],
      children: [
        {
          enum: SectionEnum.FR_EXPOSURES_EDIT,
          name: SectionEnum.FR_EXPOSURES_EDIT.toString(),
          property: 'Exposures',
          enabled: true,
          service: ExposuresService,
          model: ExposuresSectionModel,
        },
      ]
    },
  ],
  [
    SectionEnum.FR_INTERNAL_PROTECTION,
    {
      enum: SectionEnum.FR_INTERNAL_PROTECTION,
      name: 'Internal Protection',
      property: '',
      enabled: true,
      // service:FormlyInternalProtectionService,
      // model: InternalProtectionSectionModel,
      order: [ 0, 0, 7 ],
      children: [
        {
          enum: SectionEnum.FR_INTERNAL_PROTECTION_DETAILS,
          name: SectionEnum.FR_INTERNAL_PROTECTION_DETAILS.toString(),
          property: '',
          enabled: true,
          service: FormlyInternalProtectionService,
          model: InternalProtectionSectionModel,
          order: [ 1, 1, 0 ]
        },
      ]
    }
  ],
  [
    SectionEnum.FR_COMMENT_ATTACHMENT,
    {
      enum: SectionEnum.FR_COMMENT_ATTACHMENT,
      name: 'Comments & Attachments',
      property: '',
      enabled: true,
      service: FormlyCommentService,
      model: CommentAndAttachmentSectionModel,
      order: [ 0, 0, 8 ]
    },
  ],
  [
    SectionEnum.FR_SPRINKLERS,
    {
      enum: SectionEnum.FR_SPRINKLERS,
      name: 'Sprinkler Report',
      property: 'sprinklerReport',
      enabled: true,
      service: SprinklerReportService,
      order: [ 0, 0, 9 ],
      children: [
        {
          enum: SectionEnum.FR_S_SCORE,
          name: SectionEnum.FR_S_SCORE.toString(),
          property: '',
          enabled: true,
          service: ScoreCardService,
          model: ScoreCardSectionModel
        },
        {
          enum: SectionEnum.FR_S_WATER_SUPPLY,
          name: SectionEnum.FR_S_WATER_SUPPLY.toString(),
          property: '',
          enabled: true,
          service: WaterSupplyService,
          model: WaterSupplySectionModel
        },
        {
          enum: SectionEnum.FR_S_SYSTEM_COMPONENT,
          name: SectionEnum.FR_S_SYSTEM_COMPONENT.toString(),
          property: '',
          enabled: true,
          service: SystemComponentService,
          model: SystemComponentSectionModel
        },
        {
          enum: SectionEnum.FR_SR_SYSTEM_TESTING,
          name: SectionEnum.FR_SR_SYSTEM_TESTING.toString(),
          property: '',
          enabled: true,
          service: SystemTestingService,
          model: SystemTestingSectionModel
        },
        {
          enum: SectionEnum.FR_S_NON_SPRINKLERED_AREAS,
          name: SectionEnum.FR_S_NON_SPRINKLERED_AREAS.toString(),
          property: '',
          enabled: true,
          service: NonSprinkleredAreasService,
          model: NonSpinkleredAreasSectionModel
        },
        {
          enum: SectionEnum.FR_S_BUILDING_CONDITION,
          name: SectionEnum.FR_S_BUILDING_CONDITION.toString(),
          property: '',
          enabled: true,
          service: BuildingConditionService,
          model: BuildingConditionSectionModel
        },
      ]
    },
  ],
  [
    SectionEnum.FR_WIND,
    {
      enum: SectionEnum.FR_WIND,
      name: 'Wind Report',
      property: 'windReport',
      enabled: true,
      service: WindReportService,
      order: [ 0, 0, 10 ],
      children: [
        {
          enum: SectionEnum.FR_W_ENHANCED_WIND,
          name: SectionEnum.FR_W_ENHANCED_WIND.toString(),
          property: '',
          enabled: true,
          service: EnhancedWindService,
          model: EnhancedWindSectionModel
          // model: EnhancedWindSectionModelSudharsan
        },
        {
          enum: SectionEnum.FR_W_ENV_EXPOSURES,
          name: SectionEnum.FR_W_ENV_EXPOSURES.toString(),
          property: '',
          enabled: true,
          service: WindEnvironmentExposuresService,
          model: WindEnvironmentExposuresSectionModel
        },
        {
          enum: SectionEnum.FR_W_ROOF_ENVELOPE,
          name: SectionEnum.FR_W_ROOF_ENVELOPE.toString(),
          property: '',
          enabled: true,
          service: WindRoofEnvelopeService,
          model: WindRoofEnvelopeSectionModel
          // model: RoofEnvelopeSectionModel
        },
        {
          enum: SectionEnum.FR_W_WALL_ENVELOPE,
          name: SectionEnum.FR_W_WALL_ENVELOPE.toString(),
          property: '',
          enabled: true,
          service: WindWallEnvelopeService,
          model: WindWallEnvelopeSectionModel
        },
        {
          enum: SectionEnum.FR_W_FRAMEWORK,
          name: SectionEnum.FR_W_FRAMEWORK.toString(),
          property: '',
          enabled: true,
          service: WindFrameworkService,
          model: WindFrameworkSectionModel
        },
        {
          enum: SectionEnum.FR_W_WIND_LOSS,
          name: SectionEnum.FR_W_WIND_LOSS.toString(),
          property: '',
          enabled: true,
          service: WindLossService,
          model: WindLossSectionModel
        },
      ]
    },
  ],
]);
