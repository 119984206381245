/* eslint-disable @typescript-eslint/naming-convention */
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { SystemComponentSprinklersDataModel } from './system-component-sprinklers-data.model';

export class SystemComponentDataModel {
  static sectionName = 'systemComponent';

  reportId: string;
  percentAreaDryPipe: number;
  hasNonStandardTankConditions: boolean;
  overheadPiping: OverheadPiping;
  sprinklerComponents: Sprinklercomponents;
  miscItemsLayout: MiscItemsLayout;
  dryPipeValve: DryPipeValve;
  automaticSprinklers: AutomaticSprinkler;


  static fromS3(systemComponentsData: any, sprinklerReport: any): SystemComponentDataModel {

    const overheaddPiping = SystemComponentDataModel.setOverheadPiping(systemComponentsData);
    const sprinklerComponents = SystemComponentDataModel.setSprinklercomponents(systemComponentsData);
    const miscItemLayout = SystemComponentDataModel.setMiscItemsLayout(systemComponentsData);
    const sprinklersTable = SystemComponentSprinklersDataModel.fromS3(sprinklerReport.Sprinklers);
    const dryPipeValve = SystemComponentDataModel.setDryPipeValve(systemComponentsData);
    const automaticSprinklersData = SystemComponentDataModel.setAutomaticSprinklers(systemComponentsData);

    return {
      reportId: systemComponentsData?.ReportId,
      percentAreaDryPipe: systemComponentsData?.PercentAreaDryPipe,
      hasNonStandardTankConditions: systemComponentsData?.HasNonStandardTankConditions,
      overheadPiping: overheaddPiping,
      sprinklerComponents,
      miscItemsLayout: miscItemLayout,
      automaticSprinklers: { sprinklers: sprinklersTable, ...automaticSprinklersData },
      dryPipeValve
    };
  }

  static to(model: any, sectionEnum?: SectionEnum) {
    if (
      !model?.overheadPiping?.nonStandardPipeScheduleInstallType ||
      model?.overheadPiping?.nonStandardPipeScheduleInstallType === true
    ) {
      model.overheadPiping.nonStandardPipeScheduleInstallType = 'N/A';
    }
    if (!model?.dryPipeValve?.dpvType) {
      model.dryPipeValve.dpvType = 'N/A';
      model.dryPipeValve.isSupervisedDpv = null;
      model.dryPipeValve.isSupervisedDpvWithoutQod = null;
    }
    return {
      ReportId: model.reportId,
      IsUnlistedMeter: model?.miscItemsLayout?.isUnlistedMeter ? model?.miscItemsLayout?.isUnlistedMeter : false,
      IsNonStandardPrivateMain: model?.miscItemsLayout?.isNonStandardPrivateMain ? model?.miscItemsLayout?.isNonStandardPrivateMain : false,
      IsNotReadilyAccessibleSupplyControl: model?.sprinklerComponents?.isNotReadilyAccessibleSupplyControl ?
        model?.sprinklerComponents?.isNotReadilyAccessibleSupplyControl : false,
      IsNonStandardPipeSize: model?.overheadPiping.isNonStandardPipeSize ? model?.overheadPiping.isNonStandardPipeSize : false,
      AffectedAreaPipeSize: model?.overheadPiping.affectedAreaPipeSize ? Number(model?.overheadPiping.affectedAreaPipeSize) : null,
      // nonStandardPipeScheduleInstallType default value for this one is null, In UI we need to display as 'N/A'
      NonStandardPipeScheduleInstallType: model?.overheadPiping.nonStandardPipeScheduleInstallType
        && model?.overheadPiping.nonStandardPipeScheduleInstallType !== 'N/A' ?
        model?.overheadPiping.nonStandardPipeScheduleInstallType : null,
      IsNonStandardSprinklerSpacing: model?.overheadPiping.isNonStandardSprinklerSpacing ?
        model?.overheadPiping.isNonStandardSprinklerSpacing : false,
      AffectedAreaSpacing: model?.overheadPiping.affectedAreaSpacing ? Number(model?.overheadPiping.affectedAreaSpacing) : null,
      IsImproperUseOfHighLowTempSprinklers: model?.automaticSprinklers?.isImproperUseOfHighLowTempSprinklers ?
        model?.automaticSprinklers?.isImproperUseOfHighLowTempSprinklers : false,
      IsOverAgeSprinkler: model?.automaticSprinklers?.overageSprinkler?.isOverAgeSprinkler ?
        model?.automaticSprinklers?.overageSprinkler?.isOverAgeSprinkler : false,
      IsCorrodedSprinkler: model?.automaticSprinklers?.corrodedSprinkler?.isCorrodedSprinkler ?
        model?.automaticSprinklers?.corrodedSprinkler?.isCorrodedSprinkler : false,
      IsPaintedSprinkler: model?.automaticSprinklers?.paintedSprinkler?.isPaintedSprinkler ?
        model?.automaticSprinklers?.paintedSprinkler?.isPaintedSprinkler : false,
      IsOtherUnacceptableOrNonStandardSprinkler:
        model?.automaticSprinklers?.nonStandardSprinkler?.isOtherUnacceptableOrNonStandardSprinkler ?
          model?.automaticSprinklers?.nonStandardSprinkler?.isOtherUnacceptableOrNonStandardSprinkler : false,
      OverAgeSprinklerArea: model?.automaticSprinklers.overageSprinkler?.overageSprinklerArea ?
        Number(model?.automaticSprinklers.overageSprinkler.overageSprinklerArea) : null,
      CorrodedSprinklerArea: model?.automaticSprinklers.corrodedSprinkler.corrodedSprinklerArea ?
        Number(model?.automaticSprinklers.corrodedSprinkler.corrodedSprinklerArea) : null,
      PaintedSprinklerArea: model?.automaticSprinklers.paintedSprinkler.paintedSprinklerArea ?
        Number(model?.automaticSprinklers.paintedSprinkler.paintedSprinklerArea) : null,
      OtherUnacceptableOrNonStandardSprinklerArea:
        model?.automaticSprinklers?.nonStandardSprinkler?.otherUnacceptableOrNonStandardSprinklerArea ?
          Number(model?.automaticSprinklers?.nonStandardSprinkler?.otherUnacceptableOrNonStandardSprinklerArea) : null,
      PercentAreaDryPipe: model?.percentAreaDryPipe || model?.percentAreaDryPipe === 0 ? model?.percentAreaDryPipe : null,
      AreaProtectedByDryPipeSystem: model?.dryPipeValve.areaProtectedByDryPipeSystem ?
        Number(model?.dryPipeValve.areaProtectedByDryPipeSystem) : null,
      // default value for this one is null, In UI we need to display as 'N/A'
      DpvType: model?.dryPipeValve?.dpvType && model?.dryPipeValve?.dpvType !== 'N/A'
        ? model?.dryPipeValve?.dpvType : null,
      IsSupervisedDpv: model?.dryPipeValve?.isSupervisedDpv !== null && model?.dryPipeValve?.isSupervisedDpv !== undefined ? model?.dryPipeValve?.isSupervisedDpv : null,
      IsSupervisedDpvWithoutQod: model?.dryPipeValve?.isSupervisedDpvWithoutQod ? model?.dryPipeValve?.isSupervisedDpvWithoutQod : false,
      UnacceptableDpvArea: model?.dryPipeValve?.unacceptableDpvArea ? Number(model?.dryPipeValve.unacceptableDpvArea) : null,
      LacksFdConnection: model?.sprinklerComponents?.lacksFdConnection ? model?.sprinklerComponents?.lacksFdConnection : false,
      LacksLocalAlarm: model?.sprinklerComponents?.lacksLocalAlarm ? model?.sprinklerComponents?.lacksLocalAlarm : false,
      IsAbsentIndicatingValve: model?.sprinklerComponents?.isAbsentIndicatingValve ?
        model?.sprinklerComponents?.isAbsentIndicatingValve : false,
      AbsentIndicatingValveArea: model?.sprinklerComponents?.absentIndicatingValveArea ? Number(model?.sprinklerComponents.absentIndicatingValveArea): null,
      HasControlValveInNsArea: model?.miscItemsLayout?.hasControlValveInNsArea ? model?.miscItemsLayout?.hasControlValveInNsArea : false,
      HasPumpsInNsArea: model?.miscItemsLayout?.hasPumpsInNsArea ? model?.miscItemsLayout?.hasPumpsInNsArea : false,
      HasPipingThroughNsArea: model?.miscItemsLayout?.hasPipingThroughNsArea ? model?.miscItemsLayout?.hasPipingThroughNsArea : false,
      HasNonSupervisedValves: model?.miscItemsLayout?.hasNonSupervisedValves ? model?.miscItemsLayout?.hasNonSupervisedValves : false,
      HasNoAboveGroundTest: model?.miscItemsLayout?.hasNoAboveGroundTest ? model?.miscItemsLayout?.hasNoAboveGroundTest : false,
      HasNoUndergroundFlushCertificate:
        model?.miscItemsLayout?.hasNoUndergroundFlushCertificate ? model?.miscItemsLayout?.hasNoUndergroundFlushCertificate : false,
      HasInsufficientSpareSprinklers:
        model?.miscItemsLayout?.hasInsufficientSpareSprinklers ? model?.miscItemsLayout?.hasInsufficientSpareSprinklers : false,
      HasNoPlansOrHydraulicCalculations:
        model?.miscItemsLayout?.hasNoPlansOrHydraulicCalculations ? model?.miscItemsLayout?.hasNoPlansOrHydraulicCalculations : false,
      HasNoEqBracing: model?.miscItemsLayout?.hasNoEqBracing ? model?.miscItemsLayout?.hasNoEqBracing : false,
      HasNonStandardFittings: model?.miscItemsLayout?.hasNonStandardFittings ? model?.miscItemsLayout?.hasNonStandardFittings : false,
      HasImproperHangers: model?.miscItemsLayout?.hasImproperHangers ? model?.miscItemsLayout?.hasImproperHangers : false,
      HasImproperDrainageArrangements:
        model?.miscItemsLayout?.hasImproperDrainageArrangements ? model?.miscItemsLayout?.hasImproperDrainageArrangements : false,
      HasNonStandardTankConditions: model.hasNonStandardTankConditions ? model.hasNonStandardTankConditions : false,
      HasOtherMiscellaneousItems1:
        model?.miscItemsLayout?.hasOtherMiscellaneousItems1 ? model?.miscItemsLayout?.hasOtherMiscellaneousItems1 : false,
      HasOtherMiscellaneousItems2:
        model?.miscItemsLayout?.hasOtherMiscellaneousItems2 ? model?.miscItemsLayout?.hasOtherMiscellaneousItems2 : false,
      ControlValveAccessibilityComment: model?.sprinklerComponents?.controlValveAccessibilityComment ? model?.sprinklerComponents?.controlValveAccessibilityComment : '',
      LackOfFireDepartmentConnectionComment: model?.sprinklerComponents?.lackOfFireDepartmentConnectionComment ? model?.sprinklerComponents?.lackOfFireDepartmentConnectionComment : '',
      LackOfLocalAlarmComment: model?.sprinklerComponents?.lackOfLocalAlarmComment ?  model?.sprinklerComponents?.lackOfLocalAlarmComment : '',
      NonStandardPrivateMainComment: model?.miscItemsLayout?.nonStandardPrivateMainComment ? model?.miscItemsLayout?.nonStandardPrivateMainComment : '',
      OtherMiscellaneous1Description: model?.miscItemsLayout?.otherMiscellaneous1Description ? model?.miscItemsLayout?.otherMiscellaneous1Description : '',
      OtherMiscellaneous2Description: model?.miscItemsLayout?.otherMiscellaneous2Description ?  model?.miscItemsLayout?.otherMiscellaneous2Description : '',
      OtherSprinklerDescription: model?.automaticSprinklers?.otherSprinklerDescription ? model?.automaticSprinklers?.otherSprinklerDescription : '',
      SprinklerOccupancyClass1For234Comment: model?.overheadPiping?.sprinklerOccupancyClass1For234Comment || model?.overheadPiping?.sprinklerOccupancyClass1For234Comment === '' ? model?.overheadPiping?.sprinklerOccupancyClass1For234Comment : null,
      SprinklerOccupancyClass1For56Comment: model?.overheadPiping?.sprinklerOccupancyClass1For56Comment ||  model?.overheadPiping?.sprinklerOccupancyClass1For56Comment === '' ?  model?.overheadPiping?.sprinklerOccupancyClass1For56Comment : null,
      SprinklerOccupancyClass234For56Comment: model?.overheadPiping?.sprinklerOccupancyClass234For56Comment || model?.overheadPiping?.sprinklerOccupancyClass234For56Comment === '' ? model?.overheadPiping?.sprinklerOccupancyClass234For56Comment : null ,
      SprinklerImproperTemperatureComment: model?.automaticSprinklers?.sprinklerImproperTemperatureComment ? model?.automaticSprinklers?.sprinklerImproperTemperatureComment : '',
      UnlistedMeterComment: model?.miscItemsLayout?.unlistedMeterComment ? model?.miscItemsLayout?.unlistedMeterComment : '',
      IsRecalledSprinklerHead: model?.automaticSprinklers?.recalledSprinkler?.isRecalledSprinklerHead ?
        model?.automaticSprinklers?.recalledSprinkler?.isRecalledSprinklerHead : false,
      RecalledSprinklerHeadArea: model?.automaticSprinklers.recalledSprinkler.recalledSprinklerHeadArea ?
        Number(model?.automaticSprinklers.recalledSprinkler.recalledSprinklerHeadArea) : null,
      // Report: null
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
    return false;
  }

  // To set overhead piping field values
  static setOverheadPiping(systemComponentData: any) {
    const nonStandardPipeScheduleInstallType = systemComponentData?.NonStandardPipeScheduleInstallType ? systemComponentData?.NonStandardPipeScheduleInstallType : 'N/A'
    const overheadPiping = {
      isNonStandardPipeSize: systemComponentData?.IsNonStandardPipeSize,
      affectedAreaPipeSize: systemComponentData?.AffectedAreaPipeSize,
      isNonStandardSprinklerSpacing: systemComponentData?.IsNonStandardSprinklerSpacing,
      affectedAreaSpacing: systemComponentData?.AffectedAreaSpacing,
      nonStandardPipeScheduleInstallType: nonStandardPipeScheduleInstallType,
      nonStandardPipeScheduleInstallTypeFirst: nonStandardPipeScheduleInstallType,
      nonStandardPipeScheduleInstallTypeSecond: nonStandardPipeScheduleInstallType,
      nonStandardPipeScheduleInstallTypeThird: nonStandardPipeScheduleInstallType,
      sprinklerOccupancyClass1For234Comment: systemComponentData?.SprinklerOccupancyClass1For234Comment,
      sprinklerOccupancyClass1For56Comment: systemComponentData?.SprinklerOccupancyClass1For56Comment,
      sprinklerOccupancyClass234For56Comment: systemComponentData?.SprinklerOccupancyClass234For56Comment
        ? systemComponentData.SprinklerOccupancyClass234For56Comment
        : '',
    };
    return overheadPiping;
  }

  // To set sprinkler component field values
  static setSprinklercomponents(systemComponentData: any) {
    const sprinklerComponents = {
      lacksFdConnection: systemComponentData?.LacksFdConnection,
      lacksLocalAlarm: systemComponentData?.LacksLocalAlarm,
      isNonStandardSprinklerSpacing: systemComponentData?.IsNonStandardSprinklerSpacing,
      isAbsentIndicatingValve: systemComponentData?.IsAbsentIndicatingValve,
      absentIndicatingValveArea: systemComponentData?.AbsentIndicatingValveArea,
      isNotReadilyAccessibleSupplyControl: systemComponentData?.IsNotReadilyAccessibleSupplyControl,
      lackOfFireDepartmentConnectionComment: systemComponentData?.LackOfFireDepartmentConnectionComment
        ? systemComponentData.LackOfFireDepartmentConnectionComment
        : null,
      lackOfLocalAlarmComment: systemComponentData?.LackOfLocalAlarmComment
        ? systemComponentData.LackOfLocalAlarmComment
        : null,
      controlValveAccessibilityComment: systemComponentData?.ControlValveAccessibilityComment,
    };
    return sprinklerComponents;
  }

  // To set misc items/layout field values
  static setMiscItemsLayout(systemComponentData: any) {
    const miscItemLayout = {
      hasControlValveInNsArea: systemComponentData?.HasControlValveInNsArea,
      hasPumpsInNsArea: systemComponentData?.HasPumpsInNsArea,
      hasPipingThroughNsArea: systemComponentData?.HasPipingThroughNsArea,
      hasNonSupervisedValves: systemComponentData?.HasNonSupervisedValves,
      hasNoAboveGroundTest: systemComponentData?.HasNoAboveGroundTest,
      hasNoUndergroundFlushCertificate: systemComponentData?.HasNoUndergroundFlushCertificate,
      hasInsufficientSpareSprinklers: systemComponentData?.HasInsufficientSpareSprinklers,
      hasNoEqBracing: systemComponentData?.HasNoEqBracing,
      hasNoPlansOrHydraulicCalculations: systemComponentData?.HasNoPlansOrHydraulicCalculations,
      hasNonStandardFittings: systemComponentData?.HasNonStandardFittings,
      hasImproperHangers: systemComponentData?.HasImproperHangers,
      hasImproperDrainageArrangements: systemComponentData?.HasImproperDrainageArrangements,
      isUnlistedMeter: systemComponentData?.IsUnlistedMeter,
      isNonStandardPrivateMain: systemComponentData?.IsNonStandardPrivateMain,
      hasOtherMiscellaneousItems1: systemComponentData?.HasOtherMiscellaneousItems1,
      hasOtherMiscellaneousItems2: systemComponentData?.HasOtherMiscellaneousItems2,
      unlistedMeterComment: systemComponentData?.UnlistedMeterComment,
      nonStandardPrivateMainComment: systemComponentData?.NonStandardPrivateMainComment,
      otherMiscellaneous1Description: systemComponentData?.OtherMiscellaneous1Description,
      otherMiscellaneous2Description: systemComponentData?.OtherMiscellaneous2Description,
    };
    return miscItemLayout;
  }

  // To set dry pipe valve field values
  static setDryPipeValve(systemComponentData: any) {
    const dpvType = systemComponentData?.DpvType ? systemComponentData?.DpvType : 'N/A'
    const dryPipeValve = {
      areaProtectedByDryPipeSystem: systemComponentData?.AreaProtectedByDryPipeSystem,
      dpvType: dpvType,
      standardFirst: dpvType,
      isSupervisedDpv: systemComponentData?.IsSupervisedDpv,
      acceptableDryPipeSecond: dpvType,
      isSupervisedDpvWithoutQod: systemComponentData?.IsSupervisedDpvWithoutQod,
      unacceptableThird: dpvType,
      unacceptableDpvArea: systemComponentData?.UnacceptableDpvArea
    };
    return dryPipeValve;
  }

  // To set automatic sprinkler field values
  static setAutomaticSprinklers(systemComponentData: any) {
    const automaticSprinklersData = {
      isImproperUseOfHighLowTempSprinklers: systemComponentData?.IsImproperUseOfHighLowTempSprinklers,
      sprinklerImproperTemperatureComment: systemComponentData?.SprinklerImproperTemperatureComment,
      overageSprinkler: { overageSprinklerArea: systemComponentData?.OverAgeSprinklerArea, isOverAgeSprinkler: systemComponentData?.IsOverAgeSprinkler },
      corrodedSprinkler: { isCorrodedSprinkler: systemComponentData?.IsCorrodedSprinkler, corrodedSprinklerArea: systemComponentData?.CorrodedSprinklerArea },
      paintedSprinkler: { isPaintedSprinkler: systemComponentData?.IsPaintedSprinkler, paintedSprinklerArea: systemComponentData?.PaintedSprinklerArea },
      recalledSprinkler: { isRecalledSprinklerHead: systemComponentData?.IsRecalledSprinklerHead, recalledSprinklerHeadArea: systemComponentData?.RecalledSprinklerHeadArea },
      nonStandardSprinkler: {
        isOtherUnacceptableOrNonStandardSprinkler: systemComponentData?.IsOtherUnacceptableOrNonStandardSprinkler,
        otherUnacceptableOrNonStandardSprinklerArea: systemComponentData?.OtherUnacceptableOrNonStandardSprinklerArea,
      },
      otherSprinklerDescription: systemComponentData?.OtherSprinklerDescription,
    };
    return automaticSprinklersData;
  }
}

class OverheadPiping {
  isNonStandardPipeSize: boolean;
  affectedAreaPipeSize: number;
  isNonStandardSprinklerSpacing: boolean;
  affectedAreaSpacing: number;
  nonStandardPipeScheduleInstallType: string;
  sprinklerOccupancyClass1For234Comment: string;
  sprinklerOccupancyClass1For56Comment: string;
  sprinklerOccupancyClass234For56Comment: string;
}

class Sprinklercomponents {
  lacksFdConnection: boolean;
  lackOfFireDepartmentConnectionComment: string;
  lacksLocalAlarm: boolean;
  lackOfLocalAlarmComment: string;
  isAbsentIndicatingValve: boolean;
  absentIndicatingValveArea: string;
  isNotReadilyAccessibleSupplyControl: boolean;
  controlValveAccessibilityComment: string;
}

class MiscItemsLayout {
  hasControlValveInNsArea: boolean;
  hasPumpsInNsArea: boolean;
  hasPipingThroughNsArea: boolean;
  hasNonSupervisedValves: boolean;
  hasNoAboveGroundTest: boolean;
  hasNoUndergroundFlushCertificate: boolean;
  hasInsufficientSpareSprinklers: boolean;
  hasNoEqBracing: boolean;
  hasNoPlansOrHydraulicCalculations: boolean;
  hasNonStandardFittings: boolean;
  hasImproperHangers: boolean;
  hasImproperDrainageArrangements: boolean;
  isUnlistedMeter: boolean;
  isNonStandardPrivateMain: boolean;
  hasOtherMiscellaneousItems1: boolean;
  hasOtherMiscellaneousItems2: boolean;
  unlistedMeterComment: string;
  nonStandardPrivateMainComment: string;
  otherMiscellaneous1Description: string;
  otherMiscellaneous2Description: string;
}

class AutomaticSprinkler {
  sprinklers: Array<SprinklerTable>;
  isImproperUseOfHighLowTempSprinklers: boolean;
  sprinklerImproperTemperatureComment: string;
  overageSprinkler: any;
  corrodedSprinkler: any;
  paintedSprinkler: any;
  recalledSprinkler: any;
  nonStandardSprinkler: any;
  otherSprinklerDescription: string;
}
class SprinklerTable {
  sprinklerId: string;
  reportId: string;
  systemNumber: string;
  sprinklerMakeAndModel?: string;
  yearManufactured?: number;
  yearManufacturedComment?: string;
  numberOfUprightSprinklers?: number;
  numberOfPendantSprinklers?: number;
  numberOfSideWallSprinklers?: number;
  numberOfDryPendantSprinklers?: number;
  orificeSize?: number;
  orificeSizeComment?: string;
  temperatureRating?: number;
  comments?: string;
}
class DryPipeValve {
  areaProtectedByDryPipeSystem: number;
  dpvType: boolean;
  isSupervisedDpv: boolean;
  isSupervisedDpvWithoutQod: boolean;
  unacceptableDpvArea: number;
}
