/* eslint-disable @typescript-eslint/dot-notation */
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { WallsChargeableColumnService } from 'src/app/services/formly-builder/full-risk/formly-walls-chargeable-column.service';
import { FormlyWallsConstructionService } from 'src/app/services/formly-builder/formly-walls-construction.service';
import { FormControl } from '@angular/forms';
import { Constants } from 'src/app/services/util-service/constants';
import { Subject } from 'rxjs';

export class WallsChargeableColumnModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService, private wallsChargeableColumnService: WallsChargeableColumnService, private formlywallsConstructionService: FormlyWallsConstructionService) {
    super({
      key: 'areChargeableColumn',
      // type: 'segment',
      wrappers: ['simple-section-wrapper'],
      props: {
        label: 'Are there any Chargeable Columns',
        isCollapsable: true,
        isCollapsed: false,
        toggleYesNo: true,
        isDisabled: false,
        required: true,
        checkTouchedControl: true
      },
      fieldGroup: [
        {
          key: 'wallsChargeableColumn',
          defaultValue: null,
          props: {
            required: true,
            // label: 'Are there any Chargeable Columns',   // No need to add. In the error service we'll get the label from the section in this case
          }
        },
        {
          fieldGroupClassName: 'formly-flex-container column-card-view',
          fieldGroup: [
            {
              template: '<span class="label-weight">Column Construction</span>',
            },
            { template: '<span></span>', className: 'new-row' },
            {
              fieldGroupClassName: 'formly-flex-container',
              className: 'column-width',
              fieldGroup: [
                {
                  key: 'columnType',
                  resetOnHide: true
                },
                {
                  key: 'hasUnprotectedMetalColumns',
                  type: 'custom-checkbox',
                  className: 'metal-style',
                  resetOnHide: true,
                  props: {
                    label: 'Unprotected Metal',
                    maxLength: 100,
                  },
                },
                {
                  key: 'hasCombustibleColumns',
                  type: 'custom-checkbox',
                  className: 'combustible-style',
                  resetOnHide: true,
                  props: {
                    label: 'Combustible',
                  },
                },
                {
                  key: 'columnWallThickness',
                  type: 'simple-select',
                  className: 'thickness-width',
                  resetOnHide: true,
                  defaultValue: null,
                  props: {
                    label: 'Wall Thickness',
                    options: [],
                    required: true,
                    isAsteriskNotRequired: true,
                    min: 4,
                  },
                  validators: {
                    validation: [{ name: 'walls-column-thickness-validator' }],
                  },
                  hooks: {
                    onInit: (field) => {
                      const formlyService = field.options.formState.service;
                      formlyService.wallsChargeableColumnService.thicknessValues(field);
                    },
                  },
                },
                {
                  key: 'percentUnprotectedMetalColumnArea',
                  type: 'simple-input',
                  className: 'walls-bearing-style',
                  resetOnHide: true,
                  defaultValue: null,
                  props: {
                    label: 'Non-Bearing Walls',
                    required: true,
                    type: 'number',
                    inputmode: 'tel',
                    isAsteriskNotRequired: true,
                    unit: '%',
                    min: 1,
                    max: 100
                  },
                  hooks: {
                    onInit: (field) => {
                      if(field?.form?.value?.percentUnprotectedMetalColumnArea==0 || field?.form?.value?.percentUnprotectedMetalColumnArea===null || field?.form?.value?.percentUnprotectedMetalColumnArea==='') {
                        field.form.controls['percentUnprotectedMetalColumnArea'].markAsTouched();
                      }
                    },
                  },
                },
              ],
              validators: {
                checkboxCheck: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    if (!field?.model?.hasCombustibleColumns && !field?.model?.hasUnprotectedMetalColumns && field?.model?.wallsChargeableColumn) {
                      return false;
                    } else {
                      return true;
                    }
                  },
                  message: 'Select Column Material',
                },
              },
            },
          ],
          expressions: {
            hide: (field: FormlyFieldConfig) => !field.form?.get('wallsChargeableColumn')?.value
          },
        }
      ],
      hooks: {
        //onInit: (field?: FormlyFieldConfig) =>
          // if(Number(field.model.percentUnprotectedMetalColumnArea)>0) {
          //   field.formControl.get('wallsChargeableColumn').setValue("true");
          // }
          // if(Number(field.model.percentUnprotectedMetalColumnArea)===0) {
          //   field.formControl.get('wallsChargeableColumn').setValue(false);
          // }
          // field.formControl.valueChanges.pipe(
          //   distinctUntilChanged(),
          //   tap((value) => formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_WALLS_CHARGEABLE, value, true))
          // )
          onInit: (field: FormlyFieldConfig) => {
            field.formControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(),
            debounceTime(Constants.riskFormDataSyncDelay))
              .subscribe(value => {
                if (!field.formControl.pristine) {
                  formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_WALLS_CHARGEABLE, value, true);
                  // updateSection_ copies the FRM to the RR
                }
              });
          },
          onDestroy: () => {
            this.ngUnsubscribe.next();
            this.ngUnsubscribe.complete();
          }
      },
      expressions: {
        hide:
          (field: FormlyFieldConfig) => {
            const masonryArr = field?.parent?.model?.walls?.wallsArray?.filter(wall =>
              wall.constructionType === 'Masonry'
            );
            if (masonryArr?.length > 0) {
              return false;
            } else {
              return true;
            }
          },
        'props.className': (field: FieldTypeConfig) => field?.model?.wallsChargeableColumn ? 'padding-bottom' : '',
      },
    });
  }
}
