import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';

/**
 * Class that represents the Formly Model for Occupants
 */
export class AdditionDateDataModel {
  static sectionName = 'additionDate';

  additionDate1: string;
  additionDateIdentifier?: string;
  reportIdentifier: string;

  static from(reports: any): any {
    return {};
  }

  static fromS3(reports: any): AdditionDateDataModel {
    let additionDate1 = '';
    if (reports?.AdditionDate1?.length >= 4) {
      // Only get the year, first 4 digits
      const additionDate = reports.AdditionDate1.substring(0, 4);
      // If string represents a valid number
      if (!isNaN(Number(additionDate))) {
        additionDate1 = additionDate;
      }
    } 
    return {
      additionDate1: additionDate1,
      additionDateIdentifier: reports.AdditionDateIdentifier,
      reportIdentifier: reports.ReportIdentifier,
    };
  }

  /**
   * Map to RR/S3 data representation
   */
  static to(model: AdditionDateDataModel) {
    let additionDate1 = '';
    if (model?.additionDate1) {
      additionDate1 = model.additionDate1 + "-01-01T00:00:00.000Z";
    }
    const mappedData = {
      AdditionDate1: additionDate1,
      AdditionDateIdentifier: model.additionDateIdentifier,
      ReportIdentifier: model.reportIdentifier,
    }

    return mappedData;
  }

  /**
   * Check if the model has changed
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   * @returns 
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    return true;
  }
}
