/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyBuilderService } from '../formly-builder.service';
import { SectionEnum } from '../section.config';
import { WindEnvExposureDataModel } from 'src/app/models/formly-builder/formly/wind/wind-environment-exposure-data.model';
import { FormlyWrapperService } from '../formly-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class WindEnvironmentExposuresService extends SectionServiceAbstract {

  constructor(private formlyBuilderService: FormlyBuilderService) {
    super();
  }

  async updateData(model: any, riskReportData: any): Promise<any> {
    // Check if data has changed
    const hasChanged: boolean = WindEnvExposureDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const windEnvironmentsAndExposure =
        WindEnvExposureDataModel.to(model[ 'windReport' ].environmentsAndExposure, riskReportData?.ReportIdentifier);

      const windReportData = {
        ...riskReportData.WindReport,
        ...{ EnvironmentsAndExposure: windEnvironmentsAndExposure }
      };

      return { ...riskReportData, ...{ WindReport: windReportData } };
    } else {
      return undefined;
    }
  }


  loadData(riskReport: any[], fullData: any, isS3: boolean, model: any): any {
    return model;
  }

  public setSurroundingTerrainValue(field) {
    const proximityToCoastLineCheck = 'BARR';
    const proximityToCoastLine = field.form.get('proximityToCoastLine');

    proximityToCoastLine.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((proximity) => {
      if (proximity) {
        if (proximity.trim() === proximityToCoastLineCheck.trim() && field.formControl.value !== 'Directly on the Water') {
          field.formControl.setValue('Directly on the Water');
          field.props.isDisabled = true;
        } else if (proximity.trim() === proximityToCoastLineCheck.trim() && field.formControl.value === 'Directly on the Water' && !field.props.isDisabled) {
          field.props.isDisabled = true;
        } else if (field.props.isDisabled && proximity.trim() === proximityToCoastLineCheck.trim()) {
          field.props.isDisabled = true;
        } else if (field.props.isDisabled) {
          field.props.isDisabled = false;
        }
      } else if (field.props.isDisabled) {
        field.props.isDisabled = false;
      }
    });
  }

  async onApply(modalCtrl, field) {
    const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const riskReport = await formlyService.riskReport.updateSectionData(SectionEnum.FR_W_ENV_EXPOSURES, false);
    const newModel = formlyService.windReportService.loadData(riskReport, riskReport, true, formlyService.riskReport.model);
    if (newModel) {
      formlyService.riskReport.model = { ...newModel };
    }
    modalCtrl.dismiss();
  }

  onCloseWindReport(modalCtrl, field, initialModel) {
    // Call generic method
    // FormlyWrapperService.onClose(modalCtrl, field, initialModel);

    const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const reportData = formlyService.riskReport.report;
    const newModel = formlyService.windReportService.loadData(reportData, reportData, true, formlyService.riskReport.model);
    if (newModel) {
      formlyService.riskReport.model = { ...newModel };
    }
    formlyService.errorService.closePreviousToaster();
    modalCtrl.dismiss();
  }

}
