import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { SectionEnum } from '../../section.config';
import { UtilService } from 'src/app/services/util-service/util.service';
import { AlarmAndSupervisoryTestDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/alarm-supervisory-test-data.model';
import { FormlyBuilderService } from '../../formly-builder.service';


// Alarm Supervisory  Test Sheet datas needs to be saved when user hits on apply in OverageSprinklerTest sheet
// (i.e) In Case of Alarm Supervisory  test there will be two sheets one top of another, 
// When user hits apply on the top level sheet, it should call the updateData method and the changes needs to be saved to s3
@Injectable({
  providedIn: 'root',
})
export class AlarmAndSupervisoryTestService extends SectionServiceAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService,
    private utilService: UtilService) {
    super();
  }
  

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    // Will get Called only when user hits apply inside Alarm Supervisory  Test sheet
    const AlarmAndSupervisoryTests = [];
    model?.sprinklerReport?.asgrSprinklerSystemTest?.alarmAndSupervisoryTests?.forEach((alarmAndSupervisoryTest) => {
      const AlarmSupervisory = AlarmAndSupervisoryTestDataModel.to(alarmAndSupervisoryTest);
      if (AlarmSupervisory?.TestDate) {
        AlarmSupervisory.TestDate = this.utilService.convertDateToISOStringFormat(AlarmSupervisory.TestDate);
      }
      AlarmAndSupervisoryTests.push(AlarmSupervisory);
    });
    const newRr = {
      ...riskReportData,
      SprinklerReport: {
        ...riskReportData.SprinklerReport,
        AsgrSprinklerSystemTest: {
          ...riskReportData.SprinklerReport.AsgrSprinklerSystemTest,
          ...{ AlarmAndSupervisoryTests: AlarmAndSupervisoryTests }
        }
      }
    }
    return { ...riskReportData, ...newRr };
  }

  loadData(sectionData: any[], reportInfo: any, isS3: boolean, model: any): any {
    // Will get Called only when user hits apply inside Alarm Supervisory Test sheet or hit close inside Alarm Supervisory  test sheet
    const AlarmAndSupervisoryTests = sectionData;
    let alarmAndSupervisoryTest = [];
    if (AlarmAndSupervisoryTests && AlarmAndSupervisoryTests.length) {
      AlarmAndSupervisoryTests.forEach((test) => {
        const alarmAndSupervisoryTestData =AlarmAndSupervisoryTestDataModel.fromS3(test);
        alarmAndSupervisoryTest.push(alarmAndSupervisoryTestData);
      });
    }

    const newModel = {
      ...model,
      sprinklerReport: {
        ...model.sprinklerReport,
        asgrSprinklerSystemTest: {
          ...model.sprinklerReport.asgrSprinklerSystemTest,
          ...{ alarmAndSupervisoryTests: alarmAndSupervisoryTest }
        }
      }
    }
    if (newModel) {
      this.formlyBuilderService.riskReport.model = { ...newModel };
    }
    return this.formlyBuilderService.riskReport.model;
  }


  async onApply(modalCtrl, field) {
    // When apply we need to save the value to S3 here
    const alarmAndSupervisoryTestService = field.options.formState.service.alarmAndSupervisoryTestService;
    const riskReportService = field.options.formState.riskReportService;
    const rr = await riskReportService.updateSectionData(SectionEnum.FR_SR_ST_OVER_AGE_SPRINKLER_TEST, false, null, AlarmAndSupervisoryTestService);

    // After hits apply in the sheet, It needs to be sorted based on most recent date
    let AlarmAndSupervisoryTests = rr?.SprinklerReport?.AsgrSprinklerSystemTest?.AlarmAndSupervisoryTests;
    AlarmAndSupervisoryTests = field.options.formState.service.sprinklerReportService.sortArrayWithMostRecentDate(AlarmAndSupervisoryTests, 'TestDate');

    // loadData to set the sorted value back to model
    alarmAndSupervisoryTestService.loadData(AlarmAndSupervisoryTests, rr, true, riskReportService.model);

    modalCtrl.dismiss(null, 'save');
  }

  onClose(modalCtrl, field, initialModel) {
    // Tried with patchValue and resetModel
    // Tried with reset and patchValue with forLoop for each array values
    // The above two didin't help so going with load Data on close 
    const formlyBuilderService = field.options.formState.service;
    const riskReportService = field.options.formState.riskReportService;
    const formlyModel = riskReportService.model;
    const rr = riskReportService.report;
    formlyBuilderService.alarmAndSupervisoryTestService.loadData(rr?.SprinklerReport?.AsgrSprinklerSystemTest?.AlarmAndSupervisoryTests, rr, true, formlyModel);
    formlyBuilderService.errorService.closePreviousToaster();
    modalCtrl.dismiss(null, 'cancel');
  }

  
  getAlarmSupervisoryOptions(): any[] {
    return [
      { value: 'OK', label: 'Tested - In Service' },
      { value: 'NT', label: 'Not Tested' },
      { value: 'X', label: 'None or Out of Service'},
    ]
  }

}

