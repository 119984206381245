import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { SectionEnum } from '../../section.config';
import { BuildingHazardsDataModel } from 'src/app/models/formly-builder/formly/full-risk/occupant-hazards/building-hazards-data.model';
import { UtilService } from 'src/app/services/util-service/util.service';
import { OccupantHazardsService } from './occupant-hazards.service';

@Injectable({
  providedIn: 'root'
})
export class OccupantFullriskService extends SectionServiceAbstract {

  constructor(private util: UtilService,
    private occupantHazardServ: OccupantHazardsService) { super(); }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {

    return undefined
  }
  loadData(sectionData: any[], riskReportData: any, isDataInS3Format: boolean, model: any) {
    const buildingHazardsModel: BuildingHazardsDataModel = BuildingHazardsDataModel.fromS3(riskReportData.ReportHazards);
    const occupantHazardModel = this.occupantHazardServ.loadOccupantsData(sectionData, riskReportData, isDataInS3Format, model);
    const newModel = {
      ...model,
      ...{
        occupants: {
          buildingHazards: { buildingHazardArray: buildingHazardsModel }, ...occupantHazardModel
        }
      }
    };
    return newModel;
  }
}
