import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { RiskModeEnum } from 'src/app/enums/formly-builder/risk-mode.enum';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionConfig, SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Constants } from 'src/app/services/util-service/constants';

export class CombustibleConstructionModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService ) {
    super({
      id: SectionConfig.get(SectionEnum.FR_SECONDARY_CONSTRUCTION).name,
      key: 'combustibleConstruction',
      // wrappers: ['simple-section-wrapper'],
      props: {
        label: 'Combustible construction',
        isCollapsable: true,
      },
      expressions: {
        'props.isDisabled': 'formState.service.mode !== 2',
      },
      fieldGroup: [
        {
          type: 'secondary-construction',
           wrappers:['simple-section-wrapper'],
          props: {
            label: 'Combustible Construction',
            isCollapsable: true,
          },
          fieldGroupClassName: 'combustible-construction',
          fieldGroup: [
            {
              fieldGroupClassName: 'group-style',
              fieldGroup: [
                {
                  key: 'roofSurfaceConstructionType',
                  type: 'simple-select',
                  props: {
                    label: 'Roof Surface Construction',
                    required: false,
                    options: [],
                    isAsteriskNotRequired: true
                  },
                   // TODO: commented onblur because expressions were not triggerrring until we touch another field so need to write a syncing functionality
                  // modelOptions: {
                  //   updateOn: 'blur',
                  // },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => field?.model?.roofSurfaceArea > 0,
                  },
                  hooks: {
                    onInit : (field : FormlyFieldConfig) => {
                      const  floorsAndroof =field.formControl.root?.get('floorsAndRoofs')?.get('constructionEntries')?.get('floorsAndRoofsLevels')?.value;
                   const arr =  floorsAndroof?.filter((item)=> (item.levelTypeCode === 'ROOF' &&  (item.constructionTypeCode === 'FIRE' || item.deckConstruction === 'COMA')));
                      if(arr?.length> 0){
                        field.props.options =  [
                          { value: null, label: '' },
                          // { value: 'NONE', label: 'None' },
                          { value: 'CBSF', label: 'Combustible Surface' },
                          { value: 'FSWF', label: 'False Wood Roof' },
                          { value: 'FABR', label: 'Fabric' },
                        ];
                      }
                      else {
                        field.props.options = [
                          { value: null, label: '' },
                          // { value: 'NONE', label: 'None' },
                          { value: 'CBSF', label: 'Combustible Surface' },
                          { value: 'FABR', label: 'Fabric' },
                        ];
                      }
                            field.formControl.markAsTouched();
                  }
                  },
                  className: 'field-style',
                },
                {
                  key: 'isFabricatedRoofFlameSpreadLessThan25',
                  type: 'custom-checkbox',
                  props: {
                    label: 'Flame Spread <=25',
                  },
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !(field?.model?.roofSurfaceConstructionType === 'Fabric')
                  },
                  hooks: {
                    onInit: (field) => {
                        field.formControl.markAsTouched();
                    },
                  },
                  className: 'field-style',
                },
                {
                  key: 'roofSurfaceArea',
                  type: 'simple-input',
                  props: {
                    label: 'Roof surface area ',
                    unit: 'sq.ft.',
                    required: false,
                    type: 'number',
                    inputmode: 'tel',
                    isAsteriskNotRequired: true
                  },
                  // TODO: commented onblur because expressions were not triggerrring until we touch another field so need to write a syncing functionality
                  // modelOptions: {
                  //   updateOn: 'blur',
                  // },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) =>  !(field?.form?.get('roofSurfaceConstructionType')?.value === '' || field?.form?.get('roofSurfaceConstructionType')?.value === null || field?.form?.get('roofSurfaceConstructionType')?.value === 'NONE') ,
                  },
                  hooks: {
                    onInit: (field) => {
                        field.formControl.markAsTouched();
                    },
                  },
                  className: 'field-style roof-surface-position',
                  validators: {
                    roofSurfaceArea1 : {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        // check roof area in floors and roof section
                        const levels = field?.formControl?.root?.get('floorsAndRoofs')?.get('constructionEntries')?.get('floorsAndRoofsLevels')?.value;
                      const roofArea =  field?.options?.formState?.service?.secondaryConstructionsService?.calculateBuildingTotalRoofArea(field);
                      if(Number(control.value) > roofArea) {
                        return false;
                      }
                      else if( Number(control.value) <= roofArea){
                         return true;
                      }
                      },
                      message: 'Area cannot be greater than building roof area'
                    },
                    roofSurfaceArea2 : {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        // if roof surafec construction type === 'false wood roof' then roof area cannot exceed 
                        // roof areas of floorsRoof.fire resistive area + floorsroof.deckConstruction.masonry area
                        const roofSurfaceConstructionType = field?.model?.roofSurfaceConstructionType;
                        if(roofSurfaceConstructionType === 'False Wood Roof') {
                          const levels = field?.formControl?.root?.get('floorsAndRoofs')?.get('constructionEntries')?.get('floorsAndRoofsLevels')?.value;
                          const roofArea =  field?.options?.formState?.service?.secondaryConstructionsService?.calculateFireResistiveMasonryRoofArea(field);
                          if(Number(control.value) > roofArea) {
                            return false;
                          }
                          else if( Number(control.value) <= roofArea){
                             return true;
                          }
                        }
                        else {
                          return true;
                        }
                      },
                      message: "False Wood Roof area cannot exceed area of masonry or fire resistive roofs"
                    }
                  }
                },
                {
                  type: 'rf-aerial',      // TODO: Design slightly off... I keep uncommented
                  className: 'aerial-icon-style',
                  props: {
                    areaKey: 'roofSurfaceArea',
                  }
                },                
              ],
            },
            {
              // fieldGroupClassName:'group-style',
              wrappers: [ 'question-wrapper' ],
              props: {
                label: 'Raised combustible floor surface',
                firstColSize: 5,
                showError: false,
              },
              fieldGroup: [
                {
                  key: 'inchesOfAirSpace',
                  type: 'simple-input',
                  props: {
                    label: 'Inches of air space',
                    unit: 'in',
                    required: false,
                    disabled: false,
                    isDisabled: false,
                    type: 'number',
                    inputmode: 'tel',
                    isAsteriskNotRequired: true
                  },
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  hooks: {
                    onInit: (field) => {
                        field.formControl.markAsTouched();
                    },
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => field?.model?.raisedSurfaceFloorArea > 0,
                    'props.readonly': (field: FormlyFieldConfig) => {
                     const constructionClass = field?.formControl?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('constructionClass')?.value;
                     if( constructionClass == '1'|| constructionClass == '2'){
                      return true;
                     }
                  else {
                    return false;
                  }
                    },
                    'props.isDisabled': (field: FormlyFieldConfig) => field?.props?.readonly,
                  },
                  className: 'field-style1',
                },                
                {
                  key: 'raisedSurfaceFloorArea',
                  type: 'simple-input',
                  props: {
                    label: 'Raised floor area',
                    unit: 'sq.ft.',
                    required: false,
                    disabled: false,
                    isDisabled: false,
                    type: 'number',
                    inputmode: 'tel',
                    isAsteriskNotRequired: true
                  },
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  hooks: {
                    onInit: (field) => {
                        field.formControl.markAsTouched();
                    },
                  },
                  expressions: {
                    'props.required': (field: FormlyFieldConfig) => field?.model?.inchesOfAirSpace > 0,
                    'props.readonly': (field: FormlyFieldConfig) => {
                      const constructionClass = field?.formControl?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('constructionClass')?.value;
                      if(constructionClass == '1'|| constructionClass == '2'){
                       return true;
                      }
                   else {
                     return false;
                   }
                     },
                     'props.isDisabled': (field: FormlyFieldConfig) => field?.props?.readonly,
                  },
                  validators: {
                    raisedSurfaceFloorArea : {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        // check roof area in floors and roof section
                        const levels = field?.formControl?.root?.get('floorsAndRoofs')?.get('constructionEntries')?.get('floorsAndRoofsLevels')?.value;
                      const floorArea =  field?.formControl?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('totalArea')?.value;
                      if(Number(control.value) > floorArea) {
                        return false;
                      }
                      else if( Number(control.value) <= floorArea){
                         return true;
                      }
                      },
                          message: 'Raised Surface area cannot be greater than building area'
                    },
                  },
                  className: 'field-style1',
                },
                // {
                //   type: 'rf-aerial',    // TODO: Fix: it messes the design
                //   props: {
                //     areaKey: 'raisedSurfaceFloorArea',
                //     oneKey:  ''
                //   }
                // },                
              ],
            },
            {
              wrappers: [ 'question-wrapper' ],
              props: {
                label: 'Combustible concealed roof space',
                firstColSize: 5,
                showError: false,
              },
              fieldGroupClassName:'concealed-roof-style',
              fieldGroup: [
                {
                  key: 'concealedRoofArea',
                  type: 'simple-input',
                  props: {
                    label: 'Concealed roof area',
                    unit: 'sq.ft.',
                    type: 'number',
                    inputmode: 'tel',
                  },
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  hooks: {
                    onInit: (field) => {
                        field.formControl.markAsTouched();
                    },
                  },
                  className: 'field-style1 aerial-adjustment',
                  validators: {
                    concealedRoofAreaval1 : {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        // check roof area in floors and roof section
                        const levels = field?.formControl?.root?.get('floorsAndRoofs')?.get('constructionEntries')?.get('floorsAndRoofsLevels')?.value;
                        const roofArea =  field?.options?.formState?.service?.secondaryConstructionsService?.calculateBuildingTotalRoofArea(field);
                        if(Number(control.value) > roofArea) {
                          return false;
                        }
                        else if( Number(control.value) <= roofArea){
                           return true;
                        }
                      },
                      message: "Concealed roof area cannot be greater than building's roof area"
                    },
                  }
                },
                {
                  type: 'rf-aerial',    // TODO: Fix: it messes the design
                  className: 'aerial-style',
                  props: {
                    areaKey: 'concealedRoofArea',
                    oneKey:  ''
                  }
                },     
              ],
            },
            {
              // fieldGroupClassName:'group-style',
              wrappers: [ 'question-wrapper' ],
              props: {
                label: 'Combustible interior walls',
                showError: false,
                disabled: false,
                isDisabled: false
              },
              fieldGroup: [
                {
                  key: 'percentOfExteriorWallArea',
                  type: 'simple-input',
                  props: {
                    label: '% of exterior walls',
                    unit: '%',
                    min: 1,
                    max: 100,
                    type: 'number',
                    inputmode: 'tel',
                    disabled: false,
                    isDisabled: false,
                    readonly:false
                  },
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  hooks: {
                    onInit: (field) => {
                        field.formControl.markAsTouched();
                    },
                  },
                  expressions: {
                    'props.readonly': (field: FormlyFieldConfig) => {
                     const constructionClass = field?.formControl?.root?.get('floorsAndRoofs')?.get('buildingInformation')?.get('constructionClass')?.value;
                     if(constructionClass == '1'|| constructionClass == '2'){
                      return true;
                     }
                  else {
                    return false;
                  }
                    },
                  },
                  className: 'field-style1',
                },
              ],
            },
            //  {
            //   key: 'constructionLevels',
            //   type: 'occupant-levels',
            //   props: {
            //     addText: 'Add Floor',
            //   },
            //   className: 'newLine-field full-width',
            //   fieldArray: {
            //     fieldGroup: [
            //       {
            //         key: 'name',
            //         type: 'simple-select',
            //         props: {
            //           label: 'Level Type',
            //           options: [],
            //           // required: true,
            //           isNonBlockingValidation: true,
            //         },

            //       },
            //     ]
            //   }
            //   }
          ]
        },
        // {
        //   wrappers: [ 'simple-section-wrapper' ],
        //   props: {
        //     label: 'Combustible Finish',
        //     isCollapsable: true,
        //   }
        // },
        // {
        //   wrappers: [ 'simple-section-wrapper' ],
        //   props: {
        //     label: 'Highly damageable exterior finish',
        //   }
        // },
       
      ],
      hooks: {
        onInit: (field?: FormlyFieldConfig) =>{
          field?.formControl?.valueChanges.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(),
          debounceTime(Constants.riskFormDataSyncDelay))
            .subscribe(value => {
              if (!field.formControl.pristine) {
              formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_SECONDARY_CONSTRUCTION, value, true);
              }
            });
          },
          onDestroy: () => {
            this.ngUnsubscribe.next();
            this.ngUnsubscribe.complete();
          }
      },
    });
  }
}
