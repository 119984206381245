import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { FormControl } from '@angular/forms';
import { Constants } from 'src/app/services/util-service/constants';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Subject } from 'rxjs';
export class GreenConstructionModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      wrappers: ['simple-section-wrapper'],
      key: 'greenConstruction',
      props: {
        label: 'Green Construction',
        className: 'padding-bottom',
      },
      className: 'green-construction-container',
      fieldGroup: [
        {
          wrappers: ['question-wrapper'],
          key: 'solarPanelCoverage',
          id: 'solarPanelCoverageType',
          name: 'solarPanelCoverageType',
          type: 'simple-select',
          className: 'card-view-green-construction select-green-construction',
          defaultValue: 'NONE',
          props: {
            label: 'Solar Panels',  
            isLabelDisabled: true,
            isFontSizeSmall: true,        
            options: [
              { value: 'NONE', label: 'N/A' },
              { value: 'LE25', label: '<= 25%' },
              { value: 'GT25', label: '> 25% - 50%' },
              { value: 'GT50', label: '> 50% - 75%' },
              { value: 'GT75', label: '> 75%' }
            ],
          },
          validators: {
            validation: [{ name: 'solar-panel-validator', options: {} }]
          },
        },
        {
          wrappers: ['question-wrapper'],
          key: 'greenRoofCoverage',
          id: 'greenRoofCoverageType',
          name: 'greenRoofCoverageType',
          type: 'simple-select',
          className: 'card-view-green-construction select-green-construction',
          defaultValue: 'NONE',
          props: {
            label: 'Green Roof',
            isLabelDisabled: true,
            isFontSizeSmall: true,           
            options: [
              { value: 'NONE', label: 'N/A' },
              { value: 'LE25', label: '<= 25%' },
              { value: 'GT25', label: '> 25% - 50%' },
              { value: 'GT50', label: '> 50% - 75%' },
              { value: 'GT75', label: '> 75%' }
            ],        
          },
        }
      ],
      hooks: {
        onInit: (field?: FormlyFieldConfig) => {
          field?.formControl?.valueChanges.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(),
          debounceTime(Constants.riskFormDataSyncDelay))
            .subscribe(value => {
              if (!field.formControl.pristine) {
              formlyBuilderService.onSectionFieldChange(field, SectionEnum.FR_SECONDARY_CONSTRUCTION, value, true);
              }
            });
          },
          onDestroy: () => {
            this.ngUnsubscribe.next();
            this.ngUnsubscribe.complete();
          }
      },
    });
  }
}
