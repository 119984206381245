import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';

/**
 * Class that represents the Formly Model for CombustibleConstruction
 */
export class CombustibleFinishDataModel {
    
    static sectionName = 'combustibleFinish';

    finishInteriorCombustibleType : string;    // CBSB,FOAM,NONE
    finishInteriorPercentOfStoriesAffected: any;
    finishInteriorFlameSpread: any;
    finishInteriorSmokeDevelopment: any;
    isFinishInteriorUnlisted: boolean;
    isFinishInteriorThermalBarrierPresent: boolean;
    isFinishInteriorQualifiedAsSlowBurning: boolean;

    finishExteriorCombustibleType: string;    // CBSB,FOAM,NONE
    finishExteriorPercentOfStoriesAffected: any;
    finishExteriorFlameSpread: any;
    finishExteriorSmokeDevelopment: any;
    isFinishExteriorUnlisted: boolean;
    isFinishExteriorThermalBarrierPresent: boolean;
    isFinishExteriorQualifiedAsSlowBurning: boolean;

    // the datapoints for saving toggle UI controls
    isInteriorFinish: boolean;
    isExteriorFinish: boolean;
   

    static fromS3(reports:any):CombustibleFinishDataModel {
           let finishInteriorCombustibleType ;
           const finsihInteriorCT = reports?.SecondaryConstructions?.length >0 ? reports?.SecondaryConstructions[0]?.FinishInteriorCombustibleType : undefined
           switch (finsihInteriorCT) {
            case 'CBSB':
                finishInteriorCombustibleType = 'Combustible/Slow Burn';
              break;
            case 'FOAM':
                finishInteriorCombustibleType = 'Foamed Plastic';
              break;
            default:
                finishInteriorCombustibleType = null;
          }
          let finishExteriorCombustibleType ;
          const finsihExteriorCT = reports?.SecondaryConstructions?.length >0 ? reports?.SecondaryConstructions[0]?.FinishExteriorCombustibleType : undefined

          switch (finsihExteriorCT) {
           case 'CBSB':
            finishExteriorCombustibleType = 'Combustible';
             break;
           case 'FOAM':
            finishExteriorCombustibleType = 'Foamed Plastic';
             break;
           default:
            finishExteriorCombustibleType = null;
     
         }
        return {
      isInteriorFinish: reports?.SecondaryConstructions?.length > 0 && Number(reports?.ConstructionClass) != 1 ? (reports?.SecondaryConstructions[ 0 ]?.FinishInteriorCombustibleType === 'CBSB' || reports?.SecondaryConstructions[ 0 ]?.FinishInteriorCombustibleType === 'FOAM' ? true : null) : null,
      isExteriorFinish: reports?.SecondaryConstructions?.length > 0 && Number(reports?.ConstructionClass) != 1 ? (reports?.SecondaryConstructions[ 0 ]?.FinishExteriorCombustibleType === 'CBSB' || reports?.SecondaryConstructions[ 0 ]?.FinishExteriorCombustibleType === 'FOAM' ? true : null) : null,
      finishInteriorCombustibleType: reports?.SecondaryConstructions?.length > 0 ? finishInteriorCombustibleType : null,
      finishInteriorPercentOfStoriesAffected: reports?.SecondaryConstructions?.length > 0 ? (reports?.SecondaryConstructions[ 0 ]?.FinishInteriorPercentOfStoriesAffected ? reports?.SecondaryConstructions[ 0 ]?.FinishInteriorPercentOfStoriesAffected : null) : null,
      finishInteriorFlameSpread: reports?.SecondaryConstructions?.length > 0 ? (reports?.SecondaryConstructions[ 0 ]?.FinishInteriorFlameSpread !== null && reports?.SecondaryConstructions[ 0 ]?.FinishInteriorFlameSpread !== undefined ? reports?.SecondaryConstructions[ 0 ]?.FinishInteriorFlameSpread : null) : null,
      finishInteriorSmokeDevelopment: reports?.SecondaryConstructions?.length > 0 ? (reports?.SecondaryConstructions[ 0 ]?.FinishInteriorSmokeDevelopment !== null && reports?.SecondaryConstructions[ 0 ]?.FinishInteriorSmokeDevelopment !== undefined ? reports?.SecondaryConstructions[ 0 ]?.FinishInteriorSmokeDevelopment : null) : null,
      isFinishInteriorUnlisted: reports?.SecondaryConstructions?.length > 0 ? (reports?.SecondaryConstructions[ 0 ]?.IsFinishInteriorUnlisted ? reports?.SecondaryConstructions[ 0 ]?.IsFinishInteriorUnlisted : null) : null,
      isFinishInteriorThermalBarrierPresent: reports?.SecondaryConstructions?.length > 0 ? (reports?.SecondaryConstructions[ 0 ]?.IsFinishInteriorThermalBarrierPresent ? reports?.SecondaryConstructions[ 0 ]?.IsFinishInteriorThermalBarrierPresent : null) : null,
      isFinishInteriorQualifiedAsSlowBurning: reports?.SecondaryConstructions?.length > 0 ? (reports?.SecondaryConstructions[ 0 ]?.IsFinishInteriorQualifiedAsSlowBurning ? reports?.SecondaryConstructions[ 0 ]?.IsFinishInteriorQualifiedAsSlowBurning : null) : null,

    finishExteriorCombustibleType: reports?.SecondaryConstructions?.length >0 ?  finishExteriorCombustibleType : null,
    finishExteriorPercentOfStoriesAffected: reports?.SecondaryConstructions?.length >0 ? ( reports?.SecondaryConstructions[0]?.FinishExteriorPercentOfStoriesAffected ?  reports?.SecondaryConstructions[0]?.FinishExteriorPercentOfStoriesAffected : null) : null,
    finishExteriorFlameSpread: reports?.SecondaryConstructions?.length >0 ? (reports?.SecondaryConstructions[0]?.FinishExteriorFlameSpread!== null &&  reports?.SecondaryConstructions[0]?.FinishExteriorFlameSpread!== undefined ?  reports?.SecondaryConstructions[0]?.FinishExteriorFlameSpread : null) : null,
    finishExteriorSmokeDevelopment: reports?.SecondaryConstructions?.length >0 ? ( reports?.SecondaryConstructions[0]?.FinishExteriorSmokeDevelopment !== null && reports?.SecondaryConstructions[0]?.FinishExteriorSmokeDevelopment !== undefined ?  reports?.SecondaryConstructions[0]?.FinishExteriorSmokeDevelopment : null) : null,
    isFinishExteriorUnlisted: reports?.SecondaryConstructions?.length >0 ? ( reports?.SecondaryConstructions[0]?.IsFinishExteriorUnlisted ?  reports?.SecondaryConstructions[0]?.IsFinishExteriorUnlisted : null) : null,
    isFinishExteriorThermalBarrierPresent: reports?.SecondaryConstructions?.length >0 ? ( reports?.SecondaryConstructions[0]?.IsFinishExteriorThermalBarrierPresent ?  reports?.SecondaryConstructions[0]?.IsFinishExteriorThermalBarrierPresent : null) : null,
    isFinishExteriorQualifiedAsSlowBurning: reports?.SecondaryConstructions?.length >0 ? (reports?.SecondaryConstructions[0]?.IsFinishExteriorQualifiedAsSlowBurning ?  reports?.SecondaryConstructions[0]?.IsFinishExteriorQualifiedAsSlowBurning : null) : null,
        };
    }

    /**
     * 
     */
    static to(model: any) {
       return
    }

    /**
     * Check if the model has changed
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     * @returns 
     */
    static hasChanged(formlyModel: any, riskReportModel: any): boolean {
        return true;
      
      
    }
}