/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { Inject, Injectable, Injector } from '@angular/core';
import { CalculationConfig } from './config/calculation.config';
import { FormlyBuilderService } from './formly-builder.service';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { AssetType, ConfigModel } from 'src/app/enums/config-model.enum';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { OrdersService } from '../orders/orders.service';
import { UtilService } from '../util-service/util.service';
import { FormlyErrorService } from './formly-error.service';
import { RiskReportService } from './risk-report.service';
import {
  CalculateFullRiskResponseModel, ResponseModel,
  ValidationErrorListModel, ValidationErrorModel
} from 'src/app/models/risk-report/calculate-full-risk-response.model';
import { ErrorModel } from 'src/app/models/formly-builder/error.model';
import { ErrorTypeEnum } from 'src/app/enums/formly-builder/error-group.enum';
import * as _ from 'lodash';
import { Constants } from '../util-service/constants';
import { ENV, Environment } from 'src/app/interfaces/env.interface';
import { AuthHttpService } from 'src/app/auth/auth-http.service';
import { RiskUIStatus } from 'src/app/enums/formly-builder/risk-ui-status.enum';
import { RiskModeEnum } from 'src/app/enums/formly-builder/risk-mode.enum';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { LoadingService } from '../loading-service/loading.service';
import { AmplifyService } from '../amplify/amplify.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { WindReportService } from './wind/wind-report.service';
import { SprinklerReportService } from './full-risk/sprinkler/sprinkler-report.service';
import { FormlyWrapperService } from './formly-wrapper.service';
import { SectionEnum } from './section.config';

export interface BackendError {
  type: number;
  label: string;
  path?: string;
}

/**
 * Class to implement the Calculations
 * - Create a CalculationConfig interface (calculation-config.interface.ts) that declares the
 *   needed info for each calculation to be registered to be executed.
 * - Create a Calculation config class (services/fb/config/calculation.config.ts) that
 *   exports a list of configured/registered calculations (CalculationConfig).
 * - Registered calculations would have an unique key (CalculationIdEnum, now it's a string), the associated class,...
 * - The existing interface should be modified so that methods return an
 *   Reactive/RxJS/Observable model for the calculate methods (calculate: Observable).
 * - Each calculation method should implement the existing updated interface.
 * - Now the CalculationService.calculate method would:
 *   - call all registered calculations
 *   - subscribe to responses and store them in memory to maintain a status of executed calcs.
 *   - [In different story] Maybe persist to local storage?
 */
@Injectable({
  providedIn: 'root'
})
export class CalculationService {
  public isNetworkConnection = true;
  riskReportService: RiskReportService;
  ngUnsubscribe = new Subject<void>();
  public isRiskReportLoaded = true;
  public windReport: any;


  constructor(
    public formlyBuilder: FormlyBuilderService,
    private localStorageService: LocalStorageService,
    private ordersService: OrdersService,
    private utilService: UtilService,
    private errorService: FormlyErrorService,
    private injector: Injector,
    private httpService: AuthHttpService,
    private loadingService: LoadingService,
    private amplify: AmplifyService,
    private windReportService: WindReportService,
    private sprinklerService: SprinklerReportService,
    @Inject(ENV) private environment: Environment,
    private wrapperService: FormlyWrapperService
  ) {
    this.riskReportService = this.formlyBuilder.riskReport;

    this.amplify.networkConnection.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      this.isNetworkConnection = value;
    });
  }

  public async calculate() {
    console.log('Calculate!');
    this.isRiskReportLoaded = false;
    // Parametrically execute every configured calculation in SectionConfig
    for (const calculateData of CalculationConfig) {
      if (calculateData.service) {
        const processInstance: SectionServiceAbstract = this.injector.get<SectionServiceAbstract>(
          calculateData.service
        );
        processInstance.calculate(this.formlyBuilder).subscribe((calculateStatus) => {
          console.log(
            `ServiceEnum ${calculateStatus.enum} Comment '${calculateStatus.comment}' Status ${calculateStatus.status}`
          );
        });
      }
    }

    // Save and sync risk report
    const testing = true;

    if (!testing) {
      const s3RiskReport = this.formlyBuilder?.riskReport?.report;
      const reportId = s3RiskReport.ReportIdentifier;

      // Save the RR to local, but don't force sync, since we are sending the RR as payload to the calculate API anyway
      this.localStorageService.saveFormOrRiskReportToLocal(
        s3RiskReport,
        ConfigModel.Order,
        this.ordersService.selectedOrderFromS3.OrderIdFullOrderNumber,
        AssetType.RiskReport,
        reportId,
        false
      );
    }

    // Calculate API
    this.onCalculateScore();
  }

  async onCalculateScore() {
    // Debounce?
    // this.isCalculateBtnClicked = true;

    // Save "synthetic" formly errors (errors created from custom code, not from formly code)

    // Get Risk Report
    const preRiskReport = _.cloneDeep(this.riskReportService.report);
    let flatRiskReport: any = this.riskReportService.report;
    flatRiskReport = await this.setValidRiskReport(flatRiskReport);
    // Maybe the palce to check the min requirement for sprinkler and risk report

    // mark all field as Touched
    this.formlyBuilder?.fields?.forEach((field: FormlyFieldConfig) => field?.formControl?.markAllAsTouched());
    this.sprinklerService.isCalculateApiHit = false;

    // Check total floor area and return warning message
    this.checkTotalArea();
    const oldSprinklerTypeValue = this.riskReportService.model.internalProtections.sprinklersData.sprinklerTypeCodeValue;
    if (this.isNetworkConnection && this.checkRiskReport(flatRiskReport)) {
      const preModel = _.cloneDeep(this.riskReportService.model);

      // Loading Spinner
      this.loadingService.showLoading(Constants.pleaseWaitMessage);

      // API endpoint
      this.calculateFullRisk(flatRiskReport).then(async (response) => {
        // console.debug('calculate API response:', response);
        this.utilService.addTextConsoleLog('calculate API RESPONSE', JSON.stringify(response));
        this.sprinklerService.isCalculateApiHit = true;

        // Process Full Risk Report response
        let newRiskReportData = this.riskReportService.flattensFullRiskReport(response.FullRiskReport);
        newRiskReportData = this.postCalculationReportFixes(flatRiskReport, newRiskReportData, preRiskReport);
        // Clone risk details
        newRiskReportData.ClonedFrom = flatRiskReport?.ClonedFrom;
        newRiskReportData.IsExistingRiskCloned = flatRiskReport?.IsExistingRiskCloned;
        newRiskReportData.IsNewRiskCloned = flatRiskReport?.IsNewRiskCloned;
        // Update FRM formly model with freshly obtained riskreport
        if (this.checkRiskReport(flatRiskReport)) {
          this.riskReportService.report = newRiskReportData;
          this.riskReportService.processS3RiskReport(newRiskReportData, RiskModeEnum.FULLRISK, true);
          this.postCalculationModelFixes(flatRiskReport, newRiskReportData, preModel);
          // Restore synthetic errors

          // To get new sprinklerTypeCodeValue on Calculate button click
          const InternalProtectionfield = this.formlyBuilder?.fields[ 0 ]?.fieldGroup[ 6 ]?.fieldGroup[ 0 ]?.fieldGroup[ 3 ]?.fieldGroup[ 1 ]?.fieldGroup[ 1 ];
          this.formlyBuilder.formlyInternalProtectionService.setSprinklerTypeFromModel(this.riskReportService.model, InternalProtectionfield);
          if (this.riskReportService?.model?.internalProtections?.sprinklersData?.sprinklerTypeCodeValue !== oldSprinklerTypeValue) {
            const sprinklerTypeError = {
              Id: 'RSKINT',
              Message: 'Mismatch in Sprinkler Code. Please recalculate',
              PropertyNames: [
                'RiskReport.SprinklerTypeCodeValue'
              ],
              ScopesVersions: [
                1
              ]
            };
            response?.ValidationErrorList?.RiskReportValidationErrors?.push(sprinklerTypeError);
          }
        }

        // Response Errors
        if (response && response.ValidationErrorList && Object.keys(response.ValidationErrorList)?.length > 0) {
          this.riskReportService.riskUIStatus = RiskUIStatus.ValidationIssues;
          this.processResponseErrors(response.ValidationErrorList, response.Response);

        } else {
          this.formlyBuilder.clearErrors();
          if (!this.isStatusError()) {
            this.riskReportService.riskUIStatus = RiskUIStatus.Completed;
            this.riskReportService.report.Status = Constants.completeStatus;
          } else {
            this.riskReportService.riskUIStatus = RiskUIStatus.ValidationIssues;
          }
        }
        // Save the RR to local storage
        this.riskReportService.saveFullRiskData(flatRiskReport?.SurveyDate);
        // this.riskReportService.saveFullRiskData(true);
      }).catch((error) => {
        this.sprinklerService.isCalculateApiHit = false;
        // If return is 400, 500 an APIErrorModel object is returned
        const errorList: ErrorModel[] = new Array();
        if (error?.data?.length) {
          error.data.forEach((errorString) => {
            errorList.push(this.getExternalError(errorString));
          });
        } else if (error.error && Array.isArray(error.error)) {
          error.error.forEach((errorString) => {
            errorList.push(this.getExternalError(errorString));
          });
        } else {
          let err;

          if (error.error?.Response) {
            const apiError: CalculateFullRiskResponseModel = error.error as CalculateFullRiskResponseModel;
            err = { type: ErrorTypeEnum.SYS_FAILURE, message: apiError.Response.ErrorMessage };
          } else if (error?.stack?.includes('TypeError')) {
            // Example: { message: "xxx", stack: "TypeError: xxxx" }
            err = { type: ErrorTypeEnum.SYS_FAILURE, message: Constants.BackendTypeError, id: Constants.UndefinedError };
          } else if (error?.message && !error.message.contains('undefined is not')) {
            err = { type: ErrorTypeEnum.ERROR_MESSAGE, message: error.message };
          } else {
            err = { type: ErrorTypeEnum.UNKNOWN, message: Constants.UndefinedErrorMessage };
          }

          if (err) {
            // undefined is not an object
            if (err.message?.includes('undefined is not')) {
              err.message = 'Unknown error';
            }
            errorList.push(err);
          }
        }

        // Update errors
        this.errorService.addExternalErrors(this.formlyBuilder.options, errorList);
        this.utilService.addTextConsoleLog('calculate API ERROR', JSON.stringify(error));
      }).finally(() => {
        this.loadingService.dismissLoading();
        this.errorService.notifyErrorsAvailable(this.formlyBuilder.options, true);
      });
    } else {
      // Even if we don't call the API, we need to show UI/Formly errors
      // For this we send the external error list as empty, but not null
      this.errorService.notifyErrorsAvailable(this.formlyBuilder.options, true);

      // If UI errors, set RiskUIStatus accordingly
      if (this.errorService.numberOfErrors > 0) {
        this.riskReportService.riskUIStatus = RiskUIStatus.ValidationIssues;

        // Update the RiskUIStatus if the status changed
        this.riskReportService.saveFullRiskData(flatRiskReport?.SurveyDate);
      }
    }
  }

  isStatusError(): boolean {
    const hasUIErrors = this.errorService?.hasUIErrors(this.formlyBuilder?.fields);
    if (this.formlyBuilder?.options?.formState?.externalErrors?.length || hasUIErrors) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * This fixes the Formly Model after retrieving the calculation API Risk Report
   *
   * @param preRiskReport Risk Report BEFORE hitting the calculation API
   * @param newRiskReport Risk Report AFTER hitting the calculation API
   * @param preModel Formly Model AFTER hitting the calculation API and processing - still need some changes
   */
  postCalculationModelFixes(preRiskReport: any, newRiskReport: any, preModel: any) {
    // Save the initial new model
    let newModel = this.riskReportService.model;

    // 1. Fix for Chargeable panels/columns
    const oldAreChargeableColumns = preModel?.areChargeableColumn?.wallsChargeableColumn;
    const oldAreChargeablePanels = preModel?.areChargeablePanels?.chargeablePanels;

    // Save the old values to the FRM
    if (newModel?.areChargeablePanels) {
      newModel.areChargeablePanels.chargeablePanels = oldAreChargeablePanels;
    }
    if (newModel?.areChargeableColumn) {
      newModel.areChargeableColumn.wallsChargeableColumn = oldAreChargeableColumns;
    }

    // 2. Fix for Defiency Points
    if (newModel && preRiskReport.EvidenceOfFireSprinkler === 'Y') {
      const defListV1 = [
        newRiskReport?.SprinklerReport?.WaterSupplyCharges,
        newRiskReport?.SprinklerReport?.SystemComponentsCharges,
        newRiskReport?.SprinklerReport?.SystemTestCharges,
        newRiskReport?.SprinklerReport?.NonSprinkleredObstructedCharges,
        newRiskReport?.SprinklerReport?.BuildingConditionsCharges,
        newRiskReport?.SprinklerReport?.RackStorageCharges,
      ];
      const defListV2 = [
        newRiskReport?.SprinklerReport?.WaterSupplyChargesV2,
        newRiskReport?.SprinklerReport?.SystemComponentsChargesV2,
        newRiskReport?.SprinklerReport?.SystemTestChargesV2,
        newRiskReport?.SprinklerReport?.NonSprinkleredObstructedChargesV2,
        newRiskReport?.SprinklerReport?.BuildingConditionsChargesV2,
        newRiskReport?.SprinklerReport?.RackStorageChargesV2,
      ];

      //added for testing, will remove once it works fine in lambda
      newModel = this.sprinklerService.loadData(
        newRiskReport,
        newRiskReport,
        true,
        newModel
      );
      newModel.sprinklerReport[ 'defListV1' ] = [ ...defListV1 ];
      newModel.sprinklerReport[ 'defListV2' ] = [ ...defListV2 ];
    }

    // Replace the new model with fixes
    if (newModel) {
      this.riskReportService.model = { ...newModel };
    }

    return newModel;
  }



  checkTotalArea() {
    this.formlyBuilder?.fields?.forEach(async (field: FormlyFieldConfig) => {
      const minTotalArea = field?.formControl?.value?.floorsAndRoofs?.buildingInformation?.totalArea;
      if (minTotalArea < 500 || minTotalArea >= 1000000) {
        this.riskReportService.riskUIStatus = RiskUIStatus.ValidationIssues;
        this.wrapperService.showToasterMessage(Constants.areaSmallOrLargeError);
      }
    });
  }

  /**
   * This is to check the Minimum Risk Report requirements
   *
   * @param flatRiskReport
   * @returns
   */
  checkRiskReport(flatRiskReport) {
    if (flatRiskReport?.Occupants && flatRiskReport?.Occupants?.length > 0 &&
      flatRiskReport?.ReportAddresses && flatRiskReport?.ReportAddresses?.length > 0 &&
      flatRiskReport?.ReportAddresses?.find(src => src?.IsAlias !== true) !== undefined &&
      flatRiskReport?.Walls && flatRiskReport?.Walls?.length > 0 &&
      flatRiskReport?.FloorsAndRoofs && flatRiskReport?.FloorsAndRoofs?.length > 1 &&
      flatRiskReport?.FloorsAndRoofs?.find(floor => floor?.IsLowestLevel === true) !== undefined &&
      flatRiskReport?.FloorsAndRoofs?.find(floor => floor?.LevelType === 'ROOF') !== undefined) {
      if (flatRiskReport?.EvidenceOfFireSprinkler !== 'Y') {
        return true;
      }
      else if (this.checkSprinklerReportMinRequirement(flatRiskReport)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Method to execute Calculation api
   */
  calculateFullRisk(payload: any): Promise<any> {
    payload = this.riskReportService.unflattensFullRiskReport(payload);
    if (payload.SprinklerReport && !Object.keys(payload.SprinklerReport).length) {
      payload.SprinklerReport = null;
    }
    // if (payload.WindReport && !(Object.keys(payload.WindReport).length > 1)) {
    //   payload.WindReport = {};
    // }
    console.log('calculate api payload:', payload);
    // const url = 'https://underwritingapit-internal-isurvey.iso.com/api/risks/calculate-full-risk';
    const url = this.environment.iSurveyAPIurl + Constants.calculateAPI;
    return this.httpService.request('POST', url, payload);
  }

  processResponseErrors(validationErrorList: ValidationErrorListModel, validationResponse: ResponseModel) {
    const errorList: ErrorModel[] = new Array();
    validationErrorList?.RiskReportValidationErrors?.forEach((error: ValidationErrorModel) => {
      errorList.push(this.getValidationError(error, ErrorTypeEnum.BACKEND_RR_ERROR));
    });

    validationErrorList?.SprinklerReportValidationErrors?.forEach((error: ValidationErrorModel) => {
      errorList.push(this.getValidationError(error, ErrorTypeEnum.BACKEND_SR_ERROR));
    });

    validationErrorList?.WindReportValidationErrors?.forEach((error: ValidationErrorModel) => {
      errorList.push(this.getValidationError(error, ErrorTypeEnum.BACKEND_WR_ERROR));
    });

    // Update errors
    this.errorService?.addExternalErrors(this.formlyBuilder.options, errorList);
  }

  checkSprinklerReportMinRequirement(flatRiskReport) {
    let pumpWithNoTestdate = null;
    let drainTestWithNoTestDate = null;
    let alarmTestWithNoTestDate = null;
    let dryPipeTestWithNoTestDate = null;
    // FirePumpTestDate, TwoInchDrianTestDate, AlarmAndSupervisoryTestDate, DryPipeValveTripTestDate
    // These are required in UI and also not nullable in BE, So if the any of these entries are there and idf the dates were not there
    // then block the scope call
    const surveyDate = flatRiskReport.SprinklerReport?.SurveyDate;
    const hydraulicDesignStandard = flatRiskReport.SprinklerReport?.AsgrWaterSupply?.HydraulicDesignStandard;
    // const buildingCombustibilityClass = flatRiskReport?.CombustibilityClass;
    const isSpocEntriesPresent = this.formlyBuilder?.options?.formState?.isHydraulicEntryPresent || this.formlyBuilder?.options?.formState?.isPipeScheduleEntryPresent;
    if (flatRiskReport?.SprinklerReport?.AsgrSprinklerSystemTest?.FirePumpTests?.length) {
      pumpWithNoTestdate = flatRiskReport.SprinklerReport.AsgrSprinklerSystemTest.FirePumpTests.find((firePumpTest) => !firePumpTest?.TestDate);
    }
    if (flatRiskReport?.SprinklerReport?.AsgrSprinklerSystemTest?.TwoInchDrainTests?.length) {
      drainTestWithNoTestDate = flatRiskReport.SprinklerReport.AsgrSprinklerSystemTest.TwoInchDrainTests.find((test) => !test?.TestDate);
    }
    if (flatRiskReport?.SprinklerReport?.AsgrSprinklerSystemTest?.AlarmAndSupervisoryTests?.length) {
      alarmTestWithNoTestDate = flatRiskReport.SprinklerReport.AsgrSprinklerSystemTest.AlarmAndSupervisoryTests.find((test) => !test?.TestDate);
    }
    if (flatRiskReport?.SprinklerReport?.AsgrSprinklerSystemTest?.DryPipeValveTripTests?.length) {
      dryPipeTestWithNoTestDate = flatRiskReport.SprinklerReport.AsgrSprinklerSystemTest.DryPipeValveTripTests.find((test) => !test?.TestDate);
    }
    return surveyDate && hydraulicDesignStandard && isSpocEntriesPresent && !pumpWithNoTestdate
      && !drainTestWithNoTestDate && !alarmTestWithNoTestDate && !dryPipeTestWithNoTestDate ? true : false;
  }

  async setValidSprinklerReport(flatRiskReport) {
    const isFirePumpTestNotApplicable = this.formlyBuilder.riskReport.model?.sprinklerReport?.asgrSprinklerSystemTest?.isFirePumpTestNotApplicable;
    const isDpvTestNotApplicable = this.formlyBuilder.dryPipeValveTestService.calculateIsDpvTestNotApplicable();
    const sprinklerDefaultData = await this.sprinklerService.updateFullSprinklerReport(this.riskReportService.model, flatRiskReport);
    flatRiskReport = {
      ...flatRiskReport,
      SprinklerReport: {
        ...flatRiskReport?.SprinklerReport,
        ...sprinklerDefaultData,
        AsgrSprinklerSystemTest: {
          ...flatRiskReport?.SprinklerReport?.AsgrSprinklerSystemTest,
          ...{ IsFirePumpTestNotApplicable: isFirePumpTestNotApplicable },
          ...{ IsDpvTestNotApplicable: isDpvTestNotApplicable }
        }
      }
    };
    return flatRiskReport;
  }

  /**
   * Patch the completed Risk Report with needed values from a correct Risk Report
   *
   * @param flatRiskReport
   * @returns
   */
  public async setValidRiskReport(flatRiskReport: any) {
    const exampleFlatRisk = {};
    let flatRisk = flatRiskReport;

    try {
      // Check if it's enabled and set/correct a valid SR
      if (flatRisk.EvidenceOfFireSprinkler !== 'Y') {
        flatRisk.SprinklerReport = null;
      } else {
        flatRisk.SprinklerReport.IsCustomService = false;
        flatRisk = await this.setValidSprinklerReport(flatRisk);
        if (flatRisk.IsSprinklerReportDeleted) {
          flatRisk.IsSprinklerReportDeleted = false;
        }
      }

      // Check if it's enabled and set/correct a valid SR
      flatRisk.CreationCodeValue = flatRisk.CreationCodeValue ? flatRisk.CreationCodeValue : '';
      flatRisk.UpdateCodeValue = flatRisk.UpdateCodeValue ? flatRisk.UpdateCodeValue : '';

      // Added this since, billing will only be modified later in the workflow
      flatRisk.BillingType = 'Quote';

      // Fix for Status since it cant be null we are defaulting it to empty string
      if (flatRisk.Status === null || flatRisk.Status === undefined) {
        flatRisk.Status = '';
      }

      // Check if it's enabled and set/correct a valid WR
      const windReportEnabled = this.formlyBuilder?.options?.formState?.isMainSectionDisabled?.filter(val => val.sectionId === SectionEnum.FR_WIND);;
      this.windReport = flatRisk.WindReport;
      if (windReportEnabled.length === 0) {
        const windData = this.windReport = await this.windReportService.updateFullWindReport(this.riskReportService.model, flatRisk);
        flatRisk = { ...flatRisk, ...{ WindReport: windData } };
        if (flatRisk.IsWindReportDeleted) {
          flatRisk.IsWindReportDeleted = false;
        }
      } else if (windReportEnabled.length === 1) {
        // Remove the WR branch
        flatRisk = { ...flatRisk, ...{ WindReport: null } };
      }

      // Update CSP
      if (!flatRisk.CommercialStatisticalPlanTerritoryCode) {
        flatRisk.CommercialStatisticalPlanTerritoryCode = flatRisk.TerritoryCode;
      }

      // Attachments. Avoid null attachment
      if (flatRiskReport?.ReportAttachments?.length) {
        flatRiskReport?.ReportAttachments.forEach((attachment) => {
          if (attachment.Attachment === null) {
            attachment.Attachment = '';
          }
        });
      }

      // Make sure the ColumnType is ColumnTypeConstants.None = " "
      if (!flatRiskReport?.ColumnType) {
        flatRisk.ColumnType = ' ';
      }
      // Make sure we are not sending '\u0000'
      flatRiskReport.ColumnType = flatRiskReport.ColumnType.replaceAll('\u0000', ' ');
      flatRiskReport.PanelConstructionType = flatRiskReport.PanelConstructionType.replaceAll('\u0000', ' ');

      // ConstructionPercentage should be integer in backend... We'll restore it to decimal as part of post processing
      flatRisk?.Walls?.forEach((wall) => {
        if (!Number.isInteger(wall.ConstructionPercentage)) {
          wall.ConstructionPercentage = Math.round(wall.ConstructionPercentage);
        }
      });

      // Avoid zero for SprinklerReport.AsgrWaterSupply.HydraulicDesignDensity
      if (flatRisk?.SprinklerReport?.AsgrWaterSupply?.HydraulicDesignDensity === 0) {
        flatRisk.SprinklerReport.AsgrWaterSupply.HydraulicDesignDensity = null;
      }

      // Avoid zero for ColumnWallThickness & PercentUnprotectedMetalColumnArea
      if (flatRisk?.ColumnWallThickness == 0 || flatRisk?.PercentUnprotectedMetalColumnArea == 0) {
        flatRisk.ColumnWallThickness = null;
        flatRisk.PercentUnprotectedMetalColumnArea = null;
      }
      if (flatRisk?.IsWindReportDeleted === null || flatRisk?.IsWindReportDeleted === undefined) {
        flatRisk.IsWindReportDeleted = false;
      }

    } catch (error) {
      console.error('setValidRiskReport');
      console.error(error);
    }
    return flatRisk;
  }

  /**
   * Clean the risk report response from the backend with incorrect values
   *
   * @param preRiskReport
   * @param postRiskReport
   * @returns
   */
  private postCalculationReportFixes(preRiskReport: any, postRiskReport: any, originalRiskReport: any): any {
    //
    // 1. if (we had a confirm and we lost it, then bring it back)
    if (preRiskReport?.RooftopLocationPoint?.Confirmed === true && !postRiskReport?.RooftopLocationPoint?.Confirmed) {
      postRiskReport.RooftopLocationPoint.Confirmed = true;
    }
    postRiskReport.WindReport = this.windReport;   // Retaining the wind report properties even when we disable it
    // 2. We use strings for width and height! The DTO uses numbers.
    postRiskReport?.Walls?.forEach((wall, index) => {
      if (typeof wall.Height === 'number') {
        wall.Height = '' + wall.Height;
      }

      if (typeof wall.Width === 'number') {
        wall.Width = '' + wall.Width;
      }

      // Restore ConstructionPercentage to original one with decimal support
      // if (originalRiskReport?.Walls?.length > index && originalRiskReport?.Walls[index].ConstructionPercentage) {
      //   wall.ConstructionPercentage = originalRiskReport?.Walls[index].ConstructionPercentage;
      // }
    });

    return postRiskReport;
  }

  private getExternalError(errorString: string): ErrorModel {
    if (errorString.startsWith('The JSON value could not be converted to')) {
      // The JSON value could not be converted to System.Nullable`1[System.Int16].
      // Path: $.RiskReport.YearBuilt | LineNumber: 0 | BytePositionInLine: 322.
      const regExp = /^The JSON value could not be converted to(.*).Path: \$.(.*) \| LineNumber: (.*) \| BytePositionInLine: (.*)./;
      const matches = errorString.match(regExp);
      // const matches = regExp.exec(errorString);

      // Check for Error Type
      const except = matches[ 1 ];
      const path = matches[ 2 ];
      const line = matches[ 3 ];
      const bytePosition = matches[ 4 ];

      // Check for Error Path
      return { type: ErrorTypeEnum.EXTERNAL, message: errorString, paths: [ path ] };
    } else {
      return { type: ErrorTypeEnum.EXTERNAL_REQUIRED, message: errorString };
    }
  }

  private getValidationError(error: ValidationErrorModel, type: ErrorTypeEnum): ErrorModel {
    return {
      type,
      id: error.Id,
      message: error.Message,
      paths: error.PropertyNames,
      scopesVersions: error.ScopesVersions
    };
  }

  /**
   * Traverse the whole object replacing the values for all properties that have
   * a specific values with the provided value
   *
   * @param inst
   * @param property
   * @param replaceValue
   * @returns
   */
  private setAllPropertiesWithName(inst: { [ key: string ]: any }, property: string, replaceValue: any) {
    const keyArray = Object.keys(inst);
    keyArray.forEach(currentKey => {
      // Traverse the structure if we have objects
      const node = inst[ currentKey ];

      if (Array.isArray(node)) {
        node.forEach((nodeItem) => {
          this.setAllPropertiesWithName(nodeItem, property, replaceValue);
        });
      } else if (node === Object(node)) {
        // It's an object, not a primitive
        this.setAllPropertiesWithName(node, property, replaceValue);
      } else {
        if (currentKey === property) {
          inst[ currentKey ] = replaceValue;
        }
      }
    });

    return inst;
  }

  private setDefaultValue(inst: any, property: string, value: any) {
    if (!inst[ property ] || inst[ property ] === '') {
      inst[ property ] = value;
    }
    return inst;
  }

  private setEmptyArray(inst: any, property: string, propClass: any) {
    inst[ property ] = new Array(propClass);
    return inst;
  }

  private setDefaultObject(inst: any, sectionName: string, property: string): any {
    const section = inst[ sectionName ];
    const prop = section ? section[ property ] : null;

    if (!section[ property ]) {
      section[ property ] = {};
    }

    return inst;
  }

  private setSectionValue(inst: any, sectionName: string, property: string, value: any): any {
    const section = inst[ sectionName ];
    const prop = section ? section[ property ] : null;

    if (!section[ property ]) {
      section[ property ] = value;
    }
    return inst;
  }

  private setProperty(inst: any, sectionPath: string, property: string, value: any): any {
    const sectionArray: string[] = sectionPath?.split('.');
    let section: any = inst;
    sectionArray.forEach((branch: string) => {
      if (!section[ branch ]) {
        section[ branch ] = {};
      }
      section = section[ branch ];
    });

    section[ property ] = value;
    return inst;
  }

}
