export enum ErrorCode {
  Unknown = 0,
  BadRequest = 400,
  Unauthorized = 401,
  UnProcessedEntity = 422,
  NotFound = 404,
  InternalServerError = 500,
  BadGateway = 502,
  serviceUnavailable = 503,
  GatewayTimeOut = 504
}

export interface ErrorMessage {
  header?: string;
  subHeader?: string;
  message?: string;
}

export const ErrorMessages = {
  // header, subHeader, message
  500: {
    subHeader:
      'An unexpected error has occurred - please try again. If this problem persists please contact Verisk Help Desk',
  },
  502: {
    subHeader: 'Error occurred while processing your request.',
  },
  503: {
    subHeader: 'Error occurred while processing your request.',
  },
  504: {
    subHeader: 'Error occurred while processing your request.',
  },
  400: {
    subHeader: 'There is issue in request, please try again later.',
  },
  401: {
    subHeader: 'Session has expired - Please login again',
  },
  404: {
    subHeader: 'Requested resource does not exist.',
  },
  422: {
    subHeader: 'Please enter valid & required details.',
  },
  0: {
    subHeader: 'Error occurred while processing your request.',
  },
  updateTargetStatusRequestError: {
    subHeader: 'This target is already in progress with another user. The target will be removed.',
  },
};
