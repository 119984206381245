/* eslint-disable no-underscore-dangle */
import { FormlyFieldConfig } from '@ngx-formly/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class BuildingCodesSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'buildingCodes',
      wrappers: [ 'simple-section-wrapper' ],
      props: {
        label: 'Building Codes',
        isCollapsable: true,
        isCollapsed: false,
        showError: true,
      },
      fieldGroupClassName: 'overview-building-codes formly-flex-container',
      fieldGroup: [
        {
          fieldGroupClassName: 'flex-container card-view display-space-between padding-left-15',
          className: 'full-width row-container',
          fieldGroup: [
            {
              className: 'align-items-center',
              template: '<span> Building Combustibility </span>'
            },
            {
              fieldGroupClassName: 'flex-container',
              fieldGroup: [
                {
                  className: 'align-items-center padding-left-20',
                  template: '<span>V<sup class="sup-color">1</sup> </span>'
                },
                {
                  key: 'combustibilityClass',
                  type: 'simple-input',
                  defaultValue: null,
                  className: 'align-items-center building-codes-input-fields',
                  props: {
                    label: 'Building Combustibility',
                    isLabelDisabled: true,
                    isDisabled: true,
                    isHorizontalOrientation: true,
                    readonly: true
                  }
                },
                {
                  className: 'align-items-center padding-left-20',
                  template: '<span>V<sup class="sup-color">2</sup> </span>',
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !field?.options?.formState?.service._isV2
                  }
                },
                {
                  key: 'combustibilityClassV2',
                  type: 'simple-input',
                  defaultValue: null,
                  className: 'align-items-center building-codes-input-fields',
                  props: {
                    label: 'Building Combustibility V2',
                    isLabelDisabled: true,
                    isDisabled: true,
                    isHorizontalOrientation: true,
                    readonly: true
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !field?.options?.formState?.service._isV2
                  }
                }
              ]
            }
          ]
        },
        { template: '<span></span>', className: 'new-row' },
        {
          fieldGroupClassName: 'flex-container card-view display-space-between padding-left-15',
          className: 'full-width row-container',
          fieldGroup: [
            {
              className: 'align-items-center',
              template: '<span> Building CSP </span>'
            },
            {
              fieldGroupClassName: 'flex-container',
              fieldGroup: [
                {
                  className: 'align-items-center padding-left-20',
                  template: '<span>V<sup class="sup-color">1</sup> </span>'
                },
                {
                  key: 'commercialStatisticalPlanCode',
                  type: 'simple-input',
                  defaultValue: null,
                  className: 'align-items-center building-codes-input-fields',
                  props: {
                    label: 'Building CSP',
                    isLabelDisabled: true,
                    isDisabled: true,
                    isHorizontalOrientation: true,
                    readonly: true
                  }
                },
                {
                  className: 'align-items-center padding-left-20',
                  template: '<span>V<sup class="sup-color">2</sup> </span>',
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !field?.options?.formState?.service._isV2
                  }
                },
                {
                  key: 'commercialStatisticalPlanCodeV2',
                  type: 'simple-input',
                  defaultValue: null,
                  className: 'align-items-center building-codes-input-fields',
                  props: {
                    label: 'Building CSP V2',
                    isLabelDisabled: true,
                    isDisabled: true,
                    isHorizontalOrientation: true,
                    readonly: true
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !field?.options?.formState?.service._isV2
                  }
                }
              ]
            }
          ]
        },
        { template: '<span></span>', className: 'new-row' },
        {
          fieldGroupClassName: 'flex-container card-view display-space-between padding-left-15',
          className: 'full-width row-container',
          fieldGroup: [
            {
              className: 'align-items-center',
              template: '<span> BGII CSP </span>'
            },
            {
              fieldGroupClassName: 'flex-container',
              fieldGroup: [
                {
                  className: 'align-items-center padding-left-20',
                  template: '<span>V<sup class="sup-color">1</sup> </span>'
                },
                {
                  key: 'buildingWindCommercialStatisticalPlanCodeValue',
                  type: 'simple-input',
                  defaultValue: null,
                  className: 'align-items-center building-codes-input-fields',
                  props: {
                    label: 'BGII CSP',
                    isLabelDisabled: true,
                    isDisabled: true,
                    isHorizontalOrientation: true,
                    readonly: true
                  }
                },
                {
                  className: 'align-items-center padding-left-20',
                  template: '<span>V<sup class="sup-color">2</sup> </span>',
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !field?.options?.formState?.service._isV2
                  }
                },
                {
                  key: 'buildingWindCommercialStatisticalPlanCodeValueV2',
                  type: 'simple-input',
                  defaultValue: null,
                  className: 'align-items-center building-codes-input-fields',
                  props: {
                    label: 'BGII CSP V2',
                    isLabelDisabled: true,
                    isDisabled: true,
                    isHorizontalOrientation: true,
                    readonly: true
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !field?.options?.formState?.service._isV2
                  }
                }
              ]
            }
          ]
        },
        { template: '<span></span>', className: 'new-row' },
        {
          fieldGroupClassName: 'flex-container card-view display-space-between padding-left-15',
          className: 'full-width row-container',
          fieldGroup: [
            {
              className: 'align-items-center',
              template: '<span> Building BGII </span>'
            },
            {
              fieldGroupClassName: 'flex-container',
              fieldGroup: [
                {
                  className: 'align-items-center padding-left-20',
                  template: '<span>V<sup class="sup-color">1</sup> </span>'
                },
                {
                  key: 'basicGroup2Symbol',
                  type: 'simple-select',
                  defaultValue: null,
                  className: 'align-items-center building-codes-select-fields',
                  props: {
                    label: 'Building BGII',
                    isLabelDisabled: true,
                    isDisabled: true,
                    required: false,
                    isHorizontalOrientation: true,
                    // readonly: true,
                    options: []
                  },
                  expressions: {
                    'props.isDisabled': (field: FormlyFieldConfig) => {
                      const formlyService = field.options.formState.service;
                      return formlyService.buildingCodesService.enableBuildingCodes(field) ? false : true;
                    },
                    'props.required': (field: FormlyFieldConfig) => {
                      const formlyService = field.options.formState.service;
                      return formlyService.buildingCodesService.enableBuildingCodes(field) ? true : false;
                    },
                    'props.className': (field?: FormlyFieldConfig) => {
                      const formlyService = field.options.formState.service;
                      return formlyService.buildingCodesService.enableBuildingCodes(field) ? '' : 'custom-disabled-background';
                    }
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field.formControl.markAsTouched();
                      const formlyService = field.options.formState.service;
                      if (formlyService.buildingCodesService.enableBuildingCodes(field)) {
                        field.props.options = [
                          { value: 'A', label: 'A' },
                          { value: 'B', label: 'B' },
                          { value: 'AB', label: 'AB' },
                          { value: 'C', label: 'C' },
                          { value: 'CD', label: 'CD' },
                          { value: 'D', label: 'D' },
                        ];
                      } else {
                        field.props.options = [
                          { value: 'B', label: 'B' },
                          { value: 'AB', label: 'AB' },
                          { value: 'A', label: 'A' },
                          { value: 'AA', label: 'AA' },
                          { value: '4B', label: '4B' },
                          { value: '3AB', label: '3AB' },
                          { value: '2A', label: '2A' },
                        ];
                      }
                      field.formControl?.valueChanges?.pipe(debounceTime(3000), distinctUntilChanged())?.subscribe(src => {
                        if (!field.formControl.pristine) {
                          formlyService.riskReport.updateSectionData(SectionEnum.FR_BUILDING_CODES, false);
                        }
                      });
                    }
                  }
                },
                {
                  className: 'align-items-center padding-left-20',
                  template: '<span>V<sup class="sup-color">2</sup> </span>',
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !field?.options?.formState?.service._isV2
                  }
                },
                {
                  key: 'basicGroup2SymbolV2',
                  type: 'simple-select',
                  defaultValue: null,
                  className: 'align-items-center building-codes-select-fields',
                  props: {
                    label: 'Building BGII V2',
                    isLabelDisabled: true,
                    isDisabled: true,
                    isHorizontalOrientation: true,
                    readonly: true,
                    options: [
                      { value: 'A', label: 'A' },
                      { value: 'B', label: 'B' },
                      { value: 'AB', label: 'AB' },
                      { value: 'C', label: 'C' },
                      { value: 'CD', label: 'CD' },
                      { value: 'D', label: 'D' },
                    ]
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !field?.options?.formState?.service._isV2
                  }
                }
              ]
            }
          ]
        }
      ]
    });
  }
}
