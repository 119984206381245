import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class OverAgeSprinklerTestDataModel {
    static sectionName = 'systemTesting';

    overAgeSprinklerTestId: string;
    reportId: string;
    systemNumber: string;
    sprinklerTypeCodeValue: string;
    testConductedBy: string;
    areaAffected?: number;
    areaAffectedComment?: string;
    yearOfIssue?: Date;
    yearOfIssueComment?: string;
    dateTested?: Date;
    testResults: string;
    actionsTaken?: string;
    notes?: string;
    comments?: string;

    static fromS3(overAgeSprinklerTestData: any): OverAgeSprinklerTestDataModel {

        let yearOfIssue = overAgeSprinklerTestData?.YearOfIssue;
        if (yearOfIssue && isNaN(yearOfIssue)) {
            yearOfIssue = new Date(yearOfIssue).getFullYear();
        }

        return {
            overAgeSprinklerTestId: overAgeSprinklerTestData.OverAgeSprinklerTestId,
            reportId: overAgeSprinklerTestData.ReportId,
            systemNumber: overAgeSprinklerTestData.SystemNumber,
            sprinklerTypeCodeValue: overAgeSprinklerTestData.SprinklerTypeCodeValue,
            testConductedBy: overAgeSprinklerTestData.TestConductedBy,
            areaAffected: overAgeSprinklerTestData.AreaAffected,
            areaAffectedComment: overAgeSprinklerTestData.AreaAffectedComment,
            yearOfIssue,
            yearOfIssueComment: overAgeSprinklerTestData.YearOfIssueComment,
            dateTested: overAgeSprinklerTestData.DateTested,
            testResults: overAgeSprinklerTestData.TestResults,
            actionsTaken: overAgeSprinklerTestData.ActionsTaken,
            notes: overAgeSprinklerTestData.Notes,
            comments: overAgeSprinklerTestData.Comments
        };
    }

    static to(model: any, sectionEnum?: SectionEnum) {
        return {
            OverAgeSprinklerTestId: model.overAgeSprinklerTestId,
            ReportId: model.reportId,
            SystemNumber: model.systemNumber ? model.systemNumber : '',
            SprinklerTypeCodeValue: model.sprinklerTypeCodeValue ? model.sprinklerTypeCodeValue : '',
            TestConductedBy: model.testConductedBy ? model.testConductedBy : '',
            AreaAffected: model?.areaAffected ? Number(model.areaAffected) : model.areaAffected,
            AreaAffectedComment: model.areaAffectedComment,
            YearOfIssue: model.yearOfIssue ? new Date(model.yearOfIssue, 0, 1, 0, 0, 0) : '' ,
            YearOfIssueComment: model.yearOfIssueComment,
            DateTested: model.dateTested ? model.dateTested: '',
            TestResults: model.testResults ? model.testResults : '',
            ActionsTaken: model.actionsTaken,
            Notes: model.notes,
            Comments: model.comments
        };
    }

    /**
     * Check if the model has changed
     *
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     *
     */
    static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
        return false;

    }
}
