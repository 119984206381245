import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class ScoreCardSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      type: 'score-card',
      props: {
        label: 'Sprinkler Score',
        subTitle: 'Sprinkler Score',
        title: 'Sprinkler Report',
        isCollapsed: false,
        isCloseBtn: true,
        sheetButtons: [
          {
            section: 'scoreCard',
            action: 'onClose',
            label: 'Close',
            service: 'scoreCardService',
            className: 'close-btn medium-btn'
          },
        ],
      },
      expressions: {
        'props.isDisabled': (field: FormlyFieldConfig) => field.options?.formState?.service?.mode !== 2,
      },
      fieldGroup: [ {
        type: 'deficiency-points',
        fieldGroup: [
          {
            props: {
              label: 'Score',
              version: 'v1',
              versionValue: null,
            },
            fieldGroup: [
              {
                key: 'waterSupplyPoints',
                props: {
                  label: 'Water Supply'
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.root?.get('evidenceOfFireSprinkler')?.value === true ||
                    field.form?.root?.get('evidenceOfFireSprinkler')?.value === 'true' ? false : true
                }
              },
              {
                key: 'systemComponentPoints',
                props: {
                  label: 'System Components'
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.root?.get('evidenceOfFireSprinkler')?.value === true ||
                    field.form?.root?.get('evidenceOfFireSprinkler')?.value === 'true' ? false : true
                }
              },
              {
                key: 'systemTestPoints',
                props: {
                  label: 'System Testing'
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.root?.get('evidenceOfFireSprinkler')?.value === true ||
                    field.form?.root?.get('evidenceOfFireSprinkler')?.value === 'true' ? false : true
                }
              },
              {
                key: 'nonSprinkleredObstructedAreaPoints',
                props: {
                  label: 'Non - Sprinklered/Obstructed Area'
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.root?.get('evidenceOfFireSprinkler')?.value === true ||
                    field.form?.root?.get('evidenceOfFireSprinkler')?.value === 'true' ? false : true
                }
              },
              {
                key: 'buildingConditionPoints',
                props: {
                  label: 'Building Conditions'
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.root?.get('evidenceOfFireSprinkler')?.value === true ||
                    field.form?.root?.get('evidenceOfFireSprinkler')?.value === 'true' ? false : true
                }
              },
              {
                key: 'rackStorageObstructionPoints',
                props: {
                  label: 'Rack Storage Obstructions'
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.root?.get('evidenceOfFireSprinkler')?.value === true ||
                    field.form?.root?.get('evidenceOfFireSprinkler')?.value === 'true' ? false : true
                }
              }
            ],
            expressions: {
              'props.versionValue': (field: FormlyFieldConfig) => {
                const formlyService = field.options?.formState?.service;
                const sprinklerReportService = formlyService?.sprinklerReportService;
                if (field) {  
                  if(sprinklerReportService?.isCalculateApiHit && (field.model?.reportScore || field.model?.reportScore === 0)){             
                  return field.model?.reportScore;
                  }else{
                    return null;
                  }
                }
              }
            }
          },
          {
            hide: true,
            props: {
              label: 'Score',
              version: 'v2',
              versionValue: null
            },
            expressions: {
              // hide: (field) => true,
              // TODO: enable V2 here if needed
              // !field.options.formState.v2Enabled,
              'props.versionValue': (field: FormlyFieldConfig) => {
                const formlyService = field.options.formState.service;
                const sprinklerReportService = formlyService.sprinklerReportService;
                if (field) {
                  if(sprinklerReportService.isCalculateApiHit && (field.model?.reportScoreV2 || field.model?.reportScoreV2 === 0)){
                  return field.model?.reportScoreV2;
                  }else{
                  return null;
                  }
                }
              }
            },

            fieldGroup: [
              {
                key: 'waterSupplyPointsV2',
                props: {
                  label: 'Water Supply'
                },
              },
              {
                key: 'systemComponentPointsV2',
                props: {
                  label: 'System Components'
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.root?.get('evidenceOfFireSprinkler')?.value === true ||
                    field.form?.root?.get('evidenceOfFireSprinkler')?.value === 'true' ? false : true
                }
              },
              {
                key: 'systemTestPointsV2',
                props: {
                  label: 'System Testing'
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.root?.get('evidenceOfFireSprinkler')?.value === true ||
                    field.form?.root?.get('evidenceOfFireSprinkler')?.value === 'true' ? false : true
                }
              },
              {
                key: 'nonSprinkleredObstructedAreaPointsV2',
                props: {
                  label: 'Non-Sprinklered/Obstructed Area'
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.root?.get('evidenceOfFireSprinkler')?.value === true ||
                    field.form?.root?.get('evidenceOfFireSprinkler')?.value === 'true' ? false : true
                }
              },
              {
                key: 'buildingConditionPointsV2',
                props: {
                  label: 'Building Conditions'
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.root?.get('evidenceOfFireSprinkler')?.value === true ||
                    field.form?.root?.get('evidenceOfFireSprinkler')?.value === 'true' ? false : true
                }
              },
              {
                key: 'rackStorageObstructionPointsV2',
                props: {
                  label: 'Rack Storage Obstructions'
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) => field.form?.root?.get('evidenceOfFireSprinkler')?.value === true ||
                    field.form?.root?.get('evidenceOfFireSprinkler')?.value === 'true' ? false : true
                }
              }
            ],
          }
        ]
      }

      ]
    });
  }
}
