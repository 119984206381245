/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyBuilderService } from '../formly-builder.service';
import { RoofEnvelopeDataModel } from 'src/app/models/formly-builder/formly/wind/roof-envelope-data.model';
import { ModalController } from '@ionic/angular';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SectionEnum } from '../section.config';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WindRoofEnvelopeService extends SectionServiceAbstract {
  constructor() {
    super();
  }

  async updateData(model: any, riskReportData: any): Promise<any> {
    // Check if data has changed
    const hasChanged = true;
    if (hasChanged) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const roofEnvelope = RoofEnvelopeDataModel.to(model?.windReport?.roofEnvelope, riskReportData?.ReportIdentifier);
      const windReportData = { ...riskReportData.WindReport, ...{ RoofEnvelope: roofEnvelope } };
      return { ...riskReportData, ...{ WindReport: windReportData } };
    } else {
      return undefined;
    }
  }

  loadData(roofEnvelope: any[], riskReport: any, isS3: boolean, model: any): any {
    return undefined;
  }

  onClose(modalCtrl, field, initialModel) {
    // TODO: Update to use generic Component
    // FormlyWrapperService.onClose(modalCtrl,field,initialModel);
    const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const reportData = formlyService.riskReport.report;
    const newModel = formlyService.windReportService.loadData(
      reportData,
      reportData,
      true,
      formlyService.riskReport.model
    );
    if (newModel) {
      formlyService.riskReport.model = { ...newModel };
    }
    //Getting initial state data, if we dont have any data initially we will set error as null on close
    let hasSomeValues = false;
    const roofEnvelope = field.options.formState.initialStateStore.get('windReport.roofEnvelope');
    // Undefine check
    if (roofEnvelope[ 'roofEnvelopeGeometry' ]) {
      const hasEnvelopeGeometry = Object.values(roofEnvelope[ 'roofEnvelopeGeometry' ]).some(value => value === undefined);
      hasSomeValues = roofEnvelope ? true : false;
      // If we have values we mark as touched
      if (hasSomeValues && !hasEnvelopeGeometry) {
        field.fieldGroup.forEach(field => {
          field.formControl.markAsTouched();
          field.fieldGroup?.forEach(field => {
            field.formControl?.markAsTouched();
          });
        });
      } else {
        // else if remove all errors
        field.fieldGroup.forEach(field => {
          field.formControl.setErrors(null);
          field.formControl?.markAsUntouched();
          field.fieldGroup?.forEach(field => {
            field.formControl.setErrors(null);
            field.formControl?.markAsUntouched();
          });
        });
      }
    }
    formlyService.errorService.closePreviousToaster();
    modalCtrl.dismiss();
  }

  async onApply(modal: ModalController, field: FormlyFieldConfig) {
    try {
      // Get values from the options...
      const checkboxOptions = field.fieldGroup[ 0 ].fieldGroup[ 0 ].props.options;
      const roofEnvelopeModel = {};
      checkboxOptions.forEach((option) => {
        roofEnvelopeModel[ option[ 'checkboxKey' ] ] = option[ 'isItemChecked' ] === true;
        roofEnvelopeModel[ option[ 'predominantKey' ] ] = option[ 'isPredominant' ] === true;
      });
      // Get values from the options...
      const checkboxOptionsEnvelopeGeometry = field.fieldGroup[ 1 ].fieldGroup[ 0 ].props.options;
      const roofEnvelopeGeometryModel = {};
      checkboxOptionsEnvelopeGeometry.forEach((option) => {
        roofEnvelopeGeometryModel[ option[ 'checkboxKey' ] ] = option[ 'isItemChecked' ] === true;
        roofEnvelopeGeometryModel[ option[ 'predominantKey' ] ] = option[ 'isPredominant' ] === true;
      });

      // update model
      const formlyBuilderService: FormlyBuilderService = field.options.formState.service;
      const roofEnvelope = {
        ...formlyBuilderService.riskReport.model.windReport.roofEnvelope,
        ...{
          roofEnvelopeContainer: roofEnvelopeModel,
          roofEnvelopeGeometry: roofEnvelopeGeometryModel
        }
      };


      formlyBuilderService.riskReport.model = {
        ...formlyBuilderService.riskReport.model,
        windReport: {
          ...formlyBuilderService.riskReport.model.windReport,
          ...{ roofEnvelope }
        }
      };


      // Save data to the Risk Report
      const riskReport = await formlyBuilderService.riskReport.updateSectionData(SectionEnum.FR_W_ROOF_ENVELOPE, false);

      const newModel = formlyBuilderService.windReportService.loadData(
        riskReport,
        riskReport,
        true,
        formlyBuilderService.riskReport.model
      );
      if (newModel) {
        formlyBuilderService.riskReport.model = { ...newModel };
      }
    } catch (ignore) { }

    // Dismiss the modal
    modal.dismiss();
  }

  initCheckboxes(field: FormlyFieldConfig, checkboxOptions: any[]) {
    try {
      // Get values from the options...
      const rootModel = field?.options?.formState?.service?.riskReport?.model;
      let checkboxGroupModel = rootModel;
      const branchArray = field?.props?.path.split('.');
      branchArray.forEach((branch) => {
        checkboxGroupModel = checkboxGroupModel[ branch ];
      });

      checkboxOptions.forEach((option) => {
        if (checkboxGroupModel) {
          if (checkboxGroupModel[ option[ 'checkboxKey' ] ] === true) {
            option[ 'isItemChecked' ] = true;
          }

          if (checkboxGroupModel[ option[ 'predominantKey' ] ] === true) {
            option[ 'isPredominant' ] = true;
          }
        }
      });
    } catch (ignore) { }

    // Load the options as props
    field.props.options = checkboxOptions;
  }

  setSolarpanelValue(field: FormlyFieldConfig) {
    // set initial value
    if (field?.form?.root?.value?.secondaryConstructions?.greenConstruction?.solarPanelCoverage) {
      const selectedsolarCoverage = field?.form?.root?.value?.secondaryConstructions?.greenConstruction?.solarPanelCoverage;
      const solarPanelCoverage = [ 'LE25', 'GT25', 'GT50', 'GT75' ];
      if (selectedsolarCoverage === 'NONE') {
        field.props.options[ 1 ].disabled = true;
        field.props.isDisabled = false;
      } else if (solarPanelCoverage.includes(selectedsolarCoverage)) {
        field.formControl.setValue('Yes');
        field.props.options[ 1 ].disabled = false;
        field.props.isDisabled = true;
      } else {
        field.props.isDisabled = false;
        field.props.options[ 1 ].disabled = false;
      }
    }
  }

  setRoofPerimiterCoveredBySolarPanels(field: FormlyFieldConfig) {
    if (field?.form?.root?.value?.secondaryConstructions?.greenConstruction?.solarPanelCoverage) {
      const selectedsolarCoverage = field?.form?.root?.value?.secondaryConstructions?.greenConstruction?.solarPanelCoverage;
      const solarPanelCoverage = [ 'LE25', 'GT25', 'GT50', 'GT75' ];
      const roofPerimiterCovered = [ '<= 25%', '>25% - 50%', '>50% - 75%', '>75%' ];
      const ind = solarPanelCoverage.indexOf(selectedsolarCoverage);
      if (ind !== -1) {
        field.formControl.setValue(roofPerimiterCovered[ ind ]);
        field.props.isDisabled = true;
      } else if (field.form?.get('solarPanels')?.value === 'No' || field.form?.get('solarPanels')?.value === 'NDSC') {
        field.formControl.setValue('Does not apply');
        field.props.isDisabled = true;
      } else {
        field.props.isDisabled = false;
      }
    }
    field.form
      ?.get('solarPanels')
      ?.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value === 'No' || value === 'NDSC') {
          field.formControl.setValue('Does not apply');
          field.props.isDisabled = true;
        } else {
          field.props.isDisabled = false;
        }
      });
  }

  setHailProtectedMountedRoofEquipment(field: FormlyFieldConfig) {
    if (field.form?.get('mechanicalEquipmentOnRoof')?.value) {
      const value = field.form.get('mechanicalEquipmentOnRoof').value;
      if (value === 'None') {
        field.formControl.setValue('NRME');
        field.props.isDisabled = true;
      } else if (value === 'NDSC') {
        field.formControl.setValue('NDSC');
        field.props.isDisabled = true;
      } else {
        field.props.isDisabled = false;
      }
    };
    field.form
      ?.get('mechanicalEquipmentOnRoof')
      ?.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value === 'None') {
          field.formControl.setValue('NRME');
          field.props.isDisabled = true;
        } else if (value === 'NDSC') {
          field.formControl.setValue('NDSC');
          field.props.isDisabled = true;
        } else {
          field.props.isDisabled = false;
        }
      });
  }

  //Set Average Height field based on Roof Perimeter
  setAverageHeightOfParapet(field: FormlyFieldConfig) {
    const roofHeight = field?.form?.get('roofPerimeterHigherThan6InchesParapet');

    if (field?.formControl?.value != null &&
      (field?.formControl?.value === 'NAPP' || field?.formControl?.value === 'NDSC')) {
      field?.props?.options?.forEach(x => {
        x[ 'disabled' ] = true;
      });
    }

    roofHeight.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((rfHeight) => {
      if (rfHeight != null && rfHeight === 'No parapet') {
        field?.formControl?.setValue('NAPP');
        field?.props?.options?.forEach(x => {
          x[ 'disabled' ] = true;
        });
      } else if (rfHeight != null && rfHeight === 'NDSC') {
        field?.formControl?.setValue('NDSC');
        field?.props?.options?.forEach(x => {
          x[ 'disabled' ] = true;
        });
      } else if (rfHeight != null) {
        field?.props?.options?.forEach(x => {
          if (x[ 'value' ] === 'NAPP' || x[ 'value' ] === 'NDSC') {
            x[ 'disabled' ] = true;
          } else {
            x[ 'disabled' ] = false;
          }
        });
        if (field?.formControl?.value == 'NAPP' || field?.formControl?.value == 'NDSC') {
          field?.formControl?.setValue(null);
        }
      }
    });
  }

  setAgeOfRoof(field: FormlyFieldConfig) {
    let ageOfRoofCover = 0;
    let yearOfRoofCover = 0;
    let isRoofCoveringAgeNull = true;
    const parentData = field.form.root.get('floorsAndRoofs');
    const constructionEntries = parentData?.get('constructionEntries').value;
    if (constructionEntries.floorsAndRoofsLevels != null
      && constructionEntries.floorsAndRoofsLevels.length > 0) {
      constructionEntries.floorsAndRoofsLevels.forEach(element => {
        if (element?.levelTypeCode === 'ROOF') {
          if (element?.yearOfRoofCover !== undefined && element?.yearOfRoofCover !== null) {
            isRoofCoveringAgeNull = false;
            yearOfRoofCover = Number(element?.yearOfRoofCover ?? 0) > Number(ageOfRoofCover) ? Number(element?.yearOfRoofCover) : Number(ageOfRoofCover);
          }
        }
      });
      const currentYear = new Date().getFullYear();
      ageOfRoofCover = currentYear - yearOfRoofCover;
      if (!isRoofCoveringAgeNull) {
        if (ageOfRoofCover >= 0 && ageOfRoofCover <= 10) {
          field?.formControl?.setValue('New - 10 years');
        } else if (ageOfRoofCover > 10 && ageOfRoofCover <= 20) {
          field?.formControl?.setValue('>10 - 20 years');
        } else if (ageOfRoofCover > 20) {
          field?.formControl?.setValue('>20 years');
        } else {
          field?.formControl?.setValue('UKWN');
        }
        field?.props?.options?.forEach(x => x[ 'disabled' ] = true);
      }
    }
  }

  setSkyLightsPresentOnRoof(field: FormlyFieldConfig) {
    const parentData = field.form.root.get('floorsAndRoofs');
    const bgData = parentData?.get('bg2Data').value;
    if (bgData.skylightsRecognition === 'RCGN' || bgData.skylightsRecognition === 'UNRC') {
      field?.formControl?.setValue(true);
    }
  }

  setSkyLightRoofRating(field: FormlyFieldConfig) {
    const parentData = field.form.root.get('floorsAndRoofs');
    const bgData = parentData?.get('bg2Data').value;
    if (bgData.skylightsRecognition === 'RCGN') {
      field?.formControl?.setValue('UL 90');
    }
    const hasSkyLight = field.form.get('hasSkyLightsPresentOnRoof');
    if (hasSkyLight?.value === false || hasSkyLight?.value === 'false') {
      field.formControl?.setValue('NAPP');
      field.props?.options?.forEach(element => {
        element[ 'disabled' ] = true;
      });
    } else {
      field.props?.options?.forEach(element => {
        element[ 'disabled' ] = false;
      });
    }

    hasSkyLight.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((skyLight) => {
      field.form.get('skyLightsRoofRatingTypeComment')?.setValue(null);
      if (skyLight === false || skyLight === 'false') {
        field.formControl?.setValue('NAPP');
        field.props?.options?.forEach(element => {
          element[ 'disabled' ] = true;
        });
      }
      if (skyLight === true || skyLight === 'true') {
        field.props?.options?.forEach(element => {
          element[ 'disabled' ] = false;
        });
      }
    });
  }

  markAsTouched(field: FormlyFieldConfig) {
    const roofEnvelope = field.options.formState.initialStateStore.get('windReport.roofEnvelope');
    const hasSomeValues = roofEnvelope?.roofFeatures ? (Object.values(roofEnvelope?.roofFeatures)?.filter(x => x !== undefined && x !== null)?.length > 0 ? true : false) : false;
    const predominantHasSomeValues = roofEnvelope?.roofEnvelopeContainer ? (Object.values(roofEnvelope?.roofEnvelopeContainer)?.filter(x => x !== undefined && x !== null)?.length > 0 ? true : false) : false;

    if (hasSomeValues || predominantHasSomeValues) {
      field.formControl?.markAsTouched();
      field.fieldGroup?.forEach(field => field.formControl?.markAsTouched());
    } else {
      field.formControl?.markAsUntouched();
      field.formControl?.setErrors(null);
      field.fieldGroup?.forEach(field => field.formControl?.markAsUntouched());
    }
  }
}
