import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
Injectable({
    providedIn: 'root',
  })
  export class OneDriveAuthObserver {
    private isMSAuthenticatedSubject = new BehaviorSubject<string>(null);
    public isMSAuthenticated = this.isMSAuthenticatedSubject.asObservable();

    setMSAuthSubject(v: string){
      console.log('Setting new MS Auth Subject', v);
      this.isMSAuthenticatedSubject.next(v);
    }
  }