import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { maxLengthForNumber, numberCheck } from 'src/app/validators/formly-builder/validators';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
export class EnhancedWindSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyService: FormlyBuilderService) {
    super({
      key: 'enhancedWindRatingEligibility',
      type: 'rf-sheet-section',
      fieldGroupClassName: 'enhanced-sheet-container',
      props: {
        label: 'Enhanced Wind',
        title: 'Wind Report',
        subTitle: 'Enhanced Wind',
        isCollapsable: true,
        isCollapsed: false,
        isDisableable: true,
        actionButtons: [
          {
            section: 'windReport',
            action: 'showFormlySheet',
            label: 'Add',
            isDisabled: false,
            service: 'formlyWrapperService',
          },
        ],
        sheetButtons: [
          {
            section: 'windReport',
            action: 'onClose',
            label: 'Close',
            service: 'enhancedWindService',
            className: 'close-btn medium-btn',
            passInitialModel: true,
          },
          {
            section: 'windReport',
            action: 'onApply',
            hasConfirmationPopup: true,
            label: 'Apply',
            service: 'enhancedWindService',
            className: 'apply-btn large-btn',
          },
        ],
      },
      fieldGroup: [
        {
          className: 'enhanced-wind-group-container outside-section',
          wrappers: [ 'simple-section-wrapper' ],
          props: {
            hasTitleRow: false,
            showError: false,
            checkTouchedControl: true
          },
          fieldGroup: [
            {
              wrappers: [ 'question-wrapper' ],
              props: {
                label: 'Square Footage of the building',
                isHorizontalOrientation: true,
                hasShadow: true,
                required: true,
                showError: false,
                isAsteriskNotRequired: true,
                firstColSize: 5,
                isSectionLabelNormal: true,
              },
              fieldGroupClassName: 'formly-flex-container',
              fieldGroup: [
                {
                  key: 'totalArea',
                  type: 'simple-input',
                  className: 'no-padding',
                  props: {
                    unit: 'sq.ft.',
                    type: 'number',
                    readonly: true,
                    isAsteriskNotRequired: true,
                    showError: false,
                    checkTouchedControl: true,
                    maxLength: 12
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      if (formlyService.occupantsService.getOccupancyTotalArea(field)) {
                        field?.formControl?.setValue(formlyService.occupantsService.getOccupancyTotalArea(field));
                      }
                    },
                  },
                },
              ],
            },
            {
              wrappers: [ 'question-wrapper' ],
              props: {
                label: 'Geographic Risk Factor',
                isAsteriskNotRequired: true,
                firstColSize: 3.5,
                hasShadow: true,
                required: true,
                showError: false,
                isSectionLabelNormal: true,
              },
              fieldGroupClassName: 'formly-flex-container full-width',
              fieldGroup: [
                {
                  key: 'geographicRiskFactor',
                  type: 'rf-radio',
                  className: 'radio-group',
                  props: {
                    isHorizontalOrientation: true,
                    slot: 'start',
                    rightAlign: true,
                    ripplerColor: 'grey',
                    checkTouchedControl: true,
                    options: [
                      { label: 'Medium', value: 'Medium', disabled: true },
                      { label: 'High', value: 'High', disabled: true },
                      { label: 'Severe', value: 'Severe', disabled: true },
                    ],
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      if (field?.options?.formState?.rr?.GeographicRiskFactor) {
                        // For geographic risk factor value get from risk report order details
                        field?.formControl?.setValue(field?.options?.formState?.rr?.GeographicRiskFactor?.trim());
                      }
                    },
                  },
                },
              ],
            },
            {
              wrappers: [ 'simple-section-wrapper' ],
              key: 'sharedWindCharacteristics',
              className: 'narrow-section-wrapper',
              props: {
                label: 'Does this Risk ID share wind characteristics with adjoining Risk IDs?',
                checkTouchedControl: true,
                isAsteriskNotRequired: true,
                isCollapsable: true,
                toggleYesNo: true,
                showError: true,
                shallowErrors: false,
                isSectionLabelNormal: true,
              },
              expressions: {
                'props.isCollapsed': (field: FormlyFieldConfig) => !field?.model?.hasSharedWindCharacteristics
              },
              validators: {
                atleastOneoption: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const comment = field?.model?.hasSharedWindCharacteristics;
                    if (comment) {
                      if (field?.model?.sharedWindCharacteristicsRisks === undefined ||
                        field?.model?.sharedWindCharacteristicsRisks?.length === 0) {
                        return false;
                      }
                    }
                    return true;
                  },
                  message: 'At least one entry is required',
                },
              },
              fieldGroup: [
                {
                  key: 'hasSharedWindCharacteristics',
                  defaultValue: null,
                  props: {
                    label: 'Share wind characteristics',
                    required: true
                  },
                  hooks: {
                    onInit: (field) => {
                      if (field.formControl?.value === 'true' || field.formControl?.value === true) {
                        field.formControl?.markAllAsTouched();
                      }
                      field.formControl?.valueChanges.subscribe((value) => {
                        field.formControl?.markAllAsTouched();
                      });
                    }
                  },
                },
                {
                  type: 'rf-common-array',
                  key: 'sharedWindCharacteristicsRisks',
                  props: {
                    isSwipeGesture: true,
                    isSectionToggleReset: true,
                    sectionToggleKey: 'hasSharedWindCharacteristics',
                    deleteAllModelKey: 'sharedWindCharacteristicsRisks',
                  },
                  fieldArray: {
                    className: 'flex-array-contanier',
                    fieldGroup: [
                      {
                        type: 'simple-input',
                        key: 'riskId',
                        className: 'width-30',
                        props: {
                          label: 'Risk ID',
                          isAsteriskNotRequired: true,
                          required: true,
                          maxLength: 12
                        },
                        expressions: {
                          'props.disabled': (field: FormlyFieldConfig) => {
                            if (field.form?.get('address')?.value) {
                              if (field?.props?.required) {
                                field.props.required = false;
                              }
                              field?.formControl?.setValue('');
                              return true;
                            } else {
                              if (field?.props?.required) {
                                field.props.required = true;
                              }
                              return false;
                            }
                          },
                        },
                        validators: {
                          validation: [ {
                            name: 'alphanumeric-validator'
                          } ]
                        },
                      },
                      {
                        key: 'address',
                        type: 'simple-input',
                        className: 'width-auto',
                        props: {
                          label: 'Street Address',
                          isAsteriskNotRequired: true,
                          required: true,
                          maxLength: 120
                        },
                        expressions: {
                          'props.disabled': (field: FormlyFieldConfig) => {
                            if (field.form?.get('riskId')?.value) {
                              if (field?.props?.required) {
                                field.props.required = false;
                              }
                              field?.formControl?.setValue('');
                              return true;
                            } else {
                              if (field?.props?.required) {
                                field.props.required = true;
                              }
                              return false;
                            }
                          },
                        },
                      },
                      { key: 'wasManuallyEntered' },
                      {
                        key: 'doesRiskShareCharacteristics',
                        defaultValue: true
                      },
                      { key: 'city' },
                      { key: 'stateCode' },
                      { key: 'zip' },
                      { key: 'zip4' }
                    ],
                  },
                  expressions: {
                    hide: (field) => !field?.form?.get('hasSharedWindCharacteristics')?.value
                  }
                },
              ]
            },
            {
              wrappers: [ 'simple-section-wrapper' ],
              key: 'hasRoofFullyObservable',
              className: 'swiper-checkbox-wrapper narrow-section-wrapper',
              props: {
                // hasTitleRow: false,
                label: 'Was at least 90% of the roof observable during the field survey?',
                isAsteriskNotRequired: true,
                isCollapsable: true,
                checkTouchedControl: true,
                toggleYesNo: true,
                showError: true,
                isSwipeGesture: true,
                commentActionKey: 'roofFullyObservableComment',
                maxLength: 100
              },
              expressions: {
                'props.isCollapsed': (field: FormlyFieldConfig) => !field?.model?.isRoofFullyObservable
              },
              validators: {
                atleastOneoption: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const comment = field?.model?.isRoofFullyObservable;
                    if (comment) {
                      // field.props.isSwipeGesture = false;
                      let count = 0;
                      for (const property in field.model) {
                        if (field.model[ property ] === true) {
                          count++;
                        }
                      }
                      if (count < 2) {
                        return false;
                      }
                    }
                    return true;
                  },
                  message: 'If yes, at least one roof observable type must be selected',
                },
              },
              fieldGroup: [
                {
                  key: 'isRoofFullyObservable',
                  defaultValue: null,
                  props: {
                    label: 'Roof observable',
                    required: true
                  },
                  hooks: {
                    onInit: (field) => {
                      if (field.formControl?.value === 'true' || field.formControl?.value === true) {
                        field.formControl?.markAllAsTouched();
                      }
                      field.formControl?.valueChanges.subscribe((value) => {
                        field.formControl?.markAllAsTouched();
                      });
                      return formlyService.enhancedWindService.isroofObservableDuringSurveyClear(field);
                    }
                  },
                  validators: {
                    fieldCommentValidation: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        const comment = field?.form?.get('roofFullyObservableComment')?.value;
                        const value = control.value;
                        if (value !== null && (value === 'false' || value === false)) {
                          if (comment !== undefined && comment !== null && comment?.length > 0) {
                            return true;
                          } else {
                            return false;
                          }
                        }
                        return true;
                      },
                      message: 'Roof observable Comment is Required',
                    },
                  },
                },
                {
                  template: 'How was the roof observed?',
                  className: 'label-weight',
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !field?.model?.isRoofFullyObservable
                  }
                },
                {
                  key: 'isRoofObservablePhysically',
                  type: 'custom-checkbox',
                  wrappers: [ 'question-wrapper' ],
                  defaultValue: false,
                  props: {
                    label: 'Physically on the roof',
                    isHorizontalOrientation: true,
                    firstColSize: 8,
                    isSectionLabelNormal: true,
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !field?.model?.isRoofFullyObservable
                  },
                },
                {
                  key: 'isRoofObservableGradeLevel',
                  type: 'custom-checkbox',
                  wrappers: [ 'question-wrapper' ],
                  defaultValue: false,
                  props: {
                    label: 'Grade Level Observation (Peaked Roof)',
                    isHorizontalOrientation: true,
                    firstColSize: 8,
                    isSectionLabelNormal: true,
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !field?.model?.isRoofFullyObservable
                  },
                },
                {
                  key: 'isRoofObservableFromLadder',
                  type: 'custom-checkbox',
                  wrappers: [ 'question-wrapper' ],
                  defaultValue: false,
                  props: {
                    label: 'Observed from ladder',
                    isHorizontalOrientation: true,
                    firstColSize: 8,
                    isSectionLabelNormal: true,
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !field?.model?.isRoofFullyObservable
                  },
                },
                {
                  key: 'isRoofObservableAdjacentVantagePoint',
                  type: 'custom-checkbox',
                  wrappers: [ 'question-wrapper' ],
                  defaultValue: false,
                  props: {
                    label: 'Adjacent vantage point',
                    isHorizontalOrientation: true,
                    firstColSize: 8,
                    isSectionLabelNormal: true,
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !field?.model?.isRoofFullyObservable
                  },
                },
                {
                  key: 'isRoofObservableViewingDevice',
                  type: 'custom-checkbox',
                  wrappers: [ 'question-wrapper' ],
                  defaultValue: false,
                  props: {
                    label: 'Camera / Electronic viewing device',
                    isHorizontalOrientation: true,
                    firstColSize: 8,
                    isSectionLabelNormal: true,
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !field?.model?.isRoofFullyObservable
                  },
                },
                {
                  // Comment key for section
                  key: 'roofFullyObservableComment',
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field.formControl?.valueChanges
                        .subscribe((value) => field?.form?.get('isRoofFullyObservable')?.updateValueAndValidity());
                    }
                  }
                },
                {
                  // Comment key for other key
                  key: 'roofFullyObservableTypeOtherDescription',
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field.formControl?.valueChanges
                        .subscribe((value) => field?.form?.get('isRoofObservableOther')?.updateValueAndValidity());
                    }
                  }
                },
                {
                  key: 'isRoofObservableOther',
                  type: 'custom-checkbox',
                  wrappers: [ 'question-wrapper' ],
                  defaultValue: false,
                  className: 'swiper-checkbox-question swiper-starting-padding',
                  props: {
                    label: 'Other',
                    isHorizontalOrientation: true,
                    isCommentActionEnabled: true,
                    showError: false,
                    commentActionKey: 'roofFullyObservableTypeOtherDescription',
                    firstColSize: 8,
                    isSectionLabelNormal: true,
                    maxLength: 100
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => !field?.model?.isRoofFullyObservable
                  },
                  validators: {
                    fieldCommentValidation: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        const comment = field.form?.get('roofFullyObservableTypeOtherDescription')?.value;
                        const value = control?.value;
                        if (value !== null && (value === 'true' || value === true)) {
                          field.props.isSwipeGesture = true;
                          if (comment !== undefined && comment !== null && comment?.length > 0) {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          field.props.isSwipeGesture = false;
                        }
                        return true;
                      },
                      message: 'Other Comment is Required',
                    },
                  },
                },
              ]
            }
          ],
        },
        {
          wrappers: [ 'simple-section-wrapper' ],
          key: 'hasRoofExposureHazardByOtherRisks',
          className: 'enhanced-simple-section-container',
          props: {
            label: 'Is there a Roof Exposure Hazard to this Risk ID from one or more other Risk ID\'s that share wind characteristics with this Risk ID',
            checkTouchedControl: true,
            isAsteriskNotRequired: true,
            isCollapsable: true,
            isCollapsed: true,
            toggleYesNo: true,
          },
          expressions: {
            'props.isCollapsed': (field: FormlyFieldConfig) => !field?.model?.isRoofExposureHazardByOtherRisks,
            'props.showError': (field: FormlyFieldConfig) => field?.model?.isRoofExposureHazardByOtherRisks
          },
          validators: {
            atleastOneoption: {
              expression: (control: FormControl, field: FormlyFieldConfig) => {
                const comment = field?.model?.isRoofExposureHazardByOtherRisks;
                if (comment) {
                  let count = 0;
                  for (const property in field.model) {
                    if (field.model[ property ]) {
                      count++;
                    }
                  }
                  if (count < 2) {
                    return false;
                  }
                }
                return true;
              },
              message: 'If yes, at least one roof exposure hazard type must be selected',
            },
          },
          fieldGroup: [
            {
              key: 'isRoofExposureHazardByOtherRisks',
              defaultValue: null,
              props: {
                label: 'Roof exposure hazard',
                required: true
              },
              hooks: {
                onInit: (field) => formlyService.enhancedWindService.isRoofExposureHazardByOtherRisksClear(field)
              },
            },
            {
              key: 'isRoofExposureHazardParapetFlashingCondition',
              type: 'custom-checkbox',
              wrappers: [ 'question-wrapper' ],
              defaultValue: false,
              props: {
                label: '\'Fair\' or \'Poor\' roof / wall parapet flashing condition',
                isHorizontalOrientation: true,
                firstColSize: 8,
                isSectionLabelNormal: true,
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => !field?.model?.isRoofExposureHazardByOtherRisks
              },
            },
            {
              key: 'isRoofExposureHazardEquipmentUnsecured',
              type: 'custom-checkbox',
              wrappers: [ 'question-wrapper' ],
              defaultValue: false,
              props: {
                label: 'Some or all mechanical equipment on roof is unsecured',
                isHorizontalOrientation: true,
                firstColSize: 8,
                isSectionLabelNormal: true,
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => !field?.model?.isRoofExposureHazardByOtherRisks
              },
            },
            {
              key: 'isRoofExposureHazardLightlyConstructedRoofOrPenthouse',
              type: 'custom-checkbox',
              wrappers: [ 'question-wrapper' ],
              defaultValue: false,
              props: {
                label: 'Lightly constructed penthouse or roof structures',
                isHorizontalOrientation: true,
                firstColSize: 8,
                isSectionLabelNormal: true,
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => !field?.model?.isRoofExposureHazardByOtherRisks
              },
            },
            {
              key: 'isRoofExposureHazardRoofCoverDegradation',
              type: 'custom-checkbox',
              wrappers: [ 'question-wrapper' ],
              defaultValue: false,
              props: {
                label: '\'Moderate or severe\' roof cover degradation',
                isHorizontalOrientation: true,
                firstColSize: 8,
                isSectionLabelNormal: true,
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => !field?.model?.isRoofExposureHazardByOtherRisks
              },
            },
            {
              key: 'isRoofExposureHazardAntennasOrLights',
              type: 'custom-checkbox',
              wrappers: [ 'question-wrapper' ],
              defaultValue: false,
              props: {
                label: 'Stacks, antenna, satellite dishes, or lights on the roof',
                isHorizontalOrientation: true,
                firstColSize: 8,
                isSectionLabelNormal: true,
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => !field?.model?.isRoofExposureHazardByOtherRisks
              },
            },
          ]
        },
        {
          wrappers: [ 'simple-section-wrapper' ],
          key: 'hasWallExposureHazardByOtherRisks',
          className: 'enhanced-simple-section-container',
          props: {
            showError: false,
            checkTouchedControl: true,
            isAsteriskNotRequired: true,
            label: 'Is there a Wall Exposure Hazard to this Risk ID from one or more other Risk ID\'s that share wind characteristics with this Risk ID',
            isCollapsable: true,
            isCollapsed: true,
            toggleYesNo: true,
          },
          expressions: {
            'props.isCollapsed': (field: FormlyFieldConfig) => !field?.model?.isWallExposureHazardByOtherRisks,
            'props.showError': (field: FormlyFieldConfig) => field?.model?.isWallExposureHazardByOtherRisks
          },
          validators: {
            atleastOneoption: {
              expression: (control: FormControl, field: FormlyFieldConfig) => {
                const comment = field?.model?.isWallExposureHazardByOtherRisks;
                if (comment) {
                  let count = 0;
                  for (const property in field.model) {
                    if (field.model[ property ]) {
                      count++;
                    }
                  }
                  if (count < 2) {
                    return false;
                  }
                }
                return true;
              },
              message: 'If yes, at least one wall exposure hazard type must be selected',
            },
          },
          fieldGroup: [
            {
              key: 'isWallExposureHazardByOtherRisks',
              props: {
                label: 'Wall exposure hazard',
                required: true
              },
              hooks: {
                onInit: (field) => formlyService.enhancedWindService.isWallExposureHazardByOtherRisksClear(field)
              },
            },
            {
              key: 'isWallExposureHazardEIFSOnMasonry',
              type: 'custom-checkbox',
              wrappers: [ 'question-wrapper' ],
              defaultValue: false,
              props: {
                label: 'EIFS on masonry wall crosses firewall',
                isHorizontalOrientation: true,
                firstColSize: 8,
                isSectionLabelNormal: true,
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => !field?.model?.isWallExposureHazardByOtherRisks
              },
            },
            {
              key: 'isWallExposureHazardCommonCanopy',
              type: 'custom-checkbox',
              wrappers: [ 'question-wrapper' ],
              defaultValue: false,
              props: {
                label: 'Common canopy crosses firewall',
                isHorizontalOrientation: true,
                firstColSize: 8,
                isSectionLabelNormal: true,
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => !field?.model?.isWallExposureHazardByOtherRisks
              }
            },
            {
              key: 'isWallExposureHazardArchitecturalAppurtenances',
              type: 'custom-checkbox',
              wrappers: [ 'question-wrapper' ],
              defaultValue: false,
              props: {
                label: 'Signs or architectural appurtenances crosses firewall',
                isHorizontalOrientation: true,
                firstColSize: 8,
                isSectionLabelNormal: true,
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => !field?.model?.isWallExposureHazardByOtherRisks
              }
            },
          ],
        },
        {
          wrappers: [ 'simple-section-wrapper' ],
          key: 'hasPotentialExposureCreditApplicable',
          className: 'enhanced-simple-section-container',
          props: {
            label: 'Is there a potential Exposure Credit applicable to this building due to communications rules that have merged otherwise separately rated Risk ID\'s into one rating division',
            checkTouchedControl: true,
            isAsteriskNotRequired: true,
            isCollapsable: true,
            isCollapsed: true,
            toggleYesNo: true
          },
          expressions: {
            'props.isCollapsed': (field: FormlyFieldConfig) => !field?.model?.isPotentialExposureCreditApplicable,
            'props.showError': (field: FormlyFieldConfig) => field?.model?.isPotentialExposureCreditApplicable,
          },
          validators: {
            atleastOneoption: {
              expression: (control: FormControl, field: FormlyFieldConfig) => {
                const comment = field?.model?.isPotentialExposureCreditApplicable;
                if (comment) {
                  let count = 0;
                  for (const property in field.model) {
                    if (field.model[ property ]) {
                      count++;
                    }
                  }
                  if (count < 2) {
                    return false;
                  }
                }
                return true;
              },
              message: 'If yes, at least one potential exposure credit type must be selected',
            },
          },
          fieldGroup: [
            {
              key: 'isPotentialExposureCreditApplicable',
              defaultValue: null,
              props: {
                label: 'Potential exposure credit',
                required: true
              },
              hooks: {
                onInit: (field) => formlyService.enhancedWindService.isPotentialExposureCreditApplicableClear(field)
              },
            },
            {
              key: 'isPotentialExposureCreditCanopiesCircumventParapetedFireWalls',
              type: 'custom-checkbox',
              wrappers: [ 'question-wrapper' ],
              defaultValue: false,
              props: {
                label: 'Canopies circumvent parapeted fire walls',
                isHorizontalOrientation: true,
                firstColSize: 8,
                isSectionLabelNormal: true,
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => !field?.model?.isPotentialExposureCreditApplicable ?? false
              }
            },
            {
              key: 'isPotentialExposureCreditOversizedPassageways',
              type: 'custom-checkbox',
              wrappers: [ 'question-wrapper' ],
              defaultValue: false,
              props: {
                label: 'Oversized passageways communicate buildings',
                isHorizontalOrientation: true,
                firstColSize: 8,
                isSectionLabelNormal: true,
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => !field?.model?.isPotentialExposureCreditApplicable ?? false
              }
            },
            {
              key: 'isPotentialExposureCreditLargeAtrium',
              type: 'custom-checkbox',
              wrappers: [ 'question-wrapper' ],
              defaultValue: false,
              props: {
                label: 'Large atrium between buildings with exterior walls',
                isHorizontalOrientation: true,
                firstColSize: 8,
                isSectionLabelNormal: true,
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => !field?.model?.isPotentialExposureCreditApplicable ?? false
              }
            },
            {
              key: 'isPotentialExposureCreditAbovegroundGarage',
              type: 'custom-checkbox',
              wrappers: [ 'question-wrapper' ],
              defaultValue: false,
              props: {
                label: 'Aboveground communicating parking garage',
                isHorizontalOrientation: true,
                firstColSize: 8,
                isSectionLabelNormal: true,
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => !field?.model?.isPotentialExposureCreditApplicable ?? false
              }
            },
            {
              key: 'isPotentialExposureCreditUndergroundGarage',
              type: 'custom-checkbox',
              wrappers: [ 'question-wrapper' ],
              defaultValue: false,
              props: {
                label: 'Underground communicating parking garage',
                isHorizontalOrientation: true,
                firstColSize: 8,
                isSectionLabelNormal: true,
              },
              expressions: {
                hide: (field: FormlyFieldConfig) => !field?.model?.isPotentialExposureCreditApplicable ?? false
              }
            }
          ]
        }
      ],
      expressions: {
        // Hide the whole section if WR is disabled
        hide: (field: FormlyFieldConfig) => { 
          const disabledField = field?.options?.formState?.isMainSectionDisabled?.filter(val => val.sectionId === SectionEnum.FR_WIND);
          if (disabledField.length==1) {
            return true;
          }
           else if (disabledField.length==0){
            return false;
          }
        }
      },      
    });
  }
}
