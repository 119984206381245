import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Constants } from 'src/app/services/util-service/constants';

export class BuildingConditionSectionModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe1 = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
       key: 'asgrBuildingCondition',
      type: 'rf-sheet-section',
      props: {
        label: 'Building Conditions',
        isCollapsable: true,
        isCollapsed: false,
        isDisableable: true,
        isApplied:false,
        actionButtons: [
          {
            section: 'buildingCondition',
            action: 'showFormlySheet',
            label: 'Add',
            isDisabled: false,
            service: 'formlyWrapperService',
          },
        ],
          sheetButtons: [
            {
              section: 'buildingCondition',
              action: 'onClose',
              label: 'Close',
              service: 'buildingConditionService',
              className: 'close-btn medium-btn',
              passInitialModel: true,
            },
            {
              section: 'buildingCondition',
              action: 'onApply',
              label: 'Apply',
              service: 'buildingConditionService',
              className: 'apply-btn large-btn',
              hasConfirmationPopup: true,
            },
          ],
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => field.form?.root?.get('evidenceOfFireSprinkler')?.value === true ||
          field.form?.root?.get('evidenceOfFireSprinkler')?.value === 'true' ? false : true
      },
      hooks:{
        onInit: (field: FormlyFieldConfig) =>{          
          let exclusions:string[] = ['reportId', 
                                    'openSidedLevels', 'isC1orC2combustibility'];
          //Checking Add or Edit Button to Display based on hasValue and Existing Risk
          if(formlyBuilderService.hasValues(field.model,true,exclusions) 
              || field?.model?.buildingCondition?.floorOpeningProtection
              || field?.model?.buildingCondition?.openSidedLevels !== '0'
              ||  formlyBuilderService.isExistingRisk()){            
            field.props.actionButtons[0].label = 'Edit'
            field.formControl.markAllAsTouched();
          }
          //Subscribing to Apply Button Click and Save event to S3.
          formlyBuilderService.buildingConditionService.SaveToS3Event.pipe(takeUntil(this.ngUnsubscribe1),
          distinctUntilChanged())
          .subscribe(()=>{
            if(field?.props?.isApplied){
              field.props.actionButtons[0].label = 'Edit'
              field.formControl.markAllAsTouched();
            }
          });        
        },
        onDestroy: () => {
          this.ngUnsubscribe1.next();
          this.ngUnsubscribe1.complete();
        }
      },
      fieldGroup: [
        {
          key: 'buildingCondition',
          wrappers: ['simple-section-wrapper'],
          fieldGroupClassName: 'building-conditions-container',
          props: {
            label: 'Building Conditions',
            isCollapsable: true,
            isCollapsed: false,
            shallowErrors: false,
            showErrors: true,
            // className: 'row-padding-bottom',
          },
          fieldGroup: [
            {
              key: 'reportId',
              defaultValue: null,
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  formlyBuilderService.buildingConditionService.setReportId(field);
                }
              }
            },
            {
              key: 'floorOpeningProtection',
              type: 'simple-select',
              className: 'building-condition-select select-full-width',
              defaultValue: null,
              props: {
                label: 'Floor Openings',
                isAsteriskNotRequired: true,
                isHorizontalOrientation: true,
                selectWidth: true,
                isSectionLabelNormal: true,
                isCardEnabled: true,
                options: formlyBuilderService.buildingConditionService.getFloorOpeningsOptions(),
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  const disableFloorOpening = this.formlyBuilderService.buildingConditionService.disabledFloorOpening(field);
                  const floorOpening = !field?.formControl?.value || field.formControl.value === '' ? null : field.formControl.value;
                  const isExistingRisk = formlyBuilderService.isExistingRisk();
                  if (!isExistingRisk) {
                    if (!disableFloorOpening) {
                      if (!floorOpening) {
                        field.formControl?.markAsTouched();
                      }
                    } else {
                      field.formControl?.setValue(null);
                    }
                    field.props.isDisabled = disableFloorOpening;
                  }
                }
              },
              expressions: {
                'props.isDisabled': (field?: FormlyFieldConfig) => {
                  const disableFloorOpening = this.formlyBuilderService.buildingConditionService.disabledFloorOpening(field);
                  const floorOpening = !field?.formControl?.value || field.formControl.value === '' ? null : field.formControl.value;
                  const isExistingRisk = formlyBuilderService.isExistingRisk();
                  if (isExistingRisk) {
                    if (disableFloorOpening && floorOpening !== field.defaultValue) {
                      return false;
                    } else {
                      return disableFloorOpening;
                    }
                  }
                },
              },
              validators: {
                requiredvalidator: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const disableFloorOpening = this.formlyBuilderService.buildingConditionService.disabledFloorOpening(field);
                    const floorOpening = !field?.formControl?.value || field.formControl.value === '' ? null :  field.formControl.value;
                    const isExistingRisk = formlyBuilderService.isExistingRisk();
                    if(!isExistingRisk) {
                      return true;
                    }
                    if (disableFloorOpening && floorOpening !== field.defaultValue) {
                      control.markAsTouched();
                      return false;
                    } else {
                      return true;
                    }
                  },
                  message: 'Floor Opening protection is not required if there is one floor in the building or where all vertical openings are protected'
                },
              },
            },
            {
              key: 'openSidedLevels',
              type: 'simple-select',
              className: 'building-condition-select select-full-width',
              defaultValue: '0',
              props: {
                label: 'Structural Disadvantage',
                isAsteriskNotRequired: true,
                isHorizontalOrientation: true,
                selectWidth: true,
                isSectionLabelNormal: true,
                isCardEnabled: true,
                options: formlyBuilderService.buildingConditionService.getStructuralDisadvantageOptions(),
              },
              expressions: {
                'props.isDisabled': (field?: FormlyFieldConfig) => {
                  const openSidedValue = field.formControl.value;
                  const isDisabled = formlyBuilderService.buildingConditionService.disableStructuralDisAdg(field);
                  const isExistingRisk = formlyBuilderService.isExistingRisk();
                  if(!isExistingRisk) {
                    if(isDisabled) {
                      field.formControl?.setValue('0');
                    }
                    return isDisabled;
                  }
                  if (isDisabled && openSidedValue !== field.defaultValue) {
                    return false;
                  } else {
                    return isDisabled;
                  }
                },
              },
              validators: {
                requiredvalidator: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const openSidedValue = control.value;
                    const isDisabled = formlyBuilderService.buildingConditionService.disableStructuralDisAdg(field);
                    const isExistingRisk = formlyBuilderService.isExistingRisk();
                    if(!isExistingRisk) {
                      return true;
                    }
                    if (isDisabled && openSidedValue !== field.defaultValue) {
                      control.markAsTouched();
                      return false;
                    } else {
                      return true;
                    }
                  },
                  message: 'If building is not indicated as open-sided in the Wall entries, then Open Levels would not apply'
                },
                openSidedValidator: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    const isExistingRisk = formlyBuilderService.isExistingRisk();
                    if (!isExistingRisk && !field.props.isDisabled && control.value === field.defaultValue) {
                      field.formControl?.markAsTouched();
                      return false;
                    }
                    return true;
                  },
                  message: 'If building is open-sided, select the appropriate Structural Disadvantage'
                }
              }
            },
          ],
          hooks: {
            onInit:(field: FormlyFieldConfig) => {
              field.formControl['controls']?.floorOpeningProtection?.updateValueAndValidity();
              field.formControl['controls']?.openSidedLevels?.updateValueAndValidity();
            }
          }
        },
        {
          wrappers: ['simple-section-wrapper'],
          type: 'sr-field-array',
          key: 'excessiveSingleStoryHeight',
          className: 'excessive-single-story-height-container',
          props: {
            label: 'Excessive Single Story Height',
            isCollapsable: true,
            isCollapsed: false,
            showAddButton: true,
            isGridHeaderDisabled: true,
            showError: true,
            shallowErrors: true,
            checkTouchedControl: true,
            hideExcessivestory: false,
            swipeActionDetails: [
              { action: 'clone', styleName: 'cloneIcon' },
            ]
          },
          expressions: {
            'props.isCollapsed': (field: FormlyFieldConfig) => field.props.hideExcessivestory,
            'props.isCollapseIconDisabled': (field: FormlyFieldConfig) => field.props.hideExcessivestory
          },

          fieldArray: {
            fieldGroupClassName: 'formly-flex-container',
            wrappers: ['error-info-wrapper'],
            fieldGroup: [
              {
                key: 'areaDescription',
                type: 'simple-input',
                className: 'description-input',
                defaultValue: null,
                props: {
                  label: 'Description',
                  isAsteriskNotRequired: true,
                  required: true,
                  maxLength: 100
                },
              },
              {
                key: 'buildingConditionExcessSingleStoryId',
                defaultValue: null,
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    formlyBuilderService.buildingConditionService.generateExcessiveHeightId(field);
                  },
                },
              },
              {
                key: 'reportId',
                defaultValue: null,
                hooks:{
                  onInit:(field: FormlyFieldConfig)=>{
                     formlyBuilderService.buildingConditionService.setReportId(field);
                  }
                }
              },
              {
                key: 'excessStoryAreaComment',
                defaultValue: null
              },
              {
                key: 'excessStoryHeight',
                type: 'simple-input',
                defaultValue: null,
                className: 'area-input',
                props: {
                  label: 'Height',
                  type: 'number',
                  inputmode: 'tel',
                  required: true,
                  isAsteriskNotRequired: true,
                  className: 'ft-label-masonary'
                },
                validators: {
                  excessStoryHeight: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      const height = control?.value;
                      if (height && height < 21) {
                        return false;
                      }
                      return true;
                    },
                    message: "Excessive Single Story Height applies to for heights of 21' or greater."
                  },
                },
              },
              {
                key: 'excessStoryArea',
                type: 'simple-input',
                className: 'area-input',
                defaultValue: null,
                props: {
                  label: 'Area',
                  unit: 'sq.ft.',
                  type: 'number',
                  isAsteriskNotRequired: true,
                  required: true
                },

                validators: {
                  areaValidators: {
                    expression: (control: FormControl, field: FormlyFieldConfig) =>
                      formlyBuilderService.buildingConditionService.requiredAreaSpocValidation(control, field),
                    message: 'Total areas cannot exceed building area.'
                  }
                }
              },
            ],
            expressions: {
              hide: (field: FormlyFieldConfig) => field.parent.props.hideExcessivestory
            },
           
          },
          hooks: {
            onInit: (field: FormlyFieldConfig) => {
              formlyBuilderService.buildingConditionService.checkBuildingConditionEntry(field);
            }
          },
          validators: {
            duplicateValidation: {
              expression: (control: FormControl, field: FormlyFieldConfig) => {
               const isDuplicate = this.formlyBuilderService.buildingConditionService.hasDuplicateWithDifferentFields(field);
                if(isDuplicate){
                  return false;
              }else{
                return true;
              } },
              message: 'Duplicate entries should not be made, either remove or edit the duplicate entries'
            },
            checkSpoc: {
              expression: (control: FormControl, field: FormlyFieldConfig) => {
                const formlyService = field?.options?.formState?.service;
                const totalSpocArea = formlyService.buildingConditionService.getSpocArea(formlyService);
                const totalArea = formlyService.buildingConditionService.calculateArea(field); 
              //  const areaEntries = field?.formControl?.value?.every(src => src.excessStoryArea && Number(src.excessStoryArea) > 0 );
                
               if((field?.formControl?.value?.length > 1) && (totalArea > totalSpocArea)){
                  return false          
                }else{
                  return true              
                }
              },
              message:'Area cannot exceed building area.'             
            },
          }
        },
        {
          key: 'isC1orC2combustibility'
        }
      ]
    });
  }
}
