/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { SectionConfig, SectionEnum } from './section.config';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DeepLinkMessageModel, DeepLinkTargetEnum } from 'src/app/models/formly-builder/deep-link.model';
import { FormlyWrapperService } from './formly-wrapper.service';
import { FormlyFormOptions, FormlyValueChangeEvent } from '@ngx-formly/core/lib/models';
import { Constants } from '../util-service/constants';
import { OrderDetailsPage } from 'src/app/pages/my-orders/order-details/order-details.page';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from '../orders/orders.service';

@Injectable({
  providedIn: 'root'
})
export class DeepLinkService {
  public pageDetails: DeepLinkMessageModel;
  private deepLinkDataSource = new BehaviorSubject(undefined);
  public deepLinkTrigger$ = this.deepLinkDataSource.asObservable();
  private subSectionDataSource = new BehaviorSubject(undefined);
  public subSectionTrigger$ = this.subSectionDataSource.asObservable();  
  private latestLocation = new Map<string, DeepLinkMessageModel>();
  protected ngUnsubscribe = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private wrapperService: FormlyWrapperService,
    private ordersService: OrdersService
  ) {}

  /**
   * Triggers opening a Section Sheet 
   * @param param 
   * @param notify 
   * @param action 
   */
  public triggerSectionSheet(param: DeepLinkMessageModel, notify?: boolean) {
    // Typically we'll trigger an action button - Parametric

    // For now don't open sheets
    return;

    // For linkable Fields, add linkId prop and obtain branch key array
    // if (param.sectionId === SectionEnum.FR_WALLS_EDIT) {
    //   this.wrapperService.triggerActionButton("walls", "add", true);
    // } else
    if (param.sectionId === +SectionEnum.ADDRESS) {
      this.wrapperService.triggerActionButton("address", "add", true);
    } else if (param.sectionId === SectionEnum.FR_S_SYSTEM_COMPONENT) {
      this.wrapperService.onOpenSheet(["sprinklerReport", "asgrSprinklerSystemComponent"]);
    } else {
      if (param.section) {
        console.debug("3. Open Sheet " + param.section);        
        this.wrapperService.onOpenSheet([param.landing, param.section]);
        // TODO: Force subsection change if needed
        if (param.subSectionId) {
          console.debug("4. Trigger Change Tab " + param.section);  
          setTimeout(() => {
            this.triggerSubsectionSet(param.sectionId, param.subSectionId);
          }, 1000);
        }
      } 
    }
  }

  /**
   * 
   * @param sectionId 
   * @param index1 
   */
  public triggerSubsectionSet(sectionId: SectionEnum, subSectionId: SectionEnum) {
    if (!this.pageDetails) {
      this.pageDetails = {};
    }
    this.pageDetails.target = DeepLinkTargetEnum.SUBSECTION;
    this.pageDetails.sectionId = sectionId;
    this.pageDetails.subSectionId = subSectionId;     // TODO: Change this name; it's always a section/subsection?
    this.subSectionDataSource.next(this.pageDetails);
  }

  public routerNavigate(param: DeepLinkMessageModel) {
    this.router.navigate([], {
      queryParams: {
        landingId: param.landingId,
        sectionId: param.sectionId,
        subSectionId: param.subSectionId,
        landing: param.landing,
        section: param.section,
        subSection: param.subSection,
      }, relativeTo: this.activatedRoute
    }); 
  }

  /** 
   * There is a pending move to a deep link
   */
  isDeepLinkPending() {
    return true;
  }

  /**
   * 
   * @param fieldId 
   */
  goToField(fieldId: string, index?: number) {
    // const element = document.querySelector(`#${fieldId}`);
    // element.focus();
    // document.getElementById(fieldId).focus();

    if (index) {
      this.getFieldById(fieldId, index).focus();
    } else {
      this.getFieldById(fieldId).focus();
    }
  }

  public getFieldById(fieldId: string, index?: number): HTMLElement {
    const nodelist = document.querySelectorAll(`#${fieldId}`);
    if (nodelist.length === 1) {
      var elements = Array.from(nodelist);
      return (elements[0] as HTMLElement);
    } else if (nodelist.length > 1 && index) {
      var elements = Array.from(nodelist);
      return (elements[index] as HTMLElement);
    }
    return null;
  }

  /**
   * For a specific Risk Id save latest location / insertion point / cursor
   * @param riskId replacing Risk Id value with reportIdentifier
   * @param landingId 
   * @param sectionId 
   * @param subSectionId 
   * @param index1 
   * @param index2 
   * @param fieldId 
   */
  saveLatestLocation(
    riskId: string,
    landingId: SectionEnum,     
    sectionId?: SectionEnum,
    subSectionId?: SectionEnum,     
    index1?: number,
    index2?: number,
    field?: string) {
    // Save this info to memory
    this.latestLocation.set(riskId, {
      landingId: landingId,
      sectionId: sectionId || undefined,
      subSectionId: subSectionId || undefined,
      index1: index1 || undefined,
      index2: index2 || undefined,
      field: field || undefined
    });

    // TODO: Save this info to local storage
    localStorage.latestLocationInRisks = JSON.stringify(Array.from(this.latestLocation));
  }

  /**
   * Retrieve from local disk the latest location for a specific risk id
   */
  retrieveLatestLocation(riskId: string): DeepLinkMessageModel {
    // Retrieve from memory
    let location = this.latestLocation.get(riskId);

    // TODO: If not available in memory, retrieve from local storage
    if (location === undefined) {
      try {
        const myLocationMap = new Map(JSON.parse(localStorage?.latestLocationInRisks));
        if (myLocationMap !== null) {
          this.latestLocation = myLocationMap as Map<string, DeepLinkMessageModel>;
          location = this.latestLocation.get(riskId);

          // New: load to the page details for fast initial load
          this.pageDetails = location;
        }
      } catch (error) {
        console.warn("deep-link. No last Location Found.");
      }
    }
    return location;
  }

  getLatestLocation(): DeepLinkMessageModel {
    // return this.deepLinkDataSource.getValue();
    return this.pageDetails;
  }

  /**
   * Store the latest field that changed values
   * @param formlyOptions 
   */
  storeLatestTouchedField(formlyOptions: FormlyFormOptions) {
    if (formlyOptions?.fieldChanges) {
      formlyOptions.fieldChanges.pipe(
        takeUntil(this.ngUnsubscribe),
        filter((event) => event.type === 'valueChanges'))
        .subscribe((event: FormlyValueChangeEvent) => {
          if (event.type === 'valueChanges' && event.value !== undefined) {
            let path = '';
            let parent: any = event.field;
            while (parent) {
              if (parent['key']) {
                path = path + (path === '' ? '' : '.') + parent['key'];
              }
              parent = parent['parent'];
            }
            console.log(path);
          }
        });
    }
  }

  /**
   * Change the Survey Title Header using the title DOM element / id
   * @param orderDetailsPage 
   * @param title 
   */
  changeTitleHeader(orderDetailsPage: OrderDetailsPage, title: string) {
    // 1st. Approach, use DOM
    // const ionTitle = document.querySelector('#group-name')
    //   .shadowRoot
    //   .querySelector(".toolbar-title");
    // ionTitle.innerHTML = title;

    // 2nd. Approach, overwrite header public
    // if (orderDetailsPage) {
    //   setTimeout(() => {
    //     orderDetailsPage.header = title;
    //   }, 100);
    // }

    // 3rd. Approach, use API call
    this.ordersService.titleChange.next(title);
  }

  /**
   * Get the title basedon RiskId and building description
   * @param RiskId 
   * @param BuildingDescription 
   * @returns 
   */
  getRiskFormTitle(RiskId: string, BuildingDescription: string): string {
    let title;
    if (RiskId?.length) {
      // Existing Risk
      if (BuildingDescription?.length) {
        title = BuildingDescription + ' - ' + RiskId;
      } else {
        title = RiskId;
      }
    } else {
      // New Risk

      // Building description cannot be 'New Risk'
      if (BuildingDescription?.length &&
        BuildingDescription !== Constants.newBuildingDescription) {
        title = BuildingDescription + ' - ' + Constants.newBuildingDescription;
      } else {
        title = Constants.newBuildingDescription;
      }
    }

    return title;
  }  

  unsubscribe(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}