import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { BuildingBGDataModel } from 'src/app/models/formly-builder/formly/building-bg-data.model';

@Injectable({
  providedIn: 'root',
})
export class FormlyBuildingBGService extends SectionServiceAbstract {
  public getBuildingBGLookUpData(): Observable<any> {
    // TODO: Replace when lookup data available
    return of([
      { value: 'A', label: 'A' },
      { value: 'B', label: 'B' },
      { value: 'AB', label: 'AB' },
      { value: 'C', label: 'C' },
      { value: 'CD', label: 'CD' },
      { value: 'D', label: 'D' },
    ]);
  }

  async updateData(model: any, riskReportData: any) {
    const hasChanged: boolean = BuildingBGDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      return { ...riskReportData, ...BuildingBGDataModel.to(model) };
    } else {
      return undefined;
    }
  }

  loadData(sectionData: any[], reportInfo: any, isS3: boolean, model: any) {
    let buildingBG: BuildingBGDataModel;
    if (isS3) {
      buildingBG = BuildingBGDataModel.fromS3(reportInfo);
    } else {
      buildingBG = BuildingBGDataModel.from(reportInfo);
    }
    return { ...model, ...buildingBG };
  }
}
