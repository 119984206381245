import { Injectable } from '@angular/core';
import { DebugConsoleLog } from 'src/app/interfaces/debug-console';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage-service/storage.service';
import { Constants, RequestConsoleLogs } from './constants';
import { LogTimeService } from './log-time.service';

@Injectable({ providedIn: 'root' })
export class LoggerService {
    constructor(private storage: StorageService,private logTimeService: LogTimeService) { }
    manageLogs() {
        const currentLog = environment.isEnabledLogs;
        if(currentLog) {
            console['logOld'] = console.log;
            console['errorOld'] = console.error;
            console['warnOld'] = console.warn;
            console.log = (...e) => this.addLogsInArray('log', e);
            console.error = (...e) => this.addLogsInArray('error', e);
            console.warn = (...e) => this.addLogsInArray('warn', e);
        }
       
    }

    async addLogsInArray(type, e) {
        let output = "", arg, i;

        for (i = 0; i < arguments.length; i++) {
            arg = arguments[i];
            if (
                typeof arg === "object" &&
                typeof JSON === "object" &&
                typeof JSON.stringify === "function"
            ) {
                try {
                    output += ' '+ JSON.stringify(arg);
                } catch (error) {
                    if (arg && Array.isArray(arg)) {
                        for (let j = 0; j < arg.length; j++) {
                            output += ' '+ arg[j].toString();
                        }
                    } else {
                        output += ' '+ output.toString();
                    }
                }
            } else {
                output += ' '+ arg;
            }
        }
        if (this.isLogEnabled()) {
            const logObj: DebugConsoleLog = new DebugConsoleLog();
            logObj.isRequestLog = false;
            logObj.logStr = output;
            logObj.logTime = this.logTimeService.getTime();
            RequestConsoleLogs.push(logObj);
        }
        console[type + 'Old'].apply(undefined, e);
    }

    async isLogEnabled(): Promise<boolean> {
        const localValue = await this.storage.getString(Constants.debugStorageKey);
        return eval(localValue.value);
    }
}