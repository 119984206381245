import { Component} from '@angular/core';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { OneDriveService } from 'src/app/services/onedrive/onedrive-service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import moment from 'moment';
import { AttachmentService } from 'src/app/services/orders/attachments/attachment.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-files',
  templateUrl: './onedrive-explorer.component.html',
  styleUrls: ['./onedrive-explorer.component.scss']
})
export class OneDriveExplorerComponent {
  public files?: any;
  id!: string;
  selectedFile!: any;
  breadcrumbs: Array<{id: string, name: string}> = [];
  rootOnedrivePath: string = "/drive/root:";
  defaultFile = ''

  constructor(
    private route: ActivatedRoute,
    private onedriveSvc: OneDriveService,
    private attachmentSvs: AttachmentService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.initialize(this.defaultFile);
    });
  }

  navFolder(id:string){
    this.initialize(id);
  }

  selectBreadcrumb(el:any)
  {
    this.breadcrumbs.pop();
    this.initialize(el.id);
  }

  async initialize(id:string|undefined) {
    this.files = await this.onedriveSvc.getFiles(id);
    let parentRef:any = null;    
    for (var f of this.files) {
      f.ext = f.name.split(".")[f.name.split(".").length-1];  
      f.size = this.convertBytes(f.size);
      f.lastModifiedDate = moment(f.lastModifiedDateTime).format("MM/DD/YYYY hh:mm A");
      f.icon = this.getIcon(f.ext);
      parentRef = f.parentReference;
    }

    //calc breadcrumb
    if (parentRef.path == this.rootOnedrivePath)
    {
        this.breadcrumbs.push({ "name": "My Files", "id": ""});
    }
    else {
      this.breadcrumbs.push({"name":parentRef.name,"id":parentRef.id})
    }
    //trim 
    this.calcBreadcrumb(id);
  }

  fileType(e){
    if (e.detail.checked)
    {
      //show recent files
      this.initialize('recent');
    }
    else{
      //show all files
      this.initialize('');
    }
  }

  calcBreadcrumb(id) {
    let newAr: Array<{id: string, name: string}> = [];
    for (var b of this.breadcrumbs){
      newAr.push(b);
      if (b.id == id){
        break;
      }
    }
    this.breadcrumbs = newAr;
  }
  

  getIcon(ext:string){
    const extAr = ["jpg","png","xlsx","docx","docm","pdf","pptx"];
    if (extAr.includes(ext))
    {
      ext = `assets/icon/${ext}.svg`;
    }
    else {
      ext = 'assets/icon/default.svg';
    }
    return ext;
  }

  selectFile(file:MicrosoftGraph.DriveItem){
    console.log("Event", file);
    this.selectedFile = file;
    if (file.folder!=null)
    {
      this.selectedFile = null;
      this.navFolder(file.id);
    }
  }

  /*toggleSelect(evt){
    if (evt.srcElement.className.indexOf("selected") > 0)
    {
      evt.srcElement.classList.remove("selected");
    }
    else {
      evt.srcElement.classList.add("selected");
    }
  }*/
  
  onSwipeDown() {
    this.close();
  }

  async save(){
    console.log("Download File", this.selectedFile);
    let fileData = await this.onedriveSvc.DownloadFileData(this.selectedFile);
    const selectedFileData = [{
      file: this.selectedFile,
      name: this.selectedFile.name,
      type: this.selectedFile.file.mimeType,
      data: fileData,
      size: this.selectedFile.size,
      lastModified: new Date(this.selectedFile.lastModifiedDateTime).getTime()
    }];
    const pickFilesResult = { files: selectedFileData };
    this.onedriveSvc.updateOneDriveVisabilty(false);
    this.modalCtrl.dismiss(pickFilesResult);
  }

  close() {
    this.onedriveSvc.updateOneDriveVisabilty(false);
    this.modalCtrl.dismiss(null);
  }

  convertBytes(b: any): any {
    var u = 0, s=1024;
      while (b >= s || -b >= s) {
          b /= s;
          u++;
      }
      return (u ? b.toFixed(1) + ' ' : b) + ' KMGTPEZY'[u] + 'B';
  }

}
