import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class BuildingHazardsDataModel {
    static sectionName = 'buildingHazards';

    scheduleNumber: string;
    comment: string;
    reportHazardIdentifier: string;
    reportIdentifier: string;
    scopesVersion: number;

    static fromS3(buildingHazardsData: any): BuildingHazardsDataModel{

        if(buildingHazardsData !== null && buildingHazardsData.length > 0)
        {
            const buildingHazard = buildingHazardsData.map(item=>({ scheduleNumber: item.ScheduleNumber,
                comment: item.Comment,
                reportHazardIdentifier: item.ReportHazardIdentifier,
                reportIdentifier:item.ReportIdentifier,
                scopesVersion:item.ScopesVersion
            }));
            return buildingHazard;
        }else{
            return undefined;
        }
    }

    static to(model: any,sectionEnum?: SectionEnum){
        const hazardsModel = model.map((item)=>({ ScheduleNumber: item?.scheduleNumber,
                Comment: item?.comment,
                ReportHazardIdentifier: item?.reportHazardIdentifier === undefined ?'':item?.reportHazardIdentifier,
                ReportIdentifier:item?.reportIdentifier,
                ScopesVersion:1}));
                return hazardsModel;
    }

    /**
     * @param formlyModel model from formly
     * @param riskReportModel Model from the S3 Risk Report
     */
    static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum) : boolean{
    return true;
    }
}
