import { Injectable } from '@angular/core';
import { AppService } from './services/app-service/app.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { AuthService } from 'ionic-appauth';

@Injectable()
export class AppConfig {
  fields: FormlyFieldConfig[] | undefined;
  device: DeviceInfo | undefined;
  static device: DeviceInfo;
  constructor(private appService: AppService, private authService: AuthService) {}

  public async load() {
    const fieldsConfig = await this.appService.getFields;
    this.device = await Device.getInfo();
    await this.authService.loadUserInfo();
    this.fields = this.mapFields(fieldsConfig);
    AppConfig.device = this.device;
  }

  private mapFields(fields: FormlyFieldConfig[]) {
    const mapped = fields.map((field) =>
      field.type === 'grid'
        ? { ...field, fieldGroup: (field as any).rows.map((row) => ({ fieldGroup: row.cols })) }
        : field
    );
    return mapped;
  }
}
