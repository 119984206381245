/* eslint-disable @typescript-eslint/naming-convention */
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class FloorsRoofBg2DataModel {
  static sectionName = 'bg2Data';
  isWindUplift90: boolean;
  isCC3: boolean;
  isCC4: boolean;
  isMixedLowHighRise: boolean;
  is22gaRoof: boolean;
  isSteelLight: boolean;
  isSteelOtherThanLight: boolean;
  skylightsRecognition: string;
  steelType: string;

  static fromS3(reports: any): FloorsRoofBg2DataModel {

    return {
        isWindUplift90: reports?.IsWindUplift90,
        isCC3: reports?.IsCc3,
        isCC4: reports?.IsCc4,
        isMixedLowHighRise: reports?.IsMixedLowHighRise,
        is22gaRoof: reports?.Is22gaRoof,
        isSteelOtherThanLight: reports?.IsSteelOtherThanLight,
        isSteelLight: reports?.IsSteelLight,
        skylightsRecognition: reports?.SkylightsRecognition,
        steelType: reports?.IsSteelLight ? 'SL' :  reports?.IsSteelOtherThanLight ? 'SOTL' : '',
    };
  }

  static to(model: any, sectionEnum?: SectionEnum) {
    return {
        IsWindUplift90: model?.floorsAndRoofs?.bg2Data?.isWindUplift90 ? model?.floorsAndRoofs?.bg2Data?.isWindUplift90 : false,
        IsCc3: model?.floorsAndRoofs?.bg2Data?.isCC3 ? model?.floorsAndRoofs?.bg2Data?.isCC3 : false,
        IsCc4: model?.floorsAndRoofs?.bg2Data?.isCC4 ? model?.floorsAndRoofs?.bg2Data?.isCC4 : false,
        IsMixedLowHighRise: model?.floorsAndRoofs?.bg2Data?.isMixedLowHighRise ?  model?.floorsAndRoofs?.bg2Data?.isMixedLowHighRise : false,
        Is22gaRoof: model?.floorsAndRoofs?.bg2Data?.is22gaRoof ? model?.floorsAndRoofs?.bg2Data?.is22gaRoof : false,
        IsSteelOtherThanLight: model?.floorsAndRoofs?.bg2Data?.isSteelOtherThanLight ?  model?.floorsAndRoofs?.bg2Data?.isSteelOtherThanLight : false,
        IsSteelLight: model?.floorsAndRoofs?.bg2Data?.isSteelLight ? model?.floorsAndRoofs?.bg2Data?.isSteelLight : false,
        SkylightsRecognition: model?.floorsAndRoofs?.bg2Data?.skylightsRecognition,
        SteelType: model?.floorsAndRoofs?.bg2Data?.steelType,
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    return true;
  }
}