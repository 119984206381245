
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { MainDrainTestingSectionModel } from './main-drain-testing.model';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { numericInputValidator } from 'src/app/validators/formly-builder/numeric-input.validator';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class SystemDesignSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'hydraulicSystemDesign',
      wrappers: ['simple-section-wrapper'],
      fieldGroupClassName: 'hydraulic-system-design-container',
      resetOnHide: true,
      props: {
        label: 'System Design',
        linkId: SectionEnum.FR_S_WS_HY_SYSTEM_DESIGN,
        isCollapsable: true,
        isCollapsed: false,
        shallowErrors: true,
        checkTouchedControl: true,
      },
      fieldGroup: [
        {
          key: 'hydraulicDesignMethod',
          type: 'simple-select',
          className: 'hydraulic-select-design select-full-width',
          resetOnHide: true,
          props: {
            label: 'Design Method',
            isHorizontalOrientation: true,
            isCardEnabled: true,
            required: false,
            isAsteriskNotRequired: true,
            options: [
              {
                value: 'HCMC',
                label: 'Hydraulic Calculation Method – Density/Area Curve',
              },
              {
                value: 'HCMR',
                label: 'Hydraulic Calculation Method – Room Design',
              },
              {
                value: 'CMDA',
                label: 'CMDA - Control Mode Density Area',
              },
              {
                value: 'CMSA',
                label: 'CMSA - Control Mode Specific Application',
              },
              {
                value: 'ESFR',
                label: 'ESFR - Early Suppression Fast Response',
              },
              {
                value: 'UKWN',
                label: 'Unknown Design Method',
              },
            ],
          },
          expressions: {
            'props.required': (field: FormlyFieldConfig) => {
              const formlyService = field?.options?.formState?.service;
              return formlyService.waterSupplyService.isHydralicSystemDesignFieldRequired(field);
            },
          },
          hooks: {
            onInit: (field) => {
              const formlyService = field?.options?.formState?.service;
              formlyService.waterSupplyService.getoccupantSprinklerDesign(field);
            },
          },
        },
        {
          fieldGroup: [
            {
              fieldGroupClassName: 'non-numeric-design',
              wrappers: ['simple-section-wrapper'],
              type: 'non-numeric-design',
              className: 'remove-margin',
              resetOnHide: true,
              props: {
                label: 'Non Numeric Designs on Demand',
                hasTitleRow: false,
                shallowErrors: false,
                checkTouchedControl: true,
                actionButton: [
                  { action: 'showFormlySheet', label: 'Calculate', service: 'waterSupplyService', isDisabled: false },
                ],
                isCollapsable: false,
              },
              fieldGroup: [
                {
                  fieldGroup: [
                    {
                      props: {
                        title: 'Sprinkler Report',
                        subTitle: 'Non Numeric Designs On Demand',
                        className: 'calc-remove-padding',
                        sheetButtons: [
                          {
                            section: 'nonNumericDesign',
                            action: 'onClose',
                            label: 'Close',
                            service: 'systemDesignCalculateService',
                            className: 'close-btn medium-btn',
                            passInitialModel: true,
                          },
                          {
                            section: 'nonNumericDesign',
                            action: 'onCalculate',
                            label: 'Calculate',
                            service: 'systemDesignCalculateService',
                            className: 'apply-btn large-btn',
                          },
                        ],
                      },
                      fieldGroup: [
                        {
                          key: 'nonNumericDesign',
                          wrappers: ['simple-section-wrapper', 'error-info-wrapper'],
                          className: 'system-design-calculate-container',
                          resetOnHide: true,
                          props: {
                            showError: false,
                            isShallowTitleRow: true,
                            checkTouchedControl: true,
                          },
                          fieldGroup: [
                            {
                              key: 'isSheetActive',
                              defaultValue: false,
                              resetOnHide: true,
                              hooks: {
                                onInit: (field: FormlyFieldConfig) => {
                                  field.formControl?.setValue(true);
                                },
                                onDestroy: (field: FormlyFieldConfig) => {
                                  field.formControl?.setValue(false);
                                }
                              }
                            },
                            { template: '<div class="instruction-text-style">Use one of the two following combinations to calculate the system design. Subtract the hose stream GPM from Demand Flow if it is included.</div>' },
                            { template: '<div class="ws-title-style">Combination 1</div>' },
                            {
                              key: 'demandFlowComb1',
                              type: 'simple-input',
                              resetOnHide: true,
                              props: {
                                label: 'Demand Flow (Q)',
                                isCardEnabled: true,
                                type: 'number',
                                rightAlign: true,
                                unit: 'GPM',
                                min: 0,
                                max: 99999,
                                maxLength: 5,
                                isHorizontalOrientation: true,
                                readonly: false,
                              },
                              expressions: {
                                'props.readonly': (field: FormlyFieldConfig) => {
                                  if (field?.form?.get('numberOfHeadsComb2')?.value ||
                                    field?.form?.get('areaPerHeadComb2')?.value || field?.form?.get('demandFlowComb2')?.value) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                },
                                'props.required': (field: FormlyFieldConfig) => field?.parent?.model?.isSheetActive ? !field.props?.readonly : false,
                              },

                            },
                            {
                              key: 'remoteAreaComb1',
                              type: 'simple-input',
                              resetOnHide: true,
                              props: {
                                label: 'Remote Area',
                                isCardEnabled: true,
                                type: 'number',
                                rightAlign: true,
                                unit: 'sq.ft.',
                                min: 0,
                                max: 99999,
                                maxLength: 5,
                                isHorizontalOrientation: true,
                                readonly: false,
                              },
                              expressions: {
                                'props.readonly': (field: FormlyFieldConfig) => {
                                  if (field?.form?.get('numberOfHeadsComb2')?.value ||
                                    field?.form?.get('areaPerHeadComb2')?.value || field?.form?.get('demandFlowComb2')?.value) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                },
                                'props.required': (field: FormlyFieldConfig) => field?.parent?.model?.isSheetActive ? !field.props.readonly : false,
                              },
                            },
                            { template: '<div class="ws-title-style">Combination 2</div>' },
                            {
                              key: 'numberOfHeadsComb2',
                              type: 'simple-input',
                              resetOnHide: true,
                              props: {
                                label: 'Number of Heads in the Remote Area',
                                rightAlign: true,
                                isCardEnabled: true,
                                type: 'number',
                                min: 0,
                                max: 99999,
                                maxLength: 5,
                                isHorizontalOrientation: true,
                                readonly: false,
                              },
                              expressions: {
                                'props.readonly': (field: FormlyFieldConfig) => {
                                  if (field?.form?.get('demandFlowComb1')?.value || field?.form?.get('remoteAreaComb1')?.value) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                },
                                'props.required': (field: FormlyFieldConfig) => field?.parent?.model?.isSheetActive ? !field.props?.readonly : false,
                              },
                            },
                            {
                              key: 'areaPerHeadComb2',
                              type: 'simple-input',
                              resetOnHide: true,
                              props: {
                                label: 'Area Per Head',
                                isCardEnabled: true,
                                type: 'number',
                                rightAlign: true,
                                unit: 'sq.ft.',
                                min: 0,
                                max: 99999,
                                maxLength: 5,
                                readonly: false,
                                isHorizontalOrientation: true,
                              },
                              expressions: {
                                'props.readonly': (field: FormlyFieldConfig) => {
                                  if (field.form?.get('demandFlowComb1')?.value || field?.form?.get('remoteAreaComb1')?.value) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                },
                                'props.required': (field: FormlyFieldConfig) => field?.parent?.model?.isSheetActive ? !field.props?.readonly : false,
                              },
                            },
                            {
                              key: 'demandFlowComb2',
                              type: 'simple-input',
                              className: 'comb2-margin',
                              resetOnHide: true,
                              props: {
                                label: 'Demand Flow (Q):',
                                isCardEnabled: true,
                                type: 'number',
                                rightAlign: true,
                                unit: 'GPM',
                                min: 0,
                                max: 99999,
                                maxLength: 5,
                                isHorizontalOrientation: true,
                                readonly: false,
                              },
                              expressions: {
                                'props.readonly': (field: FormlyFieldConfig) => {
                                  if (field.form?.get('demandFlowComb1')?.value || field?.form?.get('remoteAreaComb1')?.value) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                },
                                'props.required': (field: FormlyFieldConfig) => field?.parent?.model?.isSheetActive ? !field.props?.readonly : false,
                              },
                            },


                          ]
                        }
                      ]

                    },
                  ],
                },
                {
                  className: 'remove-margin',
                  fieldGroup: [
                    {
                      fieldGroupClassName: 'non-numeric-design',
                      className: 'numeric-design-padding',
                      fieldGroup: [
                        {
                          key: 'hydraulicDesignDensity',
                          type: 'simple-input',
                          wrappers: ['question-wrapper'],
                          className: 'margin-input width sys-design-row-1',
                          resetOnHide: true,
                          props: {
                            label: 'Density',
                            isLabelDisabled: true,
                            isSectionLabelNormal: true,
                            isHorizontalOrientation: true,
                            isAsteriskNotRequired: true,
                            rightAlign: true,
                            required: false,
                            isDecimal: true,
                            unit: 'GPM/sq.ft.',
                            min: 0.0001,
                            max: 99999,
                            showError: false,
                            isSwipeGesture: true,
                            isCommentActionEnabled: true,
                            commentActionKey: 'hydraulicDesignDensityComment',
                            type: 'decimal',
                            modelOptions: {
                              updateOn: 'blur'
                            },
                            change: (field: FieldTypeConfig) =>
                              formlyBuilderService.formlyWrapperService.formatDecimal(field, "truncate", "2")
                          },
                          expressions: {
                            'props.required': (field: FormlyFieldConfig) => {
                              const formlyService = field?.options?.formState?.service;
                              return formlyService.waterSupplyService.isHydralicSystemDesignFieldRequired(field, formlyService);
                            },
                          },
                          hooks: {
                            onInit: (field: FormlyFieldConfig) => {
                              field?.formControl?.valueChanges.subscribe((value) => {
                                const formlyService = field?.options?.formState?.service;
                                formlyService.waterSupplyService.updateHoseStream(field);
                                if (value !== null && field?.form?.get('hydraulicWaterDemandFlow') !== null) {
                                  field?.form?.get('hydraulicWaterDemandFlow')?.updateValueAndValidity();
                                }

                              }
                              );
                            }
                          },
                        },
                        {
                          key: 'hydraulicDesignDensityComment',
                        },
                        {
                          className: 'width sys-design-row-1 area-margin padding-r',
                          fieldGroup: [
                            {
                              key: 'hydraulicRemoteArea',
                              wrappers: ['question-wrapper'],
                              type: 'simple-input',
                              resetOnHide: true,
                              props: {
                                label: 'Area',
                                isLabelDisabled: true,
                                isSectionLabelNormal: true,
                                isHorizontalOrientation: true,
                                isAsteriskNotRequired: true,
                                rightAlign: true,
                                required: false,
                                showError: false,
                                type: 'number',
                                min: 0,
                                max: 99999,
                                maxLength: 5,
                                unit: 'sq.ft.',
                              },
                              expressions: {
                                'props.required': (field: FormlyFieldConfig) => {
                                  const formlyService = field?.options?.formState?.service;
                                  return formlyService.waterSupplyService.isHydralicSystemDesignFieldRequired(field, formlyService);
                                },
                              },
                              hooks: {
                                onInit: (field: FormlyFieldConfig) => {
                                  field?.formControl?.valueChanges.subscribe((value) => {
                                    const formlyService = field?.options?.formState?.service;
                                    formlyService.waterSupplyService.updateHoseStream(field);
                                    if (value !== null && field?.form?.get('hydraulicWaterDemandFlow') !== null) {
                                      field?.form?.get('hydraulicWaterDemandFlow')?.updateValueAndValidity();
                                    }
                                  }
                                  );
                                }
                              },
                              validators: {
                                remoteAreaValidator: {
                                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                                    const v1spocNumbers = formlyBuilderService?.riskReport?.model?.sprinklerReport?.asgrWaterSupply
                                      ?.waterSupplyHydraulics?.v1WaterSupplyHydraulics;
                                    const isHydraulicEntryPresent = formlyBuilderService.waterSupplyService.checkHydraulic();
                                    const isWaterSupplyNotEvaluated = !field?.form?.parent?.value?.isWaterSupplyNotEvaluated;
                                    if (isHydraulicEntryPresent && v1spocNumbers && isWaterSupplyNotEvaluated) {
                                      if (control?.value && control?.value !== null && control?.value !== '') {
                                        const integerPattern = /^[0-9]+$/;
                                        if (!integerPattern.test(control.value)) {
                                          return false;
                                        } else {
                                          return true;
                                        }
                                      }
                                      return true;
                                    }else{
                                      return true;
                                    }
                                  },
                                  message: 'Only integers allowed'
                                },
                              },
                            },
                          ],
                        },
                        { className: 'new-row' },
                        {
                          className: 'width sys-design-row-2',
                          key: 'hydraulicWaterDemandFlow',
                          type: 'simple-input',
                          wrappers: ['question-wrapper'],
                          resetOnHide: true,
                          props: {
                            label: 'Demand Flow',
                            isLabelDisabled: true,
                            isSectionLabelNormal: true,
                            isHorizontalOrientation: true,
                            isAsteriskNotRequired: true,
                            rightAlign: true,
                            required: false,
                            showError: false,
                            min: 0,
                            max: 99999,
                            // maxLength: 5,
                            unit: 'GPM',
                            isDecimal: true,
                            type: 'decimal',
                            modelOptions: {
                              updateOn: 'blur'
                            },
                            change: (field: FieldTypeConfig) =>
                              formlyBuilderService.formlyWrapperService.formatDecimal(field, "truncate", "2")
                          },
                          expressions: {
                            'props.required': (field: FormlyFieldConfig) => {
                              const formlyService = field?.options?.formState?.service;
                              return formlyService.waterSupplyService.isHydralicSystemDesignFieldRequired(field, formlyService);
                            },
                          },
                          validators: {
                            checkDemandFlow: {
                              expression: (control: FormControl, field: FormlyFieldConfig) => {
                                const productValue = field?.model?.hydraulicDesignDensity * field?.model?.hydraulicRemoteArea;
                                if(formlyBuilderService.options?.formState?.isHydraulicEntryPresent) {
                                  if (control?.value < productValue) {
                                    return false;
                                  }
                                }                            
                                return true;
                              },
                              message: 'System demand cannot be less than the product of the Design Density and Remote Area',
                            },
                          },
                          hooks: {
                            onInit: (field: FormlyFieldConfig) => {
                              field?.formControl?.valueChanges.subscribe((value) => {
                                const formlyService = field?.options?.formState?.service;
                                formlyService.waterSupplyService.updateHoseStream(field);
                                if (value !== null && field?.form?.get('hydraulicIncludesHoseDemand') !== null) {
                                  field?.form?.get('hydraulicIncludesHoseDemand')?.updateValueAndValidity();
                                }
                              }
                              );
                            }
                          },
                        },
                        {
                          className: 'width sys-design-row-2 padding-r',
                          key: 'hydraulicWaterDemandPressure',
                          type: 'simple-input',
                          wrappers: ['question-wrapper'],
                          resetOnHide: true,
                          props: {
                            label: 'Demand Pressure',
                            isLabelDisabled: true,
                            isSectionLabelNormal: true,
                            isHorizontalOrientation: true,
                            isAsteriskNotRequired: true,
                            rightAlign: true,
                            required: false,
                            showError: false,
                            unit: 'psi',
                            min: 0,
                            max: 99999,
                            // maxLength: 5,
                            isDecimal: true,
                            type: 'decimal',
                            firstColSize: 6,
                            modelOptions: {
                              updateOn: 'blur'
                            },
                            change: (field: FieldTypeConfig) =>
                              formlyBuilderService.formlyWrapperService.formatDecimal(field, "truncate", "2")
                          },
                          expressions: {
                            'props.required': (field: FormlyFieldConfig) => {
                              const formlyService = field?.options?.formState?.service;
                              return formlyService.waterSupplyService.isHydralicSystemDesignFieldRequired(field, formlyService);
                            },
                          },
                          validators: {
                            checkDemandPressure: {
                              expression: (control: FormControl, field: FormlyFieldConfig) => {
                                const formlyService = field.options.formState.service;
                                const sprinklerReport = field?.options?.formState?.service?.riskReport?.model?.sprinklerReport;
                                const buildingHeight =
                                  sprinklerReport?.asgrWaterSupply?.evalutionOfWaterSupply?.buildingHeightInFeet * 0.434;
                                const topLineSprinklerHeight =
                                  sprinklerReport?.asgrWaterSupply?.evalutionOfWaterSupply?.topLineSprinklerHeightInFeet * 0.434;
                                  const isWaterSupplyNotEvaluated = field?.form?.parent?.value?.isWaterSupplyNotEvaluated;
                                if(formlyService.options?.formState?.isHydraulicEntryPresent) {
                                  if ((control?.value < buildingHeight || control?.value < topLineSprinklerHeight) && !isWaterSupplyNotEvaluated) {
                                    return false;
                                  }
                                }                                  
                                return true;
                              },
                              message: 'System Demand Pressure cannot be less than elevation pressure',
                            },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          key: 'isHydraulicWaterDemandLocationBor',
          type: 'rf-radio',
          className: 'remove-padding demand-at-padding left-padding-radio',
          defaultValue: true,
          resetOnHide: true,
          props: {
            label: 'Demand At',
            isHorizontalOrientation: true,
            slot: 'start',
            isCardEnabled: true,
            isLabelRight: true,
            required: false,
            options: [
              { label: 'Base of Riser', value: true, disabled: false },
              { label: 'Point of Connection', value: false, disabled: false },
            ],
          },
          expressions: {
            'props.required': (field: FormlyFieldConfig) => {
              if (field?.model?.hydraulicHoseDemand > 0 && field?.model?.hydraulicIncludesHoseDemand === null) {
                return true;
              }
            },
          },
          hooks: {
            onInit: (field: FormlyFieldConfig) => {
              if (field?.parent?.parent?.model?.supplySource?.ss1?.supplySourceTypeSs1 === 'Main Drain Estimation') {
                field.form?.get('isHydraulicWaterDemandLocationBor')?.setValue(false);
              }
            },
          },
        },
        {
          className: 'formly-flex-container hyd-sys-design hos-demand-margin left-padding-radio',
          fieldGroup: [
            {
              key: 'hydraulicIncludesHoseDemand',
              type: 'rf-radio',
              className: 'radio-shading hose-stream-demand-padding',
              resetOnHide: true,
              props: {
                label: 'Hose Stream Demand',
                isHorizontalOrientation: true,
                slot: 'start',
                isLabelRight: true,
                required: false,
                options: [
                  { label: 'Including', value: true, disabled: true },
                  { label: 'Excluding', value: false, disabled: true },
                ],
              },
              expressions: {
                'props.required': (field: FormlyFieldConfig) => {
                  if (field?.model?.hydraulicHoseDemand > 0) {
                    return true;
                  }
                },
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  const formlyService = field?.options?.formState?.service;
                  formlyService?.waterSupplyService?.updateHoseStream(field);
                },
              },
            },
            {
              key: 'hydraulicHoseDemand',
              type: 'simple-select',
              className: 'hydraulic-select-hose-stream',
              resetOnHide: true,
              props: {
                label: 'Hose Stream',
                isHorizontalOrientation: true,
                required: false,
                isAsteriskNotRequired: true,
                options: [
                  { value: 100, label: '100 GPM' },
                  { value: 250, label: '250 GPM' },
                  { value: 500, label: '500 GPM' },
                ],
              },
              expressions: {
                'props.required': (field: FormlyFieldConfig) => {
                  if (field.parent.parent.form?.value?.isWaterSupplyNotEvaluated) {
                    return false;
                  }
                  const formlyService = field?.options?.formState?.service;
                  return formlyService.waterSupplyService.isHydralicSystemDesignFieldRequired(field, formlyService);
                },
              },
              hooks: {
                onInit: (field) => {
                  field?.formControl?.valueChanges.subscribe((value) => {
                    const formlyService = field?.options?.formState?.service;
                    formlyService.waterSupplyService.updateHoseStream(field);
                  });
                  const options = [
                    { value: 100, label: '100 GPM' },
                    { value: 250, label: '250 GPM' },
                    { value: 500, label: '500 GPM' },
                  ];
                  if (field?.formControl?.value && field?.formControl?.value !== null) {
                    const result = options.some((option) => option.value === parseInt(field.formControl?.value));
                    if (!result) {
                      field?.formControl?.setValue(null);
                      field.props.required = true;
                    }
                  }
                },
              },
              validators: {
                checkDemandFlow: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    if (field.parent.parent.form?.value?.isWaterSupplyNotEvaluated) {
                      return true;
                    }
                    const isHydraulicEntryPresent = formlyBuilderService?.waterSupplyService?.checkHydraulic();
                    if (isHydraulicEntryPresent) {
                      if (control?.value && control?.value > 0) {
                        return true;
                      }
                      return false;
                    } else {
                      return true;
                    }
                  },
                  message: 'Hose Stream must be greater than 0',
                },
              },
            },
            {
              key: 'hydraulicWaterSupplyDuration',
              type: 'simple-select',
              className: 'hydraulic-select-hose-stream margin-supply-duration',
              resetOnHide: true,
              props: {
                label: 'Supply Duration',
                isHorizontalOrientation: true,
                required: false,
                isAsteriskNotRequired: true,
                options: [
                  { value: 30, label: '30 minutes' },
                  { value: 60, label: '60 minutes' },
                  { value: 90, label: '90 minutes' },
                  { value: 120, label: '120 minutes' },
                  { value: 150, label: '150 minutes' },
                  { value: 180, label: '180 minutes' },
                ],
              },
              expressions: {
                'props.required': (field: FormlyFieldConfig) => {
                  if (field.parent.parent.form?.value?.isWaterSupplyNotEvaluated) {
                    return false;
                  }
                  const formlyService = field?.options?.formState?.service;
                  return formlyService.waterSupplyService.isHydralicSystemDesignFieldRequired(field, formlyService);
                },
              },
              hooks: {
                onInit: (field) => {
                  const options = [
                    { value: 30, label: '30 minutes' },
                    { value: 60, label: '60 minutes' },
                    { value: 90, label: '90 minutes' },
                    { value: 120, label: '120 minutes' },
                    { value: 150, label: '150 minutes' },
                    { value: 180, label: '180 minutes' },
                  ];
                  if (field?.formControl?.value && field?.formControl?.value !== null) {
                    const result = options.some((option) => option.value === parseInt(field.formControl.value));
                    if (!result) {
                      field?.formControl?.setValue(null);
                      field.props.required = true;
                    }
                  }
                },
              },
              validators: {
                checkDemandFlow: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => {
                    if (field.parent.parent.form?.value?.isWaterSupplyNotEvaluated) {
                      return true;
                    }
                    const isHydraulicEntryPresent = formlyBuilderService?.waterSupplyService?.checkHydraulic();
                    if (isHydraulicEntryPresent) {
                      if (control?.value && control?.value > 0) {
                        return true;
                      }
                      return false;
                    } else {
                      return true;
                    }
                  },
                  message: 'Supply Duration must be greater than 0',
                },
              },
            },
          ],
        },
        {
          key: 'hydraulicInformationSource',
          type: 'rf-radio',
          className: 'info-margin-right remove-padding left-padding-radio ',
          resetOnHide: true,
          props: {
            label: 'Information Source ',
            isHorizontalOrientation: true,
            slot: 'start',
            isCardEnabled: true,
            isLabelRight: true,
            required: false,
            options: [
              { label: 'Calculation', value: 'CALC', disabled: false },
              { label: 'Placard Only', value: 'PLACARD', disabled: false },
            ],
          },
          expressions: {
            'props.required': (field: FormlyFieldConfig) => {
              const formlyService = field?.options?.formState?.service;
              return formlyService.waterSupplyService.isHydralicSystemDesignFieldRequired(field, formlyService);
            },
          },
        },
      ],
    });
  }
}





