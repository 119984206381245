import { s3SyncConfig } from './s3SyncConfig';

export class s3SyncStrategy {
  public static RiskReport: s3SyncConfig = new s3SyncConfig('RiskReport', 'ReportIdentifier', 'rptId', ['photos']);
  public static Order: s3SyncConfig = new s3SyncConfig('Order', 'OrderIdFullOrderNumber', 'orderNo', [
    'riskreport',
    'photos',
    'documents',
  ]);
}
