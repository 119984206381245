/* eslint-disable @typescript-eslint/naming-convention */
export class BuildingCodesDataModel {

  public combustibilityClass?: string;
  public commercialStatisticalPlanCode?: string;
  public buildingWindCommercialStatisticalPlanCodeValue?: string;
  public basicGroup2Symbol?: string;
  public combustibilityClassV2?: string;
  public commercialStatisticalPlanCodeV2?: string;
  public buildingWindCommercialStatisticalPlanCodeValueV2?: string;
  public basicGroup2SymbolV2?: string;

  static fromS3(reports: any): BuildingCodesDataModel {
    return {
      combustibilityClass: reports?.CombustibilityClass,
      buildingWindCommercialStatisticalPlanCodeValue: reports?.BuildingWindCommercialStatisticalPlanCodeValue,
      commercialStatisticalPlanCode: reports?.CommercialStatisticalPlanCode,
      basicGroup2Symbol: reports?.BasicGroup2Symbol ? reports?.BasicGroup2Symbol?.trim() : undefined
    };
  }

  static to(model: any) {
    return {
      CombustibilityClass: model?.combustibilityClass,
      BuildingWindCommercialStatisticalPlanCodeValue: model?.buildingWindCommercialStatisticalPlanCodeValue,
      CommercialStatisticalPlanCode: model?.commercialStatisticalPlanCode,
      BasicGroup2Symbol: model?.basicGroup2Symbol
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   * @returns
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    return true;
  }
}
