import { Guid } from 'guid-typescript';

export class AttachmentDataModel {
  // From Risk Report
  reportAttachmentIdentifier: string;
  reportIdentifier: string;
  attachmentTypeCodeValue?: string;         // 'pdf', 'jpeg',...
  attachmentSequence?: number;
  attachmentDescription?: string;
  attachmentTimestamp?: string;
  attachment?: string;                      // byte[]
  fileName?: string;                        // Including '.' and type code value

  // Transient, not stored in DB
  fileExtension?: string;
  isSprinklerAttachment: boolean = false;

  // From Sprinkler Report
  reportAttachmentId?: string;
  reportId?: string;
  fileType?: string;
  // fileName?: string;
  reportAttachment1?: any;                  // byte[]

  /**
   * @deprecated
   * @param exposure 
   * @returns 
   */
  static from(attachment: AttachmentDataModel): any {
    return {};
  }

  /**
   * @warning Only save attachment timestamp if attachment is not read only (=not sprinkler attachment)
   * @param rrAttachment 
   * @returns 
   */
  static to(rrAttachment: any): any {
    return {
      ReportAttachmentIdentifier: rrAttachment.reportAttachmentIdentifier,
      ReportIdentifier: rrAttachment.reportIdentifier,
      AttachmentTypeCodeValue: rrAttachment.attachmentTypeCodeValue,
      AttachmentSequence: rrAttachment.attachmentSequence,
      AttachmentDescription: rrAttachment.attachmentDescription ? rrAttachment.attachmentDescription : undefined,
      AttachmentTimestamp: rrAttachment.isSprinklerAttachment ? new Date().toISOString() : (rrAttachment.attachmentTimestamp ? rrAttachment.attachmentTimestamp : undefined),
      Attachment: "",
      FileName: rrAttachment.fileName ? rrAttachment.fileName : undefined,
    }
  }

  /**
   * @warning Sprinkler Attachments have undefined timestamp
   * @param 
   * @returns 
   */
  static fromS3(riskModel: any): AttachmentDataModel {
    const attachmentTimestamp = riskModel.AttachmentTimestamp ? riskModel.AttachmentTimestamp : undefined;
    return {
      reportAttachmentIdentifier: riskModel.ReportAttachmentIdentifier,
      reportIdentifier: riskModel.ReportIdentifier,
      attachmentTypeCodeValue: riskModel.AttachmentTypeCodeValue,
      attachmentSequence: riskModel.AttachmentSequence,
      attachmentDescription: riskModel.AttachmentDescription ? riskModel.AttachmentDescription : undefined,
      attachmentTimestamp: attachmentTimestamp,
      attachment: "",
      fileName: riskModel.FileName ? riskModel.FileName : undefined,

      // Transient
      fileExtension: riskModel.FileName ? riskModel.FileName.split('.').pop() : undefined,
      isSprinklerAttachment: riskModel.AttachmentTypeCodeValue === '0009' ? true : false
    };
  }

  /**
   * @warning Sprinkler Attachments have undefined timestamp
   * @param riskModel 
   * @returns 
   */
  static fromS3Asgr(riskModel: any): AttachmentDataModel {
    const fileExtension = riskModel.FileType ? riskModel.FileType : '';
    const filePrefix = riskModel.FileName ? riskModel.FileName : undefined;
    return {
      reportAttachmentIdentifier: riskModel.ReportAttachmentId,
      reportIdentifier: riskModel.ReportId,
      attachmentTypeCodeValue: "0009",
      attachmentSequence: 0,
      attachmentDescription: filePrefix ? filePrefix + fileExtension : filePrefix,
      attachmentTimestamp: undefined,
      attachment: "",
      fileName: filePrefix ? filePrefix + fileExtension : filePrefix,

      // Transient
      fileExtension: fileExtension.split('.').pop(),
      isSprinklerAttachment: true
    };
  }
}
