/* eslint-disable @typescript-eslint/dot-notation */
import { FormControl } from '@angular/forms';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { FormlyWrapperService } from 'src/app/services/formly-builder/formly-wrapper.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { Constants } from 'src/app/services/util-service/constants';
import { SprinklerTableSectionModel } from './sprinkler-table.model';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export class SystemComponentSectionModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe1 = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'asgrSprinklerSystemComponent',
      type: 'rf-sheet-section',
      props: {
        label: 'System Component',
        subTitle: 'System Component',
        title: 'Sprinkler Report',
        isCollapsable: true,
        isCollapsed: false,
        isDisableable: true,
        isApplied: false,
        actionButtons: [
          {
            section: 'systemComponent',
            action: 'showFormlySheet',
            label: 'Add',
            isDisabled: false,
            service: 'formlyWrapperService',
          },
        ],
        sheetButtons: [
          {
            section: 'systemComponent',
            action: 'onClose',
            label: 'Close',
            service: 'systemComponentService',
            className: 'close-btn medium-btn',
            passInitialModel: true,
          },
          {
            section: 'systemComponent',
            action: 'onApply',
            label: 'Apply',
            service: 'systemComponentService',
            className: 'apply-btn large-btn',
            hasConfirmationPopup: true,
          },
        ],
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => field.form?.root?.get('evidenceOfFireSprinkler')?.value === true ||
          field.form?.root?.get('evidenceOfFireSprinkler')?.value === 'true' ? false : true,
        // 'props.actionButtons[0].label': (field: FormlyFieldConfig) => {
        //   // Check if model hasValues
        //   const formlyBuilder: FormlyBuilderService = field?.options?.formState?.service;
        //   const hasValues = formlyBuilder.hasValues(field?.formControl?.value,
        //     true,
        //     [
        //       "keyOfPropertyWithDefaultTrueValue"
        //     ]);
        //   return hasValues ? 'Edit' : 'Add';
        // }        
      },
      hooks:{
        onInit: (field: FormlyFieldConfig) =>{          
          let exclusions:string[] = 
            ['isCorrodedSprinkler',
              'isImproperUseOfHighLowTempSprinklers',
              'isOtherUnacceptableOrNonStandardSprinkler',
              'isOverAgeSprinkler',
              'isPaintedSprinkler',
              'isRecalledSprinklerHead',
              'acceptableDryPipeSecond',
              'standardFirst',
              'unacceptableThird',
              'hasNoPlansOrHydraulicCalculations',
              'hasOtherMiscellaneousItems1',
              'hasOtherMiscellaneousItems2',
              'isNonStandardPrivateMain',
              'isUnlistedMeter',
              'isNonStandardPipeSize',
              'isNonStandardSprinklerSpacing',
              'nonStandardPipeScheduleInstallType',
              'nonStandardPipeScheduleInstallTypeFirst',
              'nonStandardPipeScheduleInstallTypeSecond',
              'nonStandardPipeScheduleInstallTypeThird',
              'sprinklerOccupancyClass1For56Comment',
              'sprinklerOccupancyClass1For234Comment',
              'sprinklerOccupancyClass234For56Comment',
              'isNotReadilyAccessibleSupplyControl',
              'lacksFdConnection',
              'lacksLocalAlarm',
              'reportId'];
          //Checking Add or Edit Button to Display based on hasValue and Existing Risk
          if(formlyBuilderService.hasValues(field.model,false,exclusions) 
              || formlyBuilderService.isExistingRisk()){            
            field.props.actionButtons[0].label = 'Edit'
            field.formControl.markAllAsTouched();
          }
          //Subscribing to Apply Button Click and Save event to S3.
          formlyBuilderService.systemComponentService.SaveToS3Event.pipe(takeUntil(this.ngUnsubscribe1),
          //debounceTime(Constants.riskFormDataSyncDelay),
          distinctUntilChanged())
          .subscribe(()=>{
            if(field?.props?.isApplied){
              field.props.actionButtons[0].label = 'Edit'
              field.formControl.markAllAsTouched();
            }
          });        
        },
        onDestroy: () => {
          this.ngUnsubscribe1.next();
          this.ngUnsubscribe1.complete();
        }
      },
      fieldGroup: [
        {
          fieldGroupClassName: 'system-component-container',
          fieldGroup: [
            {
              key: 'overheadPiping',
              wrappers: [ 'simple-section-wrapper' ],
              props: {
                label: 'Overhead Sprinkler Piping',
                isCollapsable: true,
                isCollapsed: false,
              },
              // className: 'overhead-section', // TODO: To be Removed
              fieldGroup: [
                {
                  wrappers: [ 'question-wrapper' ],
                  fieldGroupClassName: 'input-checkbox-container',
                  className: 'wrapper-title',
                  props: {
                    label: 'Non - Standard Pipe Sizes',
                    firstColSize: 8,
                    showError: false,
                    isSectionLabelNormal: true,
                  },
                  fieldGroup: [
                    {
                      type: 'custom-checkbox',
                      key: 'isNonStandardPipeSize',
                      className: 'custom-checkbox',
                      defaultValue: false,
                      expressions: {
                        'props.isDisabled': (field: FormlyFieldConfig) => {
                          const isPipeSchedule = formlyBuilderService.systemComponentService.checkSprinklerDesign();
                          if (isPipeSchedule) {
                            return false;
                          } else {
                            return true;
                          }
                        },
                      },
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          field?.formControl?.valueChanges.subscribe((value) => {
                            if (value !== null && value) {
                              field?.form?.markAllAsTouched();
                            }
                          });
                        },
                      },
                    },
                    {
                      key: 'affectedAreaPipeSize',
                      type: 'simple-input',
                      className: 'checkbox-input',
                      props: {
                        unit: 'sq.ft.',
                        type: 'number',
                        isAsteriskNotRequired: true,
                      },
                      expressions: {
                        'props.readonly': (model: FormlyFieldConfig) => {
                          const isChecked = model.form?.get('isNonStandardPipeSize')?.value;
                          if (!isChecked) {
                            model.form?.get('affectedAreaPipeSize')?.reset();
                          }
                          return !isChecked;
                        },
                        'props.required': (model: FormlyFieldConfig) => model.form?.get('isNonStandardPipeSize')?.value,
                      },
                      validators: {
                        pipeSizeValidation: {
                          expression: (control: FormControl, field: FormlyFieldConfig) => {
                            const pipeSizeValue = control?.value;
                            const totalOccupancyArea = formlyBuilderService.occupantsService.getOccupancyTotalArea(
                              field,
                              true
                            );
                            if (pipeSizeValue !== null && pipeSizeValue && totalOccupancyArea !== null) {
                              if (Number(pipeSizeValue) > Number(totalOccupancyArea)) {
                                return false;
                              } else {
                                return true;
                              }
                            } else {
                              return true;
                            }
                          },
                          message: 'Affected Area cannot exceed Sprinklered Area in Occupancy entries',
                        },
                      },
                    },
                  ],
                },
                {
                  wrappers: [ 'question-wrapper' ],
                  fieldGroupClassName: 'input-checkbox-container',
                  className: 'wrapper-title',
                  props: {
                    label: 'Non - Standard Sprinkler Spacing',
                    firstColSize: 8,
                    showError: false,
                    isSectionLabelNormal: true,
                  },
                  fieldGroup: [
                    {
                      type: 'custom-checkbox',
                      key: 'isNonStandardSprinklerSpacing',
                      className: 'custom-checkbox',
                      defaultValue: false,
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          field?.formControl?.valueChanges.subscribe((value) => {
                            if (value !== null && value) {
                              field?.form?.markAllAsTouched();
                            }
                          });
                        },
                      },
                    },
                    {
                      key: 'affectedAreaSpacing',
                      type: 'simple-input',
                      className: 'checkbox-input',
                      props: {
                        unit: 'sq.ft.',
                        type: 'number',
                        isAsteriskNotRequired: true,
                      },
                      expressions: {
                        'props.readonly': (model: FormlyFieldConfig) => {
                          const isChecked = model.form?.get('isNonStandardSprinklerSpacing')?.value;
                          if (!isChecked) {
                            model.form?.get('affectedAreaSpacing')?.reset();
                          }
                          return !isChecked;
                        },
                        'props.required': (model: FormlyFieldConfig) =>
                          model.form?.get('isNonStandardSprinklerSpacing')?.value,
                      },
                      validators: {
                        areaSpacingValidation: {
                          expression: (control: FormControl, field: FormlyFieldConfig) => {
                            const areaSpacingValue = control?.value;
                            const totalOccupancyArea = formlyBuilderService.occupantsService.getOccupancyTotalArea(
                              field,
                              true
                            );
                            if (areaSpacingValue !== null && areaSpacingValue && totalOccupancyArea !== null) {
                              if (Number(areaSpacingValue) > Number(totalOccupancyArea)) {
                                return false;
                              } else {
                                return true;
                              }
                            } else {
                              return true;
                            }
                          },
                          message: 'Affected Area cannot exceed Sprinklered Area in Occupancy entries',
                        },
                      },
                    },
                  ],
                },
                {
                  wrappers: [ 'simple-section-wrapper' ],
                  className: 'sp-schedule-wrapper',
                  defaultValue: false,
                  props: {
                    label: 'Non - Standard Pipe Schedule Installation',
                    toggleAction: true,
                    showError: false,
                    isSectionLabelNormal: true,
                    className: 'row-padding-bottom'
                  },
                  expressions: {
                    'props.disableToggle': (field: FormlyFieldConfig) =>
                      !this.formlyBuilderService.systemComponentService.checkSprinklerDesign(),
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field?.form?.markAllAsTouched();
                      field?.formControl?.markAllAsTouched();
                      const isPipeSchedule = formlyBuilderService.systemComponentService.checkSprinklerDesign();
                      if (!isPipeSchedule) {
                        field?.form?.get('nonStandardPipeScheduleInstallType')?.setValue(isPipeSchedule);
                        if (field?.model?.nonStandardPipeScheduleInstallType) {
                          field.model.nonStandardPipeScheduleInstallType = isPipeSchedule;
                        }
                      } else {
                        const valueNonStandard = field?.model?.nonStandardPipeScheduleInstallType;
                        if (valueNonStandard !== null && valueNonStandard && valueNonStandard === 'N/A') {
                          if (field?.model?.nonStandardPipeScheduleInstallType) {
                            field.model.nonStandardPipeScheduleInstallType = false;
                          }
                          field?.form?.get('nonStandardPipeScheduleInstallType')?.setValue(false);
                        }
                      }
                    },
                  },
                  fieldGroup: [
                    {
                      key: 'nonStandardPipeScheduleInstallType',
                      defaultValue: false,
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          field?.formControl?.valueChanges.subscribe((value) => {
                            if (value !== null && value !== undefined && !value) {
                              this.formlyBuilderService.systemComponentService.resetValueOnToggleOff(field);
                            }
                          });
                        },
                      },
                    },
                    {
                      fieldGroup: [
                        {
                          key: 'sprinklerOccupancyClass1For234Comment',
                          hooks: {
                            onInit: (field: FormlyFieldConfig) => {
                              field?.formControl?.valueChanges.subscribe((value) => {
                                field.form?.markAllAsTouched();
                                field.form?.get('nonStandardPipeScheduleInstallTypeFirst')?.updateValueAndValidity();
                              });
                            },
                          },
                        },
                        {
                          key: 'sprinklerOccupancyClass234For56Comment',
                          hooks: {
                            onInit: (field: FormlyFieldConfig) => {
                              field?.formControl?.valueChanges.subscribe((value) => {
                                field.formControl?.markAllAsTouched();
                                field.form?.get('nonStandardPipeScheduleInstallTypeThird')?.updateValueAndValidity();
                              });
                            },
                          },
                        },
                        {
                          key: 'nonStandardPipeScheduleInstallTypeFirst',
                          type: 'rf-radio',
                          className: 'radio-group',
                          wrappers: [ 'question-wrapper' ],
                          defaultValue: 'N/A',
                          props: {
                            isHorizontalOrientation: true,
                            isSectionLabelNormal: true,
                            isSwipeGesture: false,
                            isCommentActionEnabled: true,
                            commentActionKey: 'sprinklerOccupancyClass1For234Comment',
                            label: 'Sprinkler Protection Occupancy Class 1 Piping for 2, 3 or 4 Occupancy',
                            firstColSize: 10,
                            showError: false,
                            slot: 'start',
                            options: [
                              {
                                label: null,
                                value: 'sprinklerOccupancyClass1For234',
                                disabled: false,
                              },
                            ],
                            click: (field: FormlyFieldConfig) => {
                              const value = field.formControl?.value;
                              if (value) {
                                const radioGroups = [
                                  { formName: 'nonStandardPipeScheduleInstallTypeThird', value: 'N/A' },
                                  { formName: 'nonStandardPipeScheduleInstallTypeSecond', value: 'N/A' },
                                  {
                                    formName: 'nonStandardPipeScheduleInstallType',
                                    value: 'sprinklerOccupancyClass1For234',
                                  },
                                ];
                                const fieldToClear = [
                                  'sprinklerOccupancyClass1For56Comment',
                                  'sprinklerOccupancyClass234For56Comment',
                                ];
                                formlyBuilderService.formlyWrapperService.changeRadioButtonGroup(
                                  field,
                                  radioGroups,
                                  fieldToClear
                                );
                              }
                            },
                          },
                          expressions: {
                            'props.isSwipeGesture': (field: FormlyFieldConfig) => {
                              const nonStandard = field?.form?.get('nonStandardPipeScheduleInstallType')?.value;
                              if (nonStandard !== null && nonStandard === 'sprinklerOccupancyClass1For234') {
                                field?.formControl?.setValue('sprinklerOccupancyClass1For234');
                                return true;
                              } else {
                                field?.formControl?.setValue('N/A');
                                return false;
                              }
                            },
                          },
                          hooks: {
                            onInit: (field: FormlyFieldConfig) => {
                              field?.formControl?.valueChanges.subscribe((value) => {
                                if (value !== null && value && value === 'sprinklerOccupancyClass1For234') {
                                  const radioGroups = [
                                    { formName: 'nonStandardPipeScheduleInstallTypeThird', value: 'N/A' },
                                    { formName: 'nonStandardPipeScheduleInstallTypeSecond', value: 'N/A' },
                                  ];
                                  formlyBuilderService.formlyWrapperService.changeRadioButtonGroup(field, radioGroups);
                                }
                              });
                            },
                          },
                          validators: {
                            fieldCommentValidation: {
                              expression: (control: FormControl, field: FormlyFieldConfig) => {
                                const comment = field.form?.get('sprinklerOccupancyClass1For234Comment')?.value;
                                const value = control?.value;
                                if (value !== null && value === 'sprinklerOccupancyClass1For234') {
                                  if (comment !== null && comment && comment?.length > 0) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                }
                                return true;
                              },
                              message: 'Sprinkler Protection Occupancy Class1For234 Comment is required',
                            },
                          },
                        },
                        {
                          key: 'sprinklerOccupancyClass1For56Comment',
                          hooks: {
                            onInit: (field: FormlyFieldConfig) => {
                              field?.formControl?.valueChanges.subscribe((value) => {
                                field.formControl?.markAllAsTouched();
                                field.form?.get('nonStandardPipeScheduleInstallTypeSecond')?.updateValueAndValidity();
                              });
                            },
                          },
                        },
                        {
                          key: 'nonStandardPipeScheduleInstallTypeSecond',
                          type: 'rf-radio',
                          className: 'radio-group',
                          wrappers: [ 'question-wrapper' ],
                          defaultValue: 'N/A',
                          props: {
                            isHorizontalOrientation: true,
                            isSectionLabelNormal: true,
                            isSwipeGesture: false,
                            isCommentActionEnabled: true,
                            commentActionKey: 'sprinklerOccupancyClass1For56Comment',
                            label: 'Sprinkler Protection Occupancy Class 1 Piping for 5 or 6 Occupancy',
                            firstColSize: 10,
                            showError: false,
                            slot: 'start',
                            options: [
                              {
                                value: 'sprinklerOccupancyClass1For56',
                                commentActionKey: '',
                                bgColor: '#168BF6',
                              },
                            ],
                            click: (field: FormlyFieldConfig) => {
                              const value = field.formControl?.value;
                              if (value) {
                                const radioGroups = [
                                  { formName: 'nonStandardPipeScheduleInstallTypeThird', value: 'N/A' },
                                  { formName: 'nonStandardPipeScheduleInstallTypeFirst', value: 'N/A' },
                                  {
                                    formName: 'nonStandardPipeScheduleInstallType',
                                    value: 'sprinklerOccupancyClass1For56',
                                  },
                                ];
                                const fieldToClear = [
                                  'sprinklerOccupancyClass1For234Comment',
                                  'sprinklerOccupancyClass234For56Comment',
                                ];
                                formlyBuilderService.formlyWrapperService.changeRadioButtonGroup(
                                  field,
                                  radioGroups,
                                  fieldToClear
                                );
                              }
                            },
                          },
                          expressions: {
                            'props.isSwipeGesture': (field: FormlyFieldConfig) => {
                              const nonStandard = field?.form?.get('nonStandardPipeScheduleInstallType')?.value;
                              if (nonStandard !== null && nonStandard === 'sprinklerOccupancyClass1For56') {
                                field?.formControl?.setValue('sprinklerOccupancyClass1For56');
                                return true;
                              } else {
                                field?.formControl?.setValue('N/A');
                                return false;
                              }
                            },
                          },
                          hooks: {
                            onInit: (field: FormlyFieldConfig) => {
                              field?.formControl?.valueChanges?.subscribe((value) => {
                                if (value !== null && value && value === 'sprinklerOccupancyClass1For56') {
                                  const radioGroups = [
                                    { formName: 'nonStandardPipeScheduleInstallTypeThird', value: 'N/A' },
                                    { formName: 'nonStandardPipeScheduleInstallTypeFirst', value: 'N/A' },
                                  ];
                                  formlyBuilderService.formlyWrapperService.changeRadioButtonGroup(field, radioGroups);
                                }
                              });
                            },
                          },
                          validators: {
                            fieldCommentValidation: {
                              expression: (control: FormControl, field: FormlyFieldConfig) => {
                                const comment = field.form?.get('sprinklerOccupancyClass1For56Comment')?.value;
                                const value = control?.value;
                                if (value !== null && value === 'sprinklerOccupancyClass1For56') {
                                  if (comment !== null && comment && comment?.length > 0) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                }
                                return true;
                              },
                              message: 'Sprinkler Protection Occupancy Class1For56 Comment is required',
                            },
                          },
                        },
                        {
                          key: 'nonStandardPipeScheduleInstallTypeThird',
                          type: 'rf-radio',
                          className: 'radio-group',
                          defaultValue: 'N/A',
                          wrappers: [ 'question-wrapper' ],
                          props: {
                            isHorizontalOrientation: true,
                            isSectionLabelNormal: true,
                            isSwipeGesture: false,
                            isCommentActionEnabled: true,
                            commentActionKey: 'sprinklerOccupancyClass234For56Comment',
                            label: 'Sprinkler Protection Occupancy Class 2, 3 or 4 Piping for 5 or 6 Occupancy',
                            firstColSize: 10,
                            showError: false,
                            slot: 'start',
                            options: [
                              {
                                value: 'sprinklerOccupancyClass234For56',
                                disabled: false,
                                commentActionKey: '',
                                bgColor: '#168BF6',
                              },
                            ],
                            click: (field: FormlyFieldConfig) => {
                              const value = field.formControl?.value;
                              if (value) {
                                const radioGroups = [
                                  { formName: 'nonStandardPipeScheduleInstallTypeSecond', value: 'N/A' },
                                  { formName: 'nonStandardPipeScheduleInstallTypeFirst', value: 'N/A' },
                                  {
                                    formName: 'nonStandardPipeScheduleInstallType',
                                    value: 'sprinklerOccupancyClass234For56',
                                  },
                                ];
                                const fieldToClear = [
                                  'sprinklerOccupancyClass1For234Comment',
                                  'sprinklerOccupancyClass1For56Comment',
                                ];
                                formlyBuilderService.formlyWrapperService.changeRadioButtonGroup(
                                  field,
                                  radioGroups,
                                  fieldToClear
                                );
                              }
                            },
                          },
                          expressions: {
                            'props.isSwipeGesture': (field: FormlyFieldConfig) => {
                              const nonStandard = field?.form?.get('nonStandardPipeScheduleInstallType')?.value;
                              if (nonStandard !== null && nonStandard === 'sprinklerOccupancyClass234For56') {
                                field?.formControl?.setValue('sprinklerOccupancyClass234For56');
                                return true;
                              } else {
                                field?.formControl?.setValue('N/A');
                                return false;
                              }
                            },
                          },
                          hooks: {
                            onInit: (field: FormlyFieldConfig) => {
                              field?.formControl?.valueChanges.subscribe((value) => {
                                if (value !== null && value && value === 'sprinklerOccupancyClass234For56') {
                                  const radioGroups = [
                                    { formName: 'nonStandardPipeScheduleInstallTypeSecond', value: 'N/A' },
                                    { formName: 'nonStandardPipeScheduleInstallTypeFirst', value: 'N/A' },
                                  ];
                                  formlyBuilderService.formlyWrapperService.changeRadioButtonGroup(field, radioGroups);
                                }
                              });
                            },
                          },
                          validators: {
                            fieldCommentValidation: {
                              expression: (control: FormControl, field: FormlyFieldConfig) => {
                                const comment = field.form?.get('sprinklerOccupancyClass234For56Comment')?.value;
                                const value = control?.value;
                                if (value !== null && value === 'sprinklerOccupancyClass234For56') {
                                  if (comment !== null && comment && comment?.length > 0) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                }
                                return true;
                              },
                              message: 'Sprinkler Protection Occupancy Class234For56 Comment is required',
                            },
                          },
                        },
                      ],
                    },
                  ],
                  validators: {
                    atLeastOneCheck: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        const value = control?.value?.nonStandardPipeScheduleInstallType;
                        const isPipeSchedule = this.formlyBuilderService.systemComponentService.checkSprinklerDesign();
                        if (value !== null && value === false) {
                          return true;
                        } else if (value !== null && value === true && isPipeSchedule) {
                          return false;
                        } else {
                          return true;
                        }
                      },
                      message: 'If condition applies, select which Non-Standard Pipe Schedule Installtion applies',
                    },
                  },
                },
              ],
            },
            {
              key: 'dryPipeValve',
              wrappers: [ 'simple-section-wrapper' ],
              props: {
                label: 'Dry Pipe Valve',
                isCollapsable: true,
                isCollapsed: false,
                toggleYesNo: true,
                className: 'padding-bottom',
                checkTouchedControl: true,
              },
              expressions: {
                'props.isDisabled': (field: FormlyFieldConfig) => {
                  return !formlyBuilderService.systemComponentService.enableDryPipe(field);
                }
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  const isDpvPipe = formlyBuilderService.systemComponentService.enableDryPipe(field);
                  if (isDpvPipe) {
                    // if (field?.model?.dpvToggle !== null) {
                    field.model.dpvToggle = true;
                    field.formControl?.get('dpvToggle')?.setValue(true);
                    // }
                  } else {
                    field.model.dpvToggle = false;
                    field.formControl?.get('dpvToggle')?.setValue(false);
                  }
                  const totalDryPipeArea = this.formlyBuilderService.systemComponentService.getTotalArea();
                  if (totalDryPipeArea !== null && totalDryPipeArea !== undefined) {
                    field?.formControl?.get('areaProtectedByDryPipeSystem')?.setValue(totalDryPipeArea);
                  }
                },
              },
              fieldGroup: [
                {
                  key: 'dpvToggle',
                  defaultValue: null,
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field?.formControl?.valueChanges.subscribe((value) => {
                        if (value === false) {
                          field?.form?.get('areaProtectedByDryPipeSystem')?.reset();
                          field?.form?.get('dpvType')?.reset('N/A');
                          field?.form?.get('standardFirst')?.reset('N/A');
                          field?.form?.get('acceptableDryPipeSecond')?.reset('N/A');
                          field?.form?.get('unacceptableThird')?.reset('N/A');
                        }
                        else if (value === true) {
                          const totalDryPipeArea = this.formlyBuilderService.systemComponentService.getTotalArea();
                          if (totalDryPipeArea !== null && totalDryPipeArea !== undefined) {
                            field?.form?.get('areaProtectedByDryPipeSystem')?.setValue(totalDryPipeArea);
                          }
                        }
                      });
                    },
                  },
                },
                {
                  key: 'dpvType',
                },
                {
                  fieldGroup: [
                    {
                      key: 'areaProtectedByDryPipeSystem',
                      type: 'simple-input',
                      className: 'checkbox-input area-input',
                      props: {
                        label: 'Area Protected by Dry Pipe',
                        isCardEnabled: true,
                        isHorizontalOrientation: true,
                        rightAlign: true,
                        required: true,
                        type: 'number',
                        unit: 'sq.ft.',
                        attributes: { tabIndex: '1' }
                      },
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          field?.formControl?.valueChanges.subscribe((value) => {
                            field?.form?.get('dpvType')?.updateValueAndValidity();
                            if (value !== null && field?.form?.get('unacceptableDpvArea') !== null) {
                              field?.form?.get('unacceptableDpvArea')?.updateValueAndValidity();
                            }
                          }
                          );
                        }
                      },
                      expressions: {
                        'props.readonly': (field: FormlyFieldConfig) => {
                          const occupancyDryArea = formlyBuilderService.systemComponentService.getTotalArea();
                          if (occupancyDryArea !== null) {
                            return true;
                          } else {
                            return false;
                          }
                        },
                        'props.required': (field: FormlyFieldConfig) => {
                          const dryPipeArea = this.formlyBuilderService.systemComponentService.getAreaByDryPipeSystemTesting(field);
                          const isDpvPipe = formlyBuilderService.systemComponentService.enableDryPipe(field);
                          if (field?.form?.value?.dpvType !== null && field?.form?.value?.dpvType !== undefined && field?.form?.value?.dpvType !== "N/A") {
                            if (isDpvPipe !== false || dryPipeArea === true) {
                              return true;
                            } else {
                              return false;
                            }
                          }
                          else {
                            return false;
                          }
                        }
                      },
                      validators: {
                        pipeSizeValidation: {
                          expression: (control: FormControl, field: FormlyFieldConfig) => {
                            const sprinkleredArea = formlyBuilderService.systemComponentService.dryPipeSprinkleredOccupancyArea(control, field);
                            return sprinkleredArea;
                          },
                          message: 'Affected Area cannot exceed Sprinklered Area in Occupancy entries',
                        },
                      },
                    },
                    {
                      fieldGroupClassName: 'radio-toggle-yes-no',
                      fieldGroup: [
                        {
                          key: 'standardFirst',
                          type: 'rf-radio',
                          className: 'radio-group-toggle',
                          defaultValue: 'N/A',
                          props: {
                            firstColSize: 10,
                            showError: false,
                            options: [
                              {
                                label: 'Standard Installation',
                                value: 'STD',
                                disabled: false,
                              },
                            ],
                            click: (field: FormlyFieldConfig) => {
                              field?.formControl?.markAllAsTouched();
                              field?.form?.markAllAsTouched();
                              const value = field.formControl?.value;
                              if (value) {
                                const radioGroups = [
                                  { formName: 'unacceptableThird', value: 'N/A' },
                                  { formName: 'acceptableDryPipeSecond', value: 'N/A' },
                                  {
                                    formName: 'dpvType',
                                    value: 'STD',
                                  },
                                ];
                                formlyBuilderService.formlyWrapperService.changeRadioButtonGroup(field, radioGroups);
                              }
                            },
                          },
                          hooks: {
                            onInit: (field: FormlyFieldConfig) => {
                              field?.formControl?.valueChanges.subscribe((value) => {
                                if (value !== null && value && value === 'STD') {
                                  const radioGroups = [
                                    { formName: 'unacceptableThird', value: 'N/A' },
                                    { formName: 'acceptableDryPipeSecond', value: 'N/A' },
                                  ];
                                  formlyBuilderService.formlyWrapperService.changeRadioButtonGroup(field, radioGroups);
                                }
                              });
                            },
                          },
                        },
                        {
                          key: 'isSupervisedDpv',
                          type: 'rf-yes-no',
                          className: 'yes-no-wrapper yes-no-toggle',
                          wrappers: [ 'question-wrapper' ],
                          resetOnHide: true,
                          props: {
                            label: 'Supervised',
                            isCardEnabled: true,
                            isSectionLabelNormal: true,
                            rightAlign: true,
                            isRequired: true,
                            showError: false,
                          },
                          expressions: {
                            hide: (field: FormlyFieldConfig) => {
                              if (field?.form?.controls[ 'standardFirst' ]?.value !== 'STD') {
                                return true;
                              } else {
                                return false;
                              }
                            },
                            'props.required': (field: FormlyFieldConfig) => {
                              if (field?.form?.controls[ 'dpvType' ]?.value === 'STD') {
                                field?.formControl?.markAsTouched();
                                return true;
                              } else {
                                return false;
                              }
                            }
                          },
                        }
                      ],
                    },
                    {
                      fieldGroupClassName: 'radio-toggle-yes-no',
                      fieldGroup: [
                        {
                          key: 'acceptableDryPipeSecond',
                          type: 'rf-radio',
                          className: 'radio-group-toggle',
                          defaultValue: 'N/A',
                          props: {
                            firstColSize: 10,
                            showError: false,
                            options: [
                              {
                                label: 'Acceptable Dry Pipe Valve Without or Inoperative Quick Opening Device',
                                value: 'ACC',
                                disabled: false,
                              },
                            ],
                            click: (field: FormlyFieldConfig) => {
                              const value = field.formControl?.value;
                              if (value) {
                                const radioGroups = [
                                  { formName: 'unacceptableThird', value: 'N/A' },
                                  { formName: 'standardFirst', value: 'N/A' },
                                  {
                                    formName: 'dpvType',
                                    value: 'ACC',
                                  },
                                ];
                                formlyBuilderService.formlyWrapperService.changeRadioButtonGroup(field, radioGroups);
                              }
                            },
                          },
                          hooks: {
                            onInit: (field: FormlyFieldConfig) => {
                              field?.formControl?.valueChanges.subscribe((value) => {
                                if (value !== null && value && value === 'ACC') {
                                  const radioGroups = [
                                    { formName: 'unacceptableThird', value: 'N/A' },
                                    { formName: 'standardFirst', value: 'N/A' },
                                  ];
                                  formlyBuilderService.formlyWrapperService.changeRadioButtonGroup(field, radioGroups);
                                }
                              });
                            },
                          },
                        },
                        {
                          key: 'isSupervisedDpvWithoutQod',
                          type: 'rf-yes-no',
                          className: 'yes-no-wrapper yes-no-toggle',
                          wrappers: [ 'question-wrapper' ],
                          defaultValue: false,
                          resetOnHide: true,
                          props: {
                            label: 'Supervised',
                            isCardEnabled: true,
                            isSectionLabelNormal: true,
                            rightAlign: true,
                            isRequired: true,
                            showError: false,
                          },
                          expressions: {
                            hide: (field: FormlyFieldConfig) => {
                              if (field?.form?.controls[ 'acceptableDryPipeSecond' ]?.value !== 'ACC') {
                                return true;
                              } else {
                                return false;
                              }
                            },
                            'props.required': (field: FormlyFieldConfig) => {
                              if (field?.form?.controls[ 'dpvType' ]?.value === 'ACC') {
                                field?.formControl?.markAsTouched();
                                return true;
                              } else {
                                return false;
                              }
                            }
                          },
                        },
                      ],
                    },
                    {
                      fieldGroupClassName: 'radio-toggle-yes-no',
                      fieldGroup: [
                        {
                          key: 'unacceptableThird',
                          type: 'rf-radio',
                          className: 'radio-group-toggle',
                          defaultValue: 'N/A',
                          props: {
                            firstColSize: 12,
                            showError: false,
                            options: [
                              {
                                label: 'Unacceptable Dry Pipe Valve or Obsolete',
                                value: 'OBS',
                                disabled: false,
                              },
                            ],
                            click: (field: FormlyFieldConfig) => {
                              const value = field.formControl?.value;
                              if (value) {
                                const radioGroups = [
                                  { formName: 'standardFirst', value: 'N/A' },
                                  { formName: 'acceptableDryPipeSecond', value: 'N/A' },
                                  {
                                    formName: 'dpvType',
                                    value: 'OBS',
                                  },
                                ];
                                formlyBuilderService.formlyWrapperService.changeRadioButtonGroup(field, radioGroups);
                              }
                            },
                          },
                          hooks: {
                            onInit: (field: FormlyFieldConfig) => {
                              field?.formControl?.valueChanges.subscribe((value) => {
                                if (value !== null && value && value === 'OBS') {
                                  const radioGroups = [
                                    { formName: 'standardFirst', value: 'N/A' },
                                    { formName: 'acceptableDryPipeSecond', value: 'N/A' },
                                  ];
                                  formlyBuilderService.formlyWrapperService.changeRadioButtonGroup(field, radioGroups);
                                }
                              });
                            },
                          },
                        },
                        {
                          key: 'unacceptableDpvArea',
                          type: 'simple-input',
                          className: 'yes-no-wrapper simple-input-wrapper',
                          resetOnHide: true,
                          props: {
                            label: 'Affected Area',
                            isCardEnabled: true,
                            isSectionLabelNormal: true,
                            isHorizontalOrientation: true,
                            rightAlign: true,
                            required: true,
                            type: 'number',
                            unit: 'sq.ft.',
                            attributes: { tabIndex: '2' }
                          },
                          expressions: {

                            hide: (field: FormlyFieldConfig) => {
                              if (field?.form?.controls[ 'unacceptableThird' ]?.value !== 'OBS') {
                                return true;
                              } else {
                                return false;
                              }
                            },
                            'props.required': (field: FormlyFieldConfig) => {
                              if (field?.form?.controls[ 'dpvType' ]?.value === 'OBS') {
                                return true;
                              } else {
                                return false;
                              }
                            }
                          },
                          validators: {
                            pipeSizeValidation: {
                              expression: (control: FormControl, field: FormlyFieldConfig) => {
                                const sprinkleredArea = formlyBuilderService.systemComponentService.dryPipeSprinkleredOccupancyArea(control, field);
                                return sprinkleredArea;
                              },
                              message: 'Affected Area cannot exceed Sprinklered Area in Occupancy entries',
                            },
                            areaProtectedByDryPipeSystem: {
                              expression: (control: FormControl, field: FormlyFieldConfig) => {
                                const areaProtectedByDryPipeSystem = formlyBuilderService.systemComponentService.areaProtectedByDryPipeSystem(control, field);
                                return areaProtectedByDryPipeSystem;
                              },
                              message: 'Affected Area cannot exceed area protected by the Dry Pipe System',
                            },
                          },
                        },
                      ],
                    },
                  ],
                  validators: {
                    atLeastOneCheck: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => formlyBuilderService.systemComponentService.checkAtleastDryPipeValve(control, field),
                      message: 'Dry Pipe Valve is required',
                    },
                  },
                },
              ],
            },
            {
              key: 'automaticSprinklers',
              wrappers: [ 'simple-section-wrapper' ],
              props: {
                label: 'Automatic Sprinklers',
                isCollapsable: true,
                isCollapsed: false,
                shallowErrors: false
              },
              className: 'automatic-sprinklers-container',
              fieldGroup: [
                {
                  key: 'sprinklerImproperTemperatureComment',
                  defaultValue: null,
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field?.formControl?.valueChanges.subscribe((value) =>
                        field?.formControl?.get('isImproperUseOfHighLowTempSprinklers')?.updateValueAndValidity()
                      );
                    },
                  },
                },
                {
                  key: 'isImproperUseOfHighLowTempSprinklers',
                  type: 'custom-checkbox',
                  className: 'simple-checkbox wrapper-title custom-margin-bottom',
                  wrappers: [ 'question-wrapper' ],
                  defaultValue: false,
                  props: {
                    label: 'Improper use of Special Application Sprinklers',
                    isSwipeGesture: true,
                    isCommentActionEnabled: true,
                    isSectionLabelNormal: true,
                    showError: false,
                    firstColSize: 8,
                    commentActionKey: 'sprinklerImproperTemperatureComment',
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => {
                      const isChecked = field.form?.get('isImproperUseOfHighLowTempSprinklers')?.value;
                      field.formControl?.markAllAsTouched();
                      if (!isChecked) {
                        field.props.isSwipeGesture = false;
                        field.form?.get('sprinklerImproperTemperatureComment')?.setValue(null);
                      } else {
                        field.props.isSwipeGesture = true;
                      }
                    },
                  },
                },
                {
                  key: 'otherSprinklerDescription',
                  defaultValue: null,
                  props: {
                    required: false
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field?.formControl?.valueChanges.subscribe((value) => {
                        field.formControl?.markAllAsTouched();
                        field.form?.controls[ 'nonStandardSprinkler' ]?.get('isOtherUnacceptableOrNonStandardSprinkler')?.updateValueAndValidity();
                      });
                    },
                  },
                },
                {
                  wrappers: [ 'simple-section-wrapper' ],
                  className: 'sp-schedule-wrapper',
                  props: {
                    label: 'Non-standard/Unacceptable Sprinkler Conditions',
                    isCollapsable: false,
                    showError: false,
                    className: 'row-padding-bottom'
                  },
                  fieldGroup: [
                    {
                      key: 'overageSprinkler',
                      wrappers: [ 'question-wrapper' ],
                      className: 'radio-group',
                      fieldGroupClassName: 'input-checkbox-container',
                      props: {
                        label: 'Overage Sprinklers',
                        isSectionLabelNormal: true,
                        showError: false
                      },
                      fieldGroup: [
                        {
                          key: 'isOverAgeSprinkler',
                          type: 'custom-checkbox',
                          className: 'custom-checkbox',
                          defaultValue: false,
                          hooks: {
                            onInit: (field: FormlyFieldConfig) => {
                              field?.formControl?.valueChanges.subscribe((value) => {
                                if (value !== null && value) {
                                  field?.formControl?.markAllAsTouched();
                                }
                              });
                            },
                          },
                          validators: {
                            sprinkleredAreaValidation: {
                              expression: (control: FormControl, field: FormlyFieldConfig) => {
                                const sprinklerTest = formlyBuilderService.systemComponentService.overAgeSprinklerTest(field, control);
                                return sprinklerTest;
                              },
                              message: 'If Overage Sprinkler information is entered on System Testing, Overage Sprinklers must be included in components',
                            },
                          },
                        },
                        {
                          key: 'overageSprinklerArea',
                          type: 'simple-input',
                          className: 'checkbox-input',
                          props: {
                            required: true,
                            type: 'number',
                            isAsteriskNotRequired: true,
                            unit: 'sq.ft.',
                          },
                          expressions: {
                            'props.readonly': (model: FormlyFieldConfig) => {
                              const isChecked = model.form?.get('isOverAgeSprinkler')?.value;
                              if (!isChecked) {
                                model.form?.get('overAgeSprinklerArea')?.reset();
                              }
                              return !isChecked;
                            },
                            'props.required': (model: FormlyFieldConfig) => model.form?.get('isOverAgeSprinkler')?.value,
                          },
                          validators: {
                            sprinkleredAreaValidation: {
                              expression: (control: FormControl, field: FormlyFieldConfig) => {
                                const sprinkleredArea = formlyBuilderService.systemComponentService.sprinkleredAreaValidation(control, field);
                                return sprinkleredArea;
                              },
                              message: 'Affected Area cannot exceed Sprinklered Area in Occupancy entries',
                            },
                          },
                        },
                      ]
                    },
                    {
                      key: 'corrodedSprinkler',
                      wrappers: [ 'question-wrapper' ],
                      fieldGroupClassName: 'input-checkbox-container',
                      className: 'radio-group',
                      props: {
                        label: 'Corroded Sprinklers',
                        isSectionLabelNormal: true,
                        showError: false
                      },
                      fieldGroup: [
                        {
                          key: 'isCorrodedSprinkler',
                          type: 'custom-checkbox',
                          className: 'custom-checkbox',
                          defaultValue: false,
                        },
                        {
                          key: 'corrodedSprinklerArea',
                          type: 'simple-input',
                          className: 'checkbox-input',
                          defaultValue: null,
                          props: {
                            required: true,
                            type: 'number',
                            isAsteriskNotRequired: true,
                            unit: 'sq.ft.'
                          },
                          expressions: {
                            'props.readonly': (model: FormlyFieldConfig) => {
                              const isChecked = model.form?.get('isCorrodedSprinkler')?.value;
                              if (!isChecked) {
                                model.form?.get('corrodedSprinklerArea')?.reset();
                              }
                              return !isChecked;
                            },
                            'props.required': (model: FormlyFieldConfig) => model.form?.get('isCorrodedSprinkler')?.value,
                          },
                          validators: {
                            sprinkleredAreaValidation: {
                              expression: (control: FormControl, field: FormlyFieldConfig) => {
                                const sprinkleredArea = formlyBuilderService.systemComponentService.sprinkleredAreaValidation(control, field);
                                return sprinkleredArea;
                              },
                              message: 'Affected Area cannot exceed Sprinklered Area in Occupancy entries',
                            },
                          },
                        },
                      ]
                    },
                    {
                      key: 'paintedSprinkler',
                      wrappers: [ 'question-wrapper' ],
                      fieldGroupClassName: 'input-checkbox-container',
                      className: 'radio-group',
                      props: {
                        label: 'Painted Sprinklers',
                        isSectionLabelNormal: true,
                        showError: false
                      },
                      fieldGroup: [
                        {
                          key: 'isPaintedSprinkler',
                          type: 'custom-checkbox',
                          className: 'custom-checkbox',
                          defaultValue: false,
                        },
                        {
                          key: 'paintedSprinklerArea',
                          type: 'simple-input',
                          className: 'checkbox-input',
                          props: {
                            required: true,
                            type: 'number',
                            isAsteriskNotRequired: true,
                            unit: 'sq.ft.'
                          },
                          expressions: {
                            'props.readonly': (model: FormlyFieldConfig) => {
                              const isChecked = model.form?.get('isPaintedSprinkler')?.value;
                              if (!isChecked) {
                                model.form?.get('paintedSprinklerArea')?.reset();
                              }
                              return !isChecked;
                            },
                            'props.required': (model: FormlyFieldConfig) => model.form?.get('isPaintedSprinkler')?.value,
                          },
                          validators: {
                            sprinkleredAreaValidation: {
                              expression: (control: FormControl, field: FormlyFieldConfig) => {
                                const sprinkleredArea = formlyBuilderService.systemComponentService.sprinkleredAreaValidation(control, field);
                                return sprinkleredArea;
                              },
                              message: 'Affected Area cannot exceed Sprinklered Area in Occupancy entries',
                            },
                          },
                        },
                      ]
                    },
                    {
                      key: 'recalledSprinkler',
                      wrappers: [ 'question-wrapper' ],
                      fieldGroupClassName: 'input-checkbox-container',
                      className: 'radio-group',
                      props: {
                        label: 'Recalled Sprinklers',
                        isSectionLabelNormal: true,
                        showError: false
                      },
                      fieldGroup: [
                        {
                          key: 'isRecalledSprinklerHead',
                          type: 'custom-checkbox',
                          className: 'custom-checkbox',
                          defaultValue: false,
                        },
                        {
                          key: 'recalledSprinklerHeadArea',
                          type: 'simple-input',
                          className: 'checkbox-input',
                          props: {
                            required: true,
                            type: 'number',
                            isAsteriskNotRequired: true,
                            unit: 'sq.ft.'
                          },
                          expressions: {
                            'props.readonly': (model: FormlyFieldConfig) => {
                              const isChecked = model.form?.get('isRecalledSprinklerHead')?.value;
                              if (!isChecked) {
                                model.form?.get('recalledSprinklerHeadArea')?.reset();
                              }
                              return !isChecked;
                            },
                            'props.required': (model: FormlyFieldConfig) => model.form?.get('isRecalledSprinklerHead')?.value,
                          },
                          validators: {
                            sprinkleredAreaValidation: {
                              expression: (control: FormControl, field: FormlyFieldConfig) => {
                                const sprinkleredArea = formlyBuilderService.systemComponentService.sprinkleredAreaValidation(control, field);
                                return sprinkleredArea;
                              },
                              message: 'Affected Area cannot exceed Sprinklered Area in Occupancy entries',
                            },
                          },
                        },
                      ]
                    },
                    {
                      key: 'nonStandardSprinkler',
                      wrappers: [ 'question-wrapper' ],
                      fieldGroupClassName: 'input-checkbox-container',
                      className: 'radio-group',
                      props: {
                        label: 'Other Non-Standard Sprinklers',
                        isSectionLabelNormal: true,
                        isSwipeGesture: true,
                        isCommentActionEnabled: true,
                        showError: false,
                        firstColSize: 8,
                        commentActionKey: 'otherSprinklerDescription',
                        required: false
                      },
                      expressions: {
                        'props.isSwipeGesture': (field: FormlyFieldConfig) => {
                          const otherNonStand = field?.formControl?.get('isOtherUnacceptableOrNonStandardSprinkler')?.value;
                          if (otherNonStand !== null && otherNonStand && otherNonStand === true) {
                            return true;
                          } else {
                            return false;
                          }
                        }
                      },

                      fieldGroup: [
                        {
                          key: 'isOtherUnacceptableOrNonStandardSprinkler',
                          type: 'custom-checkbox',
                          className: 'custom-checkbox',
                          defaultValue: false,
                        },
                        {
                          key: 'otherUnacceptableOrNonStandardSprinklerArea',
                          type: 'simple-input',
                          className: 'checkbox-input',
                          props: {
                            required: true,
                            type: 'number',
                            isAsteriskNotRequired: true,
                            unit: 'sq.ft.'
                          },
                          expressions: {
                            'props.readonly': (model: FormlyFieldConfig) => {
                              const isChecked = model.form?.get('isOtherUnacceptableOrNonStandardSprinkler')?.value;
                              if (!isChecked) {
                                model?.form?.get('otherUnacceptableOrNonStandardSprinklerArea')?.reset();
                                model?.parent?.form?.get('otherSprinklerDescription')?.reset();
                              }
                              return !isChecked;
                            },
                            'props.required': (model: FormlyFieldConfig) => model?.form?.get('isOtherUnacceptableOrNonStandardSprinkler')?.value,
                          },
                          validators: {
                            sprinkleredAreaValidation: {
                              expression: (control: FormControl, field: FormlyFieldConfig) => {
                                const sprinkleredArea = formlyBuilderService.systemComponentService.sprinkleredAreaValidation(control, field);
                                return sprinkleredArea;
                              },
                              message: 'Affected Area cannot exceed Sprinklered Area in Occupancy entries',
                            },
                          },
                        },


                      ]
                    },
                  ],
                  validators: {
                    accSprinklerTotalArea: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        if (control?.value !== null) {
                          const accSprinklerTotalArea = formlyBuilderService.systemComponentService.accumulatedTotalAreaValidation(field);
                          return accSprinklerTotalArea;
                        } else {
                          return true;
                        }
                      },
                      message: 'Affected Area cannot exceed Sprinklered Area in Occupancy entries',
                    },
                  },
                },
                SprinklerTableSectionModel.build(formlyBuilderService).fields
              ],
            },
            {
              key: 'sprinklerComponents',
              wrappers: [ 'simple-section-wrapper' ],
              props: {
                label: 'Sprinkler Components',
                isCollapsable: true,
                isCollapsed: false,
                className: 'padding-bottom ',
              },
              fieldGroup: [
                {
                  key: 'lackOfFireDepartmentConnectionComment',
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field?.formControl?.valueChanges.subscribe((value) =>
                        field.form?.get('lacksFdConnection')?.updateValueAndValidity()
                      );
                    },
                  },
                },
                {
                  key: 'lacksFdConnection',
                  type: 'custom-checkbox',
                  wrappers: [ 'question-wrapper' ],
                  className: 'wrapper-title custom-checkbox',
                  defaultValue: false,
                  props: {
                    label: 'Non Standard Fire Department Connection',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    isSectionLabelNormal: true,
                    isSwipeGesture: true,
                    isCommentActionEnabled: true,
                    showError: false,
                    firstColSize: 8,
                    commentActionKey: 'lackOfFireDepartmentConnectionComment',
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => {
                      const isChecked = field.form?.get('lacksFdConnection')?.value;
                      field.form?.markAllAsTouched();
                      if (!isChecked) {
                        field.props.isSwipeGesture = false;
                        field.form?.get('lackOfFireDepartmentConnectionComment')?.setValue(null);
                      } else {
                        field.props.isSwipeGesture = true;
                      }
                    },
                  },
                  validators: {
                    lackOfFireDepartmentConnectionComment: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        const commentField = field.form.value?.lackOfFireDepartmentConnectionComment;
                        const isChecked = field.form?.get('lacksFdConnection')?.value;
                        if (isChecked === true) {
                          if (commentField !== null && commentField && commentField?.length > 0) {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          return true;
                        }
                      },
                      message: 'Non Standard Fire Department Connection Comment is required',
                    },
                  },
                },
                {
                  key: 'lackOfLocalAlarmComment',
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field?.formControl?.valueChanges.subscribe((value) =>
                        field.form?.get('lacksLocalAlarm')?.updateValueAndValidity()
                      );
                    },
                  },
                },
                {
                  key: 'lacksLocalAlarm',
                  type: 'custom-checkbox',
                  wrappers: [ 'question-wrapper' ],
                  className: 'wrapper-title custom-checkbox',
                  defaultValue: false,
                  props: {
                    label: 'Local Water Flow Alarm',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    isSectionLabelNormal: true,
                    isSwipeGesture: true,
                    isCommentActionEnabled: true,
                    firstColSize: 8,
                    commentActionKey: 'lackOfLocalAlarmComment',
                    showError: false,
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => {
                      const isChecked = field.form?.get('lacksLocalAlarm')?.value;
                      if (!isChecked) {
                        field.props.isSwipeGesture = false;
                        field.form?.get('lackOfLocalAlarmComment')?.setValue(null);
                      } else {
                        field.props.isSwipeGesture = true;
                      }
                    },
                  },
                  validators: {
                    lackOfLocalAlarmComment: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        const commentField = field.form?.value?.lackOfLocalAlarmComment;
                        const isChecked = field.form?.get('lacksLocalAlarm')?.value;
                        if (isChecked === true) {
                          if (commentField !== null && commentField?.length > 0) {
                            return true;
                          } else {
                            return false;
                          }
                        }
                        return true;
                      },
                      message: 'Local Water Flow Alarm Comment is required',
                    },
                  },
                },
                {
                  wrappers: [ 'question-wrapper' ],
                  className: 'wrapper-title',
                  props: {
                    label: 'Absence of Indicating Valves Where Required',
                    firstColSize: 8,
                    showError: false,
                    isSectionLabelNormal: true,
                  },
                  fieldGroupClassName: 'input-checkbox-container', // class required to show wrapper title,checkbox,input in horizontally
                  fieldGroup: [
                    {
                      type: 'custom-checkbox',
                      key: 'isAbsentIndicatingValve',
                      className: 'custom-checkbox',
                      props: {
                        isCardEnabled: false,
                        labelFirst: true,
                        rightAlign: true,
                      },
                    },
                    {
                      key: 'absentIndicatingValveArea',
                      type: 'simple-input',
                      className: 'checkbox-input',
                      props: {
                        unit: 'sq.ft.',
                        type: 'number',
                        isAsteriskNotRequired: true,
                      },
                      expressions: {
                        'props.readonly': (model: FormlyFieldConfig) => {
                          const isChecked = model.form?.get('isAbsentIndicatingValve')?.value;
                          if (!isChecked) {
                            model.form?.get('absentIndicatingValveArea')?.reset();
                          }
                          return !isChecked;
                        },
                        'props.required': (model: FormlyFieldConfig) =>
                          model.form?.get('isAbsentIndicatingValve')?.value,
                      },
                      validators: {
                        absentIndicatingValveArea: {
                          expression: (control: FormControl, field: FormlyFieldConfig) => {
                            const absentAreaValue = control?.value;
                            const totalOccupancyArea = formlyBuilderService.systemComponentService.checkOccupancyLevels();
                            if (absentAreaValue !== null && absentAreaValue && totalOccupancyArea !== null && totalOccupancyArea !== undefined) {
                              if (Number(absentAreaValue) > Number(totalOccupancyArea)) {
                                return false;
                              } else {
                                return true;
                              }
                            } else {
                              return true;
                            }
                          },
                          message: 'Affected Area cannot exceed Sprinklered Area in Occupancy entries',
                        },
                      },
                    },
                  ],
                },
                {
                  key: 'controlValveAccessibilityComment',
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field?.formControl?.valueChanges.subscribe((value) =>
                        field.form?.get('isNotReadilyAccessibleSupplyControl')?.updateValueAndValidity()
                      );
                    },
                  },
                },
                {
                  key: 'isNotReadilyAccessibleSupplyControl',
                  type: 'custom-checkbox',
                  wrappers: [ 'question-wrapper' ],
                  className: 'wrapper-title custom-checkbox',
                  defaultValue: false,
                  props: {
                    label: 'Supply Control Valves Not Readily Accessible',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    isSectionLabelNormal: true,
                    firstColSize: 8,
                    isSwipeGesture: true,
                    isCommentActionEnabled: true,
                    commentActionKey: 'controlValveAccessibilityComment',
                    showError: false,
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => {
                      const isChecked = field.form?.get('isNotReadilyAccessibleSupplyControl')?.value;
                      if (!isChecked) {
                        field.props.isSwipeGesture = false;
                        field.form?.get('controlValveAccessibilityComment')?.setValue(null);
                      } else {
                        field.props.isSwipeGesture = true;
                      }
                    },
                  },
                  validators: {
                    controlValveAccessibilityComment: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        const commentField = field.form?.value?.controlValveAccessibilityComment;
                        const isChecked = field.form?.get('isNotReadilyAccessibleSupplyControl')?.value;
                        if (isChecked === true) {
                          if (commentField !== null && commentField?.length > 0) {
                            return true;
                          } else {
                            return false;
                          }
                        }
                        return true;
                      },
                      message: 'Supply Control Valves Not Readily Accessible Comment is required',
                    },
                  },
                },
              ],
            },
            {
              key: 'miscItemsLayout',
              wrappers: [ 'simple-section-wrapper' ],
              props: {
                label: 'Misc Items / Layout',
                isCollapsable: true,
                isCollapsed: false,
                className: 'padding-bottom ',
              },
              // className: 'overhead-section',  // TODO: To be Removed
              className: 'misc-items-layout',
              fieldGroup: [
                {
                  key: 'hasControlValveInNsArea',
                  wrappers: [ 'question-wrapper' ],
                  type: 'custom-checkbox',
                  className: 'wrapper-title simple-checkbox',
                  props: {
                    label: 'Automatic Sprinklered Control Valves Located in Non-Sprinklered Area',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    firstColSize: 10,
                    isSectionLabelNormal: true,
                    defaultValue: false,
                  },
                },
                {
                  key: 'hasPumpsInNsArea',
                  wrappers: [ 'question-wrapper' ],
                  type: 'custom-checkbox',
                  className: 'wrapper-title simple-checkbox',
                  props: {
                    label: 'Sprinklered Pump(s) Located in Non-Sprinklered Area',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    firstColSize: 10,
                    isSectionLabelNormal: true,
                    defaultValue: false,
                  },
                },
                {
                  key: 'hasPipingThroughNsArea',
                  wrappers: [ 'question-wrapper' ],
                  type: 'custom-checkbox',
                  className: 'wrapper-title simple-checkbox',
                  props: {
                    label: 'Main Overhead Piping Passes Through Non-Sprinklered Area',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    firstColSize: 10,
                    isSectionLabelNormal: true,
                    defaultValue: false,
                  },
                },
                {
                  key: 'hasNonSupervisedValves',
                  wrappers: [ 'question-wrapper' ],
                  type: 'custom-checkbox',
                  className: 'wrapper-title simple-checkbox',
                  props: {
                    label: 'Valves Not Supervised or Chained Open',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    firstColSize: 7,
                    isSectionLabelNormal: true,
                    defaultValue: false,
                  },
                },
                {
                  key: 'hasNoAboveGroundTest',
                  wrappers: [ 'question-wrapper' ],
                  type: 'custom-checkbox',
                  className: 'wrapper-title simple-checkbox',
                  props: {
                    label: 'No Above Ground Hydrostatic Test on File',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    firstColSize: 7,
                    isSectionLabelNormal: true,
                    defaultValue: false,
                  },
                },
                {
                  key: 'hasNoUndergroundFlushCertificate',
                  wrappers: [ 'question-wrapper' ],
                  type: 'custom-checkbox',
                  className: 'wrapper-title simple-checkbox',
                  props: {
                    label: 'No Underground Flush Certificate on File',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    firstColSize: 7,
                    isSectionLabelNormal: true,
                    defaultValue: false,
                  },
                },
                {
                  key: 'hasInsufficientSpareSprinklers',
                  wrappers: [ 'question-wrapper' ],
                  type: 'custom-checkbox',
                  className: 'wrapper-title simple-checkbox',
                  props: {
                    label: 'Insufficient Spare Sprinklers / No Wrench',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    firstColSize: 7,
                    isSectionLabelNormal: true,
                    defaultValue: false,
                  },
                },
                {
                  key: 'hasNoPlansOrHydraulicCalculations',
                  wrappers: [ 'question-wrapper' ],
                  type: 'custom-checkbox',
                  className: 'wrapper-title simple-checkbox',
                  defaultValue: false,
                  props: {
                    label: 'No Plans or Hydraulic Calcs',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    isSectionLabelNormal: true,
                    firstColSize: 7,
                  },
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      const formlyService = field.options.formState.service;
                      const HydraulicInformationSource =
                        formlyService.riskReport?.model?.sprinklerReport?.asgrWaterSupply?.hydraulicSystemDesign
                          ?.hydraulicInformationSource;
                      if (
                        HydraulicInformationSource !== null &&
                        HydraulicInformationSource &&
                        HydraulicInformationSource === 'PLACARD'
                      ) {
                        field.formControl?.setValue(true);
                        field.props.isDisabled = true;
                      } else {
                        field.props.isDisabled = false;
                      }
                    },
                  },
                  validators: {
                    hasNoPlansOrHydraulicCalcValidation: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        const formlyService = field.options.formState.service;
                        const HydraulicInformationSource =
                          formlyService.riskReport?.model?.sprinklerReport?.asgrWaterSupply?.hydraulicSystemDesign
                            ?.hydraulicInformationSource;
                        if (
                          HydraulicInformationSource !== null &&
                          HydraulicInformationSource &&
                          HydraulicInformationSource === 'CALC' &&
                          field?.formControl?.value
                        ) {
                          return false;
                        } else {
                          return true;
                        }
                      },
                      message: 'Not Applicable if Hydraulic Information Source is from Calcs',
                    },
                  },
                },
                {
                  key: 'hasNoEqBracing',
                  wrappers: [ 'question-wrapper' ],
                  type: 'custom-checkbox',
                  className: 'wrapper-title simple-checkbox',
                  props: {
                    label: 'Lack of Earthquake Bracing',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    firstColSize: 7,
                    isSectionLabelNormal: true,
                    defaultValue: false,
                  },
                },
                {
                  key: 'hasNonStandardFittings',
                  wrappers: [ 'question-wrapper' ],
                  type: 'custom-checkbox',
                  className: 'wrapper-title simple-checkbox',
                  props: {
                    label: 'Non-Standard Fittings',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    firstColSize: 7,
                    isSectionLabelNormal: true,
                    defaultValue: false,
                  },
                },
                {
                  key: 'hasImproperHangers',
                  wrappers: [ 'question-wrapper' ],
                  type: 'custom-checkbox',
                  className: 'wrapper-title simple-checkbox',
                  props: {
                    label: 'Improper Hangers or Hanger Installation',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    firstColSize: 7,
                    isSectionLabelNormal: true,
                    defaultValue: false,
                  },
                },
                {
                  key: 'hasImproperDrainageArrangements',
                  wrappers: [ 'question-wrapper' ],
                  type: 'custom-checkbox',
                  className: 'wrapper-title simple-checkbox',
                  props: {
                    label: 'Improper Drainage arrangements; Drains Inside, etc.',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    firstColSize: 10,
                    isSectionLabelNormal: true,
                    defaultValue: false,
                  },
                },
                {
                  key: 'unlistedMeterComment',
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field.formControl?.valueChanges.subscribe((value) =>
                        field.form?.get('isUnlistedMeter')?.updateValueAndValidity()
                      );
                    },
                  },
                },
                {
                  key: 'isUnlistedMeter',
                  type: 'custom-checkbox',
                  wrappers: [ 'question-wrapper' ],
                  className: 'wrapper-title simple-checkbox',
                  defaultValue: false,
                  props: {
                    label: 'Unlisted Meter',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    firstColSize: 8,
                    isSectionLabelNormal: true,
                    isSwipeGesture: true,
                    isCommentActionEnabled: true,
                    commentActionKey: 'unlistedMeterComment',
                    showError: false,
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => {
                      const isChecked = field.form?.get('isUnlistedMeter')?.value;
                      field?.formControl?.markAllAsTouched();
                      if (!isChecked) {
                        field.props.isSwipeGesture = false;
                        field.form?.get('unlistedMeterComment')?.setValue(null);
                      } else {
                        field.props.isSwipeGesture = true;
                      }
                    },
                  },
                  validators: {
                    unlistedMeterComment: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        const commentField = field.form.value?.unlistedMeterComment;
                        const isChecked = field.form?.get('isUnlistedMeter')?.value;
                        if (isChecked === true) {
                          if (commentField !== null && commentField && commentField?.length > 0) {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          return true;
                        }
                      },
                      message: 'Unlisted Meter Comment is required',
                    },
                  },
                },
                {
                  key: 'nonStandardPrivateMainComment',
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field.formControl?.valueChanges.subscribe((value) =>
                        field.form?.get('isNonStandardPrivateMain')?.updateValueAndValidity()
                      );
                    },
                  },
                },
                {
                  key: 'isNonStandardPrivateMain',
                  type: 'custom-checkbox',
                  wrappers: [ 'question-wrapper' ],
                  className: 'wrapper-title simple-checkbox',
                  defaultValue: false,
                  props: {
                    label: 'Non-Standard Private Main',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    firstColSize: 8,
                    isSectionLabelNormal: true,
                    isSwipeGesture: true,
                    isCommentActionEnabled: true,
                    commentActionKey: 'nonStandardPrivateMainComment',
                    showError: false,
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => {
                      const isChecked = field.form?.get('isNonStandardPrivateMain')?.value;
                      field?.formControl?.markAllAsTouched();
                      if (!isChecked) {
                        field.props.isSwipeGesture = false;
                        field.form?.get('nonStandardPrivateMainComment')?.setValue(null);
                      } else {
                        field.props.isSwipeGesture = true;
                      }
                    },
                  },
                  validators: {
                    nonStandardPrivateMainComment: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        const commentField = field.form?.value?.nonStandardPrivateMainComment;
                        const isChecked = field.form?.get('isNonStandardPrivateMain')?.value;
                        if (isChecked === true) {
                          if (commentField !== null && commentField && commentField?.length > 0) {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          return true;
                        }
                      },
                      message: 'Non Standard Private Main Comment is required',
                    },
                  },
                },
                {
                  key: 'otherMiscellaneous1Description',
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field.formControl?.valueChanges.subscribe((value) =>
                        field.form?.get('hasOtherMiscellaneousItems1')?.updateValueAndValidity()
                      );
                    },
                  },
                },
                {
                  key: 'hasOtherMiscellaneousItems1',
                  type: 'custom-checkbox',
                  wrappers: [ 'question-wrapper' ],
                  className: 'wrapper-title simple-checkbox',
                  defaultValue: false,
                  props: {
                    label: 'Other Miscellaneous 1',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    firstColSize: 8,
                    isSwipeGesture: true,
                    isSectionLabelNormal: true,
                    isCommentActionEnabled: true,
                    commentActionKey: 'otherMiscellaneous1Description',
                    showError: false,
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => {
                      const isChecked = field.form?.get('hasOtherMiscellaneousItems1')?.value;
                      field?.formControl?.markAllAsTouched();
                      if (!isChecked) {
                        field.props.isSwipeGesture = false;
                        field.form?.get('otherMiscellaneous1Description')?.setValue(null);
                      } else {
                        field.props.isSwipeGesture = true;
                      }
                    },
                  },
                  validators: {
                    otherMiscellaneous1Description: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        const commentField = field.form.value?.otherMiscellaneous1Description;
                        const isChecked = field.form?.get('hasOtherMiscellaneousItems1')?.value;
                        if (isChecked === true) {
                          if (commentField !== null && commentField && commentField?.length > 0) {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          return true;
                        }
                      },
                      message: 'Other Miscellaneous 1 Comment is required',
                    },
                  },
                },
                {
                  key: 'otherMiscellaneous2Description',
                  hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                      field.formControl?.valueChanges.subscribe((value) =>
                        field.form?.get('hasOtherMiscellaneousItems2')?.updateValueAndValidity()
                      );
                    },
                  },
                },
                {
                  key: 'hasOtherMiscellaneousItems2',
                  type: 'custom-checkbox',
                  wrappers: [ 'question-wrapper' ],
                  className: 'wrapper-title simple-checkbox',
                  defaultValue: false,
                  props: {
                    label: 'Other Miscellaneous 2',
                    isCardEnabled: false,
                    labelFirst: true,
                    rightAlign: true,
                    firstColSize: 8,
                    isSectionLabelNormal: true,
                    isSwipeGesture: true,
                    isCommentActionEnabled: true,
                    commentActionKey: 'otherMiscellaneous2Description',
                    showError: false,
                  },
                  expressions: {
                    hide: (field: FormlyFieldConfig) => {
                      field?.formControl?.markAllAsTouched();
                      const isChecked = field.form?.get('hasOtherMiscellaneousItems2')?.value;
                      if (!isChecked) {
                        field.props.isSwipeGesture = false;
                        field.form?.get('otherMiscellaneous2Description')?.setValue(null);
                      } else {
                        field.props.isSwipeGesture = true;
                      }
                    },
                  },
                  validators: {
                    otherMiscellaneous2Description: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        const commentField = field.form?.value?.otherMiscellaneous2Description;
                        const isChecked = field.form?.get('hasOtherMiscellaneousItems2')?.value;
                        if (isChecked === true) {
                          if (commentField !== null && commentField && commentField?.length > 0) {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          return true;
                        }
                      },
                      message: 'Other Miscellaneous 2 Comment is required',
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    });
  }
}
