import { Guid } from 'guid-typescript';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class WaterSupplyPipeScheduleDataModel {
    static sectionName = 'waterSupplyPipeSchedule';

    waterSupplyPipeScheduleId: string;
    reportId: string;
    spocNumber: number;
    squareFeet: number;
    scopesVersion: number;

    // transient variables
    precentage: number;
    isMostDemanding: boolean;

    static fromS3(wsPipeScheduleData: any, sprinklerReport: any): WaterSupplyPipeScheduleDataModel {

        return {
            waterSupplyPipeScheduleId: wsPipeScheduleData?.WaterSupplyPipeScheduleId,
            reportId: sprinklerReport?.ReportId,
            spocNumber: wsPipeScheduleData?.SpocNumber,
            squareFeet: wsPipeScheduleData?.SquareFeet,
            scopesVersion: wsPipeScheduleData?.ScopesVersion,
            precentage: 0,
            isMostDemanding: false
        };
    }

    static to(model: any, sectionEnum?: SectionEnum) {
        return {
            WaterSupplyPipeScheduleId: model.waterSupplyPipeScheduleId,
            ReportId: model.reportId,
            SpocNumber: model.spocNumber ? model.spocNumber : 0,
            SquareFeet: model.squareFeet ? model.squareFeet : 0,
            ScopesVersion: model.scopesVersion ? model.scopesVersion : 0
        }
    }

    /**
     * Check if the model has changed
     *
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     *
     */
    static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
        return false;

    }

    static createEmptySpocModel(reportId, index, scope) {
        return {
            waterSupplyPipeScheduleId: Guid.create().toString(),
            reportId: reportId,
            spocNumber: index,
            squareFeet: 0,
            scopesVersion: scope,
            precentage: 0,
            isMostDemanding: false
        }
    }

    static clearSpocModel(wsPipeScheduleData) {
        return {
            waterSupplyPipeScheduleId: wsPipeScheduleData?.waterSupplyPipeScheduleId,
            reportId: wsPipeScheduleData?.reportId,
            spocNumber: wsPipeScheduleData?.spocNumber,
            squareFeet: 0,
            scopesVersion: wsPipeScheduleData?.scopesVersion,
            precentage: 0,
            isMostDemanding: false
        };
    }
}
