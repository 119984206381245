/* eslint-disable arrow-body-style */
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { maxLengthForNumber, numberCheck } from 'src/app/validators/formly-builder/validators';

export class GeneralInformationModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      fieldGroup: [
        {
          key: 'buildingName',
          type: 'input',
          wrappers: [ 'question-wrapper' ],
          props: {
            label: 'Building Name',
            placeholder: 'Enter building name',
            required: true,
            optionsAvailable: false,
            maxLength: 35,
            className: 'building-name-width',
          },
          hooks: {
            afterViewInit: (field: FormlyFieldConfig) => {
              const formlyService = field.options.formState.service;
              return formlyService.generalInformationService.valueChangeSubscription(field,
                SectionEnum.GENERAL_INFO, SectionEnum.BUILDING, 1000);
            }
          }
        },
        {
          key: 'yearBuilt',
          type: 'input',
          wrappers: [ 'question-wrapper' ],
          props: {
            label: 'Year Built',
            required: false, // Sometimes it is empty...
            readonly: true,
            inputsize: 8,
            currencyNonEditable: true,
            // pattern: '^[12]\\d{3}$',
            maxLength: 4,
            className: 'year-built-width',
            type: 'number',
            keypress: (field, event) => {
              const charCode = (event.which) ? event.which : event.keyCode;
              if (maxLengthForNumber(event.target.value, field.props.maxLength)) {
                event.preventDefault();
              }
              return numberCheck(charCode);
            },
            hasSelectIcon: false, //yearbuilt is a picker so using this boolean to differentiate from normal inputs(to add icon)
          },
          hooks: {
            afterViewInit: (field: FormlyFieldConfig) => {
              const formlyService = field.options.formState.service;
              return formlyService.generalInformationService.
                valueChangeSubscription(field, SectionEnum.GENERAL_INFO, SectionEnum.YEAR_BUILT, 1000);
            }
          },
          validators: {
            lessThan: {
              expression: (c) => c.value > 1752,
              message: 'Year built cannot be before 1753',
            },
            futureDate: {
              expression: (c) => c.value < (new Date().getFullYear() +1),
              message: 'Year built cannot be in the future'
            }
          },
          validation: {
            messages: {
              pattern: (error: any, field: FormlyFieldConfig) => `Invalid year`,
            },
          },
          expressions: {
            'props.readonly': 'formState.service.mode === 0',
            'props.hasSelectIcon': 'formState.service.mode === 0',
            'props.required': 'formState.service.mode === 1'
          },
        },
        {
          key: 'fireHydrant',
          type: 'segment',
          wrappers: ['question-wrapper'],
          props: {
            // eslint-disable-next-line @typescript-eslint/quotes
            label: "Fire Hydrant within 1,000'",
            required: true,
            firstColSize: 4.5
          },
          expressions: {
            hide: () => formlyBuilderService.mode === 0
          },
          hooks: {
            afterViewInit: (field: FormlyFieldConfig) => {
              const formlyService = field.options.formState.service;
              return formlyService.generalInformationService.
                valueChangeSubscription(field, SectionEnum.GENERAL_INFO, SectionEnum.FIRE_HYDRANT, 0);
            }
          }
        },
        {
          key: 'evidenceOfFireSprinkler',
          type: 'segment',
          wrappers: [ 'question-wrapper' ],
          props: {
            label:'Evidence of Fire Sprinkler',
            required: true,
            firstColSize: 4.5
          },
          expressions: {
            hide: () => formlyBuilderService.mode === 0
          },
          hooks: {
            afterViewInit: (field: FormlyFieldConfig) => {
              const formlyService = field.options.formState.service;
              return formlyService.generalInformationService.
                valueChangeSubscription(field, SectionEnum.GENERAL_INFO, SectionEnum.FIRE_SPRINKLER, 0);
            }
          }
        }
      ]
    });
  }
}
