/* eslint-disable @typescript-eslint/dot-notation */
import { FormControl } from '@angular/forms';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { RiskModeEnum } from 'src/app/enums/formly-builder/risk-mode.enum';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class AddressSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'address',
      wrappers: [ 'simple-section-wrapper' ],
      props: {
        label: 'Address',
        actionButtons: [
          { action: 'add', label: 'Add New Alias', isDisabled: false }
        ],
        isCollapsable: false,
        tabindex: 0
      },
      expressions: {
        'props.isDisabled': 'formState.service.mode === 0',
        // 'props.actionButtons[0].isDisabled': 'formState.service.mode === 0'
      },
      fieldGroup: [
        {
          type: 'address',
          key: 'mainAddress',
          props: {
            label: 'Address'
          },
          expressions: {
            'props.isDisabled': 'formState.service.mode === 0'
          },
          fieldGroup: [
            {
              type: 'simple-input',
              key: 'city',
              className: 'width-176',
              props: {
                label: 'City',
                required: true,
                isAsteriskNotRequired: true,
                readonly: true,
                isDisabled: true
              },
              expressions: {
                'props.isAsteriskNotRequired': formlyBuilderService.mode !== RiskModeEnum.CURRENCY ? 'true' : 'false',
                'props.required': formlyBuilderService.mode !== RiskModeEnum.CURRENCY ? 'true' : 'false'
              }
            },
            {
              type: 'simple-input',
              key: 'state',
              className: 'width-114',
              props: {
                label: 'State',
                required: true,
                isAsteriskNotRequired: true,
                readonly: true,
                isDisabled: true
              },
              expressions: {
                'props.isAsteriskNotRequired': formlyBuilderService.mode !== RiskModeEnum.CURRENCY ? 'true' : 'false',
                'props.required': formlyBuilderService.mode !== RiskModeEnum.CURRENCY ? 'true' : 'false'
              }
            },
            {
              type: 'simple-input',
              key: 'zipcode',
              className: 'width-114',
              props: {
                label: 'ZIP Code',
                type: 'number',
                required: true,
                isAsteriskNotRequired: true,
                readonly: true,
                isDisabled: true
              },
              expressions: {
                'props.isAsteriskNotRequired': formlyBuilderService.mode !== RiskModeEnum.CURRENCY ? 'true' : 'false',
                'props.required': formlyBuilderService.mode !== RiskModeEnum.CURRENCY ? 'true' : 'false'
              }
            },
            {
              type: 'county-search-box',
              key: 'county',
              className: 'width-176',
              props: {
                label: 'County',
                required: true,
                isDisabled: true
              },
              expressions: {
                'props.isAsteriskNotRequired': formlyBuilderService.mode !== RiskModeEnum.CURRENCY ? 'true' : 'false',
                'props.isDisabled': formlyBuilderService.mode === RiskModeEnum.FULLRISK ? 'false' : 'true',
                'props.required': (field: FieldTypeConfig) => {
                  if (formlyBuilderService.mode === RiskModeEnum.CURRENCY) {
                    return false;
                  }
                  return field.form.value?.addressVerificationTypeCodeValue === 'V' ? false : true;
                }
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => { field.formControl.markAsTouched(); }
              },
              asyncValidators: {
                countyVerification: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => new Promise(async (resolve) => {
                    if (field.options.formState.service.mode !== RiskModeEnum.FULLRISK) {
                      return resolve(true);
                    }
                    const ppcSearchResult = await field.options.formState.service.formlyAddressService.getCountyList();
                    const countyListForState = [ ... new Set(ppcSearchResult.map(tag => tag?.CountyName)) ];
                    if (control?.value && countyListForState?.find((src: string) =>
                      src?.trim()?.toLowerCase() === control?.value?.trim()?.toLowerCase()) === undefined) {
                      return resolve(false);
                    }
                    return resolve(true);
                  }),
                  message: `Invalid County`
                }
              }
            },
            {
              // type: 'simple-input',
              key: 'streetNumber',
              props: {
                label: 'Street Number',
                type: 'number',
                required: true,
              },
              expressions: {
                'props.required': (field: FieldTypeConfig) => formlyBuilderService.mode !== RiskModeEnum.CURRENCY &&
                  field.form.value.addressVerificationTypeCodeValue === 'M' ? true : false
              }
            },
            {
              // type: 'simple-input',
              key: 'preDirectional',
              props: {
                label: 'Pre Directional',
                required: false,
              },
            },
            {
              // type: 'simple-input',
              key: 'streetName',
              props: {
                label: 'Street Name',
                required: true,
              },
              expressions: {
                'props.required': formlyBuilderService.mode !== RiskModeEnum.CURRENCY ? 'true' : 'false'
              }
            },
            {
              // type: 'simple-input',
              key: 'streetType',
              props: {
                label: 'Street Type'
              },
            },
            {
              // type: 'simple-input',
              key: 'postDirectional',
              props: {
                label: 'Post Directional',
                required: false,
              },
            },
            { key: 'highNumber' },
            { key: 'isAddressValidated' },
            { key: 'addressVerificationTypeCodeValue' },
            { key: 'prefix' },
            { key: 'zip4' },
            { key: 'reportAddressIdentifier' },
            { key: 'reportIdentifier' }
          ],
          validators: {
            addressNotVerified: {
              expression: (control: FormControl, field: FormlyFieldConfig) => {
                if (field.options.formState.service.mode === RiskModeEnum.CURRENCY) {
                  return true;
                }
                const addressVerificationTypeCodeValue = control[ 'controls' ]?.addressVerificationTypeCodeValue.value;
                return addressVerificationTypeCodeValue ? true : false;
              },
              message: `Verify address entry`
            },
          },
        },
        {
          type: 'address-alias',
          key: 'addressAliases',
          props: {
            label: 'Address Aliases'
          },
          expressions: {
            'props.isDisabled': 'formState.service.mode === 0'
          },
          fieldArray: {
            fieldGroup: [
              {
                type: 'simple-input',
                key: 'city',
                className: 'width-176',
                props: {
                  label: 'City',
                  isAsteriskNotRequired: true,
                  required: true,
                  isDisabled: true,
                  readonly: true
                },
                expressions: {
                  'props.isAsteriskNotRequired': formlyBuilderService.mode !== RiskModeEnum.CURRENCY ? 'true' : 'false',
                  'props.required': formlyBuilderService.mode !== RiskModeEnum.CURRENCY ? 'true' : 'false'
                }
              },
              {
                type: 'simple-input',
                key: 'state',
                className: 'width-114',
                props: {
                  label: 'State',
                  isAsteriskNotRequired: true,
                  required: true,
                  isDisabled: true,
                  readonly: true
                },
                expressions: {
                  'props.isAsteriskNotRequired': formlyBuilderService.mode !== RiskModeEnum.CURRENCY ? 'true' : 'false',
                  'props.required': formlyBuilderService.mode !== RiskModeEnum.CURRENCY ? 'true' : 'false'
                }
              },
              {
                type: 'simple-input',
                key: 'zipcode',
                className: 'width-114',
                props: {
                  label: 'ZIP Code',
                  isAsteriskNotRequired: true,
                  type: 'number',
                  required: true,
                  isDisabled: true,
                  readonly: true
                },
                expressions: {
                  'props.isAsteriskNotRequired': formlyBuilderService.mode !== RiskModeEnum.CURRENCY ? 'true' : 'false',
                  'props.required': formlyBuilderService.mode !== RiskModeEnum.CURRENCY ? 'true' : 'false'
                }
              },
              {
                // type: 'simple-input',
                key: 'streetNumber',
                props: {
                  label: 'Street Number',
                  type: 'number',
                  required: true
                },
                expressions: {
                  'props.required': (field: FieldTypeConfig) => formlyBuilderService.mode !== RiskModeEnum.CURRENCY &&
                    field.form.value?.addressVerificationTypeCodeValue === 'M' ? true : false
                }
              },
              {
                // type: 'simple-input',
                key: 'preDirectional',
                props: {
                  label: 'Pre Directional',
                  required: false
                }
              },
              {
                // type: 'simple-input',
                key: 'streetName',
                props: {
                  label: 'Street Name',
                  required: true
                },
                expressions: {
                  'props.required': formlyBuilderService.mode !== RiskModeEnum.CURRENCY ? 'true' : 'false'
                }
              },
              {
                // type: 'simple-input',
                key: 'streetType',
                props: {
                  label: 'Street Type'
                }
              },
              {
                // type: 'simple-input',
                key: 'postDirectional',
                props: {
                  label: 'Post Directional',
                  required: false
                }
              },
              {
                // type: 'simple-input',
                key: 'county',
                props: {
                  label: 'County',
                  required: true
                },
                expressions: {
                  'props.required': (field: FieldTypeConfig) => {
                    if (formlyBuilderService.mode === RiskModeEnum.CURRENCY) {
                      return false;
                    }
                    return field.form.value?.addressVerificationTypeCodeValue === 'V' ? false : true;
                  }
                }
              },
              {
                key: 'addressVerificationTypeCodeValue',
                // props: {
                //   required: true
                // },
                // expressions: {
                //   'props.required': () => formlyBuilderService.mode !== RiskModeEnum.CURRENCY ? true : false
                // }
              },
              { key: 'highNumber' },
              { key: 'isAddressValidated' },
              { key: 'prefix' },
              { key: 'zip4' },
              { key: 'reportAddressIdentifier' },
              {
                key: 'isDeleted',
                defaultValue: false,
              },
              { key: 'reportIdentifier' }
            ],
            validators: {
              aliasNotVerified: {
                expression: (control: FormControl, field: FormlyFieldConfig) => {
                  if (field.options.formState.service.mode === RiskModeEnum.CURRENCY) {
                    return true;
                  }
                  const addressVerificationTypeCodeValue = control[ 'controls' ]?.addressVerificationTypeCodeValue.value;
                  return addressVerificationTypeCodeValue ? true : false;
                },
                message: `Verify alias address entry`
              },
            },
          },
        },
      ],
    });
  }
}
