/* eslint-disable space-before-function-paren */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FloorsRoofConstructionDataModel } from 'src/app/models/formly-builder/formly/floors-roof-construction-data.model';
import { FloorsAndRoofDataModel } from 'src/app/models/formly-builder/formly/floors-roof-data.model';
import { FloorsRoofBuildingInformationDataModel } from 'src/app/models/formly-builder/formly/floors-roof-building-information-data.model';
import { FormlyFloorsRoofService } from '../../formly-floors-roof.service';
import { FormlyBuilderService } from '../../formly-builder.service';
import { SectionEnum } from '../../section.config';
import { ToastController } from '@ionic/angular';
import { LightFrameConstructionDataModel } from 'src/app/models/formly-builder/formly/floors-roof-light-frame-construction-data.model';
import { FloorsRoofBg2DataModel } from 'src/app/models/formly-builder/formly/floors-roof-bg2-data.model';

@Injectable({
  providedIn: 'root'
})
export class FullRiskFloorsRoofsService extends SectionServiceAbstract {

  constructor(private formlyFloorsRoofService: FormlyFloorsRoofService, public formlyBuilderService: FormlyBuilderService,
    private toastController: ToastController) {
    super();
  }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    //return { ...riskReportData, SprinklerReport:model.sprinklerReport };

    return undefined;
  }

  loadData(floorsAndRoof: any[], fullData: any, isS3: boolean, model: any): any {
    const floorsAndRoofConstructionDataModel: FloorsRoofConstructionDataModel[] = [];
    let floorsAndRoofModel: FloorsAndRoofDataModel;
    let floorsRoofBuildingInformationDataModel: FloorsRoofBuildingInformationDataModel;
    let lightFrameConstructionDataModel: LightFrameConstructionDataModel;
    let floorsRoofBg2DataModel: FloorsRoofBg2DataModel;

    floorsAndRoof?.forEach((floor) => {
      let floorsRoofRecord;
      let buildingInformationRecord;
      if (isS3) {
        floorsRoofRecord = FloorsRoofConstructionDataModel.fromS3(floor, true);
        // buildingInformationRecord = FloorsRoofBuildingInformationDataModel.fromS3(floor);
      } else {
        floorsRoofRecord = FloorsRoofConstructionDataModel.from(floor.data);
      }
      if (!floorsRoofRecord.area) {
        floorsRoofRecord.area = 1;
      }
      floorsAndRoofConstructionDataModel.push(floorsRoofRecord);
      // floorsRoofBuildingInformationDataModel.push(buildingInformationRecord)
    });
    // Info from general report area
    if (isS3) {
      if (fullData) {
        floorsAndRoofModel = FloorsAndRoofDataModel.fromS3(floorsAndRoofConstructionDataModel, fullData);
        floorsRoofBuildingInformationDataModel = FloorsRoofBuildingInformationDataModel.fromS3(fullData);
        lightFrameConstructionDataModel = LightFrameConstructionDataModel.fromS3(fullData);
        floorsRoofBg2DataModel = FloorsRoofBg2DataModel.fromS3(fullData);
      }
    } else {
      if (fullData && fullData.data) {
        floorsAndRoofModel = FloorsAndRoofDataModel.from(floorsAndRoofConstructionDataModel, fullData.data);
      }
    }

    if (floorsAndRoofModel) {
      floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels =
        this.sortFloorsAndRoofs(floorsAndRoofModel.constructionEntries?.floorsAndRoofsLevels);
      floorsAndRoofModel.buildingInformation = floorsRoofBuildingInformationDataModel;
      floorsAndRoofModel.lightFrameConstruction = lightFrameConstructionDataModel;
      floorsAndRoofModel.bg2Data = floorsRoofBg2DataModel;
      floorsAndRoofModel = this.calculateSummaryRowValues(floorsAndRoofModel, floorsAndRoofConstructionDataModel, true);
      this.formlyBuilderService.riskReport.report.AboveGradeFloors = floorsAndRoofModel?.buildingInformation?.aboveGradeFloors;
      this.formlyBuilderService.riskReport.model = {
        ...this.formlyBuilderService.riskReport.model,
        ...{
          floorsAndRoofs: floorsAndRoofModel,
          // TODO: Kevin: please check
          // yearBuilt: fullData.YearBuilt
        }
      };
    }

    return this.formlyBuilderService.riskReport.model;
  }

  sortFloorsAndRoofs(floorsAndRoofModel: any): FloorsRoofConstructionDataModel[] {
    let arrTemp: FloorsRoofConstructionDataModel[] = [];
    let floorsRoofArray = floorsAndRoofModel;

    // Grouping based on level type
    floorsRoofArray = floorsAndRoofModel.reduce((group, floorsRoof) => {
      if (floorsRoof.isLowestLevel) {
        group[ 'levelOnGrade' ] = [ floorsRoof ];
      } else if (floorsRoof.isAreaCalc) {
        if (floorsRoof.levelTypeCode === 'ROOF' || floorsRoof.areaCalcLevelTypeCode === 'ROOF') {
          group[ 'roofAca' ] = group[ 'roofAca' ] ? group[ 'roofAca' ] : [];
          group[ 'roofAca' ].push(floorsRoof);
        } else if (floorsRoof.levelTypeCode === 'FLOO' || floorsRoof.areaCalcLevelTypeCode === 'FLOO') {
          group[ 'floorAca' ] = group[ 'floorAca' ] ? group[ 'floorAca' ] : [];
          group[ 'floorAca' ].push(floorsRoof);
        } else if (floorsRoof.levelTypeCode === 'BASE' || floorsRoof.areaCalcLevelTypeCode === 'BASE') {
          group[ 'baseAca' ] = group[ 'baseAca' ] ? group[ 'baseAca' ] : [];
          group[ 'baseAca' ].push(floorsRoof);
        } else if (floorsRoof.levelTypeCode === 'MEZZ' || floorsRoof.areaCalcLevelTypeCode === 'MEZZ') {
          group[ 'mezzAca' ] = group[ 'mezzAca' ] ? group[ 'mezzAca' ] : [];
          group[ 'mezzAca' ].push(floorsRoof);
        } else if (floorsRoof.levelTypeCode === 'ATTI' || floorsRoof.areaCalcLevelTypeCode === 'ATTI') {
          group[ 'atticAca' ] = group[ 'atticAca' ] ? group[ 'atticAca' ] : [];
          group[ 'atticAca' ].push(floorsRoof);
        }
      } else if (floorsRoof.levelTypeCode) {
        const { levelTypeCode } = floorsRoof;
        group[ levelTypeCode ] = group[ levelTypeCode ] ? group[ levelTypeCode ] : [];
        group[ levelTypeCode ].push(floorsRoof);
      } else {
        group[ 'emptyType' ] = [ floorsRoof ];
      }
      return group;
    }, {});

    // Sorting each level type based on requirement
    floorsRoofArray.ROOF = floorsRoofArray.ROOF ? this.formlyFloorsRoofService.sortByProperty(floorsRoofArray.ROOF, 1, 'area') : [];
    floorsRoofArray.roofAca = floorsRoofArray.roofAca ?
      this.formlyFloorsRoofService.sortByProperty(floorsRoofArray.roofAca, 1, 'area') : [];
    floorsRoofArray.ATTI = floorsRoofArray.ATTI ? this.formlyFloorsRoofService.sortByProperty(floorsRoofArray.ATTI, 1, 'levelStart') : [];
    floorsRoofArray.atticAca = floorsRoofArray.atticAca ?
      this.formlyFloorsRoofService.sortByProperty(floorsRoofArray.atticAca, 1, 'levelStart') : [];
    floorsRoofArray.FLOO = floorsRoofArray.FLOO ? this.formlyFloorsRoofService.sortByProperty(floorsRoofArray.FLOO, 1, 'levelStart') : [];
    floorsRoofArray.floorAca = floorsRoofArray.floorAca ?
      this.formlyFloorsRoofService.sortByProperty(floorsRoofArray.floorAca, 1, 'levelStart') : [];
    floorsRoofArray.MEZZ = floorsRoofArray.MEZZ ? this.formlyFloorsRoofService.sortByProperty(floorsRoofArray.MEZZ, 1, 'levelStart') : [];
    floorsRoofArray.mezzAca = floorsRoofArray.mezzAca ?
      this.formlyFloorsRoofService.sortByProperty(floorsRoofArray.mezzAca, 1, 'levelStart') : [];
    floorsRoofArray.BASE = floorsRoofArray.BASE ? this.formlyFloorsRoofService.sortByProperty(floorsRoofArray.BASE, 2, 'levelStart') : [];
    floorsRoofArray.baseAca = floorsRoofArray.baseAca ?
      this.formlyFloorsRoofService.sortByProperty(floorsRoofArray.baseAca, 1, 'levelStart') : [];
    floorsRoofArray.levelOnGrade = floorsRoofArray.levelOnGrade ? floorsRoofArray.levelOnGrade : [];
    floorsRoofArray.emptyType = floorsRoofArray.emptyType ? floorsRoofArray.emptyType : [];

    // Concating all the leveltype datas
    arrTemp = arrTemp.concat(floorsRoofArray.ROOF, floorsRoofArray.roofAca, floorsRoofArray.ATTI, floorsRoofArray.atticAca,
      floorsRoofArray.FLOO, floorsRoofArray.floorAca, floorsRoofArray.MEZZ, floorsRoofArray.mezzAca,
      floorsRoofArray.BASE, floorsRoofArray.baseAca, floorsRoofArray.emptyType, floorsRoofArray.levelOnGrade);
    if (arrTemp.length === floorsAndRoofModel.length) {
      floorsAndRoofModel = [ ...arrTemp ];
    }
    return floorsAndRoofModel;
  }

  calculateSummaryRowValues(floorsAndRoofModel: any, floorsAndRoofConstructionDataModel: any, isOnLoad?: boolean) {
    // floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels =
    //   floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels.filter(src => src && src.levelTypeCode);
    this.calculateLevelOnGrade(floorsAndRoofModel);
    const floorsRoofLevels = floorsAndRoofModel?.constructionEntries?.floorsAndRoofsLevels;
    if (floorsAndRoofModel && floorsAndRoofModel.constructionEntries &&
      floorsRoofLevels && floorsRoofLevels.length > 0) {
      floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels.forEach(element => {
        if (element.isLowestLevel) {
          if (!floorsAndRoofModel.constructionEntries?.levelOnGradeLength && isOnLoad) {
            floorsAndRoofModel.constructionEntries.levelOnGradeLength =
              element.length || element.length === 0 ? Number(element.length) : null;
          }
          if (!floorsAndRoofModel.constructionEntries?.levelOnGradeWidth && isOnLoad) {
            floorsAndRoofModel.constructionEntries.levelOnGradeWidth =
              element.width || element.width === 0 ? Number(element.width) : null;
          }
          element.length = floorsAndRoofModel.constructionEntries?.levelOnGradeLength ||
            floorsAndRoofModel.constructionEntries?.levelOnGradeLength === 0 ?
            Number(floorsAndRoofModel.constructionEntries?.levelOnGradeLength) : null;
          element.width = floorsAndRoofModel.constructionEntries?.levelOnGradeWidth ||
            floorsAndRoofModel.constructionEntries?.levelOnGradeWidth === 0 ?
            Number(floorsAndRoofModel.constructionEntries?.levelOnGradeWidth) : null;
          element.area = floorsAndRoofModel.constructionEntries?.levelOnGradeArea ?
            Number(floorsAndRoofModel.constructionEntries?.levelOnGradeArea) : 0;
        }
        element.area = Number(element.length) * Number(element.width);
      });
    }

    floorsAndRoofModel.buildingInformation.totalArea = this.calculatetotalArea(floorsAndRoofConstructionDataModel);
    const floorsAndRoofsLevels = floorsAndRoofModel?.constructionEntries?.floorsAndRoofsLevels; // .filter(src => !src.isLowestLevel);
    floorsAndRoofModel.buildingInformation.aboveGradeFloors =
      this.formlyBuilderService.mode === 2 ? this.calculateNoOfFloors(floorsAndRoofsLevels) : floorsAndRoofModel.aboveGradeFloors;
    floorsAndRoofModel.buildingInformation.basementLevels =
      this.formlyBuilderService.mode === 2 ? this.calculateBasementLevelsValue(floorsAndRoofsLevels) : floorsAndRoofModel.basementLevels;
    return floorsAndRoofModel;
  }


  calculateLevelOnGrade(floorsAndRoofModel: any) {

    const floorsRoofLevels = floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels;
    if (
      floorsAndRoofModel &&
      floorsAndRoofModel.constructionEntries &&
      floorsRoofLevels &&
      floorsRoofLevels.length > 0
    ) {
      const basementLevelOnGradeIndex = floorsRoofLevels
        .findIndex(src => src.levelTypeCode === 'BASE' && !src.levelType?.includes('LEVELONGRADE') && src?.isLowestLevel === false &&
          Number(src.levelStart) === Math.max(...floorsRoofLevels.filter(x => x.levelTypeCode === 'BASE' && !x?.isLowestLevel)
            .map(x => x.levelStart && x.levelStart !== null && x.levelStart !== undefined ? Number(x.levelStart) : 0)));

      const floorLevelOnGradeIndex = floorsRoofLevels.findIndex(src => src.levelTypeCode === 'FLOO' && !src.isLowestLevel &&
        Number(src.levelStart) === 1);

      const index = floorsRoofLevels.findIndex(src => src.isLowestLevel === true);
      if (index > -1) {
        if (basementLevelOnGradeIndex !== -1) {
          const baseArray = floorsRoofLevels
            .filter((src) => src.levelTypeCode === 'BASE' && !src?.isLowestLevel);
          const levelVal = baseArray?.map((x) =>
            Math.max(
              x.levelStart && x.levelStart !== null && x.levelStart !== undefined ? Number(x.levelStart) : 0,
              x.levelStop && x.levelStop !== null && x.levelStop !== undefined ? Number(x.levelStop) : 0
            ));

          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels?.forEach((src) => {
            if (src.isLowestLevel === true) {
              src.isLowestLevel = false;
              switch (src.levelTypeCode) {
                case 'FLOO':
                  src.levelType = 'Floor';
                  break;
                case 'BASE':
                  src.levelType = 'Basement';
                  break;
              }
            }
          });

          const baseLevelStop = Math.max(...levelVal) + 1;
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].isLowestLevel = true;
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelType = 'LEVELONGRADEBase';
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelTypeCode = 'BASE';
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelStop = baseLevelStop.toString();
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelStart = baseLevelStop.toString();
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelStop = null;
        }
        else if (floorLevelOnGradeIndex !== -1) {
          const index = floorsRoofLevels.findIndex(src => src.isLowestLevel === true);
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels?.forEach(src => {
            if (src.isLowestLevel === true) {
              src.isLowestLevel = false;
              switch (src.levelTypeCode) {
                case 'FLOO': src.levelType = 'Floor';
                  break;
                case 'BASE': src.levelType = 'Basement';
                  break;
              }
            }
          });
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].isLowestLevel = true;
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelType = 'LEVELONGRADEBase';
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelTypeCode = 'BASE';
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelStart = '1';
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelStop = null;
        }
        else if (floorLevelOnGradeIndex === -1 && basementLevelOnGradeIndex === -1) {
          const tempIndex = floorsRoofLevels.findIndex(src => src.levelType?.includes('LEVELONGRADE'));
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels?.forEach(src => {
            if (src.isLowestLevel === true) {
              src.isLowestLevel = false;
              switch (src.levelTypeCode) {
                case 'FLOO': src.levelType = 'Floor';
                  break;
                case 'BASE': src.levelType = 'Basement';
                  break;
              }
            }
          });
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].isLowestLevel = true;
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelType = 'LEVELONGRADEFloor';
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelTypeCode = 'FLOO';
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelStart = '1';
          floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels[ index ].levelStop = null;
        }
      }

    }
  }

  calculateNoOfFloors(floorsAndRoofModel: FloorsRoofConstructionDataModel[]) {
    let aboveGradeFloors = 0;
    aboveGradeFloors = floorsAndRoofModel?.length ?
      this.getMaxLevel(floorsAndRoofModel, 'FLOO') : 0;
    return aboveGradeFloors;
  }

  calculateBasementLevelsValue(floorsAndRoofModel: FloorsRoofConstructionDataModel[]) {
    let basementLevels = 0;
    basementLevels = floorsAndRoofModel?.length ?
      this.getMaxLevel(floorsAndRoofModel, 'BASE') : 0;
    return basementLevels;
  }

  calculatetotalArea(floorsAndRoofConstructionDataModel: any) {
    let totalArea = 0;
    floorsAndRoofConstructionDataModel.forEach((floor) => {
      if (floor && floor.levelType !== 'Roof' && floor.area && !floor.isAreaCalc) {
        if (Number(floor.levelStop) > 0 && Number(floor.levelStart) < Number(floor.levelStop)) {
          totalArea += floor.area * (Number(floor.levelStop) - Number(floor.levelStart) + 1);
        } else {
          totalArea += floor.area;
        }
      } else if (floor.isAreaCalc && floor.areaCalcLevelTypeCode !== 'ROOF') {
        totalArea += floor.width;
      }
    });
    return totalArea;
  }

  updateSummaryRowValues(riskReport: any) {
    // let floorsAndRoofModel = this.riskReportService.model.floorsAndRoofs;
    let floorsAndRoofModel = this.formlyBuilderService.riskReport.model.floorsAndRoofs;
    floorsAndRoofModel = this.calculateSummaryRowValues(floorsAndRoofModel, floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels);
    floorsAndRoofModel.constructionEntries.floorsAndRoofsLevels =
      this.sortFloorsAndRoofs(floorsAndRoofModel.constructionEntries?.floorsAndRoofsLevels);
    floorsAndRoofModel.constructionClass = riskReport.ConstructionClass;
    // this.riskReportService.model = { ...this.riskReportService.model, ...{ floorsAndRoof: floorsAndRoofModel } };
    this.formlyBuilderService.riskReport.model = {
      ...this.formlyBuilderService.riskReport.model, ...{ floorsAndRoofs: floorsAndRoofModel }
    };
  }

  getMaxLevel(allLevels: any[], levelType: string): number {
    return Math.max(
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      ...allLevels?.map(function (level) {
        if (level && level?.levelTypeCode && level?.levelStart) {
          if (level.levelTypeCode.indexOf(levelType) > -1 && Number(level.levelStart)) {
            if (Number(level.levelStop)) {
              return Math.max(Number(level.levelStart), Number(level.levelStop));
            }
            return Number(level.levelStart);
          }
        }
        return 0;
      })
    );
  }

}
