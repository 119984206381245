import { EventEmitter, Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyBuilderService } from '../../formly-builder.service';
import { SectionEnum } from '../../section.config';
import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { RiskReportService } from '../../risk-report.service';
import { BuildingConditionDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/building-condition-data.model';
import { UtilService } from 'src/app/services/util-service/util.service';


@Injectable({
  providedIn: 'root',
})
export class BuildingConditionService extends SectionServiceAbstract {
  SaveToS3Event: EventEmitter<any> = new EventEmitter<any>();
  constructor(private formlyBuilderService: FormlyBuilderService,
    private riskReportService: RiskReportService, private utilService: UtilService) {
    super();
  }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    const sectionData = model?.sprinklerReport?.asgrBuildingCondition;
    let excessiveHeight = null;
    this.formlyBuilderService.sprinklerReportService.clearScoreValues(model)
    if (sectionData) {
      excessiveHeight = BuildingConditionDataModel.to(sectionData, riskReportData.ReportIdentifier);
    }
    const sprinklerData = {
      ...riskReportData.SprinklerReport,
      ...{ AsgrBuildingCondition: excessiveHeight },
    };
    return { ...riskReportData, ...{ SprinklerReport: sprinklerData } };
  }

  loadData(sectionData: any[], reportInfo: any, isS3: boolean, model: any): any {
    return undefined;
  }

  async onApply(modalCtrl, field) {
    const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const riskReport = await formlyService.riskReport.updateSectionData(SectionEnum.FR_S_BUILDING_CONDITION, false);   
    formlyService.errorService.closePreviousToaster();
    field.props.isApplied = true;
    formlyService.buildingConditionService.SaveToS3Event.emit();
    modalCtrl.dismiss();
  }

  onClose(modalCtrl, field, initialModel) {
    setTimeout(() => {
      const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const reportData = formlyService.riskReport.report;
    const newModel = formlyService.sprinklerReportService.loadData(reportData, reportData, true, formlyService.riskReport.model, true);
    if (newModel) {
      formlyService.riskReport.model = { ...newModel };
    }  
      formlyService.errorService.closePreviousToaster();
    }, 100);
    modalCtrl.dismiss(); 
    

  }
  
  requiredAreaSpocValidation(control: FormControl, field: FormlyFieldConfig): boolean {
    const area = control?.value;
    const formlyService = field?.options?.formState?.service;
    const totalSpocArea = formlyService.buildingConditionService.getSpocArea(formlyService);
    if (area !== null && area) {
      if (Number(area) > Number(totalSpocArea)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  checkBuildingConditionEntry(field: FormlyFieldConfig) {
    const modelValue = this.riskReportService.model;
    const buildingHeightInFeet = modelValue?.sprinklerReport?.asgrWaterSupply?.evalutionOfWaterSupply?.buildingHeightInFeet;
    field.props.hideExcessivestory = (!buildingHeightInFeet || buildingHeightInFeet <= 20);
  }

  resetExcessiveHeight(){
    const modelValue1 = this.riskReportService.model;
    const buildingHeightInFeet1 = modelValue1?.sprinklerReport?.asgrWaterSupply?.evalutionOfWaterSupply?.buildingHeightInFeet;
    const reportValue1 = this.riskReportService?.report?.SprinklerReport?.AsgrBuildingCondition?.BuildingConditionExcessSingleStories;
    if(!buildingHeightInFeet1 || buildingHeightInFeet1 <= 20){
      if(reportValue1 && this.formlyBuilderService.isNewRisk()){
        this.riskReportService.report.SprinklerReport.AsgrBuildingCondition.BuildingConditionExcessSingleStories = [];
      }
    }
  }
  hasDuplicateWithDifferentFields(field): boolean {
    let allValues;

    if (typeof field.formControl.value === 'string') {
      allValues = field?.parent.form.value;
    } else {
      allValues = field?.formControl.value;
    }
    const systemNumbers = {};
    for (const obj of allValues) {
      if (systemNumbers[ obj.systemNumber ]) {
        // Found a duplicate systemNumber
        const existingObjs = systemNumbers[ obj.systemNumber ];
        for (const existingObj of existingObjs) {
          if (
            existingObj.areaDescription !== obj.areaDescription ||
            existingObj.excessStoryHeight !== obj.excessStoryHeight ||
            existingObj.excessStoryArea !== obj.excessStoryArea
          ) {
            // Other fields are different, continue checking
            continue;
          } else {
            // Other fields are the same, return true
            return true;
          }
        }
        // If no match found, add the object to existingObjs array
        existingObjs.push(obj);
      } else {
        // Store the object with its systemNumber
        systemNumbers[ obj.systemNumber ] = [ obj ];
      }
    }
    // No duplicate systemNumber with different fields found
    return false;
  }
  generateExcessiveHeightId(field) {
    if (field?.formControl?.value === null) {
      const id = this.utilService.uuid();
      field?.formControl?.setValue(id);
    }
  }

  setReportId(field) {
    const value = field?.formControl?.value;
    const id = field?.options?.formState?.service?.riskReport?.report?.SprinklerReport?.ReportId;
    if (!value && id) {
      field?.formControl?.setValue(id);
    }
  }

  getFloorOpeningsOptions() {
    return [
      { label: 'No Floor Openings', value: null },
      { label: 'Unprotected Openings', value: 'UNPROT' },
      { label: 'Openings protected by AS', value: 'AS' },
      { label: 'Openings protected by Draft Curtain', value: 'DRAFT' },
      { label: 'Openings protected by AS AND Draft Curtain', value: 'AS/DRAFT' },
    ];
  }

  getStructuralDisadvantageOptions() {
    return [
      { label: 'No Open-sided Levels', value: '0' },
      { label: 'One Open-sided Level', value: '1' },
      { label: 'Two Open-sided Levels', value: '2' },
      { label: 'Three or more Open-sided Levels', value: '3' },
    ];
  }

  // to check entries based floors and roofs levelTypeCode and constructionType
  checkFloorRoofEntries(field: FormlyFieldConfig, isLevelType) {
    const floorRoofEntries = field?.options?.formState?.service?.riskReport?.model?.
      floorsAndRoofs?.constructionEntries?.floorsAndRoofsLevels;
    let totalEntries = '';
    const allEntries = [];
    if (floorRoofEntries && floorRoofEntries.length > 0) {
      if (isLevelType) {
        if (floorRoofEntries.length === 2) {
          return totalEntries = 'FMB';
        }
        const floorRoofEntry =
          floorRoofEntries.find((floorRoof) => (floorRoof.levelTypeCode === 'FLOO' || floorRoof.levelTypeCode === 'MEZZ' ||
            floorRoof.levelTypeCode === 'BASE' || floorRoof.levelTypeCode === 'ATTI') && !floorRoof.isLowestLevel);
        if (floorRoofEntry) {
          totalEntries = 'FMB';
        }
      } else {
        floorRoofEntries.map((floorRoof) => {
          if (floorRoof.levelTypeCode === 'ROOF' || floorRoof.isLowestLevel) {
            allEntries.push(floorRoof);
          } else {
            if (floorRoof.constructionType === 'Fire Resistive' && floorRoof.isVerticalOpeningProtection === true) {
              allEntries.push(floorRoof);
              return totalEntries = 'ALL';
            }
          }
        });
        if (allEntries?.length === floorRoofEntries.length) {
          totalEntries = 'ALL';
        }
      }
    }
    return totalEntries;
  }

  // to check occupancy level type name
  checkOccupancyLevels() {
    const occupants = this.formlyBuilderService.riskReport.model?.occupants[ 'section-occupants' ]?.occupants?.filter(src => !src?.isDeleted);
    const occupantLevel = [];
    if (occupants && occupants.length > 0) {
      occupants.forEach((item) => {
        if ('occupantLevels' in item && item.occupantLevels.length > 0 && item.isDeleted === false) {
          item.occupantLevels.map((val) => {
            occupantLevel.push(val);
          });
        }
      });
    }
    const occupancyEntry = occupantLevel.find((acc) => acc && 'name' in acc && acc.name !== 'None' &&
      (acc.name === 'Mezzanine' || acc.name === 'Floor' || acc.name === 'Basement' || acc.name === 'Attic'));
    return occupancyEntry ? 'MFB' : '';
  }

  // disable floor opening based on Floor roof and occupant entries
  disabledFloorOpening(field: FormlyFieldConfig) {
    let isDisabled = false;
    const levelEntries = this.checkFloorRoofEntries(field, true);
    const allLevelEntries = this.checkFloorRoofEntries(field, false);
    const occupancyEntries = this.checkOccupancyLevels();
    if (levelEntries === '' || occupancyEntries === '') {
      isDisabled = true;
    } else {
      if (levelEntries != '' && allLevelEntries === 'ALL') {
        isDisabled = true;
      }
    }
    return isDisabled;
  }

  // Disable openSidedLevels based on walls opensided
  disableStructuralDisAdg(field: FormlyFieldConfig) {
    const isOpenSided = field.options?.formState?.service?.riskReport?.model?.isOpenSided;
    if (isOpenSided === 'true' || isOpenSided === true) {
      return false;
    } else {
      return true;
    }
  }

  getSpocArea(formlyService) {
    let totalSpocArea = 0;
    const v1waterSupplyHydraulic = formlyService?.riskReport?.model?.sprinklerReport?.asgrWaterSupply
      ?.waterSupplyHydraulics?.v1WaterSupplyHydraulics
      ? formlyService?.riskReport?.model?.sprinklerReport?.asgrWaterSupply?.waterSupplyHydraulics
        .v1WaterSupplyHydraulics
      : null;
    const v1WaterSupplyPipeSchedules = formlyService?.riskReport?.model?.sprinklerReport?.asgrWaterSupply
      ?.waterSupplyPipeSchedules?.v1WaterSupplyPipeSchedules
      ? formlyService?.riskReport?.model?.sprinklerReport?.asgrWaterSupply?.waterSupplyPipeSchedules
        .v1WaterSupplyPipeSchedules
      : null;
    if (v1waterSupplyHydraulic !== null && v1waterSupplyHydraulic?.length) {
      v1waterSupplyHydraulic.forEach((watersupply) => {
        if (watersupply?.squareFeet) {
          if (watersupply?.squareFeet !== null) {
            totalSpocArea += Number(watersupply.squareFeet);
          }
        }
      });
    }
    if (v1WaterSupplyPipeSchedules !== null && v1WaterSupplyPipeSchedules?.length) {
      v1WaterSupplyPipeSchedules.forEach((watersupply) => {
        if (watersupply?.squareFeet) {
          if (watersupply?.squareFeet !== null) {
            totalSpocArea += Number(watersupply.squareFeet);
          }
        }
      });
    }
    return totalSpocArea;
  }

  calculateArea(field: FormlyFieldConfig) {
    let totalArea = 0;
    const model = field.model;
    if (model && model !== null && model?.length) {
      model.forEach((item) => {
        if (item?.excessStoryArea) {
          if (item?.excessStoryArea !== null) {
            totalArea += Number(item.excessStoryArea);
          }
        }
      });
    }
    return totalArea;
  }
}



