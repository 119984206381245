import { Injectable } from '@angular/core';
import { ErrorCode, ErrorMessage, ErrorMessages } from '../enums/http-errorcodes.enum';
import { AppService } from '../services/app-service/app.service';
import { UtilService } from '../services/util-service/util.service';
import { AppHttpResponse } from './cap-http.service';
import { find } from 'lodash';
import { Constants, ErrorMessagesByResponse } from '../services/util-service/constants';
import { Router } from '@angular/router';
import { ToastService } from '../services/toast-service/toast.service';
import { environment } from 'src/environments/environment';
import { LoadingService } from '../services/loading-service/loading.service';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptorService {
  public isUnAuthorizedAlertShowing: boolean;
  public isServerErrorShowing: boolean;

  private toasterErrorUrls = [Constants.getCurrencyRiskMetaData, Constants.getGeoCode];
  constructor(
    private _utils: UtilService,
    private loadingService: LoadingService,
    private appService: AppService,
    private router: Router,
    private toasterService: ToastService
  ) {}

  /**
   * Handles error
   * @param response
   * @param [callback]
   * @returns error
   */
  public async handleError(response: AppHttpResponse, callback?: Function): Promise<any> {
    if (response.status > 500) {
      response.status = 500;
    }
    await this.loadingService.dismissLoading();
    switch (response.status) {
      case ErrorCode.BadRequest: // 400
        if (
          response.data &&
          typeof response.data === 'object' &&
          Array.isArray(response.data) &&
          response.data.length > 0
        ) {
          const errorMessages: string[] = [];
          response.data.forEach((errorObj) => {
            const error = find(ErrorMessagesByResponse, { Code: errorObj.Code });
            if (error) {
              errorMessages.push(error.message);
            }
          });
          if (errorMessages.length < 1) {
            errorMessages.push(ErrorMessages[ErrorCode.BadRequest].subHeader);
          }
          await this._utils.showAlert('', errorMessages.join(', '), '', callback);
        } else {
          const badRequestMessage: ErrorMessage = ErrorMessages[ErrorCode.BadRequest];
          await this._utils.showAlert(
            badRequestMessage?.header,
            badRequestMessage.subHeader,
            badRequestMessage?.message,
            callback
          );
        }

        break;

      case ErrorCode.Unauthorized:
        if (!this.isUnAuthorizedAlertShowing) {
          this.isUnAuthorizedAlertShowing = true;
          const unAuthorizedMessage: ErrorMessage = ErrorMessages[ErrorCode.Unauthorized];
          await this._utils.showAlert(
            unAuthorizedMessage?.header,
            unAuthorizedMessage?.subHeader,
            unAuthorizedMessage?.message,
            async (cb) => {
              this.isUnAuthorizedAlertShowing = false;
              if (cb) {
                await this.router.navigate(['/']);
                this.appService.proceedSignOut();
              }
            },
            {
              cssClass: 'general-alert',
            }
          );
        }
        break;

      case ErrorCode.InternalServerError: //500
        if (!this.isServerErrorShowing) {
          this.isServerErrorShowing = true;
          const messageObj: ErrorMessage = ErrorMessages[response.status];
          if (this.showToasterError(response.url)) {
            const toast = await this.toasterService.showToast(messageObj.subHeader, false, {
              cssClass: 'error-toast',
            });
            toast.onWillDismiss().then(() => (this.isServerErrorShowing = false));
          } else {
            await this._utils.showAlert(messageObj?.header, messageObj.subHeader, messageObj?.message, callback);
            this.isServerErrorShowing = false;
          }
        }
        break;
      case ErrorCode.NotFound: //404
      case ErrorCode.UnProcessedEntity: // 422
      case ErrorCode.Unknown: // 0
        if (response.error) {
          await this._utils.showAlert(
            response.error?.header,
            response.error.subHeader,
            response.error?.message,
            callback
          );
        } else {
          const messageObj: ErrorMessage = ErrorMessages[response.status];
          await this._utils.showAlert(messageObj?.header, messageObj.subHeader, messageObj?.message, callback);
        }
        break;

      default:
        const unKnownMessage: ErrorMessage = ErrorMessages[ErrorCode.Unknown];
        await this._utils.showAlert(
          unKnownMessage?.header,
          unKnownMessage?.subHeader,
          unKnownMessage?.message,
          callback
        );
        break;
    }
  }

  private showToasterError(requestUrl): boolean {
    const findUrl = this.toasterErrorUrls.find((apiEndpoint) =>
      requestUrl.includes(environment.iSurveyAPIurl + apiEndpoint)
    );
    return findUrl ? true : false;
  }
}
