import { Inject, Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';

import { Survey } from '../../interfaces/survey';
import { ENV, Environment } from '../../interfaces/env.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StreetViewService {
  apiKey: string;
  searchSource: google.maps.StreetViewSource;
  streetPreference: google.maps.StreetViewPreference;
  exterior_streetview: string = "street-view";
  orderdetails_streetview: string = "order-details-street-view";
  bestMinRadius = 10; // in meters
  bestMaxRadius = 50;
  radiusIncrement = 20;
  nearestMaxRadius = 200;
  streetViewStaticUrlBase = "https://maps.googleapis.com/maps/api/streetview?";
  panorama: google.maps.StreetViewPanorama;
  googleStreetViewService: google.maps.StreetViewService;
  loader: Loader;
  container: string;
  private isStreetViewVisible = new BehaviorSubject(false);
  currentSV = this.isStreetViewVisible.asObservable();

  constructor(@Inject(ENV) private environment: Environment) {
    this.apiKey = this.environment.googleMapsApiKey;
    if (!this.loader) {
      this.loader = new Loader({
        apiKey: this.apiKey,
        version: 'weekly',
      });
    } 
  }

  // loads the google maps api
  LoadResources(): Promise<typeof google> {
    return this.loader.load();
  }

  updateStreetViewVisibility(state:boolean){
    this.isStreetViewVisible.next(state)
  }

  getStreetViewStaticUrl(loc:any,size:string){
    const coord = this.extractCoordinate(loc);

    return this.streetViewStaticUrlBase +
      'location=' + coord[ 0 ]?.toString() + ',' + coord[ 1 ]?.toString() +
      '&size=' + size +
      '&key=' + this.apiKey +
      '&radius=' + this.nearestMaxRadius + 
      '&source=outdoor';
  }

  // initializes the street view
  initStreetView(container: string): google.maps.StreetViewPanorama {

    this.panorama = new google.maps.StreetViewPanorama(document.getElementById(container), {
      position: { lat: 37.86926, lng: -122.254811 }, // random starting point
      pov: { heading: 0, pitch: 0 },
      zoom: 1,
      disableDefaultUI: true,
      clickToGo: true,
      motionTracking: false,
      visible:false
    });

    this.googleStreetViewService = new google.maps.StreetViewService();
    this.searchSource = google.maps.StreetViewSource.OUTDOOR;
    return this.panorama;
  }

  parseCoordinates(survey: Survey) {
    return typeof survey?.LocationPoint == 'object'
      ? survey?.LocationPoint
      : typeof survey?.LocationPoint == 'string'
      ? JSON.parse(survey.LocationPoint)
      : '';
  }

  extractCoordinate(location:any) {
    var coordinates = [];
    if (location.latitude){
      coordinates[0] = location.latitude;
      coordinates[1] = location.longitude;
    }
    else if (typeof location == 'string')  {
      location = JSON.parse(location)
      coordinates = location.coordinates;
    }
    else if (location.coordinates) {
      coordinates = location.coordinates;
    }
    else {
      console.error("Invalid Location Data");
    }
    return coordinates;
  }

  // updates the streetview based on the clicked survey/marker
  async getBestStreetView(locPoints, callback): Promise<void> {
    this.streetPreference = google.maps.StreetViewPreference.BEST;
    let streetView: google.maps.StreetViewResponse | undefined;
    for (let radius = this.bestMinRadius; radius <= this.bestMaxRadius; radius += this.radiusIncrement) {
      let coordinates = this.parseCoordinates(locPoints);
      if (coordinates?.coordinates) {
        streetView = await this.getStreetView(coordinates.coordinates[0], coordinates.coordinates[1], radius);
        if (streetView && streetView.data.copyright.includes('Google')) {
          this.updatePanorama(coordinates.coordinates[0], coordinates.coordinates[1], streetView);
          return;
        }
      }
    }
    // fall back method
    await this.getNearestStreetView(locPoints, callback);
  }

  // last measure search with nearest preference and a radius of 200
  async getNearestStreetView(locPoints, callback): Promise<void> {
    this.streetPreference = google.maps.StreetViewPreference.NEAREST;
    let coordinates = this.parseCoordinates(locPoints);
    if (coordinates?.coordinates) {
      const streetView = await this.getStreetView(
        coordinates.coordinates[0],
        coordinates.coordinates[1],
        this.nearestMaxRadius
      );
      if (streetView && streetView.data.copyright.includes('Google')) {
        this.updatePanorama(coordinates.coordinates[0], coordinates.coordinates[1], streetView);
        callback(1);
      } else {
        callback(0);
        console.error('ZERO_RESULTS: There are no panoramas found that match the search criteria.');
      }
    }
  }

  // makes an api call to retrieve the closest point to a maker on a street
  async getStreetView(lat: number, lng: number, radius: number): Promise<google.maps.StreetViewResponse | undefined> {
    try {
      return await this.googleStreetViewService.getPanorama({
        location: { lat, lng },
        radius,
        preference: this.streetPreference,
        source: this.searchSource,
      });
    } catch (e) {
      return undefined;
    }
  }

  // updates the location of the street, and makes the camera look
  // from the street to the marker (the building in question)
  updatePanorama(markerLat: number, markerLng: number, { data }: google.maps.StreetViewResponse): void {
    this.panorama.setPano(data.location.pano);
    const angle = this.getAngle(markerLat, markerLng, data.location.latLng.lat(), data.location.latLng.lng());
    this.panorama.setPov({
      heading: angle,
      pitch: 0,
    });
    this.panorama.setZoom(1);
    this.panorama.setVisible(true);
  }

  // Dot product fomula to find angle: θ = arccos( (u . v) / (|u||v|) )
  getAngle(markerLat: number, markerLng: number, streetLat: number, streetLng: number): number {
    // default direction: North (0 degrees) - < 0, 1 >
    const vectorToMarker = [markerLng - streetLng, markerLat - streetLat]; // < x, y >
    const dotProduct = vectorToMarker[1];
    const magnitude = Math.sqrt(vectorToMarker[0] * vectorToMarker[0] + vectorToMarker[1] * vectorToMarker[1]);
    const angleInRadians = Math.acos(dotProduct / magnitude);
    const angleInDegrees = angleInRadians * (180 / Math.PI);
    return vectorToMarker[0] > 0 ? angleInDegrees : -angleInDegrees;
  }
}
