/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { BuildingCodesDataModel } from 'src/app/models/formly-builder/formly/full-risk/building-codes-data.model';
import { SprinklerReportService } from '../full-risk/sprinkler/sprinkler-report.service';

@Injectable({
  providedIn: 'root'
})
export class BuildingCodesService extends SectionServiceAbstract {

  constructor(private sprinklerReportService: SprinklerReportService) {
    super();
  }

  async updateData(model: any, riskReportData: any) {
    const hasChanged: boolean = BuildingCodesDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      const buildingCodes = BuildingCodesDataModel.to(model[ 'buildingCodes' ]);
      return {
        ...riskReportData,
        ...{
          // In UI they are not editable so No need to update them

          // CombustibilityClass: buildingCodes?.CombustibilityClass,
          // Bg2Csp: buildingCodes?.Bg2Csp,
          // CommercialStatisticalPlanCode: buildingCodes?.CommercialStatisticalPlanCode,
          BasicGroup2Symbol: buildingCodes?.BasicGroup2Symbol
        }
      };
    }
    return undefined;
  }

  loadData(unused: any[], reportInfo: any, isS3: boolean, model: any): any {
    const buildingCodes = BuildingCodesDataModel.fromS3(reportInfo);
    this.sprinklerReportService.updateSprinklerCombustibilityValues(buildingCodes.combustibilityClass)
    return {
      ...model,
      ...{ buildingCodes }
    };
  }

  enableBuildingCodes(field: FormlyFieldConfig): boolean {
    const stateCode = field.form.root?.value?.address?.mainAddress?.state;
    if (stateCode && stateCode?.length > 0 && stateCode === 'NY') {
      return true;
    }
    return false;
  }
}
