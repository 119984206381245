/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyBuilderService } from '../formly-builder.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { distinctUntilChanged, pairwise, startWith } from 'rxjs/operators';
import { SectionEnum } from '../section.config';
import { FireSprinklerDataModel } from 'src/app/models/formly-builder/formly/full-risk/fire-sprinkler-data.model';
import { UtilService } from 'src/app/services/util-service/util.service';
import { Constants } from '../../util-service/constants';
import { ToastController } from '@ionic/angular';
import { FullRiskGeneralService } from '../full-risk/full-risk-general.service';
import { SprinklerReportService } from '../full-risk/sprinkler/sprinkler-report.service';
import { Subscription } from 'rxjs';
import { RiskReportService } from '../risk-report.service';
@Injectable({
  providedIn: 'root',
})
export class FireSprinklerService extends SectionServiceAbstract {

  private subscription: Subscription;

  constructor(private formlyBuilderService: FormlyBuilderService,
    private utilService: UtilService,
    private toastController: ToastController,
    private fullRiskGeneralService: FullRiskGeneralService,
    private sprinklerService: SprinklerReportService,
    private riskReportService: RiskReportService,
  ) {
    super();
  }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    const hasChanged: boolean = FireSprinklerDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      if (model.evidenceOfFireSprinkler === 'true' && (!riskReportData.SprinklerReport ||
        !(riskReportData.SprinklerReport && Object.keys(riskReportData.SprinklerReport).length))) {
        // Add defualt fields to Sprinkler report when user choses Yes for presence of fire sprinkler
        //Will move this code to new file soon
        const riskReportService = this.formlyBuilderService.riskReport;
        const SprinklerDefaultData =
          await this.sprinklerService.updateFullSprinklerReport(riskReportService.model, riskReportService.report);

        return { ...riskReportData, ...FireSprinklerDataModel.to(model), ... { SprinklerReport: SprinklerDefaultData } };
      }
      else if (model.evidenceOfFireSprinkler === 'false') {
        // when there is no sprinkler and wind, the sprinkler and wind should set to null instead of empty {}
        return { ...riskReportData, ...FireSprinklerDataModel.to(model), ... { SprinklerReport: null } };
      }
      else {
        const riskReportService = this.formlyBuilderService.riskReport;
        const SprinklerDefaultData = await this.sprinklerService.updateFullSprinklerReport(riskReportService.model, riskReportService.report);
        return { ...riskReportData, ...FireSprinklerDataModel.to(model), ...{ SprinklerReport: SprinklerDefaultData } };
      }
    } else {
      return undefined;
    }
  }

  // 1. We trust the EvidenceOfFireSprinkler stored value
  // 2. On existing risks, if risk has an ASGR attached, then = "yes", else "no"
  // 3. On new risks, default is null and response is required
  loadData(sectionData: any[], reportInfo: any, isS3: boolean, model: any): any {
    let fireSprinklerModel: FireSprinklerDataModel;
    const automaticsprinklerTypePresent = this.getAutomaticSprinklerType(reportInfo);
    if (isS3) {
      fireSprinklerModel = FireSprinklerDataModel.fromS3(reportInfo);
      if (fireSprinklerModel.evidenceOfFireSprinkler === null) {
        // For existing risks based on Asgr attached we need to assign values "yes", else "no"
        if ((reportInfo.SprinklerReport && Object.keys(reportInfo.SprinklerReport).length > 1) || automaticsprinklerTypePresent) {
          fireSprinklerModel.evidenceOfFireSprinkler = true;
          // Updating the Report
          this.formlyBuilderService.riskReport.report.EvidenceOfFireSprinkler = 'Y';
        } else if (this.formlyBuilderService.isExistingRisk()) {
          fireSprinklerModel.evidenceOfFireSprinkler = false;
          this.formlyBuilderService.riskReport.report.EvidenceOfFireSprinkler = 'N';
        } else if (reportInfo?.ClonedFrom && reportInfo?.ClonedFrom?.length > 0 && reportInfo?.IsExistingRiskCloned) {
          fireSprinklerModel.evidenceOfFireSprinkler = false;
          this.formlyBuilderService.riskReport.report.EvidenceOfFireSprinkler = 'N';
        } else {
          fireSprinklerModel.evidenceOfFireSprinkler = null;
        }
        setTimeout(() => {
          this.formlyBuilderService.riskReport.updateSectionData(SectionEnum.FR_FIRE_SPRINKLER, false, SectionEnum.FR_FIRE_SPRINKLER);
        }, 1000);
      }
      console.log('Presence Of Sprinkler Value ' + fireSprinklerModel.evidenceOfFireSprinkler);
    } else {
      fireSprinklerModel = FireSprinklerDataModel.from(reportInfo.data);
    }
    return { ...model, ...fireSprinklerModel };
  }

  getAutomaticSprinklerType(reportInfo) {
    const automaticSprinklerList = [];
    if (reportInfo?.Occupants && reportInfo?.Occupants?.length) {
      reportInfo?.Occupants.forEach((occupant) => {
        if (!occupant.isDeleted) {
          if (occupant?.OccupantLevels && occupant?.OccupantLevels?.length > 0) {
            occupant?.OccupantLevels.map((level) => {
              automaticSprinklerList.push(level?.AutomaticSprinklerType);
            });
          }
        }
      });
    }
    if (automaticSprinklerList?.length) {
      if (automaticSprinklerList.includes('HYDR') || automaticSprinklerList.includes('PIPE') ||
        automaticSprinklerList.includes('13-R') || automaticSprinklerList.includes('13NC') ||
        automaticSprinklerList.includes('SHUT')) {
        return true;
      } else {
        return false;
      }
    }
  }

  valueChangeSubscription(field: FormlyFieldConfig, sectionName: SectionEnum, sectionEnum: SectionEnum, debounceTimeValue: number) {
    const initialValue = field.formControl?.value;
    const isNew = initialValue ? false : true;
    let fromPopup = false;
    if (field.formControl?.value === 'true' || field.formControl?.value === true) {
      this.enableSprinklerReport(field);
    } else {
      this.disableSprinklerReport(field, true);
    }
    this.subscription = field.formControl.valueChanges.pipe(startWith(initialValue), pairwise(),
      distinctUntilChanged()).subscribe(([ prev, next ]: [ any, any ]) => {
        field.formControl.markAsTouched();
        console.log('prev : ' + prev, 'next : ' + next);
        if (next == 'true' && !fromPopup) {
          //TODO: Add model for new sprinkler Report
          this.formlyBuilderService.riskReport.report.IsSprinklerReportDeleted = false;
          this.enableSprinklerReport(field);
          this.showToasterMessage('Sprinkler Report has been added to this risk');
          this.updateFireSprinklerData(field, sectionName, sectionEnum);
        }
        else if (next == 'false' && !prev) {
          this.removeSprinklerData(field);
          this.updateOccupantData(field);
          this.updateSprinklerRating(field);
          this.updateFireSprinklerData(field, sectionName, sectionEnum);
        }
        else if (prev == 'true' && next == 'false') {
          this.closeActiveToaster();
          this.utilService.showConfirmationPopup(Constants.removeSprinkler, '', async (cb) => {
            if (cb === 1) {
              fromPopup = false;
              if (this.formlyBuilderService.isExistingRisk()) {
                this.formlyBuilderService.riskReport.report.IsSprinklerReportDeleted = true;
              }
              this.disableSprinklerReport(field);
              this.removeSprinklerData(field);
              this.updateOccupantData(field);
              this.updateSprinklerRating(field);
              this.updateFireSprinklerData(field, sectionName, sectionEnum);
            }
            else {
              fromPopup = true;
              field.formControl.setValue('true');
            }
          }, [ 'Confirm', 'Cancel' ]);
        }
      });
  }

  updateSprinklerRating(field) {
    // Removing SprinklerRatingAsgr value
    if (field.form.root.get('internalProtections')?.get('sprinklersData')) {
      field.form.root.get('internalProtections')?.get('sprinklersData')?.get('sprinklerRatingAsgr')?.setValue(0);
    }
    this.formlyBuilderService.riskReport.report.SprinklerRatingAsgr = null;
  }

  async showToasterMessage(message) {
    const toast = await this.toastController.create({
      message,
      position: 'bottom',
      cssClass: [ 'toaster full-width-toaster green-toaster' ],
      buttons: [
        {
          text: '\u2573',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          }
        }
      ],
    });

    await toast.present();
  }

  /**
   *
   *
   *
   *
   * @method: Will disable the sprinkler in FullRisk MainSection drop down
   * @param field
   */
  disableSprinklerReport(field: FormlyFieldConfig, onLoad?: boolean) {
    const formlyService = field.options.formState.service;
    this.fullRiskGeneralService.disableMainSection(field, SectionEnum.FR_SPRINKLERS);
    formlyService.formlyInternalProtectionService?.removeValuesSprinklersData(field, onLoad);

  }

  /**
   *
   *
   *
   *
   * @method: Will enable the sprinkler in FullRisk MainSection drop down
   * @param field
   */
  enableSprinklerReport(field: FormlyFieldConfig) {
    this.fullRiskGeneralService.enableMainSection(field, SectionEnum.FR_SPRINKLERS);
  }

  //Method to make sprinkler report model empty when presence of fire sprinkler is set to 'No' From 'Yes'.
  removeSprinklerData(field: FormlyFieldConfig) {
    console.log('TODO : Sprinkler Report data will be removed');
    // remove data from sprinkler report when it is disabled
    // when there is no sprinkler and wind, the sprinkler and wind should set to null instead of empty {}
    this.formlyBuilderService.riskReport.model = {
      ...this.formlyBuilderService.riskReport.model, ...{ sprinklerReport: null }
    };
  }

  //Method to call updateData for data sync with S3
  async updateFireSprinklerData(field, sectionName, sectionEnum) {
    if (!field.formControl.pristine) {

      //field.options.formState.service.riskReport.updateSectionData(sectionName, false, sectionEnum);
      const rr = await field.options.formState.service.riskReport.updateSectionData(sectionName, false, sectionEnum);
      //update Sprinkler Report Model
      const newModel = this.sprinklerService.loadData(rr, rr, true, field.options.formState.service.riskReport.model);
      if (newModel) {
        this.formlyBuilderService.riskReport.model = { ...newModel };
      }
       
    }
  }

  async updateOccupantData(field) {
    try {
      if ((field?.formControl?.value == false || field?.formControl?.value == 'false' || field?.formControl?.value == null)) {
        if (field?.model?.occupants["section-occupants"]?.occupants?.length > 0 ) {
          field?.model?.occupants["section-occupants"]?.occupants?.map(occupant => {
            if (occupant.isDeleted == false) {
              occupant?.occupantLevels?.forEach(level => {
                level.sprinklerDesign = 'None';
                level.automaticSprinkler = 'NONE';
                level.sprinklerType = '';
              });
            }
          });
        }
        if (this.riskReportService?.report?.Occupants?.length) {
          this.riskReportService.report.Occupants?.map(occupant => {
            if (occupant && !occupant.IsDeleted) {
              if(occupant.OccupantLevels?.length) {
                occupant.OccupantLevels?.forEach(level => {
                  level.AutomaticSprinklerType = "NONE";
                  level.SprinklerDesign = "None";
                  level.SprinklerType = null;
                });
              }              
            }
          });
        }
      }     
      this.riskReportService.model = { ...this.riskReportService.model };
    }
    catch {
      console.log("Error in occupants");
    }
  }


  /**
   *
   * @description will close the active toaster if its opened
   */
  closeActiveToaster() {
    this.toastController.getTop().then((value) => {
      if (value) {
        this.toastController.dismiss();
      }
    });
  }

  onDestoryHook() {
    this.subscription.unsubscribe();
  }

}
