/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ValidatorOption } from '@ngx-formly/core/lib/models';

/**
 * @method occupantHazardSnoValidator If Hazards of occupancies schedule number includesmore than one time with in the list of harzard schedule number
 *  need to apply validation
 * @param AbstractControl formControl
 * @param FormlyFieldConfig field
 * @param ValidatorOption options
 * @return *  ValidationErrors
 * */
export function occupantHazardSnoValidator(formControl: AbstractControl, field: FormlyFieldConfig, options: ValidatorOption): ValidationErrors | null {
  return occupantHazardSnoValidation(options, formControl);
}
export function occupantHazardSnoValidation(options, formControl) {
  const hazardScheduleNo = options['hazardScheduleNo'];
  const hazardList = formControl?.value;
  const errorMsg = options['errorMsg'];
  const propertyName = options['propertyName'];
  const errorPropertyName = options['errorPropertyName'];
  const excludedScheduleNo = options['excludedScheduleNoList'] ? options['excludedScheduleNoList'] : [];
  const filteredOriginalList = excludeObjectsFromArrayOfObjects(hazardList, hazardScheduleNo, propertyName, excludedScheduleNo);
  try {
    if (filteredOriginalList.length > 1) {
      hazardList.forEach((value, index) => {
        hazardScheduleNo.forEach(list => {
          if ((value?.scheduleNumber?.substring(0, list?.length) === list) && !excludedScheduleNo.includes(value?.scheduleNumber)) {
            formControl?.controls?.at(index)?.get('scheduleNumber').setErrors({ ...(formControl?.controls?.at(index)?.get('scheduleNumber').errors), [errorPropertyName]: errorMsg });
          }
        });

      });
    }
    else {
      if (filteredOriginalList.length <= 1) {
        hazardList.forEach((value, index) => {
          if (value.scheduleNumber) {
            if (formControl?.controls?.at(index)?.get('scheduleNumber')?.errors && formControl?.controls?.at(index)?.get('scheduleNumber')?.errors[errorPropertyName] &&
              Object.keys(formControl?.controls?.at(index)?.get('scheduleNumber').errors)?.length == 1) {
              delete formControl?.controls?.at(index)?.get('scheduleNumber')?.errors[errorPropertyName];
              formControl?.controls?.at(index)?.get('scheduleNumber').setErrors(null);
            }
            else if (formControl?.controls?.at(index)?.get('scheduleNumber')?.errors && formControl?.controls?.at(index)?.get('scheduleNumber')?.errors[errorPropertyName] && Object.keys(formControl?.controls?.at(index)?.get('scheduleNumber').errors)?.length > 1) {
              delete formControl?.controls?.at(index)?.get('scheduleNumber')?.errors[errorPropertyName];
              // formControl?.controls?.at(index)?.get('scheduleNumber').setErrors(null);
            }
          }
        });
      }
    }
    return null;
  }
  catch {
    console.error(errorMsg);
    return null;
  }
}
export function excludeObjectsFromArrayOfObjects(hazardList, hazardScheduleNo, propertyName, excludedScheduleNo = []) {
  return hazardList?.filter(val => hazardScheduleNo.some(list =>
    (val[propertyName]?.substring(0, list?.length) === list && !excludedScheduleNo.includes(val[propertyName]))
  ));
}
export function filteringOccupantScheduleNo(occupantSchNo, scheduleNoStartWith, excludedString) {
  const finalArray = [];
  scheduleNoStartWith.forEach(item => {
    if (occupantSchNo?.substring(0, item.length) === item) {
      if (excludedString && excludedString.length) {
        if (!excludedString?.includes(occupantSchNo)) {
          finalArray.push(occupantSchNo);
        }
      }
      else {
        finalArray.push(occupantSchNo);
      }
    }
  });
  return finalArray;
}
export function filteringHazardScheduleNo(scheduleNo, hazardScheduleNo) {
  return (scheduleNo?.filter(o1 => hazardScheduleNo?.some(o2 => o1?.scheduleNumber?.substring(0, o2?.length) == o2)));
}
export function  duplicateScheduleNumberValidator(formControl: AbstractControl, field: FormlyFieldConfig, options: ValidatorOption): ValidationErrors | null {
  return  duplicateScheduleNumberValidation(options, formControl);
}
export function duplicateScheduleNumberValidation(options, formControl){
  const hazardList = formControl?.value;
  const errorMsg = options['errorMsg'];
  const propertyName = options['propertyName'];
  const errorPropertyName = options['errorPropertyName'];
  try{
    hazardList?.forEach((item,index) => {
      if (hazardList?.filter(i => i?.scheduleNumber === item?.scheduleNumber)?.length > 1) {
        formControl?.controls?.at(index)?.get('scheduleNumber').setErrors({ ...(formControl?.controls?.at(index)?.get('scheduleNumber').errors), [errorPropertyName]: errorMsg });
      }
      else {
        if (formControl?.controls?.at(index)?.get('scheduleNumber')?.errors && formControl?.controls?.at(index)?.get('scheduleNumber')?.errors[errorPropertyName] &&
        Object.keys(formControl?.controls?.at(index)?.get('scheduleNumber').errors)?.length == 1) {
        delete formControl?.controls?.at(index)?.get('scheduleNumber')?.errors[errorPropertyName];
        formControl?.controls?.at(index)?.get('scheduleNumber').setErrors(null);
      }
      else if (formControl?.controls?.at(index)?.get('scheduleNumber')?.errors && formControl?.controls?.at(index)?.get('scheduleNumber')?.errors[errorPropertyName] && Object.keys(formControl?.controls?.at(index)?.get('scheduleNumber').errors)?.length > 1) {
        delete formControl?.controls?.at(index)?.get('scheduleNumber')?.errors[errorPropertyName];
        // formControl?.controls?.at(index)?.get('scheduleNumber').setErrors(null);
      }
      }
    });
  }
  catch {
    console.error(errorMsg);
  }
  return null;
}
