import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';

export class FireSprinklerDataModel {
  static sectionName = 'fireSprinkler';

  evidenceOfFireSprinkler: boolean;

  static from(reports: RiskReportDataReports): FireSprinklerDataModel {
    return {
      evidenceOfFireSprinkler: reports.EvidenceOfFireSprinkler,
    };
  }

  static fromS3(reports: any): FireSprinklerDataModel {
    let evidenceOfFireSprinkler = null;

    // 1. We trust the EvidenceOfFireSprinkler stored value
    // 2. On existing risks, if risk has an ASGR attached, then = "yes", else "no"
    // 3. On new risks, default is null and response is required
    // Here lets return the value which is coming fromS3
    // And do the calculations in loadData method (since we need to check for new risk and existing risk)
    if (reports.EvidenceOfFireSprinkler === 'Y') {
      evidenceOfFireSprinkler = 'true';
    } else if (reports.EvidenceOfFireSprinkler === 'N') {
      evidenceOfFireSprinkler = 'false';
    }

    // // Option 1. We trust the EvidenceOfFireSprinkler stored value
    // const preferStoredValue = true;
    // if (preferStoredValue) {
    //   if (reports.EvidenceOfFireSprinkler === 'Y') {
    //     evidenceOfFireSprinkler = 'true';
    //   } else if (reports.EvidenceOfFireSprinkler === 'N') {
    //     evidenceOfFireSprinkler = 'false';
    //   }
    // } else {
    //   // Option 2. We always force EvidenceOfFireSprinkler to be undefined for New Risks
    //   // riskUIStatus will be  0 for New Risk
    //   const isNewRisk = reports.RiskUIStatus ? false : true;
    //   if (!isNewRisk) {
    //     if (reports.SprinklerReport && Object.keys(reports.SprinklerReport).length != 0) {
    //       evidenceOfFireSprinkler = 'true';
    //     }
    //     else {
    //       evidenceOfFireSprinkler = 'false'
    //     }
    //   }
    // }

    return {
      evidenceOfFireSprinkler
    };
  }

  static to(model: any) {
    let evidenceOfFireSprinkler = null;
    let isSprinklerReportDeleted;
    if (model.evidenceOfFireSprinkler === 'true' || model.evidenceOfFireSprinkler === true) {
      evidenceOfFireSprinkler = 'Y';
    } else if (model.evidenceOfFireSprinkler === 'false' || model.evidenceOfFireSprinkler === false) {
      evidenceOfFireSprinkler = 'N';
    }
    return {
      EvidenceOfFireSprinkler: evidenceOfFireSprinkler
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    if (riskReportModel.EvidenceOfFireSprinkler === undefined) {
      return true;
    }

    if (formlyModel.evidenceOfFireSprinkler === riskReportModel.EvidenceOfFireSprinkler) {
      return false;
    } else {
      return true;
    }
  }


}
