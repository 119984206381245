/* eslint-disable @typescript-eslint/naming-convention */
import { Guid } from 'guid-typescript';

/**
 * Class that represents the Formly Model for Occupants
 */
export class EnhancedWindDataModel {
  static sectionName = 'enhancedWind';

  hasRoofExposureHazardByOtherRisks: RoofExposure;
  hasWallExposureHazardByOtherRisks: WallExposure;
  hasPotentialExposureCreditApplicable: PotentialExposure;
  hasRoofFullyObservable: FullyObservable;
  sharedWindCharacteristics: SharedWindCharacteristics;
  reportId: string;
  totalArea: number;
  geographicRiskFactor: string;
  //S3 sync
  static fromS3(reports: any, fullData, isExistingRisk): EnhancedWindDataModel {
    const wallExposureData = EnhancedWindDataModel.setwallExposure(reports?.EnhancedWindRatingEligibility);
    const roofExposureData = EnhancedWindDataModel.setroofExposure(reports?.EnhancedWindRatingEligibility);
    const potentialExposureData = EnhancedWindDataModel.setpotentialExposure(reports?.EnhancedWindRatingEligibility);
    const roofFullyObservable = EnhancedWindDataModel.setroofObservable(reports?.EnhancedWindRatingEligibility);
    const windCharacteristics = EnhancedWindDataModel.setwindCharacteristics(reports?.EnhancedWindRatingEligibility, isExistingRisk);
    const sharedWindCharacteristicsRisksData = [];
    reports?.SharedWindCharacteristicsRisks?.forEach((arr, index) => {
      sharedWindCharacteristicsRisksData.push({
        riskId: arr?.RiskId,
        wasManuallyEntered: arr?.WasManuallyEntered,
        address: arr?.Address,
        doesRiskShareCharacteristics: arr?.DoesRiskShareCharacteristics,
        city: arr?.City,
        stateCode: arr?.StateCode,
        zip: arr?.Zip,
        zip4: arr?.Zip4
      });
    });
    windCharacteristics.sharedWindCharacteristicsRisks = sharedWindCharacteristicsRisksData;
    return {
      reportId: reports?.ReportId,
      totalArea: reports?.TotalArea,
      geographicRiskFactor: reports?.GeographicRiskFactor,
      hasWallExposureHazardByOtherRisks: wallExposureData,
      hasRoofExposureHazardByOtherRisks: roofExposureData,
      hasPotentialExposureCreditApplicable: potentialExposureData,
      hasRoofFullyObservable: roofFullyObservable,
      sharedWindCharacteristics: windCharacteristics,
    };
  }

  /**
   *
   */
  static to(model: any, reportId?: Guid, riskModel?: any) {
    const sharedWindCharacteristicsRisksData = [];
    if (model?.sharedWindCharacteristics?.sharedWindCharacteristicsRisks?.length) {
      const riskAddress = riskModel?.address?.mainAddress;
      model?.sharedWindCharacteristics?.sharedWindCharacteristicsRisks?.forEach((arr, index) => {
        if (arr.address || arr.riskId) {
          sharedWindCharacteristicsRisksData.push({
            RiskId: arr?.riskId ? arr?.riskId : null,
            WasManuallyEntered: arr?.riskId ? true : false,
            Address: arr?.address ? arr.address : null,
            DoesRiskShareCharacteristics: arr?.doesRiskShareCharacteristics ? true : false,
            City: arr?.address ? (riskAddress?.city ? riskAddress?.city : null) : null,
            StateCode: arr?.address ? (riskAddress?.state ? riskAddress?.state : null) : null,
            Zip: arr?.address ? (riskAddress?.zipcode ? riskAddress?.zipcode : null) : null,
            Zip4: arr?.address ? (riskAddress?.zip4 ? riskAddress?.zip4 : null) : null,
          });
        }
      });
    }

    return {
      enhancedWindRatingEligibility: {
        ReportId: model?.reportId ? model?.reportId : reportId,
        TotalArea: model?.totalArea,
        GeographicRiskFactor: model?.geographicRiskFactor,
        IsRoofObservablePhysically:
          model?.hasRoofFullyObservable?.isRoofObservablePhysically ? model?.hasRoofFullyObservable?.isRoofObservablePhysically : false,
        IsRoofObservableGradeLevel:
          model?.hasRoofFullyObservable?.isRoofObservableGradeLevel ? model?.hasRoofFullyObservable?.isRoofObservableGradeLevel : false,
        IsRoofObservableFromLadder:
          model?.hasRoofFullyObservable?.isRoofObservableFromLadder ? model?.hasRoofFullyObservable?.isRoofObservableFromLadder : false,
        IsRoofObservableAdjacentVantagePoint: model?.hasRoofFullyObservable?.isRoofObservableAdjacentVantagePoint ?
          model?.hasRoofFullyObservable?.isRoofObservableAdjacentVantagePoint : false,
        IsRoofObservableViewingDevice:
          model?.hasRoofFullyObservable?.isRoofObservableViewingDevice ? model?.hasRoofFullyObservable?.isRoofObservableViewingDevice : false,
        IsRoofObservableOther:
          model?.hasRoofFullyObservable?.isRoofObservableOther ? model?.hasRoofFullyObservable?.isRoofObservableOther : false,
        RoofFullyObservableComment: model?.hasRoofFullyObservable?.roofFullyObservableComment,
        RoofFullyObservableTypeOtherDescription: model?.hasRoofFullyObservable?.roofFullyObservableTypeOtherDescription,
        IsRoofFullyObservable: (model?.hasRoofFullyObservable?.isRoofFullyObservable === null ||
          model?.hasRoofFullyObservable?.isRoofFullyObservable === undefined) ? null : model?.hasRoofFullyObservable?.isRoofFullyObservable,
        HasSharedWindCharacteristics: (model?.sharedWindCharacteristics?.hasSharedWindCharacteristics === null ||
          model?.sharedWindCharacteristics?.hasSharedWindCharacteristics === undefined) ? null :
          model?.sharedWindCharacteristics?.hasSharedWindCharacteristics,
        IsRoofExposureHazardByOtherRisks: (model?.hasRoofExposureHazardByOtherRisks?.isRoofExposureHazardByOtherRisks === null ||
          model?.hasRoofExposureHazardByOtherRisks?.isRoofExposureHazardByOtherRisks === undefined) ? null :
          model?.hasRoofExposureHazardByOtherRisks?.isRoofExposureHazardByOtherRisks,
        IsWallExposureHazardByOtherRisks: (model?.hasWallExposureHazardByOtherRisks?.isWallExposureHazardByOtherRisks === null ||
          model?.hasWallExposureHazardByOtherRisks?.isWallExposureHazardByOtherRisks === undefined) ? null :
          model?.hasWallExposureHazardByOtherRisks?.isWallExposureHazardByOtherRisks,
        IsPotentialExposureCreditApplicable: (model?.hasPotentialExposureCreditApplicable?.isPotentialExposureCreditApplicable === null ||
          model?.hasPotentialExposureCreditApplicable?.isPotentialExposureCreditApplicable === undefined) ? null :
          model?.hasPotentialExposureCreditApplicable?.isPotentialExposureCreditApplicable,
        IsWallExposureHazardCommonCanopy: model?.hasWallExposureHazardByOtherRisks?.isWallExposureHazardCommonCanopy ?
          model?.hasWallExposureHazardByOtherRisks?.isWallExposureHazardCommonCanopy : false,
        IsWallExposureHazardArchitecturalAppurtenances:
          model?.hasWallExposureHazardByOtherRisks?.isWallExposureHazardArchitecturalAppurtenances ?
            model?.hasWallExposureHazardByOtherRisks?.isWallExposureHazardArchitecturalAppurtenances : false,
        IsWallExposureHazardEIFSOnMasonry: model?.hasWallExposureHazardByOtherRisks?.isWallExposureHazardEIFSOnMasonry ?
          model?.hasWallExposureHazardByOtherRisks?.isWallExposureHazardEIFSOnMasonry : false,
        IsRoofExposureHazardLightlyConstructedRoofOrPenthouse:
          model?.hasRoofExposureHazardByOtherRisks?.isRoofExposureHazardLightlyConstructedRoofOrPenthouse ?
            model?.hasRoofExposureHazardByOtherRisks?.isRoofExposureHazardLightlyConstructedRoofOrPenthouse : false,
        IsRoofExposureHazardEquipmentUnsecured: model?.hasRoofExposureHazardByOtherRisks?.isRoofExposureHazardEquipmentUnsecured ?
          model?.hasRoofExposureHazardByOtherRisks?.isRoofExposureHazardEquipmentUnsecured : false,
        IsRoofExposureHazardParapetFlashingCondition: model?.hasRoofExposureHazardByOtherRisks?.isRoofExposureHazardParapetFlashingCondition ?
          model?.hasRoofExposureHazardByOtherRisks?.isRoofExposureHazardParapetFlashingCondition : false,
        IsRoofExposureHazardAntennasOrLights: model?.hasRoofExposureHazardByOtherRisks?.isRoofExposureHazardAntennasOrLights ?
          model?.hasRoofExposureHazardByOtherRisks?.isRoofExposureHazardAntennasOrLights : false,
        IsRoofExposureHazardRoofCoverDegradation: model?.hasRoofExposureHazardByOtherRisks?.isRoofExposureHazardRoofCoverDegradation ?
          model?.hasRoofExposureHazardByOtherRisks?.isRoofExposureHazardRoofCoverDegradation : false,
        IsPotentialExposureCreditCanopiesCircumventParapetedFireWalls:
          model?.hasPotentialExposureCreditApplicable?.isPotentialExposureCreditCanopiesCircumventParapetedFireWalls ?
            model?.hasPotentialExposureCreditApplicable?.isPotentialExposureCreditCanopiesCircumventParapetedFireWalls : false,
        IsPotentialExposureCreditOversizedPassageways:
          model?.hasPotentialExposureCreditApplicable?.isPotentialExposureCreditOversizedPassageways ?
            model?.hasPotentialExposureCreditApplicable?.isPotentialExposureCreditOversizedPassageways : false,
        IsPotentialExposureCreditLargeAtrium: model?.hasPotentialExposureCreditApplicable?.isPotentialExposureCreditLargeAtrium ?
          model?.hasPotentialExposureCreditApplicable?.isPotentialExposureCreditLargeAtrium : false,
        IsPotentialExposureCreditAbovegroundGarage: model?.hasPotentialExposureCreditApplicable?.isPotentialExposureCreditAbovegroundGarage ?
          model?.hasPotentialExposureCreditApplicable?.isPotentialExposureCreditAbovegroundGarage : false,
        IsPotentialExposureCreditUndergroundGarage: model?.hasPotentialExposureCreditApplicable?.isPotentialExposureCreditUndergroundGarage ?
          model?.hasPotentialExposureCreditApplicable?.isPotentialExposureCreditUndergroundGarage : false,
      },
      sharedWindCharacteristicsRisks: sharedWindCharacteristicsRisksData
    };
  }
  static setwallExposure(reports: any) {
    const environment = {
      isWallExposureHazardCommonCanopy: reports?.IsWallExposureHazardCommonCanopy,
      isWallExposureHazardArchitecturalAppurtenances: reports?.IsWallExposureHazardArchitecturalAppurtenances,
      isWallExposureHazardEIFSOnMasonry: reports?.IsWallExposureHazardEIFSOnMasonry,
      isWallExposureHazardByOtherRisks: reports?.IsWallExposureHazardByOtherRisks,
    };
    return environment;
  }
  static setroofExposure(reports: any) {
    const environment = {
      isRoofExposureHazardLightlyConstructedRoofOrPenthouse: reports?.IsRoofExposureHazardLightlyConstructedRoofOrPenthouse,
      isRoofExposureHazardEquipmentUnsecured: reports?.IsRoofExposureHazardEquipmentUnsecured,
      isRoofExposureHazardParapetFlashingCondition: reports?.IsRoofExposureHazardParapetFlashingCondition,
      isRoofExposureHazardAntennasOrLights: reports?.IsRoofExposureHazardAntennasOrLights,
      isRoofExposureHazardRoofCoverDegradation: reports?.IsRoofExposureHazardRoofCoverDegradation,
      isRoofExposureHazardByOtherRisks: reports?.IsRoofExposureHazardByOtherRisks,
    };
    return environment;
  }
  static setpotentialExposure(reports: any) {
    const environment = {
      isPotentialExposureCreditCanopiesCircumventParapetedFireWalls: reports?.IsPotentialExposureCreditCanopiesCircumventParapetedFireWalls,
      isPotentialExposureCreditOversizedPassageways: reports?.IsPotentialExposureCreditOversizedPassageways,
      isPotentialExposureCreditLargeAtrium: reports?.IsPotentialExposureCreditLargeAtrium,
      isPotentialExposureCreditAbovegroundGarage: reports?.IsPotentialExposureCreditAbovegroundGarage,
      isPotentialExposureCreditUndergroundGarage: reports?.IsPotentialExposureCreditUndergroundGarage,
      isPotentialExposureCreditApplicable: reports?.IsPotentialExposureCreditApplicable,
    };
    return environment;
  }
  static setroofObservable(reports: any) {
    const environment = {
      isRoofObservablePhysically: reports?.IsRoofObservablePhysically,
      isRoofObservableGradeLevel: reports?.IsRoofObservableGradeLevel,
      isRoofObservableFromLadder: reports?.IsRoofObservableFromLadder,
      isRoofObservableAdjacentVantagePoint: reports?.IsRoofObservableAdjacentVantagePoint,
      isRoofObservableViewingDevice: reports?.IsRoofObservableViewingDevice,
      isRoofObservableOther: reports?.IsRoofObservableOther,
      roofFullyObservableComment: reports?.RoofFullyObservableComment,
      isRoofFullyObservable: reports?.IsRoofFullyObservable,
      roofFullyObservableTypeOtherDescription: reports?.RoofFullyObservableTypeOtherDescription,
    };
    return environment;
  }

  static setwindCharacteristics(reports: any, isExistingRisk: boolean) {
    const environment = {
      hasSharedWindCharacteristics: reports?.HasSharedWindCharacteristics !== null && reports?.HasSharedWindCharacteristics !== undefined ?
        reports?.HasSharedWindCharacteristics : (isExistingRisk ? false : null),
      sharedWindCharacteristicsRisks: reports?.SharedWindCharacteristicsRisks,
    };
    return environment;
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   * @returns
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    return true;
  }
}

class RoofExposure {
  isRoofExposureHazardParapetFlashingCondition: boolean;
  isRoofExposureHazardEquipmentUnsecured: boolean;
  isRoofExposureHazardLightlyConstructedRoofOrPenthouse: boolean;
  isRoofExposureHazardRoofCoverDegradation: boolean;
  isRoofExposureHazardAntennasOrLights: boolean;
  isRoofExposureHazardByOtherRisks: boolean;
}
class PotentialExposure {
  isPotentialExposureCreditCanopiesCircumventParapetedFireWalls: boolean;
  isPotentialExposureCreditOversizedPassageways: boolean;
  isPotentialExposureCreditLargeAtrium: boolean;
  isPotentialExposureCreditAbovegroundGarage: boolean;
  isPotentialExposureCreditUndergroundGarage: boolean;
  isPotentialExposureCreditApplicable: boolean;
}

class WallExposure {
  isWallExposureHazardEIFSOnMasonry: boolean;
  isWallExposureHazardCommonCanopy: boolean;
  isWallExposureHazardArchitecturalAppurtenances: boolean;
  isWallExposureHazardByOtherRisks: boolean;
}
class FullyObservable {
  isRoofFullyObservable: boolean;
  isRoofObservablePhysically: boolean;
  isRoofObservableGradeLevel: boolean;
  isRoofObservableFromLadder: boolean;
  isRoofObservableViewingDevice: boolean;
  isRoofObservableAdjacentVantagePoint: boolean;
  isRoofObservableOther: boolean;
  roofFullyObservableComment?: string;
  roofFullyObservableTypeOtherDescription?: string;
}
class SharedWindCharacteristics {
  hasSharedWindCharacteristics: boolean;
  sharedWindCharacteristicsRisks: any;
}
