import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';
import { AdditionDateDataModel } from './addition-date-data.model';

/**
 * Class that represents the Formly Model for Occupants
 */
export class BuildingInformationDataModel {
  static sectionName = 'buildingName';

  buildingName: string;
  noOfStories?: any;                  // Renamed from RR!!!
  isYearBuiltUndetermined: any;
  isYearBuiltFrOverride: any;
  isYearBuiltApproximate: any;
  yearBuilt: any;
  // neededFireFlow:any;
  additionDates: AdditionDateDataModel[];

  static from(reports: any): any {
    return {};
  }

  static fromS3(reports: any): BuildingInformationDataModel {
    const buildingData = {
      buildingName: reports.BuildingDescription ? reports.BuildingDescription.trim() : '',
      noOfStories: reports.NumberOfFloors ? reports.NumberOfFloors : 1,       // Default to one on new risks

      // Do not use false as default!
      // isYearBuiltFrOverride: reports.IsYearBuiltFrOverride ? reports.IsYearBuiltFrOverride : false,
      // isYearBuiltApproximate: reports.IsYearBuiltApproximate ? reports.IsYearBuiltApproximate : false,
      // isYearBuiltUndetermined: reports.IsYearBuiltUndetermined ? reports.IsYearBuiltUndetermined : false,
      isYearBuiltFrOverride: reports.IsYearBuiltFrOverride,
      isYearBuiltApproximate: reports.IsYearBuiltApproximate,
      isYearBuiltUndetermined: reports.IsYearBuiltUndetermined,
      yearBuilt: reports.YearBuilt ? reports.YearBuilt : '',
      // neededFireFlow:reports.NeededFireFlow + `   GPM`,

      // AdditionDate1 
      additionDates: []
    };

    if (reports?.AdditionDates?.length) {
      reports.AdditionDates.forEach((rrAddition) => {
        buildingData.additionDates.push(AdditionDateDataModel.fromS3(rrAddition));
      });
    }
    
    return buildingData;
  }

  /**
   * Map to RR/S3 data representation
   */
  static to(frm: any) {
    const model = frm.buildingInformation;
    let mappedData = {
      BuildingDescription: model.buildingName,
      NumberOfFloors: model.noOfStories,
      IsYearBuiltFrOverride: model.isYearBuiltFrOverride,
      IsYearBuiltApproximate: model.isYearBuiltApproximate,
      IsYearBuiltUndetermined: model.isYearBuiltUndetermined,
      YearBuilt: (model.yearBuilt !== "") ? +model.yearBuilt : null,
      // NeededFireFlow:model.neededFireFlow,
      AdditionDates: []
    }

    // Add Additions
    model.additionDates.forEach((addition) => { 
      mappedData.AdditionDates.push(AdditionDateDataModel.to(addition));
    });

    return mappedData;
  }

  /**
   * Check if the model has changed
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   * @returns 
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    // if (!formlyModel.buildingName || !riskReportModel.BuildingDescription) {
    //   return false;
    // }

    // if (formlyModel.buildingName === riskReportModel.BuildingDescription) {
    //   return false;
    // } else {
    //   return true;
    // }
    return true;
  }
}
