/* eslint-disable @typescript-eslint/naming-convention */
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class RackStorageObstructionDataModel {
  static sectionName = 'RackStorageObstruction';
  reportId: string;
  levelsMissingSprinklers?: number;
  hasConsecutiveMissingLevels?: boolean;
  isDoubleRowStorage?: boolean;

  static fromS3(rackStorageData: any): RackStorageObstructionDataModel {
    return {
      reportId: rackStorageData?.ReportId,
      levelsMissingSprinklers: rackStorageData?.LevelsMissingSprinklers,
      hasConsecutiveMissingLevels: rackStorageData?.HasConsecutiveMissingLevels,
      isDoubleRowStorage: rackStorageData?.IsDoubleRowStorage,
    };
  }

  static to(model: any, sectionEnum?: SectionEnum) {
    return {
      ReportId: model?.rackStorageObstruction?.reportId,
      LevelsMissingSprinklers:
        model?.rackStorageObstruction?.levelsMissingSprinklers ? +model.rackStorageObstruction.levelsMissingSprinklers : null,
      HasConsecutiveMissingLevels:
        model?.rackStorageObstruction?.hasConsecutiveMissingLevels !== null && model?.rackStorageObstruction?.hasConsecutiveMissingLevels !== undefined ? model?.rackStorageObstruction?.hasConsecutiveMissingLevels : null,
      IsDoubleRowStorage: model?.rackStorageObstruction?.isDoubleRowStorage !== null && model?.rackStorageObstruction?.isDoubleRowStorage !== undefined ? model?.rackStorageObstruction?.isDoubleRowStorage : null,
      // Report: null
    };
  }

}


