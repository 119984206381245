import { Injectable } from '@angular/core';
import { Requestor } from '@openid/appauth';
import { Http, HttpResponse } from '@capacitor-community/http';
import { XhrSettings } from 'ionic-appauth/lib/cordova';
import { HttpErrorInterceptorService } from './http-error-interceptor.service';
import { environment } from 'src/environments/environment';
import { Constants, RequestConsoleLogs } from '../services/util-service/constants';
import { DebugConsoleLog } from '../interfaces/debug-console';
import { findLastIndex } from 'lodash';
import { LoggerService } from '../services/util-service/log-service';
import { LogTimeService } from '../services/util-service/log-time.service';
export interface AppHttpResponse extends HttpResponse {
  error?: any;
  callback?: number | Function;
}

@Injectable({
  providedIn: 'root',
})
export class CapacitorHttpService implements Requestor {
  constructor(private httpErrorInterceptorService: HttpErrorInterceptorService, private loggerService: LoggerService,private logTimeService: LogTimeService) {}
  public async xhr<T>(settings: XhrSettings | JQueryAjaxSettings): Promise<T> {
    if (!settings.method) {
      settings.method = 'GET';
    }
    // ADD REQUEST LOG
    this.addConsoleLog(true, settings);

    const response: AppHttpResponse = await Http.request({
      method: settings.method,
      url: settings.url,
      headers: settings.headers,
      data: settings.data,
    });

    // ADD RESPONSE LOG
    this.addConsoleLog(false, response);
    if (![200, 201].includes(response.status) && !this.canByPassError(response)) {
      if (!Constants.skipErrorAPIS.some((api) => settings.url.includes(api))) {
        response.error = settings['error'];
        if (!Constants.skipErrorCodes.some((errCode) => errCode === response.data[0].Code)) {
          await this.httpErrorInterceptorService.handleError(response, (cb) => {
            response.callback = cb;
          });
        }
      }
      return Promise.reject(response);
    }
    return response.data as T;
  }

  /**
   * Scenario: After ideal screen on resume automatically calling token api but it's failing due to disallowed refresh token by okta then calling giving 401 for actual request
   * In above scenario it's showing two alerts for 400 and 401. so need to bypass generic error message.
   * Determines whether by pass error can
   * @param response
   * @returns
   */
  canByPassError(response: AppHttpResponse) {
    // https://sso.int.verisk.com/oauth2/aus111i606aVW3Wp30h8/v1/token
    if (response.url === environment.auth_config.server_host + '/v1/token') {
      return true;
    }
    return false;
  }

  async addConsoleLog(isRequest, data): Promise<void> {
    if (this.loggerService.isLogEnabled()) {
      const logObj: DebugConsoleLog = new DebugConsoleLog();
      logObj.logTime = this.logTimeService.getTime();
      if (isRequest) {
        logObj.reqTime = logObj.logTime;
        logObj.reqLogLabel = `HTTP Call to ${data.url}`;
        RequestConsoleLogs.push(logObj);
      } else {
        if (![200, 201].includes(data.status)) {
          // response as error
          logObj.resLogLabel = `HTTP Response ${data.status} - Error`;
        } else {
          logObj.resTime = logObj.logTime;
          // response as success
          if (Array.isArray(data.data)) {
            logObj.resLogLabel = `HTTP Response ${data.status} - ${data.data.length} records returned`;
          } else {
            logObj.resLogLabel = `HTTP Response ${data.status}`;
          }
        }
        const index = findLastIndex(RequestConsoleLogs, (log: DebugConsoleLog) => {
          return !log.resLogLabel && log.reqLogLabel && log.reqLogLabel.includes(data.url) && log.isRequestLog;
        });
        if (index > -1) {
          RequestConsoleLogs[index] = Object.assign(logObj, RequestConsoleLogs[index]);
        }
      }
    }
  }
}
