/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { SectionEnum } from '../../section.config';
import { FloorsAndRoofDataModel } from 'src/app/models/formly-builder/formly/floors-roof-data.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConstructionByLevelService extends SectionServiceAbstract {

  public fireResistivePresetValues: any[];
  public nonCombustiblePresetValues: any[];
  public combustiblePresetValues: any[];
  public isFloorsAndRoofsSheetEdited = false;
  public flrRoofNgUnsubscribe = new Subject<void>();
  public isFloorsAndRoofLoaded = true;

  constructor() {
    super();
    this.fireResistivePresetValues = this.getFireResistiveDefaultValues();
    this.nonCombustiblePresetValues = this.getNonCombustibleDefaultValues();
    this.combustiblePresetValues = this.getCombustibleDefaultValues();
  }

  loadData(floorsAndRoof: any[], fullData: any, isS3: boolean, model: any): any {
    return undefined;
  }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    const hasChanged: boolean = FloorsAndRoofDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      const floorsAdRoof = FloorsAndRoofDataModel.to(model[ 'floorsAndRoofs' ], true);
      const updatedModel = {
        FloorsAndRoofs: floorsAdRoof.FloorsAndRoofs,
        NumberOfFloors: model[ 'floorsAndRoofs' ]?.buildingInformation?.aboveGradeFloors ?
          model[ 'floorsAndRoofs' ]?.buildingInformation?.aboveGradeFloors : 0,
        BasementLevels: (model && model?.floorsAndRoofs && model?.floorsAndRoofs?.buildingInformation &&
          model?.floorsAndRoofs?.buildingInformation?.basementLevels) ? model?.floorsAndRoofs?.buildingInformation?.basementLevels : 0
      };
      // this.generateOptionsForLevelOnGrade(); // added for calculating new options for levelOngrade levelType
      return { ...riskReportData, ...updatedModel };
    }
  }

  validateArea(field) {
    field.formControl.valueChanges.subscribe(val => {
      for (const control of field.parent.parent.formControl[ 'controls' ]) {
        control.get('area')?.updateValueAndValidity();
      }
    });
  }

  getDeckConstructionList(field: FormlyFieldConfig) {
    const constructionTypeControl = field.form.get('constructionType');
    const levelTypeValue = field.form.get('levelTypeCode')?.value;
    if (!field.form.value.isLowestLevel) {
      field.props.options = constructionTypeControl.valueChanges.pipe(startWith('NCSB'), takeUntil(this.flrRoofNgUnsubscribe),
        map(val => {
          if (val && val === 'NCSB' || constructionTypeControl?.value === 'NCSB' &&
            field.options.formState.service.isSheetActive) {
            if (val && levelTypeValue === 'ROOF') {
              return [
                { value: 'NOCB', label: 'Non-Combustible' },
                { value: 'SLBN', label: 'Slow Burn' },
                { value: 'COMA', label: 'Concrete/Masonry' },
                { value: 'BUSB', label: 'Built-up Slow Burn' },
                { value: 'BUNC', label: 'Built-up Non-Combustible' },
              ];
            } else {
              return [
                { value: 'NOCB', label: 'Non-Combustible' },
                { value: 'SLBN', label: 'Slow Burn' },
                { value: 'COMA', label: 'Concrete/Masonry' }
              ];
            }
          }
          return [];
        }));
    }
  }

  getSupportTypeList(field: FormlyFieldConfig) {
    const deckConstructionControl = field.form.get('deckConstruction');
    if (!field.form.value.isLowestLevel) {
      field.props.options = deckConstructionControl?.valueChanges?.pipe(startWith(''), takeUntil(this.flrRoofNgUnsubscribe),
        map(val => {
          const deckConstructionValue = val && val.length > 0 ? val : field.form.value?.deckConstruction;
          const sharedValues: any[] = [
            { value: 'UNPR', label: 'Unprotected' },
            { value: 'SLBN', label: 'Slow Burn' }
          ];
          if (val && val.length > 0) {
            field.formControl.setValue(null);
          }
          switch (deckConstructionValue) {
            case 'NOCB':
              sharedValues.push({ value: 'PROT', label: 'Protected' });
              return sharedValues;
            case 'SLBN':
              return [
                { value: 'NOCB', label: 'Non-Combustible' },
                { value: 'SLBN', label: 'Slow Burn' },
                { value: 'PROT', label: 'Protected' }
              ];
            case 'COMA':
            case 'BUSB':
            case 'BUNC':
              return sharedValues;
            default:
              return [];
          }
        }));
    }
  }

  /**
   * Method for setting the Construction Preset to 'Other' for existing Risk
   */
  setPresetForExistingRisk(field: FormlyFieldConfig) {
    if (field.options.formState.service.isExistingRisk() && !this.isFloorsAndRoofsSheetEdited &&
      field.options.formState.service.isSheetActive) {
      const constructionTypeCode = field.form.get('constructionTypeCode')?.value;
      if (constructionTypeCode === 'COMB') {
        field.formControl.setValue('Other Combustible Assemblies');
      } else if (constructionTypeCode === 'FIRE') {
        field.formControl.setValue('Other Fire Resistive Assemblies');
      } else if (constructionTypeCode === 'NCSB') {
        field.formControl.setValue('Other Non-Combustible or Slow Burn Assemblies');
      }
    }
  }

  /**
   * ConstructionPreset Listing method
   */
  constructionPresetInitalization(field: FormlyFieldConfig) {
    const constructionTypeCodeValue = field.form.value.constructionTypeCode;
    const constructionTypeControl = field.form.get('constructionTypeCode');
    if (!field.form.value.isLowestLevel) {
      field.props.options = constructionTypeControl?.valueChanges?.pipe(startWith(''), takeUntil(this.flrRoofNgUnsubscribe),
        map((val: any) => {
          const levelTypeValue = field.form.value.levelTypeCode;
          if (val && val.length > 0 && field.options.formState.service.isSheetActive) {
            field.form.get('constructionMaterial')?.setValue(null);
            return this.filterConstructionPreset(levelTypeValue, val);
          } else if (constructionTypeCodeValue && constructionTypeCodeValue.length > 0) {
            return this.filterConstructionPreset(levelTypeValue, constructionTypeCodeValue);
          }
        })
      );
    }
  }

  onConstructionPresetChange(field) {
    field.formControl.valueChanges.pipe(startWith(''), takeUntil(this.flrRoofNgUnsubscribe)).subscribe(src => {
      if (src && src?.length > 0 && field.options.formState.service.isSheetActive &&
        !src?.includes('Other')) {
        const constructionTypeCode = field.form.get('constructionTypeCode')?.value;
        if (constructionTypeCode === 'FIRE') {
          const presetDetails = this.fireResistivePresetValues?.find(val => val.constructionPreset === src);
          field.form.get('damageability')?.setValue(presetDetails?.damageability);
          field.form.get('hourlyRating')?.setValue(presetDetails?.hourlyRating);
          field.form.get('bgiitype')?.setValue(presetDetails?.bgiitype);
          setTimeout(() => {
            field.form.get('fireResistanceListing')?.setValue(presetDetails?.fireResistanceListing);
          }, 0);
          field.form.get('isVerticalOpeningProtection')?.setValue(null);

        } else if (constructionTypeCode === 'NCSB') {
          const presetDetails = this.nonCombustiblePresetValues?.find(val => val.constructionPreset === src);
          field.form.get('deckConstruction')?.setValue(presetDetails?.deckConstruction);
          setTimeout(() => {
            field.form.get('supportType')?.setValue(presetDetails?.supportType);
          }, 0);
          field.form.get('isFinishSmokeDevelopmentAbove200')?.setValue(false);
          field.form.get('masonryThickness')?.setValue(null);
          field.form.get('isCombustibleInsulation')?.setValue(false);
        } else if (constructionTypeCode === 'COMB') {
          const presetDetails = this.combustiblePresetValues?.find(val => val.constructionPreset === src);
          field.form.get('combustibleType')?.setValue(presetDetails?.combustibleType);
          field.form.get('isWoodIronClad')?.setValue(presetDetails?.isWoodIronClad);
          field.form.get('isHeavyTimberColumns')?.setValue(presetDetails?.isHeavyTimberColumns);
          field.form.get('isExtraHeavyTimberColumns')?.setValue(false);
          if (presetDetails?.isExtraHeavyTimberColumns !== 'N/A') {
            field.form.get('isExtraHeavyTimberColumns')?.setValue(presetDetails?.isExtraHeavyTimberColumns);
          }
          field.form.get('hasFoamPlasticInsulation')?.setValue(false);
          if (presetDetails?.hasFoamPlasticInsulation !== 'N/A') {
            field.form.get('hasFoamPlasticInsulation')?.setValue(presetDetails?.hasFoamPlasticInsulation);
          }
          field.form.get('hasFireRetardantPaint')?.setValue(false);
        }
      } else if (field.options.formState.service.isSheetActive && src?.includes('Other')) {
        field.form.get('damageability')?.setValue(null);
        field.form.get('hourlyRating')?.setValue(null);
        field.form.get('bgiitype')?.setValue(null);
        field.form.get('fireResistanceListing')?.setValue(null);
        field.form.get('isVerticalOpeningProtection')?.setValue(null);
        field.form.get('deckConstruction')?.setValue(null);
        field.form.get('supportType')?.setValue(null);
        field.form.get('isFinishSmokeDevelopmentAbove200')?.setValue(false);
        field.form.get('masonryThickness')?.setValue(null);
        field.form.get('isCombustibleInsulation')?.setValue(false);
        field.form.get('combustibleType')?.setValue(null);
        field.form.get('isWoodIronClad')?.setValue(false);
        field.form.get('isHeavyTimberColumns')?.setValue(false);
        field.form.get('isExtraHeavyTimberColumns')?.setValue(false);
        field.form.get('isExtraHeavyTimberColumns')?.setValue(false);
        field.form.get('hasFoamPlasticInsulation')?.setValue(false);
        field.form.get('hasFoamPlasticInsulation')?.setValue(false);
        field.form.get('hasFireRetardantPaint')?.setValue(false);
      }
    });
  }
  /**
   * @returns Filters ConstructionPreset drop down list
   */
  private filterConstructionPreset(levelTypeValue: string, constructionTypeCodeValue: string) {
    return this.getFlrAndRfConstructionPreset()
      .filter(src => src.constructionType?.toLowerCase() === constructionTypeCodeValue?.toLowerCase() &&
        (src.levelType === (levelTypeValue === 'FLOO' ||
          levelTypeValue === 'BASE' || levelTypeValue === 'MEZZ' ||
          levelTypeValue === 'ATTI' ? 'Floor' : 'Roof') || src.levelType === 'Both'))
      .map(x => ({ value: x.constructionValue, label: x.constructionAssembly }));
  }
  /**
   * @returns Construction Preset Drop down list
   */
  private getFlrAndRfConstructionPreset(): any[] {
    return [
      { constructionType: 'FIRE', levelType: 'Both', constructionAssembly: 'Reinforced Concrete', constructionValue: 'Reinforced Concrete' },
      { constructionType: 'FIRE', levelType: 'Both', constructionAssembly: 'Reinforced Concrete, def. in thickness', constructionValue: 'Reinforced Concrete, def. in thickness' },
      { constructionType: 'FIRE', levelType: 'Both', constructionAssembly: 'Precast Concrete - Listed', constructionValue: 'Precast Concrete - Listed' },
      { constructionType: 'FIRE', levelType: 'Both', constructionAssembly: 'Precast Concrete - Unlisted', constructionValue: 'Precast Concrete - Unlisted' },
      { constructionType: 'FIRE', levelType: 'Both', constructionAssembly: 'Steel prot. by Plaster on Metal Lath', constructionValue: 'Steel prot. by Plaster on Metal Lath' },
      { constructionType: 'FIRE', levelType: 'Both', constructionAssembly: 'Steel prot. by Gypsum Board/Sheetrock', constructionValue: 'Steel prot. by Gypsum Board/Sheetrock' },
      { constructionType: 'FIRE', levelType: 'Both', constructionAssembly: 'Steel prot. by Intumescent Mastic Coating', constructionValue: 'Steel prot. by Intumescent Mastic Coating' },
      { constructionType: 'FIRE', levelType: 'Both', constructionAssembly: 'Steel prot. By Spray Fibers/plaster', constructionValue: 'Steel prot. By Spray Fibers/plaster' },
      { constructionType: 'FIRE', levelType: 'Both', constructionAssembly: 'Steel prot. by Cementitious Mixtures', constructionValue: 'Steel prot. by Cementitious Mixtures' },
      { constructionType: 'FIRE', levelType: 'Roof', constructionAssembly: 'NC Roof with Fire Resistive Ceiling', constructionValue: 'NC Roof with Fire Resistive Ceiling' },
      { constructionType: 'FIRE', levelType: 'Both', constructionAssembly: 'Light Supports protected w/ D-2 or 3', constructionValue: 'Light Supports protected w/ D-2 or 3' },
      { constructionType: 'FIRE', levelType: 'Both', constructionAssembly: 'Other', constructionValue: 'Other Fire Resistive Assemblies' },
      { constructionType: 'NCSB', levelType: 'Roof', constructionAssembly: 'Metal Panels on Steel Supports', constructionValue: 'Metal Panels on Steel Supports' },
      { constructionType: 'NCSB', levelType: 'Roof', constructionAssembly: 'Metal Panels on Steel Sup. w/insulation', constructionValue: 'Metal Panels on Steel Sup. w/insulation' },
      { constructionType: 'NCSB', levelType: 'Roof', constructionAssembly: 'Built-up w/o gravel on Metal Deck on Steel Supports', constructionValue: 'Built-up w/o gravel on Metal Deck on Steel Supports' },
      { constructionType: 'NCSB', levelType: 'Roof', constructionAssembly: 'Built-up w/o gravel on Slow Burn Deck on Steel Supports', constructionValue: 'Built-up w/o gravel on Slow Burn Deck on Steel Supports' },
      { constructionType: 'NCSB', levelType: 'Roof', constructionAssembly: 'Built-up w/gravel on Metal Deck on Steel Supports', constructionValue: 'Built-up w/gravel on Metal Deck on Steel Supports' },
      { constructionType: 'NCSB', levelType: 'Roof', constructionAssembly: 'Built-up w/gravel on Slow Burn Deck on Steel Supports', constructionValue: 'Built-up w/gravel on Slow Burn Deck on Steel Supports' },
      { constructionType: 'NCSB', levelType: 'Roof', constructionAssembly: 'Membrane on Metal Deck on Steel Supports', constructionValue: 'Membrane on Metal Deck on Steel Supports' },
      { constructionType: 'NCSB', levelType: 'Roof', constructionAssembly: 'Membrane on Slow Burn Deck on Steel Supports', constructionValue: 'Membrane on Slow Burn Deck on Steel Supports' },
      { constructionType: 'NCSB', levelType: 'Roof', constructionAssembly: `NC Assemblies with MCM's`, constructionValue: `NC Assemblies with MCM's` },
      { constructionType: 'NCSB', levelType: 'Roof', constructionAssembly: `Slow Burn Assemblies with MCM's`, constructionValue: `Slow Burn Assemblies with MCM's` },
      { constructionType: 'NCSB', levelType: 'Both', constructionAssembly: 'Concrete on Steel Supports', constructionValue: 'Concrete on Steel Supports' },
      { constructionType: 'NCSB', levelType: 'Floor', constructionAssembly: 'NC Decking on Steel Supports', constructionValue: 'NC Decking on Steel Supports' },
      { constructionType: 'NCSB', levelType: 'Floor', constructionAssembly: 'Slow Burn Decking on Steel Supports', constructionValue: 'Slow Burn Decking on Steel Supports' },
      { constructionType: 'NCSB', levelType: 'Both', constructionAssembly: 'NC Decking on Protected Supports', constructionValue: 'NC Decking on Protected Supports' },
      { constructionType: 'NCSB', levelType: 'Both', constructionAssembly: 'Slow Burn Decking on Protected Supports', constructionValue: 'Slow Burn Decking on Protected Supports' },
      { constructionType: 'NCSB', levelType: 'Both', constructionAssembly: 'Other', constructionValue: 'Other Non-Combustible or Slow Burn Assemblies' },
      { constructionType: 'COMB', levelType: 'Roof', constructionAssembly: 'Metal Panels on Wood Supports', constructionValue: 'Metal Panels on Wood Supports' },
      { constructionType: 'COMB', levelType: 'Both', constructionAssembly: 'Heavy Timber Construction', constructionValue: 'Heavy Timber Construction' },
      { constructionType: 'COMB', levelType: 'Roof', constructionAssembly: 'Built Up Roof on Wood Supports', constructionValue: 'Built Up Roof on Wood Supports' },
      { constructionType: 'COMB', levelType: 'Roof', constructionAssembly: 'Shingles on Wood Decking on Wood Supports', constructionValue: 'Shingles on Wood Decking on Wood Supports' },
      { constructionType: 'COMB', levelType: 'Roof', constructionAssembly: 'Clay Tile on Wood Decking on Wood Supports', constructionValue: 'Clay Tile on Wood Decking on Wood Supports' },
      { constructionType: 'COMB', levelType: 'Roof', constructionAssembly: 'Slate on Wood Decking on Wood Supports', constructionValue: 'Slate on Wood Decking on Wood Supports' },
      { constructionType: 'COMB', levelType: 'Roof', constructionAssembly: 'Standing Seam Metal on Wood Supports', constructionValue: 'Standing Seam Metal on Wood Supports' },
      { constructionType: 'COMB', levelType: 'Roof', constructionAssembly: 'Wood Shingle/Shake on Wood Supports', constructionValue: 'Wood Shingle/Shake on Wood Supports' },
      { constructionType: 'COMB', levelType: 'Roof', constructionAssembly: 'Foam Sandwich Panels', constructionValue: 'Foam Sandwich Panels' },
      { constructionType: 'COMB', levelType: 'Roof', constructionAssembly: 'Structural Insulated Panels (SIPS)', constructionValue: 'Structural Insulated Panels (SIPS)' },
      { constructionType: 'COMB', levelType: 'Roof', constructionAssembly: 'Fabric over structural frame', constructionValue: 'Fabric over structural frame' },
      { constructionType: 'COMB', levelType: 'Roof', constructionAssembly: 'Metal Panels on Steel Support w/interior comb', constructionValue: 'Metal Panels on Steel Support w/interior comb' },
      { constructionType: 'COMB', levelType: 'Roof', constructionAssembly: 'Metal Panels on Steel Support w/foam insul.', constructionValue: 'Metal Panels on Steel Support w/foam insul.' },
      { constructionType: 'COMB', levelType: 'Roof', constructionAssembly: 'MCM on Steel Supports', constructionValue: 'MCM on Steel Supports' },
      { constructionType: 'COMB', levelType: 'Roof', constructionAssembly: `Comb Assemblies w/MCM's`, constructionValue: `Comb Assemblies w/MCM's` },
      { constructionType: 'COMB', levelType: 'Both', constructionAssembly: 'Comb Assemblies w/foam insul.', constructionValue: 'Comb Assemblies w/foam insul.' },
      { constructionType: 'COMB', levelType: 'Both', constructionAssembly: '1 Hour Combustible Assembly', constructionValue: '1 Hour Combustible Assembly' },
      { constructionType: 'COMB', levelType: 'Both', constructionAssembly: 'Mass Timber', constructionValue: 'Mass Timber' }, ,
      { constructionType: 'COMB', levelType: 'Floor', constructionAssembly: 'Comb decking on Wood Supports', constructionValue: 'Comb decking on Wood Supports' },
      { constructionType: 'COMB', levelType: 'Floor', constructionAssembly: 'NC decking on Wood Supports', constructionValue: 'NC decking on Wood Supports' },
      { constructionType: 'COMB', levelType: 'Floor', constructionAssembly: 'Comb Decking on Metal Supports', constructionValue: 'Comb Decking on Metal Supports' },
      { constructionType: 'COMB', levelType: 'Both', constructionAssembly: 'Other', constructionValue: 'Other Combustible Assemblies' }
    ];
  }
  /**
   * @returns Fire Resistive Preset values
   */
  private getFireResistiveDefaultValues(): any[] {
    return [
      { constructionPreset: 'Reinforced Concrete', damageability: 1, hourlyRating: 3, fireResistanceListing: null, bgiitype: 'MASO' },
      { constructionPreset: 'Reinforced Concrete, def. in thickness', damageability: 2, hourlyRating: 1, fireResistanceListing: 'MASO', bgiitype: 'MASO' },
      { constructionPreset: 'Precast Concrete - Listed', damageability: 2, hourlyRating: null, fireResistanceListing: 'MASO', bgiitype: 'MASO' },
      { constructionPreset: 'Precast Concrete - Unlisted', damageability: 3, hourlyRating: null, fireResistanceListing: 'MASO', bgiitype: 'MASO' },
      { constructionPreset: 'Steel prot. by Plaster on Metal Lath', damageability: 2, hourlyRating: null, fireResistanceListing: null, bgiitype: 'STEE' },
      { constructionPreset: 'Steel prot. by Gypsum Board/Sheetrock', damageability: 3, hourlyRating: null, fireResistanceListing: null, bgiitype: 'STEE' },
      { constructionPreset: 'Steel prot. by Intumescent Mastic Coating', damageability: 3, hourlyRating: null, fireResistanceListing: null, bgiitype: 'STEE' },
      { constructionPreset: 'Steel prot. By Spray Fibers/plaster', damageability: 3, hourlyRating: null, fireResistanceListing: null, bgiitype: 'STEE' },
      { constructionPreset: 'Steel prot. by Cementitious Mixtures', damageability: 3, hourlyRating: null, fireResistanceListing: null, bgiitype: 'STEE' },
      { constructionPreset: 'NC Roof with Fire Resistive Ceiling', damageability: 4, hourlyRating: null, fireResistanceListing: null, bgiitype: 'STEE' },
      { constructionPreset: 'Light Supports protected w/ D-2 or 3', damageability: 4, hourlyRating: null, fireResistanceListing: null, bgiitype: 'STEE' },
      { constructionPreset: 'Other Fire Resistive Assemblies', damageability: null, hourlyRating: null, fireResistanceListing: null, bgiitype: null }
    ];
  }
  /**
   *
   * @returns Non-Combustible Preset values
   */
  private getNonCombustibleDefaultValues(): any[] {
    return [
      { constructionPreset: 'Metal Panels on Steel Supports', deckConstruction: 'NOCB', supportType: 'UNPR', masonryThickness: 'N/A' },
      { constructionPreset: 'Metal Panels on Steel Sup. w/insulation', deckConstruction: 'SLBN', supportType: 'NOCB', masonryThickness: 'N/A' },
      { constructionPreset: 'Built-up w/o gravel on Metal Deck on Steel Supports', deckConstruction: 'BUNC', supportType: 'UNPR', masonryThickness: 'N/A' },
      { constructionPreset: 'Built-up w/o gravel on Slow Burn Deck on Steel Supports', deckConstruction: 'BUSB', supportType: 'UNPR', masonryThickness: 'N/A' },
      { constructionPreset: 'Built-up w/gravel on Metal Deck on Steel Supports', deckConstruction: 'BUNC', supportType: 'UNPR', masonryThickness: 'N/A' },
      { constructionPreset: 'Built-up w/gravel on Slow Burn Deck on Steel Supports', deckConstruction: 'BUSB', supportType: 'UNPR', masonryThickness: 'N/A' },
      { constructionPreset: 'Membrane on Metal Deck on Steel Supports', deckConstruction: 'BUNC', supportType: 'UNPR', masonryThickness: 'N/A' },
      { constructionPreset: 'Membrane on Slow Burn Deck on Steel Supports', deckConstruction: 'BUSB', supportType: 'UNPR', masonryThickness: 'N/A' },
      { constructionPreset: `NC Assemblies with MCM's`, deckConstruction: 'NOCB', supportType: 'UNPR', masonryThickness: 'N/A' },
      { constructionPreset: `Slow Burn Assemblies with MCM's`, deckConstruction: 'SLBN', supportType: 'NOCB', masonryThickness: 'N/A' },
      { constructionPreset: 'Concrete on Steel Supports', deckConstruction: 'COMA', supportType: 'UNPR', masonryThickness: null },
      { constructionPreset: 'NC Decking on Steel Supports', deckConstruction: 'NOCB', supportType: 'UNPR', masonryThickness: 'N/A' },
      { constructionPreset: 'Slow Burn Decking on Steel Supports', deckConstruction: 'SLBN', supportType: 'NOCB', masonryThickness: 'N/A' },
      { constructionPreset: 'NC Decking on Protected Supports', deckConstruction: 'NOCB', supportType: 'PROT', masonryThickness: 'N/A' },
      { constructionPreset: 'Slow Burn Decking on Protected Supports', deckConstruction: 'SLBN', supportType: 'PROT', masonryThickness: 'N/A' },
      { constructionPreset: 'Other Non-Combustible or Slow Burn Assemblies', deckConstruction: null, supportType: null, masonryThickness: null }
    ];
  }

  private getCombustibleDefaultValues(): any[] {
    return [
      { constructionPreset: 'Metal Panels on Wood Supports', combustibleType: 'Wood Iron Clad', isWoodIronClad: true, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: null, hasFoamPlasticInsulation: 'N/A' },
      { constructionPreset: 'Heavy Timber Construction', combustibleType: 'Heavy Timber', isWoodIronClad: false, isHeavyTimberColumns: true, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: 'N/A' },
      { constructionPreset: 'Built Up Roof on Wood Supports', combustibleType: 'COMB', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: null, hasFoamPlasticInsulation: 'N/A' },
      { constructionPreset: 'Shingles on Wood Decking on Wood Supports', combustibleType: 'COMB', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: 'N/A' },
      { constructionPreset: 'Clay Tile on Wood Decking on Wood Supports', combustibleType: 'COMB', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: 'N/A' },
      { constructionPreset: 'Slate on Wood Decking on Wood Supports', combustibleType: 'COMB', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: 'N/A' },
      { constructionPreset: 'Standing Seam Metal on Wood Supports', combustibleType: 'COMB', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: 'N/A' },
      { constructionPreset: 'Wood Shingle/Shake on Wood Supports', combustibleType: 'COMB', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: 'N/A' },
      { constructionPreset: 'Foam Sandwich Panels', combustibleType: 'COMB', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: true },
      { constructionPreset: 'Structural Insulated Panels (SIPS)', combustibleType: 'COMB', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: true },
      { constructionPreset: 'Fabric over structural frame', combustibleType: 'COMB', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: 'N/A' },
      { constructionPreset: 'Metal Panels on Steel Support w/interior comb', combustibleType: 'COMB', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: 'N/A' },
      { constructionPreset: 'Metal Panels on Steel Support w/foam insul.', combustibleType: 'COMB', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: true },
      { constructionPreset: 'MCM on Steel Supports', combustibleType: 'COMB', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: null },
      { constructionPreset: `Comb Assemblies w/MCM's`, combustibleType: 'COMB', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: null },
      { constructionPreset: 'Comb Assemblies w/foam insul.', combustibleType: 'COMB', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: true },
      { constructionPreset: '1 Hour Combustible Assembly', combustibleType: 'HOUR', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: 'N/A' },
      { constructionPreset: 'Mass Timber', combustibleType: 'HOUR', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: 'N/A' },
      { constructionPreset: 'Comb decking on Wood Supports', combustibleType: 'COMB', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: null },
      { constructionPreset: 'NC decking on Wood Supports', combustibleType: 'COMB', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: null, hasFoamPlasticInsulation: null },
      { constructionPreset: 'Comb Decking on Metal Supports', combustibleType: 'COMB', isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: 'N/A', hasFoamPlasticInsulation: null },
      { constructionPreset: 'Other Combustible Assemblies', combustibleType: null, isWoodIronClad: false, isHeavyTimberColumns: false, isExtraHeavyTimberColumns: null, hasFoamPlasticInsulation: false }
    ];
  }

}
