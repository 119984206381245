import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import {
  CurrencyDisabledList, GeneralInformationDisableList, SoftDisableFieldList
} from 'src/app/enums/formly-builder/risk-form-currency-mode.enum';
import { RiskModeEnum } from 'src/app/enums/formly-builder/risk-mode.enum';
import { ConstructionVerificationDataModel } from 'src/app/models/formly-builder/formly/construction-verification-data.model';

@Injectable({
  providedIn: 'root',
})
export class SectionConstVerifService extends SectionServiceAbstract {
  constructor() {
    super();
  }

  async updateData(model: any, riskReportData: any) {
    // Check if data has changed
    const hasChanged: boolean = ConstructionVerificationDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      return { ...riskReportData, ...ConstructionVerificationDataModel.to(model) };
    } else {
      return undefined;
    }
  }

  loadData(floorsAndRoof: any[], reportInfo: any, isS3: boolean, model: any): any {
    let verificationDataModel: ConstructionVerificationDataModel;
    if (isS3) {
      verificationDataModel = ConstructionVerificationDataModel.fromS3(reportInfo);
    } else {
      verificationDataModel = ConstructionVerificationDataModel.from(reportInfo.data);
    }
    return { ...model, ...verificationDataModel };
  }

  /**
   *
   * @method disableEnableFields To enable and disable fields based on construction change value
   * @param FormlyFieldConfig field
   * @memberof ConstructionVerificationSectionModel
   */
  disableEnableFields(field: FormlyFieldConfig) {
    const softDisableList = Object.values(SoftDisableFieldList);
    const currencyDisabledList = Object.values(CurrencyDisabledList);
    const generalInformationDisableList = Object.values(GeneralInformationDisableList);

    const formlyBuilderService = field.options.formState.service;

    field.parent.fieldGroup.forEach((group: any) => {
      const groupKey = group.key;
      if (groupKey !== field.key) {
        if (field.formControl.value === 'true') {
          if (currencyDisabledList.indexOf(groupKey) === -1) {
            if ((groupKey === undefined || groupKey === null) && group?.fieldGroup && group?.fieldGroup.length > 0) {
              group?.fieldGroup?.forEach(item => {
                if (generalInformationDisableList.indexOf(groupKey) === -1) {
                  //group.formControl.disable();
                  item.props.readonly = true;
                } else {
                  // Soft disable
                  // For sections with arrays/children, use only soft-disable
                  item.props.isDisabled = true;
                }
              });
            }
            if (softDisableList.indexOf(groupKey) === -1) {
              //group.formControl.disable();
              group.props.readonly = true;
            } else {
              // Soft disable
              // For sections with arrays/children, use only soft-disable
              group.props.isDisabled = true;
            }
          }
        } else {
          if (formlyBuilderService.mode === RiskModeEnum.CURRENCY) {
            if (currencyDisabledList.indexOf(groupKey) === -1) {
              if ((groupKey === undefined || groupKey === null) && group?.fieldGroup && group?.fieldGroup.length > 0) {
                group?.fieldGroup?.forEach(item => {
                  if (generalInformationDisableList.indexOf(item.key) === -1) {
                    //group.formControl.disable();
                    item.props.readonly = false;
                  } else {
                    // Soft disable
                    // For sections with arrays/children, use only soft-disable
                    item.props.isDisabled = false;
                  }
                });
              }
              if (softDisableList.indexOf(groupKey) === -1) {
                group.props.readonly = false;
                //group.formControl.enable();
              } else {
                group.props.isDisabled = false;
              }
            }
          }
        }
      }
    });
  }
}
