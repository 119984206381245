import { Component, OnInit,OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FieldType, FieldTypeConfig} from '@ngx-formly/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-formly-comment',
  templateUrl: './formly-comment-fr-sheet.component.html',
  styleUrls: [ './formly-comment-fr-sheet.component.scss' ],
})
// Predominant
export class FormlyCommentComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
  initialForm: any;
  fieldValue

  protected ngUnsubscribe = new Subject<void>();
  constructor(private modalCtrl: ModalController) {
    super();
  }

  ngOnInit() {
    this.fieldValue = this.field.props.initialValue ? this.field.props.initialValue :this.field.formControl?.get(this.field.props.commentActionKey)?.value;
  }

  onSwipeDown() {
    this.close();
  }
  close() {
    return this.modalCtrl.dismiss(this.fieldValue, 'cancel');
  }
  save() {
   
    return this.modalCtrl.dismiss(this.fieldValue, 'save');

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
