import { Injectable } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ActionSheetService {
  private actionSheet;
  constructor(private actionSheetCtrl: ActionSheetController) {}

  async present(header: string, cssClass: string, buttons: Array<any>) {
    this.actionSheet = await this.actionSheetCtrl.create({
      header,
      cssClass,
      buttons,
    });
    await this.actionSheet.present();
    return this.actionSheet;
  }

  public dismiss() {
    this.actionSheet.dismiss();
  }
}
