import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { WaterSupplyPipeScheduleSpocSectionModel } from './water-supply-pipe-schedule-spoc.model';

export class PipeScheduleSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super(
      {
        id: SectionEnum.FR_S_WS_PIPE_SCHEDULE.toString(),
        props: { label: 'Pipe Schedule' },
        expressions: {
          'props.isDisabled': (field: FormlyFieldConfig) => !formlyBuilderService?.waterSupplyService?.checkPipeSchedule(field),
            // hide: (field: FormlyFieldConfig) => {
            //   if (!formlyBuilderService?.waterSupplyService?.checkPipeSchedule(field)) {
            //     return true;
            //   }
            //   return false;
            // }
        },
        // pipe schedule sections
        fieldGroupClassName: 'pipe-schedule-container',
        fieldGroup: [
          WaterSupplyPipeScheduleSpocSectionModel.build(formlyBuilderService).fields,
          {
            key: 'hasCreditableSupervisionOfWaterFlow',
            wrappers: ['question-wrapper'],
            type: 'rf-yes-no',
            props: {
              label: 'Creditable Supervision of Waterflow',
              rightAlign: true,
              firstColSize: 8,
            },
          },
          {
            key: 'adjustmentRequirement',
            wrappers: ['simple-section-wrapper'],
            className: 'adjustment-left-padding adjustment-label radio-btn-margin',
            props: {
              label: 'Adjustment Requirements',
              isCollapsable: true,
              showError: true,
              isAsteriskNotRequired: true,
            },
            fieldGroupClassName: 'adjustment-requirement-container',
            fieldGroup: [
              {
                key: 'waterSupplyAdjustmentCodeValue',
                defaultValue: 'UNAD'

              },
              {
                wrappers: ['question-wrapper'],
                className: 'requirement-style adjustment-style ',
                props: {
                  label: 'Reduce to 200 GPM for SPOC 1 in CC3-6',
                  showError: false,
                },
                fieldGroupClassName: 'radio-input-style',
                fieldGroup: [
                  {
                    key: 'reduce',
                    type: 'rf-radio',
                    className: 'radio-style',
                    props: {
                      isHorizontalOrientation: true,
                      slot: 'start',
                      allowEmptySelection: true,
                      options: [
                        { label: '', value: 'REDU', disabled: false },
                      ],
                      click: (field: FormlyFieldConfig) => {
                        const value = field?.model?.waterSupplyAdjustmentCodeValue;
                        if (value) {
                          const radioGroups = [
                            { formName: 'waterSupplyAdjustmentCodeValue', value: 'REDU' },
                            { formName: 'numberOfSprinklers', value: null },
                            { formName: 'unusualConditions', value: null },
                            { formName: 'unjastedSprinkler', value: null },

                          ];
                          const fieldToClear = [
                            'pipeScheduleNumberOfSprinklers',
                          ];
                          formlyBuilderService?.formlyWrapperService?.changeRadioButtonGroup(field, radioGroups, fieldToClear);
                        }
                      },
                    },
                    hooks: {
                      onInit: (field: FormlyFieldConfig) => {
                        field?.formControl?.valueChanges.subscribe((value) => {
                          if (value !== null && value && value === 'REDU') {
                            const radioGroups = [
                              { formName: 'numberOfSprinklers', value: null },
                              { formName: 'unusualConditions', value: null },
                              { formName: 'unjastedSprinkler', value: null },

                            ];
                            field?.form?.get('waterSupplyAdjustmentCodeValue').setValue('REDU')
                            formlyBuilderService?.formlyWrapperService?.changeRadioButtonGroup(field, radioGroups);
                          }
                        });
                      },
                    },
                  },

                ],

              },
              {
                wrappers: ['question-wrapper'],
                className: 'requirement-style adjustment-style ',
                props: {
                  label: 'Number of Sprinklers',
                  showError: false,
                },
                fieldGroupClassName: 'radio-input-style',
                fieldGroup: [
                  {
                    type: 'simple-input',
                    key: 'pipeScheduleNumberOfSprinklers',
                    className: 'input-style',
                    props: {
                      type: 'number',
                      required: false,
                      isAsteriskNotRequired: true,
                      readonly: false,
                      isDisabled: false,
                      min: 0,
                      maxLength: 7
                    },
                    expressions: {
                      'props.readonly': (model: FormlyFieldConfig) => {
                        const isCodeValue = model.form?.get('numberOfSprinklers')?.value;
                        if (isCodeValue === 'SPKR') {
                          return false
                        }
                        else {
                          return true;
                        }
                      },
                      'props.required': (model: FormlyFieldConfig) => {
                        const isCodeValue = model.form?.get('numberOfSprinklers')?.value;
                        if (isCodeValue === 'SPKR') {
                          return true
                        }
                        else {
                          return false;
                        }
                      }
                    }
                  },
                  {
                    key: 'numberOfSprinklers',
                    type: 'rf-radio',
                    className: 'radio-style',
                    props: {
                      isHorizontalOrientation: true,
                      slot: 'start',
                      allowEmptySelection: true,
                      options: [
                        { label: '', value: 'SPKR', disabled: false },
                      ],
                      click: (field: FormlyFieldConfig) => {
                        const value = field?.model?.waterSupplyAdjustmentCodeValue;
                        if (value) {
                          const radioGroups = [
                            { formName: 'reduce', value: null },
                            { formName: 'waterSupplyAdjustmentCodeValue', value: 'SPKR' },
                            { formName: 'unusualConditions', value: null },
                            { formName: 'unjastedSprinkler', value: null },

                          ];
                          formlyBuilderService?.formlyWrapperService?.changeRadioButtonGroup(field, radioGroups);
                        }

                      },
                    },

                    hooks: {
                      onInit: (field: FormlyFieldConfig) => {
                        field?.formControl?.valueChanges.subscribe((value) => {
                          if (value !== null && value && value === 'SPKR') {
                            const radioGroups = [
                              { formName: 'reduce', value: null },
                              { formName: 'unusualConditions', value: null },
                              { formName: 'unjastedSprinkler', value: null },

                            ];
                            field?.form?.get('waterSupplyAdjustmentCodeValue')?.setValue('SPKR')
                            formlyBuilderService?.formlyWrapperService?.changeRadioButtonGroup(field, radioGroups);
                          }
                        });
                      },
                    },
                  },
                ],

              },
              {
                wrappers: ['question-wrapper'],
                className: 'requirement-style adjustment-style ',
                props: {
                  label: 'Unusual Conditions',
                  showError: false,
                },
                fieldGroupClassName: 'radio-input-style',
                fieldGroup: [
                  {
                    key: 'unusualConditions',
                    type: 'rf-radio',
                    className: 'radio-style',
                    props: {
                      isHorizontalOrientation: true,
                      slot: 'start',
                      allowEmptySelection: true,
                      options: [
                        { label: '', value: 'UNUS', disabled: false },
                      ],
                      click: (field: FormlyFieldConfig) => {
                        const value = field?.model?.waterSupplyAdjustmentCodeValue;
                        if (value) {
                          const radioGroups = [
                            { formName: 'reduce', value: null },
                            { formName: 'numberOfSprinklers', value: null },
                            { formName: 'waterSupplyAdjustmentCodeValue', value: 'UNUS' },
                            { formName: 'unjastedSprinkler', value: null },

                          ];
                          const fieldToClear = [
                            'pipeScheduleNumberOfSprinklers',
                          ];
                          formlyBuilderService?.formlyWrapperService?.changeRadioButtonGroup(field, radioGroups, fieldToClear);
                        }
                      },
                    },
                    hooks: {
                      onInit: (field: FormlyFieldConfig) => {
                        field?.formControl?.valueChanges.subscribe((value) => {
                          if (value !== null && value && value === 'UNUS') {
                            const radioGroups = [
                              { formName: 'reduce', value: null },
                              { formName: 'numberOfSprinklers', value: null },
                              { formName: 'unjastedSprinkler', value: null },

                            ];
                            field?.form?.get('waterSupplyAdjustmentCodeValue')?.setValue('UNUS')
                            formlyBuilderService?.formlyWrapperService?.changeRadioButtonGroup(field, radioGroups);
                          }
                        });
                      },
                    },
                  },
                ]
              },
              {
                wrappers: ['question-wrapper'],
                className: 'requirement-style adjustment-style unadjusted-sprinkler-bottom-style',
                props: {
                  label: 'Unadjusted Sprinkler System Requirement (GPM)',
                  showError: false,
                },
                fieldGroupClassName: 'radio-input-style',
                fieldGroup: [
                  {
                    key: 'unjastedSprinkler',
                    type: 'rf-radio',
                    className: 'radio-style',
                    props: {
                      isHorizontalOrientation: true,
                      slot: 'start',
                      allowEmptySelection: true,
                      options: [
                        { label: '', value: 'UNAD', disabled: false },
                      ],
                      click: (field: FormlyFieldConfig) => {
                        const value = field?.model?.waterSupplyAdjustmentCodeValue;
                        if (value) {
                          const radioGroups = [
                            { formName: 'reduce', value: null },
                            { formName: 'numberOfSprinklers', value: null },
                            { formName: 'unusualConditions', value: null },
                            { formName: 'waterSupplyAdjustmentCodeValue', value: 'UNAD' },
                          ];
                          const fieldToClear = [
                            'pipeScheduleNumberOfSprinklers',
                          ];
                          formlyBuilderService?.formlyWrapperService?.changeRadioButtonGroup(field, radioGroups, fieldToClear);
                        }
                      },
                    },
                    hooks: {
                      onInit: (field: FormlyFieldConfig) => {
                        field?.formControl?.valueChanges.subscribe((value) => {
                          if (value !== null && value && value === 'UNAD') {
                            const radioGroups = [
                              { formName: 'reduce', value: null },
                              { formName: 'numberOfSprinklers', value: null },
                              { formName: 'unusualConditions', value: null },

                            ];
                            field?.form?.get('waterSupplyAdjustmentCodeValue')?.setValue('UNAD')
                            formlyBuilderService?.formlyWrapperService?.changeRadioButtonGroup(field, radioGroups);
                          }
                        });
                      },
                    },
                  },
                ]
              },
            ],
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                field.formControl?.markAllAsTouched()
              }
            },
            validators: {
              adjustmentCheck: {
                expression: (control: FormControl, field: FormlyFieldConfig) => {
                  let reduceValue;
                  let numberOfSprinklersValue;
                  let unusualConditionsValue;
                  let unjastedSprinklerValue;

                  reduceValue = field?.options?.formState?.service?.formlyHookReuseableService?.isCheckNull(control.value.reduce);
                  numberOfSprinklersValue = field?.options?.formState?.service?.formlyHookReuseableService?.isCheckNull(control.value.numberOfSprinklers);
                  unusualConditionsValue = field?.options?.formState?.service?.formlyHookReuseableService?.isCheckNull(control.value.unusualConditions);
                  unjastedSprinklerValue = field?.options?.formState?.service?.formlyHookReuseableService?.isCheckNull(control.value.unjastedSprinkler);

                  const isAdjustment = reduceValue || numberOfSprinklersValue || unusualConditionsValue || unjastedSprinklerValue;
                  const spocNumbers = formlyBuilderService?.waterSupplyService?.checkPipeSchedule(field);
                  return spocNumbers && !isAdjustment ? false : true
                },
                message: 'Adjustment Requirements is Required'
              }
            },
          },
          {
            key: 'hollowSheathedAreaRangeMinimum',
            wrappers: ['question-wrapper'],
            type: 'rf-radio',
            className: 'left-padding-radio combustible-width',
            defaultValue: null,
            props: {
              label: 'Combustible Hollow Sheathed Construction',
              isHorizontalOrientation: true,
              slot: 'start',
              isLabelRight: true,
              required: true,
              showError: true,
              isAsteriskNotRequired: true,
              options: [
                { label: '0-10%', value: 0, disabled: false },
                { label: '11-25%', value: 11, disabled: false },
                { label: 'Over 25%', value: 26, disabled: false },
              ],
            },
            expressions: {
              'props.required': (field: FormlyFieldConfig) => {
                const spocNumbers = formlyBuilderService?.waterSupplyService?.checkPipeSchedule(field);
                return spocNumbers ? true : false;
              }
            },
            hooks: {
              onInit: (field: FormlyFieldConfig) => {
                field.formControl.markAllAsTouched()
              }
            },
          },

          {
            key: 'dpv',
            wrappers: ['simple-section-wrapper'],
            props: {
              label: 'Dry System serves 5,000 sq. ft. or more, or 25% or more of Total Area',
              isCollapsable: true,
              shallowErrors: true

            },
            fieldGroup: [
              {
                key: 'dpvSystem',
                type: 'rf-radio',
                className: 'dpv-system',
                defaultValue: null,
                props: {
                  isHorizontalOrientation: false,
                  slot: 'end',
                  options: [
                    { label: 'Wet System', value: 'Wet', disabled: false },
                    {
                      label: 'DPV with operative QOD, or no QOD required',
                      value: 'QOD Not Required',
                      disabled: false,
                    },
                    {
                      label: 'DPV without required QOD, or Inoperative QOD',
                      value: 'No Required QOD',
                      disabled: false,
                    },
                  ],
                },
                expressions: {
                  'props.required': (field: FormlyFieldConfig) => {
                    const spocNumbers = formlyBuilderService?.waterSupplyService?.checkPipeSchedule(field);
                    return spocNumbers ? true : false;
                  }
                },
              },
            ],

          },
        ],
      },
    );
  }
}