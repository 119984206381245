import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { SectionEnum } from '../../section.config';
import { BuildingHazardsDataModel } from 'src/app/models/formly-builder/formly/full-risk/occupant-hazards/building-hazards-data.model';
import { UtilService } from 'src/app/services/util-service/util.service';

@Injectable({
  providedIn: 'root'
})
export class BuildingHazardsService extends SectionServiceAbstract{

  constructor(private util: UtilService) { super();}

async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {

   const hasChanged: boolean = BuildingHazardsDataModel.hasChanged(model, riskReportData);
     if (hasChanged) {
       const buildingHazardsModel = BuildingHazardsDataModel.to(model.occupants.buildingHazards.buildingHazardArray);
       buildingHazardsModel.map((item)=>{
        item.ReportIdentifier = riskReportData.ReportIdentifier? riskReportData.ReportIdentifier :'';
        // eslint-disable-next-line max-len
        item.ReportHazardIdentifier = this.getHazardsIdentifier(item.reportHazardIdentifier);
        return item;
       });
      return { ...riskReportData, ...{ ReportHazards: buildingHazardsModel} };
    } else {
       return undefined;
     }
}
  loadData(sectionData: any[], riskReportData: any, isDataInS3Format: boolean, model: any) {
    const buildingHazardsModel: BuildingHazardsDataModel = BuildingHazardsDataModel.fromS3(riskReportData.ReportHazards);

  //  return { ...model, ...{ occupants: {buildingHazards: {buildingHazardArray : buildingHazardsModel}} } };;
  return null;
  }
  getHazardsIdentifier(hazardsId){
  return hazardsId === false || hazardsId === undefined || hazardsId === 'undefined'|| hazardsId === '' ? this.util.uuid():hazardsId;
  }
}
