import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { NgHttpService } from '../ng-http.service';
import { CapacitorHttpService } from '../cap-http.service';
import { HttpErrorInterceptorService } from '../http-error-interceptor.service';
import { Injector } from '@angular/core';
import { LoggerService } from 'src/app/services/util-service/log-service';
import { LogTimeService } from 'src/app/services/util-service/log-time.service';

export const httpFactory = (platform: Platform, httpClient: HttpClient, injector: Injector) => {
  const httpErrorInterceptorService = injector.get(HttpErrorInterceptorService);
  const loggerService = injector.get(LoggerService);
  const logTimeService = injector.get(LogTimeService);
  return platform.is('ios') ? new CapacitorHttpService(httpErrorInterceptorService, loggerService,logTimeService) : new NgHttpService(httpClient);
};
