import { Injectable } from '@angular/core';
import { Photo } from '@capacitor/camera';
import { Directory, Encoding, Filesystem, GetUriOptions, ReadFileResult } from '@capacitor/filesystem';
import { Storage as IonicLocalStorage } from '@ionic/storage-angular';
import { Storage as AmplifyStorage, DataStore } from 'aws-amplify';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { s3SyncConfig } from 'src/app/models/s3Sync/s3SyncConfig';
import { s3SyncStrategy } from 'src/app/models/s3Sync/s3SyncStrategy';
import { RiskReport } from 'src/models';
import { AmplifyService } from '../amplify/amplify.service';
import { UtilService } from '../util-service/util.service';
import { VeriskLog } from '../util-service/verisk-log';

/**
 * TODO: Delete this file once both changes are properly merged
 */
@Injectable({
  providedIn: 'root',
})
export class S3SyncService {
  public static LOCAL_FILE_MISSING: string = 'Local JSON File Missing On Disk';
  private ionicStorageInitComplete: boolean = false;
  public hasNetWorkConnection: boolean = true;
  public configurations = {};
  // public syncInProgress$:Subject<boolean> = new Subject<boolean>();
  public syncInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private ngUnsubscribe = new Subject<void>();
  constructor(
    private amplify: AmplifyService,
    private ionicStorage: IonicLocalStorage,
    private utilService: UtilService
  ) {
    this.amplify.networkConnection.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      this.hasNetWorkConnection = value;
      if (value) {
        //USER IS CONNECTED, CHECK QUEUE
        this.initIonicStorage().then(
          () => {
            this.ionicStorage.get('SYNC_QUEUE').then(
              (sq) => {
                for (let key in sq) {
                  if (key) {
                    this.configurations[key] = s3SyncStrategy[key];
                  }
                }
                this.syncQueueChanged();
              },
              (error) => {
                let veriskLog: VeriskLog = new VeriskLog(
                  'Ionic Storage Get SyncQueue Error',
                  'ERROR',
                  'Constructor',
                  S3SyncService.name,
                  error
                );
                this.utilService.addLog(veriskLog);
              }
            );
            this.ionicStorage.get('DELETE_QUEUE').then(
              (sq) => {
                for (let key in sq) {
                  if (key) {
                    this.configurations[key] = s3SyncStrategy[key];
                  }
                }
                this.syncQueueChanged('DELETE_QUEUE');
              },
              (error) => {
                let veriskLog: VeriskLog = new VeriskLog(
                  'Ionic Storage Get DeleteQueue Error',
                  'ERROR',
                  'Constructor',
                  S3SyncService.name,
                  error
                );
                this.utilService.addLog(veriskLog);
              }
            );
          },
          (error) => {
            let veriskLog: VeriskLog = new VeriskLog(
              'Ionic Storage Init Error',
              'ERROR',
              'Constructor',
              S3SyncService.name,
              error
            );
            this.utilService.addLog(veriskLog);
          }
        );
      } else {
        Object.keys(s3SyncStrategy).forEach(key=> {
          if(!this.configurations[key]){
            this.configurations[key] = s3SyncStrategy[key];
          }
        });
      }
    });
    this.amplify.modelSynced.subscribe(
      (data) => {
        if (data && s3SyncStrategy[data.model.name]) {
          this.configurations[data.model.name] = s3SyncStrategy[data.model.name];
          this.syncDataModels(this.configurations[data.model.name]);
          const l: VeriskLog = new VeriskLog(
            'Amplify Sync Data Models',
            'DEBUG',
            'Constructor',
            S3SyncService.name,
            data.model
          );
          this.utilService.addLog(l);

          // MGSG - Update local S3 Data copy
          this.amplify.outBoxMutationProcessed.subscribe(async (value) => {
            if (value === null || (value.model && value.model.name != 'Target')) {
              return;
            }
            console.debug('amplify event ----->outboxMutationProcessed -> new Target');
            this.syncDataModels(this.configurations[data.model.name]);
            await this.onDataChange(this.configurations[data.model.name], value.element, true);
          });
          // MGSG - End
        }
        // MGSG - Log model synced events
        else if (data?.model?.name) {
          let veriskLog: VeriskLog = new VeriskLog(
            'Amplify Sync',
            'DEBUG',
            'Constructor',
            S3SyncService.name,
            data.model
          );
          this.utilService.addLog(veriskLog);
        } else {
          let veriskLog: VeriskLog = new VeriskLog('Amplify Sync', 'DEBUG', 'Constructor', S3SyncService.name, data);
          this.utilService.addLog(veriskLog);
        }
        // MGSG - End
      },
      (error) => {
        let veriskLog: VeriskLog = new VeriskLog(
          'Amplify modelSynced Error',
          'ERROR',
          'Constructor',
          S3SyncService.name,
          error
        );
        this.utilService.addLog(veriskLog);
      }
    );
  }

  initIonicStorage(): Promise<boolean> {
    return new Promise((resolve) => {
      if (!this.ionicStorageInitComplete) {
        //INIT IONIC LOCAL STORAGE
        this.ionicStorage.create().then(() => {
          this.ionicStorageInitComplete = true;
          resolve(true);
        });
      } else {
        // Storage Already Exists
        resolve(true);
      }
    });
  }

  async syncDataModels(config: s3SyncConfig): Promise<boolean> {
    let veriskLog: VeriskLog = new VeriskLog(
      'syncDataModels start',
      'DEBUG',
      this.syncDataModels.name,
      S3SyncService.name,
      config
    );
    this.utilService.addLog(veriskLog);
    //Ensure we have local storage created
    await this.initIonicStorage();
    //tell the UI we are syncing data
    this.syncInProgress$.next(true);
    //get the local amplify items for the given model

    // MGSG config is null sometimes!!!!!
    if (!config?.model) {
      veriskLog = new VeriskLog('Null Config', 'ERROR', this.syncDataModels.name, S3SyncService.name);
      this.utilService.addLog(veriskLog);
    }
    let amplifyItems: any = [];
    try {
      amplifyItems = (await this.getAmplifyItems(config.model)) || [];
    } catch (error) {
      let veriskLog: VeriskLog = new VeriskLog(
        'Error in getAmplifyItems',
        'ERROR',
        this.syncDataModels.name,
        S3SyncService.name,
        error
      );
      this.utilService.addLog(veriskLog);
    }
    
    config.amplifyData = amplifyItems;

    const sync = await this.syncModel(config);
    //tell the UI the sync is complete
    this.syncInProgress$.next(false);
    veriskLog = new VeriskLog('Sync Complete', 'INFO', this.syncDataModels.name, S3SyncService.name);
    this.utilService.addLog(veriskLog);
    return sync;
  }

  getAmplifyItems(model: string) {
    switch (model) {
      case 'RiskReport':
        DataStore.observe<RiskReport>(RiskReport).subscribe(() => {
        });
        return DataStore.query(RiskReport);
    }
  }

  async syncModel(config: s3SyncConfig): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.ionicStorage.get(config.model).then(
        (ionicStorageModel) => {
          //we have IONIC Storage for this Model
          if (ionicStorageModel) {
            this.syncExistingItems(config, ionicStorageModel).then(
              () => {
                resolve(true);
              },
              (e) => {
                let veriskLog = new VeriskLog(
                  'syncExistingItems ERROR',
                  'ERROR',
                  this.syncModel.name,
                  S3SyncService.name,
                  e
                );
                this.utilService.addLog(veriskLog);
                reject(false);
              }
            );
          } else {
            //We are creating IONIC Storage For This Model
            this.createDirectories(config).then(
              () => {
                this.syncNewItems(config).then(
                  () => resolve(true),
                  (e) => {
                    let veriskLog = new VeriskLog(
                      'syncNewItems ERROR',
                      'ERROR',
                      this.syncModel.name,
                      S3SyncService.name,
                      e
                    );
                    this.utilService.addLog(veriskLog);
                    reject(e);
                  }
                );
              },
              (e) => {
                let veriskLog = new VeriskLog(
                  'createDirectories ERROR',
                  'ERROR',
                  this.syncModel.name,
                  S3SyncService.name,
                  e
                );
                this.utilService.addLog(veriskLog);
              }
            );
          }
        },
        (error) => {
          let veriskLog = new VeriskLog('ionicStorage get ERROR', 'ERROR', this.syncModel.name, S3SyncService.name, {
            config: config,
            error: error,
          });
          this.utilService.addLog(veriskLog);
        }
      );
    });
  }

  async syncNewItems(config: s3SyncConfig): Promise<boolean> {
    return new Promise((resolve) => {
      const obj = {};
      config.amplifyData.forEach((r: any) => {
        const identifier: string = r[config.amplifyIdentifierField];
        obj[identifier] = { dataLastModified: null, synced: false };
        config.s3AssetFolders.forEach((f: string) => (obj[identifier][f] = {}));
        if (this.hasNetWorkConnection) {
          console.log('user is connected, sync s3 to local');
          config.amplifyData.forEach((amplifyItem: any) => {
            this.onDataChange(config, amplifyItem, true).then(() => {
              obj[identifier].synced = true;
              this.ionicStorage.set(config.model, obj);
            });
          });
        } else {
          this.ionicStorage.set(config.model, obj);
        }
      });
      let veriskLog = new VeriskLog(
        'IonicStorage values Initialized',
        'INFO',
        this.syncNewItems.name,
        S3SyncService.name
      );
      this.utilService.addLog(veriskLog);
      config.ionicStorageContent = obj;
      resolve(true);
    });
  }

  syncExistingItems(config: s3SyncConfig, item: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      config.ionicStorageContent = item;
      try {
        config.amplifyData.forEach((amplifyItem: any) => {
          const storageItem = config.ionicStorageContent[amplifyItem[config.amplifyIdentifierField]];
          if (storageItem && storageItem.dataLastModified !== amplifyItem.s3UpdatedDate) {
            this.onDataChange(config, amplifyItem, storageItem.dataLastModified === null);
          } else {
            if (!storageItem) {
              //MISSING RECORD IN IONIC STORAGE
              config.ionicStorageContent[amplifyItem[config.amplifyIdentifierField]] = {
                dataLastModified: null,
                synced: false,
              };
              config.s3AssetFolders.forEach(
                (f: string) => (config.ionicStorageContent[amplifyItem[config.amplifyIdentifierField]][f] = {})
              );
              this.onDataChange(config, amplifyItem, true);
            } else {
              //model in sync
              let veriskLog = new VeriskLog('model in sync', 'INFO', this.syncExistingItems.name, S3SyncService.name, {
                config: config,
                item: item,
              });
              this.utilService.addLog(veriskLog);
            }
          }
        });
        resolve(true);
      } catch (e) {
        let veriskLog = new VeriskLog(
          'sync existing items error',
          'INFO',
          this.syncExistingItems.name,
          S3SyncService.name,
          { config: config, item: item, error: e }
        );
        this.utilService.addLog(veriskLog);
        reject(false);
      }
    });
  }

  onDataChange(config: s3SyncConfig, amplifyItem: any, isLocalItemMissing: boolean = false): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (isLocalItemMissing) {
        return resolve(true);
        //TODO Reimpliment this later
        console.debug('onDataChange - Reading remote S3 data for ' + amplifyItem[config.amplifyIdentifierField]); // MGSG - Debug Log
        //WE DON'T HAVE THE DATA LOCALLY
        //GET IT FROM S3 AND SAVE IT LOCALLY
        this.retrieveFromServer(config.model, amplifyItem[config.amplifyIdentifierField]).then(
          (result) => {
            config.ionicStorageContent[amplifyItem[config.amplifyIdentifierField]].dataLastModified =
              result.metadata.LastModified;
            config.ionicStorageContent[amplifyItem[config.amplifyIdentifierField]].synced = true;
            this.ionicStorage.set(config.model, config.ionicStorageContent);
            //SAVE THE DATA LOCALY
            const view: any = JSON.parse(result.data);
            // console.debug("onDataChange - Read remote S3 Data for "+ view.RiskId);      // MGSG - Debug Log
            this.updateLocalCopy(view, config.model, true).then(
              () => {
                console.debug('onDataChange - Read remote S3 Asset ' + amplifyItem[config.amplifyIdentifierField]);
                this.syncAssetsFromRemote(config, amplifyItem[config.amplifyIdentifierField]).then((rc) => {
                  console.debug('onDataChange - Read remote S3 Asset Done' + rc);
                  resolve(true);
                });
                resolve(true);
              },
              (error) => {
                console.error('updateLocalCopy ' + error);
                reject(error);
              }
            );
          },
          (e) => {
            console.warn(e + ' THIS FILE IS NOT ON S3 OR LOCAL, THIS SHOULD NEVER HAPPEN!');
            //TODO: Should the item be removed with: DataStore.delete(amplifyItem);
          }
        );
      } else {
        //TODO: WE HAVE TO WALK THIS CASE
        //DATA EXISTS, BUT MODIFIED DATES ARE DIFFERENT
        //WILL THIS EVER HAPPEN?
        console.warn('onDataChange Local Item not Missing');
      }
    });
  }

  async syncAssetsFromRemote(config: s3SyncConfig, identifier: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      config.s3AssetFolders.forEach((folder: string) => {
        const path = 'isurvey/' + config.model.toLowerCase() + '/' + identifier + '/' + folder;
        AmplifyStorage.list(path).then(
          (files: any) => {
            if (files?.length) {
              files.forEach((f) => {
                const fileName = f.key.split('/').pop();
                const fileKey = fileName.split('.')[0];
                // FIX: Key "Photos" may not exist at this point in ionic storage... Create it
                if (config.ionicStorageContent[identifier] && !config.ionicStorageContent[identifier].Photos) {
                  config.ionicStorageContent[identifier].Photos = {};
                }
                config.ionicStorageContent[identifier].Photos[fileKey] = { synced: false };
                this.ionicStorage.set(config.model, config.ionicStorageContent);
                AmplifyStorage.get(f.key, { download: true, cacheControl: 'no-cache' }).then(
                  (storageResult) => {
                    new Response(storageResult.Body).text().then((data) => {
                      Filesystem.writeFile({
                        path: `${config.model}/${identifier}/${folder}/${fileName}`,
                        data: data,
                        directory: Directory.Documents,
                      }).then(
                        () => {
                          config.ionicStorageContent[identifier].Photos[fileKey] = {
                            name: fileName,
                            synced: true,
                            dataLastModified: new Date().toJSON(),
                          };
                          this.ionicStorage.set(config.model, config.ionicStorageContent);
                        },
                        (e) => {
                          reject('syncAssetsFromRemote - LOCAL SAVE FAILED [' + fileName + '] [' + e.message + ']');
                        }
                      );
                    });
                  },
                  (error) => {
                    if (error.name === 'NoSuchKey') {
                      let veriskLog = new VeriskLog(
                        'json not found in s3',
                        'ERROR',
                        `Document /${config.model.toLowerCase()}/${identifier}/${identifier}.json`,
                        S3SyncService.name,
                        error
                      );
                      this.utilService.addLog(veriskLog);
                      reject(
                        `Document /${config.model.toLowerCase()}/${identifier}/${identifier}.json not found in s3!`
                      );
                    }
                  }
                );
              });
            } else {
              resolve(true);
            }
          },
          (error) => {
            let veriskLog = new VeriskLog(
              'AmplifyStorage.list error',
              'ERROR',
              this.syncAssetsFromRemote.name,
              S3SyncService.name,
              error
            );
            this.utilService.addLog(veriskLog);
          }
        );
      });
    });
  }

  updateLocalCopy(data: any, model: string, firstSave: boolean = false): Promise<string> {
    return new Promise((resolve, reject) => {
      const identifier: string = data[this.configurations[model].s3IdentifierField];
      const rfo: any = {};
      rfo.directory = Directory.Documents;
      rfo.path = `${model}/${identifier}/${identifier}.json`;
      rfo.encoding = Encoding.UTF8;
      rfo.recursive = false;
      rfo.data = JSON.stringify(data);
      Filesystem.writeFile(rfo).then(
        () => {
          let veriskLog = new VeriskLog(
            'Updating local copy',
            'INFO',
            this.updateLocalCopy.name,
            S3SyncService.name,
            identifier
          );
          this.utilService.addLog(veriskLog);
          if (!this.configurations[model].ionicStorageContent[identifier])
            this.configurations[model].ionicStorageContent[identifier] = {};
          this.configurations[model].ionicStorageContent[identifier].dataLastModified = new Date().toJSON();
          this.configurations[model].ionicStorageContent[identifier].synced = false;
          this.ionicStorage.set(model, this.configurations[model].ionicStorageContent);
          if (!firstSave) {
            this.addToSyncQueue(model, identifier).then(() => resolve('REPORT SAVED LOCALY'));
          } else {
            resolve('REPORT SAVED LOCALY');
          }
        },
        (e) => {
          let veriskLog = new VeriskLog(
            'Local file write error',
            'ERROR',
            this.updateLocalCopy.name,
            S3SyncService.name,
            e
          );
          this.utilService.addLog(veriskLog);
          reject('LOCAL SAVE FAILED [' + e.message + ']');
        }
      );
    });
  }

  createDirectories(config: s3SyncConfig): Promise<boolean> {
    return new Promise((resolve) => {
      config.amplifyData.forEach((item: RiskReport) => {
        let identifier: string = item[config.amplifyIdentifierField];
        Filesystem.readdir({ directory: Directory.Documents, path: `${config.model}/${identifier}` }).then(
          (files) => {
            console.log(files);
          },
          () => {
            Filesystem.mkdir({
              directory: Directory.Documents,
              path: `${config.model}/${identifier}`,
              recursive: true,
            }).then(() => {
              console.debug('Create Local Directories');
              config.s3AssetFolders.forEach((folder: string) => {
                Filesystem.mkdir({
                  directory: Directory.Documents,
                  path: `${config.model}/${identifier}/${folder}`,
                  recursive: false,
                }).catch((e) => {
                  let veriskLog = new VeriskLog(
                    'create directories error',
                    'ERROR',
                    this.createDirectories.name,
                    S3SyncService.name,
                    e
                  );
                  this.utilService.addLog(veriskLog);
                });
              });
            });
          }
        );
      });
      resolve(true);
    });
  }

  async addToSyncQueue(model, identifier, toDelete: boolean = false): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let queueToManage = toDelete ? 'DELETE_QUEUE' : 'SYNC_QUEUE';
      this.ionicStorage.get(queueToManage).then(
        (queue) => {
          if (!queue) {
            queue = {};
            queue[model] = [identifier];
          } else {
            if (queue[model]) {
              let idx = queue[model].indexOf(identifier);
              if (idx === -1) {
                queue[model].push(identifier);
              }
            } else {
              queue[model] = [identifier];
            }
          }
          this.ionicStorage.set(queueToManage, queue).then(() => {
            this.syncQueueChanged(queueToManage);
            resolve(true);
          });
        },
        (e) => {
          let veriskLog = new VeriskLog(
            'IonicStorage Get ' + queueToManage + ' error',
            'ERROR',
            this.syncAssetsFromRemote.name,
            S3SyncService.name,
            e
          );
          this.utilService.addLog(veriskLog);
        }
      );
    });
  }

  syncQueueChanged(queueToManage: string = 'SYNC_QUEUE') {
    if (this.hasNetWorkConnection) {
      this.ionicStorage.get(queueToManage).then(
        (queue) => {
          for (let key in queue) {
            if (key) {
              if (!this.configurations[key]) this.configurations[key] = s3SyncStrategy[key];
              const config: s3SyncConfig = this.configurations[key];
              let items = queue[key];
              items.forEach(() => {
                if (!config.ionicStorageContent) {
                  this.ionicStorage.get(config.model).then((storageContent) => {
                    config.ionicStorageContent = storageContent;
                    this.getAmplifyItems(config.model).then((ampItems) => {
                      config.amplifyData = ampItems;
                      if (queueToManage === 'SYNC_QUEUE') {
                        this.processSyncQueue(queue[key], config);
                      } else {
                        this.processDeleteQueue(queue[key], config);
                      }
                    });
                  });
                } else {
                  if (queueToManage === 'SYNC_QUEUE') {
                    this.processSyncQueue(queue[key], config);
                  } else {
                    this.processDeleteQueue(queue[key], config);
                  }
                }
              });
            }
          }
          let veriskLog = new VeriskLog(
            'Queue Empty',
            'INFO',
            this.syncQueueChanged.name,
            S3SyncService.name,
            queueToManage
          );
          this.utilService.addLog(veriskLog);
        },
        (e) => {
          let veriskLog = new VeriskLog(
            'IonicStorage Get ' + queueToManage + ' error',
            'ERROR',
            this.syncQueueChanged.name,
            S3SyncService.name,
            e
          );
          this.utilService.addLog(veriskLog);
        }
      );
    } else {
      let veriskLog = new VeriskLog(
        'OFFLINE Dont Sync',
        'DEBUG',
        this.syncQueueChanged.name,
        S3SyncService.name,
        queueToManage
      );
      this.utilService.addLog(veriskLog);
    }
  }

  processSyncQueue(queue, config) {
    for (let key in config.ionicStorageContent) {
      if (key && queue.indexOf(key) !== -1) {
        const item = config.ionicStorageContent[key];
        if (!item.synced) {
          this.updateRemoteFile(config.model, key).then(
            () => {
              this.updateAmplify(config, key);
              let veriskLog = new VeriskLog(
                'Remote S3 file updated',
                'INFO',
                this.processSyncQueue.name,
                S3SyncService.name,
                key
              );
              this.utilService.addLog(veriskLog);
            },
            (e) => {
              let veriskLog = new VeriskLog(
                'Remote S3 file update ERROR',
                'ERROR',
                this.processSyncQueue.name,
                S3SyncService.name,
                e
              );
              this.utilService.addLog(veriskLog);
            }
          );
        }
        config.s3AssetFolders.forEach((folder: string) => {
          let assets = item[folder];
          for (let assetKey in assets) {
            if (assetKey && assets[assetKey]['synced'] === false) {
              const assetItem = assets[assetKey];
              const remotePath = `isurvey/${config.model.toLowerCase()}/${key}/photos/${assetItem.filename}`;
              console.log(remotePath);
              const localPath = `${config.model}/${key}/${folder}/${assetItem.filename}`;
              this.loadSingleAsset(localPath).then(
                (photo) => {
                  this.savePhotoToRemote(photo, remotePath, assetItem.format).then(
                    () => {
                      config.ionicStorageContent[key][folder][assetKey].synced = true;
                    },
                    (e) => {
                      let veriskLog = new VeriskLog(
                        'savePhotoToRemote ERROR',
                        'ERROR',
                        this.processSyncQueue.name,
                        S3SyncService.name,
                        e
                      );
                      this.utilService.addLog(veriskLog);
                    }
                  );
                },
                (e) => {
                  let veriskLog = new VeriskLog(
                    'loadSingleAsset ERROR',
                    'ERROR',
                    this.processSyncQueue.name,
                    S3SyncService.name,
                    e
                  );
                  this.utilService.addLog(veriskLog);
                }
              );
              //FIXME: This can be done better
              const LastModified: Date = new Date();
              this.finalizeItemSync(config, key, LastModified);
            }
          }
        });
      }
    }
  }

  processDeleteQueue(queue, config) {
    for (let key in config.ionicStorageContent) {
      if (key && queue.indexOf(key) !== -1) {
        const item = config.ionicStorageContent[key];
        if (item.scheduledForDelete) {
          this.removeRemoteFile(config.model, key).then(
            () => {
              this.removeAmplifyItem(config, key);
              console.debug('Remote S3 file removed'); // MGSG - Debug Log
            },
            (e) => {
              let veriskLog = new VeriskLog(
                'removeRemoteFile ERROR',
                'ERROR',
                this.processDeleteQueue.name,
                S3SyncService.name,
                e
              );
              this.utilService.addLog(veriskLog);
            }
          );
        }
        config.s3AssetFolders.forEach((folder: string) => {
          let assets = item[folder];
          for (let assetKey in assets) {
            if (assetKey && assets[assetKey]['scheduledForDelete'] === true) {
              const assetItem = assets[assetKey];
              const remotePath = `isurvey/${config.model.toLowerCase()}/${key}/photos/${assetItem.filename}`;
              //removeRemotePhoto does not return the promise on the simulator so separated deleteLocalAsset method from removeRemotePhoto methods promise
              this.removeRemotePhoto(remotePath).then(
                () => {},
                (e) => {
                  let veriskLog = new VeriskLog(
                    'removeRemotePhoto ERROR',
                    'ERROR',
                    this.processSyncQueue.name,
                    S3SyncService.name,
                    e
                  );
                  this.utilService.addLog(veriskLog);
                }
              );
              const localPath = `${config.model}/${key}/photos/${assetItem.filename}`;
              this.deleteLocalAsset(localPath).then(
                () => {
                  delete config.ionicStorageContent[key][folder][assetKey];
                  this.ionicStorage.set(config.model, config.ionicStorageContent);
                },
                (e) => {
                  let veriskLog = new VeriskLog(
                    'deleteLocalAsset ERROR',
                    'ERROR',
                    this.processSyncQueue.name,
                    S3SyncService.name,
                    e
                  );
                  this.utilService.addLog(veriskLog);
                }
              );
              //FIXME: This can be done better
              const LastModified: Date = new Date();
              this.finalizeItemSync(config, key, LastModified);
            }
          }
        });
      }
    }
  }

  updateRemoteFile(model, identifier): Promise<boolean> {
    return new Promise((resolve) => {
      const filePath: string = 'isurvey/' + model.toLowerCase() + '/' + identifier + '/' + identifier + '.json';
      this.loadlocalData(model, identifier).then(
        (local) => {
          const itemAsBlob = new Blob([JSON.stringify(local)]);
          AmplifyStorage.put(filePath, itemAsBlob, {
            useAccelerateEndpoint: true,
            level: 'public',
            progressCallback: (progress) => {
              if (progress.loaded === progress.total) {
                let veriskLog = new VeriskLog(
                  'Amplify Storage Success',
                  'INFO',
                  this.updateRemoteFile.name,
                  S3SyncService.name,
                  progress
                );
                this.utilService.addLog(veriskLog);
                resolve(true);
              }
            },
          }).catch((e) => {
            let veriskLog = new VeriskLog(
              'Amplify Storage Put ERROR',
              'ERROR',
              this.updateRemoteFile.name,
              S3SyncService.name,
              e
            );
            this.utilService.addLog(veriskLog);
          });
        },
        (e) => {
          let veriskLog = new VeriskLog(
            'loadLocalData ERROR',
            'ERROR',
            this.updateRemoteFile.name,
            S3SyncService.name,
            e
          );
          this.utilService.addLog(veriskLog);
        }
      );
    });
  }

  async removeRemoteFile(model, identifier): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const path = 'isurvey/' + model.toLowerCase() + '/' + identifier + '/' + identifier + '.json';
      AmplifyStorage.remove(path)
        .then(() => {
          // S3 Remove Success
          resolve(true);
        })
        .catch((e) => {
          let veriskLog = new VeriskLog(
            'AmplifyStorage remove ERROR',
            'ERROR',
            this.removeRemoteFile.name,
            S3SyncService.name,
            { error: e, path: path }
          );
          this.utilService.addLog(veriskLog);
          reject('Remote Delete Failed');
        });
    });
  }

  updateAmplify(config: s3SyncConfig, identifier: string) {
    const LastModified: Date = new Date();
    const amplifyItem = config.amplifyData.find((i) => i[config.amplifyIdentifierField] === identifier);
    switch (config.model) {
      case 'RiskReport':
        DataStore.save(
          RiskReport.copyOf(amplifyItem, (updated) => {
            updated.s3UpdatedDate = LastModified.toJSON();
          })
        ).then(
          () => this.finalizeItemSync(config, identifier, LastModified),
          (e) => {
            let veriskLog = new VeriskLog(
              'Amplify DataStore Save ERROR',
              'ERROR',
              this.updateAmplify.name,
              S3SyncService.name,
              { error: e, item: amplifyItem }
            );
            this.utilService.addLog(veriskLog);
          }
        );
        break;
    }
  }

  removeAmplifyItem(config: s3SyncConfig, identifier: string) {
    const LastModified: Date = new Date();
    const amplifyItem = config.amplifyData.find((i) => i[config.amplifyIdentifierField] === identifier);
    switch (config.model) {
      case 'RiskReport':
        DataStore.delete(amplifyItem).then(
          () => this.finalizeItemSync(config, identifier, LastModified),
          (e) => {
            let veriskLog = new VeriskLog(
              'Amplify DataStore delete ERROR',
              'ERROR',
              this.removeAmplifyItem.name,
              S3SyncService.name,
              { error: e, item: amplifyItem }
            );
            this.utilService.addLog(veriskLog);
          }
        );
        break;
    }
  }

  async finalizeItemSync(config: s3SyncConfig, identifier: string, LastModified: Date) {
    let ionicStorageModel;
    if (!config.ionicStorageContent) {
      ionicStorageModel = await this.ionicStorage.get(config.model);
      config.ionicStorageContent = ionicStorageModel;
    }
    config.ionicStorageContent[identifier].dataLastModified = LastModified.toJSON();
    config.ionicStorageContent[identifier].synced = true;
    await this.ionicStorage.set(config.model, config.ionicStorageContent).catch((e) => {
      let veriskLog = new VeriskLog(
        'IonicStorage set ERROR',
        'ERROR',
        this.finalizeItemSync.name,
        S3SyncService.name,
        e
      );
      this.utilService.addLog(veriskLog);
    });
    this.ionicStorage.get('SYNC_QUEUE').then(
      (queue) => {
        let items: string[] = queue[config.model];
        let idx: number = items.indexOf(identifier);
        items.splice(idx, 1);
        queue[config.model] = items;
        this.ionicStorage.set('SYNC_QUEUE', queue).then(
          () => {
            // done
          },
          (e) => {
            let veriskLog = new VeriskLog(
              'IonicStorage set SyncQueue ERROR',
              'ERROR',
              this.finalizeItemSync.name,
              S3SyncService.name,
              e
            );
            this.utilService.addLog(veriskLog);
          }
        );
      },
      (e) => {
        let veriskLog = new VeriskLog(
          'IonicStorage get Sync_Queue ERROR',
          'ERROR',
          this.finalizeItemSync.name,
          S3SyncService.name,
          e
        );
        this.utilService.addLog(veriskLog);
      }
    );
    this.ionicStorage.get('DELETE_QUEUE').then(
      (queue) => {
        if (queue && queue[config.model]) {
          let items: string[] = queue[config.model];
          let idx: number = items.indexOf(identifier);
          items.splice(idx, 1);
          queue[config.model] = items;
          this.ionicStorage.set('DELETE_QUEUE', queue).then(
            () => {
              // done
            },
            (e) => {
              let veriskLog = new VeriskLog(
                'IonicStorage set Delete_Queue ERROR',
                'ERROR',
                this.finalizeItemSync.name,
                S3SyncService.name,
                e
              );
              this.utilService.addLog(veriskLog);
            }
          );
        }
      },
      (e) => {
        let veriskLog = new VeriskLog(
          'IonicStorage get Delete_Queue ERROR',
          'ERROR',
          this.finalizeItemSync.name,
          S3SyncService.name,
          e
        );
        this.utilService.addLog(veriskLog);
      }
    );
  }

  async retrieveFromServer(model: string, identifier: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const path = 'isurvey/' + model.toLowerCase() + '/' + identifier + '/' + identifier + '.json';
      AmplifyStorage.get(path, { download: true, cacheControl: 'no-cache' }).then(
        (storageResult) => {
          new Response(storageResult.Body).text().then(
            (data) => {
              resolve({ metadata: storageResult, data: data });
            },
            (e) => {
              let veriskLog = new VeriskLog(
                'Blob to text ERROR',
                'ERROR',
                this.retrieveFromServer.name,
                S3SyncService.name,
                e
              );
              this.utilService.addLog(veriskLog);
            }
          );
        },
        (error) => {
          let veriskLog = new VeriskLog(
            'AmplifyStorage Get ERROR',
            'ERROR',
            this.retrieveFromServer.name,
            S3SyncService.name,
            { error: error, identifier: identifier }
          );
          this.utilService.addLog(veriskLog);
          if (error.name === 'NoSuchKey') {
            reject(`Document /${model.toLowerCase()}/${identifier}/${identifier}.json not found in s3!`);
          }
        }
      );
    });
  }

  async savePhoto(model: string, identifier: string, photo: Photo, name: string, folder: string = 'photos') {
    const config = this.configurations[model];
    // Convert photo to base64 format, required by Filesystem API to save
    //const base64Data = await this.readAsBase64(photo);

    Filesystem.writeFile({
      path: `${config.model}/${identifier}/${folder}/${name}.${photo.format}`,
      data: photo.dataUrl,
      directory: Directory.Documents,
    }).then(
      () => {
        if (!config.ionicStorageContent[identifier][folder]) config.ionicStorageContent[identifier][folder] = {};
        const newContent = config.ionicStorageContent;
        newContent[identifier][folder][name] = {
          name: name,
          filename: name + '.' + photo.format,
          format: photo.format,
          synced: false,
          dataLastModified: new Date().toJSON(),
        };
        this.configurations[model].ionicStorageContent = newContent;
        this.ionicStorage.set(config.model, this.configurations[model].ionicStorageContent).then(
          () => {
            //done
          },
          (e) => {
            let veriskLog = new VeriskLog(
              'IonicStorage set ERROR',
              'ERROR',
              this.savePhoto.name,
              S3SyncService.name,
              e
            );
            this.utilService.addLog(veriskLog);
          }
        );
      },
      (e) => {
        let veriskLog = new VeriskLog('Filesystem write ERROR', 'ERROR', this.savePhoto.name, S3SyncService.name, e);
        this.utilService.addLog(veriskLog);
      }
    );
  }

  async savePhotoToRemote(photo: any, path: string, format: string) {
    if (!photo.dataUrl) {
      photo.dataUrl = 'data:image/jpeg;base64,' + photo.data;
    }
    return AmplifyStorage.put(path, this.utilService.dataURItoBlob(photo.dataUrl), {
      useAccelerateEndpoint: true,
      contentType: `image/${photo.format}`,
      level: 'public',
    }).catch((e) => {
      let veriskLog = new VeriskLog(
        'AmplifyStorage.put ERROR',
        'ERROR',
        this.savePhotoToRemote.name,
        S3SyncService.name,
        e
      );
      this.utilService.addLog(veriskLog);
    });
  }

  async removeRemotePhoto(path: string) {
    return AmplifyStorage.remove(path)
      .then(() => {
        //done
      })
      .catch((e: any) => {
        let veriskLog = new VeriskLog(
          'AmplifyStorage.remove ERROR',
          'ERROR',
          this.removeRemotePhoto.name,
          S3SyncService.name,
          e
        );
        this.utilService.addLog(veriskLog);
      });
  }

  removeAsset(model: string, identifier: string, folder: string, fileName: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      const config = this.configurations[model];
      try {
        for (let key in config.ionicStorageContent[identifier][folder]) {
          if (key && config.ionicStorageContent[identifier][folder][key].name === fileName) {
            config.ionicStorageContent[identifier][folder][key]['scheduledForDelete'] = true;
            this.ionicStorage.set(config.model, config.ionicStorageContent);
            break;
          }
        }
        await this.addToSyncQueue(model, identifier, true);
        resolve(true);
      } catch (e) {
        let veriskLog = new VeriskLog('ScheduleForDelete ERROR', 'ERROR', this.removeAsset.name, S3SyncService.name, e);
        this.utilService.addLog(veriskLog);
        reject(e);
      }
    });
  }

  deleteLocalAsset(localFilePath: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const rfo: any = {};
        rfo.directory = Directory.Documents;
        rfo.path = localFilePath;
        await Filesystem.deleteFile(rfo);
        resolve(true);
      } catch (e) {
        let veriskLog = new VeriskLog(
          'Filesystem deleteFile ERROR',
          'ERROR',
          this.deleteLocalAsset.name,
          S3SyncService.name,
          e
        );
        this.utilService.addLog(veriskLog);
        reject(e);
      }
    });
  }

  async loadlocalData(model: string, identifier: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let config = this.configurations[model];
      const rfo: any = {};
      rfo.directory = Directory.Documents;
      rfo.path = config.model + '/' + identifier + '/' + identifier + '.json';
      rfo.encoding = Encoding.UTF8;
      Filesystem.readFile(rfo)
        .then((file: ReadFileResult) => {
          let data = JSON.parse(file.data);
          resolve(data);
        })
        .catch((e) => {
          if (config.ionicStorageContent[identifier]) {
            config.ionicStorageContent[identifier].dataLastModified = null;
            this.ionicStorage.set(config.model, config.ionicStorageContent);
          }
          let veriskLog = new VeriskLog(
            'Local File  Missing ERROR',
            'ERROR',
            this.loadlocalData.name,
            S3SyncService.name,
            e
          );
          this.utilService.addLog(veriskLog);
          reject(S3SyncService.LOCAL_FILE_MISSING);
        });
    });
  }

  async loadLocalDataAsync(model: string, amplifyItem: any) {
    let configModel;
    let config;
    const identifier = amplifyItem.rptId;
    const riskId = amplifyItem.riskId;

    // WARNING: Configurations can be empty on first time
    if (this.configurations && this.configurations[model]) {
      config = this.configurations[model];
      configModel = config.model;
    } else {
      configModel = 'RiskReport';
      this.configurations[configModel] = s3SyncStrategy[configModel];
      config = this.configurations[model];
      let veriskLog = new VeriskLog(
        'Missing Config ERROR',
        'ERROR',
        this.loadLocalDataAsync.name,
        S3SyncService.name,
        config
      );
      this.utilService.addLog(veriskLog);
    }

    const rfo: any = {};
    rfo.path = `${Directory.Documents}/${configModel}/${identifier}/${identifier}.json`;
    rfo.encoding = Encoding.UTF8;
    let data = undefined;

    try {
      const file: ReadFileResult = await Filesystem.readFile(rfo);
      data = JSON.parse(file.data);
    } catch (e) {
      config.ionicStorageContent[identifier] = { dataLastModified: null };
      this.ionicStorage.set(configModel, config.ionicStorageContent);
      let veriskLog = new VeriskLog(
        `${configModel} file missing on disk`,
        'ERROR',
        this.loadLocalDataAsync.name,
        S3SyncService.name,
        e
      );
      this.utilService.addLog(veriskLog);
    }
    return data;
  }

  async updateLocalRiskReport(data: any, amplifyItem: any) {
    const config = this.configurations['RiskReport'];
    if (config) {
      const LastModified: Date = new Date(amplifyItem.s3UpdatedDate);
      config.ionicStorageContent[amplifyItem.rptId] = {
        dataLastModified: LastModified, // .toJSON(),
        synced: true,
      };
      this.ionicStorage.set(config.model, config.ionicStorageContent).catch((e) => {
        let veriskLog = new VeriskLog(
          'IonicStorage set error',
          'ERROR',
          this.updateLocalRiskReport.name,
          S3SyncService.name,
          e
        );
        this.utilService.addLog(veriskLog);
      });
      config.amplifyData.push(amplifyItem);
      //SAVE THE DATA LOCALY
      await this.updateLocalCopy(data, 'RiskReport', false);
    }
  }

  loadAssets(model: string, identifier: string, folder: string): Promise<any> {
    return new Promise<string[]>((resolve) => {
      const rfo: any = {};
      (rfo.directory = Directory.Documents), (rfo.path = `${model}/${identifier}/${folder}`);
      rfo.encoding = Encoding.UTF8;
      Filesystem.readdir(rfo).then(
        (dir: any) => {
          const f: string[] = [];
          dir.files.forEach((file) => {
            f.push(`${Directory.Documents}/${model}/${identifier}/${folder}/${file.name}`);
          });
          resolve(f);
        },
        (e) => {
          let veriskLog = new VeriskLog(
            'Filesystem ReadDir error',
            'ERROR',
            this.loadAssets.name,
            S3SyncService.name,
            e
          );
          this.utilService.addLog(veriskLog);
        }
      );
    });
  }

  loadSingleAsset(path: string) {
    return new Promise<ReadFileResult>((resolve, reject) => {
      const rfo: any = {};
      (rfo.directory = Directory.Documents), (rfo.path = path);
      Filesystem.readFile(rfo).then(
        (file: any) => {
          resolve(file);
        },
        (e) => {
          let veriskLog = new VeriskLog(
            'Filesystem readFile error',
            'ERROR',
            this.loadSingleAsset.name,
            S3SyncService.name,
            e
          );
          this.utilService.addLog(veriskLog);
          reject(e);
        }
      );
    });
  }

  checkAsset(model: string, folder: string, identifier: string, photoIdentifier: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      const path: string = `${model}/${identifier}/${folder}/${photoIdentifier}.jpeg`;
      try {
        await this.loadSingleAsset(path);
        resolve(true);
      } catch (e) {
        let veriskLog = new VeriskLog('check asset failed', 'ERROR', this.checkAsset.name, S3SyncService.name, e);
        this.utilService.addLog(veriskLog);
        reject(e);
      }
    });
  }

  syncMissingAsset(model: string, folder: string, identifier: string, photoIdentifier: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const config = this.configurations[model];
      const fileName = photoIdentifier + '.jpeg';
      const fileKey = photoIdentifier;
      // FIX: Key "Photos" may not exist at this point in ionic storage... Create it
      if (config.ionicStorageContent[identifier] && !config.ionicStorageContent[identifier].Photos) {
        config.ionicStorageContent[identifier].Photos = {};
      }
      config.ionicStorageContent[identifier].Photos[fileKey] = { synced: false };
      this.ionicStorage.set(config.model, config.ionicStorageContent);
      const remotePath: string = `isurvey/${model.toLowerCase()}/${identifier}/${folder}/${fileName}`;
      AmplifyStorage.get(remotePath, { download: true, cacheControl: 'no-cache' }).then(
        (storageResult) => {
          new Response(storageResult.Body).text().then(
            async () => {
              const b64: any = await this.utilService.convertBlobToBase64(storageResult.Body as Blob);
              Filesystem.writeFile({
                path: `${config.model}/${identifier}/${folder}/${fileName}`,
                data: b64,
                directory: Directory.Documents,
              }).then(
                () => {
                  config.ionicStorageContent[identifier].Photos[fileKey] = {
                    name: fileName,
                    synced: true,
                    dataLastModified: new Date().toJSON(),
                  };
                  this.ionicStorage.set(config.model, config.ionicStorageContent);
                  resolve(true);
                },
                (e) => {
                  let veriskLog = new VeriskLog(
                    'Filesystem write error',
                    'ERROR',
                    this.syncMissingAsset.name,
                    S3SyncService.name,
                    e
                  );
                  this.utilService.addLog(veriskLog);
                  reject('syncAssetsFromRemote - LOCAL SAVE FAILED [' + fileName + '] [' + e.message + ']');
                }
              );
            },
            (e) => {
              let veriskLog = new VeriskLog(
                'Read Blob error',
                'ERROR',
                this.syncMissingAsset.name,
                S3SyncService.name,
                e
              );
              this.utilService.addLog(veriskLog);
            }
          );
        },
        (error) => {
          let veriskLog = new VeriskLog(
            'AmplifyStorage get error',
            'ERROR',
            this.syncMissingAsset.name,
            S3SyncService.name,
            error
          );
          this.utilService.addLog(veriskLog);
          if (error.name === 'NoSuchKey') {
            reject(`Document not found in s3!`);
          }
        }
      );
    });
  }

  async clearSyncData(): Promise<boolean> {
    return new Promise<boolean>(async () => {
      await this.ionicStorage.create();
      await this.ionicStorage.set('SYNC_QUEUE', {});
      await this.ionicStorage.set('DELETE_QUEUE', {});
      for (let key in this.configurations) {
        if (key) {
          await this.ionicStorage.set(key, {});
          await this.clearLocalDataDirectory(key);
        }
      }
    });
  }

  clearLocalDataDirectory(model: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      const rfo: any = {};
      rfo.directory = Directory.Documents;
      rfo.path = `${model}`;
      rfo.recursive = true;
      await Filesystem.rmdir(rfo).catch((e) => {
        let veriskLog = new VeriskLog(
          'Error clearing local data directory',
          'ERROR',
          this.clearLocalDataDirectory.name,
          S3SyncService.name,
          e
        );
        this.utilService.addLog(veriskLog);
      });
      resolve(true);
    });
  }

  saveNewItem(model: string, s3Data: any): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      // const config = this.configurations[model];
      // WARNING: Configurations can be empty on first time
      let config: s3SyncConfig;
      if (this.configurations && this.configurations[model]) {
        config = this.configurations[model];
      } else {
        // TODO: Remove this. It should not happen
        this.configurations[model] = s3SyncStrategy[model];
        config = this.configurations[model];
      }
      const view: any = JSON.parse(s3Data.data);
      const ampItems = await this.getAmplifyItems(config.model);
      config.amplifyData = ampItems;
      await this.createSingleDirectory(config, view[config.s3IdentifierField]);
      config.ionicStorageContent[view[config.s3IdentifierField]] = {
        dataLastModified: s3Data.metadata.LastModified,
        synced: true,
      };
      this.ionicStorage.set(config.model, config.ionicStorageContent).then(
        () => {
          //done
        },
        (e) => {
          let veriskLog = new VeriskLog(
            'IonicStorage save error',
            'ERROR',
            this.saveNewItem.name,
            S3SyncService.name,
            e
          );
          this.utilService.addLog(veriskLog);
        }
      );

      //SAVE THE DATA LOCALY

      this.updateLocalCopy(view, config.model, true).then(
        () => {
          this.syncAssetsFromRemote(config, view[config.s3IdentifierField]).then(() => resolve(true));
          resolve(true);
        },
        (e) => {
          let veriskLog = new VeriskLog(
            'Update local copy error',
            'ERROR',
            this.saveNewItem.name,
            S3SyncService.name,
            e
          );
          this.utilService.addLog(veriskLog);
          reject(e);
        }
      );
    });
  }
  createSingleDirectory(config: s3SyncConfig, identifier: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      try {
        await Filesystem.stat({
          directory: Directory.Documents,
          path: `${config.model}/${identifier}`,
        }).then(() => {
          resolve(true);
        });
      } catch (e) {
        let veriskLog = new VeriskLog(
          'Filesystem stat failed',
          'ERROR',
          this.createSingleDirectory.name,
          S3SyncService.name,
          e
        );
        this.utilService.addLog(veriskLog);
        Filesystem.mkdir({
          directory: Directory.Documents,
          path: `${config.model}/${identifier}`,
          recursive: true,
        }).then(
          () => {
            config.s3AssetFolders.forEach((folder: string) => {
              Filesystem.mkdir({
                directory: Directory.Documents,
                path: `${config.model}/${identifier}/${folder}`,
                recursive: false,
              });
            });
            resolve(true);
          },
          (e) => {
            let veriskLog = new VeriskLog(
              'Filesystem mkdir failed',
              'ERROR',
              this.createSingleDirectory.name,
              S3SyncService.name,
              e
            );
            this.utilService.addLog(veriskLog);
          }
        );
      }
    });
  }
  async saveLocalFile(filepath: string, data: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const clientPath: any = {};
      clientPath.directory = Directory.Documents;
      clientPath.path = filepath;
      clientPath.encoding = Encoding.UTF8;

      Filesystem.writeFile({
        path: clientPath.path,
        encoding: Encoding.UTF8,
        data: data,
        directory: Directory.Documents,
      }).then(
        () => {
          resolve(data);
        },
        (e) => {
          reject('saveLocalFile - LOCAL SAVE FAILED [' + e.message + ']');
        }
      );
    });
  }
//Todo: This is duplicate method
  async checkFileExists(getUriOptions: GetUriOptions): Promise<boolean> {
    try {
      await Filesystem.stat(getUriOptions);
      return true;
    } catch (checkDirException) {
      return false;
    }
  }

  /**
   * retreiveFile: retrieve the file from S3
   * @param serverpath
   * @param clientpath
   * @returns promise
   */
  async retreiveFile(serverpath: string, filepath: string, filetype: string, folder: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const serverPath = serverpath;
      const clientPath: any = {};
      clientPath.directory = Directory.Documents;
      clientPath.path = filepath;
      if (filetype == 'json') {
        clientPath.encoding = Encoding.UTF8;
      }

      let getUriOptions: GetUriOptions = {
        path: filepath,
        directory: Directory.Documents,
      };

      if (await this.checkFileExists(getUriOptions)) {
        Filesystem.readFile(clientPath).then(
          (file: ReadFileResult) => {
            resolve(file.data);
          },
          () => {
            reject(S3SyncService.LOCAL_FILE_MISSING);
          }
        );
      } else {
        if (this.hasNetWorkConnection) {
          try {
            // To check for existence of a file
            let url = await AmplifyStorage.get(serverPath, { validateObjectExistence: true });
            AmplifyStorage.get(serverPath, { download: true, cacheControl: 'no-cache' }).then(
              (storageResult) => {
                new Response(storageResult.Body).text().then(async (data) => {
                  this.checkAndCreateDir(folder).then(async (_) => {
                    const rfo: any = {};
                    rfo.directory = Directory.Documents;
                    rfo.path = filepath;
                    rfo.recursive = true;
                    if (filetype == 'json') {
                      rfo.encoding = Encoding.UTF8;
                      rfo.data = data;
                    } else {
                      rfo.data = await this.utilService.convertBlobToBase64(storageResult.Body as Blob);
                    }

                    Filesystem.writeFile(rfo).then(
                      () => {
                        resolve(data);
                      },
                      (e) => {
                        reject('syncAssetsFromRemote - LOCAL SAVE FAILED [' + clientPath + '] [' + e.message + ']');
                      }
                    );
                  });
                });
              },
              (error) => {
                if (error.name === 'NoSuchKey') {
                  reject(`Document /${serverPath}.json not found in s3!`);
                } else {
                  reject(error);
                }
              }
            );
          } catch (err) {
            reject(err);
          }
        } else {
          return reject('NoNetwork');
        }
      }
    });
  }

  /**
   * checkAndCreateDir - used to create a directory if not exists
   * @param path
   * @returns
   */
  checkAndCreateDir(path: string): Promise<boolean> {
    return Filesystem.readdir({
      path: path,
      directory: Directory.Documents,
    })
      .then((_) => {
        return true;
      })
      .catch((_) => {
        return Filesystem.mkdir({
          path: path,
          directory: Directory.Documents,
          recursive: true,
        }).then((_) => {
          return true;
        });
      });
  }

  async copyFilesInFolder(
    sourceFolderPath: string,
    sourceFilePath: string,
    sourceRemoteFilePath: string,
    destinationFolderPath: string,
    destinationFilePath: string,
    maxRetries: number = 2
  ): Promise<boolean> {
    let attempt = 1;
    while (attempt <= maxRetries) {
      try {
        // Check if the destination directory exists
        try {
          await Filesystem.stat({
            path: destinationFolderPath,
            directory: Directory.Documents,
          });
        } catch (error) {
          // If the directory does not exist, create it
          await Filesystem.mkdir({
            path: destinationFolderPath,
            directory: Directory.Documents,
            recursive: true,
          });
        }
  
        // Check if the file exists in the source path
        try {
          await Filesystem.stat({
            path: sourceFilePath,
            directory: Directory.Documents,
          });
        } catch (error) {
          // If the file does not exist, download it from S3
          const remotepath = `${sourceRemoteFilePath}`;
          await this.retreiveFile(remotepath, sourceFilePath, '', sourceFolderPath);
        }
  
        // Copy the file to the destination folder
        await Filesystem.copy({
          from: sourceFilePath,
          to: destinationFilePath,
          directory: Directory.Documents,
          toDirectory: Directory.Documents,
        });
  
        console.log('File copied successfully');
        return true; // Return true indicating success
      } catch (error) {
        console.error(`Error copying file (attempt ${attempt} of ${maxRetries}):`, error);
        attempt++;
  
        // If it's the last attempt, return false indicating failure
        if (attempt > maxRetries) {
          return false;
        }
      }
    }
  }
  
}
