import { Observable, Subject } from 'rxjs';
import { CalculationStatusInterface } from 'src/app/interfaces/formly-builder/calculation-status.interface';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export abstract class SectionServiceAbstract {
    protected ngUnsubscribe = new Subject<void>();

    clean(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    initSubscription() {
        this.ngUnsubscribe = new Subject<void>();
    }

    /**
     * Updates the data to the persistence layer
     *
     * @param model existing model
     * @param riskReportData Risk Report full data
     * @return updated Risk Report data
     */
    // abstract updateData(riskReportData: any): any;
    updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum): any {
        return undefined;
    }

    /**
     * Some sections will be present in a form only if some specific logic
     * checks that this is true. This method will determine if a section is
     * present.
     *
     * @param sectionData
     * @returns true if section service logic determines that it will be present
     */
    isSectionPresent(sectionData: any): boolean {
        return true;            // Default behavior
    }

    /**
     * Some sections will be present in a form only if some specific logic
     * checks that this is true. This method will determine if a section is
     * present.
     *
     * @param formControl
     * @returns no FormControl is returned
     */
    getNonDeletedFields(formControl: any): any {
        return undefined;
    }

    /**
     * Method to implement long running calculations
     * @param formlyBuilder 
     * @returns 
     */
    calculate(formlyBuilder: FormlyBuilderService): Observable<CalculationStatusInterface> {
        return undefined;
    }

    /**
     * Loads the section data from a copy of the RiskReport data retrieved from the persistence layer
     *
     * @param sectionData Section specific data
     * @param riskReportData Risk Report full data
     * @param isDataInS3Format true if RiskReport data is in "S3 format" (different from Amplify format)
     * @param model existing model
     * @return new model
     */
    abstract loadData(sectionData: any[], riskReportData: any, isDataInS3Format: boolean, model: any): any;
}
