import { Injectable } from '@angular/core';
import axios from 'axios';
import aws_exports from '../../../aws-exports';
import { Amplify } from 'aws-amplify';
import { FridService } from 'src/app/services/frid-service/frid.service';
import { AmplifyService } from 'src/app/services/amplify/amplify.service';
import { VeriskLog } from 'src/app/services/util-service/verisk-log';
import { UtilService } from 'src/app/services/util-service/util.service';

@Injectable({
  providedIn: 'root',
})
export class AxiosInterceptorService {
  constructor(
    private frIDService: FridService,
    private amplifyService: AmplifyService,
    private utilService: UtilService
  ) {
    this.setupInterceptors();
  }

  private setupInterceptors(): void {
    let regionSwitchAttempted = false;
    axios.interceptors.response.use(
      async (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        console.log('Axios Interceptor Response');
        regionSwitchAttempted = false; // Reset flag on successful response
        return response;
      },
      async (error) => {
        // Any status codes that fall outside the range of 2xx cause this function to trigger
        // Do something with response error
        console.log('Axios Interceptor Error', error);
        if (
          error.response.data.errors[0].message.trim() == 'Credential should be scoped to a valid region.' &&
          !regionSwitchAttempted
        ) {
          console.log('GraphQL is down.Flipping to the fallback region.');
          let veriskLog = new VeriskLog(
            'East Url is down',
            'INFO',
            this.setupInterceptors.name,
            AxiosInterceptorService.name,
            null,
            true
          );
          this.utilService.addLog(veriskLog);
          if (aws_exports.aws_appsync_region == 'us-east-1') {
            aws_exports.aws_appsync_region = 'us-west-2';
            let veriskLog = new VeriskLog(
              'The aws_appsync_region is now set to us-west-2',
              'INFO',
              this.setupInterceptors.name,
              AxiosInterceptorService.name,
              null,
              true
            );
            this.utilService.addLog(veriskLog);
          } else {
            aws_exports.aws_appsync_region = 'us-east-1';
            let veriskLog = new VeriskLog(
              'The aws_appsync_region is now set to us-east-1',
              'INFO',
              this.setupInterceptors.name,
              AxiosInterceptorService.name,
              null,
              true
            );
            this.utilService.addLog(veriskLog);
          }

          // Configure Amplify with updated region
          Amplify.configure(aws_exports);

          let frId: string = await this.frIDService.getToken();
          await this.amplifyService.DataStoreInit(frId);
          regionSwitchAttempted = true; // Set flag to indicate a switch was attempted
        }
        return Promise.reject(error);
      }
    );
  }
}
