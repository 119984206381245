/* eslint-disable @typescript-eslint/naming-convention */
import { Guid } from 'guid-typescript';


/**
 * Class that represents the Formly Model for Wind Env Exposure Model
 */
export class WindEnvExposureDataModel {
  static sectionName = 'windReport';

  //Evaluation of Environment
  environment: Environment;

  //Evaluation of Exposures
  exposures: Exposure;

  reportId: string;

  // TODO: Rename to read
  static fromS3(windEnvExposureData: any): WindEnvExposureDataModel {

    const environment = WindEnvExposureDataModel.setEnvironment(windEnvExposureData);
    const exposure = WindEnvExposureDataModel.setExposure(windEnvExposureData);

    return {
      reportId: windEnvExposureData?.ReportId,
      exposures: exposure,
      environment
    };
  }

  static to(model: any, reportId?: Guid) {
    return {
      ReportId: model?.reportId ? model?.reportId : reportId,
      HasSurroundingBuildingsWithRoofGravel: model?.exposures?.hasSurroundingBuildingsWithRoofGravel !== null ?
        model?.exposures?.hasSurroundingBuildingsWithRoofGravel : null,
      HasSurroundingBuildingsWithDebrisPotential: model?.exposures?.hasSurroundingBuildingsWithDebrisPotential!== null  ?
        model?.exposures?.hasSurroundingBuildingsWithDebrisPotential : null,
      IsLowerRoofWithGravel1InchOrSmaller: model?.exposures?.isLowerRoofWithGravel1InchOrSmaller !== null  ?
        model?.exposures?.isLowerRoofWithGravel1InchOrSmaller : null ,
      YardPropertyExposure: model?.environment?.yardPropertyExposure ? model?.environment?.yardPropertyExposure : '',
      ProximityToCoastLine: model?.environment?.proximityToCoastLine ? model?.environment?.proximityToCoastLine : '',
      SurroundingTerrain: model?.environment?.surroundingTerrain ? model?.environment?.surroundingTerrain : '',
      SurroundingBuildingsWithRoofGravelComment: model?.exposures?.surroundingBuildingsWithRoofGravelComment ?
        model?.exposures?.surroundingBuildingsWithRoofGravelComment : '',
      SurroundingBuildingsWithDebrisPotentialComment: model?.exposures?.surroundingBuildingsWithDebrisPotentialComment ?
        model?.exposures?.surroundingBuildingsWithDebrisPotentialComment : '',
      LowerRoofWithGravel1InchOrSmallerComment: model?.exposures?.lowerRoofWithGravel1InchOrSmallerComment ?
        model?.exposures?.lowerRoofWithGravel1InchOrSmallerComment : '',
      YardPropertyExposureComment: model?.environment?.yardPropertyExposureComment ? model?.environment?.yardPropertyExposureComment : '',
    };
  }

  static setEnvironment(windEnvExposureData: any) {
    const environment = {
      proximityToCoastLine: windEnvExposureData?.ProximityToCoastLine,
      surroundingTerrain: windEnvExposureData?.SurroundingTerrain,
      yardPropertyExposure: windEnvExposureData?.YardPropertyExposure,
      yardPropertyExposureComment: windEnvExposureData?.YardPropertyExposureComment,
    };
    return environment;
  }

  static setExposure(windEnvExposureData: any) {
    const exposure = {
      hasSurroundingBuildingsWithRoofGravel: windEnvExposureData?.HasSurroundingBuildingsWithRoofGravel,
      surroundingBuildingsWithRoofGravelComment: windEnvExposureData?.SurroundingBuildingsWithRoofGravelComment,
      hasSurroundingBuildingsWithDebrisPotential: windEnvExposureData?.HasSurroundingBuildingsWithDebrisPotential,
      surroundingBuildingsWithDebrisPotentialComment: windEnvExposureData?.SurroundingBuildingsWithDebrisPotentialComment,
      isLowerRoofWithGravel1InchOrSmaller: windEnvExposureData?.IsLowerRoofWithGravel1InchOrSmaller,
      lowerRoofWithGravel1InchOrSmallerComment: windEnvExposureData?.LowerRoofWithGravel1InchOrSmallerComment,
    };
    return exposure;
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param windEnvExposureReportModel Model from the S3 Risk Report
   * @returns
   */
  //TODO change name and values for windReport
  static hasChanged(formlyModel: any, windEnvExposureReportModel: any): boolean {
    return true;
  }
}

class Environment {
  proximityToCoastLine: string;
  surroundingTerrain: string;
  yardPropertyExposure: string;
  yardPropertyExposureComment: string;
}

class Exposure {
  hasSurroundingBuildingsWithRoofGravel: boolean;
  surroundingBuildingsWithRoofGravelComment: string;
  hasSurroundingBuildingsWithDebrisPotential: boolean;
  surroundingBuildingsWithDebrisPotentialComment: string;
  isLowerRoofWithGravel1InchOrSmaller: boolean;
  lowerRoofWithGravel1InchOrSmallerComment: string;
}
