/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { SectionEnum } from '../../section.config';
import { UtilService } from 'src/app/services/util-service/util.service';
import { OccupantsHazardsDataModel } from 'src/app/models/formly-builder/formly/full-risk/occupant-hazards/occupant-hazards.data.model';
import { ScheduleCodeService } from '../../schedule-tree.service';
import { FormlyBuilderService } from '../../formly-builder.service';
import { takeUntil } from 'rxjs/operators';
import { OccupantsSectionDataModel } from 'src/app/models/formly-builder/formly/occupants-section-data.model';
import { BuildingHazardsDataModel } from 'src/app/models/formly-builder/formly/full-risk/occupant-hazards/building-hazards-data.model';
import { HookReuseableService } from '../../hook-reuseable.service';
import { FormlyOccupantsService } from '../../formly-occupants.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FullRiskGeneralService } from '../full-risk-general.service';
import { ModalController, ToastController } from '@ionic/angular';
import { Constants } from 'src/app/services/util-service/constants';
import { FireSprinklerService } from '../../overview/fire-sprinkler.service';
import { FormlyErrorService } from '../../formly-error.service';
import { filteringHazardScheduleNo, filteringOccupantScheduleNo } from 'src/app/validators/formly-builder/occupancy-hazards-validator/occupant-hazard-sno-validator/occupant-hazard-sno-validator';
import { fi } from 'date-fns/locale';
import { RiskReportService } from '../../risk-report.service';
@Injectable({
  providedIn: 'root'
})
export class OccupantHazardsService extends SectionServiceAbstract {

  constructor(private util: UtilService,
    private scheduleService: ScheduleCodeService,
    private toastController: ToastController,
    private fireSprinklerService: FireSprinklerService,
    private formlyErrorService: FormlyErrorService,
    private fullRiskGeneralService: FullRiskGeneralService,
    private riskReportService: RiskReportService,
  private formlyBuilderService:FormlyBuilderService,
  private occupantServ:FormlyOccupantsService) { super(); }

  async updateData(model: any, riskReportData: any): Promise<any> {
    // Check if data has changed
    const hasChanged: boolean = OccupantsSectionDataModel.hasChanged(model, riskReportData);
    // Check occupant-hazards data
    // Check occupants data
    // Check level data for every occupant
    if (hasChanged) {
      const occupants = OccupantsSectionDataModel.saveDatatoS3(model.occupants['section-occupants']);
      return { ...riskReportData, ...occupants };
    } else {
      return undefined;
    }
  }

  loadData(occupant: any[], fullData: any, isS3: boolean, model: any): any {
    return undefined;
  }

  getMaxLevel(allLevels: any[], floorName: string): number {
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    return Math.max(
      ...allLevels.map(function (level) {
        if (level.name === floorName && level.levelStart) {
          return Math.max(level.levelStart, level.levelStop);
        }
        return 0;
      })
    );
  }

  public getOccupantLevels(occupantsArray) {
    const occupantLevels = [];
    if(occupantsArray && occupantsArray?.length) {
      occupantsArray.forEach((occupant) => {
        if(!occupant.isDeleted){
          if(occupant?.occupantLevels?.length) {
            occupant.occupantLevels.forEach((level) => {
              occupantLevels.push(level);
            });
          }          
        }
       
      });
    }   
    return occupantLevels;
  }

  loadOccupantsData(occupant: any[], fullData: any, isS3: boolean, model: any): any {
    const occupantsModel: OccupantsHazardsDataModel[] = [];
    const occupants = fullData.Occupants;
    let totalArea = 0;
    let aboveGradeFloors = 0;
    let basementLevels = 0;
    const buildingHazardsModel: BuildingHazardsDataModel = BuildingHazardsDataModel.fromS3(fullData.ReportHazards);
    if (!occupants) {
      console.debug('Unexpected empty occupant list\n' + fullData);
      return null;
    }

    if (occupants?.length === 0) {
      // const newModel = { ...model, ...{ occupants: occupantsModel } };
      // For now add occupants to section-occupants as well
      const newModel = {
        'section-occupants': {
          // eslint-disable-next-line object-shorthand
          aboveGradeFloors: aboveGradeFloors,
          // eslint-disable-next-line object-shorthand
          basementLevels: basementLevels,
          // eslint-disable-next-line object-shorthand
          totalArea: totalArea,
          occupants: [...occupantsModel],
        },
      };
      return newModel;
    }

    occupants?.forEach((occupant) => {
      let occupantData;
      if (isS3) {
        occupantData = OccupantsHazardsDataModel.fromS3(occupant);
        //TODO:for csp 2805
         
         
        const stateCode = fullData.ReportAddresses[0].StateCode;
        const occScheduleNo = occupant.ScheduleNumber;
        
          if (stateCode === 'MA' && occScheduleNo === '6104110000') {
            occupantData.commercialStatisticalPlan ="2805"
            occupantData.isCommercialStatisticalPlanOverride = true;
          }
          
        
        occupantsModel.push(occupantData);
      } else {
        const occupantRecord = occupant.data;
        // occupantData = OccupantsHazardsDataModel.from(occupantRecord);
        occupantsModel.push(occupantData);
      }
      const scheduleDetails: any = this.scheduleService.getScheduledetails(occupantData.occupantScheduleNo);
      if (scheduleDetails) {
        occupantData.occupantType = scheduleDetails.OccupantType;
       // occupantData.commercialStatisticalPlan = scheduleDetails.CSP;
      }
      if (!occupant.IsDeleted) {
        totalArea += occupantData.totalArea;
        const occupantAboveGradeFloors = this.getMaxLevel(occupantData.occupantLevels, 'Floor');
        if (occupantAboveGradeFloors > aboveGradeFloors) {
          aboveGradeFloors = occupantAboveGradeFloors;
        }
        const occupantBasementLevels = this.getMaxLevel(occupantData.occupantLevels, 'Basement');
        if (occupantBasementLevels > basementLevels) {
          basementLevels = occupantBasementLevels;
        }
      }
    });


    occupantsModel?.forEach((occupantLevel) => {
      if (occupantLevel.occupantLevels.length) {
        occupantLevel.occupantLevels.sort((a, b) => Number(a.levelStart) - Number(b.levelStart));
      }
      // console.log("OccupantsService "+occupantsModel);
    });
    const occupantLevels = this.getOccupantLevels(occupantsModel);
    if (occupants?.length) {
      // const newModel = { ...model, ...{ occupants: occupantsModel } };

      // For now add occupants to section-occupants as well
      const newModel = {
        'section-occupants': {
          // eslint-disable-next-line object-shorthand
          aboveGradeFloors: aboveGradeFloors,
          // eslint-disable-next-line object-shorthand
          basementLevels: basementLevels,
          // eslint-disable-next-line object-shorthand
          totalArea: this.calculateTotalArea(occupantsModel),
          occupants: [...occupantsModel],
        },
      };
      return newModel;
    }
    return null;
  }

  calculateFlrsRofsTotalMez(field) {
    const formValue = field.form?.root?.value;
    const floorsAndRoofsLevels = formValue?.floorsAndRoofs?.constructionEntries?.floorsAndRoofsLevels ?? [];
    // const flrAndRofArray = [];
    const mezzanineArray = [];
    const floorsPerLevelAreaArray = [];
    if (floorsAndRoofsLevels && floorsAndRoofsLevels.length > 0) {
      floorsAndRoofsLevels?.forEach(level => {
        if (level?.levelStop > level?.levelStart) {
          for (let i = Number(level?.levelStart); i <= Number(level?.levelStop); i++) {
            if (level?.levelTypeCode === 'MEZZ') {
              mezzanineArray?.push({
                name: 'Mezzanine',
                level: i,
                area: isNaN(level?.width) || isNaN(level?.length) ? 0 : level?.length * level?.width,
              });
            }
            if (level?.levelTypeCode === 'FLOO') {
              floorsPerLevelAreaArray?.push({
                name: 'Floor',
                level: i,
                area: isNaN(level?.width) || isNaN(level?.length) ? 0 : level?.length * level?.width,
              }); // filtering floors levels
            }
          }
        } else {
          if (level?.levelTypeCode === 'MEZZ') {
            // filtering mezzanine levels
            mezzanineArray?.push({
              name: 'Mezzanine',
              level: Number(level?.levelStart),
              area: isNaN(level?.width) || isNaN(level?.length) ? 0 : level?.length * level?.width,
            });
          }
          if (level?.levelTypeCode === 'FLOO') {
            // sum of area of all levels(floor,basement,attic) except mezzanine
            floorsPerLevelAreaArray?.push({
              name: 'Floor',
              level: Number(level?.levelStart),
              area: isNaN(level?.width) || isNaN(level?.length) ? 0 : level?.length * level?.width,
            });
          }
        }
      });
      if (mezzanineArray?.length > 0) {
        return this?.calculateMezzanineLevel(mezzanineArray, floorsPerLevelAreaArray);
      }
      else {
        return [];
      }
    }
  }

  public calculateTotalMez(model) {
    const occupantsLevelArray = []; // an array of all levels from all occupants
    const floorsPerLevelAreaArray = []; // an array of area per level for all floors from all occupants
    let levels = [];
    let totalArea = 0;
    // filters levels from non deleted occupants
    model.forEach((occupant) => {
      if (!occupant.isDeleted) {
        occupant?.occupantLevels?.forEach((level) => {
          occupantsLevelArray.push(level);
        });
      }
    });
    const mezzanineArray = [];
    occupantsLevelArray.forEach((level) => {
      if (level?.levelStop > level?.levelStart) {
        for (let i = Number(level?.levelStart); i <= Number(level?.levelStop); i++) {
          if (level?.name === 'Mezzanine') {
            mezzanineArray?.push({
              name: level?.name,
              level: i,
              area: isNaN(level?.width) || isNaN(level?.length) ? 0 : level?.length * level?.width,
            }); // filtering mezzanine levels
          } else {
            totalArea += isNaN(level?.width) || isNaN(level?.length) ? 0 : level?.length * level?.width; // sum of area of all levels(floor,basement,attic) except mezzanine
          }
          if (level?.name === 'Floor') {
            floorsPerLevelAreaArray?.push({
              name: level?.name,
              level: i,
              area: isNaN(level?.width) || isNaN(level?.length) ? 0 : level?.length * level?.width,
            }); // filtering floors levels
          }
        }
      } else {
        if (level?.name === 'Mezzanine') {
          // filtering mezzanine levels
          mezzanineArray?.push({
            name: level?.name,
            level: Number(level?.levelStart),
            area: isNaN(level?.width) || isNaN(level?.length) ? 0 : level?.length * level?.width,
          });
        } else {
          totalArea += isNaN(level?.width) || isNaN(level?.length) ? 0 : level?.length * level?.width;
          // sum of area of all levels(floor,basement,attic) except mezzanine
        }
        if (level?.name === 'Floor') {
          // sum of area of all levels(floor,basement,attic) except mezzanine
          floorsPerLevelAreaArray?.push({
            name: level?.name,
            level: Number(level?.levelStart),
            area: isNaN(level?.width) || isNaN(level?.length) ? 0 : level?.length * level?.width,
          });
        }
      }
    });

    if (mezzanineArray?.length > 0) {
      levels = this?.calculateMezzanineLevel(mezzanineArray, floorsPerLevelAreaArray);
      return levels;
    }
    else {
      return levels;
    }
    // return totalArea;
  }

  public calculateMezzanineLevel(mezzanineArray, floorsPerLevelAreaArray) {
    let flag;
    let summarizedMezzArray = [];
    let levels = [];
    if (mezzanineArray?.length > 1) {
      mezzanineArray?.forEach((item) => {
        const obj = summarizedMezzArray?.find((o) => o?.level === item?.level);
        if (obj) {
          obj.area = obj?.area + item?.area;
        } else {
          summarizedMezzArray?.push(item);
        }
      });
    } else {
      summarizedMezzArray = mezzanineArray;
    }

    summarizedMezzArray?.forEach((mezzLevel) => {
      let totalFloorArea = 0;
      floorsPerLevelAreaArray?.filter((eachLevel) => {
        if (eachLevel?.level === mezzLevel?.level) {
          totalFloorArea += eachLevel?.area;
        }
      });
      flag = (mezzLevel?.area / totalFloorArea) * 100 >= 25 ? true : false;
      if (flag) {
        // totalMezzanineArea += mezzLevel?.area;
        levels?.push(mezzLevel?.level);
      }
    });
    return levels;
  }

  public calculateAreaPerLevel(field) {
    const floorLengthControl = field?.form?.get('length');
    const floorWidthControl = field?.form?.get('width');
    floorLengthControl?.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((updatedLength) => {
      if (updatedLength) {
        setTimeout(() => {
          const updatedArea = updatedLength * field?.form?.get('width')?.value;
          field?.formControl?.setValue(updatedArea);
        }, 0);
      }
    });
    floorWidthControl?.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((updatedWidth) => {
      if (updatedWidth) {
        setTimeout(() => {
          const updatedArea = updatedWidth * field?.form?.get('length')?.value;
          field?.formControl?.setValue(updatedArea);
        }, 0);
      }
    });
  }

  getHazardsIdentifier(hazardsId) {
    return hazardsId === false || hazardsId === undefined || hazardsId === 'undefined' || hazardsId === '' ? this.util.uuid() : hazardsId;
  }

  public getMezzAtticArray(val, field) {
    let combinedArray = [];
    // const floorsRoofArray = field.form.root.controls.floorsAndRoofs.controls.constructionEntries.controls.floorsAndRoofsLevels.value;
    const fieldValue = field.form?.root?.value;
    const floorsRoofArray = fieldValue?.floorsAndRoofs?.constructionEntries?.floorsAndRoofsLevels ?? [];

    const levelsList = floorsRoofArray?.filter(src => src.levelTypeCode === val)?.map(level => {
      if (level && level.levelStop && Number(level.levelStop) > 0) {
        return Array.from(
          { length: (Number(level.levelStop) - Number(level.levelStart)) + 1 },
          (value, index) => Number(level.levelStart) + index);
      } else if (level && level.levelStart && Number(level.levelStart) > 0) {
        return [Number(level.levelStart)];
      } else {
        return [];
      }
    });
    levelsList?.forEach(x => combinedArray = combinedArray.concat(x));
    combinedArray = combinedArray?.filter((item, index) => combinedArray.indexOf(item) === index);
    return combinedArray?.sort();
  }
  
  public getOcccupLevelArray(val, occupantList) {
    let combinedArray = [];
    const levelsList = occupantList?.filter(src => src?.name === val)?.map(level => {
      if (level && level.levelStop && Number(level.levelStop) > 0) {
        return Array.from(
          { length: (Number(level.levelStop) - Number(level.levelStart)) + 1 },
          (value, index) => Number(level.levelStart) + index);
      } else if (level && level.levelStart && Number(level.levelStart) > 0) {
        return [Number(level.levelStart)];
      } else {
        return [];
      }
    });
    levelsList?.forEach(x => combinedArray = combinedArray.concat(x));
    combinedArray = combinedArray?.filter((item, index) => combinedArray.indexOf(item) === index);
    return combinedArray?.sort();
  }

  /**
   * Method for enabling or disabling wind form
   * @param field 
   * @param enablePopup 
   */
  enableDisableWindForm(field, enablePopup) {
    // +-------------------------------------------------------------------+
    // | Disable Wind Report WindReport                                    |
    // +-------------------------------------------------------------------+
    // if (!Constants.isWindReportDisablingEnabled) {
    //   return;
    // }

    const geographicRiskFactor = field?.options?.formState?.service?.riskReport?.report?.GeographicRiskFactor?.trim();
    const occupantList = field?.options?.formState?.service?.riskReport?.model?.occupants['section-occupants'].occupants;
    let totalArea = 0;
    occupantList.forEach(occupant => {
      if (!occupant.isDeleted) {
        occupant.occupantLevels.forEach(level => {
          totalArea += level.area;
        });
      }
    });
    // if(field.options.formState.service.isExistingRisk()){
    if (geographicRiskFactor?.toUpperCase() == 'MEDIUM' && totalArea > 50000) {
      this.enableWindReport(field, enablePopup);
    }
    else if (geographicRiskFactor?.toUpperCase() == 'HIGH' && totalArea > 25000) {
      this.enableWindReport(field, enablePopup);
    }
    else if (geographicRiskFactor?.toUpperCase() == 'SEVERE' && totalArea >= 10000) {
      this.enableWindReport(field, enablePopup);
    }
    else if (geographicRiskFactor?.toUpperCase() == 'LOW') {
      this.disableWindReport(field, enablePopup);
    }
    else {
      this.disableWindReport(field, enablePopup);
    }
    // }
    // else{
    //   this.disableWindReport(field,enablePopup);
    // }
  }

  enableWindReport(field: FormlyFieldConfig, enablePopup) {
    const filterArray=this.checkWindreportDisabled(field);
    this.fullRiskGeneralService.enableMainSection(field, SectionEnum.FR_WIND);
    if(filterArray.length==1){
      enablePopup == "showingPopup" ? this.fireSprinklerService.showToasterMessage(Constants.eligibleWindform) : '';
    }
  }
 
  disableWindReport(field: FormlyFieldConfig, enablePopup) {
    const filterArray=this.checkWindreportDisabled(field);
    this.fullRiskGeneralService.disableMainSection(field, SectionEnum.FR_WIND);
    if(filterArray.length==0){
    enablePopup == "showingPopup"?this.showToaster(Constants.ineligibleWindform):'';
    }
  }

  /**
   * Check if Wind Report is disabled
   * @param field 
   * @returns 
   */
  public checkWindreportDisabled(field){
    const disabledField = field?.parent?.options?.formState?.isMainSectionDisabled;
    const filterArray = disabledField.filter(val => {
    return val.sectionId == SectionEnum.FR_WIND;
    });
    return filterArray;
  }

  /**
     *
     * @description Will present the toaster with given message
     * @param * message - Stirng to be displayed
     */
  async showToaster(message) {
    const toast = await this.toastController.create({
      message,
      position: 'bottom',
      cssClass: ['error-toast toast-xl'],
      buttons: [
        {
          text: '\u2573',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          }
        }
      ],
    });

    await toast.present();

    // To set position to the toaster close button
    const buttonGroup = toast.shadowRoot.querySelector('.toast-button-group') as HTMLElement;
    buttonGroup.style.position = 'sticky';
    buttonGroup.style.top = '0';

  }
  
  /**
    * Get the total area for an array of occupants
    *
    * @param model Model of occupants
    * @returns
    */
  public calculateTotalArea(model) {
    const occupantsLevelArray = []; // an array of all levels from all occupants
    const floorsPerLevelAreaArray = []; // an array of area per level for all floors from all occupants
    let totalArea = 0;
    // filters levels from non deleted occupants
    model.forEach((occupant) => {
      if (!occupant.isDeleted) {
        occupant.occupantLevels.forEach((level) => {
          occupantsLevelArray.push(level);
        });
      }
    });
    const mezzanineArray = [];
    occupantsLevelArray.forEach((level) => {
      if (level.levelStop > level.levelStart) {
        for (let i = Number(level.levelStart); i <= Number(level.levelStop); i++) {
          if (level.name === 'Mezzanine') {
            mezzanineArray.push({
              name: level.name,
              level: i,
              area: isNaN(level.width) || isNaN(level.length) ? 0 : level.length * level.width,
            }); // filtering mezzanine levels
          } else {
            totalArea += isNaN(level.width) || isNaN(level.length) ? 0 : level.length * level.width; // sum of area of all levels(floor,basement,attic) except mezzanine
          }
          if (level.name === 'Floor') {
            floorsPerLevelAreaArray.push({
              name: level.name,
              level: i,
              area: isNaN(level.width) || isNaN(level.length) ? 0 : level.length * level.width,
            }); // filtering floors levels
          }
        }
      } else {
        if (level.name === 'Mezzanine') {
          // filtering mezzanine levels
          mezzanineArray.push({
            name: level.name,
            level: Number(level.levelStart),
            area: isNaN(level.width) || isNaN(level.length) ? 0 : level.length * level.width,
          });
        } else {
          totalArea += isNaN(level.width) || isNaN(level.length) ? 0 : level.length * level.width;
          // sum of area of all levels(floor,basement,attic) except mezzanine
        }
        if (level.name === 'Floor') {
          // sum of area of all levels(floor,basement,attic) except mezzanine
          floorsPerLevelAreaArray.push({
            name: level.name,
            level: Number(level.levelStart),
            area: isNaN(level.width) || isNaN(level.length) ? 0 : level.length * level.width,
          });
        }
      }
    });

    if (mezzanineArray.length > 0) {
      const totalMezzanineArea = this.occupantServ.calculateMezzanineArea(mezzanineArray, floorsPerLevelAreaArray);
      totalArea += totalMezzanineArea;
    }
    return totalArea;
  }

  /**
   * Get the total mezzanine area for an array of occupants where return  the chargeable mezzanine(area greater than 25%) area
   *
   * @param mezzanineArray Filtered array of mezzanines
   * @param floorsPerLevelAreaArray Filtered array of floors with each level's area
   * @returns
   */
  public calculateMezzanineArea(mezzanineArray, floorsPerLevelAreaArray) {
    let totalMezzanineArea = 0;
    let flag;
    mezzanineArray.forEach((mezzLevel) => {
      let totalFloorArea = 0;
      floorsPerLevelAreaArray.filter((eachLevel) => {
        if (eachLevel.level === mezzLevel.level) {
          totalFloorArea += eachLevel.area;
        }
      });
      flag = (mezzLevel.area / totalFloorArea) * 100 >= 25 ? true : false;
      if (flag) {
        totalMezzanineArea += mezzLevel.area;
      }
    });
    return totalMezzanineArea;
  }
  public combustibilityClassValidator(formcontrolVal, scheNo, occupantSchNo, flag, field, condcheck,
    combustibilityClass, index, fieldDetails?: any) {
    if (condcheck == 'form') {
      scheNo[index].scheduleNumber = formcontrolVal;
    }
    const scheduleNoStartWith = ['6051', '6052'];
    const hazardScheduleNo = ['7281000000', '7281000001', '7281000002', '7281000003', '7281000004', '7281000005', '7281000006', '7281000007'];
    const filteredoccupantscheduleList = filteringOccupantScheduleNo(occupantSchNo, scheduleNoStartWith, '');
    const result = filteringHazardScheduleNo(scheNo, hazardScheduleNo);
    const excludeSnoValue = this.excludeCombustibleClass(scheNo, occupantSchNo);
    const overrideCombustiblityValue = this.overrideCombustibilityClass(scheNo, combustibilityClass);
    if ((filteredoccupantscheduleList?.length > 0 && result?.length > 0) || (excludeSnoValue) || (overrideCombustiblityValue)) {
      field?.get('combustibilityClass')?.patchValue('3');
      field?.get('isCombustibilityClassOverride')?.patchValue(true);
      setTimeout(() => {
        fieldDetails.fieldGroup[ 6 ].fieldGroup[ 0 ].fieldGroup[ 1 ].fieldGroup[ 0 ].fieldGroup[ 1 ].props.isDisabled = true;
        fieldDetails.fieldGroup[ 6 ].fieldGroup[ 0 ].fieldGroup[ 1 ].fieldGroup[ 0 ].fieldGroup[ 0 ].props.isDisabled = true;
      }, 100);
    }
    else if (flag == false) {
      {
        const scheduleDetails: any = this?.scheduleService?.getScheduledetails(occupantSchNo);
        if (scheduleDetails) {
          // const cb =  field?.get('combustibilityClass');
          // cb?.setValue(scheduleDetails?.CB);
          if (field?.get('isCombustibilityClassOverride').disabled ||
            fieldDetails.fieldGroup[ 6 ].fieldGroup[ 0 ].fieldGroup[ 1 ].fieldGroup[ 0 ].fieldGroup[ 1 ].props.isDisabled) {
            field?.get('combustibilityClass')?.patchValue(scheduleDetails?.CB?.toString());
            field?.get('isCombustibilityClassOverride')?.setValue(false);
            field?.get('isCombustibilityClassOverride')?.enable();
            fieldDetails.fieldGroup[ 6 ].fieldGroup[ 0 ].fieldGroup[ 1 ].fieldGroup[ 0 ].fieldGroup[ 1 ].props.isDisabled = false;
            fieldDetails.fieldGroup[ 6 ].fieldGroup[ 0 ].fieldGroup[ 1 ].fieldGroup[ 0 ].fieldGroup[ 0 ].props.isDisabled = true;
          }
        }
      }
    }
    else{
      field?.get('isCombustibilityClassOverride')?.enable();
    }
  }
  excludeCombustibleClass(scheNo,occupantSchNo){
    const scheduleNoStartWith = ['605'];
    const hazardScheduleNo = ['7282100000', '7282100001', '7282100002', '7282100003','7282100004', '7282100005', '7282100006', '7282100007'];
    const filteredoccupantscheduleList = filteringOccupantScheduleNo(occupantSchNo, scheduleNoStartWith,['6054000000']);
    const result = filteringHazardScheduleNo(scheNo, hazardScheduleNo);
    return (filteredoccupantscheduleList?.length > 0 && result?.length > 0);
  }
  overrideCombustibilityClass(scheNo,combustibilityClass){
    const hazardScheduleNo=['7332000000', '7332000001', '7332000002', '7332000003','7332000004', '7332000005', '7332000006', '7332000007'];
    const result =filteringHazardScheduleNo(scheNo, hazardScheduleNo);
  return ((result?.length > 0) && (combustibilityClass == 1 || combustibilityClass == 2 || combustibilityClass == 3 &&scheNo?.length>0));

  }
  getCombustibleClassDetails(field){
    if(field?.formControl?.invalid ||(!field.options.formState.service.formlyHookReuseableService?.isCheckNull(field?.formControl?.value)) ){
      if(( field?.parent?.parent?.form?.get('isCombustibilityClassOverride')?.value==false )&& (field?.parent?.parent?.form?.get('isCombustibilityClassOverride')?.disabled)){
        const scheduleDetails: any = field.options.formState.service?.scheduleService?.getScheduledetails(field?.parent?.parent?.form?.get('occupantScheduleNo')?.value);
        if (scheduleDetails) {
          if(  field?.parent?.parent?.form?.get('isCombustibilityClassOverride').disabled){
            field?.parent?.parent?.form?.get('combustibilityClass')?.patchValue(scheduleDetails?.CB?.toString());
             field?.parent?.parent?.form?.get('isCombustibilityClassOverride')?.setValue(false);
              field?.parent?.parent?.form?.get('isCombustibilityClassOverride')?.enable();
          }  
        }
      }
    }
   
  }
  getHydraulicDesignStandards(field){
    const sprinklerDesignList = [];
    let occupants = field?.form?.root?.controls?.occupants?.controls["section-occupants"]?.controls?.occupants?.value;
    if (occupants && occupants?.length) {
      occupants.forEach((occupant) => {
        if (!occupant.isDeleted) {
          if (occupant?.occupantLevels && occupant.occupantLevels.length > 0) {
            occupant?.occupantLevels.map((level) => {
              sprinklerDesignList.push(level?.sprinklerDesign);
            })
          }
        }
      })
    }
    const hydraulicDesignStandard = this.formlyBuilderService.waterSupplyService.calculateHydraulicDesignStandard(sprinklerDesignList);
    if (this.riskReportService?.model?.sprinklerReport?.asgrWaterSupply) {
      this.riskReportService.model.sprinklerReport.asgrWaterSupply.hydraulicDesignStandard = hydraulicDesignStandard;
    } else {
      this.riskReportService.model.sprinklerReport.asgrWaterSupply = {
        hydraulicDesignStandard: hydraulicDesignStandard
      };
    }
  }
}
