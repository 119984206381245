import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthHttpService } from '../auth-http.service';
import { StorageService } from 'src/app/services/storage-service/storage.service';
import * as jose from 'jose';
import { AuthService } from 'ionic-appauth';

@Injectable({
  providedIn: 'root',
})
export class OfflineTokenValidationService {
  private publicKeyStorageKey = 'oktaPublicKey';
  private jwksUri = `${environment.auth_config.server_host}/v1/keys`;
  private isValidSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private storage: StorageService, private httpService: AuthHttpService, private auth: AuthService) {
    this.checkTokenValidity();
  }

  private checkTokenValidity(): void {
    this.isValid$.subscribe();
  }

  get isValid$(): Observable<boolean> {
    return this.isValidSubject.asObservable();
  }

  async fetchAndStorePublicKey() {
    let publicKey = await this.storage.getObject(this.publicKeyStorageKey);
    if (!publicKey) {
      this.fetchAndStoreKeysFromJWKS();
    }
  }

  private async fetchAndStoreKeysFromJWKS() {
    let oktaToken = await this.auth.getValidToken();
    //Save acces token for offline use
    if (oktaToken) {
      this.storage.setObject('access_token', oktaToken.accessToken);
    }
    // Fetch JWKS from the provided URI
    let jwks: any = await this.httpService.request('GET', this.jwksUri);
    if (jwks) {
      // Retrieve the access token from storage
      let accessToken: any = await this.storage.getObject('access_token');
      // Decode the protected header of the access token to extract key id
      const header = jose.decodeProtectedHeader(accessToken);
      // Filter keys by header kid to find the corresponding public key
      let publicKey = jwks.keys.filter((item) => item.kid == header.kid);
      // Store the public key in storage
      this.storage.setObject(this.publicKeyStorageKey, publicKey[0]);
    }
  }

  async validateToken(): Promise<boolean> {
    try {
      // Retrieve the public key from storage
      let publicKey: any = await this.storage.getObject(this.publicKeyStorageKey);

      if (publicKey) {
        // Retrieve the access token from storage
        let accessToken: any = await this.storage.getObject('access_token');
        // Decode the access token to extract its claims
        const claim = jose.decodeJwt(accessToken);

        // Check if the token has expired
        if (this.isTokenExpired(claim.iat)) {
          // The token was issued more than 24 hours ago so we consider it invalid
          this.isValidSubject.next(false);
          return false;
        } else {
          // The token is within 24 hours of issuance.
          // Import the public key and verify the access token
          const importedJWKSet = await jose.importJWK(publicKey, 'RS256');
          const decodedToken: any = await jose.jwtVerify(accessToken, importedJWKSet, {
            issuer: `${environment.auth_config.server_host}`, // issuer
            //audience: `${environment.audience}`, // audience
            clockTolerance: '24 hours',
          });
          // Token is valid if verification succeeds
          this.isValidSubject.next(true);
          return true;
        }
      } else {
        // Public key not found
        this.isValidSubject.next(false);
        return false;
      }
    } catch (error) {
      this.isValidSubject.next(false);
      return false;
    }
  }

  // Method to check if the token's issued at time is greater than 24 hours from now
  isTokenExpired(tokenIssuedAt: number): boolean {
    // Convert token's issued at time to milliseconds
    const tokenIssuedAtMs = tokenIssuedAt * 1000; // Convert seconds to milliseconds

    // Get the current time in milliseconds
    const now = new Date().getTime();

    // Calculate the difference between the current time and the token's issued at time
    const differenceInMs = now - tokenIssuedAtMs;

    // Convert 24 hours to milliseconds
    const twentyFourHoursInMs = 24 * 60 * 60 * 1000;

    // Check if the difference is greater than 24 hours
    return differenceInMs > twentyFourHoursInMs;
  }
}
