/* eslint-disable @typescript-eslint/dot-notation */
import { Inject, Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { AddressAliasDataModel } from 'src/app/models/formly-builder/formly/address-alias-data.model';
import { AddressDataModel } from 'src/app/models/formly-builder/formly/address-data.model';
import { UtilService } from '../util-service/util.service';
import { ENV, Environment } from 'src/app/interfaces/env.interface';
import { Constants } from '../util-service/constants';
import { AuthHttpService } from 'src/app/auth/auth-http.service';
import { FormlyBuilderService } from './formly-builder.service';
import { FormlyPublicProtectionAreaService } from './formly-ppa.service';

@Injectable({
  providedIn: 'root',
})
export class FormlyAddressService extends SectionServiceAbstract {
  // private ngUnsubscribe = new Subject<void>();

  constructor(private utilService: UtilService,
    @Inject(ENV) private environment: Environment,
    private httpService: AuthHttpService,
    private formlyBuilderService: FormlyBuilderService,
    private formlyPublicProtectionAreaService: FormlyPublicProtectionAreaService) {
    super();
  }

  getAddrLine1(addr: any): string {
    let address = addr?.streetNumber ? addr.streetNumber?.toString()?.trim() : '';
    address += addr?.highNumber?.toString()?.trim() ? '-' + addr?.highNumber?.toString()?.trim() : '';
    if (addr.preDirectional && addr.preDirectional.length > 0) {
      address += ' ' + addr.preDirectional?.trim();
    }
    address += addr?.streetName ? ' ' + addr?.streetName?.trim() : '';
    address += addr?.streetType ? ' ' + addr?.streetType?.trim() : ''; // .slice(0, 3)
    if (addr.postDirectional && addr.postDirectional.length > 0) {
      address += ' ' + addr.postDirectional?.trim();
    }
    return address;
  }

  getCounty(addr: any): string {
    return addr.county;
  }

  getAddrLine2(addr: any): string {
    return (addr?.city ? addr.city?.trim() + ', ' : '') + (addr?.state ? addr.state?.trim() + ' ' : '') +
      (addr?.zipcode ? addr?.zipcode : '');
  }

  legalAddress(addr: any): boolean {
    const requiredAddressParts = [
      addr,
      // addr.streetNumber,
      addr.streetName,
      addr.city,
      addr.state
    ];

    for (const part of requiredAddressParts) {
      if (!part) {
        return false;
      }
    }
    return true;
  }

  async updateData(model: any, riskReportData: any): Promise<any> {
    // this.formlyBuilder.riskReport.updateData(address);
    // Check if data has changed
    const hasChanged: boolean = AddressAliasDataModel.hasChanged(model, riskReportData);
    // Check section-occupants data
    // Check occupants data
    // Check level data for every occupant
    if (hasChanged) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const reportAddresses = AddressAliasDataModel.to(model[ 'address' ]);
      if (this.formlyBuilderService?.mode === 2 && model?.publicProtection?.fireProtectionArea &&
        reportAddresses?.ReportAddresses?.length) {
        reportAddresses?.ReportAddresses.forEach((address) => {
          address.FireProtectionArea = model.publicProtection.fireProtectionArea;
        });
      }
      if (this.formlyBuilderService?.mode === 2) {
        riskReportData.County = reportAddresses?.ReportAddresses?.find(src => src?.IsAlias !== true)?.County;
      }
      return { ...riskReportData, ...reportAddresses };
    } else {
      return undefined;
    }
  }

  loadData(addresses: any[], fullData: any, isS3: boolean, model: any): any {
    // TODO: Refactor and move part of this logic to the address service
    const addressModel = new AddressDataModel();
    if (addresses) {
      addresses.forEach((address) => {
        if (isS3) {
          // eslint-disable-next-line @typescript-eslint/dot-notation
          if (address[ 'IsAlias' ]) {
            addressModel.addressAliases.push(AddressAliasDataModel.fromS3(address));
          } else {
            addressModel.mainAddress = AddressAliasDataModel.fromS3(address);
          }
        } else {
          const addressRecord = address.data;
          // eslint-disable-next-line @typescript-eslint/dot-notation
          if (addressRecord[ 'IsAlias' ]) {
            addressModel.addressAliases.push(AddressAliasDataModel.from(addressRecord));
          } else {
            addressModel.mainAddress = AddressAliasDataModel.from(addressRecord);
          }
        }
      });
      if (addresses.length) {
        if (addressModel.addressAliases && addressModel.addressAliases.length && model.address && model.address.addressAliases) {
          // addressModel.addressAliases = addressModel.addressAliases.concat(model.address.addressAliases);
        }

        // Cleaning up the county value in Report (Removing county text from the value)
        if (this.formlyBuilderService.mode === 2) {
          if (this.formlyBuilderService?.riskReport?.report?.ReportAddresses?.length) {
            this.formlyBuilderService?.riskReport?.report?.ReportAddresses.forEach((address) => {
              if (!address.IsAlias && (address.County?.includes('County') || address.County?.includes('county'))) {
                address.County = address.County.replace('County', '')?.replace('county', '');
              }
            });
          }
        }

        // return { ...model, ...{ address: addressModel } };
        return { address: addressModel };
      }
    } else {
      console.debug('Unexpected empty addresses\n' + fullData);
    }
    return model;
  }

  getAddressAutocomplete(payload: any, pageSize: number): Promise<any> {
    const url = this.environment.iSurveyAPIurl + Constants.addressAutocomplete + `?applicationId=FDC&maxResult=${pageSize}`;
    this.utilService.addTextConsoleLog('getAddressAutocomplete api url: ', url);
    return this.httpService.request('POST', url, payload);
  }

  getAddressDetails(details: any): Promise<any> {
    const url = this.environment.iSurveyAPIurl + Constants.addressScrub + '?address1=' + details.street + '&zip=' +
      details.zipcode + '&applicationId=FDC&includeGeocode=false&queryType=Verify';
    this.utilService.addTextConsoleLog('getAddressDetails api url: ', url.replace(/\s/g, '%20'));
    return this.httpService.request('GET', url.replace(/\s/g, '%20'));
  }

  getNonDeletedFields(formControl: any): any {
    if (formControl[ 'controls' ]?.addressAliases && formControl[ 'controls' ]?.addressAliases.controls &&
      formControl[ 'controls' ]?.addressAliases.controls.length > 0) {
      formControl[ 'controls' ].addressAliases[ 'controls' ] =
        formControl[ 'controls' ]?.addressAliases[ 'controls' ]?.filter(src => src?.value?.isDeleted === false);
    }
    return formControl;
  }

  getParsedAddress(address: string): Promise<any> {
    const url = this.environment.iSurveyAPIurl + Constants.addressParseOnly + '?address1=' + address + '&applicationId=FDC';
    this.utilService.addTextConsoleLog('getParsedAddress api url: ', url.replace(/\s/g, '%20'));
    return this.httpService.request('GET', url.replace(/\s/g, '%20'));
  }

  async getCountyList(): Promise<any[]> {
    const userAddress = this.formlyBuilderService.riskReport?.model?.address?.mainAddress;
    let countyList = [];
    return new Promise<any>((resolve, reject) => {
      if (userAddress) {
        this.formlyPublicProtectionAreaService.getCities('ppa-list').subscribe((val) => {
          countyList = val?.filter(item => (
            ((userAddress?.state?.trim().toLowerCase() === 'nx' && item?.State?.toLowerCase() === 'ny') ||
              (userAddress?.state?.trim().toLowerCase() === 'cx' && item?.State?.toLowerCase() === 'ca') ||
              item?.State?.toLowerCase() === userAddress?.state?.trim().toLowerCase())
          ));
          resolve(countyList);
        }, (error) => {
          reject(error);
        });
      } else {
        resolve(countyList);
      }
    });
  }

}
