/* eslint-disable @typescript-eslint/naming-convention */
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
import { RiskReport } from '../../risk-report/risk-report-vm';

export class ContactInformationDataModel {
  static sectionName = 'contactInfo';

  contactName: string;
  contactPhone: any;
  escortedByName: string;
  escortedByPhone: any;

  static fromS3(reports: any): ContactInformationDataModel {
    return {
      contactName: reports.ContactName,
      contactPhone: this.formatPhoneNumber(reports.ContactPhone),
      escortedByName: reports.EscortedByName,
      escortedByPhone: this.formatPhoneNumber(reports.EscortedByPhone)
    };
  }

  static to(model: any, sectionEnum?: SectionEnum) {
      return { 
        ContactName: model.contactInformation.contactName,
        ContactPhone: this.cleanPhone(model.contactInformation.contactPhone),
        EscortedByName: model.contactInformation.escortedByName,
        EscortedByPhone: this.cleanPhone(model.contactInformation.escortedByPhone),
     };
  }

  /**
   * @param phone Formatted or unformatted phone number "(202) 459-1111" or "2024591111"
   * @returns Phone number with only digits, no formatting "2024591111"
   */
  static cleanPhone(phone): string {
    let cleanedPhone = phone ? phone : '';
    if (cleanedPhone?.length) {
      cleanedPhone = cleanedPhone.replace("(", "");
      cleanedPhone = cleanedPhone.replace(")", "");
      cleanedPhone = cleanedPhone.replace(" ", "");
      cleanedPhone = cleanedPhone.replace("-", "");
    }
    return cleanedPhone;
  }

  /**
   * 
   * @param phoneNumberString 
   * @returns 
   */
  static formatPhoneNumber(phoneNumberString): string {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return '';
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   *
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
      return true;
}
}