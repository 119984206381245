import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { UserRolesService } from '../services/util-service/user-roles.service';
import { intersection } from 'lodash';
@Directive({
  selector: '[disable-elements]',
})
export class DisableDirective implements AfterViewInit, OnDestroy {
  @Input() isPermissionReq: boolean;
  @Input() permissionType = [];
  @Input() permissionAction: string;
  permissionSubscription = null;
  constructor(private _el: ElementRef, private userroleservice: UserRolesService, private renderer: Renderer2) {}

  ngAfterViewInit() {
    if (this.isPermissionReq && this.permissionType.length) {
      this.permissionSubscription = this.userroleservice.checkUserRoleObservable.subscribe((permissions: string[]) => {
        if (permissions) {
          const common = intersection(this.permissionType, permissions);
          if (common.length === 0) {
            switch (this.permissionAction) {
              case 'disabled':
                this.renderer.removeAttribute(this._el.nativeElement, 'disabled');
                this.renderer.setAttribute(this._el.nativeElement, 'disabled', 'true');
                break;

              case 'visibility':
                this.renderer.removeAttribute(this._el.nativeElement, 'hidden');
                this.renderer.setAttribute(this._el.nativeElement, 'hidden', 'true');
                break;
              default:
                break;
            }
          } else {
            this.renderer.removeAttribute(this._el.nativeElement, 'disabled');
            this.renderer.removeAttribute(this._el.nativeElement, 'hidden');
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.permissionSubscription) {
      this.permissionSubscription.complete();
      this.permissionSubscription.unsubscribe();
    }
  }
}
