export class VeriskLog {
  public level: 'DEBUG'|'ERROR'|'INFO' = 'INFO';
  public frid: string;
  public date: string = new Date().toJSON();
  public analytics: boolean = true;
  public developerConsole: boolean = false;
  public browserConsole: boolean = true;
  public message: string;
  public component: string;
  public method: string;
  public data: any;

  constructor(msg: string, level: 'DEBUG'|'ERROR'|'INFO', method: string, component: string, data?: any, developerConsole?: boolean){
    this.message = msg;
    this.level = level;
    this.method = method;
    this.component = component;
    this.data = data;
    this.developerConsole = developerConsole;
  }
}