import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';

/**
 * Class that represents the Formly Model for CombustibleConstruction
 */
export class CombustibleConstructionDataModel {
    
    static sectionName = 'combustibleConstruction';
    // TODO: Rename to read
    roofSurfaceConstructionType : string;
    isFabricatedRoofFlameSpreadLessThan25 : boolean;
    roofSurfaceArea : any;
    inchesOfAirSpace : any
    raisedSurfaceFloorArea : any;
    concealedRoofArea : any;
    percentOfExteriorWallArea : any;


    static fromS3(reports:any):CombustibleConstructionDataModel {
           
        return {
            roofSurfaceConstructionType :reports?.SecondaryConstructions?.length >0 ? (reports?.SecondaryConstructions[0]?.RoofSurfaceConstructionType ? reports?.SecondaryConstructions[0]?.RoofSurfaceConstructionType : null) : null,
            isFabricatedRoofFlameSpreadLessThan25 :reports?.SecondaryConstructions?.length >0 ? (reports?.SecondaryConstructions[0]?.IsFabricatedRoofFlameSpreadLessThan25 ? reports?.SecondaryConstructions[0]?.IsFabricatedRoofFlameSpreadLessThan25 : null) : null,
            roofSurfaceArea :reports?.SecondaryConstructions?.length >0 ? (reports?.SecondaryConstructions[0]?.RoofSurfaceArea ? reports?.SecondaryConstructions[0]?.RoofSurfaceArea : null) : null,
            inchesOfAirSpace :reports?.SecondaryConstructions?.length >0 ?( reports?.SecondaryConstructions[0]?.InchesOfAirSpace ? reports?.SecondaryConstructions[0]?.InchesOfAirSpace : null) : null,
            raisedSurfaceFloorArea :reports?.SecondaryConstructions?.length >0 ? (reports?.SecondaryConstructions[0]?.RaisedSurfaceFloorArea ? reports?.SecondaryConstructions[0]?.RaisedSurfaceFloorArea : null) : null,
            concealedRoofArea :reports?.SecondaryConstructions?.length >0 ? (reports?.SecondaryConstructions[0]?.ConcealedRoofArea ? reports?.SecondaryConstructions[0]?.ConcealedRoofArea : null) : null,
            percentOfExteriorWallArea :reports?.SecondaryConstructions?.length >0 ? (reports?.SecondaryConstructions[0]?.PercentOfExteriorWallArea ? reports?.SecondaryConstructions[0]?.PercentOfExteriorWallArea : null) : null,
            };
    }

    /**
     * 
     */
    static to(model: any) {
       return
    }

    /**
     * Check if the model has changed
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     * @returns 
     */
    static hasChanged(formlyModel: any, riskReportModel: any): boolean {
        return true;
      
      
    }
}