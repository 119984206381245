import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';

/**
 * Class that represents the Formly Model for GreenConstruction
 */
export class GreenConstructionDataModel {
    
    static sectionName = 'greenConstruction';
    // TODO: Rename to read
    solarPanelCoverage: string;
    greenRoofCoverage: string;
    static fromS3(reports:any):GreenConstructionDataModel {//wholedata from cached report data
           
        return {
            solarPanelCoverage:reports?.SecondaryConstructions?.length >0 ? (reports?.SecondaryConstructions[0]?.SolarPanelsCoverage ? reports?.SecondaryConstructions[0]?.SolarPanelsCoverage : 'NONE') : 'NONE',// this variable is from top level
            greenRoofCoverage:reports?.SecondaryConstructions?.length >0 ? (reports?.SecondaryConstructions[0]?.GreenRoofCoverage ? reports?.SecondaryConstructions[0]?.GreenRoofCoverage : 'NONE') : 'NONE',//key value is from walls

        };
    }

    /**
     * 
     */
    static to(model: any) {
        return {
            SolarPanelsCoverage: model?.SecondaryConstructions?.solarPanelCoverage,// this variable is from top level
            GreenRoofCoverage: model?.SecondaryConstructions?.greenRoofCoverage,
        };
    }

    /**
     * Check if the model has changed
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     * @returns 
     */
    static hasChanged(formlyModel: any, riskReportModel: any): boolean {
        return true;
      
      
    }
}