import { Component, OnInit,OnDestroy, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { ErrorModel, ErrorSectionModel } from 'src/app/models/formly-builder/error.model';
import { DeepLinkService } from 'src/app/services/formly-builder/deep-link.service';
import { BackendErrorSection, SectionEnum } from 'src/app/services/formly-builder/section.config';
import { FormlyErrorService } from 'src/app/services/formly-builder/formly-error.service';
import { DeepLinkMessageModel } from 'src/app/models/formly-builder/deep-link.model';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { Constants } from 'src/app/services/util-service/constants';

@Component({
  selector: 'app-error-sheet',
  templateUrl: './error-sheet.component.html',
  styleUrls: ['./error-sheet.component.scss'],
})
export class ErrorSheetComponent implements OnInit, OnDestroy {
  // TODO: Replace by error observable...
  @Input('errors') errors: ErrorSectionModel[];
  @Input('errorNum') errorNum: number;
  @Input('errorArray') errorArray: ErrorModel[];
  @Input('form') form: any;

  isCollapsed: boolean[] = new Array(20);
  isCollapsedAll: boolean = true;

  protected ngUnsubscribe = new Subject<void>();

  constructor(private modalCtrl: ModalController, 
    private formlyService: FormlyBuilderService,
    private deepLinkService: DeepLinkService) {
  }

  ngOnInit() {
    // Classify Errors in categories
    const latestLocation: DeepLinkMessageModel = this.deepLinkService.getLatestLocation();

    const expandedIndex: number = this.getSectionIndex(latestLocation.landingId);

    for (let i = 0; i < this.isCollapsed.length; ++i) {
      if (expandedIndex === i) {
        this.isCollapsed[i] = false;
      } else {
        this.isCollapsed[i] = true;
      }
    }
  }

  private getSectionIndex(sectionEnumIndex: number): number {
    const table = new Map<number, number>([
      [SectionEnum.FR_OVERVIEW, 0],
      [SectionEnum.FR_WALLS, 1],
      [SectionEnum.FR_FLOORS_ROOFS, 2],
      [SectionEnum.FR_OCCUPANTS_HAZARDS, 3],
      [SectionEnum.FR_SECONDARY_CONSTRUCTION, 4],
      [SectionEnum.FR_EXPOSURES, 5],
      [SectionEnum.FR_INTERNAL_PROTECTION, 6],
      [SectionEnum.FR_SPRINKLERS, 7],
      [SectionEnum.FR_WIND, 12],
    ]);

    const classificationIndex = table.get(sectionEnumIndex);
    if (classificationIndex !== undefined) {
      return classificationIndex;
    }
    return -1;
  }

  onSwipeDown() {
    this.close();
  }

  close() {
    const closeData = null;
    return this.modalCtrl.dismiss(closeData, 'cancel');
  }

  getValidationErrorNumber(): number {
    return this.errorNum;
  }

  getErrorLabel(error: ErrorModel): string {
    const errorDetail = this.getErrorDetail(error);
    return errorDetail.label;
  }

  getErrorMessage(error: ErrorModel): any {
    const errorDetail = this.getErrorDetail(error);
    return errorDetail.message;
  }

  /**
   * 
   * @param dotSeparatedString 
   */
  getLastPart(dotSeparatedString: string): string {
    const period = dotSeparatedString.lastIndexOf('.');
    return dotSeparatedString.substring(period + 1);
  }

  getErrorDetail(error: ErrorModel) {
    // This we need to check, it doesn't make sense for the message to be boolean "true"/"false"
    if (error.message === true) {
      return {
        label: error.name,
        message: error.validation
      }
    } else if (error.type === 6 || (error.paths && error.message)) {
      // Backend errors
      const backendErrorId = error?.id ? " [" + error?.id + "]" : ".";
      let externalLabel = error?.paths?.length > 0 ? error.paths[error.paths.length - 1] : ((error?.paths?.length === 0) ? error.paths[0] : '');

      let externalMessage = error.message;
      if (Constants.ShowBackendErrorCode) {
        externalMessage = externalMessage + backendErrorId;
      } 

      // Specific case: required
      if (error.message === 'Required.' || error.message === 'Required' && externalLabel?.length) {
        // For the field name, use last part from the dot separated path, and uncamelize it
        // const fieldName = externalLabel.replace("RiskReport.", "");
        const fieldName = FormlyErrorService.unCamelize(this.getLastPart(externalLabel));

        externalMessage = fieldName + " is required";
        if (Constants.ShowBackendErrorCode) {
          externalMessage = externalMessage + backendErrorId;
        }
      }
      return {
        label: externalLabel,
        message: externalMessage
      }
    } else {
      return {
        label: error.name,
        message: error.message
      };
    }
  }

  toggleExpand(sectionIndex: number): void  {
    this.isCollapsed[sectionIndex] = !this.isCollapsed[sectionIndex];
  }

  toggleExpandAll(): void {
    this.isCollapsedAll = !this.isCollapsedAll;
  }  

  deepLinkTo(error: ErrorModel, section = SectionEnum.FR_OVERVIEW) {
    this.close();

    // Mark all fields as touched, so errors will show
    // this.formlyService.markAsTouchedIfHasValues();

    if (error?.linkId?.length) {
      console.debug("1. routerNavigate ErrorSheet");
      this.deepLinkService.routerNavigate({
        landingId: (error?.linkId?.length > 0) ? error.linkId[0] : undefined,
        sectionId: (error?.linkId?.length > 1) ? error.linkId[1] : undefined,
        subSectionId: (error?.linkId?.length > 2) ? error.linkId[2] : undefined,
        landing: (error?.branch?.length > 0) ? error.branch[0] : undefined,
        section: (error?.branch?.length > 1) ? error.branch[1] : undefined,
        subSection: (error?.branch?.length > 2) ? error.branch[2] : undefined,
      });
    } else if (error?.id) {
      const link = this.getSectionFromBackendId(error.id);
      if (link) {
        this.deepLinkService.routerNavigate({
          landingId: link.report,
          sectionId: link.section,
        });  
      }    
    } else {
      this.deepLinkService.routerNavigate({
        landingId: section,
        sectionId: section
      });
    }
  }

  private getSectionFromBackendId(id: string) {
    for (let index = 0; index < BackendErrorSection.length; index++) {
      const key = BackendErrorSection[index].errorId;
      if (id.includes(key)) {
        return (BackendErrorSection[index]);
      }
    }
    return undefined;
  }

  ngOnDestroy(): void {
    // this.ngUnsubscribe.next();
    // this.ngUnsubscribe.complete();
  }
}
