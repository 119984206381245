/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */
import { Guid } from 'guid-typescript';
import { ExposureModel } from 'src/app/models/risk-report/s3-risk-report.model';

export class ExposureCommunicationsDataModel {
  hasCommunications?: boolean;
  isPassage?: boolean;
  partyProtectionType?: string;
  passageCombustibleType?: string;
  passageProtectionType?: string;
  passageLength?: number;
  isPassageOpen?: string;
  isPartyWall?: boolean;
  tankCapacity?: number;
}

export class ExposureDataModel {
  exposureIdentifier: string;
  reportIdentifier: string;
  exposureDirection?: string;
  surveyDistanceExposure?: number;
  riskConstructionType?: string;
  riskProtectionType?: string;
  exposureConstructionType?: string;
  exposureProtectionType?: string;
  lengthHeightFactor?: number;
  occupancyType?: string;
  hasOutsideAutomaticSprinkler?: boolean;
  scopesVersion: number;
  exposureCharge?: string;
  passagewayCharge?: string;
  partywallCharge?: string;

  // communications?: ExposureCommunicationsDataModel;
  hasCommunications?: boolean;
  isPassage?: boolean;
  partyProtectionType?: string;
  passageCombustibleType?: string;
  passageProtectionType?: string;
  passageLength?: number;
  isPassageOpen?: string;
  isPartyWall?: boolean;
  tankCapacity?: number;

  /**
   * @deprecated
   * @param exposure 
   * @returns 
   */
  static from(exposure: ExposureModel): ExposureDataModel {
    return {
      exposureIdentifier: null,
      reportIdentifier: null,
      scopesVersion: 1
    };
  }

  static to(model: any): any {
    return {
      ExposureIdentifier: model.exposureIdentifier,
      ReportIdentifier: model.reportIdentifier,
      ExposureDirection: model.exposureDirection ? model.exposureDirection : undefined,
      SurveyDistanceExposure: model.surveyDistanceExposure ? +model.surveyDistanceExposure : null,
      RiskConstructionType: model.riskConstructionType ? model.riskConstructionType : undefined,
      RiskProtectionType: (model.riskProtectionType !==null) ? model.riskProtectionType : undefined,
      ExposureConstructionType: model.exposureConstructionType ? model.exposureConstructionType : undefined,
      ExposureProtectionType: (model.exposureProtectionType !== null) ? model.exposureProtectionType : undefined,
      LengthHeightFactor: model.lengthHeightFactor ? +model.lengthHeightFactor : undefined,
      OccupancyType: model.occupancyType ? model.occupancyType : undefined,
      HasOutsideAutomaticSprinkler: model.hasOutsideAutomaticSprinkler ? model.hasOutsideAutomaticSprinkler : false,
      ScopesVersion: model.scopesVersion ? model.scopesVersion : 1,
      ExposureCharge: model.exposureCharge ? model.exposureCharge : undefined,
      PassagewayCharge: model.passagewayCharge ? model.passagewayCharge : undefined,
      PartyWallCharge: model.partywallCharge ? model.partywallCharge : undefined,

      // Communications
      // Warning: in RR, HasCommunications is in upper level; in Formly Risk Model (FRM) it's inside communications
      // Change: communications branch removed, moved all to the top level
      // HasCommunications: model.communications?.hasCommunications ? model.communications?.hasCommunications : false,
      // IsPartyWall: (model.communications?.hasCommunications && model.communications?.isPartyWall) ? model.communications?.isPartyWall : false,
      // PartyProtectionType: (model.communications?.hasCommunications && model.communications?.isPartyWall && model.communications?.partyProtectionType) ? model.communications?.partyProtectionType : undefined,
      // IsPassage: (model.communications?.hasCommunications && model.communications?.isPassage) ? model.communications?.isPassage : false,
      // PassageCombustibleType: (model.communications?.hasCommunications && model.communications?.isPassage && model.communications?.passageCombustibleType) ? model.communications?.passageCombustibleType : undefined,
      // PassageProtectionType: (model.communications?.hasCommunications && model.communications?.isPassage && model.communications?.passageProtectionType) ? model.communications?.passageProtectionType : undefined,
      // PassageLength: (model.communications?.hasCommunications && model.communications?.isPassage && model.communications?.passageLength) ? model.communications?.passageLength : undefined,
      // IsPassageOpen: (model.communications?.hasCommunications && model.communications?.isPassage && model.communications?.isPassageOpen === 'true'),
      // TankCapacity: (model.communications?.hasCommunications && model.communications?.tankCapacity) ? model.communications?.tankCapacity : undefined,

      HasCommunications: model.hasCommunications ? model.hasCommunications : false,
      IsPartyWall: (model.hasCommunications && model.isPartyWall) ? model.isPartyWall : false,
      PartyProtectionType: (model.hasCommunications && model.isPartyWall && model.partyProtectionType) ? model.partyProtectionType : undefined,
      IsPassage: (model.hasCommunications && model.isPassage) ? model.isPassage : false,
      PassageCombustibleType: (model.hasCommunications && model.isPassage && model.passageCombustibleType) ? model.passageCombustibleType : undefined,
      PassageProtectionType: (model.hasCommunications && model.isPassage && model.passageProtectionType) ? model.passageProtectionType : undefined,
      PassageLength: (model.hasCommunications && model.isPassage && model.passageLength) ? model.passageLength : undefined,
      IsPassageOpen: (model.hasCommunications && model.isPassage && model.isPassageOpen === 'true') ? true : false,
      TankCapacity: (model.hasCommunications && model.tankCapacity) ? model.tankCapacity : undefined,
    }
  }

  /**
   * 
   * @param s3WallData 
   * @returns 
   */
  static fromS3(riskModel: any): ExposureDataModel {
    const hasCommunications = riskModel.HasCommunications ? riskModel.HasCommunications : false;
    return {
      exposureIdentifier: riskModel.ExposureIdentifier,
      reportIdentifier: riskModel.ReportIdentifier,
      exposureDirection: riskModel.ExposureDirection ? riskModel.ExposureDirection : undefined,
      surveyDistanceExposure: riskModel.SurveyDistanceExposure,
      riskConstructionType: riskModel.RiskConstructionType ? riskModel.RiskConstructionType : undefined,
      riskProtectionType: riskModel.RiskProtectionType ? riskModel.RiskProtectionType : undefined,
      exposureConstructionType: riskModel.ExposureConstructionType ? riskModel.ExposureConstructionType : undefined,
      exposureProtectionType: riskModel.ExposureProtectionType ? riskModel.ExposureProtectionType : undefined,
      lengthHeightFactor: riskModel.LengthHeightFactor ? riskModel.LengthHeightFactor : undefined,
      occupancyType: riskModel.OccupancyType ? riskModel.OccupancyType : undefined,
      hasOutsideAutomaticSprinkler: riskModel.HasOutsideAutomaticSprinkler ? riskModel.HasOutsideAutomaticSprinkler : false,
      scopesVersion: riskModel.ScopesVersion ? riskModel.ScopesVersion : 1,
      exposureCharge: riskModel.ExposureCharge ? riskModel.ExposureCharge : undefined,
      passagewayCharge: riskModel.PassagewayCharge ? riskModel.PassagewayCharge : undefined,
      partywallCharge: riskModel.PartywallCharge ? riskModel.PartywallCharge : undefined,

      // Communications
      // communications: {
      //   hasCommunications: hasCommunications,
      //   isPartyWall: (hasCommunications && riskModel.IsPartyWall) ? riskModel.IsPartyWall : false,
      //   partyProtectionType: (hasCommunications && riskModel.IsPartyWall && riskModel.PartyProtectionType) ? riskModel.PartyProtectionType : undefined,

      //   isPassage: (hasCommunications && riskModel.IsPassage) ? riskModel.IsPassage : false,
      //   passageCombustibleType: (hasCommunications && riskModel.IsPassage && riskModel.PassageCombustibleType) ? riskModel.PassageCombustibleType : undefined,
      //   passageProtectionType: (hasCommunications && riskModel.IsPassage && riskModel.PassageProtectionType) ? riskModel.PassageProtectionType : undefined,
      //   passageLength: (hasCommunications && riskModel.IsPassage && riskModel.PassageLength) ? riskModel.PassageLength : undefined,
      //   isPassageOpen: (hasCommunications && riskModel.IsPassage && riskModel.IsPassageOpen) ? '' + riskModel.IsPassageOpen : 'false',

      //   tankCapacity: (hasCommunications && riskModel.TankCapacity) ? riskModel.TankCapacity : undefined,
      // }
      hasCommunications: hasCommunications,
      isPartyWall: (hasCommunications && riskModel.IsPartyWall) ? riskModel.IsPartyWall : false,
      partyProtectionType: (hasCommunications && riskModel.IsPartyWall && riskModel.PartyProtectionType) ? riskModel.PartyProtectionType : undefined,

      isPassage: (hasCommunications && riskModel.IsPassage) ? riskModel.IsPassage : false,
      passageCombustibleType: (hasCommunications && riskModel.IsPassage && riskModel.PassageCombustibleType) ? riskModel.PassageCombustibleType : undefined,
      passageProtectionType: (hasCommunications && riskModel.IsPassage && riskModel.PassageProtectionType) ? riskModel.PassageProtectionType : undefined,
      passageLength: (hasCommunications && riskModel.IsPassage && riskModel.PassageLength) ? riskModel.PassageLength : undefined,
      isPassageOpen: (hasCommunications && riskModel.IsPassage && riskModel.IsPassageOpen) ? '' + riskModel.IsPassageOpen : 'false',

      tankCapacity: (hasCommunications && riskModel.TankCapacity) ? riskModel.TankCapacity : undefined,
    };
  }
}
