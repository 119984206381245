/* eslint-disable */
import { FormlyFieldConfig } from "@ngx-formly/core";

/**
 * Function to avoid entering non valid chars for integer and decimal numbers.
 * @param field Field
 * @param event Keypress event
 * @returns true if validation passed. An object with the validation id and a message property inside if failed. Example: { "date": { message: "Something went wrong"}}
 */
export function numericInputValidator(field: FormlyFieldConfig, event: any) {
  // TODO: Prevent '--' at the beginning
  // This works in Form Builder / Preview
  //  For iPad a validator will have to be provided.
  const MAXIMUM_SUPPORTED_NUMERIC_LENGTH = 18;
  let currentValue: string | undefined = undefined;
  let currentLength = 0;
  let fieldMin = (field && field.props && field.props.min) ? field.props.min : undefined;
  try {
    const fieldModel = field.model;
    // const stringModel = fieldModel.toString;
    // const jsonModel = JSON.stringify(fieldModel);
    const index = field.key;
    let isEmpty: boolean = false;
    if (index) {
      isEmpty = !fieldModel.hasOwnProperty(index.toString());
      if (field.formControl?.pristine) {
        // There was a previousValue but now it's empty
        // isEmpty = true;
      }
      // console.log("PrevValue="+ Config.previousValue);

      // toString formats a number by default allowing a maximum of 18 characters including the decimal separator
      // We don't allow decimal values above 18
      // If maxLength is bigger than 18, 18 will be used
      currentValue = fieldModel[ index.toString() ];
      if (currentValue) {
        const strValue = (currentValue as string).toString();
        currentLength = strValue.length;
        const decimalValue = new Number(currentValue);
      } else {
        // No currentValue

        if (Object.keys(fieldModel).length == 0) {
          // We are dealing with the first value
          // } else if (fieldMin && fieldMin > 0) {
          // If min is bigger than zero, we just don't allow zeros at the beginning
          // event.preventDefault();
        } else {
          if (!isEmpty) {
            // A zero is already there...
            if (event.which == 48) {
              // Multiple zeroes!!!! Avoid that...
              // When input field is cleared this prevents from entering one single zero
              // even if it's allowed...
              // event.preventDefault();
            }
          } else {
            // Something like 01 Was entered... It's Ok
          }
        }
      }
    }

    // Ignore input if maxLength reached
    if (field && field.props && field.props.maxLength
      && currentLength >= field.props.maxLength) {
      event.preventDefault();
    }

    // Ignore minus sign if min >= 0
    let templateMin = -9999999999999;
    if (field && field.props && field.props.min != undefined)
      templateMin = field.props.min;
    if (event.which == 45) {    // '-'
      if (templateMin >= 0)
        event.preventDefault();
      if (!isEmpty) {
        // Not the first character. Avoid minus in other than first position
        // Not working: when we clean the input it will come here and will
        // prevent the first minus as well...
        // event.preventDefault();
      }
    }

    // Never a minus sign in other position than string begin
    // Doesn't work: With '-' length return as 0
    if (event.which == 45 && currentLength != 0) {
      event.preventDefault();
    }

    if (field && field.props && !field.props.isDecimal) {
      // Integer
      // Ignore input if not valid for integers:            
      //    0 for null values
      //    8 for backspace 
      //    48-57 for 0-9 numbers
      //    45 is minus '-' 
     
      if (event.which != 8 && event.which != 0 && event.which != 45
        && event.which < 48 || event.which > 57) {          
        event.preventDefault();
          // }
      }
    } else {
      // Decimal
      //    46 period ''        
      if (currentLength == MAXIMUM_SUPPORTED_NUMERIC_LENGTH) {
        // toString formats decimal values to a maximum of 18        
        event.preventDefault();
      } else if (event.which != 8 && event.which != 0 && event.which != 45 && event.which != 46
        && event.which < 48 || event.which > 57) {
        event.preventDefault();
      }     
    }
  } catch (error) {
    // this.logger.warn("keypress event error "+error);
  }

  // Config.previousValue = currentValue;
}
