/* eslint-disable @typescript-eslint/naming-convention */
import { Guid } from 'guid-typescript';

export class WallEnvelopeDataModel {
  static sectionName = 'wallEnvelope';


  wallGlassType: WallGlassTypeInterface;
  additionalWallFeature: AdditionalWallFeature;
  wallCladding: WallCladdingInterface;

  /**
   * @deprecated Amplify no longer used to fetch this data / now S3
   * @param reports
   * @returns
   */
  static from(reports: any): WallEnvelopeDataModel {
    return null;
  }

  static fromS3(s3data: any, reportInfo): WallEnvelopeDataModel {
    const setGlassTypeData = this.setGlassType(s3data);
    const setWallFeatureData = this.setAdditionalWallFeature(s3data);
    const setWallCladding = this.setWallCladding(s3data);
    return {
      wallGlassType: setGlassTypeData,
      additionalWallFeature: setWallFeatureData,
      wallCladding: setWallCladding
    };
  }

  /**
   *
   * @method to - to store data back to s3
   * @param string buildingBg
   * @param * reports
   */
  static to(data: any, reportId?: Guid): any {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const hasCladdingTypeOtherThanGlass = (data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeNone ||
      data?.wallCladding?.hasCladdingTypeOtherThanGlassTypePrecastMasonry ||
      data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStuds ||
      data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanels ||
      data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStuds ||
      data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeReinforcedMasonry ||
      data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeUnreinforcedMasonry ||
      data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeEIFSOverMasonry ||
      data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanels ||
      data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStuds) ? true : false;
    const hasBuildingGlass = (data?.wallGlassType?.hasBuildingGlassTypeImpactResistant ||
      data?.wallGlassType?.hasBuildingGlassTypeImpactLaminated ||
      data?.wallGlassType?.hasBuildingGlassTypeImpactFullyTempered ||
      data?.wallGlassType?.hasBuildingGlassTypeImpactHeatStrengthened ||
      data?.wallGlassType?.hasBuildingGlassTypeImpactInsulatingUnits ||
      data?.wallGlassType?.hasBuildingGlassTypeImpactAnnealedOrOrdinary) ? true : false;
    return {
      ReportId: data?.reportId ? data?.reportId : reportId,
      PercentageofReinforcedOverheadDoors: data?.additionalWallFeature?.percentageofReinforcedOverheadDoors ?
        data?.additionalWallFeature?.percentageofReinforcedOverheadDoors : '',
      EvidenceOfWallDegradation: data?.additionalWallFeature?.evidenceOfWallDegradation ?
        data?.additionalWallFeature?.evidenceOfWallDegradation : '',
      EvidenceOfWallDegradationComment: data?.additionalWallFeature?.evidenceOfWallDegradationComment ?
        data?.additionalWallFeature?.evidenceOfWallDegradationComment : '',
      ReinforcedPersonnelDoors: data?.additionalWallFeature?.reinforcedPersonnelDoors ?
        data?.additionalWallFeature?.reinforcedPersonnelDoors : '',
      AwningsCanopiesOrCoveredWalkways: data?.additionalWallFeature?.awningsCanopiesOrCoveredWalkways ?
        data?.additionalWallFeature?.awningsCanopiesOrCoveredWalkways : '',
      HasSignsOrArchitecturalAppurtenances: (data?.additionalWallFeature?.hasSignsOrArchitecturalAppurtenances === null ||
        data?.additionalWallFeature?.hasSignsOrArchitecturalAppurtenances) === undefined ? null :
        data?.additionalWallFeature?.hasSignsOrArchitecturalAppurtenances,
      SignsOrArchitecturalAppurtenancesComment: data?.additionalWallFeature?.signsOrArchitecturalAppurtenancesComment ?
        data?.additionalWallFeature?.signsOrArchitecturalAppurtenancesComment : '',

      HasBuildingGlass: hasBuildingGlass,
      HasBuildingGlassTypeDoesNotApplyNoGlazing: data?.wallGlassType?.hasBuildingGlassTypeDoesNotApplyNoGlazing ?
        data?.wallGlassType?.hasBuildingGlassTypeDoesNotApplyNoGlazing : false,
      HasBuildingGlassTypeImpactAnnealedOrOrdinary: data?.wallGlassType?.hasBuildingGlassTypeImpactAnnealedOrOrdinary ?
        data?.wallGlassType?.hasBuildingGlassTypeImpactAnnealedOrOrdinary : false,
      HasBuildingGlassTypeImpactInsulatingUnits: data?.wallGlassType?.hasBuildingGlassTypeImpactInsulatingUnits ?
        data?.wallGlassType?.hasBuildingGlassTypeImpactInsulatingUnits : false,
      HasBuildingGlassTypeImpactHeatStrengthened: data?.wallGlassType?.hasBuildingGlassTypeImpactHeatStrengthened ?
        data?.wallGlassType?.hasBuildingGlassTypeImpactHeatStrengthened : false,
      HasBuildingGlassTypeImpactFullyTempered: data?.wallGlassType?.hasBuildingGlassTypeImpactFullyTempered ?
        data?.wallGlassType?.hasBuildingGlassTypeImpactFullyTempered : false,
      HasBuildingGlassTypeImpactLaminated: data?.wallGlassType?.hasBuildingGlassTypeImpactLaminated ?
        data?.wallGlassType?.hasBuildingGlassTypeImpactLaminated : false,
      HasBuildingGlassTypeImpactResistant: data?.wallGlassType?.hasBuildingGlassTypeImpactResistant ?
        data?.wallGlassType?.hasBuildingGlassTypeImpactResistant : false,
      IsBuildingGlassTypeDoesNotApplyNoGlazingPredominant: data?.wallGlassType?.isBuildingGlassTypeDoesNotApplyNoGlazingPredominant ?
        data?.wallGlassType?.isBuildingGlassTypeDoesNotApplyNoGlazingPredominant : false,
      IsBuildingGlassTypeImpactHeatStrengthenedPredominant: data?.wallGlassType?.isBuildingGlassTypeImpactHeatStrengthenedPredominant ?
        data?.wallGlassType?.isBuildingGlassTypeImpactHeatStrengthenedPredominant : false,
      IsBuildingGlassTypeImpactLaminatedPredominant: data?.wallGlassType?.isBuildingGlassTypeImpactLaminatedPredominant ?
        data?.wallGlassType?.isBuildingGlassTypeImpactLaminatedPredominant : false,
      IsBuildingGlassTypeImpactFullyTemperedPredominant: data?.wallGlassType?.isBuildingGlassTypeImpactFullyTemperedPredominant ?
        data?.wallGlassType?.isBuildingGlassTypeImpactFullyTemperedPredominant : false,
      IsBuildingGlassTypeImpactInsulatingUnitsPredominant: data?.wallGlassType?.isBuildingGlassTypeImpactInsulatingUnitsPredominant ?
        data?.wallGlassType?.isBuildingGlassTypeImpactInsulatingUnitsPredominant : false,
      IsBuildingGlassTypeImpactAnnealedOrOrdinaryPredominant:
        data?.wallGlassType?.isBuildingGlassTypeImpactAnnealedOrOrdinaryPredominant ?
          data?.wallGlassType?.isBuildingGlassTypeImpactAnnealedOrOrdinaryPredominant : false,
      IsBuildingGlassTypeImpactResistantPredominant: data?.wallGlassType?.isBuildingGlassTypeImpactResistantPredominant ?
        data?.wallGlassType?.isBuildingGlassTypeImpactResistantPredominant : false,
      PercentageOfGlassWallArea: data?.wallGlassType?.percentageOfGlassWallArea ? data?.wallGlassType?.percentageOfGlassWallArea : '',
      MaximumGlassPanelSize: data?.wallGlassType?.maximumGlassPanelSize ? data?.wallGlassType?.maximumGlassPanelSize : '',
      WindBourneDebrisProtection: data?.wallGlassType?.windBourneDebrisProtection ? data?.wallGlassType?.windBourneDebrisProtection : '',

      HasCladdingTypeOtherThanGlassTypeNone: data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeNone ?
        data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeNone : false,
      HasCladdingTypeOtherThanGlassTypePrecastMasonry: data?.wallCladding?.hasCladdingTypeOtherThanGlassTypePrecastMasonry ?
        data?.wallCladding?.hasCladdingTypeOtherThanGlassTypePrecastMasonry : false,
      HasCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStuds:
        data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStuds ?
          data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStuds : false,
      HasCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanels:
        data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanels ?
          data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanels : false,
      HasCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStuds:
        data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStuds ?
          data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStuds : false,
      HasCladdingTypeOtherThanGlassTypeReinforcedMasonry: data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeReinforcedMasonry ?
        data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeReinforcedMasonry : false,
      HasCladdingTypeOtherThanGlassTypeEIFSOverMasonry: data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeEIFSOverMasonry ?
        data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeEIFSOverMasonry : false,
      HasCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanels:
        data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanels ?
          data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanels : false,
      HasCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStuds:
        data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStuds ?
          data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStuds : false,
      HasCladdingTypeOtherThanGlassTypeUnreinforcedMasonry: data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeUnreinforcedMasonry ?
        data?.wallCladding?.hasCladdingTypeOtherThanGlassTypeUnreinforcedMasonry : false,
      HasCladdingTypeOtherThanGlass: hasCladdingTypeOtherThanGlass,
      IsCladdingTypeOtherThanGlassTypeNonePredominant: data?.wallCladding?.isCladdingTypeOtherThanGlassTypeNonePredominant ?
        data?.wallCladding?.isCladdingTypeOtherThanGlassTypeNonePredominant : false,
      IsCladdingTypeOtherThanGlassTypePrecastMasonryPredominant:
        data?.wallCladding?.isCladdingTypeOtherThanGlassTypePrecastMasonryPredominant ?
          data?.wallCladding?.isCladdingTypeOtherThanGlassTypePrecastMasonryPredominant : false,
      IsCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStudsPredominant:
        data?.wallCladding?.isCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStudsPredominant ?
          data?.wallCladding?.isCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStudsPredominant : false,
      IsCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanelsPredominant:
        data?.wallCladding?.isCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanelsPredominant ?
          data?.wallCladding?.isCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanelsPredominant : false,
      IsCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStudsPredominant:
        data?.wallCladding?.isCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStudsPredominant ?
          data?.wallCladding?.isCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStudsPredominant : false,
      IsCladdingTypeOtherThanGlassTypeReinforcedMasonryPredominant:
        data?.wallCladding?.isCladdingTypeOtherThanGlassTypeReinforcedMasonryPredominant ?
          data?.wallCladding?.isCladdingTypeOtherThanGlassTypeReinforcedMasonryPredominant : false,
      IsCladdingTypeOtherThanGlassTypeEIFSOverMasonryPredominant:
        data?.wallCladding?.isCladdingTypeOtherThanGlassTypeEIFSOverMasonryPredominant ?
          data?.wallCladding?.isCladdingTypeOtherThanGlassTypeEIFSOverMasonryPredominant : false,
      IsCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanelsPredominant:
        data?.wallCladding?.isCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanelsPredominant ?
          data?.wallCladding?.isCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanelsPredominant : false,
      IsCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStudsPredominant:
        data?.wallCladding?.isCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStudsPredominant ?
          data?.wallCladding?.isCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStudsPredominant : false,
      IsCladdingTypeOtherThanGlassTypeUnreinforcedMasonryPredominant:
        data?.wallCladding?.isCladdingTypeOtherThanGlassTypeUnreinforcedMasonryPredominant ?
          data?.wallCladding?.isCladdingTypeOtherThanGlassTypeUnreinforcedMasonryPredominant : false,

    };
  }

  static setWallCladding(setdata: any) {
    const wallCladdingModelValue = {
      hasCladdingTypeOtherThanGlassTypeNone: setdata?.HasCladdingTypeOtherThanGlassTypeNone,
      hasCladdingTypeOtherThanGlassTypePrecastMasonry: setdata?.HasCladdingTypeOtherThanGlassTypePrecastMasonry,
      hasCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStuds: setdata?.HasCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStuds,
      hasCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanels: setdata?.HasCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanels,
      hasCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStuds: setdata?.HasCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStuds,
      hasCladdingTypeOtherThanGlassTypeReinforcedMasonry: setdata?.HasCladdingTypeOtherThanGlassTypeReinforcedMasonry,
      hasCladdingTypeOtherThanGlassTypeEIFSOverMasonry: setdata?.HasCladdingTypeOtherThanGlassTypeEIFSOverMasonry,
      hasCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanels: setdata?.HasCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanels,
      hasCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStuds: setdata?.HasCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStuds,
      hasCladdingTypeOtherThanGlassTypeUnreinforcedMasonry: setdata?.HasCladdingTypeOtherThanGlassTypeUnreinforcedMasonry,
      isCladdingTypeOtherThanGlassTypeNonePredominant: setdata?.IsCladdingTypeOtherThanGlassTypeNonePredominant,
      isCladdingTypeOtherThanGlassTypePrecastMasonryPredominant: setdata?.IsCladdingTypeOtherThanGlassTypePrecastMasonryPredominant,
      isCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStudsPredominant: setdata?.IsCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStudsPredominant,
      isCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanelsPredominant: setdata?.IsCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanelsPredominant,
      isCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStudsPredominant: setdata?.IsCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStudsPredominant,
      isCladdingTypeOtherThanGlassTypeReinforcedMasonryPredominant: setdata?.IsCladdingTypeOtherThanGlassTypeReinforcedMasonryPredominant,
      isCladdingTypeOtherThanGlassTypeEIFSOverMasonryPredominant: setdata?.IsCladdingTypeOtherThanGlassTypeEIFSOverMasonryPredominant,
      isCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanelsPredominant: setdata?.IsCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanelsPredominant,
      isCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStudsPredominant: setdata?.IsCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStudsPredominant,
      isCladdingTypeOtherThanGlassTypeUnreinforcedMasonryPredominant: setdata?.IsCladdingTypeOtherThanGlassTypeUnreinforcedMasonryPredominant,
    };
    return wallCladdingModelValue;
  }

  static setGlassType(reports: any) {
    const glassType = {
      hasBuildingGlassTypeDoesNotApplyNoGlazing: reports?.HasBuildingGlassTypeDoesNotApplyNoGlazing,
      hasBuildingGlassTypeImpactAnnealedOrOrdinary: reports?.HasBuildingGlassTypeImpactAnnealedOrOrdinary,
      hasBuildingGlassTypeImpactInsulatingUnits: reports?.HasBuildingGlassTypeImpactInsulatingUnits,
      hasBuildingGlassTypeImpactHeatStrengthened: reports?.HasBuildingGlassTypeImpactHeatStrengthened,
      hasBuildingGlassTypeImpactFullyTempered: reports?.HasBuildingGlassTypeImpactFullyTempered,
      hasBuildingGlassTypeImpactLaminated: reports?.HasBuildingGlassTypeImpactLaminated,
      hasBuildingGlassTypeImpactResistant: reports?.HasBuildingGlassTypeImpactResistant,
      isBuildingGlassTypeDoesNotApplyNoGlazingPredominant: reports?.IsBuildingGlassTypeDoesNotApplyNoGlazingPredominant,
      isBuildingGlassTypeImpactHeatStrengthenedPredominant: reports?.IsBuildingGlassTypeImpactHeatStrengthenedPredominant,
      isBuildingGlassTypeImpactLaminatedPredominant: reports?.IsBuildingGlassTypeImpactLaminatedPredominant,
      isBuildingGlassTypeImpactFullyTemperedPredominant: reports?.IsBuildingGlassTypeImpactFullyTemperedPredominant,
      isBuildingGlassTypeImpactInsulatingUnitsPredominant: reports?.IsBuildingGlassTypeImpactInsulatingUnitsPredominant,
      isBuildingGlassTypeImpactAnnealedOrOrdinaryPredominant: reports?.IsBuildingGlassTypeImpactAnnealedOrOrdinaryPredominant,
      isBuildingGlassTypeImpactResistantPredominant: reports?.IsBuildingGlassTypeImpactResistantPredominant,
      percentageOfGlassWallArea: reports?.PercentageOfGlassWallArea,
      maximumGlassPanelSize: reports?.MaximumGlassPanelSize,
      windBourneDebrisProtection: reports?.WindBourneDebrisProtection
    };
    return glassType;
  }
  static setAdditionalWallFeature(s3data: any) {
    const additionalWallFeature = {
      percentageofReinforcedOverheadDoors: s3data?.PercentageofReinforcedOverheadDoors,
      evidenceOfWallDegradation: s3data?.EvidenceOfWallDegradation,
      evidenceOfWallDegradationComment: s3data?.EvidenceOfWallDegradationComment,
      reinforcedPersonnelDoors: s3data?.ReinforcedPersonnelDoors,
      awningsCanopiesOrCoveredWalkways: s3data?.AwningsCanopiesOrCoveredWalkways,
      hasSignsOrArchitecturalAppurtenances: s3data?.HasSignsOrArchitecturalAppurtenances,
      signsOrArchitecturalAppurtenancesComment: s3data?.SignsOrArchitecturalAppurtenancesComment,
    };
    return additionalWallFeature;
  }
  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    return true;
  }
}

class WallGlassTypeInterface {
  hasBuildingGlassTypeDoesNotApplyNoGlazing: boolean;
  hasBuildingGlassTypeImpactAnnealedOrOrdinary: boolean;
  hasBuildingGlassTypeImpactInsulatingUnits: boolean;
  hasBuildingGlassTypeImpactHeatStrengthened: boolean;
  hasBuildingGlassTypeImpactFullyTempered: boolean;
  hasBuildingGlassTypeImpactLaminated: boolean;
  hasBuildingGlassTypeImpactResistant: boolean;
  isBuildingGlassTypeDoesNotApplyNoGlazingPredominant: boolean;
  isBuildingGlassTypeImpactHeatStrengthenedPredominant: boolean;
  isBuildingGlassTypeImpactLaminatedPredominant: boolean;
  isBuildingGlassTypeImpactFullyTemperedPredominant: boolean;
  isBuildingGlassTypeImpactInsulatingUnitsPredominant: boolean;
  isBuildingGlassTypeImpactAnnealedOrOrdinaryPredominant: boolean;
  isBuildingGlassTypeImpactResistantPredominant: boolean;
  percentageOfGlassWallArea: string;
  maximumGlassPanelSize: string;
  windBourneDebrisProtection: string;
}
class AdditionalWallFeature {
  percentageofReinforcedOverheadDoors: string;
  evidenceOfWallDegradation: string;
  evidenceOfWallDegradationComment: string;
  reinforcedPersonnelDoors: string;
  awningsCanopiesOrCoveredWalkways: string;
  hasSignsOrArchitecturalAppurtenances: boolean;
  signsOrArchitecturalAppurtenancesComment: string;
}

class WallCladdingInterface {
  hasCladdingTypeOtherThanGlassTypeNone: boolean;
  hasCladdingTypeOtherThanGlassTypePrecastMasonry: boolean;
  hasCladdingTypeOtherThanGlassTypeMasonryVeneerOverWoodOrMetalStuds: boolean;
  hasCladdingTypeOtherThanGlassTypeMetalExteriorSandwichPanels: boolean;
  hasCladdingTypeOtherThanGlassTypeWoodVinylOrStuccoOnWoodOrMetalStuds: boolean;
  hasCladdingTypeOtherThanGlassTypeReinforcedMasonry: boolean;
  hasCladdingTypeOtherThanGlassTypeEIFSOverMasonry: boolean;
  hasCladdingTypeOtherThanGlassTypeSingleLayerOfStealPanels: boolean;
  hasCladdingTypeOtherThanGlassTypeEIFSOverWoodOrMetalStuds: boolean;
  hasCladdingTypeOtherThanGlassTypeUnreinforcedMasonry: boolean;
}
