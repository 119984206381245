import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { CalculationEnum } from 'src/app/enums/formly-builder/calculation.enum';
import { CalculationInterface } from 'src/app/interfaces/formly-builder/calculation.interface';
import { CalculationStatusInterface } from 'src/app/interfaces/formly-builder/calculation-status.interface';
import { FormlyBuilderService } from '../formly-builder.service';
import { PublicProtectionClassDataModel } from 'src/app/models/formly-builder/formly/ppc-data.model';
import { debounceTime, distinctUntilChanged, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SectionEnum } from '../section.config';
import { Constants } from '../../util-service/constants';
import { UtilService } from '../../util-service/util.service';
import { FormlyPublicProtectionAreaService } from '../formly-ppa.service';

@Injectable({
  providedIn: 'root',
})
export class PublicProtectionService extends SectionServiceAbstract implements CalculationInterface {
  calculationStatus: number;

  constructor(private formlyBuilder: FormlyBuilderService,private utilService: UtilService, 
    private publicProtectionService: FormlyPublicProtectionAreaService
  ) {
    super();
  }

  async updateData(model: any, riskReportData: any) {
    // Check if data has changed
    const hasChanged: boolean = PublicProtectionClassDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      const publicProtectionInfo = PublicProtectionClassDataModel.to(model);
      let addressArray: any[] =[];
      riskReportData.ReportAddresses.forEach((address) => {
        address['FireProtectionArea'] = model.publicProtection.fireProtectionArea;
        addressArray.push(address);
      });
     
      
      return { ...riskReportData, ...{ReportAddresses:addressArray},...publicProtectionInfo };
    } else {
      return undefined;
    }
  }

  loadData(sectionData: any[], riskReportData: any, isDataInS3Format: boolean, model: any) {
    const publicProtectionClassData: PublicProtectionClassDataModel = PublicProtectionClassDataModel.fromS3(riskReportData);
    if ((!publicProtectionClassData.isPublicProtectionClass || !publicProtectionClassData.isFireProtectionArea ||
      !publicProtectionClassData.isTerritoryCode || !publicProtectionClassData.isPpcFireHydrant) &&
      (publicProtectionClassData?.fireProtectionArea || publicProtectionClassData?.publicProtectionClass ||
        publicProtectionClassData?.territoryCode || publicProtectionClassData?.isHydrantsWithin1000feet) 
      && (riskReportData.PublicProtectionClass !== publicProtectionClassData?.publicProtectionClass || 
        riskReportData.TerritoryCode !== publicProtectionClassData?.territoryCode ||
        riskReportData.PpcFpa !== publicProtectionClassData?.fireProtectionArea ||
        riskReportData.IsHydrantsWithin1000Feet !== publicProtectionClassData?.isHydrantsWithin1000feet
      )) {
      setTimeout(() => {
        this.formlyBuilder.riskReport.updateSectionData(SectionEnum.FR_OW_PPC, false, SectionEnum.FR_OW_PPC);
      }, 1000);
    }
    this.checkIsSectionIICollapsed(publicProtectionClassData);
    // If existing risk is getting opened for first time clear the section II, section II results
    if (this.formlyBuilder.isExistingRisk() &&
      (!(publicProtectionClassData?.calculatedWaterSupplyWorksAdequacy ||
        publicProtectionClassData?.calculatedWaterSupplyWorksAdequacy === 0))) {
      publicProtectionClassData.hydrantSpacingAdequacy = null;
      publicProtectionClassData.fireFlowAt20Psi = null;
      publicProtectionClassData.overallAdequacy = null;
      publicProtectionClassData.evaluatedClass = null;
      publicProtectionClassData.calculatedWaterSupplyWorksAdequacy = null;
      publicProtectionClassData.calculatedFireDepartmentCompaniesAdequacy = null;
      publicProtectionClassData.hydrantsWithPumperOutlet = null;
      publicProtectionClassData.hydrantsWithTwoHoseOutlets = null
      publicProtectionClassData.hydrantsWithOneHoseOutlets = null;
      publicProtectionClassData.waterSupplyWorksAdequacy = null;
      publicProtectionClassData.fireDepartmentCompaniesAdequacy = null;

      this.formlyBuilder.riskReport.report.HydrantSpacingAdequacy = null;
      this.formlyBuilder.riskReport.report.FireFlowAt20Psi = null;
      this.formlyBuilder.riskReport.report.OverallAdequacy = null;
      this.formlyBuilder.riskReport.report.EvaluatedClass = null;
      this.formlyBuilder.riskReport.report.CalculatedWaterSupplyWorksAdequacy = null;
      this.formlyBuilder.riskReport.report.CalculatedFireDepartmentCompaniesAdequacy = null;
      this.formlyBuilder.riskReport.report.HydrantsWithPumperOutlet = null;
      this.formlyBuilder.riskReport.report.HydrantsWithTwoHoseOutlets = null
      this.formlyBuilder.riskReport.report.HydrantsWithOneHoseOutlets = null;
      this.formlyBuilder.riskReport.report.WaterSupplyWorksAdequacy = null;
      this.formlyBuilder.riskReport.report.FireDepartmentCompaniesAdequacy = null;
    }    
    return { ...model, ...{ publicProtection: publicProtectionClassData } };
  }

  /**
   * Method to help simulating a long running calculation
   * @param ms 
   * @returns 
   */
  sleep = (ms) => new Promise(r => setTimeout(r, ms));

  /**
   * Method to simulate a long running calculation
   * @param formlyBuilder 
   * @returns 
   */
  calculate(formlyBuilder: FormlyBuilderService): Observable<CalculationStatusInterface> {
    return new Observable((subscriber) => {
      console.log("Public Protection Service is doing something");
      this.sleep(10000).then(() => {
        console.log("PPC End");
        subscriber.next({
          enum: CalculationEnum.PPC,
          name: '',
          status: 12,
          comment: 'Running',
          service: this
        });
      });
    });
  }

 

  public addField(field) {
    const riskReport=field?.options?.formState?.service?.riskReport?.report;
    let mainAddress;
    if(riskReport?.ReportAddresses?.length){
    mainAddress = riskReport.ReportAddresses.find((address) => address.StateCode === 'NC');
    }
    if (mainAddress) {
      field.props.options = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '9E', label: '9E' },
        { value: '10', label: '10' },

      ];
    }
    else {
      field.props.options = [
        { value: '1', label: '1' },
        { value: '1X', label: '1X' },
        { value: '1Y', label: '1Y' },
        { value: '2', label: '2' },
        { value: '2X', label: '2X' },
        { value: '2Y', label: '2Y' },
        { value: '3', label: '3' },
        { value: '3X', label: '3X' },
        { value: '3Y', label: '3Y' },
        { value: '4', label: '4' },
        { value: '4X', label: '4X' },
        { value: '4Y', label: '4Y' },
        { value: '5', label: '5' },
        { value: '5X', label: '5X' },
        { value: '5Y', label: '5Y' },
        { value: '6', label: '6' },
        { value: '6X', label: '6X' },
        { value: '6Y', label: '6Y' },
        { value: '7', label: '7' },
        { value: '7X', label: '7X' },
        { value: '7Y', label: '7Y' },
        { value: '8', label: '8' },
        { value: '8B', label: '8B' },
        { value: '8X', label: '8X' },
        { value: '8Y', label: '8Y' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '10W', label: '10W' },

      ];
    }

  }

  public publicProtectionClassValues(field){
    const publicProtectionValue = field?.form?.controls?.publicProtectionClass?.value;;


    if (publicProtectionValue?.includes('/')) {
      const ppcValues = publicProtectionValue.split('/');
      const lowerPpc = ppcValues[0];
      const higherPpc = ppcValues[1];

      if (field.form?.value?.isHydrantsWithin1000feet === 'true') {
        field.form.get('publicProtectionClass')?.setValue(lowerPpc);
      }
      else if (field.form?.value?.isHydrantsWithin1000feet === 'false') {
        field.form.get('publicProtectionClass')?.setValue(higherPpc);
      }
      else {
        field.form.get('publicProtectionClass')?.setValue(null);
      }
    }
  }

  public estimate(field: FormlyFieldConfig) {
    field.parent?.formControl?.markAllAsTouched();
    let fireFlowAt20Psi;
    field?.options?.formState?.order?.
    PrePopulatingInformations?.forEach((prePopulatingInformation)=>
      {
        if(prePopulatingInformation?.PrepopulatingInformationType === 'FlowAt20Psi'){
          fireFlowAt20Psi = prePopulatingInformation?.PrepopulatingInformationValue;
        }
      })
    
    const waterSupplyWorksAdequacy = field.form?.get('waterSupplyWorksAdequacy')?.value;
    const fireDepartmentCompaniesAdequacy = field.form?.get('fireDepartmentCompaniesAdequacy')?.value;
    const neededFireFlow = field.parent?.parent?.form?.get('neededFireFlow')?.value;
    const hydrantsWithPumOutlet = field.form?.get('hydrantsWithPumperOutlet')?.value;
    const hydrantsWithTwoHoseOutlet = field.form?.get('hydrantsWithTwoHoseOutlets')?.value;
    const hydrantWithOneHoseOutlet = field.form?.get('hydrantsWithOneHoseOutlets')?.value;
    let hydrantSpacingAdequacy;
    if (neededFireFlow && (hydrantsWithPumOutlet !== null && hydrantsWithPumOutlet !== undefined && hydrantsWithPumOutlet !== '') &&
      (hydrantsWithTwoHoseOutlet !== null && hydrantsWithTwoHoseOutlet !== undefined && hydrantsWithTwoHoseOutlet !== '') &&
      (hydrantWithOneHoseOutlet !== null && hydrantWithOneHoseOutlet !== undefined && hydrantWithOneHoseOutlet !== '') &&
      (waterSupplyWorksAdequacy !== null && waterSupplyWorksAdequacy !== undefined && waterSupplyWorksAdequacy !== '') &&
      (fireDepartmentCompaniesAdequacy !== null && fireDepartmentCompaniesAdequacy !== undefined && fireDepartmentCompaniesAdequacy !== '')) {
      field.options.formState.isSectionIICollapsed = false;
      if (fireFlowAt20Psi) {
        field.form?.get('fireFlowAt20Psi')?.setValue(fireFlowAt20Psi);
      } else {
        fireFlowAt20Psi = 3500;
        field.form?.get('fireFlowAt20Psi')?.setValue(3500);
      }
      hydrantSpacingAdequacy = ((hydrantsWithPumOutlet * 1500) + (hydrantsWithTwoHoseOutlet * 750) + (hydrantWithOneHoseOutlet * 500)) / neededFireFlow;
      hydrantSpacingAdequacy = hydrantSpacingAdequacy * 100;
      if (hydrantSpacingAdequacy > 100) {
        hydrantSpacingAdequacy = 100;
      }
      field.form?.get('calculatedFireDepartmentCompaniesAdequacy')?.setValue(fireDepartmentCompaniesAdequacy);
      field.form?.get('calculatedWaterSupplyWorksAdequacy')?.setValue(waterSupplyWorksAdequacy);

        //const waterSupplyWorksAdequacy = field.parent?.formControl?.get('waterSupplyWorksAdequacy')?.value;
        let overallValue;
        if (neededFireFlow) {
          overallValue = (fireFlowAt20Psi / neededFireFlow) * 100;
          if (overallValue > 100) {
            overallValue = 100;
            field.parent?.formControl?.get('overallAdequacy')?.setValue(100);
          } else {
            field.parent?.formControl?.get('overallAdequacy')?.setValue(Math.floor(overallValue));
          }
        }
        field.form?.get('hydrantSpacingAdequacy')?.setValue(Math.floor(hydrantSpacingAdequacy));
        let evaluatedClass;
        const evaluatedAdequacy = Math.min(overallValue, hydrantSpacingAdequacy, fireDepartmentCompaniesAdequacy, waterSupplyWorksAdequacy)

      if (evaluatedAdequacy >= 90) { evaluatedClass = '1'; }
      else if (evaluatedAdequacy >= 80) { evaluatedClass = '2'; }
      else if (evaluatedAdequacy >= 70) { evaluatedClass = '3'; }
      else if (evaluatedAdequacy >= 60) { evaluatedClass = '4'; }
      else if (evaluatedAdequacy >= 50) { evaluatedClass = '5'; }
      else if (evaluatedAdequacy >= 40) { evaluatedClass = '6'; }
      else if (evaluatedAdequacy >= 30) { evaluatedClass = '7'; }
      else if (evaluatedAdequacy >= 20) { evaluatedClass = '8'; }
      else if (evaluatedAdequacy >= 10) { evaluatedClass = '9'; }
      else if (evaluatedAdequacy >= 0) { evaluatedClass = '10'; }

      let publicProtection = field.form?.get('publicProtectionClass')?.value;
      const lastChar = publicProtection.charAt(publicProtection.length - 1);
      if (isNaN(parseInt(lastChar, 10))) {
        // If the last character is non-numeric, remove it
        publicProtection = publicProtection.slice(0, -1);
      }
      // publicProtection = Number(publicProtection);
      if (Number(evaluatedClass) > Number(publicProtection)) {
        field.form?.get('publicProtectionClass')?.setValue(evaluatedClass);
        field.form?.get('evaluatedClass')?.setValue(evaluatedClass);
      } else {
        field.form?.get('evaluatedClass')?.setValue(publicProtection);
      }
      field.formControl.markAsDirty();
    } else {
      field.form?.get('hydrantSpacingAdequacy')?.setValue(null);
      field.form?.get('fireFlowAt20Psi')?.setValue(null);
      field.form?.get('overallAdequacy')?.setValue(null);
      field.form?.get('calculatedFireDepartmentCompaniesAdequacy')?.setValue(null);
      field.form?.get('calculatedWaterSupplyWorksAdequacy')?.setValue(null);
      field.form?.get('evaluatedClass')?.setValue(null);
      field.options.formState.isSectionIICollapsed = true;
    }
    this.formlyBuilder.onSectionFieldChange(field, SectionEnum.FR_OW_PPC, true, true);
  }

  /**
   * get data via email
   * @field
   */
    getDataViaEmail(field){
      const mainAddress = field?.form?.root?.value?.address?.mainAddress;
      const state = mainAddress?.state?.trim();
      const city = mainAddress?.city?.trim();
      const streetNumber = mainAddress?.streetNumber ? mainAddress?.streetNumber?.trim()+',' : '';
      const streetName = mainAddress?.streetName?.trim();
      const zipCode = mainAddress?.zipcode?.trim();
      const county =mainAddress?.county?.trim();
      const requestMail = Constants.requestMail;
      const fireProtectionArea = field?.model?.fireProtectionArea ? field?.model?.fireProtectionArea : '';
      const neededFireFlow = field?.model?.neededFireFlow ? field?.model?.neededFireFlow : '';
      const subject =`Section II Request - ${city}, ${state}`;
      // eslint-disable-next-line max-len
      const body =`Please provide the Water Supply Works and FD Companies Adequacy for\nPrimary Address: ${streetNumber} ${streetName}, ${city}, ${state}, ${zipCode}\nFire Protection Area: ${fireProtectionArea} \nCounty: ${county} \nNeed Fire Flow: ${neededFireFlow}`;
      const url: string =
        'mailto:' +
        requestMail +
        '?subject=' +subject+
        '&body=' + encodeURIComponent(body);

      this.utilService.openInNewWindow(url);
    }

  checkIsSectionIICollapsed(publicProtection) {
    if ((publicProtection?.hydrantsWithPumperOutlet || publicProtection?.hydrantsWithPumperOutlet === 0) &&
      (publicProtection?.hydrantsWithTwoHoseOutlets || publicProtection?.hydrantsWithTwoHoseOutlets === 0) &&
      (publicProtection?.hydrantsWithOneHoseOutlets || publicProtection?.hydrantsWithOneHoseOutlets === 0) &&
      (publicProtection?.waterSupplyWorksAdequacy || publicProtection?.waterSupplyWorksAdequacy === 0) &&
      (publicProtection?.fireDepartmentCompaniesAdequacy || publicProtection?.fireDepartmentCompaniesAdequacy === 0) &&
      (publicProtection?.hydrantSpacingAdequacy || publicProtection?.hydrantSpacingAdequacy === 0) &&
      (publicProtection?.fireFlowAt20Psi || publicProtection?.fireFlowAt20Psi === 0) &&
      (publicProtection?.overallAdequacy || publicProtection?.overallAdequacy === 0)) {
      this.formlyBuilder.options.formState.isSectionIICollapsed = false;
    } else {
      this.formlyBuilder.options.formState.isSectionIICollapsed = true;
    }
  }

  calculateSectionIIResults(publicProtection, field) {
    const hydrantSpacingAdequacy = this.calculateHydrantSpacingAdequacy(
      publicProtection?.hydrantsWithPumperOutlet, publicProtection?.hydrantsWithTwoHoseOutlets,
      publicProtection?.hydrantsWithOneHoseOutlets, publicProtection?.neededFireFlow);
    const fireFlowAt20Psi = this.calculateFireFlowAt20Psi(field?.options?.formState);
    const overallAdequacy = this.calculateOverallAdequacy(publicProtection?.neededFireFlow, fireFlowAt20Psi);
    const evaluatedClassResult = this.calculateEvaluatedClass(overallAdequacy,
      hydrantSpacingAdequacy, publicProtection?.fireDepartmentCompaniesAdequacy, publicProtection?.waterSupplyWorksAdequacy,
    publicProtection?.publicProtectionClass);
    field?.form?.get('hydrantSpacingAdequacy')?.setValue(hydrantSpacingAdequacy);
    field?.form?.get('fireFlowAt20Psi')?.setValue(fireFlowAt20Psi);
    field?.form?.get('overallAdequacy')?.setValue(overallAdequacy);
    field?.form?.get('evaluatedClass')?.setValue(evaluatedClassResult?.evaluatedClass);
    field?.form?.get('calculatedFireDepartmentCompaniesAdequacy')?.setValue(publicProtection.fireDepartmentCompaniesAdequacy);
    field?.form?.get('calculatedWaterSupplyWorksAdequacy')?.setValue(publicProtection.waterSupplyWorksAdequacy);
    field?.form?.get('publicProtectionClass')?.setValue(evaluatedClassResult?.publicProtectionClass);
  }


  calculateHydrantSpacingAdequacy(hydrantsWithPumperOutlet, hydrantsWithTwoHoseOutlets, hydrantsWithOneHoseOutlets, neededFireFlow) {
    let hydrantSpacingAdequacy = null;
    hydrantSpacingAdequacy = ((hydrantsWithPumperOutlet * 1500) + (hydrantsWithTwoHoseOutlets * 750)
      + (hydrantsWithOneHoseOutlets * 500)) / neededFireFlow;
    hydrantSpacingAdequacy = hydrantSpacingAdequacy * 100;
    if (hydrantSpacingAdequacy > 100) {
      hydrantSpacingAdequacy = 100;
    }
    return Math.floor(hydrantSpacingAdequacy);
  }

  calculateFireFlowAt20Psi(formState) {
    let fireFlowAt20Psi = null;
    formState?.order?.PrePopulatingInformations?.forEach((prePopulatingInformation) => {
      if (prePopulatingInformation?.PrepopulatingInformationType === 'FlowAt20Psi') {
        fireFlowAt20Psi = prePopulatingInformation?.PrepopulatingInformationValue;
      }
    });
    if (!fireFlowAt20Psi) {
      fireFlowAt20Psi = 3500;
    }
    return Number(fireFlowAt20Psi);
  }

  calculateOverallAdequacy(neededFireFlow, fireFlowAt20Psi) {
    let overallAdequacy = null;
    if (neededFireFlow && fireFlowAt20Psi) {
      overallAdequacy = (fireFlowAt20Psi / neededFireFlow) * 100;
      if (overallAdequacy > 100) {
        overallAdequacy = 100;
      } else {
        overallAdequacy = Math.floor(overallAdequacy);
      }
    }
    return overallAdequacy;
  }

  calculateEvaluatedClass(overallAdequacy, hydrantSpacingAdequacy, fireDepartmentCompaniesAdequacy, 
    waterSupplyWorksAdequacy, publicProtection) {
    let evaluatedClass;
    const evaluatedAdequacy = Math.min(overallAdequacy, hydrantSpacingAdequacy,
      fireDepartmentCompaniesAdequacy, waterSupplyWorksAdequacy)
    if (evaluatedAdequacy >= 90) { evaluatedClass = '1'; }
    else if (evaluatedAdequacy >= 80) { evaluatedClass = '2'; }
    else if (evaluatedAdequacy >= 70) { evaluatedClass = '3'; }
    else if (evaluatedAdequacy >= 60) { evaluatedClass = '4'; }
    else if (evaluatedAdequacy >= 50) { evaluatedClass = '5'; }
    else if (evaluatedAdequacy >= 40) { evaluatedClass = '6'; }
    else if (evaluatedAdequacy >= 30) { evaluatedClass = '7'; }
    else if (evaluatedAdequacy >= 20) { evaluatedClass = '8'; }
    else if (evaluatedAdequacy >= 10) { evaluatedClass = '9'; }
    else if (evaluatedAdequacy >= 0) { evaluatedClass = '10'; }

    const lastChar = publicProtection.charAt(publicProtection.length - 1);
    if (isNaN(parseInt(lastChar, 10))) {
      // If the last character is non-numeric, remove it
      publicProtection = publicProtection.slice(0, -1);
    }
    if (Number(evaluatedClass) < Number(publicProtection)) {
      evaluatedClass = publicProtection;
    }
    return {
      evaluatedClass,
      publicProtectionClass: evaluatedClass ? evaluatedClass : publicProtection
    }
  }

  valueChangeSubscription(field) {
    const initialValue = field.formControl?.value;
    field.formControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(),
      debounceTime(Constants.riskFormDataSyncDelay),startWith(initialValue), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (!field.formControl.pristine) {
          if (!field.options.formState.isSectionIICollapsed) {
            const publicProtection = field?.form?.value;
            if ((publicProtection?.hydrantsWithPumperOutlet || publicProtection?.hydrantsWithPumperOutlet === 0) &&
              (publicProtection?.hydrantsWithTwoHoseOutlets || publicProtection?.hydrantsWithTwoHoseOutlets === 0) &&
              (publicProtection?.hydrantsWithOneHoseOutlets || publicProtection?.hydrantsWithOneHoseOutlets === 0) &&
              (publicProtection?.waterSupplyWorksAdequacy || publicProtection?.waterSupplyWorksAdequacy === 0) &&
              (publicProtection?.fireDepartmentCompaniesAdequacy || publicProtection?.fireDepartmentCompaniesAdequacy === 0)) {
              if (prev != next) {
                this.formlyBuilder.publicProtectionService.calculateSectionIIResults(field.form.value, field)
                // changes to calculate section II results if section II results 
                // is expanded and if any change made in section II fields
              }
            }
          }
        }
      });
  }
}
