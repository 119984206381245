import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import moment from 'moment';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { Constants } from 'src/app/services/util-service/constants';
import { InternalSprinklerPipeConditionsSectionModel } from './internal-pipe-conditions.model';
import { DryPipeValveTripTestingSectionModel } from './dry-pipe-valve-trip-testing.model';

export class DryPipeValveTestingSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'dryPipeValveTesting',
      wrappers: ['simple-section-wrapper'],
      className: 'dry-pipe-valve-test-container',
      props: {
        className: 'padding-bottom',
        label: 'Dry Pipe Valve Testing',
        isCollapsable: true,
        isCollapsed: true,
        hideDryPipeValve: false,
        checkTouchedControl: true
      },
      expressions: {
        'props.isCollapsed': (field: FormlyFieldConfig) => field.props?.hideDryPipeValve,
        'props.isCollapseIconDisabled': (field: FormlyFieldConfig) => field.props?.hideDryPipeValve
      },
      
      fieldGroup: [   
        {
          fieldGroup: [
            {
              key: 'dpvTestDate',
              type: 'rf-date-picker',
              wrappers: ['question-wrapper'],
              className: 'date-width padding-right-0 dry-pipe-valve-testing dry-pipe-section',
              props: {
                label: 'Test Date',
                displayFormat: 'MM/DD/YY',
                datePickerId: 'dpvTestDate',
                required: false,
                isSectionLabelNormal: true,
                readonly:false,
                showError: false,
                isTitleDisableable:false,
                isAsteriskNotRequired: true,
              },

              expressions:{
                'props.readonly': (field: FormlyFieldConfig) => {
                    const root = field?.form?.root?.value;
                    return root?.sprinklerReport?.asgrSprinklerSystemTest?.dryPipeValveTesting?.hasDpvTest;
                },
                'props.required': (field: FormlyFieldConfig) => {
                    const formlyService = field.options?.formState?.service;
                    const sprinklerReport = formlyService?.riskReport?.model?.sprinklerReport;
                    const areaProtectedByDryPipeSystem = sprinklerReport?.asgrSprinklerSystemComponent?.dryPipeValve?.areaProtectedByDryPipeSystem;
                    const isDpvTestNotApplicable = sprinklerReport?.asgrSprinklerSystemTest?.dryPipeValveTesting?.isDpvTestNotApplicable;
                    const hasDpvTest = sprinklerReport?.asgrSprinklerSystemTest?.dryPipeValveTesting?.hasDpvTest;
                    if(field.props.readonly) {
                        return  !field.props.readonly;  
                    }            
                    if ((!isDpvTestNotApplicable  || !hasDpvTest) || areaProtectedByDryPipeSystem > 0) {
                      return true;
                    }
                  },
              },

               validators: {
                 validation: [{ name: 'future-date-validator', options: { msg: 'Cannot be future date.' } }],
              
                compareWithSurveyDate: {
                    expression: (control: FormControl, field: FormlyFieldConfig) => {
                      try {
                       let surveyDate = field?.form?.root?.get('survey')?.value?.surveyDate;
                       if (surveyDate && control.value) {
                          surveyDate = moment(surveyDate);
                          const testDate = moment(control.value);
                          const diff = testDate.diff(surveyDate, 'days');
                          return !(diff && diff > 0);
                        }
                      }
                      catch (e) {
                        console.log(e);
                      }
                      return true;
                    },
                    message: 'If dry pipe testing is after survey date, then use the Schedule Applied Date to enter the test date.'
                  }
              },
            },
            {
              key: 'dpvDateAfterSurvey',
              type: 'rf-date-picker',
              wrappers: ['question-wrapper'],
              className: 'date-width padding-right-0 dry-pipe-valve-testing dry-pipe-section',
              props: {
                label: 'Test Date After Survey',
                displayFormat: 'MM/DD/YY',
                datePickerId: 'dpvDateAfterSurveyDate',
                isSectionLabelNormal: true,
                required: false,
                showError: false,
                isAsteriskNotRequired: true,
              },
              expressions: {
               
                hide: (field: FormlyFieldConfig) => !field?.parent?.parent?.parent?.model?.systemTesting?.hasScheduledAppliedDate,
                'props.required': (field: FormlyFieldConfig) => {
                    const formlyService = field.options.formState.service;
                    const sprinklerReport = formlyService?.riskReport?.model?.sprinklerReport;
                    const hasScheduledAppliedDate =  sprinklerReport?.asgrSprinklerSystemTest?.systemTesting?.hasScheduledAppliedDate;
                    const isDpvTestNotApplicable = sprinklerReport?.asgrSprinklerSystemTest?.dryPipeValveTesting?.isDpvTestNotApplicable;
                    const hasDpvTest = sprinklerReport?.asgrSprinklerSystemTest?.dryPipeValveTesting?.hasDpvTest;
                    const dpvTestDate = sprinklerReport?.asgrSprinklerSystemTest?.dryPipeValveTesting?.dpvTestDate;
                    if (hasScheduledAppliedDate === true && !(isDpvTestNotApplicable  || hasDpvTest || dpvTestDate)) {
                      return true;
                    }
                    return false;
                  },
            },
              validators: {
                validation: [{ name: 'future-date-validator', options: { msg: 'Cannot be future date.' } }],
              },
            },
            {
              key: 'hasDpvTest',
              type: 'custom-checkbox',
              wrappers: ['question-wrapper'],
              className: 'wrapper-title simple-checkbox dry-pipe-section',
              props: {
                label: 'No Dry Pipe Valve Test Available ',
                isCardEnabled: false,
                labelFirst: true,
                rightAlign: true,
                showError: false,
                firstColSize: 8,
                isSectionLabelNormal: true,
                isLabelDisabled:true,
                isTitleDisableable:false,
                required:false,
                isAsteriskNotRequired: true,
              },
              validators: {
                
              },
              expressions:{
                'props.isDisabled': (field: FormlyFieldConfig) => {
                    const root = field?.form?.root?.value;
                    return root?.sprinklerReport?.asgrSprinklerSystemTest?.dryPipeValveTesting?.dpvTestDate;
        
                },
                'props.required': (field: FormlyFieldConfig) => {
                    const formlyService = field.options.formState.service;
                    const sprinklerReport = formlyService?.riskReport?.model?.sprinklerReport;
                    const areaProtectedByDryPipeSystem = sprinklerReport?.asgrSprinklerSystemComponent?.dryPipeValve?.areaProtectedByDryPipeSystem;
                    const isDpvTestNotApplicable = sprinklerReport?.asgrSprinklerSystemTest?.dryPipeValveTesting?.isDpvTestNotApplicable;
                    if(field.props.isDisabled) {
                        return  !field.props.isDisabled;  
                    } 
                    if ((areaProtectedByDryPipeSystem ===''|| areaProtectedByDryPipeSystem === null) || !isDpvTestNotApplicable) {
                      return true;
                    }
                  },

                }
              },
            
            {
              wrappers: ['simple-section-wrapper'],
              className:'internal-exam',
              props: {
                className: 'padding-bottom',
                label: 'Internal Exam',
                isCollapsable: false,
                showError: false,
              },
              fieldGroup: [
                {
                  key: 'dpvInstallationDate',
                  type: 'rf-date-picker',
                  wrappers: ['question-wrapper'],
                  className: 'date-width padding-right-0 dry-pipe-valve-testing dry-pipe-section',
                  props: {
                    label: 'Installation Date ',
                    displayFormat: 'MM/DD/YY',
                    datePickerId: 'dpvInstallationDate',
                    isSectionLabelNormal: true,
                    required: false,
                    showError: false,
                    isAsteriskNotRequired: true,
                  },
                  validators: {
    
                  },
                  expressions:{
                    'props.required': (field: FormlyFieldConfig) => {
                        const formlyService = field.options.formState.service;
                        const sprinklerReport = formlyService?.riskReport?.model?.sprinklerReport;
                        const areaProtectedByDryPipeSystem = sprinklerReport?.asgrSprinklerSystemComponent?.dryPipeValve?.areaProtectedByDryPipeSystem;
                        const isDpvTestNotApplicable = sprinklerReport?.asgrSprinklerSystemTest?.dryPipeValveTesting?.isDpvTestNotApplicable;
                        if (areaProtectedByDryPipeSystem > 0 || !isDpvTestNotApplicable) {
                          return true;
                        }
                      },
                  }
                },
                {
                  key: 'initialPipeExamTestDate',
                  type: 'rf-date-picker',
                  wrappers: ['question-wrapper'],
                  className: 'date-width padding-right-0 dry-pipe-valve-testing dry-pipe-section',
                  props: {
                    label: 'Date of Internal Pipe Exam',
                    displayFormat: 'MM/DD/YY',
                    datePickerId: 'initialPipeExamTestDate',
                    isLabelDisabled: true,
                    isSectionLabelNormal: true,
                    showError: false,
                    firstColSize: 6,
                  },
                  validators: {
                    validation: [{ name: 'future-date-validator', options: { msg: 'Cannot be future date.' } }],
                  },
                },
              ],
            },
            DryPipeValveTripTestingSectionModel.build(formlyBuilderService).fields,
            InternalSprinklerPipeConditionsSectionModel.build(formlyBuilderService).fields,
            {
                key:'isDpvTestNotApplicable',
            },
          ],
          expressions: {
          hide: (field: FormlyFieldConfig) => formlyBuilderService.dryPipeValveTestService.checkDrySystemEntry(field)
          }
        }
      ],
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          formlyBuilderService.dryPipeValveTestService.checkDrySystemEntry(field);
        }
      },
    });
  }
}